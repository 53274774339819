import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const HistoricoIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M10 3.30623C13.8878 3.30623 17.0396 6.45794 17.0396 10.3458C17.0396 14.2336 13.8878 17.3853 10 17.3853C6.11218 17.3853 2.96047 14.2336 2.96047 10.3458C2.96047 9.6978 2.43518 9.17252 1.78721 9.17252C1.13924 9.17252 0.613953 9.6978 0.613953 10.3458C0.613953 15.5295 4.81623 19.7318 10 19.7318C15.1838 19.7318 19.3861 15.5295 19.3861 10.3458C19.3861 5.162 15.1838 0.959717 10 0.959717C7.95671 0.959717 6.06546 1.61336 4.52481 2.72145V2.13297C4.52481 1.485 3.99952 0.959717 3.35155 0.959717C2.70358 0.959717 2.1783 1.485 2.1783 2.13297V6.04383C2.1783 6.6918 2.70358 7.21709 3.35155 7.21709H5.69807C6.34604 7.21709 6.87132 6.6918 6.87132 6.04383C6.87132 5.39586 6.34604 4.87057 5.69807 4.87057H5.57482C6.78449 3.89139 8.32354 3.30623 10 3.30623ZM10.7822 6.826C10.7822 6.17803 10.2569 5.65274 9.60892 5.65274C8.96095 5.65274 8.43567 6.17803 8.43567 6.826V10.7369C8.43567 11.3848 8.96095 11.9101 9.60892 11.9101H11.9554C12.6034 11.9101 13.1287 11.3848 13.1287 10.7369C13.1287 10.0889 12.6034 9.5636 11.9554 9.5636H10.7822V6.826Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HistoricoIcon;
