import React from 'react'
import moment from 'moment'
import string from '../../../utils/string'

const CID = ({ dadosRelatorio, filters }) => {
  const { dataInicial, cid10SubcategoriaId, profissional, dataFinal } = filters

  return (
    <>
      <div style={styles.titulo}>Relatório de CID</div>
      <div style={styles.subTitulo}>
        {dataInicial && (
          <div style={styles.dataInicio}>
            Data Inicio:
            <span style={styles.subTituloSpan}>{moment(dataInicial).format('DD/MM/YYYY')}</span>
          </div>
        )}
        {dataFinal && (
          <div style={styles.dataInicio}>
            Data Fim:
            <span style={styles.subTituloSpan}>{moment(dataFinal).format('DD/MM/YYYY')}</span>
          </div>
        )}
        {profissional && (
          <div>
            Profissional:
            <span style={styles.subTituloSpan}>{profissional?.nome}</span>
          </div>
        )}
        {cid10SubcategoriaId && (
          <div>
            CID:
            <span style={styles.subTituloSpan}>{`${cid10SubcategoriaId.codigo} - ${string.truncate(
              cid10SubcategoriaId.descricao,
              18
            )}`}</span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Descrição</div>
        <div style={styles.column}>Quantidade</div>
      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          const columnColor = (index) => {
            if (index % 2 !== 0) {
              return { backgroundColor: "#F2F2F2" }

            }
            return ''
          }
          return (
            <div key={item.id} style={{ ...styles.containerColuna, ...columnColor(index) }}>
              <div style={styles.column}>{item.descricao}</div>
              <div style={styles.column}>{item.quantidade}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const styles = {
  titulo: {
    marginLeft: '0px',
    marginBottom: '10px',
    marginTop: '5px',
    paddingBottom: '5px',
    textAlign: 'center',
    fontWeight: 700,
  },
  subTitulo: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #505050',
    marginBottom: '15px',
    paddingBottom: '5px',
    fontWeight: 700,
    justifyContent: 'center',
  },
  subTituloSpan: {
    marginLeft: '8px',
  },
  dataInicio: {
    marginRight: '16px',
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 700,
    width: '100%',
  },
  containerColuna: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #505050',
    padding: '6px 0',
  },
  column: {
    width: '100%',
    textAlign: 'center',
  },
}

export default CID
