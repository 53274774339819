import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import Dialog from "../../Dialog/Dialog";
import styles from "./ModalConfirmacaoStyles";
import ButtonLong from "../../Button/ButtonLong";

const ModalConfirmacao = (props) => {
  const {
    open,
    onClose,
    classes,
    icon,
    color,
    title,
    buttons,
    children,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.paperAlert,
      }}
    >
      {icon && <div className={classNames(classes.icon, classes[color])}> {icon} </div>}
      <div
        className={classNames(
          classes.title,
          classes.marginTop,
          classes.colorFont,
        )}
      >
        {title}
      </div>
      { children }
      {buttons && (
        <div className={classNames(classes.contentButtons, buttons.flexDirection === 'column' && classes.columnContentButtons)}>
          <ButtonLong
            colorCustom={buttons.colorCustomPrimaryButton || "transparencyHover"}
            onClick={buttons.handlePrimaryButton}
            disabled={buttons.disabledPrimaryButton}
            loading={buttons.loadingPrimaryButton}
            className={buttons.isButtonColorInverte ? classes.noBoxShadow : classes.primaryButton}
          >
            {buttons.labelPrimaryButton}
          </ButtonLong>
          <ButtonLong
            colorCustom={buttons.colorCustomSecondButton || "green"}
            onClick={buttons.handleSecondButton}
            disabled={buttons.disabledSecondButton}
            loading={buttons.loadingSecondButton}
            className={buttons.isButtonColorInverte ? classes.primaryButton : classes.noBoxShadow}
          >
            {buttons.labelSecondButton}
          </ButtonLong>
        </div>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(ModalConfirmacao);
