import moment from 'moment';

const ageCategories = [
  {
    category: 'NEONATAL',
    minAge: 0,
    maxAge: 1
  },
  {
    category: 'INFANTIL',
    minAge: 1,
    maxAge: 14
  },
  {
    category: 'ADULTO',
    minAge: 15,
    maxAge: 120
  }
]

export const getAgeCategoryByBirthDate = (birthDate) => {
  const birthDateMomentObj = moment(birthDate, 'YYYY-MM-DD');
  const age = moment().diff(birthDateMomentObj, 'years');
  const ageCategory = ageCategories.find(ageCategory => age >= ageCategory.minAge && age <= ageCategory.maxAge);

  return ageCategory.category;
}
