import { cmToPx } from "./MetricUnit";

const pagePrintStyle = {
    pageStyle(config, withFooter = false) {
        const unit = 'px';
        const margemSuperior = cmToPx(config?.margem?.superior) || 2;
        const margemDireita = cmToPx(config?.margem?.direita) || 2;
        const margemInferior = cmToPx(config?.margem?.inferior) || 2;
        const margemEsquerda = cmToPx(config?.margem?.esquerda) || 2;
        
        const page = `
            padding-top: ${margemSuperior}${unit};
            padding-right: ${margemDireita}${unit};
            padding-bottom: ${margemInferior}${unit};
            padding-left: ${margemEsquerda}${unit};
        `;

        const footer = `
            right: ${margemDireita}${unit};
            bottom: ${margemInferior}${unit};
            left: ${margemEsquerda}${unit};
        `;
    
        return withFooter ? {page, footer} : page;
    },

    size(config) {
        return config?.tamanhoPapel === 'PERSONALIZADO' ? [cmToPx(config?.largura), cmToPx(config?.altura)] : config?.tamanhoPapel;
    },
};

export default pagePrintStyle;