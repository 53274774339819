import styled from 'styled-components';

export const styles = ({
  paperAlert: {
    height: '465px',
    maxWidth: '340px'
  },
  inputRoot: {
    border: 'none'
  }
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  width: 340px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProcedimentosContainer = styled.div`
  display: ${props => (props.procedimentos?.length > 0 ? 'flex' : 'none')};
  flex-wrap: wrap;
  max-height: 100px;
  gap: 10px;
  overflow-y: auto;
  padding: 8px;
  border: solid 1px rgba(80, 80, 80, 0.1);
  border-radius: 8px;
`;

export const ProcedimentoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: rgba(80, 80, 80, 0.1);
`;

export const Label = styled.label`
  font-size: 12px !important;
  font-weight: 400;
  color: #505050;
  margin-left: 4px;
`;
