import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "../styles";
import { InputForm } from "../../../components/Modal/Input";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import { listaStatus } from "../../../utils/constantes";

const InformacoesCentroDeCusto = observer((props) => {
  const { classes, handleChange, centroDeCustoStore } = props;
  const { objView } = centroDeCustoStore;

  return (
    <div className={classes.contentTabInformacoes} >
      <div className={classes.contentField}>
        <div className={classes.titleField}>Nome</div>
        <InputForm
          value={objView.nome || ""}
          onChange={(e) => handleChange("nome", e)}
          className={classes.widthMaximo}
          classes={{
            input: classes.inputRetangular,
          }}
        />
      </div>
      <div className={classes.contentField}>
        <div className={classes.titleField}>Status</div>
        <ReactSelect
            className={classes.inputStatus}
            placeholder=""
            value={{ name: objView.ativo ? "Ativo" : "Inativo", value: objView.ativo }}
            onChange={(e) => handleChange("ativo", e)}
            options={listaStatus}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
        />
      </div>
    </div>
  );
});

const InformacoesCentroDeCustoWithStyles = withStyles(styles)(
  InformacoesCentroDeCusto
);
export default inject("centroDeCustoStore")(InformacoesCentroDeCustoWithStyles);
