import React from 'react'

import {withStyles} from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    IconButton,
    CircularProgress,
  } from "@material-ui/core";
import {
    ArrowDropUp as ArrowDropUpIcon,
    ArrowDropDown as ArrowDropDownIcon,

  } from "@material-ui/icons";
import arrowUpDown from "../../assets/img/svg/arrow-up-down-color-black.svg";
import TableCell from "./TableCell";
import styles from './Table.style'
import shortid from 'shortid'

const TableApp = props => {
    let {
        classes, 
        dados, 
        className,
        columns, 
        handleClick, 
        clickable, 
        comOrdenacao, 
        ordenarTabela,
        sortFields, 
        handleClickOrdenar,
        getDadosRow,
        emptyMessage,
        isLoading,
        tableRowStyle
    } = props;

    if (className) {
        classes = {
            root: [...classes.root, className.tableRoot],
            table: className.table,
            tableHead: [...classes.tableCellHead, className.tableCellHead]
        };
    }

    const renderIconOrder = (field) => {
        
        if(!sortFields){
            return(
                ordenarTabela.sortField === field ? (
                ordenarTabela.sortDir === "DESC" ? (
                    <ArrowDropDownIcon />
                    ) : (
                    <ArrowDropUpIcon />
                    )
                ) 
                : 
                <img 
                    src={arrowUpDown}
                    alt="Arrow drop up down"
                />
            )
        }

        const indexField = sortFields.findIndex((item)=> item.campo === field)

        return(
            1 + indexField !== 0?(
            sortFields[indexField].direcao === "DESC" ? (
                <ArrowDropDownIcon />
                ) : (
                <ArrowDropUpIcon />
                )
        )
            :
            <img 
            src={arrowUpDown}
            alt="Arrow drop up down"
        />
            )
        }
       
    
    const onClickId = (id, row, isClick) => {
        getDadosRow && !isClick && getDadosRow(row);
        return handleClick && !isClick ? handleClick(id) : null;
    };

    return (
        <div className={(classes.root, classes.tabela)}>
            <Table className={classes.table}>
                <TableHead
                    classes={{
                        root: classes.tableHead
                    }}
                    >
                    <TableRow>
                        {columns?.map((column, key) => {
                            if(!column) return null
                            return (
                                <>
                            { 
                                comOrdenacao && column.field ?   
                                <TableCell paddingLeft={column.paddingLeft} onlyIcon={column.onlyIcon} key={shortid.generate()} width={column?.width || null}>
                                    <div className={classes.divHeaderComIcon}>
                                        <div className={classes.headerComIcon}> {column.Header} </div>
                                        <IconButton aria-label="expand row" size="small" className={classes.botaoOrganizar} 
                                        onClick={() => handleClickOrdenar(column.field)}
                                        >
                                            {renderIconOrder(column.field)}
                                        </IconButton>
                                    </div>
                                </TableCell>
                                :
                                <TableCell align={column.align || 'center'} paddingLeft={column.paddingLeft} onlyIcon={column.onlyIcon} key={shortid.generate()}>{column.Header}</TableCell>
                            }
                            </>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dados?.map((dado, index) => {
                        const rowStyle = dado?.rowStyle;
                        let toChange = {}
                        let isClickRowAnexo = dado?.tipo === "ARQUIVO"
                        return (
                            <TableRow
                                className={
                                    clickable && !isClickRowAnexo ? classes.tableRow : classes.tableRowCursorDefault
                                }
                                key={index}
                                style={(rowStyle && tableRowStyle) || null}
                                >
                                {columns.map((column, colIndex) => {
                                    if(!column) return null
                                    if(column.Header === "Despesa" || column.Header === "Receita" || column.Header === "Valor"){
                                        switch(dado.tipo){
                                            case "DESPESA":
                                                toChange = {
                                                    color: "#FB7676",
                                                    fontWeight: 600,

                                                }
                                            break;
                
                                            case "RECEITA":
                                            toChange = {
                                                color: "#00C1BF",
                                                fontWeight: 600,
                                            }
                                            break;
                
                                            default:
                                                toChange = {}
                                            break;
                                        }
                                        
                                    } else {
                                        toChange = column?.style ? column.style : {} 
                                    }
                                    return (
                                        <TableCell 
                                            paddingLeft={column.paddingLeft} 
                                            onlyIcon={column.onlyIcon} 
                                            style={toChange} 
                                            width={column?.width || null} 
                                            onClick={() => onClickId(dado.id, dado, column.isClick)} 
                                            key={colIndex} {...column.props}
                                        >
                                            { column.getValue(dado, index) }
                                        </TableCell>
                                        )
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {!isLoading && dados?.length === 0 && (<div>{emptyMessage}</div>)}
            {isLoading && (
                <div className={classes.circularProgessArea}> 
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};


export default withStyles(styles)(TableApp);
