import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

export const CompartilharIcon = (props) => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none" xmlns="http://www.w3.org/2000/svg"
            {...normalizeProps(props)}
        >
            <path
                d="M12.3364 0.857577C13.7628 0.838666 14.9345 1.97972 14.9534 3.40619C14.9724 4.83266 13.8313 6.00437 12.4048 6.02328C11.6793 6.0329 11.0196 5.74243 10.5441 5.26695L5.87839 8.01684C5.94014 8.23256 5.97459 8.45996 5.9777 8.69512C5.98082 8.93027 5.95242 9.15851 5.8964 9.37579L10.634 12.0004C11.0967 11.5128 11.7482 11.2052 12.4734 11.1956C13.8999 11.1767 15.0716 12.3178 15.0905 13.7442C15.1094 15.1707 13.9684 16.3424 12.5419 16.3613C11.1154 16.3802 9.94371 15.2392 9.92479 13.8127C9.92168 13.5776 9.95008 13.3493 10.0061 13.132L5.26914 10.5068C4.80635 10.9947 4.15464 11.3026 3.42909 11.3122C2.00262 11.3311 0.830907 10.1901 0.811996 8.7636C0.793085 7.33713 1.93414 6.16541 3.36061 6.1465C4.08583 6.13689 4.74521 6.4271 5.22072 6.9022L9.88706 4.15295C9.82531 3.93723 9.79086 3.70983 9.78774 3.47467C9.76883 2.0482 10.9099 0.876488 12.3364 0.857577Z"
                fill="currentColor" />
        </svg>
    )
}

export default CompartilharIcon;