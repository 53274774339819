import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "../styles";
import ListaParcelas from "./ListaParcelas";
import string from "../../../../utils/string";
import FooterModalFinanceiro from "./FooterModalFinanceiro";
import { InputForm } from "../../../../components/Modal/Input";
import { TextFieldSearch } from "../../../../components/TextField";
import InputDateForm from "../../../../components/Input/InputDateForm";
import { ReactSelect } from "../../../../components/Select/SelectSearch";
import { TituloTipoRepeticao } from "../../../../stores/Financeiro/Extrato.store";
import MultiToggleButtons from "../../../../components/Button/MultiToggleButtons";

const tabs = {
  DISPONIVEL: 0,
  EXCLUIDO: 1,
};

const Repetir = observer((props) => {
  const { classes, modalState, extratoStore } = props;
  const { handleChangeModal, titulo, errors } = extratoStore;

  const [periodicidades, setPeriodicidades] = useState([]);
  const [valoresFooter, setValoresFooter] = useState(0);
  const [tabSelected, setTabSelected] = useState(tabs.DISPONIVEL);

  const isRepetir = modalState.tipoRepeticao === TituloTipoRepeticao.REPETIR;
  const disabled =
    (titulo?.id && extratoStore.onlyReadParcelas) || !extratoStore.editMode;

  useEffect(() => {
    const periodicidades = extratoStore.findAllPeriodicidades();
    setPeriodicidades(periodicidades);
    verificaValores();
  }, []);

  useEffect(() => {
    verificaValores();

  }, [modalState.tituloParcelaList]);

  const verificaValores = () => {
    const valorFooter = extratoStore.calculaValoresModalFooter();

    setValoresFooter({
      valorPago: valorFooter.Pago,
      valorTotal: valorFooter.Total,
    });
  };

  const changeTabSelected = (tab) => {
    setTabSelected(tab);
  };

  return (
    <div className={classes.content}>
      <div className={classes.dadosRepetir}>
        <div className={classes.marginTop}>
          <div className={classes.titleField}>Periodicidade</div>
          {disabled ? (
            <div className={classes.fieldDisabled}>
              {isRepetir && titulo.periodicidade?.label
                ? string.capitalize(titulo.periodicidade?.label)
                : ""}
            </div>
          ) : (
            <ReactSelect
              value={titulo.periodicidade}
              className={classNames(
                classes.selectParcelas,
                classes.selectPeriodicidade,
                errors.periodicidade ? classes.inputError : null
              )}
              onChange={(e) => handleChangeModal("periodicidade", e)}
              options={periodicidades}
              menuPlacement={"auto"}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              error={errors.periodicidade}
            />
          )}
        </div>
        <div className={classes.marginTop}>
          <div className={classes.titleField}>Conta</div>
          {disabled ? (
            <div className={classes.fieldDisabled}>
              {titulo.contaBancaria?.label}
            </div>
          ) : (
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleContaBancariaLoadOptions}
              withPaginate
              value={titulo.contaBancaria}
              classes={{ item: classes.removePadding }}
              onChange={(e) => handleChangeModal("contaBancaria", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              classNotched={classes.notchedOutlineSearch}
              maxMenuHeight={200}
            />
          )}
        </div>
        <div className={classNames(classes.marginTop, classes.rowQuantidade)}>
          <div>
            <div className={classes.titleField}>Quantidade</div>
            <InputForm
              className={classNames(classes.inputFormQuantidade)}
              value={titulo?.quantidade?.value || titulo?.quantidade || ""}
              onChange={(e) => handleChangeModal("quantidade", e)}
              disabled={disabled}
              error={errors?.quantidade}
            />
          </div>
          <div>
            <div className={classes.titleField}>Vencimento</div>
            <InputDateForm
              value={titulo.dataVencimento}
              onChange={(e) => handleChangeModal("dataVencimento", e)}
              iconposition="end"
              invalidDateMessage=""
              disabled={disabled}
              error={errors?.dataVencimento}
            />
          </div>
        </div>
          <MultiToggleButtons
          options={["Parc. Disponíveis", "Parc. Excluídas"]}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
          classes={{ buttonsContainer: classes.buttonsTab }}
          disabled={!titulo.id}
          posicaoDesabilitada={[1]}
        />
        {modalState.tituloParcelaList.length > 0 && isRepetir && (
          <ListaParcelas tipoLista={tabSelected} />
        )}
      </div>
      
      <FooterModalFinanceiro
        valorTotal={valoresFooter.valorTotal}
        valorPago={valoresFooter.valorPago}
      />
    </div>
  );
});

export default withStyles(styles)(Repetir);
