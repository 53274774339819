import React from "react";
import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";

import CardDadosCadastrais from "../../../../components/Cards/CardDadosCadastrais";
import ItemGrid from "../../../../components/Modal/ItemGridCard";
import { InputForm } from "../../../../components/Modal/Input";
import classNames from "classnames";
import { ReactSelectCheckbox } from "../../../../components/Select/SelectSearch";

const Card = ({
  configuracaoUnidade,
  fields,
  onChange,
  title,
  classes,
  disabled,
  withChildrens,
  childrenField,
}) => {
  const validateInputValue = (value) => {
    if (value < 0) return 0;
    return value;
  };

  const renderComponent = (field) => {
    switch (field.type) {
      case "input":
        return (
          <InputForm
            value={validateInputValue(field.value)}
            className={classes.inputContainer}
            onChange={(e) => onChange(field.name, e.target.value)}
            type="number"
            placeholder={"00"}
            disabled={disabled}
          />
        );
      case "multiSelect":
        return (
          <div className={classes.contentReactSelect}>
            <ReactSelectCheckbox
              onChange={(e) => onChange(field.value, e)}
              value={configuracaoUnidade[field.value] || []}
              className={classes.Select}
              options={field.options || []}
            />
          </div>
        );
      case "" || "switch":
      default:
        return (
          <Switch
            checked={!!configuracaoUnidade[field.value]}
            color="primary"
            size="medium"
            onChange={() =>
              onChange(field.value, !configuracaoUnidade[field.value])
            }
            classes={{
              root: classes.switchRoot,
              icon: classes.switchIcon,
              iconChecked: classes.switchChecked,
              bar: classes.switchBar,
            }}
            disabled={disabled}
          />
        );
    }
  };

  return (
    <CardDadosCadastrais titulo={title}>
      {!withChildrens &&
        fields?.map((field, index) => (
          <>
            <ItemGrid
              key={index}
              size={12}
              label={field.label}
              classes={{
                item: classNames(classes.wrapperFields, {
                  [classes.borderBottom]: index !== fields.length - 1,
                }),
              }}
            >
              {renderComponent(field)}
            </ItemGrid>
          </>
        ))}
      {withChildrens &&
        fields?.map((field, index) => {
          const value = configuracaoUnidade[childrenField]?.find(
            (item) => item.campo === field.value
          )?.status;
          return (
            <ItemGrid
              key={index}
              size={12}
              label={field.label}
              classes={{
                item: classNames(classes.wrapperFields, {
                  [classes.borderBottom]: index !== fields.length - 1,
                }),
              }}
            >
              <Switch
                checked={!!value}
                color="primary"
                size="medium"
                onChange={() => onChange(field.value, !value)}
                classes={{
                  root: classes.switchRoot,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchChecked,
                  bar: classes.switchBar,
                }}
                disabled={disabled}
              />
            </ItemGrid>
          );
        })}
    </CardDadosCadastrais>
  );
};

const styles = () => ({
  Select: {
    display: "flex",
    width: "200px",
    textWrap: "nowrap",
  },
  contentReactSelect: {
    display: "flex",
    textWrap: "nowrap",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#F2F2F2",
    width: "60px",
    height: "40px",
    color: "#505050",
    marginBottom: "8px",
    borderRadius: "8px",
    "&>div>input": {
      paddingRight: "0px",
    },
  },
  wrapperFields: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    "& > div": {
      color: "#5F6368",
      fontWeight: 400,
      lineHeight: "21px",
      fontSize: "14px",
    },
  },

  switchRoot: {
    marginLeft: "-10px",
  },

  switchIcon: {
    width: "24px",
    height: "24px",
    boxShadow: "none",
    background: "#fff",
    border: "2px solid rgba(134, 134, 135, 1)",
  },
  switchChecked: {
    width: "24px",
    height: "24px",
    boxShadow: "none",
    background: "#fff",
    border: "2px solid rgba(33, 154, 151, 1)",
  },
  switchBar: {
    width: "36px",
    height: "20px",
    borderRadius: "36px",
    backgroundColor: "#868687",
    opacity: "1 !important",
    marginTop: "-10px",
    marginLeft: "-18px",
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
});

export default withStyles(styles)(Card);
