import React from 'react'
import PropTypes from 'prop-types'

import { childrenWithLoading } from './utils/childrenWithLoading'
import { renderChildren } from './utils/renderChildren'

import * as S from './styles'

const Button = React.forwardRef(({ children, href, ...props }, ref) => {
  if (href) {
    return (
      <S.Link href={href}>
        <S.Button {...props} ref={ref}>
          {children}
        </S.Button>
      </S.Link>
    )
  }
  return (
    <S.Button {...props} ref={ref}>
      {props.isLoading
        ? childrenWithLoading(props.kind, props.shape, children)
        : renderChildren(props.startEnhancer, props.endEnhancer, children)}
    </S.Button>
  )
})

Button.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'outline', 'transparent', 'transparentHover']),
  shape: PropTypes.oneOf(['pill', 'round', 'circle', 'square']),
  isLoading: PropTypes.bool,
  shadow: PropTypes.bool,
  startEnhancer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  endEnhancer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  size: PropTypes.oneOf(['default', 'mini', 'large']),
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  fullWidth: PropTypes.bool,
  width: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

Button.defaultProps = {
  kind: 'primary',
  shape: 'pill',
  isLoading: false,
  shadow: false,
  size: 'default',
  disabled: false,
}

export default Button
