import React from 'react';
import AlertDialog from "../../../../../../components/Dialog/AlertDialog";
import { observer } from "mobx-react-lite";
import {inject} from "mobx-react";

const DeleteAgendamentoAlert = observer(({ atendimentoStore, idPacoteSessao, controleSessaoStore }) => {
	const { excluirAgendamentoAlertData } = atendimentoStore;

	const handleOnConfirmExcluirAgendamento = async () => {
		const { agendamento } = excluirAgendamentoAlertData;
		try {
			const isRecorrencia = Array.isArray(agendamento);
			if(isRecorrencia) {
				await atendimentoStore.excluirAgendamentoRecorrencia();
			}else{
				await atendimentoStore.excluirAgendamento({ id: agendamento?.id });
			}
			await controleSessaoStore.resetParamsSessoes()
			atendimentoStore.closeExcluirAgendamentoAlert();
			controleSessaoStore.loadSessoes({ idPacoteSessao });
			controleSessaoStore.triggerReloadFindSelectedSessao = true;
		} catch (error) {
			atendimentoStore.openNotification({
				message: 'Falha ao excluir o agendamento selecionado.',
				variant: "error",
			});
		}
	};

	return (
		<AlertDialog
			open={excluirAgendamentoAlertData.openAlert}
			onOk={handleOnConfirmExcluirAgendamento}
			alertTitle={excluirAgendamentoAlertData?.title}
			alertDescription={excluirAgendamentoAlertData?.description}
			onCancel={atendimentoStore.closeExcluirAgendamentoAlert}
			onClose={atendimentoStore.closeExcluirAgendamentoAlert}
		/>
	);
});

const stores = ['atendimentoStore', 'controleSessaoStore'];
export default inject(...stores)(DeleteAgendamentoAlert);
