import Api from '../config/api';

export const updatePerfilPublico = (variables) => {
  return Api.post('', {
    query: `
      mutation($perfilPublico: PerfilPublicoInput) {
        updatePerfilPublico(perfilPublico: $perfilPublico) {
          id
          nome
          ativo
          atendeCrianca
          atendePresencial
          utilizaTelemedicina
          fotoPerfil
          banner
          idPublico
          exibeMedidasSeguranca
          medidasSeguranca
          permiteBuscaGoogle
          experiencia 
          convenios {
            id,
            descricao
          }
          especialidades {
            especialidadeMedica {
              id
              especialidade
            }
            anamneseModelo {
              ativo
              id
              nome
              perguntas {
                id
                pergunta
                tipo
                order
                respostas {
                  id
                  nome
                  valorResposta:valor
                }
              }
            }
            valorOnline
            valorPresencial
            ativo
          }
          formasPagamento {
            id,
            nome
          },
          perfisPublicos {
            id
            atendePresencial
            utilizaTelemedicina
            especialidades {
              especialidadeMedica {
                id
                especialidade
              }
            }
          }
          facebook
          instagram
          linkedin
          twitter
          permiteProfissionalUnidade
          email
          horarioAtendimento
          permiteBuscaProfissional
          corPersonalizada
        }
      }
    `,
    variables: {
      perfilPublico: variables
    },
  });
};

export const createPerfilPublicoUnidade = (variables) => {
  return Api.post('', {
    query: `
      mutation($perfilPublico: PerfilPublicoInput) {
        createPerfilPublicoUnidade(perfilPublico: $perfilPublico) {
          id
          nome
          ativo
          atendeCrianca
          atendePresencial
          utilizaTelemedicina
          fotoPerfil
          banner
          idPublico
          exibeMedidasSeguranca
          medidasSeguranca
          permiteBuscaGoogle 
          convenios {
            id,
            descricao
          }
          especialidades {
            especialidadeMedica {
              id
              especialidade
            }
            anamneseModelo {
              ativo
              id
              nome
              perguntas {
                id
                pergunta
                tipo
                order
                respostas {
                  id
                  nome
                  valorResposta:valor
                }
              }
            }
            valorOnline
            valorPresencial
            ativo
          }
          formasPagamento {
            id,
            nome
          },
          perfisPublicos {
            id
            atendePresencial
            utilizaTelemedicina
            especialidades {
              especialidadeMedica {
                id
                especialidade
              }
            }
          }
          facebook
          instagram
          linkedin
          twitter
          permiteProfissionalUnidade
          email
          horarioAtendimento
          permiteBuscaProfissional
        }
      }
    `,
    variables: {
      perfilPublico: variables
    },
  });
};

export const createPerfilPublicoUsuario = (variables) => {
  return Api.post('', {
    query: `
      mutation($perfilPublico: PerfilPublicoInput) {
        createPerfilPublicoUsuario(perfilPublico: $perfilPublico) {
          id
          nome
          ativo
          atendeCrianca
          atendePresencial
          utilizaTelemedicina
          fotoPerfil
          banner
          idPublico
          exibeMedidasSeguranca
          medidasSeguranca
          permiteBuscaGoogle 
          convenios {
            id,
            descricao
          }
          especialidades {
            especialidadeMedica {
              id
              especialidade
            }
            anamneseModelo {
              ativo
              id
              nome
              perguntas {
                id
                pergunta
                tipo
                order
                respostas {
                  id
                  nome
                  valorResposta:valor
                }
              }
            }
            valorOnline
            valorPresencial
            ativo
          }
          formasPagamento {
            id,
            nome
          },
          perfisPublicos {
            id
            atendePresencial
            utilizaTelemedicina
            especialidades {
              especialidadeMedica {
                id
                especialidade
              }
            }
          }
          facebook
          instagram
          linkedin
          twitter
          permiteProfissionalUnidade
          email
          horarioAtendimento
          permiteBuscaProfissional
        }
      }
    `,
    variables: {
      perfilPublico: variables
    },
  });
};

export const findByUsuarioLogadoPerfilPublico = async () => {
  const response = await Api.post('', {
    query: `
      query {
        findByUsuarioLogadoPerfilPublico {
          id
          nome
          ativo
          facebook
          instagram
          linkedin
          twitter
          permiteBuscaProfissional
          fotoPerfil
          banner
          email
          atendePresencial
          atendeCrianca
          utilizaTelemedicina
          horarioAtendimento
          avaliacaoNotaMedia
          avaliacaoQuantidade
          horarioAtendimento
          idPublico
          exibeMedidasSeguranca
          medidasSeguranca
          permiteBuscaGoogle 
          corPersonalizada
          nomeLinkPublico 
          telefonePrincipal
          telefoneSecundario
          perfilVitrine
          sobre
          formacao
          experiencia
          numeroConselho
          prefixo {
            prefixo
          }
          idiomas {
            id
            descricao
          }
          convenios {
            id,
            descricao
          }
          especialidades {
            especialidadeMedica {
              id
              especialidade
            }
            anamneseModelo {
              ativo
              id
              nome
              perguntas {
                id
                pergunta
                tipo
                order
                respostas {
                  id
                  nome
                  valorResposta:valor
                }
              }
            }
            valorOnline
            valorPresencial
            ativo
          }
          formasPagamento {
            id
            nome
          }
        }
      }
    `
  });

  if(response.data.errors){
    throw Error(response.data.errors[0].message)
  }

  const findByUsuarioLogadoPerfilPublico = response?.data?.data?.findByUsuarioLogadoPerfilPublico;
  return findByUsuarioLogadoPerfilPublico
};

export const findByUnidadeLogadaPerfilPublico = async () => {
  const response = await Api.post('', {
    query: `
      query {
        findByUnidadeLogadaPerfilPublico {
          id
          nome
          ativo
          atendeCrianca
          atendePresencial
          utilizaTelemedicina
          fotoPerfil
          banner
          idPublico
          exibeMedidasSeguranca
          medidasSeguranca
          permiteBuscaGoogle 
          corPersonalizada
          idiomas {
            id
            descricao
          }
          convenios {
            id,
            descricao
          }
          especialidades {
            especialidadeMedica {
              id
              especialidade
            }
            anamneseModelo {
              ativo
              id
              nome
              perguntas {
                id
                pergunta
                tipo
                order
                respostas {
                  id
                  nome
                  valorResposta:valor
                }
              }
            }
            valorOnline
            valorPresencial
            ativo
          }
          formasPagamento {
            id,
            nome
          },
          perfisPublicos {
            id
            atendePresencial
            utilizaTelemedicina
            especialidades {
              especialidadeMedica {
                id
                especialidade
              }
            }
          }
          facebook
          instagram
          linkedin
          twitter
          permiteProfissionalUnidade
          email
          horarioAtendimento
          permiteBuscaProfissional
          perfilVitrine
          nomeLinkPublico
          unidade {
            id
          }
        }
      }
    `
  });
  const findByUnidadeLogadaPerfilPublico = response.data?.data?.findByUnidadeLogadaPerfilPublico;
  return findByUnidadeLogadaPerfilPublico;
};

export const findAllConvenioSistema = async (search, pageableDTO) => {
  const response = await Api.post('', {
    query: `
      query($search: String, $pageableDTO: PageableDTOInput) {
        findAllConvenioSistema(search: $search, pageableDTO: $pageableDTO) {
          content {
            id
            descricao
          }
          last
        }
      }
    `,
    variables: {
      search,
      pageableDTO: pageableDTO
    }
  });

  if (!response.data.errors) {
    const { content, last } = response.data.data.findAllConvenioSistema;
    return { content, last };
  } else {
    throw(response.data.errors[0]);
  }
};

export const updatePerfilPublicoFotoBanner = (fotoBannerId, perfilPublicoId) => {
  return Api.post('', {
    query: `
      mutation($fotoBannerId: UUID, $perfilPublicoId: UUID) {
        updatePerfilPublicoFotoBanner(fotoBannerId: $fotoBannerId, perfilPublicoId: $perfilPublicoId) {
          id
        }
      }
    `,
    variables: {
      fotoBannerId,
      perfilPublicoId
    }
  });
};

export const updatePerfilPublicoFotoPerfil = (fotoPerfilId, perfilPublicoId) => {
  return Api.post('', {
    query: `
      mutation($fotoPerfilId: UUID, $perfilPublicoId: UUID) {
        updatePerfilPublicoFotoPerfil(fotoPerfilId: $fotoPerfilId, perfilPublicoId: $perfilPublicoId) {
          id
        }
      }
    `,
    variables: {
      fotoPerfilId,
      perfilPublicoId
    }
  });
};

export const findAllAvaliacaoByPerfilPublico = (perfilPublicoId, startDate, endDate, pageableDTO) => {
  return Api.post('', {
    query: `
      query($dataFim: LocalDate, $dataInicio: LocalDate, $perfilPublicoId: UUID, $pageableDTO: PageableDTOInput) {
        findAllAvaliacaoByPerfilPublico(dataFim: $dataFim, dataInicio: $dataInicio, perfilPublicoId: $perfilPublicoId, pageableDTO: $pageableDTO) {
          content {
            id
            nome
            nota
            descricao
            agendamento {
              data
            }
            avaliador {
              nome
              fotoPerfil
            }
            perfilPublicoAvaliacaoExperienciaBoa {
              boa
              experiencia
            }
            perfilPublicoAvaliacaoExperienciaRuim {
              boa
              experiencia
            }
            gostou
          }
          last
        }
      }
    `,
    variables: {
      dataInicio: startDate,
      dataFim: endDate,
      perfilPublicoId,
      pageableDTO
    }
  });
};

export const updatePerfilPublicoAvaliacao = (avaliacaoId, value) => {
  return Api.post('', {
    query: `
      mutation($perfilPublicoAvaliacaoId: UUID, $gostou: Like) {
        updatePerfilPublicoAvaliacao(perfilPublicoAvaliacaoId: $perfilPublicoAvaliacaoId, gostou: $gostou) {
          id
          nome
          nota
          descricao
          agendamento {
            data
          }
          avaliador {
            nome
            fotoPerfil
          }
          perfilPublicoAvaliacaoExperienciaBoa {
            boa
            experiencia
          }
          perfilPublicoAvaliacaoExperienciaRuim {
            boa
            experiencia
          }
          gostou
        }
      }
    `,
    variables: {
      perfilPublicoAvaliacaoId: avaliacaoId,
      gostou: value
    }
  });
}

export const ativarViziProfissionalSaudeLogado = () => {
  return Api.post('', {
    query: `
      mutation {
        ativarViziProfissionalSaudeLogado  {
          id
        }
      }
    `,
  });
};

export const findAllExtratoPagamento = async (query, variables) => {
  const response = await Api.post('', {
    query: `
      query($dataFim: LocalDateTime, $dataInicio: LocalDateTime, $pageableDTO: PageableDTOInput) {
        ${query}(dataFim: $dataFim, dataInicio: $dataInicio, pageableDTO: $pageableDTO) {
          content {
            id
            nome
            dataPagamento
            valor
            profissionalSaude {
              id
              nome
            }
            sujeitoAtencao {
              id 
              nome
            }
            unidade {
              id
              nomeFantasia
            }
          }
          last
          totalElements
        }
      }
    `,
    variables,
  });
  if (response.data.errors) {
    throw(response.data.errors[0]);
  }
  return response?.data?.data?.[query];
};
