import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import styles from "../GuiaServicosFaturamentoStyle";
import Table from "../../../../../components/Table/Table";
import ItemList from "./ItemList";
import {applyCurrencyMask} from "../../../../../utils/CurrencyMask";
import { verificaCamposVazioProcedimentos } from "./ValidaDados";

const columns = [
  {
    Header: "Data",
    paddingLeft: true,
    getValue: (row) => {
      return row.data && moment(row.data).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Hora",
    paddingLeft: true,
    getValue: (row) => {
      return `${row.horaInicial} ${row.horaFinal}`;
    },
  },
  {
    Header: "Descrição",
    paddingLeft: true,
    getValue: (row) => {
      return row.descricao.nome || row.descricao.descricao;
    },
  },
  {
    Header: "Qtde",
    paddingLeft: true,
    getValue: (row) => {
      return row.quantidade;
    },
  },
  {
    Header: "Val unit",
    paddingLeft: true,
    getValue: (row) => {
      return row.valorUnitario ? applyCurrencyMask(row.valorUnitario) : `R$ 0,00`;
    },
  },
  {
    Header: "Val total",
    paddingLeft: true,
    getValue: (row) => {
      const valorTotal = row.valorUnitario && row.valorUnitario * row.quantidade;
      return applyCurrencyMask(valorTotal);
    },
  },
];

const StepItens = (props) => {
  const {
    classes,
    setDisableButton,
    handleChangeGuiaServicoFaturamento,
    listaAgendamentos,
    guiaServicoFaturamento,
    openNotification,
    setIsEdit,
    isEdit
  } = props;

  const [showItemSelecionado, setShowItemSelecionado] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [procedimentos, setProcedimentos] = useState([]);

  useEffect(() => {
    setDisableButton(true);
    listarProcedimentos();
  }, []);

  useEffect(() => {
    validationStep();
  }, [procedimentos, guiaServicoFaturamento?.itensExecucao]);

  const handleChangeProcedimentoSelecionado = (procedimentoSelecionado) => {
    const listaProcedimentoEditado = procedimentos.map((item) => {
      return item === itemSelecionado ? 
      {
        ...procedimentoSelecionado,
        quantidade: typeof procedimentoSelecionado.quantidade === 'string' ? parseFloat(procedimentoSelecionado.quantidade) : procedimentoSelecionado.quantidade,
        valorUnitario: procedimentoSelecionado.valorUnitario.includes('R$') ? parseFloat(procedimentoSelecionado.valorUnitario.replace("R$", "").replace(".", "").replace(",", ".")) : procedimentoSelecionado.valorUnitario
      } 
    : item;
    });

    setProcedimentos(listaProcedimentoEditado);
    handleChangeGuiaServicoFaturamento(
      "itensExecucao",
      listaProcedimentoEditado
    );
  };

  const listarProcedimentos = () => {
    setProcedimentos(listaAgendamentos)
  }

  const validationStep = () => {
    if (verificaCamposVazioProcedimentos(procedimentos)) {
      setDisableButton(true)
      return;
    }
    setDisableButton(false);
  };

  return (
    <div className={classes.contentTableItens}>
      {!showItemSelecionado && procedimentos.length > 0 ? (
        <Table
          className={classes.tableItens}
          dados={procedimentos}
          columns={columns}
        />
      ) : (
        <div className={classes.contentItemEdit}>
          <ItemList
            setShowItemSelecionado={setShowItemSelecionado}
            itemSelecionado={itemSelecionado}
            guiaServicoFaturamento={guiaServicoFaturamento}
            setDisableButton={setDisableButton}
            validationStep={validationStep}
            setIsEdit={setIsEdit}
            handleChangeProcedimentoSelecionado={
              handleChangeProcedimentoSelecionado
            }
            openNotification={openNotification}
            isEdit={isEdit}
            handleChangeGuiaServicoFaturamento={handleChangeGuiaServicoFaturamento}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(StepItens);
