import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const LogoMenuIcon = props => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M23.2907 40H16.7652C14.3801 40 12.441 38.0717 12.441 35.6997V19.5284C12.441 15.3619 17.032 12.7046 19.4328 12.7046H24.8822L24.88 15.3641L19.4328 15.3664C18.3568 15.3664 15.1176 17.0472 15.1176 19.5284V35.6997C15.1176 36.6026 15.8551 37.336 16.763 37.336H23.2907C24.1985 37.336 24.9361 36.6026 24.9361 35.6997V29.4309C24.9361 27.059 26.8751 25.1306 29.2602 25.1306H35.6758C36.5837 25.1306 37.3212 24.3972 37.3212 23.4943V17.0026C37.3212 16.0998 36.5837 15.3664 35.6758 15.3664L27.5566 15.3641L27.5588 12.7046H35.6758C38.061 12.7046 40 14.6329 40 17.0049V23.4966C40 25.8685 38.061 27.7969 35.6758 27.7969H29.2602C28.3523 27.7969 27.6148 28.5303 27.6148 29.4332V35.7019C27.6148 38.0717 25.6758 40 23.2907 40Z" 
      fill="currentColor" />
      <path d="M23.2348 0H16.7093C14.3242 0 12.3852 1.92834 12.3852 4.3003V10.5691C12.3852 11.4719 11.6477 12.2054 10.7398 12.2054H4.32416C1.93904 12.2054 0 14.1337 0 16.5057V22.9974C0 25.3693 1.93904 27.2977 4.32416 27.2977H20.5672C22.968 27.2977 27.559 24.6403 27.559 20.4738V4.3003C27.559 1.92834 25.6199 0 23.2348 0ZM20.5672 24.6337H4.32416C3.41629 24.6337 2.67878 23.9002 2.67878 22.9974V16.5057C2.67878 15.6028 3.41629 14.8694 4.32416 14.8694H10.7398C13.1249 14.8694 15.064 12.941 15.064 10.5691V4.3003C15.064 3.39744 15.8015 2.664 16.7093 2.664H23.237C24.1449 2.664 24.8824 3.39744 24.8824 4.3003V12.6423H24.8735V17.4932H24.8824V20.4738C24.8824 22.9528 21.6432 24.6337 20.5672 24.6337Z" 
      fill="currentColor" />
    </svg>
  );
};

export default LogoMenuIcon;