import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../../assets/jss/pages/profissionalSaudeStyle';
import { inject } from 'mobx-react';
import {observer} from 'mobx-react-lite';
import PropagandaVizi from '../PropagandaVizi/PropagandaVizi';
import PageTitle from '../../../components/PageTitle/PageTitle';
import classNames from 'classnames';
import { 
  unidadeViziPerfilIncompletoTitle,
  unidadeViziPerfilIncompletoMessage,
  unidadeSemProfissionalTitle,
  unidadeSemProfissionalMessage,
} from '../constants/perfilViziUnidadeConstants';
import {
  dadosBancariosNaoConfiguradosMessage,
  dadosBancariosNaoConfiguradosTitle,
} from '../constants/perfilViziConstants';
import Notification from "../../../components/Notification";
import {Fab} from "@material-ui/core";
import {
	Save as SaveIcon,
	Close as CloseIcon
} from '@material-ui/icons';
import DadosInsuficientesWarning from "../components/DadosInsuficientesWarning";
import { buildUrlPerfilVizi, getViziWebUrl } from '../../../config/config';
import MultiToggleButtons from '../../../components/Button/MultiToggleButtons';
import DadosGerais from './Tabs/DadosGerais';
import Customizacao from './Tabs/Customizacao';
import Atividade from './Tabs/Atividade';
import { getWhitelabelNome } from '../../../services/WhitelabelService';

const UnidadePerfilVizi = observer((props) => {
  const { classes, unidadeStore, perfilPublicoStore, history, unidadeBraspagStore, profissionalSaudeStore } = props;
  const [showMessageDadosBancarios, setShowMessageDadosBancarios] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);

  const getErrorTab = tab => {
    const { perfilPublicoUnidadeErrors } = perfilPublicoStore;
  
    const errors = {
      tabDadosGerais: ['nome', 'nomeFantasia', 'telefonePrincipal', 'codigoCnes'],
      tabCustomizacao: ['fotoPerfil'],
      tabAtividade: ['especialidades']
    }

    const errorList = errors[tab].filter(item => perfilPublicoUnidadeErrors[item]?.error || unidadeStore?.errors[item]);
  
    return errorList.length > 0 ? errorList.length : "";
  };
  
  const getQuantidadesError = () => {
    const { perfilUnidadeIsIncomplete, perfilPublicoUnidade } = perfilPublicoStore;
  
    const quantidades =  perfilPublicoUnidade?.id && perfilUnidadeIsIncomplete ? [
      getErrorTab('tabDadosGerais'),
      getErrorTab('tabCustomizacao'),
      getErrorTab('tabAtividade')
    ] : ['', '', '']
  
    return quantidades;
  };

  
  const loadUnidadeLogadaDadosBraspag = async() => {
    const response = await unidadeBraspagStore.findByUnidadeBraspag();
		if(!response) return;
    setShowMessageDadosBancarios(response?.status === null);
  };

  const loadSelectsData = async () => {
    await profissionalSaudeStore.getLanguageList();
  }

  const handleInputChange = (value, field) => {
    if (field === 'horarioAtendimento' || field === 'medidasSeguranca' || field === 'idiomas') {
      perfilPublicoStore.perfilPublicoUnidade[field] = value;
    } else if (field === 'sobre' || field === 'email') {
      unidadeStore.unidadePublica[field] = value;
    } else {
      unidadeStore.unidade[field] = value;
    }

    if (field === 'nomeFantasia') {
      perfilPublicoStore.checkPerfilUnidadeIsComplete();
    }
  };

  const handleCloseNotification = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    unidadeStore.closeNotification();
  };

  const handleUpdate = async () => {
		try {
      await unidadeStore.updateUnidadeLogada('perfilVizi');
			if (unidadeStore.unidade.perfilPublico) {
				if (perfilPublicoStore.perfilPublicoUnidade.id) {
					perfilPublicoStore.updatePerfilPublicoUnidade();
        } else {
					perfilPublicoStore.createPerfilPublicoUnidade();
				}
        unidadeStore.updateUnidadeLogadaInformacoesPublicas();
			}
			const feedbackMessage = unidadeStore.unidade.perfilPublico ? 
				'Cadastro da unidade e perfil Vizi da Unidade atualizados com sucesso.' :
				'Cadastro da unidade atualizado com sucesso';
			unidadeStore.openNotification(feedbackMessage, 'success');
		} catch(error) {
			unidadeStore.openNotification(error, 'error');
		}
  };

  const cancelUpdade = () => {
    history.push({
      pathname: '/'
    });
  };

  const createMinimumPerfilUnidade = async () => {
    try {
      await perfilPublicoStore.createUnidadePerfilPublicoWithMainData();
    } catch(error) {
      throw error;
    }
  };

  const renderTab = () => {
    const contentTab = [
      <DadosGerais handleInputChange={handleInputChange}/>,
      <Customizacao handleInputChange={handleInputChange} />,
      <Atividade handleInputChange={handleInputChange} />,
    ]
    return contentTab[tabSelected];
  }

  useEffect(() => {
    const shouldCreateMininum = perfilPublicoStore?.shouldCreateMininumUnidadePerfilPublico
    if(shouldCreateMininum) {
      createMinimumPerfilUnidade();
    }
  }, [perfilPublicoStore])

  useEffect(() => {
    loadUnidadeLogadaDadosBraspag();
  }, []);

  useEffect(() => {
    loadSelectsData()
  }, []);

  return (
    <>
      <PageTitle title="Perfil Vizi Unidade" />
      {unidadeStore.unidade.perfilPublico ? 
        <div className={classes.tableContainer}>
          <div className={classes.header}>
            <h3 className={classNames(classes.titleHeader, classes.headerTitleRoot)}>Perfil da Unidade no {getWhitelabelNome()}</h3>
            <div className={classes.viziLinkContainer}>
              <span className={classes.viziLinkLabel}>{getWhitelabelNome()}</span>
              <a className={classes.viziLink} target="_blank" href={`${getViziWebUrl()}${buildUrlPerfilVizi(perfilPublicoStore.perfilPublicoUnidade)}`} rel="noreferrer">ACESSAR PORTAL</a>
            </div>
          </div>
          <div className={classes.warnings}>
					{!unidadeStore.isLoadingInfo && perfilPublicoStore.perfilUnidadeIsIncomplete && (
						<DadosInsuficientesWarning 
							title={unidadeViziPerfilIncompletoTitle}
							message={unidadeViziPerfilIncompletoMessage}
              withoutButton
              isPerfilWarning
              requiredFields={perfilPublicoStore.perfilPublicoUnidadeErrors}
						/>
					)}
					{!unidadeStore.isLoadingInfo && perfilPublicoStore.perfilPublicoUnidade.perfisPublicos?.length === 0 && (
						<DadosInsuficientesWarning
							title={unidadeSemProfissionalTitle}
							message={unidadeSemProfissionalMessage}
							withoutButton
						/>
					)}
          {showMessageDadosBancarios && (
            <DadosInsuficientesWarning
              title={dadosBancariosNaoConfiguradosTitle}
              message={dadosBancariosNaoConfiguradosMessage}
              buttonText="Preencher dados bancários"
              onClickButton={() => history.push('/perfil/recebimentos-unidade')}
            />
          )}
				</div>
        <div className={classes.contentWithMultiToggleButtons}>
          <MultiToggleButtons
								options={["Dados gerais", "Customização", "Atividade"]}
								tabSelected={tabSelected}
								changeTabSelected={(tab) => setTabSelected(tab)}

								classes={{ buttonsContainer: classes.toogleButtonsContainer }}
								quantidades={!unidadeStore.isLoadingInfo && !profissionalSaudeStore.isLoadingInfo && getQuantidadesError()}
								colorQuantidade="#FB7676"
					/>
          {renderTab()}
        </div>
          <Notification
            close={handleCloseNotification}
            isOpen={unidadeStore.notification.isOpen}
            variant={unidadeStore.notification.variant}
            message={unidadeStore.notification.message}
          />
          <div className={classes.acoes}>
            <Fab 
              className={classes.buttonSalvar} 
              onClick={handleUpdate}
              disabled={unidadeStore.loadingSaveDadosUnidade || unidadeStore.isLoadingInfo}
            >
              <SaveIcon/>
            </Fab>
            <Fab className={classes.buttonCancelar} onClick={cancelUpdade}>
              <CloseIcon/>
            </Fab>
          </div>
        </div> : 
        <PropagandaVizi />
      }
    </>
  );
});

const UnidadePerfilViziStyles = withStyles(styles)(UnidadePerfilVizi);
const stores = ['unidadeStore', 'perfilPublicoStore', 'profissionalSaudeStore', 'unidadeBraspagStore'];
export default inject(...stores)(UnidadePerfilViziStyles);
