import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import styled from 'styled-components';

const HeaderComponent = styled.div`
    display: flex;
    align-items: center;
    height: 64px;
    padding: ${props => props.padding ? '0 16px 0 2%' : 0};
    border-bottom: ${props => props.borderBottom ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'};
`;

const Background = styled.div`
    position:absolute;
    height: 64px;
    top: 0px;
    background: transparent;
    width: 100%;
    box-shadow: ${props => (props.disableShadow ? 0 : '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)')};
    z-index:${props => (props.zIndex ? props.zIndex : '-1')};
`;


const styles = theme => ({
});


function Header(props) {
    const {classes, disableShadow, zIndex, ...other} = props;
    return (
        <>
            <HeaderComponent className={classes.root} {...other}>
                {other.children}
            </HeaderComponent>
            <Background disableShadow={disableShadow} zIndex={zIndex}/>
        </>
    )

}

export default withStyles(styles)(Header)
