import { conformToMask } from "react-text-mask";
import string from "./string";

export const phoneMask = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/
];

export const applyCodigoTussMask = codigoTuss => {
  if (string.isEmpty(codigoTuss)) {
    return "";
  }

  const mask = phoneMask;

  const conformedCodigoTuss = conformToMask(codigoTuss, mask, {
    guide: false
  });

  return conformedCodigoTuss.conformedValue;
};
