import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ListaPerguntasAnamnese from "../../Atendimento/ModalSolicitacoes/components/DocumentoSolicitacao/Anamnese";

const Anamnese = observer(({ classes, solicitacoesPacientesStore }) => {
  const { propsModalSolicitacoesPacientes } = solicitacoesPacientesStore;
  const { dados, edit } = propsModalSolicitacoesPacientes;
  const [perguntaModificada, setPerguntaModificada] = useState({});

  useEffect(() => {
    solicitacoesPacientesStore.anamnesePerguntasAlteradas = perguntaModificada;
  }, [perguntaModificada]);

  return (
    <div className={classes.content}>
      <div className={classes.nomePaciente}> {dados?.sujeitoAtencao?.nome} </div>
      <ListaPerguntasAnamnese
        perguntas={dados.perguntas}
        isDisabled={!edit}
        getValuesAnamnese={(value) => setPerguntaModificada(value)}
        isSolicitacaoPaciente={true}
      />
    </div>
  );
});

const styles = {
  nomePaciente: {
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "8px",
  },
  content: {
    padding: "16px",
  },
};

const AnamneseWithStyles = withStyles(styles)(Anamnese);
export default inject("solicitacoesPacientesStore")(AnamneseWithStyles);
