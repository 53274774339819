import React, { useEffect, useState } from 'react'
import {
  DialogContent,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Checkbox,
} from '@material-ui/core'
import PageTitle from '../../../../../../components/PageTitle/PageTitle'
import { Button } from '../../../../../../components/ui/Buttons'
import Dialog from '../../../../../../components/Dialog/Dialog'
import DialogTitle from '../../../../../../components/Dialog/DialogTitle'
import CloseIcon from '../../../../../../components/Icon/CloseIcon'
import DragAndDropList from './DragAndDropList'

import { theme } from '../../../../../../template/Theme'
import {
  findAllCampoPaciente,
  findAllCampoPacienteByProfissionalSaudeLogadoList,
  updateFiltroCampoPacienteProfissionalSaudeLogado,
} from '../../../../../../services/SujeitoAtencaoService'

const PersonalizarColunas = ({
  classes,
  open,
  onClose,
  fieldsAlreadyUpdated,
  openNotification,
}) => {
  const [selectedCamposPacienteList, setSelectedCamposPacienteList] = useState([])
  const [allCamposPacienteList, setAllCamposPacienteList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadAllCamposPacienteList = async () => {
    try {
      const allCamposPacienteList = await findAllCampoPaciente()
      const selectedCamposPaciente = await findAllCampoPacienteByProfissionalSaudeLogadoList()

      const newSelectedCamposPaciente = selectedCamposPaciente.map(campo => {
        const campoPaciente = { ...campo.campoPaciente, checked: true }
        if (['STATUS', 'NOME'].includes(campoPaciente.campo)) {
          campoPaciente.disabled = true
        }
        return campoPaciente
      })

      const newAllCamposPacienteList = allCamposPacienteList.map(campoPaciente => {
        const checked = selectedCamposPaciente.some(
          selectedCampo => selectedCampo.campoPaciente.campo === campoPaciente.campo
        )
        const campo = { ...campoPaciente, checked }
        if (['STATUS', 'NOME'].includes(campo.campo)) {
          campo.disabled = true
        }
        return campo
      })

      setAllCamposPacienteList(newAllCamposPacienteList)
      setSelectedCamposPacienteList(newSelectedCamposPaciente)
    } catch (error) {
      console.error('Error loading fields:', error)
      openNotification('Erro ao carregar campos', 'error')
    }
  }

  const handleChangeCampoPacienteList = selectedCamposPacienteList => {
    setSelectedCamposPacienteList(selectedCamposPacienteList)
  }

  const updateSelectedCampoPacienteList = (selectedCamposPacienteList, campoPaciente) => {
    const updatedCampoPaciente = { ...campoPaciente, checked: !campoPaciente.checked }
    const campoIndex = selectedCamposPacienteList.findIndex(
      cp => cp.campo === updatedCampoPaciente.campo
    )
    if (campoIndex >= 0) {
      selectedCamposPacienteList[campoIndex] = updatedCampoPaciente
    } else {
      selectedCamposPacienteList.push(updatedCampoPaciente)
    }
    return selectedCamposPacienteList.filter(campoPaciente => campoPaciente.checked)
  }

  const handleChangeCheckbox = campoPaciente => {


    const updatedSelectedCampoPacienteList = updateSelectedCampoPacienteList(
      selectedCamposPacienteList,
      campoPaciente
    )
    const updatedAllCamposPacienteList = allCamposPacienteList.map(campo => {
      if (campo.campo === campoPaciente.campo) {
        return { ...campo, checked: !campo.checked }
      }
      return campo
    })
    setAllCamposPacienteList(updatedAllCamposPacienteList)
    setSelectedCamposPacienteList(updatedSelectedCampoPacienteList)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      await updateFiltroCampoPacienteProfissionalSaudeLogado({
        filtroCamposPaciente: selectedCamposPacienteList.map(item => {
          const { campo, ordem, nome } = item
          return { campo, ordem, nome }
        }),
      })
      await fieldsAlreadyUpdated()
      openNotification('Barra de atalhos atualizada com sucesso!', 'success')
      onClose()
    } catch (error) {
      console.error(error)
      openNotification('Erro ao atualizar barra de atalhos!', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadAllCamposPacienteList()
  }, [])

  return (
    <>
      <PageTitle title='Editar barra de atalhos' />
      <Dialog
        classes={{ scrollPaper: classes.scrollPaper, paper: classes.paper }}
        maxWidth='md'
        open={open}
        onClose={onClose}
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogHeader }} disableTypography>
          <div className={classes.dialogTitle}>
            <div className={classes.dialogTitleName}>
              <h1>Editar barra de atalhos</h1>
            </div>
            <Button onClick={onClose} bgColor='#FB7676' shape='circle'>
              <CloseIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContentBorder}>
          <div className={classes.selectedItens}>
            <h1>Itens Selecionados</h1>
            <DragAndDropList
              selectedCampoPaciente={selectedCamposPacienteList}
              handleChangeCampoPacienteList={handleChangeCampoPacienteList}
              handleChangeCheckbox={handleChangeCheckbox}
            />
          </div>
          <div className={classes.dialogContent}>
            <h1 className={classes.titleAllFieldPacientes}>
              Selecione até 06 itens para mostrar na barra:
            </h1>
            <Grid className={classes.gridContainer} container direction='column'>
              {allCamposPacienteList.map((item, index) => (
                <div className={classes.campoPaciente} key={index}>
                  <Grid item>
                    <ListItem
                      key={item.campo}
                      button
                      onClick={() => handleChangeCheckbox(item)}
                      disabled={item.disabled}
                    >
                      <ListItemIcon>
                        <Checkbox checked={item.checked} color='primary' />
                      </ListItemIcon>
                      <ListItemText
                        id={item.campo}
                        primary={item.nome}
                        className={classes.listItemText}
                      />
                    </ListItem>
                  </Grid>
                </div>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <div className={classes.wrapperFooter}>
          <Button onClick={onClose} kind='transparent'>
            Cancelar
          </Button>
          <Button onClick={handleSave} isLoading={isLoading}>
            Salvar
          </Button>
        </div>
      </Dialog>
    </>
  )
}

const styles = {
  root: {
    position: 'relative',
    overflow: 'none',
    zIndex: 1,
  },
  paper: {
    borderRadius: 35,
    overflow: 'unset',
    height: 630,
  },
  dialogHeader: {
    background: 'white',
    borderRadius: '15px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 1rem 0.5rem',
  },
  dialogTitleName: {
    display: 'flex',
    alignItems: 'center',
    '& > h1': {
      color: '#333333',
      fontSize: '18px',
      fontWeight: 'bold',
      marginLeft: '8px',
    },
  },
  dialogContentBorder: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F9F9F9',
    padding: '1rem',
    borderRadius: '0 0 15px 15px',
    overflow: 'hidden'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '65%',
    height: '100%',
    margin: 0,
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    borderRadius: '10px',
  },
  gridContainer: {
    margin: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  titleAllFieldPacientes: {
    color: '#505050',
    fontSize: '16px',
    margin: '24px 0 0 35px',
  },
  selectedItens: {
    overflowY: 'scroll',
    width: '35%',
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginRight: '1rem',
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    borderRadius: '10px',
    height: '100%',
    '& > h1': {
      fontSize: '16px',
      color: '#505050',
      margin: '24px',
    },
    '& > ul': {
      paddingTop: '0px',
    },
  },
  campoPaciente: {
    display: 'flex',
    flexDirection: 'row',
    '& > div>div>div:first-child': {
      marginRight: '0px',
    },
  },
  container: {
    height: '300px',
    border: '1px solid ' + theme.palette.commons.gray3,
    marginTop: '20px',
    padding: '20px',
    borderRadius: '8px',
  },
  listItemText: {
    padding: '0 8px',
    '& > span': {
      color: '#505050',
      textTransform: 'capitalize',
      fontWeight: '500',
    },
  },
  wrapperFooter: {
    display: 'flex',
    padding: '1rem',
    backgroundColor: '#F1F1F3',
    '& > button': {
      flex: 1,
      margin: '0 0.5rem',
    },
  },
}

export default withStyles(styles)(PersonalizarColunas)
