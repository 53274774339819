import React from "react";
import Dialog from "../Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonAlert from "../Button/ButtonAlert";
import CloseIcon from '@material-ui/icons/Close';
import { Fab, MenuItem } from '@material-ui/core';
import { withStyles, Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { TextField } from "../../components/TextField";
import { useState } from "react";



const styles = theme => ({
    alertContainer: {
        width: 260,
        maxHeight: 340,
        paddingLeft: 5,
        paddingRight: 5,
    },
    alertTitle: {
        "& h6": {
            fontSize: 16,
        },
        width: '80%'
    },
    content: {
        display: 'grid',
        justifyContent: 'center',
    },
    containerButtons: {
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15,
    },

    buttonSalvar: {
        fontWeight: 'bold',
        width: '200px',
        height: 38,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
        borderRadius: 85,
        "& span": {
            fontSize: 15
        },
        marginLeft: 5,
        background: '#26ACA9',
        "&:hover": {
            background: '#218785'
        }
    },
    button: {
        position: 'absolute',
        right: 20,
        top: 20
    },
    buttonClose: {
        width: '24px',
        height: '24px',
        minHeight: 'initial',
        boxShadow: 'none',
        color: '#fff',
        background: '#868686',
    }
});

const AdicionarCodigoTvModal = props => {
    const {
        open,
        onClose,
        onSave,
        unidadeQuantity,
        classes
    } = props;

    const [dataTv, setDataTv] = useState({
        conexaoTV: "REDE"
    });

    const optionTipo = [{
        label: "Rede", value: "REDE"
    }, {
        label: "Unidade", value: "UNIDADE"
    }]


    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataTv(data => ({
            ...data,
            [name]: value
        }));
    };

    return (

        <Dialog
            open={open}
            onClose={onClose}
        >
            <div className={classes.alertContainer}>
                <DialogTitle className={classes.alertTitle}>
                    <Grid>
                        <div>
                            Adicionar código da TV
                        </div>
                        <div className={classes.button}>
                            <Fab className={classes.buttonClose} onClick={onClose}>
                                <CloseIcon fontSize={'small'} />
                            </Fab>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogActions className={classes.content}>
                    <Grid
                        container
                        spacing={16}
                        className={classes.containerButtons}
                    >
                        {unidadeQuantity > 1 &&
                            <Grid item xs={12}>
                                <Typography
                                    color="primary"
                                    component="label"
                                >
                                    Tipo
                                </Typography>
                                <TextField
                                    name="conexaoTV"
                                    select
                                    value={dataTv.conexaoTV}
                                    onChange={handleChange}
                                >
                                    {optionTipo.map((option) => (
                                        <MenuItem key={option.value} value={option.value} >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Grid>

                        }
                        <Grid item xs={12}>
                            <Typography
                                color="primary"
                                component="label"
                            >
                                Código
                            </Typography>
                            <TextField
                                name="codigoTv"
                                value={dataTv.codigoTv}
                                onChange={handleChange}
                            />

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        className={classes.containerButtons}>
                        <Grid>
                            <ButtonAlert
                                className={classes.buttonSalvar}
                                onClick={() => onSave(dataTv)}
                                autoFocus
                            >
                                Salvar
                            </ButtonAlert>
                        </Grid>

                    </Grid>
                </DialogActions>
            </div>
        </Dialog>
    )
};

export default withStyles(styles)(AdicionarCodigoTvModal);
