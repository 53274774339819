import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'

import Dialog from '../../../components/Dialog/Dialog'
import InfoIconFilled from '../../../components/Icon/InfoIconFilled'
import CloseIcon from '../../../components/Icon/CloseIcon'
import CardAtendimento from './CardAtendimento'

import { getProfissionalSaudeLogado } from '../../../services/UsuarioService'
import { findAgendamentosAtendendo } from '../../../services/ProntuarioService'
import moment from 'moment'
import Scroll from '../../../components/InfiniteScroll/Scroll'
import { Button } from '../../../components/ui/Buttons'
import PlayIcon from '../../../components/Icon/Play'
import string from '../../../utils/string'

const ModalAtendimentos = ({
  open,
  onClose,
  classes,
  agendamentoSelected,
  onStopAgendamento,
  onPlayAgendamento,
  handleSelectAgendamento,
  isLoading,
}) => {
  const [agendamentosAtendendo, setAgendamentosAtendendo] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(null)
  const [loadingFinaliza, setLoadingFinaliza] = useState(false)

  const loadAllAgendamentosAtendendo = async options => {
    try {
      const profissionalSaudeLogado = await getProfissionalSaudeLogado()
      const response = await findAgendamentosAtendendo({
        profissionalSaudeId: profissionalSaudeLogado.id,
        pageableDTO: {
          pageNumber: options?.isClearable ? 0 : pageNumber,
          pageSize: 3,
        },
        dataInicio: moment().format('YYYY-MM-DD'),
        dataFim: moment().format('YYYY-MM-DD'),
      })

      setHasMore(!response.last)
      setPageNumber(prevState => {
        if (response.last) return prevState
        return prevState + 1
      })
      setAgendamentosAtendendo(prevState => [...prevState, ...response.content])
    } catch (error) {
      console.error(error)
    }
  }

  const handleStopAgendamento = async (e, agendamento) => {
    setLoadingFinaliza(true)
    try {
      await onStopAgendamento(e, agendamento)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingFinaliza(false)
    }
  }

  useEffect(() => {
    loadAllAgendamentosAtendendo()
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth={true}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.actionsHeader}>
            <div className={classes.wrapperIcon}>
              <InfoIconFilled color='white' size={24} />
            </div>
            <CloseIcon onClick={onClose} size={24} />
          </div>
          {!agendamentoSelected?.dataInicioAtendimento && (
            <div className={classes.contentCurrentAgendamento}>
              <div>
                <h1>Iniciar atendimento</h1>
                <span>Com {string.truncate(agendamentoSelected?.nome, 15)}</span>
              </div>
              <Button
                kind='primary'
                shape='circle'
                size='large'
                onClick={e => {
                  onPlayAgendamento(e, agendamentoSelected)
                  onClose()
                }}
                disabled={isLoading}
              >
                <PlayIcon color='white' size={24} />
              </Button>
            </div>
          )}
          <h2>Você ja está com atendimentos em andamento</h2>
          <span>Deseja finalizar algum atendimento?</span>
        </div>
        <div className={classes.wrapperContent}>
          <Scroll
            loadMore={loadAllAgendamentosAtendendo}
            hasMore={hasMore}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainer}
          >
            {agendamentosAtendendo.map(agendamento => (
              <CardAtendimento
                agendamento={agendamento}
                onStopAgendamento={e => handleStopAgendamento(e, agendamento)}
                handleSelectAgendamento={() => {
                  handleSelectAgendamento(agendamento?.id)
                  onClose()
                }}
                loadingFinaliza={loadingFinaliza}
              />
            ))}
          </Scroll>
        </div>
      </div>
    </Dialog>
  )
}

const styles = {
  paper: {
    width: '350px',
    height: 'fit-content',
    overflow: 'hidden',
    maxHeight: '630px',
    padding: '24px',
  },
  scrollContainer: {
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 4px',

    '& h2': {
      fontSize: '14px',
      margin: 0,
      fontWeight: '600',
      color: '#505050',
    },
    '& span': {
      fontSize: '14px',
      fontWeight: '400',
      color: '#505050',
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  contentCurrentAgendamento: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    padding: '8px 0px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
    '& h1': {
      fontSize: '18px',
      fontWeight: '600',
      color: '#505050',
      margin: 0,
    },
  },
  actionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  wrapperIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#219A97',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    overflow: 'hidden',
  },
  wrapperContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 200px)',
  },
}

export default withStyles(styles)(ModalAtendimentos)
