import React, { Component } from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles/index";
import HeaderConfiguracoes from "../../../components/Header/HeaderConfiguracoes";
import ListaConfiguracoes from "../../../components/List/ListaConfiguracoes";
import Notification from "../../../components/Notification";
import {
  listarDocumentoModelo,
  inativarDocumentoModelo,
  ativarDocumentoModelo,
} from "../../../services/DocumentoService";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import { checkUserRole } from "../../../utils/checkUserRole";

const defaultFilterProfissional = {
  show: false,
  profissionalSaude: null,
};

@inject("googleAnalyticsStore", "usuarioStore", "modeloDocumentoStore")
class ListarDocumentos extends Component {
  state = {
    notification: {
      isOpen: false,
      message: "",
    },
    documentos: [],
    isLoading: true,
    search: "",
    status: null,
    pageNumber: 0,
    unidadeIdLogado: null,
    ordenarTabela: {
      sortField: "nome",
      sortDir: "ASC",
    },
    filterProfissional: defaultFilterProfissional,
  };

  async componentDidMount() {
    this.consulta();
    
    const unidadeAtual = await this.props.usuarioStore.getUnidadeAtual();
    this.setState({ unidadeIdLogado: unidadeAtual.id });
    
    this.verificaFilterProfissional();
    const paginaVisualizada = {
      page: window.location.hash,
      title: "Configurações - Modelos de documento",
    };
    this.props.googleAnalyticsStore.pageView(paginaVisualizada);
  }

  verificaFilterProfissional = async() => {
    const { modeloDocumentoStore } = this.props;
    const canViewFilterProfissional = await checkUserRole('ROLE_DOCUMENTO_MODELO_READ_OUTROS_PROFISSIONAIS');
    
    canViewFilterProfissional && await modeloDocumentoStore.getProfissionais();

    this.setState({
      filterProfissional: {
        show: canViewFilterProfissional,
        profissionalSaude: null,
      }
    });
  };

  handleProfissionalSaudeChange = (e) => {
    this.setState({
      filterProfissional: {
        ...this.state.filterProfissional,
        profissionalSaude: e,
      }
    }, () => this.consulta(true));
  };

  consulta = (clear=false) => {
    this.setState({ isLoading: true });
    const {
      status,
      search,
      pageNumber,
      ordenarTabela,
      documentos,
      filterProfissional,
    } = this.state;

    let filters = {
      documentos,
      pageNumber,
      search,
    }

    if(clear) {
      filters = {
        documentos: [],
        pageNumber: 0,
        search: "",
      }
    }

    listarDocumentoModelo({
        search,
        profissionalSaudeId: filterProfissional.profissionalSaude?.id,
        ativo: status,
        pageableDTO: {
          pageNumber: filters.pageNumber,
          sortDir: ordenarTabela.sortDir,
          sortField: ordenarTabela.sortField,
          pageSize: 30,
        }
      })
      .then((response) => {
        const { last, content } = response;
        this.setState({
          documentos: [...filters.documentos, ...content],
          last: last,
        });
      })
      .catch((error) => {
        const notification = {
          isOpen: true,
          message: "Erro ao carregar lista de documentos",
        };
        this.showAlertMessage(notification);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  showAlertMessage = (notification) => {
    this.setState({ notification: notification });

    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    this.setState({ notification: notification });
  };

  loadMore = () => {
    const { isLoading, documentos, pageNumber } = this.state;
    if (isLoading) {
      return;
    }

    this.setState({ pageNumber: documentos.length > 0 ? pageNumber + 1 : 0 });

    this.consulta();
  };

  atualizarIdDocumentoSelecionado = async (id) => {
    this.setState({ idDocumentoSelecionado: id });
  };

  handleClickOrdenar = async (value) => {
    const { ordenarTabela } = this.state;

    const sortDir =
      ordenarTabela.sortField !== value
        ? "ASC"
        : ordenarTabela.sortDir === "ASC"
        ? "DESC"
        : "ASC";

    this.setState(
      {
        ordenarTabela: {
          sortDir: sortDir,
          sortField: value,
        },
        documentos: [],
        pageNumber: 0,
      },
      () => this.consulta()
    );
  };

  handleClickSearch = () => {
    this.setState(
      {
        documentos: [],
        pageNumber: 0,
      },
      () => this.consulta()
    );
  };

  handleClickClear = async () => {
    this.setState(
      {
        search: "",
        documentos: [],
        pageNumber: 0,
      },
      () => this.consulta()
    );
  };

  onChangeSearch = (e) => {
    const search = e.target.value;
    this.setState({ search });
  };

  onKeypressSearch = (e) => {
    if (e.key === "Enter" && this.state.search.length >= 3) {
      this.setState(
        {
          documentos: [],
          pageNumber: 0,
        },
        () => this.consulta()
      );
    }
  };

  handleChangeStatus = (e) => {
    const value = e.target.value;
    this.setState(
      {
        status: value,
        documentos: [],
        pageNumber: 0,
      },
      () => this.consulta()
    );
  };

  handleClickNovoDocumento = () => {
    const { history } = this.props;
    this.props.modeloDocumentoStore.profissionalSaudeSelected = null;
    history.push("modelos-documento/criar");
  };

  handleEditar = async (id) => {
    const { history } = this.props;
    await history.push(`modelos-documento/${id}/editar`);
  };

  handleClickRow = (id) => {
    this.handleEditar(id);
  };

  handleAtivar = async () => {
    const { idDocumentoSelecionado } = this.state;
    await ativarDocumentoModelo(idDocumentoSelecionado);

    this.setState(
      {
        documentos: [],
        pageNumber: 0,
      },
      () => this.consulta()
    );
  };

  handleInativar = async () => {
    const { idDocumentoSelecionado } = this.state;
    await inativarDocumentoModelo(idDocumentoSelecionado);
    
    this.setState(
      {
        documentos: [],
        pageNumber: 0,
      },
      () => this.consulta()
    );
  };

  render() {
    const { classes, modeloDocumentoStore } = this.props;
    const {
      notification,
      search,
      ordenarTabela,
      documentos,
      paginaAtual,
      last,
      isLoading,
      idDocumentoSelecionado,
      filterProfissional,
    } = this.state;
    const status = [
      { name: "Ativo", label: "Ativo", value: true },
      { name: "Inativo", label: "Inativo", value: false },
    ];

    return (
      <>
        <div>
          <PageTitle title={pageConfiguracoes("Modelos de documento")} />
          <HeaderConfiguracoes
            handleClickSearch={this.handleClickSearch}
            handleClickClear={this.handleClickClear}
            placeholderSearch="Pesquisa por documento"
            valueSearch={search}
            showSearch
            onChangeSearch={this.onChangeSearch}
            onKeypressSearch={this.onKeypressSearch}
            valueStatus={this.state.status}
            handleChangeStatus={this.handleChangeStatus}
            showStatus
            status={status}
            filterProfissional={{
              ...filterProfissional,
              profissionaisSaude: modeloDocumentoStore.profissionaisSaude,
              handleProfissionalSaudeChange: this.handleProfissionalSaudeChange,
            }}
          />
          <div className={classes.contentList}>
            <ListaConfiguracoes
              showDuplicar
              lista={documentos}
              paginaAtual={paginaAtual}
              lastScroll={last}
              loadMore={this.loadMore}
              isLoading={isLoading}
              idConvenioSelecionado={this.atualizarIdDocumentoSelecionado}
              handleClickOrdenar={(value) => this.handleClickOrdenar(value)}
              handleEditar={() => this.handleEditar(idDocumentoSelecionado)}
              handleClickRow={(id) => this.handleClickRow(id)}
              handleAtivar={this.handleAtivar}
              handleInativar={this.handleInativar}
              ordenarTabela={ordenarTabela}
              handleClickNovo={this.handleClickNovoDocumento}
            />
          </div>
        </div>
        <Notification
          close={this.closeAlertMessage}
          reset={this.closeAlertMessage}
          isOpen={notification.isOpen}
          variant={"error"}
          message={notification.message}
        />
      </>
    );
  }
}

const styles = {
  contentList: {
    display: "flex",
    flexDirection: "row",
    color: "#fff",
    fontWeight: 600,
    alignItems: "center",
  },
};

export default withStyles(styles)(ListarDocumentos);
