import React from 'react';
import Timer from "react-compound-timer";

const Tempo = ({timer, startImmediately = true}) =>(
    <Timer
            initialTime={
            timer?.initialTime 
            
        }
        startImmediately = {startImmediately}
            >
            <Timer.Hours
            formatValue={value =>
            `${
                value < 10
                    ? `0${value}`
                    : value
            }:`
        }
            />
            <Timer.Minutes
            formatValue={value =>
            `${
                value < 10
                    ? `0${value}`
                    : value
            }:`
        }
            />
            <Timer.Seconds
            formatValue={value =>
            `${
                value < 10
                    ? `0${value}`
                    : value
            }`
        }
            />
    </Timer>
    )


const TempoAtendimento = (props) => {
    const { timer, isPlaying, isAtendimentoFinalizado } = props


    const validatedTimer = {
        initialTime: timer?.initialTime > 0 ? timer.initialTime : 0
    }

    return(
        <>
            {isPlaying ? 
                <Tempo timer={validatedTimer} />
                : !isAtendimentoFinalizado && ("00:00:00")}
            {isAtendimentoFinalizado &&(
                <Tempo timer={validatedTimer} startImmediately={false} />
            )}
        </>
    )
    

}

export default TempoAtendimento;
