const styles = {
  fullSizePaper: {
    display: "flex",
    borderRadius: "8px",
    padding: "24px",
    gap: "16px",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    overflow: "auto",
  },
  searchInput: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    "& > span": {
      fontSize: "0.875rem",
      color: "#505050",
    },
  },
  notchedOutline: {
    border: "0",
  },
  inputTextField: {
    fontSize: "14px",
    border: "0",
    height: 40,
    color: "#505050",
    backgroundColor: "#F2F2F2",
    margin: "0 0 0 0",
    borderRadius: "8px",
    fontFamily: "Poppins",
  },
  telefoneInputContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "4px !important",
  },
  telefoneInput: {
    height: "40px !important",
    borderRadius: "8px !important",
    backgroundColor: "#F2F2F2 !important",
    padding: "6px 0px 6px 48px !important",
    width: "100% !important",
    border: "none !important",
  },
  flagButton: {
    border: "none !important",
    borderRadius: "100px !important",
    "$:hover": {
      backgroundColor: "#F2F2F2",
    },
  },
  inputInfosTelefone: {
    color: "#505050",
    width: "100%",
  },
  observacao: {
    backgroundColor: "#F2F2F2",
    width: "98% !important",
    borderRadius: "5px",
    padding: "0px 5px",
    border: 0,
    fontSize: "14px",
    color: "#868686",
    "&>div": {
      "&>textarea": {
        height: "100%",
        borderRadius: 0,
      },
    },
  },
  contentButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: "8px",
  },
};

export default styles;