import React, { useState, useEffect } from 'react';
import { inject } from 'mobx-react';
import Moment from 'moment';
import { DialogContent, withStyles } from '@material-ui/core';

import TabelaAgendamentosDisponiveis from './TabelaAgendamentosDisponiveis';
import Header from './Header';

import Dialog from '../../../Dialog/Dialog';
import ButtonPrimary from '../../../Button/ButtonPrimary';
import { getUnidadeLogado } from '../../../../services/UsuarioService'

const TransferirAgendamentos = (props) => {
  const {
    open,
    classes,
    profissionalSaudeStore,
    atendimentoStore,
    onConfirm,
    onCancel
  } = props;

  const [filtro, setFiltro] = useState({
    profissionalSaudeId: null,
    dataInicio: Moment(),
    dataFim: null,
    periodos: { label: 'Todos', value: { horaInicio: '00:00', horaFim: '23:59' } },
  })
  const [profissionaisSaude, setProfissionaisSaude] = useState([])
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([])
  const [horarioSelecionado, setHorarioSelecionado] = useState()
  const [showExibirMaisHorarios, setShowExibirMaisHorarios] = useState(false)
  const [horariosFilter, setHorariosFilter] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadProfissionaisSaude();
  }, []);

  const loadProfissionaisSaude = async () => {
    const unidadeAtual = await getUnidadeLogado();
    const profissionaisSaude = await profissionalSaudeStore.findByUnidadeComAgenda(unidadeAtual?.id);
    setProfissionaisSaude(profissionaisSaude)
  };

  const handleChangeInput = (value, field) => {
    setFiltro({ ...filtro, [field]: value })
  };

  const handleCheckbox = (item, index) => {
    setHorarioSelecionado({ id: index, ...item });
  }

  const searchHorariosDisponiveis = () => {
    handleClickSearchHorariosDisponiveis();
  };

  const handleClickSearchHorariosDisponiveis = async () => {
    const { dataInicio, dataFim } = filtro;
    try {
      setLoading(true);
      if (dataInicio && dataFim && dataInicio > dataFim) {
        return
      }
      atendimentoStore.horariosDisponiveis = { last: false, content: [], pageable: { pageNumber: 0 } }
      await findAllHorariosDisponiveis();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const findAllHorariosDisponiveis = async (pageNumber = 0) => {
    atendimentoStore.horariosDisponiveis.pageNumber = pageNumber;
    atendimentoStore.horariosDisponiveis.filterVariables = filtro;
    const variables = {
      ...filtro,
      pageNumber
    }
    const horarios = await atendimentoStore.findAllHorariosLivresUnidade(variables);
    setHorariosDisponiveis(horarios.content);
    setShowExibirMaisHorarios(!horarios.last);
    setHorariosFilter(horarios);
  };

  const onExibirMaisResultados = () => {
    findAllHorariosDisponiveis(horariosFilter.pageNumber + 1);
  }

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper
      }}
      maxWidth="md"
      fullWidth
    >
      <Header
        className={classes.shadow}
        filtro={filtro}
        handleChangeInput={handleChangeInput}
        searchHorariosDisponiveis={searchHorariosDisponiveis}
        profissionaisSaude={profissionaisSaude}
        loading={loading}
      />

      <DialogContent className={classes.dialogContent}>
        <TabelaAgendamentosDisponiveis
          horariosDisponiveis={horariosDisponiveis}
          handleCheckbox={handleCheckbox}
          horarioSelecionado={horarioSelecionado}
          onExibirMaisResultados={onExibirMaisResultados}
          showExibirMaisHorarios={showExibirMaisHorarios}
        />
      </DialogContent>
      <div className={classes.dialogFooter}>
        <ButtonPrimary
          className={classes.cancelButton}
          onClick={onCancel}
        >
          Cancelar
        </ButtonPrimary>
        <ButtonPrimary
          className={classes.confirmButton}
          onClick={() => onConfirm(horarioSelecionado)}
        >
          Confirmar
        </ButtonPrimary>

      </div>
    </Dialog>
  )
};

const style = {
  paper: {
    maxWidth: '836px',
    height: 628,
  },

  dialogContent: {
    background: '#F2F2F4',
  },
  confirmButton: {
    borderRadius: 85,
    width: '120px',
    height: '33px',
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    border: '2px solid rgba(255, 255, 255, 0.2)',
    "& > span": {
      fontSize: "14px"
    },

  },
  cancelButton: {
    borderRadius: 85,
    width: '120px',
    height: '33px',
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    border: '2px solid rgba(255, 255, 255, 0.2)',
    background: '#FB7676',
    "& > span": {
      fontSize: "14px"
    },
    '&:hover': {
      background: '#C85E5E',
    }
  },
  dialogFooter: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    padding: '21px',
    backgroundColor: '#F2F2F4'
  },
  shadow: {
    boxShadow: '0px 1px 2px rgb(0 0 0 / 10%), 0px 8px 16px rgb(0 0 0 / 20%)'
  }
};

export default withStyles(style)(inject("profissionalSaudeStore", "atendimentoStore")(TransferirAgendamentos));