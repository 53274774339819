import { inject } from "mobx-react";
import classNames from "classnames";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import styles from "./OdontogramaStyle";
import ModalObservacao from "./Modal/ModalObservacao";
import ModalHistorico from "./Modal/Historico/ModalHistorico";
import HeaderOdontograma from "./Components/HeaderOdontograma";
import Notification from "../../../../components/Notification";
import ModalProcedimento from "./Modal/Procedimento/ModalProcedimento";
import ModalConfiguracao from "./Modal/Configuracao/ModalConfiguracao";
import ContentOdontograma from "./ContentOdontograma/ContentOdontograma";
import ListaProcedimentos from "./ListaProcedimentos/ListaProcedimentos";
import ModalConfirmacao from "../../../../components/Modal/ModalConfirmacao/ModalConfirmacao";

const Odontograma = observer(
  ({ classes, odontogramaStore, prontuarioStore, openDrawer, history }) => {
    const {
      modalObservacao,
      modalConfiguracao,
      modalProcedimento,
      getOdontograma,
      closeNotification,
      notification,
      showModalHistorico,
      isTelaAmpliada,
      modalConfirmacao,
      loadingOdontograma,
    } = odontogramaStore;

    const { showModalConfiguracao } = modalConfiguracao;
    const { showModalProcedimento } = modalProcedimento;
    const { showModalObservacao } = modalObservacao;
    const { open } = modalConfirmacao;

    const { isOpen, variant, message } = notification;

    const sujeitoAtencaoId = prontuarioStore.sujeitoAtencaoSelected?.id;

    useEffect(() => {
      odontogramaStore.locaisSelecionados = [];
      !loadingOdontograma && getOdontograma(sujeitoAtencaoId);
    }, []);

    return (
      <div
        className={classNames(
          classes.odontograma,
          isTelaAmpliada && classes.odontogramaTelaCheia
        )}
      >
        <HeaderOdontograma />
        <ContentOdontograma openDrawer={openDrawer} />
        <ListaProcedimentos history={history} />

        {showModalObservacao && <ModalObservacao />}
        {showModalProcedimento && <ModalProcedimento />}
        {showModalHistorico && <ModalHistorico />}
        {open && (
          <ModalConfirmacao
            {...modalConfirmacao}
            classes={{ paperAlert: classes.modalConfirmacao }}
          />
        )}
        <Notification
          close={closeNotification}
          reset={closeNotification}
          isOpen={isOpen}
          variant={variant}
          message={message}
        />
        {showModalConfiguracao && <ModalConfiguracao />}
      </div>
    );
  }
);

const OdontogramaWithStyles = withStyles(styles)(Odontograma);
export default inject(
  "odontogramaStore",
  "prontuarioStore"
)(OdontogramaWithStyles);
