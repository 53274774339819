import { inject } from "mobx-react";
import classNames from "classnames";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { posicoes } from "../../../Constants";
import DentesSwitch from "../../../Components/DentesSwitch";
import AddIcon from "../../../../../../../components/Icon/AddIcon";
import InputForm from "../../../../../../../components/Input/InputForm";
import { ReactSelect } from "../../../../../../../components/Select/SelectSearch";
import { Button } from "../../../../../../../components/ui/Buttons";

const Hiperdontia = observer(({ classes, odontogramaStore }) => {
    const { modalConfiguracao } = odontogramaStore;
    const { dentesAdicionados } = modalConfiguracao;

    const [denteCodigo, setDenteCodigo] = useState("");
    const [posicao, setPosicao] = useState(null);

    const handleChange = (field, value) => {
        field === "denteCodigo" ? setDenteCodigo(value) : setPosicao(value);
    };

    const handleClickAdicionaNovo = () => {
        const dente = {
            codigo: denteCodigo,
            posicao,
            status: "ATIVO"
        };

        odontogramaStore.modalConfiguracao.dentesAdicionados = [...dentesAdicionados, dente]
        cleanDente();
    };
    
    const cleanDente = () => {
        setDenteCodigo("");
        setPosicao(null);
    };

    return (
        <div className={classes.content}>
            <div className={classes.row}>
                <div className={classes.field}>
                    <label className={classes.title}>
                        Novo dente
                    </label>
                    <InputForm
                        alternativeInputClass={classes.inputCodigo}
                        type="number"
                        placeholder=""
                        value={denteCodigo}
                        onChange={(e) =>
                            handleChange("denteCodigo", e.target.value)
                        }
                    />
                </div>
                <div className={classNames(classes.field, classes.fieldPosicao)}>
                    <label className={classes.title}>
                        Posição(Obrigatório)
                    </label>
                    <ReactSelect
                        className={classes.posicao}
                        value={posicao}
                        onChange={(e) => handleChange("posicao", e)}
                        options={posicoes}
                        isClearable
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={classes.contentButton}>
                    <Button
                        shape='circle'
                        bgColor="#707C97"
                        onClick={handleClickAdicionaNovo}
                        disabled={!posicao || !denteCodigo}
                    >
                        <AddIcon color="#fff" />
                    </Button>
                </div>
            </div>
            <div>
                <DentesSwitch
                    dentes={dentesAdicionados}
                />
            </div>
        </div>)
});

const style = {
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
    },
    title: {
        color: '#868686',
    },
    inputCodigo: {
        fontSize: "14px",
        border: "0",
        minHeight: "6px",
        height: "18px",
        color: "#333333",
        backgroundColor: "#F2F2F2",
        margin: "0 0 0 0",
        padding: "9px 0px 5px 10px",
        borderRadius: "8px",
        width: '100%',
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    fieldPosicao: {
        flex: 2,
    },
    contentButton: {
        display: 'flex',
        alignItems: 'end',
    },
    posicao: {
        "& >div >div": {
            borderRadius: "8px",
            width: '100%'
        },
    },      
}

const HiperdontiaWithStyle = withStyles(style)(Hiperdontia);
export default inject('odontogramaStore')(HiperdontiaWithStyle);
