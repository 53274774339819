import React from 'react';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@material-ui/core/es';
import styles from '../../../../assets/jss/pages/profissionalSaudeStyle';
import {
  InputForm,
  InputPhoneForm,
  SelectForm,
} from '../../../../components/Modal/Input';
import {
  ElementsList,
  perfilViziOptions,
  supportWhatsappLink,
} from '../../constants/perfilViziConstants';
import string from '../../../../utils/string';
import InfoIcon from '../../../../components/Icon/InfoIcon';
import { buildUrlPerfilVizi } from '../../../../config/config';
import ItemGrid from '../../../../components/Modal/ItemGridCard';
import { TextFieldSearch } from '../../../../components/TextField';
import ClipboardCopy from '../../../../components/Input/ClipboardCopy';
import CartaoCreditoWarning from '../../components/CartaoCreditoWarning';
import { ReactSelectSearch } from '../../../../components/Select/SelectSearch';
import CardDadosCadastrais from '../../../../components/Cards/CardDadosCadastrais';
import { findAllConvenio } from '../../../../services/ConvenioService';
import ProfissionalSaudeConfigOptions from '../../components/ProfissionalSaudeConfigOptions';
import { InputForm as InputTextArea } from '../../../../components/Input/InputDadosCadastraisPaciente';

import { Cancel as CancelIcon } from '@material-ui/icons';
import { getWhitelabelAtualURL } from '../../../../services/WhitelabelService';

const DadosGerais = observer(
  ({
    classes,
    profissionalSaudeStore,
    perfilPublicoStore,
    handleConfirmModal,
  }) => {
    const {
      errors,
      profissionalSaude,
      languageList,
      paymentMethodsList,
    } = profissionalSaudeStore;

    const {
      perfilPublicoProfissionalErrors,
      perfilPublicoProfissional,
    } = perfilPublicoStore;

    const {
      formasPagamento,
    } = perfilPublicoProfissional || {};

    const disableDeleteCartaoCredito =
      perfilPublicoProfissional.utilizaTelemedicina &&
      formasPagamento.find((item) => item.id === 2);

    const handleSwitchOnChange = (option) => {
      if (
        option.attribute === 'permiteBuscaGoogle' &&
        perfilPublicoStore.perfilPublicoProfissional[option.attribute]
      ) {
        return handleOpenConfirmDisableGoogleSearch();
      }

      perfilPublicoStore.perfilPublicoProfissional[
        option.attribute
      ] = !perfilPublicoStore.perfilPublicoProfissional[option.attribute];

      if (
        option.attribute === 'atendePresencial' ||
        option.attribute === 'utilizaTelemedicina'
      ) {
        perfilPublicoStore.checkPerfilProfissionalIsComplete();
      }

      if (option.attribute === 'utilizaTelemedicina') {
        profissionalSaudeStore.profissionalSaude?.utilizaTelemedicina &&
          !isCartaoCreditoSelected() &&
          insereCartaoCredito();
      }
    };

    const switchIsChecked = (option) => {
      if (option.storedIn === 'profissionalSaudeStore') {
        return profissionalSaudeStore.profissionalSaude[option.attribute];
      } else {
        return perfilPublicoStore.perfilPublicoProfissional[option.attribute];
      }
    };

    const handleOpenConfirmDisableGoogleSearch = () => {
      const confirmModal = {
        open: true,
        title: 'Você tem certeza dessa ação?',
        text:
          'Ao desativar essa funcionalidade seu perfil público não será mais exibido nos resultados dos buscadores como Google e bing. Essa ação demorará para ser efetivada devido ao processo interno de cada um dos buscadores, mas será indicado para não mais indexar a página nos resultados de pesquisa.',
      };

      handleConfirmModal(confirmModal);
    };

    const onProfissionalDataChange = (value, campo) => {
      profissionalSaudeStore.profissionalSaude = {
        ...profissionalSaudeStore.profissionalSaude,
        [campo]: value,
      };
      if (campo === 'nome' || campo === 'cns' || campo === 'numeroConselho') {
        profissionalSaudeStore.errors[campo] = string.isEmpty(value);
        if (campo === 'nome') {
          perfilPublicoStore.checkPerfilProfissionalIsComplete();
        }
      }

      if (campo === 'cpf') {
        profissionalSaudeStore.errors.cpf = !string.validaCPF(value);
      }
    };

    const isCartaoCreditoSelected = () => {
      const { perfilPublicoProfissional } = perfilPublicoStore;
      const selectedPaymentMethods = perfilPublicoProfissional?.formasPagamento;
      return selectedPaymentMethods?.some?.((method) => method.id === 2);
    };

    const insereCartaoCredito = () => {
      profissionalSaudeStore.profissionalSaudePublico.informacao.formasPagamento = [
        ...profissionalSaudeStore.profissionalSaudePublico?.informacao
          .formasPagamento,
        {
          id: 2,
          nome: 'Cartão de Crédito',
        },
      ];
    };

    const loadConvenios = async (search, loadedOptions, { page }) => {
      try {
        const { content, last } = (
          await findAllConvenio(
            search,
            { sortDir: 'ASC', sortField: 'descricao' },
            true,
            page,
            20
          )
        ).data.data.findAllConvenio;
        const filteredOptions = content.filter((option) => {
          return !perfilPublicoProfissional.convenios.some(
            (convenio) => convenio.id === option.id
          );
        });
        return {
          options: filteredOptions,
          hasMore: !last,
          additional: {
            page: page + 1,
          },
        };
      } catch {
        console.error('Erro ao carregar os convênios');
      }
    };

    const handleRemoveConvenio = (id) => {
      perfilPublicoProfissional.convenios = perfilPublicoProfissional.convenios.filter(
        (convenio) => convenio.id !== id
      );
    };

    const handleChangeConvenios = (option) => {
      if (perfilPublicoProfissional?.convenios?.length > 0) {
        perfilPublicoProfissional.convenios = [
          ...perfilPublicoProfissional.convenios,
          option,
        ];
      } else {
        perfilPublicoProfissional.convenios = [option];
      }
    };

    return (
      <>
        <div className={classes.contentColumn}>
          <CardDadosCadastrais titulo={'Dados pessoais'}>
            <ItemGrid size={12} label={'Nome:'} bold>
              <InputForm
                name={'nome'}
                value={perfilPublicoProfissional.nome || ''}
                placeholder={'Nome Completo'}
                className={classNames(
                  classes.inputContainer,
                  errors.nome ? classes.inputError : null
                )}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.NOME
                  )
                }
              />
            </ItemGrid>
            <ItemGrid size={12} label={'Sobre:'} bold>
              <InputTextArea
                name={ElementsList.SOBRE}
                value={perfilPublicoProfissional.sobre || ''}
                placeholder={'sobre'}
                multiline
                rowsMax={5}
                rows={5}
                className={[classes.inputContainer, classes.textBox]}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.SOBRE
                  )
                }
                inputProps={{ maxLength: 500, borderRadius: 0 }}
              />
              <span className={classes.boxObservacoesCharCounter}>
                {`${perfilPublicoProfissional.sobre?.length || 0}/500`}
              </span>
            </ItemGrid>
            <ItemGrid size={12} label={'Telefone principal:'} bold>
              <InputPhoneForm
                name={ElementsList.TELEFONE_PRINCIPAL}
                value={perfilPublicoProfissional.telefonePrincipal || ''}
                placeholder={'Telefone principal'}
                error={errors.telefonePrincipal}
                className={classNames(
                  classes.inputContainer,
                  errors.telefonePrincipal ? classes.inputError : null
                )}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.TELEFONE_PRINCIPAL
                  )
                }
              />
            </ItemGrid>
            <ItemGrid size={12} label={'Telefone Secundário:'} bold>
              <InputPhoneForm
                name={ElementsList.TELEFONE_SECUNDARIO}
                value={perfilPublicoProfissional.telefoneSecundario || ''}
                placeholder={'Telefone secundario'}
                className={classNames(
                  classes.inputContainer,
                  errors.telefoneSecundario ? classes.inputError : null
                )}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.TELEFONE_SECUNDARIO
                  )
                }
              />
            </ItemGrid>
            <ItemGrid size={12} label={'E-mail: '} bold>
              <InputForm
                name={'E-mail'}
                value={perfilPublicoProfissional.email || ''}
                placeholder={'E-mail'}
                className={classNames(
                  classes.inputContainer,
                  errors.email ? classes.inputError : null
                )}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.EMAIL
                  )
                }
              />
            </ItemGrid>
          </CardDadosCadastrais>
          <CardDadosCadastrais titulo={'Dados profissionais: '}>
            {profissionalSaude.possuiAgenda && (
              <div
                className={classes.conselhoRegionalUF}
                style={{ columnGap: '8px' }}
              >
                <ItemGrid size={12} label={'Número do Conselho: '} bold>
                  <InputForm
                    name={'numeroConselho'}
                    value={perfilPublicoProfissional.numeroConselho || ''}
                    placeholder={'Numero conselho'}
                    error={errors.numeroConselho}
                    className={classNames(
                      classes.inputContainer,
                      errors.numeroConselho ? classes.inputError : null
                    )}
                    onChange={(e) =>
                      perfilPublicoStore.onFieldChange(
                        e.target.value,
                        ElementsList.NUMERO_CONSELHO
                      )
                    }
                  />
                </ItemGrid>
                <ItemGrid size={12} label={'UF:'} bold>
                  <SelectForm
                    labelOption={'UF'}
                    value={profissionalSaude.ufConselho}
                    elements={profissionalSaudeStore.estadosList}
                    className={[
                      classes.inputContainer,
                      classes.dadosCastraisContainer,
                    ]}
                    onChange={(e) =>
                      onProfissionalDataChange(e.target.value, 'ufConselho')
                    }
                  />
                </ItemGrid>
              </div>
            )}
            <ItemGrid size={12} label={'Formação:'} bold>
              <InputTextArea
                name={ElementsList.FORMACAO}
                value={perfilPublicoProfissional.formacao || ''}
                placeholder={'Formação'}
                multiline
                rowsMax={5}
                rows={5}
                className={[classes.inputContainer, classes.textBox]}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.FORMACAO
                  )
                }
                inputProps={{ maxLength: 500 }}
              />
              <span className={classes.boxObservacoesCharCounter}>
                {`${perfilPublicoProfissional?.formacao?.length || 0}/500`}
              </span>
            </ItemGrid>
            <ItemGrid size={12} label={'Experiência:'} bold>
              <InputTextArea
                name={ElementsList.EXPERIENCIA}
                value={perfilPublicoProfissional.experiencia || ''}
                placeholder={'Experiência em'}
                multiline
                rowsMax={5}
                rows={5}
                className={[classes.inputContainer, classes.textBox]}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.EXPERIENCIA
                  )
                }
                inputProps={{ maxLength: 250 }}
              />
              <span className={classes.boxObservacoesCharCounter}>
                {`${perfilPublicoProfissional?.experiencia?.length || 0}/250`}
              </span>
            </ItemGrid>
          </CardDadosCadastrais>
        </div>
        <div className={classes.contentColumn}>
          <CardDadosCadastrais
            titulo={'Link para perfil público do profissional'}
          >
            <div className={classes.perfilLinkInfoText}>
              <span>Link de acesso ao seu perfil público</span>
              <InfoIcon size={16} color='#868686' />
            </div>
            <div>
              <ClipboardCopy
                text={buildUrlPerfilVizi(
                  perfilPublicoStore.perfilPublicoProfissional
                )}
                isShortUrl
                baseUrl={getWhitelabelAtualURL()}
                disabled={!perfilPublicoStore.perfilPublicoProfissional?.id}
                emptyFieldMessage='Esse perfil não possui um link'
              />
            </div>
            <span className={classes.viziWarningText}>
              Para maiores informações sobre esse link, entre em contato com o
              suporte do AppHealth,
              <a
                className={classes.viziWarningText}
                href={supportWhatsappLink}
                target='_blank'
                rel='noreferrer'
              >
                clicando aqui
              </a>
              .
            </span>
          </CardDadosCadastrais>
          <CardDadosCadastrais titulo='Ajuste as funcionalidades'>
            <ProfissionalSaudeConfigOptions
              options={perfilViziOptions}
              handleSwitchChange={handleSwitchOnChange}
              isChecked={switchIsChecked}
            />
          </CardDadosCadastrais>
          <CardDadosCadastrais titulo={'Convênios'}>
            <div>
              <TextFieldSearch
                classNotched={classes.notchedOutline}
                classInput={classes.inputContainer}
                classIcons={classes.classIcons}
                placeholder={'Selecione o procedimento'}
                onChange={(option) => handleChangeConvenios(option)}
                value={null}
                menuPlacement={'auto'}
                key={perfilPublicoProfissional?.convenios?.length}
                getOptionLabel={(option) => option.descricao}
                getOptionValue={(option) => option.id}
                withPaginate
                loadOptions={loadConvenios}
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
              />
              <div className={classes.listaConveniosSelecionados}>
                {perfilPublicoProfissional?.convenios?.length > 0 &&
                  perfilPublicoProfissional.convenios.map((item, index) => (
                    <div className={classes.convenioOption} key={index}>
                      {item.descricao}
                      <CancelIcon
                        onClick={() => handleRemoveConvenio(item?.id)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </CardDadosCadastrais>
          <CardDadosCadastrais titulo={'Idiomas que fala: '}>
            <div>
              <ReactSelectSearch
                isMulti
                className={[classes.inputContainer, classes.withMarginBottom]}
                name={ElementsList.IDIOMAS}
                placeholder='Selecione os idiomas'
                elements={languageList}
                value={perfilPublicoProfissional[ElementsList.IDIOMAS]}
                onChange={(value) =>
                  perfilPublicoStore.onFieldChange(value, ElementsList.IDIOMAS)
                }
              />
            </div>
          </CardDadosCadastrais>
          <CardDadosCadastrais titulo={'Meios de pagamento disponíveis: '}>
            <div>
              <ReactSelectSearch
                isMulti
                className={classNames(
                  classes.inputContainer,
                  classes.withMarginBottom,
                  perfilPublicoProfissionalErrors.formasPagamento.error &&
                    classes.styleError
                )}
                name={ElementsList.FORMAS_PAGAMENTO}
                placeholder='Selecione um método de pagamento'
                elements={paymentMethodsList}
                value={perfilPublicoProfissional[ElementsList.FORMAS_PAGAMENTO]}
                optionLabel='nome'
                disableDeleteOption={disableDeleteCartaoCredito}
                onChange={(value) => {
                  perfilPublicoStore.onFieldChange(
                    value,
                    ElementsList.FORMAS_PAGAMENTO
                  );
                }}
              />
              {isCartaoCreditoSelected() && <CartaoCreditoWarning tax={6} />}
            </div>
          </CardDadosCadastrais>
        </div>
      </>
    );
  }
);

const DadosGeraisWithStyles = withStyles(styles)(DadosGerais);
export default inject(
  'profissionalSaudeStore',
  'perfilPublicoStore'
)(DadosGeraisWithStyles);
