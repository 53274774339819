export const entradaProntuarioTypesEnum = {
  RECEITA: 'RECEITA',
  DOCUMENTO: 'DOCUMENTO',
  GUIA_SERVICO: 'GUIA_SERVICO',
  GUIA_INTERNACAO: 'GUIA_INTERNACAO',
  TEXTO: 'TEXTO',
  PROCEDIMENTO_REALIZADO: 'PROCEDIMENTO_REALIZADO',
  ANAMNESE: 'ANAMNESE',
  AVALIACAO_ANTROPOMETRICA: 'AVALIACAO_ANTROPOMETRICA',
  AFERICAO_VITAL: 'AFERICAO_VITAL',
  IMAGEM: 'IMAGEM'
};

export const entradaProntuarioTypes = Object.values(entradaProntuarioTypesEnum);
