import React from 'react'
import { Switch, withStyles } from '@material-ui/core'

function Status({ checked, onChange, disabled, classes }) {
  return (
    <Switch
      checked={checked}
      color='primary'
      size='medium'
      onChange={onChange}
      classes={{
        root: classes.switchRoot,
        icon: classes.switchIcon,
        iconChecked: classes.switchChecked,
        bar: classes.switchBar,
      }}
      disabled={disabled}
    />
  )
}

const styles = {
  switchRoot: {
    marginLeft: '-10px',
  },

  switchIcon: {
    width: '24px',
    height: '24px',
    boxShadow: 'none',
    background: '#fff',
    border: '2px solid rgba(134, 134, 135, 1)',
  },
  switchChecked: {
    width: '24px',
    height: '24px',
    boxShadow: 'none',
    background: '#fff',
    border: '2px solid rgba(33, 154, 151, 1)',
  },
  switchBar: {
    width: '36px',
    height: '20px',
    borderRadius: '36px',
    backgroundColor: '#868687',
    opacity: '1 !important',
    marginTop: '-10px',
    marginLeft: '-18px',
  },
}

export default withStyles(styles)(Status)
