import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PinIcon = props => {
  return (
    <svg 
      width="18" 
      height="18" 
      viewBox="0 0 18 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M11.3163 2.75074C10.5154 1.94696 9.16534 2.14683 8.63168 3.14819L6.35918 7.41237L3.19713 8.46639C3.01219 8.52804 2.87235 8.68105 2.82756 8.87079C2.78277 9.06052 2.83941 9.25992 2.97726 9.39777L5.39198 11.8125L2.41467 14.7898L2.25 15.75L3.21016 15.5853L6.18747 12.608L8.60226 15.0228C8.74011 15.1606 8.93951 15.2173 9.12925 15.1725C9.31898 15.1277 9.472 14.9878 9.53365 14.8029L10.5879 11.6403L14.8384 9.36476C15.8354 8.83105 16.0356 7.48699 15.2374 6.68594L11.3163 2.75074Z" fill="currentColor"/>
    </svg>
  );
};

export default PinIcon
