import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DuplicaIcon = (props) => {
  return (
    <svg
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M3.74197 3.90084C3.74197 2.17292 5.14273 0.772156 6.87065 0.772156H16.2567C17.9846 0.772156 19.3854 2.17292 19.3854 3.90084V19.5443C19.3854 21.2722 17.9846 22.673 16.2567 22.673H6.87065C5.14273 22.673 3.74197 21.2722 3.74197 19.5443V3.90084ZM0.613281 7.02955C0.613281 5.87149 1.24246 4.86039 2.17762 4.31942V20.3265C2.17762 22.4864 3.92857 24.2373 6.08848 24.2373H15.8381C15.2972 25.1725 14.2861 25.8017 13.128 25.8017H6.08848C3.06461 25.8017 0.613281 23.3503 0.613281 20.3265V7.02955Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DuplicaIcon;
