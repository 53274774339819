import React from "react";
import {
  FileCopy as FileCopyIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@material-ui/icons";
import {
  Fade,
  Popper,
  MenuItem,
  ClickAwayListener,
  Paper,
  withStyles,
} from "@material-ui/core";
import Colors from "../../../../template/Colors";

const OpcaoAcaoBotaoMoreAnamnese = (props) => {
  const {
    classes,
    openMore,
    anchorEl,
    placement,
    handleCloseMore,
    handleDuplicarPergunta,
    handleExcluirPergunta,
  } = props;

  const popperModifiers = {
    name: "preventOverflow",
    enabled: true,
    options: {
      altAxis: false,
      altBoundary: false,
      tether: false,
      rootBoundary: "viewport",
      padding: 8,
    },
  };

  return (
    <Popper
      open={openMore}
      anchorEl={anchorEl}
      placement={placement}
      transition
      disablePortal={true}
      modifiers={popperModifiers}
      className={classes.fadeBotaoMore}
    >
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          timeout={350}
          className={classes.fadeBotaoMore}
        >
          <ClickAwayListener onClickAway={handleCloseMore}>
            <Paper>
              <MenuItem
                className={classes.menuMore}
                onClick={() => handleDuplicarPergunta()}
              >
                <FileCopyIcon className={classes.iconMenuMore} />
                Duplicar
              </MenuItem>
              <MenuItem
                className={classes.menuMore}
                onClick={handleExcluirPergunta}
              >
                <DeleteOutlineIcon className={classes.iconMenuMore} />
                Excluir
              </MenuItem>
            </Paper>
          </ClickAwayListener>
        </Fade>
      )}
    </Popper>
  );
};

const style = {
  fadeBotaoMore: {
    right: 15,
    width: "max-content",
  },
  menuMore: {
    color: Colors.commons.gray9,
    fontSize: 16,
    width: 140,
  },
  iconMenuMore: {
    marginRight: 10,
  },
};

export default withStyles(style)(OpcaoAcaoBotaoMoreAnamnese);
