import { inject } from "mobx-react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Checkbox, withStyles } from "@material-ui/core";
import styles from "./InformacoesStyles";
import FieldTitle from "../../../Components/FieldTitle";
import RowProcedimentos from "../Components/RowProcedimentos";
import Scroll from "../../../../../../../components/InfiniteScroll/Scroll";
import ButtonLong from "../../../../../../../components/Button/ButtonLong";
import { ReactSelectSearch } from "../../../../../../../components/Select/SelectSearch";
import InputSearchRetangular from "../../../../../../../components/Input/InputSearchRetangular";

const pageableDTODefault = {
  pageSize: 30,
  pageNumber: 0,
  sortDir: "ASC",
  sortField: "nome",
};

const Informacoes = observer(({ classes, odontogramaStore }) => {
  const {
    odontograma,
    modalProcedimento,
    locaisSelecionados,
  } = odontogramaStore;

  const {
    localOdontograma,
    procedimentos,
    last,
    procedimentosSelecionados,
  } = modalProcedimento;

  const [pageableDTO, setPageableDTO] = useState(pageableDTODefault);
  const [isChecked, setIsChecked] = useState(false);
  const [searchProcedimento, setSearchProcedimento] = useState("");
  const [locais, setLocais] = useState([]);
  const [loading, setLoading] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [localFilter, setLocalFilter] = useState(0);
  const [checkboxOptionsChecked, setcheckboxOptionsChecked] = useState([]);

  useEffect(() => {
    const locaisSelecionado = locaisSelecionados.map(
      (item) => item.local || item.localOdontograma?.id
    );
    setLocais(locaisSelecionados);
    setLocalFilter(locaisSelecionado[0]);
    setIsChecked(locaisSelecionado.length > 0);
    loadProcedimentos(true);
  }, []);

  useEffect(() => {
    locais.length === 1 && loadProcedimentos(true);
    isChecked && loadLocais();
  }, [localFilter]);

  useEffect(() => {
    if(isChecked) {
      loadLocais();
    }
  }, [isChecked]);

  const loadLocais = async () => {
    try {
      odontogramaStore.loadLocalOdontograma({
        odontogramaId: odontograma.id,
        localOdontograma: localFilter,
      });
    } catch {
      odontogramaStore.openNotification("Erro ao carregar os locais", "error");
    }
  };

  const loadProcedimentos = async (clear = false) => {
    try {
      setLoading(true);

      let nextPage = pageableDTO.pageNumber + 1;
      let pageNumber = pageableDTO.pageNumber;

      if (clear) {
        nextPage = 1;
        pageNumber = 0;
      }

      await odontogramaStore.loadProcedimentoOdontologico({
        nome: searchProcedimento,
        ...( !!localFilter && {localOdontograma: {id: localFilter}}),
        pageableDTO: {
          ...pageableDTO,
          pageNumber,
        },
      }, clear);

      setPageableDTO({
        ...pageableDTO,
        pageNumber: nextPage,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const onChangeSearchProcedimento = (value) => {
    setSearchProcedimento(value);
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter" && searchProcedimento.length >= 3) {
      handleClickSearch();
    }
  };

  const handleClickSearch = () => {
    loadProcedimentos(true);
  };

  const handleClickClear = () => {
    setSearchProcedimento("");
    loadProcedimentos(true);
  };

  const handleLocal = (value) => {
    setLocais(value);
    setLocalFilter(value.length > 0 ? [value[0]?.local] : []);
  };

  const possuiLocaisProcedimentos = () => {
    let isLocais = false;
    procedimentosSelecionados.forEach((element) => {
      const localChecked = element.localOdontograma.some(
        (item) => item.checked
      );
      isLocais = localChecked;
    });

    return isLocais;
  };

  const contemErro = () => {
    const possuiProcedimentos = procedimentosSelecionados.length > 0;

    const possuiLocais = isChecked
      ? locais.length > 0
      : possuiProcedimentos && possuiLocaisProcedimentos();

    return !possuiLocais || !possuiProcedimentos;
  };

  const handleAdicionarProcedimento = async () => {
    try {
      setLoadingSave(true);

      if (contemErro()) {
        odontogramaStore.openNotification(
          "Favor selecionar o local e o procedimento",
          "error"
        );
        return;
      }

      await odontogramaStore.adicionaProcedimento({
        isChecked,
        locais,
        procedimentos: procedimentosSelecionados,
      });

      odontogramaStore.changeCloseModalProcedimento();
      odontogramaStore.openNotification(
        "Procedimento adicionado com sucesso",
        "success"
      );
    } catch (e) {
      odontogramaStore.openNotification(
        "Erro ao adicionar o procedimento",
        "error"
      );
    } finally {
      setLoadingSave(false);
    }
  };

  const handleCheckProcedimento = (procedimento, posicao) => {
    odontogramaStore.modalProcedimento.procedimentosSelecionados = procedimento.checked
      ? removerProcedimento(procedimento)
      : [
          ...procedimentosSelecionados,
          {
            ...procedimento,
            checked: !procedimento.checked,
          },
        ];
    const localOdontograma = odontogramaStore.modalProcedimento.procedimentos[0].localOdontograma.map(
      (item) => item.id
    );

    odontogramaStore.modalProcedimento.procedimentos[posicao] = {
      ...procedimento,
      checked: !procedimento.checked,
    };

    !isChecked && localFilter > 0 && setLocalFilter(localOdontograma[0]);
  };

  const removerProcedimento = (procedimento) => {
    const listaSemProcedimentoSelecionado = procedimentosSelecionados.filter(
      (item) => item.id !== procedimento.id
    );
    return listaSemProcedimentoSelecionado;
  };

  const handleCheckFace = (posicao, tipoFace, posicaoFace) => {
    odontogramaStore.modalProcedimento.procedimentos[posicao].denteTipoFace[
      posicaoFace
    ] = {
      ...tipoFace,
      checked: !tipoFace.checked,
    };
  };

  const changeCheckboxLocal = (locaisSelect, posicaoProcedimento) => {
    setcheckboxOptionsChecked(locaisSelect);
    const locais =  odontogramaStore.modalProcedimento.procedimentos[posicaoProcedimento].localOdontograma;
    odontogramaStore.modalProcedimento.procedimentos[posicaoProcedimento].localOdontograma=locais.map(item => {
      return {
        ...item,
        checked: locaisSelect.some(local => local.descricao === item.descricao)
      }
    });

    odontogramaStore.modalProcedimento.procedimentosSelecionados = procedimentos.filter(item => item.checked);
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <FieldTitle title="Pesquise por procedimentos" />
        <InputSearchRetangular
          classInputRoot={classes.input}
          placeholder="Pesquisa por procedimento"
          value={searchProcedimento}
          onChange={(e) => onChangeSearchProcedimento(e.target.value)}
          onKeyPress={onKeypressSearch}
          onClickButtonSearch={handleClickSearch}
          handleClickClear={handleClickClear}
          className={classes.inputSearchProcedimento}
        />
        <div
          className={classNames(
            classes.checkboxLabel,
            !isChecked && classes.hiddenLocal
          )}
        >
          <Checkbox
            color="primary"
            onChange={handleChangeCheckbox}
            checked={isChecked}
          />
          <label className={classes.labelCheck}>
            Usar o mesmo procedimento em todos os dentes/faces Selecionados
          </label>
        </div>
        {isChecked && (
          <div className={classes.contentLocal}>
            <FieldTitle title="Selecione o dente ou local" />
            <ReactSelectSearch
              placeholder="Selecione aqui"
              isMulti
              elements={localOdontograma}
              value={locais}
              onChange={handleLocal}
              className={classes.selectLocal}
              isValueComplete
              optionLabel={"codigo"}
              classes={{
                chip: classes.listaLocalSelecionado,
                lista: classes.listaLocal,
              }}
            />
          </div>
        )}
      </div>
      <div
        className={classNames(
          classes.contentProcedimentos,
          !isChecked && classes.contentProcedimentosHiddenLocal
        )}
      >
        <div className={!isChecked && classes.contentTitle}>
          <FieldTitle title="Selecione o procedimento" fontSize={10} />
          {!isChecked && <FieldTitle title="Local" fontSize={10} />}
        </div>
        <Scroll
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
          className={classes.scroll}
          loadMore={() => {
            !loading && loadProcedimentos();
          }}
          isLoading={loading}
        >
          {procedimentos.length > 0 &&
            procedimentos.map((procedimento, index) => {
              return (
                <RowProcedimentos
                  procedimento={procedimento}
                  handleCheckProcedimento={handleCheckProcedimento}
                  handleCheckFace={handleCheckFace}
                  posicao={index}
                  locais={locais}
                  showLocal={!isChecked}
                  changeCheckboxLocal={(value) =>
                    changeCheckboxLocal(value, index)
                  }
                  checkboxOptionsChecked={checkboxOptionsChecked}
                />
              );
            })}
        </Scroll>
      </div>
      <div className={classes.contentButtonAdicionar}>
        <ButtonLong
          colorCustom="green"
          onClick={handleAdicionarProcedimento}
          noShadow
          disabled={loadingSave}
        >
          Adicionar
        </ButtonLong>
      </div>
    </div>
  );
});

const InformacoesWithStyles = withStyles(styles)(Informacoes);
export default inject("odontogramaStore")(InformacoesWithStyles);
