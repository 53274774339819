import React, { useEffect, useState } from "react";
import { Paper, Popper, withStyles } from "@material-ui/core";
import classNames from "classnames";

const AgendamentosAgrupadosPopper = props => {
  const {
    classes,
    dados,
    open,
    anchorEl,
    handleSelectAgendamento,
    posicao,
  } = props;

  const [quantidade, setQuantidade] = useState(0);

  const popperStyle = {
    left: posicao.pageX,
    top: posicao.pageY+15,
    position: 'fixed',
  }

  useEffect(() => {
    dados.agendamentos && setQuantidade(dados.agendamentos?.length)

  }, [dados])

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal={true}
      style={popperStyle}
    >
      <Paper className={classes.content}>
          <div className={classes.title}>
            {`AGENDAMENTOS (${quantidade})`}
          </div>
          <div>
            {
              dados.agendamentos && dados.agendamentos?.map(item => {
                return(<div key={item.id} className={classes.contentList} onClick={() => handleSelectAgendamento(item)}>
                  <div className={classNames("status", item.status)}/> 
                  <div> {item.title} </div>
                  </div>
                )
              })
            }
          </div>
      </Paper>
   </Popper>
  );
};

const styles = {
  content: {
    padding: '16px 12px 20px',
    color: '#505050',

    "& .status": {
      width: 8,
      height: 8,
      borderRadius: 100,
      border: '1px solid rgba(0, 0, 0, 0.1)',
      marginRight: 10,
    },
    "& .CONFIRMADO": {
      backgroundColor: "#D4EEEE"
    },
    "& .ATENDIDO": {
      backgroundColor: "#EAE3FE"
    },
    "& .AGENDADO": {
      backgroundColor: "#E3F4FE"
    },
    "& .AGUARDANDO": {
      backgroundColor: "#FEF2E3"
    },
    "& .ATENDENDO": {
      backgroundColor: "#E7E7E7"
    },
    "& .CANCELADO": {
      backgroundColor: "#F8D0D0"
    },
    "& .FALTOU": {
      backgroundColor: "#FEF0F7"
    },
    "& .MULTI": {
      backgroundColor: "#FBFBFB"
    },
  },
  title: {
    fontWeight: 600,
    marginBottom: 12,
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',

    "&:hover": {
      cursor: 'pointer',
      color: '#000'
    }
  }
}

export default withStyles(styles)(AgendamentosAgrupadosPopper);