import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = ({
  labelArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    height: '24px',
    borderRadius: '4px',
    padding: '4px'
  },
  labelText: {
    color: '#F9F9F9',
    fontSize: '12px !important'
  }
});

const AbbreviatedLabel = ({color, classes, text}) => {
  return (
    <div className={classes.labelArea} style={{ backgroundColor: color }}>
      <span className={classes.labelText} >{text}</span>
    </div>
  )
};

export default withStyles(styles)(AbbreviatedLabel);
