import {primaryColorDark} from "../../assets/jss/appHealth.style";

const styles = () => ({
    root: {
        display: 'flex',
        height: '64px',
        backgroundColor: primaryColorDark,
        alignSelf: 'flex-end'
    },
    footerGridDefault: {
        gridArea: 'footer',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '270px 1fr'
    },
    footerUnidade:{
        justifyContent: 'center',
        alignSelf: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '8px',
    },
    notificationContainer: {
        display: 'flex',
        height: '100%',
        justifySelf: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        background: 'rgba(49,127,168, 0.2)',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    notificationMessage: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#E3F4FE',
        color: '#2E6483',
        height: '100%',
        justifyContent: 'center',
        padding: '8px 16px 8px 16px',
        '& span': {
            fontSize: '12px'
        }
    },
    textHigilight: {
        fontWeight: 'bold'
    },
    iconContainer: {
        display: 'flex',
        height: '64px',
        width: '64px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#77CAF9",
        '& svg': {
            height: '40px',
            width: '40px',
            fontSize: '26.65px',
            color: "#FFFFFF"
        }
    },
});

export default styles;