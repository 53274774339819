import React from 'react';
import classNames from 'classnames';
import {withStyles} from "@material-ui/core/styles/index";
import {CircularProgress, ClickAwayListener, Tooltip} from '@material-ui/core';
import {inject, observer} from 'mobx-react';
import Moment from 'moment';
import string from '../../../utils/string';
import { getStatusAtendimento, getStatusRowAtendimento } from '../../../utils';
import ProcedimentoIcon from '../../Icon/ProcedimentoIcon';
import CircleInfoIcon from '../../Icon/CircleInfoIcon';
import AniversarioIcon from '../../Icon/AniversarioIcon';

function convertStyle() {
    return {
        height: 44,
        minHeight: 44,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
}

const convertStyleTodosIcons = () => {
    return {
        height: 60,
        minHeight: 60,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
}

@inject('atendimentoStore', 'unidadeStore')
@observer
class EventThreeDays extends React.Component {
    state = {
        showBarraStatus: false,
        loading: false,
        isAniversarioProximo: false,
        isAniversariante: false,
        nomesProcedimentosPedido: '',
    };

    constructor(props) {
        super(props);
        this.cardRef = React.createRef();
        this.timeoutIdHideBarraStatus = null;
    }
    
    componentDidUpdate(prevProps) {
        const { event } = this.props;
        if (event !== prevProps.event) {
            event.procedimentoPedido && event.procedimentoPedido[0]?.procedimentos?.length > 0 && this.verificaProcedimentoPedido();
            event.dataNascimento ? this.verificaAniversario() : this.setState({ isAniversarioProximo: false });
        }
    };

    componentWillUnmount() {
        if(this.timeoutIdHideBarraStatus) {
            clearTimeout(this.timeoutIdHideBarraStatus);
            this.timeoutIdHideBarraStatus = null;
        }
    }

    handleContextMenu = (e) => {
        e.preventDefault();
    };

    handleMouseUp = (e) => {
        if (typeof e === 'object' && e.button === 2) {
            this.handleRemoveEvent();
        }
    };

    verificaProcedimentoPedido = () => {
        const { event } = this.props;
        const {procedimentoPedido} = event;

        let nomeProcedimento = 'Procedimentos solicitados: ';

        procedimentoPedido[0].procedimentos.forEach(element => {
            nomeProcedimento = `${nomeProcedimento} ${element.procedimento.nome},`
        });

        this.setState({
            nomesProcedimentosPedido: nomeProcedimento
        })
    };

    handleSelectEvent = () => {
        const { onSelectAgendamento, onSelectNovoAgendamento, event, selectedDate } = this.props;
        let horarioSelecionado = {
            start: Moment(
                `${selectedDate.format("YYYY-MM-DD")} ${
                    event.horaInicio
                }`,
                "YYYY-MM-DD HH:mm"
            ),
            end: Moment(
                `${selectedDate.format("YYYY-MM-DD")} ${
                    event.horaFim
                }`,
                "YYYY-MM-DD HH:mm"
            )
        }

        if (!event.status) {
            onSelectNovoAgendamento(horarioSelecionado);
        } else {
            onSelectAgendamento({
                ...horarioSelecionado,
                id: event.id,
                status: event.status
            });
        }
    };

    handleRemoveEvent = () => {
        const { onRemoveAgendamento, event } = this.props;
        if (event.status) {
            onRemoveAgendamento(event);
        }
    };

    handleClickBarraStatus = (e) => {
        e.stopPropagation();
        this.setState(state => ({ showBarraStatus: !state.showBarraStatus }));
    };

    handleMouseOverBarraStatus = () => {
        const { event } = this.props;
        if(!this.state.showBarraStatus && event.status) {
            this.setState({ showBarraStatus: true });
            return;
        }
        if(this.timeoutIdHideBarraStatus) {
            clearTimeout(this.timeoutIdHideBarraStatus);
            this.timeoutIdHideBarraStatus = null;
        }
    };

    handleMouseLeaveBarraStatus = () => {
        if(this.state.showBarraStatus && !this.timeoutIdHideBarraStatus) {
            this.timeoutIdHideBarraStatus = setTimeout(() => {
                this.setState({ showBarraStatus: false }, () => this.timeoutIdHideBarraStatus = null);
            }, 800);
        }
    };

    handleCloseBarraStatus = (e) => {
        e.stopPropagation();
        this.setState({ showBarraStatus: false });
    };

    handleClickAlteraStatus = (e, situacao) => {
        e.stopPropagation();

        const { atendimentoStore, event } = this.props;
        this.setState({ showBarraStatus: false, loading: true }, async () => {
            await atendimentoStore.updateSituacaoAgendamento({ id: event.id, situacao });
            this.setState({ loading: false });
        });
    };

    verificaAniversario = () => {
        const { event } = this.props;
        
        const {isAniversarioProximo, isAniversariante} = string.verificaAniversarioProximoADataAtual(event.dataNascimento, 5);
        this.setState({ 
            isAniversarioProximo,
            isAniversariante
        })
    };

    render() {
        const { classes, style, event, possuiTodosIcons } = this.props;
        const { showBarraStatus, loading, isAniversarioProximo, isAniversariante, nomesProcedimentosPedido } = this.state;
        const { observacao } = event;
        const backgroundColorByStatus = getStatusRowAtendimento(event.status);
        const possuiProcedimentoSolicitado = event.procedimentoPedido && event.procedimentoPedido[0]?.procedimentos?.length > 0;
        const zeroGapIcons = possuiProcedimentoSolicitado && observacao && isAniversarioProximo;

        return (
            <React.Fragment>
                <div
                    ref={this.cardRef}
                    className={`
                        ${classes.root} 
                        ${event.status === 'BLOQUEADO' ? 'evento-bloqueado' : 'evento'}
                        ${classes.dayCalendarInner}
                    `}
                    style={possuiTodosIcons ? {...convertStyleTodosIcons(style)} : {...convertStyle(style)}}
                    onClick={this.handleSelectEvent}
                    onContextMenu={event.id && this.handleContextMenu}
                    onMouseUp={event.id && ((e) => this.handleMouseUp(e))}>
                    {
                        event.status !== 'BLOQUEADO' ?    
                        <React.Fragment>
                            <div className={classNames(classes.dayCalendarColumn, 'barra-status', possuiTodosIcons && classes.dayCalendarColumnRowIcons)}
                                style={{
                                    width: '75px',
                                    minWidth: '75px',
                                    alignItems: 'center',
                                    backgroundColor: getStatusAtendimento(event.status).color
                                }}
                                onMouseOver={event.id ? this.handleMouseOverBarraStatus : null}
                                onMouseLeave={event.id ? this.handleMouseLeaveBarraStatus : null}
                            >
                                <div className={classNames(classes.dayCalendarColumnLabelHour, { status: event.status })}>
                                    {event.horaInicio}
                                </div>
                                <ClickAwayListener onClickAway={this.handleCloseBarraStatus}>
                                    <div
                                        onClick={event.id ? this.handleClickBarraStatus : null}>
                                        <div
                                            className={classNames('barra-status-inner', { show: showBarraStatus })}>
                                            {this.props.atendimentoStore.situacoesAlteracaoStatus.map(statusItem => (
                                                <Tooltip
                                                    title={statusItem.name}
                                                    key={statusItem.value}
                                                    placement="right"
                                                >
                                                    <div
                                                        className="status-button"
                                                        style={{background: getStatusAtendimento(statusItem.value).color}}
                                                        onClick={(e) => this.handleClickAlteraStatus(e, statusItem.value)}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <div
                                className={classNames(classes.dayCalendarColumn, possuiTodosIcons && classes.dayCalendarColumnRowIcons)}
                                style={{backgroundColor: backgroundColorByStatus}} >
                                <div className={classNames(classes.dayCalendarColumnLabel, possuiTodosIcons && classes.dayCalendarColumnRowIcons, classes.columnNome, {[classes.dayCalendarColumnLabelAniversario]: possuiProcedimentoSolicitado || observacao || isAniversarioProximo })}>
                                    <div className={classNames(classes.contentIcons, zeroGapIcons && classes.gapIcons)}>
                                        { possuiProcedimentoSolicitado && <Tooltip
                                            title={nomesProcedimentosPedido}
                                            placement="top"
                                        >
                                            <div
                                                className={classNames(classes.contentIcon, classes.colorPink)}
                                            >
                                                <ProcedimentoIcon color="#fff" />
                                            </div>
                                        </Tooltip>}
                                        { observacao && <Tooltip
                                            title={observacao}
                                            placement="top"
                                        >
                                            <div
                                                className={classNames(classes.contentIcon, classes.colorBlue)}
                                            >
                                                <CircleInfoIcon color="#fff"/>
                                            </div>
                                        </Tooltip>}
                                        { event.dataNascimento && isAniversarioProximo && <Tooltip
                                            title={Moment(event.dataNascimento).format("DD/MM/YYYY")}
                                            placement="top"
                                        >
                                            <div
                                                className={classNames(classes.contentIcon, isAniversariante ? classes.colorYellow : classes.colorGray)}
                                            >
                                                <AniversarioIcon color="#fff" />
                                            </div>
                                        </Tooltip>}
                                    </div>
                                    {event.sujeitoAtencaoNome || event.nome}
                                </div>
                            </div>

                        </React.Fragment> :
                        <React.Fragment>
                            <div className={classes.dayCalendarColumn} style={{width:'75px', minWidth: '75px', alignItems: 'center', backgroundColor:'#D7D7D7', ...convertStyle(style)}} >
                                <div className={classNames(classes.dayCalendarColumnLabel, 'hora-bloqueada')}  style={{marginLeft: 0, alignItems: 'center'}}>
                                    {event.horaInicio}
                                </div>
                            </div>
                            <div className={classes.dayCalendarColumn} style={{backgroundColor:'#C6C7C7', alignItems: 'center', ...convertStyle(style)}} >
                                <div className="bloqueado">
                                    {!string.isEmpty(event.nome) && (
                                        <div>
                                            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                                                Motivo do bloqueio:
                                            </span>
                                            {event.nome}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

                {(loading && event.id) && (
                    <div className={classes.loading} style={{...convertStyle(style)}}>
                        <CircularProgress color="primary" size={24} />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

const styles = theme => ({
    root: {
        position: 'absolute',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: '#efefef',
        },
        '& .strike': {
            color: theme.palette.commons.red,
        },
        '& .barra-status': {

            '& .barra-status-inner': {
                backgroundColor: '#fff',
                display: 'none',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '75px',
                boxSizing: 'border-box',
                flexWrap: 'wrap',

                '&.show': {
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width:'77px',
                    zIndex: 4,
                    height: '100%',
                },

                '& .status-button': {
                    width: 15,
                    height: 15,
                    marginLeft: 4,
                    borderRadius: '50%',

                    '&:last-child': {
                        marginBottom: 0,
                    }
                },
            },
        },
        '&.evento-bloqueado': {
            zIndex: 2,
            '& div': {
                '&.bloqueado': {
                    backgroundColor: '#FB7676',
                    height: '1px',
                    width: '95%',
                    borderRadius: '100px',
                    '& div': {
                        color: "#868686",
                        position: "relative",
                        top: "-10px",
                    }
                },
            },
            '& .hora-bloqueada': {
                color: '#BDBDBD!important',
                fontFamily: 'Nexa Bold!important',
                fontSize: 18,
                textAlign: 'center',
                '& div': {
                    fontSize: '1.1em',
                    marginTop: -2,
                    display: 'block'
                },
            },
        },
        '&.evento': {
            zIndex: 3,
        },
        '& div': {
            fontWeight: 500,
            userSelect: 'none',
        },
    },

    columnHoraInicio: {
        width: '75px',
        minWidth: '75px',
        alignItems: 'center',
        backgroundColor: '#26ACA9',
        justifyContent: 'center',
        height: '100%',
        display: 'flex',
        color: '#fff'
    },

    dayCalendarInner: {
        fontFamily: "Poppins !important",
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        overflowX: 'hidden',
    },

    dayCalendarColumnLabel: {
        color: '#868686!important',
        fontSize: '14px!important',
        marginLeft: '24px',
        fontFamily: "Poppins !important",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    dayCalendarColumnLabelHour: {
        color: '#bdbdbd',
        fontWeight: 'bold !important',
        fontSize: '14px!important',
        alignItems: 'center',
        marginLeft: '0px',
        fontFamily: "Poppins",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.status ': {
            color: '#fff',
            fontWeight: '500 !important',
        }
    },

    dayCalendarColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minHeight: '40px',
        border: '0.5px solid rgba(0, 0, 0, 0.1)',
    },

    dayCalendarColumnTipo:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '110px',
        minHeight: '40px',
        height: '100%',
    },

    dayCalendarColumnTelefone:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '200px',
        minHeight: '40px',
        height: '100%',
    },

    dayCalendarColumnProcedimentos: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minWidth: '240px',
        minHeight: '40px',
        height: '100%',
    },
    
    tooltip: {
        backgroundColor: "#FFFFFF",
        color: "#000",
        fontSize: "14px",
        boxShadow: theme.shadows[1],
    },

    button: {
        backgroundColor: theme.palette.commons.purple,
        color: '#fff',
        fontSize: 10,
        position: 'absolute',
        top: 3,
        right: 3,
        minHeight: 22,
        minWidth: 22,
        maxHeight: 22,
        maxWidth: 22,
        padding: 0,
    },

    loading: {
        zIndex: "10",
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(239, 239, 239, 0.5)"
    },

    dayCalendarColumnLabelAniversario: {
        marginLeft: '4px',
    },

    contentIcon: {
        width: '18px',
        height: '18px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    colorYellow: {
        background: '#F9BE73',
    },
    colorGray: {
        background: '#868686',
    },
    colorBlue: {
        background: '#5462E0',
    },
    colorPink: {
        background: '#FC71B4',
    },
    columnNome: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    contentIcons: {
        gap: '2px',
        display: 'flex',
        flexDirection: 'column',
    },
    gapIcons: {
        gap: '1px',
    },
    dayCalendarColumnRowIcons: {
        minHeight: '60px',
    },

});

export default withStyles(styles)(EventThreeDays);