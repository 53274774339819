import moment from "moment";

const primeiroDiaMesAtual = moment().startOf("month").format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment().endOf("month").format("YYYY-MM-DD");

export const columns = [
    {
        Header: "Nome do usuário",
        align: "left",
        getValue: (agendamento) => {
            return agendamento.profissionalSaude;
        },
        field: "profissionalSaude"
    },
    {
        Header: "Situação do agendamento",
        align: "left",
        getValue: (agendamento) => {
            return agendamento.situacao;
        },
        field: "situacao"
    },
    {
        Header: "Quantidade",
        align: "left",
        getValue: (agendamento) => {
            return agendamento.quantidade;
        },
        field: "quantidade"
    },
];
export const situacoesOptionsDefault = [
    {
        nome: "Todas as situações",
        value: "TODAS_SITUACOES",
        checked: false
    },
    {
        nome: "Agendado",
        value: "AGENDADO",
        checked: true
    },
    {
        nome: "Aguardando",
        value: "AGUARDANDO",
        checked: false
    },
    {
        nome: "Cancelado",
        value: "CANCELADO",
        checked: false
    },
    {
        nome: "Excluído",
        value: "EXCLUIDO",
        checked: false
    },
    {
        nome: "Confirmado",
        value: "CONFIRMADO",
        checked: false
    },

]

export const filtersDefault = {
    dataInicio: primeiroDiaMesAtual,
    dataFim: ultimoDiaMesAtual,
    profissional: null,
    situacoes: ["AGENDADO"],
    criacao: false

};