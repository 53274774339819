import React from 'react'
import { withStyles } from '@material-ui/core'

const NumberLoteGuia = ({ classes, numberOfLote }) => {
  return (
    <div className={classes.wrapperNumberLote}>
      LOTE N°:{'  '}
      <strong>{numberOfLote}</strong>
    </div>
  )
}

const styles = {
  wrapperNumberLote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E3F4FE',
    border: '1px solid #73C8F9',
    padding: '0.5rem',
    borderRadius: '8px',
    color: '#505050',
    '&>strong': {
      paddingLeft: '2rem',
    },
  },
}

export default withStyles(styles)(NumberLoteGuia)
