import Api from "../config/api";

export const findAllByUsuarioLogadoPublicidade = async() => {
  const response = await Api.post("", {
    query: `
    {
      findPublicidadesAtivas {
        id
        conteudoPublicitario {
          id
          descricao
          nome
          keyS3
          linkDirecionamento
          urlDownload
        }
      }
    }`,
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findPublicidadesAtivas;
};

export const updateUsuarioPublicidade = async(publicidade) => {
  const response = await Api.post("", {
    query: `
    mutation($publicidade: PublicidadeInput){
      updatePublicidade(publicidade: $publicidade) {
        id
        interagiu
        ultimaVisualizacao
        primeiraVisualizacao
      }
    }`,
    variables: { publicidade }
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.updatePublicidade;
};

export const findConteudosPublicitariosAtivosByTipo =async (tipo)=>{
  const response = await Api.post("", {
    query:`query($tipo: TipoConteudoPublicitario) {
      findConteudosPublicitariosAtivosByTipo(tipo: $tipo) {
        descricao
        id
        linkDirecionamento
        nome
        urlDownload
      }
    }`,
    variables:{tipo}
  });
  if (response?.data?.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data?.data?.findConteudosPublicitariosAtivosByTipo;
}