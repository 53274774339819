import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Colors from "../../template/Colors";
import { Button } from "../ui/Buttons";
import { Tooltip } from "@material-ui/core";

const styles = {
  content: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  heightButton: {
    height: '40px',
  }
};

const ButtonSelected = (props) => {
  const {
    classes,
    icon,
    label,
    isCircle,
    isSelected,
    ...others
  } = props;

  return (
    <Tooltip title={isCircle ? label : ""} placement="bottom">
      <Button
        shape={isCircle ? "circle" : "pill" }
        className={classes.heightButton}
        bgColor={isSelected ? Colors.commons.secondary : Colors.commons.gray7}
        {...others}
      >
        <div className={classes.content}>
          {icon}
          {isCircle ? "" : label}
        </div>
      </Button>
    </Tooltip>
  );
};

export default withStyles(styles)(ButtonSelected);
