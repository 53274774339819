import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import Colors from '../../template/Colors'
import { ReactSelect } from '../../components/Select/SelectSearch'
import classNames from 'classnames'

const InputSelectComLabel = props => {
  const { classes, label, isSelect, noMargin } = props

  return (
    <div className={classes.divLabel}>
      {label && <span className={classes.titulo}>{label}</span>}
      {isSelect ? (
        <ReactSelect {...props} />
      ) : (
        <input {...props} className={classNames(classes.input, noMargin && classes.noMargin)} />
      )}
    </div>
  )
}

const styles = {
  divLabel: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  titulo: {
    color: Colors.commons.gray7,
    fontSize: '12px !important',
    fontWeight: 400,
    marginBottom: 4,
  },
  input: {
    borderRadius: 100,
    padding: '4px 15px',
    background: Colors.commons.gray11,
    color: Colors.commons.gray9,
    fontFamily: 'Poppins',
    border: 'none',
    height: '27px',
    marginRight: '10px',
  },
  noMargin: {
    marginRight: 0,
  }
}

export default withStyles(styles)(InputSelectComLabel)
