import { action } from "mobx";
import Api from "../config/api";

export default class ProcedimentoStore {

  @action async findAllProcedimentos({nome, pageableDTO, salaId}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
      };

      const response = await Api.post("", {
        query: `
        query ($nome: String, $ativo: Boolean, $pageableDTO: PageableDTOInput, $salaId: UUID) {
          findAllProcedimentos(nome: $nome, pageableDTO: $pageableDTO, ativo: $ativo, salaId: $salaId) {
            last
            content {
              id
              nome
              ativo
            }
          }
        }
                `,
        variables: {
          nome: nome || "",
          salaId: salaId || null,
          ativo: true,
          pageableDTO: {
            ...defaultSearchDTO,
            ...pageableDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllProcedimentos;

      const customContent = content.map(procedimento => {

        return ({
          ...procedimento,
          value: procedimento.id,
          label: procedimento.nome
        })
      });

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  };

  @action async findAllTuss22ProcedimentoEventoSaude(variables) {
    try {
      const response = await Api.post("", {
        query: `
        query ($ativo: Boolean, $pageableDTO: PageableDTOInput, $search: String, $tiposProcedimentos: [TipoProcedimento]) {
          findAllTuss22ProcedimentoEventoSaude(ativo: $ativo, pageableDTO: $pageableDTO, search: $search, tiposProcedimentos: $tiposProcedimentos) {
            last
            totalElements
            numberOfElements
            content {
              id
              codigoTermo
              descricao
              codigoTermoDescricao
            }
          }
        }
        `,
        variables: variables
      });

      const { last, content } = response.data.data.findAllTuss22ProcedimentoEventoSaude;

      const customContent = content.map(item => ({
        ...item,
        value: item.codigoTermoDescricao,
        label: item.codigoTermoDescricao
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

}
