import Colors from "../../template/Colors";

const style = {
  mensagemDeListaVazia: {
    alignItems: 'center',
    color: Colors.commons.gray10,
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  }
};

export default style;
