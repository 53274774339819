export const entitiesAttributesName = {
  paciente: {
    altura: 'altura',
    ativo: 'status',
    cns: 'CNS',
    comoConheceu: 'campo como conheceu',
    documento: 'documento',
    dataNascimento: 'data de nascimento',
    fotoPerfil: 'foto de perfil',
    nomeConjuge: 'nome do conjuge',
    nomeMae: 'nome da mãe',
    nomePai: 'nome do pai',
    nome: 'nome',
    nomeSocial: 'nome social',
    observacao: 'observação',
    nomeResponsavel: 'nome do responsável',
    documentoResponsavel: 'documento do responsável',
    peso: 'peso',
    profissionalSaude: 'profissional de saúde',
    quemIndicou: 'quem realizou a indicação',
    religiao: 'religião',
    rg: 'RG',
  },
  agendamento: {
    observacao: 'observação',
    motivoCancelamento: 'motivo do cancelamento',
  }
}