import React from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from '../styles';
import CircleSuccess from '../../../../../components/Icon/CircleSuccess';

const SuccessStep = ({ classes }) => {
  return (
    <div className={classes.confirmContainer}>
      <CircleSuccess color="#36ABA8" size={50}/>
      <span className={classes.successText}>
        Transferencia realizada com sucesso!
      </span>
      <span className={classes.descriptionText}>
        Acabamos de enviar uma notificação aos seus pacientes sobre a alteração no agendamento.
      </span>
    </div>
  );
};

export default withStyles(styles)(SuccessStep);
