import React from 'react'
import { WrapperChildren } from '../styles'

export const renderChildren = (StartEnhancer, EndEnhancer, children) => {
  if (StartEnhancer || EndEnhancer) {
    const start = typeof StartEnhancer === 'function' ? <StartEnhancer /> : StartEnhancer
    const end = typeof EndEnhancer === 'function' ? <EndEnhancer /> : EndEnhancer
    return (
      <WrapperChildren>
        {start}
        {children}
        {end}
      </WrapperChildren>
    )
  }
  return children
}
