import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { withStyles } from '@material-ui/core/styles';
import {
  Fade,
  Popper,
  MenuItem,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import {
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons";
import { TypeModalEnumReceituario } from '../../../../stores/Receituario.store';
import Scroll from '../../../../components/InfiniteScroll/Scroll';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#f9f9f9",
    color: "#505050",
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
  },
  body: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: '100%',
    fontFamily: 'Poppins',
    height: '100%'
  },
  table: {
    minWidth: 700,
    maxHeight: 700,
  },
  row: {
    maxHeight: 57,
    cursor: 'pointer',
  },
  rowInativo:{
    maxHeight: 57,
    cursor: 'pointer',
    backgroundColor: '#F5F5F5',
  },
  menuMore: {
    color: '#505050',
    fontSize: 16,
    width: 140,
  },
  iconMenuMore:{
    marginRight: 10,
  },
  botaoOrganizar: {
    color: 'white',
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  colunaStatus: {
    textAlign: '-webkit-center',
  },
  paperBotaoMore: {
    borderRadius: 8,
  },
  nameAtivo: {
    color: '#505050',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '655px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px',
    },
  },
  nameInativo: {
    color: '#505050',
    opacity: 0.5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '655px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px',
    },
  },
  scrollContainerModelos: {
    width: '100%',
    flex: 1,
    height: "calc(100vh - 64px - 64px)",
    overflow: 'auto'
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    margin: 10,
  },
  rowHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  colorIconOrdenacao: {
    filter: 'brightness(0.4)',
  },

});

const CustomizedTable = (props) => {
  const { classes, receituarioStore, loadModelos, last, modelosLoading } = props;
  const [openMore, setOpenMore ] = React.useState(false);
  const [anchorEl, setAnchorEl ] = React.useState(null);
  const [placement, setPlacement ] = React.useState(null);
  const [ordenarNome, setOrdenarNome] = useState(true);
  const [ordenarStatus, setOrdenarStatus] = useState(true);
  const [statusMore, setStatusMore] = useState('');

  const handleClickMore = (newPlacement, status, idModelo) => (event) => {
    setStatusMore(status ? "Ativo" : "Inativo")
    setAnchorEl(event.currentTarget);
    setOpenMore((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);

    receituarioStore.idModeloSelecionado = idModelo;

  };

  const handleCloseMore = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setOpenMore(false);
  };

  const handleEditar = async () => {
    receituarioStore.changeFieldsStore({
      isProntuario: false,
      tipoListagemSelecionada: "sugestoes",
      sugestoes: [],
      sugestoesPagAtual: 0,
      typeModal: TypeModalEnumReceituario.EDIT_MODAL,
    });

    await receituarioStore.findAllMedicamentoQuantidadeUso({
      pageableDTO: {
          pageSize: 6,
          pageNumber: receituarioStore.sugestoesPagAtual,
          sortDir: "DESC",
          sortField: "quantidadeUso"
      }
    })
    
    const selecionado = await receituarioStore.findByIdReceitaModelo(receituarioStore.idModeloSelecionado)
    
    receituarioStore.changeFieldsStore({
      nomeModelo: selecionado.nome,
      tipo: selecionado.tipo,
      statusNovoModelo: selecionado.ativo,
      modeloSelecionado: selecionado,
      openModalModelo: true,
    });
  }

  const handleCloseModalEditarModelo = () => {
    receituarioStore.openModalModelo = false;
    receituarioStore.tipoListagemSelecionada = "sugestoes";
    receituarioStore.typeModal=TypeModalEnumReceituario.SADT_MODAL;
    receituarioStore.idModeloSelecionado = ''
    receituarioStore.tipoListagemSelecionada = 'sugestoes';
  }

  const handleDuplicar = async () => {
    const selecionado = await receituarioStore.findByIdReceitaModelo(receituarioStore.idModeloSelecionado);
    const receitaModeloItensSelecionado = selecionado.receitaModeloItens.map((item) => {
      return({
        quantidade: item.quantidade,
        remedio: item.remedio,
        receitaItemTipoUso: item.receitaItemTipoUso,
        medicamento:{
          id: item.medicamento.id
        },
        viaAdministracao: item.routeAdm,
      })
    })
    
    const selecionadoAdaptado = {
      nome: selecionado.nome,
      ativo: selecionado.ativo,
      quantidadeUso: selecionado.quantidadeUso,
      tipo: selecionado?.tipo || 'SIMPLES',
    }

    if (selecionado.tipo === 'OFTALMOLOGICA') {
      selecionadoAdaptado.oftalmologica = selecionado.oftalmologica
    } else {
        selecionadoAdaptado.receitaModeloItens = receitaModeloItensSelecionado
    }
    
    receituarioStore.criarReceitaModelo(selecionadoAdaptado);
    receituarioStore.changeFieldsStore({
      modelosConfig: [],
      pageNumberModelo: 0,
      idModeloSelecionado: '',
    });
    loadModelos();
  }

  const handleAtivar = async () => {
    await receituarioStore.ativarReceitaModelo(receituarioStore.idModeloSelecionado);
    receituarioStore.changeFieldsStore({
      modelosConfig: [],
      pageNumberModelo: 0,
    });
    
    loadModelos();
  }

  const handleInativar = async() => {
    await receituarioStore.desativarReceitaModelo(receituarioStore.idModeloSelecionado);
    receituarioStore.changeFieldsStore({
      modelosConfig: [],
      pageNumberModelo: 0,
    });
    loadModelos();

  }

  const handleClickOrdenar = (value) =>{
    if (value === "nome"){
      setOrdenarNome(!ordenarNome);
      receituarioStore.changeFieldsStore({
        sortDirModelo: ordenarNome ? 'DESC' : 'ASC',
        sortFieldModelo: value, 
        modelosConfig: [],
        pageNumberModelo: 0,
      });

      loadModelos();
    } else {
      setOrdenarStatus(!ordenarStatus);
      receituarioStore.changeFieldsStore({
        sortDirModelo: ordenarStatus ? 'DESC' : 'ASC',
        sortFieldModelo: value, 
        modelosConfig: [],
        pageNumberModelo: 0,
      });

      loadModelos();
    }
  }

  const handleClickRow = (id) => (event) =>{
    receituarioStore.changeFieldsStore({
      idModeloSelecionado: id,
    });
    handleEditar();
  }

  const loadMoreModelos = () => {
    if (modelosLoading) {
      return;
    }

    receituarioStore.changeFieldsStore({
      pageNumberModelo: receituarioStore.modelosConfig.length > 0 ? receituarioStore.pageNumberModelo + 1 : 0,
    });
    loadModelos();

  }

  return (
    <Paper className={classes.root}>
    <Scroll
          loadMore={loadMoreModelos}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainerModelos}
        >
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.rowHeader}>
            <CustomTableCell>
                NOME
                <IconButton aria-label="expand row" size="small" className={classes.botaoOrganizar} onClick={() => handleClickOrdenar("nome")}>
                {
                  ordenarNome ? 
                  <ArrowDropUpIcon className={classes.colorIconOrdenacao}/>
                  :                   
                  <ArrowDropDownIcon className={classes.colorIconOrdenacao}/>
                }
                </IconButton>
            </CustomTableCell>
            <CustomTableCell align="center">
                STATUS
                <IconButton aria-label="expand row" size="small" className={classes.botaoOrganizar} onClick={() => handleClickOrdenar("ativo")}>
                {
                  ordenarStatus ?
                  <ArrowDropUpIcon className={classes.colorIconOrdenacao}/>                 
                  : 
                  <ArrowDropDownIcon className={classes.colorIconOrdenacao}/>
                }
                </IconButton>    
            </CustomTableCell>
            <CustomTableCell align="center"/>
          </TableRow>
        </TableHead>
        <TableBody>
        
          {receituarioStore.modelosConfig.map((modeloConfig, i) => 
            <TableRow 
              className={modeloConfig.ativo ? classes.row : classes.rowInativo} 
              key={i}
            >
              <CustomTableCell 
                component="th" 
                scope="row" 
                align="left" 
                className={modeloConfig.ativo ? classes.nameAtivo : classes.nameInativo } 
                onClick={handleClickRow(modeloConfig.id)}
              > 
                {modeloConfig.nome} 
              </CustomTableCell>
              <CustomTableCell 
                align="center" 
                className={classes.colunaStatus} 
                onClick={handleClickRow(modeloConfig.id)}
              > 
                <div style={{color: '#fff', width: 100, backgroundColor: modeloConfig.ativo  ? '#26ACA9' : ' rgba(38, 172, 169, 0.5)', borderRadius: 50}}>
                  {modeloConfig.ativo ? "Ativo" : "Inativo"}
                </div>
              </CustomTableCell>
              <CustomTableCell align="center"style={{width: 100}}>
                <IconButton 
                  aria-label="expand row" 
                  size="small" 
                  onClick={handleClickMore('bottom-end', modeloConfig.ativo, modeloConfig.id) }
                >
                  <MoreHorizIcon/>
                </IconButton>
              </CustomTableCell>
            </TableRow>)
          }
          
          <Popper open={openMore} anchorEl={anchorEl} placement={placement} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.paperBotaoMore}>
                    <ClickAwayListener onClickAway={handleCloseMore}>
                      <MenuItem className={classes.menuMore} onClick={() => handleEditar()}>
                        <EditIcon className={classes.iconMenuMore}/>
                        Editar
                      </MenuItem>
                      <MenuItem className={classes.menuMore} onClick={handleDuplicar}>
                        <FileCopyIcon className={classes.iconMenuMore}/>
                        Duplicar
                      </MenuItem>
                      { statusMore === "Inativo" ?
                        <MenuItem className={classes.menuMore} onClick={handleAtivar}>
                          <VisibilityIcon className={classes.iconMenuMore}/>
                          Ativar
                        </MenuItem> 
                      :           
                      <MenuItem className={classes.menuMore} onClick={handleInativar}>
                        <VisibilityOffIcon className={classes.iconMenuMore}/>
                          Desativar
                        </MenuItem>
                      }  
                      </ClickAwayListener>          
                  </Paper>
                </Fade>
              )}
            </Popper>
        
        </TableBody>
      </Table>
        {modelosLoading && (
          <div className={classes.notFoundContainer}>
              <CircularProgress/>
          </div>
        )}
</Scroll>
    </Paper>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CustomizedTableWithStyles = withStyles(styles)(CustomizedTable);
export default inject("receituarioStore")(CustomizedTableWithStyles)
