const styles = theme => ({
  content: {
    gridArea: "content",
    display: "grid",
    gridTemplateRows: 'auto 1fr',
    position: 'relative',
    overflow: "hidden",
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
    background: '#fff',

    "& thead th": {
      top: 0,
      position: "sticky",
    },
  },


  search: {
    gridArea: "search",
    marginBottom: 20
  },


  lista: {
    gridArea: "lista",
    marginBottom: 20,
    overflow: "hidden"
  },

  headerTitleRoot: {
    maxWidth: theme.commons.menuLeft.width
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1
  },

  titleFilterContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
  },
  
  titleHeader: {
    fontSize: 25,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: 15,
  },

  tableContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",

    '& td':{
      borderSpacing: '0',
      borderCollapse: 'collapse',
      cursor: 'pointer',
      fontSize: '14px',
      height: '48px',
      fontFamily: 'Poppins',
      fontWeight: '600',
      color:' #505050',
      
    },
  },
  
  scrollContainer: {
    height: "100%",
    
  },
  
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    
    '& h3': {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      fontSize: '1rem'
    }
  }
});

export default styles;
