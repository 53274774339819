import { formatBytes } from '../../../utils/bytes';

export const tabs = [
  {
    label: 'Extrato de pagamentos',
  },
  {
    label: 'Dados de Recebimento'
  }
];

export const accountTypeTabs = [
  {
    label: 'Conta corrente'
  },
  {
    label: 'Conta poupança'
  }
]



export const documentTypeTabs = (isProfissional, dadosBraspag) => {
  const status = ['APPROVED', 'APPROVEDWITHRESTRICTION', 'UNDERANALYSIS'];
  const isApproved = status.includes(dadosBraspag?.status);

  const tabs = [
    {
      label: 'Pessoa Jurídica',
      isDisabled: isProfissional && isApproved && dadosBraspag?.documentoTipo === 'CPF',
    },
  ];

  if (isProfissional) {
    tabs.unshift({
      label: 'Pessoa Física',
      isDisabled: isApproved && dadosBraspag?.documentoTipo === 'CNPJ',
    });
  }

  return tabs;
};

export const documentsColumns = [
  {
    Header: "Nome do arquivo",
    field: "amazonS3Objeto.nome",
    props: {center: 'true'},
    getValue: (row) => {
      return row.amazonS3Objeto.nome;
    },
  },
  {
    Header: "Tipo",
    field: "amazonS3Objeto.extensao",
    props: {center: 'true'},
    getValue: (row) => {
      return row.amazonS3Objeto.extensao;
    },
  },
  {
    Header: "Tamanho",
    field: "amazonS3Objeto.tamanho",
    props: {center: 'true'},
    getValue: (row) => {
      return formatBytes(row.amazonS3Objeto.tamanho);
    },
  },
];

export const  BraspagAnexoTipoDocumento = [
  {
    name: 'Domicilio Bancário',
    value: 'DOMICILIO_BANCARIO'
  },
  {
    name: 'Modelo de termo de adesão',
    value: 'MODEL_OF_ADHESION_TERM' 
  },
  {
    name: 'Prova de atividade comercial',
    value: 'PROOF_BUSINESS_ACTIVITY'
  }
];

export const braspagSituacoes = [
  {
    label: 'Aprovado',
    value: 'APPROVED',
    iconColor: '#27B0AD',
    color: '#DBF1F1'
  },
  {
    label: 'Aprovado com restrições',
    value: 'APPROVEDWITHRESTRICTION',
    iconColor: '#F9BE73',
    color: '#FDECD5'
  },
  {
    label: 'Rejeitado',
    value: 'REJECTED',
    iconColor: '#FB7676',
    color: '#FEE3E3'
  },
  {
    label: 'Sob análise',
    value: 'UNDERANALYSIS',
    iconColor: '#73C8F9',
    color: '#E3F4FE'
  },
  {
    label: 'Não Iniciado',
    value: 'NOTSTARTED',
    iconColor: '#868686',
    color: '#EEEEEE'
  },
  {
    label: 'Erro no cadastro',
    value: 'ERROR',
    iconColor: '#FB7676',
    color: '#FEE3E3'
  }
]