import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const SyringeIcon = props => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M8.48782 1.28782C8.60498 1.17066 8.79493 1.17066 8.91208 1.28782L10.7121 3.08782C10.8292 3.20498 10.8292 3.39493 10.7121 3.51208C10.5949 3.62924 10.405 3.62924 10.2878 3.51208L10.05 3.27422L8.97422 4.34995L10.1121 5.48782C10.2292 5.60498 10.2292 5.79493 10.1121 5.91208C9.99493 6.02924 9.80498 6.02924 9.68782 5.91208L8.99995 5.22422L5.66356 8.56061C5.38225 8.84192 5.00072 8.99995 4.6029 8.99995H3.42422L1.71208 10.7121C1.59493 10.8292 1.40498 10.8292 1.28782 10.7121C1.17066 10.5949 1.17066 10.405 1.28782 10.2878L2.99995 8.57569V7.39701C2.99995 6.99918 3.15799 6.61765 3.43929 6.33635L3.77569 5.99995L4.28782 6.51208C4.40498 6.62924 4.59493 6.62924 4.71208 6.51208C4.82924 6.39493 4.82924 6.20498 4.71208 6.08782L4.19995 5.57569L4.67569 5.09995L5.18782 5.61208C5.30498 5.72924 5.49493 5.72924 5.61208 5.61208C5.72924 5.49493 5.72924 5.30498 5.61208 5.18782L5.09995 4.67569L5.57569 4.19995L6.08782 4.71208C6.20498 4.82924 6.39493 4.82924 6.51208 4.71208C6.62924 4.59493 6.62924 4.40498 6.51208 4.28782L5.99995 3.77569L6.77569 2.99995L6.08782 2.31208C5.97066 2.19493 5.97066 2.00498 6.08782 1.88782C6.20498 1.77066 6.39493 1.77066 6.51208 1.88782L7.64995 3.02569L8.72569 1.94995L8.48782 1.71208C8.37066 1.59493 8.37066 1.40498 8.48782 1.28782ZM9.62569 2.84995L9.14995 2.37422L8.07422 3.44995L8.54995 3.92569L9.62569 2.84995Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SyringeIcon
