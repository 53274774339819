import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const ArrowAmpliarIcon = (props) => {
  return (
    <svg 
      width="15" 
      height="16" 
      viewBox="0 0 15 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
    <path 
      d="M7.89738 0.801147L13.942 0.802316L14.0387 0.813432L14.1089 0.829063L14.192 0.856648L14.2633 0.888914L14.3143 0.91741L14.3644 0.950628L14.4155 0.990547L14.466 1.03721L14.5415 1.12492L14.5989 1.21391L14.6416 1.3041L14.6699 1.38837L14.6825 1.44051L14.6898 1.48239L14.6986 1.60115V7.60426C14.6986 8.04609 14.3404 8.40426 13.8986 8.40426C13.4883 8.40426 13.1502 8.09543 13.104 7.69756L13.0986 7.60426L13.0984 3.53235L3.03039 13.5995L7.10042 13.6C7.51068 13.6 7.84882 13.9088 7.89503 14.3067L7.90042 14.4C7.90042 14.8102 7.59158 15.1484 7.19371 15.1946L7.10042 15.2L1.05167 15.1986L0.975204 15.1904L0.913888 15.1784L0.851893 15.161L0.796672 15.1408L0.724597 15.107L0.65296 15.0641L0.579787 15.0085L0.605794 15.0297C0.556344 14.9909 0.511576 14.9464 0.472482 14.8972L0.431585 14.8409L0.400574 14.7902L0.374178 14.7386L0.347144 14.6734L0.327051 14.6098L0.308246 14.5203L0.302366 14.4714L0.299194 14.4V8.39686C0.299194 7.95503 0.657367 7.59686 1.09919 7.59686C1.50946 7.59686 1.8476 7.90569 1.89381 8.30356L1.89919 8.39686V12.4675L11.9664 2.40115H7.89738C7.48711 2.40115 7.14898 2.09232 7.10276 1.69444L7.09738 1.60115C7.09738 1.15932 7.45555 0.801147 7.89738 0.801147Z"
      fill="currentColor"
    />
    </svg>
    
  );
};

export default ArrowAmpliarIcon;
