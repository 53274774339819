import React, { useEffect, useState } from "react";
import InputSelectComLabel from "../../../../components/Input/InputSelectComLabel";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Colors from "../../../../template/Colors";
import SelectCustomIcon from "./SelectCustomIcon";
import Radio from "../../../../components/Radio/Radio";
import {
  Close as CloseIcon,
} from "@material-ui/icons";
import {
  Grid,
  Checkbox,
  withStyles,
} from "@material-ui/core";
import OpcaoAcaoBotaoMoreAnamnese from "./OpcaoAcaoBotaoMoreAnamnese";
import {
  CaixaSelecaoIcon,
  DataIcon,
  HoraIcon,
  ListaSuspensaIcon,
  MultiplaEscolhaIcon,
  RespostaCurtaIcon,
  RespostaLongaIcon,
  DataEHoraIcon,
  NumeroIcon,
} from "../../../../assets/img/svg";
import TextCharCounter from "../../../../components/TextCharCounter/TextCharCounter";

const tiposCampo = [
  { label: " Resposta curta", value: "INPUT", icon: RespostaCurtaIcon },
  { label: " Resposta longa", value: "TEXT_AREA", icon: RespostaLongaIcon },
  { label: " Multipla escolha", value: "RADIO", icon: MultiplaEscolhaIcon },
  { label: " Caixa de seleção", value: "CHECKBOX", icon: CaixaSelecaoIcon },
  { label: " Lista suspensa", value: "COMBOBOX", icon: ListaSuspensaIcon },
  { label: " Data", value: "DATE", icon: DataIcon },
  { label: " Hora", value: "TIME", icon: HoraIcon },
  { label: " Data e hora", value: "DATETIME", icon: DataEHoraIcon },
  { label: " Número", value: "NUMBER", icon: NumeroIcon },
];

const CardModeloAnamnese = (props) => {
  const {
    classes,
    pergunta,
    tipo,
    handleChange,
    excluirResposta,
    listaResposta,
    handleChangeResposta,
    onClickAdicionarResposta,
    onClickOpcaoOutro,
    handleExcluirPergunta,
    handleDuplicarPergunta,
    tipoCampoPergunta,
    posicao
  } = props;

  const [openMore, setOpenMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState(null);
  const [showTipo, setShowTipo] = useState(false);

  useEffect(() => {
    setShowTipo(tipo === "CHECKBOX" || tipo === "COMBOBOX" || tipo === "RADIO")
  })

  const tipoCampo = (index) => {
    switch (tipo) {
      case "CHECKBOX":
        return <Checkbox className={classes.colorOpcaoPerguntaTipo} disabled />;
      case "COMBOBOX":
        return <div className={classes.opcaoTipoComboBox}> {index + 1}. </div>;
      case "RADIO":
        return <Radio className={classes.colorOpcaoPerguntaTipo} disabled />;
      default:
        break;
    }
  };

  const handleCloseMore = (event) => {
    setOpenMore(false);
  };

  const handleClickMore = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMore((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const possuiOutro = listaResposta.some((item) => item.isOutro);

  return (
    <div className={classes.cardEIcon}>
      <div>
        <DragHandleIcon className={classes.dragHandleIcon} />
      </div>
      <Grid className={classes.card}>
        <Grid className={classes.cardHeader}>
          <Grid item xs={8}>
            <InputSelectComLabel
              onChange={(event) => handleChange(event, "pergunta")}
              label={"Titulo da pergunta"}
              value={pergunta || ""}
              maxLength={500}
            />
            <TextCharCounter currQuantity={pergunta.length || 0} limit={500} />
          </Grid>
          {posicao}
          <Grid item xs={4}>
            <SelectCustomIcon
              label={"Tipo do campo"}
              isSelect
              onChange={(event) => handleChange(event, "tipo")}
              value={tipoCampoPergunta}
              options={tiposCampo}
            />
          </Grid>
        </Grid>
        <Grid>
          {showTipo &&
            listaResposta.map((item, index) => {
              return (
                <div key={index}>
                  <div className={classes.contentOpcaoPergunta}>
                    <div className={classes.opcaoPerguntaTipo}>
                      {tipoCampo(index)}
                    </div>
                    {item.isOutro ? (
                      <div className={classes.opcaoOutro}>Outro</div>
                    ) : (
                      <InputSelectComLabel
                        className={classes.opcaoPerguntaInput}
                        maxLength={50}
                        onChange={(event) => handleChangeResposta(event, index)}
                        value={item.nome || ""}
                      />
                    )}
                    <div
                      onClick={() => excluirResposta(index)}
                      className={classes.buttonExcluir}
                    >
                      <CloseIcon className={classes.excluirIcon} />
                    </div>
                  </div>
                </div>
              );
            })}
        </Grid>
        {showTipo && (
          <div className={classes.contentAdicionarResposta}>
            <span
              className={classes.adicionarResposta}
              onClick={onClickAdicionarResposta}
            >
              Adicionar opção
            </span>
            {!possuiOutro && tipo !== "COMBOBOX" && (
              <>
                {` ou `}
                <span
                  className={classes.adicionarResposta}
                  onClick={onClickOpcaoOutro}
                >
                  adicionar "Outro"
                </span>
              </>
            )}
          </div>
        )}
      </Grid>
      <div>
        <div
          className={classes.buttonMore}
          onClick={handleClickMore("bottom-end")}
        >
          <MoreVertIcon className={classes.moreVertIcon} />
        </div>
        <OpcaoAcaoBotaoMoreAnamnese
          openMore={openMore}
          anchorEl={anchorEl}
          placement={placement}
          handleDuplicarPergunta={handleDuplicarPergunta}
          handleExcluirPergunta={handleExcluirPergunta}
          handleCloseMore={handleCloseMore}
        />
      </div>
    </div>
  );
};

const style = (theme) => ({
  card: {
    borderRadius: 10,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "#ffff",
    marginBottom: "16px",
  },
  dragHandleIcon: {
    color: Colors.commons.gray7,
    marginRight: "16px",
  },
  cardHeader: {
    display: "flex",
    marginBottom: "5px",
  },
  cardEIcon: {
    display: "flex",
  },
  excluirIcon: {
    color: Colors.commons.gray7,
    marginTop: "5px",
  },
  contentOpcaoPergunta: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
  },
  opcaoPerguntaInput: {
    width: "100%",
  },
  opcaoTipoComboBox: {
    color: Colors.commons.gray9,
    width: 25,
  },
  opcaoPerguntaTipo: {
    marginRight: 8,
    textAlign: "end",
  },
  colorOpcaoPerguntaTipo: {
    color: Colors.commons.gray7,
  },
  buttonExcluir: {
    cursor: "pointer",
  },
  adicionarResposta: {
    color: Colors.commons.padrao,
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  contentAdicionarResposta: {
    marginTop: 8,
  },
  divOpcaoOutro: {
    display: "flex",
  },
  opcaoOutro: {
    display: "flex",
    alignItems: "end",
    width: "100%",
    marginTop: 5, 
  },
  moreVertIcon: {
    color: Colors.commons.gray9,
  },
  buttonMore: {
    cursor: "pointer",
    width: "30px",
    height: "30px",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    "&:hover": {
      background: "#EAEAEA",
    },
  },
});

export default withStyles(style)(CardModeloAnamnese);
