import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import ItemGrid from "../../../../components/Modal/ItemGrid";
import { InputForm } from "../../../../components/Modal/Input";
import ButtonWhatsApp from "../../../../components/Button/ButtonWhatsApp";
import colors from "../../../../template/Colors";
import { goToWhatsApp } from "../../../../utils/goToWhatsApp";
import { buildLinkPagamentoVizi } from "../../../../config/config";
import Notification from "../../../../components/Notification";
import NewIcon from "../../../../components/Icon/NewIcon";

const copiarLinkIcon = require("../../../../assets/img/svg/copiar-icon.svg");

const LinkPagamento = (props) => {
  const { classes, chavePublica, formaPagamentoName } = props;
  const [notification, setNotification] = useState({});

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
      variant: "",
    };
    setNotification(notification);
  };

  const handleClickWhatsappLinkPagamento = () => {
    goToWhatsApp(null, null, buildLinkPagamentoVizi(chavePublica));
  }

  const copiarLinkDaTeleconsulta = async () => {
    const link = buildLinkPagamentoVizi(chavePublica);
    try {
      await navigator.clipboard.writeText(link);
      const notification = {
        isOpen: true,
        message: 'Link de pagamento copiado para área de transferência.',
        variant: 'success'
      };
      showAlertMessage(notification);
    } catch (error) {
      console.error('Erro ao copiar Link de pagamento para área de transferência', error);
      const notification = {
        isOpen: true,
        message: 'Erro ao copiar Link de pagamento para área de transferência.',
        variant: 'error'
      };
      showAlertMessage(notification);
    }
  };

  const isFormaPagamentoValid = ['Pix', 'Cartão de Crédito'].some((formaPagamento) => formaPagamento === formaPagamentoName);

  return (
    <>
        <Notification
            close={closeAlertMessage}
            reset={closeAlertMessage}
            isOpen={notification.isOpen}
            variant={notification.variant}
            message={notification.message}
        />
        {isFormaPagamentoValid && (
          <div className={classes.row}>
            <NewIcon color='white' />
            <ItemGrid size={12} label={"Link de pagamento:"} classes={{item: classes.itemGrid}}>
              <div className={classes.inputWithButton}>
                <InputForm
                  value={buildLinkPagamentoVizi(chavePublica)}
                  className={classes.inputLink}
                  classes={{
                    input: classes.inputLink,
                  }}
                  customStyle={classes.inputLink}
                  disabled
                />
                <ButtonWhatsApp
                  onClick={handleClickWhatsappLinkPagamento}
                />
                <div  className={classes.copyLinkButton} onClick={copiarLinkDaTeleconsulta}>
                  <img
                    alt="copiar link"
                    src={copiarLinkIcon}
                  />
                </div>
              </div>
            </ItemGrid>
          </div>
        )}
    </>
  );
};

const styles = {
  row: {
    display: "flex",
    marginTop: "16px",
    padding: '16px 8px',
    border: '1px solid #21f0c7',
    borderRadius: '8px',
    position: 'relative',
    '& > svg': {
      position: 'absolute',
      top: '-20px',
      right: '0px',
    },
  },
  copyLinkButton:{
    width: '22px',
    height: '20px',
    borderRadius: '100px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '5px',
    "&:hover": {
            background: colors.commons.gray8,
    } 
  },
  inputWithButton: {
    backgroundColor: "#f2f2f2",
    height: 40,
    width: '100%',
    display: "flex",
    alignItems: "center",
    borderRadius: '8px',
  },
  inputLink: {
    width: '100%'
  },
  itemGrid: {
    paddingRight: '0px !important',
    '& > div': {
      color: '#505050',
      fontWeight: 'bold'
    }
  },
};

export default withStyles(styles)(LinkPagamento);
