import React, { useState } from 'react';

import Parser from 'html-react-parser';
import { Grid, withStyles, Checkbox } from "@material-ui/core";
import Dialog from '../../../components/Dialog/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonConfirm from "../../../components/Button/ButtonConfirm";
import ButtonCancel from "../../../components/Button/ButtonCancel";

const TermoUsoModal = props => {

    const { classes, open, documento, tipo, titulo, handleAccept, handleRefuse } = props;
    const [checkboxStatus, setCheckboxStatus] = useState(false);

    const decodificarBase64 = (documentoBase64) => {
        return decodeURIComponent(
            atob(documentoBase64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    };

    const renderDocumento = (doc) => {
        const documentoBase64 = doc[0] && doc[0].pdf.replace("data:text/html;base64,", "");

        return (
            <>
                <div className={classes.documento}>
                    {documentoBase64 && Parser(decodificarBase64(documentoBase64))}
                </div>
            </>
        )
    }

    return (
        <Dialog
            classes={{
                scrollPaper: classes.scrollPaper,
                paper: classes.paper,
            }}
            maxWidth="md"
            open={open}
            fullWidth
        >
            <DialogTitle classes={{ root: classes.dialogHeader }} disableTypography>
                <Grid className={classes.dialogTitle}>
                    <h1>{titulo}</h1>
                </Grid>
            </DialogTitle>

            <DialogContent classes={{ root: classes.dialogContentBorder }}>
                <Grid className={classes.dialogContent}>
                    <Grid className={classes.scroll}>
                        {renderDocumento(documento)}
                    </Grid>
                    <Grid className={classes.confirmTerms}>
                        <Grid className={classes.checkboxLabel}>
                            <Checkbox
                                color={'primary'}
                                onChange={() => setCheckboxStatus(!checkboxStatus)}
                                checked={checkboxStatus}
                            />
                            {`Eu li e concordo com os ${titulo}`}
                        </Grid>

                        <Grid className={classes.buttons}>
                            <ButtonCancel
                                onClick={() => handleRefuse()}
                            >
                                Recusar
                            </ButtonCancel>

                            <ButtonConfirm
                                onClick={() => handleAccept(tipo)}
                                disabled={!checkboxStatus}
                            >
                                Aceitar
                            </ButtonConfirm>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default withStyles(theme => ({

    scrollPaper: {
        alignItems: "initial",
    },
    paper: {
        borderRadius: 35,
        overflow: "unset",
        height: 630,
    },

    dialogHeader: {
        background: 'white',
        borderRadius: '15px',
        padding: '24px 36px',
    },

    dialogContentBorder: {
        backgroundColor: '#E0E0E0',
        borderRadius: '0 0 15px 15px',
        padding: '0 15px 15px',
    },

    dialogContent: {
        overflowY: 'scroll',
        margin: 0,
        backgroundColor: 'white',
        boxShadow: 'inset 0px 11px 38px -15px rgba(0,0,0,0.07);',
        height: '100%',
        borderRadius: '0 0 15px 15px',
    },

    headerContent: {
        paddingRight: 30,
        paddingLeft: 30,
    },

    headerButtons: {
        position: 'absolute',
        right: 20,
        top: 20
    },
    checkboxLabel: {
        display: 'flex',
        justifyContent: 'center'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '&>button': {
            margin: '10px'
        }
    },
    confirmTerms: {
        marginTop: '8px'
    },
    dialogTitle: {
        '&>h1': {
            color: '#26ACA9',
            fontSize: '24px',
            marginTop: '6px',
        },
    },

    scroll: {
        height: '80%',
        overflow: 'scroll'
    },
}))(TermoUsoModal);