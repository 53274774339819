import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const MoneyIcon = props => {
  return (
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <g clip-path='url(#clip0_9057_13736)'>
        <path
          d='M1.73705 3.08668C0.908697 3.09767 0.246085 3.77808 0.257067 4.60644L0.349858 11.6058C0.36084 12.4342 1.04126 13.0968 1.86961 13.0858L12.8686 12.94C13.697 12.929 14.3596 12.2486 14.3486 11.4202L14.2558 4.42085C14.2449 3.5925 13.5644 2.92989 12.7361 2.94087L1.73705 3.08668ZM4.25009 4.05346L4.26334 5.05337C4.27798 6.15784 3.3945 7.06506 2.29003 7.0797L1.29012 7.09296L1.27686 6.09305L2.27677 6.07979C2.82901 6.07247 3.27075 5.61886 3.26343 5.06662L3.25017 4.06671L4.25009 4.05346ZM7.32605 9.76318C6.35963 9.77599 5.56581 9.00295 5.553 8.03653C5.54019 7.07012 6.31324 6.2763 7.27965 6.26349C8.24606 6.25068 9.03988 7.02373 9.05269 7.99014C9.06551 8.95655 8.29246 9.75037 7.32605 9.76318ZM1.32989 10.0927L1.31663 9.09278L2.31654 9.07953C3.42101 9.06489 4.32824 9.94837 4.34288 11.0528L4.35613 12.0528L3.35622 12.066L3.34297 11.0661C3.33565 10.5139 2.88203 10.0721 2.3298 10.0794L1.32989 10.0927ZM12.3289 9.94688C11.7767 9.9542 11.3349 10.4078 11.3423 10.96L11.3555 11.96L10.3556 11.9732L10.3424 10.9733C10.3277 9.86883 11.2112 8.96161 12.3157 8.94697L13.3156 8.93371L13.3288 9.93363L12.3289 9.94688ZM12.2759 5.94723L13.2758 5.93398L13.2891 6.93389L12.2892 6.94714C11.1847 6.96179 10.2775 6.0783 10.2628 4.97383L10.2496 3.97392L11.2495 3.96066L11.2627 4.96058C11.27 5.51281 11.7237 5.95455 12.2759 5.94723ZM15.3486 11.407C15.3669 12.7875 14.2626 13.9216 12.882 13.9399L2.46826 14.0779C2.68189 14.6577 3.24299 15.0677 3.89604 15.0591L12.8953 14.9398C14.8281 14.9142 16.3742 13.3265 16.3485 11.3937L16.2823 6.39414C16.2736 5.74109 15.8489 5.19105 15.2636 4.99287L15.3486 11.407Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_9057_13736'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.210693 1.10675) rotate(-0.759532)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MoneyIcon
