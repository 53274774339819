import React from 'react'
import { withStyles } from '@material-ui/core'
import Anamnese from './Anamnese'
import Document from './Document'

const DocumentoSolicitacao = ({
  solicitacao,
  isDisabled,
  classes,
  getValuesAnamnese,
  selectedTab,
  optionsDocument,
}) => {
  if (!solicitacao) return <div className={classes.emptyMessage}>Selecione uma solicitação</div>
  return (
    <>
      {solicitacao?.tipoEntradaProntuario === 'ANAMNESE' ? (
        <Anamnese
          perguntas={solicitacao?.perguntas}
          isDisabled={!isDisabled}
          getValuesAnamnese={getValuesAnamnese}
        />
      ) : (
        <Document
          solicitacao={solicitacao}
          optionsDocument={optionsDocument}
          selectedTab={selectedTab}
        />
      )}
    </>
  )
}

const styles = () => ({
  emptyMessage: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    color: '#505050',
  },
})

export default withStyles(styles)(DocumentoSolicitacao)
