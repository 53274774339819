import { gridContainer } from "../appHealth.style";
import colors from "../../../template/Colors";

const styles = (theme) => ({
	root: {
		...gridContainer,
		overflow: "hidden",
	},

	content: {
		gridArea: "content",
		display: "grid",
		gridTemplateRows: "auto 1fr",
		position: "relative",
		overflow: "hidden",
	},

	notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '0.875rem',
    border: '0',
    minHeight: '0.375rem',
    height: 32,
    color: colors.commons.fontColor,
    backgroundColor: colors.commons.gray2,
    margin: '0',
    borderRadius: '8px',
  },
  classIcons: {
    fontSize: 24,
  },

	
	wrapperProfissionais:{
		display: 'flex'
	},

	profilePerfil:{
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingRight: 16,
	},

	inputError: {
		border: "1px solid " + theme.palette.commons.red,
	},

	headerTitleRoot: {
		maxWidth: theme.commons.menuLeft.width,
		margin: "0",
	},

	headerContainer: {
		display: "flex",
		justifyContent: "space-between",
		flexGrow: 1,
	},

	leftSpace: {
		padding: "10px",
	},

	titleHeader: {
		fontSize: "1.2rem",
		fontWeight: "bold",
		color: theme.palette.primary.main,
		whiteSpace: "nowrap",
	},

	tableContainer: {
		display: "grid",
		alignContent: "start",
		overflowY: "auto",
		gridGap: "20px",
		padding: "2% 4%",
		paddingRight: "8%",
		backgroundColor: '#F5F5F5',
	},

	gridCardContainer: {
		display: "grid",
		gridGap: "24px",
		width: "90%",
		paddingBotton: "20px",
		marginLeft: "0px",
		gridTemplateColumns: "1fr 1fr",

		"@media (max-width: 1100px)": {
			gridTemplateColumns: "1fr",
			gridTemplateRows: "auto auto auto",
		},
	},

	cardDadosProfissional: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '24px'
	},

	gridCardContainerPublic: {
		display: "grid",
		gridGap: "24px",
		paddingBotton: "20px",
		marginLeft: "0px",
		gridTemplateColumns: "1fr 1fr",
		"@media (max-width: 1100px)": {
			gridTemplateColumns: "1fr",
			gridTemplateRows: "auto auto auto",
		},
	},

	gridCardContainerTemplatColumns: {
		width: 'calc(90% - 25px)',
		gridTemplateColumns: "50% 50%",
		"@media (max-width: 1100px)": {
			width: "90%",
			gridTemplateColumns: "1fr",
			gridTemplateRows: "auto auto auto",
		},
	},

	gridCardContainerTemplatColumnsUnidade: {
		width: '100%',
		gridTemplateColumns: "1fr 1fr",
		"@media (max-width: 1100px)": {
			gridTemplateColumns: "1fr",
			gridTemplateRows: "auto auto auto",
		},
	},

	gridCardContainerPublicAtitvidade: {
		gridTemplateColumns: "1fr",
	},

	contentWithMultiToggleButtons: {
		background: '#fff',
		padding: '16px',
		borderRadius: '16px',
		width: 'calc(100% - 30px)',
	},

	cardProfessionalData: {
		width: "100%",
		marginTop: "24px",
	},

	inputDadosCadastraisPaciente: {
		padding: "5px 0 10px 0 !important",
		width: "100%",
		fontFamily: "Poppins !important",
		"& ::placeholder": {
			color: colors.commons.darkGray,
			opacity: 1,
		},
	},

	inputContainer:{
		width: 'calc( 100% - 20px )',
		background: '#F2F2F2',
		color: '#505050',
		borderRadius: '8px',
		height: '32px',
		padding: '4px 8px',

		'& > div': {
			'& > p': {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			}
		}
	},

	textBox: {
		height: 'calc( 100% - 30px )',
	},

	dadosCastraisContainer: {
		height: 'fit-content',
	},

	withoutMarginBottom: {
		margin: '10px 0 0 0'
	},

	withMarginBottom: {
		margin: '0 0 10px 0',
	},

	boxObservacoesCharCounter: {
		fontSize: '10px !important',
		color: '#868686',
		marginTop: '4px',
		marginRight: '8px',
		float: 'right'
	},

	lastColumnContent: {
		marginBottom: '24px'
	},

	lastColumnGridContent:{
		paddingRight: '24px'
	},

	cardDadosCadastrais:{
		paddingBottom: '8px !important',
	},
	cardPerfilDadosPessoais:{
		[theme.breakpoints.up("lg")]: {
			gridRow: "span 2",
		},

		[theme.breakpoints.down("lg")]: {
			gridRow: "span 1",
		},
	},

	cardPerfilDadosCadastrais:{
		[theme.breakpoints.up("lg")]: {
			gridRow: "span 2",
		},

		[theme.breakpoints.down("lg")]: {
			gridRow: "span 1",
		},
	},

	assinarDigitalmente: {

		[theme.breakpoints.up("lg")]: {
			gridColumn: "span 1",
		},

		[theme.breakpoints.down("lg")]: {
			gridColumn: "span 2",

		},
		[theme.breakpoints.down("sm")]: {
			gridColumn: 'span 1',

		},
	},

	conselhoRegionalUF: {
		display: "grid",
		gridTemplateColumns: "1fr 100px",
	},

	radioButtonGroupContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	radioButtonGroupLabel: {
		margin: 0,
	},

	radioButton: {
		margin: "0 0 0 10px",
		'& label': {
			color: `${colors.commons.secondary} !important`,
			fontWeight: 'bold',
		},
	},

	radioButtonVermelho: {
		fontWeight: 600,
		fontSize: 10,
		"& label": {
			color: `${colors.commons.red} !important`,
			fontFamily: "Poppins !important",
		},
		"&:checked": {
			background: `${colors.commons.red} !important`,
			color: `${colors.commons.red} !important`,
		},
	},

	acoes: {
		position: 'absolute',
		right: '12px',
		bottom: '35%',
		display: 'flex',
		flexDirection: 'column',
	},
	buttonCancelar: {
		margin: 8,
		height: 40,
		width: 40,
		color: '#fff',
		background: colors.commons.red,
		"&:hover": {
			background: colors.commons.darkRed,
		}
	},

	buttonSalvar: {
		margin: 8,
		height: 40,
		width: 40,
		color: '#fff',
		background: colors.primary.main,
		"&:hover": {
			background: colors.primary.dark,
		}
	},

	inputWithCheckbox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},

	formControlLabelCheckbox: {
		marginTop: 5,
	},

	input: {
		width: "100%",
	},

	inputValorTelemedicina: {
		width: "100%",
		borderBottom: '1px solid #BDBDBD',
	},

	footer: {
		gridArea: "footer",
		paddingLeft: 15,
		paddingRight: 45,
		display: "flex",
	},
	fieldConselho: {
		width: '100%',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	viziLinkContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	viziLinkLabel: {
		fontSize: '18px !important',
		color: '#505050',
		fontWeight: '700 !important'
	},
	viziLink: {
		color: '#219A97',
		fontSize: '11px !important',
		fontWeight: '700 !important'
	},
	avaliacaoPageContainer: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '24px'
	},
	reportContainer: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: '16px',
		width: '100%'
	},
	commentsHeaderTitle: {
		fontSize: '20px !important'
	},
	viziWarningText: {
		fontSize: '12px !important',
		fontWeight: '400 !important',
		color: '#5F6368',
		textDecoration: 'none',
		'&>a': {
			marginLeft: '5px',
			fontWeight: '600 !important'
		}
	},
	cardContainer: {
		height: 'fit-content', 
		marginBottom: '24px',
	},
	warnings: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '16px',
	},
	withSwitchInTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start'
	},
	perfilLinkInfoText: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		columnGap: '5px',
		color: '#868686'
	},
	toogleButtonsContainer: {
		marginBottom: "16px",
	},

	contentColumn: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },

	styleError: {
		border: '1px solid #FB7676'
	},
	convenioOption: {
    background: "#F2F2F2",
    border: "0.5px solid #BDBDBD",
    borderRadius: "100px",
    minWidth: "80px",
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 8px",
    margin: "3px",
    "& svg": {
      color: "#868686",
      marginLeft: "5px",
    },
  },
  listaConveniosSelecionados: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default styles;
