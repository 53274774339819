import { inject } from "mobx-react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import { observer } from "mobx-react-lite";
import styles from "../../../../assets/jss/pages/configuracaoReceitaStyle";
import TextFieldMetricUnit from "../../../../components/TextField/TextFieldMetricUnit";

const Margens = observer((props) => {
  const { classes, configuracaoImpressaoStore } = props;
  const {
    configuracaoImpressao,
    verifyErrors,
    errors,
  } = configuracaoImpressaoStore;

  const margemSuperiorError = errors.margem?.superior;
  const margemInferiorError = errors.margem?.inferior;
  const margemDireitaError = errors.margem?.direita;
  const margemEsquerdaError = errors.margem?.esquerda;

  const handleChangeUnitMargem = (campo, event) => {
    configuracaoImpressaoStore.configuracaoImpressao.margem[campo] =
      event.valueWithMask;

    verifyErrors();
  };

  const handleFocusMargem = (campo) => {
    configuracaoImpressaoStore.margemFocused= {
        ...configuracaoImpressaoStore.margemFocused,
        [campo]: true,
    };
  };

  const handleBlurMargem = (campo) => {
    configuracaoImpressaoStore.margemFocused= {
        ...configuracaoImpressaoStore.margemFocused,
        [campo]: false,
    };
  };

  return (
    <Grid item xs={12} xl={10}>
      <Typography component="h6">Margens</Typography>
      <Grid container direction="row">
        <Grid item xs={6}>
          <div className={classes.numberStepGroup}>
            <Typography component="label">Superior</Typography>
            <TextFieldMetricUnit
              unit="cm"
              value={configuracaoImpressao.margem.superior}
              onUnitChange={(e) => handleChangeUnitMargem("superior", e)}
              onFocus={() => handleFocusMargem("superior")}
              onBlur={() => handleBlurMargem("superior")}
              error={margemSuperiorError}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.numberStepGroup}>
            <Typography component="label">Inferior</Typography>
            <TextFieldMetricUnit
              unit="cm"
              value={configuracaoImpressao.margem.inferior}
              onUnitChange={(e) => handleChangeUnitMargem("inferior", e)}
              onFocus={() => handleFocusMargem("inferior")}
              onBlur={() => handleBlurMargem("inferior")}
              error={margemInferiorError}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.numberStepGroup}>
            <Typography component="label">Esquerda</Typography>
            <TextFieldMetricUnit
              unit="cm"
              value={configuracaoImpressao.margem.esquerda}
              onUnitChange={(e) => handleChangeUnitMargem("esquerda", e)}
              onFocus={() => handleFocusMargem("esquerda")}
              onBlur={() => handleBlurMargem("esquerda")}
              error={margemEsquerdaError}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.numberStepGroup}>
            <Typography component="label">Direita</Typography>
            <TextFieldMetricUnit
              unit="cm"
              value={configuracaoImpressao.margem.direita}
              onUnitChange={(e) => handleChangeUnitMargem("direita", e)}
              onFocus={() => handleFocusMargem("direita")}
              onBlur={() => handleBlurMargem("direita")}
              error={margemDireitaError}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
});

const MargensWithStyles = withStyles(styles)(Margens);
export default inject("configuracaoImpressaoStore")(MargensWithStyles);
