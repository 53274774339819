const styles = {
  odontograma: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    overflow: "auto",
  },
  odontogramaTelaCheia: {
    padding: "0 64px",
  },
  modalConfirmacao: {
    "& div:nth-child(2)": {
      fontSize: "18px",
    }
  },
};

export default styles;
