import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { TextFieldSearch } from '../../../components/TextField';
import Colors from "../../../template/Colors";

class ProcedimentoEdit extends React.Component {
  state={
    procedimentoEdit: null,
  }

  async componentDidMount(){
    const { procedimento } = this.props;
    await this.setState({ procedimentoEdit: procedimento })
  };

  handleChangeProcedimento = (e) => {
    this.setState({
      procedimentoEdit: e
    });
    this.props.handleChangeProcedimento(e);
  };

  render() {
    const { classes, loadProcedimentos, procedimento, handleConfirmar } = this.props;
    const { procedimentoEdit } = this.state;
    return (
      <>
        <Grid item xs={8} className={classes.inputLabel}>
          <label>Procedimento:</label>
          <TextFieldSearch
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            value={procedimentoEdit}
            placeholder={''}
            onChange={this.handleChangeProcedimento}
            loadOptions={loadProcedimentos}
            withPaginate
            debounceTimeout={300}
            additional={{
                page: 0
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div
            className={classes.botaoAdicionar}
            onClick={() => handleConfirmar(procedimento.id)}
          >
            <p className={classes.labelBotaoAdicionar}>Confirmar</p>
          </div>
        </Grid>
      </>
    );
  }
}
const style = {
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    height: 32,
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '100px',
    fontFamily: "Poppins"
  },
  botaoAdicionar:{
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    color: 'white',
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: Colors.commons.yellow,
  },
  inputLabel: {
    display: 'inline-flex',
    flexDirection: 'column',
    paddingRight: 5,
    '&>label': {
      color: "#26ACA9",
    }
  },
};

export default withStyles(style)(ProcedimentoEdit);
