import { withStyles } from "@material-ui/core";
import React from "react";
import Button from "../../../Button/Button";
import { styles } from "./styles";

import writterCuate from "../../../../assets/img/cuate.png";
import { supportWhatsappLink } from "../../../../pages/ProfissionalSaude/constants/perfilViziConstants";

import { lockedFallbackDefaultMessage, lockedFallbackDefaultTitle } from './utils/constants';

export const LockedFallback = withStyles(styles)(
  ({ classes, isLoading, blurImageSrc, customWhatsAppLink, customTitle, customMessage }) => {
    const ref = React.useRef();

    if (isLoading) return null;
    
    return (
      <div
        style={{
          position: "relative",
          flex: 1,
          ...((blurImageSrc && {
            backgroundImage: `url(${blurImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }) ||
            {}),
        }}
        ref={ref}
      >
        <div className={classes.dialog}>
          <div>
            <img src={writterCuate} alt="" />
          </div>
          <div className={classes.dialogText}>
            <h3>{customTitle || lockedFallbackDefaultTitle}</h3>
            <p>
              {customMessage || lockedFallbackDefaultMessage}
            </p>
            <a
              href={customWhatsAppLink || supportWhatsappLink}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
              }}
            >
              <Button>Fale com um consultor</Button>
            </a>
          </div>
        </div>
        {!blurImageSrc && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(245, 245, 245, 0.5);",
              backdropFilter: "blur(5.5px)",
              zIndex: 1300,
            }}
          />
        )}
      </div>
    );
  }
);
