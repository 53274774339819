import React from 'react';
import { withStyles, Switch, FormControlLabel, FormGroup } from '@material-ui/core';
import { observer } from "mobx-react-lite";

import { InputForm } from '../../../components/Modal/Input'
import RectLabel from '../../../components/Labels/RectLabel';
import classNames from 'classnames';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(0, 0, 0, 10%)',
    width: '100%',
  },
  controller: {
    width: '100%',
    justifyContent: 'space-between',

    '& span:last-child': {
      flex: 1,
    },
  },
  informacao: {
    fontSize: '10px',
    color: '#5F6368',
    margin: '-12px 36px 12px 16px',
  },
  switchContainerInput: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  wrapperContainerInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    margin: '8px 0px',
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F2F2F2',
    width: '60px',
    height: '40px',
    color: '#505050',
    margin: '8px 0px',
    borderRadius: '8px',
    '&>div>input': {
      paddingRight: '0px',
    },
  },
}



const getLabel = (option) => {
  const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "&>div": {
      height: '18px',
      marginLeft: ''
    }
  };

  return(
    <div style={labelStyle}>
      <> { option.label } </>
      { option.disabled && <RectLabel color="#9871FC" text="Em breve"/> }
    </div>
  )
};

const ProfissionalSaudeConfigOptions = observer(({ options, classes, handleChangeInput, valueInput,  handleSwitchChange, isChecked, canRender }) => {
  const renderSwitches = () => {    
    return (
      <>
        {options.map((option, index) => {

          if(option?.withInput) { return renderSwitchWithInput(option, index) }
          
          return(
          <>
            {(option.condition?.length > 0 ? canRender(option.condition) : true) && (
              <FormGroup key={index} className={classes.switchContainer}>
                <FormControlLabel
                  control={
                    <Switch
                    checked={isChecked(option)}
                    color="primary"
                    />
                  }
                  onChange={() => handleSwitchChange(option)}
                  label={getLabel(option)}
                  labelPlacement='start'
                  className={classes.controller}
                  disabled={option.disabled}
                />
                {isChecked(option) && option.informacao && <div className={classes.informacao}> {option.informacao} </div>}
              </FormGroup>
            )}
          </>
        )})}
      </>
    );
  };

  const renderSwitchWithInput = (option, index) => {
    return (
      <div className={classNames(
        classes.wrapperContainerInput, { borderBottom: index !== options.length - 1 ? '1px solid rgba(0, 0, 0, 10%)' : '' }
      )}>
        <FormGroup className={classes.switchContainerInput}>
          <FormControlLabel
            control={<Switch checked={isChecked(option)} color='primary' />}
            onChange={() => handleSwitchChange(option)}
            label={getLabel(option)}
            labelPlacement='start'
            className={classes.controller}
            disabled={option.disabled}
          />
          {isChecked(option) && option.informacao && (
            <div className={classes.informacao}> {option.informacao} </div>
          )}
        </FormGroup>
        <InputForm
          placeholder={'00'}
          className={classes.inputContainer}
          disabled={!isChecked(option)}
          type='number'
          value={valueInput(option)}
          onChange={e => handleChangeInput(e, option)}
        />
      </div>
    )
  }
  
  return (
    <>
      {options.length > 0 && (
        <div className={classes.container}>
          {renderSwitches()}
        </div>
      )}
    </>
  );
});

export default withStyles(styles)(ProfissionalSaudeConfigOptions);
