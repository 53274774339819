import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core";
import moment from "moment";
import styles from "./UnificacaoStyle";
import Dialog from "../../../../components/Dialog/Dialog";
import { AlertIcon } from "../../../../assets/img/svg";
import InfosSujeitoAtencaoModal from "../../../../components/Modal/InfosSujeitoAtencaoModal";
import ModalHeader from "../../../../components/Modal/ModalHeader";
import ListaPaciente from "./ListaPaciente";
import { findAllSujeitoAtencaoUnificacao, unificarSujeitosAtencao } from "../../../../services/SujeitoAtencaoService";
import ListaCadastroUnificacao from "./ListaCadastroUnificacao";
import ButtonLong from "../../../../components/Button/ButtonLong";
import AlertaUnificacao from "./AlertaUnificacao";
import { alertasUnificacao } from "./AlertasUnificacao";

const ModalUnificacao = (props) => {
  const { open, classes, sujeitoAtencaoStore, handleClose, handleInformacoesAlertaUnificacao, motivo } = props;
  const { objView } = sujeitoAtencaoStore;

  const [valueSearch, setValueSearch] = useState(objView.nome);
  const [pacientes, setPacientes] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [lastScroll, setLastScroll] = useState(false);
  const [isLoadingListPaciente, setIsLoadingListPaciente] = useState(false);
  const [origens, setOrigens] = useState([]);
  const [destino, setDestino] = useState({
    nome: objView.nome,
    id: objView.id,
    dataNascimento: objView.dataNascimento ? moment(objView.dataNascimento).format("DD/MM/YYYY") : '-',
    principal: true,
  });
  const [isLoadingUnificacao, setIsLoadingUnificacao] = useState(false);
  const [alerta, setAlerta] = useState(alertasUnificacao.default);

  useEffect(() => {
    loadPacientes();
  }, [pageNumber]);

  useEffect(() => {
    pageNumber === 0 && 
    pacientes.length === 0 && 
    !lastScroll && 
    loadPacientes();
  }, [pacientes])

  const onChangeSearch = (e) => {
    setValueSearch(e.target.value);
  };

  const clear = () => {
    setPacientes([]);
    setPageNumber(0);
    setLastScroll(false);
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter" && valueSearch.length >= 3) {
      clear();
    }
  };

  const handleClickSearch = () => {
    clear();
  };

  const removeItensSelecionadosPacientes = (listaPacientes, listaPacientesSelecionados) => {
    return listaPacientes.filter(item => !listaPacientesSelecionados.some(itemSelecionado => itemSelecionado.id === item.id));
  };

  const loadPacientes = async () => {
    try {
      setIsLoadingListPaciente(true);
      const listaSelecionados = [...origens, destino];

      const response = await findAllSujeitoAtencaoUnificacao({
        search: valueSearch,
        pageNumber,
        filtrarUnificados: true
      });

      const listaSemItensSelecionados = removeItensSelecionadosPacientes(response.content, listaSelecionados);

      setPacientes([...pacientes, ...listaSemItensSelecionados]);
      setLastScroll(response.last);
    } finally {
      setIsLoadingListPaciente(false);
    }
  };

  const loadMore = () => {
    if (isLoadingListPaciente) {
      return;
    }

    setPageNumber(pacientes.length > 0 ? pageNumber + 1 : 0);
  };

  const onClickPaciente = (pacienteSelecionado, index) => {
    const listaOrigens = [
      ...origens,
      {
        ...pacienteSelecionado,
        dataNascimento: pacienteSelecionado.dataNascimento
          ? moment(pacienteSelecionado.dataNascimento).format("DD/MM/YYYY")
          : "-",
      },
    ]

    const pacientesSelecionados = [...listaOrigens, destino];
    
    const listaSemItensSelecionados = removeItensSelecionadosPacientes(pacientes, pacientesSelecionados);
    setOrigens(listaOrigens);
    setPacientes(listaSemItensSelecionados)
  };

  const handleClickTrocaCadastroPrincipal = (cadastro, index) => {
    delete destino.principal;

    const listaOrigens = origens;
    listaOrigens.splice(index, 1, destino);

    setOrigens(listaOrigens);
    setDestino({
      ...cadastro,
      principal: true,
    });
  };

  const handleClickRemoveCadastro = (index) => {
    const listaOrigens = origens;
    listaOrigens.splice(index, 1);

    setOrigens([...listaOrigens]);
  };

  const handleConfirmaUnificacao = () => {
    switch (alerta.nome){
      case 'default':
        return setAlerta(alertasUnificacao.primeiraConfirmacao);
      case 'primeiraConfirmacao':
        return setAlerta(alertasUnificacao.segundaConfirmacao);
      case 'segundaConfirmacao':
        return setAlerta(alertasUnificacao.ultimaConfirmacao);
      case 'ultimaConfirmacao':
      default:
        return handleUnificarCadastros();
    } 
  };

  const handleUnificarCadastros = async() => {
    setIsLoadingUnificacao(true);
    setAlerta(alertasUnificacao.processoIniciado);
    const listIdOrigens = origens.map(item => item.id);

    const response = await unificarSujeitosAtencao({
      motivo,
      origens: listIdOrigens,
      destino: destino.id
    });

    await sujeitoAtencaoStore.verificaSujeitoAtencaoUnificado(sujeitoAtencaoStore.idOpened);

    if(response.data.errors){
      handleCancelarUnificacao();
      setAlerta(alertasUnificacao.error);

      setTimeout(() => {
        setAlerta(alertasUnificacao.default);
      }, 2500);

      setIsLoadingUnificacao(false);

    } else {
      handleInformacoesAlertaUnificacao(alertasUnificacao.sucess)
      handleClose();
    };
  };

  const handleCancelar = () => {
    handleClose();
  };

  const handleCancelarUnificacao = () => {
    setAlerta(alertasUnificacao.default);
  };

  const clearSearch = () => {
    setValueSearch('');
    clear();
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.contentModal}>
        <div className={classes.contentUnificacao}>
          <div>
            <InfosSujeitoAtencaoModal
              id={destino?.id}
              title="Unificar cadastro"
            />
            <div className={classes.contentAlert}>
              <img src={AlertIcon} alt="Alert" className={classes.iconAlert} />
              <div className={classes.mensagemAlert}>
                O cadastro marcado com estrelinha será o principal, esse irá
                receber os dados dos outros cadastros. Os demais cadastros
                selecionados serão inativados para sempre.
                <strong> Essa ação não poderá ser desfeita! </strong>
              </div>
            </div>
            </div>
              <ListaCadastroUnificacao
                handleClickRemoveCadastro={handleClickRemoveCadastro}
                handleClickTrocaCadastroPrincipal={
                  handleClickTrocaCadastroPrincipal
                }
                destino={destino}
                origens={origens}
              />
          <div className={classes.contentButtons}>
              <div className={classes.divButtonUnificar}>
                <ButtonLong 
                  noShadow 
                  colorCustom="green" 
                  onClick={handleConfirmaUnificacao}
                  className={classes.buttonUnificar}
                  disabled={isLoadingUnificacao || origens.length === 0}
                >
                    Unificar cadastros
                </ButtonLong>
              </div>

            <span className={classes.buttonCancel} onClick={handleCancelar}>
                Cancelar
            </span>
          </div>
        </div>
        <div className={classes.contentLista}>
          <ModalHeader title="" closeButton={true} actionClose={handleClose} />
          <ListaPaciente
            title="Lista de pacientes"
            valueSearch={valueSearch}
            onChangeSearch={onChangeSearch}
            onKeypressSearch={onKeypressSearch}
            handleClickSearch={handleClickSearch}
            lista={pacientes}
            lastScroll={lastScroll}
            loadMore={loadMore}
            isLoading={isLoadingListPaciente}
            onClickPaciente={onClickPaciente}
            handleClickClear={clearSearch}
          />
        </div>
      </div>
      {alerta?.open && <AlertaUnificacao
        open={alerta?.open}
        title={alerta?.title}
        textStrong={alerta?.textStrong}
        text={alerta?.text}
        icon={alerta?.icon}
        color={alerta?.color}
        isButtons={alerta?.isButtons}
        ultimaConfirmacaoUnificar={alerta?.ultimaConfirmacaoUnificar}
        handleCancelar={handleCancelarUnificacao}
        handleContinuar={handleConfirmaUnificacao}
      />}
    </Dialog>
  );
};

const ModalUnificacaodWithStyles = withStyles(styles)(ModalUnificacao);
export default inject("sujeitoAtencaoStore")(ModalUnificacaodWithStyles);
