import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import SujeitoAtencaoDados from "./SujeitoAtencao/Manutencao/SujeitoAtencaoDados";
import SujeitosDeAtencao from "./SujeitoAtencao/Consulta/SujeitosDeAtencao";

import Atendimento from "./Atendimento/Atendimento";
import ProntuarioAtendimento from "./Atendimento/ProntuarioAtendimento";

import Chat from "./Chat";
import Financeiro from "./Financeiro";
import Relatorios from "./Relatorios";
import Dashboard from "./Dashboard";
import Configuracoes from "./Configuracoes";
import Perfil from "./ProfissionalSaude/Perfil";
import TeleconsultaChimeScreen from "./Atendimento/Telemedicina/TeleconsultaChimeScreen";
import SolicitacoesPacientes from "./SolicitacoesPacientes/SolicitacoesPacientes";
import Guias from "./Guias";
import {
  RoleProtection,
  LockedFallback,
  ROLE_PROTECTION_CONDITIONS,
} from "../components/RoleProtection";
import AUTHORITIES from "../config/authorities";

import pacientesBlur from "../assets/img/blur-screens/pacientes.png";
import prontuarioBlur from "../assets/img/blur-screens/prontuario.png";
import relatoriosBlur from "../assets/img/blur-screens/relatorios.png";
import configuracoesBlur from "../assets/img/blur-screens/configuracoes.png";
import { chatWhatsAppURL, chatBlockedScreenTitle, chatBlockedScreenMessage } from "./Chat/utils/constants";

const CheckRoute = (props) => {
  const {
    component: Component,
    isAuthenticated,
    location,
    roles = [],
    condition,
    blurImageSrc,
    customWhatsAppLink,
    customTitle,
    customMessage,
  } = props;
  return isAuthenticated ? (
    <RoleProtection
      roles={roles}
      fallback={(props) => (
        <LockedFallback 
          customWhatsAppLink={customWhatsAppLink}
          customMessage={customMessage}
          customTitle={customTitle} 
          blurImageSrc={blurImageSrc} 
          {...props} 
        />
      )}
      condition={condition}
    >
      <Component {...props} />
    </RoleProtection>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
};

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  roles,
  condition,
  blurImageSrc,
  customWhatsAppLink,
  customTitle,
  customMessage,
  ...rest
}) => (
  <Route
    {...rest}
    component={(props) => (
      <CheckRoute
        isAuthenticated={isAuthenticated}
        component={Component}
        roles={roles}
        condition={condition}
        blurImageSrc={blurImageSrc}
        customWhatsAppLink={customWhatsAppLink}
        customTitle={customTitle}
        customMessage={customMessage}
        {...props}
      />
    )}
  />
);

const Routes = ({ isAuthenticated }) => (
  <Switch>
    <PrivateRoute
      exact
      path="/"
      component={Dashboard}
      isAuthenticated={isAuthenticated}
    />

    <PrivateRoute
      path="/sujeito-atencao/edit/:sujeitoId"
      component={SujeitoAtencaoDados}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_SUJEITO_ATENCAO_READ]}
      blurImageSrc={pacientesBlur}
    />
    <PrivateRoute
      path="/sujeito-atencao"
      component={SujeitoAtencaoDados}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_SUJEITO_ATENCAO_READ]}
      blurImageSrc={pacientesBlur}
    />
    <PrivateRoute
      path="/sujeitos-de-atencao"
      component={SujeitosDeAtencao}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_SUJEITO_ATENCAO_READ]}
      blurImageSrc={pacientesBlur}
    />

    <PrivateRoute
      path="/atendimento/prontuario"
      component={ProntuarioAtendimento}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_ENTRADA_PRONTUARIO_READ]}
      blurImageSrc={prontuarioBlur}
    />
    <PrivateRoute
      path="/atendimento"
      component={Atendimento}
      isAuthenticated={isAuthenticated}
      condition={ROLE_PROTECTION_CONDITIONS.ANY}
      roles={[
        AUTHORITIES.ROLE_AGENDAMENTO_READ,
        AUTHORITIES.ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS,
      ]}
    />

    <PrivateRoute
      path="/solicitacoes-de-pacientes"
      component={SolicitacoesPacientes}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_ENTRADA_PRONTUARIO_READ]}
      blurImageSrc={prontuarioBlur}
    />

    <PrivateRoute
      path="/chat"
      component={Chat}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_CHAT_READ]}
      customWhatsAppLink={chatWhatsAppURL}
      customTitle={chatBlockedScreenTitle}
      customMessage={chatBlockedScreenMessage}
    />

    <PrivateRoute
      path="/financeiro"
      component={Financeiro}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_TITULO_READ]}
      blurImageSrc={configuracoesBlur}
    />

    <PrivateRoute
      path="/guias"
      component={Guias}
      isAuthenticated={isAuthenticated}
      roles={[AUTHORITIES.ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_READ]}
      blurImageSrc={configuracoesBlur}
    />

    <PrivateRoute
      path="/relatorios"
      component={Relatorios}
      isAuthenticated={isAuthenticated}
      condition={ROLE_PROTECTION_CONDITIONS.ANY}
      roles={[
        AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO,
        AUTHORITIES.ROLE_SUJEITO_ATENCAO_RELATORIO,
      ]}
      blurImageSrc={relatoriosBlur}
    />

    <PrivateRoute
      path="/configuracoes"
      component={Configuracoes}
      isAuthenticated={isAuthenticated}
    />

    <PrivateRoute
      path="/perfil"
      component={Perfil}
      isAuthenticated={isAuthenticated}
    />

    <PrivateRoute
      path="/meeting"
      component={TeleconsultaChimeScreen}
      isAuthenticated={isAuthenticated}
    />
  </Switch>
);

export default Routes;
