import React from 'react'
import { Switch } from '@material-ui/core'
import string from '../../../../utils/string'
import SettingsIcon from '../../../../components/Icon/Settings'

const accessKeyObject = (obj, key, campo) => {
  if(campo === "E_MAIL") {
    return obj?.contato?.email || obj?.paciente?.email;
  }

  key = key.replace('sujeitoAtencao.', '')
  const keys = key.split('.')
  let value = obj
  keys.forEach((key) => {
    if (!value) return null
    value = value[key]
  })
  return value
}

export const getDynamicColumns = (columns, onClickSwitch, handleOpenModal) => {
  const dinamicColumns = columns.map((column) => {
    const { campoPaciente } = column
    if (['STATUS', 'NOME'].includes(campoPaciente.campo)) return null
    return {
      Header: campoPaciente.nome,
      getValue: (value) => {
        return accessKeyObject(value, campoPaciente?.caminhoValor, campoPaciente.campo)
      },
    }
  })
  return [
    {
      Header: (
        <SettingsIcon onClick={handleOpenModal} style={{ cursor: 'pointer' }} />
      ),
      getValue: () => '',
      onlyIcon: true,
    },
    {
      Header: 'Nome',
      getValue: (sujeitoAtencao) => {
        return string.truncate(sujeitoAtencao.nome, 30)
      },
      field: 'nome',
    },
    ...dinamicColumns,
    {
      Header: 'Status',
      getValue: (sujeitoAtencao) => (
        <Switch
          checked={sujeitoAtencao?.ativo}
          color='primary'
          onClick={() => onClickSwitch(sujeitoAtencao)}
        />
      ),
      isClick: true,
    },
  ]
}
