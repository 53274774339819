import colors from '../../../template/Colors'

const styles = {
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '0.875rem',
    border: '0',
    minHeight: '0.375rem',
    height: 35,
    color: colors.commons.fontColor,
    backgroundColor: colors.commons.gray11,
    margin: '0',
    borderRadius: '6.25rem',
  },
  classIcons: {
    fontSize: 24,
  },
  wrapperTab: {
    margin: 0,
    padding: '0 24px',
  },

  itensSolicitante: {
    paddingBottom: '0.35rem',
  },
  toggleButtons: {
    margin: '0 auto',
    width: '80%',
  },
  inputDateFormDisabled: {
    width: '100%',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    '&>div': {
      background: 'none',
      '&>input': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
  inputDisabled: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    color: '#5F6368',
    borderRadius: '8px',
    width: '100%',
    padding: '0.4rem',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  inputCodigo: {
    height: '1.5rem',
    padding: '0.2rem',
  },
  borderNone: {
    '& fieldset': { border: 'none' },
  },
  typography: {
    color: '#868686',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginBottom: '4px',
  },
  inputDadosAgendamento: {
    borderRadius: '8px',
    height: 33,
  },
  inputColor: {
    color: colors.commons.fontColor,
  },
  inputDate: {
    width: '100%',
  },
  titleObservacao: {
    padding: '3rem 0 0.8rem 0',
    color: '#868686',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginBottom: '4px',
  },
  inputObservacaoListagem: {
    background: colors.commons.gray2,
    width: 'calc(100% - 20px)',
    padding: '8px',
    borderRadius: '10px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    border: '1px solid #F2F2F2',
  },
  inputPaciente: {
    paddingRight: '10px',
  },
  wrapperTabAutorizacao: {
    margin: '0',
    padding: '0 24px',
  },
  dialogContent: {
    overflowY: 'scroll',
    margin: 0,
    paddingTop: 16,
    background: '#fff',
    borderRadius: '0 0 0.938rem 0.938rem',
    height: '100%',
    maxHeight: '200px',
  },
  tableHeadRow: {
    color: '#505050',
    fontWeight: 700,
    display: 'flex',
    fontSize: '12px',
    justifyContent: 'space-between',
  },
  tableHead: {
    fontSize: '11px',
    textAlign: 'center',
    marginLeft: '35px',
    wordBreak: 'break-all',
  },
  tableHeadDescricao: {
    padding: '0 0.8rem',
    fontSize: '11px',
  },
  tableDataRow: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableData: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    fontWeight: 400,
    padding: '0.375rem 0.5rem',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  tableDataDescricao: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    fontWeight: 400,
    padding: '0.375rem 0.5rem',
    alignItems: 'center',
    display: 'flex',
  },
  marginRight: {
    marginRight: 8,
  },
  backgroundColorGray11: {
    background: colors.commons.gray11,
  },
  paddingRight: {
    paddingRight: 8,
  },
  menuHeight: {
    maxHeight: '25vh',
    '&> div': {
      maxHeight: '25vh',
    },
  },
  inputContainer: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 8px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  inputDateForm: {
    width: '100%',
    background: '#F2F2F2 !important',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 0px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    '&>div': {
      '&>input': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
}

export default styles
