import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import { Fab } from '@material-ui/core';
import { withStyles, Grid } from "@material-ui/core";
import Dialog from "../../../../components/Dialog/Dialog";
import Button from "../../../../components/Button/Button";
import { WhatsApp } from "../../../../components/Icon";
import { alignItems, background, borderRadius, display, flexDirection, fontSize, fontWeight, height, justifyContent, overflow, padding, width } from "styled-system";
import ButtonWhatsApp from "../../../../components/Button/ButtonWhatsApp";
import { Whatsapp } from "../../../../assets/img/svg";
import MensagemWhatsappIcon from "../../../../components/Icon/MensagemWhatsappIcon";

const buttonStyle = {
    fontWeight: 'bold',
    width: 120,
    height: 38,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    borderRadius: 85,
    "& span": {
        fontSize: 15
    }
};

const styles = theme => ({
    alertContainer: {
        width: 304,
        height: 175,
        overflow:"hidden",
    },
    alertTitle: {
        "& h6": {
            fontSize: 19,
            fontStyle: 'normal',
            fontWeight: 'bold',
        },
        width: '100%'
    },
    button: {
        position: 'absolute',
        right: 20,
        top: 20
    },
    buttonWhatsApp:{
        background:"transparent",
        color:"#868686",
    },
    contentButton:{
        color:"#868686",
        fontSize:"12px",
        display:"flex",
        flexDirection: "column",
        alignItems:"flex-start",
        justifyContent:"center"
    },
    text:{
        padding:"5px",
        textWrap:"nowrap",
        fontSize:"13px",
        fontWeight:"600"
    },
    contentButtonWhatsApp:{
        display:"flex",
        flexDirection:"column",
        width:"100%",
        background:"#F2F2F2",
        border:"1px solid #0000001A",
        width:"calc(100% - 65px);",
        height:"60px",
        padding:"0px 8px ",
        borderRadius:"8px",
        alignItems:"center",
        fontWeight:"500",
        cursor:"pointer"
    },
    iconClose:{
        cursor:"pointer",
    },
    paper:{
        overflow:"hidden"
    }
});

const ModalCompartilhar = ({
    open,
    onClose,
    handleClose,
    classes,
    onClick,
}) => (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{paper:classes.paper}}
        >
            <div className={classes.alertContainer}>
                <DialogTitle className={classes.alertTitle}>
                    <Grid>
                    <div style={{fontSize: 18, color:"#505050"}} c>
                           Compartilhar
                        </div>
                        <div className={classes.button}>
                                <CloseIcon className={classes.iconClose} fontSize={'small'} onClick={onClose}/>
                        </div>
                    </Grid>
                        <div className={classes.contentButton}>
                            <div className={classes.text}>Enviar arquivo via Whatsapp</div>
                            <div className={classes.contentButtonWhatsApp} onClick={onClick}>
                                  <MensagemWhatsappIcon width={"25px"}/>
                                  WhatsApp                                 
                            </div>
                            
                        </div>
                </DialogTitle>
            </div>
        </Dialog>
    );

export default withStyles(styles)(ModalCompartilhar);
