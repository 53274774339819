const styles = {
  content: {
    display: "grid",
    gridTemplateRows: "80px 71px 1fr",
    position: "relative",
    overflow: "hidden",
    background: "#f5f5f5",

    "& thead th": {
      textAlign: "left",
      position: "sticky",
      top: 0,
    },
  },
  filtros: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: "8px",
    padding: "13px 16px 8px 16px",
    boxShadow:
      "10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)",
    background: "#fff",
    marginTop: 1,
  },
  tableContainer: {
    overflow: "auto",
    margin: "16px",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "#fff",
    "&> div": {
      height: "100%",
      "&> div": {
        height: "100%",
      },
    },
  },
  tituloFiltros: {
    color: "#868686",
    fontSize: "12px !important",
  },
  campoFiltro: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "160px",
  },
  notchedOutline: {
    border: "0",
  },
  buttonsDownloadPrint: {
    display: "flex",
    position: "absolute",
    bottom: "20px",
    right: "14px",
    width: "88px",
    justifyContent: "space-between",
  },
  inputContainer: {
    display: "flex",
    background: "#F2F2F2",
    color: "#505050",
    borderRadius: "8px",
    border: "none",
    "& > div": {
      "& > p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  },
  select: {
    width: "165px",
  },
  selectMenu: {
    marginTop: "4px",
    width: "165px",
    zIndex: 1000,
  },
  wrapperCheckbox: {
    display: "flex",
    alignItems: "center",
    flex: 2,
  },
};

export default styles;