export const sortArrayByKey = (array , field, dir) => {
  return array.sort((a, b) => {
    const aValue = a[field];
    const bValue = b[field];
    if (dir === 'ASC') {
      return (aValue > bValue) ? 1 : -1;
    }
    return (aValue < bValue) ? 1 : -1;
  })
};
