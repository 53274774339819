import React from 'react';
import styled from 'styled-components';

const Validacao = styled.span`
    text-decoration: ${props => props.isValido ? "line-through" : "none"} 
`;

const MensagemSenha = ({ validacao }) => {

    return (<div>
        Senha fraca, é necessário ter no mínimo:
        <Validacao isValido={validacao.tamanho}>8 caracteres</Validacao>,
        <Validacao isValido={validacao.letraMaiuscula}> 1 letra maiúscula </Validacao>,
        <Validacao isValido={validacao.letraMinuscula}> 1 letra minúscula </Validacao>,
        <Validacao isValido={validacao.numero}> 1 número </Validacao> e
        <Validacao isValido={validacao.caractereEspecial}> 1 caractere especial </Validacao>
    </div>)
};

export default MensagemSenha;