import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';

import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import ChatPopup from './components/ChatPopup';

const ChatPopups = observer(({ classes, chatStore, socketStore }) => {
  const { contactsToPopup } = chatStore;
  const { isConnectionOpen, isApiInitialized } = socketStore;
  const [openPopups, setOpenPopups] = useState(false);
  const isChatPage = window.location.hash === '#/chat'

  useEffect(() => {
    if (contactsToPopup.length > 0) {
      setOpenPopups(true);
    } else {
      setOpenPopups(false);
    }
  }, [contactsToPopup]);

  useEffect(() => {
    return () => {
      chatStore.contactsToPopup = [];
    }
  }, [])

  if (!isConnectionOpen || !isApiInitialized || isChatPage) {
    return null;
  }

  return (
    <>
      {openPopups && (
        <div className={classes.chatPopupsContainer}>
          <div className={classes.chatPopups}>
            {contactsToPopup.map((contact, index) => (
              <ChatPopup key={contact?.id} index={index} selectedContact={contact} />
            ))}
          </div>
        </div>  
      )}
    </>
  );
});

const StyledChatPopups = withStyles(styles)(ChatPopups);
const stores = ['chatStore', 'socketStore'];
export default inject(...stores)(StyledChatPopups);
