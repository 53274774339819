import React from "react";
import classNames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Drawer from "@material-ui/core/Drawer";
import { Switch, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Tooltip, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import FiltroSituacao from "../../FiltroSituacao";
import SyringeIcon from "../../../../components/Icon/Syringe";
import AgendaIcon from "../../../../components/Icon/AgendaIcon";
import GridContainer from "../../../../components/GridContainer";
import OpcaoListaAgendamentos from "../../OpcaoListaAgendamentos";
import ItemListaPaciente from "../../../../components/ItemListaPaciente";
import styles from "../../../../assets/jss/pages/prontuarioAtendimentoStyle";
import ButtonExpandHover from "../../../../components/Button/ButtonExpandHover";
import profileDefault from "../../../../assets/img/svg/profile-default.svg";

const DrawerProntuario = observer(
  ({
    classes,
    prontuarioStore,
    atendimentoStore,
    open,
    handleDateFilter,
    handleShowProcedimentos,
    selectAgendamento,
    changeDate,
    listarAgendamentosESelecionarAgendamento,
    handleDrawerClose,
    handleDrawerOpen,
    dateFilterHandler,
    sujeitoAtencaoSelected,
    handleChangeEmEsperaSort,
  }) => {
    const styleDrawer = open ? classes.drawerOpen : classes.drawerClose;
    const styleDataFilterArrows = open
      ? classes.dataFilterArrows
      : classes.dataFilterArrowsMinimizado;

    return (
      <Drawer
        variant="permanent"
        className={classNames(classes.drawerMinimizar, styleDrawer)}
        classes={{
          paper: styleDrawer,
        }}
        open={open}
      >
        <div className={classes.headerDrawer}>
          {open && (
            <div className={classNames(classes.title, classes.bold)}>
              Atendimento
            </div>
          )}
          <FiltroSituacao openDrawer={open} changeDate={changeDate}/>
        </div>
        <div
          className={classNames(
            classes.dataFilterContainer,
            !open && classes.dataFilterContainerMinimizado
          )}
        >
          <div className={classes.dataFilterInnerContainer}>
            <div
              className={styleDataFilterArrows}
              onClick={() => handleDateFilter("subtract")}
            >
              <KeyboardArrowLeft
                style={{ color: "rgba(0, 0, 0, 0.54)", marginRight: "-5px" }}
              />
            </div>
            <div
              className={dateFilterHandler}
              onClick={() => handleDateFilter("current")}
            >
              {changeDate.format(open ? "DD [de] MMMM" : "DD/MM")}
            </div>
            <div
              className={styleDataFilterArrows}
              onClick={() => handleDateFilter("add")}
            >
              <KeyboardArrowRight
                style={{ color: "rgba(0, 0, 0, 0.54)", marginLeft: "-5px" }}
              />
            </div>
          </div>
        </div>
        <OpcaoListaAgendamentos
          open={open}
          listarAgendamentosESelecionarAgendamento={
            listarAgendamentosESelecionarAgendamento
          }
        />
        {prontuarioStore.tabSelected === 1 && (
          <div className={classes.switchWithLabel}>
              <span className={classes.switchLabel}>Filtrar por ordem de agendamento</span>
              <Switch 
                  onChange={handleChangeEmEsperaSort} 
                  color="primary"
                  checked={prontuarioStore.agendamentosAguardandoFilterOrder !== 'byArriveTime'}
              />
          </div>
        )}

        <div className={classes.listaPacientesBotaoMinimizar}>
          {!prontuarioStore.loadingAgendamentos && (
            <GridContainer
              className={classNames(
                "scroll",
                classes.listaPacientes,
                open && classes.listaPacientesDrawerAberto
              )}
            >
              {prontuarioStore.agendamentos.map((agendamento, index) => {
                const agendamentosProcedimentosRealizados = agendamento?.procedimentos?.filter(
                  (item) => !item.procedimento.realizado
                );

                const color = atendimentoStore.situacoes
                  .filter((s) => s.value === agendamento.situacao)
                  .map((s) => s.cor)[0];
                return (
                  <div key={index}>
                    {sujeitoAtencaoSelected && (
                      <div style={{ position: "relative" }}>
                        {agendamento.urgente && (
                          <Tooltip
                            title="Urgente"
                            placement="bottom"
                            className={classNames(
                              classes.toolTipPaciente,
                              !open && classes.toolTipPacienteMinimizado
                            )}
                          >
                            <div className={classes.attentionButton}>
                              <PriorityHigh fontSize="inherit" />
                            </div>
                          </Tooltip>
                        )}
                        {agendamentosProcedimentosRealizados.length > 0 && (
                          <div
                            className={classNames(
                              classes.procedimentoButton,
                              !open && classes.procedimentoButtonMinimizado
                            )}
                          >
                            <ButtonExpandHover
                              color="#FC71B4"
                              hoverColor="#DD478F"
                              label="Procedimentos"
                              icon={<SyringeIcon size="16" />}
                              onClick={() =>
                                handleShowProcedimentos(agendamento)
                              }
                              fontSize="8px"
                              locked={!open}
                            />
                          </div>
                        )}
                        <div
                          className={classNames(
                            classes.agendamentoInfoButton,
                            !open && classes.agendamentoInfoButtonMinimizado
                          )}
                        >
                          <ButtonExpandHover
                            color="#707C97"
                            hoverColor="#515A6D"
                            label="Informações do agendamento"
                            icon={<AgendaIcon size="16" />}
                            onClick={() => handleShowProcedimentos(agendamento)}
                            direction="toLeft"
                            fontSize="8px"
                            locked={!open}
                          />
                        </div>
                      </div>
                    )}
                    <ItemListaPaciente
                      open={open}
                      key={index}
                      onClick={() => selectAgendamento(agendamento.id)}
                      paciente={agendamento}
                      image={agendamento?.urlFotoPerfil || profileDefault}
                      aniversario={agendamento?.aniversario}
                      urgente={true}
                      selecionado={
                        prontuarioStore.agendamentoSelected === agendamento.id
                      }
                      color={color}
                    />
                  </div>
                );
              })}
            </GridContainer>
          )}
          {prontuarioStore.loadingAgendamentos && (
            <div className={classes.contentLoadingContainer}>
              <CircularProgress color="primary" size={24} />
            </div>
          )}
          <IconButton
            aria-label={open ? "Close drawer" : "Open drawer"}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            className={classNames(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            {open ? (
              <>
                <ChevronLeftIcon />
                Minimizar barra lateral
              </>
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
      </Drawer>
    );
  }
);

const DrawerProntuarioWithStyles = withStyles(styles)(DrawerProntuario);
export default inject(
  "prontuarioStore",
  "atendimentoStore"
)(DrawerProntuarioWithStyles);
