import React from "react";
import { StyleSheet, Text } from "@react-pdf/renderer";

const FormLabel = ({ label, style }) => (
    <Text style={[styles.label, style]}>{label}</Text>
);

const styles = StyleSheet.create({
    label: {
        fontSize: 6,
        fontFamily: "Lato-Bold",
        marginBottom: 2,
    },
});

export default FormLabel;
