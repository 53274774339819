import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./SolicitacoesPacienteStyles";
import Footer from "../../components/Footer/Footer";
import Notification from "../../components/Notification";
import PageTitle from "../../components/PageTitle/PageTitle";
import SolicitacoesPacientesTable from "./SolicitacoesPacientesTable";
import SolicitacoesPacientesHeader from "./SolicitacoesPacientesHeader";
import MultiToggleButtons from "../../components/Button/MultiToggleButtons";
import ModalSolicitacoesPacientes from "./Modal/ModalSolicitacoesPacientes";
import ModalConfirmacao from "../../components/Modal/ModalConfirmacao/ModalConfirmacao";

const SolicitacoesPacientes = observer(
  ({ classes, solicitacoesPacientesStore }) => {
    const [tabSelected, setTabSelected] = useState(0);
    const { notification, propsModalSolicitacoesPacientes, propsModalConfirmacao } = solicitacoesPacientesStore;

    useEffect(() => {
      solicitacoesPacientesStore.getQuantidadeSolicitacoes();
    }, []);

    const changeTabSelected = (tabSelected) => {
      const {filterSolicitacoesPacientes, loadSolicitacaoPaciente} = solicitacoesPacientesStore;

      setTabSelected(tabSelected);
      filterSolicitacoesPacientes.descartado = tabSelected === 1;
      loadSolicitacaoPaciente(true);
    };

    return (
      <div className={classes.content}>
        <PageTitle title="Solicitações de Pacientes" />
        <SolicitacoesPacientesHeader />
        <div className={classes.contentSolicitacoes}>
          <MultiToggleButtons
            options={["Em aberto", "Descartados"]}
            quantidades={[solicitacoesPacientesStore.quantidadeSolicitacoesAberto , '']}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
            classes={{ buttonsContainer: classes.buttonsTab }}
          />
          <SolicitacoesPacientesTable />
        </div>
        {propsModalSolicitacoesPacientes.open && <ModalSolicitacoesPacientes />}
        <ModalConfirmacao
          {...propsModalConfirmacao}
        />
        <Notification
          close={() => solicitacoesPacientesStore.closeAlertMessage()}
          isOpen={notification.isOpen}
          variant={"error"}
          message={notification.message}
        />
        <Footer />
      </div>
    );
  }
);

const SolicitacoesPacientesWithStyles = withStyles(styles)(
  SolicitacoesPacientes
);
export default inject("solicitacoesPacientesStore")(
  SolicitacoesPacientesWithStyles
);
