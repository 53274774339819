import React from 'react';
import { withStyles } from '@material-ui/core';
import ItemGrid from '../../../../../components/Modal/ItemGrid';
import Button from '../../../../../components/Button/Button';
import { TextFieldSearch } from '../../../../../components/TextField';
import { styles, buttonStyle, cancelButtonStyle } from '../styles';
import { findProfissionalSaudeByUnidadeComAgenda } from '../../../../../services/ProfissionalSaudeService';

const SelectProfissionalStep = ({
  setProfissionalSaudeDestino,
  profissionalSaudeDestino,
  setModalStep,
  onCloseModal,
  classes,
  totalAgendamentos,
  selectedAgendamentos,
  allSelected
}) => {
  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page }
    });
  };

  const getUnidadeLogadaId = () => {
    const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);
    return unidadeLogada?.id;
  };

  const handleLoadMoreOptions = async ({ search, data }) => {
    let pageableDTO = {
      sortDir: 'ASC',
      sortField: 'nome',
      pageNumber: 0,
    };
    const unidadeId = (await getUnidadeLogadaId()) || null;

    const response = await findProfissionalSaudeByUnidadeComAgenda({
      search,
      unidadeId,
      pageableDTO
    });

    const profissionaisSaude = response.data.data.findProfissionalSaudeByUnidadeComAgenda;

    if (profissionaisSaude) {
      return {
        options: profissionaisSaude,
        hasMore: false,
      };
    };
  };

  const handleSelectProfissionalSaude = (profissionalSaude) => {
    setProfissionalSaudeDestino(profissionalSaude);
  };

  const handleTransferir = () => {
    setModalStep(1);
  }

  const quantity = allSelected ? totalAgendamentos : selectedAgendamentos.length;

  return (
    <>
      <span className={classes.title}>Transferir {quantity || 0} agendamento(s) selecionado(s)</span>
      <ItemGrid label="Para o profissional:" classes={{ item: classes.item }}>
        <TextFieldSearch
          menuPosition='fixed'
          placeholder='Selecione'
          classInput={classes.selectInput}
          classNotched={classes.notchedOutline}
          classIcons={classes.classIcons}
          loadOptions={loadProfissionaisSaude}
          withPaginate
          debounceTimeout={300}
          additional={{
            page: 0,
          }}
          getOptionLabel={(option) => option.nome}
          getOptionValue={(option) => option.id}
          onChange={(e) => handleSelectProfissionalSaude(e)}
          value={profissionalSaudeDestino}
        />
      </ItemGrid>
      <div className={classes.buttonsContainer}>
        <Button
          style={{ ...buttonStyle, ...cancelButtonStyle }}
          onClick={onCloseModal}
        >
          Cancelar
        </Button>
        <Button
          style={buttonStyle}
          onClick={handleTransferir}
          disabled={!profissionalSaudeDestino}
        >
          Salvar
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(SelectProfissionalStep);