import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const RepasseIcon = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
    <path d="M3 4C3 3.17157 3.67157 2.5 4.5 2.5H11.5C12.3284 2.5 13 3.17157 13 4V5.79289L16.2678 9.06066C16.7366 9.5295 17 10.1654 17 10.8284V18C17 18.2761 16.7761 18.5 16.5 18.5C16.2239 18.5 16 18.2761 16 18V10.8284C16 10.4306 15.842 10.0491 15.5607 9.76777L13 7.20711V10.2926L13.8536 11.1462C14.0488 11.3415 14.0488 11.658 13.8536 11.8533C13.6583 12.0486 13.3417 12.0486 13.1464 11.8533L10.1465 8.85333C9.72441 8.43132 9.48906 8.44937 9.38518 8.47463C9.21264 8.51659 9.06033 8.65555 8.84563 8.861C8.74583 8.95651 8.70385 9.05183 8.72435 9.19089C8.75014 9.36588 8.88667 9.67934 9.35356 10.1462C10.3251 11.1176 10.4578 11.2504 10.5666 11.3593L10.8534 11.6462C10.9472 11.74 10.9998 11.8671 10.9999 11.9997C10.9999 12.7742 11.0059 13.553 11.2199 14.1414C11.322 14.4223 11.4612 14.627 11.6437 14.7639C11.8226 14.898 12.0866 14.9998 12.4999 14.9998C12.776 14.9998 12.9999 15.2236 12.9999 15.4998L13 17C13 17.8284 12.3284 18.5 11.5 18.5H4.5C3.67157 18.5 3 17.8284 3 17V4ZM8 8C6.61929 8 5.5 9.11929 5.5 10.5C5.5 11.8807 6.61929 13 8 13C8.76495 13 9.44966 12.6564 9.90824 12.1152L9.85873 12.0657C9.75036 11.9572 9.61834 11.8251 8.6465 10.8533C8.11334 10.3202 7.80612 9.81904 7.73503 9.3367C7.65865 8.81842 7.86666 8.41372 8.15426 8.1385L8.18906 8.10495C8.21644 8.07846 8.24764 8.04828 8.2825 8.01578C8.18977 8.00536 8.09551 8 8 8ZM10 17V17.5H11V17C11 16.7239 11.2239 16.5 11.5 16.5H11.9999V15.9601C11.6342 15.8989 11.3174 15.7675 11.0501 15.5686C10.4415 15.7597 10 16.3283 10 17ZM4 5.5H4.5C5.32843 5.5 6 4.82843 6 4V3.5H5V4C5 4.27614 4.77614 4.5 4.5 4.5H4V5.5ZM10 4C10 4.82843 10.6716 5.5 11.5 5.5H12V4.5H11.5C11.2239 4.5 11 4.27614 11 4V3.5H10V4ZM6 17C6 16.1716 5.32843 15.5 4.5 15.5H4V16.5H4.5C4.77614 16.5 5 16.7239 5 17V17.5H6V17Z" fill="currentColor"/>
    </svg>
  );
};

export default RepasseIcon;
