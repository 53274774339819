import validaDocumentoEspanha from "./validacaoDocumentos";
import string from "./string";

const verificaDocumento = (documento, documentoTipo) => {
    if(documentoTipo && documentoTipo?.descricao !== "CPF"){
        return validaDocumentoEspanha(documento);
    };
    return string.validaCPF(documento);
}

export default verificaDocumento;