import colors from "../../../template/Colors";

const styles = {
  content: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    position: "relative",
    overflow: "hidden",
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",

    "& h3": {
      color: colors.commons.gray10,
      fontWeight: "normal",
      fontSize: "1rem",
    },
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 1,
    overflow: "hidden",
    height: "100%",

    "& thead": {
      position: "sticky",
      top: 0,
    },

    "& th": {
      textAlign: "left",
      width: "20%",
      "&:first-child": {
        width: "40%",
      },
    },
  },
};

export default styles;
