import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ChatWithStatusIcon = props => {
  return (
    <svg 
      width="49" 
      height="50" 
      viewBox="0 0 49 50" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M21.7733 16.111C17.6377 16.1658 14.3296 19.5628 14.3844 23.6984C14.3994 24.8301 14.6652 25.9015 15.1287 26.8589C14.8433 28.0862 14.5993 29.396 14.4897 30.0055C14.3658 30.6951 14.9717 31.2898 15.6566 31.1585C16.2775 31.0394 17.6269 30.7721 18.8794 30.4656C19.8262 30.8789 20.8739 31.1018 21.9719 31.0873C26.1075 31.0325 29.4156 27.6355 29.3607 23.4999C29.3059 19.3643 25.9089 16.0561 21.7733 16.111ZM21.9471 32.0858C23.2996 33.3077 25.0989 34.0418 27.0652 34.0157C28.1629 34.0012 29.2041 33.7507 30.1395 33.3125C31.1849 33.5426 32.3288 33.7709 33.0568 33.913C33.9503 34.0875 34.7179 33.2774 34.502 32.3966C34.3305 31.6965 34.0602 30.6097 33.7942 29.6066C34.2319 28.6375 34.4691 27.5597 34.4541 26.4283C34.4106 23.1505 32.2677 20.3926 29.3216 19.4168C29.6798 20.0482 29.9594 20.7303 30.1471 21.45C31.8115 22.495 32.9284 24.3368 32.9564 26.4482C32.9696 27.438 32.7424 28.3729 32.3297 29.1994L32.2028 29.4537L32.2765 29.7282C32.5158 30.6192 32.7675 31.6191 32.9521 32.3661C32.1771 32.2129 31.1322 32.0006 30.2092 31.7938L29.9442 31.7344L29.7028 31.8586C28.9061 32.2685 28.0045 32.5054 27.0453 32.5181C25.9875 32.5321 24.99 32.2711 24.121 31.8013C23.4584 31.9775 22.7635 32.0756 22.0466 32.0851C22.0134 32.0855 21.9802 32.0858 21.9471 32.0858Z" fill="white"/>
      <circle cx="31.7637" cy="15.645" r="6" fill="currentColor"/>
    </svg>    
  );
};

export default ChatWithStatusIcon;
