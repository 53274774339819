import React from 'react';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from "@material-ui/core/styles/index";
import Colors from "../../template/Colors";

const styles = (theme) => ({
    root: {
        padding: 0,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        fontSize: "18px",
        fontFamily: "Poppins",
        fontWeight: 700,
        color: Colors.commons.white,
    },
    btnCloseMini: {
        minHeight: 26,
        height: 32,
        width: 32,
        boxShadow: 'none',
        backgroundColor: Colors.commons.white,
        color: Colors.commons.secondary
    }
});

const ButtonClose = (props) => {
    const {classes, title, onClose} = props;

    return (
        <div className={classes.header}>
            {title}
            <Fab
                onClick={onClose}
                classes={{
                    sizeSmall: classes.btnCloseMini,
                }} size="small" color="primary" aria-label="close">
                <CloseIcon/>
            </Fab>
        </div>
    );

};

export default withStyles(styles)(ButtonClose)
