import React, { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles/index'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'

import { TextField, TextFieldSearch } from '../../../../components/TextField'
import styles from '../GuiaDeServicoStyle'

import {
  findAllEstadoList,
} from '../../../../services/GuiaDeServicosService'
import { verificaCamposVazioTabInterna, verificaCamposVazioTabInternaProfissional } from './ValidaDados'

const StepTabInterna = props => {
  const {
    usuarioStore,
    classes,
    loadProfissionaisSaude,
    guiaServico,
    handleChangeGuiaServico,
    setDisableButton,
    openNotification,
  } = props

  const [unidadeLogada, setUnidadeLogada] = useState({})
  const [estados, setEstados] = useState([])

  useEffect(() => {
    getUnidadeLogada();
    listarEstados();
    guiaServico?.solicitanteInterna && validationStep(guiaServico?.solicitanteInterna);
  }, []);

  const getUnidadeLogada = async () => {
    const unidadeLogada = await usuarioStore.getUnidadeAtual()
    setUnidadeLogada(unidadeLogada)
  }

  const listarEstados = async() => {
    const listaEstados = await findAllEstadoList();
    setEstados(listaEstados.data.data.findAllEstadoList);
    return listaEstados.data.data.findAllEstadoList;
  }

  const handleSelectProfissionais = option => {
    const ufConselho = option ? estados.find(item => option?.ufConselho === item.uf) : '';
    const solicitante = {
      codigoCbo: option?.tuss24TerminologiaCBO?.codigoTermo || '',
      codigoConselho: option?.conselhoProfissionalSaude?.codigoTermo || '',
      codigoOperadora: guiaServico?.convenio?.codigoUnidade,
      conselhoProfissionalSaude: option?.conselhoProfissionalSaude || '',
      nomeContratado: unidadeLogada?.nome,
      numeroConselho: option?.numeroConselho || '',
      profissional: option?.nome || '',
      tuss24TerminologiaCBO: option?.tuss24TerminologiaCBO || '',
      estado: ufConselho,
    };
    handleChangeGuiaServico('solicitanteInterna', solicitante);
    validationStep(solicitante);
  }

  const validationStep = (solicitante) => {
    let mensagemErro = '';
    if (verificaCamposVazioTabInterna(solicitante, guiaServico)) {
      if(verificaCamposVazioTabInternaProfissional(solicitante)){
        mensagemErro = 'O profissional não possui todos os dados necessários. ';
      }

      if(!guiaServico?.convenio?.codigoUnidade) {
       mensagemErro = mensagemErro + "O campo 'código da operadora' é obrigatório. ";
      }

      if(!solicitante.nomeContratado) {
        mensagemErro = mensagemErro + "O campo 'nome do contratado' é obrigatório.";
      }

      solicitante && openNotification(mensagemErro, 'error');
      setDisableButton(true);
      return
    }

    setDisableButton(false)
  }

  const getProfissionalLogado = async () => {
    const profissionalLogado = await usuarioStore.getProfissionalSaudeAtual();
    const unidade = await usuarioStore.getUnidadeAtual()
    const estadosList = await listarEstados();
    if (!profissionalLogado && estadosList.length === 0) return
    const ufConselho = profissionalLogado ? estadosList.find(item => profissionalLogado?.ufConselho === item.uf) : '';
    const solicitante = {
      codigoCbo: profissionalLogado?.tuss24TerminologiaCBO?.codigoTermo || '',
      codigoConselho: profissionalLogado?.conselhoProfissionalSaude?.codigoTermo || '',
      codigoOperadora: guiaServico?.convenio?.codigoUnidade,
      conselhoProfissionalSaude: profissionalLogado?.conselhoProfissionalSaude || '',
      nomeContratado: unidade?.nome,
      numeroConselho: profissionalLogado?.numeroConselho || '',
      profissional: profissionalLogado?.nome,
      tuss24TerminologiaCBO: profissionalLogado?.tuss24TerminologiaCBO || '',
      estado: ufConselho,
    }
    handleChangeGuiaServico('solicitanteInterna', solicitante);
    validationStep(solicitante);
  }

  useEffect(() => {
    if (guiaServico?.solicitanteInterna?.profissional ) return
      getProfissionalLogado();
  }, []);

  return (
    <Grid
      container
      direction={'row'}
      xs={12}
      className={classes.wrapperTab}
    >
      <Grid container spacing={8} className={classes.itensSolicitante}>
        <Grid item xs={6} >
          <Typography className={classes.typography} component='label'>
            Código na operadora
          </Typography>
          <TextField
            disabled
            className={(classes.itensSolicitante, classes.borderNone)}
            InputProps={{
              classes: {
                input: classes.inputDisabled,
              },
            }}
            value={guiaServico?.convenio?.codigoUnidade}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typography} component='label'>
            Nome do Contratado
          </Typography>
          <TextField
            disabled
            value={unidadeLogada?.nome}
            className={(classes.itensSolicitante, classes.borderNone)}
            InputProps={{
              classes: {
                input: classes.inputDisabled,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} className={classes.itensSolicitante}>
        <Grid item xs={12}>
          <Typography className={classes.typography} component='label'>
            Nome do Profissional Solicitante
          </Typography>
          <TextFieldSearch
            placeholder='Selecione'
            classNotched={classes.notchedOutline}
            classInput={classes.inputContainer}
            classIcons={classes.classIcons}
            classes={{paper: classes.menuHeight}}
            loadOptions={loadProfissionaisSaude}
            getOptionLabel={option => option?.nome}
            getOptionValue={option => option}
            value={guiaServico?.solicitanteInterna?.profissional && {nome: guiaServico?.solicitanteInterna?.profissional}}
            onChange={handleSelectProfissionais}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition='fixed'
          />
        </Grid>
      </Grid>
      <Grid container spacing={8} className={classes.itensSolicitante}>
        <Grid item xs={4} className={classes.paddingRight}>
          <Typography className={classes.typography} component='label'>
            Conselho
          </Typography>
          <TextField
            disabled
            placeholder=''
            className={classes.borderNone}
            InputProps={{
              classes: {
                input: classes.inputDisabled,
              },
            }}
            value={
              guiaServico?.solicitanteInterna?.conselhoProfissionalSaude
                ?.sigla || ''
            }
          />
        </Grid>
        <Grid item xs={4} className={classes.paddingRight}>
          <Typography className={classes.typography} component='label'>
            UF
          </Typography>
          <TextField
            disabled
            placeholder=''
            className={classes.borderNone}
            InputProps={{
              classes: {
                input: classes.inputDisabled,
              },
            }}
            value={guiaServico?.solicitanteInterna?.estado?.uf || ''}
            menuPosition='fixed'
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.typography} component='label'>
            Código CBO
          </Typography>
          <TextField
            disabled
            placeholder=''
            className={classes.borderNone}
            InputProps={{
              classes: {
                input: classes.inputDisabled,
              },
            }}
            value={guiaServico?.solicitanteInterna?.codigoCbo}
            onChange={handleSelectProfissionais}
          />
        </Grid>
      </Grid>
      <Typography className={classes.typography} component='label'>
        Número do conselho
      </Typography>
      <TextField
        className={(classes.itensSolicitante, classes.borderNone)}
        InputProps={{
          classes: {
            input: classes.inputDisabled,
          },
        }}
        disabled
        placeholder=''
        value={guiaServico?.solicitanteInterna?.numeroConselho}
      />
    </Grid>
  )
}

export default withStyles(styles)(inject('usuarioStore')(StepTabInterna))
