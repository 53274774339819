import colors from '../../../../template/Colors'

const styles = () => ({
    notchedOutline: {
      border: '0',
    },
    inputTextField: {
      fontSize: '0.875rem',
      border: '0',
      minHeight: '0.375rem',
      height: 35,
      color: colors.commons.fontColor,
      backgroundColor: colors.commons.gray11,
      margin: '0',
      borderRadius: '6.25rem',
    },
    classIcons: {
      fontSize: 24,
    },
    tableHeadRow: {
      color: '#505050',
      fontWeight: 700,
      display: 'flex',
      fontSize: '12px',
      justifyContent: 'space-between',
    },
    typography: {
      color: '#868686',
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: '400',
      marginBottom: '4px',
    },
    dadosGuiaServicoFaturamento: {
      display: "flex",
      flexDirection: 'row',
      padding: "0px 10px 20px 20px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      margin: '5px 0',
    },
    inputDisabled: {
      background: "#fff",
      border: "1px solid #f2f2f4",
    },
    horaSemMargem: {
      marginTop: '0px !important'
    },
    iconTime: {
      "& button": {
        background: '#EEEEEE',
      }
    },
    tableHeadItens: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: '8px'
    },
    containerListaFaturamento: {
      overflow: 'auto',
      padding: '20px',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row'
    },
    contentTableItens: {
      height: 'calc(100% - 10px)',
      marginTop: 10,
      "& thead th": {
        textAlign: "left",
        whiteSpace: 'nowrap',
        "&:last-child":{
          padding: '0 16px'
        },
      },
      "& tbody td": {    
        "&:last-child": {
          padding: '0 16px'
        }
      }
    },
    contentButtonsItens: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    contentButtonsItensAction: {
      display: 'flex',
      justifyContent: 'end',
    },
    multiButtonsItens: {
      display: 'flex',
      justifyContent: 'center',
      "& span": {
        whiteSpace: 'nowrap'
      }
    },
    buttonsActionItens:{
      display: 'flex',
      flexDirection: 'row',
    },
    buttonItemSelecionado: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '25px',
      height: '25px',
      borderRadius: '100px',
      color: '#fff',
      cursor: 'pointer',
      "& svg": {
        height: 18,
        width: 18
      }
    },
    buttonSaveItemSelecionado: {
      background: colors.commons.padrao,
      marginRight: '5px',
      "&:hover": {
        background: colors.primary.dark
      }
    },
    buttonCloseItemSelecionado: {
      background: colors.error.main,
      "&:hover": {
        background: colors.error.dark
      }
    },
    contentItemEdit: {
      height: 'calc(100% - 20px)',
      padding: '0 20px 20px',
      "& > div": {
        height: "calc(100% - 20px)",
      }
    },
    rowResumo: {
      display: 'flex',
      flexDirection: 'row'
    },
    inputMargens: {
      margin: '0 8px !important'
    },
    marginRight: {
      marginRight: 8,
    },
    noMargin: {
      margin: 0
    },
    inputValor: {
      '& input': {
        borderRadius: 100,
        background: colors.commons.gray2,
        fontFamily: 'Poppins !important',
        color: '#000',
        fontSize: 14,
      },
      '& fieldset': {
        border: 0,
      },
    },
    inputColor: {
      background: colors.commons.gray11,
      "& input": {
        color: colors.commons.gray9
      },
    },
    contentTableResumo: {
      marginTop: 20,
      overflow: 'auto',
    },
    imageMini: {
      position: 'relative',
      margin: '0 30px',
      borderRadius: '30px',
      cursor: 'pointer'
    },
    nomePaciente: {
      fontWeight: 700,
      fontSize: '14px',
      color: colors.commons.gray9
    },
    infosPaciente: {
      display: 'flex',
      flexDirection: 'column'
    },
    infoPaciente: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 12,
      color: colors.commons.gray9,
      "& div": {
        fontWeight: 700,
        fontSize: 12,
        marginLeft: '8px',
      }
    },
    contentObservacao:{
      padding: 20,
      height: '99%',
      "& > div":{
        height: '100%',
        "& > div": {
          height: '100%',
          "& > div": {
            height: '100%',
            "& > textarea": {
              height: 'calc(100% - 10px) !important'
            }
          }
        }
      }
    },
    observacao: {
      backgroundColor: "#F2F2F2",
      width: "calc(100% - 10px) !important",
      borderRadius: "5px",
      padding: "0px 5px",
      border: 0,
      fontSize: '14px',
      color: colors.commons.gray7,
      "&>div":{
        "&>textarea":{
          height: "100%",
          borderRadius: 0
        }
      }
    },
    inputInfosObservacao: {
      color: "#505050",
      width: '97%',
      borderRadius: 0,
    },
    labelResumo: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 12,
      flex: 1,
      color: colors.commons.gray9,
      "& strong": {
        marginLeft: 8,
      }
    },
    containerResumo: {
      padding: '16px 20px 0 20px',
      "& thead tr": {
        height: '36px',
      },
      "& thead th": {
        position: 'sticky',
        top: 0,
        textAlign: "left",
        whiteSpace: 'nowrap',
        "&:last-child":{
          padding: '0 16px'
        },
      },
      "& tbody td": {    
        "&:last-child": {
          padding: '0 16px'
        }
      }
    },
    buttonAdicionarExecutante: {
      marginLeft: 8,
      boxShadow: 'none',
      marginTop: '18px',
    },
    buttonConfirmarExecutante: {
      marginLeft: 8,
      boxShadow: 'none',
      maxHeight: 32
    },
    rowAtendimento: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    rowAdicionar: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '16px',
    },
    rowExecutantes:{
      marginTop: '16px',
    },
    buttonsAction: {
      display: 'flex',
      justifyContent: 'center',
    },
    mensagemDadosObrigatorio: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '100px',
      background: colors.commons.yellow,
      color: '#fff',
      fontSize: '12px',
      marginTop: 5,
    },
    buttonListaProcedimentos: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: '12px !important',
      marginBottom: '5px',
    },
    listaResumo:{
      overflow: 'auto',
      maxHeight: '160px',
    },
    listaHeigthMaior: {
      maxHeight: '180px',
    },
    listaExecutantes: {
      maxHeight: '240px',
      overflow: 'auto',
      height: '80%',
    },
    container: {
      height: 'calc(100% - 48px)',
    },
    boxTab: {
      height: 'calc(100% - 30px)',
    },
    inputValorUnitario: {
      background: '#f2f2f4',
      borderRadius: '100px',
      height: '32px',
      paddingLeft: '16px',
      width: '90%',
    },
    paddingLeft: {
      paddingLeft: '8px',
    },
    marginTop: {
      marginTop: '8px',
    },
    menuHeight: {
      maxHeight: '25vh',
      "&> div": {
        maxHeight: '25vh',
      }
    }
  })

  export default styles;
