import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography, Fab, DialogContent, DialogTitle } from '@material-ui/core'


import { TextFieldSearch } from '../../../../components/TextField'
import InputForm from '../../../../components/Input/InputForm'

import { findAllProcedimentos } from '../../../../services/GuiaDeServicosService'

import colors from '../../../../template/Colors'
import ButtonLong from '../../../../components/Button/ButtonLong'
import TrashIcon from '../../../../components/Icon/Trash'

const ButtonContainerRemove = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding-top: 10px;
`
const FabStyled = styled(Fab)`
  width: 30px;
  height: 30px;
  background: #707c97;
  color: ${colors.commons.white};
  border: 1px solid rgba(112, 124, 151, 1);
  min-height: initial;
`

const StepItens = props => {
  const {
    classes,
    openNotification,
    procedimentoSelected,
    setProcedimentoSelected,
    guiaServico,
    setDisableButton,
    handleChangeGuiaServico,
  } = props

  const [quantidades, setQuantidades] = useState(null)
  const [disableAdd, setDisableAdd] = useState(true)

  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    }
    const searchDTO = {
      pageableDTO,
      nome: search,
    }

    try {
      const { content, last } = (
        await findAllProcedimentos(searchDTO)
      ).data.data.findAllProcedimentos
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      openNotification('Erro ao carregar nome dos procedimentos', 'error')
    }
  }

  const handleSelectProcedimento = option => {
    setProcedimentoSelected(option)
    setQuantidades({
      quantidadeSolicitada: 1,
      quantidadeAutorizada: 1,
    })
  }

  const handleChangeQuantidade = e => {
    const { name, value } = e.target
    setQuantidades({
      ...quantidades,
      [name]: parseInt(value),
    })
  }

  const handleChangeCodigoTermo = e => {
    setProcedimentoSelected({
      ...procedimentoSelected,
      tuss22ProcedimentoEventoSaude: {
        ...procedimentoSelected?.tuss22ProcedimentoEventoSaude,
        codigoTermo: e.target.value,
      },
    })
  }

  const handleChange = (field, value) => {
    let newSolicitacao = guiaServico?.solicitacao
    newSolicitacao = {
      ...newSolicitacao,
      [field]: value,
    }
    handleChangeGuiaServico('solicitacao', newSolicitacao)
  }

  const validationProcedimento = () => {
    let isInvalid = false
    try {
      const { quantidadeAutorizada, quantidadeSolicitada } = quantidades
      const { id, tuss22ProcedimentoEventoSaude } = procedimentoSelected

      if (
        !id ||
        !tuss22ProcedimentoEventoSaude?.codigoTermo ||
        !quantidadeAutorizada ||
        !quantidadeSolicitada
      ) {
        isInvalid = true
      }
      if (quantidadeAutorizada <= 0 && quantidadeSolicitada <= 0) {
        isInvalid = true
      }
    } catch {
      isInvalid = true
    }
    setDisableAdd(isInvalid)
  }

  useEffect(() => {
    validationProcedimento()
  }, [procedimentoSelected, quantidades])

  const addProcedimento = () => {
    let procedimentos = guiaServico?.solicitacao?.itens || []
    procedimentos.push({
      procedimento: procedimentoSelected,
      ...quantidades,
    })
    setProcedimentoSelected(null)
    setQuantidades({
      quantidadeSolicitada: 0,
      quantidadeAutorizada: 0,
    })

    handleChange('itens', procedimentos)
  }

  const removeProcedimento = index => {
    let procedimentos = guiaServico.solicitacao.itens
    procedimentos.splice(index, 1)
    handleChange('itens', procedimentos)
  }

  const validationStep = () => {
    if (!guiaServico?.solicitacao?.itens) {
      return
    }
    setDisableButton(false)
  }

  useEffect(() => {
    validationStep()
  }, [guiaServico?.solicitacao])

  return (
    <>
      <DialogTitle className={classes.container}>
        <Grid container xs={12} spacing={12} className={classes.input}>
          <Grid container xs={12} spacing={8}>
            <Grid item xs={6}>
              <Typography className={classes.typography} component='label'>
                Nome:
              </Typography>
              <TextFieldSearch
                placeholder='Selecione'
                classNotched={classes.notchedOutline}
                classInput={classes.inputContainer}
                classIcons={classes.classIcons}
                classes={{ paper: classes.menuNome }}
                loadOptions={loadAllProcedimentos}
                getOptionLabel={option => option.nome}
                getOptionValue={option => option}
                value={procedimentoSelected}
                onChange={handleSelectProcedimento}
                withPaginate
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                menuPosition='fixed'
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.typography} component='label'>
                Código termo:
              </Typography>
              <InputForm
                name='codigoTermo'
                alternativeInputClass={classes.inputQtd}
                className={classes.inputCodigo}
                type='number'
                placeholder='0'
                value={procedimentoSelected?.tuss22ProcedimentoEventoSaude?.codigoTermo || ''}
                onChange={handleChangeCodigoTermo}
              />
            </Grid>
          </Grid>
          <Grid container spacing={8} xs={12}>
            <Grid item xs={5}>
              <Typography className={classes.typography} component='label'>
                Quantidade Solicitada:
              </Typography>
              <InputForm
                name='quantidadeSolicitada'
                alternativeInputClass={classes.inputQtd}
                className={classes.width}
                type='number'
                placeholder='0'
                value={quantidades?.quantidadeSolicitada}
                onChange={e => e.target.value.length <= 2 && handleChangeQuantidade(e)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.typography} component='label'>
                Quantidade Autorizada:
              </Typography>
              <InputForm
                name='quantidadeAutorizada'
                alternativeInputClass={classes.inputQtd}
                className={classes.width}
                type='number'
                value={quantidades?.quantidadeAutorizada}
                placeholder='0'
                onChange={e => e.target.value.length <= 2 && handleChangeQuantidade(e)}
              />
            </Grid>
            <Grid item xs={2}>
              <ButtonLong
                colorCustom='green'
                onClick={addProcedimento}
                disabled={disableAdd}
                className={classes.buttonAdicionar}
              >
                Adicionar
              </ButtonLong>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.dialogContent}>
          <Grid container className={classes.tableHeadRow}>
            <Grid item xs={5} className={classes.tableHeadDescricao}>
              Descrição
            </Grid>
            <Grid item xs={1} className={classes.tableHead}>
              Qtd Soli
            </Grid>
            <Grid item xs={1} className={classes.tableHead}>
              Qtd Aut
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item xs={12} className={classes.listagem}>
            {guiaServico?.solicitacao?.itens?.map((row, index) => (
              <Grid container key={index} className={classes.tableDataRow}>
                <Grid item xs={5} className={classes.tableDataDescricao}>
                  {row.procedimento.nome}
                </Grid>
                <Grid item xs={1} className={classes.tableData}>
                  {row.quantidadeSolicitada}
                </Grid>
                <Grid item xs={1} className={classes.tableData}>
                  {row.quantidadeAutorizada}
                </Grid>
                <Grid item xs={1} align='right' className={classes.tableData}>
                  <ButtonContainerRemove>
                    <FabStyled onClick={() => removeProcedimento(index)}>
                      <TrashIcon />
                    </FabStyled>
                  </ButtonContainerRemove>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
    </>
  )
}

const styles = () => ({
  container: {
    padding: '10px 20px 0px 20px',
  },
  input: {
    margin: '0 0.3rem 1.3rem 0.3rem',
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '0.875rem',
    border: '0',
    minHeight: '0.375rem',
    height: 35,
    color: colors.commons.fontColor,
    backgroundColor: colors.commons.gray11,
    margin: '0',
    borderRadius: '6.25rem',
  },
  classIcons: {
    fontSize: 24,
  },
  dialogContent: {
    overflowY: 'hidden',
    margin: 0,
    boxShadow: 'inset 0rem 0.875rem 2.375rem -1.875rem rgba(0,0,0,0.3)',
    paddingTop: 16,
    background: '#fff',
    borderRadius: '0 0 0.938rem 0.938rem',
    height: '100%',
  },
  tableHeadRow: {
    color: '#505050',
    fontWeight: 700,
    display: 'flex',
    fontSize: '12px',
    justifyContent: 'space-between',
  },
  tableHead: {
    fontSize: '11px',
    textAlign: 'center',
    wordBreak: 'break-all',
  },
  tableHeadDescricao: {
    padding: '0 0.8rem',
    fontSize: '11px',
  },
  tableDataRow: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableData: {
    color: '#5F6368',
    fontSize: '14px',
    fontWeight: 600,
    padding: '0.375rem 0.5rem',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  tableDataDescricao: {
    color: '#5F6368',
    fontSize: '14px',
    fontWeight: 600,
    padding: '0.375rem 0.5rem',
    alignItems: 'center',
    display: 'flex',
  },
  typography: {
    color: '#868686',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginBottom: '4px',
  },
  inputQtd: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 8px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    border: 'none',
  },
  inputCodigo: {
    width: '100%',
    '&>div': {
      height: '32px',
      '&>input': {
        height: '32px',
      },
    },
  },
  menuNome: {
    maxHeight: '30vh',
    '&> div': {
      maxHeight: '30vh',
    },
  },
  width: {
    width: '100%',
  },
  buttonAdicionar: {
    boxShadow: 'none',
    marginTop: '18px',
  },
  listagem: {
    overflow: 'auto',
    maxHeight: '130px',
  },
  inputContainer: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 8px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
    '&::placeholder': {
      opacity: 0.5,
    },
  },
})

export default withStyles(styles)(StepItens)
