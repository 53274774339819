import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid/Grid'
import PopperCustomMenu from '../Popper/PopperCustomMenu'
import MoreIcon from '../Icon/More'
import { menuOpcoesPopper } from './utils/menuOpcoesPopper'
import PreviewFile from './PreviewFile'
import localStorageService, { ACCESS_TOKEN_KEY } from '../../services/storage'
import { buildUrlDownload } from '../../config/config'

const style = theme => ({
  listItem: {
    marginBottom: '5px',
    background: '#fff',
    borderRadius: '10px',
    padding: '17px 15px',
    margin: '8px 23px',
  },
  itemTitle: {
    color: '#505050',
    fontSize: '14px',
    fontFamily: 'Nexa Black!important',
    overflowWrap: 'anywhere',
  },
  itemValue: {
    color: '#505050',
    fontSize: '12px',
    fontFamily: 'Nexa Book!important',
    marginTop: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconBaixar: {
    height: 20,
    width: 20,
  },
  buttonBaixar: {
    cursor: 'pointer',
    marginRight: 23,
    width: '25px',
    borderRadius: '100px',
    textAlign: 'center',
    alignSelf: 'center',
  },
})

const ItemListArquivos = props => {
  const {
    nomeArquivo,
    observacao,
    classes,
    size,
    baixarArquivo,
    handleRemoveArquivo,
    item,
    urlDownload,
  } = props

  const [showPreview, setShowPreview] = useState(false)
  const [url, setUrl] = useState('')
  const popperRef = useRef(null)

  const handleShowPreview = () => {
    setShowPreview(!showPreview)
  }

  const getUrlDonwload = async item => {
    const token = await localStorageService.get(ACCESS_TOKEN_KEY)
    const url = buildUrlDownload(urlDownload + item?.entradaProntuarioAmazonS3Objeto?.id, token)
    setUrl(url)
    return url
  }

  useEffect(() => {
    getUrlDonwload(item)
  }, [item])

  return (
    <Grid item xs={size} className={classes.listItem}>
      {showPreview ? (
        <PreviewFile item={item} handleClose={handleShowPreview} isProntuario urlDownload={url} />
      ) : (
        <>
          <div className={classes.header}>
            <div className={classes.itemTitle}>{nomeArquivo}</div>
            <PopperCustomMenu
              ref={popperRef}
              placement={'bottom-end'}
              iconButton={<MoreIcon color='#505050' />}
              colorButton={{
                background: '#fff',
              }}
              menuOpcoes={menuOpcoesPopper({
                functions: {
                  handleShowPreview,
                  baixarArquivo,
                  handleRemoveArquivo,
                  closePopper: popperRef?.current?.closePopper,
                },
              })}
            />
          </div>
          <div className={classes.itemValue}>{observacao}</div>
        </>
      )}
    </Grid>
  )
}

export default withStyles(style)(ItemListArquivos)
