import classNames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TextFieldSearch } from "../../../../../../components/TextField";
import { calculaParcela, calculaSubtotal } from "../../../../../../utils/calculaSubtotal";
import InputDateForm from "../../../../../../components/Input/InputDateForm";
import { ReactSelect } from "../../../../../../components/Select/SelectSearch";
import { findAllConvenio } from "../../../../../../services/OdontogramaService";
import { tiposModalOrcamento, tiposDescontoModalOrcamento } from "./Constatnts";
import InputMaskCurrency from "../../../../../../components/Input/InputMaskCurrency";
import { InputMaskPercentil } from "../../../../../../components/Input/InputMaskPercentil";

const TabPagamento = observer(({ classes, odontogramaStore, orcamentoStore, configUnidade }) => {
  const { orcamento, handleFieldModal } = orcamentoStore;
  const { limiteDescontoOrcamento } = configUnidade || {};

  const {
    convenio,
    dataVencimento,
    desconto,
    tipoPagamento,
    totalParcelas,
    valor,
    valorEntrada,
    subtotal,
    valorParcela,
  } = orcamento || {};

  const { valorDesconto, tipoDesconto } = desconto || {};

  const [parcelas, setParcelas] = useState([]);
  const [errorFieldDesconto, setErrorFieldDesconto] = useState(false);
  
  const usuarioLogado = JSON.parse(localStorage[`_immortal|usuarioLogado`] || null);
  const permiteTodosDesconto = usuarioLogado.authorities.some(a => 
    a.authority === 'ROLE_ORCAMENTO_DESCONTO');
    
  useEffect(() => {
    getParcelas();
  }, []);

  useEffect(() => {
    orcamentoStore.orcamento.subtotal = calculaSubtotal({
      valorTotal: valor,
      desconto: desconto.valorDesconto,
      tipoDesconto,
    });
  }, [valor, desconto,orcamento]);

  useEffect(() => {
    !permiteTodosDesconto && verificaLimiteDesconto();

  }, [subtotal])

  useEffect(() => {
    if(valor && totalParcelas) {
      orcamentoStore.orcamento.valorParcela = calculaParcela({
        valorTotal: valor,
        valorEntrada,
        totalParcelas: totalParcelas.value || totalParcelas,
      })
    }
  }, [valor, desconto, valorEntrada, totalParcelas,orcamento]);

  const getParcelas = () => {
    const parcelasMaxima = 50;
    let parcelas = [];

    for (let index = 1; index <= parcelasMaxima; index++) {
      parcelas.push({ value: index });
    }

    setParcelas(parcelas);
  };

  const verificaLimiteDesconto = () => {
    const error = orcamentoStore.errorLimiteDesconto(limiteDescontoOrcamento);
    
    setErrorFieldDesconto(error)
  }


  const handleConvenioLoadOptions = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: "ASC",
      sortField: "descricao",
    };
    const searchDTO = {
      ...pageableDTO,
      ativo: true,
      search,
    };

    try {
      const { content, last } = await findAllConvenio(searchDTO);
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleDesconto = (field, value) => {
    const descontoAtualizado = {
      ...desconto,
      [field]: value,
    };

    orcamentoStore.handleFieldModal("desconto", descontoAtualizado);
  };

  return (
    <div className={classes.content}>
      <div className={classes.row}>
        <div className={classNames(classes.contentField, classes.rowConvenio)}>
          <label>Convênio</label>
          <TextFieldSearch
            classNotched={classes.notchedOutline}
            placeholder=""
            withPaginate
            loadOptions={handleConvenioLoadOptions}
            value={convenio}
            onChange={(e) => handleFieldModal("convenio", e)}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </div>
        <div className={classNames(classes.contentField, classes.rowConvenio)}>
          <label>Tipo</label>
          <ReactSelect
            className={classes.reactSelect}
            placeholder={"Selecione"}
            value={tipoPagamento}
            onChange={(e) => handleFieldModal("tipoPagamento", e)}
            options={tiposModalOrcamento}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.contentField}>
          <label>Desconto</label>
          <ReactSelect
            className={classes.reactSelect}
            placeholder={"Selecione"}
            value={tipoDesconto}
            onChange={(e) => handleDesconto("tipoDesconto", e)}
            options={tiposDescontoModalOrcamento}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </div>
        <div
          className={classNames(
            classes.contentField,
            classes.contentValorDesconto
          )}
        >
          {tipoDesconto && tipoDesconto?.value === "VALOR" ? (
            <InputMaskCurrency
              value={valorDesconto}
              onChange={(e) => handleDesconto("valorDesconto", e.target.value)}
              className={classNames(classes.inputValor, errorFieldDesconto && classes.descontoError)}
              disabled={!tipoDesconto}
            />
          ) : (
            <InputMaskPercentil
              className={classNames(classes.inputPercentil, errorFieldDesconto && classes.descontoError)}
              classInputRoot={classes.inputRootPercentil}
              value={valorDesconto}
              onChange={(e) => handleDesconto("valorDesconto", e.target.value)}
              disabled={!tipoDesconto}
            />
          )}
        </div>
        <div className={classes.contentField}>
          <label>Validade</label>
          <InputDateForm
            value={dataVencimento || ""}
            onChange={(e) => handleFieldModal("dataVencimento", e)}
            iconposition="end"
            invalidDateMessage=""
          />
        </div>
      </div>
      {tipoPagamento?.value === "A_PRAZO" && (
        <div className={classes.row}>
          <div className={classes.contentField}>
            <label>Entrada</label>
            <InputMaskCurrency
              value={valorEntrada || ""}
              onChange={(e) => handleFieldModal("valorEntrada", e.target.value)}
              className={classes.inputValor}
            />
          </div>
          <div className={classes.contentField}>
            <label>Parcelas</label>
            <ReactSelect
              value={totalParcelas}
              onChange={(e) => handleFieldModal("totalParcelas", e)}
              options={parcelas}
              menuPlacement={"auto"}
              getOptionLabel={(option) => option?.value}
              getOptionValue={(option) => option?.value}
            />
          </div>
          <div className={classes.contentField}>
            <label>Valor</label>
            <InputMaskCurrency
              value={valorParcela || ""}
              className={classes.inputValor}
              disabled={true}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "8px",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    padding: "16px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  inputValor: {
    background: "#F2F2F2",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  inputPercentil: {
    height: 32,
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    background: "#f2f2f2",
  },
  inputRootPercentil: {
    border: "1px solid rgba(220, 220, 220, 0.2)",
    height: "30px",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  contentValorDesconto: {
    justifyContent: "end",
  },
  notchedOutline: {
    border: "none",
  },
  rowConvenio: {
    width: "50%",
  },
  descontoError: {
    border: '0.5px solid #FB7676',
  },
};

const TabPagamentoWithStyles = withStyles(styles)(TabPagamento);
export default inject("odontogramaStore", "orcamentoStore")(TabPagamentoWithStyles);
