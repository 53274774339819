import React from 'react'
import PropTypes from "prop-types";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles/index";
import { CircularProgress } from '@material-ui/core';
import { observer, inject } from "mobx-react";
import moment from "moment";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import mensagemAtendimentoStyle from "../assets/jss/components/mensagemAtendimentoStyle"
import arquivoIcon from '../assets/img/arquivo-icon-xs.png';
import Notification from "../components/Notification";

import ModalInfosInativacao from './Modal/ModalInfosInativacao';
import ModalInativarEntradaProntuario from './Modal/ModalInativarEntradaProntuario';

import { HUGG_ENTRADA_PRONTUARIO_TIPOS } from '../constants/HUGG_ENTRADA_PRONTUARIO_TIPOS';

import string from '../utils/string';
import ImagePreview from './ui/ImagePreview';

import PictureIcon from './Icon/PictureIcon';

@inject("prontuarioStore", "sujeitoAtencaoStore")
@observer
class MensagemAtendimento extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			isInativado: false,
			infosInativacao: {
				dia: '',
				hora: '',
				inativador: '',
				motivo: ''
			},
			showModalInativar: false,
			showInfosInativacao: false,
			loadingInfosInativacao: false,
			loadingInativacao: false,
			notification: {
				isOpen: false,
				message: ''
			},
			openModalConfirmacao:false
		};
	}

	componentDidMount() {
		const { entrada } = this.props;		
		this.setState({ isInativado: !entrada?.ativo });
	}

	componentWillUnmount() {
		this.setState({ 
			isInativado: false,
			infosInativacao: {
				dia: '',
				hora: '',
				inativador: '',
				motivo: ''
			}
		});
	}

	getDay = (data) => {
		return data ? moment(data).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")
	}
	getHour = (data) => {
		return data ? moment(data).format("HH:mm") : moment().format("HH:mm")
	}

	getInfosInativacao = async () => {
		const { prontuarioStore, entrada } = this.props
		this.setState({ loadingInfosInativacao: true })
		if (!entrada.ativo) {
			const infosInativacao = await prontuarioStore.findEntradaProntuarioInativacao(entrada.id)
			if (infosInativacao) {
				this.setState({
					infosInativacao: {
						dia: this.getDay(infosInativacao.dataInativacao),
						hora: this.getHour(infosInativacao.dataInativacao),
						inativador: infosInativacao.inativador.nome,
						motivo: infosInativacao.motivo
					}
				})
			}
		}
		this.setState({ loadingInfosInativacao: false })
	}

	handleInativar = async () => {
		const { prontuarioStore, entrada } = this.props;
		const { infosInativacao } = this.state;
		const isImage = entrada?.tipo === 'IMAGEM'
		try {
			if (
				infosInativacao.motivo?.length > 0 &&
				infosInativacao.motivo?.length < 2000
			) {
				this.setState({ loadingInativacao: true });
				if (!isImage) {
					await prontuarioStore.inativarEntradaProntuario(
						entrada.id,
						infosInativacao.motivo
					);
				} else {
					await Promise.all(entrada?.urls?.map(async (url) => {
						const response = await prontuarioStore.inativarEntradaProntuario(url.id, infosInativacao.motivo)
						return response;
					}));
				}
				this.setState({
					infosInativacao: {
						dia: this.getDay(),
						hora: this.getHour(),
						inativador: prontuarioStore.usuarioLogado.nome,
						motivo: infosInativacao.motivo,
					},
					isOpen: false,
					showModalInativar: false,
				}, async () => {
					await prontuarioStore.findAllEntradaProntuario({ withLoading: false });
				});
			}
		} catch (error) {
			const notification = {
				isOpen: true,
				message: 'Erro ao inativar o registro.'
			}
			this.showAlertMessage(notification);
		} finally {
			this.setState({ loadingInativacao: false });
		}
	};

	showAlertMessage = (notification) => {
		this.setState({ notification });

		const timeoutId = setTimeout(() => {
			this.closeAlertMessage();
			clearTimeout(timeoutId);
		}, 3000);
	};

	closeAlertMessage = () => {
		const notification = {
			isOpen: false,
			message: "",
		};
		this.setState({ notification });
	};

	handleOpenModalInativar = () => {
		this.setState({ showModalInativar: true, showInfosInativacao: false })
	}

	handleCloseModalInativar = () => {
		this.setState({ showModalInativar: false, showInfosInativacao: false })
	}

	handleChangeMotivoInativacao = (e) => {
		let infosInativacao = this.state.infosInativacao
		infosInativacao.motivo = e.target.value
		this.setState({ infosInativacao })
	}

	handleShowInfosInativacao = async () => {
		const { loadingInfosInativacao } = this.state
		if (!loadingInfosInativacao) {
			await this.getInfosInativacao()
			this.setState({ showInfosInativacao: true, showModalInativar: false, })
		}
	}

	handleCloseInfosInativacao = () => {
		this.setState({ showInfosInativacao: false, showModalInativar: false })
	}

	handleClickImage = () => {
		const { onClickImage, entrada } = this.props;

		if (typeof onClickImage === 'function') {
			onClickImage(entrada);
		}
	};

	handleClickShowMore = () => {
		const { isOpen } = this.state
		this.setState({ isOpen: !isOpen })
	}

	handleSendHuggMessage = async () => {
		const { prontuarioStore, entrada, tipo } = this.props;
		await prontuarioStore.reenviarEntradaProntuarioToHugg(entrada.id, tipo);
		this.setState({ isOpen: false });
	};

	getHuggMessage = () => {
		const { entrada, tipo } = this.props;
		const { mensagensHugg } = entrada || {};

		if (mensagensHugg === undefined) return;
		if (mensagensHugg.length === 0) return `${string.capitalize(tipo)} não enviada para o paciente.`;
		return `${string.capitalize(tipo)} enviada(o) ${mensagensHugg.length} vez(es) para o paciente.`
	}

	renderMiniatura = () => {
    const { urls } = this.props;
    return (
      <ImagePreview
        urls={urls?.map(url => url.urlMiniatura)}
        onClick={this.handleClickImage}
        containerProps={{ 
          spacing: 8, 
          padding: 8, 
          backgroundColor: 'transparent',
          borderRadius: 8,
          maxWidth: 330,
          maxHeight: 330
        }}
        imageProps={{ 
          loading: 'lazy',
          borderRadius: 8,
        }}
        Spinner={<CircularProgress size={16} />}
        DefaultImageIcon={<PictureIcon color="#FFFFFF" size={80} />}
      />
    );
	};


	render() {
		const {
			classes,
			image,
			text,
			hora,
			isRight,
			color,
			urlDownload,
			urlMiniatura,
			tipo,
			onClickImage,
			entrada,
			onClickMessage,
			isPrever,
			utilizaPlugzapi,
			handleOpenModalCompartilhar,
			...other
		} = this.props;

		const { isInativado,
			showModalInativar,
			showInfosInativacao,
			infosInativacao } = this.state;
		const isLeft = !isRight

		const className = classnames(
			classes.mensagem,
			isRight ? classes.right : '',
		);

		const showHuggOptionAndMessage = HUGG_ENTRADA_PRONTUARIO_TIPOS.includes(tipo);


		const profissionalSplittedName = entrada?.profissionalSaude?.nome?.split(' ') || "";
        const  profissionalNameWithLastName = [profissionalSplittedName[0],profissionalSplittedName[profissionalSplittedName.length - 1]].join(' ');

		return (
			<>	
				<ModalInfosInativacao
					show={showInfosInativacao}
					onClose={this.handleCloseInfosInativacao}
					infosInativacao={infosInativacao}
				/>
				{showModalInativar && <ModalInativarEntradaProntuario
					show={showModalInativar}
					onClose={this.handleCloseModalInativar}
					changeMotivo={this.handleChangeMotivoInativacao}
					inativar={this.handleInativar}
					motivo={infosInativacao.motivo}
					loadingInativacao={this.state.loadingInativacao}
					screen="Atendimento -"
				/>}
				<div
					onMouseLeave={() => this.setState({ isOpen: false })}
					className={className} {...other}>
					{isRight && !isInativado && 
						<>
							{this.state.isOpen &&
								<div className={classes.showMoreMenu}>
									<h3 onClick={() => this.handleOpenModalInativar()}>
										Inativar Registro
									</h3>
								{showHuggOptionAndMessage && isPrever && (
									<h3 onClick={this.handleSendHuggMessage}>Enviar {tipo.toLowerCase()}</h3>
								)}
								{showHuggOptionAndMessage && utilizaPlugzapi &&
									<h3 onClick={() => handleOpenModalCompartilhar(entrada)}>
										compartilhar
									</h3>
								}
								</div>
							}
							{!this.props.sujeitoAtencaoStore.isSujeitoAtencaoUnificado && <div onClick={this.handleClickShowMore} className={classes.showMore}><MoreVertIcon /></div>}
						</>
					}

					{image ?
						(isRight ? null : <img
							src={image}
							className={classes.image}
							alt={"Doutora"} />)
						: <div className={classes.imageBackground} />
					}

					{typeof text === 'string'
						? (
							<p
								className={classnames(classes[color], typeof onClickMessage === 'function' ?
									classes.pointer : "", isInativado ? classes.backgroundInativado : "")}
								onClick={!isInativado ? onClickMessage : undefined}>
								<span
									onClick={isInativado ? onClickMessage : undefined}
									className={`${classes.profissionalSaudeNome} ${isInativado ? classes.inativado : ""}`}>{
										entrada.envioExterno ? entrada?.sujeitoAtencao?.nome :  profissionalNameWithLastName
									}</span>
								{
									tipo === 'IMAGEM' && this.renderMiniatura()
								}
								{
									tipo === 'ARQUIVO'
										? (
											<a href={urlDownload} rel="noopener noreferrer" target="_blank" className={classes.linkArquivo}>
												<img src={arquivoIcon} alt={text} height="80" />
											</a>
										)
										: null
								}
								{
									tipo === "LOADING" && (
										<CircularProgress alt={text} size={72} color={'##FFFFFF'} />)
								}
								{
									tipo === "LOADING_DOCUMENTO" && (
										<CircularProgress alt={text} size={14} color={'#FFFFFF'} />
									)
								}
								<span
									onClick={isInativado ? onClickMessage : undefined}
									className={[isInativado ? classes.inativado : "", classes.textMessage]} dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br />") }} />
								{showHuggOptionAndMessage && (
									<span className={classes.huggDetails}>{this.getHuggMessage()}</span>
								)}
								<span className={`${classes.hora} ${isInativado ? classes.inativado : ""}`}>{hora}</span>
								{isInativado &&
									<span
										className={classes.mensagemInativada}
										onClick={this.handleShowInfosInativacao}
									>Para ver as informações da inativação, clique aqui</span>
								}
							</p>
						)
						: null
					}

					{image ?
						(isRight ? <img
							src={image}
							className={classes.imageRight}
							alt={"Doutora"} /> : null)
						: <div className={classes.imageBackground} />
					}
					{isLeft && !isInativado && !this.props.sujeitoAtencaoStore.isSujeitoAtencaoUnificado &&
						<>
							<div className={classes.showMore} onClick={this.handleClickShowMore}><MoreVertIcon /></div>
							{this.state.isOpen &&
								<div className={classes.showMoreMenu}>
									<h3 onClick={() => this.handleOpenModalInativar()}>Inativar Registro</h3>
									{showHuggOptionAndMessage && (
										<h3 onClick={this.handleSendHuggMessage}>Enviar {tipo.toLowerCase()}</h3>
									)}
								</div>
							}
						</>
					}
				</div>
				<Notification
					close={this.closeAlertMessage}
					reset={this.closeAlertMessage}
					isOpen={this.state.notification.isOpen}
					variant={"error"}
					message={this.state.notification.message}
				/>
			</>
		)
	}
}


MensagemAtendimento.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.string,
	selected: PropTypes.bool
};

export default withStyles(mensagemAtendimentoStyle)(MensagemAtendimento);
