import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const FilterIcon = props => {
  return (
    <svg 
      width="14" 
      height="11" 
      viewBox="0 0 
      14 11" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M9.25 9C9.66421 9 10 9.33579 10 9.75C10 10.1642 9.66421 10.5 9.25 10.5H4.75C4.33579 10.5 4 10.1642 4 9.75C4 9.33579 4.33579 9 4.75 9H9.25ZM11.25 4.75C11.6642 4.75 12 5.08579 12 5.5C12 5.91421 11.6642 6.25 11.25 6.25H2.75C2.33579 6.25 2 5.91421 2 5.5C2 5.08579 2.33579 4.75 2.75 4.75H11.25ZM13.25 0.5C13.6642 0.5 14 0.835786 14 1.25C14 1.66421 13.6642 2 13.25 2H0.75C0.335786 2 0 1.66421 0 1.25C0 0.835786 0.335786 0.5 0.75 0.5H13.25Z" fill="currentColor"/>
    </svg>
  );
};

export default FilterIcon;