import colors from '../../../../template/Colors'

export const styles = theme => ({
  container: {
    width: '100%',
    background: '#F9F9F9',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    borderCollapse: 'collapse',
    '& thead': {
      background: '#FFFFFF',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '8px',
    },

    '& th': {
      padding: '8px',
      textAlign: 'left',
    },
    '& td': {
      padding: '8px',
      textAlign: 'left',
      color: '#505050',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
    },
    '& tr>td:nth-last-child(2)': {
      textAlign: 'center',
    },
    '& tbody>tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    '& tbody>tr:last-child': {
      borderBottom: 'none',
    },
  },

  wrapperActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    '& svg': {
      cursor: 'pointer',
      padding: '8px',
      border: '1px solid transparent',
      '&:hover': {
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(0, 0, 0, 0.1)',
      },
    },

    '& >div>.ButtonCircle-root-986': {
      height: 'fit-content',
      width: 'fit-content',
    },
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    maxWidth: '200px',
    height: 35,
    color: colors.commons.gray9,
    backgroundColor: colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '8px',
    fontFamily: 'Poppins',
  },
  labelModal: {
    fontSize: '18px !important',
    color: '#505050',
    fontWeight: '600',
    textAlign: 'center',
  },
  reactSelect: {
    width: 'calc( 100% - 35px )',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 14px',

    '& > div': {
      '& > p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& > i': {
        right: '10px !important',
        top: 'calc(50% - 12px) !important',
      },
    },
  },
  inputContainer: {
    width: '60px',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '34px',

    '& > div': {
      '& > input': {
        border: 'none',
        fontSize: '14px',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
      '& > p': {
        overflow: 'hidden',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
})
