import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const CheckmarkCircleIcon = props => {
  return props.outline ? (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M23.3111 12.7071C23.7016 12.3166 23.7016 11.6834 23.3111 11.2929C22.9206 10.9024 22.2874 10.9024 21.8969 11.2929L15.104 18.0858L11.8111 14.7929C11.4206 14.4024 10.7874 14.4024 10.3969 14.7929C10.0064 15.1834 10.0064 15.8166 10.3969 16.2071L14.3969 20.2071C14.7874 20.5976 15.4206 20.5976 15.8111 20.2071L23.3111 12.7071ZM16.604 2C8.87202 2 2.604 8.26801 2.604 16C2.604 23.732 8.87202 30 16.604 30C24.336 30 30.604 23.732 30.604 16C30.604 8.26801 24.336 2 16.604 2ZM4.604 16C4.604 9.37258 9.97659 4 16.604 4C23.2314 4 28.604 9.37258 28.604 16C28.604 22.6274 23.2314 28 16.604 28C9.97659 28 4.604 22.6274 4.604 16Z'
        fill='currentColor'
      />
    </svg>
  ) : (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929C22.3166 10.9024 21.6834 10.9024 21.2929 11.2929L14.5 18.0858L11.2071 14.7929C10.8166 14.4024 10.1834 14.4024 9.79289 14.7929C9.40237 15.1834 9.40237 15.8166 9.79289 16.2071L13.7929 20.2071C14.1834 20.5976 14.8166 20.5976 15.2071 20.2071L22.7071 12.7071Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default CheckmarkCircleIcon
