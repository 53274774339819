import React from "react";
import moment from "moment";


const AgendamentosPorConvenio = ({ dadosRelatorio, dataInicial, dataFinal }) => {

  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" }

    }
    return ''
  }
  return (
    <>
      <div style={styles.titulo}>Relatório de Agendamentos por convênio</div>
      <div style={styles.subTitulo}>
        {dataInicial && (
          <div style={styles.dataInicio}>
            Data início:
            <span style={styles.subTituloSpan}>
              {moment(dataInicial).format("DD/MM/YYYY")}
            </span>
          </div>
        )}
        {dataFinal && (
          <div>
            Data fim:
            <span style={styles.subTituloSpan}>
              {moment(dataFinal).format("DD/MM/YYYY")}
            </span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.columnConvenio}>Convênio</div>
        <div style={styles.column}>Tipo</div>
        <div style={styles.column}>Quantidade</div>
      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          return (
            <div key={index} style={{ ...styles.containerColuna, ...columnColor(index) }}>
              <div style={styles.columnConvenio}>{item.descricao}</div>
              <div style={styles.column}>{item.tipo}</div>
              <div style={styles.column}>{item.quantidade}</div>
            </div>
          );
        })}
      </div>
    </>
  )
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
  },
  subTituloSpan: {
    marginLeft: "8px",
  },
  dataInicio: {
    marginRight: "16px",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  columnConvenio: {
    width: "60%",
    minWidth: "60%",
    marginBottom: "10px",
    marginRight: "3px",
  },
  column: {
    width: "20%",
  },
};

export default AgendamentosPorConvenio;