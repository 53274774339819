import classNames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "../styles";
import ListaParcelas from "./ListaParcelas";
import FooterModalFinanceiro from "./FooterModalFinanceiro";
import { TextFieldSearch } from "../../../../components/TextField";
import { applyCurrencyMask } from "../../../../utils/CurrencyMask";
import InputDateForm from "../../../../components/Input/InputDateForm";
import { ReactSelect } from "../../../../components/Select/SelectSearch";
import InputMaskCurrency from "../../../../components/Input/InputMaskCurrency";
import { TituloTipoRepeticao } from "../../../../stores/Financeiro/Extrato.store";
import MultiToggleButtons from "../../../../components/Button/MultiToggleButtons";

const tabs = {
  DISPONIVEL: 0,
  EXCLUIDO: 1,
}

const Parcelar = observer((props) => {
  const { classes, modalState, extratoStore } = props;
  const { titulo, handleChangeModal, errors } = extratoStore;

  const [parcelas, setParcelas] = useState([]);
  const [valoresFooter, setValoresFooter] = useState(0);
  const [tabSelected, setTabSelected] = useState(tabs.DISPONIVEL);

  const isParcelar = modalState.tipoRepeticao === TituloTipoRepeticao.PARCELAR;
  const disabled = (titulo?.id && extratoStore.onlyReadParcelas) || !extratoStore.editMode;

  useEffect(() => {
    getParcelas();
  }, []);

  useEffect(() => {
    verificaValoresPagos();
  }, [modalState.tituloParcelaList])

  const getParcelas = () => {
    const parcelasMaxima = 50;
    let parcelas = [];

    const listaParcelasPagas = modalState.tituloParcelaList.filter(item => item.dataPagamento);
    const quantidade = listaParcelasPagas.length > 0 ? listaParcelasPagas.length+1 : 1;

    for (let index = quantidade; index <= parcelasMaxima; index++) {
      parcelas.push({ value: index });
    }

    setParcelas(parcelas);
  };

  const verificaValoresPagos = () => {
    const valorFooter = extratoStore.calculaValoresModalFooter();

    setValoresFooter({
      valorPago: valorFooter.Pago,
      valorTotal: valorFooter.Total,
    });
  };

  const changeTabSelected = (tab) => {
    setTabSelected(tab);
  };

  return (
    <div className={classes.contentParcelar}>
      <div className={classes.contentDados}>
        <div className={classes.rowParcelar}>
          <div className={classes.contentField}>
            <div className={classes.titleField}>Entrada</div>
            <InputMaskCurrency
              value={titulo?.valorEntrada || ""}
              onChange={(e) => handleChangeModal("valorEntrada", e)}
              className={classNames(
                classes.inputValor,
                (disabled || titulo?.id) && classes.fieldDisabled
              )}
              disabled={disabled || titulo?.id}
            />
          </div>
          <div className={classNames(classes.contentField)}>
            <div className={classes.titleField}>Conta</div>
            {(disabled || titulo?.id || !titulo.valorEntrada) ? (
              <div className={classes.fieldDisabled}>
                {titulo?.contaBancaria?.nome}
              </div>
            ) : (
              <TextFieldSearch
                placeholder=""
                loadOptions={extratoStore.handleContaBancariaLoadOptions}
                withPaginate
                value={titulo?.contaBancaria}
                classes={{ root: classes.selectConta }}
                className={classes.selectConta}
                onChange={(e) => handleChangeModal("contaBancaria", e)}
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                classNotched={classes.notchedOutlineSearch}
                maxMenuHeight={200}
                isDisabled={disabled}
              />
            )}
          </div>
          <div className={classes.contentField}>
            <div className={classes.titleField}>D. Pagamento</div>
            <InputDateForm
              value={titulo?.dataPagamentoEntrada}
              onChange={(e) => handleChangeModal("dataPagamentoEntrada", e)}
              iconposition="end"
              className={classNames(
                classes.inputDate,
                (disabled || titulo?.id) && classes.inputDateDisabled
              )}
              invalidDateMessage=""
              disabled={disabled || titulo?.id || !titulo.valorEntrada}
            />
          </div>
        </div>
        <div
          className={classNames(classes.rowParcela, classes.rowMarginParcelar)}
        >
          <div className={classes.contentField}>
            <div className={classes.titleField}>Vencimento</div>
            <InputDateForm
              value={titulo?.dataVencimento}
              onChange={(e) => handleChangeModal("dataVencimento", e)}
              iconposition="end"
              className={classNames(
                classes.inputDate,
                (disabled || titulo?.id) && classes.inputDateDisabled,
              )}
              invalidDateMessage=""
              disabled={disabled}
              error={errors?.dataVencimento}
            />
          </div>
          <div
            className={classNames(classes.contentField, classes.fieldParcelas)}
          >
            <div className={classes.titleField}>Parcelas</div>
            {disabled ? (
              <div className={classes.fieldDisabled}>
                {titulo?.totalParcelas?.value || ""}
              </div>
            ) : (
              <ReactSelect
                value={titulo?.totalParcelas}
                className={classNames(
                  classes.selectParcelas,
                  errors.totalParcelas ? classes.inputError : null
                )}
                onChange={(e) => handleChangeModal("totalParcelas", e)}
                options={parcelas}
                menuPlacement={"auto"}
                getOptionLabel={(option) => option?.value}
                getOptionValue={(option) => option?.value}
              />
            )}
          </div>
          <div
            className={classNames(
              classes.contentField,
              classes.fieldValorParcelas
            )}
          >
            <div className={classes.titleField}>Valor</div>
            <div
              className={classNames(
                classes.inputValorParcelar,
              )}
            >
              {isParcelar
                ? applyCurrencyMask(modalState?.valorParcela || "")
                : ""}
            </div>
          </div>
        </div>
        <MultiToggleButtons
          options={["Parc. Disponíveis", "Parc. Excluídas"]}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
          classes={{ buttonsContainer: classes.buttonsTab }}
          disabled={!titulo.id}
          posicaoDesabilitada={[1]}
        />
        {modalState?.tituloParcelaList.length > 0 && isParcelar && (
          <ListaParcelas tipoLista={tabSelected}/>
        )}
      </div>
      <FooterModalFinanceiro
        valorTotal={valoresFooter.valorTotal}
        valorPago={valoresFooter.valorPago}
      />
    </div>
  );
});

const ParcelarWithStyles = withStyles(styles)(Parcelar);
export default inject("extratoStore")(ParcelarWithStyles);
