import React from 'react'
import { withStyles } from '@material-ui/core'
import Dialog from '../../Dialog/Dialog'
import CloseIcon from '../../Icon/Close'
import { Button } from '../../ui/Buttons'
import classNames from 'classnames'

const AlertModal = ({
  open,
  icon,
  classes,
  title,
  onClose,
  children,
  withButtons,
  buttonOptions,
  bgIconColor,
  fullWidth,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classNames(classes.paperAlert, !fullWidth && classes.paper),
      }}
    >
      <div className={classes.dialogHeader}>
        {icon && (
          <div
            style={{
              backgroundColor: bgIconColor ? bgIconColor : '#219A97',
            }}
            className={classes.wrapperIcon}
          >
            {icon}
          </div>
        )}
        <CloseIcon onClick={onClose} size={20} />
      </div>
      {title && <h5 className={classes.title}>{title}</h5>}
      {children}
      {withButtons && (
        <div className={classes.wrapperButtonsModal}>
          <Button
            kind='transparent'
            fullWidth
            isLoading={buttonOptions?.loadingCancel}
            disabled={buttonOptions?.disabledCancel}
            onClick={buttonOptions?.onCancel}
          >
            {buttonOptions?.cancelText || 'Cancelar'}
          </Button>
          <Button
            kind={buttonOptions?.kindConfirm || 'primary'}
            bgColor={buttonOptions?.bgColorConfirm}
            fullWidth
            isLoading={buttonOptions?.loadingConfirm}
            disabled={buttonOptions?.disabledConfirm}
            onClick={buttonOptions.onConfirm}
          >
            {buttonOptions?.confirmText || 'Confirmar'}
          </Button>
        </div>
      )}
    </Dialog>
  )
}

const styles = {
  paperAlert: {
    borderRadius: '12px',
    padding: '24px',
    gap: '16px',
    minWidth: '350px',
    minHeight: '150px',
  },
  paper: {
    width: '100%',
    maxWidth: '400px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',

    '& h5': {
      fontSize: '18px',
      fontWeight: '500',
      color: '#000',
      margin: 0,
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  title: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '600',
    color: '#505050',
    margin: 0,
  },
  wrapperIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: '100px',
  },
  wrapperButtonsModal: {
    display: 'flex',
    gap: '8px',
  },
}

export default withStyles(styles)(AlertModal)
