import React from 'react';
import InputDefault from './InputDefault';
import { renderMaskedInput } from './InputMaskGeneric';

const PercentilMask = (props) => {
    const maskedProps = {
      suffix: ' %',
      placeholder: ' %',
      integerLimit: 3,
      decimalLimit: 2,
      allowDecimal: true
    }

    return renderMaskedInput(maskedProps, props)
}

export const InputMaskPercentil = (props) => {
    const value = typeof props.value === 'number' ? String(props.value).replace('.', ',') : props.value;

    return <InputDefault 
        InputProps={{ inputComponent: PercentilMask }} 
        {...props}
        value={value}
    />
};
