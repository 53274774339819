import classNames from "classnames";
import React, { useReducer } from "react";
import { Drawer, withStyles } from "@material-ui/core";
import { redesSocial } from "./Constants";
import { LogoTipo } from "../../assets/img/svg";
import SideMenu from "../../components/Icon/SideMenu";
import AppStore from "../../assets/img/svg/AppStore.svg";
import GooglePlay from "../../assets/img/svg/GooglePlay.svg";

const LoginMobile = ({ classes }) => {
  const [openMenu, toggleMenu] = useReducer((state) => !state, false);

  const buttons = [
    {
      src: AppStore,
      alt: "App store",
      link: "https://apps.apple.com/br/app/app-health/id1461183704",
    },
    {
      src: GooglePlay,
      alt: "Google play",
      link:
        "https://play.google.com/store/apps/details?id=br.com.azulsystems.apphealth",
    },
  ];

  const openLink = (link) => {
    window.open(link);
  };

  return (
    <div className={classes.contentMobile}>
      <div className={classes.header}>
        <img src={LogoTipo} alt={"logo"} />

        <div
          className={classNames(
            classes.buttonSideMenu,
            openMenu && classes.menuOpened
          )}
          onClick={toggleMenu}
        >
          <SideMenu color={openMenu ? "#FFF" : "#7C7C7C"} />
        </div>
      </div>
      <Drawer
        variant="permanent"
        open={openMenu}
        anchor="right"
        classes={{
          paper: classes.drawer,
        }}
      >
        {openMenu && (
          <div className={classes.contentDrawer}>
            <div className={classes.titleDrawer}> Nossas redes </div>
            {
              <div className={classes.redesSocial}>
                {redesSocial.map((item, index) => (
                  <div
                    className={classes.redeSocialMenu}
                    onClick={() => openLink(item.link)}
                    key={index}
                  >
                    <img
                      className={classes.buttonRedeSocial}
                      src={item.icon}
                      alt={item.name}
                    />
                    {item.name}
                  </div>
                ))}
              </div>
            }
          </div>
        )}
      </Drawer>
      <div className={classes.contentInfos}>
        <p className={classes.info}>
          O sistema foi desenvolvido para desempenhar máxima performance em
          computadores, notebooks e tablets.
        </p>
        <p className={classes.info}>
          Você pode acessá-lo em seu smartphone
          <strong>baixando a versão em app</strong> totalmente atualizada e que
          te dá ainda mais controle e autonomia sobre sua clínica ou
          consultório.
        </p>

        <div className={classes.titleContentButtons}>Baixe agora mesmo</div>
        {buttons.map((item) => {
          return (
            <div className={classes.button} onClick={() => openLink(item.link)}>
              <img src={item.src} alt={item.alt} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  contentMobile: {
    height: "100vh",
  },
  contentInfos: {
    padding: "40px 16px",
    background: "#F5F5F5",
    height: "calc(100% - 144px)",
  },
  info: {
    color: "#505050",
  },
  button: {
    width: "100%",
    display: "flex",
    background: "#000000",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "16px",
  },
  titleContentButtons: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#505050",
    margin: "40px 0 8px",
  },
  header: {
    height: "48px",
    padding: "8px 27px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 2,
    position: "relative",
    boxShadow:
      "0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(38, 172, 169, 0.04), 0px 5px 25px rgba(38, 172, 169, 0.04)",
  },
  buttonSideMenu: {
    borderRadius: "100px",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuOpened: {
    background: "#333333",
  },
  drawer: {
    height: "calc(100% - 64px)",
    marginTop: "64px",
    zIndex: 1,
  },
  titleDrawer: {
    color: "#505050",
    fontWeight: 700,
    fontSize: "18px",
    padding: "40px 8px 8px 8px",
  },
  contentDrawer: {
    width: "230px",
  },
  redeSocialMenu: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "0 16px",
    color: "#505050",
    "&:hover": {
      background: "#F2F2F2",
    },
  },
};

export default withStyles(styles)(LoginMobile);
