import React, { useEffect, useState, lazy, Suspense } from 'react';

import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import { CircularProgress, withStyles } from '@material-ui/core';
import styles from '../styles';

import TabSelector from '../../../../components/TabSelector';
import tabsOptions from '../constants/tabsOptions';
import { findAllSujeitoAtencaoAudit } from '../../../../services/AuditoriaService';
import Scroll from '../../../../components/InfiniteScroll/Scroll';
import Table from '../../../../components/Table/Table';

const PacienteContent = observer(({ 
  classes, 
  isLoading, 
  onClickRow, 
  columns, 
  elements,
  loadSujeitosAtencao,
  hasMore,
  auditoriaStore,
  handleSort,
  filter
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const AuditoriaTab = lazy(() => import('../../../../components/Auditoria/Auditoria'));

  useEffect(() => {
    if (selectedTab === 1) {
      loadAudit();
    }
  }, [selectedTab]);

  const loadAudit = async () => {
    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      auditoriaTela: 'paciente',
      auditoria: `o paciente `,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    }
    
    auditoriaStore.load={
      query: findAllSujeitoAtencaoAudit, 
      variableName: "",
      variableId: "", 
    }
    await auditoriaStore.loadAuditItems();
  }

  return (
    <div className={classes.content}>
      <div className={classes.tabsArea}>
        <TabSelector
          baseColor="#F2F2F2"
          selectedColor="#FFFFFF"
          onSelect={setSelectedTab}
          tabsOptions={tabsOptions}
          selectedTabIndex={selectedTab}
        />
      </div>
      {selectedTab === 0 && (
        <Scroll
          loadMore={loadSujeitosAtencao}
          hasMore={hasMore && !isLoading}
          initialLoad={false}
          pageStart={0}
          isLoading={isLoading}
          classes={{
            root: classes.tableRoot
          }}
        >
          <Table
            columns={columns}
            dados={elements}
            handleClick={onClickRow}
            isLoading={isLoading && filter.pageNumber === 0}
            comOrdenacao
            ordenarTabela={{
              sortField: filter.sortField,
              sortDir: filter.sortDir
            }}
            handleClickOrdenar={handleSort}
          />
        </Scroll>
      )}
      {selectedTab === 1 && (
        <Suspense fallback={<CircularProgress size={32} />}>
          <AuditoriaTab
            entity='sujeitoAtencao'
            entityNameField='nome'
            withEntityName
            modificationMessageWithValue
          />
        </Suspense>
      )} 
    </div>
  );
});

const PacienteContentStyles = withStyles(styles)(PacienteContent);
const stores = ['auditoriaStore'];
export default inject(...stores)(PacienteContentStyles);