import React from "react";
import moment from "moment";
import classNames from "classnames";
import PopperCustomMenu from "../../../../../components/Popper/PopperCustomMenu";
import MoreIcon from "../../../../../components/Icon/More";
import { menuOpcoesPopper } from "./menuOpcoesPopper";
import { background, border, display, flexDirection, fontWeight, height, justifyContent, overflow, width } from "styled-system";
import string from "../../../../../utils/string";

export const getColumns = ({ functionsPopper, popperRef }) => {

  const getSituacaoStyle = (situacao) => {
    switch (situacao) {
      case "Signed":
        return styles.situacaoContratoSigned;
      case "Unsigned":
        return styles.situacaoContratoUnsigned;
      case "Cancelled":
        return styles.situacaoContratoCancelled;
      default:
        return {};
    }
  };

  const situacao = {
    Signed: "Assinado",
    Unsigned: "Não assinado",
    Cancelled: "Cancelado"
  }
  return [
    {
      Header: "Situação",
      field: "situacao",
      getValue: (data) => {
        return <div
          style={getSituacaoStyle(data.situacao)}>
          {situacao[data.situacao]}
        </div>
      },
      props: { center: "left" },
      align: "left"
    },
    {
      Header: "Nome",
      getValue: (data) => {return <div style={styles.contentNome}> {string.truncate(data?.nome || null,70)}</div>},
      field: "nome",
      props: { left: "true" },
      align: "left"
    },
    {
      Header: "Profissional",
      getValue: (data) => data.profissionalSaude?.nome || null,
      field: "profissionalSaude",
      props: { left: "true" },
      align: "left"
    },
    {
      Header: "Data de validade",
      getValue: (data) => moment(data.dataHoraValidade).format("DD/MM/YYYY"),
      field: "dataHoraValidade",
      props: { left: "true" },
      align: "left"
    },

    {
      Header: "Ações",
      getValue: (data) => {
        return <PopperCustomMenu
          ref={popperRef}
          placement={"bottom-end"}
          menuOpcoes={menuOpcoesPopper({
            data,
            functions: {
              ...functionsPopper,
              closePopper: popperRef?.current?.closePopper,
            }
          })}
          iconButton={<MoreIcon color='#505050' />}
          colorButton={{
            background: '#fff',
          }}
        />
      },
      props: { center: "true" },
      align: "left"
    },
  ];
};


const styles = {
  situacaoContratoUnsigned:{
    width: "114px",
    height:"29px",
    borderRadius:"8px",
    border:"0.5px solid  #DB7C2F",
    background:"#FCF0DF",
    fontSize:"14px",
    fontWeight:"600",
    color:"#505050",
    display:"flex",
    justifyContent:"center",
    alignItems: "center"
  },
  situacaoContratoSigned:{
    width: "114px",
    height:"29px",
    borderRadius:"8px",
    border:"0.5px solid  #007F79",
    background:"#E1F3F4",
    fontSize:"14px",
    fontWeight:"600",
    color:"#505050",
    display:"flex",
    justifyContent:"center",
    alignItems: "center"
  },
  situacaoContratoCancelled:{
    width: "114px",
    height:"29px",
    borderRadius:"8px",
    border:"0.5px solid  #BF3028",
    background:"#FCEDEF",
    fontSize:"14px",
    fontWeight:"600",
    color:"#505050",
    display:"flex",
    justifyContent:"center",
    alignItems: "center"
  },
  contentNome:{
    textWrap:"nowrap",
    textOverflow:"ellipsis",
    overflow:"hidden",
  },
};
