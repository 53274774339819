import { Switch, withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { InputForm } from "../../../components/Modal/Input";
import styles from "./MedicamentoStyle";

const InformacoesMedicamento = observer((props) => {
  const { classes, medicamentoPersonalizadoStore } = props;
  const { medicamento } = medicamentoPersonalizadoStore;

  const handleChange = (field, event) => {
    medicamentoPersonalizadoStore.medicamento[field] = field === 'ativo' ? event : event.target.value
  };

  return (
    <div className={classes.contentInformacoesMedicamento}>
      <div className={classes.contentField}>
        <div className={classes.titleField}>Nome</div>
        <InputForm
          value={medicamento.produto || ""}
          onChange={(e) => handleChange("produto", e)}
          classes={{
            input: classes.inputRetangular,
          }}
        />
      </div>
      <div className={classes.contentField}>
        <div className={classes.titleField}>Forma Farmacêutica</div>
        <InputForm
          value={medicamento.formaFarmaceutica || ""}
          onChange={(e) => handleChange("formaFarmaceutica", e)}
          classes={{
            input: classes.inputRetangular,
          }}
        />
      </div>
      <div className={classes.contentField}>
        <div className={classes.titleField}>Príncipios Ativos</div>
        <InputForm
          value={medicamento.principioAtivo || ""}
          onChange={(e) => handleChange("principioAtivo", e)}
          classes={{
            input: classes.inputRetangular,
          }}
        />
      </div>
      <div className={classes.contentField}>
        <div className={classes.titleField}>Ativo</div>
        <Switch checked={medicamento?.ativo} color="primary"
          onClick={() => handleChange("ativo", !medicamento.ativo)}
          classes={{
            root: classes.switchRoot,
            switchBase: classes.switchBase
          }}
        />
      </div>
    </div>
  );
});

const InformacoesMedicamentoWithStyle = withStyles(styles)(
  InformacoesMedicamento
);
export default inject("medicamentoPersonalizadoStore")(
  InformacoesMedicamentoWithStyle
);
