import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { Fab, Tooltip } from "@material-ui/core";
import {
  Close as CloseIcon
} from "@material-ui/icons";
import Dialog from "../../../../components/Dialog/Dialog";
import { saveAnamneseModeloByProfissionais, updateAnamneseModeloPadrao } from "../../../../services/AnamneseService";
import Notification from "../../../../components/Notification";
import {
  CaixaSelecaoIcon,
  DataIcon,
  HoraIcon,
  ListaSuspensaIcon,
  MultiplaEscolhaIcon,
  RespostaCurtaIcon,
  RespostaLongaIcon,
  DataEHoraIcon,
  NumeroIcon,
} from "../../../../assets/img/svg";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../../utils/getPageTitle";
import MultiToggleButtons from "../../../../components/Button/MultiToggleButtons";
import { tabs } from "../../../../stores/ModalConfiguracoes.store";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { findAllAnamneseModeloAuditByAnamneseModeloId } from "../../../../services/AuditoriaService";
import Auditoria from "../../../../components/Auditoria/Auditoria";
import InformacoesModeloAnamnese from "./InformacoesModeloAnamnese";
import styles from "./ModeloAnamneseStyle";
import string from "../../../../utils/string";

import ModalAnamneseHeaderOptions from "./components/ModalAnamneseHeaderOptions";
import { Button } from '../../../../components/ui/Buttons';
import { checkUserRole } from '../../../../utils/checkUserRole';

import { findProfissionalSaudeLogado } from "../../../../services/UsuarioService";

const tiposCampo = [
  { label: " Resposta curta", value: "INPUT", icon: RespostaCurtaIcon },
  { label: " Resposta longa", value: "TEXT_AREA", icon: RespostaLongaIcon },
  { label: " Multipla escolha", value: "RADIO", icon: MultiplaEscolhaIcon },
  { label: " Caixa de seleção", value: "CHECKBOX", icon: CaixaSelecaoIcon },
  { label: " Lista suspensa", value: "COMBOBOX", icon: ListaSuspensaIcon },
  { label: " Data", value: "DATE", icon: DataIcon },
  { label: " Hora", value: "TIME", icon: HoraIcon },
  { label: " Data e hora", value: "DATETIME", icon: DataEHoraIcon },
  { label: " Número", value: "NUMBER", icon: NumeroIcon },
];

const ModalModeloAnamnese = observer((props) => {
  const {
    classes,
    show,
    onClose,
    anamneseModeloSelecionado,
    loadModeloAnamneses,
    auditoriaStore,
    onChangeUnidade,
    anamneseIsPadrao,
    handleChangeSwitchPadrao,
  } = props;

  const [isAdicionarOutro, setIsAdicionarOutro] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const [dadosAnamnese, setDadosAnamnese] = useState({
    nomeModelo: "",
    statusModelo: true,
    listaPerguntas: [
      {
        pergunta: "",
        tipo: {
          label: " Resposta curta",
          value: "INPUT",
          icon: RespostaCurtaIcon,
        },
        respostas: [],
        id: 0,
      },
    ]
  });
  const [tabSelected, setTabSelected] = useState(tabs.INFORMACOES);
  const [profissionaisSaude, setProfissionaisSaude] = useState([]);
  const [unidade, setUnidade] = useState(null);
  const [userCanViewSelectors, setUserCanViewSelectors] = useState(false);

  useEffect(() => {
    if (anamneseModeloSelecionado) {
      const listaPerguntasComIsOutro = anamneseModeloSelecionado.perguntas.map(
        (item) => {
          const tipoComIcon = tiposCampo.find(
            (tipoCampo) => tipoCampo.value === item.tipo
          );
          const respostasComIsOutro = item.respostas.map((item) => {
            const resposta = item.nome === "" ? { isOutro: true } : item;
            return resposta;
          });
          return {
            ...item,
            tipo: tipoComIcon,
            respostas: respostasComIsOutro,
          };
        }
      );

      const perguntasOrdenadas = listaPerguntasComIsOutro.sort((a,b) => a.order - b.order);

      setDadosAnamnese({
        nomeModelo: anamneseModeloSelecionado.nome,
        statusModelo:  anamneseModeloSelecionado.ativo,
        listaPerguntas: perguntasOrdenadas
      })

      auditoriaStore.auditoriaProps={
        ...auditoriaStore.auditoriaProps,
        auditoria: "o modelo de anamnese",
        auditoriaTela: "anamneseModelo",
      };
    }
    checkUserPermission();
  }, []);

  const checkUserPermission = async () => {
    const userCanViewSelectors = await checkUserRole('ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS');
    setUserCanViewSelectors(userCanViewSelectors);
  }

  const onClickAdicionarPergunta = () => {
    const { listaPerguntas } = dadosAnamnese;
    const novaPerguntaPadrao = {
      pergunta: "",
      tipo: {
        label: " Resposta curta",
        value: "INPUT",
        icon: RespostaCurtaIcon,
      },
      respostas: [],
      id: listaPerguntas.length,
    };

    setDadosAnamnese({
      ...dadosAnamnese,
      listaPerguntas: [...listaPerguntas, novaPerguntaPadrao]
    });
    setIsAdicionarOutro(false);
  };

  const onSave = async() => {
    try {
      setLoading(true);

      if (contemErros()) {
        return;
      }
      const listaPerguntasSemIsOutro = dadosAnamnese.listaPerguntas.map((item, index) => {
        const respostasSemIsOutro = item.respostas.map((item) => {
          const resposta = item.isOutro ? { nome: "" } : item;

          return resposta;
        });

        return {
          pergunta: item.pergunta,
          tipo: item.tipo.value,
          respostas: respostasSemIsOutro,
          order: index
        };
      });

      const id = anamneseModeloSelecionado?.id;
      
      const anamneseModelo = {
        id,
        ativo: dadosAnamnese.statusModelo,
        nome: dadosAnamnese.nomeModelo,
        perguntas: listaPerguntasSemIsOutro,
        padrao: anamneseIsPadrao,
      };

      if (userCanViewSelectors) {
        if (profissionaisSaude[0]?.id === 0) {
          profissionaisSaude.splice(0, 1);
        }

        await saveAnamneseModeloByProfissionais(anamneseModelo, profissionaisSaude);
      } else {
        const { id } = await findProfissionalSaudeLogado();
        await saveAnamneseModeloByProfissionais(anamneseModelo, [{ id }]);
      }

      loadModeloAnamneses();
      setProfissionaisSaude([]);
      onClose();

    } catch {
      const notification = {
        isOpen: true,
        message: "Erro ao criar a anamnese.",
      };
      showAlertMessage(notification);
    } finally {
      setLoading(false);
    }
  };

  const contemErros = () => {
    const { nomeModelo, listaPerguntas } = dadosAnamnese;

    const verificaPergunta = listaPerguntas.some(
      (item) => item.pergunta === ""
    );
    const verificaResposta = listaPerguntas.filter((item) => {
      const respostaVazia = item.respostas.some((item) => item.nome === "");
      return respostaVazia;
    });

    if (string.isEmpty(nomeModelo) || verificaPergunta || verificaResposta.length > 0) {
      let errorSave = {
        nomeModelo: string.isEmpty(nomeModelo),
        pergunta: verificaPergunta,
        resposta: verificaResposta.length > 0,
      };

      let mensagemComErro = "Campos inválidos: ";

      mensagemComErro += errorSave.nomeModelo ? "nome do modelo, " : "";
      mensagemComErro += errorSave.pergunta ? "pergunta, " : "";
      mensagemComErro += errorSave.resposta ? "resposta" : "";

      const notification = {
        isOpen: true,
        message: mensagemComErro,
      };
      showAlertMessage(notification);
      return true;
    }
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };

  const getPageTitle = () => {
    const acao = !!anamneseModeloSelecionado?.id ? "Editar" : "Nova"

    return pageConfiguracoes(`${acao} anamnese`)
  };

  const changeTabSelected = async(tabSelected) => {
    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    }

    if(tabSelected === 1) {
        auditoriaStore.load={
            query: findAllAnamneseModeloAuditByAnamneseModeloId, 
            variableName: "anamneseModeloId",
            variableId: anamneseModeloSelecionado.id, 
        }
        await auditoriaStore.loadAuditItems();
    }

    setTabSelected(tabSelected);
  };

  const renderTab = () => {
    return tabSelected === 0 ? 
      <InformacoesModeloAnamnese
        setDadosAnamnese={setDadosAnamnese}
        dadosAnamnese={dadosAnamnese}
        isAdicionarOutro={isAdicionarOutro}
      /> 
      : 
      <div className={classes.contentAuditoria}>
        <Auditoria />
      </div>
  };

  const handleProfissionaisSelected = (profissionaisSelected) => {
    setProfissionaisSaude(profissionaisSelected);
  }

  const handleChangeUnidade = async (unidade, profissionalSaudeAtual, initialLoad) => {
    await onChangeUnidade(unidade.id, profissionalSaudeAtual.id, initialLoad);
    setUnidade(unidade);
  }

  const handleChangeStatus = () => {
    const dadosAnamneseCopy = { ...dadosAnamnese, statusModelo: !dadosAnamnese.statusModelo };
    setDadosAnamnese(dadosAnamneseCopy);
  }
  

  const isEditar = !!anamneseModeloSelecionado?.id;

  return (
    <>
    <PageTitle title={getPageTitle()}/>
    <Dialog
      open={show}
      classes={{
        paper: classes.paper,
      }}
      maxWidth="md"
      fullWidth
      disablePortal
    >
      <div className={classes.tituloHeader}>
        <span className={classes.titulo}>
          { isEditar ? "Editar " : "Nova "} anamnese
        </span>
        <ModalAnamneseHeaderOptions
          anamneseModeloSelecionado={anamneseModeloSelecionado}
          profissionaisSelected={profissionaisSaude}
          unidade={unidade}
          handleSelectProfissionais={handleProfissionaisSelected}
          handleSelectUnidade={handleChangeUnidade}
          openNotification={showAlertMessage}
          status={dadosAnamnese.statusModelo}
          handleChangeStatus={handleChangeStatus}
          userCanViewSelectors={userCanViewSelectors}
          isAnamnesePadrao={anamneseIsPadrao}
          handleChangeSwitchPadrao={handleChangeSwitchPadrao}
        />
        <Fab className={classes.buttonActionClose} onClick={onClose}>
          <CloseIcon />
        </Fab>
      </div>
      <MultiToggleButtons
        options={["Informações", "Auditoria"]}
        tabSelected={tabSelected}
        changeTabSelected={changeTabSelected}
        classes={{ buttonsContainer: classes.buttonsTab }}
        disabled={!anamneseModeloSelecionado?.id}
        posicaoDesabilitada={[1]}
      />
      { renderTab() }
      <div className={classes.footer}>
        <Tooltip title="Adicionar nova pergunta" placement="left">
          <Button
            onClick={() => onClickAdicionarPergunta()}
            bgColor="#707C97"
            disabled={loading}
          >
            + Campo
          </Button>
        </Tooltip>

        <Button onClick={onSave} disabled={loading} isLoading={loading}>
          { isEditar ? "Salvar alterações" : "Salvar "} 
        </Button>
      </div>
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </Dialog>
    </>
  );
});

const ModalModeloAnamneseWithStyles = withStyles(styles)(ModalModeloAnamnese);
export default inject("auditoriaStore")(ModalModeloAnamneseWithStyles);
