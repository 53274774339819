const getTipoImpressao = (tipo) => {
    switch (tipo) {
        case 'SIMPLES':
            return "RECEITA_SIMPLES"
        case 'CONTROLE_ESPECIAL':
            return "RECEITA_CONTROLADA"
        case 'OFTALMOLOGICA':
            return "RECEITA_OFTALMOLOGICA"
        default:
            return "OUTROS"
    }
}

export default getTipoImpressao