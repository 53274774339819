const styles = {
    paper: {
        maxWidth: '350px',
        padding: '35px 35px 33px 35px',
        height: '100%',
        maxHeight: '510px',
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    infoHeader: {
        color: '#505050',
        fontSize: '14px',
    },
    contentTipoDente: {
        marginTop: '16px',
    },
    titleTipo: {
        color: '#868686',
        fontSize: '12px',
    },
    tipoDentes: {
        height: '16px',
        "& label": {
            height: '16px'
        },
    },
    multiToggleButtons: {
        margin: '16px 0',
    },
    contentButtons: {
        display: 'flex',
        flexDirection: 'row',
    }
};

export default styles;