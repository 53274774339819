import { Dialog, IconButton, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import CloseIcon from "../../../../../../components/Icon/Close";
import ButtonLong from "../../../../../../components/Button/ButtonLong";
import { assinarDocumentoPlugssign, findAllDocumentosModelo, updateContrato } from "../../../../../../services/ContratoService";

import ContratoForm from "../../../../../../components/ContratoForm/ContratoForm";
import PreencherDocumento from "../../../../../Configuracoes/ModelosDocumento/Documento/PreencherDocumento";
import { findAllDocumentoModelo } from "../../../../../../services/DocumentoService";

let page = {
  pageNumber: 0,
  pageSize: 9999
};

const ModalContrato = observer(({ classes,
   open, 
   onClose, 
   readOnly,
   modeloDocumento,
   config,
   onPageChange,
   handleChange,
   handleProcedimentos,
   handleDeleteProcedimentos,
   loadContratos,
   history,
  }) => {
  
  const [options, setOptions]=useState([])
  const [profissional, setProfissional]=useState(null)
  const [search, setSearch]=useState("")
  const [documentos, setDocumentos]=useState([])


  useEffect(() => {
    findAllDocumentos()
  }, [])

  const findAllDocumentos =async ()=>{
    const variables={
      profissionalSaudeId:profissional,
      search,
      ativo:true,
      pageableDto:{
        pageNumber:0,
        pageSize:30,
        sortDir:"ASC",
        sortField:"nome"
      },
      tipo:"ASSINATURA"
    }    
    const optionTipo =  await findAllDocumentosModelo(variables)
    setOptions(optionTipo)
}
  
  const getDocumentosModelo = async({tipo})=>{
    try{
       const response = await findAllDocumentoModelo( {
           ativo: true,
           search: "",
           pageableDTO: {
               ...page,
               sortDir: 'ASC', 
               sortField: 'nome',
           } ,
           tipo:tipo,
       })
      setDocumentos(response.content)
       }catch(error){
      console.log(error)
    }
   }


  const setOptionsModelo=(e)=>{
    setOptions(e)
  }

  return (
    <Dialog
      open={open}
      >
        {<PreencherDocumento
        handleClose={()=>onClose()} 
         open={open} 
         documentoAssinatura
         setOptions={setOptionsModelo}
         options={options}
         readOnly={readOnly}
         modeloDocumento={modeloDocumento}
         config={config}
         onPageChange={onPageChange}
         handleChange={handleChange}
         handleProcedimentos={handleProcedimentos}
         handleDeleteProcedimentos={handleDeleteProcedimentos}
         getDocumentosModelo={getDocumentosModelo}
         loadContratos={loadContratos}
         isTelaPaciente
         history={history}
        />}
    </Dialog>
  )
})

const styles = () => ({
  container: {
    width: "450px",
    height: "600px",
    maxHeight: "calc(100% - 20px)"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "84px",
    alignItems: "center",
    padding: "0px 24px 0px 24px"
  },
  title: {
    color: "#000000",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "21px"
  },
  titleInput: {
    color: "#868686",
    fontSize: "11px !important"
  },
  iconClose: {
    color: "#000000",
    backgroundColor: "#ffff !important"
  },
  containerContent: {
    boxSizing: "border-box",
    height: "calc(100% - 84px)",
    backgroundColor: "#f9f9f9",
    padding: "16px 24px 10px 24px",
  },

  containerInput: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    textAlign: "left !important"
  },

  inputInfos: {
    color: "#505050",
    background: "#F2F2F2",
    border: "0 !important",
    borderRadius: "8px",
    height: "30px",
    boxSizing: "border-box",
    fontSize: "14px"
  },
  reactSelect: {
    color: "#505050",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    fontSize: "12px !important",

  },
  notchedOutline: {
    border: '0',
  },
  menuHeight: {
    maxHeight: '25vh',
    '&> div': {
      maxHeight: '25vh',
    },
  },
  classIcons: {
    fontSize: 24,
    padding: "0px"
  },
  inputDate: {
    textAlign: "left !important",
    fontSize: "14px"
  },
  listaProcedimentosSelecionados: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  procedimentoOption: {
    background: "#F2F2F2",
    border: "0.5px solid #BDBDBD",
    borderRadius: "100px",
    minWidth: "80px",
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
    padding: "4px 8px",
    margin: "3px",
    alignItems: "center",
    "& svg": {
      color: "#868686",
      marginLeft: "5px",
      fontSize: "13px",
    },
  },
  buttonEnviar: {
    width: "fit-content",
    padding: "2px 10px",
    boxSizing: "border-box"
  },
  buttonContainer: {
    height: "10%",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0px 10px",
  }
});

const stores = ['atendimentoStore']
const ModalContratoStyles = withStyles(styles)(ModalContrato)
export default inject(...stores)(ModalContratoStyles)

