import React from "react";
import { withStyles } from "@material-ui/core";
import { observer } from "mobx-react";

const styles = {
    iconButton: {
        color: 'white',
    },
}

@observer
class ButtonOptions extends React.Component {
    render() {
        const { classes, buttons } = this.props;
        return (
            <div className={classes.content}>
                { buttons("hiddenLabel") }
            </div>
        )
    }
}

export default withStyles(styles)(ButtonOptions);
