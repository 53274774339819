import React from 'react'
import moment from 'moment'

import ImpressaoHtml from '../../../../../../../components/Impressao/ImpressaoHtml'
import AnamnesePdfDocument from '../../../../../../../template/pdf/anamnese/AnamnesePdfDocument'

const PrintAnamnese = ({ sujeitoAtencao, anamnese, perguntas, isPrint, handlePrint }) => {
  const formateDates = (type, value) => {
    switch (type) {
      case 'DATE':
        return moment(value).format('DD/MM/YYYY')
      case 'DATETIME':
        return moment(value).format('DD/MM/YYYY HH:mm')
      default:
        return value
    }
  }

  const getDadosToPrint = () => {
    const perguntasFormatadas = perguntas.map(pergunta => {
      const respostas = pergunta?.respostas.map(resposta => {
        return {
          ...resposta,
          ...(pergunta?.tipo === 'DATE' ||
          pergunta?.tipo === 'DATETIME' ||
          pergunta?.tipo === 'TIME'
            ? {
                valor: formateDates(pergunta?.tipo, resposta?.valor),
              }
            : {
                valor: resposta?.valor,
              }),
        }
      })
      return {
        ...pergunta,
        respostas,
      }
    })
    const dadosParaImpressao = {
      id: anamnese?.id,
      nome: anamnese?.nome,
      perguntas: perguntasFormatadas,
    }
    return dadosParaImpressao
  }

  if (!isPrint || !sujeitoAtencao) return null
  return (
    <ImpressaoHtml
      isPrintMustache={isPrint}
      sujeitoAtencaoId={sujeitoAtencao?.id}
      handlePrintMustache={handlePrint}
      htmlStringComponent={
        <AnamnesePdfDocument dadosAnamnese={getDadosToPrint()} sujeitoAtencao={sujeitoAtencao} />
      }
    />
  )
}

export default PrintAnamnese
