import React, { useState } from "react";
import { inject } from "mobx-react";
import { withStyles } from '@material-ui/core/styles';
import {
  Fade,
  Popper,
  MenuItem,
  ClickAwayListener,
  Paper,
  IconButton,
} from "@material-ui/core";
import {
  MoreHoriz as MoreHorizIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import { ativarCentroCusto, inativarCentroCusto } from "../../../services/FinanceiroService";

const ButtonMore = (props) => {
  const { classes, centroDeCusto, centroDeCustoStore } = props;

  const [openMore, setOpenMore ] = useState(false);
  const [anchorEl, setAnchorEl ] = useState(null);
  const [placement, setPlacement ] = useState(null);

  const handleClickMore = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMore((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleCloseMore = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setOpenMore(false);
  };

  const handleAcao = async acao => {
    const statusCentroCusto = acao === "ativar" ? await ativarCentroCusto(centroDeCusto.id) : await inativarCentroCusto(centroDeCusto.id);
    const listaAlterado = centroDeCustoStore.centroCustoList.map(item => {
      return item.id === centroDeCusto.id ? { ...item, ativo: statusCentroCusto.ativo} : item;
    })
    centroDeCustoStore.centroCustoList = listaAlterado;
    setOpenMore(false);
  }

  return (
    <>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={handleClickMore("left")}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={openMore}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.paperBotaoMore}>
              <ClickAwayListener onClickAway={handleCloseMore}>
                {centroDeCusto.ativo ? (
                  <MenuItem
                    className={classes.menuMore}
                    onClick={() => handleAcao("inativar")}
                  >
                    <VisibilityOffIcon className={classes.iconMenuMore} />
                    Desativar
                  </MenuItem>
                ) : (
                  <MenuItem className={classes.menuMore} onClick={() => handleAcao("ativar")}>
                    <VisibilityIcon className={classes.iconMenuMore} />
                    Ativar
                </MenuItem>
                )
                }
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

const styles = {
  menuMore: {
    color: '#505050',
    fontSize: 16,
    width: 140,
  },
  iconMenuMore:{
    marginRight: 10,
  },
};

const ButtonMoreWithStyles = withStyles(styles)(ButtonMore);
export default inject("centroDeCustoStore")(ButtonMoreWithStyles);
