import {action, observable} from 'mobx';

import InterceptorUtil from "../utils/InterceptorUtil"

import Api, { AuthApi } from "../config/api";
import localStorageService, {
    ACCESS_TOKEN_KEY,
    EXPIRES_IN,
    getAuthLocal,
    REDES_KEY,
    REFRESH_TOKEN_KEY,
    USUARIO_LOGADO_KEY
} from '../services/storage';
import BaseStore from "./Base.store";
import { db } from '../config/config';
import moment from 'moment';

export default class LoginStore extends BaseStore {
    interceptorApi = null;
    interceptorAuthApi = null;
    interceptorChatApi = null;

    filtroHeaderStore = null;
    usuarioStore = null;
    socketStore = null;

    @observable accessToken = null;
    @observable refreshToken = null;
    @observable timer = null;

    constructor(rootStore) {
        super();
        this.usuarioStore = rootStore.usuarioStore;
        this.socketStore = rootStore.socketStore;
        this.localLogin();
    }

    /**
     * Handle login error
     * @param response
     */
    @action loginError(response) {
        this.accessToken = null;
        this.refreshToken = null;
        this.error = response;
        this.openNotification('Usuário inexistente ou senha inválida', 'error');
    }

    @action getUser() {
        return AuthApi.post('graphql',
            {
                query: `{
                    obterUsuarioLogado {
                        id
                        nome
                        login
                        fotoPerfil
                        authorities {
                            authority
                        }
                    }
                    obterRedesUsuarioLogado {
                      nome
                      unidades {
                        id
                        nome
                        dataVencimento
                      }
                      unidadesVencidas{
                        id
                        nome
                        dataVencimento
                      }
                    }
                  }`
            }
        ).then(async (response) => {
            const usuarioLogado = response.data.data.obterUsuarioLogado;
            const redes = response.data.data.obterRedesUsuarioLogado;
            await localStorageService.set(REDES_KEY, redes);
            await localStorageService.set(USUARIO_LOGADO_KEY, usuarioLogado);
            return response;
        })
    }

    /**
     * Try to connect user from local storage
     */
    async localLogin(callback=null) {
        const {accessToken, refreshToken, usuarioLogado} = getAuthLocal();

        if (accessToken && refreshToken && usuarioLogado && usuarioLogado.id) {
            this.usuarioStore.isAuthenticated = true;
            await this.saveTokens({access_token: accessToken, refresh_token: refreshToken});

            if (callback) {
                await callback();
            }
        }
    }


    @action
    async logout({ history, chatStore }) {
        const { socket } = this.socketStore;
        const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
        await AuthApi.get(`logout?token=${accessToken}`);
        await localStorageService.removeAll();
        await db.remove('chatWindows');
        socket && this.socketStore.disconnect();
        chatStore.logout();

        if (this.filtroHeaderStore) {
            this.filtroHeaderStore.unidade = 0;
            this.filtroHeaderStore.profissionalSaude = 0;
        }

        if (this.usuarioStore) {
            this.usuarioStore.isAuthenticated = false;
        }

        clearInterval(this.timer);

        history.replace("/login");
    }

    verificaTokenExpirado = async(expires_in) => {
        this.timer = expires_in && setInterval(async() => {
            const expireToken = await localStorageService.get(EXPIRES_IN);
            const isTokenExpirado = expireToken && moment().isAfter(moment(expireToken));
            if(isTokenExpirado) {
                this.usuarioStore.isAuthenticated = false;
                window.location.replace('#/login');
                clearInterval(this.timer);
            }
        }, expires_in*1000);
    };

    @action
    async saveTokens(params) {
        const {access_token, refresh_token, expires_in} = params;

        Api.interceptors.request.eject(this.interceptorApi);
        AuthApi.interceptors.request.eject(this.interceptorAuthApi);

        const dataExpires = moment().add(expires_in, 'seconds')

        await localStorageService.set(ACCESS_TOKEN_KEY, access_token);
        expires_in && await localStorageService.set(EXPIRES_IN, dataExpires.format("YYYY-MM-DD HH:mm:ss"));
        await localStorageService.set(REFRESH_TOKEN_KEY, refresh_token);

        this.verificaTokenExpirado(expires_in);
        this.accessToken = access_token;
        this.refreshToken = refresh_token;
        this.error = null;

        const _setHeader = (config) => {
            config.headers.post['Authorization'] = `bearer ${access_token}`;
            config.headers.get['Authorization'] = `bearer ${access_token}`;
            return config;
        };

        if (this.socketStore.ChatApi && this.socketStore.chatUrl) {
            this.socketStore.ChatApi.interceptors.request.eject(this.interceptorChatApi);
            this.interceptorChatApi = this.socketStore.ChatApi.interceptors.request.use(_setHeader);
            InterceptorUtil.setInterceptor(this.interceptorChatApi);
            if (!this.socketStore.isApiInitialized) {
                this.socketStore.isApiInitialized = true;
            }
        }

        this.interceptorApi = Api.interceptors.request.use(_setHeader);
        this.interceptorAuthApi = AuthApi.interceptors.request.use(_setHeader);
        
        InterceptorUtil.setInterceptor(this.interceptorAuthApi);
        InterceptorUtil.setInterceptor(this.interceptorApi);
    }
    
    @action authenticateChat = async () => {
        const access_token = await localStorageService.get(ACCESS_TOKEN_KEY);
        const _setHeader = (config) => {
            config.headers.post['Authorization'] = `bearer ${access_token}`;
            config.headers.get['Authorization'] = `bearer ${access_token}`;
            return config;
        };
        await this.socketStore.initializeApi();
        if (!!(this.socketStore.ChatApi && this.socketStore.chatUrl)) {
            this.socketStore.ChatApi.interceptors.request.eject(this.interceptorChatApi);
            this.interceptorChatApi = this.socketStore.ChatApi.interceptors.request.use(_setHeader);
            InterceptorUtil.setInterceptor(this.interceptorChatApi);
            if (!this.socketStore.isApiInitialized) {
                this.socketStore.isApiInitialized = true;
            }
        }
    }
}
