import React from 'react'

import {withStyles} from "@material-ui/core/styles/index";


const styles = theme => ({
    root: {
        display: 'flex',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    },

    item: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: '#F2F2F2',
        padding: '16px 18px',
    },

    info: {
        color: '#505050',
        fontSize: '12px',
        fontWeight: '600'
    }
});


const WeekHeader = withStyles(styles)((props) => {
    const {classes, text} = props;

    return (
        <div className={classes.item}>
            <span className={classes.info}>{text}</span>
        </div>
    )
});


const Header = (props) => {
    const {classes, items} = props;

    return (
        <div className={classes.root}>
            {items.map((item) => (
                <WeekHeader key={item.name} text={item.name.toUpperCase()}/>
            ))}
        </div>
    )
};


export default withStyles(styles)(Header)
