export default theme => ({
    contentConvenioList: {
        height: 'calc(100% - 70px)',
    },
    contentFieldHeader: {
        width: "100%",
    },
    notchedOutline: {
        border: 'none',
    },
    renderHeaderSelect: {
        "&>div>div": {
            fontSize: '14px',
            height: '38px',
            background: '#f2f2f2',
            borderRadius: '8px',
            border: '0.5px solid rgba(220, 220, 220, 0.2)',
        },
    },
    content: {
        background: "#F5F5F5",
        "& #buttonCircle": {
            boxShadow: 'none',
            background: "#fff",
            color: "#5F6368",
        },
    },
    contentModal: {
        height: "calc(100% - 214px)",
    },
    contentModalEdit: {
        height: "calc(100% - 256px)",
    },
    contentList: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
        padding: "24px 24px 0",
        gap: '16px',
        height: "calc(100% - 104px)",   
    },
    table: {
        "&>div": {
            height: 'calc(100vh - 240px)',
            overflow: 'auto',
        },
        "& thead th": {
            textAlign: "left",
            top: 0,
            position: "sticky",
            zIndex: 1,
        },
    },
    notFoundContainer: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
    },
    buttonCriar: {
        position: 'absolute',
        bottom: 88,
        right: 37,
        zIndex: 100
    },
    paperAlert: {
        maxWidth: "373px",
    },
    rowHeader: {
        gap: "8px",
        marginTop: "8px",
        display: 'grid',
        gridTemplateColumns: '49.5% 48.75%'
    },
    titleRenderHeader: {
        color: "#868686",
        fontSize: "12px",
    },
    renderHeaderInput: {
        "&>div": {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 600,
            color: '#868686',
            "&>div": {
                height: '38px',
                background: '#f2f2f2',
                borderRadius: '8px',
                border: '0.5px solid rgba(220, 220, 220, 0.2)',
            },
        },
        "& p": {
            fontWeight: '600',
            color: '#868686'
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: "16px",
    },
    contentNome: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textField: {
        height: "40px",
        width: "100%",
        "& input": {
          height: "23px",
          background: "#F2F2F2",
          border: "0.5px solid rgba(220, 220, 220, 0.2)",
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 600,
          color: '#868686',
        },
        "& ::placeholder": {
          color: '#000'
        },
    },
    notchedOutlineTextField: {
        "& fieldset": {
            border: "none",
        },
    },
});