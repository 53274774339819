import React from "react";
import getCamposModificados from "../../utils/getCamposModificados";

const CamposModificados = ({ revtype, modificacao, auditoriaTela, modificationMessageWithValue, auditChangesValues }) => {

  const camposModificados =
  revtype === "UPDATE"
    ? getCamposModificados(modificacao, auditoriaTela, modificationMessageWithValue, auditChangesValues)
    : [];

  return <>
      {
          camposModificados.map(
              (campo, index) => {
                return <li key={index}>{campo}</li>;
              }
          )
      }
  </>
};

export default CamposModificados;