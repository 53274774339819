import { action, observable } from 'mobx';
import moment from 'moment';
import {
  createDadosBraspagAnexo, createDadosBraspagProfissional,
  findAnexosBraspagProfissionalSaudeLogado,
  findByDadosBraspagProfissionalSaudeLogado,
} from '../services/ProfissionalSaudeBraspagService';
import string from '../utils/string';
import { uploadArquivos } from '../utils/uploadArquivo';
import localStorageService, { ACCESS_TOKEN_KEY } from "../services/storage";
import {getStatusBraspagByDadosIugu} from "../utils/dadosIugu";

const profissionalSaudeDadosBraspagDefault = {
  dadoBancario: {
    banco: null,
    agencia: '',
    digitoAgencia: null,
    conta: '',
    digitoVerificador: null,
    tipoConta: 'CONTA_CORRENTE',
    documento: '',
  },
  endereco: {
    bairro: '',
    cep: '',
    complemento: '',
    estado: null,
    municipio: null,
    nomeLogradouro: '',
    numero: '',
  },
  id: '',
  documentoTipo: 'CNPJ',
  nomeFantasia: '',
  razaoSocial: '',
  dadosBraspagAnexo: [],
  status: null,
};

const formErrorsDefault = {
  banco: false,
  agencia: false,
  digitoAgencia: false,
  conta: false,
  digitoVerificador: false,
  documento: false,
  nomeFantasia: false,
  razaoSocial: false,
  cep: false,
  bairro: false,
  nomeLogradouro: false,
  numero: false,
  municipio: false,
  estado: false,
  dadosBraspagAnexo: false,
}

export default class ProfissionaSaudeBraspagStore {
  profissionalSaudeStore = null;
  @observable profissionalSaudeDadosBraspag = profissionalSaudeDadosBraspagDefault;
  @observable selectedTab = 0;
  @observable documentsTabEnabled = true;
  @observable notification = {
    open: false,
    message: '',
    variant: '',
  }
  @observable accessToken = null;
  @observable formErrors = formErrorsDefault;
  @observable saving = false;
  @observable dadosBancariosHasError = false;
  @observable loading = true;

  constructor(rootStore) {
    this.profissionalSaudeStore = rootStore.profissionalSaudeStore;
    this.getAccessToke()
  }

  getAccessToke = async () => {
    this.accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
  }
  @action updateBraspag = async () => {
    try {
      if (this.formHasErrors()) {
        throw new Error('Preencha todos os campos obrigatórios.');
      }
      this.saving = true;

      const {dadoBancario, documentoTipo, endereco, responsavelTelefone, dadosBraspagAnexo, id} = this.profissionalSaudeDadosBraspag;
      const {profissionalSaude} = this.profissionalSaudeStore;

      let profissionalSaudeDadosBraspag = {
        ...this.profissionalSaudeDadosBraspag,
        documento: documentoTipo === 'CPF' ?
        profissionalSaude.cpf :
        string.removeSpecialChars(dadoBancario.documento),
        dataNascimento: moment(profissionalSaude.dataNascimento).format('YYYY-MM-DD'),
        dadoBancario: {
          ...dadoBancario,
          digitoAgencia: dadoBancario.digitoAgencia || null,
          digitoVerificador: dadoBancario?.digitoVerificador || null,
          documento: documentoTipo === 'CPF' ?
            profissionalSaude.cpf :
            string.removeSpecialChars(dadoBancario.documento),
        },
        endereco: {
          ...endereco,
          municipio: {
            id: endereco?.municipio?.value,
            nome: endereco?.municipio?.label
          },
          estado: {
            id: endereco?.estado?.value,
            nome: endereco?.estado?.label
          }
        },
        responsavelTelefone: string.removeSpecialChars(responsavelTelefone),
        dadosBraspagAnexo: dadosBraspagAnexo.flatMap((item) => { 
          return {
          arquivoTipo: item.arquivoTipo,
          documentoTipo: item.documentoTipo,
        }}),
        id: id || null,
      };

      delete profissionalSaudeDadosBraspag.perfilPublico;

      const response = await createDadosBraspagProfissional(profissionalSaudeDadosBraspag);
      const data = response?.data?.data?.createDadosBraspagProfissionalSaude;
      if (data?.id) {
        return data;
      } else {
        throw new Error('Falha ao atualizar dados bancários do profissional de saúde.');

      }
    } finally {
      this.saving = false;
    }
  };

  @action findByProfissionalSaudeBraspag = async () => {
    try {
      this.loading = true;
      const response = await findByDadosBraspagProfissionalSaudeLogado();
      const data = response.data?.data?.findByDadosBraspagProfissionalSaudeLogado;
      const dadosIugu = data?.perfilPublico?.dadosIugu;

      if (data?.id) {
        const profissionalSaudeDadosBraspag = response.data.data.findByDadosBraspagProfissionalSaudeLogado;
        this.profissionalSaudeDadosBraspag = {
          ...this.profissionalSaudeDadosBraspag,
          ...profissionalSaudeDadosBraspag,
          documentoTipo: profissionalSaudeDadosBraspag?.documentoTipo || 'CNPJ',
          dadoBancario: profissionalSaudeDadosBraspag?.dadoBancario ? 
            profissionalSaudeDadosBraspag?.dadoBancario : 
            this.profissionalSaudeDadosBraspag.dadoBancario,
          endereco: profissionalSaudeDadosBraspag?.endereco ? 
          profissionalSaudeDadosBraspag?.endereco :
          this.profissionalSaudeDadosBraspag.endereco,
          status: getStatusBraspagByDadosIugu(dadosIugu)
        }
        this.documentsTabEnabled = false;
      }
      this.loading = false;
      return response?.data?.data?.findByDadosBraspagProfissionalSaudeLogado
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action findAnexosByProfissionalSaudeBraspag = async () => {
    try{
      const response = await findAnexosBraspagProfissionalSaudeLogado();
      const {dadosBraspagAnexo} = response.data.data.findByDadosBraspagProfissionalSaudeLogado;
      this.profissionalSaudeDadosBraspag = {
        ...this.profissionalSaudeDadosBraspag,
        dadosBraspagAnexo,
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  @action uploadProfisisonalSaudeBraspagAnexo = async (file, documentoTipo) => {
    const fileData = await this.prepareFileToSend(file);
    const anexo = {
      amazonS3Objeto: {
        extensao: fileData.extensao,
        nome: fileData.nome,
        tamanho: fileData.tamanho,
      },
      arquivoTipo: fileData.image ? 'IMAGEM' : 'ARQUIVO', 
      documentoTipo,
      profissionalSaudeDadosBraspag: {
        id: this.profissionalSaudeDadosBraspag.id,
      },
      dataHoraLancamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
    };

    const arquivo = {
      base64: fileData.base64,
      extensao: fileData.extensao,
      image: fileData.image,
      nome: fileData.nome
    }

    const response = await createDadosBraspagAnexo(anexo, arquivo);
    if (!response?.data?.data?.createDadosBraspagAnexo?.id) {
      throw new Error(`Falha ao enviar o arquivo ${fileData.nome}`);
    }
  };

  formHasErrors = () => {
    this.formErrors = {
      banco: !this.profissionalSaudeDadosBraspag.dadoBancario.banco,
      agencia: this.profissionalSaudeDadosBraspag.dadoBancario.agencia?.length === 0,
      conta: this.profissionalSaudeDadosBraspag.dadoBancario.conta?.length === 0,
      documento: this.profissionalSaudeDadosBraspag.documentoTipo === 'CNPJ' &&
        (this.profissionalSaudeDadosBraspag?.documento?.length === 0 || !this.profissionalSaudeDadosBraspag?.documento),
      nomeFantasia: this.profissionalSaudeDadosBraspag.documentoTipo === 'CNPJ' && 
        (this.profissionalSaudeDadosBraspag?.nomeFantasia?.length === 0 || !this.profissionalSaudeDadosBraspag?.nomeFantasia),
      razaoSocial: this.profissionalSaudeDadosBraspag.documentoTipo === 'CNPJ' && 
        (this.profissionalSaudeDadosBraspag?.razaoSocial?.length === 0 || !this.profissionalSaudeDadosBraspag?.razaoSocial),
      cep: this.profissionalSaudeDadosBraspag?.endereco?.cep?.length === 0 ,
      nomeLogradouro: this.profissionalSaudeDadosBraspag?.endereco?.nomeLogradouro?.length === 0,
      numero: this.profissionalSaudeDadosBraspag?.endereco?.numero?.length === 0,
      bairro: this.profissionalSaudeDadosBraspag?.endereco?.bairro?.length === 0,
      estado: !this.profissionalSaudeDadosBraspag?.endereco?.estado,
      municipio: !this.profissionalSaudeDadosBraspag?.endereco?.municipio,
      responsavelNome: !this.profissionalSaudeDadosBraspag?.responsavelNome,
      responsavelTelefone: !string.validaTelefone(this.profissionalSaudeDadosBraspag?.responsavelTelefone),
      email: !this.profissionalSaudeDadosBraspag?.email,
      dadosBraspagAnexo: !this.profissionalSaudeDadosBraspag.documentoTipo === 'CNPJ' && !this.profissionalSaudeDadosBraspag?.dadosBraspagAnexo?.some((item) => item.documentoTipo === 'PROOF_BUSINESS_ACTIVITY'),
    }

    const fields = Object.keys(this.formErrors);
    return fields.some((field) => this.formErrors[field]);
  };

  validateDadosBancarios = () => {
    const {dadoBancario} = this.profissionalSaudeDadosBraspag || {}

    this.formErrors = {
      ...this.formErrors,
      banco: !dadoBancario.banco,
      agencia: dadoBancario.agencia?.length === 0,
      conta: dadoBancario.conta?.length === 0,
    }

    this.dadosBancariosHasError = this.formErrors.banco ||
      this.formErrors.agencia ||
      this.formErrors.digitoAgencia ||
      this.formErrors.conta || 
      this.formErrors.digitoVerificador;
  }

  prepareFileToSend = async (file) => {
    const listaArquivos = await uploadArquivos([file]);
    return listaArquivos[0];
  }

  openNotification = (open, message, variant) => {
    this.notification = {
      open,
      message,
      variant
    }
  };

  closeNotification = () => {
    this.notification = {
      open: false,
      message: '',
      variant: ''
    }
  }
};