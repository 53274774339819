import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ThumbsDownIcon = props => {
  return (
    <svg 
      width="19" 
      height="22" 
      viewBox="0 0 19 22" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M13.0565 13.183C13.5173 14.6101 13.75 15.8592 13.75 16.9363C13.75 19.335 12.8109 21.1837 11.25 21.1837C10.4503 21.1837 10.1721 20.734 9.8674 19.6372L9.59767 18.6157C9.49673 18.257 9.32094 17.6462 9.07065 16.7847C9.06395 16.7616 9.05409 16.7398 9.04126 16.7198L6.17451 12.2341C5.49646 11.1732 4.49599 10.3577 3.32011 9.90745L2.06287 9.42609C1.2695 9.12234 0.808883 8.29266 0.970598 7.45866L1.65676 3.91999C1.8239 3.058 2.4772 2.37199 3.33 2.16297L11.5795 0.141041C14.1107 -0.47935 16.6691 1.05558 17.313 3.58091L18.8869 9.7536C19.2621 11.2253 18.3733 12.7225 16.9016 13.0978C16.6795 13.1544 16.4513 13.183 16.2221 13.183H13.0565Z" 
      fill="currentColor"/>
    </svg>
  );
};

export default ThumbsDownIcon;