import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import styles from "../../../../assets/jss/pages/profissionalSaudeStyle";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import CardDadosCadastrais from "../../../../components/Cards/CardDadosCadastrais";
import ItemGrid from "../../../../components/Modal/ItemGrid";

import InputPhoneDdi from "../../../../components/Input/InputPhoneDdi";
import { InputForm } from "../../../../components/Modal/Input";
import { InputForm as InputTextArea } from "../../../../components/Input/InputDadosCadastraisPaciente";
import InfoIcon from "../../../../components/Icon/InfoIcon";
import ClipboardCopy from "../../../../components/Input/ClipboardCopy";
import { buildUrlPerfilVizi, getViziWebUrl } from "../../../../config/config";
import ProfissionalSaudeConfigOptions from "../../components/ProfissionalSaudeConfigOptions";
import { supportWhatsappLink } from "../../constants/perfilViziConstants";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import { perfilViziUnidadeOptions } from "../../constants/perfilViziUnidadeConstants";
import { ReactSelectSearch } from "../../../../components/Select/SelectSearch";

const confirmModalDefault = {
  open: false,
  title: "",
  text: "",
};

const DadosGerais = observer((props) => {
  const {
    classes,
    unidadeStore,
    handleInputChange,
    perfilPublicoStore,
    profissionalSaudeStore,
  } = props;
  const { unidade, errors } = unidadeStore;

  const [confirmModal, setConfirmModal] = useState(confirmModalDefault);

  const onTelefonePrincipalComDDIChange = (value, data) => {
    unidadeStore.unidade = {
      ...unidadeStore.unidade,
      telefonePrincipal: value,
      telefonePrincipalDDI: data.dialCode,
    };
  };

  const onTelefoneSecundarioChange = (value, data) => {
    unidadeStore.unidadePublica.telefoneSecundario = value.split(
      data.dialCode
    )[1];
  };

  const handleSwitchChange = (option) => {
    if (
      option.attribute === "permiteBuscaGoogle" &&
      perfilPublicoStore.perfilPublicoUnidade[option.attribute]
    ) {
      return handleOpenConfirmDisableGoogleSearch();
    }
    perfilPublicoStore.perfilPublicoUnidade[
      option.attribute
    ] = !perfilPublicoStore.perfilPublicoUnidade[option.attribute];
  };

  const switchIsChecked = (option) => {
    if (option.storedIn === "perfilPublicoStore") {
      return perfilPublicoStore.perfilPublicoUnidade[option.attribute];
    }
  };

  const handleOpenConfirmDisableGoogleSearch = () => {
    setConfirmModal({
      open: true,
      title: "Você tem certeza dessa ação?",
      text:
        "Ao desativar essa funcionalidade seu perfil público não será mais exibido nos resultados dos buscadores como Google e bing. Essa ação demorará para ser efetivada devido ao processo interno de cada um dos buscadores, mas será indicado para não mais indexar a página nos resultados de pesquisa.",
    });
  };

  const handleConfirmDisableGoogleSearch = () => {
    perfilPublicoStore.perfilPublicoUnidade.permiteBuscaGoogle = !perfilPublicoStore
      .perfilPublicoUnidade.permiteBuscaGoogle;
    setConfirmModal(confirmModalDefault);
  };

  const handleCancelDisableGoogleSearch = () => {
    setConfirmModal(confirmModalDefault);
  };

  return (
    <>
      <div
        className={classNames(
          classes.gridCardContainerPublic,
          classes.gridCardContainerTemplatColumnsUnidade
        )}
      >
        <div>
          <div className={classes.cardContainer}>
            <CardDadosCadastrais titulo="Dados da cadastrais da Unidade">
              <ItemGrid size={12} label="Razão Social:" bold>
                <InputForm
                  name="nome"
                  value={unidade.nome}
                  placeholder="Razão social"
                  className={classNames(
                    classes.inputContainer,
                    errors.nome ? classes.inputError : null
                  )}
                  onChange={(e) => handleInputChange(e.target.value, "nome")}
                />
              </ItemGrid>
              <ItemGrid size={12} label="Nome Fantasia:" bold>
                <InputForm
                  name="nomeFantasia"
                  value={unidade.nomeFantasia || ""}
                  placeholder="Nome Fantasia"
                  className={classNames(
                    classes.inputContainer,
                    errors.nome ? classes.inputError : null
                  )}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "nomeFantasia")
                  }
                />
              </ItemGrid>
              <ItemGrid size={12} label="Código CNES:" bold>
                <InputForm
                  name="codigoCnes"
                  value={unidade.codigoCnes || ""}
                  placeholder="Código CNES"
                  className={classNames(
                    classes.inputContainer,
                    errors.codigoCnes ? classes.inputError : null
                  )}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "codigoCnes")
                  }
                />
              </ItemGrid>
              <ItemGrid size={12} label="Telefone público principal:" bold>
                <InputPhoneDdi
                  value={unidade.telefonePrincipal || ""}
                  onTelefonePrincipalComDDIChange={
                    onTelefonePrincipalComDDIChange
                  }
                />
              </ItemGrid>
              <ItemGrid size={12} label="Telefone público secundário:" bold>
                <InputPhoneDdi
                  value={`${unidadeStore.unidade.telefonePrincipalDDI}${unidadeStore.unidadePublica.telefoneSecundario}`}
                  onTelefonePrincipalComDDIChange={onTelefoneSecundarioChange}
                />
              </ItemGrid>
              <ItemGrid size={12} label="E-mail:" bold>
                <InputForm
                  name="E-mail:"
                  placeholder="E-mail"
                  className={classes.inputContainer}
                  value={unidadeStore.unidadePublica.email}
                  onChange={(e) => handleInputChange(e.target.value, "email")}
                />
              </ItemGrid>
            </CardDadosCadastrais>
          </div>
          <div className={classes.cardContainer}>
            <CardDadosCadastrais titulo="Dados da Clínica">
              <ItemGrid size={12} label="Quem Somos:" bold>
                <InputTextArea
                  multiline
                  rowsMax={5}
                  rows={5}
                  className={[classes.inputContainer, classes.textBox]}
                  inputProps={{ maxLength: 500, borderRadius: 0 }}
                  value={unidadeStore.unidadePublica.sobre}
                  onChange={(e) => handleInputChange(e.target.value, "sobre")}
                />
                <span className={classes.boxObservacoesCharCounter}>
                  {`${unidadeStore.unidadePublica.sobre?.length || 0}/500`}
                </span>
              </ItemGrid>
              <ItemGrid size={12} label="Horário atendimento:" bold>
                <InputTextArea
                  multiline
                  rowsMax={5}
                  rows={5}
                  className={[classes.inputContainer, classes.textBox]}
                  inputProps={{ maxLength: 500, borderRadius: 0 }}
                  value={
                    perfilPublicoStore.perfilPublicoUnidade.horarioAtendimento
                  }
                  onChange={(e) =>
                    handleInputChange(e.target.value, "horarioAtendimento")
                  }
                />
                <span className={classes.boxObservacoesCharCounter}>
                  {`${perfilPublicoStore.perfilPublicoUnidade.horarioAtendimento
                    ?.length || 0}/500`}
                </span>
              </ItemGrid>
            </CardDadosCadastrais>
          </div>
          <div className={classes.cardContainer}>
            <CardDadosCadastrais titulo="Idiomas">
              <div>
                <ReactSelectSearch
                  isMulti
                  className={[classes.inputContainer, classes.withMarginBottom]}
                  name="idiomas"
                  placeholder="Selecione os idiomas"
                  elements={profissionalSaudeStore.languageList}
                  value={perfilPublicoStore.perfilPublicoUnidade.idiomas}
                  onChange={(value) => handleInputChange(value, "idiomas")}
                />
              </div>
            </CardDadosCadastrais>
          </div>
        </div>
        <div>
          <div className={classes.cardContainer}>
            <CardDadosCadastrais titulo="Link público da Unidade">
              <div className={classes.perfilLinkInfoText}>
                <span>Link de acesso ao seu perfil público</span>
                <InfoIcon size={16} color="#868686" />
              </div>
              <ClipboardCopy
                text={buildUrlPerfilVizi(
                  perfilPublicoStore.perfilPublicoUnidade
                )}
                baseUrl={getViziWebUrl()}
                isShortUrl
                disabled={!perfilPublicoStore?.perfilPublicoUnidade?.id}
                emptyFieldMessage="Esse perfil não possui um link"
              />
              <span className={classes.viziWarningText}>
                Para maiores informações sobre esse link, entre em contato com o
                suporte do AppHealth,
                <a
                  className={classes.viziWarningText}
                  href={supportWhatsappLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  clicando aqui
                </a>
                .
              </span>
            </CardDadosCadastrais>
          </div>
          <div className={classes.cardContainer}>
            <CardDadosCadastrais titulo="Ajuste as funcionalidades">
              <ProfissionalSaudeConfigOptions
                options={perfilViziUnidadeOptions}
                handleSwitchChange={handleSwitchChange}
                isChecked={switchIsChecked}
              />
            </CardDadosCadastrais>
          </div>
        </div>
      </div>

      <ConfirmModal
        open={confirmModal.open}
        title={confirmModal.title}
        text={confirmModal.text}
        onCancel={handleCancelDisableGoogleSearch}
        onConfirm={handleConfirmDisableGoogleSearch}
      />
    </>
  );
});

const DadosGeraisWithStyles = withStyles(styles)(DadosGerais);
const stores = [
  "unidadeStore",
  "perfilPublicoStore",
  "profissionalSaudeStore",
  "unidadeBraspagStore",
];
export default inject(...stores)(DadosGeraisWithStyles);
