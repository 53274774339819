import { action, observable } from "mobx";
import Api from "../config/api";
import BaseStore from "./Base.store";
import string from "../utils/string";
import { criarEImprimirGuiaServicoAssinado, imprimirAssinadoGuiaServico } from '../services/SADTService';

export const TypeModalEnum = {
  SADT_MODAL: 'SADTModal',
  EDIT_MODAL: 'modalEdit',
  NOVO_MODELO_MODAL: 'novoModal',
}

export const defaultObjView = {
  indicacaoClinica: null,
  tabela: {value: 22, label: 22},
  codigoTermo: null,
  descricao: null,
  quantidadeSolicitada: 1,
  guiaServicoTussViews: [],
  sujeitoAtencao: null,
  observacao: '',
};
export default class SADTStore extends BaseStore {
  @observable objView = {
    ...defaultObjView
  }
  @observable accessToken = '';
  @observable loadingButtonImpressao = false;
  @observable printing = false;
  @observable saving = false;
  @observable readOnly = false;
  @observable guiaServico = null;
  @observable entradaProntuario = null;
  @observable cid10SubcategoriaModelo = null;
  
  @observable notification = {
    isOpen: false,
    variant: '',
    message: ''
  };
  @observable tipoListagemSelecionada = 'sugestoes';
  @observable tabela = null;
  @observable codigoTermo = null;
  @observable descricao = null;
  @observable nomeModelo = "";
  @observable observacao = "";
  @observable sugestoes = [];
  @observable sugestoesLast = false;
  @observable sugestoesPagAtual = 0;
  @observable sugestoesLoading = false;
  @observable typeModal = '';
  @observable statusListaModelo = 'none';
  @observable salvarModelo = false;
  @observable modelos = [];
  @observable modelosConfig = [];
  @observable modelosPagAtual = 0;
  @observable modelosLast = false;
  @observable modelosLoading = false;
  @observable modelosLoadingModal = false;
  @observable statusNovoModelo = true;
  @observable idModeloSelecionado = '';
  @observable searchNomeModelo = "";
  @observable pageNumberModelo = 0;
  @observable sortDirModelo = 'ASC';
  @observable sortFieldModelo = 'nome';
  @observable statusModelo = undefined;
  @observable openModal = false;
  @observable isProntuario = false;
  @observable indicacaoClinicaTemporaria = null;
  @observable guiaServicoTussViewsTemporaria = [];
  @observable observacaoTemporaria = '';
  @observable disabledListaLateral = false;

  @action async findAllCid10Subcategoria(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput) {
            findAllCid10Subcategoria(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                descricao
                codigo
                descricaoAbreviada
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      let searchCid = searchDTO.search;
      const { last, content } = response.data.data.findAllCid10Subcategoria;

      const customContent = content.map(c => {
        const searchAdaptado = string.capitalize(searchCid)
        const listaDescricaoAbreviada = searchCid === '' ||
          c.descricaoAbreviada.includes(searchCid) ||
          c.descricaoAbreviada.includes(searchAdaptado)

        return ({
          ...c,
          value: c.id,
          label: listaDescricaoAbreviada ? c.descricaoAbreviada : `${c.codigo} ${c.descricao}`
        })
      });

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllTussView({search = null, tabela = null, codigo = null, descricao = null, pageableDTO,}, field) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "descricao"
      };

      const response = await Api.post("", {
        query: `
        query ($search: String, $tabela: Int, $pageableDTO: PageableDTOInput, $codigo: String, $descricao: String) {
          findAllTussView(search: $search, tabela: $tabela, pageableDTO: $pageableDTO, codigo: $codigo, descricao: $descricao) {
              last
              totalElements
              numberOfElements
              content {
                codigoTermo
                descricao
                tabela
              }
            }
          }
                `,
        variables: {
          search,
          tabela,
          codigo,
          descricao,
          pageableDTO: pageableDTO || defaultSearchDTO
        }
      });

      const { last, content } = response.data.data.findAllTussView;

      const customContent = content.map(item => ({
        ...item,
        value: item.codigoTermo,
        label: `${item.codigoTermo} - ${item.descricao}`
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllTuss22ProcedimentoEventoSaude(variables, field) {
    try {
      const response = await Api.post("", {
        query: `
        query ($codigoTermo: String, $ativo: Boolean, $descricao: String, $pageableDTO: PageableDTOInput) {
          findAllTuss22ProcedimentoEventoSaude(codigoTermo: $codigoTermo, ativo: $ativo, descricao: $descricao, pageableDTO: $pageableDTO) {
            last
            totalElements
            numberOfElements
            content {
              id
              codigoTermo
              descricao
            }
          }
        }
        `,
        variables: variables
      });

      const { last, content } = response.data.data.findAllTuss22ProcedimentoEventoSaude;

      const customContent = content.map(item => ({
        ...item,
        value: item[field],
        label: item[field]
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async createGuiaServico(input) {
    try {
      if (!input.sujeitoAtencao?.id) {
        throw new Error("Informe o Paciente");
      }

      if (!input.guiaServicoTussViews?.length) {
        throw new Error("Informe o guia de serviço Tuss");
      }

      const query = `
        mutation ($guiaServico: GuiaServicoInput) {
          createGuiaServico(guiaServico: $guiaServico) {
            id
            beneficiarioCns
            beneficiarioConvenioNumeroCarteira
            beneficiarioConvenioValidadeCarteira
            beneficiarioNome
            cid10Subcategoria {
              descricaoAbreviada
            }
            dataHoraLancamento
            solicitanteNome
            profissionalSaude {
              numeroConselho
              ufConselho
              conselhoProfissionalSaude {
                sigla
              }
            }
            sujeitoAtencao {
              dataNascimento
            }
            observacao
            guiaServicoTussViews {
              descricao
              quantidadeSolicitada
              tussView {
                codigoTermo
                descricao
                tabela
              }
            }
          }
        }
      `;

      let cid10Subcategoria;

      if (input.indicacaoClinica) {
        cid10Subcategoria = {
          id: input.indicacaoClinica.value
        }
      }

      const variables = {
        guiaServico: {
          cid10Subcategoria,
          sujeitoAtencao: {
            id: input.sujeitoAtencao.id
          },
          guiaServicoTussViews: input.guiaServicoTussViews,
          observacao: input.observacao,
        }
      };
      
      this.saving = true;
      const response = await Api.post("", {
        query,
        variables
      });

      if (response.data.errors) {
        throw response.data.errors[0];
      }

      return response.data.data.createGuiaServico;
    } catch (error) {
      this.openNotification(error.message, 'error');
      throw error;
    } finally {
      this.saving = false;
    }
  }

  @action async findGuiaServicoByIdEntradaProntuario(id) {
    try {
        const response = await Api.post('', {
            query: `
                query {
                    content: findGuiaServicoByIdEntradaProntuario(id: ${id}){
                      id
                      assinado
                      beneficiarioCns
                      beneficiarioConvenioNumeroCarteira
                      beneficiarioConvenioValidadeCarteira
                      beneficiarioNome
                      cid10Subcategoria {
                        id
                        descricaoAbreviada
                      }
                      solicitanteNome
                      profissionalSaude {
                        numeroConselho
                        ufConselho
                        conselhoProfissionalSaude {
                          sigla
                        }
                      }
                      sujeitoAtencao {
                        dataNascimento
                      }
                      entradaProntuario {
                        urlDownload
                      }
                      dataHoraLancamento
                      observacao
                      guiaServicoTussViews {
                        quantidadeSolicitada
                        descricao
                        tussView {
                          codigoTermo
                          descricao
                          tabela
                        }
                      }
                    }
                }
            `
        });

        if(response.data.errors) {
            throw response.data.errors[0];
        }

        const { content } = response.data.data;

        return content;

    } catch (error) {
        throw error;
    }
  }
  
  @action reset() {
    this.guiaServico = null;
    this.readOnly = false;
    this.objView = {
      ...defaultObjView
    };
  }

  @action async findTabelasDisponiveisTussView(){
    const response = await Api.post('',
    JSON.stringify(
      {
        query: `         
        {findTabelasDisponiveisTussView}         
        `,
      }
      ))  

      const listaTabela = response.data.data.findTabelasDisponiveisTussView.map(item => ({
        value: item,
        label: item,
      }
      ))

    return {content: [{value: null, label: 'Todas'}, ...listaTabela], last: true};
  }

  @action async findCodigosDisponiveisTussView({tabela = null, pageableDTO}) {

    const defaultSearchDTO = {
      pageSize: 30,
      pageNumber: 0,
      sortDir: "ASC",
    };

    const response = await Api.post('',
    JSON.stringify({
      query:
      `query ($tabela: Int, $pageableDTO: PageableDTOInput) {
        findCodigosDisponiveisTussView(tabela: $tabela, pageableDTO: $pageableDTO) {
          last
          totalElements
          content{
            codigoTermo
            descricao
            tabela
          }
          pageable {
            pageNumber
          }
        }
      }`,
      variables:{
        tabela,
        pageableDTO: pageableDTO || defaultSearchDTO,      
    }
    }))
    const { last, content } = response.data.data.findCodigosDisponiveisTussView;
    const customContent = content.map(item => ({
      ...item,
      value: item.codigoTermo,  
      label: item.codigoTermo,
    } 
    ));
    return {content: customContent, last};
  }

  @action async findAllGuiaServicoTussViewQuantidadeUso({pageableDTO}){
    const defaultSearchDTO = {
      pageSize: 30,
      pageNumber: 0,
      sortDir: "DESC"
    };

    this.sugestoesLoading = true;

    const response = await Api.post('',
    JSON.stringify({
      query:
      `query ($pageableDTO: PageableDTOInput) {
        findAllGuiaServicoTussViewQuantidadeUso(pageableDTO: $pageableDTO) {
          last
          numberOfElements
          content {
            quantidadeUso
            tussView {
              codigoTermo
              descricao
              tabela
            }
          }
          pageable {
            pageNumber
          }
        }
      }`,
      variables:{
        pageableDTO: pageableDTO || defaultSearchDTO,      
    }
    }))
    this.sugestoesLoading = false;
    
    const { content, last } = response.data.data.findAllGuiaServicoTussViewQuantidadeUso;
    this.sugestoesLast = last;
    this.sugestoes = [...this.sugestoes, ...content];

    return this.sugestoes;
  }

  @action async findAllGuiaServicoModelo({searchDTO = {}, pageableDTO}){

    const defaultSearchDTO = {
      pageSize: 30,
      pageNumber: 0,
      sortDir: "DESC",
      sortField: "quantidadeUso"
    }

    this.modelosLoadingModal = true;

    await Api.post('',
    JSON.stringify({
      query:
      `
      query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
        findAllGuiaServicoModelo(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
          last
          numberOfElements
          content {
            id
            nome
            observacao
            ativo
            cid10Subcategoria {
              id
              descricaoAbreviada
            }
            guiaServicoModeloTussViews {
              descricao
              quantidadeSolicitada
              tussView {
                tabela
                descricao
                codigoTermo
              }
            }
          }
          pageable {
            pageNumber
          }
        }
      }
      `,
      variables:
      {
        searchDTO: searchDTO,
        pageableDTO: pageableDTO || defaultSearchDTO
      }
    })).then(response => {
      const { content, last } = response.data.data.findAllGuiaServicoModelo;
      
      this.modelosLast = last;
      this.modelos = [...this.modelos, ...content];
    })
    .catch((error) =>{})
    .finally(() => {
      this.modelosLoadingModal = false;
    }) 

    return this.modelos;
  }

  @action async findByIdGuiaServicoModelo(id){
    try {
      const response = await Api.post('', {
        query: `
          query ($id: UUID) {
            findByIdGuiaServicoModelo(id: $id) {
              id
              ativo
              nome
              observacao
              cid10Subcategoria {
                id
                descricaoAbreviada
              }
              guiaServicoModeloTussViews {
                quantidadeSolicitada
                descricao
                tussView {
                  tabela
                  descricao
                  codigoTermo
                }
              }
            }
          }
        `,
        variables: { id }
      });
      return response.data.data.findByIdGuiaServicoModelo;

    } catch (error) {
      throw error;
    }
  }

  @action async createGuiaServicoModelo(input){
    const query = `
    mutation ($guiaServicoModelo: GuiaServicoModeloInput) {
      createGuiaServicoModelo(guiaServicoModelo: $guiaServicoModelo) {
        id
        nome
        observacao
        quantidadeUso
        ativo
        cid10Subcategoria {
          id
          descricaoAbreviada
        },
        guiaServicoModeloTussViews {
          quantidadeSolicitada
          descricao
          tussView {
            tabela
            codigoTermo
            descricao
          }
        }
      }
    }`;

    let cid10Subcategoria;

    if (input.indicacaoClinica) {
      cid10Subcategoria = {
        id:  input.indicacaoClinica || input.cid10Subcategoria.id
      }
    }

      const variables = {
        guiaServicoModelo: {
          nome: input.nome,
          observacao: input.observacao,
          ativo: input.ativo,
          guiaServicoModeloTussViews: input.guiaServicoModeloTussViews,
          cid10Subcategoria,
          quantidadeUso: 0
        }
        
      };

      const response = await Api.post("", {
        query,
        variables
      });

      if(response.data.errors) {
        throw response.data.errors[0]
      }

      return response;
  }

  @action async updateGuiaServicoModelo(input){
    const query = `
    mutation ($guiaServicoModelo: GuiaServicoModeloInput) {
      updateGuiaServicoModelo(guiaServicoModelo: $guiaServicoModelo) {
        id
        nome
        observacao
        ativo
        guiaServicoModeloTussViews {
          descricao
          quantidadeSolicitada
          tussView {
            tabela
            codigoTermo
            descricao
          }
        }
        cid10Subcategoria {
          id
          descricaoAbreviada
        }
      }
    }
    
    `;

    let cid10Subcategoria;

    if (input.indicacaoClinica) {
      cid10Subcategoria = {
        id: input.indicacaoClinica.value
      }
    }

    const variables = {
      guiaServicoModelo:{
        id: input.id,
        ativo: input.ativo,
        nome: input.nome,
        observacao: input.observacao,
        guiaServicoModeloTussViews: input.guiaServicoModeloTussViews,
        cid10Subcategoria
      }
    };

    const response = await Api.post("", {
      query,
      variables
    });

    if(response.data.errors) {
      throw response.data.errors[0]
    }

    return response.data.data.updateGuiaServicoModelo;
  }

  @action async ativarRegistro(input){
      const query = `
      mutation ($id: UUID) {
        ativarRegistro(id: $id) {
          id
          nome
          ativo
        }
      }
      `;

      const variables = {
        id: input
      }

      return await Api.post("", {
        query,
        variables
      })
  }

  @action async desativarRegistro(input){
    const query = `
    mutation ($id: UUID) {
      desativarRegistro(id: $id) {
        id
        nome
        ativo
      }
    }
    `;

    const variables = {
      id: input
    }

    return await Api.post("", {
      query,
      variables
    })
  }

  @action async registroQuantidadeUso(input){
    const query = `
    mutation ($id: UUID) {
      registroQuantidadeUso(id: $id) {
        id
        nome
        ativo
    		quantidadeUso
      }
    }
    `;

    const variables = {
      id: input
    }

    return await Api.post("", {
      query,
      variables
    })
  }

  @action changeFieldsStore(values) {
    const arrayKey = Object.keys(values);

    arrayKey.forEach(item => {
      this[item]=values[item];
    })
  }

  @action changeFieldsObjViewStore(values) {
    const arrayKey = Object.keys(values);

    arrayKey.forEach(item => {
      this.objView[item]=values[item];
    })
  }

  @action printSigned = async (token, validToken) => {
    const guiaServico = {
      ...(this.guiaServico?.id && { id: this.guiaServico?.id }),
      ...(this.objView?.indicacaoClinica && { cid10Subcategoria: {
        id: this.objView?.indicacaoClinica?.value
      }}),
      sujeitoAtencao: {
        id: this.objView?.sujeitoAtencao?.id,
      },
      guiaServicoTussViews: this.objView?.guiaServicoTussViews,
      observacao: this.objView?.observacao,
    }

    const variables = {
      guiaServico,
      ...(!validToken && {tokenAplicativo:  token.replace(' ', '')})
    }
    
    let response;
    let method = '';
    if (guiaServico?.id) {
      response = await imprimirAssinadoGuiaServico(variables);
      method = 'imprimirAssinadoGuiaServico';
    } else {
      response = await criarEImprimirGuiaServicoAssinado(variables);
      method = 'criarEImprimirGuiaServicoAssinado';
    }

    if (response?.data?.errors) {
      throw Error('Falha ao imprimir a guia de serviço');
    } else {
      return response.data.data[method];
    }
  }

  @action resetModelo = () => {
    this.nomeModelo="";
    this.statusNovoModelo=true;
    this.objView.observacao="";
    this.objView.guiaServicoTussViews = [];
    this.objView.indicacaoClinica = null;
  }
}