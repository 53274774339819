import React from 'react';
import InputForm from "./InputForm";
import MaskedInput from "react-text-mask";
import string from "../../utils/string";

export const CPFMask = (props) => {
    const { inputRef, ...other } = props;
    
    return (<MaskedInput placeholderChar={'\u2000'}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        showMask={props.showmask}
        mask={[
            /\d/, /\d/, /\d/,
            '.',
            /\d/, /\d/, /\d/,
            '.',
            /\d/, /\d/, /\d/,
            '-',
            /\d/, /\d/]}
         {...other}
    />);
};

export const InputCPFForm = ({...props}) => {
    return <InputForm inputComponent={CPFMask} {...props} onClick={(e) => {
        if (string.removeSpecialChars(e.target.value).length === 0)
            e.target.setSelectionRange(0, 0);
    }}/>
};
