import { conformToMask } from "react-text-mask";
import createNumberMask from "text-mask-addons/src/createNumberMask";

export const applyCurrencyMask = number => {
  const parseNumber = parseFloat(number || 0).toFixed(2).replace('.', ',');
  
  const mask = createNumberMask({
    prefix: "R$ ",
    thousandsSeparatorSymbol: ".",
    decimalSymbol: ",",
    allowDecimal: true,
    allowNegative: true
  });

  const conformedNumber = conformToMask(parseNumber, mask, {
    guide: false
  });

  return conformedNumber.conformedValue;
};

export const customCurrencyMask = ({
    prefix = "R$",
    thousandsSeparatorSymbol = ".",
    decimalSymbol = ",",
    decimalPlaces = 2,
  } = {}) => {
  return (input) => {
    let digits = (input.match(/\d/gi) || []).length;
    
    if (digits <= decimalPlaces) return [...prefix.split(''), ...Array(decimalPlaces).fill(/\d/)];

    if (digits === decimalPlaces + 1) {
      return [...prefix.split(''), /\d/, /\d/, decimalSymbol, ...Array(decimalPlaces).fill(/\d/)];
    }

    let mask = [];
    for (let i = digits - 1; i >= 0; i--) {
      mask.push(/\d/);
      if (i === digits - decimalPlaces) {
        mask.push(decimalSymbol);
      }

      const r = digits - i;
      if (r >= decimalPlaces + 2 && (r - decimalPlaces) % 3 === 0 && i > 0) {
        mask.push(thousandsSeparatorSymbol);
      }
    }

    return [...prefix.split(''), ...mask.reverse()];
  };
}
