import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import InfosChips from "./InfosChips";
import UrgenteIcon from "../../../../../components/Icon/UrgenteIcon";
import ObservacaoIcon from "../../../../../components/Icon/ObservacaoIcon";
import ProcedimentoIcon from "../../../../../components/Icon/ProcedimentoIcon";

const infos = [
  {
    label: "Observação",
    value: "observacao",
    icon: {
      image: <ObservacaoIcon color="#fff" size={16} />,
      colorIcon: "#27B0AD",
    },
  },
  {
    label: "Urgente",
    value: "procedimentoOdontologico",
    icon: {
      image: <UrgenteIcon color="#fff" size={16} />,
      colorIcon: "#FB7676",
    },
  },
  {
    label: "Procedimento",
    value: "procedimentoOdontologico",
    icon: {
      image: <ProcedimentoIcon color="#fff" size={16} />,
      colorIcon: "#FC71B4",
    },
  },
];

const InfosMucosaOral = observer(
  ({ classes, mucosaOralChecked, odontogramaStore }) => {
    const openModalObservacao = (isObservacao) => {
      if (isObservacao) {
        odontogramaStore.modalObservacao = {
          showModalObservacao: true,
          observacao: mucosaOralChecked.observacao,
          local: mucosaOralChecked.descricao,
        };
      }
    };

    return (
      <div className={classes.contentInfos}>
        {infos.map((item) => {
          const dado = mucosaOralChecked && mucosaOralChecked[item.value];
          const isList = Array.isArray(dado);

          const dadoChip = {
            Observação: dado,
            Procedimento: isList && dado?.length > 0,
            Urgente: isList && dado?.some((info) => info.urgente),
          };

          const showChip = dadoChip[item.label];
          const isObservacao = item.value === "observacao";

          return (
            showChip && (
              <InfosChips
                isObservacao={isObservacao}
                label={item.label}
                icon={item.icon}
                openModalObservacao={() => openModalObservacao(isObservacao)}
              />
            )
          );
        })}
      </div>
    );
  }
);

const style = {
  contentInfos: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "26px",
  },
};

const InfosMucosaOralWithStyles = withStyles(style)(InfosMucosaOral);
export default inject("odontogramaStore")(InfosMucosaOralWithStyles);
