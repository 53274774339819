import React from 'react';
import { weekDays } from '../../TransferenciaAgendamento/constants';
import ClickableTablecell from '../components/ClickableTablecell';
import string from '../../../../utils/string';
import Tooltip from '@material-ui/core/Tooltip';

import { TableCellText, CellDetailsContainer } from '../components/ClickableTablecell/styles';

const PROCEDIMENTO_NAME_MAX_LENGTH = 14;

export const getProcedimentosTableHeader = ({ onClick }) => {
  return [
    {
      Header: 'Horário',
      getValue: row => row.horaInicio,
      props: { center: 'true' },
    },
    ...weekDays.map(({ value }) => ({
      Header: value.substring(0, 3),
      getValue: (row) => {
        const isDisabled = !row[value]?.permiteAgendamento;
        const handleClick = () => onClick(row[value]);
        const procedimentosNames = row[value]?.procedimentos?.map(({ nome }) => nome).join(', ');
        return (
          <ClickableTablecell disabled={isDisabled} onClick={!isDisabled && handleClick}>
            <Tooltip 
              placement='top'
              title={renderHorariosDetais(row[value])} 
              disableHoverListener={procedimentosNames?.length <= PROCEDIMENTO_NAME_MAX_LENGTH}
              style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
            >
              <TableCellText>
                {string.truncate(procedimentosNames, 14, '...')}
              </TableCellText>
            </Tooltip>
          </ClickableTablecell>
        )
      },
      center: 'true',
      isClick: true
    })),
  ];
};

const renderHorariosDetais = (horario) => {
  return (
    <CellDetailsContainer>
      <strong>Solicitante / Responsável:</strong> {horario?.solicitante?.nome || ''}
      <br />
      <strong>Procedimento(s):</strong> {horario?.procedimentos?.map(({ nome }) => <span>{nome}</span>)}
    </CellDetailsContainer>
  );
};
