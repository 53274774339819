import moment from 'moment';

export const verifyAllChecked = (horariosOptions) => {
  return horariosOptions.slice(1).every(h => h.checked);
}

export const countChecked = (horariosOptions) => {
  return horariosOptions.slice(1).filter(h => h.checked).length;
}

export const  generateObject = ({ 
  horariosOptions, 
  operation, 
  dataInicial, 
  dataFinal,
  duracao,
}) => {
  const periodos = [];
  const allOptionsChecked = horariosOptions[0].checked;
  const daysInRange = getDaysInRange(dataInicial, dataFinal);

  if (allOptionsChecked) {
    daysInRange.forEach(day => {
      return periodos.push({
        acao: operation,
        data: day,
        horaInicio: "00:00",
        horaFim: "23:59",
      })
    });
  } else {
    daysInRange.forEach(day => {
      horariosOptions.forEach(horario => {
        if (horario.checked) {
          return periodos.push({
            acao: operation,
            data: day,
            horaInicio: horario.value,
            horaFim: moment(horario.value, 'HH:mm').add(duracao, 'minutes').format('HH:mm'),
          });
        }
      });
    });
  }
  return periodos;
}

const getDaysInRange = (dataInicial, dataFinal) => {
  const days = [];
  const startDate = moment(dataInicial);
  const endDate = moment(dataFinal);
  while (startDate <= endDate) {
    days.push(startDate.format('YYYY-MM-DD'));
    startDate.add(1, 'days');
  }
  return days;
}