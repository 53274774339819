import React, { useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'

import { TextFieldSearch } from '../../../../components/TextField'
import InputSelectComLabel from '../../../../components/Input/InputSelectComLabel'

import {
  findAllEstadoList,
  findAllConselhoProfissionalSaudeList,
  findAllTuss24TerminologiaCBO,
} from '../../../../services/GuiaDeServicosService'

import styles from '../GuiaDeServicoStyle'

const StepTabExterna = props => {
  const { classes, tabExterna, handleChangeGuiaServico, openNotification, setDisableButton } = props

  const loadAllTuss24 = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 30,
    }

    const searchDTO = {
      codigoTermo: search,
    }

    try {
      const response = await findAllTuss24TerminologiaCBO(pageableDTO, searchDTO)
      const { content, last } = response.data.data.findAllTuss24TerminologiaCBO
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch {
      openNotification('Erro ao carregar Código CBO', 'error')
    }
  }

  const loadEstadosList = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      uf: search,
    }

    try {
      const response = (await findAllEstadoList(page, searchDTO)).data.data.findAllEstadoList
      return {
        options: response,
      }
    } catch {
      openNotification('Erro ao carregar UF ', 'error')
    }
  }

  const loadConselhoProfissional = async (search, loadedOptions, { page }) => {
    try {
      const content = (await findAllConselhoProfissionalSaudeList()).data.data
        .findAllConselhoProfissionalSaudeList
      return {
        options: content,
      }
    } catch {
      openNotification('Erro ao carregar Conselho Profissional ', 'error')
    }
  }

  const handleSelectConselho = option => {
    let newSolicitante = tabExterna

    newSolicitante = {
      ...newSolicitante,
      codigoConselho: option?.codigoTermo,
      conselhoProfissionalSaude: option,
    }
    handleChangeGuiaServico('solicitanteExterna', newSolicitante)
  }

  const handleSelectUF = option => {
    let newSolicitante = tabExterna

    newSolicitante = {
      ...newSolicitante,
      estado: option,
    }
    handleChangeGuiaServico('solicitanteExterna', newSolicitante)
  }

  const handleSelectCodigoCBO = option => {
    let newSolicitante = tabExterna

    newSolicitante = {
      ...newSolicitante,
      codigoCbo: option.codigoTermo,
      tuss24TerminologiaCBO: option,
    }
    handleChangeGuiaServico('solicitanteExterna', newSolicitante)
  }

  const handleChangeTabelaExterna = event => {
    const { name, value } = event.target

    let newSolicitante = tabExterna

    newSolicitante = { ...newSolicitante, [name]: value }
    handleChangeGuiaServico('solicitanteExterna', newSolicitante)
  }

  const handleOnInput = (event, maxLength) => {
    const { value } = event.target
    return event.target.value = value.slice(0, maxLength)
  }

  const validationStep = () => {
    if (
      !tabExterna?.codigoOperadora ||
      !tabExterna?.nomeContratado ||
      !tabExterna?.profissional ||
      !tabExterna?.conselhoProfissionalSaude ||
      !tabExterna?.tuss24TerminologiaCBO ||
      !tabExterna?.numeroConselho
    ) {
      setDisableButton(true)
      return
    }
    setDisableButton(false)
  }

  useEffect(() => {
    validationStep()
  }, [tabExterna])

  return (
    <Grid
      container
      direction={'row'}
      xs={12}
      className={classes.wrapperTab}
    >
      <Grid container spacing={8}  className={classes.itensSolicitante}>
        <Grid item xs={6}>
          <InputSelectComLabel
            label={'Código na operadora:'}
            placeholder={'Código na operadora'}
            onInput={event => handleOnInput(event, 14)}
            name='codigoOperadora'
            classes={{
              input: classes.inputContainer,
            }}
            value={tabExterna?.codigoOperadora}
            onChange={handleChangeTabelaExterna}
            type='number'
            noMargin
          />
        </Grid>
        <Grid item xs={6}>
          <InputSelectComLabel
            label={'Nome do Contratado:'}
            placeholder={'Nome do Contratado'}
            name='nomeContratado'
            classes={{
              input: classes.inputContainer,
            }}
            value={tabExterna?.nomeContratado}
            onChange={handleChangeTabelaExterna}
            noMargin
          />
        </Grid>
      </Grid>
      <Grid container xs={12} className={classes.itensSolicitante}>
        <Grid item xs={12}>
          <InputSelectComLabel
            label={'Nome do Profissional Solicitante:'}
            placeholder={'Profissional Solicitante'}
            name='profissional'
            classes={{
              input: classes.inputContainer,
            }}
            value={tabExterna?.profissional}
            onChange={handleChangeTabelaExterna}
            noMargin
          />
        </Grid>
      </Grid>
      <Grid container spacing={8} className={classes.itensSolicitante}>
        <Grid item xs={4}>
          <Typography className={classes.typography} component='label'>
            Conselho
          </Typography>
          <TextFieldSearch
            placeholder='Selecione'
            classNotched={classes.notchedOutline}
            classInput={classes.inputContainer}
            classIcons={classes.classIcons}
            classes={{ paper: classes.menuHeight }}
            loadOptions={loadConselhoProfissional}
            getOptionLabel={option => option?.descricao}
            getOptionValue={option => option}
            value={tabExterna?.conselhoProfissionalSaude}
            onChange={handleSelectConselho}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition='fixed'
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.typography} component='label'>
            UF
          </Typography>
          <TextFieldSearch
            placeholder='Selecione'
            classNotched={classes.notchedOutline}
            classInput={classes.inputContainer}
            classIcons={classes.classIcons}
            classes={{ paper: classes.menuHeight }}
            loadOptions={loadEstadosList}
            getOptionLabel={option => option?.uf}
            getOptionValue={option => option}
            value={tabExterna?.estado}
            onChange={handleSelectUF}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition='fixed'
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.typography} component='label'>
            Código CBO
          </Typography>
          <TextFieldSearch
            placeholder='Selecione'
            classNotched={classes.notchedOutline}
            classInput={classes.inputContainer}
            classIcons={classes.classIcons}
            classes={{ paper: classes.menuHeight }}
            loadOptions={loadAllTuss24}
            getOptionLabel={option => `${option.termo}-${option.codigoTermo}`}
            getOptionValue={option => option}
            value={tabExterna?.tuss24TerminologiaCBO}
            onChange={handleSelectCodigoCBO}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition='fixed'
          />
        </Grid>
      </Grid>
      <InputSelectComLabel
        label={'Número do conselho:'}
        placeholder='Número do conselho'
        classes={{
          input: classes.inputContainer,
        }}
        onInput={event => handleOnInput(event, 15)}
        className={classes.itensSolicitante}
        name='numeroConselho'
        type='number'
        value={tabExterna?.numeroConselho}
        onChange={handleChangeTabelaExterna}
        noMargin
      />
    </Grid>
  )
}

export default withStyles(styles)(StepTabExterna)
