import React from "react";
import classnames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles, Grid, Typography } from "@material-ui/core";
import styles from "../../../../assets/jss/pages/configuracaoReceitaStyle";
import papelRetratoIcon from "../../../../assets/img/svg/paper-portrait.svg";
import papelPaisagemIcon from "../../../../assets/img/svg/paper-landscape.svg";

const Orientacao = observer((props) => {
  const { configuracaoImpressaoStore, classes } = props;
  const { configuracaoImpressao } = configuracaoImpressaoStore;

  const handleChangeOrientacaoPapelRetrato = () => {
    configuracaoImpressao.retrato = true;
  };

  const handleChangeOrientacaoPapelPaisagem = () => {
    configuracaoImpressao.retrato = false;
  };

  return (
    <Grid item xs={4} className={classes.formGroup}>
      <Typography component="h6">Orientação</Typography>
      <div className={classes.buttonPapelContainer}>
        <button
          className={classnames(
            classes.buttonPapel,
            configuracaoImpressao.retrato ? classes.buttonPapelSelected : null
          )}
          onClick={() => handleChangeOrientacaoPapelRetrato()}
        >
          <img src={papelRetratoIcon} alt="" />
        </button>
        <button
          className={classnames(
            classes.buttonPapel,
            !configuracaoImpressao.retrato ? classes.buttonPapelSelected : null
          )}
          onClick={() => handleChangeOrientacaoPapelPaisagem()}
        >
          <img src={papelPaisagemIcon} alt="" />
        </button>
      </div>
    </Grid>
  );
});

const OrientacaoWithStyles = withStyles(styles)(Orientacao);
export default inject("configuracaoImpressaoStore")(OrientacaoWithStyles);
