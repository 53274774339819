import React, { useRef } from "react";
import { Switch } from "@material-ui/core";
import PencilIcon from "../../../components/Icon/Pencil";
import AtivaIcon from "../../../components/Icon/AtivaIcon";
import DuplicaIcon from "../../../components/Icon/DuplicaIcon";
import DesativaIcon from "../../../components/Icon/DesativaIcon";
import PopperCustomMenu from "../../../components/Popper/PopperCustomMenu";
import string from "../../../utils/string";

export const tabsModal = {
  CONVENIO: 0,
  PRODUTO: 1,
};

export const optionsTipo = [
  {
    label: "Procedimento",
    value: "PROCEDIMENTO",
  },
  {
    label: "Odontológico",
    value: "ODONTOLOGICO",
  },
];

export const menuOpcoesPopper = (options) => {
  const optionsMenu = [
    {
      ativo: options.isEdit,
      label: "Editar",
      onClick: () => options?.functions?.handleEdit(),
      icon: <PencilIcon size="24" color="#505050" />,
    },
    {
      ativo: true,
      label: "Duplicar",
      onClick: () => options?.functions?.handleDuplicar(),
      icon: <DuplicaIcon size="24" color="#505050" />,
    },
    {
      ativo: options.desativado,
      label: "Ativar",
      onClick: () => options?.functions?.handleAtivar(),
      icon: <AtivaIcon size="24" color="#505050" />,
    },
    {
      ativo: !options.desativado,
      label: "Desativar",
      onClick: () => options?.functions?.handleDesativar(),
      icon: <DesativaIcon size="24" color="#505050" />,
    },
  ];
  return optionsMenu;
};

export const menuOpcoesMensagens = (options) => {
  const optionsMenuShort = [
    {
      ativo: options.isEdit,
      label: "Editar",
      onClick: () => options?.functions?.handleEdit(options.element.id),
      icon: <PencilIcon size="24" color="#505050" />,
    },
  ];
  return optionsMenuShort;
}

const PopperMenu = ({ row, functions }) => {
  const ref = useRef(null);
  const handleCloseWithAction = (fn) => {
    return () => {
      ref?.current && ref.current.closePopper()
      fn(row.id)
    }
  }


  return (
    <PopperCustomMenu
      menuOpcoes={menuOpcoesPopper({
        functions: {
          handleEdit: handleCloseWithAction(functions?.handleEditar),
          handleDuplicar: handleCloseWithAction(functions?.handleDuplicar),
          handleDesativar: handleCloseWithAction(functions?.handleDesativar),
          handleAtivar: handleCloseWithAction(functions?.handleAtivar),
        },
        desativado: !row.ativo,
        isEdit: true,
      })}
      ref={ref}
    />
  );
};

export const columnsProcedimentos = ({ functions }) => [
  {
    Header: "Nome do procedimento",
    field: "nome",
    getValue: (row) => row.nome,
  },
  {
    Header: "Descrição",
    field: "tuss22ProcedimentoEventoSaude.codigoTermo",
    getValue: (row) => row.tuss22ProcedimentoEventoSaude?.codigoTermoDescricao,
  },
  {
    Header: "Status",
    field: "ativo",
    getValue: (row) => <Switch checked={row.ativo} color="primary" />,
  },
  {
    Header: "Ação",
    isClick: true,
    getValue: (row) => <PopperMenu row={row} functions={functions} />,
  },
];


export const columnsMensagens = ({ functions,}) => [
  {
    Header: "Mensagens",
    field: "nome",
    getValue: (row) => {
      return (
        <div>{string.truncate(row.nome, 70)}</div>
      )
    }
  },
  {
    Header: "Status",
    field: "ativo",
    getValue: (row)=> 
    <Switch disabled={row.tipoPadrao} checked={row.ativo} color="primary" />
  },
  {
    Header: "Ação",
    isClick: true,
    getValue: (row) => {
      return (
        <PopperCustomMenu
          colorButton={{ background: "transparent" }}
          menuOpcoes={menuOpcoesMensagens({
            functions: {
              handleEdit: functions.handleEdit,
            },
            desativado: !row.ativo,
            isEdit: true,
            element: row,
          })}
          closePopperAfterClickOption
        />
      );
    },
  },
];

export const columnsConfiguracoes = ({
  functions,
  headerNome,
  isDescricao,
}) => [
    {
      Header: headerNome,
      field: isDescricao ? "descricao" : "nome",
      getValue: (row) => row.descricao || row.nome,
    },
    {
      Header: "Status",
      field: "ativo",
      getValue: (row) => <Switch checked={row.ativo} color="primary" />,
    },
    {
      Header: "Ação",
      isClick: true,
      getValue: (row) => <PopperMenu row={row} functions={functions} />,
    },


  ];

