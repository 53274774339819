import { Switch } from "@material-ui/core";
import React from "react";
import { Button } from "../../../../../components/ui/Buttons";
import EditIcon from "../../../../../components/Icon/EditIcon";
import CloseIcon from "../../../../../components/Icon/Close";
import PopperCustomMenu from "../../../../../components/Popper/PopperCustomMenu";
import DuplicaIcon from "../../../../../components/Icon/DuplicaIcon";
import DeleteIcon from "../../../../../components/Icon/DeleteIcon";

export const getHeaderColumns = (functions) => [
  {
    Header: "Nome",
    field: "nome",
    getValue: (row) => row.nome,
  },
  {
    Header: "Status",
    isClick: true,
    getValue: (row) => {
      return (
        <Switch
          checked={row.ativo}
          color="primary"
          onChange={() => functions?.changeGuiaInternacaoModeloStatus(row)}
        />
      );
    },
    props: { center: "false" },
  },
  {
    Header: "",
    isClick: true,
    getValue: (row) => {
      const statusButonLabel = row.ativo ? "Desativar" : "Ativar";
      return (
        <PopperCustomMenu
          colorButton={{
            background: "#fff",
          }}
          menuOpcoes={[
            {
              ativo: true,
              label: "Editar",
              onClick: () => functions.handleClickModelo(row.id),
              icon: <EditIcon />,
            },
            {
              ativo: true,
              label: "Duplicar",
              onClick: () => functions.handleClickModelo(row.id, true),
              icon: <DuplicaIcon />,
            },
            {
              ativo: true,
              label: statusButonLabel,
              onClick: () => functions?.changeGuiaInternacaoModeloStatus(row),
              icon: <DeleteIcon />,
            },
          ]}
          closePopperAfterClickOption
        />
      );
    },
  },
];

export const tussTableHeaderColumns = ({
  handleRemoveTuss,
  guiaInternacaoId,
}) => {
  const columns = [
    {
      Header: "Tabela",
      field: "tabela",
      getValue: (row) => row.tussView.tabela,
    },
    {
      Header: "Código",
      field: "codigo",
      getValue: (row) => row.tussView.codigoTermo,
    },
    {
      Header: "Descricão",
      field: "descricao",
      getValue: (row) => row.descricao,
    },
    {
      Header: "QTD.",
      field: "qtd",
      getValue: (row) => row.quantidadeSolicitada,
    },
  ];

  if (!guiaInternacaoId) {
    columns.push({
      Header: "",
      isClick: true,
      getValue: (_, index) => (
        <Button
          kind="transparent"
          size="mini"
          onClick={() => handleRemoveTuss(index)}
        >
          <CloseIcon />
        </Button>
      ),
    });
  }

  return columns;
};
