import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles/index";
import { Grid } from "@material-ui/core";
import Colors from "../../template/Colors";
import * as AgendamentoService from "../../services/AgendamentoService";
import AtendimentoModalListaProcedimentos from "./AtendimentoModalListaProcedimentos";
import HeaderClose from "../../components/Header/HeaderClose";
import MultiToggleButtons from "../../components/Button/MultiToggleButtons";
import AtendimentoModalTabAgendamentos from "./AtendimentoModalTabAgendamentos";
import { getProfissionalSaudeLogado } from "../../services/UsuarioService";
import AtendimentoModalTabSessoes from "../Atendimento/AtendimentoModalTabSessoes";
import AtendimentoModalTabProcedimentos from "./AtendimentoModalTabProcedimentos/AtendimentoModalTabProcedimentos";

const styles = {
  gridFlexExpandedAgendamentos: {
    padding: "20px 16px",
    marginLeft: "0px",
    flex: 8,
    backgroundColor: Colors.commons.padrao,
    maxWidth: 330,
  },
  headerMargin: {
    margin: "0px 0px 8px 0px",
  },
  contentTab: {
    borderRadius: "16px",
    padding: "12px",
    background: "#fff",
    maxHeight: "475px",
    height: "100%",
  },
  buttonsTab: {
    margin: "0px 0px 12px 0px",
  },
};

const tabs = {
  PROCEDIMENTOS: 0,
  HISTORICO: 1,
  SESSOES: 2,
};

@inject("atendimentoStore", "odontogramaStore", "filtroHeaderStore")
@observer
class AtendimentoModalSujeitoAgendamentos extends React.Component {
  state = {
    atendimentosSujeitoSelecionado: [],
    lastAtendimentoPage: false,
    atendimentosPageConfig: {
      pageSize: 30,
      pageNumber: 0,
      sortFields: [{ campo: 'data', direcao: 'DESC' }, { campo: 'horaInicio', direcao: 'DESC' }],
    },
    procedimentosSolicitados: [],
    procedimentoPedidoId: null,
    isSala: false,
    observacaoGeral: "",
    profissionalSaude: {},
    tabSelected: tabs.HISTORICO,
    isLoadingSujeitoList: false,
  };

  async componentDidMount() {
    const { odontogramaStore, filtroHeaderStore, atendimentoStore } = this.props;
    const { isModalSala, objView, isOdontograma, controleSessaoData, isTelaSessoes, idOpened } = atendimentoStore;

    const profissionalLogado = await getProfissionalSaudeLogado();

    const profissionalSaude = isOdontograma ? profissionalLogado : filtroHeaderStore.profissionalSaude;  

    if (objView?.procedimentos?.length > 0) {
      this.setState({
        isSala: true,
      });
    }
    
    const tabSelected = (controleSessaoData?.idPacote && !isTelaSessoes) ? tabs.SESSOES : tabs.HISTORICO;
    this.setState({
      tabSelected,
      apenasDoAgendamento: !!idOpened,
    });
    this.changeTabSelected(tabSelected);

    const { sujeitoAtencao } = objView;
    if (sujeitoAtencao && sujeitoAtencao.id) {
      await this.loadAgendamentosBySujeitoAtencao();
      if (!isModalSala && objView?.id) {
        AgendamentoService.findByAgendamentoProcedimentoPedido(
          objView?.id
        ).then(({ data: response }) => {
          const procedimentoSolicitado =
            response.data?.findByAgendamentoProcedimentoPedido;
          if (procedimentoSolicitado) {
            atendimentoStore.procedimentosModal = procedimentoSolicitado.procedimentosSolicitados
            this.setState({
              procedimentoPedidoId: procedimentoSolicitado.id,
              procedimentosSolicitados: procedimentoSolicitado.procedimentosSolicitados,
              observacaoGeral: procedimentoSolicitado.observacao,
              profissionalSaude: procedimentoSolicitado.profissionalSaude,
              tabSelected: tabs.PROCEDIMENTOS,
            });
          }
        });
      }

      if(odontogramaStore.verificaEspecialidadeOdontologia(profissionalSaude)){
        this.dadosProcedimentosOdontologicos();
      }
    }
  };

  componentWillUnmount() {
    this.resetPage();
  }

  dadosProcedimentosOdontologicos = async() => {
    const { odontogramaStore, atendimentoStore } = this.props;
    const { procedimentosOdontologicosOdontograma } = odontogramaStore;

    atendimentoStore.procedimentosModal = procedimentosOdontologicosOdontograma;
  };

  loadAgendamentosBySujeitoAtencao = async () => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;
    const { sujeitoAtencao } = objView;
    const { atendimentosPageConfig, atendimentosSujeitoSelecionado, isLoadingSujeitoList } = this.state;

    if (isLoadingSujeitoList) return;

    try {
      this.setState({ isLoadingSujeitoList: true }, async () => {
        const response = await AgendamentoService.findAgendamentosByIdSujeitoAtencaoPage(
          sujeitoAtencao.id,
          atendimentosPageConfig
        );
  
        const { content, last } = response;
        const updatedList = [...atendimentosSujeitoSelecionado, ...content];
        this.setState({
          isLoadingSujeitoList: false,
          atendimentosSujeitoSelecionado: updatedList,
          lastAtendimentoPage: last,
          atendimentosPageConfig: {
            ...atendimentosPageConfig,
            ...(!last && {pageNumber: atendimentosPageConfig.pageNumber + 1}),
          }
        })
      });
    } catch(error) {
      console.error(error);
    }
  }

  resetPage = () => {
    this.setState({
      atendimentosSujeitoSelecionado: [],
      lastAtendimentoPage: false,
      atendimentosPageConfig: {
        pageSize: 30,
        pageNumber: 0,
      },
    });
  };

  handleChangeCheckbox = (item) => {
    const { atendimentoStore } = this.props;
    const { procedimentosModal } = atendimentoStore;

    atendimentoStore.procedimentosModal= procedimentosModal.map(procedimento => {
      let checked = procedimento.checked || false;
      return {
        ...procedimento,
        checked: procedimento.id === item.id ? !procedimento.checked : checked
      }
    })
  };

  onClickAgendarProcedimento = () => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;

    if (atendimentoStore.isOdontograma) {
      const procedimentosSelecionados = atendimentoStore.procedimentosModal.filter(item => item.checked).map(item => {
        return {
          procedimento: item.procedimento,
          procedimentoOdontologico: {
            id: item.id
          }
        }
      });
      
      const procedimentosNaoSelecionado = atendimentoStore.procedimentosModal.filter(item => !item.checked).map(item => {
        return {
          ...item,
          checked: false,
        }
      });
      atendimentoStore.procedimentosModal=procedimentosNaoSelecionado;

      atendimentoStore.objView.procedimentos = [...objView.procedimentos, ...procedimentosSelecionados]

    } else {
      atendimentoStore.isAgendarProcedimentoSolicitado = true;
      let procedimentosSelecionados = [];
      atendimentoStore.procedimentosModal.forEach((item) => {
        const { id, procedimento, checked } = item || {};

        if (checked) {
          procedimentosSelecionados.push({
            procedimento: procedimento,
            procedimentoSolicitado: { id: id },
          });
          atendimentoStore.procedimentosPedidosSelecionados = [{
            id: this.state.procedimentoPedidoId,
            procedimentosSolicitados: atendimentoStore.procedimentosPedidosSelecionados?.procedimentosSolicitados && atendimentoStore.procedimentosPedidosSelecionados.procedimentosSolicitados.concat({
              id: id,
            })
          }]
        }
      });
      this.changeTabSelected(0);
      this.props.agendarProcedimento(procedimentosSelecionados);
    }

    this.preencherCamposAgendamento();
  };

  preencherCamposAgendamento = () => {
    const { objView } = this.props.atendimentoStore;
    const { profissionalSaude, observacaoGeral } = this.state;

    if (profissionalSaude.id) {
      objView.solicitante = {
        id: profissionalSaude.id,
        nome: profissionalSaude.nome,
      };
    }
    objView.observacao = observacaoGeral;
  };

  openModalInfosAgendamento = (atendimento) => {
    this.props.atendimentoStore.infosConsultaSelecionada = atendimento;
    this.props.atendimentoStore.openInfosAgendamentoModal = true;
  };

  changeTabSelected = (tabSelected) => {
    
    this.setState({
      tabSelected
    })
  };

  render() {
    const { classes, onClose, atendimentoStore } = this.props;
    const { isModalSala } = atendimentoStore;
    const {
      atendimentosSujeitoSelecionado,
      observacaoGeral,
      tabSelected,
    } = this.state;

    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.gridFlexExpandedAgendamentos}
      >
        <HeaderClose
          title="Informações"
          onClose={onClose}
          classes={{ header: classes.headerMargin }}
        />

        <div className={classes.contentTab}>
          <MultiToggleButtons
            options={(atendimentoStore.utilizaControleSessao && !atendimentoStore.isTelaSessoes) ? ["Proce...", "Histórico", "Sessões"] : ['Procedimentos', 'Histórico']}
            tabSelected={tabSelected}
            changeTabSelected={this.changeTabSelected}
            classes={{ buttonsContainer: classes.buttonsTab }}
          />

          {tabSelected === tabs.HISTORICO && (
            <AtendimentoModalTabAgendamentos
              atendimentosSujeitoSelecionado={atendimentosSujeitoSelecionado}
              loadMore={this.loadAgendamentosBySujeitoAtencao}
              last={this.state.lastAtendimentoPage}
            />
          )}
          {tabSelected === tabs.PROCEDIMENTOS && !isModalSala && (
            <AtendimentoModalTabProcedimentos
              procedimentosSolicitados={atendimentoStore.procedimentosModal}
              handleChangeCheckbox={this.handleChangeCheckbox}
              onClickAgendarProcedimento={this.onClickAgendarProcedimento}
              observacaoGeral={observacaoGeral}
            />
          )}
          {tabSelected === tabs.SESSOES && atendimentoStore.utilizaControleSessao && (
            <AtendimentoModalTabSessoes />
          )}
          {tabSelected === tabs.PROCEDIMENTOS && isModalSala && (
            <AtendimentoModalListaProcedimentos removeTituloModal />
          )}
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(AtendimentoModalSujeitoAgendamentos);
