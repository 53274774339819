import React from "react";
import styled from "styled-components";

const Title = styled.label`
  color: #868686;
  font-size: ${(props) => props.fontSize || "12"}px;
`;

const FieldTitle = ({ title, fontSize }) => {
  return <Title fontSize={fontSize}> {title} </Title>;
};

export default FieldTitle;
