import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';

import ImageSlider from '../../components/ImageSlider';
import { loginBannersFullSize } from './LoginBanners';
import { findConteudosPublicitariosAtivosByTipo } from '../../services/PublicidadeService';

const styles = ({
  sliderContainer: {
    height: 'calc(100% - 64px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 768px)": {
      height: '100%',
    }
  },
  image: {
    heigth: '100%',
    width: '100%'
  }
});

const LoginImagesSlider = ({ classes }) => {
  const [banners, setBanners] = useState(loginBannersFullSize)

  const getConteudosPublicitarios = async () => {
    const response = await findConteudosPublicitariosAtivosByTipo("BANNER")
    setBanners([...banners, ...response])
  }

  // useEffect(() => {
  //   getConteudosPublicitarios()
  // }, [])

  return (
    <div className={classes.sliderContainer}>
      <ImageSlider images={loginBannersFullSize} />
    </div>
  );
};

export default withStyles(styles)(LoginImagesSlider);