import React from "react";
import moment from "moment";

const Consultas = ({ dados }) => {
  return (
    <>
      <div style={styles.titulo}>Agendamentos - Unidade {dados.unidade}</div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Status</div>
        <div style={styles.column}>Data</div>
        <div style={styles.column}>Horário</div>
        <div style={styles.column}>Profissional saúde</div>
        <div style={styles.column}>Sala</div>
        <div style={styles.column}>Assinatura</div>
      </div>
      <div>
        {dados.dadosRelatorio.map((item, index) => {
          return (
            <div key={index} style={styles.containerColuna}>
              <div style={styles.column}>
                {item.situacao}
              </div>
              <div style={styles.column}>
                {moment(item.data).format("DD/MM/YYYY")}
              </div>
              <div style={styles.column}>
                {item.horaInicio} - {item.horaFim}
              </div>
              <div style={styles.column}>{item.profissionalSaudeNome}</div>
              <div style={styles.column}>{item.sala}</div>
              <div style={styles.columnAssinatura} />
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
  },
  subTituloSpan: {
    marginLeft: "8px",
  },
  dataInicio: {
    marginRight: "16px",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  columnConvenio: {
    width: "60%",
    minWidth: "60%",
    marginBottom: "10px",
    marginRight: "3px",
  },
  column: {
    width: "20%",
  },
  columnAssinatura: {
    borderBottom: "1px solid #000",
    width: "20%",
  },
};

export default Consultas;
