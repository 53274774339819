import React, { useEffect, useState } from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import {
  findAllFinanceiroGuiaServico,
  findByIdFinanceiroGuiaServico,
  dadosGuiaServicoFaturamento,
} from "../../../services/GuiaDeServicosService";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import ButtonFloat from "../../../components/ButtonFloat";
import Notification from "../../../components/Notification";
import GuiaDeServicosModal from "./GuiaDeServicosModal";
import GuiaDeServicosModalListagem from "./GuiaServicoModalListagem";
import { listaConveniosAtivo } from "../../../services/ConvenioService";
import styles from "../../../assets/jss/pages/GuiaDeServicosStyles";
import GuiaServicosFaturamentoModal from "./GuiaServicosFaturamento/GuiaServicosFaturamentoModal";
import MensagemListaVazia from "../../../components/Mensagem/MensagemListaVazia";
import PageTitle from "../../../components/PageTitle/PageTitle";
import HeaderFinanceiro from "../../Financeiro/HeaderFinanceiro";

const columns = [
  {
    Header: "Nome",
    onlyIcon: true,
    getValue: (guiaServico) => {
      return guiaServico?.beneficiario?.sujeitoAtencao?.nome;
    },
    props: { component: "th" },
  },
  {
    Header: "Convenio",
    onlyIcon: true,
    getValue: (guiaServico) => {
      return guiaServico?.convenio?.descricao;
    },
  },
  {
    Header: "N° guia prestador",
    onlyIcon: true,
    getValue: (guiaServico) => {
      return guiaServico?.numeroGuiaPrestador;
    },
  },
  {
    Header: "N° guia principal",
    onlyIcon: true,
    getValue: (guiaServico) => {
      return guiaServico?.numeroGuiaPrincipal;
    },
  },
];

const primeiroDiaMesAtual = moment().startOf("month").format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment().endOf("month").format("YYYY-MM-DD");

const filterDefault = {
  convenio: "",
  search: "",
  dataInicial: primeiroDiaMesAtual,
  dataFinal: ultimoDiaMesAtual,
};

const GuiaServicos = observer(({ classes, guiaDeServicoStore }) => {
  const [guiaServico, setGuiaServico] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "Erro",
    type: "error",
  });
  const [guiaServicoSelected, setGuiaServicoSelected] = useState({});
  const [lastPage, setLastPage] = useState(false);
  const [modificationsPerPage, setModificationsPerPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filtros, setFiltros] = useState(filterDefault);
  const [openModalFaturamento, setOpenModalFaturamento] = useState(false);
  const [
    guiaServicoSelectedFaturamento,
    setGuiaServicoSelectedFaturamento,
  ] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [listasFilters, setListasFilters] = useState(null);

  const loadFinanceiroGuiaServico = (options) => {
    const pageableDTO = {
      pageNumber: options?.isSearching ? 0 : modificationsPerPage,
      pageSize: 30,
      sortDir: "DESC",
      sortField: "numeroGuiaPrestador"
    };

    const nextPage = modificationsPerPage + 1;
    options?.isClear && setGuiaServico([])
    const textSearch = options?.isClear ? "" : search;

    setIsLoading(true);
    findAllFinanceiroGuiaServico(
      pageableDTO,
      textSearch,
      moment(filtros?.dataFinal).format("YYYY-MM-DD"),
      moment(filtros?.dataInicial).format("YYYY-MM-DD"),
      filtros?.convenio?.id
    )
      .then(({ data: response }) => {
        const content = response.data.findAllFinanceiroGuiaServico.content;

        const guiasServico = content.map(item => {
          return {
            ...item,
            beneficiario: {
              sujeitoAtencao: {
                nome: item.beneficiario?.sujeitoAtencao?.nomeSocial || item.beneficiario.sujeitoAtencao.nome
              }
            }
          }
        })

        const lastPage = response.data.findAllFinanceiroGuiaServico.last;
        setGuiaServico(
          options?.isSearching ? guiasServico : [...guiaServico, ...guiasServico]
        );
        setLastPage(lastPage);
        setModificationsPerPage(nextPage);
      })
      .catch((error) => {
        openNotification("Erro ao listar guias de serviços", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setModificationsPerPage(0);
    setSearch(value);
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter" && search.length >= 3) {
      setGuiaServico([]);
      loadFinanceiroGuiaServico({ isSearching: true });
    }
  };

  const handleClickClearSearch = () => {
    setSearch("");
    setGuiaServico([]);
    loadFinanceiroGuiaServico({ isSearching: true, isClear: true });
  };

  const handleCloseModal = () => {
    guiaDeServicoStore.changeFieldsStore({
      isOpenModal: false,
      guiaServico: null,
    });
  };

  const handleOpenNewModal = async (id) => {
    if (id) {
      const response = await findByIdFinanceiroGuiaServico(id);
      const guiaServico = response.data.data.findByIdFinanceiroGuiaServico;

      const guiaServicoSelecionado = {
        ...guiaServico,
        beneficiario: {
          ...guiaServico.beneficiario,
          nome: guiaServico.beneficiario?.sujeitoAtencao?.nomeSocial || guiaServico.beneficiario?.nome
        }
      }
      setGuiaServicoSelected(guiaServicoSelecionado);
      setDisableButton(
        guiaServico.autorizacao && true
      );
    } else {
      setGuiaServicoSelected(null);
    }
    guiaDeServicoStore.changeFieldsStore({
      isOpenModal: true
    });
  };

  const openNotification = (message, variant = null) => {
    setNotification((prevState) => ({
      ...prevState,
      open: true,
      variant: variant,
      message: message,
    }));
  };

  const closeAlertMessage = () => {
    setNotification((prevState) => ({ ...prevState, open: false }));
  };

  const carregaListaFilters = async() => {
    const convenios = await listaConveniosAtivo();

    setListasFilters({
      convenio: convenios.data.data.convenios,
    });
  };

  const handleAplicar = () => {
    loadFinanceiroGuiaServico({ isSearching: true });
  };

  useEffect(() => {
    loadFinanceiroGuiaServico();
    carregaListaFilters();
    guiaDeServicoStore.guiaServico && setGuiaServicoSelected(guiaDeServicoStore.guiaServico)
  }, []);

  const handleFaturar = async () => {
    const guiaServicoSelecionado = await dadosGuiaServicoFaturamento(
      guiaServicoSelected.id
    );
    setGuiaServicoSelectedFaturamento(guiaServicoSelecionado);
    guiaDeServicoStore.changeFieldsStore({
      isOpenModal: false,
      guiaServico: null,
    });
    setOpenModalFaturamento(true);
  };

  const handleChangeInput = (dataInicial, dataFinal) => {
    setFiltros({
      ...filtros,
      dataInicial,
      dataFinal,
    });
  };

  const redefinirFiltros = () => {
    setFiltros({
      ...filtros,
      convenio: "",
    });
  };

  return (
    <div className={classes.container}>
      <PageTitle title="Financeiro - Guia de serviços" />
      <HeaderFinanceiro
         placeholder="Pesquisar"
         onChange={handleSearchChange}
         value={search}
         search={() => loadFinanceiroGuiaServico({ isSearching: true, isClear: true })}
         clearSearch={handleClickClearSearch}
         onKeyPress={onKeypressSearch}
         searchFinanceiroGuiaServico={() => loadFinanceiroGuiaServico({ isSearching: true, isClear: true })}
         handleChangeInput={handleChangeInput}
         redefinirFiltros={redefinirFiltros}
         screen={"GuiaDeServico"}
         filter={filtros}
         setFilter={setFiltros}
         listas={listasFilters}
         propsDate={{
          handleChange: handleChangeInput,
          handleAplicar: handleAplicar,
         }}
         withFilter
         title="Guia de serviços"
      />

      <div className={classes.wrapper}>
        <div className={classes.tableContainer}>
          {guiaServico.length > 0 && (
            <Scroll
              hasMore={!isLoading && !lastPage}
              pageStart={0}
              initialLoad={false}
              loadMore={() => loadFinanceiroGuiaServico()}
            >
              <Table
                dados={guiaServico}
                columns={columns}
                clickable={true}
                handleClick={(id) => handleOpenNewModal(id)}
              />
            </Scroll>
          )}
          {isLoading && guiaServico.length === 0 && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
          {
            !isLoading && guiaServico.length === 0 && (
              <MensagemListaVazia/>
            )
          }
          <ButtonFloat onClick={() => handleOpenNewModal()} />
        </div>
        {guiaServicoSelected?.id ? (
          <GuiaDeServicosModalListagem
           openModal={guiaDeServicoStore.isOpenModal}
            handleCloseModal={handleCloseModal}
            guiaServicoSelected={guiaServicoSelected}
            handleFaturar={handleFaturar}
            loadFinanceiroGuiaServico={loadFinanceiroGuiaServico}
            disableButton={disableButton}
            setDisableButton={setDisableButton}
          />
        ) : (
          <GuiaDeServicosModal
            handleCloseModal={handleCloseModal}
            openNotification={openNotification}
            loadFinanceiroGuiaServico={loadFinanceiroGuiaServico}
          />
        )}
      </div>
      {openModalFaturamento && (
        <GuiaServicosFaturamentoModal
          openModal={openModalFaturamento}
          setOpenModalFaturamento={setOpenModalFaturamento}
          handleCloseModal={handleCloseModal}
          guiaServicoSelected={guiaServicoSelectedFaturamento}
          openNotification={openNotification}
          loadFinanceiroGuiaServico={loadFinanceiroGuiaServico}
        />
      )}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.open}
        variant={notification.type}
        message={notification.message}
      />
    </div>
  );
});

const GuiaServicosWithStyles = withStyles(styles)(GuiaServicos);
export default inject("guiaDeServicoStore")(GuiaServicosWithStyles)
