import Api from '../config/api'

export const transferirAgendamentosPorProfissionalSaude = ({ 
  profissionalSaudeDestinoId,
  profissionalSaudeOrigemId, 
  agendamentosId, 
  horaInicio, 
  horaFim, 
  dataInicio, 
  dataFim, 
  diaDaSemana
}) => {
  return Api.post('', {
    query: `
      mutation(
        $profissionalSaudeDestinoId: Long,
        $profissionalSaudeOrigemId: Long,
        $agendamentosId: [Long], 
        $horaInicio: LocalTime, 
        $horaFim: LocalTime, 
        $dataInicio: LocalDate, 
        $dataFim: LocalDate, 
        $diaDaSemana:[DiaSemana]){
        transferirAgendamentosPorProfissionalSaude(
          profissionalSaudeDestinoId: $profissionalSaudeDestinoId,
          profissionalSaudeOrigemId: $profissionalSaudeOrigemId,
          agendamentosId: $agendamentosId,
          horaInicio: $horaInicio,
          horaFim: $horaFim,
          dataInicio: $dataInicio,
          dataFim: $dataFim,
          diaDaSemana: $diaDaSemana
        ){
          id
          nome
          data
          horaInicio
          horaFim
          profissionalSaude {
            id
            nome
          }
          situacao
        }
      }
    `,
    variables: {
      profissionalSaudeDestinoId,
      profissionalSaudeOrigemId,
      agendamentosId, 
      horaInicio, 
      horaFim, 
      dataInicio, 
      dataFim, 
      diaDaSemana 
    },
  });
};

export const updateAgendamentoTransferencia = async (variables) => {
  const response = await Api.post('', {
    query: `
      mutation($agendamento: AgendamentoInput) {
        updateAgendamentoTransferencia(agendamento: $agendamento) {
          id
          horaInicio
          horaFim
          profissionalSaude {
            id
          }
          data
        }
      }
    `,
    variables: {
      agendamento: {
        ...variables
      }
    }
  });

  const id = response?.data?.data?.updateAgendamentoTransferencia?.id;

  if (!id) {
    throw new Error(`Falha ao atualizar o agendamento do paciente ${variables.nome}`);
  }

  return id;
};