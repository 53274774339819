import React from "react";
import FiltroTipoDocumento from "../../FiltroTipoDocumento";
import PesquisarFlutuante from "../../../../components/Prontuario/PesquisarFlutuante";
import FiltroData from "../../FiltroData";
import OpcoesProntuario from "../../OpcoesProntuario";
import { Tooltip, Fab, withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import PrintIcon from "../../../../components/Icon/Print";
import colors from "../../../../template/Colors";
import ButtonDadosImportantes from "./ButtonDadosImportantes";
import classNames from "classnames";

const OptionsProntuario = ({
  classes,
  handleOpenReceituario,
  handleDocument,
  handleClickSADT,
  handleClickGuiaInternacao,
  handleClickSolicitacaoProcedimento,
  handleClickAnamnese,
  handleClickInformacoesImportantes,
  handleClickAvaliacaoAntropometrica,
  handleClickTriagem,
  handleOpenImprimir,
  prontuarioStore,
  hiddenProcedimentosButton,
  tabSelected,
  sujeitoAtencaoId,
}) => {
  const isOdontograma = tabSelected === 1;
  return (
    <>
      <div className={ classNames(classes.contentOptions, isOdontograma && classes.contentOptionsOdontograma)}>
        <div className={classes.contentFuncionalidades}>
          <OpcoesProntuario
            handleOpenReceituario={handleOpenReceituario}
            handleDocument={handleDocument}
            handleClickSADT={handleClickSADT}
            handleClickSolicitacaoProcedimento={
              handleClickSolicitacaoProcedimento
            }
            handleClickAnamnese={handleClickAnamnese}
            handleClickAvaliacaoAntropometrica={
              handleClickAvaliacaoAntropometrica
            }
            handleClickTriagem={handleClickTriagem}
            hiddenProcedimentosButton={hiddenProcedimentosButton}
            handleClickGuiaInternacao={handleClickGuiaInternacao}
            sujeitoAtencaoId={sujeitoAtencaoId}
          />
          {
            prontuarioStore.roleEntradaProntuarioRead &&
            <ButtonDadosImportantes
              handleClickInformacoesImportantes={
                handleClickInformacoesImportantes
              }
              disabled={!sujeitoAtencaoId}
            />
          }

        </div>

          {
            !isOdontograma &&
        <div className={classes.contentButtons}>
            <>
              <FiltroTipoDocumento />

              <Fab className={classes.buttonYellow} onClick={handleOpenImprimir}>
                <Tooltip title="Imprimir Prontuário" placement="left">
                  <PrintIcon color="#fff" />
                </Tooltip>
              </Fab>

              <PesquisarFlutuante />
              <FiltroData />
            </>
        </div>
          }
      </div>
    </>
  );
};

const styles = (theme) => ({
  contentOptions: {
    display: "flex",
    position: "fixed",
    right: "15px",
    flexDirection: "row",
    gap: "8px",
    bottom: "90px",
    alignItems: "flex-end",
  },
  contentOptionsOdontograma: {
    top: "40vh",
    zIndex: 1,
  },
  contentFuncionalidades: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  contentButtons: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  buttonYellow: {
    minWidth: "40px",
    width: "40px !important",
    minHeight: "40px",
    height: "40px !important",
    background: colors.commons.yellow,
    "&:hover": {
      background: "#e0a048",
    },
  },
});

const OptionsProntuarioWithStyle = withStyles(styles)(OptionsProntuario);
export default inject(
  "prontuarioStore",
  "receituarioStore",
  "SADTStore",
  "googleAnalyticsStore"
)(OptionsProntuarioWithStyle);
