import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const OrcamentoIcon = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M12.9999 3V8.625C12.9999 9.66053 13.8393 10.5 14.8749 10.5H20.4999V21.125C20.4999 22.1605 19.6604 23 18.6249 23H12.6784C13.6601 21.8113 14.2499 20.287 14.2499 18.625C14.2499 14.828 11.1718 11.75 7.37488 11.75C6.72486 11.75 6.09592 11.8402 5.49988 12.0088V4.875C5.49988 3.83947 6.33934 3 7.37488 3H12.9999ZM14.2499 3.3125V8.625C14.2499 8.97018 14.5297 9.25 14.8749 9.25H20.1874L14.2499 3.3125ZM12.9999 18.625C12.9999 21.7316 10.4815 24.25 7.37488 24.25C4.26828 24.25 1.74988 21.7316 1.74988 18.625C1.74988 15.5184 4.26828 13 7.37488 13C10.4815 13 12.9999 15.5184 12.9999 18.625ZM10.3168 16.3081C10.0727 16.064 9.67701 16.064 9.43294 16.3081L6.12488 19.6161L5.31682 18.8081C5.07274 18.564 4.67701 18.564 4.43294 18.8081C4.18886 19.0521 4.18886 19.4479 4.43294 19.6919L5.68294 20.9419C5.92701 21.186 6.32274 21.186 6.56682 20.9419L10.3168 17.1919C10.5609 16.9479 10.5609 16.5521 10.3168 16.3081Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OrcamentoIcon;
