import React, { useState, Suspense, lazy, useEffect, useReducer } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import { Fab, withStyles } from '@material-ui/core';

import PageTitle from '../../../../components/PageTitle/PageTitle';
import HeaderConfiguracoesButtonNovo from '../../Components/HeaderConfiguracoesButtonNovo';
import TabSelector from '../../../../components/TabSelector';

import { getHeaderColumns } from './constants/tableHeaderColumns';
import tabsOptions from './constants/tabsOptions';

import Scroll from '../../../../components/InfiniteScroll/Scroll';
import Table from '../../../../components/Table/Table';

import AddIcon from '../../../../components/Icon/AddIcon';
import ModalGuiaInternacao from './ModalGuiaInternacao';
import Notification from '../../../../components/Notification';
import { getUnidadeLogado } from '../../../../services/UsuarioService';
import { findAllGuiaInternacaoModeloAudit } from '../../../../services/AuditoriaService';

const Auditoria = lazy(() =>
  import('../../../../components/Auditoria/Auditoria')
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    background: '#f2f2f2',
    padding: '16px 24px 0 24px',
  },
  informationArea: {
    flex: 1,
    background: '#fff',
    borderRadius: '8px 8px 0 0',
  },
  tabsArea: {
    padding: '16px',
  },
  floatButton: {
    position: 'absolute',
    bottom: '85px',
    right: '30px',
  }
};

const ListaModelosGuiaInternacao = observer(({ classes, guiaInternacaoStore, auditoriaStore }) => {
  const [tabSelected, setTabSelected] = useState(0);
  const [isLoading, toggleLoading] = useReducer((state) => !state, false);
  const [openModeloModal, setOpenModeloModal] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const { modelosPageSettings, guiaInternacaoModelosList, notification } = guiaInternacaoStore;

  useEffect(() => {
    loadUnidadeLogada();
    return () => {
      reloadList();
    }
  }, []);

  const loadUnidadeLogada = async () => {
    const unidade = await getUnidadeLogado();
    guiaInternacaoStore.unidadeId = unidade?.id;
  }

  const handleSearch = async () => {
    try {
      toggleLoading()
      await guiaInternacaoStore.loadGuiaInternacaoModelos();
    } catch(error) {
      console.error(error);
    } finally {
      toggleLoading();
    }
  }
  
  const handleSearchWithKey = async (event) => {
    if (event.key === 'Enter') {
      await handleSearch();
    }
  }

  const handleClear = () => {
    guiaInternacaoStore.clearModelosPageSettings();
    handleSearch();
  };

  const handleChange = (event, field) => {
    guiaInternacaoStore.modelosPageSettings.pageNumber = 0;
    
    if (field === 'status') {
      guiaInternacaoStore.modelosPageSettings.status = event;
      handleSearch();
    } else {
      guiaInternacaoStore.modelosPageSettings[field] = 
      event?.target?.value || '';
    }
  };

  const changeGuiaInternacaoModeloStatus = async (modelo) => {
    if (!modelo) return
    
    const { id, ativo } = modelo;

    try {
      const { ativo: newStatus } = await guiaInternacaoStore.handleChangeGuiaInternacaoModeloStatus(id, ativo);
      guiaInternacaoStore.updateModifiedModelo(id, newStatus)
    } catch(error) {
      console.error(error);
    }
  };

  const handleClickModelo = (id, isDuplicate) => {
    guiaInternacaoStore.guiaInternacaoSelectedId = id;
    setOpenModeloModal(true);
    setIsDuplicate(isDuplicate);
  }

  const handleCreateNewModelo = () => {
    setOpenModeloModal(true);
  }

  const reloadList = async () => {
    guiaInternacaoStore.resetModelosPageSettings();
    await guiaInternacaoStore.loadGuiaInternacaoModelos();
  }

  const handleCloseModal = () => {
    setOpenModeloModal(false);
    setIsDuplicate(false);
  }

  const tableFunctions = {
    changeGuiaInternacaoModeloStatus,
    handleClickModelo,
  };

  const handleChangeTab = async (index) => {
    
    setTabSelected(index);
    if (index === 1) {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoriaTela: 'guiaInternacaoModelo',
        auditoria: `o modelo de guia de internação`,
        pageNumber: 0,
        lastPage: false,
        withWhomModified: true,
        auditorias: [],
      }

      auditoriaStore.load = {
        query: findAllGuiaInternacaoModeloAudit,
        variableName: "",
        variableId: "",
      }
      await auditoriaStore.loadAuditItems()
    }
  }

  const handleClickOrdenar = (value) =>{
    const { modelosPageSettings } = guiaInternacaoStore;
    guiaInternacaoStore.modelosPageSettings = {
      ...modelosPageSettings,
      pageNumber: 0,
      sortField: value,
      sortDir: modelosPageSettings.sortField === value && modelosPageSettings.sortDir === 'ASC' ? 'DESC' : 'ASC',
    };

    handleSearch();
  }

  return (
    <div className={classes.container}>
      <PageTitle title={'Guia de internação'} />
      <HeaderConfiguracoesButtonNovo
        title={'Guia de internação'}
        labelButton={'Criar nova guia'}
        handleClickNovo={handleCreateNewModelo}
        showStatus
        status={{
          options: [
            { value: 'true', label: 'Ativo' },
            { value: 'false', label: 'Inativo' },
          ],
          value: modelosPageSettings.status,
          onChange: value => handleChange(value, 'status'),
        }}
        search={{
          handleChange: (e) => handleChange(e, 'search'),
          value: modelosPageSettings?.search || '',
          onKeypress: handleSearchWithKey,
          handleClickClear: handleClear,
          handleClickButtonSearch: handleSearch,
        }}
      />
      <div className={classes.content}>
        <div className={classes.informationArea}>
          <div className={classes.tabsArea}>
            <TabSelector
              baseColor='#F2F2F2'
              selectedColor='#FFFFFF'
              onSelect={handleChangeTab}
              selectedTabIndex={tabSelected}
              tabsOptions={tabsOptions}
            />
          </div>
          {tabSelected === 0 && (
            <Scroll 
              isLoading={isLoading} 
              loadMore={!isLoading && handleSearch} 
              hasMore={!modelosPageSettings.last}
              initialLoad={true}
            >
              {guiaInternacaoModelosList.length > 0 && (
                <Table 
                  dados={guiaInternacaoModelosList} 
                  columns={getHeaderColumns(tableFunctions)}
                  handleClick={handleClickModelo} 
                  comOrdenacao
                  ordenarTabela={{ sortField: modelosPageSettings.sortField, sortDir: modelosPageSettings.sortDir }}
                  handleClickOrdenar={(value) => handleClickOrdenar(value)} 
                />
              )}
            </Scroll>
          )}
          {tabSelected === 1 && (
            <Suspense fallback={null}>
              <Auditoria 
                entityNameField='nome'
                modificationMessageWithValue
              />
            </Suspense>
          )}
        </div>
      </div>
      {openModeloModal && (
        <ModalGuiaInternacao 
          defaultEntity="guiaInternacaoModelo"
          isOpen={openModeloModal} 
          onClose={handleCloseModal}
          reloadList={reloadList}
          isDuplicate={isDuplicate}
        />
      )}
      <div className={classes.floatButton}>
        <Fab color="primary" onClick={handleCreateNewModelo}>
          <AddIcon size={18} />
        </Fab>
      </div>
      {notification.isOpen && (
        <Notification 
          {...notification} 
          close={guiaInternacaoStore.resetNotification}
        />
      )}
    </div>
  );
});

const ListaModelosGuiaInternacaoStyle = withStyles(styles)(ListaModelosGuiaInternacao);
const  stores = ["guiaInternacaoStore", "auditoriaStore"];

export default inject(...stores)(ListaModelosGuiaInternacaoStyle);
