import React from "react";
import { withStyles } from "@material-ui/core";
import colors from "../../../../template/Colors";
import RowUnificacao from "./RowUnificacao";

const ListaCadastroUnificacao = (props) => {
  const {
    classes,
    handleClickTrocaCadastroPrincipal,
    handleClickRemoveCadastro,
    destino,
    origens,
  } = props;

  return (
    <div className={classes.content}>
        <div className={classes.titleRow}> Cadastro principal </div> 
        <RowUnificacao
          cadastro={destino}
        />
        <div className={classes.titleRow}> Outros cadastros que serão unificados </div>
        <div className={classes.listaOrigens}>
        {
          origens.length > 0 && origens.map((item, index) => (
            <RowUnificacao 
              key={item.id}
              cadastro={item}
              posicao={index}
              handleClickTrocaCadastroPrincipal={() => handleClickTrocaCadastroPrincipal(item, index)}
              handleClickRemoveCadastro={() => handleClickRemoveCadastro(index)}
            />
          ))
        }
        </div>
    </div>
  );
};

const styles = {
  content: {    
    background: "#fff",
    borderRadius: '0px 0px 16px 16px',
    margin: "0 24px",
    padding: 24,
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    height: '45%',
  },
  titleRow: {
    color: colors.commons.gray7,
  },
  listaOrigens: {
    overflow: 'auto',
    height: '70%',
  }
};

export default withStyles(styles)(ListaCadastroUnificacao);
