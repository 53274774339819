import { action, observable } from 'mobx'
import {
  findAllControleSessao,
  findAllControleSessaoAgendamentoTipo,
  findAllControleSessaoProcedimento,
} from '../services/ControleSessaoService'

export default class ControleSessaoStore {
  @observable pacoteSessao = []
  @observable pageNumber = 0
  @observable hasMore = true
  @observable isLoading = false
  @observable totalElements = null
  @observable ordenarTabela = {
    sortDir: 'ASC',
    sortField: 'nomeSessao',
  }
  @observable sessoes = []
  @observable hasMoreSessoes = true
  @observable pageNumberSessoes = 0
  @observable isLoadingSessoes = false
  @observable ordenarTabelaSessoes = {
    sortDir: 'ASC',
    sortField: 'ordem',
  }
  @observable triggerReloadFindSelectedSessao = false

  @action handleClickOrdenar = value => {
    this.resetParamsPacote();
    const sortDir =
      this.ordenarTabela.sortField !== value
        ? 'ASC'
        : this.ordenarTabela.sortDir === 'ASC'
        ? 'DESC'
        : 'ASC'
    this.ordenarTabela = {
      sortDir: sortDir,
      sortField: value,
    }
  }

  @action resetParamsPacote = () => {
    this.pageNumber = 0
    this.isLoading = false
    this.hasMore = true
    this.totalElements = null
    this.pacoteSessao = []
  }

  @action async loadAllPacotesSessoes({ idSujeitoAtencao }, clear = false, vencido = null, emAberto = null) {
    try {
      if(this.isLoading) return
      this.isLoading = true;
      let pageNumber = this.pageNumber;
      let pacoteSessao = this.pacoteSessao;

      if (clear) {
        pageNumber = 0;
        pacoteSessao = [];
      }

      const response = await findAllControleSessao({
        sujeitoAtencaoId: idSujeitoAtencao,
        ...vencido !== null && ({ vencido }),
        ...emAberto !== null && ({ emAberto }),
        pageableDTO: {
          pageNumber,
          pageSize: 30,
          sortDir: this.ordenarTabela.sortDir,
          sortField: this.ordenarTabela.sortField,
        },
      })
      const { content, last, totalElements } = response

      this.pacoteSessao = [...pacoteSessao, ...content];
      this.hasMore = last
      this.pageNumber = pageNumber + 1
      this.totalElements = totalElements
      this.isLoading = false
    } catch (error) {
      this.pageNumber = 0
      this.isLoadingSessoes = false
      this.isLoading = false
      console.error(error)
    }
  }

  @action filterResponseSessoes = response => {
    const isProcedure = !!response[0]?.procedimento?.id
    const sessaoField = isProcedure
      ? 'controleSessaoProcedimentos'
      : 'controleSessaoAgendamentoTipos'
    const sessaoSubField = isProcedure ? 'procedimento' : 'agendamentoTipo'
    const genericField = 'controleSessaoItems'
    const genericSubField = 'sessaoItem'

    const genericResponse = response?.map(item => {
      const newItem = {
        ...item,
        controleSessao: {
          ...item.controleSessao,
          [genericField]:
            item.controleSessao[sessaoField]?.map(subItem => ({
              ...subItem,
              [genericSubField]: subItem[sessaoSubField] ? { ...subItem[sessaoSubField] } : null,
            })) || [],
        },
      }
      return newItem
    })
    return genericResponse
  }

  @action resetParamsSessoes = () => {
    this.pageNumberSessoes = 0
    this.isLoadingSessoes = false
    this.hasMoreSessoes = true
    this.sessoes = []
  }

  @action loadSessoes = async ({ idPacoteSessao, isClearable = false }) => {
    const variables = {
      controleSessaoId: idPacoteSessao,
      pageableDTO: {
        pageNumber: isClearable ? 0 : this.pageNumberSessoes,
        pageSize: 30,
        sortDir: this.ordenarTabelaSessoes.sortDir,
        sortField: this.ordenarTabelaSessoes.sortField,
      },
      ativo: true,
    }
    this.isLoadingSessoes = true
    try {
      const procedimentos = await findAllControleSessaoProcedimento(variables)
      const agendamentoTipos = await findAllControleSessaoAgendamentoTipo(variables)
      const { content: procedimentosContent, last: procedimentosLast } = procedimentos
      const { content: agendamentoTiposContent, last: agendamentoTiposLast } = agendamentoTipos
      const response = [...procedimentosContent, ...agendamentoTiposContent]
      const last = procedimentosContent.length > 0 ? procedimentosLast : agendamentoTiposLast
      const genericResponse = this.filterResponseSessoes(response)
      const uniqueSessoes = genericResponse.filter(sessao => {
        return !this.sessoes.some(existingSessao => existingSessao.id === sessao.id)
      })
      this.sessoes =
        this.pageNumberSessoes === 0 ? [...uniqueSessoes] : [...this.sessoes, ...uniqueSessoes]
      this.hasMoreSessoes = last
      this.pageNumberSessoes = this.pageNumberSessoes + 1
    } catch (error) {
      console.error(error)
      this.isLoadingSessoes = false
      this.pageNumberSessoes = 0
    } finally {
      this.isLoadingSessoes = false
    }
  }

  @action handleClickOrdenarSessoes = value => {
    const sortDir =
      this.ordenarTabelaSessoes.sortField !== value
        ? 'ASC'
        : this.ordenarTabelaSessoes.sortDir === 'ASC'
        ? 'DESC'
        : 'ASC'
    this.ordenarTabelaSessoes = {
      sortDir: sortDir,
      sortField: value,
    }
    this.pageNumberSessoes = 0
  }

  @action getQuantitySessoes = (data) => {
    const quantity = data?.filter(
      (item) => {
        return item?.agendamento?.id && item?.ativo
      }
    ).length
    const totalQuantity = data?.filter(
      (item) => {
        return item?.ativo
      }
    ).length
    return { quantity, totalQuantity }
  }
}
