import React, { useState } from "react";
import { withStyles } from "@material-ui/core/es";
import { CircularProgress, IconButton } from "@material-ui/core";
import VisibilityIcon from "../Icon/VisibilityIcon";
import VisibilityOffIcon from "../Icon/VisibilityOffIcon";

const ValorOculto = (props) => {
  const { classes, valor, handleGetValor, isOculta } = props;
  const [loading, setLoading] = useState(false);

  const handleOcultar = async () => {
    setLoading(true);
    await handleGetValor();
    setLoading(false);
  };

  return (
    <div className={classes.divValorOculto}>
      {isOculta ? (
        <>
          <div className={classes.valorOculto} />
          {loading ? (
            <div className={classes.circularProgress}>
              <CircularProgress size={18} />
            </div>
          ) : (
            <IconButton onClick={handleOcultar} disabled={loading}>
              <VisibilityOffIcon />
            </IconButton>
          )}
        </>
      ) : (
        <>
          <div className={classes.saldoAtual}> {valor} </div>
          <IconButton onClick={handleOcultar}>
            <VisibilityIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};

const styles = {
  valorOculto: {
    width: "100%",
    height: "30px",
    marginRight: 5,
    borderRadius: "5px",
  },
  divValorOculto: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& button": {
      padding: 5,
    },
  },
  circularProgress: {
    padding: 2,
    width: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  saldoAtual: {
    width: "100%",
  },
};

export default withStyles(styles)(ValorOculto);
