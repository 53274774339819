import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";


const InfoAdicionais = (props) => {
    const { classes } = props;

    return(
        <div className={classes.infoAdicionais}>
            <div className={classes.informacao}> {props.informacao} </div>
            <div>{props.children}</div>
        </div>
    )
    
}

const styles = () => ({
    infoAdicionais: {
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        height: 22,
        borderRadius: 100,
        background: '#F5F5F5',
        color: '#505050',
        padding: '5px 5px 5px 10px',
        margin: '0 10px',
        whiteSpace: 'nowrap',
    },
    informacao:{
        fontWeight: 700,
        paddingRight: 5,
    },
});

export default withStyles(styles)(InfoAdicionais);
