import Api from '../config/api'

export const findAllProfissionalSaude = variables => {
  const defaultSearchDTO = {
    pageSize: 30,
    pageNumber: 0,
    sortDir: 'ASC',
    sortField: 'nome',
  }

  return Api.post('', {
    query: `
          query ($searchDTO: SearchProfissionalSaudeDTOInput) {
            findAllProfissionalSaude(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
                cpf
                ativo
                email
                dataCadastro
                dataNascimento
                tipoRepasse
                valorRepasse
                tipoCalculoRepasse
                tempoAtendimento
                percentualRepasse
                procedimentos {
                  id
                  nome
                }
                agendamentoTipos {
                  id
                  descricao
                }
              }
            }
          }`,
    variables: {
      searchDTO: {
        ...defaultSearchDTO,
        ...variables,
      },
    },
  })
}

export const ativarProfissionalSaude = variables => {
  return Api.post('', {
    query: `
      mutation ($profissionalSaudeId: Long) {
        ativarProfissionalSaude(profissionalSaudeId: $profissionalSaudeId) {
          id
          ativo
        }
      }
    `,
    variables,
  });
};

export const inativarProfissionalSaude = variables => {
  return Api.post('', {
    query: `
      mutation ($profissionalSaudeId: Long) {
        inativarProfissionalSaude(profissionalSaudeId: $profissionalSaudeId) {
          id
          ativo
        }
      }
    `,
    variables,
  });
}

export const createUsuario = async variables => {
  const response = await Api.post('', {
    query: `
      mutation ($senha:String, $profissionalSaude:ProfissionalSaudeInput, $groups: [GroupInput]) {
        createUsuario(senha: $senha, profissionalSaude: $profissionalSaude, groups: $groups) {
          id
        }
      }
    `,
    variables,
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }

  return response.data.data.createSecretaria
}

export const updateUsuario = async variables => {
  const response = await Api.post('', {
    query: `
      mutation ($profissionalSaude: ProfissionalSaudeInput, $groups: [GroupInput]) {
        updateUsuario(profissionalSaude: $profissionalSaude, groups: $groups) {
          id
        }
      }
    `,
    variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }

  return response.data.data.updateUsuario;
};

export const findAllAuditoriaProfissional = async variables => {
  const response = await Api.post('', {
    query: `
    query ($pageableDTO:PageableDTOInput ){
      findAllProfissionalSaudeAuditByUnidadeId (pageableDTO: $pageableDTO){
        last
        content {
          ativoModified
          revtype
          nome 
            revisao {
              dataHoraModificacao
              usuario {
                nome
                fotoPerfil
              }
            }
        }
      }
    }
    `,
    variables,
  })
  return response.data.data.findAllProfissionalSaudeAuditByUnidadeId
}

export const configuracaoRepasseProfissionalSaude = async(variables) => {
  try {
    const response = await Api.post("", {
      query: `
        mutation ($profissionalSaudeId: Long, $tempoAtendimento: Long, $tipoCalculoRepasse: TipoCalculoRepasse, $valorRepasse: BigDecimal, $percentualRepasse: BigDecimal, $tipoRepasse: TipoRepasse) {
          configuracaoRepasseProfissionalSaude(profissionalSaudeId: $profissionalSaudeId, tempoAtendimento: $tempoAtendimento, tipoCalculoRepasse: $tipoCalculoRepasse, valorRepasse: $valorRepasse, percentualRepasse: $percentualRepasse, tipoRepasse: $tipoRepasse) {
            id
          }
        }
      `,
      variables
    });

    if (response?.data?.data) {
      return response?.data?.data?.configuracaoRepasseProfissionalSaude
    } else {
      throw new Error(response.data.errors[0])
    }

  } catch (error) {
    throw error;
  }

};

export const getUtilizaConfiguracaoRepasse = async() =>{
  const response = await Api.post('', {
    query: `
      query {
        findByUnidadeLogadaConfiguracaoUnidade{
          utilizaConfiguracaoRepasse
        }
      }
    `,
  })
  return response.data.data.findByUnidadeLogadaConfiguracaoUnidade.utilizaConfiguracaoRepasse;
}

export const vincularProcedimentosByProfissionalSaude = async(variables) => {
  const response = await Api.post('', {
    query: `
    mutation ($profissionalSaudeId: Long, $procedimentosId: [UUID]) {
      vincularProcedimentosByProfissionalSaude(profissionalSaudeId: $profissionalSaudeId, procedimentosId: $procedimentosId)
    }
    `,
    variables
  })
  return response.data.data.vincularProcedimentosByProfissionalSaude
}

export const vincularAgendamentosTipoByProfissionalSaude = async(variables) => {
  const response = await Api.post('', {
    query: `
    mutation ($profissionalSaudeId: Long, $agendamentoTipoId: [UUID]) {
      vincularAgendamentosTipoByProfissionalSaude(profissionalSaudeId: $profissionalSaudeId, agendamentoTipoId: $agendamentoTipoId)
    }
    `,
    variables
  })
  return response.data.data.vincularTipoConsultaByProfissionalSaude
}

export const findAllAgendamentoTipoPage = async(variables) => {
  const response = await Api.post('', {
    query: `
      query ($pageableDTO: PageableDTOInput, $ativo: Boolean, $search: String) {
        findAllAgendamentoTipoPage(ativo: $ativo,search: $search,pageableDTO: $pageableDTO) {
          last
          content {
            id
            nome:descricao
          }
        }
      }
    `,
    variables: variables,

  })
  return response.data.data.findAllAgendamentoTipoPage
}
