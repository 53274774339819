import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const WarningIcon = (props) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <g clip-path='url(#clip0_7912_129574)'>
        <path
          d='M1.16663 24.4997H26.8333L14 2.33301L1.16663 24.4997ZM15.1666 20.9997H12.8333V18.6663H15.1666V20.9997ZM15.1666 16.333H12.8333V11.6663H15.1666V16.333Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_7912_129574'>
          <rect width='28' height='28' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default WarningIcon
