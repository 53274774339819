import React from "react";
import { Link } from "react-router-dom";
import colors from "../../../template/Colors";

import { withStyles } from "@material-ui/core/styles/index";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";

const style = () => ({
  root: {
    fontFamily: 'Poppins',
    fontSize: "1em",
  },

  item: {
    display: "flex",
    width: "100%",
    color: colors.commons.gray7,
    paddingLeft: "18px",
    margin: "0px 0px 8px 11px",
    textDecoration: "none",
    boxSizing: "border-box",
    alignItems: 'center',
    height: 32,
    borderRadius: '100px 0px 0px 100px',
    fontWeight: 500,
  },

  selected: {
    background: 'rgba(38, 172, 169, 0.1)',
    color: colors.commons.gray9,
    fontWeight: 500,
    fontSize: '14px',
  }
});

const ItemSubMenu = props => {
  const { classes, title, to, location } = props;
  const selected = location.pathname === to;
  
  const className = classNames(classes.item, {
    [classes.selected]: selected
  });

  return (
    <Grid item container className={classes.root}>
      <Link to={to} className={className}>
        {title} 
      </Link>
    </Grid>
  );
};

export default withStyles(style)(ItemSubMenu);
