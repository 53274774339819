export const situacoes = [
  { value: "AGENDADO", name: "Agendado", cor: "#73C8F9", checked: true },
  { value: "CONFIRMADO", name: "Confirmado", cor: "#26ACAA", checked: true },
  { value: "AGUARDANDO", name: "Aguardando", cor: "#F9BE73", checked: true },
  { value: "ATENDENDO", name: "Atendendo", cor: "#717FFC", checked: true },
  { value: "ATENDIDO", name: "Atendido", cor: "#9871FC", checked: true },
  { value: "CANCELADO", name: "Cancelado", cor: "#FB7676", checked: true },
  { value: "EXCLUIDO", name: "Excluido", cor: "#FF6230", checked: true },
  { value: "BLOQUEADO", name: "Bloqueado", cor: "#566280", checked: true },
  { value: "FALTOU", name: "Faltou", cor: "#FC71B4", checked: true },
];
