import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PriorityIcon = props => {
  return (
    <svg
      width='2'
      height='10'
      viewBox='0 0 2 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M1 9.5C0.758333 9.5 0.552083 9.41458 0.38125 9.24375C0.210417 9.07292 0.125 8.86667 0.125 8.625C0.125 8.38333 0.210417 8.17708 0.38125 8.00625C0.552083 7.83542 0.758333 7.75 1 7.75C1.24167 7.75 1.44792 7.83542 1.61875 8.00625C1.78958 8.17708 1.875 8.38333 1.875 8.625C1.875 8.86667 1.78958 9.07292 1.61875 9.24375C1.44792 9.41458 1.24167 9.5 1 9.5ZM0.125 6.5V0.5H1.875V6.5H0.125Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PriorityIcon
