export const periodos = [
  { nome: "Dia", value: 0 },
  { nome: "Semana", value: 1 },
  { nome: "Mês", value: 2 },
  { nome: "Personalizado", value: 3 },
];

export const meses = [
  { nome: "Janeiro", value: 0 },
  { nome: "Fevereiro", value: 1 },
  { nome: "Março", value: 2 },
  { nome: "Abril", value: 3 },
  { nome: "Maio", value: 4 },
  { nome: "Junho", value: 5 },
  { nome: "Julho", value: 6 },
  { nome: "Agosto", value: 7 },
  { nome: "Setembro", value: 8 },
  { nome: "Outubro", value: 9 },
  { nome: "Novembro", value: 10 },
  { nome: "Dezembro", value: 11 },
];
