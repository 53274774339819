const styles = {
  fullSizePaper: {
    maxWidth: "385px",
    maxHeight: "523px",
    padding: "32px",
    height: '100%',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
  },
  titulo: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      fontWeight: 700,
      color: "#5F6368",
      fontSize: 18,
    },
  },
  contentStatusButton: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  toggleButtons: {
    margin: "8px 0",
  },
  titleField: {
    color: "#868686",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "4px",
  },
  inputValor: {
    width: "calc(100% - 10px)",
    background: "#F2F2F2",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  notchedOutlineSearch: {
    border: "none",
  },
  fieldDesconto: {
    alignSelf: "end",
  },
  fieldDisabled: {
    backgroundColor: "#f2f2f2",
    height: 32,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
  },
  selectParcelas: {
    width: "100%",
    "&>div>div": {
      height: 32,
      borderRadius: "8px",
      display: "flex",
      alignContent: "center",
      background: "#f2f2f2",
    },
  },
  inputPercentil: {
    height: 32,
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    background: "#f2f2f2",
  },
  inputRootPercentil: {
    border: "1px solid rgba(220, 220, 220, 0.2)",
    height: "30px",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  subtotal: {
    height: "32px",
    display: "flex",
    alignItems: "center",
  },
  subtotalValor: {},
  vencimento: {
    whiteSpace: "nowrap",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  descricao: {
    width: "100%",
    "& textarea": {
      height: "100%",
      borderRadius: "8px",
      width: "100%",
    },
    "&> div": {
      padding: 0,
    },
  },
  buttonsFooter: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
    gap: '10px',
  },
  cancelar: {
    width: '100%',
    cursor: 'pointer',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
  },
  contentLoading: {
    flex: 1,
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: 8
  },
  contentTab: {
    flex: 1,
    overflow: 'hidden',
  },
  infoParcelaContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  infoParcelaContent: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  }
};

export default styles;
