import { Checkbox } from "@material-ui/core";
import React from "react";
import string from "../../../../../../utils/string";
import InputMaskCurrency from "../../../../../../components/Input/InputMaskCurrency";

export const tiposModalOrcamento = [
  {
    label: "À vista",
    value: "A_VISTA",
  },
  {
    label: "A prazo",
    value: "A_PRAZO",
  },
];

export const tiposDescontoModalOrcamento = [
  {
    label: "Percentil",
    value: "PERCENTIL",
  },
  {
    label: "Valor",
    value: "VALOR",
  },
];

export const COLUMNS = ({
  handleCheckAll,
  allCheckBoxChecked,
  handleChangeCheck,
  handleValor,
  isDisabled,
  errors,
}) => [
  {
    Header: (
      <Checkbox
        color="primary"
        checked={allCheckBoxChecked()}
        onChange={() => handleCheckAll()}
        disabled={isDisabled}
      />
    ),
    isClick: true,
    getValue: (row) => {
      const { checked } = row;
      return (
        <Checkbox
          color="primary"
          checked={checked}
          onChange={() => handleChangeCheck(row)}
          disabled={isDisabled}
        />
      );
    },
  },
  {
    Header: "Procedimento",
    getValue: (row) => string.truncate(row.procedimento?.nome, 35),
  },
  {
    Header: "Local",
    getValue: (row) => {
      const local = row.dente
        ? row.dente.codigo
        : row.mucosaOral?.descricao;
      return local;
    },
  },
  {
    Header: "Valor",
    getValue: (row) => {
      const { valorUnitario, checked } = row;
      return <InputMaskCurrency
        id="inputValor"
        value={valorUnitario || ""}
        onChange={(e) => handleValor(e.target.value, row)}
        disabled={!checked || isDisabled}
        error={checked && !valorUnitario && errors.valor}
      />
    },
  },
];
