import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core";
import TabelaReceitas from "./TabelaReceitas";
import FiltroHeader from "../../../template/Header/FiltroHeader";
import HeaderConfiguracoes from "../../../components/Header/HeaderConfiguracoes";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";

@inject("configuracaoImpressaoStore")
@observer
class ListaConfiguracaoReceita extends React.Component {
    state = {
        modelosLoading: false,
    }
    componentDidMount() {
        this.loadModelos();
    };

    loadModelos = async () => {
        const { configuracaoImpressaoStore } = this.props;

        configuracaoImpressaoStore.findAllConfiguracaoImpressao();
    }

    handleEditar = (tipo) => {
        this.props.history.push(`receita/${tipo}/editar`)
    }

    render() {
        const { classes, configuracaoImpressaoStore } = this.props;

        return (
            <>
                <div>
                    <PageTitle title={pageConfiguracoes("Impressão")}/>
                    <HeaderConfiguracoes>
                        <FiltroHeader screen="CONFIGURACAO_RECEITA" showSecretarioLogado />
                    </HeaderConfiguracoes>
                    <div className={classes.contentList}>
                        <TabelaReceitas
                            loadModelos={() => this.loadModelos()}
                            modelosLoading={this.state.modelosLoading}
                            handleEditar={(tipo) => this.handleEditar(tipo)}
                            modelosImpressao={configuracaoImpressaoStore.modelosConfiguracao}
                        />

                    </div>
                </div>
            </>
        )
    }
};

export default withStyles(theme => ({
    headerProfile: {
        padding: '0 16px',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contentList: {
        display: 'flex',
        flexDirection: 'row',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
    },
    buttonNovoModelo: {
        width: 152,
        height: 40,
        borderRadius: 87,
        boxShadow: 'none',
        background: '#F9BE73',
        color: '#fff',
        textTransform: 'capitalize',
        padding: '0 14px',
        marginLeft: 24,
        minWidth: 152,
        "@media (max-width: 1600px)": {
            width: 40,
            minWidth: 40,
            borderRadius: '100%',
        }
    },
}))(ListaConfiguracaoReceita);
