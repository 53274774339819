import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import { inject } from "mobx-react";
import moment from "moment";
import classNames from "classnames";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Checkbox,
  Fade,
  Popper,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import Scroll from '../../../components/InfiniteScroll/Scroll';
import {applyCurrencyMask} from "../../../utils/CurrencyMask";
import polygonUp from "../../../assets/img/svg/polygon-up.svg";
import polygonDown from "../../../assets/img/svg/polygon-down.svg";
 


const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#219A97",
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  body: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
    color: '#505050'
  },
}))(TableCell);

const arrowIcon = () => {

  return(
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <img
          src={polygonUp}
          alt={"Polygon up Icon"}
      />
      <img
          src={polygonDown}
          alt={"Polygon down Icon"}
      />
    </div>
  )
}

const ListaProcedimentosRealizados = (props) =>{
  const{
    classes,
    loadMore,
    lastScroll,
    handleClickOrdenar,
    handleClickRow,
    isLoading,
    procedimentosRealizadosStore,
    handleChangeCheckbox,
    handleClickConvenioValor,
    onClickFaturar,
    ordenarTabela,
    tipoProfissionalLogado,
    sujeitoAtencaoStore,
  } = props;

  const [openBotaoFaturar, setOpenBotaoFaturar ] = useState(false);
  const [anchorEl, setAnchorEl ] = useState(null);
  const [placement, setPlacement ] = useState(null);

  const isProfissionalLogadoTecnico = tipoProfissionalLogado === "tecnico";

  const handleClickMostrarBotaoFaturar = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenBotaoFaturar((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);

  };

  const handleCloseMore = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setOpenBotaoFaturar(false);
  };

  const renderIconOrder = (field) => (
    ordenarTabela.sortField === field ? (
      ordenarTabela.sortDir === "DESC" ? (
        <ArrowDropDownIcon />
        ) : (
        <ArrowDropUpIcon />
      )
    ) : arrowIcon()
  );

  const labelStatus = item => {
    if (item.ativo){
      return !!item?.titulo?.id ? "Faturado" : "A faturar"
    } else {
      return "Cancelado"
    }

  };

  const isBorderBottom = item => {
    return !item.titulo?.id && item.ativo && !isProfissionalLogadoTecnico
  }

  return (
    <Paper className={classes.root}>
      <Scroll
        loadMore={loadMore}
        hasMore={!lastScroll}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainerModelos}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {!isProfissionalLogadoTecnico && <CustomTableCell align="center" className={classes.tableHeadCheckBox} >
                <div
                  className={classes.selecionaFaturar}
                >
                  <div 
                    className={classes.arrowDrop}
                    onClick={!sujeitoAtencaoStore.isSujeitoAtencaoUnificado && handleClickMostrarBotaoFaturar("bottom-start")}  
                  >
                    <ArrowDropDownIcon className={classes.arrowDropIcon} />
                  </div>
                </div>
              </CustomTableCell>}
              <CustomTableCell align="center" className={classes.tableHead} >STATUS</CustomTableCell>
              <CustomTableCell className={classes.tableHead} >
                PROCEDIMENTO REALIZADO
                <IconButton
                  aria-label="expand row"
                  size="small"
                  className={classes.botaoOrganizar}
                  onClick={() => handleClickOrdenar("procedimento")}
                >
                  {renderIconOrder("procedimento")}
                </IconButton>
              </CustomTableCell>
              <CustomTableCell align="center" className={classes.tableHead} >
                SALA
                <IconButton
                  aria-label="expand row"
                  size="small"
                  className={classes.botaoOrganizar}
                  onClick={() => handleClickOrdenar("sala")}
                >
                   {renderIconOrder("sala")}
                </IconButton>
              </CustomTableCell>
              <CustomTableCell align="center" className={classes.tableHead} >CONVÊNIO</CustomTableCell>
              <CustomTableCell align="center" className={classes.tableHead} >VALOR</CustomTableCell>
              <CustomTableCell align="center" className={classes.tableHead} >RECEBEDOR</CustomTableCell>
              <CustomTableCell align="center" className={classes.tableHead} >
                DATA
                <IconButton
                  aria-label="expand row"
                  size="small"
                  className={classes.botaoOrganizar}
                  onClick={() => handleClickOrdenar("dataHoraLancamento")}
                >
                  {renderIconOrder("dataHoraLancamento")}
                </IconButton>
              </CustomTableCell>
              <CustomTableCell align="center" className={classes.tableHead} >SOLICITANTE</CustomTableCell>
              <CustomTableCell align="center" className={classes.tableHead} >
                RESPONSÁVEL
                <IconButton
                  aria-label="expand row"
                  size="small"
                  className={classes.botaoOrganizar}
                  onClick={() => handleClickOrdenar("profissionalSaude")}
                >
                  {renderIconOrder("profissionalSaude")}
                </IconButton>
              </CustomTableCell>
            </TableRow>
            <Popper
              open={openBotaoFaturar}
              anchorEl={anchorEl}
              placement={placement}
              transition
              className={classes.menu}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Paper className={classes.paperBotaoMore}>
                    <ClickAwayListener onClickAway={handleCloseMore}>
                      <MenuItem
                        className={classes.buttonFaturar}
                        onClick={onClickFaturar}
                      >
                        Faturar
                      </MenuItem>
                    </ClickAwayListener>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </TableHead>

          <TableBody>
            {procedimentosRealizadosStore.lista.map((item) => (
              <TableRow
                className={classNames(classes.row, {
                  [classes.rowItemFaturado]: !!item.titulo?.id,
                  [classes.rowItemInativo]: !item.ativo
                })}
                key={item.id}
              >
                {!isProfissionalLogadoTecnico && <CustomTableCell component="th" scope="row" align="left" className={classes.bodyCheckBox}>
                  <Checkbox
                    className={classes.checkBox}
                    color={"primary"}
                    onChange={(e) => handleChangeCheckbox(item)}
                    disabled={!!item?.titulo?.id || !item.ativo ||sujeitoAtencaoStore.isSujeitoAtencaoUnificado}
                  />
                </CustomTableCell>}
                <CustomTableCell onClick={handleClickRow(item.id)}>
                  { labelStatus(item) }
                </CustomTableCell>
                <CustomTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.nameAtivo}
                  onClick={handleClickRow(item.id)}
                >
                  {item.procedimento.nome}
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={classes.colunaSala}
                  onClick={handleClickRow(item.id)}
                >
                  <div
                    style={{
                      color: "#fff",
                      padding: "0 10px",
                      backgroundColor: item.ativo ? "#26ACA9" : 'rgba(50, 50, 50, 0.2)',
                      borderRadius: 50,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "115px",
                    }}
                  >
                    {item?.sala?.nome}
                  </div>
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  onClick={isProfissionalLogadoTecnico ? handleClickRow(item.id) : () => handleClickConvenioValor(item)}
                  className={item.titulo?.id || !item.ativo ? classes.colunaDisabled : classes.colunaConvenio}
                >
                  <div  className={isBorderBottom(item) && classes.borderBottomField}>
                    {item?.convenio?.descricao ||
                      item?.sujeitoAtencao?.dadosConvenio?.convenio?.descricao}
                  </div>
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={!!item.titulo?.id || !item.ativo ? classes.colunaDisabled : classes.colunaValor}
                  onClick={isProfissionalLogadoTecnico ? handleClickRow(item.id) : () => handleClickConvenioValor(item)}
                >
                  <div className={isBorderBottom(item) && classes.borderBottomField} >
                    {
                      applyCurrencyMask(
                        item.valor === 0 ?
                        0
                        :
                        item?.valor ||
                          item.procedimento.convenioProcedimentos.filter(
                            (itemProcedimento) =>
                              itemProcedimento?.convenio?.descricao ===
                              item?.sujeitoAtencao?.dadosConvenio?.convenio
                                ?.descricao
                          )[0]?.valor
                      )
                    }
                  </div>
                </CustomTableCell>
                <CustomTableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={!!item.titulo?.id || !item.ativo ? classes.colunaDisabled : classes.colunaValor}
                  onClick={isProfissionalLogadoTecnico ? handleClickRow(item.id) : () => handleClickConvenioValor(item)}
                >
                   <div  className={isBorderBottom(item) && classes.borderBottomField}>
                    { item?.recebedor?.nome }
                  </div>
                </CustomTableCell>
                <CustomTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.nameAtivo}
                  onClick={handleClickRow(item.id)}
                >
                  {moment(item.dataHoraLancamento).format("DD/MM/YYYY")}
                </CustomTableCell>
                <CustomTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.nameAtivo}
                  onClick={handleClickRow(item.id)}
                >
                  {item?.solicitante?.nome}
                </CustomTableCell>
                <CustomTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.nameAtivo}
                  onClick={handleClickRow(item.id)}
                >
                  {item?.profissionalSaude?.nome}
                </CustomTableCell>
              </TableRow>
            ))}
            {isLoading && (
              <div className={classes.notFoundContainer}>
                <CircularProgress />
              </div>
            )}
          </TableBody>
        </Table>
      </Scroll>
    </Paper>
  );
};

const styles = theme => ({
  root: {
    width: '100%',
    fontFamily: 'Poppins',
    height: '100%',
  },
  scrollContainerModelos: {
    height: '100%'
  },
  table: {
    minWidth: 700,
    maxHeight: 700,
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    minWidth: 65
  },
  tableHeadCheckBox:{
    position: 'sticky',
    zIndex: 1,
    top: 0,
    padding: '0 15px 0 24px'
  },
  bodyCheckBox: {
    padding: '0 15px 0 24px'
  },
  menu:{
    zIndex: 1
  },
  botaoOrganizar: {
    color: 'white',
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  row: {
    maxHeight: 57,
    cursor: 'pointer',
  },
  rowItemFaturado:{
    backgroundColor: '#F5F5F5',
  },
  rowItemInativo:{
    backgroundColor: '#CDCDCD',
    "& >th": {
      color: "rgba(50, 50, 50, 0.5)"
    },
    "& >td": {
      color: "rgba(50, 50, 50, 0.5)",
    },
  },
  nameAtivo: {
    color: '#505050',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200,
  },
  colunaSala: {
    textAlign: '-webkit-center',
  },
  menuMore: {
    color: '#505050',
    fontSize: 16,
    width: 140,
  },
  iconMenuMore:{
    marginRight: 10,
  },
  paperBotaoMore: {
    borderRadius: 8,
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    margin: 10,
    position: 'relative',
    left: '35vw',
  },
  colunaValor: {
    minWidth: '80px',
  },
  colunaConvenio:{
    minWidth: '100px',
  },
  selecionaFaturar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
    alignItems: 'center',
  },
  arrowDropIcon: {
    marginBottom: '-4px',
  },
  arrowDrop: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  buttonFaturar:{
    fontWeight: 600,
  },
  colunaDisabled: {
    color: "rgba(50, 50, 50, 0.5)",
    cursor: 'auto',
  },
  borderBottomField: {
    borderBottom: "1px solid #505050"
  }
});

const CustomizedTableWithStyles = withStyles(styles)(ListaProcedimentosRealizados);
export default inject("procedimentosRealizadosStore", "sujeitoAtencaoStore")(CustomizedTableWithStyles)
