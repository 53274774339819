import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import moment from 'moment'

import Table from '../../../components/Table/Table'
import Scroll from '../../../components/InfiniteScroll/Scroll'
import HeaderRelatorio from '../HeaderRelatorio'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { InputDateForm } from '../../../components/Modal/Input'
import { Button } from '../../../components/ui/Buttons'
import PrintIcon from '../../../components/Icon/Print'
import { TextFieldSearch } from '../../../components/TextField'
import ImpressaoHtml from '../../../components/Impressao/ImpressaoHtml'

import {
  countProcedimentosSolicitados,
  findAllProcedimentoSolicitado,
  relatorioProcedimentosSolicitadosCsv,
  totalProcedimentosSolicitados,
} from '../../../services/RelatorioService'
import { findAllProcedimentos } from '../../../services/ProcedimentoService'
import { findAllProfissionalSaude } from '../../../services/ProfissionalSaudeService'
import ProcedimentosSolicitados from '../../../template/pdf/relatorio/ProcedimentosSolicitados'

import ErrorCollector from '../../../utils/ErrorCollector'

import colors from '../../../template/Colors'
import ArrowDownloadIcon from '../../../components/Icon/ArrowDownload'
import { base64Convert } from '../../../utils/base64ToCsv'
import { inject } from 'mobx-react'
import Notification from '../../../components/Notification'
import { observer } from 'mobx-react-lite'

const columns = [
  {
    Header: 'Profissionais',
    getValue: item => {
      return item?.profissionalSaude?.nome
    },
    field: 'procedimentoPedido.profissionalSaude.nome',
  },
  {
    Header: 'Data',
    getValue: item => {
      return moment(item?.dataHoraLancamento).format("DD/MM/YYYY HH:mm")
    },
    field: 'dataHoraLancamento',
  },
  {
    Header: 'Procedimento',
    getValue: item => {
      return item?.procedimento?.nome
    },
    field: 'procedimento.nome',
  },
  {
    Header: 'Agendado',
    getValue: item => {
      return item.agendado ? 'Sim' : 'Não'
    },
    field: 'agendado',
  },
]

const dataAtual = moment();
const primeiroDiaDoMes = dataAtual.startOf("month");
const ultimoDiaDoMes = dataAtual.clone().endOf("month");

const RelatorioProcedimentoSolicitado = observer(({ classes, relatorioStore })=> {
  const [procedimentosSolicitados, setProcedimentosSolicitados] = useState([])
  const [filters, setFilters] = useState({
    profissional: null,
    procedimento: null,
    dataInicial: primeiroDiaDoMes,
    dataFinal: ultimoDiaDoMes,
  })
  const [hasMore, setHasMore] = useState(true)
  const [pageNumber, setPageNumber] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [ordenarTabela, setOrdenarTabela] = useState({
    sortDir: 'ASC',
    sortField: 'dataHoraLancamento',
  })
  const [dadosRelatorio, setDadosRelatorio] = useState([])
  const [isPrintMustache, setIsPrintMustache] = useState(false)
  const [totalProcendimentos, setTotalProcedimentos] = useState(0)
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [totalElements,setTotalElements]=useState(0)

  const getFiltersValues = () => {
    return {
      ...(filters.dataInicial && {
        dataHoraInicio: moment(filters.dataInicial).format('YYYY-MM-DDT00:00:00'),
      }),
      ...(filters.dataInicial && {
        dataHoraFim: moment(filters.dataFinal || filters.dataInicial).format('YYYY-MM-DDT00:00:00'),
      }),
      ...(filters.procedimento && { procedimentoId: filters.procedimento.id }),
      ...(filters.profissional && { profissionalSaudeId: filters.profissional.id }),
    }
  }
  const findProcedimentosSolicitados = async options => {
    setIsLoading(true)
    try {
      const variables = {
        pageableDTO: {
          pageNumber: options?.isClearable ? 0 : pageNumber,
          pageSize: options?.totalElements || 30,
          sortDir: ordenarTabela.sortDir,
          sortField: ordenarTabela.sortField,
        },
        ...getFiltersValues(),
      }
      const response = await findAllProcedimentoSolicitado(variables)
      setProcedimentosSolicitados(prevState => {
        return options?.isClearable ? response.content : [...prevState, ...response.content]
      })
      setHasMore(!response.last)
      setPageNumber(options?.isClearable ? 0 : pageNumber + 1)
      setTotalElements(response.totalElements)
      return response
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    }
    const searchDTO = {
      pageableDTO,
      nome: search,
    }

    try {
      const { content, last } = (
        await findAllProcedimentos(searchDTO)
      ).data.data.findAllProcedimentos
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { mostrarApenasProfissionaisAtivo } = relatorioStore.configuracaoUnidade || {};
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
          ativo: mostrarApenasProfissionaisAtivo,
        })
      ).data.data.findAllProfissionalSaude
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getTotalProcedimentos = async () => {
    try {
      const procedimentosSolicitados = await countProcedimentosSolicitados({
        ...getFiltersValues(),
      })
      setTotalProcedimentos(procedimentosSolicitados)
      return procedimentosSolicitados
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickOrdenar = async value => {
    const sortDir = ordenarTabela.sortDir === 'ASC' ? 'DESC' : 'ASC'
    setOrdenarTabela({
      sortDir,
      sortField: value,
    })

    await findProcedimentosSolicitados({ isClearable: true })
  }

  const handlePrint = async () => {
    const { dataInicial, dataFinal } = filters;

    const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)

    if (!isValid) {
      relatorioStore.openNotification("O limite de trinta dias foi excedido!", "error")
      return;
    };

    setLoadingPrint(true)
    try {
      const res = await findProcedimentosSolicitados({
        isClearable: true,
        totalElements: totalElements,
      })
      setDadosRelatorio(res.content)
    } catch (error) {
      console.error(error)
    } finally {
      setIsPrintMustache(true)
      setLoadingPrint(false)
    }
  }

  const handleDownloadCSV = async () => {
    const { dataInicial, dataFinal } = filters;

    const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)
    if (!isValid) {
      relatorioStore.openNotification("O limite de trinta dias foi excedido!", "error")
      return;
    };

    const base64 = await relatorioProcedimentosSolicitadosCsv({
      ...getFiltersValues(),
    })
    if (base64 === 'error') return
    const blob = base64Convert(base64)
    const url = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'relatorioAgendamentosSituacao.csv')
    document.body.appendChild(link)
    link.click()
  }

  const handleChangeFilters = (value, name) => {
    setFilters({ ...filters, [name]: value })
  }

  useEffect(() => {
    findProcedimentosSolicitados({ isClearable: true })
    getTotalProcedimentos()
  }, [ordenarTabela])

  const handleClickSearch = () => {

    if (filters.dataInicial && filters.dataFinal) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(filters.dataInicial, filters.dataFinal)
      if (isValid) {
        findProcedimentosSolicitados({ isClearable: true })
        getTotalProcedimentos()
      } else {
        relatorioStore.openNotification("O limite de trinta dias foi excedido!", "error")
      }
    }
  }

  const disabledButtonFilter = !filters.dataInicial || !filters.dataFinal || isLoading
  return (
    <div className={classes.content}>
      <PageTitle title='Relatórios - Procedimentos solicitados' />
      <HeaderRelatorio
        title='Procedimentos solicitados'
        screen='RelatorioProcedimentosSolicitados'
        totalTitle='Procedimentos'
        classes={{
          paper: classes.paper,
        }}
        hiddenSearch
        hiddenButtons
        totalAgendamentos={totalProcendimentos}
      />
      <div className={classes.contentFiltros}>
        <div className={classes.filtros}>
          <Grid item xs={3} className={classes.spacingConvenio}>
            <span className={classes.tituloFiltros}> Data Inicio: </span>
            <InputDateForm
              iconposition='end'
              openTo='day'
              views={['year', 'month']}
              value={filters.dataInicial || ''}
              onChange={e => handleChangeFilters(e, 'dataInicial')}
              classes={{
                input: classes.inputData,
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.spacingConvenio}>
            <span className={classes.tituloFiltros}> Data Fim: </span>
            <InputDateForm
              iconposition='end'
              openTo='day'
              views={['year', 'month']}
              value={filters.dataFinal || ''}
              onChange={e => handleChangeFilters(e, 'dataFinal')}
              classes={{
                input: classes.inputData,
              }}
            />
          </Grid>
          <div className={classes.wrapperFilters}>
            <span className={classes.tituloFiltros}> Procedimento: </span>
            <TextFieldSearch
              placeholder='Selecione'
              classNotched={classes.notchedOutline}
              classInput={classes.inputContainer}
              classIcons={classes.classIcons}
              loadOptions={loadAllProcedimentos}
              getOptionLabel={option => option.nome}
              getOptionValue={option => option}
              value={filters.procedimento}
              onChange={e => handleChangeFilters(e, 'procedimento')}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </div>
          <div className={classes.wrapperFilters}>
            <span className={classes.tituloFiltros}> Profissional: </span>
            <TextFieldSearch
              placeholder='Selecione'
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              classes={{ paper: classes.menuHeight }}
              loadOptions={loadProfissionaisSaude}
              getOptionLabel={option => option?.nome}
              getOptionValue={option => option?.id}
              value={filters.profissional}
              onChange={e => handleChangeFilters(e, 'profissional')}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </div>
        </div>
        <Button
          shape='pill'
          bgColor='#707C97'
          onClick={handleClickSearch}
          disabled={disabledButtonFilter || isLoading}
        >
          Filtrar
        </Button>
      </div>
      <ErrorCollector mensagem={'Não foi possível carregar o relatório!'}>
        <div className={classes.listaAgendamentoSituacao}>
          <Scroll
            loadMore={findProcedimentosSolicitados}
            hasMore={hasMore}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainerModelos}
          >
            {procedimentosSolicitados.length === 0 && !isLoading && (
              <div className={classes.notFoundContainer}>
                <h3>Nenhum item encontrado</h3>
              </div>
            )}

            {procedimentosSolicitados?.length > 0 && (
              <Table
                className={classes.table}
                dados={procedimentosSolicitados || []}
                columns={columns}
                comOrdenacao
                ordenarTabela={ordenarTabela}
                handleClickOrdenar={handleClickOrdenar}
              />
            )}

            {isLoading && (
              <Grid
                container
                justify='center'
                alignItems='center'
                style={{ height: '100%', marginTop: 20 }}
              >
                <CircularProgress size={30} />
              </Grid>
            )}
          </Scroll>
        </div>
      </ErrorCollector>
      <div className={classes.buttonsDownloadPrint}>
        <Button
          id='exportCsv'
          shape='circle'
          bgColor='#F9BE73'
          onClick={handleDownloadCSV}
          disabled={procedimentosSolicitados.length === 0}
        >
          <ArrowDownloadIcon />
        </Button>
        <Button
          shape='circle'
          bgColor='#F9BE73'
          onClick={handlePrint}
          disabled={procedimentosSolicitados.length === 0}
          isLoading={loadingPrint}
        >
          <PrintIcon />
        </Button>
      </div>
      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={
            <ProcedimentosSolicitados dadosRelatorio={dadosRelatorio || []} filters={filters} />
          }
        />
      )}
      <Notification
        isOpen={relatorioStore.notification.isOpen}
        variant={relatorioStore.notification.variant}
        message={relatorioStore.notification.message}
      />
    </div>
  )
})

const styles = theme => ({
  content: {
    display: 'grid',
    gridTemplateRows: '80px 71px 1fr',
    position: 'relative',
    overflow: 'hidden',
    background: '#f5f5f5',

    '& thead th': {
      textAlign: 'left',
      position: 'sticky',
      top: 0,
    },
  },

  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    background: '#fff',

    '& h3': {
      color: colors.commons.gray10,
      fontWeight: 'normal',
      fontSize: '1rem',
    },
  },

  inputData: {
    color: colors.commons.fontColor,
    paddingTop: 0,
  },

  contentFiltros: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '13px 16px 8px 16px',
    boxShadow: '10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)',
    background: '#fff',
    zIndex: 1,
    marginTop: 1,
    justifyContent: 'space-between',
  },

  filtros: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
  },

  tituloFiltros: {
    color: colors.commons.gray7,
    fontSize: '12px !important',
  },

  buttonsDownloadPrint: {
    display: 'flex',
    position: 'absolute',
    bottom: '20px',
    right: '14px',
    width: '88px',
    justifyContent: 'space-between',
  },
  listaAgendamentoSituacao: {
    overflow: 'auto',
    height: '100%',
    margin: '16px',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    background: '#fff',
    '&> div': {
      height: '100%',
      '&> div': {
        height: '100%',
      },
    },
  },

  popper: {
    zIndex: 20,
    width: 'fit-content',
    left: '-20px',
  },

  spacingConvenio: {
    maxWidth: 175,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  wrapperFilters: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  paper: {
    width: '252px',
    right: '-20px',
    left: 'auto',
  },
  notchedOutline: {
    border: '0',
  },
  inputContainer: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '30px',
    width: '100%',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  classIcons: {
    fontSize: 24,
  },
})

const RelatorioProcedimentoSolicitadoWithStyles = withStyles(styles)(RelatorioProcedimentoSolicitado)
export default inject("unidadeStore","relatorioStore")(RelatorioProcedimentoSolicitadoWithStyles)
