import Api from '../config/api'

export const saveGuiaInternacaoModelo = async (guiaInternacaoModelo, method) => {
  const response = await Api.post("", {
    query: `
      mutation($guiaInternacaoModelo: GuiaInternacaoModeloInput) {
        ${method}(guiaInternacaoModelo: $guiaInternacaoModelo) {
          id
        }
      }
    `,
    variables: {
      guiaInternacaoModelo
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data[method];
};

export const changeGuiaInternacaoModeloStatus = async (id, status) => {
  const method = status ? "inativarGuiaInternacaoModelo" : "ativarGuiaInternacaoModelo";
  const response = await Api.post("", {
    query: `
      mutation($id: UUID) {
        ${method}(id: $id) {
          id
          ativo
        }
      }
    `,
    variables: {
      id
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data[method];
}

export const findByIdGuiaInternacaoModelo = async (id) => {
  const response = await Api.post("", {
    query: `
      query($id: UUID){
        findByIdGuiaInternacaoModelo(id: $id) {
          id
          ativo
          nome
          indicacao
          observacao
          quantidadeUso
          hasOPME
          quimioterapico
          profissionalSaude {
            value:id
            label:nome
          }
          guiaInternacaoModeloTussViews {
            id
            descricao
            quantidadeSolicitada
            tussView {
              tabela
              descricao
              codigoTermo
            }
          }
          cid10Subcategoria {
            id
            codigo
            descricao
            descricaoAbreviada
          }
          tuss23TerminologiaCaraterAtendimento {
            id
            termo
          }
          tuss36TerminologiaIndicadorAcidente {
            id
            termo
          }
          tuss41TerminologiaRegimeInternacao {
            id
            termo
          }
          tuss57TerminologiaTipoInternacao {
            id
            termo
          }
        }
      }
    `,
    variables: {
      id
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findByIdGuiaInternacaoModelo;
};

export const findByIdGuiaInternacao = async (id) => {
  const response = await Api.post("", {
    query: `
      query($id: UUID) {
        findByIdGuiaInternacao(id: $id) {
          id
          ativo
          indicacao
          observacao
          hasOPME
          quimioterapico
          pdf
          profissionalSaude {
            value:id
            label:nome
          }
          guiaInternacaoTussViews {
            id
            descricao
            quantidadeSolicitada
            tussView {
              tabela
              descricao
              codigoTermo
            }
          }
          cid10Subcategoria {
            id
            codigo
            descricao
            descricaoAbreviada
          }
          tuss23TerminologiaCaraterAtendimento {
            id
            termo
          }
          tuss36TerminologiaIndicadorAcidente {
            id
            termo
          }
          tuss41TerminologiaRegimeInternacao {
            id
            termo
          }
          tuss57TerminologiaTipoInternacao {
            id
            termo
          }
        }
      }
    `,
    variables: {
      id
    },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findByIdGuiaInternacao;
};

export const findGuiaInternacaoByIdEntradaProntuario = async (idEntrada) => {
  const response = await Api.post("", {
    query:`
      query($id: Long) {
        findGuiaInternacaoByIdEntradaProntuario(id: $id) {
          id
        }
      }
    `,
    variables: {
      id: idEntrada
    },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findGuiaInternacaoByIdEntradaProntuario.id;
};

export const findAllGuiaInternacaoModelo = async (searchDto, pageableDto) => {
  const response = await Api.post("", {
    query: `
      query ($searchDto: SearchDTOInput, $pageableDto: PageableDTOInput) {
        findAllGuiaInternacaoModelo(searchDTO: $searchDto, pageableDTO: $pageableDto) {
          content {
            id
            nome
            ativo
          }
          last
        }
      }
    `,
    variables: {
      searchDto,
      pageableDto
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findAllGuiaInternacaoModelo;
};

export const alterarStatusGuiaInternacao = async (id, status) => {
  const method = status ? "ativarInternacaoRegistro" : "desativarInternacaoRegistro";
  const response = await Api.post("", {
    query: `
      mutation($id: UUID) {
        ${method}(id: $id) {
          id
        }
      }
    `,
    variables: {
      id
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data[method];
};

export const findAllTuss57TerminologiaTipoInternacao = async ({ search, pageableDTO }) => {
  const response = await Api.post("", {
    query: `
      query($search: String, $pageableDTO: PageableDTOInput) {
        findAllTuss57TerminologiaTipoInternacao(search: $search, pageableDTO: $pageableDTO) {
          content {
            id
            termo
          }
          last
        }
      }
    `,
    variables: {
      search,
      pageableDTO
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllTuss57TerminologiaTipoInternacao;
};

export const findAllTuss41TerminologiaRegimeInternacao = async ({ search, pageableDTO }) => {
  const response = await Api.post("", {
    query: `
      query($search: String, $pageableDTO: PageableDTOInput) {
        findAllTuss41TerminologiaRegimeInternacao(search: $search, pageableDTO: $pageableDTO) {
          content {
            id
            termo
          }
          last
        }
      }
    `,
    variables: {
      search,
      pageableDTO
    },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllTuss41TerminologiaRegimeInternacao;
};

export const findAllTuss23TerminologiaCaraterAtendimento = async ({ search, pageableDTO }) => {
  const response = await Api.post("", {
    query: `
      query($search: String, $pageableDTO: PageableDTOInput) {
        findAllTuss23TerminologiaCaraterAtendimento(search: $search, pageableDTO: $pageableDTO) {
          content {
            id
            termo
          }
          last
        }
      }
    `,
    variables: {
      search,
      pageableDTO
    },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllTuss23TerminologiaCaraterAtendimento;
}

export const findAllTuss36TerminologiaIndicadorAcidente = async ({ search, pageableDTO }) => {
  const response = await Api.post("", {
    query: `
      query($search: String, $pageableDTO: PageableDTOInput) {
        findAllTuss36TerminologiaIndicadorAcidente(search: $search, pageableDTO: $pageableDTO) {
          content {
            id
            termo
          }
          last
        }
      }
    `,
    variables: {
      search,
      pageableDTO
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllTuss36TerminologiaIndicadorAcidente
}

export const findAllCid10Subcategoria = async ({ search, pageableDTO }) => {
  const response = await Api.post("", {
    query: `
      query ($pageableDTO: PageableDTOInput, $searchDTO: SearchDTOInput) {
        findAllCid10Subcategoria(pageableDTO: $pageableDTO, searchDTO: $searchDTO) {
          last
          content {
            id
            descricao
            codigo
            descricaoAbreviada
          }
        }
      }
    `,
    variables: {
      pageableDTO,
      searchDTO: {
        search
      }
    },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllCid10Subcategoria;
};

export const findTabelasDisponiveisTussView = async () => {
  const response = await Api.post("", {
    query: `
      query {
        findTabelasDisponiveisTussView
      }
    `
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findTabelasDisponiveisTussView;
};

export const findAllTussView = async ({ search, pageableDTO, tabela }) => {
  const response = await Api.post("", {
    query: `
      query($search: String, $pageableDTO: PageableDTOInput, $tabela: Int) {
        findAllTussView(search: $search, pageableDTO: $pageableDTO, tabela: $tabela) {
          content {
            tabela
            codigoTermo
            descricao
          }
          last
        }
      }
    `,
    variables: {
      search,
      pageableDTO,
      tabela
    },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllTussView;
};

export const criarEImprimirGuiaInternacao = async (guiaInternacao) => {
  const response = await Api.post("", {
    query: `
      mutation($guiaInternacao: GuiaInternacaoInput) {
        criarEImprimirGuiaInternacao(guiaInternacao: $guiaInternacao) {
          id
          pdf
          cid10Subcategoria {
            codigo
            descricao
            descricaoAbreviada
            excluidos
            refer
            id
            restricaoSexo
          }
          dataHoraLancamento
          guiaInternacaoTussViews {
            descricao
            id
            quantidadeSolicitada
            tussView {
              codigoTermo
              descricao
              tabela
            }
          }
          hasOPME
          quimioterapico
          id
          indicacao
          observacao
          profissionalSaude {
            nome
            cpf
            numeroConselho
            ufConselho
            conselhoProfissionalSaude {
              id
              descricao
              sigla
              codigoTermo
            }
            tuss24TerminologiaCBO {
              codigoTermo
            }
          }
          sujeitoAtencao {
            nome
            nomeSocialOuNome
            dadosConvenios {
              numeroCarteira
              validadeCarteira
              convenio {
                id
                descricao
              }
              convenioPrincipal
            }
          }
          tuss23TerminologiaCaraterAtendimento {
            codigoTermo
            id
          }
          tuss36TerminologiaIndicadorAcidente {
            id
            codigoTermo
          }
          tuss41TerminologiaRegimeInternacao {
            id
            codigoTermo
          }
          tuss57TerminologiaTipoInternacao {
            id
            codigoTermo
          }
          solicitanteNome
        }
      }
    `,
    variables : {
      guiaInternacao
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.criarEImprimirGuiaInternacao;
};

export const imprimirGuiaInternacao = async (id) => {
  const response  = await Api.post("", {
    query: `
      mutation ImprimirGuiaInternacao($imprimirGuiaInternacaoId: UUID) {
        imprimirGuiaInternacao(id: $imprimirGuiaInternacaoId) {
          id
          pdf
          cid10Subcategoria {
            codigo
            descricao
            descricaoAbreviada
            id
            restricaoSexo
          }
          dataHoraLancamento
          guiaInternacaoTussViews {
            descricao
            id
            quantidadeSolicitada
            tussView {
              codigoTermo
              descricao
              tabela
            }
          }
          hasOPME
          quimioterapico
          indicacao
          observacao
          profissionalSaude {
            nome
            cpf
            numeroConselho
            ufConselho
            conselhoProfissionalSaude {
              id
              descricao
              sigla
              codigoTermo
            }
            tuss24TerminologiaCBO {
              codigoTermo
            }
          }
          sujeitoAtencao {
            nome
            nomeSocialOuNome
            dadosConvenios {
              numeroCarteira
              validadeCarteira
              convenio {
                id
                descricao
              }
              convenioPrincipal
            }
          }
          tuss23TerminologiaCaraterAtendimento {
            codigoTermo
            id
          }
          tuss36TerminologiaIndicadorAcidente {
            id
            codigoTermo
          }
          tuss41TerminologiaRegimeInternacao {
            id
            codigoTermo
          }
          tuss57TerminologiaTipoInternacao {
            id
            codigoTermo
          }
          solicitanteNome
        }
      }
    `,
    variables: {
      imprimirGuiaInternacaoId: id
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.imprimirGuiaInternacao;
};

export const  findAllGuiaServicoTussViewQuantidadeUso = async ({pageableDTO}) => {
  const response = await Api.post("", {
    query: `
      query($pageableDTO: PageableDTOInput) {
        findAllGuiaServicoTussViewQuantidadeUso(pageableDTO: $pageableDTO) {
          last
          content {
            quantidadeUso
            tussView {
              codigoTermo
              descricao
              tabela
            }
          }
        }
      }
    `,
    variables: {
      pageableDTO
    }
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllGuiaServicoTussViewQuantidadeUso;
};
