import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

export const VisibilityRoundedIcon = props => {
    return (
      <svg width='21' 
      height='21' 
      viewBox='0 0 21 21' 
      fill='none' 
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}>
      <path d="M1.57374 9.00267C2.42805 4.90861 5.99284 2 9.9999 2C14.0069 2 17.5717 4.9086 18.4261 9.00268C18.4966 9.34058 18.8277 9.55734 19.1656 9.48682C19.5035 9.4163 19.7202 9.08522 19.6497 8.74732C18.678 4.0914 14.6167 0.75 9.9999 0.75C5.38313 0.75 1.32165 4.09139 0.350096 8.74733C0.279587 9.08523 0.496349 9.41631 0.834249 9.48682C1.17215 9.55733 1.50323 9.34057 1.57374 9.00267ZM9.98679 4.5C12.403 4.5 14.3618 6.45875 14.3618 8.875C14.3618 11.2912 12.403 13.25 9.98679 13.25C7.57055 13.25 5.61179 11.2912 5.61179 8.875C5.61179 6.45875 7.57055 4.5 9.98679 4.5Z" fill="#505050"/>
      </svg>
    );
  };
