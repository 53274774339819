import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { TextFieldSearch } from '../../../../../components/TextField';

const styles = ({
  input: {
    display: 'flex',
    flex: 1,
    background: '#F2F2F2',
    borderRadius: '8px',
    height: '40px',
    border: 'none',
    paddingLeft: '8px',
    '& > div': {
      '& > p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '12px !important',
        color: '#505050',
        fontWeight: '600'
      },
    },
  },
  select: {
    width: '100%'
  },
  error: {
    '& > div': {
      '& > p': {
        color: '#FB7676',
      },
    },
  },
  canceled: {
    '& > div': {
      '& > p': {
        textDecoration: 'line-through'
      },
    },
  },
  notchedOutline: {
    border: 0
  },
});

const TableCellSelect = ({ 
  name, 
  value, 
  onChange, 
  classes, 
  getElements, 
  loadElementsOnInit,
  rowId,
  field,
  isModified,
  isCanceled,
  ...rest
}) => {

  const handleSelectOption = (e) => {
    onChange(rowId, e, field);
  };

  const checkError = typeof isModified === 'boolean';
  const checkCanceled = typeof isCanceled === 'boolean';

  return (
    <TextFieldSearch
      classInput={classNames(
          classes.input,
          checkError && !isModified ? classes.error : null,
          checkCanceled && isCanceled ? classes.canceled : null
        )
      }
      classNotched={classes.notchedOutline}
      value={value}
      onChange={(e) => handleSelectOption(e)}
      loadOptions={getElements}
      loadingMessage={() => null}
      isClearable={false}
      {...rest}
      components={{
        LoadingIndicator: () => null
      }}
    />
  );
};

export default withStyles(styles)(TableCellSelect);