import InputFormDefault from "../../components/Input/InputForm";
import { InputCPFForm as InputCPFFormDefault } from "../../components/Input/InputCPFForm";
import { InputPhoneForm as InputPhoneFormDefault } from "../../components/Input/InputPhoneForm";
import Colors from "../../template/Colors";
import { withStyles } from "@material-ui/core";
import SelectFormDefault from "../../components/Select/Select";
import InputDateFormDefault from "../../components/Input/InputDateForm";
import InputTimeFormDefault from "../../components/Input/InputTimeForm";

const styleInput = {
    root: {
        width: '100%',
    },
    rootWithLabel: {
        marginTop: '0 !important',
    },
    label: {
        color: Colors.primary.main,
        lineHeight: '14px',
        transform: 'none',
        transition: 'none',
        position: 'relative',
        fontSize: '1rem',
        paddingLeft: '4px',
        fontFamily: 'Poppins !important',

    },
    input: {
        paddingTop: 3,
        borderBottom: '1px solid ' + Colors.commons.gray6,
        border: 0,
        borderRadius: 0,
        padding: '0px',
        fontSize: '14px',
        fontFamily: 'Poppins !important',
        color: 'black',

        '&:focus': {
            borderColor: Colors.primary.main
        },
    },

    inputError: {
        borderBottom: '1px solid ' + Colors.commons.red,
        border: 0,
        borderRadius: 0,
        padding: 0,
        fontSize: '14px',
        color: 'black',
    },
    inputType: {},
    inputMultiline: {
        fontSize: '14px',
        color: 'black',
        paddingTop: '5px',
        paddingBottom: '10px',
        border:0,

        '&:focus': {
            border:0,
        }
    }
};

const styleDate = {
    input: {
        padding: '0px 0px 0px 22px',
        textAlign: 'center',
        color: 'black',
        fontFamily: 'Poppins !important',
    },
    icon: {
        '& button': {
            padding: 0,
            marginRight: 5,
            width: '24px',
            height: '24px',
            color: Colors.commons.gray7,
        },

        '& span.material-icons': {
            position: 'absolute',
            marginTop:0,
        },
        '& svg': {
            fontSize: '18px',
        }
    },
    label: {
        color: 'black',
        fontFamily: 'Poppins !important',
        fontWeight: 'bold',
        fontSize: '10px',
        transform: 'none',
        transition: 'none',
        marginTop: 0,
    },
    date: {
        borderWidth: 1,
        border: 0,
        borderRadius: '8px',
        fontSize: '14px',
        alignItems: 'center',
        color: 'black',
        lineHeight: '150%',
        height: '32px',
        backgroundColor: Colors.commons.gray2,
        marginTop: '0px!important'
    },
    dateOutlined: {
        boxSizing: "border-box",
        paddingLeft: 6
    },
    dateWithLabel: {
        fontFamily: 'Poppins !important',
        marginTop: '0px!important'
    },
    error: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.commons.red
    }
}

const styleSelect = {
    root: {
        width: '100%',

        '&.width-auto': {
            width: 'auto',
        }
    },
    select: {
        fontSize: '14px',
        fontFamily: 'Poppins !important',
        border: '0',
        minHeight: '6px',
        height: '18px',
        color: 'black',
        backgroundColor: Colors.commons.gray2,
        margin: '0 0 0 0',
        padding: '9px 0px 5px 8px',
        borderRadius: '8px',
        textAlign: 'start',

        '&:focus': {
            borderRadius: '8px',
            borderColor: Colors.primary.main,
            color: Colors.commons.darkGray,
            backgroundColor: Colors.commons.gray2,
        },
        '& div:focus': {
            backgroundColor: 'transparent',
        },

        '&.hide-borders': {
            borderColor: 'transparent',
            paddingRight: 30,
            lineHeight: 'normal'
        },

        '&.primary': {
            color: Colors.primary.main
        }
    },
    inputLabel: {
        fontFamily: 'Poppins !important',
        color: Colors.primary.main,
        lineHeight: '15px',
        transition: 'none',
        transform: 'none',
    },
    menuItem: {
        lineHeight: '17px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        color: Colors.commons.fontColor,
        paddingBottom: '5px',
        fontFamily: 'Poppins !important',
    },

    itemLabel: {
        paddingRight: '10px',
        color: Colors.commons.fontColor,
        fontFamily: 'Poppins !important',
    },

    icon: {
        color: Colors.commons.gray7,
        top: 'calc(50% - 12px)',
        fontSize: '24px',
        borderRadius: '50%',
        right: 6
    },
    status: {
        display: 'inline-box',
        marginTop: '1px',
        width: '15px',
        minWidth: '15px',
        height: '15px',
        borderRadius: '50%'
    },
    statusLabel: {
        marginLeft: '5px',
        marginTop: '2px',
        fontFamily: 'Poppins !important',
    },
    itemSelectedStatus: {
        display: 'inline-flex',
        alignItems: 'center',
        width: '65%'
    }
};

const styleTime = {
    input: {
        padding: '0px 0px 0px 22px',
        textAlign: 'center',
        color: Colors.commons.darkGray,
        fontFamily: 'Poppins !important',
    },
    icon: {
        '& button': {
            padding: 0,
            marginRight: 5,
            width: '24px',
            height: '24px',
            backgroundColor: Colors.primary.main,
            color: Colors.commons.white,

            "&:disabled": {
                backgroundColor: Colors.commons.gray7
            }
        },

        '& span.material-icons': {
            position: 'absolute',
            marginTop:0,
        },
        '& svg': {
            fontSize: '18px',
            fill:  `${Colors.commons.white}!important`
        }
    },
    label: {
        color: Colors.commons.secondary,
        fontWeight: 'bold',
        fontSize: '10px',
        transform: 'none',
        transition: 'none',
        marginTop: 0,
        fontFamily: 'Poppins !important',
    },
    date: {
        borderWidth: 1,
        border: 0,
        borderRadius: '8px',
        fontSize: '14px',
        alignItems: 'center',
        color: Colors.commons.darkGray,
        lineHeight: '150%',
        height: '32px',
        minWidth:'100%',
        backgroundColor: Colors.commons.gray2,
        marginTop: '0px!important',
        fontFamily: 'Poppins !important',
    },
    error: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.commons.red
    }
};

export const InputForm = withStyles(styleInput)(InputFormDefault);

export const InputPhoneForm = withStyles(styleInput)(InputPhoneFormDefault);

export const InputCPFForm = withStyles(styleInput)(InputCPFFormDefault);

export const InputDateForm = withStyles(styleDate)(InputDateFormDefault)

export const InputTimeForm = withStyles(styleTime)(InputTimeFormDefault)

export const SelectForm = withStyles(styleSelect)(SelectFormDefault);
