export const campos =(dadosMensagem)=>[
  {
    campo: "${profissionalSaude.nome}",
    value: dadosMensagem?.profissionalSaude?.nome || "",
  },
  {
    campo: "${profissionalSaude.cpf}",
    value: dadosMensagem?.profissionalSaude?.cpf || "",
  },
  {
    campo: "${profissionalSaude.numeroConselho}",
    value: dadosMensagem?.profissionalSaude?.numeroConselho || "",
  },
  {
    campo: "${sujeitoAtencao.dadosConvenio.convenio.descricao}",
    value: dadosMensagem?.convenio?.descricao || "",
  },
  {
    campo: "${sujeitoAtencao.idade}",
    value: dadosMensagem?.sujeitoAtencao?.idade || "",
  },
  {
    campo: "${sujeitoAtencao.rg}",
    value: dadosMensagem?.sujeitoAtencao?.rg || "",
  },
  {
    campo: "${unidade.complemento}",
    value: dadosMensagem?.unidade?.endereco?.complemento || "",
  },
  {
    campo: "${unidade.telefonePrincipal}",
    value: dadosMensagem?.unidade?.telefonePrincipal || "",
  },
  {
    campo: "${sujeitoAtencao.nomeSocialOuNome}",
    value: dadosMensagem?.sujeitoAtencao?.nomeSocial || "",
  },
  {
    campo: "${unidade.numero}",
    value: dadosMensagem?.unidade?.endereco?.numero || "",
  },
  {
    campo: "${sujeitoAtencao.nome}",
    value: dadosMensagem?.sujeitoAtencao?.nome || "",
  },
  {
    campo: "${sujeitoAtencao.cpf}",
    value: dadosMensagem?.sujeitoAtencao?.cpf || "",
  },
  {
    campo: "${unidade.cep}",
    value: dadosMensagem?.unidade?.endereco?.cep || "",
  },
  {
    campo: "${unidade.estado.nome}",
    value: dadosMensagem?.unidade?.endereco?.municipio?.uf || "",
  },
  {
    campo: "${unidade.municipio.nome}",
    value: dadosMensagem?.unidade?.endereco?.municipio?.nome || "",
  },
  {
    campo: "${unidade.nomeLogradouro}",
    value: dadosMensagem?.unidade?.endereco?.nomeLogradouro || "",
  },
  {
    campo: "${unidade.bairro}",
    value: dadosMensagem?.unidade.endereco?.bairro || "",
  },
  {
    campo: "${dataFormatada}",
    value: dadosMensagem?.dataFormatada || "",
  },
  {
    campo: "${horaFormatada}",
    value: dadosMensagem?.horaInicio || "",
  },
  {
    campo: "${agendamento.linkTelemedicina}",
    value: dadosMensagem?.linkTelemedicina || "",
  },
  {
    campo: "${agendamento.linkMensagemConfirmacao}",
    value: dadosMensagem?.linkConfirmarCancelarAgendamento || "",
  },
  {
    campo: "${configuracaoUnidade.horasPermiteConfirmarAgendamento}",
    value: dadosMensagem?.horasPermiteAgendamento || "",
  },
  {
    campo: "${unidade.nomeFantasia}",
    value: dadosMensagem?.unidade?.nomeFantasia || "",
  },
]