import { Fab, Tooltip, withStyles } from "@material-ui/core";
import React from 'react';
import WarningIcon from "../../../../components/Icon/WarningIcon";

const ButtonDadosImportantes = ({ classes, handleClickInformacoesImportantes ,disabled}) => {
    return(
        <Fab className={classes.buttonRed} onClick={handleClickInformacoesImportantes} disabled={disabled}>
            <Tooltip
                title={"Dados importantes"}
                placement="left"
            >
                <WarningIcon color="#fff"/>
            </Tooltip>
        </Fab>
    )
};

const styles = {
   buttonRed: {
    width: "40px",
    height: "40px",
    minHeight: "1px",
    background: "#FB6D6D",
    "&:hover": {
        background: '#f92e2e',
    },
   }
};

export default withStyles(styles)(ButtonDadosImportantes);