import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const CloseIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M13.9688 1.40625L8.39062 6.98438L13.9688 12.5625L12.5625 13.9688L6.98438 8.39062L1.40625 13.9688L0 12.5625L5.57812 6.98438L0 1.40625L1.40625 0L6.98438 5.57812L12.5625 0L13.9688 1.40625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
