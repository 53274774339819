import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const MicIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...normalizeProps(props)}>
      <path d="M5.5 9.99998C5.5 9.72384 5.27614 9.49998 5 9.49998C4.72386 9.49998 4.5 9.72384 4.5 9.99998C4.5 12.869 6.69675 15.2249 9.5 15.4776V17.5C9.5 17.7761 9.72386 18 10 18C10.2761 18 10.5 17.7761 10.5 17.5V15.4776C13.3033 15.2249 15.5 12.869 15.5 9.99998C15.5 9.72384 15.2761 9.49998 15 9.49998C14.7239 9.49998 14.5 9.72384 14.5 9.99998C14.5 12.4853 12.4853 14.5 10 14.5C7.51472 14.5 5.5 12.4853 5.5 9.99998ZM13 10.0007C12.9996 11.6573 11.6566 13 10 13C8.34315 13 7 11.6568 7 9.99998V5C7 3.34315 8.34315 2 10 2C11.6569 2 13 3.34315 13 5V10.0007Z" fill="currentColor"/>
    </svg>
  );
};

export default MicIcon;
