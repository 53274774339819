const styles = theme => ({
  content: {
    gridArea: "content",
    display: "grid",
    gridTemplateRows: '80px 1fr',
    position: 'relative',
    overflow: "hidden",
    background: '#f5f5f5',

    "& thead th": {
      top: 0,
      position: "sticky",
      borderTop: '1px solid rgba(0, 0, 0, 0.10)',

      "&:last-child":{
        width: '30%'
      },
    },
  },

  search: {
    gridArea: "search",
    marginBottom: 20
  },

  lista: {
    gridArea: "lista",
    marginBottom: 20,
    overflow: "hidden"
  },

  headerTitleRoot: {
    maxWidth: theme.commons.menuLeft.width
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1
  },

  titleHeader: {
    fontSize: 25,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    paddingLeft: 15,
  },

  tableContainer: {
    display: "flex",
    flexDirection: "Column",
    paddingTop: 1,
    overflow: "hidden"
  },
  
  scrollContainer: {
    height: "100%"
  },
  
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    
    '& h3': {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      fontSize: '1rem'
    }
  },
  contentList: {
    borderRadius: '16px',
    margin: '24px',
    background: '#fff',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
  },
  buttonsContainer: {
    margin: '0 16px',
  }
});

export default styles;
