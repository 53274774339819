import React from 'react'
import moment from 'moment'
import { WhatsApp } from '../../../components/Icon'
import LogoBenerSerFront from '../../../assets/img/svg/cartao-template/logo-beneser-front.svg'
import LogoBenerSerBack from '../../../assets/img/svg/cartao-template/logo-beneser-back.svg'
import WebIcon from '../../../components/Icon/WebIcon'
import MailIcon from '../../../components/Icon/MailIcon'
import InstagramIcon from '../../../components/Icon/InstagramIcon'
import LocationIcon from '../../../components/Icon/Location'
import bgCard from '../../../assets/img/svg/cartao-template/bg-card.svg'
import bgCardVerso from '../../../assets/img/svg/cartao-template/bg-card-verso.svg'



const BENESER_DADOS_BLOCO_ONE = {

  INSTAGRAM: { icon: <InstagramIcon size='8' color='white' />, label: '@beneser_centromedico' },
  ENDERECO: {
    icon: <LocationIcon size='8' color='white' />,
    label: 'Rua Amapá, 929 - Centro Paranavaí - PR',
  },
}
const BENESER_DADOS_BLOCO_TWO = {
  WHATSAPP: { icon: <WhatsApp size='8' color='white' />, label: '(44) 3900-3900' },
  EMAIL: { icon: <MailIcon size='8' color='white' />, label: 'contato@beneser.com.br' },
  SITE: { icon: <WebIcon size='8' color='white' />, label: 'beneser.com.br' },
}

const formatNomePacient = nome => {
  const nameSplit = nome.split(' ').filter(Boolean)

  if (nameSplit.length > 2) {
    return nameSplit
      .map((item, index) =>
        index === 0 || index === nameSplit.length - 1 ? `${item} ` : `${item.charAt(0)} `
      )
      .join('')
  }
  return nome
}

const InformationWrapper = ({ icon, rounded }) => {
  return (
    <div
      style={{
        width: '20px',
        height: rounded === 'ENDERECO' ? '20px' : '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#7153a2',
        marginRight: '8px',
        marginBottom: '-4px',
        borderTopLeftRadius: rounded === 'WHATSAPP' || rounded === 'INSTAGRAM'
          ? '5px' : '2px',
        borderTopRightRadius: rounded === 'WHATSAPP' || rounded === 'INSTAGRAM'
          ? '5px' : '2px',
        borderBottomLeftRadius: rounded === 'ENDERECO' || rounded === 'SITE'
          ? '5px' : '2px',
        borderBottomRightRadius: rounded === 'ENDERECO' || rounded === 'SITE'
          ? '5px' : '2px',
      }}
    >
      {icon}
    </div>
  )
}

const BeneSerTemplate = ({ sujeitoAtencao }) => {
  const { nome } = sujeitoAtencao

  const validade = moment()
    .add(24, 'months')
    .format('MM/YY')
  return (
    <React.Fragment>
      <div style={styles.blocoView}>
        <div style={styles.bloco}>
          <div style={styles.wrapperGreenLine}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
                '& p': {
                  fontFamily: 'Poppins',
                },
              }}
            >
              <div style={styles.linha}>
                <p style={styles.valorCampoBene}>Bene Card</p>
                <img src={LogoBenerSerFront} alt='Logo' />
              </div>
              <div style={styles.linha}>
                <div style={styles.divCampo}>
                  <p
                    style={{
                      ...styles.tituloCampo,
                      marginBottom: '-10px',
                    }}
                  >
                    Titular
                  </p>
                  <p style={styles.namePaciente}>{formatNomePacient(nome)}</p>
                </div>
              </div>
              <div style={styles.validadeCartao}>
                <p style={styles.tituloCampo}>Validade</p>
                <p style={styles.valorCampo}>{validade}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.blocoBack}>
        <div style={styles.wrapperGreenLine}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flex: 1,
              '& p': {
                fontFamily: 'Poppins',
              },
            }}
          >
            <div style={styles.linha}>
              <p style={styles.valorCampoVerso}>Bene Card</p>
              <img src={LogoBenerSerBack} alt='Logo' style={styles.logo} />
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end', flex: 1, padding: '0px 16px', gap: "4px" }}>
              <div style={styles.containerContato}>
                {Object.entries(BENESER_DADOS_BLOCO_ONE).map(([key, value]) => (
                  <div key={key} style={styles.wrapperInformations}>
                    <InformationWrapper icon={value.icon} rounded={key} />
                    <span
                      style={{
                        fontSize: '8px !important',
                        fontWeight: 500,
                        color: '#fff',
                        marginBottom: '-4px',
                        width: '100px'
                      }}
                    >
                      {value.label}
                    </span>
                  </div>
                ))}
              </div>
              <div style={styles.containerContato}>
                {Object.entries(BENESER_DADOS_BLOCO_TWO).map(([key, value]) => (
                  <div key={key} style={styles.wrapperInformations}>
                    <InformationWrapper icon={value.icon} rounded={key} />
                    <span
                      style={{
                        fontSize: '8px !important',
                        fontWeight: 500,
                        color: '#fff',
                        marginBottom: '-4px',
                        width: '100px'
                      }}
                    >
                      {value.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.blueRectangulo} />
      </div>
    </React.Fragment>
  )
}

const styles = {
  blocoView: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    pageBreakAfter: 'always',
    backgroundColor: '#00B2A2',
    minHeight: '100vh',
    backgroundImage: `url(${bgCard})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  bloco: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    padding: '8px',
    color: '#fff',
    '& p': {
      fontFamily: 'Poppins',
    },
  },
  tituloCampo: {
    fontSize: '12px',
    lineHeight: '10px',
    fontWeight: 300,
  },
  valorCampoBene: {
    fontSize: '20px',
    fontWeight: 600,
  },
  valorCampo: {
    fontSize: '16px',
    fontWeight: 600,
  },
  valorCampoVerso: {
    fontSize: '20px',
    fontWeight: 600,
    color: "#00b2a2"
  },
  linha: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  namePaciente: {
    fontSize: '28px',
    fontWeight: 500,
  },
  validadeCartao: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
  },
  divCampo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    fontSize: '38px',
  },
  imagemAtras: {
    width: '135px',
  },
  blocoBack: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px',
    minHeight: '100vh',
    position: 'relative',
    backgroundImage: `url(${bgCardVerso})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  wrapperInformations: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    fontWeight: 500,
    color: '#00B2A2',
  },
  wrapperGreenLine: {
    display: 'flex',
    flex: 1,
    padding: '8px',
    zIndex: 2
  },
  logo: {
    width: "22px",
    height: "22px"
  },
  blueRectangulo: {
    width: "100%",
    height: "30%",
    backgroundColor: '#00B2A2',
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    zIndex: 1
  },
  containerContato: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '20%',
    gap: '0px'
  }
}

export default BeneSerTemplate
