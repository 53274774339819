import React from 'react'
import moment from 'moment'
import string from '../../../../utils/string'
import { Button } from '../../../../components/ui/Buttons'
import ButtonWhatsApp from '../../../../components/Button/ButtonWhatsApp'
import { applyPhoneMask } from '../../../../utils/PhoneMask'
import ColumnTipo from '../../../../components/Agenda/DayCalendar/ColumnTipo'

export const getColumns = ({ handleClickAgendar, redirectToWhatsapp }) => {
  const columns = [
    {
      Header: 'Data criação',
      align: 'start',
      getValue: data => {
        return <span>{ moment(data.dataCriacao).format("DD/MM/YYYY") }</span>
      },
    },
    {
      Header: 'Nome',
      align: 'start',
      getValue: data => {
        return <strong>{string.truncate(data?.nome, 15)}</strong>
      },
    },
    {
      Header: 'Tipo',
      align: 'start',
      getValue: data => {
        const event = {
          tipo: data?.tipo?.descricao,
          remoto: data?.remoto,
          id: data?.id,
        }
        return <ColumnTipo event={event} />
      },
    },
    {
      Header: 'Telefone',
      align: 'start',
      getValue: data => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              textWrap: 'nowrap',
              padding: '16px 0',
            }}
            onClick={e => redirectToWhatsapp(e, data)}
          >
            <span>{applyPhoneMask(data?.sujeitoAtencao?.contato?.telefonePrincipal)}</span>
            <ButtonWhatsApp
              withCount
              messageSent={data?.mensagemWhatsappEnviada}
              quantidadeEnviado={data?.whatsappMensagemQuantidade}
              labelTooltip='Mensagem enviada, deseja enviar novamente?'
            />
          </div>
        )
      },
    },
    {
      Header: 'Data agendamento',
      align: 'start',
      getValue: data => {
        const dataAgendamento = moment(data?.data).format("DD/MM/YYYY") === "01/01/1900" ? "Não definido" : moment(data?.data).format("DD/MM/YYYY");

        return <span>{ dataAgendamento }</span>
      },
    },
    {
      Header: '',
      onlyIcon: true,
      align: 'start',
      getValue: data => {
        return (
          <Button kind='primary' onClick={() => handleClickAgendar(data)}>
            Agendar
          </Button>
        )
      },
    },
  ]
  return columns
}
