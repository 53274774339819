const styles = theme => ({
  container: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    position: 'relative',
    overflow: 'hidden',
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 1,
    overflow: 'hidden',
    height: '100%',

    "& thead": {
      position: 'sticky',
      top: 0,
    },

    '& th': {
      textAlign: 'left',
      width: '20%',
      "&:first-child": {
        width: '40%',
    },
    },
  },
  header: {
    height: '80px',
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
  },

  profile: {
    textAlign: 'end',
  },

  wrapper: {
    paddingTop: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
})

export default styles
