import React from 'react';
import Fab from '@material-ui/core/Fab';
import {withStyles} from '@material-ui/core/styles';
import Colors from '../../template/Colors';

const styles = () => ({
    root: {
        borderRadius: '100px',
        height: 40,
        width: 40,
        border: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: Colors.commons.yellow2,
        color: Colors.commons.white,

        "&:hover": {
            background: Colors.commons.darkYellow,
        }
    }
});

const ButtonYellow = (props) => {
    const {classes} = props;
    return <Fab className={classes.root} {...props}/>;
};


export default withStyles(styles)(ButtonYellow);