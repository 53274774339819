import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const CopyIcon = props => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M6.55934 6.25946L6.55895 14.734C6.55895 16.4851 7.92171 17.9179 9.64454 18.0297L9.86169 18.0367L15.6935 18.0376C15.4212 18.8068 14.6875 19.3578 13.825 19.3578H9.20115C7.01228 19.3578 5.23785 17.5834 5.23785 15.3945V8.12848C5.23785 7.26551 5.78947 6.53138 6.55934 6.25946ZM16.4672 3.50464C17.5616 3.50464 18.4488 4.39185 18.4488 5.48628V14.734C18.4488 15.8284 17.5616 16.7156 16.4672 16.7156H9.86169C8.76726 16.7156 7.88005 15.8284 7.88005 14.734V5.48628C7.88005 4.39185 8.76726 3.50464 9.86169 3.50464H16.4672Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CopyIcon
