import React, { useState, useEffect } from "react";

import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import styles from "./ModalBloquearStyles";
import { meses, periodos } from "./Constants";
import { InputDateForm } from "../../../components/Modal/Input";
import { ReactSelect, ReactSelectCheckbox } from "../../../components/Select/SelectSearch";
import PeriodDate from "../../../components/PeriodDate/PeriodDate";

import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";

import LockIcon from "../../../components/Icon/LockIcon";
import UnlockIcon from "../../../components/Icon/UnlockIcon";

import { generateTimeList } from '../../../utils/generateTimeList';
import { countChecked } from './util/utils';
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { findAllPeriodoAtendimentoModificadoAuditId } from "../../../services/AuditoriaService";
import Auditoria from "./Auditoria/Auditoria";
import { findProfissionalSaudeByUnidadeComAgenda } from "../../../services/ProfissionalSaudeService";
import { getUnidadeLogado } from "../../../services/UsuarioService";



const iconProps = {
  size: 24,
  color: "#FFF",
};

const ModalBloquearAgenda = observer(({
  classes,
  open,
  close,
  handleOpenModalConfirmacao,
  atendimentoStore,
  auditoriaStore,
  operation,
  isLoading,
  profissionalSaudeId
}) => {
  const [periodo, setPeriodo] = useState("");
  const [mes, setMes] = useState("");
  const [tabSelected, setTabSelected] = useState(0)

  const handlePeriodo = (e) => {
    atendimentoStore.dataSelecionadaBloqueioAgenda = {
      dataInicial: null,
      dataFinal: null,
    }

    if (e.nome === "Personalizado") {
      atendimentoStore.dataSelecionadaBloqueioAgenda = !atendimentoStore
        .dataSelecionadaBloqueioAgenda?.dataInicial && {
        dataInicial: moment().format("YYYY-MM-DD"),
        dataFinal: moment().format("YYYY-MM-DD"),
      };
    }

    if (e.nome === "Semana") {
      atendimentoStore.dataSelecionadaBloqueioAgenda = {
        dataInicial: moment()
          .day(0)
          .format("YYYY-MM-DD"),
        dataFinal: moment()
          .day(6)
          .format("YYYY-MM-DD"),
      };
    }

    setPeriodo(e);
  };

  const handleDia = (e) => {
    atendimentoStore.dataSelecionadaBloqueioAgenda = {
      dataInicial: e,
      dataFinal: e,
    };
  };

  const handleChangeDate = (dataInicial, dataFinal) => {
    atendimentoStore.dataSelecionadaBloqueioAgenda = {
      dataInicial,
      dataFinal,
    };
  };

  const confirmaBloqueioAgenda = () => {
    handleOpenModalConfirmacao();
    
  };

  const handleMes = (e) => {
    setMes(e);
    const currentDate = moment();
    const selectedMonth = e.value;
    const year = selectedMonth >= currentDate.month() ? currentDate.year() : currentDate.year() + 1;
    const dataMesSelecionado = moment().month(selectedMonth).year(year);

    const primeiroDiaDoMes = dataMesSelecionado.startOf("month");
    const ultimoDiaDoMes = dataMesSelecionado.clone().endOf("month");

    atendimentoStore.dataSelecionadaBloqueioAgenda = {
      dataInicial: primeiroDiaDoMes,
      dataFinal: ultimoDiaDoMes,
    };
  };

  const handledataSelecionadaBloqueioAgenda = (value) => {
    atendimentoStore.dataSelecionadaBloqueioAgenda = value;
  };
  const getProfissionaisOptions = async () => {
    try {
      let pageableDTO = {
        sortDir: 'ASC',
        sortField: 'nome',
        pageNumber: 0,
        pageSize: 999,
      };
      const unidade = await getUnidadeLogado()
      if (!unidade?.id) {
        return
      }
      const response = await findProfissionalSaudeByUnidadeComAgenda({
        search: null,
        unidadeId: unidade?.id || null,
        pageableDTO
      });

      const profissionaisSaudeByUnidadeComAgenda = response?.data?.data?.findProfissionalSaudeByUnidadeComAgenda
      if (profissionaisSaudeByUnidadeComAgenda) {
        const profissionaisSaudeByUnidadeOptions = [{
          value: "Todos os profissionais",
          label: "Todos os profissionais",
          checked: false
        }]

        profissionaisSaudeByUnidadeComAgenda.forEach((item) =>
          profissionaisSaudeByUnidadeOptions.push({
            value: item.id,
            label: item.nome,
            checked: profissionalSaudeId === item.id
          }))
        atendimentoStore.profissionaisAgendaBloqueadaOptions = profissionaisSaudeByUnidadeOptions
      }
    } catch (error) {
      console.log(error)

    }
  }
  const getHorariosToCheck = () => {
    const { duracao, horaInicioPeriodoAtendimento, horaFimPeriodoAtendimento } = atendimentoStore.configVigente;
    const timeList = generateTimeList(horaInicioPeriodoAtendimento, horaFimPeriodoAtendimento, duracao);
    timeList.unshift('Dia todo');
    return timeList.map(time => ({ label: time, value: time, checked: false }));
  };

  const changeTabSelected = async (tabSelected) => {
    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      auditoria: `o ${operation === "BLOQUEAR" ? "bloqueio" : "desbloqueio"}`,
      auditoriaTela: "PeriodoAtendimentoModificado",
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    };

    if (tabSelected === 1) {

      auditoriaStore.load = {
        query: findAllPeriodoAtendimentoModificadoAuditId,
        variableName: "id",
        variableId: profissionalSaudeId,

      }
      await auditoriaStore.loadAuditItems();
    }

    setTabSelected(tabSelected)
  }
  const checkDisabledSecundaryButton = () => {
    const { dataSelecionadaBloqueioAgenda, horariosOptions, profissionaisAgendaBloqueadaOptions } = atendimentoStore
    return !dataSelecionadaBloqueioAgenda?.dataInicial
      || countChecked(horariosOptions) === 0
      || countChecked(profissionaisAgendaBloqueadaOptions) === 0
      || isLoading
  }
  const handleClickHorario = (horarios) => {
    const { horariosOptions } = atendimentoStore;
    let horariosOptionsAux = [...horariosOptions];
  
    const opcaoDiaTodoSelecionadoHorarios = horarios.some(item => item.value === "Dia todo");
    const opcaoDiaTodoSelecionadoHorariosOptions = horariosOptionsAux.some(item => item.value === "Dia todo" && item.checked);
  
    if (opcaoDiaTodoSelecionadoHorariosOptions) {
      horariosOptionsAux = opcaoDiaTodoSelecionadoHorarios
        ? horariosOptionsAux.map(h => ({ ...h, checked: h.value === "Dia todo" ? false : horarios.some(item => item.value === h.value) }))
        : horariosOptionsAux.map(h => ({ ...h, checked: false }));

    } else {
      horariosOptionsAux = (opcaoDiaTodoSelecionadoHorarios || (!opcaoDiaTodoSelecionadoHorarios && horarios.length === horariosOptionsAux.length - 1))
        ? horariosOptionsAux.map(h => ({ ...h, checked: true }))
        : horariosOptionsAux.map(h => ({ ...h, checked: horarios.some(item => item.value === h.value) }));
    }
  
    atendimentoStore.horariosOptions = horariosOptionsAux;
  };

  const handleClickProfissionais = (profissionais) => {

    const { profissionaisAgendaBloqueadaOptions } = atendimentoStore;
    let profissionaisAgendaBloqueadaOptionsAux = profissionaisAgendaBloqueadaOptions;
    const allProfissionais = profissionais.some(item => item.value === "Todos os profissionais");
    const allProfissionaisChecked = profissionaisAgendaBloqueadaOptionsAux.some(item => item.value === "Todos os profissionais" && item.checked);
    const conditionAllCheckedTrue = allProfissionais || (!allProfissionais && profissionais.length === profissionaisAgendaBloqueadaOptionsAux.length - 1)

    if (allProfissionaisChecked) {
      profissionaisAgendaBloqueadaOptionsAux = allProfissionais
        ? profissionaisAgendaBloqueadaOptionsAux.map(h => ({
          ...h, checked: h.value === "Todos os profissionais" ?
            false : profissionais.some(item => item.value === h.value)
        }))
        : profissionaisAgendaBloqueadaOptionsAux.map(h => ({ ...h, checked: false }));

    } else {
      profissionaisAgendaBloqueadaOptionsAux = conditionAllCheckedTrue
        ? profissionaisAgendaBloqueadaOptionsAux.map(h => ({ ...h, checked: true }))
        : profissionaisAgendaBloqueadaOptionsAux.map(h => ({ ...h, checked: profissionais.some(item => item.value === h.value) }));
    }

    atendimentoStore.profissionaisAgendaBloqueadaOptions = profissionaisAgendaBloqueadaOptionsAux;

  };


  const renderSelectDate = () => {
    switch (periodo?.nome) {
      case "Dia":
      default:
        return (
          <InputDateForm
            iconposition="end"
            className={classes.dateTime}
            disabled={!periodo?.nome}
            classes={{
              date: classes.inputDate,
            }}
            value={atendimentoStore.dataSelecionadaBloqueioAgenda.dataInicial}
            onChange={handleDia}
          />
        );
      case "Semana":
        return (
          <PeriodDate
            filter={atendimentoStore.dataSelecionadaBloqueioAgenda}
            setFilter={handledataSelecionadaBloqueioAgenda}
            onChangeDate={handleChangeDate}
            handleAplicar={() => {}}
            stylePopper={classes.popperPeriodDate}
            withoutZIndex
          />
        );
      case "Mês":
        return (
          <ReactSelect
            className={classes.selectSearch}
            placeholder="Selecione"
            value={mes}
            options={meses}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.value}
            onChange={handleMes}
          />
        );
      case "Personalizado":
        return (
          <PeriodDate
            filter={atendimentoStore.dataSelecionadaBloqueioAgenda}
            setFilter={handledataSelecionadaBloqueioAgenda}
            onChangeDate={handleChangeDate}
            handleAplicar={() => {}}
            stylePopper={classes.popperPeriodDate}
            withoutZIndex
          />
        );
    }
  };

  useEffect(() => {
    atendimentoStore.horariosOptions = getHorariosToCheck();
    getProfissionaisOptions()
  }, []);

  const getLabelPeriodo = () => {
    const acao = operation === "BLOQUEAR" ? "bloquear" : "desbloquear";
    return `Qual período você deseja ${acao}?`
  };

  return (
    <ModalConfirmacao
      open={open}
      icon={operation === "BLOQUEAR" ? <LockIcon {...iconProps} /> : <UnlockIcon {...iconProps} />}
      color={"gray"}
      title={operation === "BLOQUEAR" ? "Bloquear Agenda" : "Desbloquear Agenda"}
      classes={{
        paperAlert: classes.paperAlert,
      }}
      buttons={{
        labelPrimaryButton: "Cancelar",
        handlePrimaryButton: () => close(),
        disabledPrimaryButton: isLoading,
        loadingPrimaryButton: isLoading,
        labelSecondButton: "Continuar",
        handleSecondButton: () => confirmaBloqueioAgenda(),
        disabledSecondButton: checkDisabledSecundaryButton(),
        loadingSecondButton: isLoading,
      }}
    >
      <div className={classes.content}>
        <MultiToggleButtons
          options={[operation === "BLOQUEAR" ? " Bloquear " : "Desbloquear ", "Auditoria"]}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
          classes={{ buttonsContainer: classes.buttonsTabAuditoria }}
        />
        {tabSelected === 0 ?
          <>
        <div className={classes.inputWithLabelContainer}>

              <div className={classes.title}>{getLabelPeriodo()}</div>
          <ReactSelect
            className={classes.selectSearch}
            placeholder="Selecione"
            value={periodo}
            options={periodos}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.value}
            onChange={handlePeriodo}
          />
        </div>

            <div className={classes.inputWithLabelContainer}>
              <div className={classes.title}> Profissionais </div>
              <ReactSelectCheckbox
                onChange={handleClickProfissionais}
                options={atendimentoStore.profissionaisAgendaBloqueadaOptions || []}
                value={atendimentoStore.profissionaisAgendaBloqueadaOptions.filter(item => item.checked)}

              />
            </div>

        <div className={classes.inputWithLabelContainer}>
          <div className={classes.title}> Selecione a data </div>
          {renderSelectDate()}
        </div>
        <div className={classes.inputWithLabelContainer}>
          <div className={classes.title}> Horário </div>
          <ReactSelectCheckbox
            onChange={handleClickHorario}
            options={atendimentoStore.horariosOptions || []}
            value={atendimentoStore.horariosOptions.filter(item => item.checked)}
                maxMenuHeight={130}
              />
            </div>
          </>
          :
          <div style={{ height: "183px" }}>
            <Auditoria
          />
        </div>
        }
      </div>
    </ModalConfirmacao>
  );
});

const ModalBloquearAgendaWithStyles = withStyles(styles)(ModalBloquearAgenda);
export default inject("atendimentoStore", "auditoriaStore")(ModalBloquearAgendaWithStyles);
