import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  withStyles,
} from '@material-ui/core'
import AgendaIcon from '../../../../Icon/Agenda'
import ConfiguracoesIcon from '../../../../Icon/Configuracoes'
import MoneyIcon from '../../../../Icon/Money'
import PacientesIcon from '../../../../Icon/Pacientes'
import ClipboardTaskListIcon from '../../../../Icon/ClipboardTaskListIcon'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'

const types = {
  TODOS: 'TODOS',
  AGENDA: 'AGENDA',
  ATENDIMENTO: 'ATENDIMENTO',
  PACIENTE: 'PACIENTE',
  FINANCEIRO: 'FINANCEIRO',
  SISTEMA: 'SISTEMA',
}

const FilterTypesNotification = observer(({ classes, open, anchorEl, notificationStore }) => {
  const [typesSelected, setTypesSelected] = useState([])

  const handleChangeCheckbox = (type, checked) => {
    const { paramsNotifications } = notificationStore
    if (type === types.TODOS) {
      if (checked) {
        setTypesSelected(Object.values(types))
      } else {
        setTypesSelected([])
        paramsNotifications.tipoNotificacao = []
      }
      return
    }
    if (checked) {
      setTypesSelected([...typesSelected, type])
    } else {
      const newTypes = typesSelected.filter(item => item !== type)
      setTypesSelected(newTypes)
      paramsNotifications.tipoNotificacao = newTypes
      notificationStore.findAllNotifications(true)
    }
  }

  useEffect(() => {
    if (typesSelected.length === 0) {
      return
    }
    notificationStore.paramsNotifications = {
      ...notificationStore.paramsNotifications,
      tipoNotificacao: typesSelected,
    }
    notificationStore.findAllNotifications(true)
  }, [typesSelected])

  useEffect(() => {
    setTypesSelected(notificationStore.paramsNotifications.tipoNotificacao)
  }, [notificationStore.paramsNotifications.tipoNotificacao])

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      style={{ zIndex: 999 }}
      className={classes.root}
      placement='bottom'
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id='menu-notifications-filters'
          style={{
            transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
          }}
        >
          <Paper style={{ width: 200 }}>
            <ClickAwayListener onClickAway={() => {}}>
              <MenuList>
                <MenuItem
                  onClick={() =>
                    handleChangeCheckbox(types.TODOS, !typesSelected.includes(types.TODOS))
                  }
                  className={classes.menuItem}
                >
                  <div className={classes.wrapperCheckbox}>
                    <Checkbox
                      checked={typesSelected.includes(types.TODOS)}
                      color={'primary'}
                      onChange={({ target: { checked } }) =>
                        handleChangeCheckbox(types.TODOS, checked)
                      }
                    />
                    Todos
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleChangeCheckbox(types.AGENDA, !typesSelected.includes(types.AGENDA))
                  }
                  className={classes.menuItem}
                >
                  <div className={classes.wrapperCheckbox}>
                    <Checkbox
                      checked={typesSelected.includes(types.AGENDA)}
                      color={'primary'}
                      onChange={({ target: { checked } }) =>
                        handleChangeCheckbox(types.AGENDA, checked)
                      }
                    />
                    Agenda
                  </div>
                  <AgendaIcon color='#505050' size='18' />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleChangeCheckbox(
                      types.ATENDIMENTO,
                      !typesSelected.includes(types.ATENDIMENTO)
                    )
                  }
                  className={classes.menuItem}
                >
                  <div className={classes.wrapperCheckbox}>
                    <Checkbox
                      checked={typesSelected.includes(types.ATENDIMENTO)}
                      color={'primary'}
                      onChange={({ target: { checked } }) =>
                        handleChangeCheckbox(types.ATENDIMENTO, checked)
                      }
                    />
                    Atendimento
                  </div>
                  <ClipboardTaskListIcon color='#505050' size='18' />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleChangeCheckbox(types.PACIENTE, !typesSelected.includes(types.PACIENTE))
                  }
                  className={classes.menuItem}
                >
                  <div className={classes.wrapperCheckbox}>
                    <Checkbox
                      checked={typesSelected.includes(types.PACIENTE)}
                      color={'primary'}
                      onChange={({ target: { checked } }) =>
                        handleChangeCheckbox(types.PACIENTE, checked)
                      }
                    />
                    Paciente
                  </div>
                  <PacientesIcon color='#505050' size='18' />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleChangeCheckbox(
                      types.FINANCEIRO,
                      !typesSelected.includes(types.FINANCEIRO)
                    )
                  }
                  className={classes.menuItem}
                >
                  <div className={classes.wrapperCheckbox}>
                    <Checkbox
                      checked={typesSelected.includes(types.FINANCEIRO)}
                      color={'primary'}
                      onChange={({ target: { checked } }) =>
                        handleChangeCheckbox(types.FINANCEIRO, checked)
                      }
                    />
                    Financeiro
                  </div>
                  <MoneyIcon color='#505050' size='18' />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleChangeCheckbox(types.SISTEMA, !typesSelected.includes(types.SISTEMA))
                  }
                  className={classes.menuItem}
                >
                  <div className={classes.wrapperCheckbox}>
                    <Checkbox
                      checked={typesSelected.includes(types.SISTEMA)}
                      color={'primary'}
                      onChange={({ target: { checked } }) =>
                        handleChangeCheckbox(types.SISTEMA, checked)
                      }
                    />
                    Sistema
                  </div>
                  <ConfiguracoesIcon color='#505050' size='18' />
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
})

const styles = () => ({
  root: {
    left: '60px !important',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
  },
  wrapperCheckbox: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
  },
})

const FilterTypesNotificationWithStyles = withStyles(styles)(FilterTypesNotification)
export default inject('notificationStore')(FilterTypesNotificationWithStyles)
