const gridContainer = {
    display: 'grid',
    gridTemplateColumns: `240px 1fr`,
    gridTemplateRows: '1fr 64px',
    gridTemplateAreas: ` 
            "sidebar-left content" 
            "footer footer"
            `,
    flex: 1
};

const primaryColor = '#00ACA9';
const primaryColorLight = '#00B0AE';
const primaryColorDark = '#009A97';
const green = '#196B6C';

const background = '#F9F9F9';
const fontColor = '#333333';
const yellow = '#FBBF6D';
const red = '#FB6D6D';
const purple = '#9B69FF';
const blue = '#6DC7FB';
const gray = '#666666';
const grayLight = '#969696';
const blueLight = '#52D5FC';
const sadtColor = '#2A7EAA';
const guiaInternacaoColor = "#242B80";
const fontColorDark = '#3e3e3e';
const procedimentoRealizadoColor = '#FC71B4';
const anamneseColor = "#5462E0";
const avaliacaoAntropometricaColor = "#B1B999";

const image = {
    height: 62,
    width: 62,
    backgroundColor: fontColor,
    borderRadius: "100%"
};


export {
    gridContainer,
    primaryColor,
    primaryColorLight,
    primaryColorDark,
    background,
    fontColor,
    yellow,
    red,
    purple,
    blue,
    image,
    blueLight,
    gray,
    grayLight,
    sadtColor,
    fontColorDark,
    procedimentoRealizadoColor,
    anamneseColor,
    avaliacaoAntropometricaColor,
    guiaInternacaoColor,
    green,
};
