import moment from "moment";
import { inject } from "mobx-react";
import React, { useEffect, useReducer, useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, withStyles } from "@material-ui/core";
import string from "../../utils/string";
import Table from "../../components/Table/Table";
import ButtonAcaoEmAberto from "./Buttons/ButtonAcaoEmAberto";
import ButtonAcaoDescartados from "./Buttons/ButtonAcaoDescartados";
import { findByIdPacienteAnexo, updateAgendamentoAndPersistSujeitoAtencao } from "../../services/SolicitacoesPacientesService";
import Scroll from "../../components/InfiniteScroll/Scroll";
import { Button } from "../../components/ui/Buttons";
import VincularPaciente from "./Modal/VincularPaciente";
import { findAgendamentoById } from "../../services/ProntuarioService";
import SuccessIcon from "../../components/Icon/SuccessIcon";
import AlertModal from "../../components/Modal/AlertModal";

const getAttributesAgendamento = (agendamento) => {
    const agendamentoSelecionado = agendamento?.data?.data?.findByIdAgendamento;
    const {
      id,
      data,
      situacao,
      dataInicioAtendimento,
      dataFimAtendimento,
      tipo,
      nome,
      telefone,
      cpf,
      documento,
      documentoTipo,
      dataNascimento,
      remoto,
      linkTelemedicina,
      observacao,
      convenio,
      horaInicio,
      horaFim,
      paciente,
      procedimentos,
      sujeitoAtencao,
      urgente,
      unidade,
      profissionalSaude,
    } = agendamentoSelecionado || {}
    const newAgendamento = {
      id,
      data,
      situacao,
      dataInicioAtendimento,
      dataFimAtendimento,
      tipo,
      nome,
      telefone,
      cpf,
      documento,
      documentoTipo,
      dataNascimento,
      remoto,
      linkTelemedicina,
      observacao,
      convenio,
      horaInicio,
      horaFim,
      paciente,
      procedimentos,
      ...(sujeitoAtencao?.id ? { sujeitoAtencao: {
        id: sujeitoAtencao?.id,
      }}: {sujeitoAtencao: null}),
      unidade: {id: unidade?.id},
      profissionalSaude: {id: profissionalSaude?.id},
      urgente,
  };
  return newAgendamento;
}


const SolicitacoesPacientesTable = observer(
  ({ classes, solicitacoesPacientesStore }) => {
    const [modalVincularPaciente, setModalVincularPaciente] = useState({
      open: false,
      data: {},
    })
    const [isLoadingCadastro, setIsLoadingCadastro] = useState(false)
    const [openAlertCadastro, toggleAlertCadastro] = useReducer((state) => !state, false)
    const {
      filterSolicitacoesPacientes,
      isLoading,
    } = solicitacoesPacientesStore;

    useEffect(() => {
      solicitacoesPacientesStore.loadSolicitacaoPaciente(true);
    }, [filterSolicitacoesPacientes.sortDir]);

    const handleClick = async (idSolicitacao) => {
      const query =
        filterSolicitacoesPacientes.tipo?.value === "ANAMNESE"
          ? solicitacoesPacientesStore.getDadosAnamnese
          : findByIdPacienteAnexo;

      const response = await query(idSolicitacao);

      if (response.arquivoTipo === "ARQUIVO") return;

      solicitacoesPacientesStore.propsModalSolicitacoesPacientes = {
        open: true,
        dados: response,
      };
    };

    const handleVincularPaciente = (solicitacao) => {
      setModalVincularPaciente({ open: true, data: solicitacao?.agendamento })
    }

    const handleCadastrarPaciente = async (solicitacao) => {
      try {
        setIsLoadingCadastro(true)
        const agendamentoById = await findAgendamentoById(solicitacao?.agendamento?.id)
        const agendamento = getAttributesAgendamento(agendamentoById)

         await updateAgendamentoAndPersistSujeitoAtencao({
          agendamento: {
            ...agendamento,
          }}
        )
        await solicitacoesPacientesStore.loadSolicitacaoPaciente(true);
        toggleAlertCadastro()
      } catch (error) {
        console.error(error)
        solicitacoesPacientesStore.notification = {
          isOpen: true,
          message: 'Erro ao cadastrar paciente',
        }
      } finally {
        setIsLoadingCadastro(false)
      }
    }

    const columns = [
      {
        Header: "Data",
        field: "dataHoraLancamento",
        getValue: (solicitacao) =>
          moment(solicitacao.dataHoraLancamento).format("DD/MM/YYYY"),
      },
      {
        Header: "Paciente",
        getValue: (solicitacao) => solicitacao.sujeitoAtencao?.nome || solicitacao?.agendamento?.paciente?.nome,
      },
      {
        Header: "Tipo",
        getValue: (solicitacao) => string.capitalize(solicitacao.arquivoTipo),
      },
      {
        Header: "Ação",
        isClick: true,
        getValue: (solicitacao) => {
          const { descartado, agendamento } = solicitacao;
          const { sujeitoAtencao } = agendamento || {};
          if (!sujeitoAtencao?.id) {
            return (
              <div style={{ display: "flex", gap: "10px" }}>
                <Button onClick={() => handleCadastrarPaciente(solicitacao)} isLoading={isLoadingCadastro}>Cadastrar</Button>
                <Button kind='transparent' onClick={() => handleVincularPaciente(solicitacao)}>Vincular a um perfil</Button>
              </div>
            )
          } else if (descartado) {
            return <ButtonAcaoDescartados solicitacao={solicitacao} />;
          } else {
            return <ButtonAcaoEmAberto solicitacao={solicitacao} />;
          }
        },
      },
    ];

    const handleClickOrdenar = (value) => {
      solicitacoesPacientesStore.reset();

      const sortDir =
        filterSolicitacoesPacientes.sortField !== value
          ? "ASC"
          : filterSolicitacoesPacientes.sortDir === "ASC"
          ? "DESC"
          : "ASC";

      solicitacoesPacientesStore.filterSolicitacoesPacientes = {
        ...filterSolicitacoesPacientes,
        sortDir: sortDir,
        sortField: value,
      };
    };

    return (
      <div className={classes.contentTable}>
        <Scroll
          loadMore={() => solicitacoesPacientesStore.loadSolicitacaoPaciente()}
          hasMore={!filterSolicitacoesPacientes.lastPage}
          pageStart={0}
          initialLoad={false}
        >
          {solicitacoesPacientesStore.solicitacoesPacientes.length > 0 && (
            <Table
              dados={solicitacoesPacientesStore.solicitacoesPacientes}
              columns={columns}
              clickable={true}
              comOrdenacao
              handleClick={handleClick}
              handleClickOrdenar={handleClickOrdenar}
              ordenarTabela={filterSolicitacoesPacientes}
              classes={{ table: classes.table }}
            />
          )}
          {solicitacoesPacientesStore.solicitacoesPacientes.length === 0 && (
            <div className={classes.listaVazia}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                "Nenhuma solicitação encontrada"
              )}
            </div>
          )}
        </Scroll>
        {modalVincularPaciente.open && (
          <VincularPaciente
            open={modalVincularPaciente.open}
            onClose={() => setModalVincularPaciente({ open: false })}
            data={modalVincularPaciente.data}
            refetch={() => solicitacoesPacientesStore.loadSolicitacaoPaciente(true)}
            openNotification={(message) => solicitacoesPacientesStore.notification = { isOpen: true, message }}
          />
        )}
        {openAlertCadastro && (
          <AlertModal
            open={openAlertCadastro}
            onClose={toggleAlertCadastro}
            icon={<SuccessIcon color='white' size={24} />}
            bgIconColor={'#219A97'}
          >
            <h1 className={classes.titleAlertModal}>
              Paciente cadastrado com sucesso!
            </h1>
          </AlertModal>
        )}
      </div>
    );
  }
);

const styles = {
  contentTable: {
    borderTop: "2px solid rgba(0, 0, 0, 0.1)",
    overflow: "auto",
    height: "calc(100% - 65px)",
  },
  table: {
    "& thead": {
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    "& th": {
      textAlign: "start !important",
    },
  },
  listaVazia: {
    fontWeight: 500,
    fontSize: "36px",
    textAlign: "center",
    marginTop: "10%",
  },
  titleAlertModal: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#505050',
    textAlign: 'center',
    margin: '0',
    padding: '0',
    marginTop: '24px',
  },
};

const SolicitacoesPacientesTableWithStyles = withStyles(styles)(
  SolicitacoesPacientesTable
);
export default inject("solicitacoesPacientesStore")(
  SolicitacoesPacientesTableWithStyles
);
