import React from 'react';
import MaskedInput from "react-text-mask";
import string from "../../utils/string";
import InputDefault from '../../components/Input/InputDefault';

export const loginMask = (props) => {
    const {inputRef, ...other} = props;

        const texto = [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/];
        const cpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /s/];
        const cpfEntidadeSecretario = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /s/];

        const value = string.removeSpecialChars(props.value);

        let mask = texto;

        if (!isNaN(parseInt(value))) {
            if (value.length <=11) {
                mask = cpf;
            }

            else {
                mask = cpfEntidadeSecretario;
            }
        }

        return <MaskedInput
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            placeholderChar={'\u2000'}
            mask={mask}
            {...other}
        />
};

export const InputCpf = (props) => {
    return <InputDefault 
        InputProps={{ 
            inputComponent: loginMask,
        }} 
        placeholder="CPF"
        {...props} 
    />
}

