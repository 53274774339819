import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import {
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import Colors from "../../../template/Colors";
import classNames from "classnames";
import string from "../../../utils/string";

const styles = (theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing.unit,
    marginTop: 5,
    width: "200px",
    background: "#F2F2F2",
    borderRadius: "17px",
  },
  dadosAdicionais: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    boxSizing: "border-box",
    borderRadius: "100px",
    height: "32px",
    color: Colors.commons.gray9,
    padding: "0 10px",
  },
  dadosAdicionaisInativo: {
    opacity: 0.5,
    textDecoration: 'line-through',
  },
  visibilityIcon: {
    color: "#5F6368",
  },
  buttonVisibility: {
    display: "flex",
    padding: "1px",
    borderRadius: 100,
    "&:hover": {
      background: "#DFDFDF",
    },
  },
  listaDadosAdicionais: {
    display: "flex",
    flexDirection: "column",
    color: Colors.commons.gray7,

    "& span": {
      fontWeight: 700,
    },
  },
  quantidadeDadosAdicionais: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: " 100%",
    overflow: "hidden",
  },
});

const PopoverInfosAfericaoVital = props => {
  const { classes, quantidadeCamposPreenchido, dadosAdicionais } = props;
  const [ anchorEl, setAnchorEl ] = useState(null);
  let open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null)
  };

  return(
    <div>
        <div
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <div className={classNames(classes.dadosAdicionais, {[classes.dadosAdicionaisInativo]: !dadosAdicionais.ativo})}>
            <div className={classes.quantidadeDadosAdicionais}>
              {quantidadeCamposPreenchido}
            </div>
            <div className={classes.buttonVisibility}>
              <VisibilityIcon className={classes.visibilityIcon} />
            </div>
          </div>
        </div>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div className={classes.listaDadosAdicionais}>
            {dadosAdicionais.coleta && <div>
              Coleta: <span> {string.capitalize(dadosAdicionais.coleta).replace("_", " ")} </span>
            </div>}
            {dadosAdicionais.frequenciaCardiaca && <div>
              Freq cardíaca: <span> {dadosAdicionais.frequenciaCardiaca} bpm </span>
            </div>}
            {dadosAdicionais.frequenciaRespiratoria && <div>
              Freq respiratória: <span> {dadosAdicionais.frequenciaRespiratoria} irpm </span>
            </div>}
            {dadosAdicionais.glicemia && <div>
              Glicemia: <span> {dadosAdicionais.glicemia} mg/dl </span>
            </div>}
             { dadosAdicionais.pressaoArterialSistolica && <div>
              PA sistólica: <span> {dadosAdicionais.pressaoArterialSistolica} mmhg </span>
            </div>}
            {dadosAdicionais.pressaoArterialDiastolica && <div>
              PA diastólica: <span> {dadosAdicionais.pressaoArterialDiastolica} mmhg </span>
            </div>}
           { dadosAdicionais.saturacaoO2 && <div>
              Saturação O2: <span> {dadosAdicionais.saturacaoO2} % </span>
            </div>}
            { dadosAdicionais.temperatura && <div>
              Temperatura: <span> {dadosAdicionais.temperatura} °C </span>
            </div>}
          </div>
        </Popover>
      </div>
  )
}

export default withStyles(styles)(PopoverInfosAfericaoVital);
