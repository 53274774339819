import React from "react";
import {
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import AddIcon from "../../../components/Icon/AddIcon";
import { Button } from "../../../components/ui/Buttons";
import { columnsMensagens } from "../../../pages/Configuracoes/Constants/Constants"
import ModalMensagem from "../../Atendimento/MensagemModal/mensagemModal";
import FiltroHeader from "../../../template/Header/FiltroHeader";
import HeaderConfiguracoes from "../../../components/Header/HeaderConfiguracoes";
import { FindMensagemSistemaByProfissionalSaudeId } from "../../../services/MensagensService";
import Notification from "../../../components/Notification";
import { checkUserRole } from "../../../utils/checkUserRole";
import { getProfissionalSaudeLogado } from "../../../services/UsuarioService";

@inject('filtroHeaderStore','atendimentoStore')
@observer
class Mensagem extends React.Component {
  state = {
    openModalNovaMensagem: false,
    isConfirmar: false,
    pageableDTO: {
      pageNumber: 0,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "mensagem",
    },
    novaMensagem: {},
    isModalEditar: false,
    mensagensUnidade: [{
      nome: "",
      tipoPadrao: "",
      ativo: false,
      descricao: "",
      id: null,
    }],
    mensagemSelected: null,
    isLoadingMessages: false,
    openNotification: {
      mensagem: "",
      variant: "",
      isOpen: false,
      autoHideDuration: 2000,
    },
    filtroPronto: false,
    isVizualizaOutrosProfissionais: false,
    profissional:null,
    utilizaPlugzapi:false,
  }


  async componentDidMount() {
    this.props.filtroHeaderStore.showFiltroProfissionalSaude = true;
    const{filtroHeaderStore}=this.props
    if(filtroHeaderStore.profissionalSaude){
      this.loadMessages(filtroHeaderStore.profissionalSaude.id)
    }else{
      let profissionalLogado = await getProfissionalSaudeLogado();
      await this.loadMessages(profissionalLogado.id);
    }
    this.changeRoles();
     this.getConfigUnidade()
  }

  getConfigUnidade = async () => {
    const{atendimentoStore}=this.props
    const response= await atendimentoStore.getConfiguracaoUnidadeLogada()
    const{utilizaPlugzapi}=response
    this.setState({utilizaPlugzapi:utilizaPlugzapi})
  }

  changeRoles = async () => {
    const isVizualizaOutrosProfissionais = await checkUserRole("ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS");
    this.setState({ isVizualizaOutrosProfissionais: isVizualizaOutrosProfissionais });
  }

  loadMessages = async (profissionalLogado) => {
    this.setState({isLoadingMessages:true})
    try {
      const mensagens = await FindMensagemSistemaByProfissionalSaudeId({
        profissionalSaudeId: profissionalLogado
      });
      this.setState({ mensagensUnidade: mensagens });
    } catch (error) {
      console.error(error);
    }finally{
      this.setState({isLoadingMessages:false})
    }
  }

  componentDidUpdate(prevProps) {
    const{filtroHeaderStore}=this.props;
    if( filtroHeaderStore.profissionalSaude.id !== prevProps.filtroHeaderStore.profissionalSaude.id){ 
      this.loadMessages(filtroHeaderStore.profissionalSaude.id);
    }
  }


 
  handleClickNovaMensagem = () => {
    this.setState({ openModalNovaMensagem: true });
  }

  handleCloseModalNovaMensagem = () => {
    this.setState({ mensagemSelected: null });
    this.setState({ openModalNovaMensagem: false, });
  }

  handleEdit = (id) => {
    this.setState({ isLoading: true });
    this.setState({ mensagemSelected: id, openModalNovaMensagem: true });
  }

  handleOpenNotification = (openNotification) => {
    this.setState({ openNotification });
    setTimeout(this.closeNotification, 2000);
  }

  closeNotification = () => {
    this.setState({ openNotification: { variant: "", mensagem: "", isOpen: false, } });
  }

  render() {
    const { 
      isVizualizaOutrosProfissionais, 
      openModalNovaMensagem, 
      mensagemSelected, 
      openNotification,
      mensagensUnidade,
      isLoadingMessages,
      isConfirmar, 
      isLoading,
      utilizaPlugzapi,
       } = this.state;
    const { classes } = this.props;

    return (
      <>
        <div className={classes.wrapper}>
          <PageTitle title={pageConfiguracoes("Mensagens do Sistema ")} />
          <HeaderConfiguracoes 
          classes={{ header: classes.HeaderMensagensUnidade }}
          >
            <h1 className={classes.title}>Mensagens do sistema </h1>
            <FiltroHeader
              screen={'CONFIGURACAO_MENSAGENS_SISTEMA'}
              showFiltroProfissionalSaude
              loadMensagens={this.loadMessages}
            />
          </HeaderConfiguracoes>
          {isLoadingMessages ?
              <div className={classes.loading}>
                <CircularProgress></CircularProgress>
              </div>
          :
          <div className={classes.contentWrapper}>
            <div>
              <Scroll>
                {mensagensUnidade && <Table
                  dados={mensagensUnidade}
                  columns={columnsMensagens({
                    headerNome: "Mensagem",
                    functions: {
                      handleEdit: this.handleEdit,
                    },
                  })}
                  clickable={true}
                  handleClick={(e) => this.handleEdit(e)}
                  classes={{
                    tableHead: classes.tableHeader,
                    tableRow: classes.tableRow,
                  }}
                />}
              </Scroll>
            </div>
            {openModalNovaMensagem && <ModalMensagem
              openModal={openModalNovaMensagem}
              onClose={this.handleCloseModalNovaMensagem}
              isConfirmar={isConfirmar}
              isModalEditar={this.state.isModalEditar}
              mensagemSelected={mensagemSelected}
              isLoading={isLoading}
              handleOpenNotification={this.handleOpenNotification}
              closeNotification={this.closeNotification}
              isVizualizaOutrosProfissionais={isVizualizaOutrosProfissionais}
              loadMensagens={this.loadMessages}
              utilizaPlugzapi={utilizaPlugzapi}
            />
            }
            <Button
              shape='circle'
              bgColor='#26ACA9'
              onClick={this.handleClickNovaMensagem}
              shadow
              className={classes.buttonCriar}
            >
              <AddIcon />
            </Button>
            <div onClick={this.closeNotification}>
              <Notification
                isOpen={openNotification.isOpen}
                message={openNotification.mensagem}
                variant={openNotification.variant}
                onClose={this.closeNotification}
              />
            </div>
          </div>}
        </div>
      </>
    )
  }
}
export default withStyles(theme => ({
  scroll: {
    maxHeight: '165px',
    flex: 1,
    marginTop: '8px',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    '&>div>div>table>thead>tr>td': {
      height: '40px',
      width: "400px",
      overflow: "hidden",
      '&>th': {
        padding: '0 8px',
        textAlign: 'center'
      }
    },
    '&>div>div>table>tbody>tr>td': {
      height: '40px',
      width: "400px",
      overflow: "hidden",
      '&>td': {
        padding: '0 22px',
        textAlign: 'center'
      },
    },
  },
  wrapper: {
    background: "#F2F2F2",
    overflowY: "hidden",
    overflowX: "hidden",
    "& >div>div":{
    overflow:"hidden",
    }
  },
  contentWrapper: {
    margin: "16px",
    background: "#fff",
    "& >div>div>div>div":{
      height: "calc(100vh - 150px)",
    },
  },
  tableHeader: {
    position:"sticky",
    zIndex: 1,
    "& tr > th": {
      padding: "5 16px",
      position: "sticky",
      textAlign: "start !important",
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: "200px",
      
    },
    
  },
  tableRow:{
    textWrap:"noWrap",
    overflow:"hidden",
    textOverflow:"ellipsis",
    "& >td ":{
      color:"#505050",
      fontWeight:"500",
    },
      "& div:first-child":{
        width: "70%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }
  },
  buttonCriar: {
    position: 'absolute',
    bottom: 88,
    right: 37,
    zIndex: 100
  },
  HeaderMensagensUnidade: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
  },
  popper: {
    background: "pink"
  },
  loading:{
    height: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",

  }
}))(Mensagem);