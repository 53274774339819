const styles = theme => ({
    content: {
        gridArea: "content",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        position: "relative",
        overflow: "hidden"
    },

    titleHeader:{
        fontSize: 25,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginLeft: 43.5,
    },

    headerContent:{
        justifyContent: 'space-between',
        height: 64,
        alignContent: 'center',
        paddingRight: 16,
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    titleFilterContainer:{
      margin: '25px 0 25px 20px',
    },

    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },

    formContainer: {
        paddingLeft: "calc(2% + 8px)",
        overflow: "auto",
        height: "100%",

        "& h6": {
            fontSize: "0.9rem",
            fontWeight: "bold",
            marginBottom: "15px",
        }
    },

    previewContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80%",
    },

    previewPaper: {
        position: "relative",
        border: "1px solid " + theme.palette.commons.gray5,
    },

    previewMargemVertical: {
        position: "absolute",
        width: "1px",
        backgroundColor: theme.palette.primary.main
    },

    previewMargemHorizontal: {
        position: "absolute",
        height: "1px",
        backgroundColor: theme.palette.primary.main
    },

    previewMargemVerticalFocused: {
        width: "2px",
    },

    previewMargemHorizontalFocused: {
        height: "2px",
    },

    previewMargemSuperior: {
        top: 8,
        left: 0,
        right: 0,
    },

    previewMargemInferior: {
        bottom: 8,
        left: 0,
        right: 0,
    },

    previewMargemEsquerda: {
        top: 0,
        bottom: 0,
        left: 8,
    },

    previewMargemDireita: {
        top: 0,
        bottom: 0,
        right: 8,
    },

    formControlLabelCheckbox: {
        marginLeft: 0,
        marginBottom: "10px",
    },

    previewErrorContainer: {
        marginBottom: '20px',
    },

    previewErrorMessage: {
        color: theme.palette.commons.red,
        margin: '0 0 5px',
        textAlign: 'center',
    },

    numberStepGroup: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 15,
        marginRight: 50,

        "& label": {
            marginBottom: 3
        }
    },

    formGroup: {
        marginBottom: "30px",
    },

    formGroupTamanhoPapel: {
        marginBottom: "15px",
    },

    buttonPapelContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: "15px",
    },

    buttonPapel: {
        padding: "8px",
        border: "1px solid rgba(105,105,105,0.7)",
        borderRadius: "10px",
        marginRight: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "transparent"
    },

    buttonPapelSelected: {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
    },

    notFoundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",

        '& h3': {
            color: theme.palette.primary.main,
            fontWeight: 'normal',
            fontSize: '1rem'
        }
    },
    
    acoes: {
		position: "absolute",
		right: "35px",
		bottom: "45%",
		display: "flex",
		flexDirection: "column",
	},

    buttonSalvar: {
		margin: 8,
		height: 40,
		width: 40,
        color: '#FFFFFF',
		background: theme.palette.primary.main,
		"&:hover": {
			background: theme.palette.primary.dark,
        } 
	},
    circularLoad: {
        marginRight: '15px',
    },
    buttonCancelar: {
		margin: 8,
		height: 40,
		width: 40,
		color: '#fff',
		background: theme.palette.commons.red,
		"&:hover": {
			background: theme.palette.commons.darkRed,
		}
	},
    editarHtml: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: '200px',
        '& h3': {
            color: '#333333',
            fontSize: '14.4px',
            fontWeight: 'bold',
            fontFamily: 'Nexa'
        }
    },
    inputModeloHtml: {
        display: 'flex',
        flexDirection: 'column',
        '& span': {
            color: '#333333',
            fontSize: '14.4px',
            fontFamily: 'Nexa',
            marginBottom: '12px'
        }
    },
    imagemModelo: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        '& img': {
            maxWidth: '200px',
            maxHeight: '200px',
            borderRadius: '5px'
        },
        '& p': {
            color: '#333333',
            fontSize: '12px',
            fontFamily: 'Nexa',
            marginLeft: '8px'
        }
    },
    documentoFileInput: {
        marginBottom: '20px',
        '& input': {
            display: 'none'
        },
        '& button': {
            width: '200px',
        }
    },
    buttonsTab: {
        margin: '16px 24px',
    },
    contentTab: {
        height: "calc(100vh - 192px)",
    },
    assinaturaSwitchRoot: {
        marginLeft: '-10px'
    }
});

export default styles;
