import React from 'react';
import { withStyles, Menu, MenuItem } from '@material-ui/core';
import { getContactMenuOptions } from './utils/constants';

const styles = ({
  menuItem: {
    display: 'flex',
    width: '100%',
    padding: '8px 16px',
    gap: '16px'
  }
});

const ContactItemMenu = ({ classes, contact, open, anchorEl, onClose, handleToggleFixarContato }) => {

  const renderMenuItems = () => {
    const options = getContactMenuOptions({ contact, handleToggleFixarContato, onClose });
    return options.map((option, index) => {
      return (
        <MenuItem
          key={index}
          onClick={option.onClick}
          className={classes.menuItem}
        >
          {option.label}
          {option?.icon}
        </MenuItem>
      )
    });
  };

  return (
    <Menu 
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {renderMenuItems()}
    </Menu>
  );
};

export default withStyles(styles)(ContactItemMenu);
