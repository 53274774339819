import React from 'react';
import InputDefault from './InputDefault';
import { renderMaskedInput } from './InputMaskGeneric';

export const InputMaskCm = (props) => {
    const maskedProps = {
      suffix: ' cm',
      placeholder: ' cm',
      integerLimit: 3
    }

    return renderMaskedInput(maskedProps, props)
};

export const InputMaskCmWithDecimal = (props) => {
  const maskedProps = {
    suffix: ' cm',
    placeholder: ' cm',
    integerLimit: 3,
    decimalLimit: 1,
    allowDecimal: true
  }

  return renderMaskedInput(maskedProps, props)
};

export const InputCm = (props) => {
  let mascara;

  switch(props.mask){
    case 'cm': 
      mascara = InputMaskCm;
      break;
    case 'cmWithDecimal':
      default:
      mascara = InputMaskCmWithDecimal;
      break;
  }

    return <InputDefault InputProps={{ inputComponent: mascara }} {...props} />
};
