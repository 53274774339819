import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const PacientesIcon = props => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M4.55803 14.6151L14.5423 14.4838C15.3203 14.4734 15.9683 15.0593 16.051 15.8175L16.0597 15.9615L16.0862 17.9574C16.1315 21.4519 12.4356 22.4995 9.65602 22.5363C6.93819 22.5723 3.31806 21.6656 3.1152 18.3587L3.10672 18.1295L3.08025 16.1326C3.06993 15.3545 3.65577 14.7066 4.41395 14.6239L4.55803 14.6151ZM16.5271 14.4564L21.5312 14.3901C22.3093 14.3798 22.9572 14.9648 23.0399 15.7237L23.0487 15.8679L23.0685 17.3655C23.1081 20.4227 20.268 21.397 18.1294 21.4254C17.4504 21.4344 16.6994 21.3485 15.9885 21.1322C16.6303 20.3745 17.0405 19.4175 17.0817 18.2238L17.0846 17.9441L17.0581 15.9473C17.0516 15.4537 16.8965 14.9995 16.6421 14.6163L16.5271 14.4564L21.5312 14.3901L16.5271 14.4564ZM9.40453 3.56631C11.8856 3.53342 13.9241 5.51856 13.957 7.99964C13.9899 10.4807 12.0047 12.5192 9.52366 12.5521C7.04258 12.585 5.00409 10.5998 4.9712 8.11877C4.93831 5.63769 6.92346 3.5992 9.40453 3.56631ZM18.4168 5.44403C20.3467 5.41844 21.932 6.96223 21.9576 8.89217C21.9832 10.8221 20.4394 12.4074 18.5095 12.433C16.5795 12.4586 14.9942 10.9148 14.9687 8.98483C14.9431 7.05488 16.4869 5.46961 18.4168 5.44403Z" 
      fill="currentColor" />
    </svg>


  );
};

export default PacientesIcon;