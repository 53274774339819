import React from 'react'
import { withStyles } from '@material-ui/core'

import PriorityIcon from '../../../../../../components/Icon/Priority'
import CakeIcon from '../../../../../../components/Icon/Cake'

const BADGE_TYPES = {
  BIRTHDAY: 'BIRTHDAY',
  PRIORITY: 'PRIORITY',
  ONLINE: 'ONLINE',
}

const BADGE_PROPS_BY_TYPE = {
  [BADGE_TYPES.ONLINE]: {
    left: 0,
    top: '-2%',
    backgroundColor: '#26ACA9',
    width: 8,
    height: 8,
    border: '2px solid #fff',
  },
  [BADGE_TYPES.PRIORITY]: {
    left: '-10%',
    bottom: 0,
    backgroundColor: '#FB7676',
    width: 18,
    height: 18,
  },
  [BADGE_TYPES.BIRTHDAY]: {
    right: '-10%',
    bottom: 2,
    backgroundColor: '#717FFC',
    width: 12,
    height: 12,
  },
}

const getBadgePropsByType = type => {
  return {
    position: 'absolute',
    borderRadius: '50%',
    border: '2px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...BADGE_PROPS_BY_TYPE[type],
  }
}

const SujeitoAtencaoAvatar = ({ classes, birthday, online, urgente, url }) => {
  const defaultUrl = require('../../../../../../assets/img/svg/img-profile-default.svg')
  const sourceImage = url.includes('/null') ? defaultUrl : url
  return (
    <div className={classes.imageContainer}>
      <img src={sourceImage} alt='Imagem paciente' />
      <>
        {online && <div style={{ ...getBadgePropsByType(BADGE_TYPES.ONLINE) }} />}
        {urgente && (
          <div style={{ ...getBadgePropsByType(BADGE_TYPES.PRIORITY) }}>
            <PriorityIcon color='white' size='12' />
          </div>
        )}
        {birthday && (
          <div style={{ ...getBadgePropsByType(BADGE_TYPES.BIRTHDAY) }}>
            <CakeIcon color='white' size='10' />
          </div>
        )}
      </>
    </div>
  )
}

const styles = {
  imageContainer: {
    position: 'relative',
    '@media (max-width: 1525px)': {
      display: 'none',
    },
    '& img': {
      width: 40,
      height: 40,
      borderRadius: '50%',
    },
  },
}

export default withStyles(styles)(SujeitoAtencaoAvatar)
