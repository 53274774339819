import Api from '../config/api';

export const imprimirDocumentoAssinado = (variables) => {
  return Api.post("", {
    query: `
      mutation ($camposEntrada: [DocumentoModeloCampoEntradaInput], $documentoModelo: DocumentoModeloInput, $sujeitoAtencao: SujeitoAtencaoInput, $tokenAplicativo : String, $nome: String, $documento: String) {
        criarEImprimirAssinadaDocumento(documentoModelo: $documentoModelo, sujeitoAtencao: $sujeitoAtencao, camposEntrada: $camposEntrada, tokenAplicativo: $tokenAplicativo, nome: $nome, documento: $documento) {
          dataHoraEmissao
          documento
          pdf
          id
          entradaProntuario{
            urlDownload
          }
          unidade {
            cnpj
            nome
            telefonePrincipal
            endereco {
              bairro
              cep
              complemento
              estado {
                nome
                uf
              }
              municipio {
                nome
              }
              nomeLogradouro
              numero
            }
          }
        }
      }`,
    variables: variables
  });
};
