import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const ProcedimentoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      {...normalizeProps(props)}
    >
      <path
        d="M7.07322 1.07322C7.17085 0.975592 7.32915 0.975592 7.42678 1.07322L8.92678 2.57322C9.02441 2.67085 9.02441 2.82915 8.92678 2.92678C8.82915 3.02441 8.67085 3.02441 8.57322 2.92678L8.375 2.72855L7.47855 3.625L8.42678 4.57322C8.52441 4.67085 8.52441 4.82915 8.42678 4.92678C8.32915 5.02441 8.17085 5.02441 8.07322 4.92678L7.5 4.35355L4.71967 7.13388C4.48525 7.3683 4.16731 7.5 3.83579 7.5H2.85355L1.42678 8.92678C1.32915 9.02441 1.17085 9.02441 1.07322 8.92678C0.975592 8.82915 0.975592 8.67085 1.07322 8.57322L2.5 7.14645V6.16421C2.5 5.83269 2.6317 5.51475 2.86612 5.28033L3.14645 5L3.57322 5.42678C3.67085 5.52441 3.82915 5.52441 3.92678 5.42678C4.02441 5.32915 4.02441 5.17085 3.92678 5.07322L3.5 4.64645L3.89645 4.25L4.32322 4.67678C4.42085 4.77441 4.57915 4.77441 4.67678 4.67678C4.77441 4.57915 4.77441 4.42085 4.67678 4.32322L4.25 3.89645L4.64645 3.5L5.07322 3.92678C5.17085 4.02441 5.32915 4.02441 5.42678 3.92678C5.52441 3.82915 5.52441 3.67085 5.42678 3.57322L5 3.14645L5.64645 2.5L5.07322 1.92678C4.97559 1.82915 4.97559 1.67085 5.07322 1.57322C5.17085 1.47559 5.32915 1.47559 5.42678 1.57322L6.375 2.52145L7.27145 1.625L7.07322 1.42678C6.97559 1.32915 6.97559 1.17085 7.07322 1.07322ZM8.02145 2.375L7.625 1.97855L6.72855 2.875L7.125 3.27145L8.02145 2.375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProcedimentoIcon;
