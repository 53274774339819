import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DesativaIcon = (props) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M0.0488281 5.31638C0.0488281 2.72449 2.14997 0.623352 4.74186 0.623352H17.2566C19.8485 0.623352 21.9496 2.72449 21.9496 5.31638V17.8311C21.9496 20.423 19.8485 22.5241 17.2566 22.5241H4.74186C2.14997 22.5241 0.0488281 20.423 0.0488281 17.8311L0.0488281 5.31638ZM6.53529 7.10982C6.22983 7.41527 6.22983 7.91052 6.53529 8.21597L9.89307 11.5738L6.53529 14.9315C6.22983 15.237 6.22983 15.7322 6.53529 16.0377C6.84075 16.3431 7.33599 16.3431 7.64145 16.0377L10.9992 12.6799L14.357 16.0377C14.6625 16.3431 15.1577 16.3431 15.4632 16.0377C15.7686 15.7322 15.7686 15.237 15.4632 14.9315L12.1054 11.5738L15.4632 8.21597C15.7686 7.91052 15.7686 7.41527 15.4632 7.10982C15.1577 6.80436 14.6625 6.80436 14.357 7.10982L10.9992 10.4676L7.64145 7.10982C7.33599 6.80436 6.84075 6.80436 6.53529 7.10982Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DesativaIcon;
