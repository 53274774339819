import React, { useEffect, useReducer } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

import { withStyles, Switch } from "@material-ui/core";
import Dialog from "../../../../components/Dialog/Dialog";
import { styles } from "./styles";

import InputForm from "../../../../components/Input/InputForm";
import { TextFieldSearch } from "../../../../components/TextField";

import { Button } from "../../../../components/ui/Buttons";
import ProfissionalIcon from "../../../../components/Icon/ProfissionalIcon";
import AddIcon from "../../../../components/Icon/AddIcon";
import CloseIcon from "../../../../components/Icon/Close";

import Scroll from "../../../../components/InfiniteScroll/Scroll";
import Table from "../../../../components/Table/Table";
import { tussTableHeaderColumns } from "./constants/tableHeaderColumns";

import classNames from "classnames";
import DocumentTextIcon from "../../../../components/Icon/DocumentTextIcon";
import PrintIcon from "../../../../components/Icon/Print";
import printPage from "../../../../utils/printPage";
import base64ToBlob from "../../../../utils/base64ToBlob";
import GuiaInternacaoSuggestions from "./components/GuiaInternacaoSuggestions/GuiaInternacaoSuggestions";

import { BlobProvider } from "@react-pdf/renderer";

import Notification from "../../../../components/Notification";
import GuiaInternacaoPDF from "../../../../template/pdf/GuiaInternacao/GuiaInternacao";

const cancelButtonStyles = {
  border: "none",
};

const addButtonStyles = {
  alignSelf: "flex-end",
  height: "32px",
  width: "32px",
};

const ModalGuiaInternacao = observer(
  ({
    classes,
    isOpen,
    onClose,
    defaultEntity,
    guiaInternacaoStore,
    reloadList,
    isDuplicate,
    unidadeAtual,
    sujeitoAtencaoId,
    reloadEntradaPronturio,
  }) => {
    const canViewOthersProfissionais = true;
    const {
      guiaInternacao,
      guiaInternacaoToPrint,
      formValidFields,
      isPrintingComplete,
    } = guiaInternacaoStore;
    const {
      indicacaoClinica: indicacaoClinicaError,
      caraterAtendimento: caraterAtendimentoError,
      tipoInternacao: tipoInternacaoError,
      regimeInternacao: regimeInternacaoError,
      indicacaoAcidente: indicacaoAcidenteError,
      profissionalSaude: profisisonalSaudeError,
    } = formValidFields;
    const {
      guiaInternacaoSelectedId,
      itemTabelaTuss,
      selectedEntity,
      notification,
    } = guiaInternacaoStore;
    const indicacaoClinicaInputFullSize = selectedEntity === "guiaInternacao";

    const [isLoading, toggleLoading] = useReducer((state) => !state, false);

    const handleSaveModelo = async () => {
      toggleLoading();
      try {
        await guiaInternacaoStore.handleSaveGuiaInternacaoModelo();
        guiaInternacaoStore.resetFormValidFields();
        toggleLoading();
        onClose();
        reloadList();
        guiaInternacaoStore.openNotification(
          "Modelo salvo com sucesso",
          "success"
        );
      } catch (error) {
        guiaInternacaoStore.openNotification(error.message, "error");
        toggleLoading();
      }
    };

    const loadModalData = async () => {
      await guiaInternacaoStore.loadGuiaInternacaoModeloById(isDuplicate);
    };

    const handleClickVerComoDocumento = async () => {
      toggleLoading();
      try {
        const base64 = await guiaInternacaoStore.imprimirComoDucumentoGuiaInternacao(
          sujeitoAtencaoId
        );
        printPage(base64ToBlob(base64));
        handleClose();
      } catch (error) {
        guiaInternacaoStore.openNotification(error.message, "error");
        toggleLoading();
      }
    };

    const handleClickPrint = async () => {
      toggleLoading();
      try {
        await guiaInternacaoStore.imprimirComoDucumentoGuiaInternacao(
          sujeitoAtencaoId
        );
        guiaInternacaoStore.isPrintingComplete = true;
      } catch (error) {
        guiaInternacaoStore.openNotification(error.message, "error");
        toggleLoading();
      }
    };

    const renderModalButtons = () => {
      const prontuarioButtonsStyles = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
        maxWidth: "50%",
      };

      if (selectedEntity === "guiaInternacao") {
        return (
          <div className={classes.buttonsArea}>
            <Button
              color="#505050"
              kind="transparent"
              style={prontuarioButtonsStyles}
              onClick={handleClickVerComoDocumento}
              disabled={isLoading}
              width="50%"
            >
              <DocumentTextIcon size={24} />
              <span>Ver como documento</span>
            </Button>
            <Button
              style={prontuarioButtonsStyles}
              onClick={handleClickPrint}
              disabled={isLoading}
              width="50%"
            >
              <PrintIcon size={20} />
              Imprimir
            </Button>
          </div>
        );
      }

      if (selectedEntity === "guiaInternacaoModelo") {
        return (
          <div className={classes.buttonsArea}>
            <Button
              bgColor="#FFF"
              color="black"
              onClick={handleClose}
              width="50%"
              style={cancelButtonStyles}
              isLoading={isLoading}
            >
              Cancelar
            </Button>
            <Button width="50%" onClick={handleSaveModelo} disabled={isLoading}>
              {guiaInternacaoSelectedId ? "Salvar" : "Criar"}
            </Button>
          </div>
        );
      }
    };

    const handleSelectSuggestion = (suggestion, type) => {
      if (type === "tussViewItem") {
        const { tussView } = suggestion;
        const { tabela, descricao } = tussView;
        return (guiaInternacaoStore.itemTabelaTuss = {
          tabela: { value: tabela, label: tabela },
          descricao,
          codigo: tussView,
          quantidade: 1,
        });
      }

      guiaInternacaoStore.loadGuiaInternacaoModeloAsGuiaInternacao(
        suggestion?.id
      );
    };

    const handleClose = async () => {
      toggleLoading();
      
      if (selectedEntity === "guiaInternacao")
      await reloadEntradaPronturio();

      guiaInternacaoStore.clearData();
      onClose();
    };

    const renderImpressaoCompleta = () => {
      if (!guiaInternacaoToPrint) return null;
      return (
        <div>
          <BlobProvider
            document={
              <GuiaInternacaoPDF
                guiaInternacao={guiaInternacaoToPrint}
                key={guiaInternacaoToPrint?.id}
              />
            }
          >
            {({ url, loading }) => {
              if (!loading) {
                printPage(url);
                guiaInternacaoStore.isPrintingComplete = false;
                handleClose();
              }
              return null;
            }}
          </BlobProvider>
        </div>
      );
    };

    const handleRemoveTuss = (index) => {
      const { guiaInternacao } = guiaInternacaoStore;
      const tabelaTussField =
        selectedEntity === "guiaInternacao"
          ? "guiaInternacaoTussViews"
          : "guiaInternacaoModeloTussViews";
      guiaInternacao[tabelaTussField].splice(index, 1);
      const newTuss = [...guiaInternacao[tabelaTussField]];
      guiaInternacaoStore.guiaInternacao = {
        ...guiaInternacao,
        [tabelaTussField]: newTuss,
      };
    };

    const tabelaTussField =
      selectedEntity === "guiaInternacao"
        ? "guiaInternacaoTussViews"
        : "guiaInternacaoModeloTussViews";
    const disableForm =
      selectedEntity === "guiaInternacao" && guiaInternacao?.id;
    const disabledAddTussViewButton =
      !itemTabelaTuss?.tabela ||
      !itemTabelaTuss?.codigo ||
      !itemTabelaTuss?.descricao ||
      !itemTabelaTuss?.quantidade;
    const readOnly = selectedEntity === "guiaInternacao" && guiaInternacao?.id;

    useEffect(() => {
      if (unidadeAtual) guiaInternacaoStore.unidadeId = unidadeAtual?.id;
    }, [unidadeAtual]);

    useEffect(() => {
      if (isOpen) {
        guiaInternacaoStore.selectedEntity = defaultEntity;
        if (guiaInternacaoSelectedId) loadModalData();
      }
    }, [isOpen]);

    return (
      <Dialog open={isOpen} classes={{ paper: classes.paper }}>
        <div
          className={classNames(
            classes.container,
            readOnly && classes.readOnlyContainer
          )}
        >
          <div
            className={classNames(
              classes.formArea,
              readOnly && classes.readOnlyFormArea
            )}
          >
            <div className={classes.modalHeader}>
              <span>
                {guiaInternacaoSelectedId
                  ? "Guia de internação"
                  : "Nova Guia de internação"}
              </span>
              {canViewOthersProfissionais &&
                selectedEntity === "guiaInternacaoModelo" && (
                  <div className={classes.profissionalSelector}>
                    <ProfissionalIcon size={24} color="#505050" />
                    <TextFieldSearch
                      name="profissional"
                      placeholder="Selecione..."
                      classInput={classNames(
                        classes.profissionalSelectRoot,
                        classes.selectRoot,
                        profisisonalSaudeError && classes.inputError
                      )}
                      classNotched={classes.notchedOutline}
                      debounceTimeout={300}
                      withPaginate
                      additional={{
                        page: 0,
                      }}
                      loadOptions={guiaInternacaoStore.loadProfissionaisSaude}
                      onChange={(e) =>
                        guiaInternacaoStore.onChangeGuiaInternacao(
                          e,
                          "profissionalSaude"
                        )
                      }
                      value={
                        guiaInternacaoStore.guiaInternacao.profissionalSaude
                      }
                      isDisabled={disableForm}
                    />
                  </div>
                )}
              {readOnly && (
                <CloseIcon
                  onClick={handleClose}
                  color="#505050"
                  style={{ cursor: "pointer" }}
                  size={24}
                />
              )}
            </div>
            <div className={classes.form}>
              {selectedEntity === "guiaInternacaoModelo" && (
                <div className={classes.inputArea}>
                  <label>Nome do modelo</label>
                  <InputForm
                    classes={{
                      input: classes.inputform,
                      root: classes.inputFormRoot,
                      inputError: classes.inputformErrorStyle,
                    }}
                    error={formValidFields.nome}
                    value={guiaInternacao.nome}
                    onChange={(e) =>
                      guiaInternacaoStore.onChangeGuiaInternacao(
                        e.target.value,
                        "nome"
                      )
                    }
                  />
                </div>
              )}
              <div className={classes.inputArea}>
                <label>Indícação Clínica</label>
                <TextFieldSearch
                  name="indicacaoClinica"
                  placeholder="Selecione..."
                  error={formValidFields.indicacaoClinica}
                  value={guiaInternacao.indicacaoClinica}
                  onChange={(e) =>
                    guiaInternacaoStore.onChangeGuiaInternacao(
                      e,
                      "indicacaoClinica"
                    )
                  }
                  getOptionLabel={(option) => option.descricaoAbreviada}
                  getOptionValue={(option) => option.id}
                  loadOptions={guiaInternacaoStore.loadIndicacaoClinicaOptions}
                  debounceTimeout={300}
                  withPaginate
                  additional={{
                    page: 0,
                  }}
                  classInput={classNames(
                    indicacaoClinicaInputFullSize
                      ? classes.selectRootFullSize
                      : classes.selectRoot,
                    indicacaoClinicaError && classes.inputError,
                    classes.dropDownIndicator
                  )}
                  classNotched={classes.notchedOutline}
                  isDisabled={disableForm}
                />
              </div>
              <div className={classes.inputArea}>
                <label>Caráter de Atendimento</label>
                <TextFieldSearch
                  name="caraterAtendimento"
                  placeholder="Selecione..."
                  error={formValidFields.caraterAtendimento}
                  value={guiaInternacao.caraterAtendimento}
                  onChange={(e) =>
                    guiaInternacaoStore.onChangeGuiaInternacao(
                      e,
                      "caraterAtendimento"
                    )
                  }
                  getOptionLabel={(option) => option.termo}
                  getOptionValue={(option) => option.id}
                  loadOptions={
                    guiaInternacaoStore.loadCaraterAtendimentoOptions
                  }
                  debounceTimeout={300}
                  withPaginate
                  additional={{
                    page: 0,
                  }}
                  classInput={classNames(
                    !readOnly ? classes.selectRoot : classes.selectRootReadOnly,
                    classes.dropDownIndicator,
                    caraterAtendimentoError && classes.inputError
                  )}
                  classNotched={classes.notchedOutline}
                  isDisabled={disableForm}
                />
              </div>
              <div className={classes.inputArea}>
                <label>Tipo de Internação</label>
                <TextFieldSearch
                  name="tipoInternacao"
                  placeholder="Selecione..."
                  error={formValidFields.tipoInternacao}
                  value={guiaInternacao.tipoInternacao}
                  onChange={(e) =>
                    guiaInternacaoStore.onChangeGuiaInternacao(
                      e,
                      "tipoInternacao"
                    )
                  }
                  getOptionLabel={(option) => option.termo}
                  getOptionValue={(option) => option.id}
                  loadOptions={guiaInternacaoStore.loadTipoInternacaoOptions}
                  debounceTimeout={300}
                  withPaginate
                  additional={{
                    page: 0,
                  }}
                  classInput={classNames(
                    !readOnly ? classes.selectRoot : classes.selectRootReadOnly,
                    classes.dropDownIndicator,
                    tipoInternacaoError && classes.inputError
                  )}
                  classNotched={classes.notchedOutline}
                  isDisabled={disableForm}
                />
              </div>
              <div className={classes.inputArea}>
                <label>Regime de Internação</label>
                <TextFieldSearch
                  name="regimeInternacao"
                  placeholder="Selecione..."
                  error={formValidFields.regimeInternacao}
                  value={guiaInternacao.regimeInternacao}
                  onChange={(e) =>
                    guiaInternacaoStore.onChangeGuiaInternacao(
                      e,
                      "regimeInternacao"
                    )
                  }
                  getOptionLabel={(option) => option.termo}
                  getOptionValue={(option) => option.id}
                  loadOptions={guiaInternacaoStore.loadRegimeInternacaoOptions}
                  debounceTimeout={300}
                  withPaginate
                  additional={{
                    page: 0,
                  }}
                  classInput={classNames(
                    !readOnly ? classes.selectRoot : classes.selectRootReadOnly,
                    classes.dropDownIndicator,
                    regimeInternacaoError && classes.inputError
                  )}
                  classNotched={classes.notchedOutline}
                  isDisabled={disableForm}
                />
              </div>
              <div className={classes.inputArea}>
                <label>Indíção de Acidente</label>
                <TextFieldSearch
                  name="indicacaoAcidente"
                  placeholder="Selecione..."
                  value={guiaInternacao.indicacaoAcidente}
                  onChange={(e) =>
                    guiaInternacaoStore.onChangeGuiaInternacao(
                      e,
                      "indicacaoAcidente"
                    )
                  }
                  getOptionLabel={(option) => option.termo}
                  getOptionValue={(option) => option.id}
                  loadOptions={guiaInternacaoStore.loadIndicacaoAcidenteOptions}
                  debounceTimeout={300}
                  withPaginate
                  additional={{
                    page: 0,
                  }}
                  classInput={classNames(
                    !readOnly ? classes.selectRoot : classes.selectRootReadOnly,
                    classes.dropDownIndicator,
                    indicacaoAcidenteError && classes.inputError
                  )}
                  classNotched={classes.notchedOutline}
                  isDisabled={disableForm}
                />
              </div>
              <div className={classes.formSwitches}>
                <label>Previsão de uso</label>
                <div className={classes.switchesArea}>
                  <div>
                    <span>De OPME</span>
                    <Switch
                      color="primary"
                      checked={guiaInternacao.opme}
                      onChange={() =>
                        guiaInternacaoStore.onChangeGuiaInternacao(
                          !guiaInternacao.opme,
                          "opme"
                        )
                      }
                      classes={{ disabled: classes.switchDisabled }}
                      disabled={disableForm}
                    />
                  </div>
                  <div>
                    <span>De Quimioterápico</span>
                    <Switch
                      color="primary"
                      checked={guiaInternacao.quimioterapico}
                      onChange={() =>
                        guiaInternacaoStore.onChangeGuiaInternacao(
                          !guiaInternacao.quimioterapico,
                          "quimioterapico"
                        )
                      }
                      disabled={disableForm}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.inputArea}>
                <label>Observação e Justificativas</label>
                <InputForm
                  classes={{
                    input: classes.inputform,
                    root: classNames(
                      !readOnly
                        ? classes.observacaoInputRoot
                        : classes.observacaoInputReadOnly
                    ),
                  }}
                  value={guiaInternacao.observacao}
                  onChange={(e) =>
                    guiaInternacaoStore.onChangeGuiaInternacao(
                      e.target.value,
                      "observacao"
                    )
                  }
                  disabled={disableForm}
                />
              </div>
              {!readOnly && (
                <div className={classes.tussArea}>
                  <div className={classes.inputArea}>
                    <label>Tabela</label>
                    <TextFieldSearch
                      placeholder=""
                      loadOptions={guiaInternacaoStore.loadTussTablesOptions}
                      classNotched={classes.notchedOutline}
                      classInput={classNames(
                        classes.tabelaSelect,
                        classes.dropDownIndicator
                      )}
                      classIcons={classes.tabelaIcon}
                      value={itemTabelaTuss?.tabela}
                      getOptionLabel={(option) => option?.label}
                      getOptionValue={(option) => option?.value}
                      additional={{
                        page: 0,
                      }}
                      withPaginate
                      onChange={(e) =>
                        guiaInternacaoStore.onChangeItemTabelaTuss(e, "tabela")
                      }
                    />
                  </div>
                  <div className={classes.inputArea}>
                    <label>Código de descrição tuss</label>
                    <TextFieldSearch
                      key={itemTabelaTuss?.tabela}
                      placeholder="Selecione..."
                      loadOptions={guiaInternacaoStore.loadTussViewOptions}
                      classNotched={classes.notchedOutline}
                      classInput={classNames(
                        classes.codigoTussSelect,
                        classes.dropDownIndicator
                      )}
                      value={itemTabelaTuss?.codigo}
                      debounceTimeout={300}
                      getOptionLabel={(option) =>
                        `${option.codigoTermo} - ${option.descricao}`
                      }
                      getOptionValue={(option) => option.codigoTermo}
                      additional={{
                        page: 0,
                      }}
                      withPaginate
                      onChange={(e) =>
                        guiaInternacaoStore.onChangeItemTabelaTuss(e, "codigo")
                      }
                    />
                  </div>
                  <div className={classes.inputArea}>
                    <label>Descrição</label>
                    <InputForm
                      classes={{
                        root: classes.descricaoInput,
                        input: classes.inputform,
                      }}
                      value={itemTabelaTuss?.descricao || ""}
                      onChange={(e) =>
                        guiaInternacaoStore.onChangeItemTabelaTuss(
                          e.target.value,
                          "descricao"
                        )
                      }
                    />
                  </div>
                  <div className={classes.inputArea}>
                    <label>Qtd.</label>
                    <InputForm
                      type="number"
                      inputProps={{ min: 1 }}
                      classes={{
                        root: classes.qtdSelect,
                        input: classes.inputform,
                      }}
                      value={itemTabelaTuss?.quantidade || 1}
                      onChange={(e) =>
                        guiaInternacaoStore.onChangeItemTabelaTuss(
                          e.target.value,
                          "quantidade"
                        )
                      }
                    />
                  </div>
                  <Button
                    bgColor="#219A97"
                    shape="circle"
                    color="white"
                    style={addButtonStyles}
                    onClick={() => guiaInternacaoStore.addTabelaTussCurrItem()}
                    disabled={disableForm || disabledAddTussViewButton}
                  >
                    <AddIcon />
                  </Button>
                </div>
              )}
            </div>
            <Scroll classes={{ root: classes.tussTable }}>
              <Table
                columns={tussTableHeaderColumns({
                  handleRemoveTuss,
                  guiaInternacaoId:
                    selectedEntity === "guiaInternacao"
                      ? guiaInternacao.id
                      : null,
                })}
                classes={{
                  tableCell: classes.tussTableCell,
                }}
                dados={guiaInternacao[tabelaTussField]}
              />
            </Scroll>
            {renderModalButtons()}
          </div>
          {!readOnly && (
            <div className={classes.suggestionsArea}>
              <div
                className={classNames(
                  classes.suggestionsHeader,
                  selectedEntity !== "guiaInternacaoModelo" &&
                    classes.onlyCloseButton
                )}
              >
                {selectedEntity === "guiaInternacaoModelo" && (
                  <span>Sugestões</span>
                )}
                <CloseIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                  size={24}
                />
              </div>
              <GuiaInternacaoSuggestions
                selectedEntity={selectedEntity}
                onSelect={handleSelectSuggestion}
                isDisabled={disableForm}
                changeToModeloCreation={() =>
                  (guiaInternacaoStore.selectedEntity = "guiaInternacaoModelo")
                }
              />
            </div>
          )}
        </div>
        {isPrintingComplete &&
          guiaInternacaoToPrint &&
          renderImpressaoCompleta()}
        {notification.isOpen && (
          <Notification
            isOpen={notification.isOpen}
            message={notification.message}
            variant={notification.variant}
            close={guiaInternacaoStore.resetNotification}
          />
        )}
      </Dialog>
    );
  }
);

const ModalGuiaInternacaoStyles = withStyles(styles)(ModalGuiaInternacao);
const stores = ["guiaInternacaoStore"];
export default inject(...stores)(ModalGuiaInternacaoStyles);
