import { inject } from "mobx-react";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { FormControlLabel, Radio, withStyles } from "@material-ui/core";
import TipoDente from "./Tabs/TipoDente";
import Hiperdontia from "./Tabs/Hiperdontia";
import styles from "./ModalConfiguracaoStyle";
import HeaderModal from "../../Components/HeaderModal";
import Dialog from "../../../../../../components/Dialog/Dialog";
import MultiToggleButtons from "../../../../../../components/Button/MultiToggleButtons";
import { Button } from "../../../../../../components/ui/Buttons";
import { modalConfiguracaoDefault } from "../../../../../../stores/Odontograma.store";

const tiposDente = [
  {
    name: "Permanentes",
    label: "Permanentes",
    option: 0,
    value: true,
  },
  {
    name: "Deciduo",
    label: "Decíduos",
    option: 1,
    value: false,
  },
];

const ModalConfiguracao = observer(
  ({ classes, odontogramaStore, prontuarioStore }) => {
    const { modalConfiguracao } = odontogramaStore;
    const {
      showModalConfiguracao,
      tipoDenteSelected,
      tabSelected,
      dentesAdicionados,
      dentesTipoSelecionado,
    } = modalConfiguracao;
    const { label } = tipoDenteSelected;

    const [loading, setLoading] = useState(false);
    const [tabTipoSelected, setTabTipoSelected] = useState(tabSelected);
    const [dentesStatus, setDentesStatus] = useState({...dentesTipoSelecionado});

    const changeCloseModalConfiguracao = () => {
      odontogramaStore.modalConfiguracao = modalConfiguracaoDefault;
    };

    const changeTipoDente = (value) => {
      modalConfiguracao.tipoDenteSelected = value;
      setTabTipoSelected(value.option)
    };

    const changeTabSelected = (tabSelected) => {
      modalConfiguracao.tabSelected = tabSelected;
    };

    const renderTab = () => {
      return tabSelected === 0 ? <TipoDente dentesStatus={dentesStatus} setDentesStatus={setDentesStatus} /> : <Hiperdontia />;
    };

    const handleClickSave = async () => {
      try {
        setLoading(true);
        const sujeitoAtencaoId = prontuarioStore.sujeitoAtencaoSelected?.id;

        const dentes= dentesStatus[tipoDenteSelected.name];
        const dentesAlterados = dentes.filter(item => item.alterado);

        if (dentesAdicionados.length > 0) {
          const dente = dentesAdicionados.map((item) => {
            return {
              codigo: item.codigo,
              local: item.posicao.id,
            };
          });

          await odontogramaStore.createDente({
            sujeitoAtencaoId,
            dente,
          });
          odontogramaStore.getOdontograma(sujeitoAtencaoId);
          odontogramaStore.openNotification(
            "Dente salvo com sucesso.",
            "success"
          );
        }

        if(dentesAlterados.length > 0) {
          const dentes = dentesAlterados.map(item => {
            return {
              id: item.id,
              status: item.status,
            }
          });

          await odontogramaStore.alterarStatusDente(dentes);
        }

        odontogramaStore.modalConfiguracao.dentesTipoSelecionado=dentesStatus;
        odontogramaStore.changeTabVisualizacaoSelected(tabTipoSelected);
        odontogramaStore.changeCloseModalConfiguracao();
        odontogramaStore.getOdontograma(
          prontuarioStore.sujeitoAtencaoSelected.id
        );
      } catch {
        odontogramaStore.openNotification("Erro ao salvar o dente.", "error");
      } finally {
        setLoading(false);
      }
    };

    return (
      <Dialog
        open={showModalConfiguracao}
        onClose={changeCloseModalConfiguracao}
        maxWidth="md"
        fullWidth
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.content}>
          <HeaderModal
            title="Configuração"
            onClose={changeCloseModalConfiguracao}
          />
          <div className={classes.infoHeader}>
            Habilite, desabilite ou adicione novos dentes conforme a sua
            necessidade do seu paciente.
          </div>
          <div className={classes.contentTipoDente}>
            <div className={classes.titleTipo}> Selecione o tipo </div>
            {tiposDente.map((item) => (
              <FormControlLabel
                key={item.name}
                value={item.value}
                className={classes.tipoDentes}
                control={<Radio color="primary" />}
                label={item.label}
                checked={tipoDenteSelected.value === item.value}
                onChange={() => changeTipoDente(item)}
              />
            ))}
          </div>
          <MultiToggleButtons
            options={[label, "Hiperdontia"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
            classes={{ buttonsContainer: classes.multiToggleButtons }}
          />
          {renderTab()}
        </div>
        <div className={classes.contentButtons}>
          <Button
            kind='transparent'
            onClick={changeCloseModalConfiguracao}
            disabled={loading}
            fullWidth
          >
            Cancelar
          </Button>
          <Button
            bgColor='#219A97'
            onClick={handleClickSave}
            disabled={loading}
            fullWidth
          >
            Salvar
          </Button>
        </div>
      </Dialog>
    );
  }
);

const ModalConfiguracaoWithStyles = withStyles(styles)(ModalConfiguracao);
export default inject(
  "odontogramaStore",
  "prontuarioStore"
)(ModalConfiguracaoWithStyles);
