import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import styles from "../styles";
import { InputForm } from "../../../components/Modal/Input";
import { TextFieldSearch } from "../../../components/TextField";
import InputMaskCurrency from "../../../components/Input/InputMaskCurrency";

const InformacoesContaBancaria = observer((props) => {
  const { classes, handleChange, contaBancariaStore, isDisabled } = props;
  const { objView } = contaBancariaStore;

  const handleBancoLoadOptions = async (search, loadedOptions, { page }) => {
    const response = await contaBancariaStore.findAllBanco({
      pageNumber: page,
      search,
    });

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div className={classes.contentTabInformacoes}>
      <div className={classes.contentField}>
        <div className={classes.titleField}>Nome</div>
        <InputForm
          value={objView.nome || ""}
          onChange={(e) => handleChange("nome", e)}
          className={classes.widthMaximo}
          classes={{
            input: classes.inputRetangular,
          }}
        />
      </div>
      <div className={classes.rowBanco}>
        <div className={classes.contentField}>
          <div className={classes.titleField}>Banco</div>
          <TextFieldSearch
            placeholder=""
            loadOptions={handleBancoLoadOptions}
            withPaginate
            value={objView.banco}
            onChange={(e) => handleChange("banco", e)}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            classNotched={classes.notchedOutlineSearch}
            classInput={classes.inputSearch}
            maxMenuHeight={200}
          />
        </div>
        <div className={classes.contentField}>
          <div className={classes.titleField}>Saldo inicial</div>
          <InputMaskCurrency
            disabled={isDisabled}
            value={objView.saldoInicial || ""}
            onChange={(e) => handleChange("saldoInicial", e)}
            className={classes.inputValor}
          />
        </div>
      </div>
    </div>
  );
});

const InformacoesContaBancariaWithStyles = withStyles(styles)(
  InformacoesContaBancaria
);
export default inject("contaBancariaStore")(InformacoesContaBancariaWithStyles);
