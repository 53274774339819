import React, { useEffect } from "react";
import { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import { withStyles } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import WeekCalendarHeader from "./WeekCalendarHeader";
import InfosAgendamentoModal from '../../Modal/InfosAgendamentoModal';
import ButtonFloat from "../../../components/ButtonFloat";
import { getStatusAtendimento } from '../../../utils';
import styles from "./WeekCalendar.style";
import 'react-big-calendar/lib/sass/styles.scss';
import AgendamentosAgrupadosPopper from './AgendamentosAgrupadosPopper';
import string from "../../../utils/string";
import EventCard from "./EventCard";

moment.locale('pt-BR');
const localizer = momentLocalizer(moment);

const eventStyle = (event) => {
  const bgColor = getStatusAtendimento(event.status);

  const style = {
    backgroundColor: bgColor.backgroundOpacity,
    borderRadius: "5px",
    color: "#24242C",
    display: "block",
    minHeight: "84px",
  };
  return {
    style: style
  };
};

const WeekCalendar = props => {
  const {
    weekViewSala,
    agendamentos,
    onSelectNovoAgendamento,
    onSelectAgendamento,
    onRemoveAgendamento,
    configVigente,
    selectedDate,
    tipoProfissionalLogado,
    classes,
    history,
  } = props;
  const [openInfosAgendamento, setOpenInfosAgendamento] = useState(false);
  const [infosSemana, setInfosSemana] = useState([]);
  const [agendamentosSemana, setAgendamentosSemana] = useState([]);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState('');
  const [openListaAgendamentos, setOpenListaAgendamentos] = useState(false);
  const [agendamentosAgrupado, setAgendamentosAgrupado] = useState('')
  const [anchorElAgendamentosAgrupado, setAnchorElAgendamentosAgrupado] = useState(null);
  const [posicao, setPosicao] = useState({screenX: 0, screenY: 0});

  const handleTooltipOpen = (event) => {
    setAgendamentoSelecionado(event);
    setOpenInfosAgendamento(true);
  };

  useEffect(() => {
    let infosSemana = {
      Seg: {
        quantidadeAgendamentos: 0,
        horariosDisponiveis: configVigente['segunda'].length
      },
      Ter: {
        quantidadeAgendamentos: 0,
        horariosDisponiveis: configVigente['terca'].length
      },
      Qua: {
        quantidadeAgendamentos: 0,
        horariosDisponiveis: configVigente['quarta'].length
      },
      Qui: {
        quantidadeAgendamentos: 0,
        horariosDisponiveis: configVigente['quinta'].length
      },
      Sex: {
        quantidadeAgendamentos: 0,
        horariosDisponiveis: configVigente['sexta'].length
      },
      Sáb: {
        quantidadeAgendamentos: 0,
        horariosDisponiveis: configVigente['sabado'].length
      },
      Dom: {
        quantidadeAgendamentos: 0,
        horariosDisponiveis: configVigente['domingo'].length
      },
    }

    let horariosSemana = [];

    agendamentos.forEach(agendamento => {
      const diaDaSemana = string.capitalize(moment(agendamento.start).format("ddd"));
      horariosSemana = [
        ...horariosSemana,
        {
          title: agendamento.sujeitoAtencaoNome || agendamento.nome || 'Bloqueado',
          id: agendamento.id,
          start: new Date(agendamento.start),
          end: new Date(agendamento.end),
          status: agendamento.status,
          convenio: agendamento.convenio,
          telefone: agendamento.sujeitoAtencaoTelefone || agendamento.telefone,
          telefoneDDI: agendamento.sujeitoAtencaoTelefoneDDI || agendamento.telefoneDDI,
          remoto: agendamento.remoto,
          tipoConsulta: agendamento.tipoConsulta,
          origemHealth: agendamento.origemHealth,
          sujeitoAtencaoId: agendamento?.sujeitoAtencaoId || '',
          procedimentos: agendamento?.procedimentos,
          observacao: agendamento?.observacao,
          sala: {
            nome: agendamento?.salaNome,
          } ,
          profissionalSaude: {
            nome: agendamento?.profissionalSaudeNome
          },
          nomeFantasiaUnidade: agendamento?.nomeFantasiaUnidade || '',
          chavePublica: agendamento?.chavePublica,
          mensagemWhatsappEnviada: agendamento?.mensagemWhatsappEnviada,
          endereco: agendamento?.endereco,
          data: moment(agendamento?.data, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        }
      ]

      if (agendamento.status === "BLOQUEADO") {
        infosSemana[diaDaSemana].horariosDisponiveis -= 1;
      } else {
        infosSemana[diaDaSemana].quantidadeAgendamentos += 1;
        const duracao = moment.duration(agendamento.end.diff(agendamento.start)).asMinutes();
        const horariosOcupados = (duracao / configVigente.duracao).toFixed();
        infosSemana[diaDaSemana].horariosDisponiveis -= horariosOcupados;
      }
    })

    setInfosSemana(infosSemana);

    verificaQuantidadeAgendamentosMesmoHorario(horariosSemana)
  }, [agendamentos]);

  const verificaQuantidadeAgendamentosMesmoHorario = (horariosSemana) => {
    const listasSemHorarioRepetidoEMesmoHorario = horariosSemana.reduce((acumulador, agendamento) => {
      if(!acumulador.agendamentosSemHorarioIgual){
        acumulador.agendamentosSemHorarioIgual = []
      };

      if(!acumulador.agendamentosMesmoHorario){
        acumulador.agendamentosMesmoHorario = []
      };

      const agendamentoHorarioInicio = moment(agendamento.start).format("YYYY-MM-DD HH:mm");
      const agendamentoHorarioFim = moment(agendamento.end).format("YYYY-MM-DD HH:mm"); 

      const listaMesmoHorario = horariosSemana.filter((item) => {
        const itemHorarioInicio = moment(item.start).format("YYYY-MM-DD HH:mm");
        const itemHorarioFim = moment(item.end).format("YYYY-MM-DD HH:mm");

        return itemHorarioInicio === agendamentoHorarioInicio && itemHorarioFim === agendamentoHorarioFim;
      });

      const verificaListaJaInserida = acumulador.agendamentosMesmoHorario.find(item => {
        const inicio = moment(listaMesmoHorario[0].start).format("YYYY-MM-DD HH:mm") === moment(item[0].start).format("YYYY-MM-DD HH:mm")
        const fim = moment(listaMesmoHorario[0].end).format("YYYY-MM-DD HH:mm") === moment(item[0].end).format("YYYY-MM-DD HH:mm")

        return inicio && fim;
      })

      if(!verificaListaJaInserida){
        listaMesmoHorario.length > 2 ? 
          acumulador.agendamentosMesmoHorario.push(listaMesmoHorario) : 
          acumulador.agendamentosSemHorarioIgual.push(agendamento);
      }

      return acumulador;

    }, {});

    criarNovaListaSemHorarioIgual(listasSemHorarioRepetidoEMesmoHorario)
  };

  const criarNovaListaSemHorarioIgual = ({agendamentosSemHorarioIgual, agendamentosMesmoHorario}) => {
    let agendamentosHorarioIguais = [];
    let agendamentosAgrupados = agendamentosSemHorarioIgual;
    
    agendamentosMesmoHorario && agendamentosMesmoHorario.forEach((agendamento, index) => {
      const listaStatusIguais = agendamento.filter(item => item.status === agendamento[0].status);

      agendamentosHorarioIguais = [
        ...agendamentosHorarioIguais,
        {
          title: `${agendamento.length} agendamentos`,
          start: agendamento[0]?.start,
          end: agendamento[0]?.end,
          status: listaStatusIguais.length === agendamento.length ? agendamento[0]?.status : "MULTI",
          agendamentos: agendamentosMesmoHorario[index]
        }
      ]
    })

    agendamentosHorarioIguais.forEach(agendamento => {
      agendamentosAgrupados=[
        ...agendamentosAgrupados,
        agendamento
      ]
    })

    setAgendamentosSemana(agendamentosAgrupados || []);
  };

  const handleSelectEvent = (event) => {
    const { onSelectAgendamento } = props;

    if(event?.agendamentos && event?.agendamentos?.length > 0) {
      setAgendamentosAgrupado(event);
      setAnchorElAgendamentosAgrupado(event.currentTarget);
      setOpenListaAgendamentos(!openListaAgendamentos);

    } else {
      setOpenListaAgendamentos(false);
      if (event.status === 'BLOQUEADO') {
        onSelectAgendamento(event);
      } else {
        handleTooltipOpen(event);
      }
    }
  };

  const handleSelectAgendamento = (agendamento) => {
    setOpenListaAgendamentos(false);
    handleTooltipOpen(agendamento);
  };

  const onEditAgendamento = (prontuario) => {
    onSelectAgendamento(prontuario)
    setOpenListaAgendamentos(false);
    setTimeout(() => {setOpenInfosAgendamento(false)}, 500)
  }

  const handleClosePopper = () => {
    setOpenListaAgendamentos(false);
  }

  const getDiv = (e) => {
    const {pageX, pageY} = e;

    const getPosicao = {pageX, pageY}
    setPosicao(getPosicao)
  }

  const handleOpenSujeitoEdit = (event) => {
    const {sujeitoAtencaoStore} = props;
    const {sujeitoAtencaoId} = event;
    sujeitoAtencaoStore.reset();
    sujeitoAtencaoStore.changeId(sujeitoAtencaoId);
    history.push(`/sujeito-atencao/edit/${sujeitoAtencaoId}`);
  }

  const onSelectNewAgendamento = (event) => {
    setOpenListaAgendamentos(false);
    onSelectNovoAgendamento(event)
  }

  return (
    <div className={classes.calendarContainer}>
      <Calendar
        selectable
        onSelecting={() => false}
        localizer={localizer}
        tooltipAccessor={false}
        defaultDate={moment(selectedDate)}
        timeslots={1}
        step={configVigente.duracao}
        min={moment(configVigente.horaInicioPeriodoAtendimento, "HH:mm").toDate()}
        max={moment(configVigente.horaFimPeriodoAtendimento, "HH:mm").subtract(configVigente.duracao, "minutes").toDate()}
        defaultView="week"
        events={agendamentosSemana}
        eventPropGetter={eventStyle}
        onSelectEvent={(event) => handleSelectEvent(event)}
        onSelectSlot={(event) => onSelectNewAgendamento(event)}
        components={{
          toolbar: () => <WeekCalendarHeader selectedDate={selectedDate} informacoesAgendamento={infosSemana} />,
          event: (event) => <EventCard event={event} />
        }}
        elementProps={{ onClick: e => getDiv(e) }}
      />

      {openInfosAgendamento &&
        <InfosAgendamentoModal
          weekViewSala={weekViewSala}
          open={openInfosAgendamento}
          onClose={() => setOpenInfosAgendamento(false)}
          event={agendamentoSelecionado}
          onRemoveAgendamento={onRemoveAgendamento}
          handleEditAgendamento={onEditAgendamento}
          handleOpenSujeitoEdit={handleOpenSujeitoEdit}
          fieldProcedimento={agendamentoSelecionado.procedimentos}
          calendarioSemanal
        />
      }

      {openListaAgendamentos && <AgendamentosAgrupadosPopper
        posicao={posicao}
        dados={agendamentosAgrupado}
        open={openListaAgendamentos}
        anchorEl={anchorElAgendamentosAgrupado}
        handleSelectAgendamento={handleSelectAgendamento}
        handleClosePopper={handleClosePopper}
      />}

      {tipoProfissionalLogado !== 'tecnico' &&
        <ButtonFloat
          onClick={() => onSelectNovoAgendamento()}
          icon={AddIcon}>
        </ButtonFloat>
      }
    </div>
  );

};

export default withStyles(styles)(WeekCalendar);