import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const CircleSuccess = props => {
  return (
    <svg 
      width="51" 
      height="50" 
      viewBox="0 0 51 50" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <circle cx="25.5" cy="25" r="25" fill="currentColor"/>
      <rect x="13.7352" y="26.1699" width="2.94118" height="12.5" rx="1.47059" transform="rotate(-42.3081 13.7352 26.1699)" fill="white"/>
      <rect x="13.7352" y="26.1699" width="2.94118" height="12.5" rx="1.47059" transform="rotate(-42.3081 13.7352 26.1699)" fill="white"/>
      <rect width="2.94118" height="25.3258" rx="1.47059" transform="matrix(-0.739536 -0.673117 -0.673117 0.739536 39.5752 16.6855)" fill="white"/>
      <rect width="2.94118" height="25.3258" rx="1.47059" transform="matrix(-0.739536 -0.673117 -0.673117 0.739536 39.5752 16.6855)" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear_8124_133173" x1="0.5" y1="0" x2="58.6319" y2="12.6598" gradientUnits="userSpaceOnUse">
      <stop stop-color="#36ABA8"/>
      <stop offset="1" stop-color="#309996"/>
      </linearGradient>
      </defs>
    </svg>
  );
};

export default CircleSuccess;