import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  withStyles,
} from "@material-ui/core";
import { Search, Clear as ClearIcon } from "@material-ui/icons";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

import { Button } from "../../../components/ui/Buttons";
import InputSearch from "../../../components/Input/Input";

import ListIcon from "../../../components/Icon/ListIcon";
import CloseIcon from "../../../components/Icon/CloseIcon";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";

import { getColumns } from "./utils/getColumns";
import {
  findAllAgendamentoAuditListaEspera,
  findAllAgendamentosListaDeEspera,
  findAllEspecialidadeMedica,
  findAllProfissionalSaudeComAgenda,
} from "../../../services/AgendamentoService";
import { goToWhatsApp } from "../../../utils/goToWhatsApp";
import { openModalAgendamento } from "./utils/openModalAgendamento";
import { TextFieldSearch } from "../../../components/TextField";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";

const Auditoria = lazy(() => import("../../../components/Auditoria/Auditoria"));

const tabs = {
  INFORMACAO: 0,
  AUDITORIA: 1,
};

const ModalListaEspera = observer(
  ({
    classes,
    onClose,
    open,
    profissionalSaudeSelected,
    atendimentoStore,
    filtroHeaderStore,
    loadListaEspera,
    auditoriaStore,
  }) => {
    const filtersDefault = {
      especialidadeMedica: null,
      profissionalSaude: profissionalSaudeSelected,
    };

    const [listaEspera, setListaEspera] = useState([]);
    const [valueSearch, setValueSearch] = useState("");
    const [filters, setFilters] = useState(filtersDefault);
    const [pageNumber, setPageNumber] = useState(0);
    const [hasMore, setHasMore] = useState(null);
    const [tabSelected, setTabSelected] = useState(tabs.INFORMACAO);

    const { especialidadeMedica, profissionalSaude } = filters;

    const loadAllAgendamentosListaEspera = async (options) => {
      try {
        const { content, last } = await findAllAgendamentosListaDeEspera({
          search: options?.clearSearch ? "" : valueSearch,
          ...(especialidadeMedica?.id && { especialidadeMedica }),
          ...(profissionalSaude?.id && {
            profissionalSaudeId: profissionalSaude?.id,
          }),
          pageableDTO: {
            pageSize: 10,
            pageNumber: options?.isClearable ? 0 : pageNumber,
          },
        });
        setHasMore(!last);
        setPageNumber((prevState) => {
          if (last) return prevState;
          return prevState + 1;
        });
        setListaEspera((prevState) => {
          if (options?.isClearable || options?.clearSearch) return [...content];
          return [...prevState, ...content];
        });
      } catch (error) {
        console.error(error);
      }
    };

    const onChangeSearch = (e) => {
      setValueSearch(e.target.value);
    };

    const onKeypressSearch = (e) => {
      if (e.key === "Enter" && valueSearch.length >= 3) {
        loadAllAgendamentosListaEspera({ isClearable: true });
      } else if (e.key === "Enter" && valueSearch === "") {
        handleClearSearch();
      }
    };

    const handleSearch = () => {
      if (valueSearch.length >= 3) {
        loadAllAgendamentosListaEspera({ isClearable: true });
      } else if (valueSearch === "") {
        handleClearSearch();
      }
    };

    const handleClearSearch = async () => {
      setValueSearch("");
      setPageNumber(0);
      setHasMore(null);
      await loadAllAgendamentosListaEspera({
        isClearable: true,
        clearSearch: true,
      });
    };

    const handleClickWhatsapp = async (e, option) => {
      e.stopPropagation();
      const { id } = option;
      const {
        telefonePrincipal,
        telefonePrincipalDDI,
      } = option.sujeitoAtencao.contato;
      atendimentoStore.increaseWhatsappMessageCounter(id);
      return goToWhatsApp(telefonePrincipal, telefonePrincipalDDI, "");
    };

    const loadEspecialidades = async (search, loadedOptions, { page }) => {
      const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);

      const response = await findAllEspecialidadeMedica({
        searchDTO: {
          search,
          ativo: true,
          unidadeId: unidadeLogada?.id,
        },
        pageableDTO: {
          sortDir: "ASC",
          sortField: "especialidade",
          pageNumber: page,
          pageSize: 20,
        },
      });

      const { content, last } = response;

      if (content) {
        return {
          options: content,
          hasMore: !last,
          additional: {
            page: page + 1,
          },
        };
      }
    };

    const loadProfissionalSaude = async (search, loadedOptions, { page }) => {
      const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);

      const response = await findAllProfissionalSaudeComAgenda({
        search,
        ativo: true,
        unidadeId: unidadeLogada?.id,
        pageableDTO: {
          sortDir: "ASC",
          sortField: "nome",
          pageNumber: page,
          pageSize: 20,
        },
      });

      const { content, last } = response;

      if (content) {
        return {
          options: content,
          hasMore: !last,
          additional: {
            page: page + 1,
          },
        };
      }
    };

    const handleFilters = (field, value) => {
      setFilters({
        ...filters,
        [field]: value,
      });
    };

    const handleClickCriar = (e) => {
      e.stopPropagation();
      atendimentoStore.openModalListaEsperaItem = true;
    };

    const changeTabSelected = async (tabSelected) => {
      if (tabSelected === tabs.AUDITORIA) {
        auditoriaStore.load = {
          query: findAllAgendamentoAuditListaEspera,
          variableName: "",
          variableId: "",
        };
        await auditoriaStore.loadAuditItems();
      }

      setTabSelected(tabSelected);
    };

    useEffect(() => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoriaTela: "agendamento",
        auditoria: `o paciente `,
        withWhomModified: true,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
      };
      loadListaEspera(loadAllAgendamentosListaEspera);
    }, []);

    useEffect(() => {
      loadAllAgendamentosListaEspera();
    }, [profissionalSaudeSelected]);

    return (
      <Dialog
        open={open}
        maxWidth={"md"}
        fullWidth
        onClose={onClose}
        classes={{
          paper: classes.fullSizePaper,
        }}
      >
        <>
          <div className={classes.wrapperTitle}>
            <div>
              <ListIcon size="28" color="#505050" />
              <h1>Lista de espera: {listaEspera.length} pacientes</h1>
            </div>
            <Button kind="transparent" shape="circle" onClick={onClose}>
              <CloseIcon size="24" />
            </Button>
          </div>
          <MultiToggleButtons
            options={["Informações", "Auditoria"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
          />
          <Suspense
            fallback={
              <div className={classes.wrapperLoading}>
                <CircularProgress />
              </div>
            }
          >
            {tabSelected === tabs.INFORMACAO ? (
              <>
                <div className={classes.header}>
                  <div className={classes.wrapperSearch}>
                    <div className={classes.filters}>
                      <div className={classes.searchInput}>
                        <span>Pesquisar</span>
                        <InputSearch
                          startAdornment={
                            <InputAdornment position="start">
                              <IconButton
                                className={classes.iconButtonSearch}
                                onClick={handleSearch}
                              >
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment
                              position="end"
                              disablePointerEvents={valueSearch === ""}
                            >
                              <IconButton
                                className={classes.iconButtonSearch}
                                onClick={handleClearSearch}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          classInputRoot={classes.inputRootSearch}
                          classInput={classes.inputSearch}
                          className={classes.search}
                          name={"search"}
                          placeholder={"Pesquisa"}
                          value={valueSearch}
                          onChange={onChangeSearch}
                          onKeyPress={onKeypressSearch}
                        />
                      </div>
                      <div className={classes.searchInput}>
                        <span>Profissional saúde</span>
                        <TextFieldSearch
                          classNotched={classes.notchedOutline}
                          classInput={classes.inputTextField}
                          placeholder={"Selecione"}
                          onChange={(e) =>
                            handleFilters("profissionalSaude", e)
                          }
                          value={profissionalSaude}
                          menuPlacement={"auto"}
                          isClearable
                          getOptionLabel={(option) => option.nome}
                          getOptionValue={(option) => option.id}
                          withPaginate
                          loadOptions={loadProfissionalSaude}
                          debounceTimeout={300}
                          additional={{
                            page: 0,
                          }}
                        />
                      </div>
                      <div className={classes.searchInput}>
                        <span>Especialidade</span>
                        <TextFieldSearch
                          classNotched={classes.notchedOutline}
                          classInput={classes.inputTextField}
                          placeholder={"Selecione"}
                          onChange={(e) =>
                            handleFilters("especialidadeMedica", e)
                          }
                          value={especialidadeMedica}
                          menuPlacement={"auto"}
                          isClearable
                          getOptionLabel={(option) => option.especialidade}
                          getOptionValue={(option) => option.id}
                          withPaginate
                          loadOptions={loadEspecialidades}
                          debounceTimeout={300}
                          additional={{
                            page: 0,
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.wrapperButton}>
                      <Button
                        kind="secondary"
                        onClick={handleSearch}
                        disabled={
                          !(
                            valueSearch.length > 2 ||
                            especialidadeMedica?.id ||
                            profissionalSaude?.id
                          )
                        }
                      >
                        Pesquisar
                      </Button>
                      <Button onClick={handleClickCriar}>Incluir</Button>
                    </div>
                  </div>
                </div>
                <div className={classes.content}>
                  <Scroll
                    initialLoad={false}
                    loadMore={() => loadAllAgendamentosListaEspera()}
                    hasMore={hasMore}
                    pageStart={0}
                    classes={{
                      root: classes.tableRoot,
                    }}
                  >
                    <div>
                      <Table
                        columns={getColumns({
                          handleClickAgendar: (data) => {
                            openModalAgendamento(
                              data,
                              atendimentoStore,
                              filtroHeaderStore
                            );
                          },
                          redirectToWhatsapp: (e, data) =>
                            handleClickWhatsapp(e, data),
                        })}
                        dados={listaEspera}
                        emptyMessage={
                          <div className={classes.emptyMessage}>
                            Nenhum dado encontrado
                          </div>
                        }
                      />
                    </div>
                  </Scroll>
                </div>
              </>
            ) : (
              <Auditoria />
            )}
          </Suspense>
        </>
      </Dialog>
    );
  }
);

const styles = (theme) => ({
  fullSizePaper: {
    display: "flex",
    borderRadius: "8px",
    padding: "24px",
    gap: "16px",
    overflow: "hidden",
    maxHeight: "500px",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
  },
  wrapperSearch: {
    display: "flex",
    justifyContent: "space-between",
    gap: "16px",
  },
  searchInput: {
    flex: 1,
    "& > span": {
      fontSize: "0.875rem",
      color: "#505050",
    },
  },
  wrapperButton: {
    display: "flex",
    alignItems: "flex-end",
    gap: "8px",
  },
  wrapperTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > div": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },
    "& h1": {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      color: "#505050",
      fontWeight: "600",
      marginBottom: "16px",
    },
  },
  iconButtonSearch: {
    width: 30,
    height: 30,
  },
  inputRootSearch: {
    border: "none",
  },
  inputSearch: {
    fontFamily: "Poppins !important",
  },
  search: {
    width: "calc(100% - 16px)",
    height: 32,
    padding: "4px 8px",
    borderRadius: "8px !important",
    border: "none !important",
    background: "#F2F2F2",
    justifyContent: "center",
    fontWeight: 400,
  },
  tableRoot: {
    width: "100%",
    height: "100%",
    maxHeight: "310px",
    overflow: "auto",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",

    "& th": {
      padding: "4px 0 4px 24px",
    },
    "& td": {
      padding: "4px 0 4px 24px",
    },
    "&>div>div>table>thead>tr>th": {
      textAlign: "start",
      padding: "8px",
    },
    "&>div>div>table>tbody>tr>td": {
      borderRadius: "10px",

      "& > div": {
        maxWidth: "none",
        "& > div": {
          marginLeft: "0px",
          color: "#5F6368 !important",
        },
      },
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "16px",
    height: "100%",
    maxHeight: "500px",
  },
  emptyMessage: {
    textAlign: "center",
    fontSize: "1rem",
    color: "#505050",
    fontWeight: "600",
    marginTop: "16px",
    marginBottom: "16px",
  },
  notchedOutline: {
    border: "0",
  },
  inputTextField: {
    fontSize: "14px",
    border: "0",
    height: 40,
    color: "#505050",
    backgroundColor: "#F2F2F2",
    margin: "0 0 0 0",
    borderRadius: "8px",
    fontFamily: "Poppins",
  },
  filters: {
    display: "flex",
    gap: "8px",
    width: "100%",
  },
});

const stores = ["atendimentoStore", "filtroHeaderStore", "auditoriaStore"];
const ModalListaEsperaWithStyles = withStyles(styles)(ModalListaEspera);
export default inject(...stores)(ModalListaEsperaWithStyles);
