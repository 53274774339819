import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const InativarIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M10 3.24739C6.11218 3.24739 2.96047 6.3991 2.96047 10.2869C2.96047 11.8073 3.44243 13.215 4.2619 14.3658L14.0789 4.54883C12.9281 3.72936 11.5204 3.24739 10 3.24739ZM15.7381 6.20807L5.92114 16.025C7.07189 16.8445 8.47967 17.3265 10 17.3265C13.8878 17.3265 17.0396 14.1748 17.0396 10.2869C17.0396 8.76659 16.5576 7.35882 15.7381 6.20807ZM0.613953 10.2869C0.613953 5.10316 4.81623 0.900879 10 0.900879C15.1838 0.900879 19.3861 5.10316 19.3861 10.2869C19.3861 15.4707 15.1838 19.673 10 19.673C4.81623 19.673 0.613953 15.4707 0.613953 10.2869Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InativarIcon;
