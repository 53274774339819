export const styles = ({
  paper: {
    maxWidth: '882px',
  },
  container: {
    display: 'flex',
    height: '750px',
    width: '882px',
  },
  readOnlyContainer: {
    width: '700px',
    height: 'fit-content',
    minHeight: '670px',
  },
  formArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '510px',
    gap: '8px',
    padding: '24px 16px 24px 24px',
    background: '#fff',
  },
  readOnlyFormArea: {
    width: '700px',
    padding: '24px',
  },
  inputFormError: {
    border: '1px solid red',
  },
  suggestionsArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    background: '#219A97',
    padding: '24px 16px',
    flex: 1
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      fontSize: '24px',
      fontWeight: 600,
    }
  },
  profissionalSelector: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  profissionalSelectRoot: {
    width: '150px !important'
  },
  form: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    position: 'relative'
  },
  formDisabled: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'transparent',
    zIndex: 2
  },
  inputArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    flex: 1,
    '& label': {
      color: '#868686',
      fontSize: '12px',
      fontWeight: 400,
      whiteSpace: 'nowrap'
    }
  },
  inputFormRoot: {
    width: '248px',
    borderRadius: '8px'
  },
  selectRoot: {
    width: '240px',
    borderRadius: '8px'
  },
  selectRootReadOnly: {
    width: '310px',
  },
  selectRootFullSize: {
    width: '500px',
    flex: 1
  },
  notchedOutline: {
    border: 'none',
  },
  inputFormFullSize: {
    width: '510px'
  },
  inputform: {
    border: 'none',
    background: '#F2F2F2',
    '&:focus': {
      background: '#F2F2F2',
    }
  },
  inputError: {
    border: '1px solid red',
  },
  inputLabel: {
    fontSize: '12px !important',
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#868686',
    marginBottom: '4px'
  },
  observacaoInputRoot: {
    width: '510px',
  },
  observacaoInputReadOnly: {
    width: '650px',
  },
  formSwitches: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    border: 'solid 1px #F2F2F2',
    borderRadius: '8px',
    padding: '8px',
    '& label': {
      fontSize: '12px',
      fontWeight: 400,
      color: '#868686'
    }
  },
  switchesArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  switchDisabled: {
  },
  tussArea: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  tabelaSelect: {
    width: '90px !important',
    paddingLeft: '8px',
    margin: 0,
    overflow: 'hidden',
  },
  dropDownIndicator: {
    '&>div:nth-child(2)': {
      '&>div': {
        padding: 0,
      }
    }
  },
  tabelaIcon: {
    fontSize: '24px'
  },
  codigoTussSelect: {
    width: '145px',
    borderRadius: "8px",
  },
  descricaoInput: {
    width: '115px'
  },
  tussTable: {
    maxHeight: '165px',
    flex: 1,
    marginTop: '8px',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    '&>div>div>table>thead>tr': {
      height: '40px',
      '&>th': {
        padding: '0 8px',
        textAlign: 'center'
      }
    },
    '&>div>div>table>tbody>tr': {
      height: '40px',
      '&>td': {
        padding: '0 22px',
        textAlign: 'center'
      }
    }
  },
  tussTableCell: {
    padding: 0,
    margin: 0,
  },
  suggestionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
    '& span': {
      fontSize: '18px',
      fontWeight: 700
    }
  },
  onlyCloseButton: {
    justifyContent: 'flex-end',
  },
  buttonsArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px'
  },
  linkOption: {
    color: '#505050',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& span': {
      fontSize: '14px',
      fontWeight: 600
    }
  },
  inputformErrorStyle: {
    border: '1px solid red',
    fontFamily: "Poppins",
    background: '#F2F2F2',
  },
});
