import { SocialLinks } from 'social-links';

const socialLinks = new SocialLinks();

export const socialMediaLinks = {
  facebook: 'https://facebook.com/',
  instagram: 'https://instagram.com/',
  linkedin: 'https://linkedin.com/in/',
  twitter: 'https://twitter.com/',
}

export const validateSocialMediaLink = (value, field) => {
  try {
    const sanitized = socialLinks.sanitize(field, value);
    return sanitized;
  } catch (error) {
    
  }
};

export const isValid = (link) => {
  const socialMediaName = socialLinks.detectProfile(link)
  return socialLinks.isValid(socialMediaName, link);
}