import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PauseIcon = props => {
  return (
    <svg
      width='27'
      height='28'
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M5.727 4.33834C4.38036 4.35619 3.30317 5.46233 3.32102 6.80897L3.51497 21.4388C3.53282 22.7854 4.63896 23.8626 5.9856 23.8447L9.23666 23.8016C10.5833 23.7838 11.6605 22.6777 11.6426 21.331L11.4487 6.70122C11.4308 5.35458 10.3247 4.27739 8.97806 4.29524L5.727 4.33834ZM17.1057 4.18749C15.7591 4.20535 14.6819 5.31148 14.6998 6.65812L14.8937 21.2879C14.9116 22.6346 16.0177 23.7117 17.3643 23.6939L20.6154 23.6508C21.962 23.6329 23.0392 22.5268 23.0214 21.1802L22.8274 6.55037C22.8096 5.20373 21.7034 4.12654 20.3568 4.14439L17.1057 4.18749Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PauseIcon
