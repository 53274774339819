import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid/Grid";
import Colors from "../../template/Colors";


const style = theme => ({
    item: {
        marginBottom: '5px',
        display: 'flex',
        flexDirection: 'column',
    },
    itemTitle: {
        color: Colors.commons.gray7,
        fontSize: '12px !important',
    },
    itemValue: {
        color: Colors.commons.gray9,
        fontSize: 14,
        fontWeight: 600,
    }
});

const CardItemList = (props) => {
    const {field, value, classes, size} = props;
    return <Grid item xs={size} className={classes.item}>
        <span className={classes.itemTitle}>{field} </span>
        <span className={classes.itemValue}>{value}</span>
    </Grid>;
};


export default withStyles(style)(CardItemList);