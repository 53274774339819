import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './ModalConfirmarBloqueioStyles';
import ModalConfirmacao from '../../../components/Modal/ModalConfirmacao/ModalConfirmacao';
import { inject } from 'mobx-react';
import CloseIcon from '../../../components/Icon/Close';
import InfoIconFilled from '../../../components/Icon/InfoIconFilled';
import { Button } from '../../../components/ui/Buttons/Button/styles';

const ModalConfirmarBloqueio = ({
	classes, 
	open, 
	close, 
	closeModalBloquearAgenda, 
	handleConfirm, 
	horariosPendentes,
	isLoading
}) => {

	return (
		<ModalConfirmacao
			open={open}
			color={"#219A97"}
			title={""}
			buttons={{
				isButtonColorInverte: false,
				labelSecondButton: "Continuar",
				handleSecondButton: () => handleConfirm(),
				labelPrimaryButton: "Cancelar",
				handlePrimaryButton: () => close(),
				disabledPrimaryButton: isLoading,
				disabledSecondButton: isLoading,
				loadingSecondButton: isLoading,
			}}
			classes={{
				paperAlert: classes.paperAlert,
				marginTop: classes.removeMarginTop
			}}
		>
			<div className={classes.content}>
				<div className={classes.header}>
					<InfoIconFilled size={40} color={"#219A97"} />
					<Button 
						onClick={closeModalBloquearAgenda}
						kind="transparent"
						shape="circle"
						size="mini"
					>
						<CloseIcon 	
							size={20} 
							color={"#333333"} 
							className={classes.closeIcon} 
						/>
					</Button>
				</div>
				<span className={classes.title}>Agendamentos pendentes!</span>
				<br />
				<span className={classes.subtitle}>Você possui agendamentos dia:</span>
				<div className={classes.listContainer}>
					<ul className={classes.horariosList}>
						{horariosPendentes?.map((horario, index) => (
							<li
								className={classes.horarioItem}
								key={index}
							>
								{horario}
							</li>
						))}
					</ul>
				</div>	
				<span className={classes.subtitle}>
					Os agendamentos <b>não serão</b> perdidos ao bloquear
				</span>
			</div>
		</ModalConfirmacao>
	)
};

const ModalConfirmarBloqueioWithStyles = withStyles(styles)(ModalConfirmarBloqueio);
export default inject("atendimentoStore", "filtroHeaderStore")(ModalConfirmarBloqueioWithStyles);