import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Grid, Fab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from '../../../components/Icon/Save';
import Header from "../../../template/Header/Header";
import Profile from "../../../template/Header/Profile";
import styles from "../../../assets/jss/pages/horarioAtendimentoStyle";
import Footer from "../../../components/Footer/Footer";
import ButtonLoading from "../../../components/ButtonLoading";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import InformacoesHorarioAtendimento from "./InformacoesHorarioAtendimento";
import Auditoria from "../../../components/Auditoria/Auditoria";
import { tabs } from "../../../stores/Auditoria.store";
import { findAllConfiguracaoHorarioAtendimentoAuditByConfiguracaoHorarioAtendimentoId } from "../../../services/AuditoriaService";
import ProcedimentosTab from './ProcedimentosTab';
import Notification from "../../../components/Notification";

@inject("configuracaoHorarioAtendimentoStore", "googleAnalyticsStore", "auditoriaStore")
@observer
class HorarioAtendimento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "",
            tabSelected: tabs.INFORMACOES
        }
    }

    componentDidMount() {
        const { configuracaoHorarioAtendimentoStore, match, auditoriaStore } = this.props;
        const id = match.params?.id || null;
        let configSalas = match.path.search('salas');

        this.getPageTitle();

        if (configSalas > 0) {
            this.setState({configSalas: true});
        }

        if (id) {
            configuracaoHorarioAtendimentoStore.getConfigById(id);
        } else {
            configuracaoHorarioAtendimentoStore.reset();
            configuracaoHorarioAtendimentoStore.getConfigPadrao();
        }
        
        const paginaVisualizada = {
            page: '/#/configuracoes/horario-atendimento/ID/editar', 
            title: 'Configurações - Editar horário de atendimento'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "a configuração do horário de atendimento",
            auditoriaTela: "configuracaoHorarioAtendimento",
        }
    }

    selectVigencia = config =>
        this.props.configuracaoHorarioAtendimentoStore.findById(config);

    resetNotification = () =>
        this.props.configuracaoHorarioAtendimentoStore.resetNotification();

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.props.configuracaoHorarioAtendimentoStore.closeNotification();
    };

    handleSubmit = async () => {
        try {
            const { configuracaoHorarioAtendimentoStore } = this.props;
            const { configSalas } = this.state;
            if(configSalas) {
                await configuracaoHorarioAtendimentoStore.savePeriodos('salas');
            } else{
                await configuracaoHorarioAtendimentoStore.savePeriodos();
            } 

            this.goToList();
        } catch (error) {
            console.error(error);
            this.props.configuracaoHorarioAtendimentoStore.openNotification(
                error.message,
                "error"
            );
        }
    };

    goToList = () => {
        const { history } = this.props;
        const { configSalas } = this.state;
        if(configSalas) {
            history.push("/configuracoes/horario-salas") 
        } else{
            history.push("/configuracoes/horario-atendimento");
        } 
    };

    getPageTitle = () => {
        const tela = window.location.hash.includes("atendimento") ? "atendimento" : "salas"
        const acao = window.location.hash.includes("criar") ? "Novo" : "Editar"

        this.setState({ pageTitle: pageConfiguracoes(`${acao} horário ${tela}`) });
    };

    renderTab = () => {
        const { tabSelected, configSalas } = this.state;
      
        if (tabSelected === 0) {
          return <InformacoesHorarioAtendimento />
        }

        if (tabSelected === 1 && configSalas) {
          const { id } = this.props.match.params;
          return <ProcedimentosTab configuracaoHorarioAtendimentoId={id} />;
        }

        if ((tabSelected === 1 && !configSalas) || tabSelected === 2) {
          return <Auditoria />
        }
    };

    changeTabSelected = async (tabSelected) => {
        const { auditoriaStore, match } = this.props;
        const { configSalas } = this.state;

        auditoriaStore.auditoriaProps={
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        }
    
        if ((configSalas && tabSelected === 2) || tabSelected === 1) {
          auditoriaStore.load={
            query: findAllConfiguracaoHorarioAtendimentoAuditByConfiguracaoHorarioAtendimentoId, 
            variableName: "configuracaoHorarioAtendimentoId",
            variableId: match.params.id, 
          }
          await auditoriaStore.loadAuditItems();
        }
    
        this.setState({ tabSelected });
    };

    render() {
        const { classes, configuracaoHorarioAtendimentoStore, match } = this.props;
        const { saving, notification, closeAlertMessage } = configuracaoHorarioAtendimentoStore;
        const { isOpen, variant, message } = notification;

        const { pageTitle, tabSelected, configSalas } = this.state;
        const isProcedimentoTab = tabSelected === 1 && configSalas;
        return (
            <>
                <PageTitle title={pageTitle}/>
                <Grid
                    container
                    direction="column"
                    className={classes.root}
                    wrap={"nowrap"}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        className={classes.root}
                        wrap={"nowrap"}
                    >
                        <Grid item container direction={"column"} wrap={"nowrap"}>
                            <Header padding disableShadow borderBottom>
                                <Grid item xs={9}>
                                    <div className={classes.titleFilterContainer}>
                                        <h3 className={classes.titleHeader}>
                                            Config. horário atendimento
                                        </h3>
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid
                                        container
                                        justify={"space-between"}
                                        alignItems={"center"}
                                    >
                                        <Grid item />

                                        <Grid item>
                                            <Profile />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Header>
                            <div className={classes.scroller}>
                                <div className={classes.container}>
                                    <div className={classes.content}>
                                        <MultiToggleButtons
                                            options={configSalas ? ["Informações", "Procedimentos", "Auditoria"] : ["Informações", "Auditoria"]}
                                            tabSelected={tabSelected}
                                            changeTabSelected={this.changeTabSelected}
                                            classes={{ buttonsContainer: classes.buttonsTab }}
                                            disabled={!match.params?.id}
                                            posicaoDesabilitada={[1]}
                                        />
                                        { this.renderTab() }
                                    </div>    
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Footer />
                    </Grid>
                </Grid>
                {!isProcedimentoTab && (
                    <div className={classes.acoes}>
                        <ButtonLoading 
                            loading={saving}
                            icon={SaveIcon}
                            onClick={this.handleSubmit}
                        />    
                        <Fab className={classes.buttonCancelar} onClick={this.goToList}>
                            <CloseIcon/>
                        </Fab>
                    </div>
                )}
                <Notification
                    close={closeAlertMessage}
					reset={closeAlertMessage}
					isOpen={isOpen}
					variant={variant}
					message={message}
                />
            </>
        );
    }
}

export default withStyles(styles)(HorarioAtendimento);
