import React from 'react'
import { withStyles } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import RectLabel from '../../../../../components/Labels/RectLabel'
import Profile from '../../../../../template/Header/Profile'
import { getWhitelabelByUnidade, getWhitelabelNomeByUnidade } from '../../../../../services/WhitelabelService'

const ProfissionalInfos = observer(({ prontuarioStore, classes }) => {
  const { agendamentoSelected } = prontuarioStore
  return (
    <div className={classes.container}>
      <div className={classes.originLabel}>
        <span className={classes.originLabelText}>Agendado via</span>
        <RectLabel
          text={agendamentoSelected?.paciente ? getWhitelabelNomeByUnidade(agendamentoSelected?.unidade) : 'App Health'}
          color={agendamentoSelected?.paciente ? getWhitelabelByUnidade(agendamentoSelected?.unidade)?.corPrincipal : '#229A97'}
        />
      </div>
      <Profile />
    </div>
  )
})

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'max-content',
  },
  originLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  originLabelText: {
    fontSize: '12px !important',
    fontWeigh: '500 !important',
    color: '#505050',
  },
}

const ProfissionalInfosWithStyles = withStyles(styles)(ProfissionalInfos)

export default inject('prontuarioStore')(ProfissionalInfosWithStyles)
