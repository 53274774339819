import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'

import Table from '../../../components/Table/Table'
import HeaderRelatorio from '../HeaderRelatorio'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { InputDateForm } from '../../../components/Modal/Input'
import { Button } from '../../../components/ui/Buttons'
import PrintIcon from '../../../components/Icon/Print'
import { TextFieldSearch } from '../../../components/TextField'

import ErrorCollector from '../../../utils/ErrorCollector'

import colors from '../../../template/Colors'
import { findAllProfissionalSaude } from '../../../services/ProfissionalSaudeService'
import { situacoes as SITUACOES } from '../../../utils/constants/situacoes'
import PopperCheck from '../../../components/Popper/PopperCheck'
import { findAllCid10Subcategoria, findAllRelatorioCID } from '../../../services/RelatorioService'
import moment from 'moment'
import ImpressaoHtml from '../../../components/Impressao/ImpressaoHtml'
import CID from '../../../template/pdf/relatorio/CID'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import Notification from '../../../components/Notification'

const columns = [
  {
    Header: 'CID',
    getValue: item => {
      return item?.descricao || ''
    },
  },
  {
    Header: 'Quantidade',
    getValue: item => {
      return item?.quantidade || ''
    },
  },
]


const RelatorioCID = observer(({ classes, relatorioStore }) => {
  const [filters, setFilters] = useState({
    dataInicio: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    dataFim: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
    profissional: null,
    situacoes: null,
    cid10SubcategoriaId: null,
  })
  const [relatorioCID, setRelatorioCID] = useState([])
  const [isPrintMustache, setIsPrintMustache] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [situacoes, setSituacoes] = useState(
    SITUACOES.map(item => ({ ...item, checked: !item.checked }))
  )

  const findAllRelatoriosCID = async () => {
    try {
      setIsLoading(true)
      const situacoesSelecionadas = situacoes.filter(item => item.checked).map(item => item.value)
      const situacoesFilter = situacoesSelecionadas.length === 0 ? null : situacoesSelecionadas
      const response = await findAllRelatorioCID({
        dataInicio: filters.dataInicio ? moment(filters.dataInicio).format('YYYY-MM-DD') : null,
        dataFim: filters.dataFim ? moment(filters.dataFim).format('YYYY-MM-DD') : null,
        profissionalId: filters.profissional?.id,
        situacoes: situacoesFilter,
        cid10SubcategoriaId: filters.cid10SubcategoriaId?.id,
      })
      setRelatorioCID(response)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadCID10Subcategorias = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = await findAllCid10Subcategoria({
        searchDto: {
          pageSize: 30,
          pageNumber: page,
          sortDir: 'ASC',
          sortField: 'descricao',
          search,
        },
      })
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
        })
      ).data.data.findAllProfissionalSaude
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeFilters = (value, name) => {
    setFilters({ ...filters, [name]: value })
  }

  const quantidadeChecked = () => {
    const situacoesChecked = situacoes.filter(item => item.checked)
    const quantidadeChecked = situacoesChecked.length

    const quantidade = {
      [situacoes.length]: 'Todos',
      0: 'Selecione',
    }

    return quantidade[quantidadeChecked] || `${quantidadeChecked} selecionados`
  }

  const handleChangeCheckbox = (e, index) => {
    const situacoesAux = situacoes.map((item, i) => {
      if (i === index) {
        return { ...item, checked: !item.checked }
      }
      return item
    })
    setSituacoes(situacoesAux)
    setFilters({ ...filters, situacoes: situacoesAux })
  }

  const handlePrint = () => {
    if (relatorioStore.verificaLimiteTrintaDias(filters.dataInicio, filters.dataFim)) {
      setIsPrintMustache(true)
    } else {
      relatorioStore.openNotification("O limite de trinta dias foi excedido!", "error")
    }
  }

  useEffect(() => {
    findAllRelatoriosCID()
  }, [])

  const handleClickSearch = () => {

    if (filters.dataInicio && filters.dataFim) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(filters.dataInicio, filters.dataFim)
      if (isValid) {
        findAllRelatoriosCID()
      } else {
        relatorioStore.openNotification("O limite de trinta dias foi excedido!", "error")
      }
    }
  }

  const disabledButtonFilter = !filters.dataInicio || !filters.dataFim || isLoading

  return (
    <div className={classes.content}>
      <PageTitle title='Relatórios - CID' />
      <HeaderRelatorio
        title='CID'
        screen='RelatorioCID'
        classes={{
          paper: classes.paper,
        }}
        searchWithSelect
        loadOptions={loadCID10Subcategorias}
        valueSelect={filters.cid10SubcategoriaId}
        onChangeSelect={e => handleChangeFilters(e, 'cid10SubcategoriaId')}
        hiddenButtons
        hiddenTotal
      />
      <div className={classes.filtros}>
        <div className={classes.contentFilters}>
          <Grid item xs={3} className={classes.spacingConvenio}>
            <span className={classes.tituloFiltros}> Data Inicio: </span>
            <InputDateForm
              iconposition='end'
              openTo='day'
              views={['year', 'month']}
              value={filters.dataInicio}
              onChange={e => handleChangeFilters(e, 'dataInicio')}
              classes={{
                input: classes.inputData,
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.spacingConvenio}>
            <span className={classes.tituloFiltros}> Data Fim: </span>
            <InputDateForm
              iconposition='end'
              openTo='day'
              views={['year', 'month']}
              value={filters.dataFim}
              onChange={e => handleChangeFilters(e, 'dataFim')}
              classes={{
                input: classes.inputData,
              }}
            />
          </Grid>
          <div className={classes.wrapperFilters}>
            <span className={classes.tituloFiltros}> Profissional: </span>
            <TextFieldSearch
              placeholder='Selecione'
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              classes={{ paper: classes.menuHeight }}
              loadOptions={loadProfissionaisSaude}
              getOptionLabel={option => option?.nome}
              getOptionValue={option => option?.id}
              value={filters.profissional}
              onChange={e => handleChangeFilters(e, 'profissional')}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </div>
          <div className={classes.wrapperFilters}>
            <span className={classes.tituloFiltros}> Situação: </span>
            <PopperCheck
              options={situacoes}
              changeCheckbox={handleChangeCheckbox}
              label={quantidadeChecked()}
            />
          </div>
        </div>
        <div className={classes.buttonFilter}>
          <Button
            shape='pill'
            bgColor='#707C97'
            onClick={handleClickSearch}
            disabled={disabledButtonFilter}
          >
            Filtrar
          </Button>
        </div>
      </div>
      <ErrorCollector mensagem={'Não foi possível carregar o relatório!'}>
        <div className={classes.listaAgendamentoSituacao}>
          {relatorioCID.length === 0 && !isLoading && (
            <div className={classes.notFoundContainer}>
              <h3>Nenhum item encontrado</h3>
            </div>
          )}

          {relatorioCID?.length > 0 && (
            <Table className={classes.table} dados={relatorioCID} columns={columns} />
          )}
          {isLoading && (
            <Grid
              container
              justify='center'
              alignItems='center'
              style={{ height: '100%', marginTop: 20 }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
        </div>
      </ErrorCollector>
      <div className={classes.buttonsDownloadPrint}>
        <Button
          shape='circle'
          bgColor='#F9BE73'
          onClick={handlePrint}
          disabled={relatorioCID.length === 0}
          isLoading={isLoading}
        >
          <PrintIcon />
        </Button>
      </div>
      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={<CID dadosRelatorio={relatorioCID || []} filters={filters} />}
        />
      )}
      <Notification
        isOpen={relatorioStore.notification.isOpen}
        variant={relatorioStore.notification.variant}
        message={relatorioStore.notification.message}
      />
    </div>
  )
})

const styles = theme => ({
  content: {
    display: 'grid',
    gridTemplateRows: '80px 71px 1fr',
    position: 'relative',
    overflow: 'hidden',
    background: '#f5f5f5',

    '& thead th': {
      textAlign: 'left !important',
      position: 'sticky',
      top: 0,
    },
  },

  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    background: '#fff',

    '& h3': {
      color: colors.commons.gray10,
      fontWeight: 'normal',
      fontSize: '1rem',
    },
  },

  inputData: {
    color: colors.commons.fontColor,
    paddingTop: 0,
  },

  filtros: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '13px 16px 8px 16px',
    boxShadow: '10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)',
    background: '#fff',
    zIndex: 1,
    marginTop: 1,
    justifyContent: 'space-between',
    width: '100%'
  },

  contentFilters: {
    display: "flex",
    gap: " 8px",
    alignItems: "center",
  },


  buttonFilter: {
    display: 'flex',
    paddingRight: "20px",

  },

  tituloFiltros: {
    color: colors.commons.gray7,
    fontSize: '12px !important',
  },

  buttonsDownloadPrint: {
    display: 'flex',
    position: 'absolute',
    bottom: '20px',
    right: '14px',
    width: '88px',
    justifyContent: 'space-between',
  },
  listaAgendamentoSituacao: {
    overflow: 'auto',
    margin: '16px',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    background: '#fff',
  },

  popper: {
    zIndex: 20,
    width: 'fit-content',
    left: '-20px',
  },

  spacingConvenio: {
    maxWidth: 175,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  wrapperFilters: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  paper: {
    width: '252px',
    right: '-20px',
    left: 'auto',
  },
  notchedOutline: {
    border: '0',
  },
  inputContainer: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '30px',
    width: '100%',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  classIcons: {
    fontSize: 24,
  },
})
const stores= ["relatorioStore"]
const RelatorioCidWithStyles = withStyles(styles)(RelatorioCID)
export default inject(...stores)(RelatorioCidWithStyles)
