import React from "react";
import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import styles from "../../../../assets/jss/pages/profissionalSaudeStyle";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import CardDadosCadastrais from "../../../../components/Cards/CardDadosCadastrais";
import ItemGrid from "../../../../components/Modal/ItemGridCard";

import { InputForm } from "../../../../components/Modal/Input";
import { InputForm as InputTextArea } from "../../../../components/Input/InputDadosCadastraisPaciente";
import ProfileImageAndBanner from "../../components/ProfileImageAndBanner";

const Customizacao = observer((props) => {
  const {
    classes,
    handleInputChange,
    perfilPublicoStore,
    unidadeStore,
  } = props;

  const { unidade } = unidadeStore;

  const handleSelectMedidasSeguranca = () => {
    perfilPublicoStore.perfilPublicoUnidade.exibeMedidasSeguranca = !perfilPublicoStore
      .perfilPublicoUnidade.exibeMedidasSeguranca;
  };

  const handleUpdateProfile = async (blob) => {
    try {
      await perfilPublicoStore.updateProfileImage(
        perfilPublicoStore.perfilPublicoUnidade.id,
        blob,
        "unidade"
      );
      perfilPublicoStore.checkPerfilUnidadeIsComplete();
      unidadeStore.openNotification(
        "Foto de perfil da unidade alterada com sucesso",
        "success"
      );
    } catch (error) {
      unidadeStore.openNotification(error, "error");
    }
  };

  const handleUpdateBanner = async (blob) => {
    try {
      await perfilPublicoStore.updateBannerImage(
        perfilPublicoStore.perfilPublicoUnidade.id,
        blob,
        "unidade"
      );
      unidadeStore.openNotification(
        "Banner do perfil da unidade alterado com sucesso",
        "success"
      );
    } catch (error) {
      unidadeStore.openNotification(error, "error");
    }
  };

  const handleDeleteBanner = async () => {
    try {
      await perfilPublicoStore.removeBannerImage(
        perfilPublicoStore.perfilPublicoUnidade.id,
        "unidade"
      );
      unidadeStore.openNotification(
        "Banner do perfil da unidade removido com sucesso",
        "success"
      );
    } catch (error) {
      unidadeStore.openNotification(error, "error");
    }
  };

  const handleDeleteProfileImage = async () => {
    try {
      await perfilPublicoStore.removeProfileImage(
        perfilPublicoStore.perfilPublicoUnidade.id,
        "unidade"
      );
      perfilPublicoStore.checkPerfilUnidadeIsComplete();
      unidadeStore.openNotification(
        "Foto de perfil da unidade removida com sucesso",
        "success"
      );
    } catch (error) {
      unidadeStore.openNotification(error, "error");
    }
  };

  return (
    <>
      <div
        className={classNames(
          classes.gridCardContainerPublic,
          classes.gridCardContainerTemplatColumnsUnidade
        )}
      >
        <div>
          <div className={classes.cardContainer}>
            <CardDadosCadastrais>
              <div className={classes.withSwitchInTitle}>
                <ItemGrid label="Medidas de segurança" bold>
                  <span
                    className={classes.boxObservacoesCharCounter}
                    style={{ float: "left" }}
                  >
                    Informe os seus pacientes as possíveis medidas de segurança
                    utilizadas. Exemplo: Uso de máscaras, álcool em gel, etc...
                  </span>
                </ItemGrid>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        perfilPublicoStore.perfilPublicoUnidade
                          .exibeMedidasSeguranca
                      }
                      color="primary"
                    />
                  }
                  onChange={handleSelectMedidasSeguranca}
                  labelPlacement="start"
                  className={classes.controller}
                />
              </div>
              {perfilPublicoStore.perfilPublicoUnidade
                .exibeMedidasSeguranca && (
                <div>
                  <InputTextArea
                    name={"medidasSeguranca"}
                    value={
                      perfilPublicoStore.perfilPublicoUnidade
                        .medidasSeguranca || ""
                    }
                    placeholder="Uso de máscaras, álcool em gel, etc..."
                    multiline
                    rowsMax={5}
                    rows={5}
                    className={[classes.inputContainer, classes.textBox]}
                    style={{ margin: 0 }}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "medidasSeguranca")
                    }
                    inputProps={{ maxLength: 500 }}
                  />
                  <span className={classes.boxObservacoesCharCounter}>
                    {`${perfilPublicoStore.perfilPublicoUnidade.medidasSeguranca
                      ?.length || 0}/500`}
                  </span>
                </div>
              )}
            </CardDadosCadastrais>
          </div>
          <div className={classes.cardContainer}>
            <CardDadosCadastrais titulo="Redes Sociais">
              <span>Para cada rede social digite apenas o nome de usuário</span>
              <ItemGrid size={12} label="Facebook" bold>
                <InputForm
                  className={classes.inputContainer}
                  value={perfilPublicoStore.perfilPublicoUnidade.facebook}
                  onChange={(e) =>
                    handleChangeSocialMediaLink(e.target.value, "facebook")
                  }
                />
              </ItemGrid>
              <ItemGrid size={12} label="Instagram" bold>
                <InputForm
                  className={classes.inputContainer}
                  value={perfilPublicoStore.perfilPublicoUnidade.instagram}
                  onChange={(e) =>
                    handleChangeSocialMediaLink(e.target.value, "instagram")
                  }
                />
              </ItemGrid>
              <ItemGrid size={12} label="Linkedin" bold>
                <InputForm
                  className={classes.inputContainer}
                  value={perfilPublicoStore.perfilPublicoUnidade.linkedin}
                  onChange={(e) =>
                    handleChangeSocialMediaLink(e.target.value, "linkedin")
                  }
                />
              </ItemGrid>
              <ItemGrid size={12} label="Twitter" bold>
                <InputForm
                  className={classes.inputContainer}
                  value={perfilPublicoStore.perfilPublicoUnidade.twitter}
                  onChange={(e) =>
                    handleChangeSocialMediaLink(e.target.value, "twitter")
                  }
                />
              </ItemGrid>
            </CardDadosCadastrais>
          </div>
        </div>
        <div>
          <div className={classes.cardContainer}>
            <ProfileImageAndBanner
              profileImageId={
                perfilPublicoStore.perfilPublicoUnidade.fotoPerfil
              }
              bannerImageId={perfilPublicoStore.perfilPublicoUnidade.banner}
              updateProfile={handleUpdateProfile}
              updateBanner={handleUpdateBanner}
              token={perfilPublicoStore.accessToken}
              removeProfileImage={handleDeleteProfileImage}
              removeBanner={handleDeleteBanner}
              loadingProfileImage={perfilPublicoStore.loadingProfileImage}
              loadingBannerImage={perfilPublicoStore.loadingBannerImage}
              disabled={
                !unidade.perfilPublico ||
                !perfilPublicoStore.perfilPublicoUnidade.id
              }
              corPersonalizada={
                perfilPublicoStore.perfilPublicoUnidade.corPersonalizada
              }
              field={"perfilPublicoUnidade"}
            />
          </div>
        </div>
      </div>
    </>
  );
});

const CustomizacaoWithStyles = withStyles(styles)(Customizacao);
const stores = [
  "unidadeStore",
  "perfilPublicoStore",
  "profissionalSaudeStore",
  "unidadeBraspagStore",
];
export default inject(...stores)(CustomizacaoWithStyles);
