import Api from "../config/api";

export const saveSolicitacaoProcedimento = procedimentoPedido => {
  const method = procedimentoPedido.id ? 'updateProcedimentoPedido' : 'createProcedimentoPedido'
  return Api.post("", {
  query: `
  mutation ($procedimentoPedido: ProcedimentoPedidoInput){
    ${method}(procedimentoPedido:$procedimentoPedido){
      id
      observacao
      procedimentosSolicitados {
        id
        agendado
        procedimento {
          id
          nome
        }
      }
      profissionalSaude{
        id
      }
    }
  }`,
  variables: { procedimentoPedido }
})};

export const findAllProcedimentoPedido = variables => Api.post("", {
    query: `
    query ($search: String, $idSujeitoAtencao: UUID, $pageableDTO: PageableDTOInput, $idProfissionalSaude: Long) {
      findAllProcedimentoPedido(search: $search, idSujeitoAtencao: $idSujeitoAtencao, pageableDTO: $pageableDTO, idProfissionalSaude: $idProfissionalSaude) {
        last
        content {
          id
          observacao
          procedimentos {
            id
            agendado
            procedimento {
              id
              nome
            }
          }
          profissionalSaude {
            id
            nome
          }
          sujeitoAtencao {
            id
            nome
          }
        }
      }
    }`,
    variables: variables
  })

  export const createByProcedimentoModelo = variables => {
    const response = Api.post("", {
      query: `
        mutation CreateByProcedimentoModelo($agendamentoId: Long, $procedimentoModelos: [ProcedimentoModeloInput], $sujeitoAtencaoId: UUID, $observacao: String) {
          createByProcedimentoModelo(agendamentoId: $agendamentoId, procedimentoModelos: $procedimentoModelos, sujeitoAtencaoId: $sujeitoAtencaoId, observacao: $observacao) {
            id
          }
        }`,
      variables,
    });
    return response
  }