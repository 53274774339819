const tabsOptions = [
  {
    label: 'Informacões',
  },
  {
    label: 'Auditoria',
  }
];

export default tabsOptions;
