import React, { useEffect, useState } from 'react';
import { Checkbox, Tooltip, withStyles } from '@material-ui/core';
import Scroll from '../../../../../components/InfiniteScroll/Scroll';
import moment from 'moment';
import TableCellDateInput from './TableCellDateInput';
import TableCellSelect from './TableCellSelect';
import TableCellDropDown from './TableCellDropDown';
import SituacaoTableCell from './SituacaoTableCell';
import { findAllProfissionalSaude } from '../../../../../services/ProfissionalSaudeService';
import { configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude, findEspacosLivresDoProfissionalSaude } from '../../../../../services/ConfiguracaoHorarioAtendimentoService';
import { createMultiples } from '../../../../../utils/numberArrayGenerator';
import CustomTable from '../../../../../components/Table/CustomTable';
import { updateAgendamentoSituacao } from '../../../../../services/AgendamentoService';
import { sortArrayByKey } from '../../../../../utils/objectArraySort';

const styles = ({
  root: {
    borderTop: '1px solid #F2F2F2',
    height: '100%'
  },
  tableCell: {
    paddingLeft: '24px',
    paddingRight: 0,
    fontSize: '12px !important',
    color: '#505050',
    fontWeight: '600',
    maxWidth: '178px'
  },
  canceled: {
    textDecoration: 'line-through'
  },
  pacienteName: {
    maxWidth: '120px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  profissionalSelect: {
    width: '178px'
  },
  duracaoSelect: {
    width: '100px'
  },
  horaInicioSelect: {
    width: '100px'
  }
});

const currentDay = moment().format('YYYY-MM-DD');

const ConflictsTable = ({ 
  classes, 
  agendamentos, 
  selectedAgendamentos, 
  setSelectedAgendamento, 
  openNotification
}) => {
  const [profissionaisSaude, setProfissionaisSaude] = useState([]);
  const [agendamentosToFix, setAgendamentosToFix] = useState([]);
  const [sortOptions, setSortOptions] = useState({
    sortField: 'data',
    sortDir: 'ASC'
  });
  const [areAllSelected, setAreAllSelected] = useState(false);

  useEffect(() => {
    loadProfissionaisSaude();
    initAgendamentosToFix();
  }, []);

  const loadProfissionaisSaude = async () => {
    try {
      const response = await findAllProfissionalSaude({
        pageSize: 100,
        sortField: 'nome',
      });

      const profissionaisSaude = response?.data?.data?.findAllProfissionalSaude?.content;

      if (profissionaisSaude?.length > 0) {
        setProfissionaisSaude(profissionaisSaude);
      }
    } catch (error) {
      openNotification({
        message: 'Falha ao carregar profissionais de saúde.',
        isOpen: true,
        variant: 'error'
      })
    }
  };

  const initAgendamentosToFix = () => {
    const agendamentosToFix = agendamentos.map((agendamento) => {
      const horaInicio = moment(agendamento.horaInicio, 'HH:mm');
      const horaFim = moment(agendamento.horaFim, 'HH:mm');
      return {
        ...agendamento,
        horaInicio: {
          name: horaInicio.format('HH:mm'),
          value: horaInicio.format('HH:mm')
        },
        duracao: {
          name: `${horaFim.diff(horaInicio, 'minute')} min.`,
          value: horaFim.diff(horaInicio, 'minute')
        },
        profissionalSaude: agendamento.profissionalSaude,
      };
    });

    setAgendamentosToFix(agendamentosToFix);
  }

  const handleCheckboxChange = (agendamentoId) => {
    const agendamento = agendamentosToFix.filter((agendamento => agendamento.id === agendamentoId))[0];
    const indexOfAgendamento = selectedAgendamentos.indexOf(agendamento);
    const updatedSelectedAgendamentos = [...selectedAgendamentos];

    if (indexOfAgendamento !== -1) {
      updatedSelectedAgendamentos.splice(indexOfAgendamento, 1);
    } else {
      updatedSelectedAgendamentos.push(agendamento);
    }
    setSelectedAgendamento(updatedSelectedAgendamentos);
  }

  const toggleAll = () => {
    const updatedSelectedAgendamentos = [...selectedAgendamentos];

    if (areAllSelected) {
      setAreAllSelected(false);
      return setSelectedAgendamento([]);
    }

    agendamentosToFix.forEach((agendamento) => {
      const indexOfAgendamento = selectedAgendamentos.indexOf(agendamento);

      if (indexOfAgendamento === -1 && checkIsModified(agendamento.id)) {
        updatedSelectedAgendamentos.push(agendamento);
      }
    });

    setAreAllSelected(true);
    setSelectedAgendamento(updatedSelectedAgendamentos)
  };

  const isAgendamentoSelected = (agendamentoId) => {
    const isSelected = selectedAgendamentos.some(agendamento => agendamento.id === agendamentoId);
    return isSelected;
  };

  const handleChangeData = (agendamentoId, value, field) => {
    let agendamento = agendamentosToFix.filter((agendamento) => agendamento.id === agendamentoId)[0];
    const agendamentoIndex = agendamentosToFix.indexOf(agendamento);

    agendamento = {
      ...agendamento,
      [field]: value,
    }

    if (field === 'horaInicio' || field === 'duracao') {
      agendamento = {
        ...agendamento,
        horaFim: calcHoraFim(agendamento.horaInicio.value, agendamento.duracao.value),
      }
    }

    let agendamentos = [...agendamentosToFix];
    agendamentos[agendamentoIndex] = agendamento;
    setAgendamentosToFix(agendamentos);
  };

  const calcHoraFim = (horaInicio, duracao) => {
    return moment(horaInicio, 'HH:mm').add(Number(duracao || 0), 'minutes').format('HH:mm');
  }

  const checkIsModified = (agendamentoId) => {
    let agendamentoToFix = agendamentosToFix.filter((agendamento) => agendamento.id === agendamentoId)[0];
    let agendamentoInitialState = agendamentos.filter((agendamento) => agendamento.id === agendamentoId)[0];

    return (agendamentoToFix.data !==  agendamentoInitialState.data) || (agendamentoToFix.horaInicio.value !== agendamentoInitialState.horaInicio);
  };

  const checkIsCanceled = (agendamentoId) => {
    let agendamentoToFix = agendamentosToFix.filter((agendamento) => agendamento.id === agendamentoId)[0];
    return agendamentoToFix.situacao === 'CANCELADO';
  }

  const getDurationValuesByProfissionalSelected = async (agendamentoId) => {
    const agendamento = agendamentosToFix.filter(agendamento => agendamento.id === agendamentoId)[0];
    const profissionalSaudeId = agendamento?.profissionalSaude?.id || agendamento?.profissionalSaude;
    try {
      const response = await configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude({
        profissionalSaudeId: profissionalSaudeId,
        dataInicial: currentDay,
        dataFinal: currentDay
      });

      let options = [];

      if (response?.data?.data?.configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude) {
        const { duracao } = response.data.data.configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude[0];
        options = duracao ? createMultiples(duracao, 10)
          .map(number => {
            return { name: `${number} min.`, value: number }
        }) : [];
      }
      
      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const getEspacosLivres = async (agendamentoId) => {
    const agendamento = agendamentosToFix.filter(agendamento => agendamento.id === agendamentoId)[0];
    const profissionalSaudeId = agendamento?.profissionalSaude?.id || agendamento?.profissionalSaude;

    try {
      const response = await findEspacosLivresDoProfissionalSaude({
        profissionalSaudeId,
        horaInicio: '07:00',
        horaFim: '19:00',
        data: moment(agendamento.data).format('YYYY-MM-DD'),
      });

      let options = [];

      if (response.data?.data?.findEspacosLivresDoProfissionalSaude) {
        const espacosLivres = response.data.data.findEspacosLivresDoProfissionalSaude;
        options = espacosLivres.map(horario => {
          return { name: horario, value: horario };
        });
      }

      return {
        options,
        hasMore: false,
      }
    } catch(error) {
      console.error(error);
    }
  }

  const handleCancelAgendamento = async (agendamentoId) => {
    try {
      await updateAgendamentoSituacao(agendamentoId, 'CANCELADO');
      handleChangeData(agendamentoId, 'CANCELADO', 'situacao');
      openNotification({
        message: 'Agendamento cancelado com sucesso',
        isOpen: true,
        variant: 'success'
      })
    } catch(error) {
      openNotification({
        message: 'Falha ao cancelar o agendamento',
        isOpen: true,
        variant: 'error'
      })
    }
  }

  const getTableColumns = () => {
    return [
      {
        header: <Checkbox color='primary' onChange={toggleAll}/>,
        component: (rowId) => (
          <Checkbox 
            color='primary' 
            checked={isAgendamentoSelected(rowId)}
            disabled={!checkIsModified(rowId)}
            onChange={() => handleCheckboxChange(rowId)}
          />
        )
      },
      {
        header: 'DATA',
        field: 'data',
        component: (rowId, value, onChange, field) => (
          <TableCellDateInput
            name='data'
            field={field} 
            value={value} 
            onChange={onChange}
            rowId={rowId}
            isModified={checkIsModified(rowId)}
            isCanceled={checkIsCanceled(rowId)}
            disablePast
          />
        ),
        onChange: handleChangeData,
        sortField: 'data'
      },
      {
        header: 'PACIENTE',
        field: 'nome',
        component: (rowId, value) => (
          <div className={classes.pacienteName}>
            <Tooltip title={value} placement='top-start'>
              <span className={checkIsCanceled(rowId) ? classes.canceled : null}>
                {value}
              </span>
            </Tooltip>
          </div>
        ),
        sortField: 'nome'
      },
      {
        header: 'INICIO',
        field: 'horaInicio',
        component: (rowId, value, onChange, field) => (
          <div className={classes.horaInicioSelect}>
            <TableCellSelect
              name='horaInicio'
              placeholder=''
              agendamentoId={rowId}
              value={value}
              onChange={onChange}
              rowId={rowId}
              field={field}
              getElements={() => getEspacosLivres(rowId)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              withPaginate
              isModified={checkIsModified(rowId)}
              isCanceled={checkIsCanceled(rowId)}
              cacheOptions={false}
            />
          </div>
        ),
        onChange: handleChangeData,
        sortField: 'horaInicio'
      },
      {
        header: 'DURAÇÃO',
        field: 'duracao',
        component: (rowId, value, onChange, field) => (
          <div className={classes.duracaoSelect}>
            <TableCellSelect
              name='duracao'
              placeholder=''
              rowId={rowId}
              value={value}
              field={field}
              onChange={onChange}
              getElements={() => getDurationValuesByProfissionalSelected(rowId)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              withPaginate
              isCanceled={checkIsCanceled(rowId)}
              loadElementsOnInit
              cacheOptions={false}
            />
          </div>
        ),
        onChange: handleChangeData
      },
      {
        header: 'FIM',
        field: 'horaFim',
        component: (rowId, value) => (
          <span className={checkIsCanceled(rowId) ? classes.canceled : null}>
            {value}
          </span>
        ),
        sortField: 'horaFim'
      },
      {
        header: 'PROFISSIONAL',
        field: 'profissionalSaude',
        component: (rowId, value, onChange, field) => (
          <div className={classes.profissionalSelect}>
            <TableCellSelect
              name="profissionalSaude"
              placeholder=''
              value={value}
              rowId={rowId}
              field={field}
              onChange={onChange}
              options={profissionaisSaude}
              isCanceled={checkIsCanceled(rowId)}
              getOptionLabel={(option) => option.nome}
              getOptionValue={(option) => option.id}
            />
          </div>
        ),
        onChange: handleChangeData,
        sortField: 'profissionalSaude'
      },
      {
        header: 'STATUS',
        field: 'situacao',
        component: (rowId, value) => (
          <SituacaoTableCell 
            situacao={value} 
            isCanceled={checkIsCanceled(rowId)}
          />
        ),
        sortField: 'situacao'
      },
      {
        header: 'AÇÃO',
        component: (rowId) => (
          <TableCellDropDown
            onClickOption={() => handleCancelAgendamento(rowId)}
          />
        ),
      },
    ];
  };

  const sortAgendamentosByField = (sortField) => {
    let updatedSortOptions = {...sortOptions};
    const { sortDir } = sortOptions;
    if (sortField === sortOptions.sortField) {
      updatedSortOptions.sortDir = sortDir === 'ASC' ? 'DESC' : 'ASC';
    } else {
      updatedSortOptions = {
        sortField,
        sortDir: 'ASC'
      }
    }

    setSortOptions(updatedSortOptions);
    sortAgendamentos(updatedSortOptions.sortField, updatedSortOptions.sortDir);
  }

  const sortAgendamentos = (sortField, sortDir) => {
    const sortedArray = sortArrayByKey(agendamentosToFix, sortField, sortDir);
    setAgendamentosToFix(sortedArray);
  };

  return (
    <Scroll classes={{ root: classes.root }}>
      <CustomTable
        columns={getTableColumns()} 
        data={agendamentosToFix}
        classes={{
          tableCellRoot: classes.tableCell
        }}
        withSort
        currentSortOptions={sortOptions}
        handleSort={sortAgendamentosByField}
      />
    </Scroll>
  );
};

export default withStyles(styles)(ConflictsTable);
