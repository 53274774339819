import React, { useEffect } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import styles from "./ModalProcedimentoStyle";
import Informacoes from "./Informacoes/Informacoes";
import HeaderModal from "../../Components/HeaderModal";
import Dialog from "../../../../../../components/Dialog/Dialog";

const ModalProcedimento = observer(({ classes, odontogramaStore }) => {
  const { modalProcedimento, changeCloseModalProcedimento } = odontogramaStore;

  const { showModalProcedimento } = modalProcedimento;

  useEffect(() => {
    odontogramaStore.loadTodosLocais();
  }, [])

  return (
    <Dialog
      open={showModalProcedimento}
      maxWidth="md"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <HeaderModal
        title="Adicionar procedimento"
        onClose={changeCloseModalProcedimento}
        classes={{ header: classes.header }}
      />
      <Informacoes />
    </Dialog>
  );
});

const ModalProcedimentoWithStyles = withStyles(styles)(ModalProcedimento);
export default inject("odontogramaStore")(ModalProcedimentoWithStyles);
