import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { InputForm } from "../../../../../../../../components/Modal/Input";
import PeDireito from "../../../../../../../../components/Image/Palmilhograma/PeDireito";

const PeDireitoContent = ({
  classes,
  orcamento,
  locaisPalmilhaPeDireito,
  handleEspessura,
  handleClickSelect,
}) => {
  const [areasSelected, setAreasSelected] = useState([]);

  useEffect(() => {
    setAreasSelected(locaisPalmilhaPeDireito.filter((area) => area.checked));
  }, [locaisPalmilhaPeDireito]);

  return (
    <div className={classes.content}>
      <span className={classes.title}> Pé direito </span>
      <div className={classes.peLocais}>
        <PeDireito areasSelected={areasSelected} />
        <div className={classes.contentLocais}>
          {locaisPalmilhaPeDireito.map((local, index) => {
            return (
              <div className={classes.contentLocal} key={local.index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={local.checked}
                      color={"primary"}
                      disabled={orcamento?.id}
                    />
                  }
                  label={<span className={classes.local}>{local.nome}</span>}
                  key={index}
                  onChange={() => handleClickSelect(local, 'locaisPalmilhaPeDireito', index)}
                />
                <InputForm
                  value={local.espessura}
                  onChange={(e) =>
                    handleEspessura(e, "locaisPalmilhaPeDireito", index)
                  }
                  classes={{
                    input: classes.inputInfos,
                  }}
                  type="number"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const styles = {
  content: {
    flex: 1,
    padding: "16px",
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
  contentLocais: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    marginTop: "8px",
  },
  contentLocal: {
    color: "#5F6368",
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: "#5F6368",
    fontWeight: 600,
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    width: "100%",
    display: "flex",
  },
  peLocais: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    justifyContent: "space-between",
  },
  inputInfos: {
    height: "10px",
    maxWidth: "50px",
  },
};

export default withStyles(styles)(PeDireitoContent);
