import React from 'react';
import { withStyles } from '@material-ui/core';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: "column",
    padding: '32px',
    gap: '8px'
  }
});

const ExcluirAgendamentosModal = ({ open, onConfirm, onClose, classes, isLoading }) => {
  return (
    <ConfirmModal
      open={open}
      onConfirm={onConfirm}
      onCancel={onClose}
      title="Confirmar exclusão"
      text="Você deseja continuar com a exclusão dos agendamentos selecionados?"
      classes={{
        container: classes.container
      }}
      isLoading={isLoading}
    />
  )
};

export default withStyles(styles)(ExcluirAgendamentosModal);

