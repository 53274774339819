import React from "react";
import moment from "moment";

const renderItem = ({ item, index }) => {

  const horarioAgendamento = () => {
    const horaInicio = moment(item.start).format("HH:mm");
    const horaFim = moment(item.end).format("HH:mm");

    return `${horaInicio} - ${horaFim}`
  };

  return (
    <div style={styles.containerColuna} key={index}>
      <div style={styles.coluna}>
        <div style={styles.text}>{horarioAgendamento()}</div>
      </div>

      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.nome}</div>
      </div>

      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.procedimentosString}</div>
      </div>

      <div style={styles.coluna}>
        <div style={styles.text}>{item.convenio}</div>
      </div>

      <div style={styles.coluna}>
        <div style={styles.text}>{item.solicitante}</div>
      </div>

      <div style={styles.coluna}>
        <div style={styles.text}>{item.profissionalSaude}</div>
      </div>
    </div>
  );
};

const RelatorioAgendaDiaSalaDocument = (props) => {
  const { data, agendamentos } = props;

  return (
    <>
      <div style={styles.titulo}>
        <div style={styles.textBold}> Relatório agenda - {moment(data).format("DD/MM/YY")} </div>
      </div>
      <div style={styles.containerColuna}>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Horário</div>
        </div>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>Nome</div>
        </div>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>Procedimentos</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Convênio</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Solicitante</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Profissional</div>
        </div>
      </div>

      {agendamentos.map((item, index) => renderItem({ item, index }))}
    </>
  );
};

const styles = {
  text: {
    fontSize: '12px',
    marginBottom: '8px',
  },

  textBold: {
    fontWeight: 700,
    fontSize: '12px',
    marginBottom: '8px',
  },

  titulo: {
    marginLeft: '0px',
    marginBottom: '10px',
    marginTop: '5px',
    paddingBottom: '5px',
    textAlign: 'center',
  },

  containerColuna: {
    display: 'flex',
    flexDirection: 'row',
  },

  coluna: {
    display: 'flex',
    flexGrow: 1,
    width: '75px',
  },

  colunaQuebraPalavra: {
    display: 'flex',
    flexGrow: 1,
    width: '75px',
    wordBreak: 'break-all',
  },

  colunaDescricao: {
    display: 'flex',
    flexGrow: 2,
    width: '150px',
    marginBottom: '8px',
    marginRight: '3px',
  },
}

export default RelatorioAgendaDiaSalaDocument;
