import Api from "../config/api";

export const findAuditoria = async (id, pageableDTO) => {
  const response = await Api.post("", {
    query: `
      query($id: UUID, $pageableDTO:PageableDTOInput){
        findAllSujeitoAtencaoAuditBySujeitoAtencaoId(sujeitoAtencaoId:$id, pageableDTO:$pageableDTO){
          content{
            alturaModified
            ativoModified
            cnsModified
            comoConheceuModified
            documentoModified
            dataNascimentoModified
            fotoPerfilModified
            nomeConjugeModified
            nomeMaeModified
            nomeModified
            nomePaiModified
            nomeSocialModified
            observacaoModified
            pesoModified
            profissionalSaudeModified
            quemIndicouModified
            religiaoModified
            rgModified
            revtype
            sujeitoAtencaoAuditId{
              revisao{
                id
                usuario{
                  id
                  nome
                  fotoPerfil
                }
                dataHoraModificacao
              }
            }
          }
          last
          totalElements
          totalPages
        }
      }
    `,
    variables: { id, pageableDTO },
  });
  return response;
};

export const findAllTituloParcelaAuditByTituloParcelaId = async ({
  tituloParcelaId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
              query ($tituloParcelaId: Long, $pageableDTO: PageableDTOInput) {
                  findAllTituloParcelaAuditByTituloParcelaId(tituloParcelaId: $tituloParcelaId, pageableDTO: $pageableDTO) {
                  last
                  content {
                      revisao {
                      dataHoraModificacao
                      usuario {
                          fotoPerfil
                          nome
                      }
                      }
                      revtype
                          agendamentoModified
                      categoriaFinanceiraModified
                      centroCustoModified
                          contaBancariaModified
                      dataHoraLancamentoModified
                      dataPagamentoModified
                      dataVencimentoModified
                      excluidoModified
                      formaPagamentoModified
                      nomeModified
                      numeroParcelaModified
                      periodicidadeModified
                      profissionalSaudeModified
                      sujeitoAtencaoModified
                      tipoModified
                      tipoRepeticaoModified
                      tokenPagamentoModified
                      totalParcelasModified
                      valorModified
                  }
                  }
              }`,
    variables: {
      tituloParcelaId,
      pageableDTO,
    },
  });
  const auditoria =
    response.data.data.findAllTituloParcelaAuditByTituloParcelaId;

  return auditoria;
};

export const findAllTituloAuditByTituloId = async ({
  tituloId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($tituloId: Long, $pageableDTO: PageableDTOInput) {
      findAllTituloAuditByTituloId(tituloId: $tituloId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          agendamentoModified
          categoriaFinanceiraModified
          centroCustoModified
          contaBancariaModified
          dataHoraLancamentoModified
          dataPagamentoModified
          dataVencimentoModified
          formaPagamentoModified
          nomeModified
          periodicidadeModified
          profissionalSaudeModified
          sujeitoAtencaoModified
          tipoModified
          tipoRepeticaoModified
          tokenPagamentoModified
          totalParcelasModified
          valorModified
        }
      }
    }
    `,
    variables: {
      tituloId,
      pageableDTO,
    },
  });
  const auditoria =
    response.data.data.findAllTituloAuditByTituloId;

  return auditoria;
};

export const findAllCentroCustoAuditByCentroCustoId = async ({
  centroCustoId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($centroCustoId: Long, $pageableDTO: PageableDTOInput) {
      findAllCentroCustoAuditByCentroCustoId(centroCustoId: $centroCustoId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
        }
      }
    }`,
    variables: {
      centroCustoId,
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data.findAllCentroCustoAuditByCentroCustoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllCategoriaFinanceiraAuditByCategoriaFinanceiraId = async ({
  categoriaFinanceiraId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($categoriaFinanceiraId: Long, $pageableDTO: PageableDTOInput) {
      findAllCategoriaFinanceiraAuditByCategoriaFinanceiraId(categoriaFinanceiraId: $categoriaFinanceiraId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          tipoModified
        }
      }
    }`,
    variables: {
      categoriaFinanceiraId,
      pageableDTO,
    },
  });
  const auditoria =
    response.data.data.findAllCategoriaFinanceiraAuditByCategoriaFinanceiraId;

  return auditoria;
};

export const findAllContaBancariaAuditByContaBancariaId = async ({
  contaBancariaId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($contaBancariaId: Long, $pageableDTO: PageableDTOInput) {
      findAllContaBancariaAuditByContaBancariaId(contaBancariaId: $contaBancariaId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          bancoModified
          saldoAtualModified
          saldoInicialModified
        }
      }
    }`,
    variables: {
      contaBancariaId,
      pageableDTO,
    },
  });

  const auditoria =
    response.data.data.findAllContaBancariaAuditByContaBancariaId;

  return auditoria;
};

export const findAllProdutoAuditByProdutoId = async ({
  produtoId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($produtoId: UUID, $pageableDTO: PageableDTOInput) {
      findAllProdutoAuditByProdutoId (produtoId: $produtoId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
        }
      }
    }`,
    variables: {
      produtoId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllProdutoAuditByProdutoId) {
    return response.data.data.findAllProdutoAuditByProdutoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllAgendamentoTipoAuditByAgendamentoTipoId = async ({
  agendamentoTipoId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($agendamentoTipoId: UUID, $pageableDTO: PageableDTOInput) {
      findAllAgendamentoTipoAuditByAgendamentoTipoId(agendamentoTipoId: $agendamentoTipoId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          descricaoModified
          codigoModified
          ativoModified
        }
      }
    }`,
    variables: {
      agendamentoTipoId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllAgendamentoTipoAuditByAgendamentoTipoId) {
    return response.data.data.findAllAgendamentoTipoAuditByAgendamentoTipoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllConfiguracaoHorarioAtendimentoAuditByConfiguracaoHorarioAtendimentoId = async ({
  configuracaoHorarioAtendimentoId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($configuracaoHorarioAtendimentoId: Long, $pageableDTO: PageableDTOInput) {
      findAllConfiguracaoHorarioAtendimentoAuditByConfiguracaoHorarioAtendimentoId(configuracaoHorarioAtendimentoId: $configuracaoHorarioAtendimentoId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          dataFimVigenciaModified
          dataInicioVigenciaModified
          duracaoModified
          horaFimPeriodoAtendimentoModified
          horaInicioPeriodoAtendimentoModified
        }
      }
    }`,
    variables: {
      configuracaoHorarioAtendimentoId,
      pageableDTO,
    },
  });

  if (
    response.data?.data
      ?.findAllConfiguracaoHorarioAtendimentoAuditByConfiguracaoHorarioAtendimentoId
  ) {
    return response.data.data
      .findAllConfiguracaoHorarioAtendimentoAuditByConfiguracaoHorarioAtendimentoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllMedicamentoAuditByMedicamentoId = async ({
  medicamentoId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($medicamentoId: UUID, $pageableDTO: PageableDTOInput) {
      findAllMedicamentoAuditByMedicamentoId(medicamentoId: $medicamentoId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          fabricanteModified
          formaFarmaceuticaModified
          principioAtivoModified
          produtoModified
          profissionalSaudeModified
          receituarioModified
          statusModified
        }
      }
    }`,
    variables: {
      medicamentoId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllMedicamentoAuditByMedicamentoId) {
    return response.data.data.findAllMedicamentoAuditByMedicamentoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllReceitaModeloAuditByReceitaModeloId = async ({
  receitaModeloId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($receitaModeloId: UUID, $pageableDTO: PageableDTOInput) {
      findAllReceitaModeloAuditByReceitaModeloId(receitaModeloId: $receitaModeloId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          ativoModified
          nomeModified
          profissionalSaudeModified
          quantidadeUsoModified
          tipoModified
        }
      }
    }`,
    variables: {
      receitaModeloId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllReceitaModeloAuditByReceitaModeloId) {
    return response.data.data.findAllReceitaModeloAuditByReceitaModeloId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};



export const findAllPeriodoAtendimentoModificadoAuditId = async ({
  id,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($id: Long, $pageableDTO: PageableDTOInput) {
      findAllPeriodoAtendimentoModificadoAudit(id: $id, pageableDTO: $pageableDTO) {
        last
        content {
          acao
          acaoModified
          dataModified
          horarioInicioModified
          horaFimModified
          revisao{
            clientId
            dataHoraModificacao
            id
            paciente{
              id
              nome
            }
            usuario{
              id
              nome
            }
          }
          periodoAtendimentoModificadoAuditId{
            periodoAtendimentoModificado{
              id
              acao
              data
              horaInicio
              horaFim
              profissionalSaude{
                nome
              }
            }

          }
            profissionalSaudeAudit{
              id
              nome
            }
            revtype
            unidadeAudit{
              id
            }
          }
        }
      }`,
    variables: {
      id,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllPeriodoAtendimentoModificadoAudit) {
    return response.data.data.findAllPeriodoAtendimentoModificadoAudit;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllAnamneseModeloAuditByAnamneseModeloId = async ({
  anamneseModeloId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($anamneseModeloId: UUID, $pageableDTO: PageableDTOInput) {
      findAllAnamneseModeloAuditByAnamneseModeloId(anamneseModeloId: $anamneseModeloId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
          profissionalSaudeModified
        }
      }
    }`,
    variables: {
      anamneseModeloId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllAnamneseModeloAuditByAnamneseModeloId) {
    return response.data.data.findAllAnamneseModeloAuditByAnamneseModeloId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllConvenioAuditByConvenioId = async ({
  convenioId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($convenioId: Long, $pageableDTO: PageableDTOInput) {
      findAllConvenioAuditByConvenioId(convenioId: $convenioId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          ativoModified
          descricaoModified
          codigoUnidadeModified
          registroAnsModified
          valorConsultaModified
        }
      }
    }`,
    variables: {
      convenioId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllConvenioAuditByConvenioId) {
    return response.data.data.findAllConvenioAuditByConvenioId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllGuiaServicoModeloAuditByGuiaServicoModeloId = async ({
  guiaServicoModeloId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($guiaServicoModeloId: UUID, $pageableDTO: PageableDTOInput) {
      findAllGuiaServicoModeloAuditByGuiaServicoModeloId(guiaServicoModeloId: $guiaServicoModeloId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
          indicacaoModified
          observacaoModified
          profissionalSaudeModified
        }
      }
    }`,
    variables: {
      guiaServicoModeloId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllGuiaServicoModeloAuditByGuiaServicoModeloId) {
    return response.data.data
      .findAllGuiaServicoModeloAuditByGuiaServicoModeloId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllProcedimentoAuditByProcedimentoId = async ({
  procedimentoId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($procedimentoId: UUID, $pageableDTO: PageableDTOInput) {
      findAllProcedimentoAuditByProcedimentoId(procedimentoId: $procedimentoId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
        }
      }
    }`,
    variables: {
      procedimentoId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllProcedimentoAuditByProcedimentoId) {
    return response.data.data.findAllProcedimentoAuditByProcedimentoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllConfiguracaoUnidadeAuditByConfiguracaoUnidadeId = async ({
  configuracaoUnidadeId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($configuracaoUnidadeId: Long, $pageableDTO: PageableDTOInput) {
      findAllConfiguracaoUnidadeAuditByConfiguracaoUnidadeId(configuracaoUnidadeId: $configuracaoUnidadeId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          atendimentoExigeAssinaturaPacienteModified
          azulControleAtivadoModified
          enviaConfirmacaoAntecipaFinalSemanaModified
          enviaConfirmacaoDoisDiasAntesModified
          enviaConfirmacaoDoisDiasAntesSalaModified
          enviaConfirmacaoUmaHoraAntesModified
          enviaConfirmacaoUmaHoraAntesSalaModified
          marcarFaltososAutomaticamenteModified
          notificaValorZeroAzulControleModified
          numeroHorasBloqueioAgendamentoModified
          numeroHorasCancelamentoAutomaticoModified
          utilizaTelefoneInternacionalModified
        }
      }
    }`,
    variables: {
      configuracaoUnidadeId,
      pageableDTO,
    },
  });

  if (
    response.data?.data?.findAllConfiguracaoUnidadeAuditByConfiguracaoUnidadeId
  ) {
    return response.data.data
      .findAllConfiguracaoUnidadeAuditByConfiguracaoUnidadeId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllSalaAuditBySalaId = async ({ salaId, pageableDTO }) => {
  const response = await Api.post("", {
    query: `
    query ($salaId: UUID, $pageableDTO: PageableDTOInput) {
      findAllSalaAuditBySalaId(salaId: $salaId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
        }
      }
    }`,
    variables: {
      salaId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllSalaAuditBySalaId) {
    return response.data.data.findAllSalaAuditBySalaId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllDocumentoModeloAuditByDocumentoModeloId = async ({
  documentoModeloId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($documentoModeloId: Long, $pageableDTO: PageableDTOInput) {
      findAllDocumentoModeloAuditByDocumentoModeloId(documentoModeloId: $documentoModeloId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
          modeloModified
        }
      }
    }`,
    variables: {
      documentoModeloId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllDocumentoModeloAuditByDocumentoModeloId) {
    return response.data.data.findAllDocumentoModeloAuditByDocumentoModeloId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllConfiguracaoImpressaoAuditByConfiguracaoImpressaoId = async ({
  configuracaoUnidadeId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($configuracaoUnidadeId: UUID, $pageableDTO: PageableDTOInput) {
      findAllConfiguracaoImpressaoAuditByConfiguracaoImpressaoId(configuracaoUnidadeId: $configuracaoUnidadeId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          alturaModified
          assinaturaVisivelPosicaoXModified
          assinaturaVisivelPosicaoYModified
          larguraModified
          modeloModified
          retratoModified
          tamanhoPapelModified
          tipoModified
        }
      }
    }`,
    variables: {
      configuracaoUnidadeId,
      pageableDTO,
    },
  });

  if (
    response.data?.data
      ?.findAllConfiguracaoImpressaoAuditByConfiguracaoImpressaoId
  ) {
    return response.data.data
      .findAllConfiguracaoImpressaoAuditByConfiguracaoImpressaoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllProcedimentoAuditByUnidade = async ({ pageableDTO }) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput) {
      findAllProcedimentoAuditByUnidade(pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          ativoModified
          nomeModified
        }
      }
    }`,
    variables: {
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data.findAllProcedimentoAuditByUnidade;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllSalaAuditByUnidade = async ({ pageableDTO }) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput) {
      findAllSalaAuditByUnidade(pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          ativoModified
          nomeModified
        }
      }
    }`,
    variables: {
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data.findAllSalaAuditByUnidade;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllConvenioAuditByUnidade = async ({ pageableDTO }) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput) {
      findAllConvenioAuditByUnidade(pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          ativoModified
          descricaoModified
          ativoModified
          codigoUnidadeModified
          registroAnsModified
          valorConsultaModified
        }
      }
    }`,
    variables: {
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data.findAllConvenioAuditByUnidade;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllAgendamentoTipoAuditByUnidade = async ({ pageableDTO }) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput) {
      findAllAgendamentoTipoAuditByUnidade(pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          ativoModified
          codigoModified
          descricaoModified
        }
      }
    }`,
    variables: {
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data.findAllAgendamentoTipoAuditByUnidade;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllProdutoAuditByUnidade = async ({ pageableDTO }) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput) {
      findAllProdutoAuditByUnidade(pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          ativoModified
          nomeModified
        }
      }
    }`,
    variables: {
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data.findAllProdutoAuditByUnidade;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllSujeitoAtencaoAudit = async ({ pageableDTO }) => {
  const response = await Api.post("", {
    query: `
      query($pageableDTO: PageableDTOInput) {
        findAllSujeitoAtencaoAudit(pageableDTO: $pageableDTO) {
          last
          content {
            revisao {
              dataHoraModificacao
              usuario {
                fotoPerfil
                nome
              }
            }
            revtype
            altura
            alturaModified
            ativo
            ativoModified
            cns
            cnsModified
            comoConheceu
            comoConheceuModified
            documento
            documentoResponsavel
            documentoResponsavelModified
            nomeResponsavel
            nomeResponsavelModified
            documentoModified
            dataNascimento
            dataNascimentoModified
            fotoPerfilModified
            nomeConjuge
            nomeConjugeModified
            nomeMae
            nomeMaeModified
            nome
            nomeModified
            nomePai
            nomePaiModified
            nomeSocial
            nomeSocialModified
            observacao
            observacaoModified
            peso
            pesoModified
            profissionalSaudeModified
            profissionalSaude {
              nome
            }
            quemIndicou
            quemIndicouModified
            religiao
            religiaoModified
            rg
            rgModified
            sujeitoAtencao {
              nome
            }
          }
        }
      }
    `,
    variables: {
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data.findAllSujeitoAtencaoAudit;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllProcedimentoOdontologicoAuditByProcedimentoOdontologicoId = async ({
  procedimentoOdontologicoId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($procedimentoOdontologicoId: UUID, $pageableDTO: PageableDTOInput) {
      findAllProcedimentoOdontologicoAuditByProcedimentoOdontologicoId(procedimentoOdontologicoId: $procedimentoOdontologicoId, pageableDTO: $pageableDTO) {
        last
        content {
          ativoModified
          denteModified
          mucosaOralModified
          observacaoModified
          agendamentoModified
          procedimentoModified
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
        }
      }
    }    
    `,
    variables: {
      procedimentoOdontologicoId,
      pageableDTO,
    },
  });

  if (response?.data?.data) {
    return response.data.data
      .findAllProcedimentoOdontologicoAuditByProcedimentoOdontologicoId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllControleSessaoAuditBySujeitoAtencaoId = async ({ sujeitoAtencaoId, pageableDTO }) => {
  const response = await Api.post('', {
    query: `
      query($sujeitoAtencaoId: UUID, $pageableDTO: PageableDTOInput) {
        findAllControleSessaoAuditBySujeitoAtencaoId(sujeitoAtencaoId: $sujeitoAtencaoId, pageableDTO: $pageableDTO) {
          last
          content {
            revisao {
              dataHoraModificacao
              usuario {
                fotoPerfil
                nome
              }
            }
            revtype
            ativoModified
            observacaoModified
            nomeSessaoModified
            sujeitoAtencaoModified
            }
          }
        }
        `,
    variables: {
      sujeitoAtencaoId,
      pageableDTO
    }
  });
  if (response?.data?.data) {
    return response.data.data.findAllControleSessaoAuditBySujeitoAtencaoId;
  } else {
    throw (new Error(response.data.errors[0]))
  }
}

export const findAllControleSessaoProcedimentoAuditControleSessaoId = async ({ controleSessaoId, pageableDTO }) => {
  const response = await Api.post('', {
    query: `
      query($controleSessaoId: UUID, $pageableDTO: PageableDTOInput) {
        findAllControleSessaoProcedimentoAuditControleSessaoId(controleSessaoId: $controleSessaoId, pageableDTO: $pageableDTO) {
          last
          content {
            revisao {
              dataHoraModificacao
              usuario {
                fotoPerfil
                nome
              }
            }
            revtype
            ativoModified
            agendamentoModified
            procedimentoModified
            salaModified
            controleSessaoModified
        }
      }
    }
      `,
    variables: {
      controleSessaoId,
      pageableDTO
    }
  });
  if (response?.data?.data) {
    return response.data.data.findAllControleSessaoProcedimentoAuditControleSessaoId;
  } else {
    throw (new Error(response.data.errors[0]))
  }
};

export const findAllControleSessaoAgendamentoTipoAuditByControleSessaoId = async ({ controleSessaoId, pageableDTO }) => {
  const response = await Api.post('', {
    query: `
      query($controleSessaoId: UUID, $pageableDTO: PageableDTOInput) {
        findAllControleSessaoAgendamentoTipoAuditByControleSessaoId(controleSessaoId: $controleSessaoId, pageableDTO: $pageableDTO) {
          last
          content {
            revisao {
              dataHoraModificacao
              usuario {
                fotoPerfil
                nome
              }
            }
            revtype
            ativoModified
            agendamentoModified
            agendamentoTipoModified
            salaModified
            controleSessaoModified
        }
      }
    }
      `,
    variables: {
      controleSessaoId,
      pageableDTO
    }
  });
  if (response?.data?.data) {
    return response.data.data.findAllControleSessaoAgendamentoTipoAuditByControleSessaoId;
  } else {
    throw (new Error(response.data.errors[0]))
  }
};

export const findAllCategoriaFinanceiraAuditByUnidade = async ({ pageableDTO }) => {
  const response = await Api.post('', {
    query: `
      query ($pageableDTO: PageableDTOInput) {
        findAllCategoriaFinanceiraAuditByUnidade(pageableDTO: $pageableDTO) {
          last
          content {
            ativoModified
            nomeModified
            tipoModified
            tipo
            ativo
            revtype
            nome
            categoriaFinanceira {
              nome
            }
            revisao {
              dataHoraModificacao
              usuario {
                nome
                fotoPerfil
              }
            }
          }
        }
      }
    `,
    variables: {
      pageableDTO
    }
  });

  if (response?.data?.data) {
    return response.data.data.findAllCategoriaFinanceiraAuditByUnidade;
  } else {
    throw (new Error(response.data.errors[0]))
  }
};

export const findAllContaBancariaAuditByUnidade = async ({ pageableDTO }) => {
  const response = await Api.post('', {
    query: `
      query ($pageableDTO: PageableDTOInput) {
        findAllContaBancariaAuditByUnidade(pageableDTO: $pageableDTO) {
          last
          content {
            ativoModified
            nomeModified
            saldoAtualModified
            saldoInicialModified
            banco {
              nome
            }
            bancoModified
            ativo
            revtype
            nome
            saldoAtual
            saldoInicial
            contaBancaria {
              nome
            }
            revisao {
              dataHoraModificacao
              usuario {
                nome
                fotoPerfil
              }
            }
          }
        }
      }
    `,
    variables: {
      pageableDTO
    }
  });

  if (response?.data?.data) {
    return response.data.data.findAllContaBancariaAuditByUnidade;
  } else {
    throw (new Error(response.data.errors[0]))
  }
};

export const findAllGuiaInternacaoModeloAudit = async ({ pageableDTO }) => {
  const response = await Api.post('', {
    query: `
    query($pageableDTO: PageableDTOInput) {
      findAllGuiaInternacaoModeloAudit(pageableDTO: $pageableDTO) {
        content {
          ativo
          ativoModified
          hasOPME
          hasOPMEModified
          indicacao
          indicacaoModified
          nome
          nomeModified
          observacao
          observacaoModified
          profissionalSaude {
            nome
          }
          profissionalSaudeModified
          quimioterapico
          quimioterapicoModified
          revisao {
            usuario {
              nome
              fotoPerfil
            }
            clientId
            dataHoraModificacao
            id
          }
          revtype
        }
        last
      }
    }
    `,
    variables: {
      pageableDTO
    }
  });

  if (response?.data?.errors) {
    throw new Error(response.data.errors[0]);
  }

  return response.data.data.findAllGuiaInternacaoModeloAudit;
}