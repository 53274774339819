const styles = {
  content: {
    background: "#F9F9F9",
    height: "calc(100% - 102px)",
  },
  header: {
    padding: "0 32px 16px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    height: "40px",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
  },
  labelCheck: {
    color: "#868686",
    fontSize: "14px",
  },
  checkboxLabel: {
    margin: "12px 0 20px",
    "& svg": {
      fontSize: "14px",
    },
  },
  hiddenLocal: {
    margin: "12px 0 0 0",
  },
  contentLocal: {
    display: "flex",
    flexDirection: "column",
  },
  selectLocal: {
    background: "#F2F2F2",
    color: "#505050",
    borderRadius: "8px",
    height: "32px",
    padding: "4px 8px",
    justifyContent: "center",

    "& > div": {
      "& > p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  },
  listaLocalSelecionado: {
    minWidth: "auto",
    padding: "1px 0 1px 4px",
  },
  titleProcedimentos: {
    display: "flex",
    flexDirection: "row",
  },
  contentProcedimentos: {
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
    borderRadius: "10px",
    margin: "16px 32px",
    padding: "16px",
    height: "calc(100% - 356px)",
  },
  contentProcedimentosHiddenLocal: {
    height: "calc(100% - 244px)",
  },
  scroll: {
    height: "95%",
  },
  contentButtonAdicionar: {
    margin: "16px 32px 30px",
    display: "flex",
    justifyContent: "end",

    "& button": {
      width: "205px",
    },
  },
  contentLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  contentTitle: {
    display: "grid",
    gridTemplateColumns: "70% 30%",
  },
  listaLocal: {
    height: "34px",
  },
  inputSearchProcedimento: {
    maxWidth: "100%",
  },
};

export default styles;
