import React from "react";
import { withStyles, IconButton } from "@material-ui/core";
import SearchIcon from "../../../../../../components/Icon/Search";
import ItemGrid from "../../../../../../components/Modal/ItemGrid";
import { InputForm } from "../../../../../../components/Modal/Input";
import Button from "../../../../../../components/Button/Button";

const Filter = ({
  classes,
  onChange,
  filter,
  handleApplyFilter,
  isLoading,
  dadosFiltrados,
  textButton,
  text,
  functionButton
}) => {
  const handleKeyPress = () => {
    handleApplyFilter();
  };

  return (
    <div className={classes.filterContainer}>
     
      <div style={{ flex: 1 }}>
        <ItemGrid
          label="Pesquise por documento"
          classes={{
            item: classes.item,
          }}
        >
          <InputForm
            placeholder="Pesquisar"
            className={classes.inputTextField}
            onChange={(e) => onChange(e.target.value, "search")}
            onKeyDown={(e) => e.key === "Enter" && handleKeyPress()}
            value={filter.search}
            startAdornment={
              <IconButton
                className={classes.searchButton}
                onClick={() => handleApplyFilter()}
                disabled={isLoading}
              >
                <SearchIcon />
              </IconButton>
            }
          />
        </ItemGrid>
        {dadosFiltrados &&
        <div className={classes.documentosFiltrados}
        ><span>{text}</span>
          <div className={classes.contentButton}>
            <Button
              onClick={functionButton}
              className={classes.button}
            >
              {textButton}
            </Button>
          </div>
        </div>} 
      </div>
    </div>
  );
};

const styles = {
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "16px",
    alignItems: "center",
    gap: "16px",
  },
  item: {
    padding: "0 !important",
  },
  inputTextField: {
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    width: "calc( 100% - 16px )",
    height: "32px",
    padding: "4px 8px",
    marginBottom:"10px",
  },
  searchButton: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  filterButton: {
    height: "37px",
    alignSelf: "flex-end",
  },
  button:{
    width:"130px",
    borderRadius:"30px",
    textWrap:"nowrap",
    fontSize:"13px",
    height:"32px",
    background:"#FBBF6D" },
    contentButton:{
    paddingLeft:"15px"
  },
  documentosFiltrados:{
    display:"flex",
    justifyContent:"space-between",
    color:"#505050",
    fontWeight:"600",
    alignItems:"center"
  },
};

export default withStyles(styles)(Filter);
