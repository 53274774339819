import React from 'react'
import createSelectable from './CreateSelectable'
import {withStyles} from "@material-ui/core/styles/index";


const styles = (theme) => ({

    root: {
        display: 'flex',
        cursor: 'pointer',
    },

    item: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        padding: '10px 0',
        color: '#505050',
        fontWeight: 500
    },

    selected: {
        backgroundColor: 'rgba(33, 154, 151, 0.8)',
        '&>span': {
            color: '#FFFFFF'
        }
    },

    notSelected: {
        '&:nth-child(odd) span': {
            backgroundColor: '#BDBDBD',
        },

        '&:nth-child(even) span': {
            backgroundColor: '#F2F2F2'
        }
    },

    noBorder: {
        borderRight: 0
    }

});


const HourSelectable = withStyles(styles)(class Item extends React.Component {

    render() {
        const {selectableRef, selected, selecting, item, classes, lastCol} = this.props;

        const className = [
            classes.root,
            (!selected && classes.notSelected) || '',
            (lastCol && classes.noBorder) || '',
            (selected && classes.selected) || '',
            (selecting && classes.selected) || ''
        ].join(' ');

        return (
            <div className={className}
                 ref={selectableRef}>
                <span className={classes.item}>{item.value}</span>
            </div>
        );
    }
});

export default createSelectable(HourSelectable)