import React from "react";
import { ROLE_PROTECTION_CONDITIONS } from "./constants";

import { withHasAuthority } from "./hocs";

export * from "./constants";
export { LockedFallback } from "./components";
export { withRoleProtection } from "./hocs";
export { checkUserRoles } from "./utils";

export const RoleProtection = withHasAuthority(
  ({
    children,
    fallback: FallBack,
    hasAuthority,
    awaysReturnChildren,
    ...rest
  }) => {
    if (awaysReturnChildren) {
      if (typeof children === "function") {
        const Children = children;
        return <Children {...rest} hasAuthority={hasAuthority} />;
      }

      return React.cloneElement(children, { ...rest, hasAuthority });
    }

    if (hasAuthority) return children;

    const fallbackComponent =
      typeof FallBack === "function" ? (
        <FallBack {...rest}>{children}</FallBack>
      ) : (
        FallBack
      );

    return fallbackComponent || null;
  }
);

RoleProtection.defaultProps = {
  condition: ROLE_PROTECTION_CONDITIONS.ALL,
};
