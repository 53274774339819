import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const CirclePrevIcon = props => {
  return (
    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...normalizeProps(props)}>
      <g filter="url(#filter0_dd_9110_189739)">
        <rect x="58.422" y="8" width="42.4219" height="42.4219" rx="21.211" transform="rotate(90 58.422 8)" fill="currentColor" />
        <path d="M39.9958 36.6533C39.5996 37.0307 38.9724 37.0155 38.595 36.6192L31.9877 29.6829C31.6232 29.3002 31.6232 28.6988 31.9877 28.3161L38.595 21.3797C38.9724 20.9835 39.5996 20.9682 39.9958 21.3457C40.392 21.7231 40.4073 22.3503 40.0299 22.7465L34.0736 28.9995L40.0299 35.2525C40.4073 35.6487 40.392 36.2758 39.9958 36.6533Z" fill="#7C7C7C" />
        <rect x="57.922" y="8.5" width="41.4219" height="41.4219" rx="20.711" transform="rotate(90 57.922 8.5)" stroke="black" stroke-opacity="0.1" />
      </g>
      <defs>
        <filter id="filter0_dd_9110_189739" x="0" y="0" width="74.422" height="74.4219" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9110_189739" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9110_189739" result="effect2_dropShadow_9110_189739" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9110_189739" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default CirclePrevIcon;
