import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const CircleCloseIcon = props => {
  return (
    <svg 
      width="32" 
      height="32" 
      viewBox="0 0 32 32" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M0.25 16C0.25 7.30152 7.30152 0.25 16 0.25C24.6985 0.25 31.75 7.30152 31.75 16C31.75 24.6985 24.6985 31.75 16 31.75C7.30152 31.75 0.25 24.6985 0.25 16ZM22.4205 9.5795C21.9812 9.14017 21.2688 9.14017 20.8295 9.5795L16 14.409L11.1705 9.5795C10.7312 9.14017 10.0188 9.14017 9.57951 9.5795C9.14017 10.0188 9.14017 10.7312 9.57951 11.1705L14.409 16L9.5795 20.8295C9.14017 21.2688 9.14017 21.9812 9.5795 22.4205C10.0188 22.8598 10.7312 22.8598 11.1705 22.4205L16 17.591L20.8295 22.4205C21.2688 22.8598 21.9812 22.8598 22.4205 22.4205C22.8598 21.9812 22.8598 21.2688 22.4205 20.8295L17.591 16L22.4205 11.1705C22.8598 10.7312 22.8598 10.0188 22.4205 9.5795Z" fill="currentColor"/>
    </svg>
  );
};

export default CircleCloseIcon;