import { findProfissionalSaudeByUnidadeComAgenda } from '../../../../../services/ProfissionalSaudeService';

export const getProfissionaisByUnidade = async () => {
  try {
    const response = await findProfissionalSaudeByUnidadeComAgenda();

    if (response?.data?.error) {
      throw Error(response.data.error);
    }
    
    return response?.data?.data?.findProfissionalSaudeByUnidadeComAgenda || [];
  } catch(error) {
    throw Error(error);
  }
}

export const getUnidades = () => {
  const redes = JSON.parse(localStorage.getItem("_immortal|redes"));
  const unidades = redes?.flatMap(rede => rede.unidades) || [];
  return unidades;
}
