import controleSessaoBanner from '../../assets/img/login-banners/controle_sessao_banner.png';
import repasseBanner from '../../assets/img/login-banners/repasse_banner.png';
import indicacaoBanner from '../../assets/img/login-banners/indicacao_banner.png';

export const loginBannersFullSize = [
  {
    src: controleSessaoBanner,
    alt: 'Propaganda do controle de Sessão.',
    isClickable: true,
    redirectURL: 'https://wa.me/554499763397?text=Ol%C3%A1!%20Tenho%20interesse%20em%20saber%20mais%20sobre%20o%20Controle%20de%20Sess%C3%B5es'
  },
  {
    src: repasseBanner,
    alt: 'Propaganda da funcionalidade de Repasse.',
    isClickable: true,
    redirectURL: 'https://wa.me/554499763397?text=Ol%C3%A1!%20Tenho%20interesse%20em%20saber%20mais%20sobre%20o%20Controle%20de%20Repasses'
  },
  {
    src: indicacaoBanner,
    alt: 'Programa de indicação.',
    isClickable: true,
    redirectURL: 'https://api.whatsapp.com/send?phone=5544991152683&text=Ol%C3%A1!%20Gostaria%20de%20participar%20do%20programa%20de%20indica%C3%A7%C3%A3o.'
  },
];
