import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Document, Page } from 'react-pdf'

import CloseIcon from '../Icon/CloseIcon'
import { Button } from '../ui/Buttons'
import string from '../../utils/string'

const PreviewFile = ({ item, classes, handleClose, isProntuario, urlDownload }) => {
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages)
  }

  const renderContent = () => {
    const imagesTypes = ['jpg', 'jpeg', 'png', 'gif']
    const isPdf =
      item?.arquivoAdaptado?.extensao === 'pdf' ||
      item?.entradaProntuarioAmazonS3Objeto?.extensao === 'pdf'
    const isImage =
      !!item?.arquivoAdaptado?.image ||
      imagesTypes.includes(item?.entradaProntuarioAmazonS3Objeto?.extensao)
    if (isImage) {
      const url = `data:image/${item?.arquivoAdaptado?.extensao};base64,${item?.arquivoAdaptado?.base64}`
      const currentUrl = isProntuario ? urlDownload : url
      return <img src={currentUrl} alt={item?.arquivoAdaptado?.nome || 'Preview Image'} />
    } else if (isPdf) {
      const url = `data:application/pdf;base64,${item?.arquivoAdaptado?.base64}`
      const currentUrl = isProntuario ? urlDownload : url
      return (
        <Document
          file={currentUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode='canvas'
          className={classes.document}
          options={{}}
        >
          {Array.from({ length: numPages }, (_, index) => (
            <Page
              key={`page_${index + 1}`}
              scale={0.5}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              className={classes.page}
            />
          ))}
        </Document>
      )
    } else {
      return (
        <div>
          Não foi possível exibir o documento. <br />
        </div>
      )
    }
  }

  return (
    <>
      <div className={classes.wrapperHeader}>
        <span className={classes.titulo}>
          {string.truncate(item?.arquivoAdaptado?.nome || item?.entradaProntuarioAmazonS3Objeto?.nome, 30)}
        </span>
        <Button onClick={handleClose} shape='circle' bgColor='#FB7676'>
          <CloseIcon />
        </Button>
      </div>
      <div className={classes.wrapperPreview}>{renderContent()}</div>
    </>
  )
}

const styles = {
  wrapperPreview: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: '100%',
    },
  },
  document: {
    width: '100%',
  },
  page: {
    maxWidth: '100%',
    overflowY: 'hidden',
    marginTop: '10px',
    overflowX: 'auto',
  },
  wrapperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  titulo: {
    color: '#505050',
    fontSize: '12px',
  },
}

export default withStyles(styles)(PreviewFile)
