import moment from "moment";

const dateFormatRegex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;

export const checkValidDate = (date) => {
  const split = date.split(' à ');
  return dateFormatRegex.test(split[0]) && dateFormatRegex.test(split[1]);
}

export const startDayIsBeforeEndDay = (date = null, start = null, end = null) => {
  if (date) {
    const split = date.split(' à ');
    return moment(split[0], 'DD/MM/YYYY').isSameOrBefore(moment(split[1], 'DD/MM/YYYY'), 'day');
  }

  if (start && end) {
    return moment(start, 'DD/MM/YYYY').isSameOrBefore(moment(end, 'DD/MM/YYYY'), 'day');
  }
};

export const getDatesFromString = (value) => {
  const splited = value.split(` à `);
  return { 
    dataInicial: moment(splited[0], 'DD/MM/YYYY').toDate(),
    dataFinal: moment(splited[1], 'DD/MM/YYYY').toDate(),  
  }
}
