import React, { useState } from 'react';
import * as Styles from './styles';
import PictureIcon from '../../Icon/PictureIcon';

const MAX_IMAGES = 4;

const ImagePreview = ({ urls, onClick, containerProps, imageProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingByIndexes, setIsLoadingByIndexes] = useState(urls ? urls?.map(() => true) : []);
  const [errorIndexes, setErrorIndexes] = useState(urls ? urls?.map(() => false) : []);

  const handleImageError = (index) => {
    const newErrorIndexes = [...errorIndexes];
    newErrorIndexes[index] = true;
    setErrorIndexes(newErrorIndexes);
  };

  const handleLoad = (index) => {
    const newIsLoadingByIndexes = [...isLoadingByIndexes];
    newIsLoadingByIndexes[index] = false;
    setIsLoadingByIndexes(newIsLoadingByIndexes);

    if (!newIsLoadingByIndexes.slice(0, MAX_IMAGES).includes(true)) {
      setIsLoading(false);
    }
  }

  const getGridStyles = (index) => {
    const { length: urlsLength } = urls;

    const twoImages = urlsLength === MAX_IMAGES - 2;
    const threeImagesAndNotThirdImage = urlsLength === MAX_IMAGES - 1 && index !== 2;
    const fourOrMoreImages = urlsLength >= MAX_IMAGES;
    return {
      ...((twoImages || threeImagesAndNotThirdImage || fourOrMoreImages) && { width: 'calc(50% - 4px)' }),
      ...((urlsLength >= 3) && { height: 'calc(50% - 4px)' }),
    }
  };
  
  const withBlur = urls?.length > MAX_IMAGES;
  const firstFourUrls = urls?.slice(0, MAX_IMAGES);

  return (
    <Styles.Container withBlur={withBlur} onClick={onClick} {...containerProps}>
      {withBlur && !isLoading && 
        <Styles.Overlay>
          {urls?.length} Imagens
        </Styles.Overlay>
      }
      {firstFourUrls?.map((url, index) => (
        <React.Fragment key={index}>
          {!errorIndexes[index] ? (
            <Styles.Image 
                src={url} 
                onError={() => handleImageError(index)} 
                onLoad={() => handleLoad(index)}
                alt={`Imagem ${index + 1}`}
                {...imageProps}
                {...getGridStyles(index)}
                withBlur={withBlur}
                display={errorIndexes[index] ? 'none' : 'block'}
            />
          ) : (
            <Styles.IconContainer {...getGridStyles(index)}>
              <PictureIcon color="#FFFFFF" size={80} />
            </Styles.IconContainer>
          )
        }
        </React.Fragment>
      ))}
    </Styles.Container>
  );
};

export default ImagePreview;
