import React from "react";
import debounce from "lodash.debounce";
import { inject, observer } from "mobx-react/index";
import { withStyles } from "@material-ui/core/styles/index";
import { 
  Grid,
  Paper,
  List
} from "@material-ui/core";

import Colors from "../../template/Colors";
import string from "../../utils/string";
import {applyPhoneMask, applyInternationalPhoneMask} from "../../utils/PhoneMask";
import Scroll from "../../components/InfiniteScroll/Scroll";
import ItemList from "../../components/Modal/ItemList";
import HeaderClose from "../../components/Header/HeaderClose";
import moment from "moment";
import { verificarAgendamentoRetorno } from "../../services/AgendamentoService";


const styles = ({
  gridFlexExpanded: {
    padding: '0px 28px 0px 26px',
    marginLeft: "0px",
    flex: 8,
    backgroundColor: Colors.commons.padrao
  },
  paperListTitle: {
    marginTop: '20px',
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: 700,
    color: Colors.commons.white,
    display: 'flex',
    justifyContent: 'space-between',
  },
  paperList: {
    maxHeight: '535px',
    marginBottom: 20,
    overflow: "auto",
    boxShadow: "none",
    backgroundColor: Colors.commons.padrao
  },
  listPadding: {
    paddingRight: "4px"
  },
  listItemMargin: {
    marginBottom: "5px",
  },
  listItem: {
    border: "1px solid " + Colors.commons.gray3,
    borderRadius: "15px",
    padding: "8px 0px 0px 8px",
    marginBottom: "10px",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    height: '98px'
  },
  listItemSelected: {
    border: "1px solid " + Colors.primary.main,
    borderRadius: "15px",
    padding: "10px",
    marginBottom: "10px",
    cursor: "pointer"
  },
  colorItemTitle:{
    color: Colors.commons.gray7
  },
  colorItemValue:{
    color: Colors.commons.gray9,
    borderBottom: 'none',
    fontWeight: 500,
    height: 21
  }
});

@inject("atendimentoStore", "unidadeStore", "sujeitoAtencaoStore", "filtroHeaderStore")
@observer
class AtendimentoModalSujeitoList extends React.Component {
  constructor(...args) {
    super(...args);

    this.debounceOnChange = debounce(this.debounceOnChange, 500);
  }

  loadMoreSujeitosAtencao = () => {
    const { atendimentoStore } = this.props;
    
    if (atendimentoStore.sujeitosAtencaoLoading) {
      return;
    }
    
    if (atendimentoStore.sujeitosAtencao && atendimentoStore.sujeitosAtencao.length > 0) {
      atendimentoStore.sujeitosAtencaoCurrentPage += 1;
    } else {
      atendimentoStore.sujeitosAtencaoCurrentPage = 0;
    }
    
    atendimentoStore.loadSujeitosAtencao({ pageNumber: atendimentoStore.sujeitosAtencaoCurrentPage });
  };

  onChange() {
    this.props.atendimentoStore.loadSujeitosAtencao();
  }

  debounceOnChange() {
    this.onChange();
  }

  async selectSujeito(sujeitoAtencao) {
    const { atendimentoStore, sujeitoAtencaoStore, filtroHeaderStore } = this.props;
    const {profissionalSaude} = filtroHeaderStore;
    const { utilizaAgendamentoRetorno, id } = profissionalSaude || {};
    if (atendimentoStore.objView?.paciente?.id && atendimentoStore?.waitingSelectSujeitoToLink) {
      sujeitoAtencaoStore.reset();
      const objViewFromAgendamentoToSujeito = this.getObjViewFromAgendamento(atendimentoStore.objView);
      sujeitoAtencaoStore.sujeitoObjViewFromAgendamento = objViewFromAgendamentoToSujeito;
      sujeitoAtencaoStore.willLinkViziHealthPaciente = true;
      sujeitoAtencaoStore.screenToBack = this.props.history.location.pathname;
      window.location.replace(`#/sujeito-atencao/edit/${sujeitoAtencao.id}`);
    };
    if(utilizaAgendamentoRetorno && id && sujeitoAtencao?.id) {
      const agendamentoRetornoData = await verificarAgendamentoRetorno({
        profissionalSaudeId: profissionalSaude?.id,
        sujeitoAtencaoId: sujeitoAtencao?.id,
        dataAgendamento: moment(atendimentoStore.objView.data).format('YYYY-MM-DD')
      })
      this.props.atendimentoStore.agendamentoRetornoData = agendamentoRetornoData?.data;
    };

    this.props.atendimentoStore.selectSujeitoAtencao(sujeitoAtencao);
  }

  getObjViewFromAgendamento = (objView) => {
    const {
      nome,
      documento,
      paciente,
      email,
      fotoPerfil,
      convenio,
      numeroCarteira,
      validadeCarteira,
      telefone,
      telefoneDDI
    } = objView;

    return {
      nome: nome,
      documento: documento,
      dataNascimento: moment(paciente?.dataNascimento).format('YYYY-MM-DD') || null,
      email: email || null,
      fotoPerfil: fotoPerfil || null,
      dadosConvenio: {
        convenio: convenio || null,
        numeroCarteira: numeroCarteira || null,
        validadeCarteira: validadeCarteira || null
      },
      paciente,
      telefonePrincipal: telefone || null,
      telefonePrincipalDDI: telefoneDDI || '55'
    }
  }

  formataDocumento = (documento, documentoTipo) => {
    if(!documento) {
      return ' - '
    };

    if(documentoTipo?.descricao === "CPF") {
      return string.format("###.###.###-##", documento);
    };

    return documento;
  }

  render() {
    const { classes, atendimentoStore, unidadeStore, onClose } = this.props;
    return (
      <Grid
				container
				direction="column"
				wrap="nowrap"
				className={classes.gridFlexExpanded}
			>
        <HeaderClose title="Paciente:" onClose={onClose}/>
				<Paper className={classes.paperList}>
					<Scroll
						loadMore={this.loadMoreSujeitosAtencao}
						hasMore={!atendimentoStore.sujeitosAtencaoLastContent}
						pageStart={0}
						className={classes.scrollContainer}
					>
						<List className={classes.listPadding}>
							{atendimentoStore.sujeitosAtencao.map(
								(sujeitoAtencao, i) => (
									<Grid
										container
										direction={"column"}
										wrap={"nowrap"}
										key={i}
										onClick={() => this.selectSujeito(sujeitoAtencao)}
										className={
											atendimentoStore.objView.sujeitoAtencao &&
											atendimentoStore.objView.sujeitoAtencao.id ===
												sujeitoAtencao.id
												? classes.listItemSelected
												: classes.listItem
										}
									>
										<Grid container>
											<ItemList
												size={9}
												field={"Nome:"}
												value={sujeitoAtencao.nome}
												classes={{ 
                          listItem: classes.listItemMargin,
                          itemTitle: classes.colorItemTitle,
                          itemValueSub: classes.colorItemValue,
                        }}
											/>

                      <ItemList
												size={3}
												field={"Idade:"}
												value={sujeitoAtencao.idade === " " ? " - " : sujeitoAtencao.idade}
												classes={{ 
                          listItem: classes.listItemMargin,
                          itemTitle: classes.colorItemTitle,
                          itemValueSub: classes.colorItemValue,
                        }}
											/>

                      <ItemList
												size={6}
												field={"Telefone:"}
												value={
                          unidadeStore.unidade.utilizaTelefoneInternacional ? 
                            applyInternationalPhoneMask(sujeitoAtencao?.contato?.telefonePrincipal, sujeitoAtencao?.contato?.telefonePrincipalDDI) :
                            applyPhoneMask(sujeitoAtencao?.contato?.telefonePrincipal)
                        }
												classes={{ 
                          listItem: classes.listItemMargin,
                          itemTitle: classes.colorItemTitle,
                          itemValueSub: classes.colorItemValue,
                        }}
											/>
											<ItemList
												size={6}
												field={"Documento:"}
                        value={this.formataDocumento(sujeitoAtencao?.documento, sujeitoAtencao?.documentoTipo)}
												classes={{ 
                          listItem: classes.listItemMargin,
                          itemTitle: classes.colorItemTitle,
                          itemValueSub: classes.colorItemValue,
                        }}
											/>
										</Grid>
									</Grid>
								)
							)}
						</List>
					</Scroll>
				</Paper>
			</Grid>
    );
  }
}

export default withStyles(styles)(AtendimentoModalSujeitoList);
