import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ${({ blur }) => blur && 'filter: blur(5px);'}
  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ spacing }) => spacing && `gap: ${spacing}px;`}
  ${({ margin }) => margin && `margin: ${margin}px;`}
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;

export const Overlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 48px;
  width: 180px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center; 
  color: #fff;
  font-weight: 600;
  margin: 0 !important;
  z-index: 1;
`;

export const Image = styled.img`
  ${({ display }) => display && `display: ${display};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({ height }) => height && `height: ${height};`}
  ${({ width }) => width ? `width: ${width};` : `width: 100%;`};
  ${({ withBlur }) => withBlur && 'filter: blur(5px);'}
  z-index: 0;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ height }) => height && `height: ${height};`}
  ${({ width }) => width ? `width: ${width};` : `width: 100%;`};
`;
