import moment from "moment";

const primeiroDiaMesAtual = moment()
  .startOf("month")
  .format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment()
  .endOf("month")
  .format("YYYY-MM-DD");

export const columns = [
  {
    Header: "Procedimento",
    align: "left",
    getValue: (data) => {
      return data.procedimento;
    },
    field: "procedimento",
  },
  {
    Header: "Quantidade",
    align: "left",
    getValue: (data) => {
      return data.quantidade;
    },
  },
];

export const filtersDefault = {
  dataInicio: primeiroDiaMesAtual,
  dataFim: ultimoDiaMesAtual,
  profissionalSaude: null,
  faturado: false,
  convenioId: null,
  procedimentoId: null,
};

export const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortField: "procedimento",
  sortDir: "ASC",
};
