const styles = {
  paper: {
    width: "660px",
    height: "100%",
    maxHeight: "366px",
  },
  header: {
    padding: "40px 32px 20px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    height: "100%",
    padding: "16px",
    gap: "16px",
    overflow: "auto",
    maxHeight: "278px",
  },
};

export default styles;
