import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const ArrowDownloadIcon = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M17.2443 18.4972C17.6586 18.4972 17.9943 18.833 17.9943 19.2472C17.9943 19.6269 17.7122 19.9407 17.3461 19.9904L17.2443 19.9972H6.74969C6.33548 19.9972 5.99969 19.6614 5.99969 19.2472C5.99969 18.8675 6.28185 18.5537 6.64792 18.5041L6.74969 18.4972H17.2443ZM12.0033 3.99902C12.383 3.99902 12.6968 4.28118 12.7465 4.64725L12.7533 4.74902L12.7527 14.942L15.7189 11.9714C15.9849 11.7049 16.4016 11.6803 16.6954 11.8979L16.7796 11.9705C17.0461 12.2365 17.0707 12.6531 16.8531 12.9469L16.7806 13.0311L12.5377 17.2818L12.4671 17.3443L12.3761 17.4029L12.3397 17.424L12.2485 17.462L12.128 17.4921L12.0588 17.5L11.9987 17.5019C11.9486 17.5019 11.8977 17.4964 11.848 17.4859L11.7671 17.4627C11.6711 17.4317 11.584 17.3815 11.5098 17.3167L7.22025 13.032C6.92715 12.7393 6.92682 12.2644 7.2195 11.9713C7.48558 11.7049 7.90222 11.6804 8.19599 11.898L8.28016 11.9706L11.2527 14.938L11.2533 4.74902C11.2533 4.33481 11.5891 3.99902 12.0033 3.99902Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default ArrowDownloadIcon
