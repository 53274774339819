import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ArrowUpIcon = props => {
  return (
    <svg  
      width="12" 
      height="7" 
      viewBox="0 0 12 7" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M0.600459 0.457123C0.917543 0.115648 1.45141 0.0958755 1.79288 0.412959L6 4.37984L10.2071 0.412959C10.5486 0.0958755 11.0825 0.115648 11.3995 0.457123C11.7166 0.798598 11.6969 1.33246 11.3554 1.64955L6.57413 6.14955C6.2504 6.45016 5.7496 6.45016 5.42587 6.14955L0.644623 1.64955C0.303148 1.33246 0.283376 0.798598 0.600459 0.457123Z" fill="currentColor"/>
    </svg>
  );
};

export default ArrowUpIcon;