import React from "react";
import { withStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { Grid } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputSelectComLabel from "../../../../components/Input/InputSelectComLabel";
import CardModeloAnamnese from "./CardModeloAnamnese";
import styles from "./ModeloAnamneseStyle";

const InformacoesModeloAnamnese = observer((props) => {
  const {
    classes,
    isAdicionarOutro,
    setDadosAnamnese,
    dadosAnamnese
  } = props;

  const { nomeModelo, listaPerguntas } = dadosAnamnese;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleChangeNomeModelo = (e) => {
    let { value } = e.target;
    setDadosAnamnese({
        ...dadosAnamnese,
        nomeModelo: value.substring(0, 50)
    })
  };

  const criarRespostaPadrao = async (posicaoLista) => {
    dadosAnamnese.listaPerguntas[posicaoLista] = {
        ...listaPerguntas[posicaoLista],
        respostas: [{ nome: "" }, { nome: "" }],
      };
  };

  const handleChangePerguntaTipoCampo = (event, field, posicaoLista) => {
    let valorInformadoCampo = null;
    if (field === "tipo") {
      valorInformadoCampo = event;

      if (
        valorInformadoCampo.value === "COMBOBOX" ||
        valorInformadoCampo.value === "RADIO" ||
        valorInformadoCampo.value === "CHECKBOX"
      ) {
        listaPerguntas[posicaoLista].respostas.length === 0 &&
          criarRespostaPadrao(posicaoLista);
      } else {
        listaPerguntas[posicaoLista].respostas = []
      }
    } else {
      valorInformadoCampo = event.target.value;
    }
    const novoElemento = {
      ...listaPerguntas[posicaoLista],
      [field]: valorInformadoCampo,
    };
    const novaLista = [
      ...listaPerguntas.slice(0, posicaoLista),
      novoElemento,
      ...listaPerguntas.slice(posicaoLista + 1, listaPerguntas.length),
    ];

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: novaLista
    });
  };

  const handleChangeResposta = (index, event, posicaoResposta) => {
    const value = event.target.value;

    let perguntas = listaPerguntas;
    perguntas[index].respostas[posicaoResposta].nome = value;

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: [...perguntas]
    });
  };

  const verificaPossuiMinimoDeResposta = (listaRespostasDaPerguntaSelecionada, tipo) => {
    const validaMinimoRespostaTipoRadio = !(listaRespostasDaPerguntaSelecionada.length < 3 && tipo === "RADIO");
    const validaMinimoRespostaTipoComboboxCheckbox = !(listaRespostasDaPerguntaSelecionada.length === 1 && (tipo === "CHECKBOX" || tipo === "COMBOBOX"));

    return tipo === "RADIO" ? validaMinimoRespostaTipoRadio : validaMinimoRespostaTipoComboboxCheckbox
  }

  const excluirResposta = (index, posicaoResposta) => {
    const listaRespostasDaPerguntaSelecionada = listaPerguntas[index].respostas;

    verificaPossuiMinimoDeResposta(listaRespostasDaPerguntaSelecionada, listaPerguntas[index].tipo.value) &&
    listaRespostasDaPerguntaSelecionada.splice(posicaoResposta, 1);
    listaPerguntas[index] = {
      ...listaPerguntas[index],
      respostas: listaRespostasDaPerguntaSelecionada,
    };

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: [...listaPerguntas]
    });
  };

  const verificaOutro = (perguntas) => {
    return perguntas.respostas.some(item => item.isOutro)
  };

  const colocaOutroParaFinalDaLista = (perguntaSelecionada, respostaPadrao) => {
    const tamanhoListaResposta = perguntaSelecionada.respostas.length;
    perguntaSelecionada.respostas.splice(tamanhoListaResposta-1, 0, respostaPadrao);
  }

  const onClickAdicionarResposta = (index) => {
    const respostaPadrao = { nome: "" };
    const perguntaSelecionada = listaPerguntas[index];
    let listaResposta = [];

    if (verificaOutro(perguntaSelecionada)){
      colocaOutroParaFinalDaLista(perguntaSelecionada, respostaPadrao);
      listaResposta = [...perguntaSelecionada.respostas];

    } else {
      listaResposta = [...perguntaSelecionada.respostas, respostaPadrao];

    }

    listaPerguntas[index] = {
      ...perguntaSelecionada,
      respostas: listaResposta,
    };

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: [...listaPerguntas]
    });
  };

  const onClickOpcaoOutro = (acao, index) => {
    const respostaPadrao = { isOutro: true };
    const perguntaSelecionada = listaPerguntas[index];
    const listaResposta = [...perguntaSelecionada.respostas, respostaPadrao];
    listaPerguntas[index] = {
      ...perguntaSelecionada,
      respostas: listaResposta,
    };

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: [...listaPerguntas]
    });
  };

  const handleExcluirPergunta = (index) => {
    listaPerguntas.splice(index, 1);

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: listaPerguntas.length > 0 ? [...listaPerguntas] : []
    });
  };

  const handleDuplicarPergunta = (index) => {
    const novaPerguntaDuplicada = listaPerguntas[index];
    const listaComPerguntaDuplicada = [
      ...listaPerguntas,
      novaPerguntaDuplicada,
    ];

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: listaComPerguntaDuplicada
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      listaPerguntas,
      result.source.index,
      result.destination.index
    );

    setDadosAnamnese({
        ...dadosAnamnese,
        listaPerguntas: items
    });
  };

  return (
    <>
       <Grid className={classes.contentHeader}>
        <Grid item xs={12} className={classes.input}>
          <InputSelectComLabel
            label={"Nome do modelo"}
            value={nomeModelo}
            onChange={(e) => handleChangeNomeModelo(e)}
          />
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {listaPerguntas && listaPerguntas.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={
                      typeof item.id === "string" ? item.id : item.id.toString()
                    }
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CardModeloAnamnese
                          key={index}
                          pergunta={item.pergunta}
                          tipo={item.tipo.value}
                          handleChange={(e, field) =>
                            handleChangePerguntaTipoCampo(e, field, index)
                          }
                          excluirResposta={(posicaoResposta) =>
                            excluirResposta(index, posicaoResposta)
                          }
                          listaResposta={item.respostas}
                          handleChangeResposta={(event, posicaoResposta) =>
                            handleChangeResposta(index, event, posicaoResposta)
                          }
                          onClickAdicionarResposta={() =>
                            onClickAdicionarResposta(index)
                          }
                          isAdicionarOutro={isAdicionarOutro}
                          onClickOpcaoOutro={(acao) =>
                            onClickOpcaoOutro(acao, index)
                          }
                          handleExcluirPergunta={() =>
                            handleExcluirPergunta(index)
                          }
                          handleDuplicarPergunta={() =>
                            handleDuplicarPergunta(index)
                          }
                          tipoCampoPergunta={item.tipo}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      </>
  );
});

const InformacoesModeloAnamneseWithStyles = withStyles(styles)(InformacoesModeloAnamnese);
export default inject("auditoriaStore")(InformacoesModeloAnamneseWithStyles);
