import React, { useEffect } from "react"
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from '@material-ui/core/styles';
import { InputDateForm, InputForm } from "../Modal/Input"
import { TextFieldSearch } from "../TextField"
import { findAllProcedimentos } from "../../services/ProcedimentoService";
import { findAllProfissionalSaude } from "../../services/ProfissionalSaudeService";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import classNames from "classnames";

const ContratoForm = observer((
  { classes,
    contratoData,
    handleChange,
    handleDeleteProcedimentos,
    handleProcedimentos,
    sujeitoAtencao,
    readOnly,
    documentoSelecionado,
  }) => {
    const [tipoDestinatario, setTipoDestinatario] = React.useState("TELEFONE");

    useEffect(() => {
      handleChange(sujeitoAtencao?.contato?.telefonePrincipal || "", "destinatarios")

    }, []);

  const dadosDocumento = readOnly ? documentoSelecionado : contratoData
  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
        })
      ).data.data.findAllProfissionalSaude
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }
  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    }
    const searchDTO = {
      pageableDTO,
      nome: search,
    }

    try {
      const { content, last } = (
        await findAllProcedimentos(searchDTO)
      ).data.data.findAllProcedimentos
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeTipoDestinatario = (e) => {
    const { value } = e.target;
    const { email, telefonePrincipal } = sujeitoAtencao?.contato || {};

    if(value === "TELEFONE" && telefonePrincipal) {
      handleChange(telefonePrincipal, "destinatarios")
    } 

    if(value === "EMAIL" && email) {
      handleChange(email, "destinatarios")
    }

    setTipoDestinatario(value)
  }

  return (
    <div className={classes.containerForms}>
      <div className={classes.containerInput}>
        <span className={classes.titleInput}>Nome do documento</span>
        <InputForm
          id="nome"
          placeholder="Digite o nome do documento"
          value={dadosDocumento?.nome}
          onChange={(e) => handleChange(e.target.value, "nome")}
          classes={{
            input: classes.inputInfos,
          }}
          inputProps={{ maxLength: 100 }}
          disabled={readOnly}
        />
      </div>
      <div className={classNames(classes.containerInput, classes.destinatarios)}>
        <span className={classes.titleInput}>Destinatário</span>
        <RadioGroup
          name="destinatario"
          value={tipoDestinatario}
          onChange={handleChangeTipoDestinatario}
        >
          <FormControlLabel
            value="TELEFONE"
            control={<Radio color={"primary"}/>}
            label="Telefone"
          />
          <FormControlLabel
            value="EMAIL"
            control={<Radio color={"primary"}/>}
            label="E-mail"
          />
        </RadioGroup>
        <InputForm
          id="destinatarios"
          placeholder="Digite o telefone ou email do destinatario"
          value={dadosDocumento?.destinatarios}
          onChange={(e) => handleChange(e.target.value, "destinatarios")}
          classes={{
            input: classes.inputInfos,
          }}
          inputProps={{ maxLength: 100 }}
          disabled={readOnly}
        />
      </div>
      <div className={classes.containerInput}>
        <span className={classes.titleInput}>Mensagem</span>
        <InputForm
          multiline
          inputMultiline
          id="Mensagem"
          placeholder="Digite a mensagem"
          value={dadosDocumento?.tituloMensagem}
          onChange={(e) => handleChange(e.target.value, "tituloMensagem")}
          classes={{
            input: classes.mensagem,
          }}
          disabled={readOnly}
        />
      </div>
      <div className={classes.containerInput}>
        <span className={classes.titleInput}>Data de validade</span>
        <InputDateForm
          value={dadosDocumento?.dataHoraValidade || null}
          onChange={(e) => handleChange(e, "dataHoraValidade")}
          classes={{ input: classes.inputDate }}
          disabled={readOnly}
        />
      </div>
      <div className={classes.containerInput} disabled={readOnly}>
        <span className={classes.titleInput}>Profissional</span>
        <TextFieldSearch
          placeholder='Selecione'
          classIcons={classes.classIcons}
          classInput={classes.reactSelect}
          classNotched={classes.notchedOutline}
          loadOptions={loadProfissionaisSaude}
          getOptionLabel={option => option?.nome}
          getOptionValue={option => option?.id}
          value={dadosDocumento?.profissionalSaude}
          classes={{ paper: classes.menuHeight }}
          onChange={e => handleChange(e, 'profissionalSaude')}
          withPaginate
          debounceTimeout={300}
          additional={{
            page: 0,
          }}
          menuPosition='fixed'
          isDisabled={readOnly}
        />
      </div>
      <div className={classes.containerInput} disabled={readOnly}>
        <span className={classes.titleInput}>Procedimentos</span>
        <TextFieldSearch
          classNotched={classes.notchedOutline}
          classInput={classes.reactSelect}
          classIcons={classes.classIcons}
          placeholder={"Selecione o procedimento"}
          onChange={(e, index) => handleProcedimentos(e, index)}
          value={dadosDocumento.procedimentos}
          menuPlacement={'auto'}
          isClearable
          getOptionLabel={(option) => option.nome}
          getOptionValue={(option) => option.id}
          withPaginate
          loadOptions={loadAllProcedimentos}
          debounceTimeout={300}
          additional={{
            page: 0,
          }}
          isDisabled={readOnly}
        />
        <div className={classes.listaProcedimentosSelecionados} disabled={readOnly}>
          {dadosDocumento?.procedimentos?.length > 0 &&
            dadosDocumento.procedimentos?.map((item, index) => (
              <div className={classes.procedimentoOption} key={index} disabled={readOnly}>
                {item?.nomeProcedimento || item?.nome}
                <CancelIcon
                  onClick={!readOnly ? () =>
                    handleDeleteProcedimentos(item) : null
                  }
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
})

const styles = () => ({
  mensagem: {
    minHeight: "50px",
    maxHeight: "50px !important",
    width: '98%',
  },
  titleInput: {
    color: "#868686",
    fontSize: "11px !important"
  },
  containerForms: {
    backgroundColor: "#ffff",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "100%",
    padding: "10px",
    overflow: "auto",
    boxSizing: " border-box"
  },
  containerInput: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    textAlign: "left !important"
  },
  inputInfos: {
    color: "#505050",
    background: "#F2F2F2",
    border: "0 !important",
    borderRadius: "8px",
    height: "30px",
    boxSizing: "border-box",
    fontSize: "14px"
  },
  reactSelect: {
    color: "#505050",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    fontSize: "12px !important",
  },
  notchedOutline: {
    border: '0',
  },
  menuHeight: {
    maxHeight: '25vh',
    '&> div': {
      maxHeight: '25vh',
    },
  },
  classIcons: {
    fontSize: 24,
    padding: "0px"
  },
  inputDate: {
    textAlign: "left !important",
    padding: "0px !important",
    fontSize: "14px",

  },
  listaProcedimentosSelecionados: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  procedimentoOption: {
    background: "#F2F2F2",
    border: "0.5px solid #BDBDBD",
    borderRadius: "100px",
    minWidth: "80px",
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
    padding: "4px 8px",
    margin: "3px",
    alignItems: "center",
    "& svg": {
      color: "#868686",
      marginLeft: "5px",
      fontSize: "13px",
    },
  },
  destinatarios: {
    "&> div": {
      flexDirection: "row",
      "& > label": {
        height: 'max-content',

        "& > span": {
          padding: '0 4px 0 8px',
        }
      }
    }
  }
});

const stores = ['documentoStore', "filtroHeaderStore"]
const ModalContratoWithStyles = withStyles(styles)(ContratoForm)
export default inject(...stores)(ModalContratoWithStyles)
