import React, { useState, useEffect, lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import { CircularProgress, DialogTitle } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'

import ButtonLong from '../../../components/Button/ButtonLong'
import Dialog from '../../../components/Dialog/Dialog'
import { Fab } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { updateGuiaServico } from '../../../services/GuiaDeServicosService'
import HorizontalLinearStepper from './Stepper'
import ButtonTransparent from '../../../components/Button/ButtonTransparent'


const STEPS = [
  lazy(() => import('./StepsListagem/StepListagemSujeitoAtencao')), 
  lazy(() => import('./StepsListagem/StepListagemSolicitante')),
  lazy(() => import('./StepsListagem/StepListagemSolicitacao')), 
  lazy(() => import('./StepsListagem/StepListagemItens')),
  lazy(() => import('./StepsListagem/StepListagemObservacao')),
  lazy(() => import('./Steps/StepAutorizacao')),
  ]


const GuiaDeServicosModalListagem = observer(props => {
  const {
    classes,
    openModal,
    handleCloseModal,
    guiaServicoSelected,
    loadFinanceiroGuiaServico,
    guiaDeServicoStore,
    handleFaturar,
  } = props

  const [guiaServico, setGuiaServico] = useState({})
  const [loadingSave, setLoadingSave] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const [showButtonFaturar, setShowButtonFaturar] = useState(false);

  useEffect(() => {
    setShowButtonFaturar(guiaServicoSelected.agendamentosNaoFaturados?.length > 0);
  }, [guiaServicoSelected]);
  
  const handleSave = async () => {
    setLoadingSave(true)
    const newGuiaServico = {
      id: guiaServico.id,
      autorizacao: guiaServico.autorizacao,
    }
    await updateGuiaServico(newGuiaServico)
    guiaDeServicoStore.changeFieldsStore({
      isOpenModal: false,
      guiaServico: null,
    })
    setLoadingSave(false)
    loadFinanceiroGuiaServico({ isSearching: true })
  }

  const handleChangeGuiaServico = (field, value) => {
    setGuiaServico(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleClose = () => {
    handleCloseModal()
    setGuiaServico({})
    setActiveStep(0)
  }

  const handleNext = () => {
    handleChangeStep(activeStep + 1)
  }

  const handleChangeStep = step => {
    setActiveStep(step)
  }

  const handleBack = () => {
    handleChangeStep(activeStep - 1)
  }

  const renderStep = () => {
    const Component = STEPS[activeStep]
    return (
      <Suspense fallback={<div className={classes.wrapperStep}><CircularProgress color='primary' size={12} /></div>}>
        <Component 
          guiaServico={guiaServico}
          handleChangeGuiaServico={handleChangeGuiaServico}
          disableButton={disableButton}
          setDisableButton={setDisableButton}
        />
      </Suspense>
    )
  }

  useEffect(() => {
    if (guiaServicoSelected) {
      const guiaServicoById = {
        ...guiaServicoSelected,
        solicitanteInterna: guiaServicoSelected?.solicitante,
        solicitanteExterna: guiaServicoSelected?.solicitante,
      }
      setGuiaServico(guiaServicoById)
    }
  }, [guiaServicoSelected])

  const getSteps = () => {
    return [
      'Paciente',
      'Solicitante',
      'Solicitação',
      'Itens',
      { label: 'Observação', description: '*Não obrigatório' },
      { label: 'Autorização', description: '*Não obrigatório' },
    ]
  }

  return (
    <Dialog open={openModal} maxWidth={'md'} fullWidth={true} classes={{ paper: classes.dialog }}>
      <div className={classes.root}>
        <DialogTitle classes={{ root: classes.dialogHeader }} disableTypography>
          <div className={classes.dialogTitle}>
            <h1>{guiaServico?.autorizacao ? 'Guia de serviço' : 'Editar Guia de Serviço '}</h1>
            <div className={classes.wrapperActionsTitle}>
              <Fab className={classes.buttonActionClose} onClick={handleClose}>
                <CloseIcon />
              </Fab>
            </div>
          </div>
        </DialogTitle>

        <HorizontalLinearStepper activeStep={activeStep} steps={getSteps()} />
        <div className={classes.wrapperStep}>
          {renderStep()}
        </div>
        <div className={classes.formActions}>
          <div className={classes.inlineButtons}>
            <ButtonTransparent onClick={handleBack} disabled={activeStep === 0}>
              {activeStep !== 0 && 'Voltar'}
            </ButtonTransparent>
          </div>
          {showButtonFaturar && <div className={classes.inlineButtons}>
            <ButtonLong colorCustom='gray' onClick={handleFaturar}>
              Faturar
            </ButtonLong>
          </div>}
          {activeStep === 5 ? (
            <div className={classes.inlineButtons}>
              <ButtonLong
                disabled={disableButton || loadingSave}
                colorCustom='yellow'
                onClick={handleSave}
              >
                {loadingSave && <CircularProgress color='primary' size={12} />}
                <span className={classes.labelSalvar}>Salvar</span>
              </ButtonLong>
            </div>
          ) : (
            <div className={classes.inlineButtons}>
              <ButtonLong colorCustom='green' onClick={handleNext}>
                Próxima etapa
              </ButtonLong>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
})

const styles = () => ({
  dialog: {
    height: 'max-content',
  },
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    height: '100%',
  },
  wrapperStep: {
    maxHeight: '400px',
    minHeight: '300px',
    overflowY: 'auto',
  },
  formActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    margin: '1rem 0',
    gap: '8px',
  },
  inlineButtons: {
    paddingRight: '10px',
    display: 'inline-flex',
    width: '100%',
    '& button': {
      flex: 1,
      fontWeight: 'bold',
    },
    '& button[disabled]': {
      opacity: '0.5',
      cursor: 'default !important',
    },
  },
  labelSalvar: {
    fontSize: '14px ',
    marginLeft: 10,
  },
  dialogHeader: {
    background: 'white',
    borderRadius: '15px 15px 0 0',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>h1': {
      color: '#26ACA9',
      fontSize: '1.5rem',
      marginTop: '0.375rem',
    },
  },
  wrapperActionsTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonActionClose: {
    backgroundColor: '#FB7676',
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
  },
})

const GuiaDeServicosModalListagemWithStyles = withStyles(styles)(GuiaDeServicosModalListagem)
export default inject('guiaDeServicoStore')(GuiaDeServicosModalListagemWithStyles)
