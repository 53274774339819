import React, { useEffect, useRef, useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite"
import { withStyles } from "@material-ui/core";
import CardDadosCadastrais from "../../../components/Cards/CardDadosCadastrais";
import Button from "../../../components/Button/Button";
import colors from "../../../template/Colors";
import HeaderAparelhosConectados from "./HeaderAparelhosConectados";
import Notification from "../../../components/Notification";
import { disconnectWhats, getWhatsPlugzapiQrcode, getWhatsPlugzapiStatus } from "../../../services/UnidadeService";
import { getUnidadeLogado } from "../../../services/UsuarioService";
import QRCodeModal from "../../../components/Modal/QRCodeModal";





const qrCodeButtonStyle = {
    backgroundColor: colors.commons.green,
    height: '36px',
    padding: '8px 16px',
    borderRadius: '100px',
    textTransform: 'none'
};


const AparelhosConectados = observer(({ unidadeStore, classes }) => {
    const [imageQrCode, setImageQrCode] = useState(null)
    const [openQrCodeModal, setQrCodeModal] = useState(false)
    const [loadingQrCode, setLoadingQrCode] = useState(false)
    const [whatsStatus, setWhatsStatus] = useState({
        connected: false,
        WebhookMsgFuncionando: false,
        connectedPhone: null
    })

    const intervalVerifyStatusRef = useRef(null);

    useEffect(() => {
        intervalVerifyStatusRef.current = setInterval(updateStatus, 20000);
        updateStatus()
        return () => clearInterval(intervalVerifyStatusRef.current);
    }, [])

    const handleCloseQrCodeModal = () => {
        setQrCodeModal(false)
    }
    const handleOpenQrCodeModal = () => {
        generateQrCode()
        setQrCodeModal(true)
    }

    const generateQrCode = async () => {
        const { id } = await getUnidadeLogado()
        setLoadingQrCode(true)
        if (whatsStatus.connected) {
            const { connected } = await disconnectWhats(id)
            setWhatsStatus((status) => ({ ...status, connected: connected }))
        }
        const qrCode = await getWhatsPlugzapiQrcode(id)
        if (!qrCode?.value) {
            setLoadingQrCode(false)
            unidadeStore.openNotification('Erro ao carregar o QR code.', 'error')
            return
        }
        setImageQrCode(qrCode.value)
        setLoadingQrCode(false)

    }

    const updateStatus = async () => {
        const { id } = await getUnidadeLogado()

        const whatsStatus = await getWhatsPlugzapiStatus(id)
        if (whatsStatus) {
            setWhatsStatus({
                connected: whatsStatus.connected,
                WebhookMsgFuncionando: whatsStatus.plugzapiWebhookMsgFuncionando,
                connectedPhone: whatsStatus?.plugzapiConnectedPhone || null
            })
        }

    }


    const resetNotification = () => unidadeStore.resetNotification();
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        unidadeStore.closeNotification();
    };


    return (

        <div className={classes.root}>
            <HeaderAparelhosConectados
                title={"Aparelhos conectados"}
            />
            <div className={classes.tableContainer}>
                <div className={classes.gridCardContainerAparelhos}>
                    <div style={{ height: 'fit-content', marginBottom: '24px' }}>
                        <CardDadosCadastrais titulo="Secretária virtual" label={
                            <div className={classes.headerCard}>
                                {!!whatsStatus.connectedPhone && <div className={classes.labelContainer}>
                                    <span className={classes.labelStatus}>Celular conectado:</span>
                                    <span className={classes.labelPhone}>
                                        {whatsStatus.connectedPhone}</span>
                                </div>}
                                <div className={classes.labelContainer}>
                                    <span className={classes.labelStatus}>Status:</span>
                                    <span className={whatsStatus.connected ?
                                        classes.statusConnected :
                                        classes.statusDisconnected}>
                                        {whatsStatus.connected ? "Conectado" : "Desconectado"}</span>
                                </div>
                                <div>
                                    <span className={classes.labelStatus}>Webhook funcionando:</span>
                                    <span className={whatsStatus.WebhookMsgFuncionando ?
                                        classes.statusConnected :
                                        classes.statusDisconnected}>
                                        {whatsStatus.WebhookMsgFuncionando ? "Sim" : "Não"}</span>
                                </div>
                            </div>
                        }>
                            <span className={classes.cardText}>
                                Para ter acesso a sua secretaria virtual, clique no botão abaixo para gerar seu QR code.
                            </span>
                            {!whatsStatus.WebhookMsgFuncionando && <span className={classes.cardTextWarning}>
                                Para que a secretária virtual funcione corretamente, envie a mensagem '#VALIDA_MSG_APPHEALTH%' do número desejado para o número que escaneou o QR code.
                            </span>}
                            <Button
                                style={qrCodeButtonStyle}
                                onClick={handleOpenQrCodeModal}
                            >
                                Carregar QR Code
                            </Button>
                        </CardDadosCadastrais>
                    </div>
                </div>

            </div>
            <QRCodeModal
                open={openQrCodeModal}
                onClose={handleCloseQrCodeModal}
                updateQrCode={generateQrCode}
                imageQrCode={imageQrCode}
                loadingQrCode={loadingQrCode} />
            <Notification
                close={handleClose}
                reset={resetNotification}
                isOpen={unidadeStore.notification.isOpen}
                variant={unidadeStore.notification.variant}
                message={unidadeStore.notification.message}
            />
        </div>
    );

});
const styles = theme => ({
    root: {
        backgroundColor: '#F5F5F5',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        display: "grid",
        gridRowGap: "24px",
        alignContent: "start",
        overflowY: 'auto',
        padding: "2% 4%",
        backgroundColor: '#F5F5F5',
    },
    gridCardContainerAparelhos: {
        display: "grid",
        gridGap: "24px",
        width: "90%",
        paddingBottom: 20,
        marginLeft: 0,
        gridTemplateColumns: "1fr 1fr",

        "@media (max-width: 1100px)": {
            gridTemplateColumns: "1fr",
            gridTemplateRows: "auto auto auto",
        },
    },
    headerCard: {
        flexDirection: "column"
    },
    labelContainer: {
        flexDirection: "row"
    },
    labelStatus: {
        fontWeight: 700,
        fontSize: "10px !important",
        color: "#505050"
    },
    statusDisconnected: {
        fontSize: "10px !important",
        backgroundColor: "#FFE1E0",
        borderRadius: "8px",
        padding: "0 5px",
        marginLeft: "4px",
        color: "#FB7676"
    },
    statusConnected: {
        fontSize: "10px !important",
        backgroundColor: "#E3F4FE",
        borderRadius: "8px",
        padding: "0 5px",
        marginLeft: "4px",
        color: "#52D5FC"
    },
    imgContainer: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: "300px",
        display: "flex",
        flexDirection: "column"
    },
    cardText: {
        fontSize: '12px !important',
        fontWeight: '400 !important',
        color: '#5F6368',
    },
    cardTextWarning: {
        fontSize: '10px !important',
        fontWeight: '700 !important',
        color: '#FB7676'
    },
    warningCode: {
        fontSize: '12px !important',
        fontWeight: '400 !important',
        color: '#5F6368',
        textAlign: "center"
    },
    labelPhone: {
        fontWeight: 400,
        fontSize: "10px !important",
        marginLeft: "4px"

    }
})
const stores = ['unidadeStore'];
const AparelhosConectadosStyles = withStyles(styles)(AparelhosConectados);
export default inject(...stores)(AparelhosConectadosStyles);