import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Informacoes from "./Informacoes";
import HeaderModal from "../../Components/HeaderModal";
import Dialog from "../../../../../../components/Dialog/Dialog";
import Auditoria from "../../../../../../components/Auditoria/Auditoria";
import { tabsAuditoria } from "../../../../../../stores/Odontograma.store";
import MultiToggleButtons from "../../../../../../components/Button/MultiToggleButtons";
import { findAllProcedimentoOdontologicoAuditByProcedimentoOdontologicoId } from "../../../../../../services/AuditoriaService";

const ModalEditarProcedimento = observer(
  ({ classes, odontogramaStore, auditoriaStore }) => {
    const [tabSelected, setTabSelected] = useState(tabsAuditoria.INFORMACAO);

    const { modalEditarProcedimento } = odontogramaStore;
    const {
      showModal,
      isDuplicarProcedimento,
      procedimentoOdontologicoSelecionado,
    } = modalEditarProcedimento;

    useEffect(() => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoria: "o procedimento",
        auditoriaTela: "procedimentoOdontologico",
      };
    }, []);

    const changeTabSelected = async (tabSelected) => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
      };

      if (tabSelected === 1) {
        auditoriaStore.load = {
          query: findAllProcedimentoOdontologicoAuditByProcedimentoOdontologicoId,
          variableName: "procedimentoOdontologicoId",
          variableId: procedimentoOdontologicoSelecionado.id,
        };
        await auditoriaStore.loadAuditItems();
      }

      setTabSelected(tabSelected);
    };

    return (
      <Dialog
        open={showModal}
        maxWidth="md"
        fullWidth
        classes={{
          paper: classes.paper,
        }}
      >
        <HeaderModal
          title={
            isDuplicarProcedimento
              ? "Duplicar procedimento"
              : "Editar procedimento"
          }
          onClose={() => odontogramaStore.changeCloseModalEditarProcedimento()}
          classes={{ header: classes.header }}
        />
        <MultiToggleButtons
          options={["Informações", "Auditoria"]}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
          disabled={isDuplicarProcedimento}
          posicaoDesabilitada={[1]}
        />
        {tabSelected === 0 ? <Informacoes /> : <Auditoria />}
      </Dialog>
    );
  }
);

const styles = {
  paper: {
    maxWidth: "619px",
    padding: "32px",
    gap: "16px",
    maxHeight: "242px",
    height: "100%",
  },
};

const ModalEditarProcedimentoWithStyles = withStyles(styles)(
  ModalEditarProcedimento
);
export default inject(
  "odontogramaStore",
  "auditoriaStore"
)(ModalEditarProcedimentoWithStyles);
