export const mensagensColor = {
  TEXTO: "green",
  RECEITA: "blue",
  DOCUMENTO: "purple",
  GUIA_SERVICO: "sadtColor",
  GUIA_INTERNACAO: "guiaInternacaoColor",
  IMAGEM: "green",
  ARQUIVO: "green",
  LOADING: "green",
  LOADING_DOCUMENTO: "purple",
  PROCEDIMENTO_REALIZADO: "procedimentoRealizadoColor",
  ANAMNESE: "anamneseColor",
  AVALIACAO_ANTROPOMETRICA: "avaliacaoAntropometricaColor",
  AFERICAO_VITAL: "avaliacaoAntropometricaColor",
};

export const entradaTipos = [
  "DOCUMENTO",
  "LOADING_DOCUMENTO",
  "RECEITA",
  "GUIA_SERVICO",
  "PROCEDIMENTO_REALIZADO",
  "ANAMNESE",
  "AVALIACAO_ANTROPOMETRICA",
  "AFERICAO_VITAL",
  "GUIA_INTERNACAO"
];

export const MAX_IMAGE_MESSAGE_INTERVAL_TO_GROUP_IN_MIN = 3;
