import React from 'react';
import { withStyles } from '@material-ui/core';

const styles =({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '24px',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08);',
    borderRadius: '12px',
  },
  textArea: {
    display: 'flex',
    gap: '4px',
    '& span': {
      display: 'flex',
      fontSize: '18px',
      fontWeight: 600,
      color: '#505050',
      textAlign: 'justify',
      wordBreak: 'break-word',
    },
  },
  icon: {
    flex: 1,
  },
});

const AlertCard = ({ 
  classes, 
  icon, 
  message, 
  withButton, 
  buttonLabel, 
  width, 
  height, 
  backgroundColor,
}) => {
  const customStyle = {
    width: width,
    height: height,
    backgroundColor: backgroundColor
  }
  
  return (
    <div className={classes.container} style={customStyle}>
      <div className={classes.textArea}>
        <div className={classes.icon}>
          {icon}
        </div>
        <span>{message}</span>
      </div>
      {withButton && <div>{buttonLabel}</div>}
    </div>
  );
};

export default withStyles(styles)(AlertCard);
