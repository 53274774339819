import Colors from "../../../../template/Colors";

const styles = {
    paper: {
      maxWidth: "744px",
      height: 600,
    },
    tituloHeader: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: '64px',
      alignItems: 'center',
      padding: '0 32px',
    },
    tituloContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    titulo: {
      color: '#333333',
      fontWeight: 600,
      fontSize: "24px !important",
    },
    buttonActionSave: {
      background: Colors.commons.secondary,
      width: "35px",
      height: "35px",
      color: "white",
      marginLeft: 10,
      boxShadow: "none",
      "&:hover": {
        background: Colors.primary.dark,
      },
    },
    buttonActionClose: {
      background: Colors.error.main,
      width: "35px",
      height: "35px",
      color: "white",
      marginLeft: 10,
      boxShadow: "none",
      "&:hover": {
        background: Colors.error.dark,
      },
    },
    labelStatus: {
      color: Colors.commons.gray7,
      fontSize: "12px !important",
      fontWeight: 400,
      marginBottom: 4,
    },
    contentHeader: {
      display: "flex",
      margin: "12px 0",
      padding: "0 32px",
    },
    content: {
      padding: "16px 15px",
      background: Colors.commons.grayLight,
      height: "100%",
      overflow: "auto",
    },
    card: {
      borderRadius: 10,
      padding: 16,
      height: 54,
      display: "flex",
      width: "100%",
      background: "#ffff",
    },
    dragHandleIcon: {
      color: Colors.commons.gray7,
      marginRight: "16px",
    },
    cardEIcon: {
      display: "flex",
    },
    buttonAdicionarPergunta: {
      background: Colors.commons.secondary,
      width: 40,
      height: 40,
      position: "absolute",
      bottom: "22px",
      right: "22px",
      borderRadius: "100px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      color: " #fff",
      cursor: "pointer",
      "&:hover": {
        background: Colors.primary.dark,
      },
    },
    buttonsTab: {
      margin: "8px 24px 0px",
    },
    contentAuditoria: {
        height: 'calc(100% - 121px)',
        marginTop: '16px',
        "&>div": {
            height: '100%',
        }
    },
    footer: {
      display: 'flex',
      padding: '16px 24px 24px 24px',
      backgroundColor: '#F9F9F9',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  };

  export default styles;