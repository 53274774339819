import React, { useEffect, useState } from 'react'
import { Switch, FormControlLabel } from '@material-ui/core'
import { ativarProfissionalSaude, inativarProfissionalSaude } from '../../../../../../../services/ConfiguracaoSecretariaService'

const Status = ({ row, updateProfissionalSaudeOnList }) => {
  const [statusSecretaria, setStatusSecretaria] = useState(false);

  useEffect(() => {
    setStatusSecretaria(row.ativo);

  }, [row.ativo])

  const handleChangeStatus = async event => {
    try {
      const { checked } = event.target
      const variables = {
        profissionalSaudeId: row.id,
      }
      const response = checked ? await ativarProfissionalSaude(variables) : await inativarProfissionalSaude(variables)
      if (!response) return
      setStatusSecretaria(checked)
      updateProfissionalSaudeOnList(row.id)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FormControlLabel
      control={<Switch checked={statusSecretaria} color='primary' />}
      onChange={e => handleChangeStatus(e)}
    />
  )
}

export default Status
