import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { Button } from "../../../components/ui/Buttons";

const ContentListTitulosTabPagamento = observer((props) => {
  const { classes, extratoStore, history } = props;
  const { titulo } = extratoStore;

  const handleClickEditar = async (item) => {
    extratoStore.titulo = item;
    extratoStore.open = true;
    extratoStore.editMode = true;
    history.push("/financeiro");
    extratoStore.onlyReadParcelas = false;
    extratoStore.getTabs();
  };

  return (
    <div className={classes.content}>
      <span className={classes.titulos}> Títulos </span>
      {titulo.map((item) => {
        return (
          <div className={classes.row}>
            {item.nome}
            <Button onClick={() => handleClickEditar(item)}>Editar</Button>
          </div>
        );
      })}
    </div>
  );
});

const styles = {
  content: {
    paddingRight: "16px",
    margin: "16px 0",
  },
  titulos: {
    color: "#585858",
    fontWeight: "500",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #BDBDBD",
    color: "#868686",
    padding: "4px 0",
  },
};

const ContentListTitulosTabPagamentoWithStyles = withStyles(styles)(
  ContentListTitulosTabPagamento
);
export default inject(
  "atendimentoStore",
  "extratoStore"
)(ContentListTitulosTabPagamentoWithStyles);
