import React from 'react';
import InputDefault from './InputDefault';
import { renderMaskedInput } from './InputMaskGeneric';

export const InputMaskMm = (props) => {
    const maskedProps = {
      suffix: ' mm',
      placeholder: ' mm',
      integerLimit: 3
    }

    return renderMaskedInput(maskedProps, props)
}

export const InputMm = (props) => {
    return <InputDefault 
        InputProps={{ inputComponent: InputMaskMm }} 
        {...props} 
    />
};
