import React from 'react';
import ButtonPrimary from './ButtonPrimary';
import ButtonClearPrimary from './ButtonClearPrimary';
import ButtonClearGray from './ButtonClearGray';

const SelectedButton = (props) => {
    const { selected, unselectedColor, className, ...others } = props;
    
    if (selected) {
        return <ButtonPrimary className={className} {...others} />;
    }
    
    if (unselectedColor === 'gray') {
        return <ButtonClearGray className={className} {...others} />;
    }
    
    return <ButtonClearPrimary className={className} {...others} />;
};

SelectedButton.defaultProps = {
    selected: true,
};

export default SelectedButton;