import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import ContactItem from "./ContactItem";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import TabSelector from '../../../components/TabSelector';
import { contactsListTabs } from './utils/constants';

const styles = {
  contactListContainer: {
    display: "flex",
    flexDirection: "column",
  },
  disabledWithBlur: {
    position: "absolute",
    width: "25%",
    height: "calc(100% - 170px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 1,
  },
  tabs: {
    margin: "0 16px 8px 16px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "0 !important",
  }
};

const ContactsList = observer(({ classes, onClickContact, chatStore, socketStore }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tabSeleted, setTabSeleted] = useState(0);
  const {
    contacts,
    contactsPaginationSettings,
    scrollContactListTop,
  } = chatStore;

  const { isConnectionOpen } = socketStore;

  const topListRef = useRef(null);

  useEffect(() => {
    if (scrollContactListTop) {
      topListRef.current.scrollIntoView({ behavior: "smooth" });
    }

    chatStore.scrollContactListTop = false;
  }, [scrollContactListTop]);

  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    const ativo = tabSeleted === 0;
    chatStore.resetContacts(ativo);
  }, [tabSeleted]);

  const loadContacts = async () => {
    setIsLoading(true);
    await chatStore.getContacts();
    setIsLoading(false);
  };

  const getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    setAccessToken(accessToken);
  };

  const hasMore = !contactsPaginationSettings.lastPage;
  return (
    <>
      <TabSelector 
        selectedTabIndex={tabSeleted} 
        onSelect={setTabSeleted} 
        tabsOptions={contactsListTabs}
        classes={{ tabs: classes.tabs }}
        borderRadius={'0px'}
      />
      <Scroll
        loadMore={loadContacts}
        hasMore={hasMore && !isLoading}
        pageStart={0}
        isLoading={isLoading}
      >
        <div ref={topListRef} />
        <div className={classes.contactListContainer}>
          {!isConnectionOpen && <div className={classes.disabledWithBlur} />}
          {contacts &&
            contacts.map((contact) => (
              <ContactItem
                key={contact.id}
                contact={contact}
                accessToken={accessToken}
                onClickContact={isConnectionOpen ? onClickContact : null}
              />
            ))}
        </div>
      </Scroll>
    </>
  );
});

const ContactsListStyles = withStyles(styles)(ContactsList);
const stores = ["chatStore", "socketStore"];
export default inject(...stores)(ContactsListStyles);
