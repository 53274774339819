import React, { lazy } from "react";
import { action, observable } from "mobx";
import Api from "../config/api";
import {
  adicionarObservacaoDente,
  adicionarObservacaoMucosaOral,
  alterarStatusDente,
  createDente,
  createProcedimentoOdontologico,
  findAllLocalOdontograma,
  findAllProcedimentoOdontologico,
  findAllProcedimentoOdontologicoSemOrcamento,
  findAllProcedimentosOdontologico,
  findByProcedimentoOdontologicoId,
  findOdontogramaBySujeitoAtencao,
  getListaProcedimentoOdontologico,
  updateProcedimentoOdontologico,
} from "../services/OdontogramaService";
import BaseStore from "./Base.store";
import {
  denteTipoFace,
  tabsModalConfiguracao,
} from "../pages/Atendimento/Tabs/Odontograma/Constants";

const tabsVisualizacao = {
  PERMANENTE: 0,
  DECIDUO: 1,
};

export const tabsAuditoria = {
  INFORMACAO: 0,
  AUDITORIA: 1,
};

export const modalConfiguracaoDefault = {
  showModalConfiguracao: false,
  tipoDenteSelected: {
    name: "Permanentes",
    label: "Permanentes",
    value: true,
  },
  tabSelected: tabsModalConfiguracao.TIPO_DENTE,
  dentesAdicionados: [],
  dentesTipoSelecionado: {
    Permanentes: [],
    Deciduos: []
  },
};

const odontogramaDefault = {
  id: null,
  denteDeciduo: [],
  dentePermanente: [],
  mucosaOral: [],
  denteSupranumerario: [],
};

const modalProcedimentoDefault = {
  showModalProcedimento: false,
  localOdontograma: [],
  procedimentos: [],
  procedimentosSelecionados: [],
  todosLocais: [],
};

const modalObservacaoDefault = {
  showModalObservacao: false,
  observacao: "",
};

const modalEditarProcedimentoDefault = {
  showModal: false,
  procedimentoOdontologicoSelecionado: null,
  isDuplicarProcedimento: false,
};

const listagemProcedimentosDefault = {
  procedimentosOdontologicos: [],
  loading: false,
  last: false,
};

const modalProcedimentosRealizadosDefault = {
  showModalProcedimentosRealizados: false,
  procedimentoRealizado: {
    agendamento: {
      id: null
    },
    agendamentoProcedimentoId: null,
    procedimento: {
      id: null
    },
    procedimentoOdontologico: {
      id: null
    },
  },
};

export const modalConfirmacaoDefault = {
  open: false,
  title: "",
  color: "",
  icon: null,
  buttons: null,
};

export default class OdontogramaStore extends BaseStore {
  prontuarioStore = null;
  sujeitoAtencaoStore = null;
  orcamentoStore = null;

  @observable tabVisualizacaoSelected = tabsVisualizacao.PERMANENTE;
  @observable modalObservacao = modalObservacaoDefault;
  @observable showModalHistorico = false;
  @observable modalProcedimento = modalProcedimentoDefault;
  @observable modalEditarProcedimento = modalEditarProcedimentoDefault;
  @observable modalConfirmacao = modalConfirmacaoDefault;
  @observable locaisSelecionados = [];
  @observable isClickRightLocalSelecionado = false;
  @observable procedimentoSelecionado = null;
  @observable odontograma = odontogramaDefault;
  @observable dentes = [];
  @observable isTelaAmpliada = false;
  @observable modalConfiguracao = modalConfiguracaoDefault;
  @observable listagemProcedimentos = listagemProcedimentosDefault;
  @observable modalProcedimentosRealizados = modalProcedimentosRealizadosDefault;
  @observable procedimentosOdontologicosOdontograma = [];
  @observable procedimentosOrcamento = [];
  @observable isLoadingProcedimentosOrcamento = false;
  @observable dadosModalConfirmacao = modalConfirmacaoDefault; 
  @observable loadingOdontograma = false;

  constructor(rootStore) {
    super();
    this.prontuarioStore = rootStore.prontuarioStore;
    this.sujeitoAtencaoStore = rootStore.sujeitoAtencaoStore;
    this.orcamentoStore = rootStore.orcamentoStore;
  }

  @action verificaEspecialidadeOdontologia = (profissionalSaude) => {
    const possuiEspecialidadeOdontologia = profissionalSaude?.especialidades?.length > 0 &&
      profissionalSaude.especialidades?.some(item => item.especialidade === "Odontologia");
      return possuiEspecialidadeOdontologia;
  };

  @action changeTabVisualizacaoSelected = (tabSelected) => {
    this.dentes =
      tabSelected === 0
        ? this.odontograma.dentePermanente
        : this.odontograma.denteDeciduo;

    this.tabVisualizacaoSelected = tabSelected;
  };

  @action changeCloseModalProcedimento = () => {
    this.removerSelecaoDentes();
    this.modalProcedimento = modalProcedimentoDefault;
    this.isClickRightLocalSelecionado = false;
    this.locaisSelecionados=[];
  };

  @action handleCloseModalRealizarProcedimento = () => {
    this.modalProcedimentosRealizados = modalProcedimentosRealizadosDefault;
  };

  @action handleCloseModalObservacao = () => {
    this.modalObservacao = modalObservacaoDefault;
    this.isClickRightLocalSelecionado = false;
    this.locaisSelecionados=[];
    this.dentes = this.dentes.map((item) => {
      return {
        ...item,
        selecionado: false,
      };
    })
    this.odontograma.mucosaOral=this.odontograma.mucosaOral.map(item => {
      return {
        ...item,
        checked: false
      }
    })
  };

  removerSelecaoDentes = () => {
    this.dentes = this.dentes.map((item) => {
      return {
        ...item,
        selecionado: false,
      };
    });

    this.locaisSelecionados = [];
  };

  @action getOdontograma = async () => {
    try {
      this.loadingOdontograma = true;
      const sujeitoAtencaoId = this.prontuarioStore.sujeitoAtencaoSelected.id

      const response = await findOdontogramaBySujeitoAtencao(sujeitoAtencaoId);

      this.getDadosOdontograma(response);
    } catch{
      this.openNotification("Erro ao carregar o odontograma", "error");
    } finally {
      this.loadingOdontograma = false;
    }
  };

  @action getDadosOdontograma = async (odontograma) => {
    const denteTipos = ["dentePermanente", "denteDeciduo"];
    let dentePermanente = [];
    let denteDeciduo = [];

    denteTipos.forEach((tipo) => {
      const denteTipo =
        tipo === "dentePermanente" ? "permanente" : "deciduo";
      const dentesComImagens = odontograma[tipo].map((dente) => {
        return {
          ...dente,
          local: 4,
          ImagemFrontal: <img src={`/dentes/${denteTipo}/frontal/dente${dente.codigo}.png`} alt="dente frontal"/>,
          ImagemVistaSuperior: <img src={`/dentes/${denteTipo}/vistaSuperior/dente${dente.codigo}.png`} alt="dente vista superior"/>,
        };
      });

      if (tipo === "dentePermanente") {
        dentePermanente = dentesComImagens;
      } else {
        denteDeciduo = dentesComImagens;
      }
    });

    const mucosaOral = odontograma.mucosaOral.map(item => {
      return {
        ...item,
        codigo: item.descricao
      }
    })

    this.odontograma = await {
      ...odontograma,
      mucosaOral,
      dentePermanente,
      denteDeciduo,
    };
    this.dentes =
      this.tabVisualizacaoSelected === 0 ? dentePermanente : denteDeciduo;
  };

  verificaLocalSelecionado = (procedimento) => {
    let isChecked = false;
    procedimento.localOdontograma.forEach((itemProcedimento) => {
      isChecked = this.modalProcedimento.procedimentosSelecionados.some(
        (itemSelecionado) => itemSelecionado.id === itemProcedimento.id
      );
    });

    return isChecked;
  };

  @action loadProcedimentoOdontologico = async (variables, clear = false) => {
    try {
      let locaisComId = [];
      const response = await findAllProcedimentosOdontologico(variables);
      const procedimentoComCheck = response.content.map((item) => {
        const procedimentoSelecionado = this.modalProcedimento.procedimentosSelecionados.find(
          (procedimentoSelecionado) => {
            return procedimentoSelecionado.id === item.id;
          }
        );

        const localOdontograma = procedimentoSelecionado?.id
          ? procedimentoSelecionado.localOdontograma
          : item?.tuss22ProcedimentoEventoSaude?.localOdontograma;

        this.modalProcedimento.todosLocais.forEach((local) => {
          const isLocalProcedimento = localOdontograma.some(
            (element) => element.id === local.local
          );
          isLocalProcedimento && locaisComId.push(local);
        });

        const procedimento = {
          ...item,
          checked: !!procedimentoSelecionado,
          localOdontograma: locaisComId.map((local) => ({
            ...local,
            checked:
              !!procedimentoSelecionado && this.verificaLocalSelecionado(item),
            nome: local.codigo || local.descricao,
          })),
          denteTipoFace: denteTipoFace,
        };

        const tipoDente = item.tuss22ProcedimentoEventoSaude?.localOdontograma.some((tipo) => tipo.id === 4);
        !tipoDente && delete procedimento.denteTipoFace;

        return procedimento;
      });

      this.modalProcedimento = {
        ...this.modalProcedimento,
        procedimentos: clear ? procedimentoComCheck : [
          ...this.modalProcedimento.procedimentos,
          ...procedimentoComCheck,
        ],
        last: response.last,
      };
    } catch (error) {
      throw error;
    }
  };

  @action loadLocalOdontograma = async (variables) => {
    try {
      const response = await findAllLocalOdontograma(variables);

      this.modalProcedimento.localOdontograma = response.map((item) => {
        return {
          ...item,
          codigo: item.codigo || item.descricao,
        };
      });
    } catch (error) {
      throw error;
    }
  };

  facesSelecionados = (procedimento) => {
    let facesSelecionadoString = "";
    const denteTipoFace = procedimento?.denteTipoFace;

    if(!denteTipoFace) {
      return facesSelecionadoString;
    };

    const facesSelecionado = denteTipoFace.filter(
      (item) => item.checked
    );

    facesSelecionado.forEach((item) => {
      facesSelecionadoString = facesSelecionadoString + `${item.value}, `;
    });

    return facesSelecionadoString;
  };

  @action loadTodosLocais = async () => {
    const response = await findAllLocalOdontograma({
      odontogramaId: this.odontograma.id,
      localOdontograma: [],
    });

    this.modalProcedimento.todosLocais = response.map(item => {
      return {
        ...item,
        codigo: item.codigo || item.descricao 
      }
    });
  };

  @action adicionaProcedimento = async ({
    isChecked,
    locais,
    procedimentos,
  }) => {
    try {
      let query = "";

      if (isChecked) {
        locais.forEach((local, indexLocal) => {
          const isDente = local.local === 4;
          const tipoLocal = isDente ? "dente" : "mucosaOral";
          procedimentos.forEach((procedimento, index) => {
            const nomeQuery = `${indexLocal}${tipoLocal}${index}`;
            const face = isDente
              ? `, face: [${this.facesSelecionados(procedimento)}],`
              : "";

            query =
              query +
              `create${nomeQuery}: createProcedimentoOdontologico(
                odontogramaId: "${this.odontograma.id}", 
                procedimentoOdontologico: {
                  procedimento: {id: "${procedimento.id}"},
                  ${tipoLocal}: { id: "${local.id}"},
                  ${face}
                  tuss22ProcedimentoEventoSaude: { 
                    id: ${procedimento.tuss22ProcedimentoEventoSaude.id},
                  }
                }
              ) 
              { id }`;
          }
        );
      
        });
      } else {
        procedimentos.forEach((procedimento, index) => {
          const locaisChecked = procedimento.localOdontograma.filter(
            (item) => item.checked
          );
          locaisChecked.forEach((local, indexLocal) => {
            const isDente = local.local === 4;
            const tipoLocal = isDente ? "dente" : "mucosaOral";
            const face = isDente
            ? `, face: [${this.facesSelecionados(procedimento)}],`
            : "";
            const nomeQuery = `${indexLocal}${tipoLocal}${index}`;

            query =
              query +
              `create${nomeQuery}: createProcedimentoOdontologico(
                odontogramaId: "${this.odontograma.id}", 
                procedimentoOdontologico: {
                  procedimento: {id: "${procedimento.id}"},
                  ${tipoLocal}: { id: "${local.id}"},
                  ${face}
                  tuss22ProcedimentoEventoSaude: { 
                    id: ${procedimento.tuss22ProcedimentoEventoSaude.id},
                  }
                }
              ) 
              { id }`;
          });
        });
      }

      const response = await Api.post("", {
        query: `
          mutation {${query}}
        `,
      });

      if (!response.data.errors) {
        this.getOdontograma();
        this.findAllProcedimentoOdontologico();

        return response.data.data.createProcedimentoOdontologico;
      } else {
        throw response.data.errors[0];
      }
    } catch (error) {
      throw error;
    }
  };

  @action findAllProcedimentoOdontologico = async (
    pageableDTO = { sortDir: "DESC", sortField: "agendamento.data" },
    procedimentoRealizado = false
  ) => {
    try {
      this.listagemProcedimentos.loading = true;

      const response = await findAllProcedimentoOdontologico({
        odontogramaId: this.odontograma.id,
        pageableDTO,
        procedimentoRealizado,
      });

      this.listagemProcedimentos.procedimentosOdontologicos = response;

      if (!response.errors) {
        return response;
      } else {
        throw response.errors[0];
      }
    } catch (error) {
      throw error;
    } finally {
      this.listagemProcedimentos.loading = false;
    }
  };

  @action loadProcedimentoOdontologicoSelect = async (variables) => {
    try {
      const response = await findAllProcedimentosOdontologico(variables);
      const content = response.content.map((item) => {
        return {
          ...item,
          nome: item.codigoTermoDescricao,
        };
      });

      if (!response.errors) {
        return {
          content,
          last: response.last,
        };
      } else {
        throw response.errors[0];
      }
    } catch (error) {
      throw error;
    }
  };

  getDenteTipoFace = (face) => {
    const denteTipoFaceChecked = denteTipoFace.map((tipoFace) => {
      const isChecked = face.some((item) => item === tipoFace.value);

      return {
        ...tipoFace,
        checked: isChecked,
      };
    });

    return denteTipoFaceChecked;
  };

  @action findByProcedimentoOdontologicoId = async (
    procedimentoOdontologicoId, modal="modalEditarProcedimento"
  ) => {
    try {
      const response = await findByProcedimentoOdontologicoId(
        procedimentoOdontologicoId
      );

      const localSelecionado = response.dente || response.mucosaOral;
      const denteTipoFaceComChecked =
        localSelecionado.localOdontograma?.id === 4 &&
        this.getDenteTipoFace(response.face);

      this[modal] = {
        ...this[modal],
        procedimentoOdontologicoSelecionado: {
          id: response.id,
          tuss22ProcedimentoEventoSaude: {
            id: response.tuss22ProcedimentoEventoSaude.id,
          },
          procedimento: response.procedimento,
          local: response.local,
          observacao: response.observacao,
          localSelecionado: {
            ...localSelecionado,
            denteTipoFace: denteTipoFaceComChecked,
            local: localSelecionado.localOdontograma?.id,
          },
        },
      };
    } catch (error) {
      throw error;
    }
  };

  @action updateProcedimentoOdontologico = async (procedimentoOdontologico) => {
    try {
      const response = await updateProcedimentoOdontologico({
        odontogramaId: this.odontograma.id,
        procedimentoOdontologico,
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  @action changeCloseModalEditarProcedimento = () => {
    this.modalEditarProcedimento = modalEditarProcedimentoDefault;
  };

  @action changeCloseModalConfirmacao = () => {
    this.modalConfirmacao = modalConfirmacaoDefault;
  };

  @action changeCloseModalConfiguracao = () => {
    this.modalConfiguracao = modalConfiguracaoDefault;
  };

  @action createProcedimentoOdontologico = async (procedimentoOdontologico) => {
    try {
      delete procedimentoOdontologico.id;

      const response = await createProcedimentoOdontologico({
        procedimentoOdontologico,
        odontogramaId: this.odontograma.id,
      });

      if (!response.errors) {
        return response;
      } else {
        throw response.errors[0];
      }
    } catch (error) {
      throw error;
    }
  };

  @action alterarStatusDente = async(dente) => {
    try {
      await alterarStatusDente({
        odontogramaId: this.odontograma.id,
        dente
      })

    } catch {
      this.openNotification(`Erro ao alterar o status do dente`, "error")
    }
  }

  @action getListaProcedimentoOdontologico = async(odontogramaId, procedimentoId ) => {
    const variables = {
      odontogramaId: odontogramaId || this.odontograma.id,
      pageableDTO: {
        sortDir: "DESC",
        sortField: "dataHoraLancamento",
      },
      agendado: false,
      procedimentoRealizado: false,
    }

    const response = await getListaProcedimentoOdontologico(variables);
    const procedimentos = response.filter(item => item.id !== procedimentoId);
    this.procedimentosOdontologicosOdontograma = procedimentos;
  };

  @action salvarObservacao = async({observacao, localId, local}) => {
    try {
      const response = local === "dente" ? 
      await adicionarObservacaoDente({
        observacao,
        [`${local}Id`]: localId,
        odontogramaId: this.odontograma.id
      })
      :
      await adicionarObservacaoMucosaOral({
        observacao,
        [`${local}Id`]: localId,
        odontogramaId: this.odontograma.id
      });

      return response;
    } catch {
      this.openNotification(`Erro ao salvar a observação`, "error")
    }
  };

  @action createDente = async({ sujeitoAtencaoId, dente }) => {
    try {
      await createDente({
        odontogramaId: this.odontograma.id,
        sujeitoAtencaoId,
        dente,
      })
    } catch {
      this.openNotification("Erro ao salvar o dente", "error")
    }
  };

  @action findAllProcedimentoOdontologicoSemOrcamento = async(variables) => {
    try {
      const response = await findAllProcedimentoOdontologicoSemOrcamento({
        sujeitoAtencaoId: variables.sujeitoAtencaoId,
        orcado: false,
        procedimentoRealizado: false,
        pageableDTO: { sortDir: "DESC", sortField: "agendamento.data" }
      });

      this.orcamentoStore.orcamento.orcamentoProcedimentos = response.map(item => {
        return {
          ...item,
          checked: false
        }
      });

    } catch (e) {
      this.openNotification("Erro ao carregar os procedimentos odontológicos", "error")
    }
  };

}
