import { 
    blue, 
    primaryColor,
    green, 
    purple, 
    red, 
    yellow, 
    sadtColor, 
    procedimentoRealizadoColor, 
    anamneseColor, 
    avaliacaoAntropometricaColor, 
    image, 
    grayLight, 
    guiaInternacaoColor 
} from "../appHealth.style";

const mensagemAtendimentoStyle = {
    imageBackground: {
        ...image
    },
    mensagem: {
        display: "flex",
        marginBottom: "15px",
        fontFamily: "Poppins !important",

        "& p": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            margin: "0 16px",
            padding: "13px 15px",
            maxWidth: "60%",
            position: "relative",
            
            "& span": {
                marginBottom: "8px",
            },
            "& img": {
                cursor: "pointer"
            }
        }
    },

    backgroundInativado:{
        backgroundColor: "#F5F5F5 !important"
    },

    inativado: {
        color: "#BDBDBD !important",
    },

    profissionalSaudeNome: {
        fontSize: '12px !important',
        fontWeight: 'bold',
        width: '100%'
    },

    mensagemInativada:{
        fontSize: '12px !important',
        color: "#BDBDBD",
        width: '100%',
        cursor: "pointer",
        transition: "0.2s",

        "&:hover":{
            color: grayLight
        }
    },

    hora: {
        position: 'absolute',
        bottom: -2,
        right: 15,
        color: '#fff',
        fontSize: '11px !important'
    },

    linkArquivo: {
        display: 'block',
        height: 80,
        marginBottom: 5,
    },

    right: {
        justifyContent: "flex-end"
    },

    image: {
        height: "40px",
        width: "40px",
        marginTop: 5,
        borderRadius: "31px"
    },

    imageRight: {
        height: "40px",
        width: "40px",
        marginTop: 5,
        marginRight: 5,
        borderRadius: "31px"
    },

    primary: {
        backgroundColor: primaryColor,
        color: "#fff",
    },

    green: {
        backgroundColor: green,
        color: "#fff",
    },

    purple: {
        backgroundColor: purple,
        color: "#fff",
    },

    blue: {
        backgroundColor: blue,
        color: "#fff",
    },

    yellow: {
        backgroundColor: yellow,
        color: "#fff",
    },

    sadtColor: {
        backgroundColor: sadtColor,
        color: "#fff",
    },

    procedimentoRealizadoColor: {
        backgroundColor: procedimentoRealizadoColor,
        color: "#fff",
    },

    anamneseColor: {
        backgroundColor: anamneseColor,
        color: "#fff",
    },

    avaliacaoAntropometricaColor: {
        backgroundColor: avaliacaoAntropometricaColor,
        color: "#fff",
    },

    guiaInternacaoColor: {
        backgroundColor: guiaInternacaoColor,
        color: "#fff",
    },

    red: {
        backgroundColor: red,
        color: "#fff",
    },
    
    pointer: {
        cursor: "pointer",
    },

    showMore:{
        cursor: "pointer"
    },

    showMoreMenu:{
        display: "flex",	
        flexDirection: "column",
        backgroundColor: "white",
        padding: "8px 0",
        borderRadius: "8px",
        width: 200,
        maxHeight: 80,
        height: 'fit-content',
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
        "& h3":{
            backgroundColor: "white",
            margin: 0,
            padding: "12px 16px",
            color: "#505050",
            fontSize: 12,
            cursor: "pointer",
        },
        "& h3:hover":{
            backgroundColor: "#EEEEEE"
        }
    },
    textMessage: {
        overflowWrap: 'break-word',
        width: '100%'
    },
    huggDetails: {
        fontSize: '10px !important',
        color: '#FFF',
        marginTop: '4px',
        marginBottom: '4px',
        alignSelf: 'flex-end'
    }
};

export default mensagemAtendimentoStyle;
