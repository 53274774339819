import React from 'react';
import {withStyles} from "@material-ui/core";
import MaskedInput from "react-text-mask";
import FormControl from '@material-ui/core/FormControl';
import { datePeriodMask } from './utils/masks';
import CalendarIcon from '../Icon/Calendar';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '2px 6px 0 6px',
    justifyContent: 'space-between',
    height: '28px',
    background: '#f2f2f2',
    color: '#505050',
    borderRadius: '8px',
  },
  input: {
    border: 'none',
    color: '#505050',
    background: '#f2f2f2',
    flex: '1',
    maxWidth: '185px',
    width: '100%'
  },
  todayIcon: {
    fontSize: '18px',
    cursor: 'pointer',
    color: '#868686'
  }
});

const PeriodInputDateForm = ({ classes, onClickCalendar, value, onChange, disabled }) => {
  return (
      <FormControl className={classes.container}>
        <MaskedInput 
          mask={datePeriodMask}
          showMask
          className={classes.input}
          value={value}
          onChange={(e) => onChange(e)}
          guide={false}
          disabled={disabled}
          placeholder="__/__/____ à __/__/____"
        />
        <CalendarIcon onClick={onClickCalendar}/>
      </FormControl>
  );
};

export default withStyles(styles)(PeriodInputDateForm);