import Colors from "../../../template/Colors";

const styles = {
  dialogPaper: {
    maxWidth: "540px",
    maxHeight: "390px",
    height: "100%",
    overflow: 'hidden',
  },
  modalHeader: {
    minHeight: 64,
    padding: "0px 32px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    justifyContent: "space-between",
  },
  titleModal: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "27px",
    color: Colors.commons.secondary,
  },
  buttonClose: {
    width: 32,
    height: 32,
    background: "#FB7676",
    borderRadius: "100px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: Colors.commons.darkRed,
    },
    "& svg": {
      color: "#fff",
    },
  },
  toggleButtons: {
    margin: "8px 24px",
  },
  inputRetangular: {
    borderRadius: "8px",
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  titleField: {
    color: "#868686",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "4px",
  },
  contentInformacoesMedicamento: {
    padding: "0 24px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 32px",
    gap: "10px",
    height: "69px",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    alignItems: "center",
  },
  buttonCancelar: {
    color: Colors.commons.gray9,
    cursor: "pointer",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontWeight: 700,
  },
  contentModal: {
    height: 'calc(100% - 125px)',
    "&>div": {
        height: '100%',
    }
  },
  switchRoot: {
    marginLeft: '-10px'
  },
  switchBase: {
    height: 'fit-content'
  }
};

export default styles;
