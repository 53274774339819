import React, { useEffect, useState } from "react";
import moment from "moment";
import { CircularProgress, Fab, Grid, withStyles } from "@material-ui/core";
import Dialog from "../../../components/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "./VersoesSistemaStyle";
import ButtonLong from "../../../components/Button/ButtonLong";
import {
  findAllVersaoSistema,
  marcarVisualizacaoVersaoSistema,
} from "../../../services/UnidadeService";
import { inject } from "mobx-react";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import { Close } from "@material-ui/icons";
import PageTitle from "../../../components/PageTitle/PageTitle";

const VersoesSistemaModal = (props) => {
  const { classes, open, versoes, screen, close } = props;

  const [loading, setLoading] = useState(false);
  const [versoesSistema, setVersoesSistema] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [last, setLast] = useState(0);
  const [loadingVersoes, setLoadingVersoes] = useState(false);

  useEffect(() => {
    screen === "Profile" ? loadVersoesSistema() : setVersoesSistema(versoes);
  }, []);

  useEffect(() => {
    pageNumber !== 0 && loadVersoesSistema();
  }, [pageNumber]);

  const loadVersoesSistema = async () => {
    try {
      setLoadingVersoes(true);

      const response = await findAllVersaoSistema({
        pageableDTO: {
          pageNumber,
          pageSize: 10,
          sortDir: "DESC",
          sortField: "dataHoraLancamento",
        },
      });
      setVersoesSistema([...versoesSistema, ...response.content]);
      setLast(response.last);
    } finally {
      setLoadingVersoes(false);
    }
  };

  const handleClickConfirmaVisualizacao = async () => {
    setLoading(true);
    const versoesSistemaId = versoes.map((item) => {
      return item.id;
    });

    await marcarVisualizacaoVersaoSistema(versoesSistemaId);
    close();

    setLoading(false);
  };

  const loadMore = () => {
    if (loadingVersoes) {
      return;
    }

    setPageNumber(pageNumber + 1);
  };

  return (
    <>
    <PageTitle title="Versões"/>
    <Dialog
      classes={{
        scrollPaper: classes.scrollPaper,
        paper: classes.paper,
      }}
      maxWidth="sm"
      open={open}
      fullWidth
    >
      <div className={classes.dialogHeader}>
        <div className={classes.headerTitle}> Novas atualizações </div>
        {screen === "Profile" && (
          <Fab className={classes.buttonActionClose} onClick={close}>
            <Close />
          </Fab>
        )}
      </div>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <Scroll
          loadMore={loadMore}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
        >
          {versoesSistema.map((item, index) => {
            return (
              <div key={index}>
                <div className={classes.title}>
                  <div className={classes.versao}> {item.versao} </div>
                  <div className={classes.dataHoraLancamento}>
                    {moment(item.dataHoraLancamento).format(
                      "DD/MM/YYYY - HH:mm"
                    )}
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: item.changelog }} />
              </div>
            );
          })}
        </Scroll>
        {loadingVersoes && (
          <div className={classes.notFoundContainer}>
            <CircularProgress />
          </div>
        )}
        {screen === "Dashboard" && (
          <Grid className={classes.buttonConfirm}>
            <ButtonLong
              colorCustom="green"
              disabled={loading}
              onClick={() => handleClickConfirmaVisualizacao()}
            >
              Ok
              {loading && (
                <CircularProgress
                  size={18}
                  color={"#fff"}
                  className={classes.circularLoading}
                />
              )}
            </ButtonLong>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
    </>
  );
};

const VersoesSistemaModalWithStyles = withStyles(styles)(VersoesSistemaModal);
export default inject("unidadeStore")(VersoesSistemaModalWithStyles);
