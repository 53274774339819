export const mensagemPadrao = {
    agendamento: [
      'tipoModified',
      'dataChegadaSujeitoAtencaoModified',
      'dataNascimentoModified',
      'solicitanteIdModified',
      'profissionalSaudeIdModified',
      'salaIdModified',
      'telefoneModified',
      'convenioIdModified',
      'pacienteIdModified',
    ],
};