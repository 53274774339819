import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import {
  Grid,
  DialogContent,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Checkbox,
  Fab,
} from "@material-ui/core";
import {
  Save as SaveIcon,
  Search,
  Clear as ClearIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import HeaderModalConfiguracoes from "../Header/HeaderModalConfiguracoes";
import Dialog from "../Dialog/Dialog";
import ButtonPrimary from "../Button/ButtonPrimary";
import Colors from "../../template/Colors";
import {
  Radio,
  RadioGroup,
} from "../RadioButton/RadioButtonModalConfiguracoes";
import InputSearch from "../Input/Input";
import Scroll from "../InfiniteScroll/Scroll";
import { TextField } from "../../components/TextField";
import InputMaskCurrency from "../../components/Input/InputMaskCurrency";
import PageTitle from "../PageTitle/PageTitle";
import { pageConfiguracoes } from "../../utils/getPageTitle";
import MultiToggleButtons from "../Button/MultiToggleButtons";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import Auditoria from "../Auditoria/Auditoria";
import { tabs } from "../../stores/ModalConfiguracoes.store";

export const status = [
  { name: "Ativo", label: "Ativo", value: "true" },
  { name: "Inativo", label: "Inativo", value: "false" },
];

export const opcoes = {
  convenio: [
    { name: "procedimento", value: "procedimento" },
    { name: "produto", value: "produto" },
    { name: "tipo consulta", value: "agendamentoTipo" },
  ],
  sala: [
    { name: "procedimentos", value: "procedimentos" },
    { name: "profissionaisSaude", value: "profissionaisSaude" },
  ],
  procedimento: [
    { name: "convenio", value: "convenio" },
    { name: "produto", value: "produto" },
  ],
  produto: [
    { name: "procedimentos", value: "procedimentos" },
    { name: "convenios", value: "convenios" },
  ],
  agendamentoTipo: [{ name: "convenio", value: "convenio" }],
};

const ModalConfiguracoes = observer((props) => {
  const {
    classes,
    handleChange,
    handleChangeCheckbox,
    itemSelecionado,
    onClose,
    handleSave,
    loadingSave,
    tipoOpcao,
    onChangeTipo,
    texto,
    onChangeSearchModal,
    handleClickClearModal,
    onKeypressSearchModal,
    handleClickSearch,
    searchModal,
    loadingListaModal,
    listaModal,
    loadMore,
    lastScroll,
    disableButton,
    pesquisaPor,
    handleChangeTuss,
    handleCodigoTermoLoadOptions,
    handleDescricaoLoadOptions,
    headerCustom,
    tabSelected,
    changeTabSelected,
    modalConfiguracoesStore,
    handleConvenioSistemaLoadOptions,
    handleChangeConvenioSistema,
  } = props;

  const { opcao, titulo, showModal } = modalConfiguracoesStore;

  const [listaOpcao, setListaOpcao] = useState([]);

  useEffect(() => {
    changeTabSelected(tabs.INFORMACOES)

    switch (opcao) {
      case "convenio":
        return setListaOpcao(opcoes.convenio);
      case "procedimento":
        return setListaOpcao(opcoes.procedimento);
      case "sala":
        return setListaOpcao(opcoes.sala);
      case "produto":
        return setListaOpcao(opcoes.produto);
      case "agendamentoTipo":
      default:
        return setListaOpcao(opcoes.agendamentoTipo);
    }
  }, []);

  const validarTipoListaComValor = () => {
    const listaComDadoValor =
      opcao === "convenio" ||
      (opcao === "procedimento" && tipoOpcao === "convenio") ||
      (opcao === "produto" && tipoOpcao === "convenios") ||
      opcao === "agendamentoTipo";

    return listaComDadoValor;
  };

  const validarTipoListaComQuantidade = () => {
    const listaComDadoQuantidade =
      (opcao === "produto" && tipoOpcao === "procedimentos") ||
      (opcao === "procedimento" && tipoOpcao === "produto");

    return listaComDadoQuantidade;
  };

  const getPageTitle = () => {
    const acao = itemSelecionado?.id ? "Editar" : "Novo";

    return pageConfiguracoes(`${acao} ${titulo}`);
  };

  return (
    <>
      <PageTitle title={getPageTitle()} />
      <Dialog
        open={showModal}
        classes={{
          paper: classes.paper,
        }}
        maxWidth="md"
        fullWidth
      >
        <div className={classes.tituloFechar}>
          <span className={classes.titulo}>
            {!!itemSelecionado.id ? `Editar ${titulo}` : `Novo ${titulo}`}
          </span>
          <Fab className={classes.buttonActionClose} onClick={onClose}>
            <CloseIcon />
          </Fab>
        </div>
        <MultiToggleButtons
          options={["Informações", "Auditoria"]}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
          classes={{ buttonsContainer: classes.buttonsTab }}
          disabled={!itemSelecionado?.id}
          posicaoDesabilitada={[1]}
        />

        {tabSelected === 0 ? (
          <>
            <HeaderModalConfiguracoes
              itemSelecionado={itemSelecionado}
              status={status}
              handleChange={handleChange}
              titulo={titulo}
              handleChangeTuss={handleChangeTuss}
              handleCodigoTermoLoadOptions={handleCodigoTermoLoadOptions}
              handleDescricaoLoadOptions={handleDescricaoLoadOptions}
              headerCustom={headerCustom}
              handleConvenioSistemaLoadOptions={handleConvenioSistemaLoadOptions}
              handleChangeConvenioSistema={handleChangeConvenioSistema}
            />
            <DialogContent className={classes.dialogContent}>
              <Grid container className={classes.contentESalvar}>
                <div className={classes.content}>
                  <div className={classes.radioContent}>
                    <RadioGroup name="tipo" />
                    {listaOpcao &&
                      listaOpcao.map((item) => (
                        <Radio
                          key={item.value}
                          value={item.value}
                          className={classes.radioButton}
                          checked={tipoOpcao === item.value}
                          onChange={() => onChangeTipo(item.value)}
                          label={item.name}
                        />
                      ))}
                  </div>
                  <div className={classes.conteudoSelecionado}>
                    <div>
                      <span> {texto} </span>
                      <Divider className={classes.divider} />
                      <InputSearch
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              className={classes.iconButtonSearch}
                              onClick={handleClickSearch}
                            >
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className={classes.iconButtonSearch}
                              onClick={handleClickClearModal}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        classInputRoot={classes.inputRootSearch}
                        classInput={classes.inputSearch}
                        className={classes.searchModal}
                        name={"search"}
                        placeholder={`Pesquisa por ${pesquisaPor}`}
                        value={searchModal}
                        onChange={onChangeSearchModal}
                        onKeyPress={onKeypressSearchModal}
                      />
                    </div>
                    <div className={classes.lista}>
                      <Scroll
                        loadMore={loadMore}
                        hasMore={!lastScroll}
                        pageStart={0}
                        initialLoad={false}
                        className={classes.scrollContainerModelos}
                      >
                        {listaModal.length > 0 &&
                          listaModal.map((itemLista, index) => (
                            <div className={classes.linha} key={index}>
                              <Checkbox
                                color={"primary"}
                                onChange={() =>
                                  handleChangeCheckbox(itemLista, index)
                                }
                                checked={itemLista[tipoOpcao].checked}
                              />
                              <div className={classes.procedimento}>
                                {itemLista[tipoOpcao].nome ||
                                  itemLista[tipoOpcao].descricao}
                              </div>
                              {validarTipoListaComValor() && (
                                <div
                                  className={
                                    !itemLista[tipoOpcao].checked
                                      ? classes.valor
                                      : classes.valorDesabilitado
                                  }
                                >
                                  <span
                                    className={
                                      itemLista[tipoOpcao].checked
                                        ? classes.spanValor
                                        : classes.spanValorDesabilitado
                                    }
                                  >
                                    Valor:
                                  </span>
                                  <InputMaskCurrency
                                    name={"valor"}
                                    value={
                                      itemLista.valor === 0
                                        ? ""
                                        : itemLista.valor
                                    }
                                    className={
                                      itemLista[tipoOpcao].checked
                                        ? classes.inputValor
                                        : classes.inputValorDesabilitado
                                    }
                                    onChange={(e) => handleChange(e, itemLista, index)}
                                    disabled={!itemLista[tipoOpcao].checked}
                                  />
                                </div>
                              )}
                              {validarTipoListaComQuantidade() && (
                                <div
                                  className={
                                    !itemLista[tipoOpcao].checked
                                      ? classes.valor
                                      : classes.valorDesabilitado
                                  }
                                >
                                  <span
                                    className={
                                      itemLista[tipoOpcao].checked
                                        ? classes.spanValor
                                        : classes.spanValorDesabilitado
                                    }
                                  >
                                    Quantidade:
                                  </span>
                                  <TextField
                                    name={"quantidade"}
                                    value={itemLista.quantidade}
                                    className={
                                      itemLista[tipoOpcao].checked
                                        ? classes.spanValor
                                        : classes.spanValorDesabilitado
                                    }
                                    onChange={(e) => handleChange(e, itemLista, index)}
                                    disabled={!itemLista[tipoOpcao].checked}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        {loadingListaModal && (
                          <div className={classes.notFoundContainer}>
                            <CircularProgress />
                          </div>
                        )}
                      </Scroll>
                    </div>
                  </div>
                </div>
                <Grid item xs={12}>
                  <div className={classes.divBotaoSalvar}>
                    <ButtonPrimary
                      onClick={handleSave}
                      className={classes.botaoSalvar}
                      disabled={disableButton || loadingSave}
                    >
                      {loadingSave ? (
                        <CircularProgress
                          color="inherit"
                          size={12}
                          style={{ marginLeft: 10 }}
                        />
                      ) : (
                        <SaveIcon />
                      )}
                      <span className={classes.labelSalvar}>
                        
                        {`Salvar ${titulo}`}
                      </span>
                    </ButtonPrimary>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <div className={classes.contentAuditoria}>
            <Auditoria />
          </div>
        )}
      </Dialog>
    </>
  );
});

const style = {
  paper: {
    maxWidth: "744px",
    height: 628,
    overflow: 'hidden',
  },
  radioContent: {
    display: "flex",
    flexDirection: "column",
  },
  botaoSalvar: {
    borderRadius: 85,
    width: "auto",
    padding: "7px 13px",
    justifyContent: "end",
  },
  labelSalvar: {
    fontSize: "14px !important",
    marginLeft: 10,
  },
  divBotaoSalvar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "24px",
  },
  dialogContent: {
    background: Colors.commons.grayLight,
    padding: "0 0 24px 24px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    height: "90%",
    overflow: "auto",
    width: "100%",
  },
  contentESalvar: {
    height: "100%",
  },
  divider: {
    margin: "16px 0",
  },
  iconButtonSearch: {
    height: 35,
    width: 35,
  },
  inputRootSearch: {
    border: "none",
  },
  inputSearch: {
    fontFamily: "Poppins!important",
  },
  searchModal: {
    minWidth: "100%",
    height: 48,
    borderRadius: "8px !important",
    border: "none !important",
    background: "#F2F2F2",
    justifyContent: "center",
    fontWeight: 400,
    marginBottom: "16px",
  },
  conteudoSelecionado: {
    borderRadius: "0px 0px 10px 10px",
    background: "#fff",
    padding: "16px 23px 10px",
    marginBottom: "18px",
    marginRight: "24px",
    boxShadow:
      "5px 5px 20px rgba(112, 124, 151, 0.05), 5px 5px 25px rgba(112, 124, 151, 0.05)",
    width: "100%",
  },
  lista: {
    height: "calc(100% - 130px)",
  },
  linha: {
    display: "grid",
    gridTemplateColumns: "40px 1fr 30%",
    alignItems: "center",
    paddingBottom: 10,
  },
  valor: {
    display: "flex",
    flexDirection: "column",
  },
  spanValor: {
    color: Colors.primary.main,
    "& input": {
      fontFamily: "Poppins !important",
      fontSize: 14,
      paddingLeft: 0,
    },
    "& fieldset": {
      borderTop: 0,
      borderRight: 0,
      borderLeft: 0,
      borderRadius: 0,
    },
  },
  inputValor: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
  },
  inputValorDesabilitado: {
    color: Colors.commons.gray,
    borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
  },
  spanValorDesabilitado: {
    color: Colors.commons.gray,
    "& input": {
      color: Colors.commons.gray,
      paddingLeft: 0,
      fontFamily: "Poppins !important",
      fontSize: 14,
    },
    "& fieldset": {
      borderTop: 0,
      borderRight: 0,
      borderLeft: 0,
      borderRadius: 0,
    },
  },
  procedimento: {
    color: "#505050",
    fontWeight: 700,
  },
  scrollContainerModelos: {
    height: "80vh",
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    margin: 10,
  },
  tituloFechar: {
    minHeight: "64px",
    display: "flex",
    padding: "0 32px",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    justifyContent: "space-between",
  },
  titulo: {
    color: Colors.primary.main,
    fontWeight: "bold",
    fontSize: "18px !important",
  },
  buttonActionClose: {
    backgroundColor: Colors.commons.red,
    width: "35px",
    height: "35px",
    color: "white",
    marginLeft: 10,
    "&:hover": {
      background: Colors.commons.darkRed,
    },
  },
  buttonsTab: {
    margin: "8px 24px 0px",
  },
  contentAuditoria: {
    marginTop: '16px',
    height: 'calc(100% - 117px)',
    '&>div': {
      height: '100%',
    }
  },
};

const ModalConfiguracoesWithStyles = withStyles(style)(ModalConfiguracoes);
export default inject("modalConfiguracoesStore")(ModalConfiguracoesWithStyles);
