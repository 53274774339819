import React from 'react';
import PrintIcon from "../../../components/Icon/Print";
import PencilIcon from "../../../components/Icon/Pencil";
import VisibilityIcon from "../../../components/Icon/VisibilityIcon";
import ArrowDownloadIcon from "../../../components/Icon/ArrowDownload";

export const menuOpcoes = ({isTipoAnamnese, solicitacao, solicitacoesPacientesStore, showBaixar}) => [
    {
        ativo: isTipoAnamnese,
        label: "Editar",
        onClick: () => solicitacoesPacientesStore.handleEditar(solicitacao),
        icon: <PencilIcon color="#505050" />,
    },
    {
        ativo: solicitacao.arquivoTipo === "IMAGEM" || isTipoAnamnese,
        label: "Visualizar",
        onClick: () => solicitacoesPacientesStore.handleVisualizar(solicitacao),
        icon: <VisibilityIcon color="#505050" />,
    },
    {
        ativo: solicitacao.arquivoTipo === "IMAGEM",
        label: "Imprimir",
        onClick: () => solicitacoesPacientesStore.handleImprimir(solicitacao),
        icon: <PrintIcon color="#505050" />,
    },
    {
        ativo: showBaixar || solicitacao.arquivoTipo !== "ANAMNESE",
        label: "Baixar",
        onClick: () => solicitacoesPacientesStore.handleDownload(solicitacao),
        icon: <ArrowDownloadIcon color="#505050" />,
    },
]