import React from 'react';
import { withStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

const styles = ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textStyle: {
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#333333',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const CollapseButton = ({classes, buttonStyle, buttonTextStyle, onClick, type}) => {
  return (
    <>
      {type === 'expand' ? 
        <div onClick={onClick} className={buttonStyle || classes.button}> 
          <span className={buttonTextStyle || classes.textStyle}>MOSTRAR MAIS</span>
          <KeyboardArrowDown />
        </div>:
        <div onClick={onClick} className={buttonStyle || classes.button}>
          <span className={buttonTextStyle || classes.textStyle}>MOSTRAR MENOS</span>
          <KeyboardArrowUp />  
        </div>
      }
    </>
  )
};

export default withStyles(styles)(CollapseButton); 
