import {observable, action} from 'mobx';
import {findUnidadeLogada} from '../services/UnidadeService';
import {AuthApi} from "../config/api";
import {createMutation} from "../pages/Querys";
import localStorageService, {USUARIO_LOGADO_KEY} from "../services/storage";
import { findAllSala } from "../services/SalaService";
import BaseStore from './Base.store';

const defaultUnidades = [
    { nome: 'Selecione', id: 0 }
];

const defaultProfissionaisSaude = [
    { nome: 'Selecione', id: 0 }
];

const defaultSala = [
    { nome: 'Selecione', id: '0' }
];

export default class FiltroHeaderStore extends BaseStore {
    @observable unidades = defaultUnidades;
    @observable profissionaisSaude = defaultProfissionaisSaude;
    @observable salas = defaultSala;
    @observable unidade = 0;
    @observable profissionalSaude = 0;
    @observable sala = '0';
    @observable showFiltroProfissionalSaude = false;
    @observable showFiltroSalas = false;
    @observable filtroHeaderErrorMessage = null;
    @observable loadingFiltroHeader = false;
    @observable isLoadingSala = false;
    usuarioStore = null;
    profissionalSaudeStore = null;

    constructor(rootStore) {
        super();
    }

    @action async checkRoles(screen) {
        this.showFiltroProfissionalSaude = false;
        this.showFiltroSalas = false;
        const usuarioLogado = await this.usuarioStore.getUsuarioLogado();

        if(!usuarioLogado) {
            return;
        }
        
        const { authorities } = usuarioLogado;
        
        if(!authorities) {
            return;
        }
        
        if (screen === 'AGENDAMENTO') {
            this.showFiltroProfissionalSaude = authorities.some(a => 
                a.authority === 'ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS');
        }
        if (screen === 'CONFIGURACAO_HORARIO_ATENDIMENTO') {
            this.showFiltroProfissionalSaude = authorities.some(a => 
                a.authority === 'ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_READ_OUTROS_PROFISSIONAIS');
        }
        if (screen === 'CONFIGURACAO_RECEITA' || screen === 'CADASTRO_CONVENIO') {
            this.showFiltroProfissionalSaude = true;
        }
        if (screen === 'CONFIGURACAO_HORARIO_SALAS' || screen === 'AGENDAMENTO_SALA') {
            this.showFiltroSalas = true;
        }
        
        return this;
    }

    @action async changeFiltros(filtroAtual = { unidadeId: 0, profissionalSaudeId: 0, showSecretarioLogado: false }, unidadeChanged = false) {
        if(!this.usuarioStore) {
            return;
        }
        
        try {
            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();
            if(!unidadeChanged && !filtroAtual.unidadeId && unidadeAtual && unidadeAtual.id) {
                this.unidade = unidadeAtual;
                filtroAtual.unidadeId = unidadeAtual.id;
            }
            
            if(!unidadeChanged && !filtroAtual.profissionalSaudeId && profissionalSaudeAtual && profissionalSaudeAtual.id) {
                this.profissionalSaude = profissionalSaudeAtual;
                filtroAtual.profissionalSaudeId = profissionalSaudeAtual?.id;
            }
        
            if(unidadeChanged) {
                this.profissionalSaude = 0;
                filtroAtual.profissionalSaudeId = 0;
            }
            
            const unidades = await this.usuarioStore.getUnidades();
            const profissionaisSaude = await this.profissionalSaudeStore.findByUnidadeComAgenda(filtroAtual.unidadeId);
            
            this.unidades = defaultUnidades.concat(unidades.map(u => ({
                nome: u.nome,
                id: u.id,
            })));

            let hasDefaultProfissionalSaude = false;
            
            let listaProfissionaisSaude = defaultProfissionaisSaude;

            if( filtroAtual.showSecretarioLogado && !profissionalSaudeAtual.possuiAgenda) {
                const secretarioLogado = [
                    { nome: profissionalSaudeAtual.nome, id: profissionalSaudeAtual.id }
                ];
                
                listaProfissionaisSaude = listaProfissionaisSaude.concat(secretarioLogado);
            }

            this.profissionaisSaude = listaProfissionaisSaude.concat(profissionaisSaude.map(profissional => {
                if (filtroAtual.profissionalSaudeId === profissional.id) {
                    hasDefaultProfissionalSaude = true;
                }
                const especialidade = profissional?.especialidades?.[0]?.especialidade || null;
                return {
                    nome: profissional.nome,
                    id: profissional.id,
                    utilizaAgendamentoRetorno: profissional.utilizaAgendamentoRetorno,
                    ...(especialidade && { especialidade })
                };
            }));

            if (!hasDefaultProfissionalSaude && profissionaisSaude instanceof Array && profissionaisSaude.length === 1) {
                this.profissionalSaude = profissionaisSaude[0];
                filtroAtual.profissionalSaudeId = this.profissionalSaude?.id;
                hasDefaultProfissionalSaude = true;
            }
            
            if (!hasDefaultProfissionalSaude) {
                this.profissionalSaude = 0;
                filtroAtual.profissionalSaudeId = 0;
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    @action
    async getFiltroAtual() {
        if (this.loadingFiltroHeader) return;

        try {
            const { unidade, profissionalSaude, sala } = this;
            this.loadingFiltroHeader = true;

            if (unidade && profissionalSaude) {
                return {
                    unidadeId: unidade.id,
                    profissionalSaudeId: profissionalSaude.id,
                    salaId: sala?.id
                };
            }

            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const idUnidade = unidade?.id || unidadeAtual?.id;
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();
            const profissionaisSaude = await this.profissionalSaudeStore.findByUnidadeComAgenda(idUnidade);
            const profissionalOnLocalStorage = await localStorageService.get('current_agenda_owner');

            if (Array.isArray(profissionaisSaude) && profissionaisSaude.length === 1) {
                this.unidade = unidade || unidadeAtual;
                this.profissionalSaude = profissionaisSaude[0];
            }
            else if (profissionalOnLocalStorage) {
                this.profissionalSaude = profissionalOnLocalStorage;
            }
            else {
                const defaultProfissional = profissionaisSaude.find(p => p.id === profissionalSaudeAtual?.id);
                this.profissionalSaude = defaultProfissional || profissionaisSaude[0];
            }

            return {
                unidadeId: idUnidade,
                profissionalSaudeId: this.profissionalSaude?.id,
                salaId: sala?.id
            };
            
        } catch (error) {
            console.error("Error fetching filter data:", error);
            throw error;
        } finally {
            this.loadingFiltroHeader = false;
        }
    }

    @action async listarSalas(pageNumber, search) {
        if (this.isLoadingSala) return;
        this.isLoadingSala = true;
        const response = await findAllSala(
            {
                ativo: true,
                search: search ?? '',
                pageableDTO:{
                    pageNumber: pageNumber ?? 0,
                    pageSize: 30,
                    sortDir: 'ASC',
                    sortField: 'nome'
                }
            }
        );

        const salas = response?.data?.data?.findAllSala?.content || [];

        this.salas = defaultSala.concat(salas.map(sala => ({
            nome: sala.nome,
            id: sala.id
        })));

        this.isLoadingSala = false;
        return response.data.data.findAllSala
    }

    async alterarUnidadeLogada(unidadeId) {
    try {
        this.filtroHeaderErrorMessage = null;
        if (!unidadeId) {
            throw new Error(
                "É necessário selecionar uma unidade."
            );
        }

        const response = await AuthApi.post('graphql',
            JSON.stringify({
                query: createMutation({
                    name: `alterarUnidadeAtual`,
                    objType: 'Long',
                    objName: 'idUnidade',
                    attrsReturn: `
                        id
                        nome
                        login
                        fotoPerfil
                        authorities {
                            authority
                        }
                    `
                }, 'mutation'),
                variables: { idUnidade: unidadeId }
            })
        );
            await localStorageService.set(USUARIO_LOGADO_KEY, response.data.data.alterarUnidadeAtual);
            await localStorageService.set('current_agenda_owner', null);

            await findUnidadeLogada();

            return response.data.data.alterarUnidadeAtual
        
    } catch (error) {
        this.filtroHeaderErrorMessage = error;
    }
}}