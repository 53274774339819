import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Auditoria from "../../../components/Auditoria/Auditoria";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";

const tabs = {
  INFORMACOES: 0,
  AUDITORIA: 1,
};

const ContentConfiguracoes = observer(
  ({
    classes,
    auditoriaStore,
    children,
    setAuditoriaTela,
    quantidadeProcedimentos,
  }) => {
    const [tabSelected, setTabSelected] = useState(tabs.INFORMACOES);

    const changeTabSelected = async (tabSelected) => {
      if (tabSelected === tabs.AUDITORIA) {
        setAuditoriaTela();
        await auditoriaStore.loadAuditItems();
      }

      setTabSelected(tabSelected);
    };

    const renderTab = () => {
      return tabSelected === 0 ? (
        children
      ) : (
        <div className={classes.contentAuditoria}>
          <Auditoria />
        </div>
      );
    };

    return (
      <div className={classes.content}>
        <MultiToggleButtons
          options={["Informações", "Auditoria"]}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
          classes={{ buttonsContainer: classes.buttonsTab }}
          disabled={quantidadeProcedimentos === 0}
          posicaoDesabilitada={[1]}
        />
        {renderTab()}
      </div>
    );
  }
);

const styles = {
  content: {
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  buttonsTab: {
    margin: "16px",
  },
  contentAuditoria: {
    padding: "0 16px",
    height: "calc(100vh - 346px)",
    overflow: "auto",
  },
};

const ContentConfiguracoesWithStyles = withStyles(styles)(ContentConfiguracoes);
export default inject("auditoriaStore")(ContentConfiguracoesWithStyles);
