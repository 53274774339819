import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Local from "../Components/Local";
import FieldTitle from "../Components/FieldTitle";
import Dialog from "../../../../../components/Dialog/Dialog";
import ButtonLong from "../../../../../components/Button/ButtonLong";
import { InputForm } from "../../../../../components/Input/InputDadosCadastraisPaciente";

const ModalObservacao = observer(({ classes, odontogramaStore, prontuarioStore }) => {
  const { modalObservacao, locaisSelecionados } = odontogramaStore;
  const { showModalObservacao, observacao } = modalObservacao;

  const localSelecionado = locaisSelecionados[0];
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    localSelecionado && getObservacao();
  }, []);

  const onObservacaoChange = (value) => {
    modalObservacao.observacao = value;
  };

  const getObservacao = () => {
    modalObservacao.observacao = localSelecionado?.observacao;
  };

  const cancelar = () => {
    odontogramaStore.handleCloseModalObservacao();
  };

  const salvar = async () => {
    try {
      setLoading(true)
      const local = localSelecionado.local === 4 ? "dente" : "mucosaOral";
  
      await odontogramaStore.salvarObservacao({
        observacao,
        localId: localSelecionado.id,
        local
      });

      odontogramaStore.getOdontograma(
        prontuarioStore.sujeitoAtencaoSelected.id
      );
      odontogramaStore.handleCloseModalObservacao();
      odontogramaStore.openNotification("Observação salvo com sucesso.", "success")
    } finally {
      setLoading(false)
    }
  };

  const getLocal = () => {
    const local = localSelecionado?.local === 4 ? `Dente ${localSelecionado?.codigo}` : localSelecionado?.descricao

    return local;
  };

  return (
    <Dialog
      open={showModalObservacao}
      maxWidth="md"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.contentTitle}>
        <div className={classes.title}> Observação </div>
        <Local label={getLocal()} />
      </div>
      <div>
        <FieldTitle title="Observações:"/>
        <InputForm
          id="observacao"
          value={observacao}
          className={classes.inputObservacoes}
          rowsMax={20}
          rows={4}
          multiline
          onChange={(e) => onObservacaoChange(e.target.value)}
        />
      </div>
      <div className={classes.contentButtons}>
        <ButtonLong
          colorCustom="transparency"
          noShadow
          onClick={cancelar}
          className={classes.buttonCancelar}
          disabled={loading}
        >
          Cancelar
        </ButtonLong>
        <ButtonLong colorCustom="green" noShadow onClick={salvar} disabled={loading} loading={loading}>
          Salvar
        </ButtonLong>
      </div>
    </Dialog>
  );
});

const styles = {
  paper: {
    padding: 32,
    maxWidth: "361px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  contentTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: "24px",
    color: "#505050",
  },
  inputObservacoes: {
    background: "#F2F2F2",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "111px",
    overflow: "auto",
    padding: "0 8px",
    width: 'calc(100% - 16px)'
  },
  contentButtons: {
    display: "flex",
  },
  buttonCancelar: {
    border: "none",
  },
};

const ModalObservacaoWithStyle = withStyles(styles)(ModalObservacao);
export default inject("odontogramaStore", "prontuarioStore")(ModalObservacaoWithStyle);
