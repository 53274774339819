import React from 'react';
import ThumbsUpIcon from '../../../../components/Icon/ThumbsUpIcon';
import ThumbsUpEmpytIcon from '../../../../components/Icon/ThumbsUpEmpytIcon';
import ThumbsDownIcon from '../../../../components/Icon/ThumbsDownIcon';
import ThumbsDownEmptyIcon from '../../../../components/Icon/ThumbsDownEmptyIcon';

const renderThumbs = (Component, props) => <Component size={20} {...props} />

const ThumbsDownEmpty = (props) => renderThumbs(ThumbsDownEmptyIcon, {...props, color: '#FB7676'});
const ThumbsDownFilled = (props) => renderThumbs(ThumbsDownIcon, {...props, color: '#FB7676'});
const ThumbsUpEmpyt = (props) => renderThumbs(ThumbsUpEmpytIcon, {...props, color: '#219A97'});
const ThumbsUpFilled = (props) => renderThumbs(ThumbsUpIcon, {...props, color: '#219A97'});

export {
  ThumbsDownEmpty,
  ThumbsDownFilled,
  ThumbsUpEmpyt,
  ThumbsUpFilled
};
