import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import {
  Popper,
  Paper,
} from "@material-ui/core";
import colors from "../../template/Colors";
import classNames from "classnames";

const styles = (theme) => ({
  select: {
    width: "100%",
    background: "#F2F2F2",
    cursor: "pointer",
    borderRadius: "100px",
    height: "33px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "space-between",
    "&>span": {
      color: "#505050",
      marginLeft: "15px",
      maxWidth: "65%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "&>svg": {
      marginRight: "5px",
    },
  },
  colorArrow: {
    color: colors.commons.gray7,
    background: "#F2F2F2",
  },
  popper: {
    zIndex: 1000,
    width: "100%",
    maxWidth: 300,
  },
  paper: {
    padding: "0"
  },
  checkboxOptions: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textOverflow: "ellipsis",
    '&>label': {
      padding: "8px",
      margin: 0,
      '&:hover': {
        backgroundColor: "#F2F2F2",
        transition: "all 0.3s ease",
      }
    }
  },
  selectDisabled: {
    opacity: 0.4,
  },
});

const MultipleSelectCheckbox = ({ classes, quantidadeChecked, listaOpcoes, changeCheckbox, withCustomText, customText, disabled }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open ? "no-transition-popper" : null;

  const handleClick = (event) => {
    if (disabled) return
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  return (
    <>
      <div onClick={handleClick} className={classNames(classes.select, disabled && classes.selectDisabled)}>
        <span> {withCustomText ? customText : quantidadeChecked || "Selecione"} </span>
        <ArrowDropDownIcon className={classes.colorArrow} />
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        className={classes.popper}
      >
        <Paper className={classes.paper}>
          <div className={classes.checkboxOptions}>
            {listaOpcoes.map((item, index) => (
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={item.checked}
                label={<span> {item.nome} </span>}
                key={index}
                onChange={() => changeCheckbox(item)}
              />
            ))}
          </div>
        </Paper>
      </Popper>
    </>
  );
};

export default withStyles(styles)(MultipleSelectCheckbox);
