import moment from "moment";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import CloseIcon from "../../components/Icon/CloseIcon";
import ButtonLong from "../../components/Button/ButtonLong";
import { updateUsuarioPublicidade } from "../../services/PublicidadeService";
import { Button } from "../../components/ui/Buttons";
import { buildUrlDownload } from "../../config/config";
import localStorageService, { ACCESS_TOKEN_KEY } from "../../services/storage";

const Publicidade = ({ classes, closePublicidade, publicidade }) => {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    vizualizaPublicidade();
  }, []);

  const vizualizaPublicidade = () => {
    updateUsuarioPublicidade({
      id: publicidade.id,
      interagiu: false,
      ultimaVisualizacao: moment().format("YYYY-MM-DDThh:mm:ss"),
    });
  };

  const handleClosePublicidade = () => {
    interagirPublicidade();
    closePublicidade();
  };

  const handleAcessa = () => {
    window.open(publicidade.conteudoPublicitario.linkDirecionamento, "_blank");
    interagirPublicidade();
    closePublicidade();
  };

  const interagirPublicidade = () => {
    updateUsuarioPublicidade({
      id: publicidade.id,
      interagiu: true,
    });
  };

  const getUrlDownload = async () => {
    const token = await localStorageService.get(ACCESS_TOKEN_KEY)
    const url = buildUrlDownload(publicidade.conteudoPublicitario.urlDownload, token)
    setUrl(url)
    return url
  }


  useEffect(() => {
    getUrlDownload()
  }, [publicidade])

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <div className={classes.info}>{publicidade.conteudoPublicitario.descricao}</div>
        <Button
          shape='circle'
          bgColor='#FB7676'
          shadow
          onClick={handleClosePublicidade}
        >
          <CloseIcon />
        </Button>
      </div>
      <img
        onClick={handleAcessa}
        src={url}   
        alt={`Publicidade ${publicidade.conteudoPublicitario.nome}`}
        className={classes.imagemPublicidade}
      />
      <div>
        <ButtonLong
          colorCustom="green"
          onClick={handleAcessa}
          className={classes.button}
        >
          Acessar
        </ButtonLong>
      </div>
    </div>
  );
};

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "16px",
    gap: "12px",
    position: "absolute",
    width: "478px",
    height: "341px",
    left: "72px",
    bottom: "0px",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: "16px 16px 0px 0px",
  },
  info: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#505050",
    width: "95%",
  },
  header: {
    display: "flex",
  },
  contentImagem: {
    height: "calc(100% - 78px)",
  },
  button: {
    width: "50%",
  },
  imagemPublicidade: {
    height: "240px",
    width: "480px",
    borderRadius: "16px",
    cursor: "pointer",
  },
};

export default withStyles(styles)(Publicidade);
