import { withStyles } from "@material-ui/core";
import React, { useState } from "react";
import Dialog from "../../../components/Dialog/Dialog";
import { inject } from "mobx-react";
import { Button } from "../../../components/ui/Buttons";
import CloseIcon from "../../../components/Icon/Close";
import { TextFieldSearch } from "../../../components/TextField";
import {
  createAgendamentoFilaEspera,
  findAllAgendamentoTipoPage,
  findAllEspecialidadeMedica,
  findAllProfissionalSaudeComAgenda,
  findAllSujeitoAtencao,
} from "../../../services/AgendamentoService";
import PhoneInput from "react-phone-input-2";
import pt from "react-phone-input-2/lang/pt.json";
import { InputForm, InputPhoneForm } from "../../../components/Modal/Input";
import string from "../../../utils/string";
import styles from "./styles";

const agendamentoDefault = {
  sujeitoAtencao: null,
  especialidadeMedica: null,
  profissionalSaude: null,
  telefone: "",
  tipo: null,
  observacao: "",
  telefoneDDI: "55",
};

const ModalListaEsperaItem = ({
  atendimentoStore,
  unidadeStore,
  onClose,
  open,
  classes,
  loadListaEspera,
}) => {
  const [agendamento, setAgendamento] = useState(agendamentoDefault);
  const [loadingSave, setLoadingSave] = useState(false);
  const {
    sujeitoAtencao,
    especialidadeMedica,
    profissionalSaude,
    telefoneDDI,
    telefone,
    tipo,
    observacao,
  } = agendamento;
  const { unidade } = unidadeStore || {};

  const disabled =
    !sujeitoAtencao ||
    !string.validaTelefone(telefone) ||
    !tipo ||
    !(especialidadeMedica || profissionalSaude?.id);

  const handleAgendamento = (value, field) => {
    if (field === "sujeitoAtencao") {
      const telefone = value.contato?.telefonePrincipal || "";
      const telefoneDDI = value.contato?.telefonePrincipalDDI || "";

      setAgendamento({ ...agendamento, [field]: value, telefone, telefoneDDI });
    } else {
      setAgendamento({ ...agendamento, [field]: value });
    }
  };

  const onTelefoneChangeComDDI = (phone, data) => {
    const [_, ...phoneNumber] = string
      .removeSpecialChars(phone)
      .split(data.dialCode);
    const phoneWithoutDialCode = phoneNumber.join(data.dialCode);
    setAgendamento({
      ...agendamento,
      telefone: phoneWithoutDialCode,
      telefoneDDI: data.dialCode,
    });
  };

  const loadEspecialidades = async (search, loadedOptions, { page }) => {
    const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);

    const response = await findAllEspecialidadeMedica({
      searchDTO: {
        search,
        ativo: true,
        unidadeId: unidadeLogada?.id,
      },
      pageableDTO: {
        sortDir: "ASC",
        sortField: "especialidade",
        pageNumber: page,
        pageSize: 20,
      },
    });

    const { content, last } = response;

    if (content) {
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    }
  };

  const loadSujeitoAtencao = async (search, loadedOptions, { page }) => {
    const response = await findAllSujeitoAtencao({
      searchDTO: {
        search,
        ativo: true,
        sortDir: "ASC",
        sortField: "nome",
        pageNumber: page,
        pageSize: 20,
      },
    });

    const { content, last } = response;

    if (content) {
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    }
  };

  const loadProfissionalSaude = async (search, loadedOptions, { page }) => {
    const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);

    const response = await findAllProfissionalSaudeComAgenda({
      search,
      ativo: true,
      unidadeId: unidadeLogada?.id,
      pageableDTO: {
        sortDir: "ASC",
        sortField: "nome",
        pageNumber: page,
        pageSize: 20,
      },
    });

    const { content, last } = response;

    if (content) {
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    }
  };

  const loadTipo = async (search, loadedOptions, { page }) => {
    const response = await findAllAgendamentoTipoPage({
      pageableDTO: {
        sortDir: "ASC",
        sortField: "descricao",
        pageNumber: page,
        pageSize: 20,
      },
      search,
      ativo: true,
    });

    const { content, last } = response;
    if (content) {
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    }
  };

  const onSave = async () => {
    try {
      setLoadingSave(true);
      await createAgendamentoFilaEspera({ agendamento });
      loadListaEspera({ isClearable: true });
      onClose();
    } catch (error) {
      atendimentoStore.openNotification("Erro ao salvar!", "error");
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{
        paper: classes.fullSizePaper,
      }}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <h1>Novo paciente para lista de espera</h1>
          <Button kind="transparent" shape="circle" onClick={onClose}>
            <CloseIcon size="24" />
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.searchInput}>
            <span>Paciente</span>
            <TextFieldSearch
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              placeholder={"Selecione"}
              onChange={(e) => handleAgendamento(e, "sujeitoAtencao")}
              value={sujeitoAtencao}
              menuPlacement={"auto"}
              getOptionLabel={(option) => option.nome}
              getOptionValue={(option) => option.id}
              withPaginate
              loadOptions={loadSujeitoAtencao}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </div>
          <div className={classes.searchInput}>
            <span>Telefone</span>
            {unidade.utilizaTelefoneInternacional ? (
              <PhoneInput
                value={telefoneDDI + telefone}
                onChange={(phone, data) => onTelefoneChangeComDDI(phone, data)}
                placeholder="Telefone Principal"
                preferredCountries={["br", "es"]}
                localization={pt}
                masks={{ br: "(..) .....-....", ar: ". ... ... ...." }}
                containerClass={classes.telefoneInputContainer}
                inputClass={classes.telefoneInput}
                buttonClass={classes.flagButton}
                countryCodeEditable={false}
                disableDropdown={!unidade.utilizaTelefoneInternacional}
              />
            ) : (
              <InputPhoneForm
                id="telefone"
                value={telefone}
                classes={{
                  input: classes.inputInfosTelefone,
                }}
                onChange={(e) => handleAgendamento(e.target.value, "telefone")}
              />
            )}
          </div>
          <div className={classes.searchInput}>
            <span>Tipo consulta</span>
            <TextFieldSearch
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              placeholder={"Selecione"}
              onChange={(e) => handleAgendamento(e, "tipo")}
              value={tipo}
              menuPlacement={"auto"}
              isClearable
              getOptionLabel={(option) => option.descricao}
              getOptionValue={(option) => option.id}
              withPaginate
              loadOptions={loadTipo}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </div>
          <div className={classes.searchInput}>
            <span>Profissional saúde</span>
            <TextFieldSearch
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              placeholder={"Selecione"}
              onChange={(e) => handleAgendamento(e, "profissionalSaude")}
              value={profissionalSaude}
              menuPlacement={"auto"}
              isClearable
              getOptionLabel={(option) => option.nome}
              getOptionValue={(option) => option.id}
              withPaginate
              loadOptions={loadProfissionalSaude}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </div>
          <div className={classes.searchInput}>
            <span>Especialidade</span>
            <TextFieldSearch
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              placeholder={"Selecione"}
              onChange={(e) => handleAgendamento(e, "especialidadeMedica")}
              value={especialidadeMedica}
              menuPlacement={"auto"}
              isClearable
              getOptionLabel={(option) => option.especialidade}
              getOptionValue={(option) => option.id}
              withPaginate
              loadOptions={loadEspecialidades}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </div>
          <div className={classes.searchInput}>
            <span>Observação</span>
            <InputForm
              value={observacao || ""}
              className={classes.observacao}
              classes={{
                input: classes.inputInfos,
              }}
              rows={4}
              multiline
              onChange={(e) => handleAgendamento(e.target.value, "observacao")}
            />
          </div>
        </div>
        <div className={classes.contentButton}>
          <Button
            onClick={onSave}
            disabled={disabled || loadingSave}
            loading={loadingSave}
          >
            Salvar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const stores = ["atendimentoStore", "unidadeStore"];
const ModalListaEsperaItemWithStyles = withStyles(styles)(ModalListaEsperaItem);
export default inject(...stores)(ModalListaEsperaItemWithStyles);
