import React from 'react';
import { withStyles } from '@material-ui/core';
import FilledCircleIcon from '../../../../../components/Icon/FilledCircleIcon';
import colors from '../../../../../template/Colors';
import string from '../../../../../utils/string';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center'
  },
  canceled: {
    textDecoration: 'line-through'
  }
});

const SituacaoTableCell = ({ classes, situacao, isCanceled }) => {
  const checkCanceled = typeof isCanceled === 'boolean';

  return (
    <div className={classes.container}>
      <FilledCircleIcon
        color={colors.status[String(situacao).toLowerCase()]}
      />
      <span className={checkCanceled && isCanceled ? classes.canceled : null}>
        {string.capitalize(situacao)}
      </span>
    </div>
  )
};

export default withStyles(styles)(SituacaoTableCell);