import { inject } from "mobx-react";
import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import PopperDentesSupranumerario from "./PopperDentesSupranumerario";

const DenteSupranumerario = observer(({
  classes,
  denteSupranumerario,
  odontogramaStore,
  prontuarioStore,
}) => {
  const popperRef = useRef(null);
  const sujeitoAtencaoId = prontuarioStore?.sujeitoAtencaoSelected?.id;

  const handleClickOpenPopper = (e) => {
    return popperRef?.current?.handleOpenPopper(e);
  };

  return (
    <>
      <div onClick={handleClickOpenPopper} className={classes.content}>
        +{denteSupranumerario.length}
      </div>
      <PopperDentesSupranumerario
        ref={popperRef}
        key={denteSupranumerario}
        placement="bottom-end"
        denteSupranumerario={denteSupranumerario}
        odontogramaStore={odontogramaStore}
        sujeitoAtencaoId={sujeitoAtencaoId}
      />
    </>
  );
});

const styles = {
  content: {
    position: "absolute",
    top: "40%",
    right: "-4px",
    height: "30px",
    width: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100px",
    background: "#F9FDFF",
    fontWeight: "600",
    fontSize: "10px",
    cursor: "pointer",
  },
};

const DenteSupranumerarioWithStyles = withStyles(styles)(DenteSupranumerario);
export default inject(
  "odontogramaStore",
  "prontuarioStore"
)(DenteSupranumerarioWithStyles);
