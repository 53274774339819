import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const VisibilityIcon = props => {
  return (
    <svg 
      width='21' 
      height='12' 
      viewBox='0 0 21 12' 
      fill='none' 
      xmlns='http://www.w3.org/2000/svg'
      { ...normalizeProps(props)}
    >
      <path 
        d='M10.6 3.80443C12.8091 3.80443 14.6 5.59529 14.6 7.80443C14.6 10.0136 12.8091 11.8044 10.6 11.8044C8.39086 11.8044 6.6 10.0136 6.6 7.80443C6.6 5.59529 8.39086 3.80443 10.6 3.80443ZM10.6 5.30443C9.21929 5.30443 8.1 6.42372 8.1 7.80443C8.1 9.18514 9.21929 10.3044 10.6 10.3044C11.9807 10.3044 13.1 9.18514 13.1 7.80443C13.1 6.42372 11.9807 5.30443 10.6 5.30443ZM10.6 0.299805C15.2135 0.299805 19.1961 3.44981 20.3011 7.86419C20.4017 8.26601 20.1575 8.67328 19.7557 8.77387C19.3539 8.87445 18.9466 8.63025 18.846 8.22844C17.9071 4.47776 14.5214 1.7998 10.6 1.7998C6.67693 1.7998 3.2901 4.48007 2.35286 8.23295C2.2525 8.63483 1.84536 8.87925 1.44349 8.77888C1.04161 8.67852 0.797194 8.27138 0.897556 7.86951C2.00065 3.45252 5.98448 0.299805 10.6 0.299805Z' 
        fill='currentColor'
      />
    </svg>
  );
};

export default VisibilityIcon;