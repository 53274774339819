import React from 'react';

import { withStyles } from '@material-ui/core';

import MessagesList from './MessagesList';
import ChatInput from './ChatInput';

const styles = () => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  }
});

const ChatContainer = ({ classes }) => {
  return (
    <div className={classes.content}>
      <MessagesList />
      <ChatInput />
    </div>
  );
};

export default withStyles(styles)(ChatContainer);
