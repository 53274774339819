import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Fade,
  Popper,
  MenuItem,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import {
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from "@material-ui/icons";
import Scroll from '../../../components/InfiniteScroll/Scroll';
import string from '../../../utils/string';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#F9F9F9",
    color: "#505050",
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
  },
  body: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: '100%',
    fontFamily: 'Poppins',
    height: '100%'
  },
  table: {
    minWidth: 700,
    maxHeight: 700,
  },
  row: {
    maxHeight: 57,
    cursor: 'pointer',
  },
  menuMore: {
    color: '#505050',
    fontSize: 16,
    width: 140,
  },
  iconMenuMore: {
    marginRight: 10,
  },
  paperBotaoMore: {
    borderRadius: 8,
  },
  nameAtivo: {
    color: '#505050',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '655px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px',
    },
  },

  scrollContainerModelos: {
    width: '100%',
    flex: 1,
    height: "calc(100vh - 64px - 64px)",
    overflow: 'auto'
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    margin: 10,
  },
  rowHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }
});

const TabelaReceitas = (props) => {
  const { classes, modelosLoading, handleEditar, modelosImpressao } = props;
  const [openMore, setOpenMore] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState(null);
  const [tipo, setTipo] = useState(null)


  const handleClickMore = (newPlacement, tipo) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMore((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    setTipo(tipo);
  };

  const handleCloseMore = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setOpenMore(false);
  };

  return (
    <Paper className={classes.root}>
      <Scroll
        initialLoad={false}
        className={classes.scrollContainerModelos}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.rowHeader}>
              <CustomTableCell>
                NOME
              </CustomTableCell>
              <CustomTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>

            {modelosImpressao.map((modeloConfig, i) =>
              <TableRow
                className={classes.row}
                key={i}
              >
                <CustomTableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.nameAtivo}
                  onClick={() => handleEditar(modeloConfig.tipo)}
                >
                  {string.removeUnderline(modeloConfig.tipo)}
                </CustomTableCell>
                <CustomTableCell align="center" style={{ width: 100 }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={handleClickMore('bottom-end', modeloConfig.tipo)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </CustomTableCell>
              </TableRow>)
            }

            <Popper open={openMore} anchorEl={anchorEl} placement={placement} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.paperBotaoMore}>
                    <ClickAwayListener onClickAway={handleCloseMore}>
                      <MenuItem
                        className={classes.menuMore} 
                        onClick={() => handleEditar(tipo)}
                      >
                        <EditIcon className={classes.iconMenuMore} />
                        Editar
                      </MenuItem>
                    </ClickAwayListener>
                  </Paper>
                </Fade>
              )}
            </Popper>

          </TableBody>
        </Table>
        {modelosLoading && (
          <div className={classes.notFoundContainer}>
            <CircularProgress />
          </div>
        )}

      </Scroll>
    </Paper>
  );
}

TabelaReceitas.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TabelaReceitasWithStyles = withStyles(styles)(TabelaReceitas);
export default (TabelaReceitasWithStyles)
