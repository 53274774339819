import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import Dentes from "./Dentes/Dentes";
import MucosaOral from "./MucosaOral/MucosaOral";

const ContentOdontograma = observer(
  ({ classes, openDrawer }) => {
    return (
      <div className={classes.content}>
        <Dentes openDrawer={openDrawer} />
        <MucosaOral />
      </div>
    );
  }
);

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    margin: "8px 0",
  },
};

const ContentOdontogramaWithStyles = withStyles(styles)(ContentOdontograma);
export default inject("odontogramaStore")(ContentOdontogramaWithStyles);
