import React from "react";

import { withStyles } from "@material-ui/core/styles/index";

import { SelectableGroup } from "react-selectable-fast";
import ListHours from "./ListHours";
import Header from "./WeekHeader";

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
		position: "relative",
    '@media (max-width: 820px)': {
      overflowY: 'visible'
    }
  },
  horariosContainer: {
    flex: 1,
		overflowY: "overlay",
		"&::-webkit-scrollbar": {
			width: "16px !important",
			borderRadius: "100px !important",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: "100px",
		},
  },
  agenda: {
    display: "flex",
    position: "relative",
    overflowY: "hidden",
    border: "1px solid rgba(0, 0, 0, 0.1)",

    '@media (max-width: 820px)': {
      overflowY: 'visible'
    }
  },
});

const AgendaConfiguracaoAtendimento = ({
  classes,
  values,
  onSelected,
}) => {
  return (
    <div className={classes.root}>
      <Header items={values} />
      <div className={classes.horariosContainer}>
        <SelectableGroup
          ref={(ref) => (window.selectableGroup = ref)}
          className={classes.agenda}
          clickClassName="tick"
          enableDeselect
          tolerance={0}
          globalMouse={true}
          allowClickWithoutSelected={true}
          onSelectionFinish={onSelected}
        >
          {values.map((weekday, key) => {
            return (
              <ListHours
                weekday={weekday}
                key={weekday.name}
                lastItem={values.length - 1 === key}
              />
            );
          })}
        </SelectableGroup>
      </div>
    </div>
  );
};

export default withStyles(styles)(AgendaConfiguracaoAtendimento);
