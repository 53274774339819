import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import Dialog from '../../../../components/Dialog/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import ButtonLong from '../../../../components/Button/ButtonLong'
import InputSelectComLabel from '../../../../components/Input/InputSelectComLabel'
import TabSelector from '../../../../components/TabSelector'
import MultiSelect from './components/MultiSelect'
import ButtonTransparent from '../../../../components/Button/ButtonTransparent'
import Header from './components/Header'
import ListGuias from './components/ListGuias'
import NumberLoteGuia from './components/NumberLoteGuia'

import {
  findAllFinanceiroGuiaServicoFaturadaSemLote,
  findAllFinanceiroGuiaConsultaSemLote,
  updateGuiaServicoLote,
  nextNumeroLote,
  gerarXmlLote,
  saveGuiaServicoLote,
} from '../../../../services/GuiaDeServicosLoteService'
import { findConveniosByAtivo } from '../../../../services/ConvenioService'

import styles from './GuiaLoteStyles'

const GuiaDeServicosLoteModal = observer(props => {
  const {
    classes,
    handleCloseModal,
    openModal,
    setOpenModal,
    guiaServicoLoteSelected,
    disableField,
    loadGuiasDeServicoLote,
    openNotification,
    usuarioStore,
  } = props
  const [loadingSave, setLoadingSave] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [typeOfGuia, setTypeOfGuia] = useState('CONSULTA')
  const [listGuias, setListGuias] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [lastPage, setLastPage] = useState(false)
  const [conveniosList, setConveniosList] = useState([])
  const [convenioSelected, setConvenioSelected] = useState(null)
  const [isEditingGuia, setIsEditingGuia] = useState(false)
  const [listaGuiaSelecionada, setListaGuiaSelecionada] = useState([])
  const [numeroLote, setNumeroLote] = useState(null)

  const handleSave = async () => {
    setLoadingSave(true)
    try {
      const nomeAtributoGuia = typeOfGuia === 'SERVICO' ? 'guias' : 'guiasConsulta'
      if (guiaServicoLoteSelected) {
        const listaIds = listaGuiaSelecionada.map(guia => guia.id)
        const newUpdateGuiaServicoLote = {
          lote: guiaServicoLoteSelected?.id,
          [nomeAtributoGuia]: listaIds,
        }
        await updateGuiaServicoLote(newUpdateGuiaServicoLote)
      } else {
        const newGuiaServicoLote = {
          lote: {
            convenio: convenioSelected,
            numeroLote: numeroLote,
            [nomeAtributoGuia]: listaGuiaSelecionada,
          },
        }
        await saveGuiaServicoLote(newGuiaServicoLote)
      }
      setOpenModal(false)
      setLoadingSave(false)
      loadGuiasDeServicoLote({ isSearching: true })
    } catch (error) {
      openNotification(
        `Ocorreu um erro ao ${guiaServicoLoteSelected ? 'Editar' : 'Salvar'}`,
        'error'
      )
      setLoadingSave(false)
    }
  }

  const handleCreateNumberLote = async () => {
    const nextNumberOfLote = await nextNumeroLote()
    setNumeroLote(nextNumberOfLote)
  }

  const handleClose = () => {
    handleCloseModal()
  }

  const handleChangeConvenio = value => {
    if (guiaServicoLoteSelected && value?.id !== guiaServicoLoteSelected?.convenio?.id) {
      openNotification('Convênio selecionado não é o mesmo da guia', 'info')
    } else {
      setConvenioSelected(value)
    }
  }

  const download = async () => {
    try {
      const base64 = await gerarXmlLote(guiaServicoLoteSelected.id)

      const link = document.createElement('a')
      if (link.download !== undefined) {
        link.href = `data:text/xml;base64,${base64.xmlLote}`
        link.target = '_blank'
        link.download = guiaServicoLoteSelected.numeroLote
        link.click()
      }
    } catch {
      openNotification('Erro ao gerar o xml', 'error')
    }
  }

  const loadGuia = async (type = 'CONSULTA', page) => {
    if (isLoading) return
    const pageableDTO = {
      pageNumber: page,
      pageSize: 20,
      sortDir: 'ASC',
      sortField: 'numeroGuiaPrestador',
    }

    try {
      setIsLoading(true)
      const response =
        type === 'CONSULTA'
          ? await findAllFinanceiroGuiaConsultaSemLote({
              pageableDTO,
              ...(convenioSelected &&
                convenioSelected?.id !== 0 && { convenioId: convenioSelected.id }),
            })
          : await findAllFinanceiroGuiaServicoFaturadaSemLote({
              pageableDTO,
              ...(convenioSelected &&
                convenioSelected?.id !== 0 && { convenioId: convenioSelected.id }),
            })
      setPageNumber(page)
      setListGuias(prevState =>
        page === 0 ? response.content : [...prevState, ...response.content]
      )
      setLastPage(response.last)
    } catch (error) {
      openNotification('Erro ao carregar as guias', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const loadMore = async () => {
    if (isLoading || lastPage) return
    setIsLoading(true)
    const page = pageNumber + 1
    await loadGuia(typeOfGuia, page)
  }

  const listAllConvenios = async () => {
    const unidadeLogada = await usuarioStore.getUnidadeAtual()
    const response = await findConveniosByAtivo(unidadeLogada?.id)
    setConveniosList(response?.data?.data?.convenios)
  }

  useEffect(() => {
    if (convenioSelected?.id) {
      loadGuia(typeOfGuia, 0)
    }
    listAllConvenios()
  }, [typeOfGuia, convenioSelected?.id])

  useEffect(() => {
    if (guiaServicoLoteSelected) {
      const isGuiaServico = guiaServicoLoteSelected?.guias?.length > 0
      setTypeOfGuia(isGuiaServico ? 'SERVICO' : 'CONSULTA')
      handleChangeConvenio(guiaServicoLoteSelected?.convenio)
    }
  }, [guiaServicoLoteSelected])

  useEffect(() => {
    if (guiaServicoLoteSelected) return
    handleCreateNumberLote()
  }, [])

  const typeOfGuiaSelected =
    guiaServicoLoteSelected?.guias?.length > 0 ? 'Guia de Serviço' : 'Guia de Consulta'

  const verificaListaSelecionada = () => {
    return listaGuiaSelecionada.length === 0; 
  };

  const disabledButton = disableField || loadingSave || verificaListaSelecionada();

  return (
    <Dialog
      open={openModal}
      maxWidth={'md'}
      fullWidth={true}
      classes={{
        paper: classes.fullSizePaper,
      }}
    >
      <Header
        handleClose={handleClose}
        isGuiaServicoSelected={!!guiaServicoLoteSelected}
        handleDownloadXML={download}
        handleEdit={() => setIsEditingGuia(true)}
        numberOfLote={numeroLote || guiaServicoLoteSelected?.numeroLote}
      />

      <Grid container className={classes.content}>
        <div style={{ width: '100%' }}>
          <TabSelector
            tabsOptions={[
              { label: 'Informações' },
              { label: 'Auditoria', tagEmBreve: true, tagColor: '#9871FC', isDisabled: true },
            ]}
            baseColor='#F2F2F2'
            selectedColor='#FFF'
            onSelect={index => setSelectedTabIndex(index)}
            selectedTabIndex={selectedTabIndex}
          />
        </div>
        {selectedTabIndex === 0 && (
          <>
            {guiaServicoLoteSelected && !isEditingGuia ? (
              <React.Fragment>
                <div className={classes.wrapperInfosGuia}>
                  <span>
                    Tipo de guia: <strong>{typeOfGuiaSelected}</strong>
                  </span>
                  <NumberLoteGuia numberOfLote={guiaServicoLoteSelected?.numeroLote} />
                </div>
                <ListGuias guiaSelected={guiaServicoLoteSelected} />
              </React.Fragment>
            ) : (
              <>
                <div className={classes.containerFilters}>
                  <div className={classes.wrapperTypes}>
                    <h2>Tipo de guia</h2>
                    <div style={{ display: 'flex' }}>
                      <FormControlLabel
                        value={typeOfGuia}
                        name='guiaConsulta'
                        className={classes.radioButton}
                        control={<Radio color='primary' />}
                        label={'Guia de Consulta'}
                        checked={typeOfGuia === 'CONSULTA'}
                        onChange={() => {
                          if (guiaServicoLoteSelected) return
                          setTypeOfGuia('CONSULTA')
                        }}
                      />
                      <FormControlLabel
                        value={typeOfGuia}
                        name='guiaServico'
                        className={classes.radioButton}
                        control={<Radio color='primary' />}
                        label={'Guia de Serviço'}
                        checked={typeOfGuia === 'SERVICO'}
                        onChange={() => {
                          if (guiaServicoLoteSelected) return
                          setTypeOfGuia('SERVICO')
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.wrapperTypes}>
                    <InputSelectComLabel
                      label={'Convênio'}
                      placeholder={'Selecione um convênio'}
                      value={convenioSelected}
                      onChange={e => handleChangeConvenio(e)}
                      isDisabled={guiaServicoLoteSelected}
                      noMargin
                      isSelect
                      className={classes.inputContainer}
                      options={conveniosList}
                      getOptionLabel={option => option.descricao}
                      getOptionValue={option => option.id}
                    />
                  </div>
                </div>
                <MultiSelect
                  onEndReached={loadMore}
                  getSelectedItems={item => setListaGuiaSelecionada(item)}
                  listGuias={listGuias}
                  isLoading={!lastPage || isLoading}
                  guiaSelected={guiaServicoLoteSelected}
                  typeOfGuia={typeOfGuia}
                  convenioSelected={convenioSelected?.id}
                />
              </>
            )}
          </>
        )}
      </Grid>
      {(!guiaServicoLoteSelected || isEditingGuia) && (
        <div className={classes.buttonsContainer}>
          <ButtonTransparent onClick={handleClose}>Cancelar</ButtonTransparent>
          <ButtonLong
            colorCustom='green'
            onClick={handleSave}
            loading={loadingSave}
            disabled={disabledButton}
          >
            Salvar
          </ButtonLong>
        </div>
      )}
    </Dialog>
  )
})

const GuiaDeServicosLoteModalWithStyles = withStyles(styles)(GuiaDeServicosLoteModal)
export default inject('usuarioStore')(GuiaDeServicosLoteModalWithStyles)
