import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const PersonIcon = (props) => {
  return (
    <svg
      width='26'
      height='27'
      viewBox='0 0 26 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M18.5044 13.9846C19.8004 13.9846 20.8509 15.0352 20.8509 16.3311V17.1133C20.8509 20.1973 17.942 23.3707 13.0292 23.3707C8.11643 23.3707 5.20752 20.1973 5.20752 17.1133V16.3311C5.20752 15.0352 6.25809 13.9846 7.55403 13.9846H18.5044ZM13.0292 3.81641C15.4051 3.81641 17.3312 5.74245 17.3312 8.11835C17.3312 10.4942 15.4051 12.4203 13.0292 12.4203C10.6533 12.4203 8.72729 10.4942 8.72729 8.11835C8.72729 5.74245 10.6533 3.81641 13.0292 3.81641Z'
        fill='currentColor'
      />
    </svg>
  );
};
export default PersonIcon;
