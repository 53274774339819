import React from 'react'
import string from '../../../../../../../../../utils/string'
import StatusBadge from '../../../../StatusBadge'
import FinanceiroBadge from '../../../../FinanceiroBadge'
import { Checkbox } from '@material-ui/core'
import moment from 'moment'

export const getColumns = ({
  handleCheckAll,
  sessoesSelectedToCancel,
  handleChange,
  checkIsFaturadoOrRealizado,
  allCheckBoxChecked,
}) => {
  const columns = [
    {
      Header: 'Sessões',
      getValue: data => {
        const sessoes = data?.controleSessao?.controleSessaoItems || [];
        const totalQuantity = sessoes?.filter(item => {
          return item?.ativo
        }).length
        return `${data.ordem}/${totalQuantity}`
      },
    },
    {
      Header: 'Procedimento',
      getValue: data => {
        return string.truncate(data?.procedimento?.nome || data?.agendamentoTipo?.descricao, 15)
      },
      field: 'controleSessao.controleSessaoProcedimentos.procedimento.nome',
    },
    {
      Header: 'Status',
      getValue: data => {
        return <StatusBadge status={data?.agendamento?.situacao} />
      },
    },
    {
      Header: 'Financeiro',
      getValue: data => {
        const getStatus = () => {
          const sessaoFaturada = data?.titulo?.id || data?.controleSessao?.titulo?.id
          if (!!sessaoFaturada) return 'FATURADO'
          return 'NAO_FATURADO'
        }
        return <FinanceiroBadge status={getStatus()} />
      },
    },
    {
      Header: 'Data do agendamento',
      getValue: data => {
        return moment(data?.agendamento?.data).format('DD/MM/YYYY') || 'Não definido'
      },
      field: 'agendamento.data',
    },
    {
      Header: (
        <Checkbox
          color='primary'
          checked={allCheckBoxChecked()}
          onChange={e => handleCheckAll(e)}
        />
      ),
      getValue: data => {
        const isDisabled = checkIsFaturadoOrRealizado(data)
        const isChecked = sessoesSelectedToCancel?.some(item => item.id === data.id)
        return (
          <Checkbox
            color='primary'
            checked={isChecked}
            disabled={isDisabled}
            onChange={e => handleChange(e, data)}
          />
        )
      },
    },
  ]
  return columns
}
