import { action, observable } from "mobx";
import Api from "../config/api";

export const TypeModalEnumReceituario = {
    RECEITUARIO_MODAL: 'ReceituarioModal',
    EDIT_MODAL: 'modalEdit',
    NOVO_MODELO_MODAL: 'novoModal',
}


export default class ReceituarioStore {
    @observable sugestoes = [];
    @observable modelos = [];
    @observable nomeModelo = '';
    @observable typeModal = '';
    @observable tipoListagemSelecionada = 'sugestoes';
    @observable searchNomeModelo = '';
    @observable modelosConfig = [];
    @observable pageNumberModelo = 0;
    @observable statusListaModelo = 'none';
    @observable statusModelo = undefined;
    @observable idModeloSelecionado = '';
    @observable modelosLoadingModal = false;
    @observable statusNovoModelo = true;
    @observable modeloSelecionado = {};
    @observable isProntuario = false;
    @observable criarNovoModelo = false;
    @observable openModalModelo = false;
    @observable usoTemp = "";
    @observable drugTemp = null;
    @observable amountTemp = "";
    @observable ascriptionTemp = "";
    @observable recipesTemp = [];
    @observable possuiToken = false;
    @observable accessToken = null;
    @observable inputAdscricao = "";
    @observable inputRemedio = "";

    @action async criarEImprimirReceita(variables) {
        try {
            const response = await Api.post("", {
                query: `
                    mutation ($receita: ReceitaInput) {
                        imprimir: criarEImprimirReceita(receita: $receita) {
                            id, 
                            pdf,
                            entradaProntuario {
                            id
                            }
                        }
                    }
                `,
                variables: variables
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }
            return response.data.data.imprimir;
        } catch (error) {
            throw error;
        }
    }

    @action async imprimirReceita(variables) {
        try {
            const response = await Api.post("", {
                query: `
                mutation ($id: Long) {
                    imprimir: imprimirReceita(id: $id)
                }
            `,
                variables: variables
            });
            if (response.data.errors) {
                throw response.data.errors[0];
            }
            return response.data.data.imprimir;
        } catch (error) {
            throw error;
        }
    }

    @action async criarEImprimirAssinadaReceita(variables) {     
        try {
            const response = await Api.post("", {
                query: `
                mutation ($receita: ReceitaInput, $tokenAplicativo: String) {
                    imprimir: criarEImprimirAssinadaReceita(receita: $receita, tokenAplicativo: $tokenAplicativo) {
                        id
                        pdf
                        assinado
                        entradaProntuario {
                          urlDownload
                        } 
                    }
                }
            `,
                variables: variables
            });
            if (response.data.errors) {
                throw response.data.errors[0];
            }
            return response.data.data.imprimir;
        } catch (error) {
            throw error;
        }
    }

    @action async imprimirAssinadaReceita(variables) {
        try {
            const response = await Api.post("", {
                query: `
                mutation ($id: Long, $tokenAplicativo: String) {
                    imprimir: imprimirAssinadaReceita(id: $id, tokenAplicativo: $tokenAplicativo) {
                        id
                        pdf
                        entradaProntuario {
                          urlDownload
                        } 
                    }
                }
            `,
                variables: variables
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }
            return response.data.data.imprimir;
        } catch (error) {
            throw error;
        }
    }

    @action async findAllReceitaModelo({searchDTO = {}, pageableDTO}) {
        const defaultSearchDTO = {
            pageSize: 30,
            pageNumber: 0,
            sortDir: "ASC",
            sortField: "nome"
        }

        this.modelosLoadingModal = true;
        
        await Api.post('', JSON.stringify({
            query:
            `
            query ($tipo: ReceitaTipo, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput) {
                findAllReceitaModelo(tipo: $tipo, ativo: $ativo, search: $search pageableDTO: $pageableDTO) {
                    last
                    numberOfElements
                    content {
                        ativo
                        quantidadeUso
                        nome
                        tipo
                        id
                        oftalmologica {
                            longeOlhoDireitoEsferico
                            longeOlhoDireitoCilindrico
                            longeOlhoDireitoEixo
                            longeOlhoDireitoDnp
                            longeOlhoEsquerdoEsferico
                            longeOlhoEsquerdoCilindrico
                            longeOlhoEsquerdoEixo
                            longeOlhoEsquerdoDnp
                            pertoOlhoDireitoEsferico
                            pertoOlhoDireitoCilindrico
                            pertoOlhoDireitoEixo
                            pertoOlhoDireitoDnp
                            pertoOlhoEsquerdoEsferico
                            pertoOlhoEsquerdoCilindrico
                            pertoOlhoEsquerdoEixo
                            pertoOlhoEsquerdoDnp
                            pertoAdicao
                            observacao
                        }
                        receitaModeloItens {
                            id
                            adscricao
                            remedio
                            receitaItemTipoUso
                            quantidade
                            viaAdministracao
                            medicamento {
                                fabricante
                                formaFarmaceutica
                                id
                                principioAtivo
                                produto
                                receituario
                                status
                                subtitulo
                                tarja
                                tipo
                                titularidade
                            }
                        }
                    }
                    pageable {
                        pageNumber
                    }
                    }
                }
            `,
            variables:
            {
                ...searchDTO,
                pageableDTO: pageableDTO || defaultSearchDTO
            }            
        })).then(response => {
            const { content, last } = response.data.data.findAllReceitaModelo;
            this.modelosLast = last;
            this.modelos = pageableDTO.pageNumber === 0 ? content : [...this.modelos, ...content];
        })
        .catch((error) => {})
        .finally(() => {
            this.modelosLoadingModal = false;
        })
    }

    @action async findAllMedicamentoQuantidadeUso({pageableDTO}){
        const defaultSearchDTO = {
            pageSize: 30,
            pageNumber: 0,
            sortDir: "DESC",
            sortField: "quantidadeUso"
        };
    
        this.sugestoesLoading = true;
    
        const response = await Api.post('',
        JSON.stringify({
            query:
            `query ($pageableDTO: PageableDTOInput) {
                findAllMedicamentoQuantidadeUso(pageableDTO: $pageableDTO) {
                    last
                    numberOfElements
                    totalElements
                    content {
                        id
                        quantidadeUso
                        medicamento {
                        fabricante
                        formaFarmaceutica
                        id
                        principioAtivo
                        produto
                        receituario
                        status
                        subtitulo
                        tarja
                        tipo
                        titularidade
                        }
                    }
                    pageable {
                        pageNumber
                    }
                    }
                }`,
            variables:{
            pageableDTO: pageableDTO || defaultSearchDTO,      
        }
        }))
        this.sugestoesLoading = false;
        
        const { content, last } = response.data.data.findAllMedicamentoQuantidadeUso;
        this.sugestoesLast = last;
        this.sugestoes = [...this.sugestoes, ...content];        

        return this.sugestoes;
    }
        
    @action async criarReceitaModelo(receitaModelo){
        const query = `
        mutation ($receitaModelo: ReceitaModeloInput) {
            criarReceitaModelo(receitaModelo: $receitaModelo) {
                ativo
                id
                nome
                quantidadeUso
                receitaModeloItens {
                    adscricao
                    formaFarmaceutica
                    viaAdministracao
                    medicamento {
                        fabricante
                        formaFarmaceutica
                        id
                        tipo
                        principioAtivo
                        tarja
                        fabricante
                        subtitulo
                    }
                }    
            }        
        }`;
            const variables = {
                receitaModelo: {
                    ...receitaModelo,
                    ativo: receitaModelo.ativo || true,
                }            
            };
        
            return await Api.post("", {
                query,
                variables
            });
        }

        @action async atualizarReceitaModelo(receitaModelo){
            const query = `
            mutation ($receitaModelo: ReceitaModeloInput) {
                atualizarReceitaModelo(receitaModelo: $receitaModelo) {
                    ativo
                    id
                    nome
                    quantidadeUso
                    receitaModeloItens {
                        id
                        adscricao
                        formaFarmaceutica
                        quantidade
                        receitaItemTipoUso
                        remedio
                        medicamento {
                        fabricante
                        formaFarmaceutica
                        id
                        tipo
                        principioAtivo
                        tarja
                        fabricante
                        subtitulo
                        }
                        viaAdministracao
                    }
                    oftalmologica {
                        id
                        longeOlhoDireitoCilindrico
                        longeOlhoDireitoDnp
                        longeOlhoDireitoEixo
                        longeOlhoDireitoEsferico
                        longeOlhoEsquerdoCilindrico
                        longeOlhoEsquerdoDnp
                        longeOlhoEsquerdoEixo
                        longeOlhoEsquerdoEsferico
                        pertoOlhoDireitoCilindrico
                        pertoOlhoDireitoDnp
                        pertoOlhoDireitoEixo
                        pertoOlhoDireitoEsferico
                        pertoOlhoEsquerdoCilindrico
                        pertoOlhoEsquerdoDnp
                        pertoOlhoEsquerdoEixo
                        pertoOlhoEsquerdoEsferico
                        pertoAdicao
                        observacao
                      }
                }
            }`;
            const variables = {
                receitaModelo: {
                    id: receitaModelo.id,
                    ...receitaModelo
                }            
            };
        
            return await Api.post("", {
                query,
                variables
            });
        }

        @action async somarQuantidadeUso(id){
            const query = `
            mutation ($id: UUID) {
                somarQuantidadeUso(id: $id) {
                    ativo
                    id
                    nome
                    quantidadeUso
                    receitaModeloItens {
                        id
                        adscricao
                        formaFarmaceutica
                        medicamento {
                        fabricante
                        formaFarmaceutica
                        id
                        tipo
                        principioAtivo
                        tarja
                        fabricante
                        subtitulo
                        }
                        quantidade
                        remedio
                    }
                    tipo
                    }
                }
                `;
                const variables = {id: id};          
            
                return await Api.post("", {
                    query,
                    variables
                });
            }

    @action async findByIdReceitaModelo(id){
        try {
            const response = await Api.post('', {
                query: `
                query ($id: UUID) {
                    findByIdReceitaModelo(id: $id) {
                        ativo
                        quantidadeUso
                        nome
                        tipo
                        id
                        oftalmologica {
                            id
                            longeOlhoDireitoEsferico
                            longeOlhoDireitoCilindrico
                            longeOlhoDireitoEixo
                            longeOlhoDireitoDnp
                            longeOlhoEsquerdoEsferico
                            longeOlhoEsquerdoCilindrico
                            longeOlhoEsquerdoEixo
                            longeOlhoEsquerdoDnp
                            pertoOlhoDireitoEsferico
                            pertoOlhoDireitoCilindrico
                            pertoOlhoDireitoEixo
                            pertoOlhoDireitoDnp
                            pertoOlhoEsquerdoEsferico
                            pertoOlhoEsquerdoCilindrico
                            pertoOlhoEsquerdoEixo
                            pertoOlhoEsquerdoDnp
                            pertoAdicao
                            observacao
                        }
                        receitaModeloItens {
                            id
                            adscricao
                            remedio
                            receitaItemTipoUso
                            quantidade
                            viaAdministracao
                            medicamento {
                                fabricante
                                formaFarmaceutica
                                id
                                principioAtivo
                                produto
                                receituario
                                status
                                subtitulo
                                tarja
                                tipo
                                titularidade
                            }
                        }
                    }
                }
            `,
            variables: { id }
        });
        return response.data.data.findByIdReceitaModelo;
    
        } catch (error) {
        throw error;
        }
    }

    @action async ativarReceitaModelo(input){
        const query = `
        mutation ($id: UUID) {
            ativarReceitaModelo(id: $id) {
            id
            nome
            ativo
          }
        }
        `;
  
        const variables = {
          id: input
        }
  
        return await Api.post("", {
          query,
          variables
        })
    }

    @action async desativarReceitaModelo(input){
        const query = `
        mutation ($id: UUID) {
            desativarReceitaModelo(id: $id) {
            id
            nome
            ativo
          }
        }
        `;
  
        const variables = {
          id: input
        }
  
        return await Api.post("", {
          query,
          variables
        })
    };

    @action async possuiTokenAssinaturaValido(){
        const response = await Api.post("", {
            query: `
            query {
                possuiTokenAssinaturaValido
            }
            `
        });
        const {possuiTokenAssinaturaValido} = response.data.data

        return possuiTokenAssinaturaValido;
    };

    @action changeFieldsStore(values) {
        const arrayKey = Object.keys(values);
    
        arrayKey.forEach(item => {
          this[item]=values[item];
        })
    };

}