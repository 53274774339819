import React, { useEffect, useState, useRef, useReducer } from 'react';

import { withStyles } from '@material-ui/core';
import { styles } from './styles';

import Button from '../../components/ui/Buttons/Button';
import PlayIcon from '../Icon/Play';
import PauseIcon from '../Icon/Pause';

import CustomCanvas from './AudioWave';
import moment from 'moment';

const recordingReducer = (state, action) => {
  switch (action.type) {
    case "START":
      return { ...state, recording: true };
    case "STOP":
      return { ...state, recording: false };
    default:
      return state;
  }
};

const customButtonSize = {
  height: '24px',
  width: '24px',
}

const VoiceRecorder = ({ classes, height, width, waveColor, onData, isCancelled, limitTime, onError }) => {
  const [audioURLPreview, setAudioURLPreview] = useState(null);
  const [timer, setTimer] = useState(0);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);

  const [state, toggleRecording] = useReducer(recordingReducer, {
    recording: true
  });

  useEffect(() => {
    if (isCancelled) {
      setAudioURLPreview(null);
      stopRecording();
    }
  }, [isCancelled]);

  useEffect(() => {
    startRecording();
  }, []);

  useEffect(() => {
    const { recording } = state;
    let interval;
    if (recording) {
      interval = startTimer();
    } else {
      stopTimer();
    }
  
    return () => {
      stopTimer(interval);
    };
  }, [state.recording]);

  const startTimer = () => {
    return setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  }

  const stopTimer = (interval) => {
    if (interval) {
      clearInterval(interval);
    }
  }

  useEffect(() => {
    if (timer >= limitTime) {
      stopRecording();
    }
  }, [timer]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      toggleRecording({ type: "START" });

      mediaRecorder.current.ondataavailable = (e) => {
        audioChunks.current.push(e.data);
      };

      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/aac" });
        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURLPreview(audioURL);
        onData(audioBlob);
      };

      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error starting recording:", error);
      onError("Verifique a permissão de acesso do microfone.");
    }
  };

  const stopRecording = () => {
    toggleRecording({ type: "STOP" });
    if (mediaRecorder.current && recording) {
      mediaRecorder.current.stop();
    }

    if (mediaRecorder?.current?.stream) {
      const audioStreamTrack = mediaRecorder.current.stream.getTracks()[0];
      audioStreamTrack.stop();
    }
  };

  const handleClickRecordingButton = () => {
    const { recording } = state;
    if (recording) {
      stopRecording();
    } else {
      startRecording();
    }
  }

  const { recording } = state;
  return (
    <div className={classes.voiceRecorderContainer}>
      {recording && (
        <span>{moment(timer * 1000).format("mm:ss")}</span>
      )}
      <div className={classes.waveOrAudioContainer}>
        {recording && mediaRecorder?.current && (
          <CustomCanvas
            mediaRecorderRef={mediaRecorder}
            height={height}
            width={width}
            isRecording={recording}
            strokeColor={waveColor}
          />  
        )}
        {audioURLPreview && !recording && 
          <audio 
            className={classes.audioPlayerPreview} 
            style={{ height: `${height}px` }} 
            controls 
            src={audioURLPreview} 
          />
        }
      </div>
      <Button
        shape='circle'
        onClick={handleClickRecordingButton}
        bgColor="#FB7676"
        style={customButtonSize}
      >
        {recording ? 
          <PauseIcon color="white" size={10} /> : 
          <PlayIcon color="white" size={14} />
        }
      </Button>
    </div>
  );
};

export default withStyles(styles)(VoiceRecorder);
