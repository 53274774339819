import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, buttonStyle, cancelButtonStyle } from '../styles';
import Button from '../../../../../components/Button/Button';
import CircleWarning from '../../../../../components/Icon/CircleWarning';
import { transferirAgendamentosPorProfissionalSaude } from '../../../../../services/TransferenciaAgendamentoService';
import moment from 'moment';

const ConfirmStep = ({ 
  classes, 
  onCloseModal,
  setModalStep, 
  setTransactionSuccess,
  profissionalSaudeDestino,
  setConflictedAgendamentos,
  selectedAgendamentos,
  currentFilter,
  setNotification,
  allSelected
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    let variables = {
      profissionalSaudeDestinoId: profissionalSaudeDestino.id,
      profissionalSaudeOrigemId: currentFilter?.profissionalSaude?.id || null
    };

    if (allSelected) {
      variables = {
        ...variables,
        ...currentFilter,
        dataInicio: moment(currentFilter.dataInicial).format('YYYY-MM-DD'),
        dataFim: moment(currentFilter.dataFinal).format('YYYY-MM-DD')
      }
    } else {
      variables = {...variables, agendamentosId: selectedAgendamentos }
    }
    try {
      setIsLoading(true);
      const response = await transferirAgendamentosPorProfissionalSaude(variables);
      const conflictedAgendamentos = response.data.data.transferirAgendamentosPorProfissionalSaude;
      if (conflictedAgendamentos?.length > 0) {
        const agendamentosWithProfissionalDestino = conflictedAgendamentos.map((agendamento) => {
          return {
            ...agendamento,
            profissionalSaude: {
              ...profissionalSaudeDestino,
            },
          }
        });
        setConflictedAgendamentos(agendamentosWithProfissionalDestino);
        setTransactionSuccess(false);
      } else {
        setTransactionSuccess(true);
      }
      setModalStep(2);
      setIsLoading(false);
      
      if (response.data.error) {
        onCloseModal();
        throw Error('Falha ao transferir agendamentos');
      }
    } catch(error) {
      setNotification({
        message: error.message,
        isOpen: true,
        variant: 'error'
      })
      setIsLoading(false);
    }
  };  

  return (
    <div className={classes.confirmContainer}>
      <CircleWarning color="#F9BE73" size={50} />
      <span className={classes.confirmText}>Essa ação não poderá ser desfeita, você deseja continuar?</span>
      <div className={classes.buttonsContainer}>
        <Button 
          style={{...buttonStyle, ...cancelButtonStyle }}
          onClick={onCloseModal}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button 
          style={buttonStyle}
          onClick={handleSave}
          disabled={isLoading}
          loading={isLoading}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(ConfirmStep);