import { action, observable } from "mobx";
import moment from "moment";

import { findAllEstadoList, getDadosGuiaConsultaAgendamento } from "../services/GuiaDeConsultaService";

export const guiaConsultaDefault = {
  id: null,
  convenio: null,
  dataAtendimento: moment().format("YYYY-MM-DD"),
  codigoTabela: "",
  codigoProcedimento: "",
  valorProcedimento: "",
  agendamentoTipo: "",
  beneficiario: {
    nome: "",
    numeroCarteira: "",
    dataValidadeCarteira: "",
    cns: "",
    atendimentoRN: "N",
  },
  nomeProfissional: "",
  numeroConselhoProfissional: "",
  conselhoProfissional: "",
  uf: "",
  cbo: "",
  ausenciaCodigoValidacao: "",
  codigoValidacao: "",
  observacao: "",
  tuss52TerminologiaTipoConsulta: null,
  tuss22ProcedimentoEventoSaude: null,
};

export default class GuiaDeConsultaStore {
  @observable isOpenModal = false;
  @observable guiaConsulta = null;

  @action changeFieldsStore(values) {
    const arrayKey = Object.keys(values);

    arrayKey.forEach((item) => {
      this[item] = values[item];
    });
  }

  @action async carregaGuiaConsultaAgendamento(id) {
    const {convenio, sujeitoAtencao, id: responseID, data, tipo, profissionalSaude} = await getDadosGuiaConsultaAgendamento(id);
    const uf = profissionalSaude?.ufConselho &&  await this.getUf(profissionalSaude?.ufConselho);
    const valorProcedimento = convenio?.convenioAgendamentoTipo && convenio?.convenioAgendamentoTipo.find(
        (item) => item.agendamentoTipo?.id === tipo?.id
    );

    const dadosGuia = {
      agendamentoId: responseID,
      convenio: convenio || sujeitoAtencao?.dadosConvenio?.convenio,
      dataAtendimento: data,
      codigoTabela: "",
      codigoProcedimento: "",
      valorProcedimento: valorProcedimento?.valor,
      agendamentoTipo: {
        id: tipo?.id,
        descricao: tipo?.descricao,
      },
      beneficiario: {
        sujeitoAtencao: {
          id: sujeitoAtencao.id,
          nome: sujeitoAtencao.nome,
          nomeSocial: sujeitoAtencao.nomeSocial,
        },
        nome: sujeitoAtencao.nome,
        numeroCarteira: sujeitoAtencao.dadosConvenio?.numeroCarteira,
        dataValidadeCarteira:
          sujeitoAtencao.dadosConvenio?.validadeCarteira,
        cns: sujeitoAtencao.cns,
        atendimentoRN: "N",
      },
      profissionalSaude: {
        id: profissionalSaude?.id,
        nome: profissionalSaude?.nome,
      },
      nomeProfissional: profissionalSaude?.nome,
      numeroConselhoProfissional: profissionalSaude?.numeroConselho,
      conselhoProfissional:
        profissionalSaude.conselhoProfissionalSaude,
      uf: uf,
      cbo: profissionalSaude?.tuss24TerminologiaCBO?.codigoTermo,
      ausenciaCodigoValidacao: "",
      codigoValidacao: "",
      observacao: "",
      tuss52TerminologiaTipoConsulta:
        tipo?.tuss52TerminologiaTipoConsulta,
      tuss22ProcedimentoEventoSaude:
        tipo?.tuss22ProcedimentoEventoSaude,
    };

    this.guiaConsulta = dadosGuia;
    this.isOpenModal = true;
  };

  @action async getUf(uf) {
    const listaEstados = await findAllEstadoList();
    const estado = listaEstados.find(item => item.uf === uf);

    return estado;
  };
}
