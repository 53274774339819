import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../GuiaServicosFaturamentoStyle";
import ButtonLong from "../../../../../../components/Button/ButtonLong";
import {
  Edit as EditIcon,
} from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import { TextFieldSearch } from "../../../../../../components/TextField";
import {
  profissionalExecutante,
  findAllTuss,
} from "../../../../../../services/GuiaDeServicosService";
import { verificaCamposObrigatorioExecutante } from "../ValidaDados";

const Executantes = (props) => {
  const {
    classes,
    openNotification,
    itemSelecionado,
    setItemExecucao,
  } = props;

  const [executantes, setExecutantes] = useState([]);
  const [itemExecutante, setItemExecutante] = useState({});

  useEffect(() => {
    listarExecutantes();
  }, [itemSelecionado.executantes]);

  const listarExecutantes = () => {
    const executantes = itemSelecionado.executantes.map((item, index) => {
      return {
        posicao: index,
        edit: false,
        id: item.id,
        cpf: item.cpf,
        profissionalSaude: {
          nome: item.profissionalSaude.nome,
          id: item.id,
        },
        conselhoProfissionalSaude: item.conselhoProfissionalSaude,
        numeroConselho: item.numeroConselho,
        estado: item.estado,
        informacao: {
          tuss24TerminologiaCBO: item.tuss24TerminologiaCBO,
        },
        tuss24TerminologiaCBO: item.tuss24TerminologiaCBO,
        tuss35TerminologiaGrauParticipacao:
          item.tuss35TerminologiaGrauParticipacao,
      };
    });

    setExecutantes(executantes);
  };

  const handleChangeItem = e => {
    setItemExecutante({ ...itemExecutante, 
      tuss35TerminologiaGrauParticipacao: e });
    };
    
    const handleChangeItemProfissionalSaude = e => {
    setItemExecutante({ ...itemExecutante, 
      conselhoProfissionalSaude: e?.conselhoProfissionalSaude,
      cpf: e?.cpf,
      id: e?.id,
      tuss24TerminologiaCBO: e?.informacao?.tuss24TerminologiaCBO,
      nome: e?.nome,
      numeroConselho: e?.numeroConselho,
      estado: e?.estado,
      profissionalSaude: e && {id: e?.id, nome: e?.nome}});
  }

  const handleAdicionaExecutante = () => {
    const listaExecutante = [...executantes, itemExecutante];
    const dadosExecucao = {
      ...itemSelecionado,
      executantes: listaExecutante,
    };
    
    setItemExecucao(dadosExecucao);
    setExecutantes(listaExecutante);
    setItemExecutante({});
  };

  const loadAllTuss35 = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllTuss35TerminologiaGrauParticipacao",
      list: "35TerminologiaGrauParticipacao",
    });
  };

  const handleLoadMoreOptions = async ({ search, data, method, list }) => {
    const response = await findAllTuss(
      {
        pageableDTO: {
          pageNumber: data.page,
          pageSize: 30,
          sortDir: "ASC",
          sortField: "termo",
        },
        search,
      },
      list
    );
    const { content, last } = response[method];
    return {
      options: content,
      hasMore: !last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const loadProfissionalSaude = async (search, loadedOptions, { page }) => {
    try {
      const { content } = (await profissionalExecutante({
        search,
      })).data.data.findAllProfissionalSaude;

      return {
        options: content,
      };
    } catch {
      openNotification("Erro ao carregar profissional de saúde ", "error");
    }
  };

  const handleChangeEdit = (e, index) => {
    const executanteEditado = executantes[index];
    const listaExecutantes = [
      ...executantes.slice(0, index),
      {
        ...executanteEditado,
        tuss35TerminologiaGrauParticipacao: e
      },
      ...executantes.slice(index+1, executantes.length)
    ]
    setExecutantes(listaExecutantes);
  };

  const handleChangeEditProfissionalSaude = (e, index) => {
    const executanteEditado = executantes[index];
    const listaExecutantes = [
      ...executantes.slice(0, index),
      {
        ...executanteEditado,
        conselhoProfissionalSaude: e?.conselhoProfissionalSaude,
        cpf: e?.cpf,
        id: e?.id,
        tuss24TerminologiaCBO: e?.informacao?.tuss24TerminologiaCBO,
        nome: e?.nome,
        numeroConselho: e?.numeroConselho,
        estado: e?.estado,
        profissionalSaude: {id: e?.id, nome: e?.nome}
      },
      ...executantes.slice(index+1, executantes.length)
    ]
    setExecutantes(listaExecutantes);
  };



  const handleConfirmar = (index) => {
    const executanteEditado = executantes[index];
    const listaExecutantes = [
      ...executantes.slice(0, index),
      {
        ...executanteEditado,
        edit: false,
      },
      ...executantes.slice(index+1, executantes.length)
    ];

    const dadosExecucao = {
      ...itemSelecionado,
      executantes: listaExecutantes,
    };
    
    setItemExecucao(dadosExecucao);

    setExecutantes(listaExecutantes);
  };

  const onClickEdit = (selecionado) => {
    setExecutantes(executantes.map((item) => {
      return item.profissionalSaude === selecionado.profissionalSaude
        ? {
            ...item,
            edit: true,
          }
        : item;
    }));
  };

  const handleExcluir = (index) => {
    const listaExecutantes = [
      ...executantes.slice(0, index), 
      ...executantes.slice(index + 1, executantes.length)
    ];

    const dadosExecucao = {
      ...itemSelecionado,
      executantes: listaExecutantes,
    };

    setExecutantes(listaExecutantes);
    setItemExecucao(dadosExecucao);
  };

  return (
    <div className={classes.contentTableItens}>
      <div className={classes.rowAdicionar}>
        <Grid item xs={3}>
          <Typography className={classes.typography} component="label">
            Grau part
          </Typography>
          <TextFieldSearch
            placeholder=""
            classNotched={classes.notchedOutline}
            classInput={classNames(classes.inputTextField, classes.marginRight)}
            classIcons={classes.classIcons}
            classes={{paper: classes.menuHeight}}
            loadOptions={loadAllTuss35}
            getOptionLabel={(option) => option.codigoTermo}
            getOptionValue={(option) => option}
            value={itemExecutante.tuss35TerminologiaGrauParticipacao}
            onChange={(e) =>
              handleChangeItem(e)
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.typography} component="label">
            Nome do profissional:
          </Typography>
          <TextFieldSearch
            placeholder=""
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            classes={{paper: classes.menuHeight}}
            loadOptions={loadProfissionalSaude}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={itemExecutante.profissionalSaude}
            onChange={(e) => handleChangeItemProfissionalSaude(e)}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </Grid>
        <Grid xs={2} className={classes.buttonsAction}>
          <ButtonLong
            colorCustom="yellow"
            onClick={handleAdicionaExecutante}
            className={classes.buttonAdicionarExecutante}
            disabled={!itemExecutante.profissionalSaude || !itemExecutante.tuss35TerminologiaGrauParticipacao}
          >
            Adicionar
          </ButtonLong>
        </Grid>
      </div>
      <div className={classes.listaExecutantes}>
        {executantes.map((item, index) => {
          return item.edit ? (
            <div className={classes.rowAdicionar}>
              <Grid item xs={3}>
                <TextFieldSearch
                  placeholder=""
                  classNotched={classes.notchedOutline}
                  classInput={classNames(
                    classes.inputTextField,
                    classes.marginRight
                  )}
                  classIcons={classes.classIcons}
                  loadOptions={loadAllTuss35}
                  getOptionLabel={(option) => option.codigoTermo}
                  getOptionValue={(option) => option}
                  value={item.tuss35TerminologiaGrauParticipacao}
                  onChange={(e) =>
                    handleChangeEdit(
                      e,
                      index
                    )
                  }
                  withPaginate
                  debounceTimeout={300}
                  additional={{
                    page: 0,
                  }}
                  menuPosition="fixed"
                />
              </Grid>
              <Grid item xs={7}>
                <TextFieldSearch
                  placeholder=""
                  classNotched={classes.notchedOutline}
                  classInput={classes.inputTextField}
                  classIcons={classes.classIcons}
                  loadOptions={loadProfissionalSaude}
                  getOptionLabel={(option) => option.nome}
                  getOptionValue={(option) => option.id}
                  value={item.profissionalSaude}
                  onChange={(e) =>
                    handleChangeEditProfissionalSaude(e, index)
                  }
                  withPaginate
                  debounceTimeout={300}
                  additional={{
                    page: 0,
                  }}
                  menuPosition="fixed"
                />
              </Grid>
              <Grid xs={2} className={classes.buttonsAction}>
                <ButtonLong
                  colorCustom="yellow"
                  onClick={() => handleConfirmar(index)}
                  className={classes.buttonConfirmarExecutante}
                >
                  Confirmar
                </ButtonLong>
              </Grid>
            </div>
          ) : (
            <div className={classes.rowExecutantes}>
            <div className={classes.rowAdicionar}>
              <Grid item xs={3} className={classes.paddingLeft}>
                {item.tuss35TerminologiaGrauParticipacao?.codigoTermo}
              </Grid>
              <Grid item xs={7} className={classes.paddingLeft}>
                {item.profissionalSaude?.nome}
              </Grid>
              <Grid item xs={2} className={classes.buttonsAction}>
                <div
                  onClick={() => onClickEdit(item)}
                  className={classNames(
                    classes.buttonItemSelecionado,
                    classes.buttonSaveItemSelecionado
                  )}
                >
                  <EditIcon />
                </div>
                {index !== 0 && <div
                  onClick={() => handleExcluir(index)}
                  className={classNames(
                    classes.buttonItemSelecionado,
                    classes.buttonCloseItemSelecionado
                  )}
                >
                  <CloseIcon />
                </div>}
              </Grid>
            </div>
            {verificaCamposObrigatorioExecutante(item) && 
            <div className={classes.mensagemDadosObrigatorio}> 
              O executante não possui todos os dados obrigatórios. 
            </div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles)(Executantes);
