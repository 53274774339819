import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const SearchIcon = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M6.16663 0C9.20419 0 11.6666 2.46243 11.6666 5.5C11.6666 6.74832 11.2507 7.89951 10.55 8.82257L14.697 12.9697C14.9899 13.2626 14.9899 13.7374 14.697 14.0303C14.4307 14.2966 14.014 14.3208 13.7204 14.1029L13.6363 14.0303L9.4892 9.88336C8.56613 10.5841 7.41495 11 6.16663 11C3.12906 11 0.666626 8.53757 0.666626 5.5C0.666626 2.46243 3.12906 0 6.16663 0ZM6.16663 1.5C3.95749 1.5 2.16663 3.29086 2.16663 5.5C2.16663 7.70914 3.95749 9.5 6.16663 9.5C8.37576 9.5 10.1666 7.70914 10.1666 5.5C10.1666 3.29086 8.37576 1.5 6.16663 1.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchIcon;
