const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",

    "& div#grafico-atendimento": {
      marginLeft: '-60px',
      width: 'calc(100% + 80px) !important',
    },

    "& div#grafico-tempo-espera": {
      marginLeft: '-20px',
      width: 'calc(100% + 50px) !important',
    },
  },

  title: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#505050',
  },

  cardRelatorio: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFF',
    color: '#000',
    width: '100%',
    height: '156px',
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
      color: '#219A97',
      background: '#F1FCFA',
      border: 'solid 1px #219A97 '
    }

  },
  cardText: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    alignItems: 'flex-end'
  },

  contentButtomRelatorio: {
    alignSelf: 'center',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    height: "77vh",
    maxHeight: "calc(100vh - 64px - 64px)",
    overflow: "auto",
    [theme.breakpoints.down(1200)]: {
      padding: "0 1%",
    },
    [theme.breakpoints.down(1900)]: {
      padding: "0 2%",
    },
    [theme.breakpoints.up(1900)]: {
      padding: "0 10%",
    },
  },

  boxTotaisSaldoGrafico: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      "&.arrow": {
        position: "absolute",
        right: 10,
        bottom: 10,
        height: 25,
      }
    }
  },

  boxWhite: {
    width: "100%",
    height: 250,
    borderRadius: 30,
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: '15px 15px 0 0',
    boxSizing: "border-box",

    "& .recharts-cartesian-axis-tick": {
      fontSize: 10,

      "& text": {
        fill: theme.palette.commons.fontColor,
      }
    },

    "& .recharts-pie-sector .recharts-sector": {
      stroke: "none"
    },

    "& .recharts-pie-labels text": {
      fontSize: 12
    },

    "& .custom-tooltip": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      padding: '0 10px',
      height: 30,
      borderRadius: 6,
      boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",

      "& .label": {
        fontSize: 14,
        color: theme.palette.commons.fontColor
      }
    }
  },

  boxAgenda: {
    padding: 0,
  },

  agendaGrafico: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "55%",
  },

  agendaGraficoLegenda: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%",
    height: "80%",
    borderLeft: "2px solid #e5e5e3",
    paddingLeft: 15,
  },

  agendaGraficoLegendaItem: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },

  agendaGraficoLegendaIcone: {
    width: 25,
    height: 25,
    borderRadius: "50%",
  },

  agendaGraficoLegendaLabel: {
    color: theme.palette.commons.fontColor,
    fontSize: 12,
    width: "calc(100% - 50px)",
    marginLeft: 10,
  },

  agendaGraficoLegendaTotal: {
    color: theme.palette.commons.fontColor,
    fontSize: 12,
    textAlign: "center",
  },

  boxWhiteTitle: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    color: "#00b0ae",
    marginTop: 15,
    marginBottom: 5,
  },

  buttonProfile: {
    display: 'flex',
    gap: '16px',
  },

  reactSelect: {
    width: '100%',
    maxWidth: '200px',
    "&>div>div": {
      borderRadius: '8px',
      height: '40px',
    }
  },

  labelButton: {
    marginLeft: '12px',
    color: '#505050'
  },


  divider: {
    border: '1px solid #ECE9F1',
    transform: 'rotate(0.38deg)',
    maxWidth: '160px',
    display: 'flex',
    margin: '0px 20%',
  },

  informacaoAgenda: {
    gap: '10px',
    background: '#219A97',
    borderRadius: '250px',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 700,
    padding: '4px 11px',
    marginTop: 10,
  },

  informacaoAtendido: {
    opacity: 0.5,
  },

});

export default styles;
