import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const SideMenu = (props) => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M1.68822 16.3945H25.468C26.1976 16.3945 26.7891 16.986 26.7891 17.7156C26.7891 18.3932 26.2791 18.9515 25.622 19.0279L25.468 19.0367H1.68822C0.958602 19.0367 0.367126 18.4453 0.367126 17.7156C0.367126 17.0381 0.877123 16.4797 1.53416 16.4034L1.68822 16.3945H25.468H1.68822ZM1.68806 8.46797L25.4678 8.4651C26.1974 8.46502 26.7891 9.05642 26.7891 9.78604C26.7891 10.4635 26.2792 11.022 25.6222 11.0984L25.4681 11.1073L1.68838 11.1102C0.958761 11.1102 0.367126 10.5188 0.367126 9.78922C0.367126 9.11172 0.876986 8.55326 1.534 8.47687L1.68806 8.46797L25.4678 8.4651L1.68806 8.46797ZM1.68822 0.541382H25.468C26.1976 0.541382 26.7891 1.13286 26.7891 1.86248C26.7891 2.53999 26.2791 3.09838 25.622 3.17469L25.468 3.18358H1.68822C0.958602 3.18358 0.367126 2.5921 0.367126 1.86248C0.367126 1.18497 0.877123 0.626583 1.53416 0.55027L1.68822 0.541382H25.468H1.68822Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default SideMenu;
