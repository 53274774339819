import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const CircleError = props => {
  return (
    <svg 
      width="50" 
      height="50" 
      viewBox="0 0 50 50" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <circle cx="25" cy="25" r="25" fill="currentColor"/>
      <path d="M25 15C30.523 15 35 19.478 35 25C35 30.522 30.523 35 25 35C19.477 35 15 30.522 15 25C15 19.478 19.477 15 25 15ZM25 16.667C20.405 16.667 16.667 20.405 16.667 25C16.667 29.595 20.405 33.333 25 33.333C29.595 33.333 33.333 29.595 33.333 25C33.333 20.405 29.595 16.667 25 16.667ZM24.9987 27.5022C25.5502 27.5022 25.9973 27.9494 25.9973 28.5009C25.9973 29.0524 25.5502 29.4996 24.9987 29.4996C24.4471 29.4996 24 29.0524 24 28.5009C24 27.9494 24.4471 27.5022 24.9987 27.5022ZM24.9945 20C25.3742 19.9997 25.6882 20.2816 25.7381 20.6476L25.7451 20.7494L25.7487 25.251C25.749 25.6652 25.4135 26.0013 24.9993 26.0016C24.6196 26.0019 24.3055 25.72 24.2556 25.354L24.2487 25.2522L24.2451 20.7506C24.2447 20.3364 24.5802 20.0003 24.9945 20Z" fill="white"/>
    </svg>
  );
};

export default CircleError;