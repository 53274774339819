import { observable, action } from "mobx";

import Api from "../../config/api";
import string from "../../utils/string";
import { findAllContaBancaria } from "../../services/ContaBancariaService";
import BaseStore from "../Base.store";

const defaultObjView = {
  id: null,
  nome: null,
  saldoInicial: 0,
  banco: null,
  unidade: null
};

const defaultSearchDTO = {
  pageNumber: 0,
  pageSize: 20,
  sortDir: "ASC",
  sortField: "nome"
};

export const defaultFilters = {
  search: '',
  ativo: true,
};

export const defaultModalConfirmacao = {
  open: false,
  title: "",
  color: "",
  icon: null,
  buttons: null,
};

export default class ContaBancariaStore extends BaseStore {
  usuarioStore = null;
  @observable currentPage = null;
  @observable totalElements = 0;
  @observable numberOfElements = 0;
  @observable loading = true;
  @observable saving = false;
  @observable opening = false;
  @observable open = false;
  @observable contaBancariaList = [];
  @observable objView = {
    ...defaultObjView
  };
  @observable searchDTO = {
    ...defaultSearchDTO
  };
  @observable filters = defaultFilters;
  @observable last = false;
  @observable modalConfirmacao = defaultModalConfirmacao;

  @action async findAll(clear) {
    try {
      if (this.currentPage === this.searchDTO.pageNumber) {
        return;
      }

      if(clear) {
        this.searchDTO.pageNumber = 0;
        this.contaBancariaList = [];
      }
      
      this.currentPage = clear && this.searchDTO.pageNumber;
      
      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = unidadeAtual.id;

      this.loading = true;
      
      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput) {
              findAllContaBancaria(searchDTO: $searchDTO) {
                  totalElements
                  numberOfElements
                  content {
                      id
                      nome
                      saldoInicial
                      banco {
                        id
                        nome
                      }
                  }
              }
          }
                `,
        variables: {
          searchDTO: {
            ...this.searchDTO,
            unidadeId
          }
        }
      });

      const { content, numberOfElements } = response.data.data.findAllContaBancaria;

      const list = content.map(item => {
        return {
          ...item,
          isOculta: true,
          saldoAtual: ""
        }
      })

      this.numberOfElements =numberOfElements;
      
      if (this.numberOfElements === 0 && string.isEmpty(this.searchDTO.search)) {
        return;
      }
      
      if(this.searchDTO.pageNumber > 1) {
        this.contaBancariaList = [...this.contaBancariaList, ...list];
      } else {
        this.contaBancariaList = [...list];
      }
      this.searchDTO.pageNumber += 1;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
  
  @action async findAllBanco(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "codigo"
      };
      
      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
            findAllBanco(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
                codigo
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...searchDTO
          },
          pageableDTO: defaultSearchDTO
        }
      });

      const { last, content } = response.data.data.findAllBanco;
      
      const customContent = content.map(c => ({
        ...c,
        value: c.id,
        label: `${c.codigo} - ${c.nome}`
      }));
      
      return {
        last,
        content: customContent,
      };
    } catch (error) {
      throw error;
    }
  }

  @action async save(contaBancaria) {
    try {
      if (this.saving) {
        return;
      }

      if (!contaBancaria) {
        throw new Error('Preencha os dados');
      }

      if (
        string.isEmpty(contaBancaria.nome) ||
        !contaBancaria.banco ||
        string.isEmpty(contaBancaria.saldoInicial)
      ) {
        throw new Error('Preencha os dados');
      }

      let metodo = "create";
      if (contaBancaria.id) {
        metodo = "update";
      }

      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = unidadeAtual.id;

      this.saving = true;
      await Api.post("", {
        query: `
          mutation ($contaBancaria: ContaBancariaInput) {
              ${metodo}ContaBancaria(contaBancaria: $contaBancaria) {
                  id
              }
          }
        `,
        variables: {
          contaBancaria: {
            ...contaBancaria,
            saldoInicial: string.currencyMaskToFloat(contaBancaria.saldoInicial),
            banco: {
              id: contaBancaria.banco.id,
            },
            unidade: {
              id: unidadeId
            }
          }
        }
      });
    } catch (error) {
      throw error;
    } finally {
      this.saving = false;
    }
  }

  @action edit(id) {
    this.loadById(id);
  }

  @action openNew() {
    this.open = true;
    this.objView = {
      ...defaultObjView
    };
  }

  @action async loadById(id) {
    try {
      this.opening = true;
      const response = await Api.post("", {
        query: `
            query ($id: Long) {
                findByIdContaBancaria(id: $id) {
                    id
                    nome
                    saldoInicial
                    banco {
                      id
                      nome
                      codigo
                    }
                }
            }
        `,
        variables: {
          id
        }
      });

      const data = response.data.data.findByIdContaBancaria;
      
      this.objView = {
        ...data,
        banco: {
          ...data.banco,
          value: data.banco.id,
          label: `${data.banco.codigo} - ${data.banco.nome}`
        },
        saldoInicial: parseFloat(data.saldoInicial).toFixed(2).replace('.', ',')
      };
      this.open = true;
    } catch (error) {
      throw error;
    } finally {
      this.opening = false;
    }
  }

  @action closeModal() {
    this.open = false;
    this.objView = {
      ...defaultObjView
    };
  };

  @action findAllContaBancaria = async(clear) => {
    try {
      let searchDTO = this.searchDTO;
      let list = this.contaBancariaList;

      if(clear) {
        searchDTO=defaultSearchDTO;
        list = []
      }
      this.loading = true;

      const response = await findAllContaBancaria({searchDTO: {
        ...searchDTO,
        ...this.filters
      }});

      const { content, last } = response;

      const contasBancaria = content.map(item => {
        return {
          ...item,
          isOculta: true,
          saldoAtual: ""
        }
      })

      this.contaBancariaList = [...list, ...contasBancaria];
      this.last = last;
      this.searchDTO={
        ...this.searchDTO,
        pageNumber: this.searchDTO.pageNumber+1
      }
  
    } catch {
      this.openNotification(
        "Erro ao carregar as contas bancária",
        "error"
      );
    } finally {
      this.loading = false;
    }
  };
}
