import React, { useEffect, useState } from "react";

import { withStyles } from "@material-ui/core/styles/index";

import { ReactSelect, ReactSelectCheckbox } from "../../components/Select/SelectSearch";
import { InputDateForm } from "../../components/Modal/Input"
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button } from "../ui/Buttons";
const styleInputSearch = () => ({
    reactSelect: {
        width: "100%",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        gap: "8px",
        marginTop: "20px"
    },
    dateTime: {
        width: "100%",
        fontFamily: "Poppins !important",
        fontSize: 12,
        "& input": {
            color: "#505050",
            fontWeight: 500,
        },
    },
    wrapperFields: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginTop: "8px",
        "& span": {
            color: "#868686",
            fontSize: "12px",
            fontWeight: 400,
        },
    },
});

const FiltroBuscaHorariosDisponiveis = observer((props) => {
    const {
        classes,
        searchHorarios,
        redefinirFiltros,
        handleChangeInput,
        filterHorarioDisponivel,
        usuarioStore,
        profissionalSaudeStore,
        atendimentoStore
    } = props;

    const [listaProfissinalSaude, setListaProfissinalSaude] = useState([]);

    const loadProfissionaisSaude = async () => {
        const { especialidadeMedica, tipoConsulta } = filterHorarioDisponivel;
        const tipoConsultaSelected = tipoConsulta?.length === 1 ? tipoConsulta[0]?.value : null;
        const unidadeLogada = await usuarioStore.getUnidadeAtual();
        const listaProfissinalSaude = await profissionalSaudeStore.findByUnidadeComAgenda(unidadeLogada?.id, especialidadeMedica?.value, tipoConsultaSelected);
        atendimentoStore.listaProfissionalSaudeComAgenda = listaProfissinalSaude;
        setListaProfissinalSaude(listaProfissinalSaude);
    }

    const periodos = [
        { label: 'Todos', value: {horaInicio: '00:00', horaFim: '23:59'} },
        { label: 'Manhã', value: {horaInicio: '00:00', horaFim: '12:00'} },
        { label: 'Tarde', value: {horaInicio: '12:00', horaFim: '18:00'} },
        { label: 'Noite', value: {horaInicio: '18:00', horaFim: '23:59'} },
    ];

    const tipoConsulta = [
        { label: 'Presencial', value: 'PRESENCIAL' },
        { label: 'Online', value: 'ONLINE' },
    ];

    useEffect(() => {
        loadProfissionaisSaude();
    }, [filterHorarioDisponivel.especialidadeMedica, filterHorarioDisponivel.tipoConsulta]);


    useEffect(() => {
        getSpecializationsUnidade();
    }, []);

    const getSpecializationsUnidade = () => {
        const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);
        profissionalSaudeStore.getSpecializations(unidadeLogada.id);
    };

    return (
        <div className={classes.content}>
            <div className={classes.wrapperFields}>
                <div>
                    <span>Especialidade</span>
                    <ReactSelect
                        className={classes.reactSelect}
                        name="especialidade"
                        placeholder={"Especialidade"}
                        value={filterHorarioDisponivel.especialidadeMedica}
                        onChange={(e) => handleChangeInput(e, 'especialidadeMedica', 'filterHorarioDisponivel')}
                        options={profissionalSaudeStore.specializationList || []}
                        isClearable
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                    />
                </div>
                <div>
                    <span>Tipo</span>
                    <ReactSelectCheckbox
                        className={classes.reactSelect}
                        onChange={(e) => handleChangeInput(e, 'tipoConsulta', 'filterHorarioDisponivel')}
                        options={tipoConsulta || []}
                        value={filterHorarioDisponivel.tipoConsulta}
                    />
                </div>
                <div>
                    <span>Profissional Saúde </span>
                    <ReactSelect
                        className={classes.reactSelect}
                        name="Profissional Saúde"
                        placeholder={"Profissional Saúde"}
                        value={filterHorarioDisponivel.profissionalSaudeId}
                        onChange={(e) => handleChangeInput(e, 'profissionalSaudeId', 'filterHorarioDisponivel')}
                        options={listaProfissinalSaude || []}
                        isClearable
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div>
                    <span>Períodos </span>
                    <ReactSelect
                        className={classes.reactSelect}
                        name="Períodos"
                        placeholder={"Todos"}
                        value={filterHorarioDisponivel.periodos}
                        options={periodos || []}
                        onChange={(e) => handleChangeInput(e, 'periodos', 'filterHorarioDisponivel')}
                    />
                </div>
                <div>
                    <span>Data início </span>

                    <InputDateForm
                        id="dataInicio"
                        iconposition="end"
                        className={classes.dateTime}
                        classes={{
                            date: classes.inputDadosAgendamento,
                        }}
                        value={filterHorarioDisponivel.dataInicio}
                        onChange={(e) => handleChangeInput(e, 'dataInicio', 'filterHorarioDisponivel')}
                    />
                </div>

                <div>
                    <span>Data fim</span>

                    <InputDateForm
                        id="dataFim"
                        iconposition="end"
                        className={classes.dateTime}
                        classes={{
                            date: classes.inputDadosAgendamento,
                        }}
                        value={filterHorarioDisponivel.dataFim}
                        onChange={(e) => handleChangeInput(e, 'dataFim', 'filterHorarioDisponivel')}
                    />
                </div>
            </div>

            <div className={classes.buttons}>
                <Button
                    onClick={redefinirFiltros}
                    kind="transparent"
                    fullWidth
                >
                    Redefinir filtros
                </Button>
                <Button
                    onClick={searchHorarios}
                    kind="primary"
                    fullWidth
                >
                    Pesquisar horários
                </Button>
            </div>
        </div>
    );
});

const FiltroBuscaHorariosDisponiveisWithStyles = withStyles(styleInputSearch)(FiltroBuscaHorariosDisponiveis);
export default inject('profissionalSaudeStore', 'usuarioStore', 'atendimentoStore')(FiltroBuscaHorariosDisponiveisWithStyles);