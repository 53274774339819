import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { StepConnector } from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
    '& div': {
      padding: '0px',
    },
    '& span': {
      fontSize: '0.85rem',
      fontWeight: '400',
      fontFamily: 'Poppins',
      color: '#000000',
    },
  },
  typography: {
    color: '#000000',
    fontSize: '0.7rem',
    fontFamily: 'Poppins',
    fontWeight: '400',
    textAlign: 'center',
  },
})

const Connector = withStyles({
  alternativeLabel: { top: '0.75rem', left: 'calc(-50% + 0.75rem)', right: 'calc(50% + 0.75rem)' },
  active: { '& $line': { borderColor: '#219A97' } },
  completed: { '& $line': { borderColor: '#219A97' } },
  line: {
    borderWidth: '4px',
  },
})(StepConnector)

const HorizontalLinearStepper = ({ classes, activeStep, steps }) => {
  return (
    <div className={classes.root}>
      <Stepper
        classes={{
          alternativeLabel: classes.paperRoot,
        }}
        className={classes.paperRoot}
        activeStep={activeStep}
        alternativeLabel
        orientation='horizontal'
        connector={<Connector />}
      >
        {steps.map((step, index) => {
          const stepProps = {}
          const labelProps = {}
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{step.label || step}</StepLabel>
              {step.description && <Typography className={classes.typography}>{step.description}</Typography>}
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}

export default withStyles(styles)(HorizontalLinearStepper)
