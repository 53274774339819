import React from "react";
import moment from "moment";
import Dialog from "../../../components/Dialog/Dialog";
import { Typography, CircularProgress } from "@material-ui/core";
import {inject, observer} from "mobx-react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PrintIcon from "@material-ui/icons/Print";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Fab, Grid } from "@material-ui/core";
import Api from "../../../config/api";
import InputDateForm from "../../../components/Input/InputDateForm";
import ProntuarioContent from '../../../template/pdf/prontuario/Prontuario';
import { isIOS } from "../../../utils/OS";
import {findUnidadeLogada} from "../../../services/UnidadeService";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import PageTitle from "../../../components/PageTitle/PageTitle";
import string from "../../../utils/string";

@inject('configuracaoImpressaoStore', 'profissionalSaudeStore', 'prontuarioStore')
@observer
class Imprimir extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataInicial: null,
            dataFinal: null,
            printing: false,
            mensagens: [],
            unidade: {},
            loadingButtonImpressao: false,
            isPrintMustache: false,
        };
    }

    async componentDidMount() {
        const { profissionalSaudeStore} = this.props;

        await profissionalSaudeStore.carregaProfissionalSaude();

        await this.props.configuracaoImpressaoStore.getConfig('OUTROS');

        await findUnidadeLogada().then(resposta => {
            this.setState({
                ...this.state,
                unidade: resposta
            })
        });
    }

    handleChangeDate = (field, date) => {
        this.setState({ [field]: date });
    };

    handlePrint = async () => {
        try {
            if (isIOS) {
                this.newWindowRef = window.open('', 'print_prontuario');
    
                if (this.newWindowRef) {
                    const loadingContent = `<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
                        <h1 style="font-family: -apple-system;">Aguarde...</h1>
                    </div>`;
                    this.newWindowRef.document.write(loadingContent);
                }
            }

            const { prontuarioStore } = this.props;
            const { selectedsTypesToShow } = prontuarioStore;
            const { dataInicial, dataFinal } = this.state;

            const onlyLoggedProfissional = selectedsTypesToShow.includes('PROFISSIONAL');
            
            this.setState({ loadingButtonImpressao: true });
            
            const response = await Api.post("", {
                query: `
            query($searchDTO: SearchEntradaProntuarioDTOInput) {
              findAllEntradaProntuario(searchDTO: $searchDTO) {
                totalElements
                totalPages
                content {
                    id
                    dataHoraLancamento
                    texto
                    tipo
                    profissionalSaude {
                        nome
                    }
                    entradaProntuarioInativacao{
                        dataInativacao
                        inativador{
                            nome
                        }
                        motivo
                    }
                    ativo
                }
              }
            }`,
                variables: {
                    searchDTO: {
                        pageNumber: 0,
                        pageSize: 9999,
                        profissionalSaudeId: onlyLoggedProfissional ? this.props.profissionalSaude?.id : null,
                        sortDir: "ASC",
                        sortField: "dataHoraLancamento",
                        sujeitoAtencaoId: this.props.sujeitoAtencao?.id,
                        unidadeId: this.props.unidadeAtual?.id,
                        dataInicial: dataInicial
                            ? moment(dataInicial).format("YYYY-MM-DD")
                            : undefined,
                        dataFinal: dataFinal
                            ? moment(dataFinal).format("YYYY-MM-DD")
                            : undefined
                    }
                }
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            const mensagens = response?.data?.data?.findAllEntradaProntuario?.content || [];
            const onlyTextMessages = mensagens.map((message) => {
                return {
                    ...message,
                    texto: string.stripHtml(message.texto).replace(['\n', '\t'], '')
                }
            });
            this.setState({ mensagens: onlyTextMessages });
            this.imprimirHtml();
        } catch (error) {
            throw error;
        }
    };

    imprimirHtml = () => {
        this.setState({ 
            isPrintMustache: true,
            printing: false, 
        });
    }

    render() {
        const { classes, open, handleClose } = this.props;
        const { loadingButtonImpressao } = this.state;

        return (
            <>
            <PageTitle title="Imprimir prontuário"/>
            <Dialog
                classes={{
                    scrollPaper: classes.scrollPaper,
                    paper: classes.paper
                }}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle
                    classes={{ root: classes.dialogTitle }}
                    disableTypography
                >
                    <Typography
                        color="primary"
                        component="h1"
                        className={classes.title}
                    >
                        Imprimir Prontuário
                    </Typography>
                    <Fab
                        className={classes.buttonClose}
                        color="primary"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </Fab>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <Typography color="primary" component="label">
                                    Data Inicial
                                </Typography>
                                <InputDateForm
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.dataInicial}
                                    onChange={e =>
                                        this.handleChangeDate("dataInicial", e)
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <Typography color="primary" component="label">
                                    Data Final
                                </Typography>
                                <InputDateForm
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.dataFinal}
                                    onChange={e =>
                                        this.handleChangeDate("dataFinal", e)
                                    }
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Grid className={classes.actions}>
                    <Fab className={classes.buttonImpressao} onClick={this.handlePrint} disabled={loadingButtonImpressao}>
                        {!loadingButtonImpressao && <PrintIcon color='primary' />}
                        {loadingButtonImpressao && <CircularProgress />}
                    </Fab>
                </Grid>
                { this.state.isPrintMustache && 
                    <ImpressaoHtml
                        type='prontuario'
                        isPrintMustache={this.state.isPrintMustache}
                        sujeitoAtencaoId={this.props.sujeitoAtencao?.id}
                        handlePrintMustache={() => {this.setState({ isPrintMustache: false })}}
                        htmlStringComponent={ <ProntuarioContent 
                            mensagens={this.state.mensagens}
                            sujeitoAtencao={this.props.sujeitoAtencao}
                         />}
                        habilitaBotaoImpressao={() => this.setState({ loadingButtonImpressao: false })}
                    />}
            </Dialog>
            </>
        );
    }
}

export default withStyles(theme => ({
    root: {
        position: "relative",
        overflow: "none",
        zIndex: 1
    },
    paper: {
        borderRadius: 35,
        overflow: "unset",
        height: 160
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        borderRadius: 15
    },
    title: {
        fontSize: 16,
        marginLeft: 5
    },
    dialogContent: {
        overflowY: "hidden",
        margin: 0,
    },
    actions: {
        position: "absolute",
        right: 0,
        bottom: -65
    },
    input: {
        marginLeft: 5,
        marginRight: 5
    },
    container: {
        height: "300px",
        border: "1px solid " + theme.palette.commons.gray3,
        marginTop: "20px",
        padding: "20px",
        borderRadius: 10
    },
    buttonImpressao: {
        backgroundColor: "#fff"
    },
    buttonClose: {
        width: "30px",
        height: "30px",
        minHeight: "initial"
    },

}))(Imprimir);
