import React from "react";
import { withStyles } from "@material-ui/core";

const DivisorVisualizacaoDente = ({ classes }) => {
  const visualizacaoDente = [
    { label: "Vista frontal", isTopo: false },
    { label: "Vista topo", isTopo: true },
    { label: "Vista frontal", isTopo: false },
  ];

  return (
    <div className={classes.content}>
      {visualizacaoDente.map((item) => {
        return (
          <div
            className={
              item.isTopo
                ? classes.contentDivisorVistaTopo
                : classes.contentDivisor
            }
          >
            <div className={classes.label}> {item.label} </div>
            <div className={classes.divisorLateralEsquerdo} />
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  content: {
    marginTop: "36px",
    display: "flex",
    gap: "8px",
    flexDirection: "column",
  },
  contentDivisor: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    width: "17px",
  },
  contentDivisorVistaTopo: {
    flex: 1.5,
    flexDirection: "row",
    display: "flex",
    width: "17px",
  },
  label: {
    transform: "rotate(270deg)",
    fontWeight: 500,
    fontSize: "10px",
    color: "#F5F5F5",
    whiteSpace: "nowrap",
    width: "100%",
    alignSelf: "center",
    marginLeft: "12px",
    marginTop: "36px",
  },
  divisorLateralEsquerdo: {
    width: 17,
    border: "1px solid #F9BE73",
    borderRight: "none",
    minWidth: "17px",
    display: "flex",
  },
};

export default withStyles(styles)(DivisorVisualizacaoDente);
