import Api from "../config/api";

export const findByDadosBraspagProfissionalSaudeLogado = () => {
  return Api.post('', {
    query: `
      query {
        findByDadosBraspagProfissionalSaudeLogado {
          id
          nomeFantasia
          documento
          documentoTipo
          razaoSocial
          status
          responsavelNome
          responsavelTelefone
          email
          dadoBancario {
            banco {
              id
              nome
              codigo
            }
            operacao
            conta
            digitoVerificador
            agencia
            digitoAgencia
            tipoConta
            documento
          }
          endereco {
            bairro
            cep
            complemento
            estado {
              label: nome
              value: id
            }
            municipio {
              label: nome
              value: id
            }
            nomeLogradouro
            numero
          }
          dadosBraspagAnexo {
            id
            arquivoTipo
            urlDownload
            documentoTipo
            descartado
            amazonS3Objeto {
              nome
              extensao
              nomeComExtensao
              tamanho
            }
          }
          perfilPublico{
            dadosIugu{
              verified
            }
          }
        }
      }
    `
  });
};

export const findAnexosBraspagProfissionalSaudeLogado = () => {
  return Api.post('', {
    query: `
      query {
        findByDadosBraspagProfissionalSaudeLogado {
          dadosBraspagAnexo {
            id
            arquivoTipo
            urlDownload
            documentoTipo
            descartado
            amazonS3Objeto {
              nome
              extensao
              nomeComExtensao
              tamanho
            }
          }
        }
      }
    `
  });
}

export const findByDadosBraspagUnidadeLogada = () => {
  return Api.post('', {
    query: `
      query {
        findByDadosBraspagUnidadeLogada {
          id
          nomeFantasia
          documento
          documentoTipo
          razaoSocial
          status
          responsavelNome
          responsavelTelefone
          email
          dadoBancario {
            banco {
              id
              nome
              codigo
            }
            operacao
            conta
            digitoVerificador
            agencia
            digitoAgencia
            tipoConta
            documento
          }
          endereco {
            bairro
            cep
            complemento
            estado {
              label: nome
              value: id
            }
            municipio {
              label: nome
              value: id
            }
            nomeLogradouro
            numero
          }
          dadosBraspagAnexo {
            id
            arquivoTipo
            urlDownload
            amazonS3Objeto {
              nome
              extensao
              nomeComExtensao
              tamanho
            }
          }
          perfilPublico{
            dadosIugu{
              verified
            }
          }
        }
      }
    `
  });
};

export const updateDadosBraspag = (variables) => {
  return Api.post('', {
    query: `
      mutation($dadosBraspag: DadosBraspagInput) {
        updateDadosBraspag(dadosBraspag: $dadosBraspag) {
          id
        }
      }
    `,
    variables: {
      dadosBraspag: variables,
    }
  });
};

export const createDadosBraspagProfissional = (variables) => {
  return Api.post('', {
    query: `
      mutation($dadosBraspag: DadosBraspagInput) {
        createDadosBraspagProfissionalSaude(dadosBraspag: $dadosBraspag) {
          id
        }
      }
    `,
    variables: {
      dadosBraspag: variables,
    }
  });
};

export const createDadosBraspagUnidade = (variables) => {
  return Api.post('', {
    query: `
      mutation($dadosBraspag: DadosBraspagInput) {
        createDadosBraspagUnidade(dadosBraspag: $dadosBraspag) {
          id
        }
      }
    `,
    variables: {
      dadosBraspag: variables,
    }
  });
};


export const createDadosBraspagAnexo = async (anexo, arquivo) => {
  const response = await Api.post('', {
    query: `
      mutation($anexo: DadosBraspagAnexoInput, $arquivo: ObjetoAmazonS3DTOInput) {
        createDadosBraspagAnexo(anexo: $anexo, arquivo: $arquivo) {
          id
        }
      }
    `,
    variables: {
      anexo,
      arquivo,
    },
  });
  if (response.data.errors) {
    throw response.data.errors;
  }
  return response;
};

export const inativarDadosBraspag = async (id) => {
  const response = await Api.post('', {
    query: `
      mutation($dadosBraspagAnexoId: UUID!) {
        inativarDadosBraspagAnexo(dadosBraspagAnexoId: $dadosBraspagAnexoId) {
          id
        }
      }
    `,
    variables: {
      dadosBraspagAnexoId: id,
    }
  });
  if (response.data.errors) {
    throw response.data.errors;
  }

  return response;
}