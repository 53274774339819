import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const VideoIcon = props => {
  return (
    <svg
      width='27'
      height='28'
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M17.174 9.16517C17.174 7.34112 15.6953 5.86243 13.8713 5.86243H5.94471C4.12066 5.86243 2.64197 7.34112 2.64197 9.16517V18.4129C2.64197 20.2369 4.12066 21.7156 5.94471 21.7156H13.8713C15.6953 21.7156 17.174 20.2369 17.174 18.4129V9.16517ZM18.4951 11.0554V16.5389L22.1469 19.644C22.7903 20.1912 23.7795 19.7339 23.7795 18.8892V8.75979C23.7795 7.91918 22.7986 7.46045 22.1535 7.99937L18.4951 11.0554Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default VideoIcon
