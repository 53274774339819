import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Fab, CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  button: {
    margin: 8,
		height: 40,
		width: 40,
		background: theme.palette.primary.main,
		"&:hover": {
			background: theme.palette.primary.dark,
    },
    color: '#FFFFFF'
  },
  circircularLoad: {
    marginRight: '15px'
  }
});


const ButtonLoading = (props) => {
  const {loading, icon, classes, onClick} = props;
  const Icon = icon;

  return (
    <>
      {loading ? <CircularProgress className={classes.circircularLoad} /> :
        <Fab onClick={onClick} className={classes.button} disabled={loading}>
          <Icon />
        </Fab>
      }
    </>
  )
}

export default withStyles(styles)(ButtonLoading);