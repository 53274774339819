import React from "react";
import cidadeFormatada from "../../../utils/cidadeFormatada";
import { formattedPhone } from "../../../components/Input/TelefoneFormatado";

const renderItem = ({ styles, item, index, utilizaTelefoneInternacional }) => {
    const columnColor = (index) => {
        if (index % 2 !== 0) {
            return { backgroundColor: "#F2F2F2" }

        }
        return ''
    }
    return (
        <div
            style={{ ...styles.containerColuna, ...columnColor(index) }}
            key={index}>
            <div style={styles.colunaDescricao}>
                <div style={styles.text}>
                    {item.nome}
                </div>
            </div>
            <div style={styles.colunaDescricao}>
                <div style={styles.text}>
                    {item.quemIndicou || "-"}
                </div>
            </div>
            <div style={styles.coluna}>
                <div style={styles.text}>
                    {
                        formattedPhone({
                            utilizaTelefoneInternacional: utilizaTelefoneInternacional,
                            telefone: item.contato?.telefonePrincipal || item.contato?.telefoneSecundario,
                            telefoneDDI: item.contato?.telefonePrincipalDDI || item.contato?.telefoneSecundarioDDI
                        })
                    }
                </div>
            </div>
            <div style={styles.coluna}>
                <div style={styles.text}>
                    {
                        cidadeFormatada({
                            municipio: item.endereco?.municipio?.nome,
                            uf: item.endereco?.municipio?.uf,
                            espanhaMunicipio: item.espanhaMunicipio?.descricao
                        })
                    }
                </div>
            </div>
        </div>
    );
};

const RelatorioIndicacoesPdfDocument = (props) => {
    const {
        cidade,
        pesquisadoPor,
        situacao,
        indicacoes,
        utilizaTelefoneInternacional,
    } = props;

    return (
        <>
            <div style={styles.titulo}>
                <div style={styles.textBold}>
                    Relatório indicações
                </div>
            </div>
            <div style={styles.subTitulo}>
                <div style={styles.textBold}>
                    Pesquisado por: {pesquisadoPor || " - "}
                </div>
                <div style={styles.textBold}>
                    Cidade: {cidade?.label || " - "}
                </div>
                <div style={styles.textBold}>
                    Situação: {situacao}
                </div>
            </div>

            <div style={styles.containerColuna}>
                <div style={styles.colunaDescricao}>
                    <div style={styles.textBold}>
                        Paciente
                    </div>
                </div>
                <div style={styles.colunaDescricao}>
                    <div style={styles.textBold}>
                        Indicado por
                    </div>
                </div>
                <div style={styles.coluna}>
                    <div style={styles.textBold}>
                        Telefone
                    </div>
                </div>
                <div style={styles.coluna}>
                    <div style={styles.textBold}>
                        Cidade
                    </div>
                </div>
            </div>

            {indicacoes.map((item, index) =>
                renderItem({ styles, item, index, utilizaTelefoneInternacional })
            )}
        </>
    );
};

const styles = {
    text: {
        fontSize: 12
    },
    textBold: {
        fontSize: 12,
        fontWeight: 700,
    },
    titulo: {
        marginLeft: 0,
        marginBottom: 10,
        marginTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
    },
    subTitulo: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #505050',
        marginBottom: 15,
        paddingBottom: 5,
        justifyContent: 'space-between',
    },
    containerColuna: {
        display: "flex",
        flexDirection: "row",
    },
    coluna: {
        flexGrow: "1",
        width: "50px"
    },
    colunaDescricao: {
        flexGrow: "2",
        width: "90px",
        marginBottom: "10px",
        marginRight: "3px"
    },
};

export default RelatorioIndicacoesPdfDocument;
