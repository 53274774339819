import { withStyles } from "@material-ui/core";
import React from "react";

const CardGrafico = (props) => {
  const { classes, children, title } = props;

  return (
    <div className={classes.card}>
      <div className={classes.title}> {title} </div>
      {children}
    </div>
  );
};

const styles = {
  card: {
    background: "#FFFFFF",
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    padding: "15px 21px",
  },
  title: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#11263C",
    marginBottom: "13px",
  },
};

export default withStyles(styles)(CardGrafico);
