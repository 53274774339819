import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.size && `height: ${props.size}px`};
  ${props => props.size && `width: ${props.size}px`};
  ${props => props.color && `background-color: ${props.color}`};
  ${props => props.textColor && `color: ${props.textColor}`};
  ${props => props.fontSize && `font-size: ${props.fontSize}px !important`};
  border-radius: 50%;
  z-index: 1;
`;

const CircleCount = ({ size, color, textColor, value, fontSize, className}) => {
  return (
    <Circle
      size={size}
      color={color}
      textColor={textColor}
      fontSize={fontSize}
      className={className}
    >
      {value}
    </Circle>
  )
};

export default CircleCount;