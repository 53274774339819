import React from 'react'
import PropTypes from 'prop-types';

import {withStyles} from "@material-ui/core/styles/index";
import {SnackbarContent, IconButton, Snackbar} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import classNames from 'classnames'
import ButtonLong from '../components/Button/ButtonLong';

NotificationContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', '']).isRequired,
};

const styles1 = theme => ({
    success: {
        backgroundColor: "#D4EEEE",
        color: "#1B402F",
    },
    error: {
        backgroundColor: "#F8DBDB",
        color: "#5C1D1D",
    },
    info: {
        backgroundColor: "#E3F4FE",
        color: "#1B204A",
    },
    warning: {
        backgroundColor: theme.palette.commons.yellow,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: '14px',
    },
    content: {
        height: "38px !important",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        position: "fixed",
        bottom: "100px",
    },
    close: {
        width: '30px !important',
        height: '30px !important', 
        color: "#fff",
    },
    successButtonClose: {
        backgroundColor: "#219A97",
    },
    errorButtonClose: {
        backgroundColor: "#FB7676",
    },
    infoButtonClose: {
        backgroundColor: "#717FFC",
    },
    buttonSaibaMais: {
        padding: "0 16px"
    }
});


function NotificationContent(props) {
    const {classes, className, message, onClose, variant, handleSaibaMais, ...other} = props;

    const colorCustom = {
        success: "green",
        error: "red",
        info: "blue",
    };

    return (
        <SnackbarContent
            className={classNames(classes[variant], className, classes.content)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    {message}
                </span>
            }
            action={[
                !!handleSaibaMais ? 
                <ButtonLong 
                    onClick={() => handleSaibaMais()}
                    colorCustom={colorCustom[variant]}
                    className={classes.buttonSaibaMais}
                    noShadow
                >
                    Saiba mais
                </ButtonLong>
                :
                <IconButton
                    key="close"
                    aria-label="Close"
                    classes={{ root: classNames(classes.iconButton, classes[`${variant}ButtonClose`]) }}
                    className={classNames(classes.close)}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon}/>
                </IconButton>
                ,
            ]}
            {...other}
        />
    );
}

const NotificationContentWrapper = withStyles(styles1)(NotificationContent);

function Notification({close, reset, isOpen, message, variant, handleSaibaMais}) {

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isOpen}
            onClose={close}
            onExited={reset}
        >
            <NotificationContentWrapper
                onClose={close}
                variant={variant}
                message={message}
                handleSaibaMais={handleSaibaMais}
            />
        </Snackbar>
    )
}

export default Notification;