import React from "react";
import classNames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { applyCurrencyMask } from "../../../../utils/CurrencyMask";

const Footer = observer((props) => {
  const { classes, valorPago } = props;
  const valorTotal = props.extratoStore.titulo.subtotal;
  const valorNaoPago =  valorTotal-(valorPago || 0);
  return (
    <div className={classes.content}>
      <div className={classes.row}>
        <div className={classNames(classes.label, classes.labelNegrito)}>
          Valor total
        </div>
        <div className={classNames(classes.valorVerde, classes.labelNegrito)}>
          { typeof valorTotal === "string" ? valorTotal : applyCurrencyMask(valorTotal) }
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}> Valor recebido </div>
        <div className={classNames(classes.valorVerde, classes.labelNegrito)}>
          {applyCurrencyMask(valorPago)}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}> Valor restante </div>
        <div
          className={classNames(classes.valorVermelho, classes.labelNegrito)}
        >
          {applyCurrencyMask(valorNaoPago)}
        </div>
      </div>
    </div>
  );
});

const styles = {
  content: {
    marginTop: "8px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    color: "#505050",
    fontSize: "12px",
  },
  labelNegrito: {
    fontWeight: 600,
  },
  valorVerde: {
    color: "#26ACA9",
  },
  valorVermelho: {
    color: "#FB7676",
  },
};

const FooterWithStyles = withStyles(styles)(Footer);
export default inject("extratoStore")(FooterWithStyles);
