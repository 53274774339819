//import styled from "styled-components";
import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core";
import ReceituarioModelo from "../../../Atendimento/Receituario/ReceituarioModelo";
import ListarModeloReceituario from "./ListarModeloReceituario";
import { TypeModalEnumReceituario } from "../../../../stores/Receituario.store";
import { findAllReceitaModelo } from '../../../../services/receituarioService';
import Notification from "../../../../components/Notification";
import HeaderConfiguracoes from "../../../../components/Header/HeaderConfiguracoes";
import { listaStatus } from "../../../../utils/constantes";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../../utils/getPageTitle";
import { Add } from "@material-ui/icons";
import { Button } from "../../../../components/ui/Buttons";

@inject("receituarioStore")
@observer
class ModeloReceituario extends React.Component {
    state = {
        paginaAtual: 0,
        last: false,
        modelosLoading: false,
        search: "",
        notification: {
            open: false,
            message: ""
        },
    }
    componentDidMount() {
        const { receituarioStore } = this.props;
        receituarioStore.changeFieldsStore({
            pageNumberModelo: 0,
            searchNomeModelo: '',
            statusModelo: null,
            sortDirModelo: 'ASC',
            sortFieldModelo: 'nome',
            modelosConfig: [],
        })
        this.loadModelos();
    };

    showAlertMessage = (notification) => {
		this.setState({ notification: notification });

		const timeoutId = setTimeout(() => {
			this.closeAlertMessage();
			clearTimeout(timeoutId);
		}, 3000);
    };

    showAlertMessageDuracao = () => {
        const notification = {
            isOpen: true,
            message: "Falha ao carregar a lista de modelos de receituário.",
        };
        this.showAlertMessage(notification);
    };
    
    closeAlertMessage = () => {
        const notification = {
            isOpen: false,
            message: "",
        };
        this.setState({ notification: notification });
    };

    loadModelos = ()  => {
        const { receituarioStore } = this.props;

        this.setState({modelosLoading: true})
        findAllReceitaModelo({
            search: receituarioStore.searchNomeModelo || '',
            ativo: receituarioStore.statusModelo,
            pageNumber: receituarioStore.pageNumberModelo || 0,
            sortDir: receituarioStore.sortDirModelo || 'ASC',
            sortField: receituarioStore.sortFieldModelo || 'nome'
        }).then(response => {
            const { content, pageable: { pageNumber }, last } = response.data.data.findAllReceitaModelo;
            this.setState({
                paginaAtual: pageNumber,
                last: last
            });
            receituarioStore.modelosConfig = [...receituarioStore.modelosConfig, ...content]
        })
        .catch(() =>{
            this.showAlertMessageDuracao();
        })
        .finally(() => {
            this.setState({modelosLoading: false})
        })  
    }

    handleClickNovoModelo = async () => {
        const { receituarioStore } = this.props;

        receituarioStore.changeFieldsStore({
            isProntuario: false,
            nomeModelo: "",
            statusNovoModelo: true,
            typeModal: TypeModalEnumReceituario.NOVO_MODELO_MODAL,
            openModalModelo: true,
            sugestoesPagAtual: 0,
            sugestoes: [],
        });

        await receituarioStore.findAllMedicamentoQuantidadeUso({
            pageableDTO: {
                pageSize: 6,
                pageNumber: receituarioStore.sugestoesPagAtual,
                sortDir: "DESC",
                sortField: "quantidadeUso"
            }
        })
    }

    handleCloseModalNovoModelo = () => {
        const { receituarioStore } = this.props;
        receituarioStore.changeFieldsStore({
            modelosConfig: [],
            pageNumberModelo: 0,
            openModalModelo: false,
            tipoListagemSelecionada: 'sugestoes',
            idModeloSelecionado: '',
        });
        this.loadModelos();
    }

    handleChangeStatusModelo = (e) => {
        const { receituarioStore } = this.props;
        let value = e.target.value;
        receituarioStore.changeFieldsStore({
            statusModelo: value,
            modelosConfig: [],
            pageNumberModelo: 0,
        })
        this.loadModelos();
    };

    handleClickSearch = () => {
        const { receituarioStore } = this.props;
        receituarioStore.changeFieldsStore({
            searchNomeModelo: this.state.search,
            modelosConfig: [],
            pageNumberModelo: 0,
        });
        this.loadModelos();
    };

    handleClickClear = () => {
        const { receituarioStore } = this.props;
        receituarioStore.changeFieldsStore({
            searchNomeModelo:  '',
            modelosConfig: [],
            pageNumberModelo: 0,
        });
        this.setState({search: ''})
        this.loadModelos();
    };

    onChangeSearchNomeModelo = (e) => {
        this.setState({search: e.target.value})
    };

    onKeypressSearchNomeModelo = (e) => {
        const { receituarioStore } = this.props;
        if (e.key === 'Enter' && this.state.search.length >= 3) {
            receituarioStore.changeFieldsStore({
                searchNomeModelo: this.state.search,
                modelosConfig: [],
                pageNumberModelo: 0,
            });
            this.handleClickSearch();
        }
    };

    render(){
        const { classes, receituarioStore } = this.props;

    return (
        <>
            <div>
                <PageTitle title={pageConfiguracoes("Modelos de receituário")}/>
                <HeaderConfiguracoes
                    handleClickSearch={this.handleClickSearch}
                    handleClickClear={this.handleClickClear}
                    placeholderSearch="Pesquisa por nome do modelo"
                    valueSearch={this.state.search}
                    showSearch
                    onChangeSearch={this.onChangeSearchNomeModelo}
                    onKeypressSearch={this.onKeypressSearchNomeModelo}
                    valueStatus={receituarioStore.statusModelo}
                    showStatus
                    handleChangeStatus={this.handleChangeStatusModelo}
                    status={listaStatus}
                />
                
                <div className={classes.contentList}>
                    <ListarModeloReceituario 
                        loadModelos={() => this.loadModelos()} 
                        paginaAtual={this.state.paginaAtual} 
                        last={this.state.last} 
                        modelosLoading={this.state.modelosLoading}
                    />
                </div>

                <Button
                    shape='circle'
                    bgColor='#26ACA9'
                    onClick={this.handleClickNovoModelo}
                    shadow
                    className={classes.buttonCriar}
                >
                    <Add/>
                </Button>
            </div>

            <Notification
                close={this.closeAlertMessage}
                reset={this.closeAlertMessage}
                isOpen={this.state.notification.isOpen}
                variant={"error"}
                message={this.state.notification.message}
            />

            {this.props.receituarioStore.openModalModelo && <ReceituarioModelo 
                handleClose={this.handleCloseModalNovoModelo} 
                loadModelos={() => this.loadModelos()}
                open={this.props.receituarioStore.openModalModelo}
                screen="Configurações"
            />}
        </>
    )
}};

export default withStyles(theme => ({
    headerProfile:{
        padding: '0 16px',
    },
    header:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contentList:{
        display: 'flex',
        flexDirection: 'row',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
    },
    buttonNovoModelo:{
        width: 152,
        height: 40,
        borderRadius: 87,
        boxShadow: 'none',
        background: '#F9BE73',
        color: '#fff',
        textTransform: 'capitalize',
        padding: '0 14px',
        marginLeft: 24,
        minWidth: 152,
        "@media (max-width: 1600px)": {
            width: 40,
            minWidth: 40,
            borderRadius: '100%',
        }
    },
    labelButtonNovoModelo: {
        marginLeft: '6px',
        "@media (max-width: 1600px)": {
            display: 'none',
        }
    },

    columnHeaderFiltro:{
        justifyContent: 'center',
        width: 70,
    },
    columnHeaderNome:{
        width: '50%',
    },
    columnHeaderItens: {
        width: '15%'
    },
    columnHeaderStatus:{
        width: '15%',
    },
    columnHeaderLabel:{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 20,
        alignItems: 'center',
    },
    arrowIcon: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterButton: {
        width: 48,
        height: 48,
        background: '#fff',
        boxShadow: 'none',
        marginRight: 20,
    },

    inputRootSearch:{
        border: 'none',
    },
    
    inputSearch:{
        fontFamily: "Poppins!important",
    },

    searchNomeModelo:{
        width: '100%',
        height: 48,
        borderRadius: "8px !important",
        border: 'none !important',
        background: "#F2F2F2",
        justifyContent: 'center',
        fontWeight: 400,
    },
    selectStatus:{
        width: 200,
        marginLeft: 16,
    },
    iconButtonSearch:{
        width: 30,
        height: 30,
    },
    gridSearch:{
        width: '100%',
        maxWidth: '582px'
    },
    buttonCriar: {
        position: 'absolute',
        bottom: 88,
        right: 37,
        zIndex: 100
    },

}))(ModeloReceituario);
