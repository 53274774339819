export const viasExternas = [
  {
    name: 'Via Cutânea',
    value: 'CUTANEA'
  },
  {
    name: 'Via Retal',
    value: 'RETAL'
  },
  {
    name: 'Via Vaginal',
    value: 'VAGINAL'
  },
  {
    name: 'Via Oftalmica',
    value: 'OFTALMICA'
  },
  {
    name: 'Via Otológica',
    value: 'OTOLOGICA'
  },
  {
    name: 'Via Nasal',
    value: 'NASAL'
  },
  {
    name: 'Via Intramuscular',
    value: 'INTRAMUSCULAR'
  },
  {
    name: 'Via Endovenosa',
    value: 'ENDOVENOSO'
  },
  {
    name: 'Via Tópica',
    value: 'TOPICO'
  },
  {
    name: 'Via Subcutânea',
    value: 'SUBCUTANEA'
  },
];

export const viasInternas = [
  {
    name: 'Via Oral',
    value: 'ORAL'
  },
  {
    name: 'Via Bucal',
    value: 'BUCAL'
  },
  {
    name: 'Via Sublingual',
    value: 'SUBLINGUAL'
  },
  {
    name: 'Via Gastrointestinal',
    value: 'GASTROINTESTINAL'
  },
  {
    name: 'Via Inalatória',
    value: 'INALATORIA'
  },
];