import React from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { 
    Fab, 
    Grid, 
    DialogContent,
} from "@material-ui/core";
import Dialog from "../../../components/Dialog/Dialog";
import {
    Save as SaveIcon,
    Close as CloseIcon,
    Star as StarIcon
} from "@material-ui/icons";
import RecipeList from "./RecipeList";
import DrugForm from "./DrugForm";
import OftalmoForm from "./OftalmoForm";
import {TypeModalEnumReceituario} from "../../../stores/Receituario.store";
import Colors from "../../../template/Colors";
import InputForm from "../../../components/Input/InputForm";
import Lista from "./Lista";
import Notification from "../../../components/Notification";
import PageTitle from "../../../components/PageTitle/PageTitle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { findAllReceitaModeloAuditByReceitaModeloId } from "../../../services/AuditoriaService";
import Auditoria from "../../../components/Auditoria/Auditoria";
import classNames from "classnames";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import string from "../../../utils/string";

const tabs = {
    SIMPLES: 0,
    CONTROLE_ESPECIAL: 1,
    OFTALMOLOGICA: 2,
    AUDITORIA: 3,
};

const tabsName = [
    "SIMPLES",
    "CONTROLE_ESPECIAL",
    "OFTALMOLOGICA",
    "AUDITORIA",
]

const defaultState = {
    typeCurrentRecipe: "SIMPLES",
    idReceita: null,
    useTypeSelected: null,
    recipesModelo: [],
    isEditingRecipe: false,
    newRecipes: [
        {
            drug: "",
            pharmaceuticalForm: "",
            amount: "",
            ascription: "",
        },
    ],
    lastIdRecipeModelo: 1,
    drugs: [],
    creatingReceita: false,
    receitaCreated: false,
    printing: false,
    printData: '',
    receitaOftalmologica: {},
    recipe: {
        drug: "",
        amount: "",
        ascription: "",
    },
    nomeModelo: "",
    statusNovoModelo: true,
    sugestoesLoading: false,
    notification: {
        isOpen: false,
        message: ''
    },
    openModalModelo: false,
    isLoading:false,
};

export const statusNovoModelo = [
    {name: 'Ativo', label: 'Ativo', value: true}, 
    {name: 'Inativo', label: 'Inativo', value: false},
];
@inject(
    "configuracaoImpressaoStore",
    "profissionalSaudeStore",
    "receituarioStore",
    "prontuarioStore",
    "auditoriaStore",
)
class ReceituarioModelo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.refEditorObservacao = React.createRef();
    }

    state = {
        ...defaultState,
    };

    async componentDidMount() {
        const {
            receituarioStore,
            auditoriaStore,
        } = this.props;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o modelo de receita",
            auditoriaTela: "receitaModelo",
        };

        (receituarioStore.typeModal === TypeModalEnumReceituario.EDIT_MODAL) && await this.handleNewModelRecipe(receituarioStore.modeloSelecionado)

        receituarioStore.sugestoesPagAtual = 0;
        receituarioStore.sugestoes = [];

        this.setState({ sugestoesLoading: true });
        await receituarioStore.findAllMedicamentoQuantidadeUso({
            pageableDTO: {
                pageSize: 10,
                pageNumber: receituarioStore.sugestoesPagAtual,
                sortDir: "DESC",
                sortField: "quantidadeUso"
            }
        })
        this.setState({ sugestoesLoading: false });
    }

    handleEditRecipe = (id) => () => {
        const updateRecipeEdit = (recipe) => recipe.id === id ? { ...recipe, isEditing: true } : recipe;
        this.setState({
            isEditingRecipe: true,
            recipesModelo: this.state.recipesModelo.map(updateRecipeEdit)
        })
    };

    handleSaveRecipe = (id) => () => {
        const updateRecipeSave = (recipe) => recipe.id === id ? { ...recipe, isEditing: false } : recipe;
        this.setState({
            isEditingRecipe: false,
            recipesModelo: this.state.recipesModelo.map(updateRecipeSave)
        })
    };

    handleChangeTypeRecipe = async(type) => {
        const { auditoriaStore, receituarioStore } = this.props;

        auditoriaStore.auditoriaProps={
        ...auditoriaStore.auditoriaProps,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
        }

        if (type === 3) {
            auditoriaStore.load={
                query: findAllReceitaModeloAuditByReceitaModeloId, 
                variableName: "receitaModeloId",
                variableId: receituarioStore.idModeloSelecionado, 
            }
            await auditoriaStore.loadAuditItems();
        }

        this.setState({ typeCurrentRecipe: tabsName[type] });
    };

    handleChangeRecipe = () => (name, value, idRecipe) => {
        const updateRecipe = (recipe) => recipe.id === idRecipe ? { ...recipe, [name]: value } : recipe;
        this.setState({recipesModelo: this.state.recipesModelo.map(updateRecipe)})
    };

    handleRemoveRecipe = (id) => () => {
        const updateRecipeRemove = (recipe) => recipe.id !== id;
        this.setState({recipesModelo: this.state.recipesModelo.filter(updateRecipeRemove)})
    };

    handleNewRecipe = () => {
        const { recipe } = this.state;
        this.setState((prevState) => ({
            [`recipesModelo`]: [
                ...prevState[`recipesModelo`],
                {
                    ...recipe,
                    id: prevState.lastIdRecipeModelo,
                    useType: recipe.useType || "INTERNO"
                },
            ],
            lastIdRecipeModelo: prevState.lastIdRecipeModelo + 1,
            recipe: {
                drug: "",
                amount: "",
                ascription: "",
            },
        }));
    };

    handleNewModelRecipe = (item) => {
        try{
            const { lastIdRecipeModelo } = this.state;
            const converted = item.receitaModeloItens.map((item, index) => (
                {
                drug: {
                    fabricante: item.medicamento?.fabricante,
                    formaFarmaceutica: item.medicamento?.formaFarmaceutica,
                    id: item.medicamento?.id,
                    label: item.remedio,
                    principioAtivo: item.medicamento?.principioAtivo,
                    produto: item.medicamento?.produto,
                    receituario: item.medicamento?.receituario,
                    status: item.medicamento?.status,
                    subtitulo: item.medicamento?.subtitulo,
                    tarja: item.medicamento?.tarja,
                    tipo: item.medicamento?.tipo,
                    titularidade: item.medicamento?.titularidade,
                    value: item.medicamento?.id,
                },
                amount: item.quantidade || "",
                ascription: {label: item.adscricao || ""},
                useType: item.receitaItemTipoUso || "INTERNO",
                id: lastIdRecipeModelo + index + 1,
                routeAdm: item.viaAdministracao
            }));
            
            this.setState((prevState) => ({
                lastIdRecipeModelo: prevState.lastIdRecipeModelo + item.receitaModeloItens.length,
                recipesModelo: [
                    ...prevState.recipesModelo,
                    ...converted
                ],
                typeCurrentRecipe: item.tipo,
                receitaOftalmologica: item.oftalmologica ? item.oftalmologica : {}
            }));
            
        } catch (error){
            const notification = {
                isOpen: true,
                message: 'Erro no modelo selecionado.'
              }
              this.showAlertMessage(notification);
        }
    }

    showAlertMessage = (notification) => {
        this.setState({ notification });
        
        const timeoutId = setTimeout(() => {
          this.closeAlertMessage();
          clearTimeout(timeoutId);
        }, 3000);
      };
    
      closeAlertMessage = () => {
        const notification = {
          isOpen: false,
          message: ''
        };
        this.setState({ notification });
      };

    handleChangeNewRecipe = (name, value, idRecipe) => {
        this.setState((prevState) => ({
            recipe: { ...prevState.recipe, [name]: value },
        }));
    };
    
    handleChangeOftalmoForm = (name, value) => {
        this.setState((prevState) => ({
            receitaOftalmologica: { ...prevState.receitaOftalmologica, [name]: value },
        }));
    };

    handlePrint = async () => {
        const { recipesModelo, receitaOftalmologica, typeCurrentRecipe } = this.state;
        const { receituarioStore, prontuarioStore } = this.props;
        this.setState({isLoading:true,})
        
        if (receituarioStore.typeModal === TypeModalEnumReceituario.NOVO_MODELO_MODAL){
            const recipesAdaptado = recipesModelo.map((item) => 
            {
                return (
                {
                    quantidade: item.amount,
                    adscricao: item.ascription.label,
                    remedio: item.drug.label,
                    receitaItemTipoUso: item.useType,
                    ...( item.drug?.id && { medicamento: {
                        id: item.drug.id
                    }}),
                    viaAdministracao: item.routeAdm,
                })}
            );

            let novoModelo = {
                nome: receituarioStore.nomeModelo,
                ativo: receituarioStore.statusModelo,
                quantidadeUso: 0,
                tipo: typeCurrentRecipe,
            }

            if (typeCurrentRecipe === 'OFTALMOLOGICA') {
                novoModelo.oftalmologica = receitaOftalmologica
                const editorObservacoes = this.refEditorObservacao.current.handleSubmitFromChild();
                novoModelo.oftalmologica.observacao = editorObservacoes
            } else {
                novoModelo.receitaModeloItens = recipesAdaptado
            }
            await receituarioStore.criarReceitaModelo(novoModelo)

            receituarioStore.nomeModelo = "";
            receituarioStore.statusModelo = true;

            if(receituarioStore.isProntuario){
                receituarioStore.openModalModelo = false;
                prontuarioStore.receituario.open= true;
            } else {
                receituarioStore.openModalModelo = false;

            };

            if (!!this.props.loadModelos){
                receituarioStore.modelosConfig = [];
                receituarioStore.pageNumberModelo = 0;
                this.props.loadModelos && this.props.loadModelos();       
        }
    } else if (receituarioStore.typeModal === TypeModalEnumReceituario.EDIT_MODAL){     
        const recipesAdaptado = recipesModelo.map((item) => 
            {
                return (
                {
                    quantidade: item.amount,
                    adscricao: item.ascription.label,
                    remedio: item.drug.label,
                    receitaItemTipoUso: item.useType,
                    medicamento: {
                        id: item.drug.id
                    }, 
                    viaAdministracao: item.routeAdm,
                })}
            );

        const novoModelo = {
            id: receituarioStore.idModeloSelecionado,
            nome: receituarioStore.nomeModelo,
            ativo: receituarioStore.statusNovoModelo,
            quantidadeUso: 0,
            tipo: typeCurrentRecipe,
        };

        if (typeCurrentRecipe === 'OFTALMOLOGICA') {
            novoModelo.oftalmologica = receitaOftalmologica
        } else {
            novoModelo.receitaModeloItens = recipesAdaptado
        }
        
        await receituarioStore.atualizarReceitaModelo(novoModelo);

        if (!!this.props.loadModelos){
            receituarioStore.modelosConfig = [];
            receituarioStore.pageNumberModelo = 0;
            this.props.loadModelos && this.props.loadModelos();       
        }
        receituarioStore.openModalModelo = false;
        this.setState({isLoading:false,})
    }
    };

    renderFormNewRecipe = () => (
        <DrugForm
            key="new"
            recipe={this.state.recipe}
            onChange={this.handleChangeNewRecipe}
            onBlur={this.handleNewRecipe}
        />
    );

    renderFormOftalmoRecipe = () => (
        <OftalmoForm
            key="new"
            recipe={this.state.recipe}
            onChange={this.handleChangeOftalmoForm}
            receitaOftalmologica={this.state.receitaOftalmologica}
            refEditorObservacao={this.refEditorObservacao}
        />
    );

    renderContent = () => {
        const { classes, handleClose, receituarioStore } = this.props;
        const { typeModal } = receituarioStore;
        const {
            recipesModelo,
            typeCurrentRecipe,
            isLoading,
        } = this.state;

        const disableButtonImpressaoModelo = string.isEmpty(receituarioStore.nomeModelo) || isLoading ||(this.state.typeCurrentRecipe !== 'OFTALMOLOGICA' && this.state.recipesModelo.length <= 0);
        const remediosModeloUsoInterno = recipesModelo.filter((item) => item.useType === "INTERNO");
        const remediosModeloUsoExterno = recipesModelo.filter((item) => item.useType === "EXTERNO");

        this.selectSugestao = async (item) => {
            const { receituarioStore } = this.props;
            receituarioStore.changeFieldsStore({
                idDrugSelecionado: item.medicamento.id,
            }); 

            this.setState({
                recipe: {
                    drug: {
                        label: item.medicamento.subtitulo ? `${item.medicamento.produto}, ${item.medicamento.subtitulo}` : item.medicamento.produto,
                        id: item.medicamento.id
                },
                    amount: "",
                    ascription: {label: "", value: ""},
                },
            })
        }

        this.getTitleModal = () => {
            const { typeModal } = this.props.receituarioStore;
            
            return typeModal === TypeModalEnumReceituario.EDIT_MODAL ? 'Editar modelo' : 'Novo modelo receituário';
        }
        this.handleChangeStatusNovoModelo = (e) => {
            const { receituarioStore } = this.props;
            
            this.setState({statusNovoModelo: e})
            receituarioStore.changeFieldsStore({
                statusNovoModelo: e.value
            });
        }
        
        this.handleChangeNomeModelo = (e) => {
            const { receituarioStore } = this.props;
            const value = e.target.value;
            this.setState({nomeModelo: value})
            receituarioStore.changeFieldsStore({
                nomeModelo: value
            });
        }

        this.loadMoreSugestoes = async () => {
            const { receituarioStore } = this.props;
            this.setState({sugestoesLoading: true})
    
            if (receituarioStore.sugestoesLoading) {
                return;
            }
    
            receituarioStore.sugestoesPagAtual = receituarioStore.sugestoes.length > 0 ? receituarioStore.sugestoesPagAtual + 1 : 0;
    
            await receituarioStore.findAllMedicamentoQuantidadeUso({          
                pageableDTO: {
                    pageSize: 10,
                    pageNumber: receituarioStore.sugestoesPagAtual,
                    sortDir: "DESC",
                    sortField: "quantidadeUso"
                }
            })
            this.setState({sugestoesLoading: false})
        }

        return (
            <React.Fragment>
                <Grid className={classes.receita}>
                        <Grid className={classes.dialogHeaderContent}>
                            <div className={classes.dialogTitle}>
                                {this.getTitleModal()}
                            </div>
                            <div className={classes.headerButtons}>
                                <Fab
                                    className={classes.buttonSave}
                                    onClick={this.handlePrint}
                                    disabled={(disableButtonImpressaoModelo)}
                                >
                                    <SaveIcon/>
                                </Fab>
                                <Fab
                                    className={classes.buttonActionClose}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </Fab>
                            </div>
                        </Grid>
                        <MultiToggleButtons
                            options={["Simples", "Controlada", "Oftalmologica" , "Auditoria"]}
                            tabSelected={tabs[typeCurrentRecipe]}
                            changeTabSelected={this.handleChangeTypeRecipe}
                            classes={{ buttonsContainer: classNames(classes.buttonsTab, typeCurrentRecipe === "AUDITORIA" && classes.marginButtons) }}
                            disabled={typeModal === TypeModalEnumReceituario.NOVO_MODELO_MODAL}
                            posicaoDesabilitada={[3]}
                        />
                        { typeCurrentRecipe !== "AUDITORIA" && <Grid className={classes.headerContent}>
                            <Grid className={classes.rowModelo}>
                                <Grid item xs={8} className={classes.input}>
                                    <div className={classes.titleField}> Nome do modelo: </div>
                                    <InputForm
                                        alternativeInputClass={classes.inputModelo}
                                        className={classes.inputNomeModelo}
                                        placeholder=""
                                        value={receituarioStore.nomeModelo}
                                        onChange={(e) => this.handleChangeNomeModelo(e)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.input}>
                                        <div className={classes.titleField}> Status: </div>
                                        <ReactSelect
                                            key={`status-${String(this.state.statusNovoModelo)}`}
                                            className={classes.selectStatus}
                                            name="status"
                                            placeholder="Status"
                                            value={receituarioStore.statusNovoModelo ? statusNovoModelo[0] : statusNovoModelo[1]}
                                            onChange={this.handleChangeStatusNovoModelo}
                                            options={statusNovoModelo || []}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.value}
                                        />
                                    </div>
                                </Grid>        
                            </Grid>
                            {this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && this.renderFormNewRecipe()}
                        </Grid>}

                    <DialogContent 
                    classes={{ root: classes.dialogContentBorder }}>
                        <div className={classNames(classes.dialogContent, typeCurrentRecipe === "AUDITORIA" && classes.paddingContentAuditoria) } >
                        {typeCurrentRecipe === "AUDITORIA" && <Auditoria />}
                        {this.state.typeCurrentRecipe === "OFTALMOLOGICA" && this.renderFormOftalmoRecipe()}
                        {this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && 
                        typeCurrentRecipe !== "AUDITORIA" &&
                        remediosModeloUsoInterno.length > 0 && 
                                       <RecipeList
                                title="Uso Interno"
                                type="internal"
                                recipes={remediosModeloUsoInterno}
                                drugs={this.state.drugs}
                                handleEdit={(id) => this.handleEditRecipe(id)}
                                handleSave={(id) => this.handleSaveRecipe(id)}
                                handleRemove={(id) => this.handleRemoveRecipe(id)}
                                handleChange={() => this.handleChangeRecipe()}
                            />
                        }
                        {this.state.typeCurrentRecipe !== "OFTALMOLOGICA" &&  
                        typeCurrentRecipe !== "AUDITORIA" &&
                        <span className={classes.spanSeparator}></span>}

                        {this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && 
                        typeCurrentRecipe !== "AUDITORIA" &&
                        remediosModeloUsoExterno.length > 0 &&
                            <RecipeList
                                title="Uso Externo"
                                type="external"
                                recipes={remediosModeloUsoExterno}
                                drugs={this.state.drugs}
                                handleEdit={(id) => this.handleEditRecipe(id)}
                                handleSave={(id) => this.handleSaveRecipe(id)}
                                handleRemove={(id) => this.handleRemoveRecipe(id)}
                                handleChange={() => this.handleChangeRecipe()}
                            />
                        }
                        </div>
                    </DialogContent>
                </Grid>

               { this.state.typeCurrentRecipe !== "OFTALMOLOGICA" && 
               typeCurrentRecipe !== "AUDITORIA" &&
               <Grid className={classes.lateral}>
                    <div className={classes.radioButton}>
                        <div className={classes.tituloSugestao}>
                            <StarIcon style={{marginRight: 10}}/>
                            Sugestões
                        </div>
                    </div>
                    <Lista 
                        loadMoreSugestoes={this.loadMoreSugestoes}
                        selectSugestao={(item) => this.selectSugestao(item)}
                    />
                </Grid>}
                <Notification
                    close={this.closeAlertMessage}
                    reset={this.closeAlertMessage}
                    isOpen={this.state.notification.isOpen}
                    variant={'error'}
                    message={this.state.notification.message}
                />
            </React.Fragment>
        );
    };

    getPageTitle = () => {
        const { receituarioStore, screen } = this.props;

        const acao = receituarioStore.typeModal === TypeModalEnumReceituario?.EDIT_MODAL ? "Editar" : "Novo";

        return `${screen} - ${acao} modelo de receituario`;
    };

    render() {
        const { classes, open, receituarioStore } = this.props;
        this.setState({openModalModelo: receituarioStore.openModalModelo})

        return (
            <>
            <PageTitle title={this.getPageTitle()} /> 
            <Dialog
                classes={{
                    scrollPaper: classes.scrollPaper,
                    paper: classes.paper,
                }}
                maxWidth="md"
                open={open}
                fullWidth
            >
                {this.renderContent()}
            </Dialog>
            </>
        );
    }
}

export default withStyles({
    root: {
        position: "relative",
        overflow: "none",
        zIndex: 1,
    },
    paper: {
        borderRadius: 10,
        overflow: "unset",
        height: 630,
        display: 'flex',
        flexDirection: 'row'
    },
    dialogHeaderContent: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 32px',
        justifyContent: 'space-between',
        minHeight: '64px',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        color: '#26ACA9',
        fontSize: '18px',
        fontWeight: 700,
    },
    dialogContentBorder: {
        backgroundColor: '#E0E0E0',
        borderRadius: '0 0 0 15px',
        padding: '0 15px 15px 15px'
    },
    dialogContent: {
        overflowY: 'scroll',
        margin: 0,
        backgroundColor: 'white',
        boxShadow: 'inset 0px 11px 38px -15px rgba(0,0,0,0.07);',
        height: '100%',
        borderRadius: '0 0 15px 15px',
    },
    headerButtons: {
        display: "flex",
        gap: "8px"
    },
    buttonActionClose: {
        backgroundColor: Colors.commons.red,
        width: '40px',
        height: '40px',
        color: 'white',
        "&:hover":{
            background: Colors.commons.darkRed
        }
    },
    buttonSave: {
        backgroundColor: Colors.commons.secondary,
        width: '40px',
        height: '40px',
        color: 'white',
        "&:hover":{
            background: Colors.primary.dark
        }
    },
    spanSeparator: {
        margin: '0px 60px',
        borderBottom: '1px solid #E0E0E0',
        width: 'auto',
        display: 'block',
    },
    receita:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    lateral:{
        display:'flex',
        flexDirection: 'column',
        background: '#26ACA9',
        borderRadius: '0 10px 10px 0',
        alignItems: 'center',
        width: '100%',
        maxWidth: '330px',
    },
    itemList:{
        fontSize: '12px !important',
        fontFamily: "Poppins !important",  
    },
    radioButton:{
        margin: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '86%',
    },
    tituloSugestao:{
        display: 'flex',
        flexDirection: 'row',
        minWidth: 'auto',
        fontWeight: 'normal !important',
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: 87,
        color: '#fff',
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 12px',
        width: '100%',
    },
    lista:{
        textAlign: '-webkit-center',
    },
    header:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    rowModelo: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 58,
        marginTop: 10,
    },
    inputModelo:{
        fontFamily: "Poppins!important",
        border: '0',
        fontSize: '14px',
        minHeight: '6px',
        height: '18px',
        color: Colors.commons.fontColor,
        backgroundColor: Colors.commons.gray2,
        margin: '0 0 0 0',
        padding: '9px 0px 5px 10px',
        borderRadius: '100px',
        width: '100%',
        marginRight: 10,

    },
    inputNomeModelo: {
        width: '100%',
    },
    buttonsTab: {
        margin: '16px 16px 0px',
    },
    headerContent: {
        padding: '0 36px 8px',
    },
    titleField: {
        color: "#868686",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
    },
    marginButtons: {
        margin: 16
    },
    paddingContentAuditoria: {
        "&>div": {
            paddingTop: 16,
        },
    },
    selectStatus: {
        width: '100%',
    },

})(ReceituarioModelo);

