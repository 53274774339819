const styles = ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    width: '100%',
    overflow: 'hidden'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px !important'
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '18px !important',
    fontWeight: '700'
  },
  buttonAndProfileArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '16px',
    alignItems: 'center',
    gap: '16px'
  },
  contentContainer: {
    display: 'flex',
    height: 'calc(100% - 128px)',
    flexDirection: 'column',
    backgroundColor: '#E5E5E5',
    padding: '24px 24px 0 24px',
    gap: '16px',
    overflow: 'hidden'
  },
  content: {
    flex: 1,
    backgroundColor: '#FFF',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  tabsArea: {
    padding: '18px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  notchedOutline: {
    border: 'none'
  },
  inputTextField: {
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    width: 'calc( 100% - 16px )',
    height: '32px',
    padding: '4px 8px',
  },
  item: {
    padding: '0 !important'
  },
  searchButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  filterButton: {
    height: '37px',
    alignSelf: 'flex-end'
  },
  contentButton: {
    display: 'flex',
    width: '100px',
    justifyContent: 'space-around',
    position: 'absolute',
    bottom: '88px',
    right: '8px',
  },
  tableRoot: {
    width: '100%',
    overflow: 'auto',
    height: 'calc(100% - 72px)',
    '&>div>div>table>thead>tr>th': {
      textAlign: 'start',
    }
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  patitentsCounterText: {
    fontSize: '12px !important'
  }
});

export default styles;