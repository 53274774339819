import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import moment from 'moment';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PeriodDate from '../../../components/PeriodDate/PeriodDate';
import { TextFieldSearch } from '../../../components/TextField';
import ItemGrid from '../../../components/Modal/ItemGridCard';

import Profile from '../../../template/Header/Profile';
import Header from '../../../template/Header/Header';

import { pageConfiguracoes } from '../../../utils/getPageTitle';
import TabSelector from '../../../components/TabSelector';
import ButtonLong from '../../../components/Button/ButtonLong';
import Button from '../../../components/Button/Button';
import AgendamentosTable from './AgendamentosTable';

import { findProfissionalSaudeByUnidadeComAgenda } from '../../../services/ProfissionalSaudeService';
import { findAllAgendamentoByDataHoraDecorrente, updateAgendamentoSituacao } from '../../../services/AgendamentoService';
import ModalTransferencia from './Modais/ModalTransferencia';
import MultipleSelectCheckbox from '../../../components/Select/MultipleSelectCheckbox';
import { weekDays } from './constants';
import classNames from 'classnames';
import PeriodTime from '../../../components/PeriodTime';
import ModalConflitos from './Modais/ModalConflitos';

import Notification from '../../../components/Notification';
import ExcluirAgendamentosModal from './Modais/ExcluirAgendamentosModal';
import dates from '../../../utils/dates';

const today = moment().format('YYYY-MM-DD');
const lastDayInMonth = moment().endOf("month").format('YYYY-MM-DD');

const filterButtonStyle = {
  display: 'flex',
  flex: '1',
  width: '120px',
  height: '40px',
  padding: '6px 24px 6px 24px',
  borderRadius: '100px',
  backgroundColor: '#707C97',
  alignSelf: 'end',
  textTransform: 'capitalize',
};

const defaultFilter = {
  dataInicial: today,
  dataFinal: lastDayInMonth,
  diasSemana: [],
  profissionalSaude: null,
  horaInicio: moment().format('HH:mm'),
  horaFim: '18:00',
  pageNumber: 0,
  pageSize: 30,
  sortField: 'data',
  sortDir: 'ASC'
}

const defaultNotification = {
  message: '',
  variant: '',
  isOpen: false,
}

const checkboxOptionsDefault = [{ nome: 'Todos os Dias', value: 'TODOS_DIAS' }, ...weekDays].map((item) => {
  return {
    ...item,
    checked: true,
  };
});

const TransferenciaAgendamento = ({ classes }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const [hasMoreAgendamentos, setHasMoreAgendamentos] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);
  const [openModalConflitos, setOpenModalConflitos] = useState(false);
  const [conflictedAgendamentos, setConflictedAgendamentos] = useState([]);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const [agendamentos, setAgendamentos] = useState([]);
  const [agendamentosQuantity, setAgendamentosQuantity] = useState(0);
  const [selectedAgendamentosIds, setSelectedAgendamentosIds] = useState([]);
  const [lastChecked, setLastChecked] = useState(null);
  const [toggleAllCheckboxesLastValue, setToggleAllCheckBoxesLastValue] = useState(false);
  const [weekDaysCheckBoxes, setWeekDaysCheckBoxes] = useState(checkboxOptionsDefault);

  const [modalStep, setModalStep] = useState(0);

  const [notification, setNotification] = useState(defaultNotification);
  const [timeRangeInputError, setTimeRangeInputError] = useState(false);

  const [triggerReload, setTriggerRelod] = useState(false);

  useEffect(() => {
    findAgendamentos(null);
  }, []);

  useEffect(() => {
    if (triggerReload) {
      findAgendamentos({
        ...defaultFilter,
        horaInicio: moment().format('HH:mm'),
      });
      setTriggerRelod(false);
    }
  }, [triggerReload]);

  const getUnidadeLogadaId = () => {
    const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);
    return unidadeLogada?.id;
  };

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page }
    })
  };

  const handleLoadMoreOptions = async ({ search, data }) => {
    let pageableDTO = {
      sortDir: 'ASC',
      sortField: 'nome',
      pageNumber: 0,
    };
    const unidadeId = (await getUnidadeLogadaId()) || null

    const response = await findProfissionalSaudeByUnidadeComAgenda({
      search,
      unidadeId,
      pageableDTO
    });

    const profissionaisSaude = response.data.data.findProfissionalSaudeByUnidadeComAgenda;

    if (profissionaisSaude) {
      return {
        options: profissionaisSaude,
        hasMore: false,
      };
    };
  };

  const findAgendamentos = async (customFilter = null) => {
    try {
      setIsLoading(true);
      let searchDTO = prepareData(customFilter ? customFilter : filter);
      const { horaInicio, horaFim } = searchDTO;
      if (!dates.validateTimeRange(horaInicio, horaFim)) {
        setNotification({
          message: 'Intervalo de horário inválido.',
          variant: 'error',
          isOpen: true
        });
        setTimeRangeInputError(true);
        return;
      } else {
        setTimeRangeInputError(false);
      }

      const response = await findAllAgendamentoByDataHoraDecorrente({ searchDTO });
      if (response.data.data.findAllAgendamentoByDataHoraDecorrente) {
        const { content, last, totalElements } = response.data.data.findAllAgendamentoByDataHoraDecorrente;
        if (!last) {
          setFilter({
            ...filter,
            horaInicio: searchDTO.horaInicio,
            sortDir: searchDTO.sortDir,
            sortField: searchDTO.sortField,
            pageNumber: searchDTO.pageNumber + 1
          });
        }
        setHasMoreAgendamentos(!last);
        setAgendamentos(
          searchDTO.pageNumber === 0 ? [...content] : [...agendamentos, ...content]
        );
        setAgendamentosQuantity(totalElements);
        setIsLoading(false);
      }
    } catch (error) {
      setNotification({
        message: 'Falha ao carregar agendamentos',
        isOpen: true,
        variant: 'error'
      });
      setIsLoading(false);
    }
  };

  const prepareData = (filterInput) => {
    let searchDTO = {
      dataInicial: moment(filterInput.dataInicial).format('YYYY-MM-DD'),
      dataFinal: moment(filterInput.dataFinal).format('YYYY-MM-DD'),
      horaInicio: filterInput.horaInicio,
      horaFim: filterInput.horaFim,
      diaDaSemana: getSelectedDays(),
      pageNumber: filterInput.pageNumber,
      pageSize: filterInput.pageSize,
      sortDir: filterInput.sortDir,
      sortField: filterInput.sortField
    }

    const unidadeLogadaId = getUnidadeLogadaId();
    searchDTO = {
      ...searchDTO,
      unidadeId: unidadeLogadaId
    }

    if (filter.profissionalSaude) {
      searchDTO = {
        ...searchDTO,
        profissionalSaudeId: filter.profissionalSaude.id,
      }
    }
    return searchDTO;
  }

  const onTransfer = () => {
    if (selectedAgendamentosIds.length === 0) {
      setNotification({
        isOpen: true,
        message: 'Selecione pelo menos um agendamento para realizar a transferência.',
        variant: 'error'
      })
      return;
    }
    setOpenModalTransferencia(true);
  };

  const onDelete = () => {
    if (selectedAgendamentosIds.length === 0) {
      setNotification({
        isOpen: true,
        message: 'Selecione pelo menos um agendamento.',
        variant: 'error'
      })
      return;
    }
    setOpenConfirmDeleteModal(true);
  };

  const handleDeleteAgendamentosSelecionados = () => {
    selectedAgendamentosIds.forEach(async (agendamento, index) => {
      try {
        setIsLoading(true)
        await updateAgendamentoSituacao(agendamento, 'EXCLUIDO');
        if (index === selectedAgendamentosIds.length - 1) {
          setNotification({
            message: 'Todos agendamentos foram excluidos',
            isOpen: true,
            variant: 'success'
          });
          setOpenConfirmDeleteModal(false);
          findAgendamentos(defaultFilter);
          setSelectedAgendamentosIds([]);

        }
      } catch (error) {
        setNotification({
          message: error.message,
          isOpen: true,
          variant: 'error'
        });
        setIsLoading(false);
        setOpenConfirmDeleteModal(false);
        setSelectedAgendamentosIds([]);
      }
    });
  }

  const onCheckAgendamento = (agendamento, event) => {
    const currentIndex = selectedAgendamentosIds.indexOf(agendamento.id);
    const updatedSelectedAgendamentosIds = [...selectedAgendamentosIds];

    const shiftKeyPressed = event.nativeEvent.shiftKey;

    if (shiftKeyPressed && lastChecked) {
      const start = agendamentos.findIndex(item => item.id === lastChecked);
      const stop = agendamentos.findIndex(item => item.id === agendamento.id);
      const agendamentosInRange = agendamentos.slice(Math.min(start, stop), Math.max(start, stop));

      agendamentosInRange.forEach((agendamento) => {
        const index = updatedSelectedAgendamentosIds.indexOf(agendamento.id);

        if (index === -1) {
          return updatedSelectedAgendamentosIds.push(agendamento.id);
        }

        if (agendamento.id !== lastChecked) {
          updatedSelectedAgendamentosIds.splice(index, 1);
        }
      });
    }

    if (currentIndex === -1) {
      updatedSelectedAgendamentosIds.push(agendamento.id);
    } else {
      updatedSelectedAgendamentosIds.splice(currentIndex, 1);
    }

    setSelectedAgendamentosIds(updatedSelectedAgendamentosIds);
    setLastChecked(agendamento.id);
    setToggleAllCheckBoxesLastValue(false);
  };

  const agendamentoIsChecked = (agendamento) => {
    return selectedAgendamentosIds.some(id => id === agendamento.id);
  };

  const handleSelectProfissionalSaude = (value) => {
    setFilter({ ...filter, profissionalSaude: value, pageNumber: 0 });
  }

  const handleClearFilter = () => {
    setFilter(defaultFilter);
    setWeekDaysCheckBoxes(checkboxOptionsDefault);
    setTriggerRelod(true);
  }

  const toggleAllCheckboxes = () => {
    if (!toggleAllCheckboxesLastValue || selectedAgendamentosIds.length < agendamentos.length) {
      const allAgendamentosIds = agendamentos.map(agendamento => agendamento.id);
      setSelectedAgendamentosIds(allAgendamentosIds);
      setToggleAllCheckBoxesLastValue(true);
    } else {
      setSelectedAgendamentosIds([]);
      setToggleAllCheckBoxesLastValue(false);
    }
  }

  const handleChangeDate = (dataInicial, dataFinal) => {
    setFilter({
      ...filter,
      dataInicial,
      dataFinal,
      pageNumber: 0,
    });
  };

  const handleChangeTimeFilter = (value, field) => {
    setFilter({
      ...filter,
      [field]: value,
      pageNumber: 0,
    });
  };

  const handleChangeTimePeriodInput = (value) => {
    const timePeriodSplitted = value.split(' à ');
    const horaInicio = timePeriodSplitted[0];
    const horaFim = timePeriodSplitted[1]

    setFilter({
      ...filter,
      horaInicio,
      horaFim,
    });
  };

  const handleToggleCheckBox = (item) => {
    if (item.value === 'TODOS_DIAS') {
      return toggleAllDays(!item.checked);
    }

    const itemIndex = weekDaysCheckBoxes.findIndex(element => element.value === item.value);
    let days = [...weekDaysCheckBoxes];
    days[itemIndex] = { ...item, checked: !item.checked };
    setWeekDaysCheckBoxes(days);
  };

  const toggleAllDays = (valueToToggle) => {
    const days = weekDaysCheckBoxes.map(item => {
      return {
        ...item,
        checked: valueToToggle
      };
    });
    setWeekDaysCheckBoxes(days);
  };

  const getSelectedDays = () => {
    let selectedDays = [];
    weekDaysCheckBoxes.forEach(item => {
      if (item.value !== 'TODOS_DIAS' && item.checked) {
        selectedDays.push(item.value);
      }
    });
    return selectedDays?.length > 0 ? selectedDays : null;
  };

  const getDaysInputPlaceholder = () => {
    const seletedDays = getSelectedDays();
    if (!seletedDays || seletedDays.length === 0) {
      return 'Selecione';
    }

    if (seletedDays?.length > 0 && seletedDays?.length < 6) {
      return `${seletedDays.length} dia(s)`;
    }

    return 'Todos os dias';
  }

  const handleCloseModalTransferencia = () => {
    setOpenModalTransferencia(false);
    setToggleAllCheckBoxesLastValue(false);
    resetFilterAndSelecteds();
  }

  const handleOpenConflictModal = () => {
    setOpenModalTransferencia(false);
    setOpenModalConflitos(true);
  };

  const handleCloseConflictModal = () => {
    setModalStep(0);
    setOpenModalConflitos(false);
    resetFilterAndSelecteds();
  }

  const handleCloseNotification = () => {
    setNotification(defaultNotification);
  }

  const resetFilterAndSelecteds = () => {
    setFilter(defaultFilter);
    setSelectedAgendamentosIds([]);
    setTriggerRelod(true);
  };

  const daysInputPlaceholder = getDaysInputPlaceholder();

  return (
    <div className={classes.page}>
      <PageTitle title={pageConfiguracoes('Transfêrencia Agendamento')} />
      <Header className={classes.header} disableShadow={true}>
        <h1>Transferência de agendamento</h1>
        <Profile />
      </Header>
      <div className={classes.filters}>
        <ItemGrid size={12} label="Data:">
          <PeriodDate
            filter={filter}
            setFilter={handleChangeDate}
            onChangeDate={handleChangeDate}
            handleAplicar={() => null}
            classes={{
              container: classes.inputContainer,
            }}
            stylePopper={classes.calendarPopper}
            minDate={moment().toDate()}
          />
        </ItemGrid>
        <ItemGrid label="Hora:">
          <PeriodTime
            value={`${filter.horaInicio} à ${filter.horaFim}`}
            onChangeInput={handleChangeTimePeriodInput}
            onChangePeriod={handleChangeTimeFilter}
            filter={filter}
            error={timeRangeInputError}
          />
        </ItemGrid>
        <ItemGrid label="Dias da semana:">
          <MultipleSelectCheckbox
            listaOpcoes={weekDaysCheckBoxes}
            withCustomText
            customText={daysInputPlaceholder}
            changeCheckbox={handleToggleCheckBox}
            classes={{
              select: classNames(classes.inputContainer, classes.select),
              popper: classes.selectMenu
            }}
          />
        </ItemGrid>
        <ItemGrid size={12} label="Profissional da Saúde:">
          <TextFieldSearch
            menuPosition='fixed'
            placeholder='Selecione'
            classInput={classes.inputContainer}
            classNotched={classes.notchedOutline}
            loadOptions={loadProfissionaisSaude}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            onChange={(e) => handleSelectProfissionalSaude(e)}
            value={filter.profissionalSaude}
          />
        </ItemGrid>
        <Button
          style={filterButtonStyle}
          onClick={() => findAgendamentos({ ...filter, pageNumber: 0 })}
        >
          Filtrar
        </Button>
      </div>
      <div className={classes.content}>
        <div className={classes.wrapperList}>
          <div className={classes.headerList}>
            <TabSelector
              tabsOptions={[
                {
                  label: 'Agendamentos',
                  roundedTag: agendamentosQuantity > 0,
                  tagColor: '#5462E0',
                  tagText: agendamentosQuantity,
                },
                { label: 'Auditoria', isDisabled: true }
              ]}
              baseColor='#F2F2F2'
              selectedColor='#FFF'
              onSelect={(index) => setSelectedTabIndex(index)}
              selectedTabIndex={selectedTabIndex}
            />
            <ButtonLong
              colorCustom='green'
              onClick={onTransfer}
              disabld={isLoading}
            >
              Transferir selecionados
            </ButtonLong>
            <ButtonLong
              colorCustom='red'
              onClick={onDelete}
              disabld={isLoading}
            >
              Excluir Selecionados
            </ButtonLong>
          </div>
          <AgendamentosTable
            agendamentos={agendamentos}
            onCheckAgendamento={onCheckAgendamento}
            agendamentoIsChecked={agendamentoIsChecked}
            handleClearFilter={handleClearFilter}
            isLoading={isLoading}
            toggleAllCheckboxes={toggleAllCheckboxes}
            toggleAllCheckboxesLastValue={toggleAllCheckboxesLastValue && selectedAgendamentosIds.length === agendamentos.length}
            loadAgendamentos={findAgendamentos}
            hasMore={hasMoreAgendamentos}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      </div>
      <ModalTransferencia
        open={openModalTransferencia}
        allSelected={toggleAllCheckboxesLastValue}
        selectedAgendamentos={selectedAgendamentosIds}
        totalAgendamentos={agendamentosQuantity}
        currentFilter={filter}
        handleClose={handleCloseModalTransferencia}
        conflictedAgendamentos={conflictedAgendamentos}
        setConflictedAgendamentos={setConflictedAgendamentos}
        handleOpenConflictModal={handleOpenConflictModal}
        openNotification={setNotification}
        modalStep={modalStep}
        setModalStep={setModalStep}
        setNotification={setNotification}
      />
      <ModalConflitos
        open={openModalConflitos}
        onClose={handleCloseConflictModal}
        agendamentos={conflictedAgendamentos}
        openNotification={setNotification}
      />
      <ExcluirAgendamentosModal
        open={openConfirmDeleteModal}
        onConfirm={handleDeleteAgendamentosSelecionados}
        onClose={() => setOpenConfirmDeleteModal(false)}
        isLoading={isLoading}
      />
      <Notification
        {...notification}
        close={handleCloseNotification}
      />
    </div>
  );
};

const styles = () => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 65px)',
    width: '100%',
    overflow: 'hidden'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      color: '#505050',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      paddingLeft: '1rem',
    },
    '& button': {
      paddingRight: '22px',
    },
  },
  filters: {
    backgroundColor: '#fff',
    display: 'grid',
    gridTemplateColumns: '220px 145px 165px 200px 120px',
    gap: '16px',
    boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
    padding: '0 16px 8px 16px',
  },

  inputContainer: {
    display: 'flex',
    flex: 1,
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '40px',
    border: 'none',
    '& > div': {
      '& > p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  calendarPopper: {
    zIndex: 11
  },
  notchedOutline: {
    border: 'none'
  },
  select: {
    width: '165px',
  },
  selectMenu: {
    marginTop: '4px',
    width: '165px',
    zIndex: 1000
  },
  content: {
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    background: '#F2F2F2',
    overflow: 'hidden'
  },
  wrapperList: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    margin: '22px 22px 0 22px',
    backgroundColor: '#fff',
    borderRadius: '1rem',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  headerList: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    height: 'fit-content',
    padding: '1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '& > button': {
      flex: '1',
    },
    '& > div': {
      flex: '3',
    },
  },
});

export default withStyles(styles)(TransferenciaAgendamento);
