import React from "react";
import string from "../../../utils/string";
import { Dates } from "../../../utils";
import moment from "moment";
import cidadeFormatada from "../../../utils/cidadeFormatada";
import { formattedPhone } from "../../../components/Input/TelefoneFormatado";

const renderItem = ({ item, index, utilizaTelefoneInternacional }) => {
  const calculaIdade = () => {
    return Dates.calculaIdade(item.dataNascimento);
  };

  const dataNascimentoFormatada = () => {
    return moment(item?.dataNascimento).format("DD/MM/YYYY");
  };

  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" }

    }
    return ''
  }

  return (
    <div
      style={{ ...styles.containerColuna, ...columnColor(index) }}
      key={index}>
      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.nome}</div>
      </div>

      <div style={styles.coluna}>
        <div style={styles.text}>
          {formattedPhone({
            utilizaTelefoneInternacional,
            telefone: item.contato?.telefonePrincipal,
            telefoneDDI: item.contato?.telefonePrincipalDDI
          })}
        </div>
      </div>

      <div style={styles.colunaDescricaoQuebraPalavra}>
        <div style={styles.text}>{item.contato?.email || "-"}</div>
      </div>

      <div style={styles.coluna}>
        <div style={styles.text}>
          {
            cidadeFormatada({
              municipio: item.endereco?.municipio?.nome,
              uf: item.endereco?.municipio?.uf,
              espanhaMunicipio: item.espanhaMunicipio?.descricao
            })
          }
        </div>
      </div>
      <div style={styles.colunaQuebraPalavra}>
        <div style={styles.text}>{calculaIdade()}</div>
      </div>
      <div style={styles.coluna}>
        <div style={styles.text}>{dataNascimentoFormatada()}</div>
      </div>
    </div>
  );
};

const RelatorioAniversariantesDocument = (props) => {
  const { dataInicio, dataFim, cidade, situacao, aniversariantes, utilizaTelefoneInternacional } = props;


  return (
    <>
      <div style={styles.titulo}>
        <div style={styles.textBold}>Relatório aniversariantes</div>
      </div>
      <div style={styles.subTitulo}>
        <div style={styles.textBold}>
          Data início: {string.formatData(dataInicio)}
        </div>
        <div style={styles.textBold}>Data fim: {string.formatData(dataFim)}</div>
        <div style={styles.textBold}>Cidade: {cidade?.label || " - "}</div>
        <div style={styles.textBold}>Situação: {situacao}</div>
      </div>

      <div style={styles.containerColuna}>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>Paciente</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Telefone</div>
        </div>
        <div style={styles.colunaDescricaoQuebraPalavra}>
          <div style={styles.textBold}>E-mail</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Cidade</div>
        </div>
        <div style={styles.colunaQuebraPalavra}>
          <div style={styles.textBold}>Idade</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Aniversário</div>
        </div>
      </div>

      {aniversariantes.map((item, index) => renderItem({ item, index, utilizaTelefoneInternacional }))}
    </>
  );
};

const styles = {
  text: {
    fontSize: '12px',
    marginBottom: '8px',
  },

  textBold: {
    fontWeight: 700,
    fontSize: '12px',
    marginBottom: '8px',
  },

  titulo: {
    marginLeft: '0px',
    marginBottom: '10px',
    marginTop: '5px',
    paddingBottom: '5px',
    textAlign: 'center',
  },

  subTitulo: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #505050',
    marginBottom: '15px',
    justifyContent: 'space-between',
    paddingBottom: '5px',
  },

  containerColuna: {
    display: 'flex',
    flexDirection: 'row',
  },

  coluna: {
    display: 'flex',
    flexGrow: 1,
    width: '75px',
  },

  colunaQuebraPalavra: {
    display: 'flex',
    flexGrow: 1,
    width: '75px',
    wordBreak: 'break-all',
  },

  colunaDescricao: {
    display: 'flex',
    flexGrow: 2,
    width: '150px',
    marginBottom: '8px',
    marginRight: '3px',
  },

  colunaDescricaoQuebraPalavra: {
    display: 'flex',
    flexGrow: 2,
    width: '150px',
    marginBottom: '8px',
    marginRight: '3px',
    wordBreak: 'break-all',
  },
}

export default RelatorioAniversariantesDocument;
