import { action, observable } from "mobx";
import { findAllProcedimentosRealizados } from "../services/ProcedimentosRealizadosService";

export default class ProcedimentosRealizadosStore {
  @observable search = "";
  @observable lista = [];
  @observable loading = false;
  @observable sortFieldProcedimentosRealizados = "dataHoraLancamento";
  @observable sortDirProcedimentosRealizados = "DESC";
  @observable pageNumber = 0;
  @observable last = false;


  @action async findAllProcedimentosRealizados(variables){
      this.lista=[];
      this.loading = true;
      findAllProcedimentosRealizados(variables).then(response => {
        const { content, last } = response.data.data.findAllProcedimentoRealizado;
        this.lista= content;
        this.last= last;
      }).finally(()=>{
        this.loading=false;
      })
  }
  
}