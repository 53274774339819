import { withStyles } from "@material-ui/core/es";
import React from "react";

const Local = ({ classes, label }) => {
  return <div className={classes.content}> {label} </div>;
};

const styles = {
  content: {
    background: "#717FFC",
    padding: "0 8px",
    fontSize: "12px",
    fontWeight: 700,
    color: "#fff",
    border: "2px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "4px",
    height: "14px",
    display: "flex",
    alignItems: "center",
    width: "max-content",
  },
};

export default withStyles(styles)(Local);
