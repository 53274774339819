import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography, withStyles } from '@material-ui/core'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { TextFieldSearch, TextField } from '../../../../../components/TextField'
import styles from '../GuiaDeConsultaModalStyles'
import { findAllProfissionalSaude } from '../../../../../services/GuiaDeServicosService'
import { findAllTuss24TerminologiaCBO } from '../../../../../services/GuiaDeServicosService'
import { verificaCamposVazioStepProfissionalSaude } from './ValidaDados'

const StepProfissionalSaude = observer(props => {
  const {
    classes,
    handleChangeGuiaConsulta,
    guiaConsulta,
    openNotification,
    guiaDeConsultaStore,
  } = props

  useEffect(() => {
    guiaConsulta.profissionalSaude && validaDados()
  }, [guiaConsulta.profissionalSaude])

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
        })
      ).data.data.findAllProfissionalSaude
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch {
      openNotification('Erro ao carregar profissionais ', 'error')
    }
  }

  const loadAllTuss24 = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 30,
    }

    const searchDTO = {
      codigoTermo: search,
    }

    try {
      const response = await findAllTuss24TerminologiaCBO(pageableDTO, searchDTO)
      const { content, last } = response.data.data.findAllTuss24TerminologiaCBO
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch {
      openNotification('Erro ao carregar Código CBO', 'error')
    }
  }

  const handleSelectProfissionais = async option => {
    const { id, nome, numeroConselho, conselhoProfissionalSaude, tuss24TerminologiaCBO } = option || {}
    const uf = option?.ufConselho ? await guiaDeConsultaStore.getUf(option?.ufConselho) : ''

    const dadosProfissionalSaude = {
      profissionalSaude: { id, nome },
      nomeProfissional: nome,
      numeroConselhoProfissional: numeroConselho || '',
      conselhoProfissional: conselhoProfissionalSaude || '',
      uf: uf || '',
      cbo: tuss24TerminologiaCBO?.codigoTermo || '',
      tuss24TerminologiaCBO: tuss24TerminologiaCBO || '',
    }

    handleChangeGuiaConsulta('profissionalSaude', dadosProfissionalSaude)
    handleChangeGuiaConsulta('tuss24TerminologiaCBO', option?.tuss24TerminologiaCBO || '')
  }

  const validaDados = () => {
    const erro = verificaCamposVazioStepProfissionalSaude(guiaConsulta)
    if (erro) {
      openNotification('O profissional não possui todos os dados necessários', 'error')
      return
    }
  }

  return (
    <Grid>
      <Typography className={classes.typography} component='label'>
        Nome do Profissional Executante
      </Typography>
      <TextFieldSearch
        placeholder='Selecione'
        classNotched={classes.notchedOutline}
        classInput={classes.inputTextField}
        classIcons={classes.classIcons}
        classes={{ paper: classes.menuHeight }}
        loadOptions={loadProfissionaisSaude}
        getOptionLabel={option => option?.nome}
        getOptionValue={option => option?.id}
        value={guiaConsulta?.profissionalSaude}
        onChange={handleSelectProfissionais}
        withPaginate
        debounceTimeout={300}
        additional={{
          page: 0,
        }}
        menuPosition='fixed'
      />
      <Grid container spacing={8} style={{ padding: '0.5rem 0' }}>
        <Grid item xs={3} className={classes.paddingRight}>
          <Typography className={classes.typography} component='label'>
            Conselho
          </Typography>
          <TextField
            disabled
            placeholder=''
            className={classes.borderNone}
            InputProps={{
              classes: {
                input: classes.inputDisabled,
              },
            }}
            value={guiaConsulta?.conselhoProfissional?.sigla || ''}
          />
        </Grid>
        <Grid item xs={3} className={classes.paddingRight} spacing={8}>
          <Typography className={classes.typography} component='label'>
            UF
          </Typography>
          <TextField
            disabled
            placeholder=''
            className={classes.borderNone}
            InputProps={{
              classes: {
                input: classes.inputDisabled,
              },
            }}
            value={guiaConsulta?.uf?.uf || ''}
            menuPosition='fixed'
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typography} component='label'>
            Código CBO
          </Typography>
          <TextFieldSearch
            placeholder='Selecione'
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            classes={{ paper: classes.menuHeight }}
            loadOptions={loadAllTuss24}
            getOptionLabel={option => `${option.termo}-${option.codigoTermo}`}
            getOptionValue={option => option}
            value={guiaConsulta?.tuss24TerminologiaCBO}
            onChange={option => {
              handleChangeGuiaConsulta('tuss24TerminologiaCBO', option)
              handleChangeGuiaConsulta('cbo', option?.codigoTermo)
            }}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition='fixed'
          />
        </Grid>
      </Grid>
      <Typography className={classes.typography} component='label'>
        Número do conselho
      </Typography>
      <TextField
        className={classes.borderNone}
        InputProps={{
          classes: {
            input: classes.inputDisabled,
          },
        }}
        disabled
        placeholder=''
        value={guiaConsulta?.numeroConselhoProfissional}
      />
    </Grid>
  )
})

const StepProfissionalSaudeWithStyles = withStyles(styles)(StepProfissionalSaude)
export default inject('guiaDeConsultaStore')(StepProfissionalSaudeWithStyles)
