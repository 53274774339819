import React from 'react';
import { 
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow 
} from '@material-ui/core';
import ArrowUpAndDownIcon from '../../Icon/ArrowUpAndDownIcon';
import ArrowDownIcon from '../../Icon/ArrowDownIcon';
import ArrowUpIcon from '../../Icon/ArrowUpIcon';

const styles = ({
  withSortIcon: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
});

const CustomTable = ({ classes, columns, data, withSort, currentSortOptions, handleSort}) => {

  const TableRowComponent = ({ rowData, columns }) => {
    return (
      <TableRow>
        {columns?.map(({ component, onChange, field }, index) => (
          <TableCell key={index} classes={{root: classes.tableCellRoot}}>
            {component(rowData.id, rowData[field], onChange, field)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderSortIcon = (sortField) => {
    if (sortField !== currentSortOptions.sortField) {
      return (
        <ArrowUpAndDownIcon size={14} color="#505050"/>
      );
    }
    
    if (currentSortOptions.sortDir === 'ASC') {
      return (
        <ArrowDownIcon size={10} color="#505050"/>
      );
    }

    return (
      <ArrowUpIcon size={10} color="#505050"/>
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns?.map(({ header, sortField }, index) => (
            <TableCell key={index} classes={{root: classes.tableCellRoot}}>
              <div className={classes.withSortIcon}>
                {header}
                {withSort && sortField && (
                  <div onClick={(() => handleSort(sortField))}>
                    {renderSortIcon(sortField)}
                  </div>
                )}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((row) => (
          <TableRowComponent key={row.id} rowData={row} columns={columns} />
        ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(CustomTable);