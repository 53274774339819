import React from "react";
import moment from "moment";
import Colors from "../../Colors";
import { applyCodigoTussMask } from "../../../utils/codigoTussMask";


const renderItem = ({ styles, item, index }) => {

    return (
        <div style={styles.containerColuna} key={index}>
            <div style={styles.colunaPequena}>
                <div style={[styles.text]}>
                    {item.tussView.tabela}
                </div>
            </div>

            <div style={styles.colunaTuss}>
                <div style={[styles.text]}>
                    {applyCodigoTussMask(item.tussView.codigoTermo)}
                </div>
            </div>

            <div style={styles.colunaDescricao}>
                <div style={[styles.text]}>
                    {item.descricao}
                </div>
            </div>

            <div style={styles.colunaPequena}>
                <div style={[styles.text]}>
                    {item.quantidadeSolicitada}
                </div>
            </div>
        </div>
    );
};

const Pagina = (props) => {
    const { guiaServico } = props;

    return (
        <div style={styles.page} >
            <div>
                <div style={[styles.text, styles.textBold]}>
                    Paciente: {guiaServico?.beneficiarioNome}
                </div>
            </div>

            { !!guiaServico?.cid10Subcategoria?.descricaoAbreviada &&
                <div>
                    <div style={[styles.text, styles.textBold]}>
                        Indicação clínica: {guiaServico?.cid10Subcategoria?.descricaoAbreviada}
                    </div>
                </div>}

            { !!guiaServico?.observacao && (
                <div>
                    <div style={[styles.text, styles.textBold]}>
                        Observações: {guiaServico?.observacao}
                    </div>
                </div>)}
            <div>
                <div style={[styles.text, styles.textBold]}>
                    Data: {moment(guiaServico?.dataHoraLancamento).format("DD/MM/YYYY")}
                </div>

            </div>
            <div style={styles.titulos}>
                <div style={[styles.text, styles.textBold]}>
                    Exames
                </div>
            </div>

            <div style={styles.containerColuna}>
                <div style={styles.colunaPequena}>
                    <div style={[styles.text, styles.textBold]}>
                        Tabela
                    </div>
                </div>
                <div style={styles.colunaTuss}>
                    <div style={[styles.text, styles.textBold]}>
                        TUSS
                    </div>
                </div>
                <div style={styles.colunaDescricao}>
                    <div style={[styles.text, styles.textBold]}>
                        Descrição
                    </div>
                </div>
                <div style={styles.colunaPequena}>
                    <div style={[styles.text, styles.textBold]}>
                        Qtde.
                    </div>
                </div>
            </div>

            {guiaServico?.guiaServicoTussViews.map((item, index) =>
                renderItem({ styles, item, index })
            )}
        </div>
    );
};

const SADTGuiaSimples = props => (
    <Pagina {...props} />
);

const styles = {
    page: {
        margin: 0
    },
    text: {
        fontFamily: "Helvetica",
        fontSize: 12,
        margin: '5px'
    },
    textBold: {
        fontFamily: "Helvetica-Bold"
    },
    titulos: {
        borderBottomWidth: 1,
        marginLeft: 0,
        marginBottom: 15,
        marginTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
    },
    headerData: {
        flexDirection: "row",
        alignItems: "center"
    },
    logo: {
        width: 40,
        height: 40,
    },
    logoContainer: {
        width: '10%',
    },
    headerUnidade: {
        marginTop: '15px',
        fontSize: 15,
        color: Colors.primary.main
    },
    containerColuna: {
        display: "flex",
        flexDirection: "row",
    },
    colunaDescricao: {
        flexGrow: "2",
        width: "120px",
    },
    colunaTuss: {
        width: "64px",
        marginRight: "12px"
    },
    colunaPequena: {
        width: "40px",
        marginRight: "12px"
    },
    footer: {
        position: "absolute",
        paddingTop: 10,
    },
}

export default SADTGuiaSimples;
