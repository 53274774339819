import React, { useReducer } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'

import InputSenha from '../../../../../../components/Input/InputSenha'
import ItemGrid from '../../../../../../components/Modal/ItemGridCard'
import ModalConfirmacao from '../../../../../../components/Modal/ModalConfirmacao/ModalConfirmacao'
import SuccessIcon from '../../../../../../components/Icon/SuccessIcon'
import { ITENS_TO_VERIFY } from '../../../../../../utils/passwordValidations'

const CriarSenha = ({ classes, secretariaInformations, handleChange, hasError }) => {
  const [openNotification, toggleOpenNotification] = useReducer(state => !state, false)
  const renderVerifyPassword = value => {
    return (
      <div className={classes.verifyPassword}>
        <h1>Senha deve conter:</h1>
        {ITENS_TO_VERIFY.map((verifyFunction, index) => {
          const { isValid, message } = verifyFunction(value)
          const currentClass = isValid
            ? classes.validPasswordItemText
            : classes.invalidPasswordItemText
          return (
            <ul key={index} className={classes.verifyPasswordItem}>
              <li
                className={classNames(
                  classes.verifyPasswordItemText,
                  value.length > 0 && currentClass
                )}
              >
                {message}
              </li>
            </ul>
          )
        })}
      </div>
    )
  }

  const passwordIsInvalid = () => {
    const { senha, confirmarSenha } = secretariaInformations
    const passwordIsValid = ITENS_TO_VERIFY.every(verifyFunction => verifyFunction(senha).isValid)
    if (!passwordIsValid) return false
    return senha !== confirmarSenha
  }

  return (
    <>
      {renderVerifyPassword(secretariaInformations.senha)}
      <ItemGrid size={12} label={'Senha'}>
        <InputSenha
          className={classNames(classes.inputContainer, hasError['senha'] && classes.inputError)}
          value={secretariaInformations.senha}
          id='senha'
          onChange={value => handleChange(value, 'senha')}
        />
      </ItemGrid>
      <ItemGrid size={12} label={'Confirmar senha'}>
        <InputSenha
          className={classNames(classes.inputContainer, passwordIsInvalid() && classes.inputError)}
          id='confirmarSenha'
          value={secretariaInformations.confirmarSenha}
          onChange={value => handleChange(value, 'confirmarSenha')}
        />
      </ItemGrid>
      <ModalConfirmacao
        open={openNotification}
        onClose={toggleOpenNotification}
        icon={<SuccessIcon />}
        color={'green'}
        title={'Secretário criado com sucesso!'}
      >
        <p className={classes.modalConfirmacaoDescription}>
          Foi enviado um email para acessar o sistema.
        </p>
      </ModalConfirmacao>
    </>
  )
}

const styles = theme => ({
  inputContainer: {
    width: 'calc( 100% - 20px )',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '4px 8px',
    '& > div': {
      '& > p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  inputError: {
    border: '1px solid ' + theme.palette.commons.red,
  },
  verifyPassword: {
    display: 'flex',
    flexDirection: 'column',
  },
  verifyPasswordItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '-10px',
  },
  verifyPasswordItemText: {
    fontSize: '0.875rem',
    color: '#333333',
    fontWeight: '500',
  },
  validPasswordItemText: {
    textDecoration: 'line-through',
    color: '#868686',
  },
  invalidPasswordItemText: {
    color: '#FB7676',
  },
  modalConfirmacaoDescription: {
    fontSize: '0.75rem',
    color: '#333333',
    fontWeight: '500',
    textAlign: 'center',
  },
})

export default withStyles(styles)(CriarSenha)
