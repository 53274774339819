import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = ({
  text: {
    color: '#868686',
    fontSize: '10px !important',
    marginLeft: '4px'
  }
});

const TextCharCounter = ({currQuantity, limit, classes}) => {
  return (
    <span className={classes.text}>
      {`${currQuantity}/${limit} caracteres`}
    </span>
  );
};

export default withStyles(styles)(TextCharCounter);
