import { withStyles } from "@material-ui/core";
import React from "react";
import { Delete as DeleteIcon } from "@material-ui/icons";
import classNames from "classnames";
import InputSelectComLabel from "../../../../components/Input/InputSelectComLabel";
import Colors from "../../../../template/Colors";
import {
  ImagePdf,
  ImageXlsx,
  ImageDoc,
  ImagePpt,
} from "../../../../assets/img/svg";
import { formatBytes } from "../../../../utils/bytes";

const extensoes = {
  pdf: ImagePdf,
  xlsx: ImageXlsx,
  docx: ImageDoc,
  ppt: ImagePpt,
};

const ItemAnexo = (props) => {
  const { classes, anexo, onNomeChange, excluirItem, loading } = props;

  const imagemMiniatura = () => {
    const imagem = `data:image/${anexo.extensao};base64,${anexo.base64}`;

    return extensoes[anexo.extensao] || imagem;
  };

  return (
    <div className={classes.item}>
      <div className={classes.divImagemMiniatura}>
        <img
          src={imagemMiniatura()}
          alt={anexo.nome}
          width={54}
          height={54}
          className={classes.imagemMiniatura}
        />
      </div>

      <InputSelectComLabel
        label={"Nome do arquivo:"}
        value={anexo.nome}
        onChange={onNomeChange}
        classes={{
          input: classes.inputNome,
          divLabel: classes.divLabelNomeArquivo
        }}
        disabled={loading}
      />

      <div
        className={classNames(
          classes.positionExtensaoDelete,
          classes.borderGray,
          classes.widthTamanho
        )}
      >
        {anexo.extensao}
      </div>
      <InputSelectComLabel
        label={"Tamanho:"}
        value={anexo.tamanho ? formatBytes(anexo.tamanho) : ""}
        classes={{
          input: classNames(classes.inputTamanho, classes.borderGray),
          divLabel: classes.divLabelTamanho,
        }}
        disabled
      />
      <div
        onClick={!loading && excluirItem}
        className={classNames(
          classes.positionExtensaoDelete,
          classes.divDelete
        )}
      >
        <DeleteIcon className={classNames(classes.deleteIcon, {[classes.deleteIconDisabled]: loading})} />
      </div>
    </div>
  );
};

const style = {
  item: {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
  },
  deleteIcon: {
    color: Colors.commons.gray3,
  },
  positionExtensaoDelete: {
    display: "flex",
    marginRight: "8px",
    height: "35px",
    borderRadius: "100px",
    marginTop: "23px",
    padding: "0 8px",
    alignItems: "center",
  },
  divDelete: {
    cursor: "pointer",
  },
  inputTamanho: {
    background: "#fff",
  },
  borderGray: {
    border: "1px solid #F2F2F2",
    maxWidth: "70px",
  },
  divLabelTamanho: {
    width: "auto",
  },
  widthTamanho: {
    width: "100%",
    maxWidth: "50px",
    justifyContent: "center",
  },
  divImagemMiniatura: {
    marginRight: 8,
    background: "#F5F5F5",
    borderRadius: 5,
    width: 54,
    height: 54,
  },
  imagemMiniatura: {
    borderRadius: 5,
  },
  deleteIconDisabled: {
    cursor: 'default',
    opacity: 0.3
  },
  divLabelNomeArquivo: {
    maxWidth: 220,
  }
};

export default withStyles(style)(ItemAnexo);
