import React, { useState } from 'react';
import { withStyles, Popper, Paper } from '@material-ui/core';
import TripleDotsIcon from '../../../../../components/Icon/TripleDotsIcon';
import DeleteIcon from '../../../../../components/Icon/DeleteIcon';

const styles = ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '43px',
    width: '43px',
    transition: 'background 0.25s',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E5E5E5',
    }
  },
  popper: {
    zIndex: 1000,
    width: "100%",
    maxWidth: '262px',
    maxHeight: '62px'
  },
  paper: {
    padding: "20px 16px 20px 20px",
    backgroundColor: 'white',
    color: '#505050'
  },
  menu: {
    
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '14px',
    '&:hover': {
      cursor: 'pointer',
    }
  }
});

const TableCellDropDown = ({ classes, onClickOption }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open ? "no-transition-popper" : null;

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };
  
  return (
    <>
      <div className={classes.button} onClick={handleClick}>
        <TripleDotsIcon size={18} color="#5F6368" />
      </div>
      <Popper 
        open={open}
        id={id} 
        anchorEl={anchorEl}
        placement={"bottom"}
        className={classes.popper}
        disablePortal
      >
        <Paper className={classes.paper}>
          <div className={classes.menu}>
            <div className={classes.menuItem} onClick={onClickOption}>
              <DeleteIcon size={18} color="#505050"/>
              <span>Cancelar agendamento</span>
            </div>
          </div>
        </Paper>
      </Popper>
    </>
  );
};

export default withStyles(styles)(TableCellDropDown);