import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles, Grid, Typography, Switch } from "@material-ui/core";
import styles from "../../../../assets/jss/pages/configuracaoReceitaStyle";
import TextFieldMetricUnit from "../../../../components/TextField/TextFieldMetricUnit";

const PosicaoAssinaturaDigital = observer((props) => {
  const { classes, configuracaoImpressaoStore } = props;
  const {
    configuracaoImpressao,
    handleChangeUnit,
    handleChangeSignVisibility
  } = configuracaoImpressaoStore;

  return (
    <Grid item xs={12} xl={10}>
      <Typography component="h6">Posição XY da Assinatura Digital</Typography>
      <Grid container direction="row">
        <Grid item xs={6}>
          <div className={classes.numberStepGroup}>
            <Typography component="label">Posição X</Typography>
            <TextFieldMetricUnit
              unit="px"
              value={configuracaoImpressao.assinaturaVisivelPosicaoX}
              onUnitChange={(e) =>
                handleChangeUnit("assinaturaVisivelPosicaoX", e)
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.numberStepGroup}>
            <Typography component="label">Posição Y</Typography>
            <TextFieldMetricUnit
              unit="px"
              value={configuracaoImpressao.assinaturaVisivelPosicaoY}
              onUnitChange={(e) =>
                handleChangeUnit("assinaturaVisivelPosicaoY", e)
              }
            />
          </div>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Typography component="h6" style={{ marginBottom: 0 }}>
          Assinatura visível
        </Typography>
        <Switch 
          classes={{
            root: classes.assinaturaSwitchRoot,
          }}
          color="primary" checked={configuracaoImpressao.assinaturaVisivel}
          onChange={handleChangeSignVisibility} 
        />
      </Grid>
    </Grid>
  );
});

const PosicaoAssinaturaDigitalWithStyles = withStyles(styles)(
  PosicaoAssinaturaDigital
);
export default inject("configuracaoImpressaoStore")(
  PosicaoAssinaturaDigitalWithStyles
);
