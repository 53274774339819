import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold !important',
    whiteSpace: 'nowrap',
    marginBottom: '4px'
  },
  message: {
    fontSize: '12px !important'
  }
});

const TooltipCard = ({ classes, title, message }) => {
  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <span className={classes.message}>{message}</span>
    </div>
  )
};

export default withStyles(styles)(TooltipCard);