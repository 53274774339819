import React, { useState } from 'react';
import { inject } from "mobx-react";
import {withStyles} from "@material-ui/core/styles/index";
import {
  Grid, 
  IconButton, 
  InputAdornment,
} from '@material-ui/core'
import {
  Clear as ClearIcon,
  Search,
} from "@material-ui/icons";
import InputSearchRetangular from '../../../components/Input/InputSearchRetangular';

const SearchProcedimentosRealizados = (props) =>{
  const { classes, procedimentosRealizadosStore, sujeitoId } = props;
  
  const [search, setSearch] = useState("");

  const handleClickSearch = () => {
    procedimentosRealizadosStore.findAllProcedimentosRealizados({
      idSujeitoAtencao: sujeitoId,
      search: search,
      pageableDTO:{
        pageSize: 30,
        pageNumber: 0,
        sortDir: "DESC",
        sortField: "dataHoraLancamento"
      }
    });
  };

  const handleClickClear = () => {
    setSearch("");
    procedimentosRealizadosStore.lista=[];
    procedimentosRealizadosStore.pageNumber=0;
    procedimentosRealizadosStore.search="";
    procedimentosRealizadosStore.last=false;
    procedimentosRealizadosStore.findAllProcedimentosRealizados({
      search: "",
      idSujeitoAtencao: sujeitoId,
      pageableDTO:{
        pageSize: 30,
        pageNumber: 0,
        sortDir: "DESC",
        sortField: "dataHoraLancamento"
      }
    })
  };

  const onChangeSearch = (e) =>{
    const {value} = e.target;
    setSearch(value);
    procedimentosRealizadosStore.search = value;
  }

  const onKeypressSearch = (e) => {
    if (e.key === 'Enter' && search.length >= 3) {
      handleClickSearch();
    }
  }

  return(
    <Grid className={classes.grid}>
    <InputSearchRetangular
      startAdornment={
        <InputAdornment position="start">
          <IconButton className={classes.iconButtonSearch}
                      onClick={handleClickSearch}>
            <Search/>
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton className={classes.iconButtonSearch}
                      onClick={handleClickClear}>
            <ClearIcon/>
          </IconButton>
        </InputAdornment>}
      name={"searchProcedimentosRealizados"}
      placeholder={"Pesquisa por procedimento, dia, sala ou profissional responsável"}
      value={search}
      onChange={onChangeSearch}
      onKeyPress={onKeypressSearch}
    />
    </Grid>
  )
};

const styles ={
  grid:{
    padding: '8px 12px',
  },

}

const CustomizedTableWithStyles = withStyles(styles)(SearchProcedimentosRealizados);
export default inject("procedimentosRealizadosStore")(CustomizedTableWithStyles)