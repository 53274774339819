import React, { useRef } from 'react';
import Button from './Button';

const ButtonWithFileDialog = ({ text, onLoadFile, style, disabled }) => {
  const ref = useRef();
  
  const handleClick = () => {
    ref.current.click();
  }

  const handleLoadImage = async () => {
    const fileInput = ref && ref.current;

    if (!fileInput instanceof HTMLElement) {
      return;
    }
    
    let url = '';
    const fileReader = new FileReader();
    fileReader.onloadend = async function() {
      url = fileReader.result;
      if (url) {
        const blob = await getBlob(url);
        onLoadFile(blob)
      }
    }
    fileReader.readAsDataURL(fileInput.files[0]);
  }
  
  const getBlob = async (url) => {
    let res = await fetch(url);
    let blob = await res.blob();
    return blob;
  }; 

  return (
    <Button onClick={!disabled ? handleClick : null} style={style}>
      <input 
        ref={ref} 
        type="file"
        accept=".jpg,.jpeg,.png" 
        style={{ height: 0, width: 0 }}
        onChange={handleLoadImage}
      />
      <span>{text}</span>
    </Button>
  )
};

export default ButtonWithFileDialog;