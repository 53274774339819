import Api from "../config/api";

export const findAllFinanceiroGuiaConsulta = async (variables) => {
  const response = await Api.post("", {
    query: `
      query($pageableDTO: PageableDTOInput, $search: String, $dataFim:LocalDate, $dataInicio:LocalDate, $convenioId: Long, $sujeitoAtencaoId: UUID, $agendamentoTipoId: UUID){
        findAllFinanceiroGuiaConsulta(pageableDTO: $pageableDTO, search: $search, dataFim:$dataFim, dataInicio:$dataInicio, convenioId: $convenioId, sujeitoAtencaoId: $sujeitoAtencaoId, agendamentoTipoId: $agendamentoTipoId){
          last
          content{
          id
          beneficiario{
            sujeitoAtencao{
              nome
              nomeSocial
            }
          }
          convenio {
            id
            descricao
          }
          agendamentoTipo {
            id
            codigo
            descricao
            tuss22ProcedimentoEventoSaude {
              id
              codigoTermo
              descricao
            }
            tuss52TerminologiaTipoConsulta {
              id
              codigoTermo
              descricao
            }
          }
          numeroGuiaPrestador
        }
      }
    }`,
    variables: variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllFinanceiroGuiaConsulta;
};

export const findByIdFinanceiroGuiaConsulta = async (id) => {
  const response = await Api.post("", {
    query: `
    {
      findByIdFinanceiroGuiaConsulta(financeiroGuiaConsultaId: "${id}") {
        id
        beneficiario {
          atendimentoRN
          dataValidadeCarteira
          numeroCarteira
          nome
          nomeSocial
          cns
          sujeitoAtencao {
            id
            nome
            nomeSocial
          }
        }
        agendamentoTipo {
          id
          descricao
          codigo
        }
        dataAtendimento
        ausenciaCodigoValidacao
        codigoValidacao
        codigoTabela
        cbo
        cnes
        uf
        cnpjContratado
        cpfContratado
        codigoPrestadorNaOperadora
        codigoProcedimento
        codigoTabela
        codigoValidacao
        conselhoProfissional
        conselhoProfissionalSaude {
          id
          sigla
          codigoTermo
        }
        convenio {
          id
        }
        dataAtendimento
        financeiroGuiaServico {
          id
        }
        indicacaoAcidente
        nomeContratado
        nomeProfissional
        numeroGuiaOperadora
        numeroGuiaPrestador
        profissionalSaude {
          id
        }
        registroAns
        tipoConsulta
        unidade {
          id
        }
        conselhoProfissionalSaude {
          sigla
        }
        convenio {
          id
          descricao
        }
        tuss36TerminologiaIndicadorAcidente {
          id
          termo
          codigoTermo
        }
        tuss24TerminologiaCBO {
          id
          termo
          codigoTermo
        }
        nomeProfissional
        numeroConselhoProfissional
        observacao
        profissionalSaude {
          id
          nome
        }
        tuss22ProcedimentoEventoSaude {
          codigoTermo
          descricao
          id
        }
        tuss52TerminologiaTipoConsulta {
          codigoTermo
          descricao
          id
        }
        valorProcedimento
      }
      findAllEstadoList {
        codigoTermo
        uf
      }
    }
    `,
  });

  const {findAllEstadoList, findByIdFinanceiroGuiaConsulta} = response.data.data;
  const uf = findAllEstadoList.find(item => item.codigoTermo === findByIdFinanceiroGuiaConsulta.uf);

  return {...findByIdFinanceiroGuiaConsulta, uf};
};

export const createFinanceiroGuiaConsulta = async (guiaConsulta) => {
  const response = await Api.post("", {
    query: `
    mutation ($profissionalSaudeId: Long, $ausenciaCodValidacao: FinanceiroGuiaServicoAusenciaCodValidacao, $observacao: String, $convenioId: Long, $valorProcedimento: BigDecimal, $codigoValidacao: String, $dataAtendimento: LocalDate, $indicacaoAcidente: Tuss36TerminologiaIndicadorAcidenteInput, $agendamentoTipoId: UUID, $beneficiario: FinanceiroGuiaServicoBeneficiarioInput, $cbo: Tuss24TerminologiaCBOInput) {
      createFinanceiroGuiaConsulta(profissionalSaudeId: $profissionalSaudeId, ausenciaCodValidacao: $ausenciaCodValidacao, observacao: $observacao, convenioId: $convenioId, valorProcedimento: $valorProcedimento, codigoValidacao: $codigoValidacao, dataAtendimento: $dataAtendimento, indicacaoAcidente: $indicacaoAcidente, agendamentoTipoId: $agendamentoTipoId, beneficiario: $beneficiario, cbo: $cbo) {
        id
      }
    }
    `,
    variables: { ...guiaConsulta },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response;
};

export const createFinanceiroGuiaConsultaAgendamento = async(guiaConsulta) => {
  const response = await Api.post("", {
    query: `
    mutation ($agendamentoId: Long, $profissionalSaudeId: Long, $ausenciaCodValidacao: FinanceiroGuiaServicoAusenciaCodValidacao, $observacao: String, $convenioId: Long, $valorProcedimento: BigDecimal, $codigoValidacao: String, $dataAtendimento: LocalDate, $indicacaoAcidente: Tuss36TerminologiaIndicadorAcidenteInput, $agendamentoTipoId: UUID, $beneficiario: FinanceiroGuiaServicoBeneficiarioInput, $cbo: Tuss24TerminologiaCBOInput) {
      createFinanceiroGuiaConsultaAgendamento(agendamentoId: $agendamentoId, profissionalSaudeId: $profissionalSaudeId, ausenciaCodValidacao: $ausenciaCodValidacao, observacao: $observacao, convenioId: $convenioId, valorProcedimento: $valorProcedimento, codigoValidacao: $codigoValidacao, dataAtendimento: $dataAtendimento, indicacaoAcidente: $indicacaoAcidente, agendamentoTipoId: $agendamentoTipoId, beneficiario: $beneficiario, cbo: $cbo) {
        id
      }
    }
    `,
    variables: { ...guiaConsulta },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response;

};

export const updateFinanceiroGuiaConsulta = async (financeiroGuiaConsulta) => {
  const response = await Api.post("", {
    query: `
    mutation ($financeiroGuiaConsulta: FinanceiroGuiaConsultaInput) {
      updateFinanceiroGuiaConsulta(financeiroGuiaConsulta: $financeiroGuiaConsulta) {
        id
      }
    }
    `,
    variables: { financeiroGuiaConsulta },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response;
};

export const listasDoFiltro = () =>
  Api.post("", {
    query: `{
      findAllConvenioList(searchDTO: {
        ativo: true,
        sortDir: "ASC",
        sortField: "descricao"
      }) {
        id
        descricao
      }
      findAllAgendamentoTipo(ativo: true, pageableDTO: {sortDir: "ASC", sortField: "descricao"}) {
        id
        descricao
        codigo
      }
    }`,
  });

export const consultaSujeitosDeAtencao = async ({
  search = null,
  pageNumber,
}) => {
  const response = await Api.post("", {
    query: `
    query ($page: SearchSujeitoAtencaoDTOInput){
      findAllSujeitoAtencao(searchDTO: $page){
        last
        content {
          value: id
          label: nome
        }
      }
    }`,
    variables: {
      page: {
        search: search,
        pageSize: 20,
        pageNumber,
        sortField: "nome",
        sortDir: "ASC",
      },
    },
  });
  const { findAllSujeitoAtencao } = response?.data?.data;

  return findAllSujeitoAtencao;
};

export const findAllConvenio = (
  search = "",
  sort = { sortDir: "ASC", sortField: "descricao" },
  ativo = true,
  pageNumber = 0,
  pageSize = 30
) =>
  Api.post("", {
    query: `
      query ($searchDTO: SearchDTOInput) {
          findAllConvenio(searchDTO: $searchDTO) {
              last
              content {
                id
                descricao
                convenioAgendamentoTipo {
                  agendamentoTipo {
                    id
                    descricao
                  }
                  valor
                }
              }
          }
      }
  `,
    variables: {
      searchDTO: {
        search,
        ...sort,
        ativo,
        pageNumber,
        pageSize,
      },
    },
  });

export const listarSelectModal = async () => {
  const response = await Api.post("", {
    query: `
      {
        findAllTuss36TerminologiaIndicadorAcidente(pageableDTO: {pageNumber: 0}) {
          content {
            id
            termo
            codigoTermo
          }
        }
        findAllAgendamentoTipo(ativo: true, pageableDTO: {sortDir: "ASC", sortField: "descricao"}) {
          id
          descricao
          ativo
          codigo
          tuss22ProcedimentoEventoSaude {
              id
              codigoTermo
              descricao
          }
          tuss52TerminologiaTipoConsulta {
              id
              codigoTermo
              descricao
          }
      }
      }`,
  });

  return {
    findAllTuss36TerminologiaIndicadorAcidente:
      response.data.data.findAllTuss36TerminologiaIndicadorAcidente,
    findAllAgendamentoTipo: response.data.data.findAllAgendamentoTipo,
  };
};

export const getDadosGuiaConsultaAgendamento = async (id) => {
  const response = await Api.post("", {
    query: `
      {
        content: findByIdAgendamento(id: "${id}") {
          id
          data
          tipo {
            id
            descricao
            convenioAgendamentoTipo {
              agendamentoTipo {
                id
              }
              valor
            }
            tuss22ProcedimentoEventoSaude {
              id
              descricao
              codigoTermo
            }
            tuss52TerminologiaTipoConsulta {
              id
              descricao
              codigoTermo
            }
          }
          nome
          convenio {
            id
            descricao
            convenioAgendamentoTipo {
              agendamentoTipo {
                id
              }
              valor
            }
          }
          profissionalSaude {
            id
            nome
            ufConselho
            numeroConselho
            conselhoProfissionalSaude {
              codigoTermo
              sigla
            }
            tuss24TerminologiaCBO {
              id
              codigoTermo
              termo
            }
          }
          sujeitoAtencao {
            id
            nome
            nomeSocial
            dadosConvenio {
              numeroCarteira
              validadeCarteira
              convenio {
                id
                descricao
              }
            }
            cns
          }
        }
      }  
    `,
  });

  return response.data.data.content;
};

export const findAllEstadoList = async() => {
  const response = await Api.post("", {
    query: `{
      findAllEstadoList {
        codigoTermo
        uf
      }
    }`
  });

  return response.data.data.findAllEstadoList;
};
