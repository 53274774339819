import { action, observable } from "mobx";
import Api from "../config/api";

export default class TermoUsoStore {

    @observable isOpenTermoUsoModal = false;
    @observable termoUso = {};

    @action openModal = () => {
        this.isOpenTermoUsoModal = true;
    }

    @action closeModal = () => {
        this.isOpenTermoUsoModal = false;
    }

    @action refuseTermoUso = (history) => {
        history.push('login')
    }

    @action buscarTermoUso = async (tipos) => {
        try {
            const result = await Api.post('', {
                query: `
                    query{
                        buscarTermoUso(tipos: ${tipos}){
                            id
                            nome
                            clientId
                            tipo
                            pdf
                        }
                    }`
            });

            const { buscarTermoUso } = result.data.data;

            if (buscarTermoUso && buscarTermoUso.length) {
                this.termoUso = buscarTermoUso;
                this.openModal();
            }

            return buscarTermoUso;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    @action assinarTermoUso = async (tipos) => {
        try {
            const result = await Api.post('', {
                query: `
                    mutation{
                        assinarUsuarioTermoUso(tipos: ${tipos}) {
                            id
                        }
                    }`
            });

            const { assinarUsuarioTermoUso } = result.data.data;

            if (assinarUsuarioTermoUso) {
                this.closeModal();
            }

            return assinarUsuarioTermoUso;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}