import React from "react";
import { withStyles, IconButton } from "@material-ui/core";
import { Button } from "../../../../../../components/ui/Buttons";
import SearchIcon from "../../../../../../components/Icon/Search";
import ItemGrid from "../../../../../../components/Modal/ItemGrid";
import { InputForm } from "../../../../../../components/Modal/Input";
import FilterIcon from "../../../../../../components/Icon/FilterIcon";

const Filter = ({
  classes,
  onClickFilterButton,
  onChange,
  filter,
  handleApplyFilter,
  isLoading,
}) => {
  const handleKeyPress = () => {
    handleApplyFilter();
  };

  return (
    <div className={classes.filterContainer}>
      <div style={{ flex: 1 }}>
        <ItemGrid
          label="Pesquisar orçamento"
          classes={{
            item: classes.item,
          }}
        >
          <InputForm
            placeholder="Pesquise por nome"
            className={classes.inputTextField}
            onChange={(e) => onChange(e.target.value, "search")}
            onKeyDown={(e) => e.key === "Enter" && handleKeyPress()}
            value={filter.search}
            startAdornment={
              <IconButton
                className={classes.searchButton}
                onClick={() => handleApplyFilter()}
                disabled={isLoading}
              >
                <SearchIcon />
              </IconButton>
            }
          />
        </ItemGrid>
      </div>
      <Button
        kind="secondary"
        startEnhancer={<FilterIcon size={12} color="white" />}
        onClick={onClickFilterButton}
        className={classes.filterButton}
      >
        Filtrar
      </Button>
    </div>
  );
};

const styles = {
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "16px",
    alignItems: "center",
    gap: "16px",
  },
  item: {
    padding: "0 !important",
  },
  inputTextField: {
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    width: "calc( 100% - 16px )",
    height: "32px",
    padding: "4px 8px",
  },
  searchButton: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  filterButton: {
    height: "37px",
    alignSelf: "flex-end",
  },
};

export default withStyles(styles)(Filter);
