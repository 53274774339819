import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const AnexoIcon = (props) => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M1.28033 7.60876L6.58363 2.30546C7.65757 1.23151 9.39878 1.23151 10.4727 2.30546C11.5467 3.3794 11.5467 5.1206 10.4727 6.19454L4.10876 12.5585C3.6206 13.0467 2.82915 13.0467 2.34099 12.5585C1.85283 12.0703 1.85283 11.2789 2.34099 10.7907L7.99784 5.13388C8.29074 4.84099 8.29074 4.36612 7.99784 4.07322C7.70495 3.78033 7.23008 3.78033 6.93718 4.07322L1.28033 9.73008C0.206388 10.804 0.206388 12.5452 1.28033 13.6192C2.35427 14.6931 4.09548 14.6931 5.16942 13.6192L11.5334 7.2552C13.1931 5.59548 13.1931 2.90452 11.5334 1.2448C9.87365 -0.414932 7.1827 -0.414932 5.52297 1.2448L0.21967 6.5481C-0.0732233 6.84099 -0.0732233 7.31586 0.21967 7.60876C0.512563 7.90165 0.987437 7.90165 1.28033 7.60876Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AnexoIcon;
