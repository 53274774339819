import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const CircleInfoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...normalizeProps(props)}
    >
      <path
        d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM5.5 6.5V8C5.5 8.27614 5.72386 8.5 6 8.5C6.27614 8.5 6.5 8.27614 6.5 8V6.5C6.5 6.22386 6.27614 6 6 6C5.72386 6 5.5 6.22386 5.5 6.5ZM6 3.75C5.58579 3.75 5.25 4.08579 5.25 4.5C5.25 4.91421 5.58579 5.25 6 5.25C6.41421 5.25 6.75 4.91421 6.75 4.5C6.75 4.08579 6.41421 3.75 6 3.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CircleInfoIcon;
