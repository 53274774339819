import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DocumentError = (props) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M9 0V4.5C9 5.32843 9.67157 6 10.5 6H15V14.5C15 15.3284 14.3284 16 13.5 16H8.74284C9.52818 15.0491 10 13.8296 10 12.5C10 9.46243 7.53757 7 4.5 7C3.97999 7 3.47683 7.07217 3 7.20703V1.5C3 0.671573 3.67157 0 4.5 0H9ZM10 0.25V4.5C10 4.77614 10.2239 5 10.5 5H14.75L10 0.25ZM9 12.5C9 14.9853 6.98528 17 4.5 17C2.01472 17 0 14.9853 0 12.5C0 10.0147 2.01472 8 4.5 8C6.98528 8 9 10.0147 9 12.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5V12.5C4 12.7761 4.22386 13 4.5 13C4.77614 13 5 12.7761 5 12.5V10.5C5 10.2239 4.77614 10 4.5 10ZM4.5 15.125C4.84518 15.125 5.125 14.8452 5.125 14.5C5.125 14.1548 4.84518 13.875 4.5 13.875C4.15482 13.875 3.875 14.1548 3.875 14.5C3.875 14.8452 4.15482 15.125 4.5 15.125Z"
        fill="#currentColor"
      />
    </svg>
  );
};

export default DocumentError;
