import React, { useEffect } from 'react'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { Tooltip, withStyles } from '@material-ui/core'

import SujeitoAtencaoAvatar from './Avatar'
import RectLabel from '../../../../../components/Labels/RectLabel'
import WarningIcon from '../../../../../components/Icon/Warning'
import PencilIcon from '../../../../../components/Icon/Pencil'
import MoneyIcon from '../../../../../components/Icon/Money'
import { Button } from '../../../../../components/ui/Buttons'

import { buildUrlFotoPerfil } from '../../../../../config/config'
import { formatAge } from '../../utils/formatAge'
import string from '../../../../../utils/string'
import { checkUserRole } from '../../../../../utils/checkUserRole'

const SujeitoAtencaoInfos = observer(
  ({ classes, handleClickEdit, handleClickFinanceiro, prontuarioStore, atendimentoStore }) => {
    const { sujeitoAtencaoSelected, agendamentoSelected } = prontuarioStore
    const isBirthday = () => {
      if (!sujeitoAtencaoSelected?.dataNascimento) return false
      const today = new Date()
      const birthday = new Date(sujeitoAtencaoSelected?.dataNascimento)
      return today.getDate() === birthday.getDate() && today.getMonth() === birthday.getMonth()
    }
    const withDadosSensiveis = sujeitoAtencaoSelected?.informacoesImportantes?.length > 0

    useEffect(() => {
      checkViewFinaceiroRole()
    }, [])

    const checkViewFinaceiroRole = async () => {
      atendimentoStore.canViewFincanceiro = await checkUserRole('ROLE_TITULO_PARCELA_CREATE')
    }

    return (
      <div className={classes.container}>
        <div className={classes.wrapperPacienteInfos}>
          <SujeitoAtencaoAvatar
            birthday={isBirthday()}
            urgente={!!agendamentoSelected?.urgente}
            url={buildUrlFotoPerfil(sujeitoAtencaoSelected?.fotoPerfil)}
          />
          <div className={classes.pacienteInfos}>
            <div className={classes.pacientePrimaryInfos}>
              <Tooltip title={sujeitoAtencaoSelected?.nome?.length > 30 ? sujeitoAtencaoSelected?.nome : ""}>
                <span className={classes.pacienteNome}>{string.truncate(sujeitoAtencaoSelected?.nome, 30)}</span>
              </Tooltip>
              <Button
                kind='transparent'
                shape='square'
                size='mini'
                onClick={handleClickEdit}
                className={classes.actionsPaciente}
              >
                <PencilIcon />
              </Button>
              {atendimentoStore.canViewFincanceiro && (
                <Button
                  kind='transparent'
                  shape='square'
                  size='mini'
                  onClick={handleClickFinanceiro}
                  className={classes.actionsPaciente}
                >
                  <MoneyIcon />
                </Button>
              )}
            </div>
            <div className={classes.pacienteSecondaryInfos}>
              <span>{formatAge(sujeitoAtencaoSelected)}</span>
              {withDadosSensiveis && (
                <RectLabel
                  text={
                    <div className={classes.warningText}>
                      <WarningIcon color='white' size='10' />
                      <span>Dados Sensíveis</span>
                    </div>
                  }
                  color='#FB7676'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  wrapperPacienteInfos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pacienteInfos: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    width: 'max-content',
  },
  pacienteNome: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pacientePrimaryInfos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pacienteSecondaryInfos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > span': {
      fontSize: 12,
      fontWeight: 500,
      color: '#7C7C7C',
      marginRight: 6,
    },
  },
  warningText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      marginLeft: 4,
      fontSize: '10px !important',
      fontWeight: 600,
    },
  },
  actionsPaciente: {
    padding: '0px 8px !important',
  },
}

const SujeitoAtencaoInfosWithStyles = withStyles(styles)(SujeitoAtencaoInfos)
const stores = ['prontuarioStore', 'atendimentoStore']

export default inject(...stores)(SujeitoAtencaoInfosWithStyles)
