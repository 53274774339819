export const uploadArquivo = async(documentoFileInput) => {
    const fileInput = documentoFileInput && documentoFileInput.current;

    if (!fileInput instanceof HTMLElement) {
      return;
    }

    let url = "";
    const fileReader = new FileReader();
    fileReader.onloadend = function() {
      url = fileReader.result;
    };
    const files = fileInput.files[0];
    fileReader.readAsDataURL(files);

    return files;
};

const extractNameAndExtension = (file) => {
  const splitted = file.split('.');
  const extension = `.${splitted.pop()}`;
  const filename = splitted.join('.');
  return {filename, extension};
};

export const uploadArquivos = async(arquivos) => {
  let listaArquivos = [];

  for (let arquivo of arquivos) {
    const base64 = await getBase64FromFile(arquivo);
    const { filename, extension } = extractNameAndExtension(arquivo.name);
    const anexo = {
      nome: filename,
      image: arquivo.type.indexOf("image") !== -1,
      extensao: extension,
      base64: base64,
      tamanho: arquivo.size
    };
    listaArquivos=[...listaArquivos, anexo]
  };

  return listaArquivos
}

const getBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = (error) => reject(error);
  });
};