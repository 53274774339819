import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { DialogTitle, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../GuiaServicosFaturamentoStyle";
import { TextFieldSearch } from "../../../../../components/TextField";

const StepAtendimento = (props) => {
  const {
    classes,
    setDisableButton,
    guiaServicoFaturamento,
    handleChangeGuiaServicoFaturamento,
    loadTipoAtendimento,
    loadIndicacaoAcidente,
    loadTipoConsulta,
    loadMotivoEncerramentoAtendimento,
  } = props;

  useEffect(() => {
    validationStep();
  }, [guiaServicoFaturamento?.atendimento]);

  const handleChangeAtendimento = (option, field) => {
    let newDadoAtendimento = guiaServicoFaturamento?.atendimento;

    newDadoAtendimento = { ...newDadoAtendimento, [field]: option };
    handleChangeGuiaServicoFaturamento("atendimento", newDadoAtendimento);
  };

  const validationStep = () => {
    const tipoConsulta = guiaServicoFaturamento?.atendimento?.tuss50TerminologiaTipoAtendimento?.termo === "Consulta" && 
      !guiaServicoFaturamento?.atendimento?.tuss52TerminologiaTipoConsulta
    if (
      !guiaServicoFaturamento?.atendimento?.tuss50TerminologiaTipoAtendimento ||
      !guiaServicoFaturamento?.atendimento
        ?.tuss36TerminologiaIndicadorAcidente ||
      tipoConsulta
    ) {
      setDisableButton(true);
      return;
    }
    setDisableButton(false);
  };

  return (
    <DialogTitle className={classes.container}>
      <Grid container xs={12} className={classes.input}>
        <div className={classes.rowAtendimento}>
          <Grid item xs={6} className={classes.marginRight}>
            <Typography className={classes.typography} component="label">
              Tipo de atendimento:
            </Typography>
            <TextFieldSearch
              placeholder=""
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              loadOptions={loadTipoAtendimento}
              getOptionLabel={(option) => option.termo}
              getOptionValue={(option) => option.id}
              value={
                guiaServicoFaturamento?.atendimento
                  ?.tuss50TerminologiaTipoAtendimento
              }
              onChange={(e) =>
                handleChangeAtendimento(e, "tuss50TerminologiaTipoAtendimento")
              }
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition="fixed"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typography} component="label">
              Indicação de acidente:
            </Typography>
            <TextFieldSearch
              placeholder=""
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              loadOptions={loadIndicacaoAcidente}
              getOptionLabel={(option) => option.termo}
              getOptionValue={(option) => option.id}
              value={
                guiaServicoFaturamento?.atendimento
                  ?.tuss36TerminologiaIndicadorAcidente
              }
              onChange={(e) =>
                handleChangeAtendimento(
                  e,
                  "tuss36TerminologiaIndicadorAcidente"
                )
              }
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition="fixed"
            />
          </Grid>
        </div>
        {guiaServicoFaturamento?.atendimento?.tuss50TerminologiaTipoAtendimento
          ?.termo === "Consulta" && (
          <Grid item xs={12}>
            <Typography className={classes.typography} component="label">
              Tipo de consulta:
            </Typography>
            <TextFieldSearch
              placeholder=""
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              classes={{paper: classes.menuHeight}}
              loadOptions={loadTipoConsulta}
              getOptionLabel={(option) => option.descricao}
              getOptionValue={(option) => option.id}
              value={
                guiaServicoFaturamento?.atendimento
                  ?.tuss52TerminologiaTipoConsulta
              }
              onChange={(e) =>
                handleChangeAtendimento(e, "tuss52TerminologiaTipoConsulta")
              }
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition="fixed"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography className={classes.typography} component="label">
            Motivo de encerramento do atendimento:
          </Typography>
          <TextFieldSearch
            placeholder=""
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            classes={{paper: classes.menuHeight}}
            loadOptions={loadMotivoEncerramentoAtendimento}
            getOptionLabel={(option) => option.termo}
            getOptionValue={(option) => option.id}
            value={
              guiaServicoFaturamento?.atendimento
                ?.tuss39TerminologiaMotivoEncerramento
            }
            onChange={(e) =>
              handleChangeAtendimento(e, "tuss39TerminologiaMotivoEncerramento")
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default withStyles(styles)(StepAtendimento);
