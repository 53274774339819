import React from "react";
import styled from "styled-components";

const Chip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: #f2f2f2;
  border-radius: 100px;
  width: fit-content;
  height: 18px;
  margin: 0 8px 8px;
  cursor: ${(props) => (props.isObservacao ? "pointer" : "auto")};
`;

const Icon = styled.div`
  background: ${(props) => props.colorIcon};
  border-radius: ${(props) => (props.label === "Urgente" ? "100px" : "8px")};
  border: 1px solid rgba(220, 220, 220, 0.2);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfosChips = ({ label, icon, isObservacao, openModalObservacao }) => {
  return (
    <Chip isObservacao={isObservacao} onClick={openModalObservacao}>
      <Icon colorIcon={icon?.colorIcon} label={label}>
        {icon?.image}
      </Icon>
      {label}
    </Chip>
  );
};

export default InfosChips;
