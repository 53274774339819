const styles = {
  Wrapper:{
    display:"none",
  },
  paperAlert: {
    width: "320px",
    maxWidth: "320px",
    // height:"405px",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "24px",
    color: "#505050",
    fontWeight:"600",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "16px 0"
  },
  tip:{
    fontSize: "12px",
    fontWeight: "400",
    color: "#868686",
  },
  selectSearch: {
    width: "100%",
    height: "32px",
    "&>div>div": {
      borderRadius: "8px",
      height: "34px",
      border: "1px solid rgba(220, 220, 220, 0.2)",
    },
  },
  inputDate: {
    borderRadius: "8px",
  },
  dateTime: {
    width: "100%",
  },
  popperPeriodDate: {
    zIndex: 10000,
    width: "100%",
    maxWidth: "512px",
  },
  selectMultiCheckbox: {
    display: "flex",
    borderRadius: "8px"
  },
  multiCheckboxPopper: {
    zIndex: 1500,
    width: "320px",
    maxHeight: "300px",
    left: "-10px !important",
    top: "4px !important",
    "&:hover": {
      cursor: "pointer",
      background: "rgba(0, 0, 0, 0.04)"
    }
  },
  multiCheckboxPaper: {
    height: "320px",
    overflow: "hidden",
    margin: 0,
    padding: "8px 0",
  },
  multiCheckboxList: {
    height: "100%",
    overflowY: "auto",
    padding: "0 16px",
    gap: "8px",
    "&>label": {
      margin: "0 !important",
    },
  },
  inputWithLabelContainer: {
    display: "flex",
    flexDirection: "column"
  }
};

export default styles;
