import React, { useEffect } from 'react'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'

import { TextFieldSearch } from '../../../../components/TextField'
import InputSelectComLabel from '../../../../components/Input/InputSelectComLabel'
import { InputDateForm } from '../../../../components/Modal/Input'

import { findAllConvenio } from '../../../../services/ConvenioService'

import styles from '../GuiaDeServicoStyle'
import { verificaCamposVazioPaciente } from './ValidaDados'

const opcoesAtendimentoRN = [
  { value: 'S', label: 'Sim' },
  { value: 'N', label: 'Não' },
]

const StepSujeitoAtencao = props => {
  const {
    loadSujeitosAtencao,
    sujeitoSelected,
    handleChangeGuiaServico,
    setDisableButton,
    guiaServico,
    classes,
  } = props

  useEffect(() => {
    handleChangeGuiaServico('beneficiario', {
      ...guiaServico?.beneficiario,
      atendimentoRN: opcoesAtendimentoRN[1].value,
    })
  }, [])

  const loadConvenios = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllConvenio(search, { sortDir: 'ASC', sortField: 'descricao' }, true, page, 20)
      ).data.data.findAllConvenio
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch {
      openNotification('Erro ao carregar ', 'error')
    }
  }

  const handleChangeSujeitoAtencao = value => {
    const newSujeitoAtencao = {
      ...guiaServico?.beneficiario,
      sujeitoAtencao: value,
      nome: value?.nome,
      numeroCarteira: value?.dadosConvenio?.numeroCarteira,
      dataValidadeCarteira: value?.dadosConvenio?.validadeCarteira,
      cns: value?.cns,
    }
    handleChangeGuiaServico('beneficiario', newSujeitoAtencao)
  }

  const handleChangeBeneficiario = (field, value) => {
    const newBeneficiario = {
      ...guiaServico.beneficiario,
      [field]: value,
    }
    handleChangeGuiaServico('beneficiario', newBeneficiario)
  }

  const getValue = resposta => {
    if (resposta === 'S') {
      return { value: 'S', label: 'Sim' }
    } else if (resposta === 'N') {
      return { value: 'N', label: 'Não' }
    }
  }

  const validationStep = () => {
    if (verificaCamposVazioPaciente(sujeitoSelected, guiaServico)) {
      setDisableButton(true)
      return
    }
    setDisableButton(false)
  }

  useEffect(() => {
    validationStep()
  }, [guiaServico.beneficiario])

  return (
    <DialogContent>
      <Grid container>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Typography className={classes.typography} component='label'>
              Paciente
            </Typography>
            <TextFieldSearch
              placeholder='Selecione'
              loadOptions={loadSujeitosAtencao}
              classNotched={classes.notchedOutline}
              classInput={classes.inputContainer}
              classIcons={classes.classIcons}
              getOptionLabel={option => option?.nomeSocial || option?.nome}
              getOptionValue={option => option}
              value={sujeitoSelected}
              onChange={option => {
                handleChangeSujeitoAtencao(option)
                handleChangeGuiaServico('convenio', option?.dadosConvenio?.convenio)
              }}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typography} component='label'>
              Convênio
            </Typography>
            <TextFieldSearch
              placeholder='Selecione'
              loadOptions={loadConvenios}
              classNotched={classes.notchedOutline}
              classInput={classes.inputContainer}
              classIcons={classes.classIcons}
              getOptionLabel={option => option?.descricao}
              getOptionValue={option => option?.value}
              value={guiaServico?.convenio}
              onChange={option => handleChangeGuiaServico('convenio', option)}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <InputSelectComLabel
              label={'Número da Carteira'}
              placeholder='Digite o número da carteira'
              classes={{
                input: classes.inputContainer,
              }}
              onInput={e => (e.target.value = e.target.value.slice(0, 20))}
              value={guiaServico?.beneficiario?.numeroCarteira || ''}
              onChange={e => handleChangeBeneficiario('numeroCarteira', e.target.value)}
              type='number'
              noMargin
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typography} component='label'>
              Validade da Carteira
            </Typography>
            <InputDateForm
              iconposition='end'
              placeholder='00/00/0000'
              className={classes.inputDateForm}
              value={guiaServico?.beneficiario?.dataValidadeCarteira || ''}
              onChange={e =>
                handleChangeBeneficiario('dataValidadeCarteira', moment(e).format('YYYY-MM-DD'))
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <InputSelectComLabel
              label={'Cartão Nacional da Saúde'}
              placeholder='Digite o número do CNS'
              onInput={e => (e.target.value = e.target.value.slice(0, 15))}
              classes={{
                input: classes.inputContainer,
              }}
              value={guiaServico?.beneficiario?.cns || ''}
              onChange={e => handleChangeBeneficiario('cns', e.target.value)}
              type='number'
              noMargin
            />
          </Grid>
          <Grid item xs={6}>
            <InputSelectComLabel
              label={'Atendimento a RN'}
              placeholder='Selecione'
              isSelect
              value={getValue(guiaServico?.beneficiario?.atendimentoRN)}
              className={classes.inputContainer}
              onChange={e => handleChangeBeneficiario('atendimentoRN', e.value)}
              options={opcoesAtendimentoRN || []}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
            />
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  )
}

export default withStyles(styles)(StepSujeitoAtencao)
