import React, { useEffect, useReducer, useState } from 'react';
import { withStyles } from '@material-ui/core';
import * as Styles from './styles';

import ModalConfirmacao from '../../../../../components/Modal/ModalConfirmacao/ModalConfirmacao';
import { TextFieldSearch } from '../../../../../components/TextField';
import { ReactSelectCheckbox } from '../../../../../components/Select/SelectSearch';
import CloseIcon from '../../../../../components/Icon/CloseIcon';

import { weekDays } from '../../../TransferenciaAgendamento/constants';
import { combineDayHourPairs, loadProcedimentos } from './utils/utils';
import { updateProcedimentosConfiguracaoHorarioAtendimento } from '../../../../../services/ConfiguracaoHorarioAtendimentoService';
import { findAllProfissionalSaude } from '../../../../../services/ProfissionalSaudeService';

const ConfigProcedimentosModal = ({
  classes,
  isOpen,
  diaSemana,
  horaInicio,
  procedimentos,
  horarios,
  onClose,
  currentConfigVigente,
  solicitante,
}) => {
  const [horariosOptions, setHorariosOptions] = useState([]);
  const [diaSemanaOptions, setDiaSemanaOptions] = useState([]);
  const [selectedProcedimentos, setSelectedProcedimentos] = useState([]);
  const [profissionalSaude, setProfissionalSaude] = useState(null);
  const [isLoading, toggleLoading] = useReducer((state) => !state, false);

  const handleChangeWeekDay = (selectedsDays) => {
    const updatedWeekDays = diaSemanaOptions.map((item) => ({
      ...item,
      checked: checkIsSelected(item, selectedsDays),
    }));
    setDiaSemanaOptions(updatedWeekDays);
  };

  const handleChangeHours = (selectedHours) => {
    const updatedHours = horariosOptions.map((item) => ({
      ...item,
      checked: checkIsSelected(item, selectedHours),
    }));
    setHorariosOptions(updatedHours);
  };

  const checkIsSelected = (item, list) => {
    return list.some((option) => option.value === item.value);
  };

  const loadProcedimentosOptions = async (search, _, { page }) => {
    const { sala } = currentConfigVigente;
    const { content, last } = await loadProcedimentos({
      search,
      pageNumber: page,
      profissionalSaudeId: profissionalSaude?.id,
      salaId: sala ? sala.id : null,
    });

    return {
      options: content.map((item) => ({ nome: item.nome, id: item.id })),
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadProfissionaisSaudeOptions = async (search, _, { page }) => {
    const response = await findAllProfissionalSaude({
      pageNumber: page,
      search,
    });

    const { content, last } = response?.data?.data?.findAllProfissionalSaude;
    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSelectProcedimento = (procedimento) => {
    const updatedProcedimentos = [...selectedProcedimentos, procedimento];
    setSelectedProcedimentos(updatedProcedimentos);
  };

  const handleRemoveProcedimento = (index) => {
    const updatedProcedimentos = selectedProcedimentos.filter(
      (_, i) => i !== index
    );
    setSelectedProcedimentos(updatedProcedimentos);
  };

  const handleSaveConfig = async () => {
    const selectedsDays = diaSemanaOptions.filter((item) => item.checked);
    const selectedHours = horariosOptions.filter((item) => item.checked);
    const weekDayHourPairs = combineDayHourPairs(selectedsDays, selectedHours);
    let horariosAtendimento = currentConfigVigente.horariosAtendimento || [];
    weekDayHourPairs.forEach((item) => {
      const { diaSemana, horaInicio } = item;
      const indexToUpdate = horariosAtendimento.findIndex(
        (config) =>
          config.diaSemana === diaSemana && config.horaInicio === horaInicio
      );

      if (
        indexToUpdate > -1 &&
        horariosAtendimento[indexToUpdate]?.permiteAgendamento
      ) {
        horariosAtendimento[indexToUpdate].procedimentos = [
          ...selectedProcedimentos,
        ];
        horariosAtendimento[indexToUpdate].solicitante = profissionalSaude
          ? { id: profissionalSaude?.id }
          : null;
      }
    });
    const newConfigVigente = {
      ...currentConfigVigente,
      horariosAtendimento,
    };
    onSaveConfig(newConfigVigente);
  };

  const onSaveConfig = async (newConfigVigente) => {
    try {
      toggleLoading();
      await updateProcedimentosConfiguracaoHorarioAtendimento(newConfigVigente);
      onClose({ isReloadingConfig: true });
      clearData();
    } catch (error) {
      console.error(error);
    } finally {
      toggleLoading();
    }
  };

  const clearData = () => {
    setSelectedProcedimentos([]);
    setProfissionalSaude(null);
  };

  useEffect(() => {
    if (!isOpen) return;
    const horariosOptions = horarios?.map((item) => ({
      value: item,
      label: item,
      checked: item === horaInicio || false,
    }));
    const diasSemanaOptions = weekDays.map((item) => ({
      value: item.value,
      label: item.nome,
      checked: item.value === diaSemana || false,
    }));
    setHorariosOptions(horariosOptions || []);
    setDiaSemanaOptions(diasSemanaOptions || []);
    setSelectedProcedimentos(procedimentos || []);
    setProfissionalSaude(solicitante || null);
  }, [isOpen]);

  if (!isOpen) return null;

  const buttonsConfig = {
    labelPrimaryButton: 'Cancelar',
    handlePrimaryButton: onClose,
    disabledPrimaryButton: isLoading,
    labelSecondButton: 'Salvar',
    handleSecondButton: handleSaveConfig,
    disabledSecondButton: isLoading,
    loadingSecondButton: isLoading,
  };

  return (
    <ModalConfirmacao
      classes={{ paperAlert: classes.paperAlert }}
      open={isOpen}
      buttons={buttonsConfig}
      title='Procedimentos'
    >
      <Styles.Container>
        <Styles.InputContainer>
          <Styles.Label>Solicitante / Responsável</Styles.Label>
          <TextFieldSearch
            classNotched={classes.inputRoot}
            placeholder='Solicitante / Responsável'
            withPaginate
            onChange={(e) => setProfissionalSaude(e)}
            value={profissionalSaude}
            loadOptions={loadProfissionaisSaudeOptions}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Styles.InputContainer>

        <Styles.InputContainer>
          <Styles.Label>Dias da Semana</Styles.Label>
          <ReactSelectCheckbox
            options={diaSemanaOptions}
            value={diaSemanaOptions.filter((item) => item.checked)}
            onChange={handleChangeWeekDay}
          />
        </Styles.InputContainer>

        <Styles.InputContainer>
          <Styles.Label>Horários</Styles.Label>
          <ReactSelectCheckbox
            options={horariosOptions}
            value={horariosOptions.filter((item) => item.checked)}
            onChange={handleChangeHours}
          />
        </Styles.InputContainer>

        <Styles.InputContainer>
          <Styles.Label>Procedimentos</Styles.Label>
          <TextFieldSearch
            classNotched={classes.inputRoot}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            loadOptions={loadProcedimentosOptions}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            onChange={handleSelectProcedimento}
            placeholder='Selecione o(s) procedimento(s)'
          />
        </Styles.InputContainer>

        <Styles.ProcedimentosContainer procedimentos={selectedProcedimentos}>
          {selectedProcedimentos.map((procedimento, index) => (
            <Styles.ProcedimentoItem>
              {procedimento?.nome}
              {
                <CloseIcon
                  onClick={() => handleRemoveProcedimento(index)}
                  size={12}
                  color='#505050'
                />
              }
            </Styles.ProcedimentoItem>
          ))}
        </Styles.ProcedimentosContainer>
      </Styles.Container>
    </ModalConfirmacao>
  );
};

export default withStyles(Styles.styles)(ConfigProcedimentosModal);
