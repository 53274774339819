import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const RelatorioDashboardIcon = props => {
  return (
    <svg 
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill= "none"
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M25.0173 4.58355C26.9959 4.55732 28.6211 6.13999 28.6473 8.11855L28.8632 24.4026C28.8894 26.3812 27.3068 28.0064 25.3282 28.0326L9.04412 28.2485C7.06556 28.2747 5.44036 26.6921 5.41413 24.7135L5.19825 8.42942C5.17202 6.45086 6.75469 4.82566 8.73325 4.79943L25.0173 4.58355ZM11.4424 12.5856C10.9028 12.5927 10.4711 13.0341 10.4782 13.5714L10.5906 22.0475C10.5977 22.5848 11.0409 23.0145 11.5805 23.0074C12.1202 23.0002 12.5518 22.5588 12.5447 22.0216L12.4323 13.5454C12.4252 13.0082 11.982 12.5784 11.4424 12.5856ZM22.481 9.8333C21.9414 9.84046 21.5096 10.2717 21.5166 10.7965L21.6641 21.9233C21.671 22.4481 22.1141 22.8677 22.6537 22.8606C23.1933 22.8534 23.6251 22.4222 23.6182 21.8974L23.4707 10.7706C23.4637 10.2458 23.0206 9.82615 22.481 9.8333ZM16.9983 16.4165C16.4715 16.4287 16.0543 16.8621 16.0664 17.3846L16.1737 22.0052C16.1858 22.5277 16.6227 22.9414 17.1495 22.9293C17.6763 22.9171 18.0935 22.4837 18.0814 21.9612L17.9741 17.3406C17.962 16.8181 17.5251 16.4044 16.9983 16.4165Z" fill="currentColor"/>
    </svg>
  );
};

export default RelatorioDashboardIcon;