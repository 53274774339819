import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const IconInfo = (props) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z" fill="currentColor" />

      <path d="M20 10.5C25.523 10.5 30 14.978 30 20.5C30 26.022 25.523 30.5 20 30.5C14.477 30.5 10 26.022 10 20.5C10 14.978 14.477 10.5 20 10.5ZM20.0018 23.5037C19.4503 23.5037 19.0031 23.9508 19.0031 24.5024C19.0031 25.0539 19.4503 25.501 20.0018 25.501C20.5533 25.501 21.0005 25.0539 21.0005 24.5024C21.0005 23.9508 20.5533 23.5037 20.0018 23.5037ZM19.9996 15.5C19.4868 15.5002 19.0643 15.8864 19.0067 16.3837L19 16.5004L19.0018 21.5012L19.0086 21.6179C19.0665 22.1152 19.4893 22.5011 20.0022 22.5009C20.515 22.5007 20.9375 22.1145 20.9951 21.6171L21.0018 21.5005L21 16.4996L20.9932 16.383C20.9353 15.8857 20.5125 15.4998 19.9996 15.5Z" fill="white"/>
    </svg>
  );
};

export default IconInfo;
