import React, { useRef, useEffect, useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';

import { withStyles } from '@material-ui/core';

import Scroll from '../../../components/InfiniteScroll/Scroll';
import MessageItem from './MessageItem';

import chatBackgroundImage from '../../../assets/img/chat/chat-background.png';
import RectLabel from '../../../components/Labels/RectLabel';

import moment from 'moment';

const styles = ({
  messagesListContainer: {
    display: 'flex',
    height: 'calc(100% - 72px)',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundImage: `url(${chatBackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
    flex: 1,
  },
  messageItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateLabel: {
    alignSelf: 'center',
    margin: '16px 0',
  },
  dateLabelText: {
    fontSize: '14px !important',
    fontWeight: 400
  },
  messageList: {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: '16px',
    padding: '24px',
  }
});

const dataLabelCustomStyle = {
  padding: '4px 12px',
  borderRadius: '12px',
}

const MessagesList = observer(({ classes, chatStore, socketStore, selectedContact, isPopup }) => {
  const { isApiInitialized } = socketStore;
  const [isLoading, toggleLoading] = useReducer((state) => !state, false);
  const currentContact = selectedContact || chatStore?.selectedContact;
  const { loggedUser, willScrollToBottom } = chatStore;
  const { messages, paginationOptions } = currentContact;
  const scroll = useRef(null);

  useEffect(() => {
    if (!loggedUser) {
      chatStore.getProfissionalSaudeAtual();
    }
  }, [loggedUser])

  useEffect(() => {
    if (scroll?.current && willScrollToBottom) { 
      scroll.current.scrollTop = scroll.current.scrollHeight;
      chatStore.willScrollToBottom = false;
    }
  }, [messages]);
  
  const loadMessages = async () => {
    const { pageNumber } = paginationOptions.pageableDTO || {};
    toggleLoading();
    if (pageNumber === 0) {
      chatStore.willScrollToBottom = true;
    }

    await chatStore.loadMessagesByContact(currentContact, paginationOptions, messages, isPopup);
    toggleLoading();
  }

  const renderMessageItem = (message, index) => {
    const prevMessage = index + 1 < messages.length ? messages[index + 1] : null;
    const sameDayPrevMessage = prevMessage && moment(prevMessage.dataHoraCriacao).isSame(message.dataHoraCriacao, 'day');
    const messageIsToday = moment(message.dataHoraCriacao).isSame(moment(), 'day');
    const label = messageIsToday ? 'Hoje' : moment(message.dataHoraCriacao).format('DD/MM/YYYY');
  
    return (
      <div className={classes.messageItem} key={message.id}>
        {!sameDayPrevMessage && (
          <div className={classes.dateLabel}>
            <RectLabel 
              withPadding
              classes={{ labelText: classes.dateLabelText }} 
              text={label} 
              color="#808EAB"
              areaStyle={dataLabelCustomStyle}
            />
          </div>
        )}
        <MessageItem message={message} isPopup={isPopup} />
      </div>
    );
  };
  
  const hasMore = !paginationOptions?.lastPage;
  const messagesToRender = messages?.map((message, index) => renderMessageItem(message, index)) || [];
  return (
    <div className={classes.messagesListContainer}>
      {currentContact && isApiInitialized && (
        <Scroll
          initialLoad={true}
          loadMore={loadMessages}
          hasMore={hasMore && !isLoading}
          pageStart={0}
          isLoading={isLoading}
          className={classes.scroll}
          threshold={250}
          isReverse
          rootRef={scroll}
        >
          <div className={classes.messageList}>
            {messagesToRender}
          </div>
        </Scroll>
      )}
    </div>
  );
});

const MessagesListStyles = withStyles(styles)(MessagesList);
const stores = ['chatStore', 'socketStore'];
export default inject(...stores)(MessagesListStyles);
