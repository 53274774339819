import moment from 'moment';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
	Fab,
	Tooltip,
	withStyles,
	Popper,
	CircularProgress,
	ClickAwayListener,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import colors from '../../template/Colors';
import { FiltroDataIcon } from '../../assets/img/svg';
import { findAllDataLancamentoEntradaProntuario } from '../../services/ProntuarioService';
import { observer } from 'mobx-react-lite';

const styles = () => ({
  optionButton: {
    display: 'flex',
  },
  filterButton: {
    width: '40px',
    height: '40px',
    background: colors.commons.yellow,
    color: '#FFFFFF',
    '&:hover': {
      background: '#e0a048',
    },
  },
  popperFilter: {
    marginRight: 12,
  },
  filter: {
    width: 350,
    height: 'calc(100vh - 220px)',
    borderRadius: 16,
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
  },
  headerFilter: {
    background: '#F9BE73',
    borderRadius: '16px 16px 0px 0px',
    height: '100%',
    maxHeight: '80px',
    padding: '16px 24px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentFilter: {
    display: 'flex',
		gap: '8px',
    background: colors.commons.white,
    height: '100%',
    maxHeight: 'calc(100vh - 300px)',
    borderRadius: '0 0 16px 16px',
    overflow: 'auto',
    padding: '0 15px',
    flexDirection: 'column',
  },
  quantidadeEntrada: {
    fontSize: '32px !important',
    color: colors.commons.white,
  },
  irRegistro: {
    fontSize: '15px !important',
    color: colors.commons.white,
  },
  quantidadeClear: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dataItem: {
    borderRadius: '8px',
    display: 'flex',
		flexDirection: 'column',
    cursor: 'pointer',
    padding: '4px',

    "&:hover":{
      background: colors.commons.gray2,
    }
  },
  dataItemSelecionado: {
    background:  colors.commons.gray2,
  },
  closeButton: {
    width: '40px',
    height: '40px',
    background: '#FB7676',
    color: '#FFFFFF',
    '&:hover': {
      background: '#b55959',
    },
  },
  clearButton: {
    color: '#fff',
    cursor: 'pointer',
  },
});

const FiltroData = observer((props) => {
  const { classes, prontuarioStore } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [datasLancamentoEntrada, setDatasLancamentoEntrada] = useState([]);
  const [sujeitoAtencaoSelectedId, setSujeitoAtencaoSelectedId] = useState('');
  const { openFiltroData } = prontuarioStore;

  useEffect(() => {
    openFiltroData &&
      prontuarioStore.sujeitoAtencaoSelected.id !== sujeitoAtencaoSelectedId &&
      listarDatasEntrada();
  }, [openFiltroData]);

  const listarDatasEntrada = async () => {
    const datasEntrada = await findAllDataLancamentoEntradaProntuario(
      prontuarioStore.sujeitoAtencaoSelected.id
    );
    setSujeitoAtencaoSelectedId(prontuarioStore.sujeitoAtencaoSelected.id);
    setDatasLancamentoEntrada(datasEntrada);
  };

  const handlePressButtonFilter = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    prontuarioStore.openFiltroData = !openFiltroData
  };

  const handleClickSelectData = (data) => {
    if(prontuarioStore.dataEntrada === data) return;
    prontuarioStore.dataEntrada = data;
    prontuarioStore.findAllEntradaProntuario(
      { withLoading: false, isDateFilter: true },
      prontuarioStore.sujeitoAtencaoSelected
    );
  };

  const clearFilter = () => {
    prontuarioStore.dataEntrada = null;
    prontuarioStore.findAllEntradaProntuario(
      { withLoading: false },
      prontuarioStore.sujeitoAtencaoSelected
    );
  };

  const handlePressCloseIcon = () => {
    prontuarioStore.openFiltroData=false;
  };

  const closePopper = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    prontuarioStore.openFiltroData = false;
  };

  const renderProfissionais = (item) => {
    const profissionais = item.split(';');
    const profissionaisList = profissionais.map((item) => {
      const splitted = item.split(':');
      return (
        <>
          <strong>{`${splitted[0]}:`}</strong>
          <span> {splitted[1]}</span>
        </>
      );
    });
    return profissionaisList;
  }

  return (
    <>
      <div className={classes.optionButton}>
        <Popper
          open={openFiltroData}
          anchorEl={anchorEl}
          placement={'left-end'}
          className={classes.popperFilter}
        >
          <ClickAwayListener onClickAway={closePopper}>
            <div className={classes.filter}>
              <div className={classes.headerFilter}>
                <span className={classes.irRegistro}> Ir para registro </span>
                <div className={classes.quantidadeClear}>
                  <div className={classes.quantidadeEntrada}>
                    {' '}
                    {`${datasLancamentoEntrada.length} datas`}{' '}
                  </div>
                  {prontuarioStore.loadingMoreMensagens && (
                    <CircularProgress
                      style={{
                        color: '#FFFFFF',
                        width: 25,
                        height: 25,
                      }}
                    />
                  )}
                  <div className={classes.clearButton} onClick={clearFilter}>
                    Limpar
                  </div>
                </div>
              </div>
              <div className={classes.contentFilter}>
                {datasLancamentoEntrada.length > 0 ? (
                  datasLancamentoEntrada.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames(
                          classes.dataItem,
                          item.dataLancamento === prontuarioStore.dataEntrada &&
                            classes.dataItemSelecionado
                        )}
                        onClick={() => handleClickSelectData(item.dataLancamento)}
                        onMouseMove={() => handleClickSelectData(item.dataLancamento)}
                      >
                        {moment(item.dataLancamento, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                        {renderProfissionais(item.profissionais)}
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.notFoundContainer}>
                    Nenhum item encontrado
                  </div>
                )}
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
        {openFiltroData ? (
          <Fab className={classes.closeButton} onClick={handlePressCloseIcon}>
            <CloseIcon />
          </Fab>
        ) : (
          <Fab
            className={classes.filterButton}
            onClick={handlePressButtonFilter}
          >
            <Tooltip title={'Filtro por data'} placement='left'>
              <img src={FiltroDataIcon} alt={'Filtrar por data'} />
            </Tooltip>
          </Fab>
        )}
      </div>
    </>
  );
});

const FiltroDataWithStyles = withStyles(styles)(FiltroData);
export default inject('prontuarioStore')(FiltroDataWithStyles);
