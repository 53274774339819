import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useState, useRef } from "react";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withStyles, Grid } from "@material-ui/core";
import imageCompression from "browser-image-compression";
import styles from "../../../../assets/jss/pages/configuracaoReceitaStyle";
import { InputForm } from "../../../../components/Input/InputDadosCadastraisPaciente";
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import { buildUrlImagemDocumentoTemporario } from "../../../../config/config";
import localStorageService, {
  ACCESS_TOKEN_KEY,
} from "../../../../services/storage";
import { NestorApi } from "../../../../config/api";

const EditarConfigReceita = observer((props) => {
  const { classes, configuracaoImpressaoStore } = props;
  const { configuracaoImpressao, handleChangeInput } = configuracaoImpressaoStore;

  const [showHtmlEdit, setShowHtmlEdit] = useState(false);
  const documentoFileInput = useRef();

  const handleClickEnviarArquivos = () => {
    const fileInput = documentoFileInput && documentoFileInput.current;

    if (!fileInput instanceof HTMLElement) {
      return;
    }
    fileInput.value = "";
    fileInput.click();
  };

  const adicionarNovoArquivo = async (e) => {
    try {
      const image = e.target.files[0];

      const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);

      const responseNestor = await uploadNestor(image);

      if (responseNestor.data) {
        const novaImagem = {
          id: responseNestor.data?.id,
          url: buildUrlImagemDocumentoTemporario(
            responseNestor.data?.id,
            accessToken
          ),
          isNew: true,
        };

        configuracaoImpressaoStore.configuracaoImpressao.imagens = [
          ...configuracaoImpressaoStore.configuracaoImpressao.imagens,
          novaImagem,
        ];

        return novaImagem;
      }
      return null;
    } catch (error) {
      console.error("erro", error);
    }
  };

  const uploadNestor = async (image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(image, options);

    const formData = new FormData();
    formData.append("file", compressedFile);

    return NestorApi.post("/resource/AppHealth", formData);
  };

  return (
    <>
      <Grid xs={10}>
        <div
          className={classes.editarHtml}
          onClick={() => setShowHtmlEdit(!showHtmlEdit)}
        >
          {showHtmlEdit ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          <h3>Editar código do modelo</h3>
        </div>
        {showHtmlEdit && (
          <>
            <div className={classes.documentoFileInput}>
              <input
                ref={documentoFileInput}
                type="file"
                onChange={(e) => adicionarNovoArquivo(e)}
              />

              <ButtonPrimary onClick={() => handleClickEnviarArquivos()}>
                Adicionar imagem
              </ButtonPrimary>
            </div>

            {configuracaoImpressaoStore.configuracaoImpressao.imagens.map(
              (image) => {
                return (
                  <div className={classes.imagemModelo}>
                    <img src={image.url || image} alt={"Imagem do modelo"} />
                    <p>
                      {configuracaoImpressaoStore.tagImagemModelo(
                        image.url || image
                      )}
                    </p>
                  </div>
                );
              }
            )}

            <div className={classes.inputModeloHtml}>
              <span>
                Atenção ao alterar o modelo, algumas mudanças podem fazer com
                que o modelo deixe de funcionar.
              </span>
              <InputForm
                id="observacao"
                value={configuracaoImpressao.modelo}
                rowsMax={20}
                rows={5}
                multiline
                onChange={(e) => handleChangeInput("modelo", e)}
              />
            </div>
          </>
        )}
      </Grid>
    </>
  );
});

const EditarConfigReceitaWithStyles = withStyles(styles)(EditarConfigReceita);
export default inject("configuracaoImpressaoStore", "filtroHeaderStore")(
  EditarConfigReceitaWithStyles
);
