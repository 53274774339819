import { withStyles } from "@material-ui/core";
import React from "react";
import { observer } from "mobx-react-lite";
import PeEsquerdoContent from "./PeEsquerdoContent";
import PeDireitoContent from "./PeDireitoContent";

const grupoCAVR = ["CAVR", "CAVR_TOTAL", "CAVR_PROLONGADA"];

const grupoCAVL = ["CAVL", "CAVL_TOTAL", "CAVL_PROLONGADA"];

const TabDetalhes = observer(({ classes, orcamento, handleFieldModal }) => {
  const { locaisPalmilhaPeDireito, locaisPalmilhaPeEsquerdo } = orcamento || [];

  const handleEspessura = (e, local, index) => {
    const value = e.target.value;

    const locaisCopy = orcamento[local].map((item, idx) =>
      idx === index ? { ...item, espessura: value } : item
    );

    handleFieldModal(local, locaisCopy);
  };

  const handleClickSelect = (value, local, index) => {
    const locaisCopy = orcamento[local].map((item, idx) => {
      if (
        value.codigo.includes("CAVR") &&
        grupoCAVR.some((element) => element === item.codigo)
      ) {
        if (item.checked) {
          return {
            ...item,
            checked: false,
          };
        }
      }

      if (
        value.codigo.includes("CAVL") &&
        grupoCAVL.some((element) => element === item.codigo)
      ) {
        if (item.checked) {
          return {
            ...item,
            checked: false,
          };
        }
      }

      const itemAlterado =
        idx === index
          ? {
              ...item,
              checked: !value.checked,
            }
          : item;

      return itemAlterado;
    });

    handleFieldModal(local, locaisCopy);
  };

  return (
    <div className={classes.container}>
      <PeEsquerdoContent
        locaisPalmilhaPeEsquerdo={locaisPalmilhaPeEsquerdo}
        handleEspessura={handleEspessura}
        orcamento={orcamento}
        handleClickSelect={handleClickSelect}
      />
      <div className={classes.divider} />
      <PeDireitoContent
        locaisPalmilhaPeDireito={locaisPalmilhaPeDireito}
        handleEspessura={handleEspessura}
        orcamento={orcamento}
        handleClickSelect={handleClickSelect}
      />
    </div>
  );
});

const styles = {
  container: {
    display: "flex",
    gap: "8px",
    height: "100%",
  },
  content: {
    flex: 1,
    padding: "16px",
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
  contentLocal: {
    color: "#5F6368",
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: "#5F6368",
    fontWeight: 600,
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    width: "100%",
    display: "flex",
  },
  divider: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
  },
  inputInfos: {
    height: "10px",
    maxWidth: "50px",
  },
};

export default withStyles(styles)(TabDetalhes);
