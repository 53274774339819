import React from 'react'
import TrashIcon from '../../Icon/Trash'
import ArrowDownloadIcon from '../../Icon/ArrowDownload'
import EyeOutlineIcon from '../../Icon/EyeOutline'

export const menuOpcoesPopper = options => {
  const { functions } = options

  const handleCallCurrentFunction = (functionName, ...params) => {
    if (functionName) {
      functionName()
      if (functions?.closePopper && typeof functions?.closePopper === 'function') {
        functions.closePopper()
      }
    }
  }

  const optionsMenu = [
    {
      ativo: true,
      icon: <EyeOutlineIcon size='20' color='#505050' />,
      label: 'Visualizar',
      onClick: () => handleCallCurrentFunction(functions?.handleShowPreview),
    },
    {
      ativo: true,
      icon: <ArrowDownloadIcon size='20' color='#505050' />,
      label: 'Baixar',
      onClick: () => handleCallCurrentFunction(functions?.baixarArquivo),
    },
    {
      ativo: true,
      icon: <TrashIcon size='20' color='#505050' />,
      label: 'Excluir',
      onClick: () => handleCallCurrentFunction(functions?.handleRemoveArquivo),
    },
  ]
  return optionsMenu
}
