import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import {
  Fade,
  Popper,
  MenuItem,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import {
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import Scroll from '../InfiniteScroll/Scroll';
import arrowUpDown from "../../assets/img/svg/arrow-up-down.svg";
import { Button } from "../ui/Buttons";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#F9F9F9",
    color: "#505050",
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
  },
  body: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
  },
}))(TableCell);

const ListaConfiguracoes = (props) =>{
  const{
    classes,
    loadMore,
    lastScroll,
    idConvenioSelecionado,
    handleClickOrdenar,
    lista,
    handleClickRow,
    handleEditar,
    isLoading,
    handleDuplicar,
    handleAtivar,
    handleInativar,
    ordenarTabela,
    showDuplicar,
    showHeaderOrdenarLista,
    handleClickNovo,
  } = props;
  const [openMore, setOpenMore ] = useState(false);
  const [anchorEl, setAnchorEl ] = useState(null);
  const [placement, setPlacement ] = useState(null);
  const [statusMore, setStatusMore] = useState('');

  const handleClickMore = (newPlacement, status, idConvenio) => (event) => {
    setStatusMore(status ? "Ativo" : "Inativo")
    setAnchorEl(event.currentTarget);
    setOpenMore((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    idConvenioSelecionado(idConvenio)
  };

  const handleCloseMore = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setOpenMore(false);
  };

  const renderIconOrder = (field) => (
    ordenarTabela.sortField === field ? (
      ordenarTabela.sortDir === "DESC" ? (
        <ArrowDropDownIcon className={classes.colorIconOrdenacao}/>
        ) : (
        <ArrowDropUpIcon className={classes.colorIconOrdenacao}/>
      )
    ) 
    : 
    <img 
          src={arrowUpDown}
          alt="Arrow drop up down"
          className={classes.colorIconOrdenacao}
    />
  );

  return(
    <Paper className={classes.root}>
      <Scroll
            loadMore={loadMore}
            hasMore={!lastScroll}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainerModelos}
          >
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.rowHeader}>
              <CustomTableCell>
                  NOME
                  <IconButton aria-label="expand row" size="small" className={classes.botaoOrganizar} 
                  onClick={() => handleClickOrdenar(showHeaderOrdenarLista ? showHeaderOrdenarLista : 'nome')}
                  >
                    {renderIconOrder(showHeaderOrdenarLista ? showHeaderOrdenarLista : "nome")}
                  </IconButton>
              </CustomTableCell>
              <CustomTableCell align="center">
                  STATUS
                  <IconButton aria-label="expand row" size="small" className={classes.botaoOrganizar} 
                  onClick={() => handleClickOrdenar("ativo")}
                  >
                    {renderIconOrder("ativo")}
                  </IconButton>    
              </CustomTableCell>
              <CustomTableCell align="center"/>
            </TableRow>
          </TableHead>

          <TableBody>
            {lista.map((item) => 
              <TableRow className={item.ativo ? classes.row : classes.rowInativo} key={item.id} >
                <CustomTableCell 
                  component="th" 
                  scope="row" 
                  align="left" 
                  className={item.ativo ? classes.nameAtivo : classes.nameInativo } 
                  onClick={() => handleClickRow(item.id)}
                > 
                  { item.nome || item.descricao} 
                </CustomTableCell>
                <CustomTableCell 
                  align="center" 
                  className={classes.colunaStatus} 
                  onClick={() => handleClickRow(item.id)}
                > 
                  <div 
                    style={{
                      color: '#fff', 
                      width: 100, 
                      backgroundColor: item.ativo  ? '#26ACA9' : ' rgba(38, 172, 169, 0.5)', borderRadius: 50
                    }}
                  >
                    {item.ativo ? "Ativo" : "Inativo"}
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center"style={{width: 100}}>
                  <IconButton 
                    aria-label="expand row" 
                    size="small" 
                    onClick={handleClickMore('bottom-end', item.ativo, item.id)}
                  >
                    <MoreHorizIcon/>
                  </IconButton>
                </CustomTableCell>
              </TableRow>
            )}
            
            <Popper open={openMore} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.paperBotaoMore}>
                      <ClickAwayListener onClickAway={handleCloseMore}>
                        <MenuItem className={classes.menuMore} 
                        onClick={() => handleEditar()}
                        >
                          <EditIcon className={classes.iconMenuMore}/>
                          Editar
                        </MenuItem>
                        {!showDuplicar && <MenuItem className={classes.menuMore} 
                        onClick={handleDuplicar}
                        >
                          <FileCopyIcon className={classes.iconMenuMore}/>
                          Duplicar
                        </MenuItem>}
                        { statusMore === "Inativo" ?
                          <MenuItem className={classes.menuMore} 
                          onClick={handleAtivar}
                          >
                            <VisibilityIcon className={classes.iconMenuMore}/>
                            Ativar
                          </MenuItem> 
                        :           
                        <MenuItem className={classes.menuMore} 
                        onClick={handleInativar}
                        >
                          <VisibilityOffIcon className={classes.iconMenuMore}/>
                            Desativar
                          </MenuItem>
                        }  
                        </ClickAwayListener>          
                    </Paper>
                  </Fade>
                )}
              </Popper>
          
          </TableBody>
        </Table>
          {isLoading && (
            <div className={classes.notFoundContainer}>
                <CircularProgress/>
            </div>
          )}
        
  </Scroll>
    <Button
      shape='circle'
      bgColor='#26ACA9'
      onClick={handleClickNovo}
      shadow
      className={classes.buttonCriar} 
    >
      <AddIcon/>
    </Button>
    </Paper>
  )
};

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: '100%',
    fontFamily: 'Poppins',
    height: '100%'
  },
  scrollContainerModelos: {
    width: '100%',
    flex: 1,
    height: "calc(100vh - 64px - 64px)",
    overflow: 'auto'
  },
  table: {
    minWidth: 700,
    maxHeight: 700,
  },
  botaoOrganizar: {
    color: 'white',
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  row: {
    maxHeight: 57,
    cursor: 'pointer',
  },
  rowInativo:{
    maxHeight: 57,
    cursor: 'pointer',
    backgroundColor: '#F5F5F5',
  },
  nameAtivo: {
    color: '#505050',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '655px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px',
    },
  },
  nameInativo: {
    color: '#505050',
    opacity: 0.5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '655px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px',
    },
  },
  colunaStatus: {
    textAlign: '-webkit-center',
  },
  menuMore: {
    color: '#505050',
    fontSize: 16,
    width: 140,
  },
  iconMenuMore:{
    marginRight: 10,
  },
  paperBotaoMore: {
    borderRadius: 8,
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    margin: 10,
  },
  rowHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  colorIconOrdenacao: {
    filter: 'brightness(0.4)',
  },
  buttonCriar: {
    position: 'absolute',
    bottom: 88,
    right: 37,
    zIndex: 100
  },
});

export default withStyles(styles)(ListaConfiguracoes);
