import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const AfericaoVitalIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      {...normalizeProps(props)}
    >
      <path
        d="M3.59961 4.30156C3.59961 3.63882 4.13687 3.10156 4.79961 3.10156H10.7996C11.4624 3.10156 11.9996 3.63882 11.9996 4.30156V10.3016C11.9996 10.9643 11.4624 11.5016 10.7996 11.5016H4.79961C4.13687 11.5016 3.59961 10.9643 3.59961 10.3016V4.30156ZM7.18213 5.10066C7.13978 4.98208 7.02789 4.90256 6.90197 4.90157C6.77606 4.90058 6.66292 4.97832 6.61871 5.09623L5.79171 7.30156H5.09961C4.93392 7.30156 4.79961 7.43588 4.79961 7.60156C4.79961 7.76725 4.93392 7.90156 5.09961 7.90156H5.99961C6.12466 7.90156 6.2366 7.82399 6.28051 7.7069L6.89274 6.07429L8.11709 9.50246C8.15751 9.61564 8.26157 9.69377 8.38153 9.70102C8.50149 9.70826 8.61419 9.64322 8.66794 9.53573L9.48502 7.90156H10.4996C10.6653 7.90156 10.7996 7.76725 10.7996 7.60156C10.7996 7.43588 10.6653 7.30156 10.4996 7.30156H9.29961C9.18598 7.30156 9.0821 7.36576 9.03128 7.4674L8.44568 8.6386L7.18213 5.10066Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AfericaoVitalIcon;
