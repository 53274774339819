import React from 'react';

const SaveIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3333 6.66667V2.23958H2.23958V6.66667H13.3333ZM7.65625 16.7708C8.31597 17.4306 9.09722 17.7604 10 17.7604C10.9028 17.7604 11.684 17.4306 12.3438 16.7708C13.0035 16.1111 13.3333 15.3299 13.3333 14.4271C13.3333 13.5243 13.0035 12.7431 12.3438 12.0833C11.684 11.4236 10.9028 11.0938 10 11.0938C9.09722 11.0938 8.31597 11.4236 7.65625 12.0833C6.99653 12.7431 6.66667 13.5243 6.66667 14.4271C6.66667 15.3299 6.99653 16.1111 7.65625 16.7708ZM15.5729 0L20 4.42708V17.7604C20 18.3507 19.7743 18.8715 19.3229 19.3229C18.8715 19.7743 18.3507 20 17.7604 20H2.23958C1.61458 20 1.07639 19.7743 0.625 19.3229C0.208333 18.8715 0 18.3507 0 17.7604V2.23958C0 1.64931 0.208333 1.12847 0.625 0.677083C1.07639 0.225694 1.61458 0 2.23958 0H15.5729Z" fill="#F9F9F9"/>
    </svg>
  );
};

export default SaveIcon;
