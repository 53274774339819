import React from "react";
import classNames from "classnames";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import VideoIcon from "../../Icon/Video";
import LocationIcon from "../../Icon/Location";

const ColumnTipo = ({ classes, event, backgroundColorByStatus }) => {
  const isRemoto = event.remoto;
  const isAgendamento = typeof event.id === "number";

  return (
    <div
      className={classes.dayCalendarColumnTipo}
      style={{ backgroundColor: backgroundColorByStatus }}
    >
      <Tooltip title={isRemoto ? "Telemedicina" : "Presencial"} placement="top">
        <div className={classes.dayCalendarColumnLabel}>
          {isAgendamento && (
            <div
              className={classNames(
                classes.contentIcon,
                isRemoto && classes.backgroundColor
              )}
            >
              {isRemoto ? (
                <VideoIcon size={10} color="#fff" />
              ) : (
                <LocationIcon size={10} color="#fff" />
              )}
            </div>
          )}
          {event?.tipo}
        </div>
      </Tooltip>
    </div>
  );
};

const styles = {
  dayCalendarColumnLabel: {
    color: "#868686!important",
    fontSize: "14px!important",
    fontFamily: "Poppins !important",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "flex",
    gap: "4px",
  },
  dayCalendarColumnTipo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: "110px",
    minHeight: "40px",
    height: "100%",
  },
  dayCalendarColumnLabelAniversario: {
    marginLeft: "4px",
  },
  contentIcon: {
    backgroundColor: "#707C97",
    width: "18px",
    height: "18px",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backgroundColor: {
    backgroundColor: "#9871FC",
  },
};

export default withStyles(styles)(ColumnTipo);
