import React from 'react'

import { withStyles } from "@material-ui/core/styles/index"
import Moment from "moment";
import { inject, observer } from "mobx-react"
import {
    Close as CloseIcon,
    Save as SaveIcon,
    Print as PrintIcon,
} from "@material-ui/icons";
import {
    UnificacaoIcon,
} from "../../../assets/img/svg";
import {
    InputDateForm,
    InputForm,
    SelectForm,
    InputPhoneForm
} from "../../../components/Input/InputDadosCadastraisPaciente";
import MultipleSelect from "../../../components/Select/MultipleSelect";

import Text from "../../../components/Text"

import { ReactSelectCreateAsync, SelectSearch, SelectSearchAsync } from "../../../components/Select/SelectSearchAsync"
import { ReactSelect } from "../../../components/Select/SelectSearch";

import string from "../../../utils/string"

import Checkbox from '../../../components/Checkbox/Checkbox'

import styles from "../../../assets/jss/pages/sujeitoAtencaoStyle"
import { Fab, FormControlLabel } from '@material-ui/core';
import ItemGrid from "../../../components/Modal/ItemGridCard";
import CardDadosCadastrais from '../../../components/Cards/CardDadosCadastrais';
import { consultaDeCep, findByIdSujeitoAtencaoImpressao, liberarAcessoVizi } from "../../../services/SujeitoAtencaoService";
import FichaSujeitoAtencaoPdfDocument from "../../../template/pdf/sujeito-atencao/fichaSujeitoAtencao";
import { getProfissionalSaudeLogado, getUnidadeLogado } from '../../../services/UsuarioService';
import { findSujeitoAtencaoByDocumento } from '../../../services/SujeitoAtencaoService';
import ImpressaoHtml from '../../../components/Impressao/ImpressaoHtml';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import pt from 'react-phone-input-2/lang/pt.json'
import classNames from 'classnames';
import ModalUnificacao from './Unificacao/ModalUnificacao';
import AlertaUnificacao from './Unificacao/AlertaUnificacao';
import { alertasUnificacao } from './Unificacao/AlertasUnificacao';
import { checkUserRole } from '../../../utils/checkUserRole';
import { TextFieldSearch } from '../../../components/TextField';
import { InputDocumentos } from '../../../components/Input/InputDocumentos';
import { InputCEPForm } from '../../../components/Input/InputCEPForm';
import validaDocumentoEspanha from '../../../utils/validacaoDocumentos';
import PageTitle from '../../../components/PageTitle/PageTitle';
import ButtonWhatsApp from '../../../components/Button/ButtonWhatsApp';
import { goToWhatsApp } from '../../../utils/goToWhatsApp';
import SelectCustomIcon from '../../Configuracoes/ModelosDocumento/Anamnese/SelectCustomIcon';
import { StatusAtivadoIcon, StatusInativadoIcon } from "../../../assets/img/svg";
import PortalViziActivationCard from '../../ProfissionalSaude/components/PortalViziActivationCard';
import { beforeActivationText, afterActivationText } from '../constants/constants';
import { InputCPFForm } from '../../../components/Modal/Input';
import CardMultiConvenios from './CardMultiConvenios';
import { Button } from '../../../components/ui/Buttons';
import BeneSerTemplate from '../../../template/cartao/bene-ser';
import { getWhitelabelNome } from '../../../services/WhitelabelService';

const anademPacienteOptions = [{ name: 'Habilitar acesso', value: 'HABILITAR' }, { name: 'Não habilitado', value: 'NAO_HABILITAR' }];

const status = [
    { label: " Ativado", value: true, icon: StatusAtivadoIcon },
    { label: " Inativado", value: false, icon: StatusInativadoIcon },
];

const opcoesPortalPaciente = anademPacienteOptions.map(item => {
    const label = item.value === "HABILITAR" ? " Ativado" : " Inativado";
    const icon = item.value === "HABILITAR" ? StatusAtivadoIcon : StatusInativadoIcon;

    return { label: label, value: item.value, icon: icon };
});

@inject('sujeitoAtencaoStore', 'configuracaoImpressaoStore', 'unidadeStore', 'usuarioStore')
@observer
class InformacoesPrincipais extends React.Component {
    state = {
        typingTimeout: 0,
        printing: false,
        isCreating: false,
        disabled: false,
        isPrintMustache: false,
        openModalUnificar: false,
        openModalAlertaUnificacao: false,
        dadosAlertaUnificacao: {
            title: "",
            text: "",
            textStrong: "",
            icon: null,
            color: "",
            isButtons: true,
        },
        motivoUnificacao: "",
        paisAtuacao: null,
        showDocumentoWarning: false,
        isPrintCartao: false,
        whitelabelNome: null,
    };

    async componentDidMount() {
        const { sujeitoAtencaoStore, usuarioStore } = this.props;

        const [profissionalLogado, paisAtuacao, whitelabelNome] = await Promise.all([
            getProfissionalSaudeLogado(),
            usuarioStore.getPaisAtuacao(),
            getWhitelabelNome(),
            sujeitoAtencaoStore.findConfigUnidade(),
        ]);

        this.scrollToObservacao();
        this.verificaRoleUnificacao();

        this.setState({
            disabled: profissionalLogado?.tipo === 4 || sujeitoAtencaoStore.isSujeitoAtencaoUnificado,
            paisAtuacao,
            whitelabelNome,
        });

        if (!this.props.unidadeStore.unidade.id) {
            await this.props.unidadeStore.checkUsoTelefoneInternacional();
        }
        this.props.sujeitoAtencaoStore.unidadeUtilizaTelefoneInternacional = this.props.unidadeStore.unidade.utilizaTelefoneInternacional;
    }

    handleChange = (value, field) => {
        const { objView, errors } = this.props.sujeitoAtencaoStore;
        const currentField = field === 'telefonePrincipal' ? 'telefone' : field;
        if (errors[currentField]) {
            const validations = {
                telefone: () => { errors.telefone = string.isEmpty(value) },
                email: () => { errors.email = !string.validaEmail(value) },
                nome: () => { errors.nome = value == null || value.length === 0 },
            }

            errors[field] = validations?.[currentField]?.();
        }
        if (currentField === 'documentoResponsavel') {
            errors.documentoResponsavel = !string.validaCPF(value)
        }
        objView[field] = value;
    }

    handleChangeTipoDocumento = (value) => {
        const { sujeitoAtencaoStore } = this.props;

        sujeitoAtencaoStore.objView.documentoTipo = value;
        sujeitoAtencaoStore.objView.documento = null;
    };

    onDocumentoChange = async (e, field) => {
        const { sujeitoAtencaoStore } = this.props;
        let value = e.target.value;

        this.verificaDocumento(value);
        sujeitoAtencaoStore.objView[field] = value;


        if (string.validaCPF(value)) {
            const documentWithoutSpecialChars = string.removeSpecialChars(value);
            const documentAlreadyRegistred = await this.searchRepeatedDocument(documentWithoutSpecialChars);
            this.setState({ showDocumentoWarning: documentAlreadyRegistred });
        }

    };


    searchRepeatedDocument = async (document) => {
        try {
            const response = await findSujeitoAtencaoByDocumento(document);
            const pacientes = response?.data?.data?.findSujeitoAtencaoByDocumento;

            if (pacientes) {
                return pacientes?.length > 0;
            }
            return false;
        } catch (error) {
            console.error(response);
        }
    }

    verificaDocumento = (value) => {
        const { sujeitoAtencaoStore } = this.props;

        let errorDocumento = false;

        if (sujeitoAtencaoStore.objView.documentoTipo) {
            if (sujeitoAtencaoStore.objView.documentoTipo?.descricao === "CPF") {
                errorDocumento = (value == null || string.removeSpecialChars(value).length > 0) &&
                    !string.validaCPF(value);
            } else {
                errorDocumento = !validaDocumentoEspanha(value);
            };
        };

        sujeitoAtencaoStore.errors.documento = errorDocumento;
    };

    loadProfissionaisSaude = (query, callback) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            search: query,
            typing: false,
            typingTimeout: setTimeout(() => {
                this.props.sujeitoAtencaoStore.loadProfissionaisSaude(query, callback);
            }, 500)
        });
    };

    loadTipoLogradouro = (query, callback) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            search: query,
            typing: false,
            typingTimeout: setTimeout(() => {
                this.props.sujeitoAtencaoStore.loadTiposLogradouro(query, callback);
            }, 500)
        });
    };

    loadNaturalidade = (query, callback) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: query,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.props.sujeitoAtencaoStore.loadNaturalidade(query, callback);
            }, 500)
        });
    };

    loadSelectEspanhaMunicipio = async (search, loadedOptions, { page }) => {
        return this.handleLoadMoreOptions({
            search,
            loadedOptions,
            data: { page },
        });
    };

    handleLoadMoreOptions = async ({ search, data }) => {
        const { sujeitoAtencaoStore } = this.props;

        if (data.searchDTO) {
            searchDTO = {
                ...data.searchDTO
            };
        }
        const response = await sujeitoAtencaoStore.findEspanhaMunicipio({
            pageableDTO: {
                pageNumber: data.page,
                pageSize: 20,
                sortDir: "ASC",
                sortField: "descricao"
            },
            descricao: search,
            codigoProvincia: sujeitoAtencaoStore.provincia.value
        });
        return {
            options: response.content,
            hasMore: !response.last,
            additional: {
                page: data.page + 1
            }
        };
    };

    onCEPChange(e) {
        const { sujeitoAtencaoStore } = this.props;
        const { paisAtuacao } = this.state;
        this.props.sujeitoAtencaoStore.objView.cep = e.target.value;
        const cep = e.target.value;
        const cepSemMascara = cep.replace(/\D/g, '');
        if (!paisAtuacao || paisAtuacao?.descricao === 'BRASIL') {
            if (cepSemMascara.length === 8) {
                consultaDeCep(cepSemMascara).then((response) => {
                    const responseConsultaDeCep = response.data.data.consultaDeCep;
                    if (responseConsultaDeCep !== null) {
                        const { logradouro, bairro, municipio } = responseConsultaDeCep;

                        this.props.sujeitoAtencaoStore.objView = {
                            ...this.props.sujeitoAtencaoStore.objView,
                            nomeLogradouro: logradouro,
                            bairro,
                            estado: { value: municipio.estado.id, label: municipio.estado.nome },
                            municipio: { value: municipio.id, label: municipio.nome },
                        }
                        this.props.sujeitoAtencaoStore.contemErros({ revalidate: true })
                    }
                })
            }
        } else {
            sujeitoAtencaoStore.objView.cep = cep;
        }
    };

    onEstadoChange(estado) {
        this.props.sujeitoAtencaoStore.objView.estado = estado;
        this.props.sujeitoAtencaoStore.objView.municipio = 'none';
        this.props.sujeitoAtencaoStore.findMunicipioBy(estado);
    };

    onEspanhaComunidadeAutonomaChange = comunidadeAutonoma => {
        const { sujeitoAtencaoStore } = this.props;

        sujeitoAtencaoStore.comunidadeAutonoma = comunidadeAutonoma;
        sujeitoAtencaoStore.provincia = null;
        sujeitoAtencaoStore.objView.espanhaMunicipio = null;
        sujeitoAtencaoStore.findProvinciaBy(comunidadeAutonoma);
    };

    onEspanhaProvinciaChange = provincia => {
        const { sujeitoAtencaoStore } = this.props;

        sujeitoAtencaoStore.provincia = provincia;
        sujeitoAtencaoStore.objView.espanhaMunicipio = null;
    };

    onEspanhaMunicipioChange = espanhaMunicipio => {
        const { sujeitoAtencaoStore } = this.props;

        sujeitoAtencaoStore.objView.espanhaMunicipio = espanhaMunicipio;
    };


    onTelefonePrincipalComDDIChange(value, data) {
        const { telefoneSecundario } = this.props.sujeitoAtencaoStore.objView;

        this.props.sujeitoAtencaoStore.errors.telefone = string.isEmpty(value) && string.isEmpty(telefoneSecundario);
        this.props.sujeitoAtencaoStore.objView.telefonePrincipal = value;
        this.props.sujeitoAtencaoStore.objView.telefonePrincipalDDI = String(data.dialCode);
    };

    onTelefoneSecundarioComDDIChange(value, data) {
        const { telefonePrincipal } = this.props.sujeitoAtencaoStore.objView;

        this.props.sujeitoAtencaoStore.errors.telefone = string.isEmpty(value) && string.isEmpty(telefonePrincipal);
        this.props.sujeitoAtencaoStore.objView.telefoneSecundario = value;
        this.props.sujeitoAtencaoStore.objView.telefoneSecundarioDDI = String(data.dialCode);
    };

    onDataEntradaBrasilChange(date) {
        if (!date.isValid) {
            if (string.removeSpecialChars(date).length < 8)
                return;
        }

        date = Moment(date, 'DD/MM/YYYY');
        this.props.sujeitoAtencaoStore.objView.dataEntradaBrasil = date;
    };

    onPermiteAcessoAppChange(event, element) {
        if (event.target.checked) {
            this.props.sujeitoAtencaoStore.objView.permiteAcessoApp = [
                ...this.props.sujeitoAtencaoStore.objView.permiteAcessoApp,
                { id: element.value }
            ];
        } else {
            let permiteAcessoAppBuffer = [];
            for (const permiteAcessoApp of this.props.sujeitoAtencaoStore.objView.permiteAcessoApp) {
                if (permiteAcessoApp.id !== element.value) {
                    permiteAcessoAppBuffer.push(permiteAcessoApp);
                }
            }
            this.props.sujeitoAtencaoStore.objView.permiteAcessoApp = permiteAcessoAppBuffer;
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.sujeitoAtencaoStore.closeNotification();
    };

    loadPaises = (query, callback) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: query,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.props.sujeitoAtencaoStore.loadPaises(query, callback);
            }, 500)
        });

    };

    loadOcupacao = (query, callback) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: query,
            typing: false,
            typingTimeout: setTimeout(() => {
                self.props.sujeitoAtencaoStore.loadOcupacao(query, callback);
            }, 500)
        });
    };

    onCreateOcupacao = (ocupacao) => {
        let valueTrim = ocupacao.toString().trim();

        if (valueTrim) {
            this.setState({ isCreating: true })

            this.props.sujeitoAtencaoStore.createOcupacao(ocupacao)
                .then(response => {
                    this.handleChange(response, 'ocupacao');
                })
                .finally(() => {
                    this.setState({ isCreating: false })
                })
        }
    };

    saveSujeitoAtencao = () => {
        this.props.sujeitoAtencaoStore.save();
    }
    resetNotification = () => this.props.sujeitoAtencaoStore.resetNotification();

    cancelar = () => {
        const { idOpened } = this.props.sujeitoAtencaoStore;
        this.props.sujeitoAtencaoStore.removeObjViewOnStorage(idOpened);
        this.props.sujeitoAtencaoStore.reset();
        this.props.sujeitoAtencaoStore.resetVincularViziVariaveis();
        this.props.history.push(`/sujeitos-de-atencao`)
    };

    handlePrint = async () => {
        const { sujeitoAtencaoStore } = this.props;
        let { idOpened } = this.props.sujeitoAtencaoStore;

        sujeitoAtencaoStore.disabledBotaoImpressao();
        const dadosRecuperados = await findByIdSujeitoAtencaoImpressao(idOpened).then(resposta => {
            return resposta.data.data.findByIdSujeitoAtencao;
        });

        this.setState({
            sujeitoAtencaoRecuperado: dadosRecuperados,
        });

        this.handlePrintFicha();
    };

    unificar = () => {
        this.setState({
            dadosAlertaUnificacao: alertasUnificacao.preAviso,
            openModalAlertaUnificacao: true
        });
    };

    handlePrintFicha = async () => {
        const { configuracaoImpressaoStore } = this.props;
        await configuracaoImpressaoStore.getConfig('OUTROS');

        this.setState({ isPrintMustache: true });
    };

    handleCancelarUnificacao = () => {
        this.setState({ openModalAlertaUnificacao: false })
    };

    handleContinuarUnificao = () => {
        const { dadosAlertaUnificacao } = this.state;

        if (dadosAlertaUnificacao.nome === "preAviso") {
            this.setState({
                dadosAlertaUnificacao: alertasUnificacao.motivo,
            });
        } else {
            if (!this.state.motivoUnificacao) {
                this.props.sujeitoAtencaoStore.notification = {
                    isOpen: true,
                    variant: 'error',
                    message: 'Informe o motivo.'
                }
                return;
            };
            this.setState({
                openModalAlertaUnificacao: false,
                openModalUnificar: true,
            });
        }
    };

    handleChangeMotivoUnificacao = e => {
        this.setState({ motivoUnificacao: e.target.value });
    };

    handleCloseUnificar = () => {
        this.setState({
            openModalUnificar: false,
        })
    };
    handleInformacoesAlertaUnificacao = informacoes => {
        this.setState({
            dadosAlertaUnificacao: {
                title: informacoes.title,
                text: informacoes.text,
                textStrong: informacoes.textStrong,
                icon: informacoes.icon,
                color: informacoes.color,
                isButtons: informacoes.isButtons,
            },
            openModalAlertaUnificacao: true,
        });

        if (informacoes.nome === "sucess") {
            this.verificaSujeitoAtencaoUnifcado();
            setTimeout(() => {
                this.setState({ openModalAlertaUnificacao: false });
            }, 1000);

        };
    };

    handleOpenAlertaUnificacao = () => {
        this.setState({
            openModalAlertaUnificacao: true,
        })
    };

    verificaSujeitoAtencaoUnifcado = async () => {
        const { sujeitoAtencaoStore } = this.props;
        const profissionalLogado = await getProfissionalSaudeLogado();

        this.setState({
            disabled: profissionalLogado?.tipo === 4 || sujeitoAtencaoStore.isSujeitoAtencaoUnificado,
        });
    };

    handlePrintMustache = async () => {
        const { sujeitoAtencaoStore } = this.props;
        sujeitoAtencaoStore.habilitaBotaoImpressao();
        this.setState({
            isPrintMustache: false,
        });
    };

    verificaRoleUnificacao = async () => {
        const isRoleUnificacao = await checkUserRole("ROLE_SUJEITO_ATENCAO_UNIFICAR");
        this.setState({ isRoleUnificacao })
    };

    openWhatsApp = (tel, ddi) => {
        let telefone = tel;
        if (tel.indexOf(' ') >= 0) {
            telefone = tel.substring(3);
        }

        goToWhatsApp(telefone, ddi)
    };

    handleLinkPacienteVizi = async () => {
        const { sujeitoAtencaoStore } = this.props;
        const { whitelabelNome } = this.state;
        const response = await liberarAcessoVizi(sujeitoAtencaoStore.objView.id)


        if (response.data?.data?.liberarAcessoVizi?.id) {
            sujeitoAtencaoStore.openNotification(`Acesso ao ${whitelabelNome} liberado com sucesso.`, 'success');
            sujeitoAtencaoStore.objView.paciente = {
                id: response.data.data.liberarAcessoVizi.id
            }
        } else {
            sujeitoAtencaoStore.openNotification(`Falhar ao liberar acesso ao ${whitelabelNome} para o paciente selecionado.`, 'error');
        }
    }

    checkHasMainData = () => {
        const { objView } = this.props.sujeitoAtencaoStore;
        return string.validaEmail(objView.email) || string.validaTelefone(objView.telefonePrincipal) || string.validaCPF(objView.documento);
    }

    scrollToObservacao = () => {
        const hashContainsObservacao = window.location.hash.includes('source=observacao');

        if (hashContainsObservacao) {
            document.getElementById('observacao').scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    handlePrintCardPaciente = async () => {
        const { configuracaoImpressaoStore } = this.props;
        configuracaoImpressaoStore.configuracaoImpressao.retrato = false;
        this.setState({ isPrintCartao: true });
    }

    render() {
        const { classes, sujeitoAtencaoStore } = this.props;
        const { disabled, openModalUnificar, openModalAlertaUnificacao, dadosAlertaUnificacao, paisAtuacao, whitelabelNome } = this.state;
        const { errors, objView, utilizaImpressaoCartao } = this.props.sujeitoAtencaoStore;
        const showPrintCartao = utilizaImpressaoCartao && objView?.nome


        return (
            <div className={classes.tableContainer}>
                <PageTitle title="Paciente - Informações principais" />

                <h3 className={classes.titleHeaderDados}> Dados cadastrais paciente</h3>
                <div className={classes.gridCardContainer}>
                    <div className={classes.cardStatusCadastro}>
                        <CardDadosCadastrais titulo={'Status do cadastro'} >
                            <SelectCustomIcon
                                label=""
                                isSelect
                                onChange={(e) => this.handleChange(e.value, 'ativo')}
                                value={objView.ativo ? status[0] : status[1]}
                                options={status}
                                disabled={disabled}
                                className={classes.fieldSelectIcon}
                            />
                        </CardDadosCadastrais>
                    </div>
                    <div className={classes.cardDadosPessoais}>
                        <CardDadosCadastrais titulo={'Dados pessoais'} >
                            <ItemGrid size={12} label={"Nome:"} className={classes.cardNome}>
                                <InputForm
                                    name={'nome'}
                                    value={objView.nome}
                                    className={classNames(classes.inputDadosCadastraisPaciente, errors.nome ? classes.inputError : null)}
                                    placeholder={'Nome Completo'}
                                    error={errors.nome}
                                    disabled={disabled}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 100), "nome")}
                                />
                            </ItemGrid>

                            <ItemGrid size={12} label={"Nome social:"} >
                                <InputForm
                                    name={'nomeSocial'}
                                    value={objView.nomeSocial}
                                    className={classes.inputDadosCadastraisPaciente}
                                    placeholder={'Nome Social'}
                                    error={errors.nome}
                                    disabled={disabled}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 100), "nomeSocial")}
                                />
                            </ItemGrid>

                            <div className={classes.inputMenor}>
                                <ItemGrid xs={6} md={6} lg={6} xl={6} label={"Sexo:"}>
                                    <SelectForm labelOption={'Sexo'}
                                        name={'sexo'}
                                        disabled={disabled}
                                        value={objView.sexoId}
                                        onChange={({ target: { value } }) => this.handleChange(value, "sexoId")}
                                        className={classes.inputDadosCadastraisPacienteSexo}
                                        elements={sujeitoAtencaoStore.sexos} />
                                </ItemGrid>

                                <ItemGrid xs={6} md={6} lg={3} xl={2} label={'Data nascimento:'}>

                                    <InputDateForm
                                        id='data'
                                        className={classNames(classes.inputDadosCadastraisPacienteNascimento, errors.dataNascimento ? classes.inputError : null)}
                                        disabled={disabled}
                                        value={objView.dataNascimento}
                                        placeholder={'Data Nascimento'}
                                        onChange={date => this.handleChange(date, "dataNascimento")}
                                        iconposition="end"
                                    />
                                </ItemGrid>

                            </div>

                            <ItemGrid xs={12} label={'Estado Civil:'}>
                                <SelectForm labelOption={'Estado Civil'}
                                    className={classes.inputDadosCadastraisPaciente}
                                    disabled={disabled}
                                    name={'estadoCivil'}
                                    value={objView.estadoCivil}
                                    onChange={({ target: { value } }) => this.handleChange(value, 'estadoCivil')}
                                    elements={sujeitoAtencaoStore.estadosCivis} />
                            </ItemGrid>
                            <ItemGrid xs={12} label={'Profissional da saúde:'}>
                                <SelectSearchAsync
                                    className={classes.selectField}
                                    isDisabled={disabled}
                                    placeholder={'Profissional Saúde'}
                                    name={'profissionalSaude'}
                                    value={objView.profissionalSaude}
                                    loadOptions={(query, callback) => this.loadProfissionaisSaude(query, callback)}
                                    onChange={(e) => this.handleChange(e, 'profissionalSaude')} />
                            </ItemGrid>
                        </CardDadosCadastrais>
                    </div>
                    <div className={classes.cardDadosContato}>
                        <CardDadosCadastrais titulo={'Dados de contato:'}>

                            <ItemGrid className={classes.inputWithCheckbox} xs={4} label={'E-mail'}>
                                <InputForm type="text" name="email"
                                    placeholder="E-mail" value={objView.email}
                                    className={classes.inputDadosCadastraisPaciente}
                                    disabled={disabled}
                                    error={errors.email}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 50), 'email')} />

                                <FormControlLabel control=
                                    {
                                        <Checkbox
                                            checked={objView.receberMensagemEmail}
                                            color="primary"
                                            disabled={disabled}
                                            onChange={({ target: { checked } }) => this.handleChange(checked, 'receberMensagemEmail')}
                                            className={classes.checkBoxReceberMsg}
                                        />
                                    }
                                    className={classes.formControlLabelCheckbox}
                                    label="Receber Msg. por E-mail"
                                />
                            </ItemGrid>
                            <ItemGrid className={classes.inputWithCheckbox} xs={4} label={'Telefone principal:'}>
                                {this.props.unidadeStore.unidade.utilizaTelefoneInternacional ?
                                    <div className={classes.rowTelefone}>
                                        <PhoneInput
                                            value={objView.telefonePrincipal}
                                            onChange={(phone, data) => this.onTelefonePrincipalComDDIChange(phone, data)}
                                            placeholder='Telefone Principal'
                                            countryCodeEditable={false}
                                            preferredCountries={['br', 'es']}
                                            localization={pt}
                                            masks={{ br: '(..) .....-....', ar: '. ... ... ....' }}
                                            containerClass={classes.telefoneInputContainer}
                                            inputClass={classNames(classes.telefoneInput, errors.telefone ? classes.telefoneInputError : null)}
                                            disableDropdown={!this.props.unidadeStore.unidade.utilizaTelefoneInternacional}
                                        />
                                        {string.validaTelefone(objView.telefonePrincipal) &&
                                            <ButtonWhatsApp onClick={() => this.openWhatsApp(objView.telefonePrincipal, objView.telefonePrincipalDDI)} />
                                        }
                                    </div>
                                    :
                                    <div className={classes.rowTelefone}>
                                        <InputPhoneForm type="text" name="telefonePrincipal"
                                            placeholder="Telefone Principal"
                                            className={classNames(classes.inputDadosCadastraisPaciente, errors.telefone ? classes.inputError : null)}
                                            disabled={disabled}
                                            value={objView.telefonePrincipal}
                                            error={errors.telefone}
                                            onChange={({ target: { value } }) => this.handleChange(value, 'telefonePrincipal')}
                                        />
                                        {
                                            string.validaTelefone(objView.telefonePrincipal) &&
                                            <ButtonWhatsApp onClick={() => goToWhatsApp(objView.telefonePrincipal)} />
                                        }
                                    </div>
                                }
                                <FormControlLabel control=
                                    {
                                        <Checkbox
                                            checked={objView.receberMensagemWhatsappTelefonePrincipal}
                                            color="primary"
                                            disabled={disabled}
                                            onChange={({ target: { checked } }) => this.handleChange(checked, 'receberMensagemWhatsappTelefonePrincipal')}
                                            className={classes.checkBoxReceberMsg} />
                                    }
                                    className={classes.formControlLabelCheckbox}
                                    label="Receber Msg. por Whatsapp"
                                />

                            </ItemGrid>

                            <ItemGrid className={classes.inputWithCheckbox} xs={4} label={'Telefone secundário:'}>
                                {this.props.unidadeStore.unidade.utilizaTelefoneInternacional ?
                                    <div className={classes.rowTelefone}>
                                        <PhoneInput
                                            value={objView.telefoneSecundario}
                                            onChange={(phone, data) => this.onTelefoneSecundarioComDDIChange(phone, data)}
                                            placeholder='Telefone Secundario'
                                            preferredCountries={['br', 'es']}
                                            localization={pt}
                                            masks={{ br: '(..) .....-....', ar: '. ... ... ....' }}
                                            containerClass={classNames(classes.telefoneInputContainer)}
                                            inputClass={classNames(classes.telefoneInput)}
                                            countryCodeEditable={false}
                                            disableDropdown={!this.props.unidadeStore.unidade.utilizaTelefoneInternacional}
                                        />
                                        {string.validaTelefone(objView.telefoneSecundario) &&
                                            <ButtonWhatsApp onClick={() => this.openWhatsApp(objView.telefoneSecundario, objView.telefoneSecundarioDDI)} />
                                        }
                                    </div>
                                    :
                                    <div className={classes.rowTelefone}>
                                        <InputPhoneForm type="text" name="telefoneSecundario"
                                            className={classes.inputDadosCadastraisPaciente}
                                            placeholder="Telefone Secundário"
                                            disabled={disabled}
                                            error={errors.telefone}
                                            value={objView.telefoneSecundario}
                                            onChange={({ target: { value } }) => this.handleChange(value, 'telefoneSecundario')}
                                        />
                                        {
                                            string.validaTelefone(objView.telefonePrincipal) &&
                                            <ButtonWhatsApp onClick={() => goToWhatsApp(objView.telefoneSecundario)} />
                                        }
                                    </div>
                                }
                                <FormControlLabel control=
                                    {
                                        <Checkbox
                                            checked={objView.receberMensagemWhatsappTelefoneSecundario}
                                            color="primary"
                                            disabled={disabled}
                                            onChange={({ target: { checked } }) => this.handleChange(checked, 'receberMensagemWhatsappTelefoneSecundario')}
                                            className={classes.checkBoxReceberMsg}
                                        />
                                    }
                                    className={classes.formControlLabelCheckbox}
                                    label="Receber Msg. por Whatsapp"
                                />
                            </ItemGrid>
                        </CardDadosCadastrais>
                    </div>
                    <div className={classes.cardDadosCadastrais}>
                        <CardDadosCadastrais titulo={'Dados cadastrais:'}>
                            {paisAtuacao?.descricao === "ESPANHA" && <ItemGrid xs={3} label={'Tipo de documento:'}>
                                <ReactSelect
                                    className={classes.selectSearch}
                                    name="Tipo de documento"
                                    placeholder={"Tipo de documento"}
                                    value={objView.documentoTipo}
                                    onChange={this.handleChangeTipoDocumento}
                                    options={sujeitoAtencaoStore.documentosTipo || []}
                                    isClearable
                                    getOptionLabel={(option) => option.descricao}
                                    getOptionValue={(option) => option.id}
                                />
                            </ItemGrid>}

                            <ItemGrid xs={3} label={'Documento:'}>
                                {
                                    objView.documentoTipo ?
                                        <InputDocumentos
                                            name={'Documento'}
                                            placeholder="Documento"
                                            value={objView.documento}
                                            disabled={disabled}
                                            error={errors.documento}
                                            className={classNames(classes.inputDivMask, errors.documento ? classes.inputError : null)}
                                            classes={{
                                                input: classes.inputMask,
                                                inputError: classes.inputMaskError,
                                            }}
                                            onChange={(e) => this.onDocumentoChange(e, 'documento')}
                                            tipo={objView?.documentoTipo?.descricao}
                                        />
                                        :
                                        <InputForm
                                            name="Documento"
                                            placeholder="Documento"
                                            value={objView.documento}
                                            disabled={disabled}
                                            className={classNames(classes.inputDadosCadastraisPaciente, errors.documento ? classes.inputError : null)}
                                            onChange={(e) => this.onDocumentoChange(e, 'documento')}
                                        />
                                }
                                {this.state.showDocumentoWarning && (
                                    <div className={classes.documentWarning}>
                                        <span className={classes.documentWarningText}>
                                            Existe(m) paciente(s) com o mesmo documento.
                                        </span>
                                    </div>
                                )}
                            </ItemGrid>

                            <ItemGrid xs={3} label={'RG:'}>
                                <InputForm type="text" name="rg"
                                    placeholder="RG" value={objView.rg}
                                    disabled={disabled}
                                    className={classNames(classes.inputDadosCadastraisPaciente, errors.rg ? classes.inputError : null)}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 50), 'rg')} />
                            </ItemGrid>
                            <ItemGrid xs={6} label={'Nacionalidade:'}>
                                <SelectForm labelOption={'Nacionalidade'} name={'nacionalidade'}
                                    value={objView.nacionalidadeId}
                                    disabled={disabled}
                                    onChange={({ target: { value } }) => this.handleChange(value, 'nacionalidadeId')}
                                    className={classes.inputDadosCadastraisPaciente}
                                    elements={sujeitoAtencaoStore.nacionalidades} />
                            </ItemGrid>
                            {objView.nacionalidadeId !== 3 && (<ItemGrid xs={3} label={'Naturalidade:'}>
                                <SelectSearchAsync name={'Naturalidade'}
                                    placeholder="Naturalidade"
                                    value={objView.municipioOrigem}
                                    isDisabled={disabled}
                                    className={classes.selectField}
                                    loadOptions={(query, callback) => this.loadNaturalidade(query, callback)}
                                    elements={sujeitoAtencaoStore.municipiosOrigem}
                                    onChange={(e) => this.handleChange(e, 'municipioOrigem')} />
                            </ItemGrid>)}
                        </CardDadosCadastrais>
                    </div>
                    <div className={classes.cardDadosAdicionais}>
                        <CardDadosCadastrais titulo={'Dados adicionais:'}>
                            <ItemGrid xs={6} label={"Nome do Responsável:"}>
                                <InputForm
                                    type="text"
                                    placeholder="Nome do Responsável"
                                    value={objView.nomeResponsavel}
                                    onChange={({ target: { value } }) => this.handleChange(value, 'nomeResponsavel')}
                                    disabled={disabled}
                                    className={classes.inputDadosCadastraisPaciente}
                                />
                            </ItemGrid>
                            <ItemGrid xs={6} label={"CPF do Responsável:"}>
                                <InputCPFForm
                                    type="text"
                                    placeholder="CPF do Responsável"
                                    value={objView.documentoResponsavel}
                                    onChange={(e) => {
                                        const unmaskCpf = e.target.value.replace(/[^\d]/g, "");
                                        this.handleChange(unmaskCpf, 'documentoResponsavel')
                                    }}
                                    disabled={disabled}
                                    className={classNames(classes.cpfInputContainer, errors.documentoResponsavel ? classes.inputErrorCpf : null)}
                                />
                            </ItemGrid>
                            <ItemGrid xs={6} label={"Nome do cônjuge:"}>
                                <InputForm
                                    type="text"
                                    name="Nome do cônjuge"
                                    placeholder="Nome do cônjuge"
                                    value={objView.nomeConjuge}
                                    disabled={disabled}
                                    className={classes.inputDadosCadastraisPaciente}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 100), "nomeConjuge")}
                                />
                            </ItemGrid>

                            <ItemGrid xs={6} label={'Raça:'}>
                                <SelectForm labelOption={'Raça'} name={'raca'}
                                    className={classes.inputDadosCadastraisPaciente}
                                    value={objView.racaId}
                                    disabled={disabled}
                                    onChange={({ target: { value } }) => this.handleChange(value, 'racaId')}
                                    elements={sujeitoAtencaoStore.racas} />
                            </ItemGrid>

                            <ItemGrid xs={6} label={'Religião:'}>
                                <InputForm type="text" name="religiao"
                                    className={classes.inputDadosCadastraisPaciente}
                                    disabled={disabled}
                                    placeholder="Religião" value={objView.religiao}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 50), 'religiao')} />
                            </ItemGrid>

                            <ItemGrid xs={6} label={'Escolariedade'}>
                                <SelectForm labelOption={'Escolaridade'} name={'escolaridade'}
                                    className={classes.inputDadosCadastraisPaciente}
                                    value={objView.escolaridadeId}
                                    disabled={disabled}
                                    onChange={({ target: { value } }) => this.handleChange(value, 'escolaridadeId')}
                                    elements={sujeitoAtencaoStore.escolaridades} />
                            </ItemGrid>

                            <ItemGrid xs={6} label={'Ocupação:'}>
                                <ReactSelectCreateAsync
                                    placeholder={'Ocupação'}
                                    name={'ocupacao'}
                                    value={objView.ocupacao}
                                    isDisabled={disabled || this.state.isCreating}
                                    isLoading={this.state.isCreating}
                                    onCreateOption={this.onCreateOcupacao}
                                    loadOptions={(query, callback) => this.loadOcupacao(query, callback)}
                                    className={classes.selectField}
                                    formatCreateLabel={valor => "Criar ocupação " + valor + " ?"}
                                    noOptionsMessage={() => "Pesquisar"}
                                    loadingMessage={() => "Carregando..."}
                                    onChange={(e) => this.handleChange(e, 'ocupacao')} />
                            </ItemGrid>

                            <ItemGrid xs={6} label={"Quem indicou:"}>
                                <InputForm
                                    type="text"
                                    name="Quem indicou"
                                    placeholder="Quem indicou"
                                    value={objView.quemIndicou}
                                    disabled={disabled}
                                    className={classes.inputDadosCadastraisPaciente}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 100), 'quemIndicou')}
                                />
                            </ItemGrid>

                            <ItemGrid xs={6} label={'Cartão nacional de saúde:'}>
                                <InputForm type="text" name="cns"
                                    placeholder="CNS" value={objView.cns}
                                    disabled={disabled}
                                    className={classes.inputDadosCadastraisPaciente}
                                    onChange={({ target: { value } }) => {
                                        const cnsNumero = value.replace(/[^\d]/g, "");
                                        this.handleChange(cnsNumero.substring(0, 15), 'cns')
                                    }} />
                            </ItemGrid>


                        </CardDadosCadastrais>
                    </div>
                    {objView.nacionalidadeId === 3 && (
                        <div className={classes.cardDadosEstrangeiros}>
                            <CardDadosCadastrais titulo={'Dados de estrangeiros:'}>
                                <div className={classes.inputMenorEstrangeiro}>
                                    <ItemGrid xs={4} label={'País de origem:'}>
                                        <SelectSearchAsync
                                            className={classes.inputDadosCadastraisPaciente}
                                            placeholder={'País'} name={'pais'}
                                            value={objView.pais}
                                            isDisabled={disabled}
                                            loadOptions={(query, callback) => this.loadPaises(query, callback)}
                                            onChange={(e) => this.handleChange(e, 'pais')} />
                                    </ItemGrid>

                                    <ItemGrid xs={6} label={'Data de entrada no país:'}>
                                        <InputDateForm id='dataEntradaBrasil'
                                            className={classes.inputDadosCadastraisPaciente}
                                            placeholder={'Data Entrada'}
                                            disabled={disabled}
                                            value={objView.dataEntradaBrasil}
                                            onChange={date => this.onDataEntradaBrasilChange(date)}
                                            onInputChange={e => this.onDataEntradaBrasilChange(e.target.value)}
                                            iconposition="end" />
                                    </ItemGrid>
                                </div>
                            </CardDadosCadastrais>
                        </div>
                    )}
                    <div className={classes.cardDadosConvenio}>
                        <CardMultiConvenios disabled={disabled} />
                    </div>
                    {(objView?.id && (objView?.paciente?.id || this.checkHasMainData())) && (
                        <div className={classes.cardViziLink}>
                            <CardDadosCadastrais titulo={`Portal ${whitelabelNome}`}>
                                <PortalViziActivationCard
                                    activated={Boolean(objView.paciente?.id)}
                                    beforeActivationText={beforeActivationText}
                                    afterActivationText={afterActivationText}
                                    userType="sujeitoAtencao"
                                    variant={Boolean(objView.paciente?.id) ? 'viziActivated' : 'viziReadyToActivate'}
                                    onPressActivation={this.handleLinkPacienteVizi}
                                />
                            </CardDadosCadastrais>
                        </div>
                    )}
                    <div className={classes.cardAnadem}>
                        <CardDadosCadastrais titulo={'Portal do paciente:'}>
                            <div className={classes.anadem}>
                                <SelectCustomIcon
                                    label=""
                                    isSelect
                                    onChange={(e) => this.handleChange(e.value, 'anademPaciente')}
                                    value={objView.anademPaciente === "HABILITAR" ? opcoesPortalPaciente[0] : opcoesPortalPaciente[1]}
                                    options={opcoesPortalPaciente}
                                    disabled={disabled}
                                    className={classes.fieldSelectIcon}
                                />
                                {objView.anademPaciente === 'HABILITAR' &&
                                    <ItemGrid xs={12} md={12} lg={12} xl={12} style={{ border: 1 }} className={classes.containerAcesso}>
                                        <Text text={'Selecione as agendas que o usuário poderá ter acesso para agendar consultas'} className={classes.anademProfissionaisLabel} />
                                        <MultipleSelect
                                            name="permiteAcessoApp"
                                            placeholder={'Profissionais'}
                                            disabled={disabled}
                                            permiteAcessoApp={this.props.sujeitoAtencaoStore.objView.permiteAcessoApp}
                                            elements={sujeitoAtencaoStore.profissionaisSaudeComAgenda}
                                            className={classes.multipleAcesso}
                                            onChange={(event, element) => this.onPermiteAcessoAppChange(event, element)}
                                        />
                                    </ItemGrid>
                                }
                            </div>
                        </CardDadosCadastrais>
                    </div>
                    {showPrintCartao && (
                        <div className={classes.cardPrintCard}>
                            <CardDadosCadastrais titulo={'Imprimir cartão:'}>
                                <div className={classes.cardPrintCard}>
                                    <span className={classes.cardPrintCardText}>
                                        Imprima o cartão do paciente para que ele possa ser identificado na unidade de saúde.
                                    </span>
                                    <Button onClick={this.handlePrintCardPaciente} fullWidth style={{ marginTop: 10 }}>
                                        Imprimir cartão
                                    </Button>
                                </div>
                            </CardDadosCadastrais>
                        </div>
                    )}
                    <div className={classes.cardDadosResidenciais}>
                        <CardDadosCadastrais titulo={'Dados residenciais:'}>
                            <div className={classes.inputMenor}>
                                <ItemGrid xs={2} label={paisAtuacao?.descricao === 'ESPANHA' ? 'Código postal' : 'CEP:'}>
                                    <InputCEPForm
                                        type="text"
                                        placeholder={paisAtuacao?.descricao === 'ESPANHA' ? 'Código postal' : "CEP"}
                                        value={objView.cep}
                                        disabled={disabled}
                                        className={classNames(classes.inputDivMask, errors.cep ? classes.inputError : null)}
                                        classes={{
                                            input: classes.inputMask,
                                        }}
                                        onChange={(e) => this.onCEPChange(e)}
                                        pais={paisAtuacao?.descricao}
                                    />
                                </ItemGrid>
                                <ItemGrid xs={2} label={"Tipo logradouro:"}>
                                    <SelectSearchAsync
                                        className={classNames(classes.selectField, errors.tipoLogradouro && classes.inputError)}
                                        isDisabled={disabled}
                                        placeholder={'Selecione'}
                                        value={objView.tipoLogradouro}
                                        loadOptions={(query, callback) => this.loadTipoLogradouro(query, callback)}
                                        onChange={(e) => this.handleChange(e, 'tipoLogradouro')}
                                    />
                                </ItemGrid>
                            </div>

                            <ItemGrid xs={7} label={'Logradouro:'}>
                                <InputForm type="text"
                                    name="nomeLogradouro"
                                    placeholder="Logradouro"
                                    value={objView.nomeLogradouro}
                                    disabled={disabled}
                                    className={classNames(classes.inputDadosCadastraisPaciente, errors.nomeLogradouro ? classes.inputError : null)}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 100), 'nomeLogradouro')} />
                            </ItemGrid>
                            <div className={classes.inputMenor}>
                                <ItemGrid xs={3} label={'Número:'} >
                                    <InputForm type="number"
                                        name="numero"
                                        placeholder="Número"
                                        value={objView.numero}
                                        disabled={disabled}
                                        className={classNames(classes.inputDadosCadastraisPaciente, errors.numero ? classes.inputError : null)}
                                        onChange={({ target: { value } }) => this.handleChange(value.substring(0, 10), 'numero')} />
                                </ItemGrid>


                                <ItemGrid xs={3} label={'Bairro:'}>
                                    <InputForm type="text"
                                        name="bairro"
                                        placeholder="Bairro"
                                        value={objView.bairro}
                                        disabled={disabled}
                                        className={classNames(classes.inputDadosCadastraisPaciente, errors.bairro ? classes.inputError : null)}
                                        onChange={({ target: { value } }) => this.handleChange(value.substring(0, 100), 'bairro')} />
                                </ItemGrid>
                            </div>

                            <ItemGrid xs={12} label={'Complemento:'}>
                                <InputForm type="text"
                                    name="complemento"
                                    placeholder="Complemento"
                                    value={objView.complemento}
                                    disabled={disabled}
                                    className={classes.inputDadosCadastraisPaciente}
                                    onChange={({ target: { value } }) => this.handleChange(value.substring(0, 100), 'complemento')} />
                            </ItemGrid>
                            {
                                paisAtuacao?.descricao === "ESPANHA" ?
                                    <>
                                        <ItemGrid xs={6} label={'Comunidade autonoma:'}>
                                            <SelectSearch
                                                placeholder={'Comunidade autonoma'}
                                                value={sujeitoAtencaoStore.comunidadeAutonoma}
                                                isDisabled={disabled}
                                                onChange={this.onEspanhaComunidadeAutonomaChange}
                                                className={classes.inputDadosCadastraisPaciente}
                                                elements={sujeitoAtencaoStore.espanhaComunidadesAutonoma || []}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={6} label={'Província:'}>
                                            <SelectSearch
                                                placeholder={'Província'}
                                                value={sujeitoAtencaoStore.provincia}
                                                isDisabled={disabled || !sujeitoAtencaoStore.comunidadeAutonoma}
                                                onChange={this.onEspanhaProvinciaChange}
                                                className={classes.inputDadosCadastraisPaciente}
                                                elements={sujeitoAtencaoStore.espanhaProvincias || []}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={6} label={'Cidade:'}>
                                            <TextFieldSearch
                                                placeholder="Cidade"
                                                classNotched={classes.notchedOutline}
                                                classInput={classes.inputTextField}
                                                classIcons={classes.classIcons}
                                                loadOptions={this.loadSelectEspanhaMunicipio}
                                                withPaginate
                                                value={objView.espanhaMunicipio}
                                                onChange={this.onEspanhaMunicipioChange}
                                                debounceTimeout={300}
                                                additional={{
                                                    page: 0
                                                }}
                                                key={sujeitoAtencaoStore.provincia?.value}
                                                isDisabled={disabled || !sujeitoAtencaoStore.provincia}
                                            />
                                        </ItemGrid>
                                    </>
                                    :
                                    <>
                                        <ItemGrid xs={6} md={6} lg={6} xl={6} label={'Estado:'}>
                                            <SelectSearch
                                                className={classNames(classes.selectField, errors.estado ? classes.inputError : null)}
                                                name="estado"
                                                placeholder={'Estado'}
                                                value={objView.estado}
                                                isDisabled={disabled}
                                                elements={sujeitoAtencaoStore.estados}
                                                onChange={(e) => this.onEstadoChange(e)} />
                                        </ItemGrid>
                                        <ItemGrid xs={6} md={6} lg={6} xl={6} label={'Cidade:'} >
                                            <SelectSearch
                                                className={classNames(classes.selectField, errors.municipio ? classes.inputError : null)}
                                                name="municipio"
                                                placeholder={'Cidade'}
                                                value={objView.municipio}
                                                isDisabled={disabled}
                                                elements={sujeitoAtencaoStore.municipios}
                                                onChange={(e) => this.handleChange(e, 'municipio')} />
                                        </ItemGrid>
                                    </>
                            }

                        </CardDadosCadastrais>
                    </div>
                    <div className={classes.cardFiliacao}>
                        <CardDadosCadastrais titulo={'Filiação:'}>

                            <ItemGrid xs={6} label={'Nome da mãe:'}>
                                <InputForm type="text" name="nomeMae"
                                    className={classes.inputDadosCadastraisPaciente}
                                    disabled={disabled}
                                    placeholder="Nome da Mãe" value={objView.nomeMae}
                                    onChange={({ target: { value } }) => this.handleChange(value, 'nomeMae')} />
                            </ItemGrid>

                            <ItemGrid xs={6} label={'Nome do pai:'}>
                                <InputForm type="text" name="nomePai"
                                    className={classes.inputDadosCadastraisPaciente}
                                    disabled={disabled}
                                    placeholder="Nome do Pai" value={objView.nomePai}
                                    onChange={({ target: { value } }) => this.handleChange(value, 'nomePai')} />
                            </ItemGrid>
                        </CardDadosCadastrais>
                    </div>
                    <div className={classes.cardObservaoes}>
                        <CardDadosCadastrais titulo={'Observações:'}>

                            <ItemGrid xs={12}>
                                <InputForm
                                    id="observacao"
                                    value={objView.observacao}
                                    className={classes.inputDadosCadastraisPacienteObservacao}
                                    rowsMax={20}
                                    rows={5}
                                    disabled={disabled}
                                    multiline
                                    onChange={({ target: { value } }) => this.handleChange(value, 'observacao')}
                                />
                            </ItemGrid>
                        </CardDadosCadastrais>
                    </div>
                </div>
                <div className={classes.acoes}>
                    {!disabled && (
                        <>
                            <Fab
                                className={classNames(classes.buttonAcoes, classes.buttonSalvar)}
                                onClick={this.saveSujeitoAtencao}
                                disabled={sujeitoAtencaoStore.loadingSave}
                            >
                                <SaveIcon />
                            </Fab>
                            <Fab
                                className={classNames(classes.buttonAcoes, classes.buttonCancelar)}
                                onClick={this.cancelar}
                            >
                                <CloseIcon />
                            </Fab>
                        </>
                    )}

                    {sujeitoAtencaoStore.idOpened && (
                        <Fab
                            className={classNames(classes.buttonAcoes, classes.buttonYellow)}
                            onClick={this.handlePrint}
                            disabled={sujeitoAtencaoStore.loadingPrint || !objView.id}
                        >
                            <PrintIcon />
                        </Fab>
                    )}
                    {(objView.id && !sujeitoAtencaoStore.isSujeitoAtencaoUnificado) && this.state.isRoleUnificacao &&
                        <Fab
                            className={classNames(classes.buttonAcoes, classes.buttonYellow)}
                            onClick={this.unificar}
                        >
                            <img src={UnificacaoIcon} alt="Unificação" />
                        </Fab>
                    }

                </div>
                {this.state.isPrintMustache &&
                    <ImpressaoHtml
                        isPrintMustache={this.state.isPrintMustache}
                        dadosSujeitoAtencao={this.state.sujeitoAtencaoRecuperado}
                        handlePrintMustache={this.handlePrintMustache}
                        htmlStringComponent={<FichaSujeitoAtencaoPdfDocument
                            sujeitoAtencao={this.state.sujeitoAtencaoRecuperado}
                        />}
                        habilitaBotaoImpressao={sujeitoAtencaoStore.habilitaBotaoImpressao()}
                    />}
                {
                    openModalUnificar &&
                    <ModalUnificacao
                        open={openModalUnificar}
                        handleClose={this.handleCloseUnificar}
                        handleInformacoesAlertaUnificacao={this.handleInformacoesAlertaUnificacao}
                        motivo={this.state.motivoUnificacao}
                    />
                }
                {
                    openModalAlertaUnificacao &&
                    <AlertaUnificacao
                        open={openModalAlertaUnificacao}
                        title={dadosAlertaUnificacao.title}
                        text={dadosAlertaUnificacao.text}
                        textStrong={dadosAlertaUnificacao.textStrong}
                        icon={dadosAlertaUnificacao.icon}
                        color={dadosAlertaUnificacao.color}
                        isButtons={dadosAlertaUnificacao.isButtons}
                        handleCancelar={this.handleCancelarUnificacao}
                        handleContinuar={this.handleContinuarUnificao}
                        onChangeMotivo={this.handleChangeMotivoUnificacao}
                        valueInput={this.state.motivoUnificacao}
                    />
                }

                {this.state.isPrintCartao && (
                    <ImpressaoHtml
                        isPrintMustache={this.state.isPrintCartao}
                        dadosSujeitoAtencao={this.state.sujeitoAtencaoRecuperado}
                        handlePrintMustache={() => this.setState({ isPrintCartao: false })}
                        htmlStringComponent={<BeneSerTemplate sujeitoAtencao={sujeitoAtencaoStore.objView} />}
                    />
                )}
            </div>

        )

    }
}

export default withStyles(styles)(InformacoesPrincipais)
