import React from 'react'
import { withStyles } from '@material-ui/core'

const STATUS = {
  NAO_FATURADO: {
    color: '#F7F3F1',
    text: 'Pendente',
  },
  FATURADO: {
    color: '#E1F3F4',
    text: 'Faturado',
  },
}

const FinanceiroBadge = ({ classes, status }) => {
  if (!status) return null
  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: STATUS[status]?.color,
      }}
    >
      <span className={classes.text}>{STATUS[status]?.text}</span>
    </div>
  )
}

const styles = {
  container: {
    width: 'fit-content',
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '8px',
  },
  text: {
    color: '#505050',
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: '0 8px',
  },
}

export default withStyles(styles)(FinanceiroBadge)
