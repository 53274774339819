import React from "react";
import { Switch } from "@material-ui/core";
import string from "../../../utils/string";
import AddIcon from "../../../components/Icon/AddIcon";
import { Button } from "../../../components/ui/Buttons";
import EditIcon from "../../../components/Icon/EditIcon";
import DeleteIcon from "../../../components/Icon/DeleteIcon";

const tableStatusStyle = {
  width: "50px",
  padding: "4px 16px",
};

export const columns = ({ functions }) => [
  {
    Header: "",
    style: tableStatusStyle,
    isClick: true,
    getValue: (row) => {
      const { id, ativo } = row;
      return row.categoriaFilho ? (
        "item"
      ) : (
        <Switch
          onChange={() => functions.handleClickStatus({ id, ativo })}
          checked={ativo}
          color="primary"
        />
      );
    },
  },
  {
    Header: "Nome",
    field: "nome",
    getValue: (row) => {
      return row.nome;
    },
  },
  {
    Header: "tipo",
    getValue: (row) => {
      return row.tipo;
    },
  },
  {
    Header: "",
    isClick: true,
    getValue: (row) => {
      const rowStyle = {
        display: "flex",
        alignItems: "center",
        gap: "4px",
      };

      const rowStyleCategoriaFilho = {
        display: "flex",
        gap: "8px",
      };

      return row.categoriaFilho ? (
        <div style={rowStyleCategoriaFilho}>
          <Button
            onClick={() => functions.handleEdit(row)}
            shape="circle"
            bgColor="#707C97"
            style={{ width: '24px', height: '24px' }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={() => functions.handleDelete(row)}
            shape="circle"
            bgColor="#FB7676"
            style={{ width: '24px', height: '24px' }}
          >
            <DeleteIcon size={14} />
          </Button>
        </div>
      ) : (
        <div
          onClick={() => functions.handleAdicionarFilho(row)}
          style={rowStyle}
        >
          <AddIcon size={10} /> Adicionar {string.capitalize(row.tipo)}
        </div>
      );
    },
  },
];

export const tabs = {
  INFORMACOES: 0,
  AUDITORIA: 1,
};
