import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import NoEncryptionOutlinedIcon from '@material-ui/icons/NoEncryptionOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from "@material-ui/icons/Print";

import ActionButton from './ActionButton';
import ActionSwitchButton from './ActionSwitchButton';
import SaveIcon from '../../Icon/Save';
import { Tooltip } from '@material-ui/core';

const colors = {
  menu: '#515E79',
  active: '#707C97',
  deactivated: '#C6CEDF',
  print: '#FBBF6D',
  yellowLight: '#FEF2E3',
}

const styles = () => ({
  container: {
    position: 'absolute',
    bottom: 24,
    right: 37,
    zIndex: 4,
    display: 'grid',
    rowGap: '22px',
  },
  filterButtons: {
    display: 'grid',
    width: 'fit-content',
    rowGap: '22px',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    columnGap: '8px',
  }
});

const ActionsButtons = (props) => {
  const { 
    classes, 
    userType, 
    addButtonAction,
    printButtonAction, 
    saveConfigMultiAgenda,
    disabledSave,
    hideBlocked, 
    hideEmpty, 
    onClickBlock, 
    onClickEmpty,
  } = props
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className={classes.container}>
      {openDrawer && (
        <div className={classes.filterButtons}> 
          <ActionSwitchButton 
            enableIcon={NoEncryptionOutlinedIcon} 
            disableIcon={HttpsOutlinedIcon} 
            buttonEnabled={hideBlocked} 
            enableColor={colors.active} 
            disableColor={colors.deactivated} 
            enableText={'Ocultar bloqueados'} 
            disableText={'Exibir bloqueados'}
            onClick={onClickBlock}
          />
          <ActionSwitchButton
            enableIcon={VisibilityOffOutlinedIcon} 
            disableIcon={VisibilityOutlinedIcon} 
            buttonEnabled={hideEmpty} 
            enableColor={colors.active} 
            disableColor={colors.deactivated}
            enableText={'Ocultar vazios'} 
            disableText={'Exibir vazios'}
            onClick={onClickEmpty}
          /> 
        </div>
      )}
      <div className={classes.actionButtons}>
        {
          saveConfigMultiAgenda && 
          <Tooltip title="Salvar configurações" placement="top">
            <ActionButton
              onClick={saveConfigMultiAgenda}
              color={colors.print}
              icon={SaveIcon}
              disabled={disabledSave}
            />
          </Tooltip>
        }
        {printButtonAction && <ActionButton
          onClick={printButtonAction}
          color={colors.print}
          icon={PrintIcon}
        />}
        <ActionButton 
          onClick={() => setOpenDrawer(!openDrawer)}
          color={colors.menu}
          icon={MenuIcon}
          />
          {userType !== 'tecnico' && (
            <ActionButton 
              onClick={addButtonAction}
              icon={AddIcon}
            />
          )}
      </div>
    </div>
  )

};

export default withStyles(styles)(ActionsButtons);
