import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ArrowUpAndDown = props => {
  return (
    <svg 
      width="10" 
      height="15" 
      viewBox="0 0 10 15" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M0.747129 5.84662C1.07663 6.17613 1.61087 6.17613 1.94037 5.84662L5 2.78699L8.05963 5.84662C8.38913 6.17613 8.92337 6.17613 9.25287 5.84662C9.58238 5.51712 9.58238 4.98288 9.25287 4.65338L5.59662 0.997129C5.26712 0.667624 4.73288 0.667624 4.40338 0.997129L0.747129 4.65338C0.417624 4.98288 0.417624 5.51712 0.747129 5.84662ZM0.747129 9.15338C1.07663 8.82387 1.61087 8.82387 1.94037 9.15338L5 12.213L8.05963 9.15338C8.38913 8.82387 8.92337 8.82387 9.25287 9.15338C9.58238 9.48288 9.58238 10.0171 9.25287 10.3466L5.59662 14.0029C5.26712 14.3324 4.73288 14.3324 4.40338 14.0029L0.747129 10.3466C0.417624 10.0171 0.417624 9.48288 0.747129 9.15338Z" fill="currentColor"/>
    </svg>
  );
};

export default ArrowUpAndDown;