import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import FormLabel from "./FormLabel";
import string from "../../../utils/string";

const FormGroup = props => {
  const {
    label,
    value = "",
    maxLength,
    type,
    style,
    rows = 1,
    showRowNumber = false,
    startRowNumber = 1
  } = props;
  const chars = [];
  const rowsArray = [];
  const currentValue = typeof value === 'string' ? string.truncate(value, maxLength, "") : string.truncate(value?.toString(), maxLength, "");
  const currentMaxLength = maxLength || currentValue.length;
  const remaingCharLength = currentMaxLength - currentValue.length;

  for (let i = startRowNumber; i <= rows + startRowNumber - 1; i++) {
    rowsArray.push({
      id: i
    });
  }

  for (let i = 0; i < currentValue.length; i++) {
    chars.push({
      text: currentValue[i],
      last: remaingCharLength > 0 ? false : currentValue.length === i + 1
    });
  }

  if (remaingCharLength > 0) {
    for (let i = 0; i < remaingCharLength; i++) {
      chars.push({
        text: "",
        last: remaingCharLength === i + 1
      });
    }
  }

  return (
    <View style={[styles.formGroup, style]}>
      {label && <FormLabel label={label} />}
      {rowsArray.map(row => (
        <View
          key={row.id}
          style={[
            styles.chardFieldGroup,
            rowsArray.length > 1 ? styles.chardFieldGroupRows : null
          ]}
        >
          {showRowNumber && (
            <View>
              <Text style={styles.numberRowText}>{`${row.id} - `}</Text>
            </View>
          )}
          {type !== "textarea" ? 
          
          chars.map((char, index) => {
            let last = char.last;
            if (type === "date" && (index === 1 || index === 4)) {
              last = true;
            }

            if (type === "date" && (index === 2 || index === 5)) {
              return <Separator key={index} separator="/" />;
            }

            if (type === "currency" && index === chars.length - 4) {
              last = true;
            }

            if (type === "currency" && index === chars.length - 3) {
              return (
                <Separator key={index} separator="," style={styles.separatorCommaView} />
              );
            }

            if (type === "time" && index === chars.length - 4) {
              last = true;
            }

            if (type === "time" && index === chars.length - 3) {
              return (
                <Separator key={index} separator=":" style={styles.separatorCommaView} />
              );
            }
            return (
              <CharFieldText key={index} char={char} last={last} {...props} />
            );
          })
          :
          <Text style={styles.observacao}> {value} </Text>
        }
        </View>
      ))}
    </View>
  );
};

const CharFieldText = ({ char, last, onlyBorderBottom, noBorder }) => (
  <View
    style={[
      styles.charFieldTextContainer,
      last ? styles.charFieldTextContainerLast : null,
      onlyBorderBottom ? styles.charFieldTextContainerOnlyBorderBottom : null,
      noBorder ?  styles.charFieldTextContainerNoBorder : null
    ]}
  >
    <Text style={styles.charFieldText}>{char.text}</Text>
  </View>
);

const Separator = ({ separator, style }) => (
  <View style={[styles.separatorView, style]}>
    <Text style={styles.separatorText}>{separator}</Text>
  </View>
);

const styles = StyleSheet.create({
  formGroup: {
    position: "relative",
    borderColor: "#707070",
    borderWidth: 0.5,
    marginLeft: 2,
    marginRight: 2,
    padding: 2,
    paddingRight: 3
  },
  chardFieldGroup: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  chardFieldGroupRows: {
    marginTop: 2
  },
  charFieldTextContainer: {
    position: "relative",
    borderLeftWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: "#707070",
    width: 9,
    height: 6,
    justifyContent: "center",
    alignItems: "center"
  },
  charFieldTextContainerLast: {
    borderRightWidth: 0.5
  },
  charFieldTextContainerNoBorder: {
    border: 'none'
  },
  charFieldTextContainerOnlyBorderBottom: {
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  charFieldText: {
    position: "absolute",
    left: 0,
    right: -1,
    bottom: 1,
    fontSize: 7,
    fontFamily: "Lato-Regular",
    textAlign: "center"
  },
  observacao:{
    position: "absolute",
    left: 0,
    right: -1,
    bottom: 1,
    top: 0,
    fontSize: 7,
    fontFamily: "Lato-Regular",
    textAlign: "left",
  },
  numberRowText: {
    fontSize: 5,
    fontFamily: "Lato-Bold"
  },
  separatorView: {
    width: 4,
    justifyContent: "center",
    alignItems: "center"
  },
  separatorCommaView: {
    width: 2,
    marginLeft: 1
  },
  separatorText: {
    fontSize: 5,
    fontFamily: "Lato-Bold"
  },
});

export default FormGroup;
