import React from 'react'
import TableCellMaterial from '@material-ui/core/TableCell';
import {withStyles} from '@material-ui/core/styles';
import Colors from '../../template/Colors';
import classNames from 'classnames';

const style = theme => ({
    root: {
        color: Colors.commons.gray9,
        fontFamily: "Poppins",
    },

    rootOnlyIcon: {
        padding: '0 16px',
        width: '25px',
    },
    
    rootPadding: {
        padding: '0 0 0 16px',
    },

    head: {
        textTransform: 'uppercase',
        fontWeight: 600,
        background: Colors.commons.grayLight,
        maxHeight: '55px',

    },

    tableCenter: {
        textAlign: 'center',
        maxHeight: '52px',
        
    }
});


function TableCell(props) {
    const {classes, onlyIcon, paddingLeft, ...other} = props;
    const bodyStyle = other.center ? classes.tableCenter : '';

    return <TableCellMaterial
        classes={{
            root: classNames(classes.root, onlyIcon && classes.rootOnlyIcon, paddingLeft && classes.rootPadding),
            head: classes.head,
            body: bodyStyle
        }}
        style={{textAlign: props.align || 'center'}}
        {...other}
    />
}

export default withStyles(style)(TableCell);
