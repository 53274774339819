import Colors from "../../../template/Colors";

const styles = () => ({
  paperWidthMd: {
    width: "356px",
    height: "291px",
    padding: "32px",
  },

  header: {
    fontWeight: 600,
    fontSize: "18px",
    color: Colors.commons.gray9,
    marginBottom: 16,
  },

  buttonCancel: {
    color: Colors.commons.gray9,
    cursor: "pointer",
    height: 20,
    fontWeight: 700,
    width: "100%",
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  },

  titleField: {
    color: Colors.commons.gray7,
    fontSize: 12,
  },

  field: {
    margin: "4px 0 16px",
    width: "calc(100% - 14px)",
  },

  fieldError: {
    marginBottom: 0,
  },

  content: {
    height: "100%",
  },
});

export default styles;
