import React from 'react';
import { withStyles } from '@material-ui/core';
import Table from '../../../components/Table/Table';
import Scroll from '../../../components/InfiniteScroll/Scroll';
import { getAgeendamentosTableHeader } from './utils/getAgendamentosTableHeader';

const styles = ({
  tableContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  centerInTable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    height: '100%',
    marginTop: '2rem'
  },
  emptyMessageTexts: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    textAlign: 'center',
  },
  normalText: {
    color: '#5F6368',
    fontSize: '18px !important',
  },
  actionText: {
    color: '#219A97',
    fontSize: '18px !important',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const AgendamentosTable = ({ 
    handleClearFilter, 
    agendamentos, 
    onCheckAgendamento, 
    agendamentoIsChecked, 
    classes, 
    isLoading, 
    toggleAllCheckboxes,
    toggleAllCheckboxesLastValue,
    loadAgendamentos,
    filter,
    setFilter,
    hasMore
  }) => {
  
  const clearFilter = () => {
    handleClearFilter();
  };

  const EmptyTableMessage = (
    <div className={classes.centerInTable}>
      {!isLoading  &&
        <div className={classes.emptyMessageTexts}>
          <span className={classes.normalText}>Nenhum agendamento encontrado</span>
          <span className={classes.actionText} onClick={clearFilter}>Limpar filtro</span>
        </div>
      }
    </div>
  );

  const handleClickOrdenar = async (value) => {
    const sortDir = filter.sortField !== value ? 
      "ASC" :
      (filter.sortDir === "ASC" ? "DESC" : "ASC");
    
    let updatedFilter = {
      ...filter,
      sortDir,
      sortField: value,
      pageNumber: 0
    }

    setFilter(updatedFilter)
    loadAgendamentos(updatedFilter);
  }

  const columns = getAgeendamentosTableHeader(
    onCheckAgendamento, 
    agendamentoIsChecked, 
    toggleAllCheckboxes, 
    toggleAllCheckboxesLastValue
  );

  return (
    <Scroll
      className={classes.tableContainer}
      loadMore={() => loadAgendamentos(null)}
      pageStart={0}
      initialLoad={false}
      hasMore={hasMore && !isLoading}
      isLoading={isLoading}
    >
      <Table
        dados={agendamentos}
        columns={columns}
        comOrdenacao
        showEmpytDataMessage
        isLoading={isLoading && filter.pageNumber === 0}
        emptyMessage={EmptyTableMessage}
        ordenarTabela={{
          sortField: filter.sortField,
          sortDir: filter.sortDir
        }}
        handleClickOrdenar={handleClickOrdenar}
      />
    </Scroll>
  );
};

export default  withStyles(styles)(AgendamentosTable);

