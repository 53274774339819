import { observer } from "mobx-react-lite";
import { Checkbox, ClickAwayListener, Paper, Popper } from "@material-ui/core";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { menuOpcoesPopperLocal } from "../Constants";
import MoreIcon from "../../../../../components/Icon/More";
import PopperCustomMenu from "../../../../../components/Popper/PopperCustomMenu";

const PopperDentesSupranumerario = observer(
  (
    { placement, denteSupranumerario, odontogramaStore, sujeitoAtencaoId },
    ref
  ) => {

    const { locaisSelecionados } = odontogramaStore;

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [dentes, setDentes] = useState(denteSupranumerario);
    
    const dadosMenuOpcoes = (item) => ({
      isDente: true,
      ativo: item.status === "ATIVO",
      functions: {
        openModalProcedimento: (e) =>
          openModalProcedimento(e, item),
        openModalObservacao: (e) =>
          openModalObservacao(e, item),
        openModalHistorico: (e) =>
          openModalHistorico(e, item),
        handleAlteraStatusDente: (e) =>
          handleAlteraStatusDente(e, item),
      },
    })

    useEffect(() => {
      if (locaisSelecionados.length === 0) {
        const dentesNaoSelecionado = denteSupranumerario.map((item) => {
          return {
            ...item,
            selecionado: false,
          };
        });
        setDentes(dentesNaoSelecionado);
      }
    }, [locaisSelecionados]);

    const popperRef = useRef(null);

    const handleOpenPopper = (event) => {
      const { currentTarget } = event;
      setAnchorEl(currentTarget);
      setOpen(!open);
    };

    const handleClosePopper = (event) => {
      if (anchorEl.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    useImperativeHandle(ref, () => {
      return {
        handleClosePopper,
        handleOpenPopper,
      };
    });

    const handleChangeCheckbox = (dente, posicao) => {
      const listaDentesSupranumerario = dentes;
      let listaLocaisSelecionados = locaisSelecionados;

      listaDentesSupranumerario[posicao] = {
        ...listaDentesSupranumerario[posicao],
        selecionado: !dente.selecionado,
      };
      setDentes(listaDentesSupranumerario);

      if(dente.selecionado) {
        const lista = listaLocaisSelecionados.filter(item => item.id !== dente.id);
        listaLocaisSelecionados = lista

      } else {
        listaLocaisSelecionados.push({
          ...dente,
          local: 4,
          selecionado: !dente.selecionado,
        })
      }
      odontogramaStore.locaisSelecionados = listaLocaisSelecionados;
    };

    const openModalObservacao = (e, dente) => {
      odontogramaStore.modalObservacao.showModalObservacao = true;
      odontogramaStore.locaisSelecionados = [
        {
          ...dente,
          local: 4,
        },
      ];
      return popperRef?.current?.closePopper(e);
    };

    const openModalProcedimento = (e, dente) => {
      odontogramaStore.modalProcedimento.showModalProcedimento = true;
      odontogramaStore.locaisSelecionados = [
        {
          ...dente,
          local: 4,
        },
      ];
      return popperRef?.current?.closePopper(e);
    };

    const openModalHistorico = (e, dente) => {
      odontogramaStore.showModalHistorico = true;
      odontogramaStore.locaisSelecionados = [
        {
          ...dente,
          local: 4,
        },
      ];
      return popperRef?.current?.closePopper(e);
    };

    const handleAlteraStatusDente = async (e, dente) => {
      const dentes = [
        {
          id: dente.id,
          status: dente.status === "ATIVO" ? "INATIVO" : "ATIVO"
        }
      ]
      await odontogramaStore.alterarStatusDente(dentes);

      odontogramaStore.getOdontograma(sujeitoAtencaoId);
      odontogramaStore.openNotification(
        "Status do dente alterado com sucesso!",
        "success"
      );

      return popperRef?.current?.closePopper(e);
    };

    const isDenteSelecionado = () => {
      const listaDentes = [...locaisSelecionados, ...dentes];
      return listaDentes.some((item) => item.selecionado);
    };

    return (
      <div ref={ref}>
        <Popper open={open} anchorEl={anchorEl} placement={placement}>
          <ClickAwayListener onClickAway={handleClosePopper}>
            <Paper>
              {dentes.map((item, index) => {
                return (
                  <div style={styles.contentRow} key={item.id}>
                    <Checkbox
                      key={`${item.id}-${item.selecionado}`}
                      color="primary"
                      onChange={() => handleChangeCheckbox(item, index)}
                      checked={item.selecionado}
                    />
                    <span style={styles.labelDente}>
                      Hiperdontia - {item.codigo}
                    </span>
                    {!isDenteSelecionado() && (
                      <PopperCustomMenu
                        placement={"bottom-end"}
                        ref={popperRef}
                        iconButton={<MoreIcon color="#505050" />}
                        colorButton={{
                          background: "#fff",
                        }}
                        menuOpcoes={menuOpcoesPopperLocal(dadosMenuOpcoes(item))}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  },
  { forwardRef: true }
);

const styles = {
  contentRow: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    padding: "4px 0px 4px 8px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    width: "200px",
    height: "48px",
  },
  labelDente: {
    color: "#868686",
    fontSize: "14px",
    fontWeight: 600,
  },
};

export default PopperDentesSupranumerario;
