import React from "react";
import styled from "styled-components";
import { components } from "react-select";

const Option = props => {
  const { data } = props;
  
  if (data.__isNew__) {
    return (
      <components.Option {...props}>
        <Title>
          <Subtitle>Adicionar</Subtitle>
          <ProductName>{` "${data.value}"`}</ProductName>
        </Title>
      </components.Option>
    )
  }
  
  const { produto, subtitulo, principioAtivo } = data;
  return (
    <components.Option {...props}>
      <Title>
        <ProductName>{produto}</ProductName>
        {subtitulo && <Subtitle>, {subtitulo}</Subtitle>}
      </Title>
      <Description>{principioAtivo}</Description>
    </components.Option>
  );
};

const Title = styled.p`
  margin: 0;
`;
const ProductName = styled.span`
  font-weight: bold;
`;
const Subtitle = styled.span`
    
`;

const Description = styled.span`
    display: block;
    font-size: 11px !important;
    white-space: nowrap;
    text-overflow: ellipsis;                   
    overflow: hidden;
`;

export default Option;
