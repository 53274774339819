import { gridContainer } from "../appHealth.style"

const styles = theme => ({
  root: {
    ...gridContainer,
    overflow: "hidden",
  },

  rootExtrato: {
    display: 'grid',
    gridTemplateColumns: `240px 1fr`,
    gridTemplateRows: '1fr 64px',
    gridTemplateAreas: ` 
            "sidebar-left content" 
            "footer footer"
            `,
    flex: 1,
    overflow: "hidden",
  },
  
  panelLeft: {
    padding: 0,
    boxShadow: '0px 10px 15px rgb(0 0 0 / 3%), 0px 7px 25px rgb(38 172 169 / 4%), 0px 5px 25px rgb(38 172 169 / 4%)',
  },
  
  titleHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: "#219A97"
  },
  
  footer: {
    gridArea: "footer",
    paddingLeft: 15,
    paddingRight: 160,
    height: '100%'
  },
  
  unidadeContainer: {
    display: 'grid',
    gridArea: 'footer',
    width: '100%',
    gridTemplateColumns: '270px 1fr'
  },
  footerUnidade:{
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
}
});

export default styles;
