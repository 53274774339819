import React from "react";
import {
  PieChart as PieChartDefault,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip
} from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "middle" : "middle"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChart = ({
  ResponsiveContainerProps,
  PieChartProps,
  PieProps,
  TooltipProps,
  data
}) => (
  <ResponsiveContainer width="100%" height={160} {...ResponsiveContainerProps}>
    <PieChartDefault {...PieChartProps}>
      <Tooltip {...TooltipProps} />
      <Pie
        data={data}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={70}
        {...PieProps}
      >
        {data.map((entry, index) => (
          <Cell key={index} fill={entry.color} />
        ))}
      </Pie>
    </PieChartDefault>
  </ResponsiveContainer>
);

export default PieChart;
