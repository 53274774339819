import React from "react";
import Dialog from "../../components/Dialog/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import colors from "../../template/Colors";
import ButtonLong from "../../components/Button/ButtonLong";

const ConfirmEditRecorrencia = (props) => {
  const {
    classes,
    open,
    onClose,
    handleAgendamentoSelecionado,
    handleTodosAgendamentos,
    isExcluir,
  } = props;

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper,
      }}
      maxWidth="sm"
      fullWidth
    >
      <div className={classes.header}>
        <Fab
          className={[classes.fab, classes.closeIconProcedimentos]}
          onClick={onClose}
        >
          <CloseIcon style={{ fontSize: 20 }} className={classes.icon} />
        </Fab>
      </div>
      <div className={classes.text}>
        <div>Este agendamento pertence a uma recorrência.</div>
        <div>Gostaria de {isExcluir ? 'excluir' : 'editar'}:</div>
      </div>
      <div className={classes.buttons}>
        <ButtonLong
          colorCustom="transparency"
          onClick={handleAgendamentoSelecionado}
          className={classes.marginRight}
        >
          Apenas este agendamento
        </ButtonLong>
        <ButtonLong colorCustom="green" onClick={handleTodosAgendamentos}>
          Todos
        </ButtonLong>
      </div>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: 8,
    padding: 20,
    width: '450px',
  },
  header: {
    display: "flex",
    justifyContent: "end",
  },
  buttonClose: {
    width: "24px",
    height: "24px",
    minHeight: "24px",
    color: "#fff",
    marginLeft: "8px",
    boxShadow:
      "4px 4px 15px rgba(38, 172, 169, 0.05), 2px 2px 10px rgba(38, 172, 169, 0.1), 1px 1px 50px rgba(38, 172, 169, 0.15);",
  },
  closeIconProcedimentos: {
    background: colors.error.main,
    width: "32px",
    height: "32px",
    "&:hover": {
      background: colors.error.dark,
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    marginBottom: '16px',
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
  marginRight: {
    marginRight: 16,
  }
};

export default withStyles(styles)(ConfirmEditRecorrencia);
