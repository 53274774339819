import React from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core";
import ButtonLong from "../../../components/Button/ButtonLong";
import VisibilityIcon from "../../../components/Icon/VisibilityIcon";
import PopperCustomMenu from "../../../components/Popper/PopperCustomMenu";
import { menuOpcoes } from "./utils";
import MoreIcon from "../../../components/Icon/MoreIcon";

const ButtonAcaoDescartados = ({
  classes,
  solicitacoesPacientesStore,
  solicitacao,
}) => {
  const isTipoAnamnese = solicitacao.arquivoTipo === "ANAMNESE";

  const menuConfig = {
    isTipoAnamnese,
    solicitacao,
    solicitacoesPacientesStore, 
    showBaixar: true
  }   

  return (
    <div className={classes.widthButton}>
      <PopperCustomMenu 
        placement={"bottom-end"} 
        menuOpcoes={menuOpcoes(menuConfig)}
        iconButton={<MoreIcon color="#fff" />}
      />
      {solicitacao.arquivoTipo !== "ARQUIVO" && (
        <ButtonLong
          className={classes.buttonAcao}
          colorCustom="green"
          onClick={() =>
            solicitacoesPacientesStore.handleVisualizar(solicitacao)
          }
          noShadow
        >
          <VisibilityIcon />
          Visualizar
        </ButtonLong>
      )}
    </div>
  );
};

const styles = {
  buttonAcao: {
    height: "40px",
    "& span": {
      display: "flex",
      gap: "8px",
    },
  },
  widthButton: {
    display: "flex",
    gap: "8px",
    maxWidth: "180px",
    alignItems: "center",

    "& #buttonCircle": {
      minWidth: "40px",
    },
  },
};

const ButtonAcaoDescartadosWithStyles = withStyles(styles)(
  ButtonAcaoDescartados
);
export default inject("solicitacoesPacientesStore")(
  ButtonAcaoDescartadosWithStyles
);
