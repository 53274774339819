import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import InputSelectComLabel from "../../../../../../components/Input/InputSelectComLabel";
import {
  InputDateForm,
  InputTimeForm,
} from "../../../../../../components/Modal/Input";
import classNames from "classnames";
import { TextFieldSearch } from "../../../../../../components/TextField";
import styles from "../../GuiaServicosFaturamentoStyle";
import InputMaskCurrency from "../../../../../../components/Input/InputMaskCurrency";
import { applyCurrencyMask } from "../../../../../../utils/CurrencyMask";

const DadosExecucao = (props) => {
  const {
    classes,
    handleChangeItens,
    itemExecucao,
    handleChangeProcedimento,
    handleChangeValorUnitario,
    handleChangeQuantidade,
    loadProcedimento,
    loadVia,
    loadTec,
    handleChangeHorario
  } = props;

  return (
    <>
      <div className={classes.row}>
        <Grid item xs={4}>
          <Typography className={classes.typography} component="label">
            Data*:
          </Typography>
          <InputDateForm
            id="data-consulta"
            iconposition="end"
            className={classes.dateTime}
            classes={{
              date: classes.inputColor,
            }}
            value={itemExecucao?.data || null}
            onChange={(e) => handleChangeItens(e, "data")}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.typography} component="label">
            Hora inicial*:
          </Typography>
          <InputTimeForm
            value={
              itemExecucao?.horaInicial
                ? moment(itemExecucao?.horaInicial, "HH:mm").toDate()
                : null
            }
            classes={{
              date: classNames(classes.inputMargens, classes.inputColor),
              icon: classes.iconTime,
            }}
            iconposition="end"
            className={classes.dateTime}
            onChange={(e) => handleChangeHorario(e, "horaInicial")}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.typography} component="label">
            Hora final*:
          </Typography>
          <InputTimeForm
            value={
              itemExecucao?.horaFinal
                ? moment(itemExecucao?.horaFinal, "HH:mm").toDate()
                : null
            }
            classes={{
              date: classNames(classes.horaSemMargem, classes.inputColor),
              icon: classes.iconTime,
            }}
            iconposition="end"
            className={classes.dateTime}
            onChange={(e) => handleChangeHorario(e, "horaFinal")}
          />
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid item xs={3}>
          <InputSelectComLabel
            label="Tabela*:"
            classes={{
              input: classes.inputDisabled,
            }}
            value={itemExecucao.tabela}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <InputSelectComLabel
            label="Código do procedimento*:"
            value={itemExecucao?.codigoProcedimento?.codigoTermo || ""}
            disabled
            classes={{
              input: classes.inputDisabled,
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.typography} component="label">
            Descrição*:
          </Typography>
          <TextFieldSearch
            placeholder=""
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            classes={{paper: classes.menuHeight}}
            loadOptions={loadProcedimento}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={itemExecucao?.descricao}
            onChange={handleChangeProcedimento}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid item xs={6}>
          <Typography className={classes.typography} component="label">
            Via:
          </Typography>
          <TextFieldSearch
            placeholder=""
            classNotched={classes.notchedOutline}
            classInput={classNames(classes.inputTextField, classes.marginRight)}
            classIcons={classes.classIcons}
            loadOptions={loadVia}
            getOptionLabel={(option) => option.termo}
            getOptionValue={(option) => option.id}
            value={itemExecucao?.tuss61TerminologiaViaAcesso}
            onChange={(e) =>
              handleChangeItens(e, "tuss61TerminologiaViaAcesso")
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typography} component="label">
            Tec:
          </Typography>
          <TextFieldSearch
            placeholder=""
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            loadOptions={loadTec}
            getOptionLabel={(option) => option.termo}
            getOptionValue={(option) => option.id}
            value={itemExecucao?.tuss48TerminologiaTecnicaUtilizada}
            onChange={(e) =>
              handleChangeItens(e, "tuss48TerminologiaTecnicaUtilizada")
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid item xs={4}>
          <InputSelectComLabel
            label="Quantidade*:"
            value={itemExecucao.quantidade}
            type="number"
            onChange={handleChangeQuantidade}
          />
        </Grid>
        <Grid item xs={4} className={classes.marginRight}>
          <Typography className={classes.typography} component="label">
            Valor unitário*:
          </Typography>
          <InputMaskCurrency
            value={String(itemExecucao?.valorUnitario).includes('R$') ? itemExecucao?.valorUnitario : applyCurrencyMask(itemExecucao?.valorUnitario)}
            onChange={handleChangeValorUnitario}
            className={classes.inputValor}
            classes={{
              input: classes.inputValorUnitario
            }}
            noMargin
          />
        </Grid>
        <Grid item xs={4}>
          <InputSelectComLabel
            label="Valor total:"
            value={applyCurrencyMask(itemExecucao.valorTotal || 0)}
            className={classes.inputValor}
            noMargin
            disabled
          />
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(DadosExecucao);
