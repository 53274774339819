import React from "react";
import { InputAdornment, Fab, withStyles, Typography, IconButton } from "@material-ui/core";
import { Print, Send, FileCopy as DuplicateIcon, ChevronLeft, ChevronRight, OpenInNew, GetApp as DownloadIcon } from "@material-ui/icons";
import InputSearch from "../../../components/Input/Input";
import styles from "../../../assets/jss/pages/prontuarioPreviewDocumentoStyle";
import arquivoIcon from "../../../assets/img/arquivo-icon.png";
import Button from "@material-ui/core/Button";
import { isIOS } from "../../../utils/OS";
import DocumentPreview from "../../../template/pdf/documento/Documento";
import printPage from "../../../utils/printPage";
import { inject, observer } from "mobx-react";
import TokenDialog from "../../../components/Dialog/TokenDialog";
import InputTokenDialog from "../../../components/Dialog/InputTokenDialog";
import { buildUrlDownload } from "../../../config/config";
import localStorageService, { ACCESS_TOKEN_KEY } from "../../../services/storage";
import base64ToBlob from "../../../utils/base64ToBlob";
import PageTitle from "../../../components/PageTitle/PageTitle";
import CloseIcon from "../../../components/Icon/CloseIcon";

import { ZoomWrapper } from "../../../components/ZoomWrapper";
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import ModalInativarEntradaProntuario from '../../../components/Modal/ModalInativarEntradaProntuario';

@inject("configuracaoImpressaoStore", "profissionalSaudeStore", "receituarioStore", "documentoStore", "prontuarioStore")
@observer
class PreviewDocumento extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			originalImageWidth: null,
			originalImageHeight: null,
			imageWidth: null,
			imageHeight: null,
			showImage: false,
			printing: false,
			loadingButtonImpressao: false,
			ready: false,
			showTokenDialog: false,
			showInoputTokenDialog: false,
			tokenDialogActionClicked: false,
			signToken: "",
			tokenError: false,
			pdfNumPages: null,
			pdfCurrentPage: null,
			fileSelecionado: null,
			assinado: false,
			openInativarModal: false,
			motivoInativacao: '',
		};

		this.imageContainerRef = React.createRef();
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	async componentDidMount() {
		const { profissionalSaudeStore, prontuarioStore, preview } = this.props;

		await profissionalSaudeStore.carregaProfissionalSaude();
		await prontuarioStore.checkUseDigitalSignature();
		const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
		prontuarioStore.accessToken = accessToken;

		await this.props.configuracaoImpressaoStore.getConfig('DOCUMENTO');
    
		this.setState({
			...this.state,
			ready: true,
			fileSelecionado: preview.files ? preview.files[0] : preview,
			ativado: preview?.ativado
		})
	}

	componentDidUpdate(prevProps) {
		const { preview } = this.props;

		if (preview !== prevProps.preview) {
			preview.open && preview.files.length > 0 && this.setState({ fileSelecionado: preview.files[0] });
		}

		
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize() {
		const { originalImageWidth, originalImageHeight } = this.state;
		this.updateImageHeight(originalImageWidth, originalImageHeight);
	}

	handleChangeDescricao = (e) => {
		const { onChangeDescricao } = this.props;
		if (typeof onChangeDescricao === 'function') {
			onChangeDescricao(e.target.value);
		}
	}

	handleEnterDescricao = (e) => {
		if (e.key === 'Enter' && e.shiftKey && e.ctrlKey) {
			e.preventDefault();
			const { onSend } = this.props;
			if (typeof onSend === 'function') {
				onSend();
			}
		}
	}

	handleImageLoad = (e) => {
		const { width, height } = e.target;
		this.updateImageHeight(width, height);
	}

	updateImageHeight = (originalWidth, originalHeight) => {
		const imageContainer = this.imageContainerRef && this.imageContainerRef.current;

		let ratio = 0;  // Used for aspect ratio
		const maxWidth = imageContainer?.clientWidth - 30; // Max width for the image
		const maxHeight = imageContainer?.clientHeight - 30;    // Max height for the image
		const width = originalWidth;    // Current image width
		const height = originalHeight;  // Current image height

		if (width > maxWidth && width > height) {
			ratio = width / height;
			const newHeight = maxWidth / ratio;

			this.setState({
				imageWidth: newHeight > maxHeight ? null : maxWidth,
				imageHeight: newHeight > maxHeight ? maxHeight : newHeight,
				originalImageWidth: originalWidth,
				originalImageHeight: originalHeight,
				showImage: true,
			});
			return;
		}

		if (height > maxHeight && height > width) {
			ratio = height / width;

			this.setState({
				imageWidth: maxHeight / ratio,
				imageHeight: maxHeight,
				originalImageWidth: originalWidth,
				originalImageHeight: originalHeight,
				showImage: true,
			});
			return;
		}

		this.setState({
			imageWidth: null,
			imageHeight: maxHeight,
			originalImageWidth: originalWidth,
			originalImageHeight: originalHeight,
			showImage: true,
		});
	};

	handlePrint = () => {
		this.setState({ tokenDialogActionClicked: true });
		if (isIOS) {
			this.newWindowRef = window.open('', 'print_documento');

			if (this.newWindowRef) {
				const loadingContent = `<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
                <h1 style="font-family: -apple-system;">Aguarde...</h1>
                </div>`;
				this.newWindowRef.document.open();
				this.newWindowRef.document.write(loadingContent);
				this.newWindowRef.document.close();
			}
		}

		this.renderPrintPdf();
		this.setState({ loadingButtonImpressao: true });
	};

	openInputTokenDialog = async () => {
		const { receituarioStore } = this.props;
		this.setState({ tokenDialogActionClicked: true });
		const possuiToken = await receituarioStore.possuiTokenAssinaturaValido();
		this.setState({
			showInputTokenDialog: !possuiToken,
			showTokenDialog: false
		});

		possuiToken && this.handleDownloadDocumentSigned();
	};

	handleTokenChange = (event) => {
		const { value } = event.target
		this.setState({ signToken: value });
	};

	handleCancelInputToken = () => {
		this.setState({
			showInputTokenDialog: false,
			tokenError: false,
			signToken: ''
		})
	};

	handleDownloadDocumentSigned = async () => {
		const { content, documentoStore, receituarioStore, prontuarioStore } = this.props;

		this.setState({ printing: true, tokenDialogActionClicked: false });
		try {
			const token = await localStorageService.get(ACCESS_TOKEN_KEY);
			const possuiToken = await receituarioStore.possuiTokenAssinaturaValido();
			if (content?.assinado) {
				const printData = buildUrlDownload(content.entradaProntuario.urlDownload, token);
				window.open(printData, "_blank");

				this.setState({
					showInputTokenDialog: false,
					showTokenDialog: false,
					printing: false
				})
			} else {
				const documentVariables = possuiToken ? 
					{
						id: prontuarioStore.previewDocumento.content.id
					} :
					{
						id: prontuarioStore.previewDocumento.content.id,
						tokenAplicativo: this.state.signToken.replace(' ', '')
					}

				const response = await documentoStore.imprimirAssinadaDocumento(documentVariables);
				const printData = buildUrlDownload(response.entradaProntuario.urlDownload, token);
				window.open(printData, "_blank");
				this.setState({
					showInputTokenDialog: false,
					showTokenDialog: false,
					printing: false
				})
			}
		} catch(error) {
			prontuarioStore.notification = {
				isOpen: true,
				variant: 'error',
				message: "Erro ao imprimir o documento."
			};
			this.setState({
				showInputTokenDialog: false,
				showTokenDialog: false,
				printing: false
			})
		}
	}

	openTokenDialog = () => {
		this.setState({ showTokenDialog: true })
	}

	handleClickPrint = () => {
		const { prontuarioStore, profissionalSaudeStore } = this.props;	
		const {utilizaAssinaturaDigital} = profissionalSaudeStore.profissionalSaude;
		const {assinaturaDigitalObrigatoria} = prontuarioStore;
		const openSignQuestionDialog = assinaturaDigitalObrigatoria || utilizaAssinaturaDigital;
		if (openSignQuestionDialog) {
			this.openTokenDialog();
		} else {
			this.handlePrint();
		}
	}

	renderPrintPdf = async () => {
		const { documentoStore, prontuarioStore } = this.props;
		const documentoBase64 = await documentoStore.imprimirDocumento({ id: prontuarioStore.previewDocumento.content.id });
		printPage(base64ToBlob(documentoBase64))

		this.setState({
			showTokenDialog: false,
			showInputTokenDialog: false,
			tokenDialogActionClicked: false,
		})
	};

	handlePdfPageChange = (currentPage, numPages) => {
		this.setState({
			pdfNumPages: numPages,
			pdfCurrentPage: currentPage
		});
	};

	alteraFile = (altera) => {
		const { preview } = this.props;
		const { fileSelecionado } = this.state;

		let posicao
		preview.files.forEach((file, index) => {
			if (file.id === fileSelecionado.id) {
				posicao = index
			}
		});

		this.setState({ fileSelecionado: altera === "proximo" ? preview.files[posicao + 1] : preview.files[posicao - 1] });
	};

	openNewPage = () => {
		window.open(this.state.fileSelecionado.imageUrl, "_blank")
	};

	handleCloseTokenDialog = () => {
		this.setState({ showTokenDialog: false, tokenDialogActionClicked: false });
	}

	handleInativar = async () => {
		const { prontuarioStore } = this.props;
		const { fileSelecionado, motivoInativacao } = this.state;
		const { id: fileSelecionadoId } = fileSelecionado || {};

		try {
			await prontuarioStore.inativarEntradaProntuario(fileSelecionadoId, motivoInativacao);
			this.setState({ openInativarModal: false, motivoInativacao: '' });
			await prontuarioStore.updateFileList(fileSelecionadoId);
			prontuarioStore.openNotification("Sucesso ao inativar entrada de prontuário", "success");
			await prontuarioStore.findAllEntradaProntuario({ withLoading: false });
		} catch (error) {
			console.error(error);
			prontuarioStore.openNotification("Erro ao inativar entrada de prontuário", "error");
		}
	}

	render() {
		const { 
			classes, 
			descricao, 
			onClose, 
			onSend, 
			documentoStore, 
			duplicarDocumento, 
			preview, 
			excluirArquivo, 
			screen,  
			prontuarioStore 
		} = this.props;
		const { 
			imageWidth, 
			imageHeight, 
			showImage, 
			ready, 
			pdfCurrentPage, 
			pdfNumPages, 
			fileSelecionado, 
			printing, 
			tokenDialogActionClicked,
			openInativarModal,
			motivoInativacao
		} = this.state;

		const showDeleteButton = ((preview.files && preview.files.length > 1 && !preview?.id) ||
			(fileSelecionado?.id && fileSelecionado?.isImage && fileSelecionado?.ativo));

		return (
			<>
				<PageTitle title={`${screen} - Pré-visualizar documento`} />
				{fileSelecionado && <div className={classes.container}>
					<div className={classes.header}>
						<div>
							<p className={classes.headerTitle}>Pré-visualizar</p>
						</div>
						{pdfCurrentPage && pdfNumPages &&
							<div className={classes.pageIndexesContainer}>
								{`${pdfCurrentPage} / ${pdfNumPages}`}
							</div>
						}
						<div>
							{showDeleteButton && (
									<>
										<Button
											onClick={() => {
												preview?.id ? 
													this.setState({ openInativarModal: true }) : 
													excluirArquivo(fileSelecionado);
											}}
										>
											<DeleteIcon color='white' size={24} />
										</Button>
									</>
								)
							}
							{fileSelecionado && fileSelecionado.previewOnly && (
									<Button
										onClick={this.openNewPage}
									>
										<OpenInNew className={classes.headerButtonIcon} />
									</Button>
								)
							}
							{preview?.content?.assinado &&
								(
									<Button>
										<a 
											href={buildUrlDownload(preview?.content?.entradaProntuario?.urlDownload, prontuarioStore.accessToken)}
											download={`documento_${this.state.entrada?.id}`}
											className={classes.downloadAssinadoLink}
											target="_blank" 
											rel="noreferrer"
										>
											<DownloadIcon
												className={classes.headerButtonIcon}
											/>
										</a>
									</Button>
								)
							}
							{!preview.isImage && (
								<>
									<Button
										className={'no-print'} onClick={duplicarDocumento}
									>
										<DuplicateIcon
											className={classes.headerButtonIcon}
										/>
									</Button>
									<Button 
										className={'no-print'} onClick={this.handleClickPrint}>
										<Print
											className={classes.headerButtonIcon}
										/>
									</Button>
								</>
							)}
							<Button onClick={onClose}>
								<CloseIcon size={22} color="white" />
							</Button>
						</div>
					</div>
					<div ref={this.imageContainerRef} 
					className={!fileSelecionado.isImage && fileSelecionado.content ? classes.printContainer : classes.imageContainer}
					
							
					>
						{preview.files && fileSelecionado !== preview.files[0] ?
							<IconButton
								className={classes.buttonLeftRight}
								onClick={() => this.alteraFile('anterior')}
							>
								<ChevronLeft />
							</IconButton>
							: <div className={classes.hiddenButtonLeftRight} />}
						{!fileSelecionado.isImage && !fileSelecionado.content && <img src={arquivoIcon}
							alt=""
							className={classes.documentIcon}
						/>}
						

						{fileSelecionado.isImage && 
						<ZoomWrapper>
								<img src={fileSelecionado.imageUrl}
									alt="preview"
									id="preview-image"
									className={classes.image}
									style={{
										width: imageWidth,
										maxHeight: imageHeight,
										visibility: showImage ? 'visible' : 'hidden',
										flex: 1,
										objectFit: 'contain',
									}}
									onLoad={this.handleImageLoad}
								/>
						</ZoomWrapper>
						}
						
						
						{!fileSelecionado.isImage && ready && fileSelecionado.content && (
							<ZoomWrapper>
							<DocumentPreview
								modeloDocumento={fileSelecionado.content}
								documentoStore={documentoStore}
								config={this.props.configuracaoImpressaoStore.configuracaoImpressao}
								onPageChange={this.handlePdfPageChange}
							/>
							</ZoomWrapper>
						)}
						
						{preview.files && fileSelecionado !== preview.files[preview.files.length - 1] ?
							<IconButton
								className={classes.buttonLeftRight}
								onClick={() => this.alteraFile('proximo')}
							>
								<ChevronRight />
							</IconButton>
							: <div className={classes.hiddenButtonLeftRight} />}
					</div>
					{preview.previewOnly === false && <form onSubmit={(e) => {
						e.preventDefault();
						onSend();
					}} className={classes.form}>
						<InputSearch
							id="preview-documento-input-descricao"
							classInputRoot={classes.inputMessageSujeitoAtencaoRoot}
							classInput={classes.inputMessageSujeito}
							onChange={this.handleChangeDescricao}
							value={descricao}
							multiline
							onKeyDown={this.handleEnterDescricao}
							startAdornment={<div className={classes.inputAdornmentStart} />}
							endAdornment={
								<InputAdornment position="end">
									<Fab
										type="submit"
										className={classes.buttonSendInput}
									>
										<Send color={"primary"} className={classes.buttonSendIcon} />
									</Fab>
								</InputAdornment>
							}
							placeholder="Adicione uma descrição para imagem"
						/>
					</form>}
					{preview.previewOnly === true && <Typography component="p">{descricao}</Typography>}
					<TokenDialog
						open={this.state.showTokenDialog}
						handleClose={this.handleCloseTokenDialog}
						onCancel={this.handlePrint}
						onOk={this.openInputTokenDialog}
						isLoading={tokenDialogActionClicked}
					/>
					<InputTokenDialog
						open={this.state.showInputTokenDialog}
						inputValue={this.state.signToken}
						onChange={this.handleTokenChange}
						onCancel={this.handleCancelInputToken}
						onOk={this.handleDownloadDocumentSigned}
						error={this.state.tokenError}
						loadingOk={printing}
						canPrintSigned={this.props.profissionalSaudeStore.canPrintSigned}
						history={this.props.history}
					/>
					{openInativarModal && (
						<ModalInativarEntradaProntuario
							show={openInativarModal}
							onClose={() => this.setState({ openInativarModal: false })}
							motivo={motivoInativacao}
							changeMotivo={(e) => this.setState({ motivoInativacao: e.target.value })}
							inativar={this.handleInativar}
						/>
					)}
				</div>
				}
			</>
		);
	}
}

PreviewDocumento.defaultProps = {
	onSend: () => {
	},
	onClose: () => {
	},
};

export default withStyles(styles)(PreviewDocumento);
