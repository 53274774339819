import { action, observable } from 'mobx';
import moment from 'moment';
import {
  createDadosBraspagAnexo, createDadosBraspagUnidade,
  findByDadosBraspagUnidadeLogada,
  updateDadosBraspag,
} from '../services/ProfissionalSaudeBraspagService';
import string from '../utils/string';
import { uploadArquivos } from '../utils/uploadArquivo';
import localStorageService, { ACCESS_TOKEN_KEY } from "../services/storage";
import {getStatusBraspagByDadosIugu} from "../utils/dadosIugu";

const unidadeDadosBraspagDefault = {
  dadoBancario: {
    banco: null,
    agencia: '',
    digitoAgencia: null,
    conta: '',
    digitoVerificador: null,
    tipoConta: 'CONTA_CORRENTE',
    documento: '',
  },
  endereco: {
    bairro: '',
    cep: '',
    complemento: '',
    estado: null,
    municipio: null,
    nomeLogradouro: '',
    numero: '',
  },
  id: '',
  documentoTipo: 'CNPJ',
  nomeFantasia: '',
  razaoSocial: '',
  dadosBraspagAnexo: [],
  status: null,
};

const formErrorsDefault = {
  banco: false,
  agencia: false,
  conta: false,
  documento: false,
  nomeFantasia: false,
  razaoSocial: false,
  cep: false,
  bairro: false,
  nomeLogradouro: false,
  numero: false,
  municipio: false,
  estado: false,
}

export default class UnidadeBraspagStore {
  profissionalSaudeStore = null;
  @observable unidadeDadosBraspag = unidadeDadosBraspagDefault;
  @observable selectedTab = 0;
  @observable documentsTabEnabled = true;
  @observable notification = {
    open: false,
    message: '',
    variant: '',
  }
  @observable accessToken = null;
  @observable formErrors = formErrorsDefault;
  @observable saving = false;
  @observable dadosBancariosHasError = false;
  @observable loading = true;

  constructor(rootStore) {
    this.unidadeStore = rootStore.unidadeStore;
    this.getAccessToke()
  }

  getAccessToke = async () => {
    this.accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
  }

  @action updateBraspag = async () => {
    try {
      if (this.formHasErrors()) {
        throw new Error('Preencha todos os campos obrigatórios.');
      }
      this.saving = true;

      const {dadoBancario, endereco, responsavelTelefone, unidade, id} = this.unidadeDadosBraspag;

      let unidadeDadosBraspag = {
        ...this.unidadeDadosBraspag,
        documento: string.removeSpecialChars(dadoBancario.documento),
        dataNascimento: moment(unidade?.dataNascimento).format('YYYY-MM-DD'),
        dadoBancario: {
          ...dadoBancario,
          digitoAgencia: dadoBancario?.digitoAgencia || null,
          digitoVerificador: dadoBancario?.digitoVerificador || null,
          documento: string.removeSpecialChars(dadoBancario.documento),
        },
        endereco: {
          ...endereco,
          municipio: {
            id: endereco?.municipio?.value,
            nome: endereco?.municipio?.label
          },
          estado: {
            id: endereco?.estado?.value,
            nome: endereco?.estado?.label
          }
        },
        responsavelTelefone: string.removeSpecialChars(responsavelTelefone),
        id: id || null,
      };

      delete unidadeDadosBraspag.dadosBraspagAnexo;
      delete unidadeDadosBraspag.perfilPublico;

      const response = await createDadosBraspagUnidade(unidadeDadosBraspag);
      const data = response?.data?.data?.createDadosBraspagUnidade;
      if (data?.id) {
        return data;
      } else {
        throw new Error('Falha ao atualizar dados bancários da unidade.');
      }
    } finally {
      this.saving = false;
    }
  };

  @action findByUnidadeBraspag = async () => {
    try {
      this.loading = true;
      const response = await findByDadosBraspagUnidadeLogada();

      const { findByDadosBraspagUnidadeLogada: dadosBraspagUnidadeLogada } = response.data.data

      const dadosIugu = dadosBraspagUnidadeLogada?.perfilPublico?.dadosIugu;

      if (dadosBraspagUnidadeLogada?.id) {
        this.unidadeDadosBraspag = {
          ...this.unidadeDadosBraspag,
          ...dadosBraspagUnidadeLogada,
          documentoTipo: 'CNPJ',
          dadoBancario: dadosBraspagUnidadeLogada?.dadoBancario ? 
          dadosBraspagUnidadeLogada?.dadoBancario : 
            this.unidadeDadosBraspag.dadoBancario,
          endereco: dadosBraspagUnidadeLogada?.endereco ? 
          dadosBraspagUnidadeLogada?.endereco :
          this.unidadeDadosBraspag.endereco,
          status: getStatusBraspagByDadosIugu(dadosIugu),
        }
        this.documentsTabEnabled = false;
        return response?.data?.data?.findByDadosBraspagUnidadeLogada
      }
      this.loading = false;
    } catch (error) {
      return null
    } finally {
      this.loading = false;
    }
  };

  @action uploadUnidadeBraspagAnexo = async (file, documentoTipo) => {
    const fileData = await this.prepareFileToSend(file);
    const anexo = {
      amazonS3Objeto: {
        extensao: fileData.extensao,
        nome: fileData.nome,
        tamanho: fileData.tamanho,
      },
      arquivoTipo: fileData.image ? 'IMAGEM' : 'ARQUIVO', 
      documentoTipo,
      unidadeDadosBraspag: {
        id: this.unidadeDadosBraspag.id,
      },
      dataHoraLancamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
    };

    const arquivo = {
      base64: fileData.base64,
      extensao: fileData.extensao,
      image: fileData.image,
      nome: fileData.nome
    }

    const response = await createDadosBraspagAnexo(anexo, arquivo);
    if (!response?.data?.data?.createDadosBraspagAnexo?.id) {
      throw new Error(`Falha ao enviar o arquivo ${fileData.nome}`);
    }
  };

  formHasErrors = () => {
    const { banco, agencia, digitoAgencia, conta, digitoVerificador } = this.unidadeDadosBraspag.dadoBancario;
    const { documentoTipo, documento, nomeFantasia, razaoSocial, endereco, email, responsavelTelefone, responsavelNome } = this.unidadeDadosBraspag;

    this.formErrors = {
      banco: !banco,
      agencia: !agencia,
      digitoAgencia: !digitoAgencia,
      conta: !conta,
      digitoVerificador: !digitoVerificador,
      documento: documentoTipo === 'CNPJ' && !documento,
      nomeFantasia: documentoTipo === 'CNPJ' && !nomeFantasia,
      razaoSocial: documentoTipo === 'CNPJ' && !razaoSocial,
      cep: !endereco?.cep,
      nomeLogradouro: !endereco?.nomeLogradouro,
      numero: !endereco?.numero,
      bairro: !endereco?.bairro,
      estado: !endereco?.estado,
      municipio: !endereco?.municipio,
      responsavelNome: !responsavelNome,
      responsavelTelefone: !string.validaTelefone(responsavelTelefone),
      email: !email,
    }

    const fields = Object.keys(this.formErrors);
    return fields.some((field) => this.formErrors[field]);
  };

  validateDadosBancarios = () => {
    const {dadoBancario} = this.unidadeDadosBraspag || {}

    this.formErrors = {
      ...this.formErrors,
      banco: !dadoBancario.banco,
      agencia: dadoBancario.agencia?.length === 0,
      conta: dadoBancario.conta?.length === 0,
    }

    const keysToVerify = ['banco', 'agencia', 'digitoAgencia', 'conta', 'digitoVerificador'];
    return keysToVerify.some((key) => this.formErrors[key]);
  }

  prepareFileToSend = async (file) => {
    const listaArquivos = await uploadArquivos([file]);
    return listaArquivos[0];
  }

  openNotification = (open, message, variant) => {
    this.notification = {
      open,
      message,
      variant
    }
  };

  closeNotification = () => {
    this.notification = {
      open: false,
      message: '',
      variant: ''
    }
  }
};