import React from "react";
import { inject, observer } from "mobx-react";
import string from "../../../utils/string";
import { findAllCentroCustoAuditByCentroCustoId } from "../../../services/AuditoriaService";
import ModalFinanceiro from "../ModalFinanceiro";
import InformacoesCentroDeCusto from "./InformacoesCentroDeCusto";

const tabs = {
  INFORMACOES: 0,
  AUDITORIA: 1,
};

@inject("centroDeCustoStore", "auditoriaStore")
@observer
class CentroDeCustoModal extends React.Component {
  state = {
    status: undefined,
    tabSelected: tabs.INFORMACOES,
  };

  componentDidMount() {
    const { auditoriaStore } = this.props;

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      auditoria: "o centro de custo",
      auditoriaTela: "centroCusto",
    }
  }

  handleChange = (field, event) => {
    const { centroDeCustoStore } = this.props;
    centroDeCustoStore.objView[field] =
      field === "ativo" ? event.value : event.target.value;
  };

  handleClose = () => {
    const { onClose, centroDeCustoStore, auditoriaStore } = this.props;

    centroDeCustoStore.closeModal();

    if (typeof onClose === "function") {
      onClose();
      auditoriaStore.reset();
    }
  };

  contemErros = () => {
    const { centroDeCustoStore } = this.props;
    const { objView } = centroDeCustoStore;

    if (!objView.nome) {
      return true;
    }
  };

  handleSave = async (e) => {
    try {
      e.preventDefault();

      const { centroDeCustoStore, auditoriaStore } = this.props;

      if (this.contemErros()) {
        auditoriaStore.notification = {
          isOpen: true,
          message: "Por favor, preencha o nome.",
        };
        auditoriaStore.showAlertMessage(notification);
      }

      const centroCusto = {
        ...centroDeCustoStore.objView,
      };

      if (centroDeCustoStore.saving) {
        return;
      }

      if (string.isEmpty(centroCusto.nome)) {
        return;
      }

      await centroDeCustoStore.save(centroCusto);
      centroDeCustoStore.closeModal();
      centroDeCustoStore.centroCustoList = [];
      centroDeCustoStore.currentPage = null;
      centroDeCustoStore.searchDTO.pageNumber = 0;
      centroDeCustoStore.findAll();
    } catch (error) {
      console.error(error);
    }
  };

  changeTabSelected = async (tabSelected) => {
    const { auditoriaStore, centroDeCustoStore } = this.props;

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    }
    
    if (tabSelected === 1) {
      auditoriaStore.load={
        query: findAllCentroCustoAuditByCentroCustoId, 
        variableName: "centroCustoId",
        variableId: centroDeCustoStore.objView.id, 
      }
      await auditoriaStore.loadAuditItems();
    }

    this.setState({ tabSelected });

  };

  render() {
    const { open, centroDeCustoStore } = this.props;
    const { objView, saving, opening } = centroDeCustoStore;
    const { tabSelected } = this.state;

    const pageTitle = objView?.id
      ? "Financeiro - Editar centro de custo"
      : "Financeiro - Novo centro de custo"

    return (
      <>
        <ModalFinanceiro
          opening={opening}
          tabsProps={{
            options: ["Informações", "Auditoria"],
            tabSelected: tabSelected,
            changeTabSelected: this.changeTabSelected,
            disabled: !objView?.id,
            posicaoDesabilitada: [1],
          }}
          open={open}
          pageTitle={pageTitle}
          handleClose={this.handleClose}
          modalTitle="Centro de custo"
          handleSave={this.handleSave}
          loadingSave={saving}
          componentInformacoes={
            <InformacoesCentroDeCusto
              handleChange={this.handleChange}
              isDisabled={objView?.id}
            />
          }
        />
      </>
    );
  }
}

export default CentroDeCustoModal;
