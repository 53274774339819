import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";
import { VisibilityIcon, VisibilityOffIcon } from "../../assets/img/svg";
import Colors from "../../template/Colors";
import classNames from "classnames";

const InputSenha = (props) => {
  const { classes, error, className, errorMessage, ...others } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl fullWidth>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        className={classNames(classes.field, className)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              className={classes.buttonIcon}
            >
              {showPassword ? (
                <img src={VisibilityIcon} alt="Senha visível" />
              ) : (
                <img src={VisibilityOffIcon} alt="Senha invisível" />
              )}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
        {...others}
      />
      {error && (
        <FormHelperText className={classes.error}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const styles = {
  buttonIcon: {
    height: 28,
    width: 28,
  },
  field: {
    background: "#F2F2F2",
    borderRadius: "8px",
    height: "32px",
    width: "100%",
    fontFamily: "Poppins",
    color: Colors.commons.gray9,
    fontSize: "14px",

    "& fieldset": {
      border: "none",
    },
  },
  error: {
    color: Colors.error.main,
    fontSize: "12px",
    fontFamily: "Poppins",
    marginTop: "4px",
  },
};

export default withStyles(styles)(InputSenha);
