import { getWhitelabelNome, getWhitelabelAtualURL } from "../../../services/WhitelabelService";

export const perfilViziOptions = [
  {
    label: 'Atendimento Presencial',
    attribute: 'atendePresencial',
    storedIn: 'perfilPublicoStore'
  },
  {
    label: 'Atendimento por teleconsulta',
    attribute: 'utilizaTelemedicina',
    storedIn: 'perfilPublicoStore',
    informacao: 'Ativando essa funcionalidade será ativado automaticamente para seu paciente o meio de pagamento online via cartão de crédito.'
  },
  {
    label: 'Atende crianças',
    attribute: 'atendeCrianca',
    storedIn: 'perfilPublicoStore'
  },
  {
    label: `Perfil visível na busca`,
    attribute: 'permiteBuscaProfissional',
    storedIn: 'perfilPublicoStore'
  },
  {
    label: 'Exibir perfil nos resultados do Google',
    attribute: 'permiteBuscaGoogle',
    storedIn: 'perfilPublicoStore',
    informacao: 'Se caso não queira que sua página seja encontrada nos resultados de busca de plataformas como Google, Bing, entre outros, desative essa funcionalidade.'
  },
  {
    label: 'Habilitar perfil apenas para visualização',
    attribute: 'perfilVitrine',
    storedIn: 'perfilPublicoStore'
  }
]

export const beforeActivationText = `o ativar essa funcionalidade, será liberado a funcionalidade do perfil público dentro do sistema ${getWhitelabelNome()}. Será adicionado uma aba no menu lateral “Perfil ${getWhitelabelNome()}”, onde poderá configurar todas as informações que aparecerão no perfil público.`;

export const afterActivationText = "Para desabilitar essa funcionalidade entre em contato com nosso time de suporte.";

export const advertisementText = `O ${getWhitelabelNome()} é a plataforma ideal para profissionais da saúde. Dê visibilidade à sua clínica ou consultório, ofereça agendamento online, tenha um sistema de gestão completo e multiplique a eficiência do seu negócio.`

export const supportWhatsappLink = "https://web.whatsapp.com/send?phone=554430136077";

export const suppEmail = "suporte@apphealth.com.br";

export const LPComercial = `${getWhitelabelAtualURL()}/profissional/`;

export const dadosInsuficientesTitle = "Seu perfil está incompleto!";
export const dadosInsuficientesMessage = "Para ter um bom perfil e ser encontrado pelos seus pacientes, é necessário preencher alguns campos básicos.";
export const agendaNaoConfiguradaTitle = "Você não tem uma agenda configurada no sistema";
export const agendaNaoConfiguradaMessage = `Seu perfil será inativado na busca do ${getWhitelabelNome()}pois ainda não realizou nenhuma configuração de agenda disponível dentro das configurações.`;
export const dadosBancariosNaoConfiguradosTitle = "Dados bancários";
export const dadosBancariosNaoConfiguradosMessage = "Preencha seus dados bancários para ter acesso a teleconsulta e recebimentos online.";

export const tabs = {
  DADOS_GERAIS: 0,
  CUSTOMIZACAO: 1,
  ATIVIDADE: 2 
};

export const ElementsList = {
  NOME: "nome",
  EMAIL: "email",
  NUMERO_CONSELHO: "numeroConselho",
	TELEFONE_PRINCIPAL: "telefonePrincipal",
	TELEFONE_SECUNDARIO: "telefoneSecundario",
	EXPERIENCIA: "experiencia",
	HORARIO_ATENDIMENTO: "horarioAtendimento",
	MEDIDAS_SEGURANCA: "medidasSeguranca",
	SOBRE: "sobre",
	FORMACAO: "formacao",
	FORMAS_PAGAMENTO: "formasPagamento",
	CONVENIOS: "convenios",
	IDIOMAS: "idiomas",
	ESPECIALIDADES: "especialidades",
	FACEBOOK: 'facebook',
	INSTAGRAM: 'instagram',
	LINKEDIN: 'linkedin',
	TWITTER: 'twitter'
};

export const TAXA_ADMINISTRATIVA = 6;