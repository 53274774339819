import React, { useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import { Grid, withStyles } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import styles from "./ThreeDaysCalendar.style";
import {Dates} from "../../../utils"
import AgendaThreeDays from "./AgendaThreeDays";
import ButtonFloat from "../../../components/ButtonFloat";
import string from "../../../utils/string";

export const HeaderAgendamento = props => {
    const {
        diasAgendamento,
        classes,
    } = props;

    const dataHeader = (item) => {
        const dataAgendamento = moment(item).format("DD/MM")
        const diaSemana = moment(item).format("dddd").replace("-feira","").toUpperCase();

        const infoHeader =  `${dataAgendamento} - ${diaSemana}`

        return infoHeader
    }

    return (
        <div className={classes.dayCalendarHeaderInner}>
            {diasAgendamento.map((item) => {
                return(<div className={classes.rowHeader}>
                    <div className={classNames(classes.dayCalendarColumnHeader, classes.columnHoraFim)}>
                        <div className={classNames(classes.dayCalendarColumnHeaderLabel, classes.columnHoraFimLabel)}>
                            Hora início
                        </div>
                    </div>
                    <div className={classNames(classes.dayCalendarColumnHeader, classes.columnData)}>
                        <div className={classNames(classes.dayCalendarColumnHeaderLabel, classes.columnDataLabel)}>
                            {dataHeader(item)}
                        </div>
                    </div>
                </div>)
            }) }
        </div>
    )
};

const Agendamento = props => {

    const {
        selectedDate,
        agendamentos,
        configVigente,
        classes,
        onSelectAgendamento,
        onSelectNovoAgendamento,
        onRemoveAgendamento,
        tipoProfissionalLogado,
        diasAgendamento
    } = props;
    
    const daysOfWeekTransform =  Dates.daysOfWeekTransform();
    const [listaHorariosAgendamento, setListaHorariosAgendamento] = useState([]);

    useEffect(() => {
        let listaAgendamentos = [];

        if (!agendamentos) {
            return
        }

        const agendamentosFiltrados = agendamentos.reduce((agendamento, atual) => {
            const agendamentoDuplicado = agendamento.find(item => 
                moment(item.start).format("x") === moment(atual.start).format("x") && 
                atual.status === "BLOQUEADO"
            );

            if (!agendamentoDuplicado) {
                return agendamento.concat([atual]);
            } else {
                return agendamento;
            }
        }, []);

        const agendamentosDatasAgrupado = diasAgendamento.map(agendamento => {
            const listaAgendamentosData = agendamentosFiltrados.filter(item => moment(item.start).format("YYYY-MM-DD") === agendamento.format("YYYY-MM-DD"));
        
            return listaAgendamentosData;
        });

        const selectDateString = selectedDate.format('dddd');

        const listaHoraInicioAgendamento = configVigente[daysOfWeekTransform[string.capitalize(selectDateString)]].map(configuracao => {
            const quantidadesAgendamento = agendamentosDatasAgrupado.map(agendamentoDia => {
                const agendamentoMesmoHorario = agendamentoDia.filter(item => configuracao.horaInicio === moment(item.start).format("HH:mm"));
                const quantidade = agendamentoMesmoHorario.length;
                return quantidade
            });

            const max = quantidadesAgendamento.reduce((a, b) => {
                return Math.max(a, b);
            }, -Infinity);

            const horario = {...configuracao, quantidade: max || 1}
            
            return horario;
        });

        listaAgendamentos = diasAgendamento.map((item, index) => {
            let agendamentoDoDia = [];
            listaHoraInicioAgendamento.forEach(configuracaoAtual => {
                const agendamentosMesmoHorario = agendamentosDatasAgrupado.length > 0 && agendamentosDatasAgrupado[index].filter(item => moment(item.start).format("HH:mm") === configuracaoAtual.horaInicio);

                for (let i=0; i < configuracaoAtual.quantidade; i++){
                    let agendamentoAdaptado = {};

                    if(!!agendamentosMesmoHorario[i]) {
                        agendamentoAdaptado = {
                            horaInicio: moment(agendamentosMesmoHorario[i]?.start).format("HH:mm"),
                            horaFim: moment(agendamentosMesmoHorario[i]?.end).format("HH:mm"),
                            id: agendamentosMesmoHorario[i]?.id,
                            nome: agendamentosMesmoHorario[i].sujeitoAtencaoNome ? agendamentosMesmoHorario[i].sujeitoAtencaoNome : agendamentosMesmoHorario[i].nome,
                            convenio: agendamentosMesmoHorario[i].sujeitoAtencaoConvenio ? agendamentosMesmoHorario[i].sujeitoAtencaoConvenio : agendamentosMesmoHorario[i].convenio,
                            status: agendamentosMesmoHorario[i]?.status,
                            tipo: agendamentosMesmoHorario[i]?.tipo,
                            telefone: agendamentosMesmoHorario[i].sujeitoAtencaoTelefone &&  agendamentosMesmoHorario[i].sujeitoAtencaoTelefoneDDI ? agendamentosMesmoHorario[i].sujeitoAtencaoTelefone :
                                agendamentosMesmoHorario[i].telefone && agendamentosMesmoHorario[i].telefoneDDI ? agendamentosMesmoHorario[i].telefone : '',
                            telefoneDDI: agendamentosMesmoHorario[i].sujeitoAtencaoTelefone &&  agendamentosMesmoHorario[i].sujeitoAtencaoTelefoneDDI ? agendamentosMesmoHorario[i].sujeitoAtencaoTelefoneDDI :
                                agendamentosMesmoHorario[i].telefone && agendamentosMesmoHorario[i].telefoneDDI ? agendamentosMesmoHorario[i].telefoneDDI : '',
                            idade: agendamentosMesmoHorario[i]?.idade,
                            procedimentos: agendamentosMesmoHorario[i]?.procedimentos,
                            solicitante: agendamentosMesmoHorario[i]?.solicitante,
                            dataNascimento: agendamentosMesmoHorario[i]?.dataNascimento,
                            observacao: agendamentosMesmoHorario[i]?.observacao,
                            procedimentoPedido: agendamentosMesmoHorario[i]?.procedimentoPedido,
                        }
                    } else {
                        if(configuracaoAtual.quantidade > 1 && agendamentosMesmoHorario[0]?.status === "BLOQUEADO"){
                            agendamentoAdaptado = {
                                permiteAgendamento: false,
                                start: moment(`${moment(item[index]).format('YYYY-MM-DD')} ${configuracaoAtual.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                                end: moment(`${moment(item[index]).format('YYYY-MM-DD')} ${configuracaoAtual.horaFim}`, 'YYYY-MM-DD HH:mm'),
                                horaInicio: configuracaoAtual.horaInicio,
                                horaFim: configuracaoAtual?.horaFim,
                                situacao: 'BLOQUEADO',
                                status: 'BLOQUEADO'
                            }  

                        } else {
                            agendamentoAdaptado = {
                                id: configuracaoAtual?.horaInicio,
                                data: item,
                                horaInicio: configuracaoAtual.horaInicio,
                                horaFim: configuracaoAtual?.horaFim
                            }
                        }
                    }

                    agendamentoDoDia = [...agendamentoDoDia, agendamentoAdaptado];
                } 
            })            
            return agendamentoDoDia;
        })
        const agendamentosOrdenados = ordenaAgendamentos(listaAgendamentos);
        setListaHorariosAgendamento(agendamentosOrdenados)

    }, [agendamentos]);

    const ordenaAgendamentos = (listaAgendamentos) => {
        return listaAgendamentos.map(item => {
            const listaOrdenada = item.sort((a,b) => moment(a.horaInicio, "HH:mm").format("x") - moment(b.horaInicio, "HH:mm").format("x"))

            return listaOrdenada;
        });
    };

    const renderAgendamentos = () => {
        return listaHorariosAgendamento.length > 0 && listaHorariosAgendamento.map((agendamentos) => {
            return agendamentos.length > 0 && 
            <div className={classes.contentCalendarDay}>
                <AgendaThreeDays
                    agendamentos={agendamentos}
                    selectedDate={selectedDate}
                    onSelectNovoAgendamento={onSelectNovoAgendamento}
                    onSelectAgendamento={onSelectAgendamento}
                    onRemoveAgendamento={onRemoveAgendamento}
                />
            </div>
        })
    }

    return (
        <div className={classes.contentCalendar}>
            {renderAgendamentos()}
            {tipoProfissionalLogado !== 'tecnico' &&
                <ButtonFloat
                    onClick={() => onSelectNovoAgendamento()}
                    icon={AddIcon}>
                </ButtonFloat>
            }
        </div>
    );
};

const Calendar = props => {
    const {
        selectedDate,
        agendamentos,
        classes,
        onSelectAgendamento,
        onSelectNovoAgendamento,
        onRemoveAgendamento,
        configVigente,
        tipoProfissionalLogado
    } = props;

    const diaSelecionadoAux = moment(selectedDate)
    const diaSelecionadoAux1 = moment(selectedDate)
    const dia1 = selectedDate;
    const dia2 = diaSelecionadoAux.add(1, 'days');
    const dia3 = diaSelecionadoAux1.add(2, 'days');

    const diasAgendamento = [dia1, dia2, dia3];

    return (
        <Grid
        className={classes.dayCalendar}
        item
        xs={12}
        container
        direction={"column"}
        wrap={"nowrap"}
        >
            <HeaderAgendamento diasAgendamento={diasAgendamento} selectedDate={selectedDate} classes={classes} />
            <Agendamento 
                selectedDate={selectedDate}
                agendamentos={agendamentos}
                configVigente={configVigente}
                classes={classes}
                onSelectAgendamento={onSelectAgendamento}
                onSelectNovoAgendamento={onSelectNovoAgendamento}
                onRemoveAgendamento={onRemoveAgendamento}
                tipoProfissionalLogado={tipoProfissionalLogado}
                diasAgendamento={diasAgendamento}
            />
        </Grid>

    )
};

const ThreeDaysCalendar = props => {
  return (
    <Calendar {...props} />
  );
};

export default withStyles(styles)(ThreeDaysCalendar);