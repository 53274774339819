import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '../../../components/Button/Button';
import { supportWhatsappLink, suppEmail, LPComercial } from '../constants/perfilViziConstants';
import { WhatsApp } from '../../../components/Icon';
import MailOutline from '@material-ui/icons/MailOutline';
import { getWhitelabelNome } from '../../../services/WhitelabelService';

const styles = ({
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '16px',
    marginTop: '8px',
    marginBottom: '16px'
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '6px',
    color: '#5F6368',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    },
    '&>a': {
      color: '#5F6368',
    }
  },
  text: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: '#5F6368',
  },
  grayLink: {
    color: '#5F6368',
    fontWeight: 'bold',
  },
  greenLink: {
    color: '#009A95',
    fontWeight: 'bold',
  }
});

const PerfilPublicoCard = ({ classes }) => {
  const onClickMigrar = () => {
    window.open(LPComercial, '_blank')
  };

  return (
    <>
      <span className={classes.text}>
        Essa funcionalidade será desativada em breve da forma como você conhece. Está sendo desenvolvido o {getWhitelabelNome()}, um novo portal com muito mais funcionalidades para te oferecer uma experiência muito superior.
      </span>
      <span className={classes.text}>
        Por essa razão, o <b>perfil público</b> que faz parte do sistema App Health <b>será desativado</b> no <b>dia 10/01/2022</b> e migrado para o plano gratuito do {getWhitelabelNome()} de forma automática até dia no dia <b>10/01/2022</b>, contendo todas as informações que hoje já fazem parte do seu perfil atual.
      </span>
      <span className={classes.text}>
        Para ter acesso ao perfil profissional completo do {getWhitelabelNome()}, entre em contato com o time Comercial e 
        <a href={LPComercial} target="_blank" className={classes.grayLink} rel="noreferrer"> Conheça os beneficios do {getWhitelabelNome()}</a>.
      </span>
      <Button
        style={{
          borderRadius: '100px',
          padding: '8px 16px',
          height: '36px',
          backgroundColor: '#F9BE73',
          textTransform: 'none'
        }}
        onClick={onClickMigrar}
      >
        Migrar para {getWhitelabelNome()}
      </Button>
      <span className={classes.text}>
        <a href={LPComercial} target="_blank" className={classes.greenLink} rel="noreferrer">Clique aqui para saber os benefícios do {getWhitelabelNome()} </a>
        ou entre em contato com nossa equipe de Suporte através do e-mail ou WhatsApp para receber todas as orientações dessa mudança.
      </span>
      <div className={classes.contactContainer}>
            <div className={classes.contactItem}>
              <WhatsApp />
              <a href={supportWhatsappLink} target='_blank' rel="noreferrer">
                <b>
                  WhatsApp
                </b>
              </a>
            </div>
            <div className={classes.contactItem}>
              <MailOutline />
              <a href={`mailto: ${suppEmail}`} target='_blank' type='email' rel="noreferrer" >
                <b>
                  E-mail
                </b>
              </a>
            </div>
          </div>
    </>
  )
};


export default withStyles(styles)(PerfilPublicoCard);