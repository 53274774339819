import React from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { Fab } from "@material-ui/core";
import {
	Save as SaveIcon,
	Close as CloseIcon
} from '@material-ui/icons';
import { InputForm } from "../../../components/Modal/Input";
import styles from "../../../assets/jss/pages/perfilUnidadeStyle";
import string from "../../../utils/string";
import { SelectSearch } from "../../../components/Select/SelectSearchAsync"
import Notification from "../../../components/Notification";
import CardDadosCadastrais from "../../../components/Cards/CardDadosCadastrais";
import ItemGrid from "../../../components/Modal/ItemGridCard";
import AdicionarCodigoTvModal from '../../../components/Modal/AdicionarCodigoTvModal';
import localStorageService, { ACCESS_TOKEN_KEY } from "../../../services/storage";
import { TextFieldSearch } from '../../../components/TextField';
import { InputCEPForm } from '../../../components/Input/InputCEPForm';
import InputPhoneDdi from "../../../components/Input/InputPhoneDdi";
import PageTitle from "../../../components/PageTitle/PageTitle";
import TermoUsoModal from "../../Dashboard/TermoUso/TermoUsoModal";
import PortalViziActivationCard from "../components/PortalViziActivationCard";
import { beforeActivationText, afterActivationText } from "../constants/perfilViziConstants";
import RectLabel from "../../../components/Labels/RectLabel";
import Button from "../../../components/Button/Button";
import DadosInsuficientesWarning from "../components/DadosInsuficientesWarning";
import {
	unidadeViziPerfilIncompletoTitle,
	unidadeViziPerfilIncompletoMessage,
	unidadeSemProfissionalTitle,
	unidadeSemProfissionalMessage
} from '../constants/perfilViziUnidadeConstants';

import colors from "../../../template/Colors";
import ProfileImageAndBanner from "../components/ProfileImageAndBanner";
import { getWhitelabelNome } from "../../../services/WhitelabelService";
import AdicionarCodigoTotemModal from '../../../components/Modal/AdicionarCodigoTotemModal';
import { conectarTotem } from '../../../services/TotemService';

const labelStyle = {
	height: '16px',
	marginRight: '6px'
};

const tvButtonStyle = {
	backgroundColor: colors.commons.yellow,
	height: '36px',
	padding: '8px 16px',
	borderRadius: '100px',
	textTransform: 'none'
};

const ViziCardLabel = <RectLabel text="Novidade" color="#FC71B4" areaStyle={labelStyle} />;

@inject("unidadeStore", "usuarioStore", "perfilPublicoStore", "profissionalSaudeStore", "termoUsoStore")
@observer
class PerfilUnidade extends React.Component {

	constructor(props) {
		super(props);
		this.carregaDadosUnidade();
		this.state = {
			openAdicionarCodigoTvModal: false,
			openAdicionarCodigoTotemModal: false,
			accessToken: "",
			loadingSaveFoto: false,
			paisAtuacao: null,
			unidadesQuantity: 0
		};
		this.carregaComunidadesAutonoma();
	}

	async componentDidMount() {
		await this.props.perfilPublicoStore.findAllConvenioSistemaList();
		await this.props.profissionalSaudeStore.getPaymentMethods();
		await this.props.profissionalSaudeStore.getSpecializations();
	}

	carregaDadosUnidade = async () => {
		const { unidadeStore, usuarioStore } = this.props;

		await unidadeStore.getUnidadeLogada();
		await unidadeStore.getUnidadePublicaLogada();
		const paisAtuacao = await usuarioStore.getPaisAtuacao();
		const unidades = await usuarioStore.getUnidades();
		const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
		const unidadesQuantity = unidades.length
		this.setState({
			accessToken,
			paisAtuacao,
			unidadesQuantity
		}, () => {
			this.carregaComunidadesAutonoma(paisAtuacao?.descricao);
		});
	};

	carregaComunidadesAutonoma = async (paisAtuacao) => {
		const { unidadeStore } = this.props;
		paisAtuacao === "ESPANHA" && unidadeStore.loadEspanhaComunidadesAutonoma();
	};

	onFieldChange(value, campo) {
		this.props.unidadeStore.unidade = {
			...this.props.unidadeStore.unidade,
			[campo]: value
		};
		this.props.unidadeStore.errors = {
			...this.props.unidadeStore.errors,
			[campo]: string.isEmpty(value)
		}

		if (campo === 'nomeFantasia') {
			this.props.perfilPublicoStore.checkPerfilUnidadeIsComplete();
		}
	}

	onEstadoChange(event) {
		this.props.unidadeStore.objView.estado = event;
		this.onMunicipioChange(null);

		if (this.props.unidadeStore.objView.estado) {
			this.props.unidadeStore.getMunicipioByEstado(this.props.unidadeStore.objView.estado.value);
		} else {
			this.props.unidadeStore.municipios = []
		}
		this.props.unidadeStore.errors.estado = this.props.unidadeStore.objView.estado === undefined;
	}

	onMunicipioChange(event) {
		this.props.unidadeStore.objView.municipio = event;
		this.props.unidadeStore.errors.municipio = this.props.unidadeStore.objView.municipio === undefined;
	}

	onEnderecoChange(value, campo) {
		this.props.unidadeStore.unidade = {
			...this.props.unidadeStore.unidade,
			endereco: {
				...this.props.unidadeStore.unidade.endereco,
				[campo]: value
			}
		};
		this.props.unidadeStore.errors = {
			...this.props.unidadeStore.errors,
			[campo]: string.isEmpty(value)
		}
	}

	onChangeSobre(value) {
		let isValid = string.isEmpty(value) || string.isMaxLength(value, 500)
		this.props.unidadeStore.unidadePublica.sobre = value
		this.props.unidadeStore.errorsInfosPublicas.sobre = isValid
	}
	onChangeTelefoneSecundario(value) {
		let onlyNumbers = string.removeSpecialChars(value)
		this.props.unidadeStore.unidadePublica.telefoneSecundario = onlyNumbers
		this.props.unidadeStore.errorsInfosPublicas.telefoneSecundario = string.isEmpty(onlyNumbers)
	}

	onChangePerfilPublico(value) {
		this.props.unidadeStore.unidade.perfilPublico = value
	}

	onChangePerfilPublicoProfissional(value) {
		this.props.perfilPublicoStore.perfilPublicoUnidade.permiteProfissionalUnidade = value;
	}

	handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.props.unidadeStore.closeNotification();
	};

	update = async () => {
		const { unidadeStore } = this.props;
		try {
			await unidadeStore.updateUnidadeLogada('dadosCadastrais');
			unidadeStore.openNotification('Cadastro da unidade atualizado com sucesso', 'success');
		} catch (error) {
			unidadeStore.openNotification(error?.message || 'Erro ao atualizar unidade', 'error');
		}
	};

	cancelar = () => {
		this.props.history.push({
			pathname: '/'
		});
	};

	onClickAdicionarCodigoTv = () => {
		this.setState({ openAdicionarCodigoTvModal: true });
	};

	onClickAdicionarCodigoTotem = () => {
		this.setState({ openAdicionarCodigoTotemModal: true });
	}

	saveCodigoTotem = async ({ codigoTotem }) => {
		try {
			await conectarTotem(codigoTotem);
			this.setState({ openAdicionarCodigoTotemModal: false });
		} catch(error) {
			console.log(error);
		}
	}

	onCloseAdicionarCodigoTv = () => {
		this.setState({ openAdicionarCodigoTvModal: false });
	}

	saveCodigoTv = async ({ codigoTv, conexaoTV }) => {

		const validarCodigo = await this.props.unidadeStore.validarCodigoTv(codigoTv, 'CHAMAR_PROXIMO', conexaoTV);

		validarCodigo && this.onCloseAdicionarCodigoTv();

	}

	resetNotification = () => this.props.unidadeStore.resetNotification();

	onEspanhaComunidadeAutonomaChange = (comunidadeAutonoma) => {
		const { unidadeStore } = this.props;

		unidadeStore.comunidadeAutonoma = comunidadeAutonoma;
		unidadeStore.provincia = null;
		unidadeStore.unidade.espanhaMunicipio = null;
		unidadeStore.findProvinciaBy(comunidadeAutonoma);
	};

	onEspanhaProvinciaChange = provincia => {
		const { unidadeStore } = this.props;

		unidadeStore.provincia = provincia;
		unidadeStore.unidade.espanhaMunicipio = null;
	};

	onEspanhaMunicipioChange = espanhaMunicipio => {
		const { unidadeStore } = this.props;

		unidadeStore.errors = {
			...this.props.unidadeStore.errors,
			espanhaMunicipio: !espanhaMunicipio
		};
		unidadeStore.unidade.espanhaMunicipio = espanhaMunicipio;
	};

	loadSelectEspanhaMunicipio = async (search, loadedOptions, { page }) => {
		return this.handleLoadMoreOptions({
			search,
			loadedOptions,
			data: { page },
		});
	};

	handleLoadMoreOptions = async ({ search, data }) => {
		const { unidadeStore } = this.props;

		if (data.searchDTO) {
			searchDTO = {
				...data.searchDTO
			};
		}
		const response = await unidadeStore.findEspanhaMunicipio({
			pageableDTO: {
				pageNumber: data.page,
				pageSize: 20,
				sortDir: "ASC",
				sortField: "descricao"
			},
			descricao: search,
			codigoProvincia: unidadeStore.provincia.value
		});
		return {
			options: response.content,
			hasMore: !response.last,
			additional: {
				page: data.page + 1
			}
		};
	};

	handleSelectOption = (value, field) => {
		this.props.perfilPublicoStore.perfilPublicoUnidade = {
			...this.props.perfilPublicoStore.perfilPublicoUnidade,
			[field]: value,
		};
	}

	onTelefonePrincipalComDDIChange = (value, data) => {
		const { unidadeStore } = this.props;
		unidadeStore.unidade = {
			...unidadeStore.unidade,
			telefonePrincipal: value,
			telefonePrincipalDDI: data.dialCode
		};
	};

	isCartaoCreditoSelected = () => {
		const selectedPaymentMethods = this.props.perfilPublicoStore.perfilPublicoUnidade.formasPagamento;
		return selectedPaymentMethods.some((method) => method.id === 2);
	}

	handleActivateVizi = async () => {
		const termoDeUso = await this.props.termoUsoStore.buscarTermoUso('VIZI_UNIDADE');
		if (termoDeUso.length === 0) {
			this.props.unidadeStore.unidade.perfilPublico = true;
		}
	};

	handleAssinarTermoUsoVizi = () => {
		this.props.termoUsoStore.assinarTermoUso('VIZI_UNIDADE');
		this.props.unidadeStore.unidade.perfilPublico = true;
	};

	getPortaViziCardVariant = () => {
		const { unidadeStore } = this.props;
		if (unidadeStore.unidade.perfilPublico) {
			return 'viziActivated';
		} else {
			return 'viziDisabledInUnidade'
		}
	};

	removeProfileImage = async () => {
		const { perfilPublicoStore, unidadeStore } = this.props;
		try {
			await perfilPublicoStore.removeProfileImage(
				perfilPublicoStore.perfilPublicoUnidade.id,
				"unidade"
			);
			perfilPublicoStore.checkPerfilUnidadeIsComplete();
			unidadeStore.openNotification(
				"Foto de perfil da unidade removida com sucesso",
				"success"
			);
		} catch (error) {
			unidadeStore.openNotification(error, "error");
		}
	};

	updateProfile = async (blob) => {
		const { perfilPublicoStore, unidadeStore } = this.props;
		try {

			await perfilPublicoStore.updateProfileImage(
				perfilPublicoStore.perfilPublicoUnidade.id,
				blob,
				"unidade"
			);
			perfilPublicoStore.checkPerfilUnidadeIsComplete();
			unidadeStore.openNotification(
				"Foto de perfil da unidade alterada com sucesso",
				"success"
			);
		} catch (error) {
			unidadeStore.openNotification(error, "error");
		}
	};


	render() {
		const { classes, unidadeStore, termoUsoStore, perfilPublicoStore } = this.props;
		const { unidade, errors, objView } = unidadeStore;
		const { loadingProfileImage, perfilPublicoUnidade, accessToken } = perfilPublicoStore;
		const { paisAtuacao } = this.state;
		return (
			<div className={classes.tableContainer}>
				<PageTitle title="Dados cadastrais da unidade" />
				<h3
					className={classNames(classes.titleHeader, classes.headerTitleRoot)}
				>
					Dados cadastrais da unidade
				</h3>
				{!unidadeStore.isLoadingInfo && unidade.perfilPublico && perfilPublicoStore.perfilUnidadeIsIncomplete && (
					<div style={{ width: "90%" }}>
						<DadosInsuficientesWarning
							title={unidadeViziPerfilIncompletoTitle}
							message={unidadeViziPerfilIncompletoMessage}
							withoutButton
							isPerfilWarning
							requiredFields={perfilPublicoStore.perfilPublicoUnidadeErrors}
						/>
					</div>
				)}
				{!unidadeStore.isLoadingInfo && unidade.perfilPublico && perfilPublicoStore.perfilPublicoUnidade.perfisPublicos?.length === 0 && (
					<div style={{ width: "90%" }}>
						<DadosInsuficientesWarning
							title={unidadeSemProfissionalTitle}
							message={unidadeSemProfissionalMessage}
							withoutButton
						/>
					</div>
				)}
				<div className={classes.gridCardContainerPerfil}>
					<div>
						<div style={{ height: 'fit-content', marginBottom: '24px' }}>
							<CardDadosCadastrais titulo="Fila de espera na TV">
								<span className={classes.cardText}>
									Para sincronizar seu sistema de fila de atendimento com a sua TV, clique no botão abaixo e adicione o código.
								</span>
								<Button
									style={tvButtonStyle}
									onClick={this.onClickAdicionarCodigoTv}
								>
									Adicionar código da TV
								</Button>
							</CardDadosCadastrais>
						</div>
						<div style={{ height: 'fit-content', marginBottom: '24px' }}>
							<CardDadosCadastrais titulo="Totem de avaliação">
								<span className={classes.cardText}>
									Para sincronizar seu sistema com o Totem de avaliação, clique no botão abaixo e adicione o código.
								</span>
								<Button
									style={{
										...tvButtonStyle,
										backgroundColor: '#00B0AE',
									}}
									onClick={this.onClickAdicionarCodigoTotem}
								>
									Adicionar código do Totem
								</Button>
							</CardDadosCadastrais>
						</div>
						<ProfileImageAndBanner
							profileImageId={
								perfilPublicoUnidade.fotoPerfil
							}
							updateProfile={this.updateProfile}
							token={accessToken}
							removeProfileImage={this.removeProfileImage}
							loadingProfileImage={loadingProfileImage}
							cardFotoPerfilUnidade={{
								title: "Imagem de Perfil",
								informacao: "Foto da sua clínica que será utilizado em documentos tamanho máximo 180x180px no formato png ou jpeg.",
								hidden: true,
							}}
						/>
						<div style={{ height: 'fit-content', margin: '24px 0' }}>
							<CardDadosCadastrais titulo={"Dados da unidade:"}>
								<ItemGrid size={12} label={"Razão social:"} bold>
									<InputForm
										name={'nome'}
										value={unidade.nome || ""}
										placeholder={'Razão social'}
										error={errors.nome}
										className={classNames(classes.inputContainer, errors.nome ? classes.inputError : null)}
										onChange={(e) => this.onFieldChange(e.target.value, 'nome')}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"Nome Fantasia:"} bold>
									<InputForm
										name={'nomeFantasia'}
										value={unidade.nomeFantasia || ""}
										placeholder={'Nome Fantasia'}
										className={classNames(classes.inputContainer, errors.nomeFantasia ? classes.inputError : null)}
										onChange={(e) => this.onFieldChange(e.target.value, 'nomeFantasia')}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"Código CNES:"} bold>
									<InputForm
										name={'codigoCnes'}
										value={unidade.codigoCnes || ""}
										placeholder={'Código CNES'}
										className={classNames(classes.inputContainer, errors.codigoCnes ? classes.inputError : null)}
										onChange={(e) => this.onFieldChange(e.target.value, 'codigoCnes')}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"Telefone principal:"} bold>
									<InputPhoneDdi
										value={unidade.telefonePrincipal || ""}
										onTelefonePrincipalComDDIChange={this.onTelefonePrincipalComDDIChange}
									/>
								</ItemGrid>
							</CardDadosCadastrais>
						</div>
					</div>
					<div>
						<div style={{ height: 'fit-content', marginBottom: '24px' }}>
							{unidade.perfilPublico && (
								<div>
									<CardDadosCadastrais titulo="Portal Vizi Unidade"
										label={ViziCardLabel}
									>
										<PortalViziActivationCard
											activated={unidade.perfilPublico}
											beforeActivationText={beforeActivationText}
											afterActivationText={afterActivationText}
											onPressActivation={this.handleActivateVizi}
											variant="viziActivated"
											userType="unidade"
										/>
									</CardDadosCadastrais>
								</div>
							)}
						</div>
						<div style={{ height: 'fit-content', marginBottom: '24px' }}>
							<CardDadosCadastrais titulo={"Endereço:"}>
								<ItemGrid size={12} label={paisAtuacao?.descricao === 'ESPANHA' ? 'Código postal' : "CEP:"} bold>
									<InputCEPForm name={"cep"}
										placeholder={paisAtuacao?.descricao === 'ESPANHA' ? 'Código postal' : "CEP:"}
										showmask={'false'}
										value={unidade.endereco?.cep}
										className={classNames(classes.inputContainer)}
										classes={{
											input: classes.input,
										}}
										error={errors.cep}
										onChange={(e) => this.onEnderecoChange(e.target.value, 'cep')}
										pais={paisAtuacao?.descricao}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"Logradouro:"} bold>
									<InputForm name={"nomeLogradouro"}
										placeholder={"Logradouro"}
										value={unidade.endereco?.nomeLogradouro}
										className={classNames(classes.inputContainer, errors.nomeLogradouro ? classes.inputError : null)}
										onChange={(e) => this.onEnderecoChange(e.target.value, 'nomeLogradouro')}
									/>
								</ItemGrid>
								<div className={classes.numeroBairro}>
									<ItemGrid size={12} label={"Número:"} bold>
										<InputForm name={"numero"}
											placeholder={"Número"}
											value={unidade.endereco?.numero}
											className={classNames(classes.inputContainer, errors.numero ? classes.inputError : null)}
											onChange={(e) => this.onEnderecoChange(e.target.value, 'numero')}
										/>
									</ItemGrid>
									<ItemGrid size={12} label={"Bairro:"} bold>
										<InputForm name={"bairro"}
											placeholder={"Bairro"}
											value={unidade.endereco?.bairro}
											className={classNames(classes.inputContainer, errors.bairro ? classes.inputError : null)}
											onChange={(e) => this.onEnderecoChange(e.target.value, 'bairro')}
										/>
									</ItemGrid>
								</div>
								<ItemGrid size={12} label={"Complemento:"} bold>
									<InputForm name={"complemento"}
										placeholder={"Complemento"}
										value={unidade.endereco?.complemento}
										className={classes.inputContainer}
										onChange={(e) => this.onEnderecoChange(e.target.value, 'complemento')}
									/>
								</ItemGrid>
								{paisAtuacao?.descricao === "ESPANHA" ?
									<>
										<ItemGrid xs={6} label={'Comunidade autonoma:'} bold>
											<SelectSearch
												placeholder={'Comunidade autonoma'}
												value={unidadeStore.comunidadeAutonoma}
												onChange={this.onEspanhaComunidadeAutonomaChange}
												className={classes.inputEnderecoEspanha}
												elements={unidadeStore.espanhaComunidadesAutonoma || []}
												menuPortalTarget={document.body}
											/>
										</ItemGrid>
										<ItemGrid xs={6} label={'Província:'} bold>
											<SelectSearch
												placeholder={'Província'}
												value={unidadeStore.provincia}
												isDisabled={!unidadeStore.comunidadeAutonoma}
												onChange={this.onEspanhaProvinciaChange}
												className={classes.inputEnderecoEspanha}
												elements={unidadeStore.espanhaProvincias || []}
												menuPortalTarget={document.body}
											/>
										</ItemGrid>
										<ItemGrid xs={6} label={'Cidade:'} bold>
											<TextFieldSearch
												placeholder="Cidade"
												classNotched={classes.notchedOutline}
												classInput={errors.espanhaMunicipio ? classes.selectError : classes.inputTextField}
												classIcons={classes.classIcons}
												loadOptions={this.loadSelectEspanhaMunicipio}
												withPaginate
												value={unidadeStore.unidade.espanhaMunicipio}
												onChange={this.onEspanhaMunicipioChange}
												debounceTimeout={300}
												additional={{
													page: 0
												}}
												key={unidadeStore.provincia?.value}
												isDisabled={!unidadeStore.provincia}
												menuInTop
											/>
										</ItemGrid>
									</> :
									<>
										<ItemGrid size={12} label={"Estado:"} bold>
											<SelectSearch
												name={"estado"}
												placeholder={"Estado"}
												value={objView.estado}
												className={classNames(classes.inputContainer, errors.estados ? classes.inputError : null)}
												onChange={(e) => this.onEstadoChange(e)}
												elements={unidadeStore.estados}
											/>
										</ItemGrid>
										<ItemGrid size={12} label={"Município:"} bold>
											<SelectSearch
												name={"municipio"}
												placeholder={"Municipio"}
												value={objView.municipio}
												className={classNames(classes.inputContainer, errors.municipio ? classes.inputError : null)}
												onChange={(e) => this.onMunicipioChange(e)}
												elements={unidadeStore.municipios}
											/>
										</ItemGrid>
									</>
								}
							</CardDadosCadastrais>
						</div>
					</div>
				</div>
				<Notification
					close={this.handleClose}
					reset={this.resetNotification}
					isOpen={unidadeStore.notification.isOpen}
					variant={unidadeStore.notification.variant}
					message={unidadeStore.notification.message}
				/>

				{termoUsoStore.isOpenTermoUsoModal && (
					<TermoUsoModal
						open={termoUsoStore.isOpenTermoUsoModal}
						documento={termoUsoStore.termoUso}
						tipo={"VIZI_PROFISSIONAL_SAUDE"}
						titulo={`Termos e condições da plataforma ${getWhitelabelNome()}`}
						handleAccept={this.handleAssinarTermoUsoVizi}
						handleRefuse={termoUsoStore.closeModal}
					/>
				)}

				<AdicionarCodigoTvModal
					rede={unidadeStore.unidade}
					open={this.state.openAdicionarCodigoTvModal}
					onClose={this.onCloseAdicionarCodigoTv}
					onSave={this.saveCodigoTv}
					unidadeQuantity={this.state.unidadesQuantity}
				/>
				<AdicionarCodigoTotemModal
					onClose={() => this.setState({ openAdicionarCodigoTotemModal: false })}
					open={this.state.openAdicionarCodigoTotemModal}
					onSave={this.saveCodigoTotem}
				/>
				<div className={classes.acoes}>
					<Fab
						className={classes.buttonSalvar}
						onClick={this.update}
						disabled={unidadeStore.loadingSaveDadosUnidade || unidadeStore.isLoadingInfo}
					>
						<SaveIcon />
					</Fab>
					<Fab className={classes.buttonCancelar} onClick={this.cancelar}>
						<CloseIcon />
					</Fab>
				</div>
			</div>
		);
	}
};

export default withStyles(styles)(PerfilUnidade);