import React from 'react';
import styled from 'styled-components';
import { fontColor } from '../../../assets/jss/appHealth.style';
import ProcedimentoEdit from './ProcedimentoEdit';
import { Button } from '../../../components/ui/Buttons';
import TrashIcon from '../../../components/Icon/Trash';

const SolicitacaoProcedimentoList = ({
  procedimentos,
  handleEdit,
  handleRemove,
  classes,
  handleChangeProcedimento,
  loadProcedimentos,
  handleConfirmar,
  procedimentoEdit,
}) => (
  <Container>
    <div>
      {procedimentos.map((procedimento, index) =>
        !procedimento.isEditing ? (
          <Recipe key={index + 1}>
            <ProcedimentoInformation>
              <InformationItem>
                <ItemNumero>
                  {`${index+1}`.padStart(2,"0")}
                  .
                </ItemNumero>
                <ItemLabel>{procedimento.label || procedimento.nome}</ItemLabel>
              </InformationItem>
            </ProcedimentoInformation>
            {
              <Actions>
                <Button
                  bgColor={"#FB7676"}
                  shape={"circle"}
                  onClick={() => handleRemove(procedimento.id, index)}
                >
                  <TrashIcon size='24'/>
                </Button>
              </Actions>
            }
          </Recipe>
        ) : (
          <div key={index + 1}>
            <Recipe style={{ position: 'relative', zIndex: 2, alignItems: 'flex-end' }}>
              <ProcedimentoEdit
                procedimento={procedimento}
                handleChangeProcedimento={handleChangeProcedimento}
                loadProcedimentos={loadProcedimentos}
                handleConfirmar={handleConfirmar}
                procedimentoEdit={procedimentoEdit}
              />
            </Recipe>
          </div>
        ),
      )}
    </div>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Recipe = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.725rem 1.25rem;
  color: ${fontColor};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 8px 0;
`;

const ProcedimentoInformation = styled.div`
  margin-right: 15px;
`;

const InformationItem = styled.p`
  font-size: 1rem;
  margin: 0;
  color: ${fontColor};
  white-space: nowrap;
  display: flex;
  @media (max-width: 900px) {
    white-space: normal !important;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ItemLabel = styled.span`
  white-space: initial;
  font-size: 14px;
  font-weight: 600;
  color: #868686;
  @media (max-width: 900px) {
    white-space: normal !important;
  }
`;

const ItemNumero = styled.span`
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #868686;
`;

export default SolicitacaoProcedimentoList
