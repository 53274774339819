export const ITENS_TO_VERIFY = [
  value => ({
    isValid: value.match(/[0-9]/g),
    message: '1 número(s)',
  }),
  value => ({
    isValid: value.match(/[A-Z]/g),
    message: '1 letra maiúscula',
  }),
  value => ({
    isValid: value.match(/[a-z]/g),
    message: '1 letra minúscula',
  }),
  value => ({
    isValid: value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g),
    message: '1 caractere especial',
  }),
  value => ({
    isValid: value.length >= 8,
    message: '8 dígitos',
  }),
]

const passwordRegex =  /^(?=.*[!@#$%^&*()_+\-=])(?=.*[A-Z])(?=.*[a-z]).{8,}$/

export const isValidPassword = (password) => {
  return passwordRegex.test(password);
}
