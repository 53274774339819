import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import styles from "./RecuperarSenhaStyles";
import Notification from "../../components/Notification";
import PageTitle from "../../components/PageTitle/PageTitle";
import LoginRecuperarSenha from "../Login/LoginRecuperarSenha";
import { ArrowLeft } from "../../assets/img/svg";
import AlterarSenha from "./AlterarSenha";
import EnviarCpf from "./EnviarCpf";

const RecuperarSenha = observer((props) => {
  const { classes, recuperarSenhaStore, location, history } = props;
  const [loadingPage, setLoadingPage] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    validarToken();
  }, []);

  const validarToken = async () => {
    const urlParams = queryString.parse(location.search);
    if (urlParams?.token) {
      try {
        await recuperarSenhaStore.validarToken(urlParams.token);
        setLoadingPage(false);
        setToken(urlParams.token);
      } catch (error) {
        setLoadingPage(false);
        setToken(null);
      }
    } else {
      setLoadingPage(false);
      setToken(null);
    }
  };

  return (
    <div>
      <PageTitle title="Recuperar senha" />
      <LoginRecuperarSenha>
        {loadingPage && (
          <Grid
            container
            justify="center"
            alignContent="center"
            style={{ flex: 1 }}
          >
            <CircularProgress size={32} />
          </Grid>
        )}
        {!loadingPage && (
          <div className={classes.content}>
            <div className={classes.titulo}>Recuperar senha</div>
            {!token ? <EnviarCpf /> : <AlterarSenha token={token} history={history} />}
            <Link to="login" className={classes.voltarLoginLinkStyle}>
              <img
                src={ArrowLeft}
                alt="seta esquerda"
                className={classes.arrowLeftIcon}
              />
              <div className={classes.textoVoltarLogin}>
                Voltar para acessar a conta
              </div>
            </Link>
          </div>
        )}
      </LoginRecuperarSenha>
      <Notification
        close={(event, reason) =>
          recuperarSenhaStore.closeNotification(event, reason)
        }
        reset={() => recuperarSenhaStore.resetNotification()}
        isOpen={recuperarSenhaStore.notification.isOpen}
        variant={recuperarSenhaStore.notification.variant}
        message={recuperarSenhaStore.notification.message}
      />
    </div>
  );
});

const RecuperarSenhaWithStyles = withStyles(styles)(RecuperarSenha);
export default inject("recuperarSenhaStore")(RecuperarSenhaWithStyles);
