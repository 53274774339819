import React from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles/index";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import ButtonFloat from "../../../components/ButtonFloat";
import styles from "../../../assets/jss/pages/medicamentoPersonalizadoStyle";
import MedicamentoModal from "./MedicamentoModal";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import Notification from "../../../components/Notification";
import { getMedicamentosColumns } from "./getMedicamentosColumns";
import { ativarMedicamento, inativarMedicamento } from "../../../services/MedicamentoService";
@inject("medicamentoPersonalizadoStore", "googleAnalyticsStore")
@observer
class MedicamentosPersonalizados extends React.Component {
  componentDidMount() {
    const { medicamentoPersonalizadoStore } = this.props;
    medicamentoPersonalizadoStore.findAllMedicamentoPersonalizado({
      pageNumber: 0
    });

    const paginaVisualizada = {
      page: window.location.hash,
      title: 'Configurações - Medicamentos personalizados'
    }
    this.props.googleAnalyticsStore.pageView(paginaVisualizada);
  }

  handleSearchChange = e => {
    const { medicamentoPersonalizadoStore } = this.props;
    const search = e.target.value;
    medicamentoPersonalizadoStore.search = search;
    medicamentoPersonalizadoStore.pageableDTO.pageNumber = 0;
  };

  handleClickSearch = () => {
    const { medicamentoPersonalizadoStore } = this.props;

    medicamentoPersonalizadoStore.clearList();
    medicamentoPersonalizadoStore.findAllMedicamentoPersonalizado();
  };

  onKeypressSearch = (e) => {
    const { medicamentoPersonalizadoStore } = this.props;
    if (e.key === "Enter" && medicamentoPersonalizadoStore.search.length >= 3) {
      medicamentoPersonalizadoStore.clearList();
      medicamentoPersonalizadoStore.findAllMedicamentoPersonalizado();
    }
  };

  handleClickClear = async () => {
    const { medicamentoPersonalizadoStore } = this.props;

    medicamentoPersonalizadoStore.search = '';
    medicamentoPersonalizadoStore.clearList();
    medicamentoPersonalizadoStore.findAllMedicamentoPersonalizado();
  };

  loadMore = () => {
    const { medicamentoPersonalizadoStore } = this.props;

    if (
      medicamentoPersonalizadoStore.loading ||
      medicamentoPersonalizadoStore.last
    ) {
      return;
    }

    const pageable = {
      ...medicamentoPersonalizadoStore.pageableDTO
    };
    pageable.pageNumber += 1;
    medicamentoPersonalizadoStore.findAllMedicamentoPersonalizado(pageable);
  };

  handleClickOpen(medicamentoId) {
    const { medicamentoPersonalizadoStore } = this.props;

    medicamentoPersonalizadoStore.edit(medicamentoId);
  }

  handleCreate = () => {
    const { medicamentoPersonalizadoStore } = this.props;

    medicamentoPersonalizadoStore.openNew();
  };

  handleClickStatusSwitch = async (medicamentoId) => {
    const { medicamentoPersonalizadoStore } = this.props;
    const medicamentoIndex = medicamentoPersonalizadoStore.elements.findIndex((medicamento) => medicamento.id === medicamentoId);
    const medicamento = medicamentoPersonalizadoStore.elements[medicamentoIndex];

    try {
      const medicamentoUpdated = medicamento.ativo ? 
        await inativarMedicamento(medicamentoId) : 
        await ativarMedicamento(medicamentoId); 
      medicamentoPersonalizadoStore.elements[medicamentoIndex] = medicamentoUpdated;
    } catch(error) {
      medicamentoPersonalizadoStore.notification = {
        message: error.message,
        variant: 'error',
        isOpen: true
      }
    }
  }

  handleClickOrdenar = (value) => {
    const { medicamentoPersonalizadoStore } = this.props;
    const { pageableDTO } = medicamentoPersonalizadoStore;

    const sortDir = pageableDTO.sortField !== value ? "ASC" : (pageableDTO.sortDir === "ASC" ? "DESC" : "ASC")
    const status = value === 'ativo' ? sortDir === 'ASC' : medicamentoPersonalizadoStore.ativoValue;

    const updatedPageableDTO = {
      sortField: value,
      sortDir,
      pageNumber: 0,
    }

    medicamentoPersonalizadoStore.pageableDTO = {
      ...medicamentoPersonalizadoStore.pageableDTO,
      ...updatedPageableDTO,
    }
    medicamentoPersonalizadoStore.ativoValue = status;
    medicamentoPersonalizadoStore.findAllMedicamentoPersonalizado();
  };

  render() {
    const { classes, medicamentoPersonalizadoStore } = this.props;
    const { search, open, elements, last, loading, pageableDTO } = medicamentoPersonalizadoStore;
    const hasMore = !last;
    const columns = getMedicamentosColumns(this.handleClickStatusSwitch);

    return (
      <div className={classes.container}>
        <PageTitle title={pageConfiguracoes("Medicamentos personalizados")} />
        <HeaderConfiguracoesButtonNovo
          title="Medicamentos personalizados"
          labelButton="Novo Medicamento"
          handleClickNovo={this.handleCreate}
          search={{
            handleChange: this.handleSearchChange,
            value: search,
            onKeypress: this.onKeypressSearch,
            handleClickClear: this.handleClickClear,
            onClickButtonSearch: this.handleClickSearch
          }}
        />

        <div className={classes.content}>
          <div className={classes.tableContainer}>
            <Scroll
              loadMore={this.loadMore}
              hasMore={hasMore}
              className={classes.scrollContainer}
              isLoading={loading}
            >
              {elements?.length > 0 &&
                <Table
                  dados={elements}
                  columns={columns}
                  clickable={true}
                  handleClick={this.handleClickOpen.bind(this)}
                  ordenarTabela={{
                    sortField: pageableDTO.sortField,
                    sortDir: pageableDTO.sortDir
                  }}
                  comOrdenacao
                  handleClickOrdenar={this.handleClickOrdenar}
                />
              }
            </Scroll>
          </div>

          <ButtonFloat onClick={() => this.handleCreate()} />
        </div>
        {open && <MedicamentoModal open={open} />}
        <Notification
          close={() => medicamentoPersonalizadoStore.closeNotification()}
          reset={() => medicamentoPersonalizadoStore.closeNotification()}
          isOpen={medicamentoPersonalizadoStore.notification.isOpen}
          variant={medicamentoPersonalizadoStore.notification.variant}
          message={medicamentoPersonalizadoStore.notification.message}
        />
      </div>
    );
  };
};

export default withStyles(styles)(MedicamentosPersonalizados);
