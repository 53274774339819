import React, { useEffect, useReducer } from 'react';

import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';

import { withStyles } from '@material-ui/core';
import { Button } from '../../../../components/ui/Buttons';

import CloseIcon from '../../../../components/Icon/CloseIcon';
import MessagesList from '../../components/MessagesList';
import ChatInput from '../../components/ChatInput';
import { calculatePopupPosition } from './utils/utils';
import HorizontalLineIcon from '../../../../components/Icon/HorizontalLineIcon';

const styles = ({
  popupContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '270px',
    position: 'absolute',
    bottom: '0',
  },
  popupHeaderButtons: {
    display: 'flex',
  },
  chatPopupHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#3DC4BE',
    color: 'white',
    padding: '8px',
    borderRadius: '12px 12px 0 0',
    cursor: 'pointer'
  },
  chatPopupContent: {
    display: 'flex',
    flexDirection: 'column',
    transition: '1s',
    height: '280px',
    backgroundColor: '#FFF'
  },
});

const ChatPopup = observer(({ classes, selectedContact, index, chatStore }) => {
  const [open, togglePopup] = useReducer((open) => !open, true);
  const { contact } = selectedContact;

  const popupHorizontalPosition = {
    right: `${calculatePopupPosition(parseInt(index), 270, 16)}px`
  };

  const handleClosePopup = (event) => {
    event.stopPropagation();
    chatStore.closePopup(contact?.id);
  }

  useEffect(() => {
    if (open) {
      chatStore.willScrollToBottom = true;
    }
  }, [open])

  const { nome } = contact?.profissionalSaudeContato || {};
  return (
    <div className={classes.popupContainer} style={popupHorizontalPosition}>
      <div className={classes.chatPopupHeader} onClick={() => togglePopup()}>
        <span className={classes.contactName}>
          {nome}
        </span>
        <div className={classes.popupHeaderButtons} >
          {open && (
            <Button
              kind="transparent"
              shape="circle" 
              size="mini"
              onClick={(event) => {
                event.stopPropagation();
                togglePopup()
              }}
            >
              <HorizontalLineIcon size={20} color="#FFF" />
            </Button>
          )}
          <Button
            kind="transparent"
            shape="circle" 
            size="mini"
            onClick={handleClosePopup}
          >
            <CloseIcon color="#FFF" size={18} />
          </Button>
        </div>
      </div>
      {open && (
        <div className={classes.chatPopupContent} style={{ height: open ? '280px' : '0' }}>
          <MessagesList
            isPopup
            selectedContact={selectedContact}
          />
          <ChatInput
            isPopup
            popupContact={selectedContact}
          />
        </div>
      )}
    </div>
  )
});

const ChatPopupStyles = withStyles(styles)(ChatPopup); 
const stores = ['chatStore'];
export default inject(...stores)(ChatPopupStyles);
