import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PlayIcon = props => {
  return (
    <svg
      width='27'
      height='28'
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M10.2172 4.59751C8.57966 3.70475 6.58687 4.90594 6.6116 6.77083L6.80555 21.4011C6.83027 23.2659 8.85413 24.4139 10.4674 23.4781L23.3751 15.9908C25.02 15.0367 24.9884 12.6507 23.3188 11.7405L10.2172 4.59751Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PlayIcon
