import moment from 'moment';

export const getAudioDuration = async (url) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  try {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    audioContext.close();
    return audioBuffer.duration;
  } catch (error) {
    console.error(error);
  }
};

export const formatAudioDuration = (duration) => {
  let audioDurationFormated = moment.duration(duration, 'second');
  audioDurationFormated = moment.utc(audioDurationFormated.asMilliseconds()).format('mm:ss');
  return audioDurationFormated;
};
