const styles = theme => ({
  root: {
    padding: 0,
    position: 'relative',
    height: '100vh',
  },

  headerTitleRoot: {
    maxWidth: theme.commons.menuLeft.width
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    padding: '24px 10% 24px 24px',
    gap: '10%',
    '@media (max-width: 820px)': {
      overflow: 'visible',
      padding: 0
    }
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    paddingTop: '16px',
    gap: '16px',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    flex: 1
  },

  titleFilterContainer: {
    display: 'flex',
    flexDirection: 'row', 
    alignItems: 'center',
  },

  titleHeader: {
    whiteSpace: 'nowrap',
    fontSize: 18,
    textAlign: "center",
    fontWeight: 700,
    color: '#000',
    margin: '0 15px 5px',
  },

  inputsContainer: {
    display: 'flex',
    gap: '16px',
    marginBottom: '16px',
    padding: '0 16px',
    flexWrap: 'wrap'
  },

  inputGroup: {
    display: 'flex',
    gap: '16px',
  },

  dateLabel: {
    fontSize: 12,
    color: '#868686',
    fontWeight: 400
  },

  durationInputRoot: {
    backgroundColor: '#F2F2F2',
    border: 'solid 1px #DCDCDC33',
    marginTop: '20px !important',
    maxWidth: '180px'
  },
  
  durationInputLabel: {
    color: '#868686',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18px',
    fontFamily: 'Poppins'
  },

  durationInputFocused: {
    color: '#868686',
  },

  scroller: {
    height: '100%',
    '@media (max-width: 820px)': {
      overflowY: 'auto',
      border: 'none',
      margin: '24px 80px 24px 24px',
      paddingRight: '64px',
    },
    "&::-webkit-scrollbar": {
      width: "16px !important",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: "100px",
      padding: "6px 4px"
    },
    "&::-webkit-scrollbar-thumb:vertical": {
      width: "8px !important",
      borderRadius: "100px",
    },
  },

  agenda: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'calc(100vh - 252px)',
    padding: 0,
    '@media (max-width: 820px)': {
      overflow: 'visible',
    }
  },

  contentLoadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },

  notFoundTitle: {
      fontSize: '1.2rem',
      color: theme.palette.primary.main,
      fontWeight: 'normal',
  },

  tempoAtendimentoInput: {
    background: '#eeeeee',
    height: '32px',
    borderRadius: '8px',
    "& input": {
      fontFamily: 'Poppins',
      padding: '0px',
      color: '#868686',
      fontSize: 14,
      textAlign: 'center',
    },

    "&>div>div": {
      border: `0.5px solid rgba(220, 220, 220, 0.2)`,
      height: '32px',
      justifyContent: 'center',
      padding: 0,
    },
  },
  acoes: {
		position: "absolute",
		right: "5px",
		bottom: "45%",
		display: "flex",
		flexDirection: "column",
    '@media (max-width: 820px)': {
      right: '5px'
    }
	},

  buttonCancelar: {
		margin: 8,
		height: 40,
		width: 40,
		color: '#fff',
		background: theme.palette.commons.red,
		"&:hover": {
			background: theme.palette.commons.darkRed,
		}
	},
  
  buttonsTab: {
    margin: '0 16px'
  },

  titleHeaderInformacoes: {
    color: theme.palette.primary.main,
  },

  dateHora: {
    borderRadius: 8,
  },

  dateTime: {
    width: '100px',
    "&>div": {
      border: '0.5px solid rgba(220, 220, 220, 0.2)',
      backgroundColor: '#F2F2F2'
    },

    "& input": {
      borderRadius: 8,
      padding: '0px 0px 0px 12px',
    },

    "& button": {
      backgroundColor: '#EEEEEE',
    },

    "& p": {
      display: 'none',
    },
  },

  date: {
    width: 132,
    borderWidth: 0.5
  },

  searchBlock: {
    marginBottom: 4,
  },

  contentTempoAtendimento: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

});

export default styles;
