export const getNewAnamnese = (anamneseSelected, anamneseResponse) => {
  const newAnamnese = anamneseSelected?.perguntas.map(pergunta => {
    const keys = Object.keys(anamneseResponse[pergunta?.id] || {})
    const respostas = pergunta?.respostas.map(resposta => {
      const key = keys.find(key => key === resposta?.id)

      return {
        ...(pergunta?.tipo === 'RADIO' || pergunta?.tipo === 'CHECKBOX'
          ? {
              id: resposta?.id,
              nome: resposta?.nome === '' ? anamneseResponse[pergunta?.id]?.[key] : resposta?.nome,
              valor: key === resposta?.id ? !!anamneseResponse[pergunta?.id]?.[key] : false,
            }
          : {
              id: resposta?.id,
              nome: resposta?.nome,
              valor: anamneseResponse[pergunta?.id]?.[key],
            }),
      }
    })
    return {
      ...pergunta,
      respostas,
    }
  })
  return newAnamnese
}
