import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles, FormControlLabel, Switch } from "@material-ui/core/es";
import { InputForm } from "../../../../components/Modal/Input";
import ItemGrid from "../../../../components/Modal/ItemGridCard";
import { ElementsList } from "../../constants/perfilViziConstants";
import styles from "../../../../assets/jss/pages/profissionalSaudeStyle";
import ProfileImageAndBanner from "../../components/ProfileImageAndBanner";
import CardDadosCadastrais from "../../../../components/Cards/CardDadosCadastrais";
import { InputForm as InputTextArea } from "../../../../components/Input/InputDadosCadastraisPaciente";
import { socialMediaLinks } from "../../../../utils/socialMediaLinks";

const Customização = observer(
  ({
    classes,
    profissionalSaudeStore,
    perfilPublicoStore,
  }) => {
    const { profissionalSaude } = profissionalSaudeStore;

    const onFieldRedesSociaisChange = (value, campo) => {
      const isValueSocialMediaBaseUrl =
        socialMediaLinks[campo]?.length - 1 === value?.length;
      if (isValueSocialMediaBaseUrl) return null;
      const valueWithoutDomain = value.replaceAll(socialMediaLinks[campo], "");

      if (value.includes("http")) {
        perfilPublicoStore.perfilPublicoProfissional = {
          ...perfilPublicoStore.perfilPublicoProfissional,
          [campo]: value,
        };
      } else {
        perfilPublicoStore.perfilPublicoProfissional = {
          ...perfilPublicoStore.perfilPublicoProfissional,
          [campo]: socialMediaLinks[campo] + valueWithoutDomain,
        };
      }
    };

    const handleSelectMedidasSeguranca = () => {
      perfilPublicoStore.perfilPublicoProfissional.exibeMedidasSeguranca = !perfilPublicoStore
        .perfilPublicoProfissional.exibeMedidasSeguranca;
    };

    const handleUpdateProfile = async (blob) => {
      try {
        await perfilPublicoStore.updateProfileImage(
          perfilPublicoStore.perfilPublicoProfissional.id,
          blob,
          "profissional"
        );
        perfilPublicoStore.checkPerfilProfissionalIsComplete();
        profissionalSaudeStore.openNotification(
          "Foto de perfil do profissional alterada com sucesso",
          "success"
        );
      } catch (error) {
        profissionalSaudeStore.openNotification(error, "error");
      }
    };

    const handleUpdateBanner = async (blob) => {
      try {
        await perfilPublicoStore.updateBannerImage(
          perfilPublicoStore.perfilPublicoProfissional.id,
          blob,
          "profissional"
        );
        profissionalSaudeStore.openNotification(
          "Banner do perfil do profissional alterado com sucesso ",
          "success"
        );
      } catch (error) {
        profissionalSaudeStore.openNotification(error, "error");
      }
    };

    const handleDeleteBanner = async () => {
      try {
        await perfilPublicoStore.removeBannerImage(
          perfilPublicoStore.perfilPublicoProfissional.id,
          "profissional"
        );
        profissionalSaudeStore.openNotification(
          "Banner do perfil do profissional removido com sucesso",
          "success"
        );
      } catch (error) {
        profissionalSaudeStore.openNotification(error, "error");
      }
    };

    const handleDeleteProfileImage = async () => {
      try {
        await perfilPublicoStore.removeProfileImage(
          perfilPublicoStore.perfilPublicoProfissional.id,
          "profissional"
        );
        perfilPublicoStore.checkPerfilProfissionalIsComplete();
        profissionalSaudeStore.openNotification(
          "Foto de perfil do profissional removida com sucesso",
          "success"
        );
      } catch (error) {
        profissionalSaudeStore.openNotification(error, "error");
      }
    };

    return (
      <>
        <div className={classes.contentColumn}>
          <CardDadosCadastrais titulo="Horário de atendimento">
            <ItemGrid>
              <InputTextArea
                name={ElementsList.HORARIO_ATENDIMENTO}
                value={
                  perfilPublicoStore.perfilPublicoProfissional
                    .horarioAtendimento || ""
                }
                multiline
                rowsMax={5}
                rows={5}
                className={[
                  classes.inputContainer,
                  classes.withoutMarginBottom,
                  classes.textBox,
                ]}
                onChange={(e) =>
                  perfilPublicoStore.onFieldChange(
                    e.target.value,
                    ElementsList.HORARIO_ATENDIMENTO
                  )
                }
                inputProps={{ maxLength: 500 }}
              />
              <span className={classes.boxObservacoesCharCounter}>
                {`${perfilPublicoStore.perfilPublicoProfissional
                  .horarioAtendimento?.length || 0}/500`}
              </span>
            </ItemGrid>
            <div className={classes.withSwitchInTitle}>
              <ItemGrid label="Medidas de segurança" bold>
                <span
                  className={classes.boxObservacoesCharCounter}
                  style={{ float: "left" }}
                >
                  Informe os seus pacientes as possíveis medidas de segurança
                  utilizadas. Exemplo: Uso de máscaras, álcool em gel, etc...
                </span>
              </ItemGrid>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      perfilPublicoStore.perfilPublicoProfissional
                        .exibeMedidasSeguranca
                    }
                    color="primary"
                  />
                }
                onChange={() => handleSelectMedidasSeguranca()}
                labelPlacement="start"
                className={classes.controller}
              />
            </div>
            {perfilPublicoStore.perfilPublicoProfissional
              .exibeMedidasSeguranca && (
              <div>
                <InputTextArea
                  name={ElementsList.MEDIDAS_SEGURANCA}
                  value={
                    perfilPublicoStore.perfilPublicoProfissional
                      .medidasSeguranca || ""
                  }
                  placeholder="Uso de máscaras, álcool em gel, etc..."
                  multiline
                  rowsMax={5}
                  rows={5}
                  className={[classes.inputContainer, classes.textBox]}
                  style={{ margin: 0 }}
                  onChange={(e) =>
                    perfilPublicoStore.onFieldChange(
                      e.target.value,
                      ElementsList.MEDIDAS_SEGURANCA
                    )
                  }
                  inputProps={{ maxLength: 500 }}
                />
                <span className={classes.boxObservacoesCharCounter}>
                  {`${perfilPublicoStore.perfilPublicoProfissional
                    .medidasSeguranca?.length || 0}/500`}
                </span>
              </div>
            )}
          </CardDadosCadastrais>
          <CardDadosCadastrais titulo={"Redes Sociais"}>
            <span>Para cada rede social digite apenas o nome de usuário</span>
            <ItemGrid size={12} label="Facebook" bold>
              <InputForm
                className={classes.inputContainer}
                onChange={(e) =>
                  onFieldRedesSociaisChange(
                    e.target.value,
                    ElementsList.FACEBOOK
                  )
                }
                value={
                  perfilPublicoStore.perfilPublicoProfissional.facebook || ""
                }
              />
            </ItemGrid>
            <ItemGrid size={12} label="Instagram" bold>
              <InputForm
                className={classes.inputContainer}
                onChange={(e) =>
                  onFieldRedesSociaisChange(
                    e.target.value,
                    ElementsList.INSTAGRAM
                  )
                }
                value={
                  perfilPublicoStore.perfilPublicoProfissional.instagram || ""
                }
              />
            </ItemGrid>
            <ItemGrid size={12} label="Linkedin" bold>
              <InputForm
                className={classes.inputContainer}
                onChange={(e) =>
                  onFieldRedesSociaisChange(
                    e.target.value,
                    ElementsList.LINKEDIN
                  )
                }
                value={
                  perfilPublicoStore.perfilPublicoProfissional.linkedin || ""
                }
              />
            </ItemGrid>
            <ItemGrid size={12} label="Twitter" bold>
              <InputForm
                className={classes.inputContainer}
                onChange={(e) =>
                  onFieldRedesSociaisChange(
                    e.target.value,
                    ElementsList.TWITTER
                  )
                }
                value={
                  perfilPublicoStore.perfilPublicoProfissional.twitter || ""
                }
              />
            </ItemGrid>
          </CardDadosCadastrais>
        </div>
        <div className={classes.contentColumn}>
          <ProfileImageAndBanner
            profileImageId={
              perfilPublicoStore.perfilPublicoProfissional.fotoPerfil
            }
            bannerImageId={perfilPublicoStore.perfilPublicoProfissional.banner}
            updateProfile={handleUpdateProfile}
            updateBanner={handleUpdateBanner}
            removeProfileImage={handleDeleteProfileImage}
            removeBanner={handleDeleteBanner}
            token={perfilPublicoStore.accessToken}
            loadingProfileImage={perfilPublicoStore.loadingProfileImage}
            loadingBannerImage={perfilPublicoStore.loadingBannerImage}
            disabled={
              !profissionalSaude.utilizaVIZI ||
              !perfilPublicoStore.perfilPublicoProfissional.id
            }
            corPersonalizada={
              perfilPublicoStore.perfilPublicoProfissional.corPersonalizada
            }
            field={"perfilPublicoProfissional"}
          />
        </div>
      </>
    );
  }
);

const CustomizaçãoWithStyles = withStyles(styles)(Customização);
export default inject(
  "profissionalSaudeStore",
  "perfilPublicoStore",
)(CustomizaçãoWithStyles);
