const styles = {
    content: {
        background: '#f2f2f2',
        width: '100%',
    },
    contentSolicitacoes: {
        margin: '16px 16px 0 16px',
        background: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '16px 16px 0 0',
        height: 'calc(100% - 199px)',
    },
    buttonsTab: {
        margin: '16px',
    },
};

export default styles;