export default theme => ({
    dayCalendar: {
        fontFamily: "Poppins!important",
        padding: '0 0 0 0',
        overflow: 'auto'
    },

    iconOption: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
    },

    actions: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        zIndex: 4,
    },

    dayCalendarInner: {
        fontFamily: "Poppins!important",
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'hidden',
        alignItems: 'flex-start'
    },
    
    dayCalendarHeaderInner: {
        fontFamily: "Poppins!important",
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'hidden',
        minHeight: '64px'
    },
    dayCalendarColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: '250px',
        height: '40px',
        minHeight: '40px',
    },
    dayCalendarColumnHeader: {
        fontFamily: "Poppins!important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minWidth: '200px',
        height: '64px',
        backgroundColor: '#219A97',
    },

    ColumnHeaderHour: {
        width: '79px', 
        minWidth: '79px', 
        backgroundColor: '#26ACA9', 
        alignItems: 'center' 
    },
    dayCalendarColumnHeaderLabel: {
        color: '#F9F9F9',
        fontSize: '16px!important',
        fontFamily: "Poppins!important",
        fontWeight: 700,
        userSelect: 'none',
        marginLeft: '20px'
    },

    dayCalendarColumnHeaderLabelHour: {
        color: '#F9F9F9',
        fontSize: '16px!important',
        fontFamily: "Poppins!important",
        fontWeight: 700,
        userSelect: 'none',
        alignItems: 'center', 
        marginLeft: '0px' 
    },

    dayCalendarColumnHeaderTipo:{
        fontFamily: "Poppins!important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '110px !important',
        height: '64px',
        backgroundColor: '#219A97'
    },
    dayCalendarColumnHeaderTelefone:{
        fontFamily: "Poppins!important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '160px',
        height: '64px',
        backgroundColor: '#219A97'
    },

    weekCalendarColumnHeaderDayWeekLabel: {
        color: theme.palette.primary.main,
        fontSize: '0.9em !important',
        fontWeight: 'lighter',
        userSelect: 'none'
    },
    dayCalendarEvent: {
        width: '100%',
        minHeight: '44px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        borderBottom:'1px solid #b5b5b5',
        
        "&>div": {
            fontSize: '1.1em !important',
            color: '#ccc',
            userSelect: 'none'
        },
        
        '&>div[role="tooltip"]': {
            top: '30% !important',
            left: '10px !important',
            zIndex: 3
        },
        '&.last-day > div[role="tooltip"]': {
            left: 'auto !important',
            right: '5px !important',
        }
    },
    dayCalendarColumnLabel: {
        color: '#BDBDBD!important',
        fontSize: '14px!important',
        fontFamily: "Poppins!important",
        fontWeight: 700,
    },
    rowMaior: {
        minHeight: '60px',
    },
});
