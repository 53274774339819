import React from "react";
import Header from "../../template/Header/Header";
import Profile from "../../template/Header/Profile";
import InputSearchRetangular from "../../components/Input/InputSearchRetangular";
import { withStyles } from "@material-ui/core";
import InputSearchFilter from "../../components/Input/InputSearchFilter";
import colors from "../../template/Colors";
import PeriodDate from "../../components/PeriodDate/PeriodDate";
import { ReactSelect } from "../../components/Select/SelectSearch";
import { Button } from "../../components/ui/Buttons";

const optionsStatus = [
  { label: "Ativo", value: true },
  { label: "Inativo", value: false },
];

const HeaderFinanceiro = (props) => {
  const {
    classes,
    withzindex,
    withFilter,
    propsDate,
    title,
    propsButton,
    propsStatus,
    ...others
  } = props;
  return (
    <Header
      padding
      className={[classes.header, withzindex && classes.zindex]}
      disableShadow
    >
      <div className={classes.contentHeaderSearchFilters}>
        <div className={classes.contentSearch}>
          <div className={classes.tituloSearch}>{title} </div>
          {withFilter ? (
            <InputSearchFilter {...others} />
          ) : (
            <InputSearchRetangular
              placeholder="Pesquisar"
              className={classes.inputSearch}
              {...others}
            />
          )}
        </div>
        <div className={classes.contentFilters}>
          {propsDate && (
            <div className={classes.filterDateContainer}>
              <span className={classes.tituloFiltros}> Filtrar por data: </span>
              <PeriodDate
                filter={others.filter}
                setFilter={others.setFilter}
                onChangeDate={propsDate.handleChange}
                handleAplicar={propsDate.handleAplicar}
                stylePopper={classes.zindex}
              />
            </div>
          )}
          {propsStatus && (
            <div>
              <span className={classes.tituloFiltros}> Status: </span>
              <ReactSelect
                className={classes.selectStatus}
                placeholder="Selecione"
                value={propsStatus.status ? optionsStatus[0] : optionsStatus[1]}
                onChange={propsStatus.handleStatus}
                options={optionsStatus}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </div>
          )}
          {propsButton && (
            <div className={classes.contentButton}>
              <Button onClick={propsButton.handleClickNew} fontSize="14px">
                {propsButton.label}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div>
        <Profile />
      </div>
    </Header>
  );
};

const style = {
  header: {
    height: "80px !important",
    background: "#fff",
    gap: "8px",
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
    justifyContent: "space-between",
  },
  contentHeaderSearchFilters: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  zindex: {
    zIndex: 100,
  },
  filtroData: {
    color: colors.commons.gray7,
    fontSize: "12px !important",
  },
  contentSearch: {
    display: "flex",
    flexDirection: "column",
  },
  tituloSearch: {
    color: "#000",
    fontWeight: 700,
    fontSize: "18px",
  },
  inputSearch: {
    height: "32px",
    width: "250px",
  },
  tituloFiltros: {
    color: colors.commons.gray7,
    fontSize: "12px !important",
    height: "27px",
    display: "flex",
    alignItems: "end",
  },
  filterDateContainer: {
    height: "70px",
    width: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectStatus: {
    "&>div>div": {
      border: "1px solid #DCDCDC",
      width: "110px",
      height: "32px",
    },
  },
  contentFilters: {
    display: "flex",
    gap: "16px",
  },
  contentButton: {
    alignSelf: "end",
  },
};

export default withStyles(style)(HeaderFinanceiro);
