import React, { useState } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'

import { Button } from '../../../components/ui/Buttons'

import { formatBytes } from '../../../utils/bytes'
import { downloadArquivo, downloadImage } from '../../../utils/downloadImagem'

import ArrowDownloadIcon from '../../../components/Icon/ArrowDownload'
import TrashIcon from '../../../components/Icon/Trash'
import { inativarDadosBraspag } from '../../../services/ProfissionalSaudeBraspagService'
import { buildUrlSolicitacaoPaciente } from '../../../config/config'
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../services/storage'

const ListFilesSelected = ({ classes, files, loading, showAlertMessage, refetch }) => {
  const [loadingDelete, setLoadingDelete] = useState(null)
  const getUrl = async item => {
    const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
    const url = buildUrlSolicitacaoPaciente(item.urlDownload, accessToken)
    if (item?.arquivoTipo === 'IMAGEM') {
      await downloadImage(item.amazonS3Objeto.nomeComExtensao, url)
    } else {
      await downloadArquivo(item.amazonS3Objeto.nomeComExtensao,url)
    }
    console.log(item.amazonS3Objeto.nomeComExtensao, item.urlDownload)
  }

  const handleRemoveFile = async (item, index) => {
    setLoadingDelete(index)
    try {
      await inativarDadosBraspag(item.id)
      await refetch()
    } catch (error) {
      showAlertMessage('Ocorreu um erro ao remover o arquivo', 'error')
    } finally {
      setLoadingDelete(null)
    }
  }

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.wrapperLoading}>
          <CircularProgress size={12} color={'primary'} />
        </div>
      )}
      {files.map((item, index) => {
        const { amazonS3Objeto } = item
        return (
          <div className={classes.wrapperFile} key={index}>
            <span>{amazonS3Objeto.nomeComExtensao}</span>
            <div className={classes.wrapperActions}>
              <span>{formatBytes(amazonS3Objeto.tamanho)}</span>
              {item?.urlDownload && (
                <Button kind='secondary' shape='circle' onClick={() => getUrl(item)}>
                  <ArrowDownloadIcon />
                </Button>
              )}
              <Button
                kind='secondary'
                shape='circle'
                onClick={() => handleRemoveFile(item, index)}
                bgColor='#FB7676'
                isLoading={loadingDelete === index}
              >
                <TrashIcon size='24' />
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  wrapperLoading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    '& span': {
      color: '#868686',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
    },
  },
  wrapperFile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      color: '#868686',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  wrapperActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    '& button': {
      width: 'auto',
      height: 'auto',
      padding: '4px !important',
      minHeight: '40px !important',
      minWidth: '40px !important',
    },
    '& span': {
      color: '#868686',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      padding: '0 16px',
    },
  },
}

export default withStyles(styles)(ListFilesSelected)
