import { inject } from "mobx-react";
import React, { useEffect, useState, useRef } from "react";
import ReactDOMServer from 'react-dom/server';
import ReactToPrint from "react-to-print";
import moment from "moment";
import { configuracaoImpressaoPage, htmlMustache } from "../../template/pdf/mustache/mustache";
import { findByIdSujeitoAtencao } from "../../services/SujeitoAtencaoService";
import localStorageService, { PROFISSIONAL_SAUDE_LOGADO_KEY } from '../../services/storage';

const customStyle = `
body { max-height: 90%;  
-webkit-print-color-adjust:exact !important;
print-color-adjust:exact !important; }`;

const ImpressaoHtml = ({
    configuracaoImpressaoStore,
    handlePrintMustache,
    htmlStringComponent,
    sujeitoAtencaoId,
    dadosSujeitoAtencao,
    unidadeStore,
    habilitaBotaoImpressao
}) => {

    const [html, setHtml] = useState('');
    const [sizeOrientacaoPage, setSizeOrientacaoPage] = useState('');

    const componentRef = useRef();

    useEffect(() => {
        handlePrint();

    }, [html]);

    const getDadosUnidade = async () => {
        const { nomeLogradouro, numero, bairro, complemento, municipio, estado } = unidadeStore.unidade.endereco;
        const { espanhaMunicipio } = unidadeStore.unidade;

        const endereco = unidadeStore.unidade?.telefonePrincipalDDI === "34" ?
            `${nomeLogradouro}, nº ${numero}, ${complemento}, ${bairro}/ ${espanhaMunicipio?.descricao}, ${espanhaMunicipio?.provincia?.descricao}, ${espanhaMunicipio?.provincia?.comunidadeAutonoma?.descricao}` :
            `${nomeLogradouro}, nº ${numero}, ${complemento}, ${bairro}/ ${municipio?.nome} - ${estado?.nome}`

        const unidade = {
            ...unidadeStore.unidade,
            enderecoString: endereco,
            endereco: endereco
        }
        return unidade;
    };

    const getDadosSujeitoAtencao = async () => {
        const sujeitoAtencao = await findByIdSujeitoAtencao(sujeitoAtencaoId);
        return sujeitoAtencao.data.data.findByIdSujeitoAtencao;
    };

    const handlePrint = async () => {
        const htmlString = await ReactDOMServer.renderToStaticMarkup(htmlStringComponent);
        const unidade = await getDadosUnidade();
        const profissionalSaude = await localStorageService.get(PROFISSIONAL_SAUDE_LOGADO_KEY);
        const sujeitoAtencao = sujeitoAtencaoId && await getDadosSujeitoAtencao();

        const sizeOrientacaoPage = configuracaoImpressaoPage(configuracaoImpressaoStore.configuracaoImpressao)
        const dataEmissao = moment().format('DD/MM/YYYY HH:mm');
        const modelo = configuracaoImpressaoStore.configuracaoImpressao.modelo;
        const dados = {
            configuracao: { ...configuracaoImpressaoStore.configuracaoImpressao },
            dataEmissao,
            unidade,
            profissionalSaude,
            sujeitoAtencao: sujeitoAtencao || dadosSujeitoAtencao,
            html: htmlString
        }
        const html = htmlMustache({ modelo, dados });
        setHtml(html);
        setSizeOrientacaoPage(sizeOrientacaoPage);

        printHtml();
    };

    const printHtml = () => {
        const elementReactToPrint = document.getElementById("reactToPrint");
        if (html && elementReactToPrint) {
            elementReactToPrint.click();
            handlePrintMustache();
        }
    }

    return (
        <div>
            <ReactToPrint
                trigger={() => <button id="reactToPrint" style={{ display: 'none' }} />}
                content={() => componentRef.current}
                pageStyle={sizeOrientacaoPage + customStyle}
                onAfterPrint={habilitaBotaoImpressao}
            />
            <div style={{ display: "none" }}>
                <div ref={componentRef} dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </div>
    )
}

export default inject("configuracaoImpressaoStore", "atendimentoStore", "unidadeStore", "sujeitoAtencaoStore")(ImpressaoHtml);
