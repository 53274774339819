import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    containerOpcoes: {
        padding: '0 16px',
        maxHeight: '120px',
        overflowY: 'auto',
    },
    opcao: {
        '& span': {
            fontSize: 12,
            color: '#7C7C7C !important',
            fontWeight: 900,
            '& span': {
                color: '#7C7C7C',
            },
        },
    },

});

class MultipleSelect extends React.Component {
    handleChange(event, element) {
        this.props.elements[event.target.name].checked = event.target.checked;
        this.props.onChange && this.props.onChange(event, element);
    };

    render() {
        return (
            <div className={this.props.classes.containerOpcoes}>
                <FormControl component="fieldset">
                    <FormGroup>
                        {this.props.elements.map((element, index) => (
                            <FormControlLabel className={this.props.classes.opcao}
                                              control={<Checkbox
                                                  checked={(this.props.permiteAcessoApp || []).some(p => p.id === element.value)}
                                                  onChange={(e) => this.handleChange(e, element)} name={index}/>}
                                              label={element.name}

                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </div>
        );
    }
}

export default withStyles(styles)(MultipleSelect);