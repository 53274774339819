import { withStyles } from "@material-ui/core";
import React from "react";
import Select, { components } from "react-select";
import Colors from "../../../../template/Colors";

const { Option, SingleValue } = components;

const IconOption = (props) => {
  return (
    <Option {...props}>
      <div style={{ display: "flex" }}>
        <img
          src={props.data.icon}
          alt={props.data.label}
          style={{ marginRight: 5 }}
        />
        {props.data.label}
      </div>
    </Option>
  );
};

const SingleValueIcon = ({ children, ...props }) => (
  <SingleValue {...props}>
    <div style={{ alignItems: "center", display: "flex" }}>
      <img
        src={props.data.icon}
        alt={props.data.label}
        style={{ marginRight: 5 }}
      />
      {children}
    </div>
  </SingleValue>
);

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <i
        className={`material-icons ${props.className} `}
        style={{
          fontFamily: "Poppins !important",
          color: Colors.commons.gray7,
          top: "calc(50% - 12px)",
          fontSize: "24px",
          borderRadius: "50%",
          right: 6,
          margin: "3px 5px",
          width: "24px",
        }}
      >
        {props.selectProps.menuIsOpen ? "arrow_drop_up" : "arrow_drop_down"}
      </i>
    )
  );
};

const SelectCustomIcon = (props) => {
  const { classes, label, options, disableMenuPortal } = props;
  return (
    <div className={classes.divLabel}>
      { label && <span className={classes.titulo}>{label}</span>}
      <Select
        {...props}
        components={{
          Option: IconOption,
          SingleValue: SingleValueIcon,
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
        styles={customStyles}
        options={options}
        menuPlacement={'auto'}
        menuPortalTarget={disableMenuPortal ? null : document.body}
      />
    </div>
  );
};

const styles = {
  divLabel: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 35,
  },
  titulo: {
    color: Colors.commons.gray7,
    fontSize: "12px !important",
    fontWeight: 400,
    marginBottom: 4,
  },
};

const customStyles = {
  menu: (provided) => ({
    ...provided,
    maxHeight: "20vh",
    "&> div": {
      maxHeight: "20vh",
    },
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#ccc"
      : state.isFocused
      ? "#e9e9e9"
      : "#fff",
    color: Colors.commons.fontColor,
    cursor: "pointer",
    fontFamily: "Poppins !important",
    fontSize: "14px",
    width: "100% !important",
    ":active": {
      backgroundColor: state.isSelected ? "#999" : "#e9e9e9",
      color: "black",
    },
  }),
  control: (provided) => ({
    ...provided,
    width: "100%",
    minHeight: 0,
    padding: "1px 0 1px 8px",
    fontSize: "14px",
    fontFamily: "Poppins !important",
    color: Colors.commons.black,
    backgroundColor: Colors.commons.gray2,
    borderRadius: "100px",
    cursor: "pointer",
    borderStyle: "none",
    maxHeight: "35px",
    lineHeight: 1.5,
    boxShadow: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: Colors.commons.darkGray,
    fontFamily: "Poppins !important",
  }),
  input: (provided) => ({
    ...provided,
    color: Colors.commons.black,
    opacity: "1 !important",
    fontFamily: "Poppins !important",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export default withStyles(styles)(SelectCustomIcon);
