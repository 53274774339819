import React from 'react'
import { inject } from 'mobx-react';

import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

import ArquivoIcon from '../../../assets/img/arquivo-icon.png';
import moment from 'moment';

import { buildChatFileUrl } from '../../../config/config';
import string from '../../../utils/string';
import DoubleCheckIcon from '../../../components/Icon/DoubleCheckIcon';

const styles = ({
  message: {
    maxWidth: '50%',
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '8px',
    padding: '4px 12px',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
    backgroundColor: '#FFF'
  },
  messageContent: {
    display: 'flex',
    textAlign: 'left',
    '& a': {
      color: 'black'
    }
  },
  toRight: {
    alignSelf: 'flex-end',
    backgroundColor: '#219A97',
    color: 'white',
    borderRadius: '12px 0 12px 12px',
    '& a': {
      color: 'white',
    }
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    gap: '4px', 
    '& span': {
      fontSize: '12px !important',
    }
  },
  imgMiniaturaContainer: {
    maxWidth: '250px',
    maxHeight: '250px',
    objectFit: 'contain',
    padding: '4px'
  },
  imageMiniatura: {
    height: '100%',
    width: '100%',
    borderRadius: '8px'
  },
  audioPlayer: {
    flex: 1,
    height: '36px',
    maxWidth: '100%',
  },
  audioPlayerPopup: {
    width: '120px',
  },
  textContent: {
    wordBreak: 'break-word',
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '150px',
    textDecoration: 'none',
    gap: '4px',
    alignItems: 'center',
  },
  documentMiniatura: {
    display: 'flex',
    flexDirection: 'column',
    height: '120px',
    width: '120px',
  },
  documentName: {
    fontSize: '12px !important',
    color: 'white',
    lineBreak: 'anywhere',
    textAlign: 'center',
  }
});

const MessageItem = ({ classes, message, chatStore, socketStore, isPopup }) => {
  const checkLoggedUserIsSender = () => {
    const { id } = message?.remetente || {};
    const { loggedUser } = chatStore;
    return id === loggedUser?.id;
  }

  const loggedUserIsSender = checkLoggedUserIsSender();
  const messageDateFormated = moment(message.dataHoraCriacao).format('HH:mm');

  const renderMessageContent = () => {
    const { tipoMensagem, urlDownload, mensagemAmazonS3Objeto } = message;
    const { nomeComExtensao } = mensagemAmazonS3Objeto || {};
    const { chatUrl } = socketStore;

    switch (tipoMensagem) {
      case 'IMAGE':
        return (
          <div className={classes.imgMiniaturaContainer}>
            <img
              loading='lazy'
              className={classes.imageMiniatura}
              src={buildChatFileUrl(urlDownload, chatUrl)}
              alt='mensagem'
            />
          </div>
        );
      case 'AUDIO':
        return (
          <audio
            className={classNames(
              classes.audioPlayer,
              isPopup ? classes.audioPlayerPopup : null
            )}
            src={buildChatFileUrl(urlDownload, chatUrl)}
            controls
            preload='auto'
          />
        );
      case 'FILE':
        return (
          <a
            className={classes.documentContainer}
            href={buildChatFileUrl(urlDownload, chatUrl)}
            target='_blank'
            rel='noreferrer'
          >
            <div className={classes.documentMiniatura}>
              <img
                className={classes.imageMiniatura}
                src={ArquivoIcon}
                alt='Arquivo'
              />
            </div>
            <span className={classes.documentName}>{nomeComExtensao}</span>
          </a>
        );
      default:
        const textIsLink = string.stringIsLink(message?.mensagem);
        return (
          <>
            {textIsLink ? (
              <a
                className={classes.textContent}
                href={message.mensagem}
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                {message.mensagem}{' '}
              </a>
            ) : (
              <span className={classes.textContent}>{message.mensagem}</span>
            )}
          </>
        );
    };
  };

  return (
    <div 
      className={classNames(classes.message, loggedUserIsSender ? classes.toRight : null)}
    >
      <div className={classes.messageContent}>
        {renderMessageContent()}
      </div>
      <div className={classes.time}>
        <span>{messageDateFormated}</span>
        <DoubleCheckIcon size={12} color={message?.lido ? "#95DAF8" : "#505050"}/>
      </div>
    </div>
  );
};

const MessageItemStyles = withStyles(styles)(MessageItem);
const stores = ['chatStore', 'socketStore'];
export default inject(...stores)(MessageItemStyles);
