import { action, observable } from "mobx";
import Api from "../config/api";
import string from "../utils/string";
import { getProfissionalSaudeLogado } from '../services/UsuarioService';
import {buildUrlFotoPerfil} from "../config/config";
import localStorageService, {ACCESS_TOKEN_KEY} from "../services/storage";
import BaseStore from './Base.store';

export const MargemPosicaoMedianiz = {
    ESQUERDA: "ESQUERDA",
    SUPERIOR: "SUPERIOR"
};

export const ConfiguracaoImpressaoTamanhoPapel = {
    A4: "A4",
    A5: "A5",
    A6: "A6",
    PERSONALIZADO: "PERSONALIZADO",
};

export const LarguraAlturaPapel = {
    A4: [21, 29.7],
    A5: [14.8, 21],
    A6: [10.5, 14.8],
};

export const posicoes = {
    "superior": "top",
    "inferior": "bottom",
    "esquerda": "left",
    "direita": "right",
};

const configuracaoImpressaoDefault = {
    id: null,
    retrato: true,
    margem: {
        direita: 2,
        esquerda: 2,
        inferior: 2,
        superior: 2,
    },
    tamanhoPapel: ConfiguracaoImpressaoTamanhoPapel.A4,
    unidade: null,
    usarCabecalhoRodapePadrao: false,
    imprimirDadosProfissionalSaude: false,
    usarLogoClinica: false,
    largura: 21,
    altura: 29.7,
    imagens: [],
    assinaturaVisivelPosicaoX: null,
    assinaturaVisivelPosicaoY: null,
    assinaturaVisivel: true,
};

const margemFocusedDefault = {
    esquerda: false,
    direita: false,
    superior: false,
    inferior: false,
};

const errorsDefault = {
    largura: false,
    altura: false,
    margem: {
        superior: false,
        inferior: false,
        direita: false,
        esquerda: false,
    }
};

export default class ConfiguracaoImpressaoStore extends BaseStore {
    usuarioStore = null;
    filtroHeaderStore = null;
    @observable errors = errorsDefault;
    @observable saving = false;
    @observable loadingConfig = true;
    @observable configuracaoImpressao = {
        ...configuracaoImpressaoDefault
    };
    @observable modelosConfiguracao = [];
    @observable margemFocused = margemFocusedDefault;
    @observable tokenDialogLoading = false;

    constructor(rootStore) {
        super();
        this.usuarioStore = rootStore.usuarioStore;
        this.filtroHeaderStore = rootStore.filtroHeaderStore;
    }

    @action
    getTamanhoPapelList() {
        return Object.keys(ConfiguracaoImpressaoTamanhoPapel).map(value => ({
            value,
            name: string.capitalize(value)
        }));
    }

    @action
    getMargemPosicaoMedianizList() {
        return Object.keys(MargemPosicaoMedianiz).map(value => ({
            value,
            name: string.capitalize(value)
        }));
    }

    @action
    async getConfig(tipo, tela) {
        try {
            this.loadingConfig = true;
            this.reset();

            let profissionalSaudeId = null;
            
            if(tela === 'configuracao'){
                const profissionalSaudeFiltro = await this.filtroHeaderStore.getFiltroAtual();
                profissionalSaudeId = profissionalSaudeFiltro.profissionalSaudeId;

            } else {
                const profissionalSaudeAtual = await getProfissionalSaudeLogado();
                profissionalSaudeId = profissionalSaudeAtual?.id
            }

            const response = await Api.post("", {
                query: `
                    query ($profissionalSaude: ProfissionalSaudeInput, $tipo: TipoConfiguracaoImpressao) {
                        configuracaoImpressaoAtual: findConfiguracaoImpressaoByProfissionalSaudeAndTipo(profissionalSaude: $profissionalSaude, tipo: $tipo) {
                            id
                            retrato
                            largura
                            altura
                            margem {
                                direita
                                esquerda
                                inferior
                                superior
                            }
                            tamanhoPapel
                            assinaturaVisivelPosicaoX
                            assinaturaVisivelPosicaoY
                            assinaturaVisivel
                            imagens {
                              id
                            }
                            modelo
                        }
                    }
                `, 
                variables: {
                    profissionalSaude: {
                        id: profissionalSaudeId
                    },
                    tipo
                }
            });

            if (response.data.errors) {
                throw new Error(response.data.errors[0].message);
            }

            const { configuracaoImpressaoAtual } = response.data.data;

            this.configuracaoImpressao = {
                ...configuracaoImpressaoAtual
            }

        } catch (error) {
            console.error(error);
        } finally {
            this.loadingConfig = false;
        }
    }

    @action
    async findAllConfiguracaoImpressao() {
        try {
            let { profissionalSaudeId } = await this.filtroHeaderStore.getFiltroAtual();
                 
            if (!profissionalSaudeId) {
                const profissionalSaudeAtual = await getProfissionalSaudeLogado();
                profissionalSaudeId = profissionalSaudeAtual?.id
            }

            const response = await Api.post("", {
                query: `
                    query ($profissionalSaude: ProfissionalSaudeInput) {
                        modelosConfiguracao: findConfiguracaoImpressaoByProfissionalSaudeAndUnidadeAtual(profissionalSaude: $profissionalSaude) {
                            id
                            tipo
                        }
                    }
                `, 
                variables: {
                    profissionalSaude: {
                        id: profissionalSaudeId
                    }
                }
            });

            if (response.data.errors) {
                throw new Error(response.data.errors[0].message);
            }

            const { modelosConfiguracao } = response.data.data;
            this.modelosConfiguracao = [
                ...modelosConfiguracao
            ]

            return modelosConfiguracao
        } catch (error) {
            console.error(error);
        }
    }

    @action
    async save() {
        try {
            if (this.errors.length > 0) {
                return;
            }
            
            this.saving = true;

            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const unidadeId = unidadeAtual?.id;

            const { profissionalSaudeId } = await this.filtroHeaderStore.getFiltroAtual();

            let metodo = 'createConfiguracaoImpressao';

            if (this.configuracaoImpressao.id) {
                metodo = 'updateConfiguracaoImpressao';
            }
            
            let configuracaoImpressao = {
                ...this.configuracaoImpressao,
            };

            let imagens = []

            configuracaoImpressao.imagens.forEach(image => {
                image.isNew && imagens.push({id: image.id})
            })

            const assinaturaVisivelPosicaoX = 
                configuracaoImpressao?.assinaturaVisivelPosicaoX === 0 || configuracaoImpressao?.assinaturaVisivelPosicaoX 
                ? string.numberMaskToFloat(configuracaoImpressao.assinaturaVisivelPosicaoX) 
                : null;


            const assinaturaVisivelPosicaoY = 
                configuracaoImpressao?.assinaturaVisivelPosicaoY === 0 || configuracaoImpressao?.assinaturaVisivelPosicaoY 
                ? string.numberMaskToFloat(configuracaoImpressao.assinaturaVisivelPosicaoY) 
                : null;

            const response = await Api.post("", {
                query: `mutation ($configuracaoImpressao: ConfiguracaoImpressaoInput) {
                    configuracaoImpressao: ${metodo}(configuracaoImpressao: $configuracaoImpressao) {
                        id
                    }
                }`,
                variables: {
                    configuracaoImpressao: {
                        id: configuracaoImpressao.id || undefined,
                        ...configuracaoImpressao,
                        largura: string.numberMaskToFloat(configuracaoImpressao.largura),
                        altura: string.numberMaskToFloat(configuracaoImpressao.altura),
                        margem: {
                            ...configuracaoImpressao.margem,
                            superior: string.numberMaskToFloat(configuracaoImpressao.margem.superior),
                            inferior: string.numberMaskToFloat(configuracaoImpressao.margem.inferior),
                            esquerda: string.numberMaskToFloat(configuracaoImpressao.margem.esquerda),
                            direita: string.numberMaskToFloat(configuracaoImpressao.margem.direita),
                        },
                        assinaturaVisivelPosicaoX,
                        assinaturaVisivelPosicaoY,
                        assinaturaVisivel: configuracaoImpressao.assinaturaVisivel,
                        unidade: {
                            id: unidadeId
                        },
                        profissionalSaude: {
                            id: profissionalSaudeId
                        },
                        imagens: imagens
                    }
                }
            });

            if (response.data.errors) {
                throw new Error(response.data.errors[0].message);
            }

            const id = response.data.data.configuracaoImpressao.id;

            this.configuracaoImpressao.id = id;
        } catch (error) {
            console.error(error);
        } finally {
            this.saving = false;
        }
    }

    @action
    reset() {
        this.configuracaoImpressao = {
            ...configuracaoImpressaoDefault
        };
    }

    @action
    async buildUrlImages() {
        const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
        const imagens = this.configuracaoImpressao.imagens.map(image => {
            return {
                id: image.id,
                url: buildUrlFotoPerfil(image.id, accessToken),
            }
        })

        this.configuracaoImpressao.imagens = imagens;
    }

    @action
    tagImagemModelo(url) {
        return `
            <img src="${url}" style="width: 200px;"/>
        `
    }

    @action handleChangeInput = (campo, event) => {
        const value = event.target.value;
        this.configuracaoImpressao[campo] = value;
    
        if (campo === "tamanhoPapel" && LarguraAlturaPapel[value]) {
          this.configuracaoImpressao.largura =
            LarguraAlturaPapel[value][0];
          this.configuracaoImpressao.altura =
            LarguraAlturaPapel[value][1];
        }

        this.verifyErrors();
    };

    @action handleChangeUnit = (campo, event) => {
        this.configuracaoImpressao[campo] =
          event.valueWithMask;
    
        this.verifyTamanhoPapel();
        this.verifyErrors();
    };

    @action handleChangeSignVisibility = () => {
        this.configuracaoImpressao.assinaturaVisivel = !this.configuracaoImpressao.assinaturaVisivel; 
    }

    verifyTamanhoPapel = () => {
        const largura = string.numberMaskToFloat(this.configuracaoImpressao.largura);
        const altura = string.numberMaskToFloat(this.configuracaoImpressao.altura);
    
        const larguraAlturaDiferenteDeA4 =
          largura !== LarguraAlturaPapel.A4[0] ||
          altura !== LarguraAlturaPapel.A4[1];
        const larguraAlturaDiferenteDeA5 =
          largura !== LarguraAlturaPapel.A5[0] ||
          altura !== LarguraAlturaPapel.A5[1];
        const larguraAlturaDiferenteDeA6 =
          largura !== LarguraAlturaPapel.A6[0] ||
          altura !== LarguraAlturaPapel.A6[1];
    
        if (
          larguraAlturaDiferenteDeA4 &&
          larguraAlturaDiferenteDeA5 &&
          larguraAlturaDiferenteDeA6
        ) {
          this.configuracaoImpressao.tamanhoPapel =
            ConfiguracaoImpressaoTamanhoPapel.PERSONALIZADO;
        }
    };

    @action verifyErrors = () => {
        const largura = string.numberMaskToFloat(this.configuracaoImpressao.largura);
        const altura = string.numberMaskToFloat(this.configuracaoImpressao.altura);
        const margemSuperior = string.numberMaskToFloat(
          this.configuracaoImpressao.margem.superior
        );
        const margemInferior = string.numberMaskToFloat(
          this.configuracaoImpressao.margem.inferior
        );
        const margemDireita = string.numberMaskToFloat(
          this.configuracaoImpressao.margem.direita
        );
        const margemEsquerda = string.numberMaskToFloat(
          this.configuracaoImpressao.margem.esquerda
        );
    
        const percentualPaginaMargem = 30;
        const maxMargemHorizontal = largura * (percentualPaginaMargem / 100);
        const maxMargemVertical = altura * (percentualPaginaMargem / 100);
    
        let errors = {}
    
        if (largura > LarguraAlturaPapel.A4[0]) {
          errors={
            ...errors,
            largura: "Largura não pode ser maior que " + LarguraAlturaPapel.A4[0] + " cm"
          };
        }
        if (altura > LarguraAlturaPapel.A4[1]) {
            errors={
                ...errors,
                altura: "Altura não pode ser maior que " + LarguraAlturaPapel.A4[1] + " cm"
            };
        }
    
        if (margemSuperior > maxMargemVertical) {
            errors={
                ...errors,
                margem: {
                    ...errors.margem,
                    superior: `Margem superior não pode ser maior que ${percentualPaginaMargem}% da altura da página.`
                }
            };

        }
        if (margemInferior > maxMargemVertical) {
            errors={
                ...errors,
                margem: {
                    ...errors.margem,
                    inferior: `Margem inferior não pode ser maior que ${percentualPaginaMargem}% da altura da página.`
                }
            };
        }
        if (margemDireita > maxMargemHorizontal) {
            errors={
                ...errors,
                margem: {
                    ...errors.margem,
                    direita: `Margem inferior não pode ser maior que ${percentualPaginaMargem}% da altura da página.`
                }
            };
        }
        if (margemEsquerda > maxMargemHorizontal) {
            errors={
                ...errors,
                margem: {
                    ...errors.margem,
                    esquerda: `Margem esquerda não pode ser maior que ${percentualPaginaMargem}% da largura da página.`,
                }
            };
        }
    
        this.errors = errors;
      };

}
