import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { inject } from "mobx-react";
import {

} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { ReactSelect } from '../../../Select/SelectSearch';
import { InputDateForm } from '../../Input';

import { Button } from '../../../ui/Buttons';

const Header = observer((props) => {
    const {
        classes,
        filtro,
        handleChangeInput,
        searchHorariosDisponiveis,
        profissionaisSaude,
        profissionalSaudeStore,
        loading
    } = props;
    const { specializationList } = profissionalSaudeStore;
    useEffect(() => {
        handleChangeInput(null, 'especialidadeMedica')
    }, [filtro.profissionalSaudeId])
    useEffect(() => {
        getSpecializationsUnidade();
    }, []);

    const periodos = [
        { label: 'Todos', value: { horaInicio: '00:00', horaFim: '23:59' } },
        { label: 'Manhã', value: { horaInicio: '00:00', horaFim: '12:00' } },
        { label: 'Tarde', value: { horaInicio: '12:00', horaFim: '18:00' } },
        { label: 'Noite', value: { horaInicio: '18:00', horaFim: '23:59' } },
    ];
    const getEspecialityInProfissional = (especialidades) => {

        const especialityInProfissional = especialidades.map(
            (profissionalEspecialidade) => {

                if (profissionalEspecialidade) {
                    return {
                        label: profissionalEspecialidade.especialidade || profissionalEspecialidade.name,
                        value: profissionalEspecialidade.id || profissionalEspecialidade.value
                    }
                }

                return null
            });

        return especialityInProfissional
    }
    const getEspecialityOptions = () => {
        if (!filtro.profissionalSaudeId?.id) {
            const allEspecialityUnidadeLogada = getEspecialityInProfissional(specializationList)

            return allEspecialityUnidadeLogada
        }

        return getEspecialityInProfissional(filtro.profissionalSaudeId?.especialidades)
    }


    const getSpecializationsUnidade = () => {
        const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);
        profissionalSaudeStore.getSpecializations(unidadeLogada.id);
    };

    return (
        <div className={classes.content}>
            <h1>Transferência de agendamento</h1>
            <div className={classes.inputGroup}>
                <div className={classes.input}>
                    <span>A partir de: </span>

                    <InputDateForm
                        id="dataInicio"
                        iconposition="end"
                        value={filtro.dataInicio}
                        onChange={(e) => handleChangeInput(e, 'dataInicio')}
                    />
                </div>

                <div className={classes.input}>
                    <span>Data fim</span>
                    <InputDateForm
                        id="dataFim"
                        iconposition="end"
                        value={filtro.dataFim}
                        onChange={(e) => handleChangeInput(e, 'dataFim')}
                    />
                </div>
                <div className={classes.input}>
                    <span>Períodos </span>
                    <ReactSelect
                        className={classes.reactSelect}
                        name="periodos"
                        placeholder={"Todos"}
                        options={periodos || []}
                        onChange={(e) => handleChangeInput(e, 'periodos')}
                    />
                </div>
                <div className={classes.input}>
                    <span>Profissional Saúde </span>
                    <ReactSelect
                        className={classes.reactSelect}
                        name="profissional"
                        placeholder={"Profissional"}
                        value={filtro.profissionalSaudeId}
                        onChange={(e) => handleChangeInput(e, 'profissionalSaudeId')}
                        options={profissionaisSaude || []}
                        isClearable
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className={classes.input}>
                    <span>Especialidade</span>
                    <ReactSelect
                        className={classes.reactSelect}
                        name="especialidade"
                        value={filtro.especialidadeMedica}
                        placeholder={"Especialidade"}
                        onChange={(e) => handleChangeInput(e, 'especialidadeMedica')}
                        options={
                            getEspecialityOptions() || []}
                        isClearable
                    />
                </div>
                <div className={classes.buttons}>
                    <Button
                        onClick={searchHorariosDisponiveis}
                        isLoading={loading}
                        disabled={loading}
                    >
                        Buscar
                    </Button>
                </div>
            </div>
        </div>
    )
});

const style = {
    content: {
        padding: '20px 32px',
        height: '108',
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
        '& > h1': {
            color: '#219A97',
            fontSize: '18px'
        }
    },
    inputGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        gap: '16px'
    },
    input: {
        display: 'flex',
        flexDirection: 'column',
        width: '24%',
        '& > span': {
            color: '#989898',
            fontSize: '12px'
        }
    },
    searchButton: {
        borderRadius: 85,
        width: '120px',
        height: '33px',
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
        border: '2px solid rgba(255, 255, 255, 0.2)',
        "& > span": {
            fontSize: "14px"
        }
    }
};



const HeaderWithStyles = withStyles(style)(Header);
export default inject('profissionalSaudeStore')(HeaderWithStyles);