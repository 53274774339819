import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const CloseIcon = props => {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M13.9688 2.23145L8.39063 7.80957L13.9688 13.3877L12.5625 14.7939L6.98438 9.21582L1.40625 14.7939L0 13.3877L5.57813 7.80957L0 2.23145L1.40625 0.825195L6.98438 6.40332L12.5625 0.825195L13.9688 2.23145Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default CloseIcon
