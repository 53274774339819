export const styles = ({
  voiceRecorderContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: '16px',
  },
  waveOrAudioContainer: {
    display: 'flex',
    flex: 1,
  },  
  audioPlayerPreview: {
    flex: 1,
  }
});
