import React from 'react'
import { withStyles } from '@material-ui/core'
import { Grid, Typography } from '@material-ui/core'
import { InputForm, SelectForm } from '../Input/InputDadosCadastraisPaciente'
import Colors from '../../template/Colors'
import { TextField, TextFieldSearch } from '../../components/TextField'
import classNames from 'classnames'

const HeaderModalConfiguracoes = props => {
  const {
    classes,
    itemSelecionado,
    titulo,
    handleChange,
    status,
    handleChangeTuss,
    handleCodigoTermoLoadOptions,
    handleDescricaoLoadOptions,
    headerCustom,
    handleConvenioSistemaLoadOptions,
    handleChangeConvenioSistema,
  } = props

  return (
    <Grid className={classes.header}>
      <Grid container direction='row'>
        {(titulo === 'procedimento' || titulo === 'produto') && (
          <>
            <Grid item xs={4}>
              <div className={classes.inputTussCodigo}>
                <Typography
                  color='primary'
                  component='label'
                  className={classes.label}
                >
                  Código:
                </Typography>
                <TextFieldSearch
                  placeholder=''
                  classNotched={classes.notchedOutline}
                  classInput={classes.inputTextField}
                  classIcons={classes.classIcons}
                  loadOptions={handleCodigoTermoLoadOptions}
                  withPaginate
                  value={
                    titulo === 'procedimento'
                      ? itemSelecionado.tuss22ProcedimentoEventoSaude
                          ?.codigoTermo
                      : itemSelecionado.tuss19OrtesesProtesesMateriais
                          ?.codigoTermo
                  }
                  onChange={e => handleChangeTuss(e)}
                  debounceTimeout={300}
                  additional={{
                    page: 0,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={classes.inputTussDescricao}>
                <Typography
                  color='primary'
                  component='label'
                  className={classes.label}
                >
                  Descrição:
                </Typography>
                <TextFieldSearch
                  placeholder=''
                  classNotched={classes.notchedOutline}
                  classInput={classes.inputTextField}
                  classIcons={classes.classIcons}
                  loadOptions={handleDescricaoLoadOptions}
                  withPaginate
                  value={
                    titulo === 'procedimento'
                      ? itemSelecionado.tuss22ProcedimentoEventoSaude?.descricao
                      : itemSelecionado.tuss19OrtesesProtesesMateriais
                          ?.descricao
                  }
                  onChange={e => handleChangeTuss(e)}
                  debounceTimeout={300}
                  additional={{
                    page: 0,
                  }}
                />
              </div>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={8}
          className={classes.formGroup}
        >
          <Typography color='primary' component='label'>
            {`Nome do ${titulo}:`}
          </Typography>
          <InputForm
            name={titulo === 'convênio' || titulo === 'tipo consulta' ? 'descricao' : 'nome'}
            value={itemSelecionado.nome || itemSelecionado.descricao}
            onChange={e => handleChange(e)}
            alternativeInputClass={classes.inputNome}
            className={classes.inputDescricao}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography color='primary' component='label'>
            Status:
          </Typography>
          <SelectForm
            labelOption={'Status'}
            placeholder='Status'
            name={'ativo'}
            value={itemSelecionado.ativo}
            onChange={e => handleChange(e)}
            elements={status || []}
          />
        </Grid>

        {titulo === 'convênio' && (
          <>
          <Grid item xs={4} className={classNames(classes.inputsTiss, classes.paddingRight)}>
            <Typography color='primary' component='label'>
              Registro ANS
            </Typography>
            <TextField
              name='registroAns'
              alternativeInputClass={classes.inputNome}
              value={itemSelecionado.registroAns}
              onChange={e => e.target.value.length <= 6 && handleChange(e)}
              type='number'
            />
          </Grid>
          <Grid item xs={4} className={classNames(classes.inputsTiss, classes.paddingRight)}>
            <Typography color='primary' component='label'>
              Código na operadora
            </Typography>
            <TextField
              name='codigoUnidade'
              alternativeInputClass={classes.inputNome}
              value={itemSelecionado.codigoUnidade}
              onChange={e => e.target.value.length <= 14 && handleChange(e)}
              type='number'
            />
          </Grid>
          <Grid item xs={4} className={classes.inputsTiss}>
            <Typography color='primary' component='label'>
              Operadora
            </Typography>
            <TextFieldSearch
              name='convenioSistema'
              placeholder=''
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              loadOptions={handleConvenioSistemaLoadOptions}
              withPaginate
              value={itemSelecionado.convenioSistema}
              onChange={e => handleChangeConvenioSistema(e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </Grid>
          </>
        )}
      </Grid>
        {headerCustom && <div>
          {headerCustom}
        </div>}
    </Grid>
  )
}

const style = {
  header: {
    padding: '16px 40px',
    boxShadow:
      '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    zIndex: 1,
    "& label": {
      fontFamily: 'Poppins',
    }
  },
  inputNome: {
    fontFamily: 'Poppins!important',
    border: '0',
    fontSize: '14px',
    minHeight: '6px',
    height: '18px',
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    padding: '9px 0px 5px 10px',
    borderRadius: '100px',
    width: '100%',
    marginRight: 10,
  },
  inputDescricao: {
    width: '100%',
  },
  inputValorConsulta: {
      borderRadius: 100,
      background: Colors.commons.gray2,
      fontFamily: 'Poppins !important',
      color: '#000',
      fontSize: 14,
      marginRight: 10,
      marginBottom: '1rem',
      height: 30,
      paddingLeft: 10,
    '& fieldset': {
      border: 0,
    },
  },
  inputsTiss: {
    '& input': {
      borderRadius: 100,
      background: Colors.commons.gray2,
      fontFamily: 'Poppins',
      paddingLeft: '1.5rem',
      color: '#000',
      fontSize: 14,
    },
    '& fieldset': {
      border: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      appearance: 'none',
    },
  },
  paddingRight: {
    paddingRight: 10,
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    height: 32,
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '100px',
  },
  classIcons: {
    fontSize: 24,
  },
  inputTussCodigo: {
    margin: '0 10px 5px 0',
  },
  inputTussDescricao: {
    marginBottom: '5px',
  },
}

export default withStyles(style)(HeaderModalConfiguracoes)
