import React, { useEffect } from 'react';

import { withStyles } from '@material-ui/core';
import styles from './styles.js';

import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';

import { buildUrlFotoPerfilLight } from '../../config/config';

import PageTitle from '../../components/PageTitle/PageTitle';
import Header from '../../template/Header/Header';
import Footer from '../../components/Footer/Footer';
import Profile from '../../template/Header/Profile';

import InputSearch from '../../components/Input/Input';
import debounce from 'lodash.debounce';

import ContactsList from './components/ContactsList';
import ChatContainer from './components/ChatContainer';

import chatLogo from '../../assets/img/chat/chat-logo-health.png';
import Avatar from 'react-avatar';
import Notification from '../../components/Notification';
import AlertCard from '../../components/Cards/AlertCard';
import InfoIconFilled from '../../components/Icon/InfoIconFilled.js';

const Chat = observer(({ classes, chatStore, socketStore }) => {
  const { selectedContact, loggedUser, accessToken, notification, isDisconnected } = chatStore;
  const { contact } = selectedContact || {};
  const { isApiInitialized } = socketStore;

  useEffect(() => {
    if (!isApiInitialized) {
      return;
    }

    loadInitialData();
    if (!accessToken) {
      chatStore.getAccessToken();
    }
  }, []);

  const loadInitialData = async () => {
    const currentUnidadeId = await chatStore.getUnidadeLogadaId();
    await chatStore.getProfissionalSaudeAtual();

    if (currentUnidadeId !== chatStore.unidadeLogadaId) {
      chatStore.unidadeLogadaId = currentUnidadeId;
      chatStore.resetContacts();
      chatStore.selectedContact = null;
    }
  };

  const getSeletedContactProfilePic = () => {
    const { profissionalSaudeContato } = contact || {};
    const { fotoPerfil } = profissionalSaudeContato || {};

    if (fotoPerfil && accessToken) {
      return buildUrlFotoPerfilLight(fotoPerfil, accessToken);
    }

    return null;
  };

  const handleSearchContact = async (e) => {
    e.preventDefault();
    await chatStore.getContacts();
  };

  const debounceSearch = debounce(async (value) => {
    chatStore.contactInputSearch = value;
    await chatStore.getContacts();
  }, 100);

  const handleChangeSearchInput = async (e) => {
    const value = e.target.value;
    await debounceSearch(value);
  };

  const renderUnselectedContent = () => {
    if (isDisconnected) {
      return (
        <AlertCard
          message={'Houve um problema com a sua conexão. A reconexão automática foi iniciada...'}
          width={'290px'}
          backgroundColor={'#FFF'}
          withLoadAnimation
          icon={
            <InfoIconFilled
              color="#FB7676"
              size={36}
            />
          }
        />
      );
    }
    return (
      <div className={classes.imageContainer}>
        <img src={chatLogo} alt='App Health Logo' />
      </div>
    );
  }

  const contactName = contact?.profissionalSaudeContato?.nome;

  useEffect(() => {
    return () => {
      chatStore.selectedContact = null;
      chatStore.willScrollToBottom = false;
    };
  }, []);

  const showContactList = (isApiInitialized && loggedUser) || chatStore?.contacts?.length > 0;
  return (
    <div className={classes.gridChatContainer}>
      <PageTitle title='Chat' />
      <div className={classes.sidebarLeft}>
        <div className={classes.sidebarHeader}>
          <span className={classes.sideBarTitle}>Chat</span>
          <form
            className={classes.contactSearchForm}
            onSubmit={(e) => handleSearchContact(e)}
          >
            <InputSearch
              placeholder='Contatos'
              classes={{
                inputRoot: classes.contactSearch,
              }}
              disabled={isDisconnected}
              onChange={(e) => handleChangeSearchInput(e)}
              value={chatStore.contactInputSearch}
              onClickSearch={(e) => handleSearchContact(e)}
            />
          </form>
        </div>
        {showContactList && <ContactsList />}
      </div>
      <div className={classes.content}>
        <Header>
          <div className={classes.contentHeader}>
            <div>
              {contact && (
                <div className={classes.selectedContact}>
                  <Avatar
                    round={true}
                    size={48}
                    name={contactName}
                    src={getSeletedContactProfilePic()}
                    maxInitials={2}
                  />
                  <span>{contactName}</span>
                </div>
              )}
            </div>
            <Profile />
          </div>
        </Header>
        {loggedUser && contact ? (
          <ChatContainer />
        ) : (
          <div className={classes.unselectedContact}>
            {renderUnselectedContent()}
          </div>
        )}
      </div>
      <Footer />
      <Notification
        isOpen={notification.isOpen}
        variant={'error'}
        message={notification.message}
        close={() => chatStore.resetNotification()}
      />
    </div>
  );
});

const stores = ['chatStore', 'socketStore', 'googleAnalyticsStore'];
const ChatStyles = withStyles(styles)(Chat);
export default inject(...stores)(ChatStyles);
