import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = ({
  reportItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemText: {
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: '#333333'
  },
});

const ReportItem = ({ classes, description, quantity }) => {
  return (
    <div className={classes.reportItem}>
      <span className={classes.itemText}>{description}</span>
      <span className={classes.itemText}>{quantity}</span>
    </div>
  )
};

export default withStyles(styles)(ReportItem);