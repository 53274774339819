export const getStatusBraspagByDadosIugu = (dadosIugu) => {
    if(!dadosIugu) return null;

    switch (dadosIugu?.verified) {
        case null:
            return 'UNDERANALYSIS';
        case true:
            return 'APPROVED';
        case false:
            return 'REJECTED';
        default:
            return 'UNDERANALYSIS';
    }
}
