import React from "react";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import Colors from "../../template/Colors";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";

const styles = {
  root: {
    borderRadius: "100px",
    height: 32,
    width: "100%",
    border: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: Colors.commons.white,
    textTransform: "capitalize",
  },
  red: {
    background: Colors.error.main,

    "&:hover": {
      background: Colors.error.dark,
    },
  },
  green: {
    background: Colors.commons.secondary,

    "&:hover": {
      background: Colors.primary.dark,
    },
    "&:disabled": {
      background: Colors.commons.secondary,
      color: Colors.commons.white,
      opacity: 0.3,
    }
  },
  yellow: {
    background: Colors.commons.yellow2,

    "&:hover": {
      background: Colors.commons.darkYellow,
    },
  },
  gray: {
    background: Colors.commons.gray10,

    "&:hover": {
      background: Colors.commons.gray10Dark,
    },
  },
  transparency: {
    background: "none",
    color: Colors.commons.gray9,
    fontWeight: 700,
    border: `1px solid ${Colors.commons.gray9}`,

    "&:hover": {
      opacity: 0.5,
    },
  },
  transparencyHover: {
    background: "none",
    color: Colors.commons.gray9,
    fontWeight: 700,
    
    "&:hover": {
      background: '#F1FCFA',
      border: `1px solid #219A97`,
    },
  },
  blue: {
    background: "#717FFC",
    "&:hover": {
      opacity: 0.5,
    },
  },
  noShadow: {
    boxShadow: 'none'
  },
  load: {
    marginLeft: '8px',
  },
};

const ButtonLong = (props) => {
  const {
    classes,
    colorCustom,
    className,
    loading,
    children,
    noShadow,
    ...others
  } = props;
  let buttonClass = classNames(
    classes.root,
    classes[colorCustom],
    noShadow && classes.noShadow,
    className && className
  );
  return (
    <Fab className={buttonClass} {...others}>
      {children}
      {loading && <CircularProgress className={classes.load} size={14} color="inherit"/>}
    </Fab>
  );
};

export default withStyles(styles)(ButtonLong);
