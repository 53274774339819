const styles = {
    paperAlert: {
        maxWidth: '320px !important',
        width: '320px',
        maxHeight: '330px'
    }, 
    content: {
        fontWeight: 500,
        color: '#505050',
        fontSize: '17px',
        margin: '16px 0',
    },
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px'
    },
    colorText: {
        color: '#717FFC',
        margin: 0
    },
    title: {
        fontSize: '18px !important',
        fontWeight: 600,
        color: '#505050',
    },
    subtitle: {
        fontSize: '14px !important',
        color: '#505050',
        fontWeight: 400
    },
    listContainer: {
        height: '90px',
        overflowY: 'auto'
    },
    horariosList: {
        margin: 0,  
    },
    horarioItem: {
        textAlign: 'start',
        fontWeight: 600,
        fontSize: '14px !important',
    },
    removeMarginTop: {
        marginTop: 0
    }
};

export default styles;