import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const ObservacaoIcon = (props) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M14 0.536621C15.9971 0.536621 17.6296 2.09777 17.7436 4.06628L17.75 4.28662V9.28662H12.75L12.5297 9.29299C10.6341 9.40282 9.1162 10.9207 9.00637 12.8163L9 13.0366V18.0366H4C2.0029 18.0366 0.370424 16.4755 0.256366 14.507L0.25 14.2866V4.28662C0.25 2.28952 1.81115 0.657045 3.77966 0.542987L4 0.536621H14ZM17.6259 10.5379C17.5291 10.8336 17.3773 11.1103 17.177 11.3527L17.0178 11.5277L11.2411 17.3044C10.9609 17.5846 10.621 17.7915 10.2512 17.9125L10.25 13.0366L10.2569 12.85C10.3475 11.6211 11.3265 10.6393 12.5542 10.5442L12.75 10.5366L17.6259 10.5379Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ObservacaoIcon;
