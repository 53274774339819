// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

import {
  Flex
} from 'amazon-chime-sdk-component-library-react';

import logo from "../../../../assets/img/LogoLogin.png";

const MeetingDetails = () => {

  return (
    <Flex container layout="fill-space-centered">
      <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
        <img src={logo} alt={"logo"} style={{ opacity:0.2, filter: 'alpha(opacity=20)'}} height='100vh' />
      </Flex>
    </Flex>
  );
};

export default MeetingDetails;
