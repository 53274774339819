import Api from "../config/api";

export const createAfericaoVital = async ( afericaoVital ) => {
  const response = await Api.post("", {
    query: `
      mutation ($afericaoVital: AfericaoVitalInput) {
        createAfericaoVital(afericaoVital: $afericaoVital) {
          id
        }
      }`,
    variables: { afericaoVital },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response;
};

export const findAllAfericaoVital = async({sujeitoAtencaoId, pageableDTO, dataFinal, dataInicial, ativo}) => {
  const pageableDefault = {
    pageSize: 30,
    pageNumber: 0,
    sortDir: "DESC",
    sortField: "data",
    ...pageableDTO
  };

  const response = await Api.post("", {
    query: `
    query ($ativo: Boolean, $sujeitoAtencaoId: UUID, $dataInicial: LocalDate, $dataFinal: LocalDate, $pageableDTO: PageableDTOInput) {
      findAllAfericaoVital(ativo: $ativo, sujeitoAtencaoId: $sujeitoAtencaoId, dataInicial: $dataInicial, dataFinal: $dataFinal, pageableDTO: $pageableDTO) {
        last
        content {
          id
          ativo
          entradaProntuario {
            id
          }
          coleta
          data
          frequenciaCardiaca
          frequenciaRespiratoria
          glicemia
          pressaoArterialSistolica
          pressaoArterialDiastolica
          saturacaoO2
          temperatura
        }
      }
    }
    `,
    variables: {
      ativo,
      dataInicial,
      dataFinal,
      sujeitoAtencaoId,
      pageableDTO: pageableDefault,
    }
  });

  return response.data.data.findAllAfericaoVital;
};

export const findByIdEntradaProntuarioAfericaoVital = async id => {
  const response = await Api.post("", {
    query: `
    {
      findByIdEntradaProntuarioAfericaoVital(idEntradaProntuario: ${id}){
        ativo
        id
        temperatura
        coleta
        data
        frequenciaCardiaca
        frequenciaRespiratoria
        glicemia
        pressaoArterialDiastolica
        pressaoArterialSistolica
        saturacaoO2
      }
    }
    `
  });

  if(response.data.errors){
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.findByIdEntradaProntuarioAfericaoVital;
}
