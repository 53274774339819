import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const DoubleChevronIcon = props => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M6.98618 3.86575C7.22611 3.6372 7.6059 3.64643 7.83445 3.88636L11.8355 8.08674C12.0563 8.31847 12.0563 8.68267 11.8355 8.9144L7.83445 13.1148C7.6059 13.3547 7.22611 13.3639 6.98618 13.1354C6.74624 12.9068 6.73701 12.527 6.96556 12.2871L10.5724 8.50057L6.96556 4.71403C6.73701 4.47409 6.74624 4.0943 6.98618 3.86575ZM3.78618 3.86575C4.02611 3.6372 4.4059 3.64643 4.63445 3.88636L8.63552 8.08674C8.85626 8.31847 8.85626 8.68267 8.63552 8.9144L4.63445 13.1148C4.4059 13.3547 4.02611 13.3639 3.78618 13.1354C3.54624 12.9068 3.53701 12.527 3.76556 12.2871L7.37243 8.50057L3.76556 4.71403C3.53701 4.47409 3.54624 4.0943 3.78618 3.86575Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default DoubleChevronIcon
