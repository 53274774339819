import {primaryColor} from "../appHealth.style";

const selectRedeStyle = {
    main: {
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        maxHeight: "100vh",
        overflowY: "auto"
    },
    content : {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        overflowY: "hidden"
    },
    header: {
        width: "100%",
        height: "48px",
        padding: "8px 0px",
        display: "flex",
        alignItems: "center",
        boxShadow:
        "0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(38, 172, 169, 0.04), 0px 5px 25px rgba(38, 172, 169, 0.04)",
        '& img': {
            padding: '0 16px',
        }  
    },
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        padding: "32px",
        overflowY: "auto",
        maxHeight: "100%",
        height: 'max-content',
    },
    wrapperRedes:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    aside: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        '& img': {
            width: '100%',
            objectFit: 'contain',
        },
        "@media(max-width: 1360px)": {
            width: '30%',
        },
        "@media(max-width: 980px)": {
            display: 'none'
        },
    },
    title: {
        fontSize: "1.25rem",
        fontWeight: 'bold',
        color: '#505050',
        margin: "16px 0px",
    },

    card: {
        borderLeft: `solid 4px ${primaryColor}`,
    },

    nameRede: {
        fontSize: "1rem",
        color: primaryColor,
        fontWeight: 'bold',
        margin: "16px 0 0"
    },
};

export default selectRedeStyle;
