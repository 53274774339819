const styles = {
  content: {
    background: "#505050",
    borderRadius: "16px 16px 0 0",
    display: "grid",
    gridTemplateColumns: "50px 1fr 50px",
    width: "100%",
    padding: "8px 0",
  },
  contentDentes: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "auto",
    alignItems: "center",
    "@media (max-width: 1170px)": {
      alignItems: "start",
    },
  },
  contentTelaCheia: {
    "@media (max-width: 1170px)": {
      alignItems: "center",
    },
  },
  contentCloseDrawer: {
    alignItems: "center",
    "@media (max-width: 1035px)": {
      alignItems: "start",
    },
  },
  dentes: {
    display: "flex",
    flexDirection: "row",
  },
  divisor: {
    height: "1px",
    width: "100%",
    background: "#F9BE73",
    display: "flex",
    margin: "10px 0",
    alignSelf: "center",
  },
  dente: {
    width: "40px",
  },
};

export default styles;
