import { action } from "mobx";
import Api from "../config/api";

export default class DocumentoStore {

  @action async imprimirAssinadaDocumento(variables){
    const response = await Api.post("", {
      query: `
      mutation ($tokenAplicativo: String, $id: Long) {
        imprimir: imprimirAssinadaDocumento(id: $id, tokenAplicativo: $tokenAplicativo) {
          assinado
          dataHoraEmissao
          documento
          pdf
          id
          entradaProntuario{
            urlDownload
          }
        }
      }`,
          variables: variables
    });
    if (response.data.errors) {
      throw response.data.errors[0];
    };
    return response.data.data.imprimir;

  };

  @action async imprimirDocumento(variables) {
    const response = await Api.post("", {
        query: `
        mutation ($id: Long) {
            imprimir: imprimirDocumento(id: $id)
        }
    `,
        variables: variables
    });
    if (response.data.errors) {
      throw response.data.errors[0];
    }
    return response.data.data.imprimir;
  };

  @action async findAllDocumentoModelo(variables){
    try {
      const response = await Api.post("", {
        query: `
        query FindAllDocumentosModelo($profissionalSaudeId: Long, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput){
          findAllDocumentosModelo(profissionalSaudeId: $profissionalSaudeId, ativo: $ativo, search: $search, pageableDTO: $pageableDTO){
            last
            content {
              camposEntrada{
              campo
              campoAmigavel
              campoCompleto
              valor
              }
              camposSistema{
              campo
              descricao
              entidade
              entidadeComCampo
              entidadeEnum
              }
              modelo
              name: nome
              value: id
            }
          }
        }`,
        variables: variables
      });
      const { last, content } = response.data.data.findAllDocumentosModelo;

      const customContent = content.map(documento => {
        return ({
          ...documento,
          value: documento.value,
          label: documento.name
        })
      }); 

      return {
        last,
        content: customContent
      }
    } catch (error){
      throw error;
    }
  };

}
