import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { withStyles } from '@material-ui/core/styles';
import {
  Fade,
  Popper,
  MenuItem,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import {
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons";

import { TypeModalEnum } from '../../../../stores/SADT.store';
import Scroll from '../../../../components/InfiniteScroll/Scroll';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#f9f9f9",
    color: "#505050",
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
  },
  body: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: '100%',
    height: '100%'
  },
  table: {
    minWidth: 700,
    maxHeight: 700,
  },
  row: {
    maxHeight: 57,
    cursor: 'pointer',
  },
  rowInativo:{
    maxHeight: 57,
    cursor: 'pointer',
    backgroundColor: '#F5F5F5',
  },
  menuMore: {
    color: '#505050',
    fontSize: 16,
    width: 140,
  },
  iconMenuMore:{
    marginRight: 10,
  },
  botaoOrganizar: {
    color: 'white',
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  colunaStatus: {
    textAlign: '-webkit-center',
  },
  paperBotaoMore: {
    borderRadius: 8,
  },
  nameAtivo: {
    color: '#505050',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '655px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px',
    },
  },
  nameInativo: {
    color: '#505050',
    opacity: 0.5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '655px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px',
    },
  },
  scrollContainerModelos: {
    width: '100%',
    flex: 1,
    height: "calc(100vh - 64px - 64px)",
    overflow: 'auto'
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    margin: 10,
  },
  rowHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  colorIconOrdenacao: {
    filter: 'brightness(0.4)',
  },

});

const CustomizedTable = (props) => {
  const { classes, SADTStore, loadModelos, last, modelosLoading } = props;
  const [openMore, setOpenMore ] = useState(false);
  const [anchorEl, setAnchorEl ] = useState(null);
  const [placement, setPlacement ] = useState(null);
  const [ordenarNome, setOrdenarNome] = useState(true);
  const [ordenarStatus, setOrdenarStatus] = useState(true);
  const [statusMore, setStatusMore] = useState('');
  const[isLoading, setIsLoading]= useState(false)

  const handleClickMore = (newPlacement, status, idModelo) => (event) => {
    setStatusMore(status ? "Ativo" : "Inativo")
    setAnchorEl(event.currentTarget);
    setOpenMore((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);

    SADTStore.changeFieldsStore({
      idModeloSelecionado: idModelo
    });
  };

  const handleCloseMore = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setOpenMore(false);
  };

  const handleEditar = async () => {
    SADTStore.changeFieldsStore({
      sugestoes: [],
      sugestoesPagAtual: 0,
      typeModal: TypeModalEnum.EDIT_MODAL
    });
    
    await SADTStore.findAllGuiaServicoTussViewQuantidadeUso({
      pageableDTO: {
        pageSize: 6,
        pageNumber: SADTStore.sugestoesPagAtual,
        sortDir: "DESC",
        sortField: "quantidadeUso"
      }
    })
    
    const selecionado = await SADTStore.findByIdGuiaServicoModelo(SADTStore.idModeloSelecionado)
    SADTStore.nomeModelo = selecionado.nome;
    SADTStore.statusNovoModelo = selecionado.ativo;
    SADTStore.objView.observacao = selecionado.observacao;
    SADTStore.objView.guiaServicoTussViews = selecionado.guiaServicoModeloTussViews;
    SADTStore.cid10Subcategoria = selecionado.cid10Subcategoria;
    SADTStore.objView.indicacaoClinica = selecionado.cid10Subcategoria && {
      value: selecionado.cid10Subcategoria.id,
      label: selecionado.cid10Subcategoria.descricaoAbreviada,
    };
    
    SADTStore.openModal = true;
  }

  const handleDuplicar = async () => {
    setIsLoading(true)
    SADTStore.typeModal = TypeModalEnum.EDIT_MODAL;
    const selecionado = await SADTStore.findByIdGuiaServicoModelo(SADTStore.idModeloSelecionado);

    SADTStore.createGuiaServicoModelo(selecionado);
    SADTStore.modelosConfig = [];
    SADTStore.pageNumberModelo = 0;
    SADTStore.idModeloSelecionado = ''
    loadModelos();
    setIsLoading(false)
  }

  const handleAtivar = async () => {
    setIsLoading(true)
    await SADTStore.ativarRegistro(SADTStore.idModeloSelecionado);
    SADTStore.modelosConfig = [];
    SADTStore.pageNumberModelo = 0;
    loadModelos();
    setIsLoading(false)
  }

  const handleInativar = async() => {
    setIsLoading(true)
    await SADTStore.desativarRegistro(SADTStore.idModeloSelecionado);
    SADTStore.modelosConfig = [];
    SADTStore.pageNumberModelo = 0;
    loadModelos();
    setIsLoading(false)
  }

  const handleClickOrdenar = (value) =>{
    if (value === "nome"){
      setOrdenarNome(!ordenarNome);
      SADTStore.sortDirModelo = ordenarNome ? 'DESC' : 'ASC';
      SADTStore.sortFieldModelo = value; 
      SADTStore.modelosConfig = [];
      SADTStore.pageNumberModelo = 0;
      loadModelos();
    } else {
      setOrdenarStatus(!ordenarStatus);
      SADTStore.sortDirModelo = ordenarStatus ? 'DESC' : 'ASC';
      SADTStore.sortFieldModelo = value;
      SADTStore.modelosConfig = [];
      SADTStore.pageNumberModelo = 0;
      loadModelos();
    }
  }

  const handleClickRow = (id) => (event) =>{
    SADTStore.idModeloSelecionado = id;
    handleEditar();
  }

  const loadMoreModelos = () => {
    if (modelosLoading) {
      return;
    }

    SADTStore.pageNumberModelo = SADTStore.modelosConfig.length > 0 ? SADTStore.pageNumberModelo + 1 : 0;
    loadModelos();

  }

  return (
    <Paper className={classes.root}>
    <Scroll
          loadMore={loadMoreModelos}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainerModelos}
        >
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.rowHeader}>
            <CustomTableCell>
                NOME
                <IconButton aria-label="expand row" size="small" className={classes.botaoOrganizar} onClick={() => handleClickOrdenar("nome")}>
                {
                  ordenarNome ? 
                    <ArrowDropUpIcon className={classes.colorIconOrdenacao}/>
                  :
                    <ArrowDropDownIcon className={classes.colorIconOrdenacao}/>
                }
                </IconButton>
            </CustomTableCell>
            <CustomTableCell align="center">
                STATUS
                <IconButton aria-label="expand row" size="small" className={classes.botaoOrganizar} onClick={() => handleClickOrdenar("ativo")}>
                {
                  ordenarStatus ? 
                    <ArrowDropUpIcon className={classes.colorIconOrdenacao}/>
                  :
                    <ArrowDropDownIcon className={classes.colorIconOrdenacao}/>
                }
                </IconButton>    
            </CustomTableCell>
            <CustomTableCell align="center"/>
          </TableRow>
        </TableHead>

        <TableBody>
          {SADTStore.modelosConfig.map((modeloConfig) => 
            <TableRow className={modeloConfig.ativo ? classes.row : classes.rowInativo} key={modeloConfig.id} >
              <CustomTableCell 
                component="th" 
                scope="row" 
                align="left" 
                className={modeloConfig.ativo ? classes.nameAtivo : classes.nameInativo } 
                onClick={handleClickRow(modeloConfig.id)}
              > 
                {modeloConfig.nome} 
              </CustomTableCell>
              <CustomTableCell 
                align="center" 
                className={classes.colunaStatus} 
                onClick={handleClickRow(modeloConfig.id)}
              > 
                <div 
                  style={{
                    color: '#fff', 
                    width: 100, 
                    backgroundColor: modeloConfig.ativo  ? '#26ACA9' : ' rgba(38, 172, 169, 0.5)', borderRadius: 50
                  }}
                >
                  {modeloConfig.ativo ? "Ativo" : "Inativo"}
                </div>
              </CustomTableCell>
              <CustomTableCell align="center"style={{width: 100}}>
                <IconButton 
                  aria-label="expand row" 
                  size="small" 
                  onClick={handleClickMore('bottom-end', modeloConfig.ativo, modeloConfig.id)}
                >
                  <MoreHorizIcon/>
                </IconButton>
              </CustomTableCell>
            </TableRow>
          )}
          
          <Popper open={openMore} anchorEl={anchorEl} placement={placement} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.paperBotaoMore}>
                    <ClickAwayListener onClickAway={handleCloseMore}>
                      <MenuItem className={classes.menuMore} onClick={handleEditar}>
                        <EditIcon className={classes.iconMenuMore}/>
                        Editar
                      </MenuItem>
                      <MenuItem className={classes.menuMore} onClick={!isLoading && handleDuplicar}>
                        <FileCopyIcon className={classes.iconMenuMore}/>
                        Duplicar
                      </MenuItem>
                      { statusMore === "Inativo" ?
                        <MenuItem className={classes.menuMore} onClick={!isLoading && handleAtivar}>
                          <VisibilityIcon className={classes.iconMenuMore}/>
                          Ativar
                        </MenuItem> 
                      :           
                      <MenuItem className={classes.menuMore} onClick={!isLoading &&handleInativar}>
                        <VisibilityOffIcon className={classes.iconMenuMore}/>
                          Desativar
                        </MenuItem>
                      }  
                      </ClickAwayListener>          
                  </Paper>
                </Fade>
              )}
            </Popper>
        
        </TableBody>
      </Table>
        {modelosLoading && (
          <div className={classes.notFoundContainer}>
              <CircularProgress/>
          </div>
        )}
</Scroll>
    </Paper>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const CustomizedTableWithStyles = withStyles(styles)(CustomizedTable);
export default inject("SADTStore")(CustomizedTableWithStyles)
