import React from "react";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/Remove";
import { fontColor } from "../../../assets/jss/appHealth.style";
import { withStyles, Fab } from "@material-ui/core";
import DrugForm from "./DrugForm";
import { getRouteAdmByValue } from '../../../services/receituarioService';

const RecipeList = ({
  title,
  recipes,
  handleEdit,
  handleRemove,
  readOnly,
  classes,
  handleChange,
  handleSave
}) => (
    <Container>
      <Header>
        {title}
      </Header>

      <div>
        {recipes.map((r) =>
          !r.isEditing ? (
            <Recipe key={r.id}>
              <RecipeInformation>
                <InformationItem>
                  <ItemLabel>{r.drug ? r.drug.label : ''}</ItemLabel>
                  {r.amount &&
                    <>
                      <ItemLabel>{r.amount}</ItemLabel>
                    </>
                  }
                </InformationItem>
                {r.routeAdm && 
                  <InformationAdscricao>
                    {getRouteAdmByValue(r.routeAdm)}
                  </InformationAdscricao>
                }
                <InformationAdscricao>{r?.drug?.principioAtivo }</InformationAdscricao>
                <InformationAdscricao>{r?.ascription?.label || ''}</InformationAdscricao>

              </RecipeInformation>

              {
                !readOnly && (
                  <Actions>
                    <Fab
                      className={classes.buttonEdit}
                      onClick={handleEdit(r.id)}
                    >
                      <EditIcon fontSize={'inherit'} />
                    </Fab>
                    <Fab
                      className={classes.buttonRemove}
                      onClick={handleRemove(r.id)}
                    >
                      <RemoveIcon />
                    </Fab>
                  </Actions>
                )
              }
            </Recipe>
          ) : (
              <div key={r.id}>
                <Recipe style={{ position: "relative", zIndex: 2 }}>
                  <DrugForm
                    key="change-form"
                    recipe={r}
                    type={r.useType}
                    onChange={handleChange()}
                    onBlur={handleSave(r.id)}
                  />
                </Recipe>
              </div>
            ))}
      </div>
    </Container>
  );

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
`;

const Header = styled.div`
  font-family: Nexa Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  display: flex;
`;

const Recipe = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.725rem 1.25rem;
  color: ${fontColor};
`;

const RecipeInformation = styled.div`
  margin-right: 15px;
  width: 100%;
`;

const InformationItem = styled.div`
  justify-content: space-between;
  font-size: 1rem;
  margin: 0;
  color: ${fontColor};
  white-space: nowrap;
  display: flex;
  @media (max-width: 900px){
    white-space: normal !important;
  }           
`;

const InformationAdscricao = styled.p`
  font-size: 0.8rem;
  margin: 0;
  color: ${fontColor};
  display: flex;        
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: -8px;
`;

const ItemLabel = styled.div`
  white-space: pre-wrap;
  @media (max-width: 900px){
    white-space: normal !important
  }     

`;

export default withStyles(theme => ({
  buttonEdit: {
    backgroundColor: '#26ACA9',
    minWidth: 20,
    minHeight: 20,
    width: 24,
    height: 24,
    color: 'white',
    alignContent: 'center',
    fontSize: 'medium',
    margin: 5,
    padding: 5
  },
  buttonRemove: {
    backgroundColor: '#FB7676',
    minWidth: 20,
    minHeight: 20,
    width: 24,
    height: 24,
    color: 'white',
    alignContent: 'center',
    fontSize: '18px',
    margin: 5
  }
}))(RecipeList);
