import React, { useEffect, useMemo, useState } from 'react'
import Dialog from '../../../components/Dialog/Dialog'
import { Button } from '../../../components/ui/Buttons'
import CloseIcon from '../../../components/Icon/Close'
import { withStyles } from '@material-ui/core'
import { InputCPFForm } from '../../../components/Input/InputCPFForm'
import { InputPhoneForm } from '../../../components/Input/InputPhoneForm'
import InputForm from '../../../components/Input/InputForm'
import ItemGrid from '../../../components/Modal/ItemGrid'
import InputDateForm from '../../../components/Input/InputDateForm'
import {
  findAllSujeitoAtencao,
  liberarAcessoVizi,
} from '../../../services/SolicitacoesPacientesService'
import { buildUrlFotoPerfil } from '../../../config/config'
import { Person } from '@material-ui/icons'
import string from '../../../utils/string'
import Scroll from '../../../components/InfiniteScroll/Scroll'
import classNames from 'classnames'

function VincularPacienteModal({ open, onClose, classes, data, refetch, openNotification }) {
  const [values, setValues] = useState({
    nome: '',
    documento: '',
    telefonePrincipal: '',
    dataNascimento: '',
  })
  const [sujeitosAtencao, setSujeitosAtencao] = useState([])
  const [sujeitoAtencaoSelecionado, setSujeitoAtencaoSelecionado] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)

  const loadSujeitoAtencao = async filters => {
    try {
      if (!hasMore) return
      const { content, last } = await findAllSujeitoAtencao(page, filters)
      setSujeitosAtencao(prevState => (page === 0 ? content : [...prevState, ...content]))
      setHasMore(!last)
    } catch (error) {
      console.error(error)
      openNotification('Erro ao carregar os pacientes')
    }
  }

  const loadMore = () => {
    setPage(page + 1)
    loadSujeitoAtencao({
      nome: values.nome,
      telefone: values.telefonePrincipal,
      documento: values.documento,
      dataNascimento: values.dataNascimento,
    })
  }

  const handleChange = (value, field) => {
    setValues(prevState => ({ ...prevState, [field]: value }))
    setHasMore(true)
    setPage(0)
    setSujeitosAtencao([])
    loadSujeitoAtencao({ [field]: value })
  }

  const onSubmit = async () => {
    if (!sujeitoAtencaoSelecionado || !data?.paciente) return
    setLoading(true)
    try {
      await liberarAcessoVizi({
        sujeitoAtencaoId: sujeitoAtencaoSelecionado?.id,
        pacienteId: data?.paciente?.id,
      })
      await refetch()
      onClose()
    } catch (error) {
      console.error(error)
      openNotification('Erro ao vincular o paciente')
    } finally {
      setLoading(false)
    }
  }

  const disabledButton = !sujeitoAtencaoSelecionado || !data?.paciente;

  useEffect(() => {
    if (data?.paciente) {
      loadSujeitoAtencao({
        nome: data?.paciente?.nome,
      })
      setValues({
        nome: data?.paciente?.nome,
        documento: data?.paciente?.documento,
        telefonePrincipal: data?.paciente?.telefonePrincipal,
        dataNascimento: data?.paciente?.dataNascimento,
      })
    }
  }, [data])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth={true}
      classes={{ paper: classes.paper }}
    >
      <header className={classes.header}>
        <div className={classes.headerDialog}>
          <h3>Pesquisar paciente</h3>
          <Button kind='transparent' shape='circle' onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.headerFields}>
          <ItemGrid size={6} label={'Nome:'} bold>
            <InputForm
              value={values.nome}
              onChange={e => handleChange(e.target.value, 'nome')}
              placeholder={'Digite o nome'}
              type='text'
              className={classes.inputContainer}
            />
          </ItemGrid>
          <ItemGrid size={6} label={'Telefone:'} bold>
            <InputPhoneForm
              placeholder={'(00) 0 0000 0000'}
              className={classes.inputContainer}
              value={values.telefonePrincipal}
              onChange={e => handleChange(e.target.value, 'telefonePrincipal')}
            />
          </ItemGrid>
        </div>
        <div className={classes.headerFields}>
          <ItemGrid size={8} label={'CPF:'} bold>
            <InputCPFForm
              placeholder={'000.000.000-00'}
              className={classes.inputContainer}
              value={values.documento}
              onChange={e => handleChange(e.target.value, 'documento')}
            />
          </ItemGrid>
          <ItemGrid size={4} label={'Nascimento:'} bold>
            <InputDateForm
              className={classes.inputContainer}
              placeholder={'Data Nascimento'}
              value={values.dataNascimento}
              onChange={value => handleChange(value, 'dataNascimento')}
              iconposition='end'
            />
          </ItemGrid>
        </div>
      </header>
      <div className={classes.contentDialog}>
        <Scroll loadMore={loadMore} hasMore={hasMore} pageStart={0} initialLoad={false}>
          {sujeitosAtencao.map((item, index) => (
            <div
              className={classNames(
                classes.cardPaciente,
                sujeitoAtencaoSelecionado?.id === item?.id && classes.cardPacienteSelected
              )}
              key={index}
              onClick={() => setSujeitoAtencaoSelecionado(item)}
            >
              {item?.fotoPerfil ? (
                <img
                  src={buildUrlFotoPerfil(item?.fotoPerfil)}
                  alt='Foto do paciente'
                  className={classes.cardPacienteFoto}
                />
              ) : (
                <div className={classes.cardPacienteFotoDefault}>
                  <Person color='#505050' />
                </div>
              )}
              <div className={classes.cardPacienteInfo}>
                <div className={classes.cardPacienteRow}>
                  <ItemGrid size={8} label={'Nome:'} bold>
                    <h3>{string.truncate(item?.nome, 15)}</h3>
                  </ItemGrid>
                  <ItemGrid size={4} label={'Idade:'} bold>
                    <h3>{item?.idade}</h3>
                  </ItemGrid>
                </div>
                <div className={classes.cardPacienteRow}>
                  <ItemGrid size={8} label={'Telefone:'} bold>
                    <h3>{item?.contato?.telefonePrincipal}</h3>
                  </ItemGrid>
                  <ItemGrid size={4} label={'CPF:'} bold>
                    <h3>{item?.documento}</h3>
                  </ItemGrid>
                </div>
              </div>
            </div>
          ))}
        </Scroll>
      </div>
      <footer className={classes.footer}>
        <Button kind='transparent' onClick={onClose} fullWidth>
          Cancelar
        </Button>
        <Button
          kind='primary'
          onClick={onSubmit}
          fullWidth
          isLoading={loading}
          disabled={disabledButton}
        >
          Vincular
        </Button>
      </footer>
    </Dialog>
  )
}

const styles = theme => ({
  paper: {
    height: '500px',
    borderRadius: 8,
    maxWidth: '450px',
    overflow: 'hidden',
    background: '#F2F2F2',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05)',
  },
  headerFields: {
    display: 'flex',
    gap: '16px',
    padding: '8px 16px',
  },
  headerDialog: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    height: 60,
    '& h3': {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '21px',
      color: '#505050',
    },
  },

  cardPacienteFoto: {
    width: '65px',
    height: '100px',
    borderRadius: '8px',
    objectFit: 'cover',
  },

  cardPacienteFotoDefault: {
    width: '65px',
    height: '100px',
    borderRadius: '8px',
    background: '#F2F2F2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentDialog: {
    padding: '16px 16px 0',
    height: 'calc(100% - 60px)',
    overflowY: 'auto',
    cursor: 'pointer',
    '& > div > div': {
      gap: '8px',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  inputContainer: {
    width: '100%',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '4px 8px',

    '& > div': {
      '& > input': {
        border: 'none',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
      '& > p': {
        overflow: 'hidden',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: '16px',
  },
  cardPaciente: {
    display: 'flex',
    gap: '16px',
    padding: '8px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05)',
  },
  cardPacienteSelected: {
    background: '#d8d8d8',
  },
  cardPacienteInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'space-around',
  },
  cardPacienteRow: {
    display: 'flex',
    '& h3': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '16px',
      color: '#2E2E2E',
      margin: 0,
    },
  },
})

export default withStyles(styles)(VincularPacienteModal)
