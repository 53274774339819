import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const StopIcon = props => {
  return (
    <svg 
      width="18" 
      height="18" 
      viewBox="0 0 18 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path 
        d="M2.67298 0.792839C1.52843 0.808012 0.612886 1.74816 0.62806 2.89271L0.792904 15.3271C0.808077 16.4716 1.74822 17.3872 2.89277 17.372L15.3272 17.2072C16.4717 17.192 17.3872 16.2518 17.3721 15.1073L17.2072 2.67292C17.1921 1.52836 16.2519 0.612821 15.1074 0.627995L2.67298 0.792839Z" fill="currentColor"
      />
    </svg>
  );
};

export default StopIcon;
