import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';

import Avatar from 'react-avatar';
import { buildChatFileUrl, buildUrlFotoPerfilLight } from '../../../config/config';

import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

import string from '../../../utils/string';
import moment from 'moment';
import ContactItemMenu from './ContactItemMenu';
import PinIcon from '../../../components/Icon/PinIcon';
import FilledCircleIcon from '../../../components/Icon/FilledCircleIcon';
import MicIcon from '../../../components/Icon/MicIcon';
import PhotoIcon from '../../../components/Icon/PhotoIcon';
import { formatAudioDuration, getAudioDuration } from '../../../utils/getAudioDuration';
import DocumentTextIcon from '../../../components/Icon/DocumentTextIcon';

const styles = ({
  root: {
    display: 'flex',
    flex: 1,
    height: '48px',
  },
  selected: {
    backgroundColor: '#F1FCFA',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    flex: 1,
    width: 'calc(100% - 32px)',
    gap: '10px',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  profile: {
    height: '48px',
    width: '48px',
    borderRadius: '50%',
  },
  profileImage: {
    height: '100%',
    width: '100%',
  },
  contactContainer: {
    display: 'flex',  
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80%'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    flex: 1,
    textWrap: 'wrap',
  },
  name: {
    fontWeight: '600'
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 'calc(100% - 76px)',
    '&>span': {
      flex: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60px'
  },
  lastMessageTime: {
    fontSize: '12px !important'
  },
  messagePreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '&>span': {
      flex: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  },
  iconsContainers: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  }
})

const ContactItem = observer(({ 
  classes, 
  contact, 
  accessToken,
  chatStore,
  socketStore
}) => {
  const [openContactOptions, setOpenContactOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastMessagePreviewContent, setLastMessagePreviewContent] = useState('');
  const { profissionalSaudeContato, fixado, ultimaMensagem } = contact;
  const { nome, fotoPerfil, id } = profissionalSaudeContato;

  const { selectedContact, loggedUser } = chatStore;
  const selectedContactId = selectedContact?.contact?.profissionalSaudeContato?.id;

  const isSelected = selectedContactId === id;
  
  const handleClickContact = (e) => {
    e.preventDefault();
    setOpenContactOptions(true);
    setAnchorEl(e.currentTarget);
  }

  const getPreviewTimeOrDate = () => {
    const isToday = moment().isSame(moment(ultimaMensagem?.dataHoraCriacao), 'day');
    const isThisWeek = moment().isSameOrAfter(moment(ultimaMensagem?.dataHoraCriacao), 'week');
    if (isToday) {
      return moment(ultimaMensagem?.dataHoraCriacao).format('HH:mm');
    }

    if (isThisWeek) {
      return moment(ultimaMensagem?.dataHoraCriacao).locale('pt-br').format('ddd');
    }

    return moment(ultimaMensagem?.dataHoraCriacao).format('DD/MM/YYYY');
  }

  const getLastMessageContent = async () => {
    const { chatUrl } = socketStore;

    switch (ultimaMensagem?.tipoMensagem) {
      case 'AUDIO':
        const audioUrl = buildChatFileUrl(ultimaMensagem?.urlDownload, chatUrl);
        const audioDuration = await getAudioDuration(audioUrl);
        const audioDurationFormated = formatAudioDuration(audioDuration);

        return (
          <div className={classes.messagePreviewContainer}>
            <MicIcon size={16} color="#505050" />
            <span>
              {audioDurationFormated}
            </span>
          </div>
        )
      case 'IMAGE':
        return (
          <div className={classes.messagePreviewContainer}>
            <PhotoIcon size={16} color="#505050" />
            <span>Foto</span>
          </div>
        );
      case 'FILE':
        return (
          <div className={classes.messagePreviewContainer}>
            <DocumentTextIcon size={16} color="#505050" />
            <span>
              {ultimaMensagem?.mensagemAmazonS3Objeto?.nomeComExtensao || 'Arquivo'}
            </span>
          </div>
        )
      default:
        return <span>{ultimaMensagem?.mensagem}</span>
    };
  };

  const loadLastMessagePreview = async () => {
    const lastMessageContent = await getLastMessageContent();
    setLastMessagePreviewContent(lastMessageContent);
  }

  useEffect(() => {
    if (ultimaMensagem) {
      loadLastMessagePreview();
    }
  }, [ultimaMensagem]);

  const messagePreviewDate = ultimaMensagem?.dataHoraCriacao ? getPreviewTimeOrDate() : '';
  const hasUnreadMessage = ultimaMensagem?.lido === false && ultimaMensagem?.remetente?.id !== loggedUser.id;

  return (
    <div className={classNames(classes.root, isSelected && classes.selected)}>
      <div 
        className={classes.container}
        onClick={() => chatStore.setSelectedContact(contact)}
        onContextMenu={(e) => handleClickContact(e, contact)}
      >
        <Avatar
          size={48}
          name={nome}
          src={buildUrlFotoPerfilLight(fotoPerfil, accessToken)}
          round={true}
          maxInitials={2}
        />
        <div className={classes.contactContainer}>
          <div className={classes.textArea}>
            <span className={classes.name}>{string.truncate(nome, 25, '...')}</span>
            {lastMessagePreviewContent}
          </div>
          <div className={classes.contactDetails}>
            <span className={classes.lastMessageTime}>
              {messagePreviewDate}
            </span>
            <div className={classes.iconsContainers}>
              {typeof fixado === 'number' && (
                <PinIcon color="#505050" size={18} />
              )}
              {hasUnreadMessage && (<FilledCircleIcon color="#219A97" size={12} />)}
            </div>
          </div>
        </div>
      </div>
      <ContactItemMenu
        contact={contact}
        open={openContactOptions}
        onClose={() => setOpenContactOptions(false)}
        anchorEl={anchorEl}
        handleToggleFixarContato={chatStore.handleToggleFixarContato}
      />
    </div>
  );
});

const ContactItemStyles = withStyles(styles)(ContactItem);
const store = ['chatStore', 'socketStore'];
export default inject(...store)(ContactItemStyles);
