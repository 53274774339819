import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const FilledCircleIcon = props => {
  return (
    <svg 
    width="13" 
    height="14" 
    viewBox="0 0 13 14" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    { ...normalizeProps(props)}
    >
      <rect y="0.986328" width="12.0287" height="12.0287" rx="6.01437" fill="currentColor"/>
      <rect x="0.751797" y="1.73813" width="10.5252" height="10.5252" rx="5.26258" stroke="#DCDCDC" stroke-opacity="0.2" stroke-width="1.50359"/>
    </svg>
  );
};

export default FilledCircleIcon;