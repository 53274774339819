import React from "react";
import { Page, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import moment from "moment";
import Header from "./Header";
import FormGroup from "./FormGroup";
import FormHeading from "./FormHeading";
import FormLabel from "./FormLabel";

Font.register({
    family: "Lato-Bold",
    src: "/fonts/lato-v15-latin-700.ttf",
});

Font.register({
    family: "Lato-Regular",
    src: "/fonts/lato-v15-latin-regular.ttf",
});

const SADTPdfDocument = ({ guiaServico, urlLogo }) => {
    const paginas = [];
    const guiaServicoTussViews = [...guiaServico.guiaServicoTussViews];

    if (guiaServicoTussViews.length >= 5) {
        let last = false;
        let count = 0;
        let tussViewsSeparadaPorPagina = [];
        guiaServicoTussViews.forEach((item, index) => {
            count = count + 1;
            last = guiaServicoTussViews.length === index + 1;

            tussViewsSeparadaPorPagina.push(item);

            if (count === 4 || last) {
                count = 0;
                paginas.push({
                    ...guiaServico,
                    guiaServicoTussViews: [...tussViewsSeparadaPorPagina],
                });
                tussViewsSeparadaPorPagina = [];
                return;
            }
        });
    } else {
        paginas.push(guiaServico);
    }

    return (
        <Document>
            {paginas.map((item, index) => (
                <Pagina key={index} guiaServico={item} urlLogo={urlLogo}/>
            ))}
        </Document>
    );
};

const verificaRN = dataNascimento => {
    if (dataNascimento !== ""){
        const diaAtual = moment();
        const idadeEmDias = diaAtual.diff(dataNascimento, "days");
        return idadeEmDias <= 28 ? "S" : "N"
    }
};

const Pagina = ({ guiaServico, urlLogo }) => (
    <Page style={styles.page} orientation="landscape" size="A4">
        <View style={styles.container}>
            {!!urlLogo && (
                <View style={styles.contentLogo}>
                    <Image alt="Logo" src={urlLogo} style={styles.logo}/>
                </View>
            )}
            <Header
                titulo="GUIA DE SERVIÇOS O PROFISSIONAL / SERVIÇOS O AUXILIAR DE DIAGNÓSTICO E TERAPIA - SP/SADT"
                numeroGuiaPrestador=""
            />
            <View style={styles.formGroupContainer}>
                <FormGroup label="1 - Registro ANS" maxLength={6} />
                <FormGroup label="3 - Número Guia Principal" maxLength={20} />
            </View>
            <View style={styles.formGroupContainer}>
                <FormGroup
                    label="4 - Data da Autorização"
                    type="date"
                    maxLength={10}
                />
                <FormGroup label="5 - Senha" maxLength={20} />
                <FormGroup
                    label="6 - Data de Validade da Senha"
                    type="date"
                    maxLength={10}
                />
                <FormGroup
                    label="7 - Número da Guia Atribuído pela Operadora"
                    maxLength={20}
                />
            </View>
            <FormHeading label="Dados do Beneficiário" />
            <View style={styles.formGroupContainer}>
                <FormGroup
                    label="8 - Número da Carteira"
                    value={
                        guiaServico?.beneficiarioConvenioNumeroCarteira || ""
                    }
                    maxLength={20}
                />
                <FormGroup
                    label="9 - Validade da Carteira"
                    type="date"
                    value={
                        guiaServico?.beneficiarioConvenioValidadeCarteira ? 
                            moment(guiaServico?.beneficiarioConvenioValidadeCarteira).format("DD/MM/YYYY") 
                            : 
                            ""
                    }
                    maxLength={10}
                />
                <FormGroup
                    label="10 - Nome"
                    maxLength={30}
                    value={guiaServico?.beneficiarioNome || ""}
                    style={{ paddingRight: 17 }}
                />
                <FormGroup
                    label="11 - Cartão Nacional de Saúde"
                    value={guiaServico?.beneficiarioCns || ""}
                    maxLength={15}
                />
                <FormGroup 
                    label="12 - Atendimento a RN" 
                    maxLength={1} 
                    value={verificaRN(guiaServico.sujeitoAtencao?.dataNascimento || "")}
                />
            </View>
            <FormHeading label="Dados do Solicitante" />
            <View style={styles.formGroupContainer}>
                <FormGroup label="13 - Código na Operadora" maxLength={14} />
                <FormGroup
                    label="14 - Nome do Contratado"
                    maxLength={70}
                    noBorder
                    style={{ paddingRight: 17 }}
                />
            </View>
            <View style={styles.formGroupContainer}>
                <FormGroup
                    label="15 - Nome do Profissional Solicitante"
                    maxLength={22}
                    value={guiaServico?.solicitanteNome || ""}
                />
                <FormGroup 
                    label="16 - Conselho Profissional" 
                    maxLength={2} 
                />
                <FormGroup 
                    label="17 - Número do Conselho" 
                    maxLength={15} 
                    value={guiaServico.profissionalSaude?.numeroConselho || ""}
                />
                <FormGroup 
                    label="18 - UF" 
                    maxLength={2} 
                    value={guiaServico.profissionalSaude?.ufConselho || ""}
                />
                <FormGroup label="19 - Código CBO" maxLength={6} />
                <FormGroup
                    label="20 - Assinatura do Profissional Solicitante"
                    maxLength={26}
                    noBorder
                    style={{ paddingRight: 23 }}
                />
            </View>
            <FormHeading label="Dados da Solicitação / Procedimentos ou Itens Assistenciais Solicitados" />
            <View style={styles.formGroupContainer}>
                <FormGroup label="21 - Caráter do Atendimento" maxLength={1} />
                <FormGroup
                    label="22 - Data da Solicitação"
                    type="date"
                    value={
                        guiaServico?.dataHoraLancamento
                            ? moment(guiaServico?.dataHoraLancamento).format(
                                  "DD/MM/YYYY"
                              )
                            : ""
                    }
                    maxLength={10}
                />
                <FormGroup
                    label="23 - Indicação Clínica"
                    maxLength={66}
                    value={
                        guiaServico?.cid10Subcategoria?.descricaoAbreviada || ""
                    }
                    style={{ paddingRight: 12 }}
                />
            </View>
            <View style={styles.formGroupContainerBorder}>
                {guiaServico?.guiaServicoTussViews?.length > 0 &&
                    guiaServico.guiaServicoTussViews.map((item, index) => (
                        <View
                            key={index}
                            style={[
                                styles.formGroupContainer,
                                { marginBottom: 0 },
                            ]}
                        >
                            <FormGroup
                                label={index !== 0 ? undefined : "24 - Tabela"}
                                maxLength={2}
                                showRowNumber
                                startRowNumber={index + 1}
                                value={item?.tussView?.tabela || ""}
                                style={[
                                    styles.formGroupRows,
                                    styles.formGroupNoMarginLeft,
                                    styles.formGroupTabela,
                                ]}
                            />
                            <FormGroup
                                label={
                                    index !== 0
                                        ? undefined
                                        : "25 - Código do Procedimento ou Item Assistencial"
                                }
                                maxLength={10}
                                value={item?.tussView?.codigoTermo || ""}
                                style={[
                                    styles.formGroupRows,
                                    styles.formGroupCodigoTermo,
                                ]}
                            />
                            <FormGroup
                                label={
                                    index !== 0 ? undefined : "26 - Descrição"
                                }
                                maxLength={55}
                                style={[
                                    styles.formGroupRows,
                                    styles.formGroupDescricao,
                                ]}
                                value={item?.descricao || item?.tussView?.descricao || ""}
                            />
                            <FormGroup
                                label={
                                    index !== 0
                                        ? undefined
                                        : "27 - Qtde. Solic."
                                }
                                maxLength={3}
                                style={[
                                    styles.formGroupRows,
                                    styles.formGroupQtdeSolic,
                                ]}
                                value={item?.quantidadeSolicitada || ""}
                            />
                            <FormGroup
                                label={
                                    index !== 0 ? undefined : "28 - Qtde. Aut."
                                }
                                maxLength={3}
                                style={[
                                    styles.formGroupRows,
                                    styles.formGroupQtdeAut,
                                ]}
                            />
                        </View>
                    ))}
            </View>
            <FormHeading label="Dados do Contratado Executante" />
            <View style={styles.formGroupContainer}>
                <FormGroup label="29 - Código na Operadora" maxLength={14} />
                <FormGroup
                    label="30 - Nome do Contratado"
                    maxLength={62}
                    noBorder
                    style={{ paddingRight: 16 }}
                />
                <FormGroup label="31 - Código CNES" maxLength={7} />
            </View>
            <FormHeading label="Dados do Atendimento" />
            <View style={styles.formGroupContainer}>
                <FormGroup label="32 - Tipo de Atendimento" maxLength={2} />
                <FormGroup
                    label="33 - Indicação de Acidente (acidente ou doença relacionada)"
                    maxLength={1}
                />
                <FormGroup label="34 - Tipo de Consulta" maxLength={1} />
                <FormGroup
                    label="35 - Motivo de Encerramento do Atendimento"
                    maxLength={2}
                />
            </View>
            <FormHeading label="Dados da Execução / Procedimentos e Exames Realizados" />
            <View
                style={[
                    styles.formGroupContainer,
                    styles.formGroupContainerBorder,
                ]}
            >
                <FormGroup
                    label="36 - Data"
                    maxLength={10}
                    type="date"
                    rows={5}
                    showRowNumber
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="37 - Hora Inicial"
                    value={"  :  "}
                    maxLength={5}
                    rows={5}
                    type="time"
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="38 - Hora Final"
                    value={"  :  "}
                    maxLength={5}
                    rows={5}
                    type="time"
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="39 - Tabela"
                    maxLength={2}
                    rows={5}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="40 - Código do Procedimento"
                    maxLength={10}
                    rows={5}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="41 - Descrição"
                    maxLength={14}
                    rows={5}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                    onlyBorderBottom
                />
                <FormGroup
                    label="42 - Qtde."
                    maxLength={3}
                    rows={5}
                    style={[
                        styles.formGroupRows,
                        styles.formGroupNoMarginLeft,
                        { paddingRight: 0 },
                    ]}
                />
                <FormGroup
                    label="43 - Via"
                    maxLength={1}
                    rows={5}
                    style={[
                        styles.formGroupRows,
                        styles.formGroupNoMarginLeft,
                        { paddingRight: 0 },
                    ]}
                />
                <FormGroup
                    label="44 - Tec."
                    maxLength={1}
                    rows={5}
                    style={[
                        styles.formGroupRows,
                        styles.formGroupNoMarginLeft,
                        { paddingRight: 0 },
                    ]}
                />
                <FormGroup
                    label="45 - Fator Red./Acresc."
                    maxLength={4}
                    value={" ,  "}
                    rows={5}
                    type="currency"
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="46 - Valor Unitário (R$)"
                    type="currency"
                    maxLength={10}
                    rows={5}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="47 - Valor Total (R$)"
                    type="currency"
                    maxLength={10}
                    rows={5}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
            </View>
            <FormHeading label="Identificação do(s) Profissional(is) Executante(s)" />
            <View
                style={[
                    styles.formGroupContainer,
                    styles.formGroupContainerBorder,
                ]}
            >
                <FormGroup
                    label="48 - Seq.Ref"
                    maxLength={2}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="49 - Grau Part."
                    maxLength={2}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="50 - Código na Operadora/CPF"
                    maxLength={14}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="51 - Nome do Profissional"
                    maxLength={28}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                    onlyBorderBottom
                />
                <FormGroup
                    label="52 - Conselho Profissional"
                    maxLength={2}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="53 - Número no Conselho"
                    maxLength={15}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="54 - UF"
                    maxLength={2}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
                <FormGroup
                    label="55 - Código CBO"
                    maxLength={6}
                    rows={3}
                    style={[styles.formGroupRows, styles.formGroupNoMarginLeft]}
                />
            </View>
            <View
                style={[
                    styles.formGroupLabelContainer,
                    styles.formGroupContainerBorder,
                ]}
            >
                <View style={styles.formLabels}>
                    <FormLabel
                        label="56 - Data de Realização de Procedimentos em Série"
                        style={styles.formLabel}
                    />
                    <FormLabel
                        label="57 - Assinatura do Beneficiário ou Responsável"
                        style={styles.formLabel}
                    />
                </View>
                <View style={styles.formGroups}>
                    <FormGroup
                        maxLength={10}
                        type="date"
                        rows={2}
                        showRowNumber
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                            styles.formGroupNoMarginRight,
                            { paddingRight: 0.5 },
                        ]}
                    />
                    <FormGroup
                        maxLength={6}
                        rows={2}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                        ]}
                        onlyBorderBottom
                    />

                    <FormGroup
                        maxLength={10}
                        type="date"
                        rows={2}
                        showRowNumber
                        startRowNumber={3}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                            styles.formGroupNoMarginRight,
                            { paddingRight: 0.5 },
                        ]}
                    />
                    <FormGroup
                        maxLength={6}
                        rows={2}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                        ]}
                        onlyBorderBottom
                    />

                    <FormGroup
                        maxLength={10}
                        type="date"
                        rows={2}
                        showRowNumber
                        startRowNumber={5}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                            styles.formGroupNoMarginRight,
                            { paddingRight: 0.5 },
                        ]}
                    />
                    <FormGroup
                        maxLength={6}
                        rows={2}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                        ]}
                        onlyBorderBottom
                    />

                    <FormGroup
                        maxLength={10}
                        type="date"
                        rows={2}
                        showRowNumber
                        startRowNumber={7}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                            styles.formGroupNoMarginRight,
                            { paddingRight: 0.5 },
                        ]}
                    />
                    <FormGroup
                        maxLength={6}
                        rows={2}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                        ]}
                        onlyBorderBottom
                    />

                    <FormGroup
                        maxLength={10}
                        type="date"
                        rows={2}
                        showRowNumber
                        startRowNumber={9}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                            styles.formGroupNoMarginRight,
                            { paddingRight: 0.5 },
                        ]}
                    />
                    <FormGroup
                        maxLength={6}
                        rows={2}
                        style={[
                            styles.formGroupRows,
                            styles.formGroupNoMarginLeft,
                        ]}
                        onlyBorderBottom
                    />
                </View>
            </View>
            <View style={styles.formGroupContainer}>
                <FormGroup
                    label="58 - Observação / Justificativa"
                    noBorder
                    type="textarea"
                    style={styles.textarea}
                    value={ guiaServico?.observacao || ""}
                />
            </View>
            <View style={styles.formGroupContainer}>
                <FormGroup
                    label="59 - Total Procedimentos (R$)"
                    maxLength={12}
                    type="currency"
                    style={styles.formGroupTotais}
                />
                <FormGroup
                    label="60 - Total de Taxas e Aluguéis (R$)"
                    maxLength={12}
                    type="currency"
                    style={styles.formGroupTotais}
                />
                <FormGroup
                    label="61 - Total de Materiais (R$)"
                    maxLength={12}
                    type="currency"
                    style={styles.formGroupTotais}
                />
                <FormGroup
                    label="62 - Total de OPME (R$)"
                    maxLength={12}
                    type="currency"
                    style={styles.formGroupTotais}
                />
                <FormGroup
                    label="63 - Total de Medicamentos (R$)"
                    maxLength={12}
                    type="currency"
                    style={styles.formGroupTotais}
                />
                <FormGroup
                    label="64 - Total de Gases Medicinais (R$)"
                    maxLength={12}
                    type="currency"
                    style={styles.formGroupTotais}
                />
                <FormGroup
                    label="65 - Total de Geral (R$)"
                    maxLength={12}
                    type="currency"
                    style={styles.formGroupTotais}
                />
            </View>
            <View style={styles.formGroupContainer}>
                <FormGroup
                    label="66 - Assinatura do Responsável pela Autorização"
                    maxLength={28}
                    noBorder
                    style={[styles.formGroupAssinatura, { paddingRight: 5 }]}
                />
                <FormGroup
                    label="67 - Assinatura do Beneficiário ou Responsável"
                    maxLength={28}
                    noBorder
                    style={[styles.formGroupAssinatura, { paddingRight: 5 }]}
                />
                <FormGroup
                    label="68 - Assinatura do Contratado"
                    maxLength={28}
                    noBorder
                    style={[styles.formGroupAssinatura, { paddingRight: 6 }]}
                />
            </View>
        </View>
    </Page>
);

const styles = StyleSheet.create({
    page: {
        padding: 15,
    },
    container: {
        borderWidth: 1,
        borderColor: "#707070",
        paddingHorizontal: 10,
        paddingVertical: 8,
    },
    formGroupContainer: {
        flexDirection: "row",
        marginBottom: 1.5,
    },
    formGroupLabelContainer: {
        flexDirection: "column",
        marginBottom: 1.5,
    },
    formLabels: {
        flexDirection: "row",
    },
    formGroups: {
        flexDirection: "row",
    },
    formLabel: {
        marginTop: 2,
        marginLeft: 2,
        marginRight: 2,
    },
    formGroupContainerBorder: {
        borderColor: "#707070",
        borderWidth: 0.5,
        marginLeft: 2,
        marginRight: 2,
    },
    formGroupRows: {
        borderWidth: 0,
    },
    formGroupNoMarginLeft: {
        marginLeft: 0,
    },
    formGroupNoMarginRight: {
        marginRight: 0,
    },
    formGroupTotais: {
        marginRight: 3,
    },
    formGroupAssinatura: {
        marginRight: 0.6,
    },
    textarea: {
        backgroundColor: "#F9F9F9",
        flex: 1,
        height: 35,
        justifyContent: 'start',
    },
    formGroupTabela: {
        width: 35,
    },
    formGroupCodigoTermo: {
        width: 140,
    },
    formGroupDescricao: {
        width: 500,
    },
    formGroupQtdeSolic: {
        width: 48,
    },
    formGroupQtdeAut: {
        width: 48,
    },
    contentLogo: {
        position: 'absolute',
        top: 4,
        left: 10,
    },  
    logo: {
        maxHeight: '25px',
        maxWidth: '90px',
    }
});

export default SADTPdfDocument;
