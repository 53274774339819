import React from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

const GraficoAtendimentos = (props) => {
  const { data, datasKey } = props;

  return (
    <ResponsiveContainer width="100%" height={260} id={"grafico-atendimento"}>
      <AreaChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={datasKey && datasKey[0]}
          stroke="#27B0AD"
          strokeWidth={2}
          fill="#fff"
        />
        <Area
          type="monotone"
          dataKey={datasKey && datasKey[1]}
          stroke="rgba(236, 233, 241, 1)"
          strokeWidth={2}
          fill="#fff"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default GraficoAtendimentos;
