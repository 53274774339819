export const ausenciaCodigosValidacao = [
    { label: "Beneficiário internado", value: "BENEFICIARIO_INTERNADO" },
    {
      label: "Beneficiário em situação de urgência/emergência",
      value: "BENEFICIARIO_EMERGENCIA",
    },
    {
      label:
        "Intermitência/Instabilidade de sistemas e regularização do atendimento após saída do beneficiário do prestador de serviço",
      value: "INTERMITENCIA",
    },
    {
      label: "Beneficiário se negou a transmitir o número do token",
      value: "BENEFICIARIO_NEGOU_TOKEN",
    },
    {
      label: "Beneficiário em coleta domiciliar",
      value: "BENEFICIARIO_COLETA_DOMICILIAR",
    },
    {
      label: "Material para exames enviado ao prestador por terceiros",
      value: "MATERIAL_EXAMES",
    },
    {
      label: "Beneficiário não possui celular",
      value: "BENEFICIARIO_NAO_POSSUI_CELULAR",
    },
];