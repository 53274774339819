import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const FinanceiroMenuIcon = props => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path 
        d="M2.96433 7.95592C2.95153 6.99094 3.72343 6.19831 4.6884 6.18552L18.1671 6.00683C19.1321 5.99403 19.9247 6.76593 19.9375 7.7309L20.05 16.2175C20.0628 17.1825 19.2909 17.9751 18.3259 17.9879L4.84723 18.1666C3.88226 18.1794 3.08963 17.4075 3.07683 16.4425L2.96433 7.95592ZM5.95297 7.417L5.96621 8.41542C5.97169 8.82898 5.64088 9.16868 5.22732 9.17416L4.2289 9.1874L4.24875 10.685L5.24717 10.6718C6.48785 10.6553 7.48029 9.63624 7.46384 8.39556L7.4506 7.39714L5.95297 7.417ZM11.5403 14.5828C12.7809 14.5663 13.7734 13.5472 13.7569 12.3065C13.7405 11.0658 12.7214 10.0734 11.4807 10.0899C10.24 10.1063 9.24757 11.1254 9.26402 12.3661C9.28047 13.6068 10.2996 14.5992 11.5403 14.5828ZM4.30831 15.1779L5.30674 15.1647C5.7203 15.1592 6.06 15.49 6.06548 15.9036L6.07872 16.902L7.57635 16.8821L7.56311 15.8837C7.54666 14.643 6.52756 13.6506 5.28688 13.6671L4.28846 13.6803L4.30831 15.1779ZM17.0481 15.758C17.0426 15.3444 17.3734 15.0047 17.787 14.9992L18.7854 14.986L18.7656 13.4884L17.7672 13.5016C16.5265 13.5181 15.534 14.5372 15.5505 15.7778L15.5637 16.7763L17.0614 16.7564L17.0481 15.758ZM16.9488 8.26982L16.9356 7.2714L15.438 7.29125L15.4512 8.28968C15.4677 9.53035 16.4868 10.5228 17.7274 10.5063L18.7259 10.4931L18.706 8.99547L17.7076 9.00871C17.294 9.01419 16.9543 8.68338 16.9488 8.26982ZM5.51741 19.6556C6.04717 20.544 7.02314 21.1335 8.13181 21.1188L18.3656 20.9831C20.9848 20.9484 23.08 18.797 23.0453 16.1778L22.9758 10.9361C22.9611 9.82739 22.3459 8.86739 21.4437 8.36137L21.5476 16.1976C21.5714 17.9897 20.1379 19.4618 18.3458 19.4855L5.51741 19.6556Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FinanceiroMenuIcon;