import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const MailIcon = props => {
  return (
    <svg
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M9.26665 3.59932V7.40038C9.26665 8.13676 8.6697 8.73372 7.93332 8.73372H2.06665C1.33027 8.73372 0.733276 8.13676 0.733276 7.40038V3.59932L4.86478 6.03023C4.94823 6.07932 5.05174 6.07932 5.13519 6.03023L9.26665 3.59932ZM7.93332 1.80038C8.61975 1.80038 9.18503 2.31911 9.25856 2.98595L4.99999 5.491L0.741415 2.98595C0.814938 2.31911 1.38022 1.80038 2.06665 1.80038H7.93332Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default MailIcon
