import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const TrashIcon = props => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M8.00005 1C9.14771 1 10.0901 1.87878 10.1911 3.00015L13.6 3C13.9314 3 14.2 3.26863 14.2 3.6C14.2 3.90376 13.9743 4.15479 13.6815 4.19452L13.6 4.2H13.1336L12.12 12.9834C12.0268 13.7907 11.3432 14.4 10.5305 14.4H5.46955C4.65685 14.4 3.97325 13.7907 3.88009 12.9834L2.86565 4.2H2.40005C2.09629 4.2 1.84526 3.97428 1.80553 3.68142L1.80005 3.6C1.80005 3.29624 2.02577 3.04521 2.31863 3.00548L2.40005 3L5.809 3.00015C5.90998 1.87878 6.85239 1 8.00005 1ZM6.80005 6C6.60368 6 6.44036 6.12381 6.40649 6.28709L6.40005 6.35V11.25L6.40649 11.3129C6.44036 11.4762 6.60368 11.6 6.80005 11.6C6.99642 11.6 7.15974 11.4762 7.1936 11.3129L7.20005 11.25V6.35L7.1936 6.28709C7.15974 6.12381 6.99642 6 6.80005 6ZM9.20005 6C9.00368 6 8.84036 6.12381 8.80649 6.28709L8.80005 6.35V11.25L8.80649 11.3129C8.84036 11.4762 9.00368 11.6 9.20005 11.6C9.39642 11.6 9.55974 11.4762 9.5936 11.3129L9.60005 11.25V6.35L9.5936 6.28709C9.55974 6.12381 9.39642 6 9.20005 6ZM8.00005 2.2C7.51624 2.2 7.11268 2.54357 7.02004 3.00004H8.98005C8.88742 2.54357 8.48385 2.2 8.00005 2.2Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default TrashIcon
