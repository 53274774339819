import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { observer } from "mobx-react-lite";
import { Print as PrintIcon } from "@material-ui/icons";
import styles from "../RelatorioStyle";
import { TextFieldSearch } from "../../../components/TextField";
import { findAllProfissionalSaude, relatorioAgendamentosRepasse, relatorioAgendamentosRepasseCsv, relatorioAgendamentosRepasseValorTotal } from "../../../services/RelatorioService";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import HeaderRelatorio from "../HeaderRelatorio";
import Notification from "../../../components/Notification";
import { inject } from "mobx-react";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/ui/Buttons";
import { InputDateForm } from "../../../components/Modal/Input";
import moment from "moment";
import PopperCheck from "../../../components/Popper/PopperCheck";
import { situacoes } from "../../../utils/constants/situacoes";
import RelatorioRepassePdfDocument from "../../../template/pdf/relatorio/Repasse";
import ArrowDownloadIcon from "../../../components/Icon/ArrowDownload";
import { base64Convert } from "../../../utils/base64ToCsv";
import { applyCurrencyMask } from "../../../utils/CurrencyMask";
import { getProfissionalSaudeLogado } from "../../../services/UsuarioService";

const columns = [
  {
    Header: 'Data',
    field: "data",
    getValue: (repasse) => {
      return moment(repasse.data).format("DD/MM/YYYY")
    },
  },
  {
    Header: 'Paciente',
    field: "nome",
    getValue: (repasse) => {
      return repasse.nome
    },
  },
  {
    Header: 'Profissional',
    field: "profissionalSaude",
    getValue: (repasse) => {
      return repasse.profissionalSaude?.nome
    },
  },
  {
    Header: 'Valor',
    getValue: (repasse) => {

      return applyCurrencyMask(repasse.valorRepasseAgendamento)
    },
  }
];

const primeiroDiaDoMes = moment().startOf("month");
const ultimoDiaDoMes = moment().endOf("month");

const filtersDefault = {
  dataInicial: primeiroDiaDoMes.format("YYYY-MM-DD"),
  dataFinal: ultimoDiaDoMes.format("YYYY-MM-DD"),
  profissionalSaude: null,
  situacoes: [
    "AGENDADO",
    "CONFIRMADO",
    "AGUARDANDO",
    "ATENDENDO",
    "CANCELADO",
    "EXCLUIDO",
    "BLOQUEADO",
    "FALTOU",
  ],
  tipo: null,
};

const RelatorioRepasse = observer((props) => {
  const { classes, configuracaoImpressaoStore } = props;

  const [filters, setFilters] = useState(filtersDefault);
  const [pageNumber, setPageNumber] = useState(0);
  const [agendamentosRepasses, setAgendamentosRepasses] = useState([]);
  const [repassesRelatorio, setRepassesRelatorio] = useState([]);
  const [lastScroll, setLastScroll] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [valorTotal, setValorTotal] = useState(0);
  const [situacoesList, setSituacoesList] = useState(situacoes.map(item => {
    return item.value === "ATENDIDO" ? { ...item, checked: true } : { ...item, checked: false }
  }));
  const [ordenarTabela, setOrdenarTabela] = useState({
    sortField: "data",
    sortDir: "ASC",
  });
  const { notification } = configuracaoImpressaoStore;

  const usuarioLogado = JSON.parse(localStorage[`_immortal|usuarioLogado`] || null)
  const canViewOutros = usuarioLogado.authorities.some(item => item.authority === 'ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS');  
  
  useEffect(() => {
    loadAgendamentosRepasses(true);
  }, [filters, ordenarTabela])

  useEffect(() => {
    search === "" && loadAgendamentosRepasses(true)
  }, [search])

  const onClickPesquisar = () => {
    loadAgendamentosRepasses(true);
  };

  const getValorTotalRepasse = async() => {
    const { situacoes, dataInicial, dataFinal, profissionalSaude, tipo } = filters;
    const profissional = canViewOutros ? profissionalSaude : await getProfissionalSaudeLogado();

    const variables = {
      situacoes,
      dataInicial,
      dataFinal,
      ...(profissional?.id && {
        profissionalSaudeId: profissional.id
      }),
      tipoId: tipo,
      search,
    };

    const response = await relatorioAgendamentosRepasseValorTotal(variables);
    setValorTotal(response)
  };

  const loadAgendamentosRepasses = async (clear = false) => {
    try {
      setLoading(true);
      const { situacoes, dataInicial, dataFinal, profissionalSaude, tipo } = filters;

      let page = pageNumber;
      let agendamentos = agendamentosRepasses;
      
      if (clear) {
        page = 0;
        agendamentos = [];
      };
      
      const profissional = canViewOutros ? profissionalSaude : await getProfissionalSaudeLogado();

      const variables = {
        search: {
          situacoes,
          dataInicial,
          dataFinal,
          ...(profissional?.id && {
            profissionalSaudeId: profissional.id
          }),
          tipoId: tipo,
          search,
        },
        pageableDTO: {
          pageNumber: page,
          pageSize: 30,
          sortDir: ordenarTabela.sortDir,
          sortField: ordenarTabela.sortField,
        }
      }

      const response = await relatorioAgendamentosRepasse(variables);
      const { content, last, totalElements } = response;

      setAgendamentosRepasses([...agendamentos, ...content]);
      setLastScroll(last);
      setTotalElements(totalElements);
      setPageNumber(page + 1);
      getValorTotalRepasse();

    } catch (e) {
      configuracaoImpressaoStore.openNotification("Erro ao carregar a lista de repasses", "error");
    } finally {
      setLoading(false);
    }

  };

  const handleClickClearPesquisar = () => {
    setSearch("");
  };

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  const onKeypressSearch = (e) => {
    if (e.key === 'Enter' && search.length >= 3) {
      loadAgendamentosRepasses(true);
    }
  };

  const changeCheckbox = (e, index) => {
    let situacoes = situacoesList;
    let actualState = situacoes[index].checked;
    situacoes[index].checked = !actualState;
    setSituacoesList(situacoes);
    const statusChecked = situacoes
      .filter((value) => !value.checked)
      .map(({ value }) => value);

    setFilters({
      ...filters,
      situacoes: statusChecked
    })
    loadAgendamentosRepasses(true);
  };

  const quantidadeChecked = () => {
    const situacoesChecked = situacoesList.filter((item) => item.checked);
    const quantidadeChecked = situacoesChecked.length;

    const quantidade = {
      [situacoes.length]: "Todos",
      0: "Selecione",
    };

    return quantidade[quantidadeChecked] || `${quantidadeChecked} selecionados`;
  };

  const printRelatorio = async() => {
    const { situacoes, dataInicial, dataFinal, profissionalSaude, tipo } = filters;
    await configuracaoImpressaoStore.getConfig('RELATORIO');
    const response = await relatorioAgendamentosRepasse({
      search: {
        situacoes,
        dataInicial,
        dataFinal,
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude.id
        }),
        tipoId: tipo,
        search,
      },
      pageableDTO: {
        pageNumber: 0,
        pageSize: totalElements,
        sortDir: ordenarTabela.sortDir,
        sortField: ordenarTabela.sortField,
      }
    });

    setRepassesRelatorio(response.content);
    setPrinting(true);
  };

  const relatorioCsv = async() => {
    try {
      const { dataFinal, dataInicial, profissionalSaude, situacoes, tipo } = filters;
      const searchFilter = {
        situacoes,
        dataInicial,
        dataFinal,
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude.id
        }),
        tipoId: tipo,
        search,
      };
  
      const response = await relatorioAgendamentosRepasseCsv({search: searchFilter});
      return response;

    } catch {
      configuracaoImpressaoStore.openNotification("Erro ao tentar baixar o relatório", "error");
    }
  }

  const getCsv = async () => {
    const base64 = await relatorioCsv();
    if (base64 !== "error") {
      const blob = base64Convert(base64);
      const url = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "relatorioAgendamentosRepasse.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleProfissionalLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
    });
  };

  const handleLoadMoreOptions = async ({ search, data }) => {
    let searchDTO = {};

    if (data.searchDTO) {
      searchDTO = {
        ...data.searchDTO,
      };
    }
    const response = await findAllProfissionalSaude({
      pageNumber: data.page,
      search,
      ...searchDTO,
    });

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const handleProfissionalChange = (e) => {
    setFilters({
      ...filters,
      profissionalSaude: e
    });
  };

  const handleClickOrdenar = async (value) => {
    const sortDir =
      ordenarTabela.sortField !== value
        ? "ASC"
        : ordenarTabela.sortDir === "ASC"
          ? "DESC"
          : "ASC";

    setOrdenarTabela({
      sortDir: sortDir,
      sortField: value,
    });
  };

  const handleDateChange = (e, field) => {
    const dataFormatada = e && moment(e).format("YYYY-MM-DD");
    setFilters({
      ...filters,
      [field]: dataFormatada
    });
  };

  return (
    <div className={classes.content}>
      <HeaderRelatorio
        search={onClickPesquisar}
        clearSearch={handleClickClearPesquisar}
        value={search}
        title='Repasse geral'
        totalTitle='agendamentos'
        onChange={handleSearchChange}
        onKeyPress={onKeypressSearch}
        totalAgendamentos={totalElements}
        hiddenButtons
        hiddenFilter
        infosHeader={[{
          title: 'Valor de repasse total',
          label: applyCurrencyMask(valorTotal)
        }]}
      />

      <div className={classes.filtros}>
        <PageTitle title="Relatórios - Repasse" />
        <Grid item xs={3} className={classes.spacing}>
          <span className={classes.tituloFiltros}> Data início: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataInicial}
            classes={{
              input: classes.inputData,
            }}
            onChange={(e) => handleDateChange(e, "dataInicial")}
          />
        </Grid>
        <Grid item xs={3} className={classes.spacing}>
          <span className={classes.tituloFiltros}> Data fim: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataFinal}
            classes={{
              input: classes.inputData,
            }}
            onChange={(e) => handleDateChange(e, "dataFinal")}
          />
        </Grid>
        {canViewOutros && <Grid item xs={3} className={classes.spacing}>
          <span className={classes.tituloFiltros}> Profissional: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            loadOptions={handleProfissionalLoadOptions}
            withPaginate
            value={filters.profissionalSaude}
            onChange={handleProfissionalChange}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Grid>}
        <Grid item xs={3} className={classes.spacing}>
          <span className={classes.tituloFiltros}> Situação: </span>
          <PopperCheck
            options={situacoesList}
            changeCheckbox={changeCheckbox}
            label={quantidadeChecked()}
          />
        </Grid>
      </div>

      <div className={classes.contentTableRepasse}>
        <Scroll
          loadMore={() => loadAgendamentosRepasses()}
          hasMore={!lastScroll}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainer}
        >
          {agendamentosRepasses.length === 0 && !loading &&
            <div className={classes.notFoundContainer}>
              <h3>Nenhum item encontrado</h3>
            </div>
          }
          {agendamentosRepasses.length > 0 &&
            <Table
              className={classes.table}
              dados={agendamentosRepasses}
              columns={columns}
              clickable={false}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
            />
          }
          {
            loading && <Grid
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: "4px" }}
            >
              <CircularProgress size={30} />
            </Grid>
          }
        </Scroll>
        <div className={classes.buttonsDownloadPrint}>
          <Button
            id="exportCsv"
            onClick={getCsv}
            shape='circle'
            bgColor='#707C97'
            >
            <ArrowDownloadIcon />
          </Button>
          <Button
            onClick={printRelatorio}
            shape='circle'
            bgColor='#F9BE73'
          >
            <PrintIcon />
          </Button>
        </div>
      </div>
      { printing && 
        <ImpressaoHtml
          isPrintMustache={printing}
          handlePrintMustache={() => setPrinting(false)}
          htmlStringComponent={ <RelatorioRepassePdfDocument
            filter={filters}
            search={search}
            repasses={repassesRelatorio}
          />}
        />}
      <Notification
        close={() => configuracaoImpressaoStore.closeNotification()}
        reset={() => configuracaoImpressaoStore.closeNotification()}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </div>
  );
});

const RelatorioRepasseWithStyles = withStyles(styles)(RelatorioRepasse)
export default inject("configuracaoImpressaoStore")(RelatorioRepasseWithStyles)