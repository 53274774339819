import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Fab } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import ButtonLong from '../../../../../../components/Button/ButtonLong'
import NumberLoteGuia from '../NumberLoteGuia'
import PencilIcon from '../../../../../../components/Icon/Pencil'
import ArrowDownloadIcon from '../../../../../../components/Icon/ArrowDownload'

const Header = ({
  handleClose,
  isGuiaServicoSelected,
  handleDownloadXML,
  numberOfLote,
  handleEdit,
  classes,
}) => {
  const [isEditingMode, setIsEditingMode] = useState(false)

  const onClickEdit = () => {
    setIsEditingMode(true)
    handleEdit()
  }

  return (
    <DialogTitle classes={{ root: classes.dialogHeader }} disableTypography>
      <div className={classes.dialogTitle}>
        <h1>Lote de Guias</h1>
        {!isGuiaServicoSelected || isEditingMode ? (
          <div className={classes.wrapperActionsTitle}>
            <NumberLoteGuia numberOfLote={numberOfLote} />
            <Fab className={classes.buttonActionClose} onClick={handleClose}>
              <CloseIcon />
            </Fab>
          </div>
        ) : (
          <div className={classes.wrapperActionsTitle}>
            <div className={classes.buttonXml}>
              <ButtonLong colorCustom='yellow' onClick={handleDownloadXML}>
                <ArrowDownloadIcon color='white' className={classes.iconXml} />
                Baixar XML
              </ButtonLong>
            </div>
            <Fab className={classes.buttonActionEdit} onClick={onClickEdit}>
              <PencilIcon color='white' />
            </Fab>
            <Fab className={classes.buttonActionClose} onClick={handleClose}>
              <CloseIcon />
            </Fab>
          </div>
        )}
      </div>
    </DialogTitle>
  )
}

const styles = {
  dialogHeader: {
    background: 'white',
    borderRadius: '15px 15px 0 0',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>h1': {
      color: '#26ACA9',
      fontSize: '1.5rem',
      marginTop: '0.375rem',
    },
  },
  wrapperActionsTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonActionClose: {
    backgroundColor: '#FB7676',
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
  },
  buttonActionEdit: {
    backgroundColor: '#707C97',
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
  },
  buttonXml: {
    display: 'flex',
    width: '160px',
    justifySelf: 'flex-end',
  },
  iconXml: {
    marginRight: 8,
  },
}

export default withStyles(styles)(Header)
