import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { InputForm } from '../../../../components/Input/InputDadosCadastraisPaciente'
import colors from '../../../../template/Colors'

const StepObservacao = props => {
  const { classes, handleChangeGuiaServico, guiaServico, setDisableButton } = props

  useEffect(() => {
    setDisableButton(false)
  }, [])

  return (
    <Grid xs={12} className={classes.contentObservacao}>
      <Typography component='label' className={classes.titleObservacao}>
        Adicione sua observação ou justificativa:
      </Typography>
      <InputForm
        id='observacao'
        placeholder='Digite uma observação'
        className={classes.inputObservacao}
        rowsMax={10}
        rows={10}
        multiline
        value={guiaServico?.observacao}
        onChange={e =>
          e.target.value.length <= 500 && handleChangeGuiaServico('observacao', e.target.value)
        }
        maxLength={500}
      />
    </Grid>
  )
}

const styles = () => ({
  contentObservacao: {
    padding: '24px 24px 0px 24px',
  },
  titleObservacao: {
    color: '#868686',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginBottom: '4px',
  },
  inputObservacao: {
    background: colors.commons.gray2,
    borderRadius: 10,
    width: 'calc(100% - 18px)',
    maxHeight: 'auto',
    boxSizing: 'borderBox',
    padding: '8px',
    fontSize: '0.875rem',
    fontWeight: '600',
    color: '#505050',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
})

export default withStyles(styles)(StepObservacao)
