import moment from "moment";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import ModalOrcamento from "../ModalOrcamento";
import string from "../../../../../../../utils/string";
import ProcedimentosOdontograma from "../ProcedimentosOdontograma";
import {
  defaultErrors,
  orcamentoDefault,
} from "../../../../../../../stores/Orcamento.store";
import { getProfissionalSaudeLogado } from "../../../../../../../services/UsuarioService";
import {
  findConfiguracaoUnidade,
  saveOrcamento,
} from "../../../../../../../services/OdontogramaService";
import Notification from "../../../../../../../components/Notification";
import ImpressaoHtml from "../../../../../../../components/Impressao/ImpressaoHtml";
import OrcamentoPdf from "../../../../../../../template/pdf/orcamentos/OrcamentoPdf";
import { verificaIsDifferent } from "../../../../../../../utils/verificaOrcamentosDiferentes";

const ModalOrcamentoOdontologico = observer(
  ({
    orcamentoStore,
    odontogramaStore,
    setOpenModalOrcamento,
    openModalOrcamento,
    sujeitoAtencaoSelected,
    loadList,
    orcamentoComparar,
    configuracaoImpressaoStore,
  }) => {
    const { dadosConvenio } = sujeitoAtencaoSelected || {};
    const { convenio: convenioSujeitoAtencao } = dadosConvenio || {};
    const { orcamento, handleFieldModal, notification, closeAlertMessage } = orcamentoStore;

    const [configUnidade, setConfigUnidade] = useState(null);
    const [isLoadingProcedimentos, setIsLoadingProcedimentos] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [openImpressaoOrcamento,setoOpenImpressaoOrcamento] =useState(false)
    const [orcamentoPrint,setOrcamentoPrint]=useState(null)


    const {
      nome,
      convenio,
      dataVencimento,
      desconto,
      id,
      observacao,
      orcamentoProcedimentos,
      profissionalSaude,
      status,
      tipoPagamento,
      totalParcelas,
      valor,
      valorEntrada,
    } = orcamento || {};
    const isDisabled = id && status !== "PENDENTE";

    useEffect(() => {
      !id && getDadosOrcamento();
      getConfigUnidade();
      orcamentoStore.errors = defaultErrors;
    }, []);

    const getDadosOrcamento = async () => {
      getDadosSemOrcamento();
      await getProcedimentosOdontograma();
    };

    const getConfigUnidade = async () => {
      const configUnidade = await findConfiguracaoUnidade();

      setConfigUnidade(configUnidade);
    };

    const contemErros = () => {
      const procedimentoChecked = orcamentoProcedimentos.some(
        (item) => item.checked
      );
      const errorDesconto = orcamentoStore.errorLimiteDesconto(
        configUnidade.limiteDescontoOrcamento
      );
      const errors = {
        nome: !nome,
        procedimentos: !procedimentoChecked,
        tipoPagamento: !tipoPagamento,
        valor: !valor,
        desconto: errorDesconto,
      };

      orcamentoStore.errors = errors;
      const possuiErros = Object.values(errors).some((item) => item);

      return possuiErros;
    };

    const handleSave = async (action = "") => {
      try {
        if (contemErros()) {
          const message = orcamentoStore.errors.desconto
            ? "Limite de desconto ultrapassado."
            : "Favor verificar os campos obrigatórios";

              orcamentoStore.openNotification(message, "error");
              throw new Error(orcamentoStore.errors[0])
         
        }

        setIsLoadingSave(true);
        const procedimentosChecked = orcamentoProcedimentos
          .filter((item) => item.checked)
          .map((item) => {
            const { dente, id, mucosaOral, valorUnitario } = item;
            return {
              procedimentoOdontologico: {
                id,
                ...(mucosaOral && { mucosaOral }),
                ...(dente && { dente }),
              },
              valorUnitario:
                typeof valorUnitario === "string"
                  ? string.currencyMaskToFloat(valorUnitario)
                  : valorUnitario,
              quantidade: 1,
            };
          });

        let descontoNumber = 0;

        if (desconto?.valorDesconto) {
          descontoNumber =
            desconto?.tipoDesconto?.value === "VALOR"
              ? string.currencyMaskToFloat(desconto.valorDesconto)
              : string.removeMask(String(desconto.valorDesconto), "%");
        }

        const variables = {
          ...(id && { id }),
          nome,
          observacao,
          status: action || status || "PENDENTE",
          orcamentoProcedimentos: procedimentosChecked,
          valor:
            typeof valor === "string"
              ? string.currencyMaskToFloat(valor)
              : valor,
          tipo: "ODONTOLOGICO",
          profissionalSaude: {
            id: profissionalSaude?.id,
          },
          ...(totalParcelas && { totalParcelas: totalParcelas?.value }),
          ...(valorEntrada && {
            valorEntrada:
              typeof valorEntrada === "string"
                ? string.currencyMaskToFloat(valorEntrada)
                : valorEntrada,
          }),
          ...(convenio?.value && {
            convenio: { id: convenio?.value },
          }),
          ...(dataVencimento && {
            dataVencimento: moment(dataVencimento).format("YYYY-MM-DD"),
          }),
          ...(descontoNumber && {
            desconto: {
              valorDesconto: descontoNumber,
              tipoDesconto: desconto?.tipoDesconto?.value,
            },
          }),
          ...(tipoPagamento?.value && {
            tipoPagamento: tipoPagamento?.value,
          }),
          sujeitoAtencao: {
            id: sujeitoAtencaoSelected.id,
          },
        };

        const response = await saveOrcamento(variables);
        await loadList();

        if (response.status === "PENDENTE") {
          orcamentoStore.orcamento = orcamentoDefault;
        } else if (response.status === "APROVADO") {
          orcamentoStore.orcamento = {
            ...orcamentoStore.orcamento,
            id: response?.id,
          };
          odontogramaStore.dadosModalConfirmacao.open = true
        }

        orcamentoStore.openNotification(
          "Orçamento salvo com sucesso!",
          "success"
        );
       
        return response?.id

      } catch (e) {
        orcamentoStore.openNotification("Erro ao salvar o orçamento", "error");
      } finally {
        setIsLoadingSave(false);
      }
    };

    const getDadosSemOrcamento = async () => {
      const { id, descricao } = convenioSujeitoAtencao || {};

      const profissionalLogado = await getProfissionalSaudeLogado();

      const profissionalSaude = {
        id: profissionalLogado.id,
        value: profissionalLogado.id,
        label: profissionalLogado.nome,
      };

      const convenio = convenioSujeitoAtencao
        ? {
            value: id,
            label: descricao,
          }
        : null;

      orcamentoStore.orcamento = {
        ...orcamento,
        profissionalSaude,
        convenio,
      };
    };

    const getProcedimentosOdontograma = async () => {
      try {
        setIsLoadingProcedimentos(true);
        await odontogramaStore.findAllProcedimentoOdontologicoSemOrcamento({
          sujeitoAtencaoId: sujeitoAtencaoSelected.id,
        });
      } catch {
        orcamentoStore.openNotification(
          "Erro ao carregar os procedimentos odontológicos",
          "error"
        );
      } finally {
        setIsLoadingProcedimentos(false);
      }
    };


    const getOrcamentoPrint = async(action) =>{

      if (!id) {
        const idNovoOrcamento = await handleSave(action);
        return await orcamentoStore.findOrcamentoById(idNovoOrcamento);
      } else {
        const orcamentoSelecionado = { ...orcamento };
        const orcamentoComparacao = orcamentoComparar;

          if (verificaIsDifferent(orcamentoSelecionado, orcamentoComparacao,isOdontologico)) {
          const idOrcamentoUpdate = await handleSave();
          return await orcamentoStore.findOrcamentoById(idOrcamentoUpdate);
        } else {
          return orcamento; 
        }
      }
    }
    
    const handlePrint = async (e, action) => {

      try {
        await configuracaoImpressaoStore.getConfig("ORCAMENTO");
        let orcamentoPrint = await getOrcamentoPrint(action)

        
        setOrcamentoPrint(orcamentoPrint)

        setoOpenImpressaoOrcamento(true);
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <>
        <ModalOrcamento
          open={openModalOrcamento}
          onClose={() => setOpenModalOrcamento(false)}
          title={id ? "Editar orçamento" : "Emitir orçamento"}
          handleSave={handleSave}
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
          isLoadingSave={isLoadingSave}
          setIsLoadingSave={setIsLoadingSave}
          handlePrint={handlePrint}
        >
          <ProcedimentosOdontograma
            isLoadingProcedimentos={isLoadingProcedimentos}
            isDisabled={isDisabled}
            orcamento={orcamento}
            handleFieldModal={handleFieldModal}
          />
        </ModalOrcamento>
        {openImpressaoOrcamento &&
          <ImpressaoHtml
            handlePrintMustache={() => setoOpenImpressaoOrcamento(false)}
            habilitaBotaoImpressao={()=> setOpenModalOrcamento(false)}
            isPrintMustache={openImpressaoOrcamento}
            htmlStringComponent={
              <OrcamentoPdf
                orcamento={orcamentoPrint}
              />}
          />}
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </>
    );
  }
);

export default inject(
  "odontogramaStore",
  "orcamentoStore",
  "configuracaoImpressaoStore"
)(ModalOrcamentoOdontologico);
