export const styles = ({
  chatPopupsContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    right: 0,
    maxWidth: '60%',
    zIndex: 2
  },
  popups: {
    display: 'flex',
    position: 'relative',
  }
});
