import Api from '../config/api'

export const findByUnidadeLogadaConfiguracaoUnidade = () =>
  Api.post('', {
    query: `
        query {
          findByUnidadeLogadaConfiguracaoUnidade{
              utilizaMensagemPersonalizada
              azulControleAtivado
              enviaConfirmacaoDoisDiasAntes
              enviaConfirmacaoDoisDiasAntesSala
              enviaConfirmacaoUmDiasAntes
              enviaConfirmacaoUmDiasAntesSala
              enviaConfirmacaoUmaHoraAntes
              enviaConfirmacaoUmaHoraAntesSala
              marcarFaltososAutomaticamente
              numeroHorasBloqueioAgendamento
              notificaValorZeroAzulControle
              notificaAzulControleAgendamentoSala
              numeroHorasCancelamentoAutomatico
              utilizaTelefoneInternacional
              enviaConfirmacaoAntecipaFinalDeSemana
              horasPermiteConfirmarAgendamento
              enviaMensagemConfirmacaoEmail
              utilizaEnderecoMensagemAgendamento
              confirmaPresencaAgendamento
              validaAgendamentoMesmoHorario
              utilizaControleSessao
              utilizaFotoUnidadeGuiaSadt
              permiteAdministradorVerProntuarios
              utilizaWaba
              utilizaConfiguracaoRepasse
              utilizaConvenioAgendamento
              assinaturaDigitalObrigatoria
              utilizaImpressaoCartao
              utilizaPlugzapi
              utilizaCidObrigatorio
              permiteAtualizarAgendamentoSituacaoFuturo
              utilizaFinanceiroAppHealth
              visualizarAgendamentoRede
              cadastroRapidoAgendamento
              utilizaContratoAzulControle
              mostrarHorariosBloqueados
              permiteDuplicidadeCPF
              limiteDescontoOrcamento
              faturamentoObrigatorio
              mostrarApenasProfissionaisAtivo
              utilizaEtiquetaIdentificacao
              filtroProcedimento
              preencherTipoConsulta
              camposSujeitoAtencao {
                campo
                status
                id
              }
              unidade{
                id
              }
            }
        }
    `,
  })

export const saveConfiguracaoUnidade = async configuracaoUnidade => {
  return await Api.post('', {
    query: `
      mutation($configuracaoUnidade:ConfiguracaoUnidadeInput){
        updateConfiguracaoUnidade(configuracaoUnidade: $configuracaoUnidade){
            unidade{
              id
            }
          }
        }
        `,
    variables: { ...configuracaoUnidade },
  })
}

export const findGroupByRedeId = async (idRede) => {
  const response = await Api.post('', {
    query: `
      query ($idRede: Long, $ativo: Boolean) {
        findGroupByRedeId(idRede: $idRede, ativo: $ativo) {
        id
        nome
        }
      } 
    `,
    variables: { idRede, ativo: true }
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.findGroupByRedeId
}
