import React from 'react';
import Status from './ListSecretarias/Status';
import {applyCurrencyMask} from "../../../../../utils/CurrencyMask";
import { formatDate, mask, masks } from '../../utils';
import { Button } from '../../../../../components/ui/Buttons';
import RepasseIcon from '../../../../../components/Icon/RepasseIcon';
import ConfiguracoesIcon from '../../../../../components/Icon/Configuracoes';

export const profissionalSelectedDefault = {
  id: "",
  nome: "",
  cpf: "",
  tipoRepasse: "",
  valorRepasse: "",
  tipoCalculoRepasse: "",
  tempoAtendimento: "",
  percentualRepasse: "",
}

export const tabsOptions = [
  {
    label: "Secretários",
    tipoProfissional: 2,
  },
  {
    label: "Médicos",
    tipoProfissional: 1,
  },
  {
    label: "Técnicos",
    tipoProfissional: 4,
  },
  {
    label: "Administrador",
    tipoProfissional: 3,
  },
  {
    label: "Auditoria",
  },
];

export const tiposRepasse = [
  {
    value: "FIXO",
    label: "Valor fixo",
  }, 
  {
    value: "POR_HORA",
    label: "Por hora",
  }, {
    value: "POR_ATENDIMENTO",
    label: "Por atendimento",
  },
];

export const tiposCalculoRepasse = [
  {
    value: "PERCENTIL",
    label: "Percentil",
  }, {
    value: "VALOR",
    label: "Valor",
  },
];
