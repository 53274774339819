const styles = theme => ({
    root: {
        padding: '0.7%',

        "& thead th": {
            top: 0,
            position: "sticky",
            background: '#F9F9F9',
            color: '#505050',
        },
    },

    tableRow: {
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        cursor:'pointer'
    },

    tableRowCursorDefault: {
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        cursor:'default'
    },

    tableCell: {
        padding: '0 0 4px',
        borderColor: theme.palette.commons.gray3,
        color: theme.palette.commons.fontColor,
        fontSize: '1rem'
    },

    tableCenter: {
        textAlign: 'center'
    },

    tabela: {
        width: '100%'
    },

    headerComIcon: {
        fontSize: 12, 
        alignSelf: 'center'
    },

    divHeaderComIcon: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
    },

    headerIcon: {
        cursor:'pointer'
    },

    botaoOrganizar: {
        color: '#505050',
        width: 20,
        height: 20,
        marginLeft: 10,
    },

    circularProgessArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '24px'
    },

    tableHead: {
        position: 'sticky', 
        top: '0', 
        zIndex: '10',
    }
});

export default styles;