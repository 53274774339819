import React from 'react';
import PinIcon from '../../../../components/Icon/PinIcon';

export const getContactMenuOptions = ({ contact, handleToggleFixarContato, onClose }) => [
  {
    label: contact?.fixado !== null ? 'Desfixar contato' : 'Fixar contato',
    icon: <PinIcon size={24} color="#505050" />,
    onClick: () => {
      handleToggleFixarContato(contact);
      onClose();
    }
  }
];

export const contactsListTabs = [
  {
    label: 'Ativos',
  },
  {
    label: 'Inativos',
  }
];
