import React from "react";
import { inject, observer } from "mobx-react";
import { BlobProvider } from "@react-pdf/renderer";

import { withStyles } from "@material-ui/core/styles";
import { 
    Fab, 
    Grid, 
    CircularProgress,
    Tooltip
} from "@material-ui/core";
import Dialog from "../../../components/Dialog/Dialog";
import {
    Close as CloseIcon,
    Print as PrintIcon,
    FileCopy as DuplicateIcon,
    Save as SaveIcon,
    Assignment as AssignmentIcon
} from "@material-ui/icons";

import localStorageService, { ACCESS_TOKEN_KEY } from "../../../services/storage";

import SADTPdfDocument from "../../../template/pdf/SADT/SADT";
import SADTGuiaSimples from "../../../template/pdf/SADTGuiaSimples/SADTGuiaSimples";
import printPage from "../../../utils/printPage";
import Notification from "../../../components/Notification"
import { isIOS } from "../../../utils/OS";
import { TypeModalEnum } from "../../../stores/SADT.store";
import {findUnidadeLogada} from "../../../services/UnidadeService";
import InformacoesImportantesModal from "../InformacoesImportantes/InformacoesImportantesModal";
import { checkUserRole } from "../../../utils/checkUserRole";
import PageTitle from "../../../components/PageTitle/PageTitle";
import styles from './SADTStyle';
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { tabs } from "../../../stores/Auditoria.store";
import InformacoesSADT from "./InformacoesSADT";
import Auditoria from "../../../components/Auditoria/Auditoria";
import ListagemLateral from "./ListagemLateral/ListagemLateral";
import { findAllGuiaServicoModeloAuditByGuiaServicoModeloId } from "../../../services/AuditoriaService";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import { buildUrlDownload, buildUrlFotoPerfil } from "../../../config/config";
import InputTokenDialog from '../../../components/Dialog/InputTokenDialog';
import TokenDialog from '../../../components/Dialog/TokenDialog';
import {possuiTokenValido} from '../../../services/DocumentoService';
import ArrowDownloadIcon from "../../../components/Icon/ArrowDownload";

const formErrorsDefault = {
    nome: false,
    indicacaoClinica: false,
}
@inject('SADTStore', 'configuracaoImpressaoStore', 'auditoriaStore', 'profissionalSaudeStore', 'unidadeStore', 'prontuarioStore')
@observer
class SADTModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unidadeLogada: null,
            showInformacoesImportantesModal: false,
            tabSelected: tabs.INFORMACOES,
            loadingButtonSave: false,
            isPrintMustache: false,
            formErrors: formErrorsDefault,
            urlLogo: "",
            openTokenDialog: false,
            tokenDialogLoading: false,
            openInputTokenDialog: false,
            token: '',
            tokenError: false,
            finishingSignedPrint: false,
        }
    }

    async componentDidMount() {
        const { SADTStore, sujeitoAtencao, auditoriaStore, prontuarioStore, profissionalSaudeStore } = this.props;
        const roleEntradaProntuarioRead = checkUserRole('ROLE_ENTRADA_PRONTUARIO_READ');
        const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
		SADTStore.accessToken = accessToken;

        this.setState({showInformacoesImportantesModal: roleEntradaProntuarioRead && sujeitoAtencao?.informacoesImportantes?.length > 0});

        SADTStore.sugestoesPagAtual = 0;
        SADTStore.sugestoes = [];

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o modelo de SP/SADT",
            auditoriaTela: "guiaServicoModelo",
        }

        SADTStore.findAllGuiaServicoTussViewQuantidadeUso({          
            pageableDTO: {
                pageSize: 10,
                pageNumber: SADTStore.sugestoesPagAtual,
                sortDir: "DESC",
                sortField: "quantidadeUso"
            }
        })

        if (SADTStore.entradaProntuario?.id) {
            try {
                SADTStore.readOnly = true;
                SADTStore.guiaServico = await SADTStore.findGuiaServicoByIdEntradaProntuario(SADTStore.entradaProntuario?.id);
                SADTStore.objView = {
                    ...SADTStore.objView,
                    observacao: SADTStore.guiaServico?.observacao,
                    guiaServicoTussViews: SADTStore.guiaServico?.guiaServicoTussViews,
                    indicacaoClinica: {
                        label: SADTStore.guiaServico?.cid10Subcategoria?.descricaoAbreviada,
                        value: SADTStore.guiaServico?.cid10Subcategoria?.id
                    },
                }
            } catch (error) {
                SADTStore.guiaServico = null;
            } finally {
                SADTStore.entradaProntuario = null;
            }
        }

        SADTStore.objView.sujeitoAtencao = sujeitoAtencao;
        this.getLogo();
        await profissionalSaudeStore.carregaProfissionalSaude();
        await prontuarioStore.checkUseDigitalSignature();
    }

    getLogo = async() => {
        const { sujeitoAtencao, unidadeStore } = this.props;
        const { convenio } = sujeitoAtencao?.dadosConvenio || {};
        const { convenioSistema } = convenio || {};
        const { convenioLogo } = convenioSistema || {};
        const token = await localStorageService.get(ACCESS_TOKEN_KEY);

        const urlLogo = convenioLogo?.urlDownload && buildUrlDownload(convenioLogo.urlDownload, token);
        const unidadeUtilizaFotoPerfilComoLogo = await unidadeStore.checkUnidadeUtilizaFotoPerfilComoLogo();
        const dadosUnidade = !urlLogo && unidadeUtilizaFotoPerfilComoLogo && await unidadeStore.getLogoUnidadeLogada();
        const urlLogoUnidade = dadosUnidade ? buildUrlFotoPerfil(dadosUnidade.fotoPerfil, token) : null;
        this.setState({ urlLogo: urlLogo || urlLogoUnidade });
    };

    getErrorMessage = form => {
        if (!form.nome || form.ativo === undefined || !form.indicacaoClinica){
            this.setState({
                formErrors: {
                    nome: !form.nome,
                    indicacaoClinica: !form.indicacaoClinica
                }
            });
            return 'Favor preencher todos os campos.'
        } else {
            this.setState({
                formErrors: formErrorsDefault
            });
        }

        if (form.guiaServicoModeloTussViews.length === 0) {
            return 'Adicione pelo menos um procedimento.'
        }
    }

    handlePrint = async (tipo) => {
        const { SADTStore, configuracaoImpressaoStore, profissionalSaudeStore, prontuarioStore } = this.props;
        const {utilizaAssinaturaDigital} = profissionalSaudeStore.profissionalSaude
        const {assinaturaDigitalObrigatoria} = prontuarioStore;
        if (isIOS) {
            this.newWindowRef = window.open('', 'print_sadt');
            
            if (this.newWindowRef) {
                const loadingContent = `<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
                <h1 style="font-family: -apple-system;">Aguarde...</h1>
                </div>`;
                this.newWindowRef.document.write(loadingContent);
            }
        }
        
        try {
            SADTStore.loadingButtonImpressao = true;
            
            let guiaServico = SADTStore.guiaServico;
            
            if(SADTStore.objView?.guiaServicoTussViews?.length === 0) {
                throw Error('Informe o guia de serviço Tuss');
            }

            if (!SADTStore.readOnly && tipo !== 'guiaSimples') {
                guiaServico = await SADTStore.createGuiaServico(SADTStore.objView);
                SADTStore.guiaServico = guiaServico;
            }

            if(!guiaServico && tipo !== 'guiaSimples') return;

            SADTStore.readOnly = true;
            if(tipo === 'guiaSimples') {
                await configuracaoImpressaoStore.getConfig('OUTROS');
                const unidadeLogada = await findUnidadeLogada();
                const openSignDialog = utilizaAssinaturaDigital || assinaturaDigitalObrigatoria;

                if (openSignDialog) {
                    this.setState({ unidadeLogada, openTokenDialog: true })
                } else {
                    this.handlePrintUnsigned();
                }
            } else {
                SADTStore.printing = true;
            }
            

        } catch(error) {
            SADTStore.openNotification('Informe o guia de serviço Tuss', 'error');
            SADTStore.loadingButtonImpressao = false;
        }
    };

    handlePrintUnsigned = async () => {
        const { SADTStore } = this.props;

        try {
            if(!SADTStore.guiaServico?.id) {
                const guia = {
                    ...SADTStore.objView,
                    sujeitoAtencao: this.props.sujeitoAtencao
                };
                
                const response = await SADTStore.createGuiaServico(guia);
                SADTStore.guiaServico = response;
            }
        } catch(error) {
            console.error(error);
        }

        this.setState({
            tokenDialogLoading: true,
            isPrintMustache: true,
        });
    };

    handleTokenChange = (e) => {
        this.setState({ token: e.target.value });
    }

    handleChooseSigned = async () => {
        this.setState({ openTokenDialog: false });
        try {
            const response = await possuiTokenValido();
            const { possuiTokenAssinaturaValido: validToken } = response.data.data;

            if (validToken) {
                await this.handleFinishSignedPrint(validToken);
            } else {
                this.setState({ openInputTokenDialog: true });
            }
        } catch(e) {
            console.error(e);
        }
    }

    handleFinishSignedPrint = async (validToken=false) => {
        const { SADTStore } = this.props;
        const { token } = this.state;

        this.setState({ finishingSignedPrint: true });

        try {
            const guiaServico = await SADTStore.printSigned(token, validToken);
            const urlDownload = guiaServico.entradaProntuario.urlDownload;
            const completeURL = buildUrlDownload(urlDownload, SADTStore.accessToken);
            window.open(completeURL, '_blank');

            this.resetPrintStates();
        } catch (e) {
            console.error(e.message);
            this.resetPrintStates(true);
        }
    }

    handleCloseTokenDialog = () => {
        this.resetPrintStates();
    }

    renderPrintPdf = () => 
    (
        <div>
            <BlobProvider
                document={
                    <SADTPdfDocument
                        guiaServico={this.props.SADTStore.guiaServico}
                        urlLogo={this.state.urlLogo}
                    />
                }
            >
                {({ url, loading }) => {
                    if (!loading) {
                        if (isIOS && this.newWindowRef) {
                            this.newWindowRef.location = url;
                            const timeoutId = setTimeout(() => {
                                this.newWindowRef.print();
                                clearTimeout(timeoutId);
                            }, 1000);
                        } else {
                            printPage(url);
                        }
                        this.props.SADTStore.loadingButtonImpressao = false;
                        this.props.SADTStore.printing = false;
                        this.props.handleClose();
                    }
                    return null;
                }}
            </BlobProvider>
        </div>
    );

    isValidCreate = (modelo) => {
        return !!modelo.nome && 
        modelo.ativo !== undefined && 
        modelo.guiaServicoModeloTussViews.length > 0
    };

    isValidUpdate = (modelo) => {
        return !!modelo.nome && 
        modelo.ativo !== undefined && 
        modelo.guiaServicoModeloTussViews.length > 0 &&
        modelo.indicacaoClinica
    };

    handleSave = async () => {
        const { SADTStore } = this.props;
        try {
        this.setState({ loadingButtonSave: true });

        if (SADTStore.idModeloSelecionado) {
            let indicacaoClinica;
            if(SADTStore.objView?.indicacaoClinica?.value){
                indicacaoClinica = SADTStore.objView.indicacaoClinica
            }

            const novoModelo = {
                id: SADTStore.idModeloSelecionado,
                nome: SADTStore.nomeModelo,
                ativo: SADTStore.statusNovoModelo,
                observacao: SADTStore.objView.observacao,
                guiaServicoModeloTussViews: SADTStore.objView.guiaServicoTussViews,
                indicacaoClinica
            }
            const errors = this.getErrorMessage(novoModelo)

            if (this.isValidUpdate(novoModelo)) {
                await SADTStore.updateGuiaServicoModelo(novoModelo);
                SADTStore.resetModelo();
                SADTStore.changeFieldsStore({
                    openModal: false,
                    pageNumberModelo: 0,
                    modelosConfig: [],
                    idModeloSelecionado: null,
                    tipoListagemSelecionada: 'sugestoes'
                });
                this.props.loadModelos && this.props.loadModelos();

            } else {
                SADTStore.openNotification(errors, 'error');
            }

        } else {
            const novoModelo = {
                nome: SADTStore.nomeModelo,
                observacao: SADTStore.objView.observacao,
                ativo: SADTStore.statusNovoModelo,
                guiaServicoModeloTussViews: SADTStore.objView.guiaServicoTussViews,
                indicacaoClinica: SADTStore.objView.indicacaoClinica?.value
            }

            const errors = this.getErrorMessage(novoModelo);
            if (this.isValidCreate(novoModelo)) {
                await SADTStore.createGuiaServicoModelo(novoModelo);
                SADTStore.resetModelo();
                if (SADTStore.isProntuario){
                    SADTStore.changeFieldsStore({
                        typeModal: TypeModalEnum.SADT_MODAL,
                        observacaoTemporaria: "",
                        guiaServicoTussViewsTemporaria: [],
                        indicacaoClinicaTemporaria: null
                    });
                    
                    SADTStore.changeFieldsObjViewStore({
                        observacao: SADTStore.observacaoTemporaria || "",
                        guiaServicoTussViews: SADTStore.guiaServicoTussViewsTemporaria || [],
                        indicacaoClinica: SADTStore.indicacaoClinicaTemporaria || null
                    })
                } else {
                    SADTStore.openModal = false;                   
                }
                
                SADTStore.pageNumberModelo = 0;
                
                if (!!this.props.loadModelos){
                    SADTStore.modelosConfig = []
                    this.props.loadModelos && this.props.loadModelos();
                } 

            } else {
                SADTStore.openNotification(errors, 'error');
            }
        } 
        } catch(errors) {
            SADTStore.openNotification("Não foi possível salvar o modelo SP/SADT", 'error')
        } finally {
            this.setState({ loadingButtonSave: false });
        };
        
    }

    getTitleModal = () => {
        const { typeModal } = this.props.SADTStore;
        if (typeModal === TypeModalEnum.SADT_MODAL) {
            return 'SP/SADT';
        }
        if (typeModal === TypeModalEnum.EDIT_MODAL) {
            return 'Editar modelo';
        }

        return 'Novo modelo SP/SADT';   
    }

    handleCopySadt = () => {
        const { SADTStore } = this.props;
        SADTStore.readOnly = false;
    }

    titleTipo = () => {
        const { SADTStore, screen } = this.props;
        const { typeModal } = SADTStore;

        switch (typeModal) {
            case "modalEdit":
                return `${screen} - Editar modelo SP/SADT`;
            case "novoModal": 
                return `${screen} - Novo modelo SP/SADT`;
            default:
                const acao = SADTStore.readOnly ? "Visualizar" : "Novo";
                return `${screen} - ${acao} SP/SADT`;
        }
    }

    changeTabSelected = async(tabSelected) => {
        const { auditoriaStore, SADTStore } = this.props;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            pageNumber: 0,
            lastPage: false,
            auditorias: [],
        }

        if (tabSelected === 1) {
            auditoriaStore.load={
                query: findAllGuiaServicoModeloAuditByGuiaServicoModeloId, 
                variableName: "guiaServicoModeloId",
                variableId: SADTStore.idModeloSelecionado, 
            }
            await auditoriaStore.loadAuditItems();
        };
            
        SADTStore.disabledListaLateral = tabSelected === 1;
        this.setState({ tabSelected });
    };

    renderTab = () => {
        const { tabSelected } = this.state;

        return tabSelected === 0 ? <InformacoesSADT formErrors={this.state.formErrors}/> : <Auditoria/>
    };

    handlePrintMustache = () => {
        const { SADTStore } = this.props;
        this.resetPrintStates();
        SADTStore.loadingButtonImpressao = false;
    };

    resetPrintStates = (withError=false) => {
        const { SADTStore } = this.props;

        this.setState({
            isPrintMustache: false,
            tokenDialogLoading: false,
            openTokenDialog: false,
            openInputTokenDialog: false || withError,
            token: '',
            loadingButtonSave: false,
            finishingSignedPrint: false,
            tokenError: withError,
        }, () => {
            SADTStore.loadingButtonImpressao = false;
        });
    };

    render() {
        const { classes, SADTStore } = this.props;
        const { 
            loadingButtonImpressao, 
            printing, 
            readOnly, 
            typeModal, 
            guiaServico 
        } = SADTStore;
        const {
            showInformacoesImportantesModal,
            tabSelected,
            loadingButtonSave,
            openTokenDialog,
            tokenDialogLoading,
            openInputTokenDialog,
            token,
            tokenError,
            finishingSignedPrint
        } = this.state;

        const signedUrl = guiaServico?.assinado ? 
            buildUrlDownload(guiaServico.entradaProntuario.urlDownload, SADTStore.accessToken) : null

        return (
            <>
                <PageTitle title={this.titleTipo()} />
                <Dialog
                    classes={{
                        scrollPaper: classes.scrollPaper,
                        paper: showInformacoesImportantesModal ? classes.fullSizePaper : classes.paper
                    }}
                    maxWidth="md"
                    open={SADTStore.openModal}
                    fullWidth
                >
                {showInformacoesImportantesModal && 
                    <Grid>
                        <InformacoesImportantesModal 
                            openedInsideModal={SADTStore.openModal}
                        />
                    </Grid>
                }
                <div className={classes.conteudoEsquerdo}>
                        <div className={classes.dialogTitle}>
                            {this.getTitleModal()}
                            <div>
                                { typeModal === TypeModalEnum.SADT_MODAL ? 
                                    <>
                                        {readOnly && signedUrl && (
                                            <Tooltip title="Baixar guia assinada" placement="bottom">
                                                <Fab className={classes.buttonImpressao}
                                                    disabled={loadingButtonImpressao}
                                                >
                                                    {loadingButtonImpressao && <CircularProgress />}
                                                    {!loadingButtonImpressao && (
                                                        <a
                                                            href={signedUrl}
                                                            download={`guia_servico_${guiaServico?.id}.pdf`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.downloadIconLink}
                                                        >
                                                            <ArrowDownloadIcon color="white" />
                                                        </a>
                                                    )}
                                                </Fab>
                                            </Tooltip>
                                        )}
                                        {readOnly && (
                                            <Tooltip title={'Duplicar'} placement="bottom">
                                                <Fab
                                                    className={classes.buttonImpressao}
                                                    onClick={() => this.handleCopySadt()}
                                                    disabled={loadingButtonImpressao}
                                                >
                                                    {!loadingButtonImpressao &&  <DuplicateIcon color="white" />}
                                                    {loadingButtonImpressao && <CircularProgress />}
                                                </Fab>
                                            </Tooltip>
                                        )}

                                        <Tooltip title={'SP/SADT'} placement="bottom">
                                            <Fab
                                                className={classes.buttonImpressao}
                                                onClick={() => this.handlePrint('guiaCompleta')}
                                                disabled={loadingButtonImpressao}
                                            >
                                                {!loadingButtonImpressao && <PrintIcon />}
                                                {loadingButtonImpressao && <CircularProgress />}
                                            </Fab>
                                        </Tooltip>
                                        
                                        <Tooltip title={'SP/SADT como documento'} placement="bottom">
                                            <Fab
                                                className={classes.buttonImpressao}
                                                onClick={() => this.handlePrint('guiaSimples')}
                                                disabled={loadingButtonImpressao}
                                            >
                                                {!loadingButtonImpressao && <AssignmentIcon />}
                                                {loadingButtonImpressao && <CircularProgress />}
                                            </Fab>
                                        </Tooltip>
                                    </>
                                :
                                    <>
                                        <Fab
                                            className={classes.buttonImpressao}
                                            onClick={this.handleSave}
                                            disabled={loadingButtonSave}
                                        >
                                            { loadingButtonSave ? <CircularProgress /> : <SaveIcon /> }
                                        </Fab>
                                    </>
                                }
                                <Fab
                                    className={classes.buttonActionClose}
                                    onClick={this.props.handleClose}
                                >
                                    <CloseIcon />
                                </Fab>
                            </div>
                        </div>
                        { typeModal !== TypeModalEnum.SADT_MODAL && <MultiToggleButtons
                            options={["Informações", "Auditoria"]}
                            tabSelected={tabSelected}
                            changeTabSelected={this.changeTabSelected}
                            classes={{ buttonsContainer: classes.buttonsTab }}
                            disabled={!SADTStore.idModeloSelecionado}
                            posicaoDesabilitada={[1]}
                        />}
                        { this.renderTab() }
                    </div>
                    { !readOnly && <ListagemLateral /> }
                    {printing && (this.renderPrintPdf())}
                </Dialog>
                <Notification
                    close={e => SADTStore.closeNotification(e)}
                    reset={() => SADTStore.resetNotification()}
                    isOpen={SADTStore.notification.isOpen}
                    variant={SADTStore.notification.variant}
                    message={SADTStore.notification.message}
                />
                {
                    this.state.isPrintMustache && 
                    <ImpressaoHtml
                        isPrintMustache={this.state.isPrintMustache}
                        sujeitoAtencaoId={this.props.sujeitoAtencao.id}
                        handlePrintMustache={() => this.handlePrintMustache()}
                        htmlStringComponent={ 
                            <SADTGuiaSimples
                                guiaServico={this.props.SADTStore.guiaServico}
                            />
                        }
                    />
                }
                <TokenDialog
                    open={openTokenDialog}
                    handleClose={this.handleCloseTokenDialog}
                    onCancel={this.handlePrintUnsigned}
                    onOk={this.handleChooseSigned}
                    isLoading={tokenDialogLoading}
                />
                <InputTokenDialog
                    open={openInputTokenDialog}
                    onChange={this.handleTokenChange}
                    onOk={() => this.handleFinishSignedPrint(false)}
                    onCancel={this.handleCloseTokenDialog}
                    onClose={this.handleCloseTokenDialog}
                    loading={finishingSignedPrint}
                    error={tokenError}
                    inputValue={token}
                    canPrintSigned={this.props.profissionalSaudeStore.canPrintSigned}
                    history={this.props.history}
                />
            </>
        );
    }
}

export default withStyles(styles)(SADTModal);
