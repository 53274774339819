const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
        maxHeight: '97px',
        height: '100%',
        background: '#fff',
        boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    },
    contentTitleHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '40px',
    },
    titleHeader: {
        fontWeight: 700,
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        color: '#505050',
    },
    contentFilters: {
        display: 'flex',
    },
    buttonFiltrar: {
        marginTop: '15px',
    },
    search: {
        height: 28,
        width: '100%',
        "&> div": {
            border: '1px solid rgba(220, 220, 220, 0.2)',
        }
    },
    reactSelect: {
        width: "100%",
        "&>div>div": {
            border: '0.5px solid rgba(220, 220, 220, 0.2)',
            borderRadius: '8px',
            height: '32px',
            alignContent: 'center',
        },
        
    },
};

export default styles;