export const convertToObject = (config) => {
  return config.reduce((acc, item) => {
    const day = item.diaSemana.toUpperCase();
    acc[day] = { 
      procedimentos: item.procedimentos,
      diaSemana: item.diaSemana,
      permiteAgendamento: item.permiteAgendamento,
      horaInicio: item.horaInicio,
      solicitante: item.solicitante,
    };
    return acc;
  }, {});
};
