import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import React from "react";
import { Fab, Grid, List, CircularProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import ItemList from "../../../../components/Modal/ItemList";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import styles from '../SADTStyle';

const Modelos = observer((props) => {
  const { classes, SADTStore, onChangeTipoListagem } = props;

  const handleClickCriarNovoModelo = () => {
    SADTStore.resetModelo();
    SADTStore.changeFieldsStore({
      indicacaoClinicaTemporaria: SADTStore.objView.indicacaoClinica,
      guiaServicoTussViewsTemporaria: SADTStore.objView.guiaServicoTussViews,
      observacaoTemporaria: SADTStore.objView.observacao,
      isProntuario: true,
      typeModal: "novoModal"
    });

    onChangeTipoListagem("sugestoes");
  };

  const handleClickSelecionarModelo = (item) => {
    const { objView } = SADTStore;

    objView.observacao = `${objView.observacao} ${item.observacao}`;
    objView.guiaServicoTussViews = [
      ...objView.guiaServicoTussViews,
      ...item.guiaServicoModeloTussViews,
    ];
    objView.indicacaoClinica = {
      label: item.cid10Subcategoria.descricaoAbreviada,
      value: item.cid10Subcategoria.id,
    };

    SADTStore.registroQuantidadeUso(item.id);
  };

  const loadMoreModelos = async () => {
    if (SADTStore.modelosLoadingModal) {
      return;
    }

    SADTStore.modelosPagAtual =
      SADTStore.modelos.length > 0 ? SADTStore.modelosPagAtual + 1 : 0;
    await SADTStore.findAllGuiaServicoModelo({
      searchDTO: {
        ativo: true,
      },
      pageableDTO: {
        pageSize: 10,
        pageNumber: SADTStore.modelosPagAtual,
        sortDir: "DESC",
        sortField: "quantidadeUso",
      },
    });
  };

  return (
    <Grid className={classes.opcaoModelo}>
      <Fab
        className={classes.criarNovoModelo}
        onClick={handleClickCriarNovoModelo}
      >
        <AddIcon />
        <span className={classes.labelCriar}>Criar novo modelo </span>
      </Fab>
      <Scroll
        loadMore={loadMoreModelos}
        hasMore={!SADTStore.modelosLast}
        pageStart={0}
        className={classes.scrollContainerModelos}
      >
        <List>
          {SADTStore.modelos.map((item) => (
            <Grid
              className={classes.cardModelo}
              onClick={() => handleClickSelecionarModelo(item)}
            >
              <ItemList
                size={12}
                field={"Modelo:"}
                className={classes.itemList}
                value={item.nome}
              />
            </Grid>
          ))}
          {SADTStore.modelosLoadingModal && (
            <div className={classes.notFoundContainer}>
              <CircularProgress style={{ color: "#fff" }} />
            </div>
          )}
        </List>
      </Scroll>
    </Grid>
  );
});

const ModelosWithStyles = withStyles(styles)(Modelos);
export default inject("SADTStore")(ModelosWithStyles);
