import React from 'react';

import {withStyles} from "@material-ui/core/styles/index";
import Dialog from '../Dialog/Dialog';
import { DialogContent,DialogTitle } from '@material-ui/core';
import { primaryColor, fontColor } from "../../assets/jss/appHealth.style";
import { TextField } from "../TextField";


const styles = (theme) => ({
    modalTitle:{
        "& span":{
            fontSize: "24px",
            fontWeight: "bold",
            color: primaryColor
        }
    },

    infoInativacao:{
        color: fontColor,
        "& span":{
            marginLeft: "5px",
            fontWeight: "normal"
        }
    },
})

const ModalInfosInativacao = (props) =>{
    const {onClose, show, infosInativacao, classes} = props
    const {inativador, hora, dia, motivo} = infosInativacao
    return(
        <Dialog
            fullWidth
            maxWidth="sm"
            open={show}
            onClose={onClose}
            >
                <DialogTitle className={classes.modalTitle}>
                        <span>Informações sobre a inativação</span>
                </DialogTitle>
                <DialogContent>
                    <h3 className={classes.infoInativacao}
                    >Profissional Inativador: <span>{inativador}</span>
                    </h3>
                    <h3 className={classes.motivoInativacao}>Motivo:</h3>
                    <TextField
                    maxRows={5}
                    multiline
                    disabled
                    value={motivo}
                    />
                    <h3 className={classes.infoInativacao}
                    >Data/hora: <span>{dia} às {hora}</span>
                    </h3>
                </DialogContent>
            </Dialog>
    )
}

export default withStyles(styles)(ModalInfosInativacao)