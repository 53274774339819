import React from 'react'

import Notification from '../../../components/Notification'
import GracefulImage from '../../../components/Image/GracefulImage'
import Scroll from '../../../components/InfiniteScroll/Scroll'

import moment from 'moment'
import getCamposModificados from '../../../utils/getCamposModificados'
import { findAuditoria } from '../../../services/AuditoriaService'
import { buildUrlFotoPerfil } from '../../../config/config'

import { withStyles } from '@material-ui/core/styles'
import styles from '../../../assets/jss/pages/AuditoriaStyles'
import PageTitle from '../../../components/PageTitle/PageTitle'

const profileDefault = require('../../../assets/img/svg/profile-default.svg')

class Auditoria extends React.Component {
  state = {
    auditoriaSujeitoSelected: [],
    modificacoes: [],
    modificacoesPage: 0,
    notification: {
      isOpen: false,
      message: '',
    },
    isLoading: false,
    lastPage: false,
  }

  componentDidMount() {
    this.loadAuditItems()
  }

  openNotification = (message, variant = null) => {
    this.setState({
      notification: {
        isOpen: true,
        message: message,
        variant: variant,
      },
    })
  }

  closeNotification = () => {
    this.setState({
      notification: {
        isOpen: false,
      },
    })
  }

  resetNotification = () => {
    this.setState({
      notification: {
        isOpen: false,
        message: '',
        variant: '',
      },
    })
  }

  loadAuditItems = () => {
    const id = this.props.match.params.id
    const pageableDTO = {
      pageNumber: this.state.modificacoesPage,
      pageSize: 10,
    }
    const { modificacoesPage } = this.state
    const nextPage = modificacoesPage + 1

    this.setState({ isLoading: true })
    findAuditoria(id, pageableDTO)
      .then(({ data: response }) => {
        const content =
          response.data.findAllSujeitoAtencaoAuditBySujeitoAtencaoId.content
        const lastPage =
          response.data.findAllSujeitoAtencaoAuditBySujeitoAtencaoId.last
        this.setState({
          auditoriaSujeitoSelected: [
            ...this.state.auditoriaSujeitoSelected,
            ...content,
          ],
          lastPage: lastPage,
          modificacoesPage: nextPage,
        })
      })
      .catch(error => {
        this.openNotification(
          'Erro ao carregar auditorias do paciente',
          'error'
        )
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  renderizarHistoricoItems = () => {
    const { classes } = this.props
    const { auditoriaSujeitoSelected } = this.state

    if (auditoriaSujeitoSelected.length > 0) {
      return (
        <Scroll
          hasMore={!this.state.isLoading && !this.state.lastPage}
          pageStart={0}
          initialLoad={false}
          className={classes.auditoriaBody}
          loadMore={this.loadAuditItems}
        >
          {auditoriaSujeitoSelected.map((modificacao, index) => {
              const { revtype, sujeitoAtencaoAuditId } = modificacao
              const revisao = sujeitoAtencaoAuditId?.revisao
              const dataHoraModificacao = revisao?.dataHoraModificacao && moment(revisao.dataHoraModificacao).format('DD [de] MMMM [às] HH[h]mm')
              

              const camposModificados =  revtype === 'UPDATE' ? getCamposModificados(modificacao, "paciente"): []
              const listCamposModificados = camposModificados.map((campo, index) => {
                return <li key={index}>{campo}</li>
              })

              const tipoModificacao = revtype === 'CREATE' ? 'Criou' : 'Alterou'

              return (
                <div className={classes.auditoriaItem} key={index}>
                  <GracefulImage
                    src={
                      revisao?.usuario?.fotoPerfil ? buildUrlFotoPerfil(revisao.usuario.fotoPerfil): profileDefault
                    }
                    height='32'
                    className={classes.profissionalProfileImage}
                  />
                  <div className={classes.auditoriaText}>
                    <div className={classes.auditoriaItemInformacao}>
                      <p>
                        <strong>{revisao?.usuario?.nome}</strong>
                        {`${tipoModificacao} um paciente`}
                      </p>

                        <ul>{listCamposModificados}</ul>
                    </div>
                    <p className={classes.auditoriaItemData}>
                      {dataHoraModificacao}
                    </p>
                  </div>
                </div>
              )
            })}
        </Scroll>
      )
    } else {
      return (
        <div className={classes.auditoriaNotFoundItens}>
          Não há modificações neste paciente
        </div>
      )
    }
  }

  render() {
    return (
      <>
        <PageTitle title="Paciente - Auditoria" />
        {this.renderizarHistoricoItems()}

        <Notification
          close={() => this.closeNotification()}
          reset={() => this.resetNotification()}
          isOpen={this.state.notification.isOpen}
          variant={this.state.notification.variant}
          message={this.state.notification.message}
        />
      </>
    )
  }
}

export default withStyles(styles)(Auditoria)
