import React from "react";
import { inject } from "mobx-react";
import styled from "styled-components";
import { TextFieldSearch } from "../../../components/TextField";
import { Grid } from "@material-ui/core";
import SelectMedicamentosOption from "./SelectMedicamentos/Option";
import SelectAdscricaoOption from "./SelectAdscricao/Option";
import isTouchCapable from "../../../utils/isTouchCapable";
import InputForm from "../../../components/Input/InputForm";
import { SelectForm } from "../../../components/Input/InputDadosCadastraisPaciente";
import { withStyles } from "@material-ui/core/styles";
import Colors from "../../../template/Colors";
import { viasInternas, viasExternas } from "../../../utils/viaAdmContants";

@inject("receitaStore", "receituarioStore")
class DrugForm extends React.Component {
  state = {
    adscricaoList: [],
    inputAdscricao: "",
    inputRemedio: "",
  };

  componentDidUpdate(prevProps) {
    const { receitaStore } = this.props;
    const { inputAdscricao, inputRemedio } = receitaStore;

    if (inputAdscricao === "") {
      this.setState({ inputAdscricao: "" });
    }

    if (inputRemedio === "") {
      this.setState({ inputRemedio: "" });
    }

    if (this.props.idDrugSelecionado && prevProps.idDrugSelecionado !== this.props.idDrugSelecionado){
      this.loadAdscricao(this.props.idDrugSelecionado);
      this.props.alterarIdDrug();
    }
  } 

  loadAdscricao = medicamentoId => {
    this.props.receitaStore.findAllProfissionalSaudeAdscricao({ medicamentoId })
      .then(response => {
        this.setState({
          adscricaoList: response
        });
      });
  };

  handleChange = name => event => {
    this.props.onChange(name, event.target.value, this.props.recipe.id);
  };

  handleChangeAutocomplete = name => selectedOption => {
    let value = selectedOption;
    if (value && value.id) {
      this.loadAdscricao(value.id);
    }
    if (name === 'ascription') {
      this.props.receituarioStore.inputAdscricao = "";
    }
    if (!!value && typeof value === 'string') {
      value = this.newOption(value);
    }

    if (value === null || !!value.value) {
      this.props.onChange(name, value, this.props.recipe.id);
    }
  };

  handleBlurAutocomplete = name => event => {
    if (isTouchCapable()) {
      return;
    }

    let value = event.target.value;
    if (!!value && typeof value === 'string') {
      value = this.newOption(value);
      this.props.onChange(name, value, this.props.recipe.id);
    }
  };

  newOption = value => ({
    label: value,
    value,
    __isNew__: true
  });

  save = () => {
    const { onBlur, recipe } = this.props;

    if (recipe && !!recipe.drug) {
      onBlur();
    }

    this.clearInputsData();
  }

  handleInputAdscricao = (e) => {
    const { receituarioStore } = this.props;
    const { inputAdscricao } = this.state;

    receituarioStore.inputAdscricao = e ? e : inputAdscricao
    this.setState({
      inputAdscricao: e
    })
  };
  
  handleChangeInputRemedio = (e) => {
    const { receituarioStore } = this.props;
    const { inputRemedio } = receituarioStore;
    receituarioStore.inputRemedio = e ? e : inputRemedio;
    this.setState({
      inputRemedio: e
    })  
  }

  handleFocusSelectAdscricao = () => {
    const { receituarioStore } = this.props;
    this.setState({ inputAdscricao: receituarioStore.inputAdscricao })
  }

  handleFocusSelectRemedio = () => {
    const { receituarioStore } = this.props;
    this.setState({ inputRemedio: receituarioStore.inputRemedio })
  }

  clearInputsData = () => {
    const { receituarioStore } = this.props;
    receituarioStore.inputAdscricao = "";
    receituarioStore.inputRemedio = "";
    this.setState({ inputAdscricao: "", inputRemedio: "" });
  }

  render() {
    const { recipe, receitaStore, classes } = this.props;
    const { adscricaoList, inputAdscricao, inputRemedio } = this.state;
    const useType = [{ name: 'Interno', value: 'INTERNO' }, { name: 'Externo', value: 'EXTERNO' }];

    return (
      <Form>      
        <Row container spacing={8}>
          <Grid item xs={3} className={classes.inputLabel}>
            <label>Uso:</label>
            <SelectForm
              name={"useType"}
              value={recipe.useType || 'INTERNO'}
              onChange={this.handleChange("useType")}
              elements={useType}
            />
          </Grid>
          <Grid item xs={3} className={classes.inputLabel}>
            <label>Via Adm.:</label>
            <SelectForm
              name={"routeAdm"}
              value={recipe.routeAdm || ''}
              onChange={this.handleChange("routeAdm")}
              elements={recipe.useType === 'INTERNO' || !recipe.useType ? viasInternas : viasExternas}
            />
          </Grid>
          <Grid item xs={6} className={classes.inputLabel}>
            <label>Remédio:</label>
            <TextFieldSearch
              placeholder=""
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              value={recipe.drug}
              onChange={this.handleChangeAutocomplete('drug')}
              onBlur={this.handleBlurAutocomplete('drug')}
              loadOptions={receitaStore.loadMedicamentos}
              components={{ Option: SelectMedicamentosOption}}
              isCreatable
              allowCreateWhileLoading
              createOptionPosition="first"
              onInputChange={this.handleChangeInputRemedio}
              inputValue={inputRemedio}
              onFocus={this.handleFocusSelectRemedio}
            />
          </Grid>
        </Row>
        <Grid container alignItems={"flex-end"}>
          <Grid item xs={2} className={classes.inputLabel}>
            <label>Qtde.:</label>
            <InputForm
              alternativeInputClass={classes.inputStatus}
              value={recipe.amount}
              onChange={this.handleChange('amount')}
            />
          </Grid>
          <Grid item xs={8} className={classes.inputLabel}>
            <label>Adscrição:</label>
            <TextFieldSearch
              placeholder=""
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              value={recipe?.ascription?.label ? recipe.ascription : ""}
              onChange={this.handleChangeAutocomplete('ascription')}
              onBlur={this.handleBlurAutocomplete('ascription')}
              options={adscricaoList}
              components={{ Option: SelectAdscricaoOption }}
              isCreatable
              createOptionPosition="first"
              onInputChange={this.handleInputAdscricao}
              inputValue={inputAdscricao}
              onFocus={this.handleFocusSelectAdscricao}
            />
          </Grid>
          <Grid item xs={2}>
            <div
                className={classes.botaoAdicionarRemedio}
                onClick={this.save}
            >
                <p className={classes.labelBotaoAdicionarRemedio}> 
                  {recipe?.id ? "Confirmar" : "Adicionar"} 
                </p>
            </div>
          </Grid>
        </Grid>
      </Form>
    );
  }
}

const Form = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 17px;
  box-sizing: border-box;
`;

const Row = styled(Grid)`
  margin-bottom: 10px !important;
  display: 'flex';
  justify-content: 'space-between';
`;

export default withStyles(theme => ({
  actions: {
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 15,
  },

  botaoAdicionarRemedio:{
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    width: 110,
    color: 'white',
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: Colors.commons.yellow,
    "@media (max-width: 900px)":{
      width: 80
    }
  },

  labelBotaoAdicionarRemedio:{
    color: 'white',
    fontWeight: 500,
  },

  inputStatus: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    border: '0',
    minHeight: '6px',
    height: '18px',
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    padding: '9px 0px 5px 10px',
    borderRadius: '100px',
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    height: 32,
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '100px',
    fontFamily: "Poppins"
  },
  inputLabel: {
    display: 'inline-flex',
    flexDirection: 'column',
    paddingRight: 5,
    '&>label': {
      fontWeight: 400,
    fontSize: "12px",
      color: "#868686",
    },
    textOverflow: 'ellipsis',
    width:100,

  },
  classIcons: {
    fontSize: 24,
  },
  input: {
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 15,
  },
  label:{
    fontFamily: "Poppins !important",
    fontWeight: 'bold',
  },

}))(DrugForm)

