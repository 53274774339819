import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Radio from "@material-ui/core/Radio";
import { FormControlLabel, withStyles } from "@material-ui/core";
import styles from "../../styles";
import string from "../../../../utils/string";
import { InputForm } from "../../../../components/Modal/Input";
import { categoriaFinanceiraTipo } from "../../../../stores/Financeiro/CategoriaFinanceira.store";

const InformacoesCategoria = observer((props) => {
  const { classes, handleChange, categoriaFinanceiraStore, isDisabled } = props;
  const { objView } = categoriaFinanceiraStore;

  return (
    <div className={classes.contentTabInformacoes}>
      <div className={classes.contentField}>
        <div className={classes.titleField}>Nome</div>
        <InputForm
          value={objView.nome || ""}
          onChange={(e) => handleChange("nome", e)}
          className={classes.widthMaximo}
          classes={{
            input: classes.inputRetangular,
          }}
        />
      </div>
      <div>
        {categoriaFinanceiraTipo.map((item) => (
          <FormControlLabel
            key={item}
            value={item}
            name="isReceita"
            control={<Radio color="primary" />}
            label={string.capitalize(item)}
            checked={objView.tipo === item}
            onChange={() => handleChange("tipo", item)}
            disabled={isDisabled}
          />
        ))}
      </div>
    </div>
  );
});

const InformacoesCategoriaWithStyles = withStyles(styles)(InformacoesCategoria);
export default inject("categoriaFinanceiraStore")(
  InformacoesCategoriaWithStyles
);
