import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import {
  Remove as RemoveIcon,
} from "@material-ui/icons";
import Colors from "../../template/Colors";
import moment from "moment";


const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#219A97",
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    padding: "0px 21px",
    height: 40,
  },
  body: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    padding: "0px 21px",
    height: 48,
  },
}))(TableCell);

const ListaRecorrenciaAgendamento = (props) => {
  const { lista, onClickRemove, classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.rowHeader}>
            <CustomTableCell>Data</CustomTableCell>
            <CustomTableCell align="center">Início</CustomTableCell>
            <CustomTableCell align="center">Duração</CustomTableCell>
            <CustomTableCell align="center">Fim</CustomTableCell>
            <CustomTableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {lista.map((item, index) => (
            <TableRow key={index}>
              <CustomTableCell component="th" scope="row" align="left">
                {moment(item.data).format("DD/MM/YYYY")}
              </CustomTableCell>
              <CustomTableCell align="center">{item.inicio}</CustomTableCell>
              <CustomTableCell align="center">{item.duracao.label}</CustomTableCell>
              <CustomTableCell align="center">{item.fim}</CustomTableCell>
              <CustomTableCell align="center">
                <IconButton
                  className={classes.buttonRemove}
                  aria-label="expand row"
                  size="small"
                  onClick={() => onClickRemove(item)}
                >
                  <RemoveIcon />
                </IconButton>
              </CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const styles = {
  tableHeader: {
    maxHeight: "40px",
  },
  rowHeader: {
    height: "40px",
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  buttonRemove: {
    height: 24,
    width: 24,
    backgroundColor: Colors.commons.gray10,
    color: Colors.commons.white,
    "&:hover": {
      background: Colors.commons.waikawaGrey,
    },
  },
};

export default withStyles(styles)(ListaRecorrenciaAgendamento);
