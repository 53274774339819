import localStorageService, {USUARIO_LOGADO_KEY, PROFISSIONAL_SAUDE_LOGADO_KEY, UNIDADE_LOGADA_KEY} from '../services/storage';
import Api from "../config/api";
import getTipoProfissional from '../utils/getTipoProfissional';


export const getUsuarioLogado = () => {
    return localStorageService.get(USUARIO_LOGADO_KEY);
}

export const dadosGtm = async({pagePath}) => {
	const usuario = await getUsuarioLogado();
	const profissionalSaude = await getProfissionalSaudeLogado();
	const unidade = await getUnidadeLogado();
	
	const userIdUnidade = profissionalSaude?.id;
	const idUnidade = unidade?.id;
	const unidadeMunicipio = unidade?.endereco?.municipio?.nome;
	const unidadeEstado = unidade?.endereco?.estado?.uf;
	const ufConselho = profissionalSaude?.ufConselho;
	const tipoProfissional = getTipoProfissional(profissionalSaude?.tipo);
	const conselhoProfissionalSaude = profissionalSaude?.conselhoProfissionalSaude?.sigla;
	
	usuario && dataLayer.push({ 
		user_id: userIdUnidade,
		userId: usuario?.id,
		userIdUnidade,
		tipoProfissional,
		idUnidade,
		unidadeMunicipio,
		unidadeEstado,
		conselhoProfissionalSaude,
		ufConselho,
		pagePath,
	});
};

export const getProfissionalSaudeLogado = () => {
	return localStorageService.get(PROFISSIONAL_SAUDE_LOGADO_KEY);
}

export const getUnidadeLogado = () => {
	return localStorageService.get(UNIDADE_LOGADA_KEY);
}

export const permiteVerAgendaOutrosProfissionais = async () => {
	const usuarioLogado = await getUsuarioLogado();

	return usuarioLogado?.authorities.some(a => a.authority === 'ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS');
}

export const tipoProfissionalLogado = async() => {
	const profissionalSaudeLogado = await getProfissionalSaudeLogado();
	
	const tipoProfissional = profissionalSaudeLogado.tipo;

	switch (tipoProfissional) {
		case 1:
			return 'medico'
		case 2:
			return 'secretario' 
		case 3:
			return 'administrador' 
		case 4:
			return 'tecnico' 
		default:
			return null;
	}
}

export const acessoFinanceiro =  async () => {
	const usuarioLogado = await getUsuarioLogado();

	const isRoleTituloReadOutrosProfissionais =  usuarioLogado.authorities?.some(item => 
		item.authority === 'ROLE_TITULO_READ');

	const isRoleTituloParcelaReadOutrosProfissionais =  usuarioLogado.authorities?.some(item =>
		item.authority === 'ROLE_TITULO_PARCELA_READ');

	return isRoleTituloReadOutrosProfissionais && isRoleTituloParcelaReadOutrosProfissionais;
}

export const findProfissionalSaudeLogado = async() => {
	const profissionalSaudeLogado = await Api.post('',
	{
		query: `{
			findProfissionalSaudeLogado {
				id
				ativo
				cns
				cpf
				dataNascimento
				email
				nome
				numeroConselho
				possuiAgenda
				tipo
				perfilPublico
				ufConselho
				atendePresencial
				atendeCrianca
				utilizaAssinaturaDigital
				utilizaSecretariaVirtual
				utilizaTelemedicina
				permiteAvaliacaoVizi
				visualizaOutrosProntuarios
				utilizaAgendamentoRetorno
				retornoDias
				enviaEmailConfirmacaoAgendamento
				valorTelemedicina
				prontuarioExclusivo
				utilizaVIZI
				tuss24TerminologiaCBO{
					id
					codigoTermo
					termo
				}
				endereco{
					estado{
						id
						uf
					}
				}
				convenios {
					id
					descricao
					convenioSistema{
						id
					}
				}
				procedimentos{
					id
					nome
				}
				especialidades {
					especialidade
				}
				nomeSala
				prefixo {
					prefixo
				}
				conselhoProfissionalSaude {
					id
					sigla
					descricao
					codigoTermo
				}
				visualizarRegistroInativo
			},
		}`
	});

	return profissionalSaudeLogado?.data?.data?.findProfissionalSaudeLogado;
}

export const findUnidadeLogada = async() => {
	const response = await Api.post('',
	{
		query: `
			query {
				findUnidadeLogada {
					nome
					nomeFantasia
					endereco {
						bairro
						complemento
						cep
						tipoLogradouro
						municipio {
							nome
						}
						estado {
							uf
						}
						nomeLogradouro
						numero
					}
					profissionaisSaude{
					id
					nome
					}
					telefonePrincipal
				}
			}
		`
	});
	return response.data.data.findProfissionalSaudeLogado;
}
