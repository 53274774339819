import React, { useEffect, useState, useReducer } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import { Grid, Fab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { inject } from "mobx-react";
import moment from "moment";

import Notification from "../../components/Notification";
import Dialog from "../../components/Dialog/Dialog";
import Colors from "../../template/Colors";
import { InputForm } from "../../components/Modal/Input";
import { ReactSelect } from "../../components/Select/SelectSearch";
import ButtonConfirm from "../../components/Button/ButtonConfirm";
import ListaRecorrenciaAgendamento from "./ListaRecorrenciaAgendamento";
import dates from "../../utils/dates";
import WarningIcon from '../../components/Icon/Warning'
import ModalConfirmacao from "../../components/Modal/ModalConfirmacao/ModalConfirmacao";

const listaDiasSemana = [
  { nome: "Seg", selecionado: false, index: 1, value: "SEGUNDA_FEIRA" },
  { nome: "Ter", selecionado: false, index: 2, value: "TERCA_FEIRA" },
  { nome: "Qua", selecionado: false, index: 3, value: "QUARTA_FEIRA" },
  { nome: "Qui", selecionado: false, index: 4, value: "QUINTA_FEIRA" },
  { nome: "Sex", selecionado: false, index: 5, value: "SEXTA_FEIRA" },
  { nome: "Sab", selecionado: false, index: 6, value: "SABADO" },
  { nome: "Dom", selecionado: false, index: 0, value: "DOMINGO" },
];

const tempoDeRepeticao = [
  { nome: "Dia", value: "days", id: 1, tipo: "DIARIO" },
  { nome: "Semana", value: "weeks", id: 2, tipo: "SEMANAL" },
  { nome: "Mês", value: "months", id: 3, tipo: "MENSAL" },
  { nome: "Ano", value: "years", id: 4, tipo: "ANUAL" },
];

const RecorrenciaModal = (props) => {
  const { classes, show, onClose, atendimentoStore, dadosRecorrencia, controleSessaoStore } = props;
  const [repetirACada, setRepetirACada] = useState(1);
  const [repeticao, setRepeticao] = useState(tempoDeRepeticao[1]);
  const [quantidadeRepeticao, setQuantidadeRepeticao] = useState({nome: 1, id: 1, value: 1});
  const [lista, setLista] = useState([]);
  const [diasSemana, setDiasSemana] = useState(listaDiasSemana);
  const [diasSemanaSelecionados, setDiasSemanaSelecionados] = useState([]);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const [listaOpcaoQuantidade, setListaOpcaoQuantidade] = useState([]);
  const [disabledButtonConcluir, setDisabledButtonConcluir] = useState(true);
  const [agendamento, setAgendamento] = useState(null);
  const [loadingConcluir, setLoadingConcluir] = useState(false)
  const [openDialogVencimento, toggleDialogVencimento] = useReducer((state) => !state, false);

  useEffect(() => {
    listarOpcao();
    dadosRecorrencia && carregaDados();
  }, []);

  const carregaDados = async() => {
    const tipo = tempoDeRepeticao.find(item => item.tipo === dadosRecorrencia.tipo);
    const quantidade = {nome: dadosRecorrencia.quantidade, id: dadosRecorrencia.quantidade, value: dadosRecorrencia.quantidade};
    const dataConsultaSelecionada = moment(atendimentoStore.objView.data, 'YYYY-MM-DD');
    const agendamento = await atendimentoStore.dadosAgendamento();
  
    setAgendamento(agendamento);
    setRepetirACada(dadosRecorrencia.intervalo);
    setRepeticao(tipo);
    setQuantidadeRepeticao(quantidade);

    if (dadosRecorrencia.tipo === "SEMANAL"){
      const listaDiasSemanaSelecionada = listaDiasSemana.map(diaSemana => {
        const isDiaSelecionado = dadosRecorrencia.diaSemanas.some(item => item.diaSemana === diaSemana.value);
        return isDiaSelecionado ? 
          {
            ...diaSemana,
            selecionado: true
          }
          :
          diaSemana
      });
  
      const listaDiasSemanaSelecionadaTrue = listaDiasSemanaSelecionada.filter(item => item.selecionado);
      setDiasSemana(listaDiasSemanaSelecionada);
      setDiasSemanaSelecionados(listaDiasSemanaSelecionadaTrue);
    }
    const lista = dadosRecorrencia.agendamentosValidos.filter(item => {
      return moment(item.data).isAfter(dataConsultaSelecionada) || item.data === dataConsultaSelecionada.format('YYYY-MM-DD');
    }).map(item => {
      const data = dadosRecorrencia.diferencaData
        ? moment(item.data).add(dadosRecorrencia.diferencaData, 'days').format('YYYY-MM-DD') 
        : item.data;
      return ({
        data,
        inicio: agendamento.horaInicio,
        duracao: {
          label: atendimentoStore.objView.duracao.label,
          value: atendimentoStore.objView.duracao.value
        },
        fim: agendamento.horaFim,
        procedimentos: item.procedimentos
      })
    })
    setLista(lista);
    setDisabledButtonConcluir(false);
  }

  const listarOpcao = () => {
    const listaQuantidade = [];
    let maxValue = 0;
    let lengthArray = 0;
    const {objView} = atendimentoStore;
    const {procedimentos, tipo} = objView || {};

    if(!atendimentoStore?.recorrenciaControleSessao?.idPacote){
      
      const existProcedimentoByPacote = procedimentos?.some(item => item?.procedimento?.idPacote);    
      if(existProcedimentoByPacote) {
        const quantidadePacotesSessoes = procedimentos?.reduce((previousValue, item) => {
          const idPacote = item.procedimento.idPacote;
          const totalQuantity = item.procedimento.totalQuantity;
          if(idPacote) {
            if(!previousValue[idPacote]) {
              previousValue[idPacote] = {
                id: idPacote,
                quantidade: 1,
                totalQuantity
              }
            } else {
              previousValue[idPacote] = {
                id: idPacote,
                quantidade: previousValue[idPacote].quantidade+1,
                totalQuantity
              }
            };
          }
          return previousValue;
        }, {});
        const keysQuantidadePacotesSessoes = Object.keys(quantidadePacotesSessoes);

        const quantidadeMax = keysQuantidadePacotesSessoes.map(item => {
          const pacote = quantidadePacotesSessoes[item];

          const quantidadeMaxAgendamento = pacote.totalQuantity/pacote.quantidade;
          return Math.floor(quantidadeMaxAgendamento);
        })

        const quantidadeMinimaControleSessao = quantidadeMax && Math.min(...quantidadeMax);
        maxValue = quantidadeMinimaControleSessao;

      } else if(tipo?.idControleSessaoAgendamentoTipo) {
        const pacoteAgendamentoTipo = controleSessaoStore.pacoteSessao.find(item => {
          const agendamentoTipo = item.controleSessaoAgendamentoTipos.some(agendamentoTipo => {
            return agendamentoTipo.id === tipo?.idControleSessaoAgendamentoTipo
          });

          return agendamentoTipo
        });

        atendimentoStore.objView.tipo = {
          ...tipo,
          idPacote: pacoteAgendamentoTipo.id
        }
        const { totalQuantity, quantity } = controleSessaoStore.getQuantitySessoes(pacoteAgendamentoTipo.controleSessaoAgendamentoTipos);
        maxValue = totalQuantity - quantity;

      } else {
        lengthArray = 200
      }

    } else {
      maxValue = atendimentoStore?.recorrenciaControleSessao?.totalQuantity ;
    }
      lengthArray = maxValue || 200;

    for (let index = 1; index <=  lengthArray; index++) {
      listaQuantidade.push({nome: index, id: index, value: index})
    };
    setListaOpcaoQuantidade(listaQuantidade)
  }

  const handleChangeRepeticao = (e) => {
    setLista([]);
    setRepeticao(e);
  };

  const onClickDiaSemana = (dia) => {
    const diasSemanaAlteraSelecionado = diasSemana.map((item) =>
      item.nome === dia.nome
        ? {
            ...item,
            selecionado: !item.selecionado,
          }
        : item
    );

    const diasSemanaSelecionados = diasSemanaAlteraSelecionado.filter(
      (item) => item.selecionado
    );
    setDiasSemanaSelecionados(diasSemanaSelecionados);
    setDiasSemana(diasSemanaAlteraSelecionado);
  };

  const onClickRemove = (item) => {
    const listaNova = lista;
    const indexAgendamentoSelecionado = listaNova.indexOf(item);
    listaNova.splice(indexAgendamentoSelecionado, 1)
    
    setLista([...listaNova])    
  };

  const onChangeQuantidadeRepeticao = (event) => {
    setQuantidadeRepeticao(event);
  };

  const onChangeRepetirACada = (e) => {
    const { value } = e.target;
    setRepetirACada(value.replace(/\D/gim, ''));
  };

  const onClickConcluir = async() => {
    setLoadingConcluir(true)
    try {
    const {objView} = atendimentoStore;
    const {procedimentos} = objView || {};
    let dataVencimento = null;
    const agendamentoRecorrencia = {
      dataCriacao: moment().format('YYYY-MM-DDTHH:mm'),
      diaSemanas: diasSemanaSelecionados.map(diaSemana => {
        return { diaSemana: diaSemana.value }
      }),
      intervalo: repetirACada,
      quantidade: quantidadeRepeticao.value,
      tipo: repeticao.tipo
    }
    atendimentoStore.agendamentoRecorrencia = atendimentoStore?.agendamentoRecorrencia?.id ? 
      {
        ...agendamentoRecorrencia,
        id: atendimentoStore.agendamentoRecorrencia.id
      } :
      agendamentoRecorrencia;

      if(atendimentoStore?.recorrenciaControleSessao?.idPacote){
        dataVencimento = atendimentoStore?.recorrenciaControleSessao?.dataVencimento;
      }else {
        dataVencimento = procedimentos?.reduce((previousValue, item) => {
        const dataVencimento = item?.procedimento?.dataVencimento;
        if(dataVencimento) {
          if(!previousValue) {
            previousValue = dataVencimento
          } else {
            previousValue = moment(previousValue).isBefore(dataVencimento) ? previousValue : dataVencimento
          };
        }
        return previousValue;
      }, null);
    }

    const agendamentoAdaptado = {
        ...agendamento,
        ...( !!agendamento?.procedimentos && {procedimentos: agendamento.procedimentos.map(item => {
          item.nomeProcedimento && delete item.nomeProcedimento
          return item;
        })}),
      }
    
    await atendimentoStore.agendarRecorrencia(lista, agendamentoAdaptado, dataVencimento);

    setLoadingConcluir(false)
    atendimentoStore.open = false;
    atendimentoStore.updatingRecorrencia = false;
    atendimentoStore.onExit();
    onClose();
  } catch (error) {
    setLoadingConcluir(false)
    if(error?.message.includes('Data de vencimento')){
      toggleDialogVencimento()
    }else{
      showAlertMessage({
        isOpen: true,
        message: 'Erro ao salvar recorrência.'
      });
    }
  };
}

  const repetirSemana = () => {
    const { objView, isLastAgendamentoOfRecurrence } = atendimentoStore;
    const { data, horaInicio, duracao, horaFim } = objView;
    
    if (diasSemanaSelecionados.length === 0) {
      const notification = {
        isOpen: true,
        message: "Preencher os dias da semana.",
      };
      return showAlertMessage(notification);
    }
    
    const dataConsulta = !isLastAgendamentoOfRecurrence ?
      moment(data) :
      getNewRecurrenceStartDate(data, diasSemanaSelecionados, repeticao.tipo, repetirACada);
    const inicioConsulta = horaInicio;
    const duracaoConsulta = duracao;
    const fimConsulta = horaFim;
    
    let listaComRepeticao = [];
    
    while (listaComRepeticao.length <  quantidadeRepeticao.value) {
      let novaData = null;
      diasSemanaSelecionados.forEach((item) => {
        novaData = dataConsulta.day(item.index);

        let isDataConsultaDiaSemanaEspecificaMaiorQueDataConsulta = novaData.isAfter(
            atendimentoStore.objView.data,
            "day"
          ) || novaData.format("YYYY-MM-DD") === (atendimentoStore.idOpened ? atendimentoStore.objView.data : atendimentoStore.objView.data.format("YYYY-MM-DD"));

          (listaComRepeticao.length <  quantidadeRepeticao.value) && isDataConsultaDiaSemanaEspecificaMaiorQueDataConsulta &&
            listaComRepeticao.push(
              {
                data: novaData.format("YYYY-MM-DD"),
                inicio: inicioConsulta,
                duracao: duracaoConsulta,
                fim: fimConsulta,
              }
            );
      })
      novaData = dataConsulta.add(repetirACada, repeticao.value);
    }
    setLista(listaComRepeticao);
  };
  
  const getNewRecurrenceStartDate = (startDate, weekDays = [], type, frequency) => {
    const typesToAdd = {
      'DIARIO': 'day',
      'SEMANAL': 'week',
      'MENSAL': 'month',
      'ANUAL': 'year'
    }
    
    if (type === 'SEMANAL') {
      return dates.getNextDayBasedInWeekDays(startDate, weekDays, frequency);
    } else {
      return moment(startDate, 'YYYY-MM-DD').add(frequency, typesToAdd[type]);
    }
  }

  const repetirDiaOuMesOuAno = () => {
    const { objView, isLastAgendamentoOfRecurrence } = atendimentoStore;
    const { data, horaInicio, duracao, horaFim } = objView;
    
    const dataConsulta = !isLastAgendamentoOfRecurrence ?
      moment(data) :
      getNewRecurrenceStartDate(data, diasSemanaSelecionados, repeticao.tipo, repetirACada);
    const inicioConsulta = horaInicio;
    const duracaoConsulta = duracao;
    const fimConsulta = horaFim;

    let quantidade = 1;
    let listaComRepeticao = [];

    while (quantidade <= quantidadeRepeticao.value) {
      let novaData = null;
      listaComRepeticao.push({
        data: novaData
          ? novaData.format("YYYY-MM-DD")
          : dataConsulta.format("YYYY-MM-DD"),
        inicio: inicioConsulta,
        duracao: duracaoConsulta,
        fim: fimConsulta,
      });
      novaData = dataConsulta.add(repetirACada, repeticao.value);
      quantidade++;
    }
    setLista(listaComRepeticao);
  }

  const onClickVer = () => {
    if (!quantidadeRepeticao?.value || !repetirACada || !repeticao){
      const notification = {
        isOpen: true,
        message: 'Preencher todos os campos.'
      }
      showAlertMessage(notification);
      return
    }

    if (repeticao.nome === "Semana") {
      repetirSemana();
    } else {
      repetirDiaOuMesOuAno()
    }
    setDisabledButtonConcluir(false)
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };

  return (
    <Dialog
      open={show}
      classes={{
        paper: classes.paper,
      }}
      maxWidth="md"
      fullWidth
    >
      <Grid
        container
        className={classes.container}
        direction={"column"}
        wrap={"nowrap"}
      >
        <div className={classes.header}>
          <div className={classes.headerTituloClose}>
            <span className={classes.tituloHeader}>
              Recorrência de agendamento
            </span>
            <Fab
              onClick={onClose}
              classes={{
                sizeSmall: classes.btnClose,
                extendedFab: classes.extendedFab,
              }}
              size="small"
            >
              <CloseIcon />
            </Fab>
          </div>
          <div className={classes.contentHeader}>
            <div className={classes.rowHeader}>
              <span className={classes.labelInformacao}> Repetir a cada: </span>
              <InputForm
                name="repetirACada"
                className={classes.inputRepetir}
                classes={{
                  input: classes.inputFormRepetir,
                }}
                value={repetirACada || ""}
                onChange={onChangeRepetirACada}
              />
              <ReactSelect
                value={repeticao}
                className={classes.selectRepetir}
                onChange={handleChangeRepeticao}
                options={tempoDeRepeticao || []}
                isClearable
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.id}
              />
            </div>
            {repeticao?.nome === "Semana" && (
              <div className={classes.diasSemana}>
                {diasSemana.map((dia, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => onClickDiaSemana(dia)}
                      className={
                        dia.selecionado
                          ? classes.diaSelecionado
                          : classes.diaNaoSelecionado
                      }
                    >
                      {dia.nome}
                    </div>
                  );
                })}
              </div>
            )}
            <div className={classes.rowHeader}>
              <span className={classes.labelInformacao}>
                Quantidade de agendamentos:
              </span>
              <ReactSelect
                value={quantidadeRepeticao}
                name="quantidadeRepeticao"
                onChange={onChangeQuantidadeRepeticao}
                isClearable
                className={classes.inputQuantidade}
                options={listaOpcaoQuantidade || []}
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.id}
              />
            </div>
          </div>
          <div className={classes.contentButtonHeader}>
            <div className={classes.buttonVer} onClick={() => onClickVer()}>
              Ver
            </div>
          </div>
        </div>
        <div
          className={
            repeticao?.nome === "Semana"
              ? classes.contentAgendamentoSemana
              : classes.contentAgendamento
          }
        >
          <ListaRecorrenciaAgendamento
            lista={lista}
            onClickRemove={onClickRemove}
          />
        </div>
        <div className={classes.contentButtonInferior}>
          <span className={classes.buttonCancel} onClick={onClose}>
            Cancelar
          </span>

          <ButtonConfirm 
            onClick={onClickConcluir}
            disabled={disabledButtonConcluir || loadingConcluir}
          >
            {
              loadingConcluir ?
                <CircularProgress size={24} color={'#FFFFFF'}/>
                :
                'Concluir'
            }            
          </ButtonConfirm>
        </div>
      </Grid>
      <ModalConfirmacao
        open={openDialogVencimento}
        icon={<WarningIcon />}
        onClose={toggleDialogVencimento}
        color={'red'}
        title={''}
      >
        <p className={classes.infoVencimento}>Data de vencimento do controle de sessão não pode ser menor que a data de recorrência</p>
      </ModalConfirmacao>
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </Dialog>
  );
};

const style = {
  paper: {
    maxWidth: "500px",
    maxHeight: "575px",
    height: "100%",
  },
  container: {
    height: "100%",
  },
  header: {
    maxHeight: 258,
    padding: "26px 24px 15px",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: 2,
  },
  tituloHeader: {
    fontSize: "18px !important",
    color: Colors.commons.padrao,
    fontWeight: 700,
    marginBottom: 10,
  },
  headerTituloClose: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnClose: {
    width: "32px",
    height: "32px",
    minHeight: "26px",
    color: Colors.commons.padrao,
    boxShadow:
      "0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(38, 172, 169, 0.04), 0px 5px 25px rgba(38, 172, 169, 0.04)",
  },
  diasSemana: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0",
    cursor: "pointer",
  },
  rowHeader: {
    padding: "8px 0",
    display: "flex",
    alignItems: "center",
  },

  diaSelecionado: {
    borderRadius: 100,
    background: Colors.commons.padrao,
    color: Colors.commons.grayLight,
    width: 51,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  diaNaoSelecionado: {
    borderRadius: 100,
    background: "#f2f2f2",
    color: Colors.commons.gray9,
    width: 51,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  labelInformacao: {
    color: Colors.commons.gray9,
    fontSize: 14,
    fontWeight: 400,
  },
  inputRepetir: {
    maxWidth: 90,
    margin: "0px 16px",
  },
  inputFormRepetir: {
    textAlign: "center",
  },
  selectRepetir: {
    width: 224,
  },
  inputQuantidade: {
    width: '215px',
    marginLeft: '15px',
  },
  buttonVer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    cursor: "pointer",
    width: 120,
    height: 33,
    fontWeight: 700,
    color: Colors.commons.white,
    background: Colors.commons.gray10,
    "&:hover": {
      background: Colors.commons.waikawaGrey,
    },
  },
  contentButtonHeader: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2px",
  },
  contentAgendamento: {
    padding: "0 24px",
    background: Colors.commons.gray11,
    height: "100vh",
    maxHeight: "327px",
    overflow: 'auto',
  },
  contentAgendamentoSemana: {
    maxHeight: 280,
    padding: "0 24px",
    background: Colors.commons.gray11,
    height: "100vh",
    overflow: 'auto',

  },
  contentButtonInferior: {
    padding: "15px 24px 21px",
    background: Colors.commons.gray11,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonCancel: {
    color: Colors.commons.gray9,
    cursor: "pointer",
    height: 20,
    fontWeight: 700,
    marginRight: 36,
  },
  infoVencimento: {
    fontSize: '16px',
    fontWeight: '600',
    color: Colors.commons.gray9,
    textAlign: 'center',
  }
};

const RecorrenciaModalWithStyles = withStyles(style)(RecorrenciaModal);
export default inject("atendimentoStore", "controleSessaoStore")(RecorrenciaModalWithStyles);
