import React from "react";
import {
  withStyles,
  Grid,
} from "@material-ui/core";
import Profile from '../../../template/Header/Profile';
import ButtonLong from "../../../components/Button/ButtonLong";
import InputSearchRetangular from "../../../components/Input/InputSearchRetangular";
import { ReactSelect } from "../../../components/Select/SelectSearch";

const HeaderConfiguracoesButtonNovo = (props) => {
  const { 
    classes,
    handleClickNovo,
    title,
    status,
    showStatus,
    labelButton,
    hiddenButton,
    search
  } = props;

  return(
    <header className={classes.headerRoot} >
      <div className={classes.title}>{title}</div>
      <div className={classes.wrapperFilters}>
        <Grid className={classes.contentHeader}>
            <InputSearchRetangular
              placeholder={"Pesquisar"}
              onKeyPress={search?.onKeypress}
              className={classes.search}
              value={search?.value}
              onChange={(e) => search?.handleChange(e)}
              onClickButtonSearch={search?.onClickButtonSearch}
              handleClickClear={search?.handleClickClear}
            />
      
              <div className={classes.filterStatus}> 
              {showStatus && (
                <>
                  <span className={classes.label}>Status:</span>
                  <ReactSelect 
                    className={classes.select}
                    options={status.options}
                    value={status.value}
                    onChange={(item) => status.onChange(item)}
                  />
                </>
                )}
              </div>
          <div>
          {hiddenButton ? null : (
              <ButtonLong
                colorCustom="green"
                onClick={() => handleClickNovo()}
                className={classes.buttonNovo}
                noShadow
              >
              {labelButton}
              </ButtonLong>
          )}
          </div>
          <Profile />
        </Grid> 
      </div>
    </header>

  )
};

const style = {
  headerRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '#fff',
    position: 'relative',
    padding: '8px 16px',
  },
  wrapperFilters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
  },
  search: {
    height: '32px',
    maxWidth: '500px',
    flex: 1,
    '& > div': {
      border: 'none !important',
    },
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    gap: '8px',
    flex: 1,
  },
  buttonNovo: {
    padding: '0 16px',
  },
  title: {
    fontWeight: 700,
    fontSize: '18px',
  },
  info: {
    color: '#333333',
    fontSize: '14px',
  },
  filterStatus: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  select: {
    width: 'auto',
    maxWidth: '200px',
  },
}

export default withStyles(style)(HeaderConfiguracoesButtonNovo);
