import moment from "moment";
import { action, observable } from "mobx";
import { printImage } from "../utils/printUrl";
import { buildUrlSolicitacaoPaciente } from "../config/config";
import { downloadArquivo, downloadImage } from "../utils/downloadImagem";
import localStorageService, { ACCESS_TOKEN_KEY } from "../services/storage";
import { getQuantidadeAnamnese, getQuantidadeExames, listarAnamneseSolicitada, getQuantidadeTotalSolicitacoes } from "../services/SolicitacoesPacientesService";
import { findAllPacienteAnexo, findByIdAnamnese, findByIdPacienteAnexo } from "../services/SolicitacoesPacientesService";

const primeiroDiaMesAtual = moment()
  .startOf("month")
  .format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment()
  .endOf("month")
  .format("YYYY-MM-DD");

export const tiposSolicitacoes = [
  { name: "Anamnese", value: "ANAMNESE" },
  { name: "Exame", value: "EXAME" },
];

const filterSolicitacoesPacientesDefault = {
  search: "",
  tipo: tiposSolicitacoes[0],
  dataInicial: primeiroDiaMesAtual,
  dataFinal: ultimoDiaMesAtual,
  descartado: false,
  pageNumber: 0,
  sortDir: "DESC",
  sortField: "dataHoraLancamento",
  lastPage: false,
};

const defaultNotification = {
  isOpen: false,
  message: "",
};

export const propsModalConfirmacaoDefault = {
  open: false,
  icon: null,
  color: "",
  title: "",
  buttons: null,
};

export const propsModalSolicitacoesPacientesDefault = {
  open: false,
  dados: null,
  edit: false,
};

export default class SolicitacoesPacientesStore {
  @observable notification = defaultNotification;
  @observable isLoading = false;
  @observable solicitacoesPacientes = [];
  @observable filterSolicitacoesPacientes = filterSolicitacoesPacientesDefault;
  @observable propsModalConfirmacao = propsModalConfirmacaoDefault;
  @observable
  propsModalSolicitacoesPacientes = propsModalSolicitacoesPacientesDefault;
  @observable anamnesePerguntasAlteradas = {};
  @observable isPrintAnamnese = false;
  @observable quantidadeSolicitacoesAberto = 0;
  @observable quantidadeTotalSolicitacoes = 0;

  @action loadSolicitacaoPaciente = async (clear = false) => {
    try {
      this.isLoading = true;
      const { filterSolicitacoesPacientes } = this;
      const isExame = filterSolicitacoesPacientes.tipo.value === "EXAME";
      let nextPage = filterSolicitacoesPacientes.pageNumber + 1;
      let solicitacoes = this.solicitacoesPacientes;
      if (clear) {
        solicitacoes = [];
        nextPage = 1;
        this.filterSolicitacoesPacientes.pageNumber = 0;
      }

      const query = isExame        
          ? findAllPacienteAnexo
          : listarAnamneseSolicitada;
      const variablesQuery = {
        search: filterSolicitacoesPacientes.search || null,
        dataHoraInicial: moment(filterSolicitacoesPacientes.dataInicial).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        dataHoraFinal: moment(filterSolicitacoesPacientes.dataFinal).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        prontuarioGerado: false,
        descartado: filterSolicitacoesPacientes.descartado,
        pageableDTO: {
          pageSize: 30,
          pageNumber: filterSolicitacoesPacientes.pageNumber,
          sortDir: filterSolicitacoesPacientes.sortDir,
          sortField: filterSolicitacoesPacientes.sortField,
        },
      };

      const response = await query(variablesQuery);

      const dados = isExame ? response?.content : this.getDadosAnamneses(response?.content);

      this.filterSolicitacoesPacientes.lastPage = response.last;
      this.solicitacoesPacientes = [...solicitacoes, ...dados];
      this.filterSolicitacoesPacientes.pageNumber = nextPage;
    } catch (error) {
      const notification = {
        isOpen: true,
        message: `Erro ao carregar as solicitações de pacientes.`,
      };
      this.showAlertMessage(notification);
    } finally {
      this.isLoading = false;
    }
  };

  @action getDadosAnamneses = (dados) => {
    const anamneses = dados.map(item => {
      return (
        {
          ...item,
          arquivoTipo: item.tipoEntradaProntuario
        }
      )
    });
    
    return anamneses;
  };

  @action reset() {
    this.filterSolicitacoesPacientes = filterSolicitacoesPacientesDefault;
    this.solicitacoesPacientes = [];
  }

  @action closeAlertMessage() {
    this.notification = defaultNotification;
  }

  @action showAlertMessage = (notification) => {
    this.notification = notification;

    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  @action handleDownload = async (dados) => {
    const url = await this.getUrlImagem(dados.urlDownload);

    const tipo =
      dados.arquivoTipo === "IMAGEM" ? downloadImage : downloadArquivo;
    tipo(dados.amazonS3Objeto?.nome, url);
  };

  @action handleImprimir = async (dados) => {
    if (dados.arquivoTipo === "ANAMNESE") {
      this.isPrintAnamnese = true;
    } else {
      const urlImagem = await this.getUrlImagem(dados.urlDownload);
      printImage(urlImagem);
    };
  };

  getUrlImagem = async (urlDownload) => {
    const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
    const urlImagem = buildUrlSolicitacaoPaciente(urlDownload, accessToken);

    return urlImagem;
  };

  @action handleVisualizar = async (solicitacao) => {
    await this.getDadosSolicitacao(solicitacao, {isEdit: false});
  };

  @action getDadosSolicitacao = async(solicitacao, action) => {
    const anexo = solicitacao.arquivoTipo === "ANAMNESE" ? await this.getDadosAnamnese(solicitacao.id) : await findByIdPacienteAnexo(solicitacao.id);

    this.propsModalSolicitacoesPacientes= {
      open: true,
      dados: anexo,
      edit: action.isEdit,
    };
  };

  @action getDadosAnamnese = async(id) => {
    const anamnese = await findByIdAnamnese(id);

    return {
      ...anamnese,
      arquivoTipo: anamnese.tipoEntradaProntuario
    };
  };

  @action handleEditar = async(solicitacao) => {
    await this.getDadosSolicitacao(solicitacao, {isEdit: true});
  };

  @action getQuantidadeSolicitacoes = async() => {
    const dataHoraInicial = moment(this.filterSolicitacoesPacientes?.dataInicial).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    const dataHoraFinal = moment(this.filterSolicitacoesPacientes?.dataFinal).format(
      "YYYY-MM-DDTHH:mm:ss"
    );

    const quantidade = this.filterSolicitacoesPacientes.tipo?.value === "ANAMNESE" ? 
      await getQuantidadeAnamnese({ dataHoraFinal, dataHoraInicial }) :
      await getQuantidadeExames({ dataHoraFinal, dataHoraInicial });

    this.quantidadeSolicitacoesAberto = quantidade;
  };

  @action getQuantidadeSolicitacoesPaciente = async () => {
    const dataHoraInicial = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const dataHoraFinal = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const quantidadeTotalSolicitacoes = await getQuantidadeTotalSolicitacoes(dataHoraInicial, dataHoraFinal);
    this.quantidadeTotalSolicitacoes = quantidadeTotalSolicitacoes;
  }

  @action onClose = () => {
    this.open = false;
  };

}
