import Api from "../config/api";

export const findAllBanco = (search, page) => {
  return Api.post('', {
    query: `
      query($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
        findAllBanco(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
          content {
            id
            nome
            codigo
          }
          last
        }
      }
    `,
    variables: {
      pageableDTO: {
        pageNumber: page,
        pageSize: 30,
        sortDir: 'ASC',
        sortField: 'codigo'
      },
      searchDTO: {
        search,
        ativo: true
      }
    }
  });
}

export const findAllContaBancaria = async (variables) => {
  const response = await Api.post("", {
      query: `
        query ($searchDTO: SearchDTOInput) {
          findAllContaBancaria(searchDTO: $searchDTO) {
            last
            content {
              id
              nome
              ativo
              saldoInicial
              banco {
                id
                nome
              }
            }
          }
        }
      `,
      variables: variables
  });

  if(response?.data?.data) {
    return response.data.data.findAllContaBancaria;
  } else {
    throw(new Error(response.data.errors[0]))
  }
};