export const calcTotalTitulo = ({
  agendamentoTipo, 
  procedimentos, 
  convenio,
  isModalSala,
}) => {
  const agendamentoTipoValor = isModalSala ? 0 : (agendamentoTipo && convenio && getAgendamentoTipoValor(agendamentoTipo, convenio));
  const procedimentosTotalValor = procedimentos?.length > 0 && getProcedimentosValor(procedimentos, convenio);
  return agendamentoTipoValor + procedimentosTotalValor;
}

const getAgendamentoTipoValor = (agendamentoTipo, convenio) => {
  const { value: id } = agendamentoTipo;
  const convenioAgendamentoTipo = convenio.convenioAgendamentoTipo?.filter(convenioAgendamentoTipo => convenioAgendamentoTipo.agendamentoTipo.id === id)[0];
  return convenioAgendamentoTipo?.valor || 0;
}

const getProcedimentosValor = (procedimentos, convenio) => {
  const { convenioProcedimentos } = convenio || {};

  const procedimentosValueTotal = procedimentos.reduce((total, procedimento) => {
    const convenioProcedimento = convenioProcedimentos?.find(
      (convenioProcedimento) => convenioProcedimento?.procedimento?.id === procedimento?.procedimento?.id
    );
    const valor = convenioProcedimento?.valor || 0;
    return total + valor;
  }, 0);

  return procedimentosValueTotal;
};