import {
  Facebook,
  Instagram,
  Linkedin,
  Whatsapp,
  Youtube,
} from "../../assets/img/svg";

export const redesSocial = [
  {
    name: "Whatsapp",
    icon: Whatsapp,
    link: "https://web.whatsapp.com/send?phone=554430136077",
  },
  {
    name: "Instagram",
    icon: Instagram,
    link: "https://www.instagram.com/sistema.apphealth/",
  },
  {
    name: "Linkedin",
    icon: Linkedin,
    link: "https://www.linkedin.com/company/sistema-apphealth/mycompany/",
  },
  {
    name: "Facebook",
    icon: Facebook,
    link: "https://www.facebook.com/sistema.apphealth",
  },
  {
    name: "Youtube",
    icon: Youtube,
    link: "https://www.youtube.com/channel/UCK0sPW53v7sXzbqJkUGLCsw",
  },
];

export const termos = [
  {
    label: "Termos de uso",
    link: "https://www.apphealth.com.br/termos-de-uso",
    value: "termoUso",
  },
  {
    label: "Políticas de privacidade",
    link: "https://www.apphealth.com.br/politica-de-privacidade",
    value: "politicaPrivacidade",
  },
];
