export const getTypeOfSolicitacao = (type, extensao) => {
  switch (type) {
    case 'ANAMNESE':
      return 'Anamnese'
    case 'ARQUIVO':
    case 'IMAGEM':
      return `Documento - ${extensao.toUpperCase()}`
    default:
      return ''
  }
}
