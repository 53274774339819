import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const EditIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...normalizeProps(props)}
    >
      <path
        d="M10.7332 2.5606C11.4808 1.81311 12.6927 1.81314 13.4403 2.56066C14.1878 3.30823 14.1878 4.52029 13.4402 5.26783L12.7065 6.0015L9.99935 3.29439L10.7332 2.5606ZM9.29221 4.00147L3.33829 9.95484C3.15725 10.1359 3.02085 10.3566 2.93989 10.5994L2.02567 13.3421C1.96578 13.5218 2.01254 13.7198 2.14646 13.8538C2.28038 13.9877 2.47846 14.0344 2.65813 13.9746L5.40087 13.0603C5.64368 12.9794 5.86432 12.843 6.04531 12.662L11.9993 6.70857L9.29221 4.00147Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;
