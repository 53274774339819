import React from "react";
import ValorOculto from "../../../components/ValorOculto/ValorOculto";
import { applyCurrencyMask } from "../../../utils/CurrencyMask";
import { Switch } from "@material-ui/core";

export const tabs = {
  INFORMACOES: 0,
  AUDITORIA: 1,
};

const tableStatusStyle = {
  width: "50px",
  padding: "4px 16px",
};

export const columns = ({ handleClickStatus, handleGetValor }) => [
  {
    Header: "",
    style: tableStatusStyle,
    isClick: true,
    getValue: (row) => {
      const { id, ativo } = row;
      return (
        <Switch
          onChange={() => handleClickStatus({ id, ativo })}
          checked={ativo}
          color="primary"
        />
      );
    },
    props: { center: "left" },
  },
  {
    Header: "Nome",
    getValue: (row) => {
      return row.nome;
    },
    props: { center: "left" },
  },
  {
    Header: "Banco",
    getValue: (row) => {
      return row.banco && row.banco.nome;
    },
    props: { center: "true" },
  },
  {
    Header: "Saldo inicial",
    getValue: (row) => {
      if (typeof row.saldoInicial !== "number") {
        return "-";
      }
      return applyCurrencyMask(row.saldoInicial);
    },
    props: { center: "true" },
  },
  {
    Header: "Saldo atual",
    isClick: true,
    getValue: (row) => {
      const { saldoAtual, isOculta } = row;

      return (
        <ValorOculto
          valor={applyCurrencyMask(saldoAtual)}
          handleGetValor={() => handleGetValor(row)}
          isOculta={isOculta}
        />
      );
    },
    props: { center: "true" },
  },
];
