import styled from 'styled-components'
import {
  backgroundColorByKind,
  borderStyleByKind,
  paddingBySize,
  fontSizeBySize,
  getShape,
  borderHoverStyle,
} from './utils/stylesByProps'

export const Button = styled.button`
  cursor: pointer;
  border: ${props => borderStyleByKind(props.kind)};
  background-color: ${props => props.bgColor || backgroundColorByKind[props.kind].backgroundColor};
  color: ${props => props.color || backgroundColorByKind[props.kind].color};
  ${props => props.fullWidth && 'width: 100%;'};
  ${props => props.width && `width: ${props.width};`};
  ${props => props.height && `height: ${props.height};`};
  ${props => props.fontSize && `font-size: ${props.fontSize};`};
  ${props => getShape[props.shape]};
  font-weight: ${props => props.fontWeight || 600};
  padding: ${props => props.size && paddingBySize[props.size].padding};
  font-size: ${props => props.size && fontSizeBySize[props.size].fontSize};
  transition: all 0.2s ease-in-out;
  ${props => props.disabled && 'opacity: 0.5; cursor: not-allowed;'}
  ${props => props.shadow && 'box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);'}
  &:hover {
    background-color: ${props =>
      props.bgColor || backgroundColorByKind[props.kind].hover || backgroundColorByKind[props.kind].backgroundColor};
    color: ${props => props.color || backgroundColorByKind[props.kind].color};
    ${props => borderHoverStyle[props.kind] && `border: ${borderHoverStyle[props.kind]}`};
    opacity: 0.8;
  }
`

export const WrapperChildren = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

export const Link = styled.a`
  text-decoration: none;
`
