import React from "react";
import moment from "moment";
import string from "../../../utils/string";
import { formattedPhone } from "../../../components/Input/TelefoneFormatado";

const Pagina = (props) => {
  const { sujeitoAtencao, utilizaTelefoneInternacional } = props;

  return (
    <>
      <div style={styles.titulos}>
        <div style={styles.headeGroup}>Dados principais</div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Nome:</div>
          <div style={styles.text}>
            {sujeitoAtencao.nome || ""}
          </div>
        </div>

        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Nome Social:</div>
          <div style={styles.text}>
            {sujeitoAtencao.nomeSocial || ""}
          </div>
        </div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Sexo:</div>
          <div style={styles.text}>
            {string.capitalize(sujeitoAtencao.sexo?.descricao || "") || ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Status:</div>
          <div style={styles.text}>
            {sujeitoAtencao.ativo ? "Ativo" : "Inativo"}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Documento:</div>
          <div style={styles.text}>
            {sujeitoAtencao.documento || ""}
          </div>
        </div>

        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>RG:</div>
          <div style={styles.text}>
            {sujeitoAtencao.rg || ""}
          </div>
        </div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>
            Data nascimento:
          </div>
          <div style={styles.text}>
            {string.formatData(sujeitoAtencao.dataNascimento || "") || ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Estado civíl:</div>
          <div style={styles.text}>
            {string.capitalize(sujeitoAtencao.estadoCivil?.descricao || "") ||
              ""}
          </div>
        </div>
      </div>
      <div style={styles.headerNomeSujeito}>
        <div style={styles.headerNomeSujeitoLabel}>
          Profissional responsável:
        </div>
        <div style={styles.text}>
          {sujeitoAtencao.profissionalSaude?.nome || ""}
        </div>
      </div>

      <div style={styles.titulos}>
        <div style={styles.headeGroup}>Convênio</div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Descricao:</div>
          <div style={styles.text}>
            {sujeitoAtencao.dadosConvenio?.convenio?.descricao || ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>
            Número Carteira:
          </div>
          <div style={styles.text}>
            {sujeitoAtencao.dadosConvenio?.numeroCarteira || ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>
            Validade da Carteira:
          </div>
          <div style={styles.text}>
            {string.formatData(
              sujeitoAtencao.dadosConvenio?.validadeCarteira || ""
            ) || ""}
          </div>
        </div>
      </div>
      <div style={styles.titulos}>
        <div style={styles.headeGroup}>Endereço</div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>CEP:</div>
          <div style={styles.text}>
            {string.format("##.###-###", sujeitoAtencao.endereco?.cep || "") ||
              ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Logradouro:</div>
          <div style={styles.text}>
            {sujeitoAtencao.endereco?.nomeLogradouro || ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Número:</div>
          <div style={styles.text}>
            {sujeitoAtencao.endereco?.numero || ""}
          </div>
        </div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Bairro:</div>
          <div style={styles.text}>
            {sujeitoAtencao.endereco?.bairro || ""}
          </div>
        </div>

        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Complemento:</div>
          <div style={styles.text}>
            {sujeitoAtencao.endereco?.complemento || ""}
          </div>
        </div>
      </div>
      <div style={styles.headerData}>
        {sujeitoAtencao?.endereco?.municipio &&
            <div style={styles.headerNomeSujeito}>
              <div style={styles.headerNomeSujeitoLabel}>Múnicipio:</div>
              <div style={styles.text}>
                {sujeitoAtencao.endereco?.municipio?.nome || ""}
              </div>
            </div>}
        {sujeitoAtencao?.endereco?.estado &&
            <div style={styles.headerNomeSujeito}>
              <div style={styles.headerNomeSujeitoLabel}>Estado:</div>
              <div style={styles.text}>
                {sujeitoAtencao.endereco?.estado?.nome || ""}
              </div>
            </div>}

        {sujeitoAtencao?.espanhaMunicipio &&
            <div style={styles.headerNomeSujeito}>
              <div style={styles.headerNomeSujeitoLabel}>Múnicipio:</div>
              <div style={styles.text}>
                {string.capitalize(sujeitoAtencao.espanhaMunicipio?.descricao) || ""}
              </div>
            </div>}

        {sujeitoAtencao?.espanhaMunicipio &&
            <div style={styles.headerNomeSujeito}>
              <div style={styles.headerNomeSujeitoLabel}>Provincia:</div>
              <div style={styles.text}>
                {string.capitalize(sujeitoAtencao.espanhaMunicipio?.provincia?.descricao) || ""}
              </div>
            </div>}

        {sujeitoAtencao?.espanhaMunicipio &&
            <div style={styles.headerNomeSujeito}>
              <div style={styles.headerNomeSujeitoLabel}>Comunidade Autonoma:</div>
              <div style={styles.text}>
                {string.capitalize(sujeitoAtencao.espanhaMunicipio?.provincia?.comunidadeAutonoma?.descricao) || ""}
              </div>
            </div>}
      </div>

      <div style={styles.titulos}>
        <div style={styles.headeGroup}>Contato</div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>
            Telefone principal:
          </div>
          <div style={styles.text}>
            { formattedPhone({
              utilizaTelefoneInternacional,
              telefone: sujeitoAtencao.contato?.telefonePrincipal,
              telefoneDDI: sujeitoAtencao.contato?.telefonePrincipalDDI
            }) }
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>
            Telefone secundario:
          </div>
          <div style={styles.text}>
            { formattedPhone({
                utilizaTelefoneInternacional,
                telefone: sujeitoAtencao.contato?.telefoneSecundario,
                telefoneDDI: sujeitoAtencao.contato?.telefoneSecundarioDDI
            }) }
          </div>
        </div>
      </div>
      <div style={styles.headerNomeSujeito}>
        <div style={styles.headerNomeSujeitoLabel}>E-mail:</div>
        <div style={styles.text}>
          {sujeitoAtencao.contato?.email || ""}
        </div>
      </div>

      <div style={styles.titulos}>
        <div style={[styles.text, styles.textBold]}>Dados Adicionais</div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Raça:</div>
          <div style={styles.text}>
            {string.capitalize(sujeitoAtencao.raca?.descricao || "") || ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Religião:</div>
          <div style={styles.text}>
            {sujeitoAtencao.religiao || ""}
          </div>
        </div>
      </div>
      <div style={styles.headerData}>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Nome da mãe:</div>
          <div style={styles.text}>
            {sujeitoAtencao.nomeMae || ""}
          </div>
        </div>
        <div style={styles.headerNomeSujeito}>
          <div style={styles.headerNomeSujeitoLabel}>Nome do pai:</div>
          <div style={styles.text}>
            {sujeitoAtencao.nomePai || ""}
          </div>
        </div>
      </div>
      <div style={styles.headerNomeSujeito}>
        <div style={styles.headerNomeSujeitoLabel}>Escolariedade:</div>
        <div style={styles.text}>
          {string.capitalize(sujeitoAtencao.escolaridade?.descricao || "") ||
            ""}
        </div>
      </div>
      <div style={styles.headerNomeSujeito}>
        <div style={styles.headerNomeSujeitoLabel}>Ocupação:</div>
        <div style={styles.text}>
          {sujeitoAtencao.ocupacao?.descricao || ""}
        </div>
      </div>
      <div style={styles.headerNomeSujeito}>
        <div style={styles.headerNomeSujeitoLabel}>Quem indicou:</div>
        <div style={styles.text}>
          {sujeitoAtencao.quemIndicou || ""}
        </div>
      </div>
      <div style={styles.headerNomeSujeito}>
        <div style={styles.headerNomeSujeitoLabel}>
          CNS (Carteira Nacional de Saúde):
        </div>
        <div style={styles.text}>{sujeitoAtencao.cns || ""}</div>
      </div>
      <div style={styles.titulos}>
        <div style={styles.headeGroup}>Observação</div>
      </div>
      <div style={styles.headerNomeSujeito}>
        <div style={styles.text}>
          {sujeitoAtencao.observacao || ""}
        </div>
      </div>
    </>
  );
};

const FichaSujeitoAtencaoPdfDocument = (props) =>
  props.InfosSujeitoAtencao ? (
      <PaginaInfosSujeitoAtencao {...props} />
  ) : (
    <Pagina {...props} />
  );

const PaginaInfosSujeitoAtencao = (props) => {
  const { sujeitoAtencao, dataAgendamento, convenioAgendamento, utilizaTelefoneInternacional, horaInicioAgendamento, profissionalSaude, tipoAtendimento, sala, procedimentos, isModalSala } = props;

  const calculaIdade = () => {
    return moment().diff(sujeitoAtencao?.dataNascimento, "years");
  };

  const getEnderecoFormatado = () => {
    const { endereco } = sujeitoAtencao
    const {nomeLogradouro, numero, bairro, municipio, estado} = endereco
    return `${nomeLogradouro ? `${nomeLogradouro},` : ''} ${numero ? `n° ${numero},` : ''} ${bairro || ''}  ${municipio?.nome ? `/ ${municipio?.nome}` : ''}  ${estado?.nome  ? `- ${estado?.nome}` : ''}`
  }

  const getDateAgendamento = () => {
    const data = moment(dataAgendamento).format('DD/MM/YYYY');
    return horaInicioAgendamento && horaInicioAgendamento !== "00:00" ? `${data} às ${horaInicioAgendamento}` : data;
  }

  const getProcedimentos = () => {
    let procedimentosString = '';
    procedimentos.forEach(item => {
      procedimentosString = `${procedimentosString} ${item.nomeProcedimento},`;
    });

    return procedimentosString;
  }

  return (
    <>
      <div>
        <div style={styles.tituloInfo}>Infos Paciente:</div>
      </div>
      <div style={styles.Infos}>
        <div>
          <div style={styles.InfosNome}>{sujeitoAtencao?.nomeSocial || sujeitoAtencao?.nome || ""}</div>
          <div style={styles.InfosInSameRow}>
            <div style={styles.row}>
              <div style={styles.CampTitle}>Telefone:</div>
              <div style={styles.CampValue}>
              {formattedPhone({
                utilizaTelefoneInternacional,
                telefone: sujeitoAtencao.contato?.telefonePrincipal, 
                telefoneDDI: sujeitoAtencao.contato?.telefonePrincipalDDI
              })}
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.CampTitle}>Telefone Secundário:</div>
              <div style={styles.CampValue}>
                {formattedPhone({
                  utilizaTelefoneInternacional,
                  telefone: sujeitoAtencao.contato?.telefoneSecundario, 
                  telefoneDDI: sujeitoAtencao.contato?.telefoneSecundarioDDI
                })}
              </div>
            </div>
          </div>
          <div style={styles.InfosInSameRow}>
            {sujeitoAtencao?.documento && <div style={styles.row}>
              <div style={styles.CampTitle}>Documento:</div>
              <div style={styles.CampValue}>
                {string.format('###.###.###-##', sujeitoAtencao.documento)|| ""}
              </div>
            </div>}
            <div style={styles.row}>
              <div style={styles.CampTitle}>Convênio:</div>
              <div style={styles.CampValue}>
                {convenioAgendamento?.descricao || ""}
              </div>
            </div>
          </div>
          {sujeitoAtencao?.dataNascimento &&
            <div style={styles.InfosInSameRow}>
              <div style={styles.row}>
                <div style={styles.CampTitle}>Data de nascimento:</div>
                <div style={styles.CampValue}>
                  {moment(sujeitoAtencao?.dataNascimento).format("DD/MM/YYYY") || ""}
                </div>
              </div>
              <div style={styles.row}>
                <div style={styles.CampTitle}>Idade:</div>
                <div style={styles.CampValue}>
                  {calculaIdade(sujeitoAtencao?.dataNascimento)}
                </div>
              </div>
            </div>
          }
          {sujeitoAtencao.endereco &&
            <div style={styles.row}>
              <div style={styles.CampTitle}>Endereço:</div>
              <div style={styles.CampValue}>
                {getEnderecoFormatado(sujeitoAtencao)}
              </div>
            </div>
          }
          {sujeitoAtencao?.espanhaMunicipio && <div style={styles.row}>
            <div style={styles.CampTitle}>Município:</div>
            <div style={styles.CampValue}>
              {sujeitoAtencao?.espanhaMunicipio?.descricao || ""}
            </div>
          </div>}
          <div style={styles.row}>
            <div style={styles.CampTitle}>Data do atendimento:</div>
            <div style={styles.CampValue}>
              {dataAgendamento && getDateAgendamento()}
            </div>
          </div>
          {sala?.id && <div style={styles.row}>
              <div style={styles.CampTitle}>Sala:</div>
              <div style={styles.CampValue}>
                {sala.nome}
              </div>
            </div>
          }
          {!isModalSala &&
            <div style={styles.row}>
              <div style={styles.CampTitle}>Tipo do atendimento:</div>
              <div style={styles.CampValue}>
                {tipoAtendimento}
              </div>
            </div>
          }
          {procedimentos && procedimentos.length > 0 && <div style={styles.row}>
            <div style={styles.CampTitle}>Procedimentos:</div>
              <div style={styles.CampValue}>
                {getProcedimentos()}
              </div>
            </div>
          }
          { profissionalSaude && <div style={styles.row}>
            <div style={styles.CampTitle}>Profissional:</div>
            <div style={styles.CampValue}>
              {profissionalSaude}
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};

const styles = {
  text: {
    fontSize: '14px',
    textAlign: 'justify',
  },
  textBold: {
    fontWeight: 700,
  },
  headeGroup: {
    textAlign: 'justify',
    fontWeight: 700,
    fontSize: '16px',
  },
  titulos: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderBottom: '1px solid #505050',
    marginLeft: '0px',
    marginBottom: '15px',
    padding: '5px 0',
    fontSize: '18px',
    fontWeight: 700,
  },
  headerSujeito: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '8px',
  },
  headerNomeSujeito: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '10px',
  },
  headerNomeSujeitoLabel: {
    marginRight: '5px',
    fontSize: '14px',
    textAlign: 'justify',
    fontWeight: 700,

  },
  headerData: {
    display: 'flex',
    flexDirection: 'row'
  },

  tituloInfo: {
    fontSize: '24px',
  },

  Infos: {
    marginTop: '16px',
  },

  InfosNome: {
    fontSize: '18px'
  },

  InfosInSameRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginTop: '8px',
    flexWrap: 'nowrap',
    gap: '12px'
  },

  CampTitle: {
    fontSize: '12px',
  },

  CampValue: {
    marginLeft: '5px',
    fontSize: '12px',
    fontWeight: 700,
  },

  row: {
    display: 'flex', 
    flexDirection: 'row',
    marginTop: '8px',
    flexWrap: 'nowrap'
  },

  spaceTel: {
    marginLeft: '8px'
  }
};

export default FichaSujeitoAtencaoPdfDocument;
