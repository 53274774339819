import * as Mustache from 'mustache';

export const htmlMustache = ({modelo, dados}) => {
    const template = `
        <html>
        <head>
            <style>
                *{
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }
            </style>
        </head>
            <body>
                {{{html}}}
            </body>
        </html>
    `
    const html = Mustache.render(modelo || template, dados);
  
    return html;
};

export const configuracaoImpressaoPage = config => {
    const size = config.tamanhoPapel === 'PERSONALIZADO' ? `${config?.largura}0mm ${config?.altura}0mm` : config?.tamanhoPapel;
    const orientation = config.retrato ? 'portrait' : 'landscape';

    return `
        @page {
            size: ${size || "A4"} ${orientation};
        }
    `
};