import React from "react";
import {Text, View, Document, StyleSheet, Image} from "@react-pdf/renderer";
import styled from '@react-pdf/styled-components';
import logoSourceDefault from "../../../assets/img/logo.png";
import Colors from "../../Colors";
import string from "../../../utils/string";
import {applyCurrencyMask} from "../../../utils/CurrencyMask";
import pagePrintStyle from "../../../utils/pagePrintStyle";

const renderItem = ({styles, item, index}) => {

        return (
        <View style={styles.containerColuna} key={index}>
            <View style={styles.coluna}>
                <Text style={[styles.text]}>
                    {string.formatData(item.dataVencimento) || ""}
                </Text>
            </View>

            <View style={styles.coluna}>
                <Text style={[styles.text]}>
                    {item.profissionalSaude?.nome || "-"}
                </Text>
            </View>

            <View style={styles.colunaDescricao}>
                <Text style={[styles.text]}>
                    {item.sujeitoAtencao?.nome || "-"}
                </Text>
            </View>

            <View style={styles.coluna}>
                <Text style={[styles.text]}>
                  {item.convenio?.descricao || "-"}
                </Text>
            </View>

            <View style={styles.colunaDescricao}>
                <Text style={[styles.text]}>
                    {item.nome || ""}
                </Text>
            </View>

            <View style={styles.coluna}>
                <Text style={[styles.text, item.tipo === "RECEITA" ? styles.receita : styles.despesa]}>
                    {item.tipo === 'RECEITA' ? calculateValueWithDiscount(item) : "R$ 0,00"}
                </Text>
            </View>
            
            <View style={styles.coluna}>
                <Text style={[styles.text, item.tipo === "RECEITA" ? styles.receita : styles.despesa]}>
                    {item.tipo === 'DESPESA' ? calculateValueWithDiscount(item) : "R$ 0,00"}
                </Text>
            </View>

            <View style={styles.coluna}>
                <Text style={[styles.text]}>
                    {string.formatData(item.dataPagamento) || ""}
                </Text>
            </View>

            <View style={styles.coluna}>
                <Text style={[styles.text]}>
                    {item.formaPagamento?.nome || "-"}
                </Text>
            </View>
        </View>
    );
};

const calculateValueWithDiscount = (item) => {
    const discount = item.tipoDesconto === 'PERCENTIL' ? (item.valor * item.desconto) / 100 : item.desconto;
    const result = item.valor - (discount || 0);
    return applyCurrencyMask(result);
};

const Pagina = (props) => {
    const {
        extratos,
        totais,
        config,
        unidade
    } = props;

    const usarCabecalhoRodapePadrao = config?.usarCabecalhoRodapePadrao || false;
    const pageStyle = pagePrintStyle.pageStyle(config, true);

    const Page = styled.Page`
       ${pageStyle.page}
    `;

    const Footer = styled.View`
       ${pageStyle.footer}
    `;

    const size = pagePrintStyle.size(config);

    return (
        <Page
            size={size || "A4"}
            orientation={!config?.retrato ? "landscape" : "portrait"}
            style={styles.page}
        >
            {usarCabecalhoRodapePadrao && (
                <View style={styles.headerData}>
                    <View style={styles.logoContainer}>
                        <Image src={logoSourceDefault} style={styles.logo}/>
                    </View>
                    <Text style={[styles.text, styles.textBold, styles.headerUnidade]}>
                        {unidade.nome || ""}
                    </Text>
                </View>
            )}
            <View style={styles.titulos}>
                <Text style={[styles.text, styles.textBold]}>
                    Extrato financeiro
                </Text>
            </View>

            <View style={styles.containerColuna}>
                <View style={styles.coluna}>
                    <Text style={[styles.text, styles.textBold]}>
                        Data
                    </Text>
                </View>
                <View style={styles.coluna}>
                    <Text style={[styles.text, styles.textBold]}>
                        Profissional
                    </Text>
                </View>
                <View style={styles.colunaDescricao}>
                    <Text style={[styles.text, styles.textBold]}>
                        Paciente
                    </Text>
                </View>
                <View style={styles.coluna}>
                    <Text style={[styles.text, styles.textBold]}>
                        Convênio
                    </Text>
                </View>
                <View style={styles.colunaDescricao}>
                    <Text style={[styles.text, styles.textBold]}>
                        Descrição
                    </Text>
                </View>
                <View style={styles.coluna}>
                    <Text style={[styles.text, styles.textBold]}>
                        Receita
                    </Text>
                </View>
                <View style={styles.coluna}>
                    <Text style={[styles.text, styles.textBold]}>
                        Despesa
                    </Text>
                </View>
                <View style={styles.coluna}>
                    <Text style={[styles.text, styles.textBold]}>
                        Pago em
                    </Text>
                </View>
                <View style={styles.coluna}>
                    <Text style={[styles.text, styles.textBold]}>
                        Forma de pagamento
                    </Text>
                </View>
            </View>

            {extratos.map((item, index) =>
                renderItem({styles, item, index})
            )}

            <Footer style={styles.footer}>
                <View style={styles.headerFooter}>
                    <Text style={[styles.text, styles.textBold]}>
                        Balanço
                    </Text>
                </View>
                <View style={styles.containerColuna}>
                    <View style={styles.coluna}>
                        <Text style={[styles.text, styles.textBold, styles.receita]}>
                            Total receitas: {totais.totalReceitaFormatted}
                        </Text>
                    </View>
                    <View style={styles.coluna}>
                        <Text style={[styles.text, styles.textBold, styles.despesa]}>
                            Total despesas: {totais.totalDespesaFormatted}
                        </Text>
                    </View>
                    <View style={styles.coluna}>
                        <Text style={[styles.text, styles.textBold]}>
                            Total: {totais.totalFormatted}
                        </Text>
                    </View>
                </View>
            </Footer>
        </Page>
    );
};

const RelatorioExtratoPdfDocument = props => (
    <Document>
        <Pagina {...props} />
    </Document>
);

const page = {
    margin: 0
};

const styles = StyleSheet.create({
    page: {
        ...page,
    },
    receita: {
        color: "green"
    },
    despesa: {
        color: "red"
    },
    text: {
        fontFamily: "Helvetica",
        fontSize: 8
    },
    textBold: {
        fontFamily: "Helvetica-Bold"
    },
    titulos: {
        borderBottomWidth: 1,
        marginLeft: 0,
        marginRight: page.margin * 2,
        marginBottom: 15,
        marginTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
    },
    headerSujeito: {
        flexDirection: "column",
        marginRight: page.margin * 2 - 5
    },
    headerNomeSujeito: {
        flexDirection: "row",
        marginRight: '10px'
    },
    headerColumn: {
        marginRight: '20px',
        marginLeft: '20px'
    },
    headerData: {
        flexDirection: "row",
        alignItems: "center"
    },
    logo: {
        width: 40,
        height: 40,
    },
    logoContainer: {
        width: '10%',
    },
    headerUnidade: {
        marginTop: '15px',
        fontSize: 15,
        color: Colors.primary.main
    },
    itemData: {
        marginLeft: '20px',
        marginRight: "20px"
    },
    containerColuna: {
        display: "flex",
        flexDirection: "row",
    },
    coluna: {
        flexGrow: "1",
        width: "50px"
    },
    colunaPaciente: {
        flexGrow: "2",
        width: "86px",
        marginBottom: "10px",
        marginRight: "4px"
    },
    colunaDescricao: {
        flexGrow: "2",
        width: "90px",
        marginBottom: "10px",
        marginRight: "3px"
    },
    footer: {
        position: "absolute",
        paddingTop: 10,
        borderTopWidth: 1
    },
    headerFooter: {
        textAlign: 'center',
        marginBottom: "10px"
    }

});

export default RelatorioExtratoPdfDocument;
