import React from 'react';
import Select, {components} from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

import Colors from "../../template/Colors";
import FormControl from "@material-ui/core/FormControl/FormControl";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";


function noOptionsMessage() {
    return 'Pesquisar'
}

function loadingMessage() {
    return 'Carregando...'
}

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        height: 'auto',
      }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ccc' : (state.isFocused ? '#e9e9e9' : '#fff'),
        color: Colors.commons.fontColor,
        cursor: 'pointer',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        width: '100% !important',
        ':active': {
            backgroundColor: state.isSelected ? '#999' : '#e9e9e9',
            color: 'black',
        },
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        minHeight: 0,
        paddingLeft: '8px',
        fontSize: '14px',
        fontFamily: 'Poppins !important',
        color: Colors.commons.black,
        backgroundColor: Colors.commons.gray2,
        borderRadius: '100px',
        cursor: 'pointer',
        borderStyle:'none',
        maxHeight: '32px',
        lineHeight: 1.5,
        boxShadow: "none"
    }),
    placeholder: (provided) => ({
        ...provided,
        color: Colors.commons.darkGray,
        alignItems: 'center',
        fontFamily: 'Poppins !important',
    }),
    input: (provided) => ({
        ...provided,
        maxWidth: '250px',
        color: Colors.commons.black,
        opacity: '1 !important',
        fontFamily: 'Poppins !important',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const color = Colors.commons.gray9;

        return { ...provided, opacity, transition, color };
    }
}

const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
        <i className={`material-icons ${props.className} `}
           style={{
               fontFamily: 'Poppins !important',
               color: Colors.commons.gray7,
               top: 'calc(50% - 12px)',
               fontSize: '24px',
               borderRadius: '50%',
               right: 6,
               margin:'3px 5px',
               width: '24px'
           }}>
            {props.selectProps.menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        </i>
    );
};

const ClearIndicator = (props) => {
    return components.ClearIndicator && (
        <components.ClearIndicator {...props}>
            <i className={`material-icons ${props.className} `}
               style={{
                   color: Colors.commons.gray7,
                   fontSize: '16px',
                   borderRadius: '50%',
               }}>
                {'close'}
            </i>
        </components.ClearIndicator>
    );
};

export const SelectSearch = props => {
    const { className, elements, placeholder, isClearable, disabled, ...other } = props;
    return <FormControl className={className} disabled={disabled}>
        <Select
            {...other}
            isDisabled={disabled}
            styles={customStyles}
            placeholder={placeholder}
            isClearable={isClearable || false}
            components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator}}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            options={elements?.map(c => ({ value: c.value, label: c.name}))}
        />
    </FormControl>
};



export const SelectSearchAsync = props => {
    const { className, placeholder, ...other } = props;

    return <FormControl className={className} >
        <AsyncSelect
            {...other}
            styles={customStyles}
            cacheOptions
            isClearable={true}
            placeholder={placeholder}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator }}
        />
    </FormControl>
};

export const ReactSelectCreateAsync = props => {
    const { className, elements, placeholder, ...other } = props;


    return <FormControl className={className}>
        <AsyncCreatableSelect
            {...other}
            styles={customStyles}
            placeholder={placeholder || ""}
            isClearable
            components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator}}
            createOptionPosition="first"
        />
    </FormControl>
};
