export const coresLocais = {
  CIC: "#5DFF64",
  CAVR: "#E3AE21",
  CAVR_TOTAL: "#D6D616",
  CAVR_PROLONGADA: "#C6C61F",
  CAVL: "#FE5FAC",
  CAVL_TOTAL: "#FFFF4F",
  CAVL_PROLONGADA: "#C6C61F",
  BRC: "#5AACFD",
  BOTON: "#E42A2A",
  BIC: "#2898F9",
  ARCO_LONGITUDINAL: "#E42A2A",
};
