import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import styles from "./RecuperarSenhaStyles";
import string from "../../utils/string";
import { InputCpf } from "../Login/InputCpf";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import ButtonLong from "../../components/Button/ButtonLong";

const RecuperarSenha = observer((props) => {
  const { classes, recuperarSenhaStore } = props;
  const [credentials, setCredentials] = useState({
    login: ""
  });

  const handleLoginChange = (e) => {
    setCredentials({ login: string.removeSpecialChars(e.target.value) });
  };

  const handleRedefinirSenha = async (event) => {
    event.preventDefault();

    try {
      if (string.isEmpty(credentials.login)) {
        return;
      }

      await recuperarSenhaStore.redefinir(credentials.login);
      setCredentials({ login: "" });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className={classes.textoInformativo}>
        <strong>Digite seu CPF</strong> de cadastro abaixo e clique em enviar.
        Nós lhe <strong>enviaremos um e-mail</strong> com link
        <strong> para recadastrar a sua senha</strong>.
      </div>
      <div>
        <div className={classes.titleInput}> CPF </div>
        <InputCpf
          value={credentials.login}
          classInputRoot={classes.inputStyle}
          onChange={handleLoginChange}
        />
      </div>
      <ButtonLong
        colorCustom="green"
        type="submit"
        className={classes.buttonEnviar}
        loading={recuperarSenhaStore.redefinindo}
        onClick={handleRedefinirSenha}
      >
        Enviar
      </ButtonLong>
    </>
  );
});

const RecuperarSenhaWithStyles = withStyles(styles)(RecuperarSenha);
export default inject("recuperarSenhaStore")(RecuperarSenhaWithStyles);
