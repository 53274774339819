import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import PeriodDate from '../../../components/PeriodDate/PeriodDate';
import { inject } from 'mobx-react';
import Button from '../../../components/Button/Button';
import moment from 'moment';

const styles = ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '18px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: '#868686',
  },
  buttonRoot: {
    height: '32px',
    borderRadius: '8px',
    padding: '8px 16px 8px 16px',
    textTransform: 'none'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '12px',
  },
  zindex: {
    zIndex: 100
  }
});

const DateFilter = ({ classes, filter, onApply, setFilter, setGetAllFlag, resetPage }) => {
  const handleClickCurrentMonth = () => {
    setGetAllFlag(false);
    resetPage();
    setFilter({
      dataInicial: moment().startOf("month").format("YYYY-MM-DD"),
      dataFinal: moment().endOf("month").format("YYYY-MM-DD"),
    });
    onApply();
  };

  const handleChangeDate = (startDate, endDate) => {
    setGetAllFlag(false);
    resetPage();
    setFilter({
      dataInicial: startDate,
      dataFinal: endDate
    });
  };

  const handleClickAll = () => {
    setGetAllFlag(true);
    resetPage();
    onApply();
  }

  const handleOnChangeInput = (range) => {
    setGetAllFlag(false);
    resetPage();
    setFilter({
      dataInicial: range.dataInicial,
      dataFinal: range.dataFinal
    });
    onApply();
  }

  return (
    <div className={classes.filterContainer}>
      <div className={classes.container}>
        <span className={classes.label}>Filtrar por data</span>
        <PeriodDate 
          filter={filter} 
          handleAplicar={onApply}
          setFilter={handleOnChangeInput}
          onChangeDate={handleChangeDate}
          searchOnlyOnApply
          searchIfInputValid
          stylePopper={classes.zindex}
        />
      </div>
      <div className={classes.container}>
        <span className={classes.label}>Exibir</span>
        <div className={classes.buttons}>
          <Button 
            classes={{root: classes.buttonRoot}}
            style={{backgroundColor: '#F9BE73'}}
            onClick={handleClickCurrentMonth}
          >
            Mês Atual
          </Button>
          <Button 
            classes={{root: classes.buttonRoot}} 
            style={{backgroundColor: '#707C97'}}
            onClick={handleClickAll}
          >
            Tudo
          </Button>
        </div>
      </div>

    </div>
  )
};

export default withStyles(styles)(DateFilter);