import React from 'react';
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import './app/config/moment';

import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';

import './app/assets';
import Routes from './routes';

import stores from './app/stores/Root.store';

import { initializeFirebase } from './push-notification';
import { initializeGmt } from './app/config/config';

initializeFirebase();
initializeGmt();

const app = document.getElementById('app');
window.stores = stores;
var ttChatLoaderS = document.createElement('script');
document.tomticketChatLoaderScriptVersion = 2;
ttChatLoaderS.src = 'https://apphealth.tomticket.com/scripts-chat/chat.min.js'
+ '?id=EP65777'
+ '&account=4209728P16052024032926'
+ '&autoOpen=0'
+ '&hideWhenOffline=0'
+ '&d=apphealth'
+ '&ts=' + new Date().getTime()
+ '&ref=' + encodeURIComponent(document.URL);
document.body.appendChild(ttChatLoaderS);

render(
  <Provider {...stores}>
    <MeetingProvider>
      <HashRouter>
        <Routes />
      </HashRouter>
    </MeetingProvider>
  </Provider>,
  app
);

if (process.env.NODE_ENV !== 'production') {
  require('mobx-logger').enableLogging({
    action: true,
    reaction: false,
    transaction: true,
    compute: false,
  });
}
