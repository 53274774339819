const style = {
  tableContainerConsultas: {
    display: "grid",
    alignContent: "start",
    overflowY: "auto",
    background: "#E5E5E5",

    "& thead": {
      zIndex: 0,
      "& th": {
        top: 0,
        zIndex: 0,
        position: "sticky",
        width: "20%",
  
        "&:first-child": {
          width: "100px",
          borderRadius: "16px 0 0",
        },
        "&:last-child": {
          borderRadius: "0 16px 0 0",
        },
      }
    },

    "& tbody td": {
      width: "20%",

      "&:first-child": {
        width: "100px",
      },
    },
  },
  contentHeaderConsultas: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    borderTop: "1px solid #f2f2f2",
    background: "#fff",
    height: "69px",
  },

  contentButtonHeaderConsultas: {
    display: "flex",
    gap: "16px",
    width: "100%",
    maxWidth: "305px",
  },

  titleHeaderConsultas: {
    fontWeight: 700,
    fontSize: "18px",
  },

  contentButtonCircleConsultas: {
    display: "flex",
    gap: "16px",
    position: "absolute",
    right: "40px",
    bottom: "40px",
  },

  contentTableConsultas: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    background: "#fff",
    margin: "16px 24px",
    height: "100%",
  },
  contentFiltersConsultas: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    background: "#fff",
    margin: "24px 24px 0",
    height: "54px",
    display: "flex",
    padding: "27px 16px",
    gap: "16px",
    alignItems: "center",
  },
  tituloFiltros: {
    color: "#868686",
    fontSize: "12px !important",
  },
  field: {
    display: "flex",
    flexDirection: "column",
  },
  contentButtonFilter: {
    width: "120px",
    height: "100%",
    display: "flex",
    alignItems: "end",
  },
  fieldProfissional: {
    width: "50%",
  },
  contentLoading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    marginTop: "16px",
  },
  notchedOutlineSearch: {
    border: "none",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  contentButtonVerTodos: {
    width: '150px',
    height: "100%",
    display: "flex",
    alignItems: "end",
  },
};

export default style;
