import colors from "../../template/Colors";

const styles = {
  errorMessage: {
    fontSize: "10px !important",
    color: "#FB7676",
    paddingLeft: "4px",
  },
  contentCalendar: {
    height: '362px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    "& .rdrDateDisplayWrapper, .rdrMonthAndYearPickers": {
      display: "none",
    },
    "& .rdrDefinedRangesWrapper": {
      display: "none",
    },
    "& .rdrCalendarWrapper, .rdrDateRangeWrapper": {
      maxWidth: "512px",
      borderRadius: "8px",
    },
    "& .rdrMonth": {
      padding: "0 0.833em 0 0.833em",
    },
    "& .rdrDayNumber span": {
      fontSize: "12px",
    },
    "& .rdrDay rdrDayToday": {
      border: "1px solid rgba(0, 0, 0, 0.87)",
      borderRadius: "16px",
      width: "28px",
      height: "28px",
      marginTop: "7px",
    },
    "& .rdrDayToday .rdrDayNumber": {
      "& span::after": {
        content: "none",
      },
    },
    "& .rdrDayInPreview": {
      borderColor: '#219A97',
    },
    "& .rdrDayStartPreview .rdrDayEndPreview": {
      borderColor: '#219A97',
    },
    "& .rdrInRange": {
      backgroundColor: "rgba(33, 154, 151, 0.1)",
      "& .rdrDayNumber span": {
        color: "rgba(0, 0, 0, 0.87) !important",
      },
    },
    "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrSelected ~ .rdrDayNumber span": {
      color: "rgba(0, 0, 0, 0.87) !important",
    },
    "& .rdrEndEdge": {
      backgroundColor: colors.commons.padrao,
      right: "1px",
    },
    "& .rdrStartEdge": {
      backgroundColor: colors.commons.padrao,
      left: 0,
    },
    "& .rdrDayEndPreview": {
      margin: "2px 0 2px 2px",
      border: `1px solid ${colors.commons.padrao}`,
      height: 26,
      width: 26,
    },
    "& .rdrMonthName": {
      textAlign: "center",
      textTransform: "capitalize",
    },
    "& .rdrMonthAndYearWrapper": {
      alignItems: "unset",
      height: "0px",
      paddingTop: "0px",
    },
    "& .rdrNextPrevButton": {
      marginTop: "8px",
      backgroundColor: "transparent !important",
    },
    "& .rdrDay": {
      height: "38px",
    },
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'end',
    padding: '8px 16px',
  },
  button: {
    width: "139px",
    padding: "8px 16px",
  },
};

export default styles;
