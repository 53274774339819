import moment from "moment";
import { inject } from "mobx-react";
import classNames from 'classnames';
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core/es";
import React, { useEffect, useState } from "react";
import { Delete, Edit, Print } from "@material-ui/icons";
import styles from "./ModalStyles";
import { CircularProgress } from "@material-ui/core";
import InformacoesParcela from "./InformacoesParcela";
import Dialog from "../../../../../components/Dialog/Dialog";
import { tabs } from "../../../../../stores/Auditoria.store";
import Auditoria from "../../../../../components/Auditoria/Auditoria";
import StatusPagamento from "../../../../../components/Financeiro/StatusPagamento";
import MultiToggleButtons from "../../../../../components/Button/MultiToggleButtons";
import { findAllTituloParcelaAuditByTituloParcelaId } from "../../../../../services/AuditoriaService";
import { Button } from "../../../../../components/ui/Buttons";

const ModalParcela = observer((props) => {
  const {
    classes,
    open,
    title,
    close,
    auditoriaStore,
    extratoStore,
    configuracaoImpressaoStore,
  } = props;
  const { tituloParcela } = extratoStore;
  const [tabSelected, setTabSelected] = useState(tabs.INFORMACOES);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(tituloParcela.dataPagamento);
    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      auditoria: "o lançamento",
      auditoriaTela: "lancamento",
    };
  }, []);

  const handleEditar = () => {
    setIsDisabled(false);
  };

  const handlePrintRecibo = async () => {
    try {
      extratoStore.loadingButtonActionModal = true;
      await configuracaoImpressaoStore.getConfig();

      const dadosTitulo = await extratoStore.loadById(
        extratoStore.tituloParcela.id
      );
      extratoStore.dadosTituloPrint = dadosTitulo;
      extratoStore.dadosParaImpressaoRecibo = dadosTitulo;
      extratoStore.printingRecibo = true;

      extratoStore.isPrintMustacheModal = true;
    } finally {
      extratoStore.loadingButtonActionModal = false;
    }
  };

  const handleDelete = () => {
    extratoStore.modalConfirmarExcluir={
      open: true,
      buttons: {
        ...extratoStore.modalConfirmarExcluir.buttons,
        handlePrimaryButton: handleCloseModalConfirmarExcluir,
        handleSecondButton: handleConfirmDelete,
      }
    }
  };

  const handleCloseModalConfirmarExcluir = () => {
    extratoStore.modalConfirmarExcluir.open = false;
  };

  const handleConfirmDelete = async () => {
    try {
      extratoStore.loadingButtonActionModal = true;
      const titulo = {
        ...extratoStore.tituloParcela,
        sujeitoAtencao: { id: extratoStore.titulo.sujeitoAtencao?.id },
        profissionalSaude: { id: extratoStore.titulo.profissionalSaude?.id },
        centroCusto: extratoStore.titulo.centroCusto?.id && {
          id: extratoStore.titulo.centroCusto?.id,
        },
        contaBancaria: extratoStore.titulo.contaBancaria?.id && {
          id: extratoStore.titulo.contaBancaria?.id,
        },
        excluido: true,
      };
      if (tituloParcela.id) {
        await extratoStore.updateTituloParcela(titulo);
      };

      close();
    } catch (error) {
      extratoStore.openNotification('Erro ao excluir a parcela', 'error');
    } finally {
      extratoStore.modalConfirmarExcluir.open = false;
      extratoStore.loadingButtonActionModal = false;
    }
  };

  const changeTabSelected = async (tabSelected) => {
    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    };

    if (tabSelected === 1) {
      auditoriaStore.load = {
        query: findAllTituloParcelaAuditByTituloParcelaId,
        variableName: "tituloParcelaId",
        variableId: extratoStore.tituloParcela.id,
      };
      await auditoriaStore.loadAuditItems();
    }

    setTabSelected(tabSelected);
  };

  const renderTab = () => {
    return tabSelected === 0 ? (
      <InformacoesParcela close={close} isDisabled={isDisabled} />
    ) : (
      <Auditoria />
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      fullWidth
      classes={{
        paper: classes.fullSizePaper,
      }}
    >
      <div className={classes.header}>
        <div className={classes.titulo}>
          <div> {title || " - "} </div>
          <div>
            {moment(tituloParcela?.dataVencimento).format("DD/MM/YYYY") ||
              " - "}
          </div>
        </div>
        <div className={classes.contentStatusButton}>
          <StatusPagamento
            dataVencimento={tituloParcela?.dataVencimento}
            dataPagamento={tituloParcela?.dataPagamento}
            status={tituloParcela?.excluido && "Excluído"}
          />
          {!tituloParcela.excluido && tituloParcela.dataPagamento && 
            <Button
              shape='circle'
              bgColor='#707C97'
              onClick={handleEditar}
              disabled={extratoStore.loadingModalParcela}
            >
              <Edit />
            </Button>
          }
          {tituloParcela.dataPagamento && (
            <Button
              shape='circle'
              bgColor='#F9BE73'
              onClick={handlePrintRecibo}
              disabled={extratoStore.loadingModalParcela}
            >
              <Print />
            </Button>
          )}
          {!tituloParcela.excluido && !tituloParcela.dataPagamento && (
            <Button
              shape='circle'
              bgColor='#FB7676'
              onClick={handleDelete}
              disabled={extratoStore.loadingModalParcela}
            >
              <Delete />
            </Button>
          )}
        </div>
      </div>
      <MultiToggleButtons
        options={["Informações", "Auditoria"]}
        tabSelected={tabSelected}
        changeTabSelected={changeTabSelected}
        classes={{ buttonsContainer: classes.toggleButtons }}
      />
      {extratoStore.loadingModalParcela ? (
        <div className={classes.contentLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.contentTab}>{renderTab()}</div>
      )}
    </Dialog>
  );
});

const ModalParcelaWithStyles = withStyles(styles)(ModalParcela);
export default inject(
  "auditoriaStore",
  "extratoStore",
  "configuracaoImpressaoStore"
)(ModalParcelaWithStyles);
