import React, { useCallback, useEffect, useMemo, useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Tooltip, withStyles } from "@material-ui/core";

import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";
import string from "../../../utils/string";
import { findControleSessaoById } from "../../../services/ControleSessaoService";
import InputQuantity from "./components/InputQuantity";

const AtendimentoModalTabSessoes = observer(
  ({ classes, atendimentoStore, controleSessaoStore }) => {
    const { controleSessaoData } = atendimentoStore;
    const [sessaoSelected, setSessaoSelected] = useState([]);

    const loadPacoteSessao = async () => {
      try {
        if (controleSessaoData?.idPacote) {
          const response = await findControleSessaoById(
            controleSessaoData?.idPacote
          );
          setSessaoSelected(response);
        } else {
          await controleSessaoStore.resetParamsPacote();
          await controleSessaoStore.loadAllPacotesSessoes(
            {
              idSujeitoAtencao: atendimentoStore.objView?.sujeitoAtencao?.id,
            },
            true,
            false,
            true,
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const currentField = useMemo(() => {
      const isProcedure = controleSessaoData?.isProcedure;
      const field = isProcedure
        ? "controleSessaoProcedimentos"
        : "controleSessaoAgendamentoTipos";
      const subField = isProcedure ? "procedimento" : "agendamentoTipo";
      return { field, subField };
    }, [controleSessaoData]);

    const numberOfSessions = useMemo(() => {
      const { field } = currentField;
      const quantity = sessaoSelected?.[field]?.filter((item) => {
        return item?.agendamento?.id;
      }).length;
      const totalQuantity = sessaoSelected?.[field]?.filter((item) => {
        return item?.ativo;
      }).length;
      return { quantity, totalQuantity };
    }, [currentField, sessaoSelected]);

    const getAgendamentoTipoOrProcedimento = useCallback(
      (data) => {
        const { field, subField } = currentField;
        const fieldValue = data?.[field]?.[0]?.[subField];
        const procedimento = fieldValue?.nome;
        const agendamentoTipo = fieldValue?.descricao;
        return procedimento || agendamentoTipo;
      },
      [currentField]
    );

    const getNumberOfSessionsByPacote = useCallback((data) => {
      const field = string.currentFieldControleSessao(data)?.field;
      const quantity = data?.[field]?.filter((item) => {
        return item?.agendamento?.id;
      }).length;
      const totalQuantity = data?.[field]?.filter((item) => {
        return item?.ativo;
      }).length;
      return `${quantity}/${totalQuantity}`;
    }, []);

    const getNameProcedimentoByPacote = (data) => {
      const field = string.currentFieldControleSessao(data)?.field;
      const subField = string.currentFieldControleSessao(data)?.subField;
      const fieldValue = data?.[field]?.[0]?.[subField];
      const procedimento = fieldValue?.nome;
      const agendamentoTipo = fieldValue?.descricao;
      return procedimento || agendamentoTipo;
    };

    const handleLoadMore = () => {
      controleSessaoStore.loadAllPacotesSessoes({
        idSujeitoAtencao: atendimentoStore.objView?.sujeitoAtencao?.id,
        },
        false,
        false,
        true,
      );
    };

    const getColumns = () => {
      const columns = [
        {
          Header: "Procedimento",
          getValue: (data) => {
            const currentValue = controleSessaoData?.idPacote
              ? getAgendamentoTipoOrProcedimento(data)
              : getNameProcedimentoByPacote(data);
            return (
              <Tooltip title={currentValue} placement="top">
                <div>{string.truncate(currentValue, 10)}</div>
              </Tooltip>
            );
          },
        },
        {
          Header: "Sessões",
          getValue: (data) => {
            const currentValue = controleSessaoData?.idPacote
              ? `${numberOfSessions?.quantity}/${numberOfSessions?.totalQuantity}`
              : getNumberOfSessionsByPacote(data);
            return currentValue;
          },
        },

        {
          Header: "QTD",
          getValue: (data) => {
            const options = controleSessaoData?.idPacote
              ? sessaoSelected
              : controleSessaoStore?.pacoteSessao;

            return (
              <InputQuantity
                data={data}
                options={options}
                currentField={currentField}
                controleSessaoData={controleSessaoData}
                numberOfSessions={numberOfSessions}
                atendimentoStore={atendimentoStore}
              />
            );
          },
        },
      ];
      return columns;
    };

    useEffect(() => {
      loadPacoteSessao();
    }, []);

    return (
      <>
        <Scroll
          initialLoad={false}
          loadMore={handleLoadMore}
          hasMore={
            !controleSessaoStore.isLoading && !controleSessaoStore.hasMore
          }
          pageStart={0}
          classes={{
            root: classes.tableRoot,
          }}
        >
          <Table
            columns={getColumns()}
            dados={
              controleSessaoData?.idPacote
                ? [sessaoSelected]
                : controleSessaoStore.pacoteSessao
            }
          />
        </Scroll>
      </>
    );
  }
);

const styles = {
  tableRoot: {
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    maxHeight: "420px",
    borderRadius: "8px",
    overflowX: "hidden",
    "&>div>div>table>thead>tr>th": {
      textAlign: "start",
      padding: "8px 16px",
    },
    "&>div>div>table>thead>tr": {
      height: "auto",
    },
    "&>div>div>table>tbody>tr>td": {
      padding: "8px 16px",
      color: "#505050",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
  emptyLabel: {
    fontWeight: 500,
    fontSize: "12px !important",
    lineHeight: "18px",
    letterSpacing: "0.05em",
    color: "#505050",
  },
};

const stores = ["atendimentoStore", "controleSessaoStore"];
const AtendimentoModalTabSessoesWithStyles = withStyles(styles)(
  AtendimentoModalTabSessoes
);
export default inject(...stores)(AtendimentoModalTabSessoesWithStyles);
