import React from "react";
import {
    withStyles,
} from "@material-ui/core";
import Profile from '../../../../template/Header/Profile';


const HeaderAparelhosConectados = ({ classes, title }) => {


    return (
        <header className={classes.headerRoot} >
            <div className={classes.wrapperFilters}>
                <h1 className={classes.title}>
                    {title}
                </h1>
                <Profile />
            </div>
        </header>

    )
};

const style = {
    headerRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: '#fff',
        position: 'relative',
        padding: '8px 16px',
    },
    wrapperFilters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '100%',
    },

    title: {
        fontWeight: 700,
        fontSize: '18px',
    },

}

export default withStyles(style)(HeaderAparelhosConectados);
