import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import {withStyles} from "@material-ui/core";
import {inject} from 'mobx-react';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {IconButton} from '@material-ui/core';

const WeekPicker = (props) => {
    const { classes, atendimentoStore } = props;

     // eslint-disable-next-line
    const  [ diaSelecionado ,  setDiaSelecionado ]  =  useState (new Date()) ;
    const [activeStartDate, setActiveStartDate] = useState(undefined);
    const [mes, setMes] = useState(new  Date ());

    useEffect(() => {
        setActiveStartDate(undefined)
    }, [mes])
    
    const start = moment(atendimentoStore.selectedDate).day(0);
    const end = moment(atendimentoStore.selectedDate).day(6);

    const clickDay = (day) => {
        const { tipoProfissionalLogado, isSalaSelected } = atendimentoStore;
        atendimentoStore.selectedDate = moment(day);
        if (tipoProfissionalLogado !== 'tecnico'){
            atendimentoStore.initObjectView(atendimentoStore.selectedDate);
        }

        if(tipoProfissionalLogado === 'tecnico' || isSalaSelected) {
            atendimentoStore.initObjectViewSala(atendimentoStore.selectedDate);
        }
    }
    const formatarDiasDaSemana = (locale, value) => ['D', 'S', 'T', 'Q', 'Q', 'S', ' S '][value.getDay ()];

    const onActiveStartDateChange = (mes) =>{
        setMes(mes);
    }

    const onClickCurrentDayButton = () => {
        const { tipoProfissionalLogado, isSalaSelected } = atendimentoStore;
        const dateToSet = new Date();
        atendimentoStore.selectedDate = moment(dateToSet);
        dateToSet.setMonth(dateToSet.getMonth());
        const beginOfMonth = new Date(
            dateToSet.getFullYear(),
            dateToSet.getMonth(),
            1
        );
        setActiveStartDate(beginOfMonth);
        setDiaSelecionado(dateToSet);
        atendimentoStore.selectedDate = moment(dateToSet)
        if (tipoProfissionalLogado !== 'tecnico'){
            atendimentoStore.initObjectView(atendimentoStore.selectedDate);
        }

        if(tipoProfissionalLogado === 'tecnico' || isSalaSelected) {
            atendimentoStore.initObjectViewSala(atendimentoStore.selectedDate);
        }
    };

  return  (  
        <>
            <Calendar
                onChange={setDiaSelecionado}
                calendarType={"US"}
                className={classes.calendario}
                value={[start, end]}
                prevLabel={<ArrowUpwardIcon className={classes.iconeMudarMes}/>}
                nextLabel={<ArrowDownwardIcon className={classes.iconeMudarMes}/>}
                prev2Label={null}
                next2Label={null}
                onClickDay={(day) => clickDay(day)}
                formatShortWeekday={formatarDiasDaSemana} 
                activeStartDate={activeStartDate}
                onActiveStartDateChange={ (mes) => onActiveStartDateChange(mes)}
                formatMonthYear={(locale, date) => moment(date).format('MMMM YYYY')}
            /> 
            <IconButton
                onClick={onClickCurrentDayButton}
                className={classes.botaoDataAtual}

            >
                Ir para data de hoje
            </IconButton>
        </>
  ) ; 
};

const styles = theme => ({
    botaoDataAtual:{
        fontSize: 14,
        color: '#27B0AD',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 0,
        padding: 5,
        marginTop: 5,
    },
    iconeMudarMes:{
        fontSize: 20,
    },
    calendario: {
        backgroundColor: "#F9F9F9",
        borderWidth: `0 !important`,
        "& .react-calendar__viewContainer": {
            "& .react-calendar__tile":{
                fontSize: 12,
                padding: "0.1em",
                borderRadius: "50%",
                maxWidth: '28px !important',
                height: '28px',
                margin: "0 4px",
                "@media (max-height: 600px)":{
                    maxWidth: '24px !important',
                    height: '24px',
                    margin: "0 6px",
                },
            },
            "& .react-calendar__tile--active": { //dia selecionado
                backgroundColor: `#26ACA9 !important`,
                color: "#fff !important",
                padding: "0.5em",
                fontSize: '12px',
                borderRadius: "0px",
                maxHeight: 28,
                maxWidth: "36px !important",
                margin: 0,
                "&:hover":{
                    color: "#fff !important",
                    backgroundColor: `#26ACA9 !important`,
                    borderRadius: "0px",
                    maxHeight: 28,
                    maxWidth: "36px !important",
                },
                "@media (max-height: 600px)":{
                    height: '24px',
                    margin: "0px !important",
                    maxWidth: '36px !important',
                    minWidth: '36px !important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    "&:hover":{
                        borderRadius: 0,
                        maxHeight: 24,
                        minWidth: "36px !important",
                    },
                    "& > abbr":{
                        width: 19,
                        height: 19,
                        padding:4,
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '-5px',
                    },
                },
            },

            "& .react-calendar__tile--hasActive":{
                backgroundColor: `#26ACA9 !important`,
                padding: "0.69em",
                color: "#fff !important",
                "&:hover":{
                    color: "#fff !important",
                    borderRadius: 0,
                },
                "@media (max-height: 600px)":{
                    minWidth: '36px !important',
                    maxWidth: '36px !important',
                    height: '24px',
                    margin: "0 5px",
                    "&:hover":{
                        borderRadius: 0,
                        maxHeight: 24,
                        minWidth: "36px !important",
                    },
                    "& > abbr":{
                        width: 19,
                        height: 19,
                        padding:4,
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '-5px',
                        alignItems: 'center',
                    },
                },
            },
            "& .react-calendar__tile--rangeStart":{
                borderRadius: '27px 0 0 27px',
                padding: "0.69em",
                margin:0,
                maxWidth: "36px !important",
                "& > abbr":{
                    marginTop: '-3px',
                    display: 'flex',
                    justifyContent: 'center',
                },
                "&:hover":{
                    color: "#fff !important",
                    backgroundColor: `#26ACA9 !important`,
                    borderRadius: '27px 0 0 27px !important',
                },
                "@media (max-height: 600px)":{
                    borderRadius: '27px 0 0 27px !important',
                    maxWidth: '36px !important',
                    minWidth: '36px !important',
                    height: '24px',
                    margin: "0px !important",
                    alignItems: 'center',
                    "&:hover":{
                        borderRadius: '27px 0 0 27px !important',
                        maxHeight: 24,
                        maxWidth: "36px !important",
                        minWidth: '36px !important',
                    },
                    "& > abbr":{
                        marginTop: '-5px',
                        display: 'flex',
                        justifyContent: 'center',
                    },
                },
                
            },

            "& .react-calendar__tile--rangeEnd":{
                borderRadius: '0 27px 27px 0',
                padding: "0.69em",
                margin:0,
                maxWidth: "36px !important",
                "& > abbr":{
                    marginTop: '-2px',
                    display: 'flex',
                    justifyContent: 'center',
                },
                "&:hover":{
                    color: "#fff !important",
                    backgroundColor: `#26ACA9 !important`,
                    borderRadius: "0 27px 27px 0 !important",
                },
                "@media (max-height: 600px)":{
                    borderRadius: "0 27px 27px 0 !important",
                    maxWidth: '32px !important',
                    minWidth: '36px !important',
                    height: '24px',
                    margin: "0px !important",
                    alignItems: 'center',
                    "&:hover":{
                        borderRadius: "0 27px 27px 0 !important",
                        maxHeight: 24,
                        maxWidth: "32px !important",
                        minWidth: '36px !important',
                    },
                    "& > abbr":{
                        marginTop: '-2px',
                        display: 'flex',
                        justifyContent: 'center',
                    },
                },
            },
            "& .react-calendar__month-view__days":{
                "& .react-calendar__tile--now": { //dia atual
                    backgroundColor: `#F9F9F9`,
                    fontSize: '13px',
                    maxHeight: 28,
                    maxWidth: "36px !important",
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#00B0AE !important',
                    fontWeight: 700,
                    "& > abbr":{
                        background: '#D4EEEE',
                        minWidth: 26,
                        maxHeight: 25,
                        padding: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: "50%",
                    },
                    "@media (max-height: 600px)":{
                        maxWidth: '36px !important',
                        height: '24px',
                        margin: "0px !important",
                        color: '#FBBF6D !important',
                        fontWeight: 700,
                        "& > abbr":{
                            color: '#00B0AE !important',
                            background: '#D4EEEE',
                            fontWeight: 700,
                            minWidth: 24,
                            height: 24,
                            padding:4,
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: "50%",
                            marginTop: '0px',
                            alignItems: 'center',
                        },
                    },
                },
                
                "& .react-calendar__month-view__days__day--weekend":{
                    color: `rgba(0, 0, 0, 0.87)`,
                },
                "& .react-calendar__month-view__days__day--neighboringMonth":{
                    color: 'rgba(0, 0, 0, 0.38)'
                }
            },
            
            "& .react-calendar__year-view__months__month":{
                borderRadius: 0,
                padding: '1.3em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '80px',
                "&:hover":{
                    borderRadius: "0px !important",
                }
            },
            "& .react-calendar__year-view":{
                borderRadius: "0px !important",
                "& .react-calendar__tile--now":{
                    background: '#F9F9F9',
                    border: '1px solid #000',
                },
                "& .react-calendar__tile--hasActive":{
                    background: "#26ACA9",
                    color: "#fff",
                    borderRadius: "0px !important",
                },
            },
            "& .react-calendar__decade-view":{
                "& .react-calendar__tile--now":{
                    background: '#F9F9F9',
                    border: '1px solid #000',
                },
                "& .react-calendar__tile--hasActive":{
                    background: "#26ACA9",
                    color: "#fff",
                }
            },
            "& .react-calendar__century-view__decades__decade":{
                borderRadius: 0,
                padding: '1.3em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '80px',
                "&:hover":{
                    borderRadius: "0px !important",
                },
            },

            "& .react-calendar__decade-view__years__year":{
                borderRadius: 0,
                padding: '1.3em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '80px',
                "&:hover":{
                    borderRadius: "0px !important",
                },
            },
            "& .react-calendar__century-view":{
                borderRadius: "0px !important",
                "& .react-calendar__tile--hasActive":{
                    background: "#26ACA9 !important",
                    color: "#fff",
                    borderRadius: "0px !important",
                },
                "& .react-calendar__tile--now":{
                    background: '#F9F9F9',
                    border: '1px solid #000',
                    borderRadius: "0px !important",
                },
                "&:hover":{
                    borderRadius: "0px !important",
                },
            }

        },
        "& .react-calendar__month-view__weekdays__weekday":{
            "& > abbr":{
                textDecoration: 'none !important',
            },
        },
        "& .react-calendar__month-view__weekdays": { //dias da semana
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: 'normal !important',
            "& .react-calendar__month-view__weekdays__weekday":{
                marginLeft: '-1px',
                padding: '0.1em',
            },
        },
        "& .react-calendar__navigation": {
                display: 'grid !important',
                gridTemplateColumns: '1fr 20% 20%',
                gridTemplateAreas: `"label arrowLeft arrowRight"`,
                marginBottom: 0,
                marginLeft: 6,
                height: 40,
                
            "& .react-calendar__navigation__arrow": {
                color: "rgba(0, 0, 0, 0.6)",
            },

            "& .react-calendar__navigation__prev-button":{
                gridArea: "arrowLeft",
            },
            "& .react-calendar__navigation__label": { //mês
                color: "rgba(0, 0, 0, 0.6)",
                textTransform: 'capitalize',
                fontWeight: 500,
                gridArea: "label",
                justifySelf: 'flex-start',
            },
            "& .react-calendar__navigation__next-button":{
                gridArea: "arrowRight",
            }
        },
        "& .react-calendar__navigation__label":{
            width: '100%',
            textAlign: 'start',
        },
        "& .react-calendar__navigation__label__labelText--from":{
            fontSize: 14,
            color: "#26ACA9"
        }
    }
});

const WeekPickerWithStyles = withStyles(styles)(WeekPicker);
export default inject("atendimentoStore")(WeekPickerWithStyles);
