import { withStyles } from "@material-ui/core/es";
import { inject } from "mobx-react";
import React from "react";
import classNames from "classnames";
import { Delete, Edit } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import string from "../../../utils/string";
import styles from "./styles";
import { observer } from "mobx-react-lite";
import PrintIcon from "../../../components/Icon/Print";

const ModalHeader = observer((props) => {
  const { classes, extratoStore, configuracaoImpressaoStore } = props;
  const { titulo, saving, loadingButtonActionModal } = extratoStore;

  const handleConfirmDelete = async (e) => {
    try {
      extratoStore.loadingButtonActionModal = true;
      e.preventDefault();

      if (titulo.id) {
        await extratoStore.inativarTitulo(titulo.titulo?.id || titulo.id);
      }

      extratoStore.closeModal();
      extratoStore.tituloList = [];
      extratoStore.currentPage = null;
      extratoStore.searchDTO.pageNumber = 0;
      extratoStore.findAll();
    } catch (error) {
      extratoStore.openNotification(error.message, 'error');
    } finally {
      extratoStore.modalConfirmarExcluir.open = false;
      extratoStore.loadingButtonActionModal = false;
    }
  };

  const handleDelete = (e) => {
    extratoStore.modalConfirmarExcluir={
      open: true,
      buttons: {
        ...extratoStore.modalConfirmarExcluir.buttons,
        handlePrimaryButton: handleCloseModalConfirmarExcluir,
        handleSecondButton: () => handleConfirmDelete(e),
      }
    }
  };

  const handleCloseModalConfirmarExcluir = () => {
    extratoStore.modalConfirmarExcluir.open = false;
  };

  const handleEdit = () => {
    extratoStore.editMode = true;
  };
  
  const handlePrintRecibo = async () => {
    try {
      extratoStore.loadingButtonActionModal = true;
      await configuracaoImpressaoStore.getConfig();

      const dadosTitulo = titulo.titulo;
      extratoStore.dadosTituloPrint = dadosTitulo;
      extratoStore.dadosParaImpressaoRecibo = dadosTitulo;
      extratoStore.printingRecibo = true;

      extratoStore.isPrintMustacheModal = true;
    } finally {
      extratoStore.loadingButtonActionModal = false;
    }
  };

  return (
    <div className={classes.dialogTitle}>
      <div className={classes.tituloModal}>
        {titulo.tipo ? string.capitalize(titulo.tipo) : ""}
      </div>
      <div className={classes.contentButton}>
        {titulo.id && (
          <Fab
            disabled={saving || loadingButtonActionModal}
            className={classNames(classes.buttonHeader, classes.buttonEdit)}
            onClick={handleEdit}
          >
            <Edit />
          </Fab>
        )}
        {titulo?.id && titulo?.dataPagamento && titulo?.tipoRepeticao === "NAO_REPETIR" && (
          <Fab
            onClick={handlePrintRecibo}
            disabled={saving || loadingButtonActionModal}
            className={classNames(classes.buttonHeader, classes.buttonPrint)}
          >
            <PrintIcon/>
          </Fab>
        )}
        {!titulo.dataPagamento && titulo.id && (
          <Fab
            disabled={saving || loadingButtonActionModal}
            className={classNames(classes.buttonHeader, classes.buttonExcluir)}
            onClick={handleDelete}
          >
            <Delete />
          </Fab>
        )}
      </div>
    </div>
  );
});

const ModalHeaderWithStyles = withStyles(styles)(ModalHeader);
export default inject(
  "extratoStore",
  "configuracaoImpressaoStore"
)(ModalHeaderWithStyles);
