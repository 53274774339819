import React, { Suspense, useEffect, useReducer, useState } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'

import TabSelector from '../../../../../../components/TabSelector'

import { findAllControleSessaoAuditBySujeitoAtencaoId } from '../../../../../../services/AuditoriaService'

import ModalEditSession from './Modals/EditSession'
import ModalCancelSession from './Modals/CancelSession'

import { Button } from '../../../../../../components/ui/Buttons'

const TableTab = React.lazy(() => import('./TableTab'))
const AuditoriaTab = React.lazy(() => import('../../../../../../components/Auditoria/Auditoria'))

const TABS_OPTIONS = [
  {
    label: 'Informações',
  },
  {
    label: 'Auditoria',
  },
]

const SessoesContent = observer(
  ({
    classes,
    auditoriaStore,
    controleSessaoStore,
    handleRedirect,
    history,
    handleOpenModalCreate,
  }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [pacoteSelecionado, setPacoteSelecionado] = useState(null)
    const [openModalCancel, toggleModalCancel] = useReducer(state => !state, false)

    const [openModalEdit, toggleModalEdit] = useReducer(state => !state, false)

    const idSujeitoAtencao = window.location.href.split('/')[6]

    const loadAudit = async () => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoriaTela: 'pacoteSessoes',
        auditoria: `o pacote de sessões`,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
      }

      auditoriaStore.load = {
        query: findAllControleSessaoAuditBySujeitoAtencaoId,
        variableName: 'sujeitoAtencaoId',
        variableId: idSujeitoAtencao,
      }
      await auditoriaStore.loadAuditItems()
    }

    const handleOpenModalEdit = id => {
      toggleModalEdit()
      setPacoteSelecionado(id)
    }

    const handleOpenModalCancel = id => {
      toggleModalCancel()
      setPacoteSelecionado(id)
    }

    const handleLoadAllPacotesSessoes = () => {
      controleSessaoStore.resetParamsPacote()
      controleSessaoStore.loadAllPacotesSessoes({
        idSujeitoAtencao,
      }, true)
    }
    const tabsOptions = {
      0: (
        <TableTab
          toggleModalCancel={handleOpenModalCancel}
          handleRedirect={handleRedirect}
          handleOpenModalEdit={handleOpenModalEdit}
          history={history}
        />
      ),
      1: (
        <div className={classes.wrapperAudit}>
          <AuditoriaTab />
        </div>
      ),
    }

    const selectedTabComponent = tabsOptions[selectedTab]
    const emptySessions = controleSessaoStore.totalElements === 0

    useEffect(() => {
      if (selectedTab === 1) {
        loadAudit()
      }
    }, [selectedTab])

    return (
      <div className={classes.content}>
        {!emptySessions ? (
          <>
            <div className={classes.tabsArea}>
              <TabSelector
                baseColor='#F2F2F2'
                selectedColor='#FFFFFF'
                onSelect={setSelectedTab}
                tabsOptions={TABS_OPTIONS}
                selectedTabIndex={selectedTab}
              />
            </div>
            <Suspense
              fallback={
                <div className={classes.wrapperLoading}>
                  <CircularProgress size={32} />
                </div>
              }
            >
              {selectedTabComponent}
            </Suspense>
            {openModalEdit && (
              <ModalEditSession
                open={openModalEdit}
                onClose={toggleModalEdit}
                idPacote={pacoteSelecionado}
                loadAllPacotesSessoes={handleLoadAllPacotesSessoes}
              />
            )}
            {openModalCancel && (
              <ModalCancelSession
                open={openModalCancel}
                onClose={toggleModalCancel}
                idPacote={pacoteSelecionado}
              />
            )}
          </>
        ) : (
          <>
            <div className={classes.containerEmpty}>
              <h1>Nenhuma sessão foi criada</h1>
              <div className={classes.wrapperActions}>
                <Button kind='primary' onClick={() => handleOpenModalCreate('CONSULTA')}>
                  Nova consulta
                </Button>
                <Button kind='secondary' onClick={() => handleOpenModalCreate('PROCEDIMENTO')}>
                  Novo procedimento
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
)

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    width: '100%',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    backgroundColor: '#FFF',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    maxHeight: 'calc(100vh - 72px)',
  },
  tabsArea: {
    padding: '18px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  wrapperAudit: {
    width: '100%',
    overflow: 'auto',
    height: 'calc(100% - 72px)',
  },
  containerEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    height: '100%',

    '& h1': {
      fontSize: '24px',
      fontWeight: '400',
    },
  },
  wrapperActions: {
    display: 'flex',
    gap: '16px',
  },
  wrapperLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
  },
}

const SessoesContentStyles = withStyles(styles)(SessoesContent)
const stores = ['auditoriaStore', 'controleSessaoStore']
export default inject(...stores)(SessoesContentStyles)
