import { gridContainer } from "../appHealth.style"

const styles = theme => ({
    root: {
        ...gridContainer,
        overflow: "hidden",
    },

    rootWithoutSidebar: {
        display: "block"
    },

    panelLeft: {
        padding: 0,
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(38, 172, 169, 0.04), 0px 5px 25px rgba(38, 172, 169, 0.04)',

    },

    titleHeader: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.commons.padrao
    },

    footer: {
        gridArea: "footer",
        paddingLeft: 15,
        paddingRight: 45,
    },
});

export default styles;
