import React from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    RootRef,
    Checkbox
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { withStyles } from "@material-ui/core/styles/index";

const reordenarItens = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class DragAndDropList extends React.Component {
    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    handleChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked });
    };

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const selectedCampoProntuarioList = reordenarItens(
            this.props.selectedCampoProntuarioList,
            result.source.index,
            result.destination.index
        ).map((item, index) => ({ ...item, ordem: index + 1}));

        this.props.handleChangeCampoProntuarioList(selectedCampoProntuarioList);
    }

    handleClickItem(value) {
        this.props.handleChangeCheckbox(value)
    }

    render() {
        const { classes, selectedCampoProntuarioList } = this.props;
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <RootRef rootRef={provided.innerRef}>
                            <List>
                                {selectedCampoProntuarioList.map((item, index) => (
                                    <Draggable key={item.campo} draggableId={item.campo} index={index} isDragDisabled={item.disabled} >
                                        {(provided) => (
                                            <ListItem
                                                className={classes.listItem}
                                                ContainerComponent="li"
                                                ContainerProps={{ ref: provided.innerRef }}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                disabled={item.disabled}

                                            >
                                                <DragHandleIcon
                                                    classes={
                                                        { root: classes.dragHandleIcon }
                                                    }
                                                />
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={true}
                                                        onClick={() => this.handleClickItem(item)}
                                                        disabled={item.disabled}
                                                        marginRight={0}
                                                        classes={{
                                                            root: classes.checkbox,
                                                            checked: classes.checked,
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <p className={classes.boxWhiteTitle}>{item.nome}</p>
                                                <ListItemSecondaryAction />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

const styles = {
    checkbox: {
        color: "#000",
        '&$checked': {
            color: "#26ACA9",
        },
    },
    checked: {},
    dragHandleIcon: {
        color: '#000'
    },
    listItem: {
        paddingBottom: '0px',
        '&>div':{
            margin: '0 8px',
        }
    },
    boxWhiteTitle: {
        fontSize: 14,
        fontWeight: 500,
        textAlign: "center",
        color: "#7C7C7C",
        marginTop: 5,
        marginBottom: 5,
    },
};

export default withStyles(styles)(DragAndDropList);

