import React, { useEffect, useState } from "react";
import { Grid, Checkbox, withStyles } from "@material-ui/core";
import { findAllProcedimentoPedidoSujeitoAtencao } from "../../../services/AgendamentoService";
import { inject } from "mobx-react";

const TabProcedimentoPedidoFaturado = ({ classes, atendimentoStore }) => {
  const [procedimentosFaturado, setProcedimentosFaturado] = useState([]);

  useEffect(() => {
    loadProcedimentosFaturado();
  }, []);

  const loadProcedimentosFaturado = async () => {
    const response = await findAllProcedimentoPedidoSujeitoAtencao({
      idSujeitoAtencao: atendimentoStore.objView?.sujeitoAtencao?.id,
      agendado: false,
      pageableDTO: {
        pageNumber: 0,
        pageSize: 30,
      },
    });

    let procedimentosSolicitados = [];

    response.content.forEach((item) => {
      const procedimentosFaturados =
        item.procedimentosSolicitadosFaturadosNaoAgendados;

      procedimentosFaturados.forEach((item) => {
        procedimentosSolicitados.push({
          ...item,
          checked: false,
        });
      });
    });

    setProcedimentosFaturado(procedimentosSolicitados);
  };

  const handleChangeCheckbox = (item) => {
    const procedimentosFaturadoCopy = [...procedimentosFaturado];
    const index = procedimentosFaturadoCopy.indexOf(item);
    procedimentosFaturadoCopy[index] = {
      ...procedimentosFaturadoCopy[index],
      checked: !procedimentosFaturadoCopy[index].checked,
    };
    setProcedimentosFaturado(procedimentosFaturadoCopy);

    if (item.checked) {
      const procedimentos = atendimentoStore.objView.procedimentos.filter(
        (procedimento) => procedimento.procedimentoSolicitado?.id !== item?.id
      );

      atendimentoStore.objView.procedimentos = [...procedimentos];
    } else {
      const procedimentos = atendimentoStore.objView?.procedimentos?.length > 0 ?
        [
          ...atendimentoStore.objView.procedimentos,
          {
            procedimento: item.procedimento,
            procedimentoSolicitado: { id: item.id },
          },
        ]
        :
        [{
          procedimento: item.procedimento,
          procedimentoSolicitado: { id: item.id },
        }]
        
      atendimentoStore.objView.procedimentos = procedimentos;
    }
  };

  return (
    <div>
      {procedimentosFaturado.map((item) => (
        <Grid
          container
          direction={"row"}
          wrap={"nowrap"}
          key={item.id}
          className={classes.procedimentoCheckBox}
        >
          <Grid item>
            <Checkbox
              color={"primary"}
              onChange={() => handleChangeCheckbox(item)}
              checked={item.checked}
              disabled={item.agendado}
            />
          </Grid>
          <Grid item size={5}>
            {item?.procedimento?.nome}
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const styles = {};

const TabProcedimentoPedidoFaturadoWithStyles = withStyles(styles)(
  TabProcedimentoPedidoFaturado
);
export default inject("atendimentoStore")(
  TabProcedimentoPedidoFaturadoWithStyles
);
