import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const UnlockIcon = (props) => {
  return (
    <svg 
      width="16" 
      height="16" 
      viewBox="0 0 16 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M7.8 1.80078C8.82505 1.80078 9.72669 2.44948 10.0628 3.40063C10.1732 3.71307 10.0094 4.05585 9.69694 4.16625C9.40682 4.26876 9.09054 4.13486 8.95844 3.86505L8.93132 3.80042C8.76341 3.3252 8.31249 3.00078 7.8 3.00078C7.16771 3.00078 6.6497 3.49005 6.60391 4.11065L6.60062 4.20016L6.6006 5.39958L11.25 5.40016C11.9645 5.40016 12.5494 5.95526 12.5969 6.65773L12.6 6.75016V12.4479C12.6 13.1624 12.0449 13.7473 11.3424 13.7948L11.25 13.7979H4.35C3.63548 13.7979 3.05061 13.2428 3.00311 12.5404L3 12.4479V6.75016C3 6.03564 3.5551 5.45077 4.25757 5.40327L4.35 5.40016L5.4006 5.39958L5.40062 4.20016C5.40062 2.87502 6.47486 1.80078 7.8 1.80078ZM7.8 8.69987C7.3034 8.69987 6.90083 9.10245 6.90083 9.59904C6.90083 10.0956 7.3034 10.4982 7.8 10.4982C8.2966 10.4982 8.69917 10.0956 8.69917 9.59904C8.69917 9.10245 8.2966 8.69987 7.8 8.69987Z" fill="currentColor" />
    </svg>

  );
};

export default UnlockIcon;
