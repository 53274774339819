import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography, withStyles } from '@material-ui/core'
import InputSelectComLabel from '../../../../../components/Input/InputSelectComLabel'
import { InputForm } from '../../../../../components/Input/InputDadosCadastraisPaciente'
import styles from '../GuiaDeConsultaModalStyles'
import { ausenciaCodigosValidacao } from './Constants'

const StepValidacao = props => {
  const { classes, handleChangeGuiaConsulta, guiaConsulta } = props

  const handleChange = (field, value, minLength) => {
    if (value.length <= minLength) {
      handleChangeGuiaConsulta(field, value)
    }
  }

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <InputSelectComLabel
            label='Ausencia código validação'
            value={guiaConsulta?.ausenciaCodigoValidacao}
            className={classes.inputContainer}
            onChange={e => handleChangeGuiaConsulta('ausenciaCodigoValidacao', e)}
            isSelect
            options={ausenciaCodigosValidacao || []}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
          />
        </Grid>
        <Grid item xs={6}>
          <InputSelectComLabel
            label='Código de validação'
            value={guiaConsulta?.codigoValidacao}
            classes={{
              input: classes.inputContainer,
            }}
            onChange={({target: {value}}) => handleChange('codigoValidacao', value, 10)}
          />
        </Grid>
      </Grid>
      <Typography component='label' className={classes.typography}>
        Observação:
      </Typography>
      <InputForm
        id='observacao'
        className={classes.inputObservacao}
        rowsMax={9}
        rows={9}
        multiline
        value={guiaConsulta?.observacao}
        onChange={({target: {value}}) => handleChange('observacao', value, 500)}
        maxLength={500}
      />
    </div>
  )
}

export default withStyles(styles)(StepValidacao)
