import string from "./string";

const cidadeFormatada = ({municipio, uf, espanhaMunicipio}) => {
    const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);

    if((municipio || espanhaMunicipio) && uf) {
        return unidadeLogada?.rede?.paisAtuacao?.descricao === "ESPANHA" ? string.capitalize(espanhaMunicipio) : `${municipio} - ${uf}`
    }
    return "-";
};

export default cidadeFormatada;