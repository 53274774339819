import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'

import RoundButton from '../../components/Button/RoundButton'
import { Button } from '../../components/ui/Buttons'
import { theme } from '../../template/Theme'

import VideoIcon from '../../components/Icon/Video'

import { Tooltip } from '@material-ui/core'
import { VideocamOffOutlined } from '@material-ui/icons'
import { getViziWebUrl } from '../../config/config'

const TeleconsultaButton = props => {
  const { prontuarioStore, classes } = props
  const isOpenWindowAtendimentoChime = localStorage.getItem('isOpenWindowAtendimentoChime')
  const [windowTeleconsulta, setWindowTeleconsulta] = useState(undefined)


  const buildLinkTelemedicina = () => {
    if(!prontuarioStore.agendamentoSelected) return

    return `${getViziWebUrl()}/telemedicina/${prontuarioStore.agendamentoSelected?.chavePublica}/profissional`
  }

  const isEndedParamInWindowPath = (currWindow) => {
    try{
      return currWindow.location.pathname.includes('ended')
    } catch (e) {
      return false
    }
  }

  const onClickCloseTeleconsulta = async () => {
    props.eventoGA('Prontuario', 'Consultar paciente', 'Encerrar teleconsulta')

    if (windowTeleconsulta) {
      windowTeleconsulta.close()
    } else {
      const newWindow = window.open(
        buildLinkTelemedicina(),
        'Teleconsulta',
        'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=900,height=600, top=100, left=200'
      )
      await window.close()
      newWindow.close()
    }
    prontuarioStore.closeWindowAtendimentoChime()
    prontuarioStore.openModalConfirmarTempoTeleconsulta()
  }

  const onClickIniciarTeleconsulta = async e => {
    const verificarTermosUsoAssinados = await props.verificarTermosUsoAssinados()

    if (verificarTermosUsoAssinados.length) {
      return
    }
    props.eventoGA('Prontuario', 'Consultar paciente', 'Iniciar teleconsulta')
    const dadosTeleconsultaAtiva = {
      prontuarioId: prontuarioStore.agendamentoSelected?.id,
      sujeitoAtencaoId: prontuarioStore.agendamentoSelected?.sujeitoAtencao?.id,
    }

    localStorage.setItem('dadosTeleconsulta', JSON.stringify(dadosTeleconsultaAtiva))

    const newWindow = window.open(
      buildLinkTelemedicina(),
      'Teleconsulta',
      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=900,height=600, top=100, left=200'
    )
    setWindowTeleconsulta(newWindow)

    prontuarioStore.openWindowAtendimentoChime()

    const popupTick = setInterval(() => {
      const isEndedParamPresent = isEndedParamInWindowPath(newWindow)

      if (newWindow && (newWindow.closed || isEndedParamPresent)) {
        clearInterval(popupTick)
        prontuarioStore.closeWindowAtendimentoChime()
        prontuarioStore.openModalConfirmarTempoTeleconsulta()
      }
    }, 500)
  }

  if (isOpenWindowAtendimentoChime === 'true') {
    return (
      <Tooltip title='Encerrar teleconsulta' placement='bottom'>
        <Button
          kind='primary'
          shape='circle'
          size='default'
          bgColor='#9B69FF'
          onClick={onClickCloseTeleconsulta}
        >
          <VideocamOffOutlined />
        </Button>
      </Tooltip>
    )
  }

  return (
    <RoundButton onClick={onClickIniciarTeleconsulta} marginLeft>
      <VideoIcon />
      <p className={classes.labelButtonTeleconsulta}>Teleconsulta</p>
    </RoundButton>
  )
}

const styles = {
  buttonHeader: {
    marginLeft: '20px',
    boxShadow: 'none',
    '& svg': {
      marginRight: '10px',
    },
  },
  dialog: {
    minHeight: '90vh',
    maxHeight: '90vh',
    borderRadius: 20,
  },
  labelButtonTeleconsulta: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  wrapperTimeAtendimento: {
    width: '50px',
    padding: '0px 12px',
  },
}

TeleconsultaButton.propTypes = {
  sujeitoAtencao: PropTypes.shape({ nome: PropTypes.string }).isRequired,
  classes: PropTypes.object.isRequired,
  onChangeTeleconsultaEncerrado: PropTypes.func.isRequired,
  onChangeTempoTeleconsulta: PropTypes.func.isRequired,
}

export default withStyles(styles)(inject('prontuarioStore')(TeleconsultaButton))
