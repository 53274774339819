import moment from 'moment';

export const createValidacaoHorariosVariables = ({ horaInicio, horaFim, data, profissionalSaudeId }) => {
  const { dataInicial, dataFinal } = data;
  const dataInicialMoment = moment(dataInicial);
  const dataFinalMoment = moment(dataFinal);

  if (dataInicialMoment.isSame(dataFinalMoment)) {
    return [{
      horaInicio: horaInicio,
      horaFim: horaFim,
      data: dataInicialMoment.format("YYYY-MM-DD"),
      profissionalSaude: {
        id: profissionalSaudeId
      }
    }];
  } else {
    const dataAux = dataInicialMoment.clone();
    const periodos = [];
    while (dataAux.isBefore(dataFinalMoment)) {
      periodos.push({
        horaInicio: horaInicio,
        horaFim: horaFim,
        data: dataAux.format("YYYY-MM-DD"),
        profissionalSaude: {
          id: profissionalSaudeId
        }
      });
      dataAux.add(1, 'days');
    }

    return periodos;
  }
}
