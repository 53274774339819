import React from "react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles/index";
import colors from "../../template/Colors";

const StatusAgendamento = (props) => {
    const { status, classes } = props;

    const color = () => {
        const statusAgendamento = status.toLowerCase();

        const styleAgendamento = {
            backgroundColor: colors.statusLight[statusAgendamento],
            border: `1px solid ${colors.status[statusAgendamento]}`,
        }

        return styleAgendamento
    };

    return(
        <div className={classnames(classes.statusAgendamento)} style={color()}>
            { status }
        </div>
    )
};

const style = {
    statusAgendamento: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        color: "#868686",
        padding: '4px 8px',
        fontSize: '13px',
    },
}

export default withStyles(style)(StatusAgendamento);