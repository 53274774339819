import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import styles from "../../../../assets/jss/pages/configuracaoReceitaStyle";
import string from "../../../../utils/string";
import EditarConfigReceita from "./EditarConfigReceita";
import TamanhoPapel from "./TamanhoPapel";
import Orientacao from "./Orientacao";
import Margens from "./Margens";
import PosicaoAssinaturaDigital from "./PosicaoAssinaturaDigital";
import PreVisualizacao from "./PreVisualizacao";

const InformacoesConfiguracaoReceita = observer((props) => {
  const {
    classes,
    configuracaoImpressaoStore,
    isProfissionalSaudeSelecionado,
  } = props;
  const {
    configuracaoImpressao,
    loadingConfig,
  } = configuracaoImpressaoStore;

  const largura = string.numberMaskToFloat(configuracaoImpressao.largura);
  const altura = string.numberMaskToFloat(configuracaoImpressao.altura);

  const showPreview = !!(largura && altura);

  return (
    <>
      {!loadingConfig && isProfissionalSaudeSelecionado && (
        <Grid container className={classes.formContainer}>
          <Grid item xs={8} lg={6}>
            <TamanhoPapel/>
            <Orientacao/>
            <Margens/>
            <PosicaoAssinaturaDigital/>
          </Grid>
          <Grid item xs={4} lg={6}>
            {showPreview && <PreVisualizacao/>}
          </Grid>
          <EditarConfigReceita />
        </Grid>
      )}
    </>
  );
});

const InformacoesConfiguracaoReceitaWithStyles = withStyles(styles)(
  InformacoesConfiguracaoReceita
);
export default inject("configuracaoImpressaoStore", "filtroHeaderStore")(
  InformacoesConfiguracaoReceitaWithStyles
);
