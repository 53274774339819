import React from "react";
import moment from "moment";
import { formattedPhone } from "../../../components/Input/TelefoneFormatado";


const UltimosAtendimentos = ({ dadosRelatorio, filters }) => {
  const { periodo, nomeSujeitoAtencao } = filters;

  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" }
    }
    return ''
  }

  return (
    <>
      <div style={styles.titulo}>Relatório de Procedimentos solicitados</div>
      <div style={styles.subTitulo}>
        {nomeSujeitoAtencao && (
          <div>
            Pesquisar:
            <span>{nomeSujeitoAtencao}</span>
          </div>
        )}
        {periodo && (
          <div>
            Período:
            <span>{periodo.name}</span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Paciente</div>
        <div style={styles.column}>Idade</div>
        <div style={styles.column}>Telefone</div>
        <div style={styles.column}>E-mail</div>
        <div style={styles.column}>Cidade</div>
        <div style={styles.column}>Último atendimento</div>
        <div style={styles.column}>Convênio</div>

      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          const { nome, idade, contatoTelefonePrincipal, contatoTelefonePrincipalDdi, contatoEmail, nomeMunicipio, descricaoConvenio, dataUltimoAtendimento } = item || {};
          return (
            <div key={item.id} style={{ ...styles.containerColuna, ...columnColor(index) }}>
              <div style={styles.column}>{nome}</div>
              <div style={styles.column}>{idade}</div>
              <div style={styles.column}>{formattedPhone({
                telefone: contatoTelefonePrincipal,
                telefoneDDI: contatoTelefonePrincipalDdi
              })}</div>
              <div style={{
                ...styles.column,
                wordBreak: 'break-word'
              }}>
                {contatoEmail}</div>
              <div style={styles.column}>{nomeMunicipio}</div>
              <div style={styles.column}>
                {dataUltimoAtendimento ? moment(dataUltimoAtendimento).format("DD/MM/YYYY") : ""}
              </div>
              <div style={styles.column}>{descricaoConvenio}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
    gap: "8px",
  },

  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  column: {
    width: "100%",
    textAlign: "left"
  },
};

export default UltimosAtendimentos;
