import React from 'react'
import OpenIcon from '../../../../components/Icon/Open'
import TrashIcon from '../../../../components/Icon/Trash'
import PrintIcon from '../../../../components/Icon/Print'
import PencilIcon from '../../../../components/Icon/Pencil'
import ArrowDownloadIcon from '../../../../components/Icon/ArrowDownload'

export const menuOpcoesPopper = options => {
  const { type, functions, selectedTab } = options;
  const isDocument = type === 'ARQUIVO' || type === 'IMAGEM'
  const optionsMenu = [
    {
      ativo: type === 'ANAMNESE',
      label: 'Editar',
      onClick: () => functions?.handleEdit(),
      icon: <PencilIcon size='24' color='#505050' />,
    },
    {
      ativo: type === 'ANAMNESE',
      label: 'Imprimir',
      onClick: () => functions?.handlePrint(),
      icon: <PrintIcon size='24' color='#505050' />,
    },
    {
      ativo: isDocument,
      label: 'Baixar',
      onClick: functions?.handleDownload,
      icon: <ArrowDownloadIcon size='24' color='#505050' />,
    },
    {
      ativo: isDocument,
      label: 'Abrir em nova guia',
      onClick: functions?.handleOpenNewTab,
      icon: <OpenIcon size='24' color='#505050' />,
    },
    {
      ativo: selectedTab === 0,
      label: 'Descartar',
      onClick: functions?.handleDiscardSolicitacao,
      icon: <TrashIcon size='24' color='#505050' />,
    },
  ]
  return optionsMenu
}
