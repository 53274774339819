import React from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core";
import colors from "../../../../template/Colors";
import Grid from "@material-ui/core/Grid";
import { applyInternationalPhoneMask, applyPhoneMask } from "../../../../utils/PhoneMask";
import string from "../../../../utils/string";
import dates from "../../../../utils/dates";
import CardItemList from "../../../../components/List/CardItemList";

const CardPaciente = (props) => {
  const { classes, sujeitoAtencao, unidadeStore, onClickPaciente } = props;

  return (
    <div onClick={() => onClickPaciente(sujeitoAtencao)}>
    <Grid container className={classes.card}>
      <CardItemList
        size={9}
        field={"Nome:"}
        value={sujeitoAtencao.nome}
        classes={{
          listItem: classes.listItemMargin,
          itemTitle: classes.colorItemTitle,
          itemValueSub: classes.colorItemValue,
        }}
      />

      <CardItemList
        size={3}
        field={"Idade:"}
        value={sujeitoAtencao.dataNascimento ? dates.calculaIdade(sujeitoAtencao.dataNascimento) : " - "}
        classes={{
          listItem: classes.listItemMargin,
          itemTitle: classes.colorItemTitle,
          itemValueSub: classes.colorItemValue,
        }}
      />

      <CardItemList
        size={6}
        field={"Telefone:"}
        value={
          unidadeStore.unidade.utilizaTelefoneInternacional
            ? applyInternationalPhoneMask(
                sujeitoAtencao?.contato?.telefonePrincipal,
                sujeitoAtencao?.contato?.telefonePrincipalDDI
              )
            : applyPhoneMask(sujeitoAtencao?.contato?.telefonePrincipal)
        }
        classes={{
          listItem: classes.listItemMargin,
          itemTitle: classes.colorItemTitle,
          itemValueSub: classes.colorItemValue,
        }}
      />

      <CardItemList
        size={6}
        field={"CPF:"}
        value={
          sujeitoAtencao?.cpf
            ? string.format("###.###.###-##", sujeitoAtencao.cpf)
            : " - "
        }
        classes={{
          listItem: classes.listItemMargin,
          itemTitle: classes.colorItemTitle,
          itemValueSub: classes.colorItemValue,
        }}
      />
    </Grid>
    </div>
  );
};

const styles = {
  content: {
    width: "100vw",
    height: "100%",
    background: colors.commons.padrao,
    padding: "19px 18px 11px 16px",
    maxWidth: "301px",
  },
  card: {
    padding: '8px',
    marginBottom: '8px',
    borderRadius: '10px',
    boxShadow: '0px 2px 5px rgb(0 0 0 / 8%)',
    cursor: 'pointer',
    margin: '4px',
    width: 'auto',
  }
};

const CardPacienteWithStyles = withStyles(styles)(CardPaciente);
export default inject("unidadeStore")(CardPacienteWithStyles);
