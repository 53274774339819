import Api from "../config/api";

export const findAllPacienteAnexo = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($search: String, $pageableDTO: PageableDTOInput, $dataHoraInicial: LocalDateTime, $dataHoraFinal: LocalDateTime, $prontuarioGerado: Boolean, $descartado: Boolean, $agendamentoId: Long) {
        findAllPacienteAnexo(search: $search, pageableDTO: $pageableDTO, dataHoraInicial: $dataHoraInicial, dataHoraFinal: $dataHoraFinal, prontuarioGerado: $prontuarioGerado, descartado: $descartado, agendamentoId: $agendamentoId) {
          last
          content {
            id
            dataHoraLancamento
            arquivoTipo
            descartado
            urlDownload
            amazonS3Objeto {
              extensao
              id
              nome
              nomeComExtensao
              tamanho
            }
            sujeitoAtencao {
              id
              nome
            }
            agendamento {
              id
              sujeitoAtencao {
                id
                nome
              }
              paciente {
                id
                nome
              }
            }
          }
        }
      }           
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllPacienteAnexo;
};

export const findByIdPacienteAnexo = async (id) => {
  const response = await Api.post("", {
    query: `
      query ($id: UUID) {
        findByIdPacienteAnexo(id: $id) {
          id
          arquivoTipo
          urlDownload
          descartado
          agendamento {
            id
            sujeitoAtencao {
              id
            }
            paciente {
              id
            }
          }
          amazonS3Objeto {
            extensao
            id
            nome
            nomeComExtensao
            tamanho
          }
        }
      }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findByIdPacienteAnexo;
};

export const anexarSolicitacoesPaciente = async (
  variables,
  tipoSolicitacao
) => {
  const response = await Api.post("", {
    query: `
      mutation ($pacienteAnexoId: UUID, $agendamentoId: Long) {
        createEntradaProntuarioByPaciente${tipoSolicitacao}(pacienteAnexoId: $pacienteAnexoId, agendamentoId: $agendamentoId) {
          id
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data[
    `createEntradaProntuarioByPaciente${tipoSolicitacao}`
  ];
};

export const descartarSolicitacoesPaciente = async (id) => {
  const response = await Api.post("", {
    query: `
      mutation ($id: UUID) {
        descartarPacienteAnexo(id: $id)
      }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.descartarPacienteAnexo;
};

export const findByIdAnamnese = async (id) => {
  const response = await Api.post("", {
    query: `
    query ($id: UUID) {
      findByIdAnamnese(id: $id) {
        id
        nome
        prontuarioGerado
        dataHoraLancamento
        tipoEntradaProntuario
        descartado
        agendamento {
          id
        }
        sujeitoAtencao {
          id
          nome
        }
        perguntas {
          id
          pergunta
          tipo
          respostas {
            id
            nome
            valor
            valorConvertido
          }
        }
      }
    }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findByIdAnamnese;
};

export const listarAnamneseSolicitada = async variables => {
  const response = await Api.post('', {
    query: `
    query ($dataHoraInicial: LocalDateTime, $dataHoraFinal: LocalDateTime, $descartado: Boolean, $pageableDTO: PageableDTOInput, $search: String, $prontuarioGerado: Boolean, $agendamentoId: Long) {
      findAnamneseByAgendamento(dataHoraInicial: $dataHoraInicial, dataHoraFinal: $dataHoraFinal, descartado: $descartado, pageableDTO: $pageableDTO, search: $search, prontuarioGerado: $prontuarioGerado, agendamentoId: $agendamentoId) {
        last
        content {
          id
          tipoEntradaProntuario
          descartado
          agendamento {
            id
            sujeitoAtencao {
              id
              nome
            }
            paciente {
              id
              nome
              documento
              dataNascimento
              fotoPerfil
              telefonePrincipal
            }
          }
          dataHoraLancamento
          unidade {
            id
            nome
          }
          profissionalSaude {
            id
            nome
          }
          sujeitoAtencao {
            id
            nome
          }
        }
      }
    }
    `,
    variables,
  })
  return response.data.data.findAnamneseByAgendamento
}

export const getQuantidadeTotalSolicitacoes = async(dataHoraInicial, dataHoraFinal) => {

  const response = await Api.post('', {
    query: `
      query($dataHoraInicial: LocalDateTime, $dataHoraFinal: LocalDateTime) {
        totalPacienteAnexo(dataHoraInicial: $dataHoraInicial, dataHoraFinal: $dataHoraFinal)
        totalAnamnese(dataHoraInicial: $dataHoraInicial, dataHoraFinal: $dataHoraFinal, possuiAgendamento: true)
      }
    `,
    variables: {
      dataHoraInicial,
      dataHoraFinal
    }
  });
  const { totalPacienteAnexo, totalAnamnese } = response?.data?.data;
  
  return totalPacienteAnexo + totalAnamnese;
};

export const getQuantidadeExames = async(variables) => {
  const response = await Api.post('', {
    query: `
      query ($dataHoraInicial: LocalDateTime, $dataHoraFinal: LocalDateTime){
        totalPacienteAnexo(dataHoraFinal: $dataHoraFinal, dataHoraInicial: $dataHoraInicial)
      }
    `,
    variables,
  });
  
  return response.data.data.totalPacienteAnexo;
};

export const getQuantidadeAnamnese = async(variables) => {
  const response = await Api.post('', {
    query: `
      query ($dataHoraInicial: LocalDateTime, $dataHoraFinal: LocalDateTime){
        totalAnamnese(dataHoraFinal: $dataHoraFinal, dataHoraInicial: $dataHoraInicial, possuiAgendamento: true)
      }
    `,
    variables,
  });
  
  return response.data.data.totalAnamnese;
};


export const findAllSujeitoAtencao = async (pageNumber, agendamento) => {
  const response = await Api.post('', {
    query: `
      query ($pageable: PageableDTOInput, $agendamento: AgendamentoInput) {
        findSujeitoAtencaoByAgendamento(pageable: $pageable, agendamento: $agendamento){
              totalElements
              numberOfElements
              last
              totalPages
              totalElements
              content {
                  id
                  nome
                  documento
                  dataNascimento
                  idade
                  fotoPerfil
                  contato {
                    telefonePrincipal
                    telefonePrincipalDDI
                    email
                }
              }
          }
      }`,
    variables: {
      pageable: {
        pageSize: 20,
        pageNumber: pageNumber,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      agendamento: agendamento,
    },
  })

  if(response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.findSujeitoAtencaoByAgendamento
}

export const updateAgendamentoAndPersistSujeitoAtencao = async (variables) => {
  const response = await Api.post('', {
    query: `

    mutation ($agendamento: AgendamentoInput) { 
      updateAgendamentoAndPersistSujeitoAtencao (agendamento : $agendamento)  {
        id
        sujeitoAtencao {
            id
        }
      }
    }
    `,
    variables,
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.updateAgendamentoAndPersistSujeitoAtencao;
}

export const liberarAcessoVizi = async (variables) => {
  const response = await Api.post('', {
    query: `
    mutation($sujeitoAtencaoId: UUID, $pacienteId: UUID) {
      liberarAcessoVizi(sujeitoAtencaoId: $sujeitoAtencaoId, pacienteId: $pacienteId) {
        id
        nome
      }
    }
    `,
    variables,
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.liberarAcessoVizi;
}
