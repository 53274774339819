import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './confirmarCancelamentoStyles';
import ModalConfirmacao from '../../../components/Modal/ModalConfirmacao/ModalConfirmacao';
import CloseIcon from '../../../components/Icon/Close';
import { Button } from '../../../components/ui/Buttons/Button/styles';
import IconInfo from '../../../components/Icon/IconInfo';
import moment from 'moment';

const ModalConfirmarCancelamento= ({
	classes, 
	close,
	handleConfirm, 
  handleOpenModalConfirmacao,
	horariosSelecionados,
	isLoading
}) => {

 const confirmaBloqueioAgenda=()=>{
  console.log(horariosSelecionados,"TESTE")
 }

	return (
		<ModalConfirmacao
        open={true}
			color={"#219A97"}
			title={""}
			buttons={{
				isButtonColorInverte: false,
				labelSecondButton: "Confirmar",
				handleSecondButton: () => confirmaBloqueioAgenda(),
				labelPrimaryButton: "Cancelar",
				handlePrimaryButton: () => close(),
				disabledPrimaryButton: isLoading,
				disabledSecondButton: isLoading,
				loadingSecondButton: isLoading,
			}}
			classes={{
				paperAlert: classes.paperAlert,
				marginTop: classes.removeMarginTop
			}}
		>
			<div className={classes.content}>
				<div className={classes.header}>
					<IconInfo size={40} color={"#219A97"} />
					<Button 
						onClick={close}
						kind="transparent"
						shape="circle"
						size="mini"
					>
						<CloseIcon 	
							size={20} 
							color={"#333333"} 
							className={classes.closeIcon} 
						/>
					</Button>
				</div>
				<span className={classes.title}>{horariosSelecionados.length} Agendamentos serão cancelados!</span>
				<br />
				<span className={classes.subtitle}>Você possui agendamentos dia:</span>
				<div className={classes.listContainer}>
					<ul className={classes.horariosList}>
						{horariosSelecionados?.map((horario, index) => (//Alterar pra query correta
							<li
								className={classes.horarioItem}
								key={index}
							>
								{moment(horario).format('DD-MM-YYYY')}
							</li>
						))}
					</ul>
				</div>	
			</div>
		</ModalConfirmacao>
	)
};


export default withStyles(styles)(ModalConfirmarCancelamento);
