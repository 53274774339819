import React from 'react';
import Moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles
} from "@material-ui/core";
import Checkbox from '../../../Checkbox/Checkbox'
import ButtonPrimary from '../../../Button/ButtonPrimary';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "#219A97",
        color: theme.palette.common.white,
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 700,
    },
    body: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 600,
        color: '#505050'
    },
    root: {
        padding: 0,
        paddingLeft: '16px',
        borderBottom: 'none'
    }
}))(TableCell);

const TabelaHorariosDisponiveis = (props) => {
    const {
        classes,
        horariosDisponiveis,
        handleCheckbox,
        horarioSelecionado,
        onExibirMaisResultados,
        showExibirMaisHorarios
    } = props;

    const calcularDuracao = (horaInicio, horaFim) => {
        return `${Moment(horaFim, 'HH:mm').diff(Moment(horaInicio, 'HH:mm'), 'minute')} min.`
    }

    return (
        <div className={classes.content}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRowHeader}>
                        <CustomTableCell />
                        <CustomTableCell >
                            Data consulta
                        </CustomTableCell>
                        <CustomTableCell >Início</CustomTableCell>
                        <CustomTableCell >Duração</CustomTableCell>
                        <CustomTableCell >Fim</CustomTableCell>
                        <CustomTableCell >Profissional de saúde</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {horariosDisponiveis.length > 0 && horariosDisponiveis.map((item, index) => {
                        return (
                            <>
                                <TableRow className={classes.tableRow} key={index}>
                                    <CustomTableCell className={classes.nomeProcedimento}>
                                        <Checkbox
                                            checked={index === horarioSelecionado?.id}
                                            color={"primary"}
                                            onChange={() => handleCheckbox(item, index)}
                                        />
                                    </CustomTableCell>
                                    <CustomTableCell
                                        className={classes.nomeProcedimento}
                                    >
                                        {Moment(item.data).format("DD/MM/YYYY")}
                                    </CustomTableCell>
                                    <CustomTableCell component="th">
                                        {item.horaInicio}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        className={classes.valor}

                                    >
                                        {calcularDuracao(item.horaInicio, item.horaFim)}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        className={classes.valor}

                                    >
                                        {item.horaFim}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        component="th"
                                        className={classes.valor}

                                    >
                                        {item.profissionalSaudeNome}
                                    </CustomTableCell>
                                </TableRow>
                            </>
                        );
                    })}
                </TableBody>
            </Table>
            {showExibirMaisHorarios &&
                    <div className={classes.buttons}>
                        <ButtonPrimary
                            onClick={() => onExibirMaisResultados()}
                            className={classes.searchButton}
                        >
                            Exibir mais horários
                        </ButtonPrimary>
                    </div>
                }
        </div>
    )
};

const style = {
    content: {
        '& > h1': {
            color: '#219A97',
            fontSize: '18px'
        }
    },
    listaFaturar: {
        display: "flex",
        flexDirection: "column",
        maxHeight: '170px',
        overflow: 'auto',
    },
    table: {
        borderRadius: '16px'
    },
    tableRowHeader: {
        height: 48
    },
    tableRow: {
        height: 40,
        backgroundColor: '#FFFFFF'
    },
    buttons: {
        justifyContent: 'center',
        marginTop: '8px',
        display: 'flex'
    },
    searchButton: {
        borderRadius: 85,
        width: '160px',
        height: '33px',
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
        border: '2px solid rgba(255, 255, 255, 0.2)',
        "& > span": {
          fontSize: "14px"
        },
    
      },

};

export default withStyles(style)(TabelaHorariosDisponiveis);