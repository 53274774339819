import { withStyles } from '@material-ui/core'
import React from 'react'
import { CustomPicker, HuePicker } from 'react-color'
import { Saturation, EditableInput } from 'react-color/lib/components/common'

const EDITABLE_INPUT_STYLES = {
  wrap: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '4px',
    width: '100%',
    maxWidth: '250px',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '0.3rem 0.5rem',
    width: '100%',
    flex: 4,
  },
  label: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '0.3rem 0.5rem',
    width: '100%',
    flex: 1,
  },
}

const getColorsSugestaoBall = color => {
  return {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: color,
  }
}

const ColorPicker = CustomPicker(props => {
  const { classes } = props
  return (
    <>
      <div className={classes.containerColorActions}>
        <div className={classes.wrapperSaturation}>
          <Saturation {...props} />
        </div>
        <EditableInput
          value={props.hex}
          onChange={props.onChange}
          label='Hex'
          style={EDITABLE_INPUT_STYLES}
          {...props}
        />
        <HuePicker
          width='100%'
          height='8px'
          pointer={() => <div style={styles.pointer} />}
          {...props}
        />
      </div>
      <div className={classes.wrapperSugestao}>
        <h3 className={classes.textSugestao}>Sugestões de cores</h3>
      </div>
      <div className={classes.wrapperSugestaoColors}>
        {props.presetColors.map((color, index) => {
          return (
            <div
              key={index}
              style={getColorsSugestaoBall(color)}
              onClick={() => props.onChange(color)}
            />
          )
        })}
      </div>
    </>
  )
})

const styles = {
  containerColorActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    '&>.hue-picker': {
      borderRadius: '8px',
      overflow: 'hidden',
    },
  },
  wrapperSaturation: {
    position: 'relative',
    width: '100%',
    height: '100px',
    borderRadius: '0px 8px 8px 8px',
    overflow: 'hidden',
  },
  wrapperSugestao: {
    textAlign: 'center',
  },
  textSugestao: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#505050',
  },
  wrapperSugestaoColors: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 0.5fr)',
    gridGap: '0.8rem',
  },
  pointer: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    border: '3px solid #fff',
    background: 'transparent',
    marginTop: '-2px',
    boxShadow: '0 0 2px rgba(0,0,0,.6)',
  },
}

export default withStyles(styles)(ColorPicker)
