const DNI_REGEX = /^(\d{8})([A-Z])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

const validaDocumentoEspanha = (str) => {
  str = str.toUpperCase().replace(/\s/, "");

  let valid = false;
  let type = spainIdType(str);

  switch (type) {
    case "dni":
      valid = validDNI(str);
      break;
    case "nie":
    default:
      valid = validNIE(str);
      break;
  }

  return valid;
};

const spainIdType = (str) => {
  if (str.match(DNI_REGEX)) {
    return "dni";
  }
  if (str.match(NIE_REGEX)) {
    return "nie";
  }
};

const validDNI = (dni) => {
  const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
  const letter = dni_letters.charAt(parseInt(dni, 10) % 23);

  return letter === dni.charAt(8);
};

const validNIE = (nie) => {
  let nie_prefix = nie.charAt(0);

  switch (nie_prefix) {
    case "X":
      nie_prefix = 0;
      break;
    case "Y":
      nie_prefix = 1;
      break;
    case "Z":
    default:
      nie_prefix = 2;
      break;
  }

  return validDNI(nie_prefix + nie.substr(1));
};

export default validaDocumentoEspanha;
