import {
  withStyles,
} from "@material-ui/core/es";
import React, { useState } from "react";
import { inject } from "mobx-react";
import { Grid } from "@material-ui/core";
import Colors from "../../../template/Colors";
import PopoverInfosAfericaoVital from "./PopoverInfosAfericaoVital";
import ModalInativarEntradaProntuario from "../../../components/Modal/ModalInativarEntradaProntuario";
import Notification from "../../../components/Notification";
import classNames from "classnames";
import InputSelectComLabel from "../../../components/Input/InputSelectComLabel";
import moment from "moment";
import PopperCustomMenu from "../../../components/Popper/PopperCustomMenu";
import MoreIcon from "../../../components/Icon/MoreIcon";

const InfosHistoricoLancamento = (props) => {
  const {
    classes,
    afericaoVital,
    handleVisualizar,
    prontuarioStore,
    inativarAfericaoVital,
    afericaoVitalSelecionado
  } = props;

  const menuOpcoesPopper = [
    {ativo: true, label: "Visualizar", onClick: () => handleVisualizarHistorico()},
    {ativo: afericaoVital.ativo, label: "Inativar", onClick: () => handleInativar()},
  ]

  const [showModalInativar, setShowModalInativar] = useState(false);
  const [motivoInativacao, setMotivoInativacao] = useState("");
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const [loadingInativacao, setLoadingInativacao] = useState(false);

  const quantidadeCamposPreenchido = () => {
    const dadosAfericaoVital = {
      temperatura: afericaoVital.temperatura,
      pressaoArterialSistolica: afericaoVital.pressaoArterialSistolica,
      pressaoArterialDiastolica: afericaoVital.pressaoArterialDiastolica,
      frequenciaRespiratoria: afericaoVital.frequenciaRespiratoria,
      frequenciaCardiaca: afericaoVital.frequenciaCardiaca,
      glicemia: afericaoVital.glicemia,
      saturacaoO2: afericaoVital.saturacaoO2,
      coleta: afericaoVital.coleta
    }

    const transformaValuesDoObjetoEmArray = Object.values(dadosAfericaoVital);
    const valoresValidos = transformaValuesDoObjetoEmArray.filter(
      (item) => !!item
    );

    const quantidadeDeValue = valoresValidos.length

    return `${quantidadeDeValue} dados`;
  };

  const handleVisualizarHistorico = () => {
    handleVisualizar();
  };

  const handleInativar = () => {
    setShowModalInativar(true);
  };

  const handleCloseModalInativar = () => {
    setShowModalInativar(false);
  };

  const handleChangeMotivoInativacao = (e) => {
    setMotivoInativacao(e.target.value);
  };

  const handleInativarModal = async () => {
    try {
      setLoadingInativacao(true);
      if (motivoInativacao.length > 0 && motivoInativacao.length < 2000) {
        await prontuarioStore.inativarEntradaProntuario(
          afericaoVital.entradaProntuario.id,
          motivoInativacao
        );
        prontuarioStore.findAllEntradaProntuario({ withLoading: false });
        inativarAfericaoVital();
        setShowModalInativar(false);
      }
    } catch (error) {
      const notification = {
        isOpen: true,
        message: "Erro ao inativar a avaliação antropometrica.",
      };
      showAlertMessage(notification);
    } finally {
      setLoadingInativacao(false);
    }
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };

    setNotification(notification);
  };

  return (
    <div className={classes.row}>
      <Grid item xs={4} className={classes.spacing}>
        <span
          className={classNames(classes.titulo, {
            [classes.tituloInativo]: !afericaoVital.ativo,
          })}
        >
          Lançamento:
        </span>
        <InputSelectComLabel
          disabled={true}
          classes={{
            input: classNames(classes.inputLancamento, {
              [classes.dadoInativoRisco]: !afericaoVital.ativo,
            }),
          }}
          className={classes.inputLancamento}
          value={moment(afericaoVital.data).format("DD/MM/YYYY")}
        />
      </Grid>
      <Grid item xs={8} className={[classes.spacing, classes.spacingMargin]}>
        <span
          className={classNames(classes.titulo, {
            [classes.tituloInativo]: !afericaoVital.ativo,
          })}
        >
          Dados adicionados:
        </span>
        <PopoverInfosAfericaoVital
          quantidadeCamposPreenchido={quantidadeCamposPreenchido()}
          dadosAdicionais={afericaoVital}
        />
      </Grid>
     { !afericaoVitalSelecionado && 
        <PopperCustomMenu
          placement={"bottom-end"}
          iconButton={<MoreIcon style={{ transform: 'rotate(90deg)' }} color="#505050"/>}
          menuOpcoes={menuOpcoesPopper}
        />
     }
      { showModalInativar && <ModalInativarEntradaProntuario
        show={showModalInativar}
        onClose={handleCloseModalInativar}
        changeMotivo={handleChangeMotivoInativacao}
        inativar={handleInativarModal}
        motivo={motivoInativacao}
        loadingInativacao={loadingInativacao}
        screen="Atendimento - Aferição vital -"
      /> }
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </div>
  );
};

const style = {
  row: {
    display: "flex",
    "& button": {
      background: "#f9f9f9",
      height: "30px",
      
      "&:hover": {
        background: "#f9f9f9",
      }
    },
  },
  spacing: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 14,
  },
  spacingMargin: {
    margin: "0 8px",
  },
  titulo: {
    color: Colors.commons.gray7,
    fontSize: "12px !important",
  },
  inputImc: {
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "100px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  dadosAdicionais: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    boxSizing: "border-box",
    borderRadius: "100px",
    height: "32px",
    color: Colors.commons.gray9,
  },
  visibilityIcon: {
    color: "#5F6368",
  },
  buttonVisibility: {
    display: "flex",
    padding: "1px",
    borderRadius: 100,
    "&:hover": {
      background: "#DFDFDF",
    },
  },
  dadosAdicionaisBold: {
    fontWeight: 700,
  },
  moreVertIcon: {
    color: Colors.commons.gray9,
  },
  buttonMore: {
    cursor: "pointer",
    width: "35px",
    height: "30px",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    "&:hover": {
      background: "#EAEAEA",
    },
  },
  popperMenu: {
    zIndex: 1,
  },
  popperPaper: {
    width: "110px",
  },
  inputData: {
    padding: "4px 0px 0px 13px",
  },
  dadoInativo: {
    opacity: 0.5,
  },
  dadoInativoRisco: {
    opacity: 0.5,
    textDecoration: "line-through",
  },
  tituloInativo: {
    opacity: 0.3,
  },
  inputLancamento: {
    height: 22,
    marginRight: 0,
    padding: "4px 12px",
  },
};

const InfosHistoricoLancamentoWithStyle = withStyles(style)(InfosHistoricoLancamento);
export default inject("prontuarioStore")(InfosHistoricoLancamentoWithStyle);
