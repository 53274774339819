import React from 'react';

import * as S from './styles';

const Switch = ({
  label,
  checked,
  height,
  width,
  checkedColor,
  uncheckedColor,
  ...props
}) => {
  const colorCheck = checked ? checkedColor : uncheckedColor
  if (label) {
    return (
      <S.Container {...props}>
        <S.Label>{label}</S.Label>
        <S.SwitchContainer
          width={width}
          bgColor={colorCheck}
        >
          <S.Indicator
            height={height}
            checked={checked}
            borderColor={colorCheck}
          />
        </S.SwitchContainer>
      </S.Container>
    );
  } else {
    return (
      <S.Container {...props}>
        <S.SwitchContainer
          width={width}
          bgColor={colorCheck}
        >
          <S.Indicator
            height={height}
            checked={checked}
            borderColor={colorCheck}
          />
        </S.SwitchContainer>
      </S.Container>
    );
  }
};

export default Switch;
