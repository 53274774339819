import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import {
  Grid, 
  IconButton, 
  InputAdornment,
} from '@material-ui/core'
import {
  Clear as ClearIcon,
  Search,
} from "@material-ui/icons";
import InputSearchRetangular from '../../../components/Input/InputSearchRetangular';

const SearchHeader = (props) =>{
  const { 
    classes, 
    placeholder, 
    handleClickSearch, 
    handleClickClear, 
    onChangeSearch, 
    onKeypressSearch, 
    search 
  } = props;
  
  return(
    <Grid className={classes.grid}>
    <InputSearchRetangular
      startAdornment={
        <InputAdornment position="start">
          <IconButton className={classes.iconButtonSearch}
                      onClick={() => handleClickSearch()}>
            <Search/>
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton className={classes.iconButtonSearch}
                      onClick={() => handleClickClear()}>
            <ClearIcon/>
          </IconButton>
        </InputAdornment>}
      placeholder={placeholder}
      value={search}
      onChange={onChangeSearch}
      onKeyPress={onKeypressSearch}
    />
    </Grid>
  )
};

const styles ={
  grid:{
    padding: '8px 12px',
  },

}

export default withStyles(styles)(SearchHeader)