import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const SettingsIcon = props => {
  return (
    <svg
      width='21'
      height='22'
      viewBox='0 0 21 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M2.74507 8.49013C3.09919 7.3432 3.69359 6.28683 4.48444 5.38778C4.61818 5.23574 4.8304 5.17938 5.02195 5.24502L6.94916 5.90543C7.47165 6.08437 8.04028 5.80587 8.21922 5.28338C8.23681 5.232 8.2502 5.17927 8.25921 5.12581L8.59805 3.11513C8.63174 2.91522 8.78282 2.75543 8.98053 2.7106C9.55903 2.57942 10.153 2.50874 10.7556 2.50075C11.3579 2.49277 11.9532 2.54761 12.5346 2.66326C12.7334 2.7028 12.8887 2.85842 12.9277 3.0573L13.3208 5.05862C13.4271 5.60059 13.9526 5.95378 14.4945 5.8475C14.5478 5.83705 14.6002 5.82228 14.651 5.80336L16.5601 5.09205C16.7498 5.02136 16.9635 5.07208 17.1012 5.22051C17.9156 6.09829 18.5378 7.13853 18.9222 8.27567C18.987 8.46751 18.9298 8.67952 18.7771 8.81262L17.2399 10.1532C16.8237 10.5162 16.7806 11.1479 17.1436 11.5641C17.1793 11.6051 17.2183 11.643 17.2601 11.6776L18.8324 12.977C18.9884 13.106 19.0513 13.3164 18.9916 13.5099C18.6375 14.6568 18.0431 15.7132 17.2522 16.6122C17.1185 16.7643 16.9062 16.8206 16.7147 16.755L14.7875 16.0946C14.265 15.9156 13.6964 16.1941 13.5174 16.7166C13.4998 16.768 13.4865 16.8207 13.4774 16.8744L13.1376 18.8853C13.1038 19.0852 12.9527 19.2449 12.7551 19.2897C12.1769 19.4207 11.5833 19.4913 10.981 19.4993C10.3784 19.5072 9.78272 19.4523 9.20094 19.3365C9.00211 19.297 8.84685 19.1412 8.80787 18.9423L8.41587 16.9414C8.30959 16.3994 7.78409 16.0462 7.24213 16.1525C7.18884 16.163 7.13648 16.1777 7.08568 16.1967L5.17657 16.908C4.98683 16.9787 4.77318 16.9279 4.63546 16.7795C3.82106 15.9017 3.19886 14.8615 2.81446 13.7243C2.74961 13.5325 2.80688 13.3205 2.9595 13.1874L4.49671 11.8468C4.91292 11.4838 4.95604 10.8521 4.59302 10.4359C4.55733 10.395 4.51836 10.357 4.47652 10.3224L2.90429 9.02304C2.7482 8.89404 2.68533 8.68362 2.74507 8.49013ZM8.8685 11.0265C8.88314 12.131 9.79036 13.0145 10.8948 12.9998C11.9993 12.9852 12.8828 12.078 12.8681 10.9735C12.8535 9.86902 11.9463 8.98554 10.8418 9.00018C9.73734 9.01483 8.85386 9.92205 8.8685 11.0265Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SettingsIcon
