import React, { useMemo, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'

import { TextFieldSearch } from '../../../components/TextField'

import ContentProcedimentos from './ContentProcedimentos'
import InputForm from '../../../components/Input/InputForm'
import AddIcon from '../../../components/Icon/AddIcon'
import { Button } from '../../../components/ui/Buttons'

const TabProcedimentos = observer(props => {
  const { classes, isDisabled, titulo, setProcedimentos, procedimentos, extratoStore } = props
  const [quantidadeSessoes, setQuantidadeSessoes] = useState(1)
  const [procedimento, setProcedimento] = useState(null)
  const handleProcedimentosLoadOptions = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
    })
  }

  const handleLoadMoreOptions = async ({ search, data }) => {
    let searchDTO = {}
    const { profissionalSaude } = extratoStore.titulo;

    if (data.searchDTO) {
      searchDTO = {
        ...data.searchDTO,
      }
    }

    const response = await extratoStore.findAllProcedimentos({
      pageNumber: data.page,
      search,
      ...(profissionalSaude && { profissionalSaudeId: profissionalSaude.id }),
      ...searchDTO,
    })

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1,
      },
    }
  }

  const incluirProcedimentoNaLista = procedimento => {
    const procedimentosNaoSelecionados = [...procedimentos]
    procedimentosNaoSelecionados.push(procedimento)
    procedimentosNaoSelecionados.sort(function(a, b) {
      if (a.nome > b.nome) {
        return 1
      }
      if (a.nome < b.nome) {
        return -1
      }
      return 0
    })
    setProcedimentos(procedimentosNaoSelecionados)
  }

  const handleRemoveProcedimento = procedimento => {
    const { procedimentos } = extratoStore.titulo
    if (procedimentos?.length === 0) return

    const updatedProcedimentos = procedimentos.filter(item => {
      return item.id !== procedimento.id
    })
    extratoStore.titulo.procedimentos = updatedProcedimentos
    if (extratoStore.titulo.convenio) {
      extratoStore.atualizarTotalComProcedimento(procedimento, 'sub', procedimento.totalQuantity)
    }

    incluirProcedimentoNaLista(procedimento)
  }

  const handleChangeProcedimento = (procedimento, index) => {
    const { procedimentos } = extratoStore.titulo
    if (procedimentos?.length === 0) return
    const updatedProcedimentos = procedimentos.map((item, i) => {
      if (i !== index) return item
      return procedimento
    })
    extratoStore.titulo.procedimentosubs = updatedProcedimentos
    extratoStore.titulo.procedimentos = updatedProcedimentos
    incluirProcedimentoNaLista(procedimento)
  }

  const handleChangeQuantidade = (event, index) => {
    const { procedimentos } = extratoStore.titulo
    const { value } = event.target
    const indexProcedimentos = titulo.procedimentoControleSessao ? index - 1 : index
    const oldProcedimento = procedimentos[indexProcedimentos]
    
    procedimentos[indexProcedimentos] = {
      ...oldProcedimento,
      totalQuantity: value
    }
  }

  const handleChange = (event, field) => {
    if (field === 'PROCEDIMENTO') {
      setProcedimento(event)
    } else {
      setQuantidadeSessoes(event.target.value)
    }
  }

  const isProcedimentoRepetido = procedimento => {
    const {procedimentos, procedimentoControleSessao} = titulo
    if (!procedimentos) return false
    const verifyByControleSessao =
      procedimentoControleSessao?.procedimento?.id === procedimento.id || false
    const verifyByProcedimentos = procedimentos.some(item => item.id === procedimento.id)
    return verifyByProcedimentos || verifyByControleSessao
  }

  const handleAddProcedimento = (newProcedimento) => {
    const procedimentoToAdd = newProcedimento || procedimento
    const quantidadeSessoesToAdd = newProcedimento ? newProcedimento.totalQuantity : quantidadeSessoes
    if (!procedimentoToAdd || !quantidadeSessoesToAdd) return
    if (isProcedimentoRepetido(procedimentoToAdd)) {
      extratoStore.openNotification('Procedimento já adicionado', 'error')
      return
    }

    const procedimentoComSessoes = {
      ...procedimentoToAdd,
      nome: procedimentoToAdd.nome,
      id: procedimentoToAdd.id,
      totalQuantity: quantidadeSessoesToAdd,
    }

    extratoStore.selectProcedimento(procedimentoComSessoes)
    const listaSemSelecionados = extratoStore.procedimentos.filter(item => {
      return procedimentoToAdd.id !== item.id
    })
    if (extratoStore.titulo.convenio) {
      extratoStore.atualizarTotalComProcedimento(procedimentoToAdd, 'sum', quantidadeSessoesToAdd)
    }
    extratoStore.procedimentos = listaSemSelecionados
  }

  const optionsProcedimentos = () => {
    const { agendamentoTipos, procedimentos, procedimentoControleSessao } = titulo
    const procedimentosWithAgendamento = agendamentoTipos
      ? [...agendamentoTipos, ...procedimentos]
      : procedimentos
    const response = procedimentoControleSessao
      ? [procedimentoControleSessao, ...procedimentosWithAgendamento]
      : procedimentosWithAgendamento
    return response
  }

  const disableButton = useMemo(() => {
    return !procedimento || !quantidadeSessoes
  }, [procedimento, quantidadeSessoes])

  const procedimentosControleSessao = titulo?.procedimentoControleSessao?.pacoteSessoes


  return (
    <div className={classes.contentField}>
      <Grid container spacing={16} className={classes.wrapperFields}>
        <Grid item xs={6}>
          <div className={classes.titleField}>Procedimentos</div>
          <TextFieldSearch
            placeholder=''
            loadOptions={handleProcedimentosLoadOptions}
            withPaginate
            value={procedimento}
            getOptionLabel={option => option.nome}
            getOptionValue={option => option.id}
            onChange={e => handleChange(e, 'PROCEDIMENTO')}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            isDisabled={isDisabled || procedimentosControleSessao}
            classNotched={classes.notchedOutlineSearch}
            classInput={classes.inputSearch}
          />
        </Grid>
        <Grid item xs={4}>
          <div className={classes.titleField}>Nº de Sessões</div>
          <InputForm
            value={quantidadeSessoes}
            onChange={e => handleChange(e, 'QUANTIDADE')}
            placeholder={'Digite a quantidade'}
            type={'number'}
            disabled={isDisabled || procedimentosControleSessao}
            className={classes.inputContainer}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              handleAddProcedimento()
              setProcedimento(null)
              setQuantidadeSessoes(1)
            }}
            shape='circle'
            disabled={disableButton}
          >
            <AddIcon />
          </Button>
        </Grid>
      </Grid>

      <div className={classes.containerProcedimentos}>
        {optionsProcedimentos()?.length > 0 && (
          <div className={classes.wrapperProcedimentos}>
            <ContentProcedimentos
              loadProcedimentos={handleProcedimentosLoadOptions}
              options={optionsProcedimentos()}
              handleChangeProcedimento={handleChangeProcedimento}
              handleRemoveProcedimento={handleRemoveProcedimento}
              handleChangeQuantidade={handleChangeQuantidade}
              titulo={extratoStore.titulo}
              disabled={!!titulo.controleSessaoId}
            />
          </div>
        )}
      </div>
    </div>
  )
})

const styles = {
  titleField: {
    color: '#868686',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '4px',
  },
  contentField: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 20px)',
    marginTop: '10px',
    gap: '16px',
  },
  inputSearch: {
    background: '#F2F2F2',
    border: '0.5px solid rgba(220, 220, 220, 0.2)',
    borderRadius: '8px',
    height: '30px',
  },
  notchedOutlineSearch: {
    border: 'none',
  },
  containerProcedimentos: {
    overflowY: 'scroll',
  },
  wrapperProcedimentos: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: '25px',
  },
  wrapperFields: {
    display: 'flex',
    alignItems: 'end',
  },
  inputContainer: {
    width: '100%',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '34px',

    '& > div': {
      '& > input': {
        border: 'none',
        fontSize: '14px',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
      '& > p': {
        overflow: 'hidden',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
}

const stores = ['extratoStore'];
const TabProcedimentosWithStyles = withStyles(styles)(TabProcedimentos); 
export default inject(...stores)(TabProcedimentosWithStyles);

