import { Grid, withStyles } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '../../../../../../components/ui/Buttons'
import Dialog from '../../../../../../components/Dialog/Dialog'
import CloseIcon from '../../../../../../components/Icon/Close'
import ItemGrid from '../../../../../../components/Modal/ItemGrid'
import { TextFieldSearch } from '../../../../../../components/TextField'
import { InputForm } from '../../../../../../components/Modal/Input'
import { findAllProcedimentos } from '../../../../../../services/ProcedimentoService'
import TrashIcon from '../../../../../../components/Icon/Trash'
import AddIcon from '../../../../../../components/Icon/AddIcon'
import string from '../../../../../../utils/string'
import {
  createModeloProcedimento,
  updateProcedimentoModelo,
} from '../../../../../../services/ProcedimentoModeloService'

function ModalModelosProcedimentos({ open, onClose, classes, rowSelected, isDuplicate }) {
  const [values, setValues] = useState({
    procedimento: null,
    modelo: '',
  })
  const [procedimentos, setProcedimentos] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    }
    const searchDTO = {
      pageableDTO,
      nome: search,
    }

    try {
      const { content, last } = (
        await findAllProcedimentos(searchDTO)
      ).data.data.findAllProcedimentos
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (e, field) => {
    setValues({ ...values, [field]: e })
  }

  const handleAddToListProcedimentos = () => {
    if (values.procedimento) {
      setProcedimentos([...procedimentos, values.procedimento])
      setValues({ ...values, procedimento: null })
    }
  }

  const handleRemoveProcedimento = index => {
    const newProcedimentos = [...procedimentos]
    newProcedimentos.splice(index, 1)
    setProcedimentos(newProcedimentos)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      if (rowSelected && !isDuplicate) {
        await updateProcedimentoModelo({
          procedimentoModelo: {
            id: rowSelected.id,
            nome: values.modelo,
            procedimentos: procedimentos.map(item => {
              return {
                id: item.id,
              }
            }),
          },
        })
      } else {
        await createModeloProcedimento({
          procedimentoModelo: {
            nome: values.modelo,
            ativo: true,
            procedimentos: procedimentos.map(item => {
              return {
                id: item.id,
              }
            }),
          },
        })
      }
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const disabledSaveButton = useMemo(() => {
    return string.isEmpty(values.modelo) || procedimentos.length === 0
  }, [values, procedimentos])

  const disabledAddButton = useMemo(() => {
    return !values.procedimento
  }, [values])

  useEffect(() => {
    if (rowSelected) {
      setValues({
        modelo: rowSelected.nome,
        procedimento: null,
      })
      setProcedimentos(rowSelected.procedimentos)
    }
  }, [rowSelected])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      classes={{
        paper: classes.paper,
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <div className={classes.header}>
        <h1>Modelo de procedimentos</h1>
        <Button onClick={onClose} kind='transparent' shape='square' color='primary'>
          <CloseIcon size='20' color='#505050' />
        </Button>
      </div>
      <Grid className={classes.wrapperFields}>
        <ItemGrid size={12} label='Nome do modelo'>
          <InputForm
            name='modelo'
            value={values.modelo}
            onChange={e => handleChange(e.target.value, 'modelo')}
            placeholder={'Nome do Modelo'}
            className={classes.input}
          />
        </ItemGrid>
        <div className={classes.addProcedimento}>
          <ItemGrid size={12} label='Selecione os procedimentos'>
            <TextFieldSearch
              placeholder='Selecione'
              classNotched={classes.notchedOutline}
              classInput={classes.inputContainer}
              classIcons={classes.classIcons}
              loadOptions={loadAllProcedimentos}
              getOptionLabel={option => option.nome}
              getOptionValue={option => option}
              name='procedimento'
              value={values.procedimento}
              onChange={e => handleChange(e, 'procedimento')}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </ItemGrid>
          <Button
            onClick={handleAddToListProcedimentos}
            kind='primary'
            shape='circle'
            disabled={disabledAddButton}
          >
            <AddIcon />
          </Button>
        </div>
      </Grid>

      <div className={classes.content}>
        {procedimentos?.map((item, index) => {
          return (
            <div className={classes.wrapperProcedimento} key={index}>
              <span>{item.nome}</span>
              <Button
                onClick={() => handleRemoveProcedimento(index)}
                bgColor='#FB7676'
                shape='circle'
                color='primary'
              >
                <TrashIcon size='20' color='#fff' />
              </Button>
            </div>
          )
        })}
      </div>

      <div className={classes.footer}>
        <Button onClick={onClose} kind='transparent' width='160px'>
          Cancelar
        </Button>
        <Button
          onClick={onSubmit}
          kind='primary'
          width='160px'
          disabled={isLoading || disabledSaveButton}
          isLoading={isLoading}
        >
          Salvar
        </Button>
      </div>
    </Dialog>
  )
}

const styles = {
  paper: {
    background: '#F2F2F2',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    background: '#fff',

    '& h1': {
      fontSize: '24px',
      fontWeight: '600',
      color: '#333333',
    },
  },

  content: {
    padding: '1rem',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    gap: '16px',
  },
  wrapperProcedimento: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFFFFF',
    gap: '8px',

    '& > span': {
      fontSize: '14px',
      fontWeight: '600',
      color: '#868686',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  wrapperFields: {
    padding: '0.5rem 1rem',
    backgroundColor: '#FFFFFF',
  },
  input: {
    width: '100%',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '34px',

    '& > div': {
      '& > input': {
        border: 'none',
        fontSize: '14px',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
      '& > p': {
        overflow: 'hidden',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  addProcedimento: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',

    '& > div': {
      width: 'calc(100% - 40px)',
    },
  },
  notchedOutline: {
    border: '0',
  },
  inputContainer: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '30px',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  classIcons: {
    fontSize: 24,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
  },
}

export default withStyles(styles)(ModalModelosProcedimentos)
