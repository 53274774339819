import React from "react";
import Dialog from "./Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonAlert from "../Button/ButtonAlert";
import ButtonClearPrimary from "../Button/ButtonClearPrimary";
import CloseIcon from '@material-ui/icons/Close';
import { Fab } from '@material-ui/core';
import { withStyles, Grid } from "@material-ui/core";

const buttonStyle = {
    fontWeight: 'bold',
    width: 120,
    height: 38,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    borderRadius: 85,
    "& span": {
        fontSize: 15
    }
};

const styles = theme => ({
    alertContainer: {
        maxWidth: 304,
        maxHeight: 175
    },
    alertTitle: {
        "& h6": {
            fontSize: 19,
            fontStyle: 'normal',
            fontWeight: 'bold',
        },
        width: '60%'
    },
    containerButtons: {
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15
    },
    buttonCancel: {
        ...buttonStyle,
        marginRight: 5,
        background: '#FAFAFC'
    },
    buttonOk: {
        ...buttonStyle,
        marginLeft: 5,
        background: '#26ACA9',
        "&:hover": {
          background: '#218785'
        }        
    },
    button: {
        position: 'absolute',
        right: 20,
        top: 20
    },
    buttonClose: {
        width: '20px',
        height: '20px',
        minHeight: 'initial'
    }
});

const TokenDialog = ({
    open,
    onClose,
    handleClose,
    onCancel,
    onOk,
    classes,
    isLoading,
}) => (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={classes.alertContainer}>
                <DialogTitle className={classes.alertTitle}>
                    <Grid>
                        <div style={{fontSize: 18}}>
                            Deseja utilizar a assinatura digital?
                        </div>
                        <div className={classes.button}>
                            <Fab className={classes.buttonClose} color='primary' onClick={handleClose}>
                                <CloseIcon fontSize={'small'} />
                            </Fab>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogActions style={{ textAlign: "center" }}>
                    <Grid container className={classes.containerButtons}>
                        <Grid item xs={12}>
                            <ButtonClearPrimary
                                className={classes.buttonCancel}
                                onClick={onCancel}
                                disabled={isLoading}
                            >
                                <React.Fragment>
                                    Não
                                </React.Fragment>
                            </ButtonClearPrimary>
                            <ButtonAlert
                                className={classes.buttonOk}
                                onClick={onOk}
                                autoFocus
                                disabled={isLoading}
                            >
                                <React.Fragment>
                                    Sim
                                </React.Fragment>
                            </ButtonAlert>
                        </Grid>
                    </Grid>
                </DialogActions>
            </div>
        </Dialog>
    );

export default withStyles(styles)(TokenDialog);
