import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const InfoIcon = props => {
  return props.filled ? (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M6.99605 1.69531C10.3103 1.69531 12.997 4.38202 12.997 7.69624C12.997 11.0105 10.3103 13.6972 6.99605 13.6972C3.68183 13.6972 0.995117 11.0105 0.995117 7.69624C0.995117 4.38202 3.68183 1.69531 6.99605 1.69531ZM6.99376 6.64566C6.68606 6.64586 6.43261 6.87765 6.39815 7.17608L6.39416 7.24605L6.39632 10.547L6.4004 10.617C6.43525 10.9154 6.68901 11.1468 6.99671 11.1466C7.30441 11.1464 7.55786 10.9146 7.59233 10.6162L7.59632 10.5462L7.59416 7.24527L7.59007 7.1753C7.55522 6.87692 7.30147 6.64546 6.99376 6.64566ZM6.99632 4.39593C6.5816 4.39593 6.2454 4.73213 6.2454 5.14684C6.2454 5.56156 6.5816 5.89776 6.99632 5.89776C7.41103 5.89776 7.74723 5.56156 7.74723 5.14684C7.74723 4.73213 7.41103 4.39593 6.99632 4.39593Z'
        fill='currentColor'
      />
    </svg>
  ) : (
    <svg
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M9.00002 -0.000732422C13.419 -0.000732422 17.0013 3.58154 17.0013 8.00051C17.0013 12.4195 13.419 16.0017 9.00002 16.0017C4.58106 16.0017 0.998779 12.4195 0.998779 8.00051C0.998779 3.58154 4.58106 -0.000732422 9.00002 -0.000732422ZM9.00002 1.19927C5.2438 1.19927 2.19878 4.24429 2.19878 8.00051C2.19878 11.7567 5.2438 14.8017 9.00002 14.8017C12.7562 14.8017 15.8013 11.7567 15.8013 8.00051C15.8013 4.24429 12.7562 1.19927 9.00002 1.19927ZM8.99711 6.79973C9.30086 6.79954 9.55205 7.02509 9.59197 7.31793L9.5975 7.39934L9.60038 11.8006C9.6006 12.132 9.33214 12.4008 9.00077 12.401C8.69701 12.4012 8.44583 12.1756 8.40591 11.8828L8.40038 11.8014L8.3975 7.40013C8.39728 7.06876 8.66573 6.79995 8.99711 6.79973ZM9.00038 4.00152C9.44162 4.00152 9.79931 4.35922 9.79931 4.80045C9.79931 5.24169 9.44162 5.59938 9.00038 5.59938C8.55914 5.59938 8.20145 5.24169 8.20145 4.80045C8.20145 4.35922 8.55914 4.00152 9.00038 4.00152Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InfoIcon
