import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { WrapperChildren } from '../styles'

export const childrenWithLoading = (kind, shape, children) => {
  if (shape === 'round' || shape === 'circle' || shape === 'square') {
    return (
      <CircularProgress
        size={12}
        color={kind === 'primary' || kind === 'secondary' ? 'white' : 'primary'}
      />
    )
  }
  return (
    <WrapperChildren>
      {children}
      <CircularProgress
        size={12}
        color={kind === 'primary' || kind === 'secondary' ? 'white' : 'primary'}
      />
    </WrapperChildren>
  )
}
