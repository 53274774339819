import { applyPhoneMask } from '../../../../utils/PhoneMask'
import string from '../../../../utils/string'

export const optionsCard = solicitacao => {
  const { sujeitoAtencao, tipoEntradaProntuario } = solicitacao
  const options = [
    {
      label: 'Nome',
      value: sujeitoAtencao?.nome,
    },
    {
      label: 'Tipo Documento',
      value: tipoEntradaProntuario,
    },
    {
      label: 'Telefone',
      value: applyPhoneMask(sujeitoAtencao?.contato?.telefonePrincipal),
    },
    {
      label: 'CPF',
      value: sujeitoAtencao?.cpf && string.cpfMask(sujeitoAtencao?.cpf),
    },
  ]
  return options
}
