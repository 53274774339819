import React from 'react';
import classnames from "classnames"
import {withStyles} from "@material-ui/core/styles/index";
import {
    IconButton, 
    InputAdornment,
    TextField
  } from '@material-ui/core'
  import {
    Clear as ClearIcon,
    Search as SearchIcon,
  } from "@material-ui/icons";
import inputStyle from "./Input.scss"


export const Input = ({className, ...props}) => {

    const style = classnames(
        'border-focus-input-primary',
        inputStyle["input-form"],
        className
    );

    return (
        <div className={style}>
            <label htmlFor={props.id}>{props.label}</label>
            <input {...props} />
        </div>
    );
};

export const TextArea = ({className, ...props}) => {

    const style = classnames(
        'border-focus-input-primary',
        inputStyle["input-form"],
        className
    );

    return (
        <div className={style}>
            <label htmlFor={props.id}>{props.label}</label>
            <textarea {...props} />
        </div>
    );
};

export const InputIcon = ({className, icon, ...props}) => {

    const style = classnames(
        'border-focus-input-primary',
        inputStyle["input-form"],
        className
    );

    const styleIcon = classnames(
        inputStyle["img"],
        className
    );

    return (
        <div className={style}>
            <label htmlFor={props.id}>{props.label}</label>
            <input {...props} />
            <div>
                <img className={styleIcon} src={icon} alt=""/>
            </div>
        </div>
    );
};

const styleInputSearch = theme => ({
    root: {
        width: '100%',
        maxWidth: '264px',
        height: '40px',
      },
      inputRoot: {
        padding: "0 10px",
        fontSize: 14,
        fontFamily: "Poppins",
        color: '#868686',
        height: '41px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        background: '#F2F2F2',
        fontWeight: 400,
        borderRadius: '8px !important',
        justifyContent: 'center',
    },
    input: {
        padding: '7px 0'
    },
    iconButtonSearch: {
        width: 30,
        height: 30,
    },
    icon: {
        color: "#868686",
        width: 24,
        height: 20,
    }
});

function InputSearch(props) {
    const {classes, classInputRoot, classInput, startAdornment, endAdornment, hideStartAdornment, hideEndAdornment, handleClickClear, onClickButtonSearch, value, ...rest} = props;

    const getStartAdornment =  startAdornment || (
        <InputAdornment position="start">
            <IconButton className={classes.iconButtonSearch} onClick={onClickButtonSearch}>
                <SearchIcon className={classes.icon}/>
            </IconButton>
        </InputAdornment>
    );
    
    const getEndAdornment = value?.length > 0 && (endAdornment || (
        <InputAdornment position="end">
            <IconButton className={classes.iconButtonSearch} onClick={handleClickClear}>
                <ClearIcon className={classes.icon}/>
            </IconButton>
        </InputAdornment>
        ))

    return (
        <TextField
            className={classes.root}
            id="input-with-icon-textfield"
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: `${classes.inputRoot} ${classInputRoot}`,
                    input: `${classes.input} ${classInput}`,
                    focused: classes.inputFocused
                },
                startAdornment: hideStartAdornment ? null : getStartAdornment,
                endAdornment: hideEndAdornment ? null : getEndAdornment,
            }}
            InputLabelProps={{
                shrink: true,
            }}
            value={value}
            {...rest}
        />
    )
}

export default withStyles(styleInputSearch)(InputSearch);
