import React from "react";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Check";
import Colors from "../../../template/Colors";
import { Grid, Fab, withStyles } from "@material-ui/core";
import { TextFieldSearch } from "../../../components/TextField";
import InputForm from "../../../components/Input/InputForm";
import { InputCEPForm } from "../../../components/Input/InputCEPForm";
import GracefulImage from '../../../components/Image/GracefulImage';
import { buildUrlFotoPerfil } from "../../../config/config";

const Patient = ({
    patient,
    states,
    cities,
    handleEdit,
    handleSave,
    handleChange,
    isFetchingCities,
    readOnly,
    classes,
    accessToken
}) => {
    const urlPhoto = buildUrlFotoPerfil(patient?.picture, accessToken)

    return !patient.isEditing ? (
        <Container>
            <Grid item xs={1} className={classes.imageGrid}>
                <GracefulImage src={urlPhoto}
                    alt={'Imagem Perfil'} width="100%"
                    className={classes.imageMini}
                    placeholderColor={'#00ACA9'} />
            </Grid>
            <Grid item xs={10} className={classes.infoGrid}>
                <PatientInformation>
                    <Row container>
                        <InformationName>{patient.name}</InformationName>
                    </Row>
                    <Row container spacing={8}>
                        <InformationItem item xs={3}>
                            <InformationLabel>CEP:</InformationLabel>
                            <Information>{patient.zipCode}</Information>
                        </InformationItem>
                        <InformationItem item xs={6}>
                            <InformationLabel>Logradouro:</InformationLabel>
                            <Information>{patient.street}</Information>
                        </InformationItem>
                        <InformationItem item xs={3}>
                            <InformationLabel>Nº:</InformationLabel>
                            <Information>{patient.number}</Information>
                        </InformationItem>
                    </Row>
                    <Row container spacing={8}>
                        <InformationItem item xs={6}>
                            <InformationLabel>Bairro:</InformationLabel>
                            <Information>{patient.neighborhood}</Information>
                        </InformationItem>
                        <InformationItem item xs={2}>
                            <InformationLabel>UF:</InformationLabel>
                            <Information>{patient.state?.label}</Information>
                        </InformationItem>
                        <InformationItem item xs={4}>
                            <InformationLabel>Cidade:</InformationLabel>
                            <Information>{patient.city?.label}</Information>
                        </InformationItem>
                    </Row>
                </PatientInformation>
            </Grid>
            <Grid item xs={1} className={classes.buttonEditGrid}>
                <Actions>
                    {!readOnly && <Fab
                        className={classes.buttonEdit}
                        onClick={handleEdit}
                    >
                        <EditIcon fontSize={'inherit'} />
                    </Fab>}
                </Actions>
            </Grid>
        </Container>
    ) : (
            <Container>
                <Grid item xs={1} className={classes.imageGrid}>
                    <GracefulImage src={urlPhoto}
                        alt={'Imagem Perfil'} width="100%"
                        className={classes.imageMini}
                        placeholderColor={'#00ACA9'} />
                </Grid>
                <Grid item xs={10} className={classes.infoGrid}>
                    <PatientForm onSubmit={handleSave}>
                        <Row container>
                            <InformationName>{patient.name}</InformationName>
                        </Row>
                        <Row container spacing={8}>
                            <InformationItem item xs={3}>
                                <InformationLabel>CEP:</InformationLabel>
                                <InputCEPForm
                                    alternativeInputClass={classes.inputEdit}
                                    placeholder="CEP"
                                    value={patient.zipCode}
                                    onChange={handleChange("zipCode")}
                                    showmask={'false'}
                                />
                            </InformationItem>
                            <InformationItem item xs={6}>
                                <InformationLabel>Logradouro:</InformationLabel>
                                <InputForm
                                    className={classes}
                                    alternativeInputClass={classes.inputEdit}
                                    placeholder="Logradouro"
                                    value={patient.street}
                                    onChange={handleChange("street")}
                                />
                            </InformationItem>
                            <InformationItem item xs={3}>
                                <InformationLabel>Nº:</InformationLabel>
                                <InputForm
                                    alternativeInputClass={classes.inputEdit}
                                    placeholder="Número"
                                    value={patient.number}
                                    onChange={handleChange("number")}
                                />
                            </InformationItem>
                        </Row>
                        <Row container spacing={8}>
                            <InformationItem item xs={4}>
                                <InformationLabel>Bairro:</InformationLabel>
                                <InputForm
                                    className={classes}
                                    alternativeInputClass={classes.inputEdit}
                                    placeholder="Bairro"
                                    value={patient.neighborhood}
                                    onChange={handleChange("neighborhood")}
                                />
                            </InformationItem>
                            <InformationItem item xs={4}>
                                <InformationLabel>UF:</InformationLabel>
                                <TextFieldSearch
                                    placeholder="UF"
                                    className={classes.selectFullWidth}
                                    classNotched={classes.notchedOutline}
                                    classInput={classes.inputTextField}
                                    value={patient.state}
                                    onChange={handleChange("state")}
                                    options={states.map(s => ({
                                        value: s.id,
                                        label: s.uf
                                    }))}
                                />
                            </InformationItem>
                            <InformationItem item xs={4}>
                                <InformationLabel>Cidade:</InformationLabel>
                                <TextFieldSearch
                                    name='Teste'
                                    placeholder="Cidade"
                                    className={classes.selectFullWidth}
                                    classNotched={classes.notchedOutline}
                                    classInput={classes.cidadeInputTextField}
                                    value={patient.city}
                                    onChange={handleChange("city")}
                                    options={cities.map(c => ({
                                        value: c.id,
                                        label: c.nome
                                    }))}
                                    isDisabled={isFetchingCities}
                                />
                            </InformationItem>
                        </Row>
                    </PatientForm>
                </Grid>
                <Grid item xs={1} className={classes.buttonEditGrid}>
                    <Actions>
                        {!readOnly && <Fab
                            className={classes.buttonEdit}
                            onClick={handleSave}
                        >
                            <SaveIcon fontSize={'inherit'} />
                        </Fab>}
                    </Actions>
                </Grid>
            </Container>
        )
};

const Container = styled(Grid)`
  display: flex;
  justify-content: space-between;
  color: ${Colors.commons.gray7};
  border-radius: 17px;
  background-color: #fff;
  margin-top: 25px;
`;

const PatientInformation = styled(Grid)`
  width: 100%;
`;

const InformationName = styled.div`
  margin: 0;
  font-family: Nexa Black !important;
  font-size: 14px;
`;

const InformationLabel = styled.div`
  margin: 0;
  display: inline-flex;
  font-family: Nexa Bold !important;
  font-size: 12px;
  margin-right: 5px;
  color: ${Colors.commons.secondary};
`;

const Information = styled.div`
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
`;

const InformationItem = styled(Grid)`
  margin: 0;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-left: 10px;
`;

const PatientForm = styled.form`
  width: 100%;
`;

const Row = styled(Grid)`
  margin-bottom: 5px !important;
`;

const inputStyle = {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    height: 20,
    color: Colors.commons.gray7,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '100px',
};

export default withStyles(theme => ({
    root: {
        width: '100%',
    },
    buttonEdit: {
        backgroundColor: Colors.commons.gray7,
        minWidth: 20,
        minHeight: 20,
        width: 20,
        height: 20,
        color: 'white',
        alignContent: 'center',
        fontSize: 'medium',
    },
    inputEdit: {
        borderRadius: 0,
        border: 'none',
        borderBottom: `1px solid ${Colors.commons.gray7}`,
        height: 12,
        padding: '3px 0',
        fontSize: 14,
    },
    imageGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    imageMini: {
        borderRadius: 100,
    },
    infoGrid: {
        marginRight: '-20px',
    },
    buttonEditGrid: {
        padding: '0 20px'
    },
    notchedOutline: {
        border: '0',
    },
    inputTextField: {
        ...inputStyle,
        height: '32px',
    },
    cidadeInputTextField: {
        ...inputStyle,
        height: '32px',
    },
    selectFullWidth: {
        width: '100%'
    },
}))(Patient);
