// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

import { AppStateProvider } from './providers/AppStateProvider';
import { NavigationProvider } from './providers/NavigationProvider';
import { Meeting } from './views';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, lightTheme } from 'amazon-chime-sdk-component-library-react';

const Chime = () => (
  <AppStateProvider>
    <Theme>
      <NavigationProvider>
        <Meeting />
      </NavigationProvider>
    </Theme>
  </AppStateProvider>
);

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Chime;
