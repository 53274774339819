export const eventCardHeight = 40.5;

export default theme => ({
    dayCalendar: {
        fontFamily: "Poppins!important",
        overflow: 'auto',
        display: 'flex',
        height: '100%',
        background: '#fff',
    },
    
    dayCalendarHeaderInner: {
        fontFamily: "Poppins!important",
        width: '100%',
        flexDirection: 'row',
        overflowX: 'hidden',
        minHeight: '60px',
        maxHeight: '60px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
    },
    dayCalendarColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: '250px',
        height: '40px',
        minHeight: '40px',
    },
    dayCalendarColumnHeader: {
        fontFamily: "Poppins!important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    columnHoraInicio: {
        backgroundColor: '#219A97',
        maxWidth: '75px',
    },
    columnHoraFim: {
        backgroundColor: '#F4F4F4',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        maxWidth: '75px',
    },
    columnData: {
        backgroundColor: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    dayCalendarColumnHeaderLabel: {
        fontWeight: 400,
        color: '#F9F9F9',
        fontSize: '16px!important',
        fontFamily: "Poppins!important",
        userSelect: 'none',
        marginLeft: '15px',
        lineHeight: '19px',
    },
    columnHoraInicioLabel: {
        color: '#F9F9F9',
    },
    columnHoraFimLabel: {
        color: '#5F6368',
    },
    columnDataLabel: {
        color: "#868686",
        fontWeight: 700,
        alignSelf: 'center',
    },
    contentCalendar: {
        display: 'flex',
        flexDirection: 'row',
    },
    contentCalendarDay: {
        width: '100%'
    },
    rowHeader: {
        display: 'flex'
    },
});
