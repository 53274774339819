export const styles  = ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    height: 'calc(100vh - 250px)',
    '&>div>div>div>table>thead>tr': {
      height: '35px',
      '&>th': {
        padding: '4px 8px',
        textAlign: 'center'
      }
    },
    '&>div>div>div>table>tbody>tr': {
      height: '35px',
      '&>td': {
        padding: '4px 8px',
        textAlign: 'center',
        width: '100px',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    },
  },
});
