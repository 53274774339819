import Api from "../config/api";

export const findAgendamentoTipoByAtivo = async(profissionalSaudeId=null) => {
    const profissionalSaude = profissionalSaudeId ? `profissionalSaudeId: ${profissionalSaudeId}` : '';

    const response = await Api.post("", {
        query: `
        query {
            findAllAgendamentoTipo(ativo: true, pageableDTO: {sortDir: "ASC", sortField: "descricao"}, ${profissionalSaude}) {
                id
                descricao
                ativo
                codigo
                tuss22ProcedimentoEventoSaude {
                    id
                    codigoTermo
                    descricao
                }
                tuss52TerminologiaTipoConsulta {
                    id
                    codigoTermo
                    descricao
                }
            }
        }
    `,
    });

    if(response.data.errors) {
        throw new Error('Erro ao buscar tipos de agendamento');
    } ;

    return response;
}

export const findAllAgendamentoTipoPage = async(pageableDTO, search, ativo) =>{
    const response = await Api.post("", {
        query: `
        query ($pageableDTO: PageableDTOInput, $search: String, $ativo: Boolean) {
            findAllAgendamentoTipoPage(pageableDTO: $pageableDTO, search: $search, ativo: $ativo) {
              last
              totalPages
              numberOfElements
              content {
                id
                descricao
                ativo
                codigo
              }
            }
        }
    `,variables:{pageableDTO, search, ativo}
});
    return response
}

export const findAllAgendamentoTipo = () =>
    Api.post("", {
        query: `
        query {
            findAllAgendamentoTipo {
                id
                descricao
                ativo
                codigo
            }
        }
    `,
});


export const findAllTuss52TerminologiaTipoConsulta = async ( pageableDTO, ativo, search) => 
    await Api.post("", {
        query: `
        query ($pageableDTO: PageableDTOInput, $ativo: Boolean, $search: String) {
            findAllTuss52TerminologiaTipoConsulta(ativo: $ativo, pageableDTO: $pageableDTO, search: $search) {
              last
              numberOfElements
              totalElements
              totalPages
              content {
                id
                descricao
                ativo
                codigoTermo
                codigoTermoDescricao
              }
            }
          }          
        `,variables:{pageableDTO, ativo, search}
    })




export const findAllTuss22ProcedimentoEventoSaude = async (variables) => 
    await Api.post("", {
        query: `
        query ($pageableDTO: PageableDTOInput, $search: String, $ativo: Boolean) {
            findAllTuss22ProcedimentoEventoSaude(ativo: $ativo, pageableDTO: $pageableDTO, search: $search) {
              last
              numberOfElements
              totalElements
              totalPages
              content {
                id
                descricao
                ativo
                codigoTermo
                codigoTermoDescricao
              }
            }
        }
        `,variables: variables
    })




export const saveAgendamentoTipo = agendamentoTipo => {
    const method = agendamentoTipo.id ? 'updateAgendamentoTipo' : 'createAgendamentoTipo';
    return Api.post("", {
        query: `
            mutation ($agendamentoTipo: AgendamentoTipoInput) {
                ${method}(agendamentoTipo: $agendamentoTipo) {
                    id
                    descricao
                    ativo
                }
            }
        `,
        variables: { agendamentoTipo }
    });
};

export const findByIdAgendamentoTipo = id => Api.post("", {
    query: `
        query ($id: UUID) {
            findByIdAgendamentoTipo(id: $id) {
                id
                descricao
                ativo
                codigo
                destaque
                unidade {
                    id
                }
                tuss22ProcedimentoEventoSaude {
                    codigoTermo
                    ativo
                    descricao
                    id
                    codigoTermoDescricao
                }
                tuss52TerminologiaTipoConsulta {
                    codigoTermo
                    ativo
                    descricao
                    id
                    codigoTermoDescricao
                }
                convenioAgendamentoTipo {
                    convenio {
                        id
                    }
                    valor
                }
            }
        }
    `,
    variables: { id }
})

export const findAllAgendamentoTipoSearch = async(search, pageNumber) =>{
    const pageableDTO = {
        pageNumber,
        pageSize: 30,
        sortDir: "ASC",
        sortField: "descricao"
    };
    const ativo = true;

    const response = await Api.post("", {
        query: `
        query($pageableDTO: PageableDTOInput, $ativo: Boolean, $search: String){
            findAllAgendamentoTipoPage(pageableDTO: $pageableDTO, ativo: $ativo, search: $search){
                last
                content{
                    id
                    descricao
                }
            }
        }
    `,
    variables:{
        pageableDTO, 
        ativo, 
        search
    }
});
    return response
}

export const agendamentoTipoNomeJaExistente = async search => {
    const response =  await Api.post("", {
    query: `
      {
        agendamentoTipoNomeJaExistente(search: "${search}")
      }`
    })
    
    return response.data.data.agendamentoTipoNomeJaExistente
  };