import React, { useState, useRef } from "react";
import { inject } from "mobx-react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import styles from "./SujeitoAtencaoAnexoStyle";
import Dialog from "../../../../components/Dialog/Dialog";
import { createSujeitoAtencaoAnexos } from "../../../../services/SujeitoAtencaoAnexoService";
import Notification from "../../../../components/Notification";
import ButtonDashed from "../../../../components/Button/ButtonDashed";
import ButtonLong from "../../../../components/Button/ButtonLong";
import ItemAnexo from "./ItemAnexo";
import { uploadArquivos } from "../../../../utils/uploadArquivo";

const ModalAnexo = (props) => {
  const {
    open,
    closeModal,
    classes,
    anexoSelecionadoUpload,
    sujeitoAtencaoStore,
    reloadAnexos,
  } = props;

  const [anexos, setAnexos] = useState(anexoSelecionadoUpload);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    variant: "",
  });

  const documentoFileInput = useRef();

  const onNomeChange = (e, index) => {
    const { value } = e.target;

    setAnexos([
      ...anexos.slice(0, index),
      { ...anexos[index], nome: value },
      ...anexos.slice(index + 1),
    ]);
  };

  const save = async () => {
    try {
      setLoading(true);

      const listaAnexos = anexos.map((anexo) => {
        delete anexo.tamanho;
        return {
          tipo: anexo.image ? "IMAGEM" : "ARQUIVO",
          anexo: anexo,
        };
      });

      await createSujeitoAtencaoAnexos({
        sujeitoAtencaoId: sujeitoAtencaoStore.objView.id,
        anexos: listaAnexos,
      });

      const notification = {
        isOpen: true,
        message: "Anexos salvo com sucesso.",
        variant: "success",
      };
      showAlertMessage(notification);
      reloadAnexos();
      closeModal();
    } catch {
      const notification = {
        isOpen: true,
        message: "Erro ao salvar",
        variant: "error",
      };
      showAlertMessage(notification);
    } finally {
      setLoading(false);
    }
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
      variant: "",
    };
    setNotification(notification);
  };

  const openEnviarArquivo = () => {
    const fileInput = documentoFileInput && documentoFileInput.current;

    if (!fileInput instanceof HTMLElement) {
      return;
    }
    fileInput.value = "";
    fileInput.click();
  };

  const handleSelectDocumento = async () => {
    const filesArray = Object.values(documentoFileInput.current?.files);
    const listaArquivos = await uploadArquivos(filesArray);
    setAnexos([...anexos, ...listaArquivos]);
  };

  const excluirItem = (index) => {
    setAnexos([
      ...anexos.slice(0, index),
      ...anexos.slice(index + 1),
    ]);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.contentModalAnexo}>
        <div>
          <div className={classes.titleHeaderModalAnexo}>
            Adicionar arquivos
          </div>
          <div className={classes.listaAnexos}>
            {anexos && anexos.map((anexo, index) => {
              return (
                <ItemAnexo
                  key={index}
                  onNomeChange={(e) => onNomeChange(e, index)}
                  anexo={anexo}
                  excluirItem={() => excluirItem(index)}
                  loading={loading}
                />
              );
            })}
          </div>
        </div>
        <div>
          <ButtonDashed
            onClick={openEnviarArquivo}
            disabled={loading}
            className={classes.buttonDashed}
          >
            + adicionar mais arquivos
          </ButtonDashed>
          <div className={classes.contentButtons}>
            <ButtonLong
              colorCustom="green"
              onClick={save}
              disabled={loading}
              className={classes.buttonSaveAnexo}
            >
              Salvar
            </ButtonLong>
            <div
              className={classNames(classes.buttonCancelarAnexo, {[classes.buttonCancelarAnexoDisabled]: loading})}
              onClick={!loading && closeModal}
            >
              Cancelar
            </div>
          </div>
        </div>
        <input
          ref={documentoFileInput}
          type="file"
          multiple
          className={classes.documentoFileInput}
          onChange={(e) => handleSelectDocumento(e)}
        />
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </div>
    </Dialog>
  );
};

const ModalAnexodWithStyles = withStyles(styles)(ModalAnexo);
export default inject("sujeitoAtencaoAnexoStore", "sujeitoAtencaoStore")(
  ModalAnexodWithStyles
);
