import React, { useEffect, useReducer, useState } from 'react'

import { withStyles } from '@material-ui/core'

import { SessoesContent } from './components'
import ModalCreateSession from './components/SessoesContent/Modals/CreateSession'
import SpeedDials from '../../../../components/SpeedDials'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'

const PacoteSessoes = observer(({ classes, history, controleSessaoStore, atendimentoStore }) => {
  const [typeOfPacote, setTypeOfPacote] = useState('')
  const [openModalCreate, toggleModalCreate] = useReducer(state => !state, false)
  const sujeitoAtencaoId = window.location.href.split('/')[6]

  const actions = [
    {
      name: 'Novo procedimento',
      buttonClasses: classes.buttonAddProcedimento,
      tipo: 'PROCEDIMENTO',
    },
    {
      name: 'Nova consulta',
      buttonClasses: classes.buttonAddConsulta,
      tipo: 'CONSULTA',
    },
  ]

  const handleRedirect = async id => {
    await controleSessaoStore.resetParamsSessoes()
    history.push(`/sujeito-atencao/edit/${sujeitoAtencaoId}/sessoes-em-andamento/${id}`)
  }

  const handleOpenModalCreate = type => {
    toggleModalCreate()
    setTypeOfPacote(type)
  }

  const handleClickAction = action => {
    handleOpenModalCreate(action.tipo)
  }

  useEffect(() => {
    atendimentoStore.onExit()
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.wrapperTitle}>
        <h1>Sessões em andamento</h1>
      </div>
      <SessoesContent
        handleRedirect={handleRedirect}
        history={history}
        handleOpenModalCreate={handleOpenModalCreate}
      />
      <div className={classes.floatButtonsContainer}>
        <SpeedDials actions={actions} onClickAction={handleClickAction} withTooltip={false} />
      </div>
      {openModalCreate && (
        <ModalCreateSession
          open={openModalCreate}
          onClose={toggleModalCreate}
          type={typeOfPacote}
        />
      )}
    </div>
  )
})

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '24px',
    overflow: 'hidden',
    backgroundColor: '#F5F5F5',
  },
  buttonAddProcedimento: {
    backgroundColor: '#707C97 !important',
    width: 'fit-content',
    borderRadius: '100px',
    border: '2px solid rgba(220, 220, 220, 0.2)',
    padding: '8px 16px',
    color: '#fff',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
  },

  buttonAddConsulta: {
    backgroundColor: '#26ACA9 !important',
    width: 'fit-content',
    borderRadius: '100px',
    border: '2px solid rgba(220, 220, 220, 0.2)',
    padding: '8px 16px',
    color: '#fff',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
  },

  floatButtonsContainer: {
    position: 'absolute',
    bottom: 5,
    right: 37,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'end',
    columnGap: '8px',
    '& > div': {
      alignItems: 'flex-end',
    },
  },
  wrapperTitle: {
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      fontSize: '18px',
      margin: '0',
      fontWeight: '700',
    },
  },
})

const PacoteSessoesWithStyles = withStyles(styles)(PacoteSessoes)
export default inject('controleSessaoStore', 'atendimentoStore')(PacoteSessoesWithStyles)
