import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ThumbsDownEmptyIcon = props => {
  return (
    <svg 
      width="18" 
      height="22" 
      viewBox="0 0 18 22" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path 
        d="M13.4996 16.9847C13.4996 19.4266 12.3595 21.1834 10.4932 21.1834C9.5183 21.1834 9.15177 20.6406 8.80212 19.3876L8.59597 18.6157C8.49503 18.257 8.31924 17.6462 8.06895 16.7847C8.06226 16.7616 8.0524 16.7398 8.03957 16.7198L5.17281 12.2341C4.49476 11.1732 3.49429 10.3577 2.31841 9.90745L1.84513 9.72624C0.598402 9.24891 -0.125426 7.94513 0.128697 6.63456L0.533195 4.54848C0.774622 3.30339 1.71828 2.31249 2.9501 2.01057L10.5778 0.141041C13.109 -0.47935 15.6674 1.05558 16.3113 3.58091L17.7262 9.13007C18.1697 10.8693 17.1192 12.6388 15.3799 13.0823C15.1175 13.1492 14.8478 13.183 14.5769 13.183H12.7536C13.2497 14.8159 13.4996 16.0757 13.4996 16.9847ZM1.60127 6.9201C1.48576 7.51581 1.81477 8.10844 2.38147 8.32541L2.85475 8.50661C4.33036 9.07158 5.58585 10.095 6.43674 11.4263L9.3035 15.912C9.39354 16.0529 9.46292 16.206 9.50949 16.3665L10.0614 18.2868L10.2731 19.0781C10.4125 19.5661 10.4827 19.6834 10.4932 19.6834C11.3609 19.6834 11.9996 18.6993 11.9996 16.9847C11.9996 16.1002 11.6738 14.6492 11.0158 12.6696C10.8544 12.1841 11.2158 11.683 11.7275 11.683H14.5769C14.7228 11.683 14.868 11.6648 15.0093 11.6288C15.9459 11.39 16.5115 10.4372 16.2727 9.50067L14.8578 3.95152C14.4172 2.22366 12.6668 1.17344 10.9349 1.59792L3.30718 3.46745C2.64389 3.63002 2.13577 4.16358 2.00577 4.83402L1.60127 6.9201Z" 
        fill="currentColor"
      />
    </svg>
  );
};

export default ThumbsDownEmptyIcon;