const styles = theme => ({
  auditoriaBody: {
    display: 'flex',
    height: '100%',
    width: '100%',
    paddingBottom:'2rem',
    flexDirection: 'column',
    "&> div": {
      margin: '1rem 0rem 0rem 1rem',
    }
  },
  profissionalProfileImage: {
    borderRadius: '50%',
  },
  auditoriaText: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: '16px',
  },
  auditoriaItem: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '8px',
    margin: '0 16px 12px 0',
  },
  auditoriaItemInformacao: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '6px',
    borderRadius: '4px',
    '& strong':{
      paddingRight: '0.3rem',
    },
    '& p': {
      margin: 0,
      fontSize: '14px',
      color: '#505050',
    },
    '& ul': {
      margin: 0,
      '& li': {
        color: '#505050',
        fontSize: '14px',
        wordBreak: 'break-word',
      },
    },
  },
  auditoriaItemData: {
    color: '#ADADAD',
    margin: '8px 0 0 0',
    padding: 0,
    fontSize: '12px',
  },
  auditoriaNotFoundItens: {
    display: 'flex',
    alignItens: 'flex-start',
    marginBottom: '2rem',
    marginTop: '3rem',
    color: '#1A202C',
    fontWeight: '600',
    fontSize: '15px',
    marginLeft: '1rem',
  },
})

export default styles
