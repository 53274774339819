import React, { useMemo, useState } from 'react'
import { Tooltip, withStyles } from '@material-ui/core'

import AlertModal from '../../../components/Modal/AlertModal'
import InfoIconFilled from '../../../components/Icon/InfoIconFilled'
import { findAllCid10Subcategoria } from '../../../services/RelatorioService'
import { TextFieldSearch } from '../../../components/TextField'
import CloseIcon from '../../../components/Icon/CloseIcon'
import { Button } from '../../../components/ui/Buttons'
import { persistCIDByAgendamento } from '../../../services/ProntuarioService'

const ModalCID = ({
  open,
  onClose,
  classes,
  agendamentoSelected,
  onStopAgendamento,
  openFromStop,
}) => {
  const { cid10Subcategorias: cidsAgendamento } = agendamentoSelected || {}

  const [cid10Subcategorias, setCid10Subcategorias] = useState(cidsAgendamento)
  const [isLoading, setIsLoading] = useState(false)

  const loadCID10Subcategorias = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = await findAllCid10Subcategoria({
        searchDto: {
          pageSize: 30,
          pageNumber: page,
          sortDir: 'ASC',
          sortField: 'descricaoAbreviada',
          search,
        },
      })
      const filteredOptions = content.filter(option => {
        return !cid10Subcategorias?.some(cid => cid.id === option.id)
      })
      return {
        options: filteredOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = value => {
    !!value && setCid10Subcategorias(prevState => {
      return [...prevState, value]
    })
  }

  const handleRemove = id => {
    setCid10Subcategorias(prevState => {
      return prevState.filter(item => item?.id !== id)
    })
  }

  const onSubmit = async e => {
    setIsLoading(true)
    try {
      await persistCIDByAgendamento({
        agendamento: {
          id: agendamentoSelected.id,
        },
        cid10Subcategorias: cid10Subcategorias.map(item => {
          return {
            id: item?.id,
          }
        }),
      })
      if (agendamentoSelected?.dataInicioAtendimento && openFromStop) {
        await onStopAgendamento(e)
      }
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const disabledButton = useMemo(() => {
    return cid10Subcategorias?.length === 0
  }, [cid10Subcategorias])

  return (
    <AlertModal
      open={open}
      onClose={onClose}
      icon={<InfoIconFilled color='white' size={24} />}
      title={
        agendamentoSelected?.cid10Subcategorias?.length === 0
          ? 'Informe o CID para esse atendimento'
          : 'Para finalizar o atendimento é necessário informar o CID'
      }
    >
      <div className={classes.container}>
        <div className={classes.contentModal}>
          <TextFieldSearch
            placeholder='Selecione'
            key={`cid10Subcategorias-${cid10Subcategorias?.length}`}
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            loadOptions={loadCID10Subcategorias}
            getOptionLabel={option => option?.descricaoAbreviada}
            getOptionValue={option => option?.id}
            withPaginate
            menuPlacement='auto'
            value={null}
            onChange={handleChange}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
          <div className={classes.listaCidSelecionados}>
            {cid10Subcategorias?.length > 0 &&
              cid10Subcategorias.map((item, index) => (
                <div className={classes.cidOption} key={index}>
                  <Tooltip
                    title={item?.descricaoAbreviada}
                    placement='top'
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <span>{item?.descricaoAbreviada}</span>
                  </Tooltip>

                  <CloseIcon onClick={() => handleRemove(item?.id)} />
                </div>
              ))}
          </div>
        </div>
        <Button onClick={onSubmit} isLoading={isLoading} disabled={disabledButton || isLoading}>
          Confirmar
        </Button>
      </div>
    </AlertModal>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    '&>button': {
      width: 'fit-content',
      marginLeft: 'auto',
    },
  },
  contentModal: {
    maxHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    overflow: 'auto',
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    fontSize: '0.875rem',
    fontWeight: '600',
    height: '32px',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
  classIcons: {
    fontSize: 24,
  },
  listaCidSelecionados: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
    gap: '5px',
  },
  cidOption: {
    background: '#F2F2F2',
    border: '0.5px solid #BDBDBD',
    borderRadius: '100px',
    display: 'grid',
    gridTemplateColumns: '1fr 20px',
    alignItems: 'center',
    padding: '4px 8px',
    '& span': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& svg': {
      width: '16px',
      height: '16px',
      color: '#868686',
      cursor: 'pointer',
    },
  },
  tooltip: {
    background: '#5F6368',
    opacity: 1,
    fontFamily: 'Poppins !important',
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: '400',
    border: '1px solid rgba(220, 220, 220, 0.2)',
  },
}

export default withStyles(styles)(ModalCID)
