import React from "react";
import moment from "moment";

const renderItem = ({ styles, item, index }) => {
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" }

    }
    return ''
  }
  return (
    <div style={{ ...styles.containerColuna, ...columnColor(index) }} key={index}>
      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.quemIndicou || "-"}</div>
      </div>
      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.quantidade}</div>
      </div>
    </div>
  );
};

const RelatorioIndicacoesDocument = (props) => {
  const { pesquisadoPor, dataInicio, dataFim, situacao, indicacoes } = props;

  const dataFormatada = (tipo) => {
    const data = tipo === "inicio" ? dataInicio : dataFim;

    return data ? moment(data).format("DD/MM/YYYY") : " - ";
  };

  return (
    <>
      <div style={styles.titulo}>
        <div style={styles.textBold}>Relatório indicações por data</div>
      </div>
      <div style={styles.subTitulo}>
        <div style={styles.textBold}>
          Pesquisado por: {pesquisadoPor || " - "}
        </div>
        <div style={styles.textBold}>
          Data início: {dataFormatada("inicio")}
        </div>
        <div style={styles.textBold}>Data fim: {dataFormatada("fim")}</div>
        <div style={styles.textBold}>Situação: {situacao}</div>
      </div>

      <div style={styles.containerColuna}>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>Indicado por</div>
        </div>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>Quantidade</div>
        </div>
      </div>

      {indicacoes.map((item, index) => renderItem({ styles, item, index }))}
    </>
  );
};

const styles = {
  text: {
    fontSize: 12,
  },
  textBold: {
    fontSize: 12,
    fontWeight: 700,
  },
  titulo: {
    marginLeft: 0,
    marginBottom: 10,
    marginTop: 5,
    paddingBottom: 5,
    textAlign: "center",
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: 15,
    paddingBottom: 5,
    justifyContent: "space-between",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
  },
  colunaDescricao: {
    flexGrow: "2",
    width: "90px",
    marginBottom: "10px",
    marginRight: "3px",
  },
};

export default RelatorioIndicacoesDocument;
