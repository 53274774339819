import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { Tooltip, withStyles } from '@material-ui/core'

import { Button } from '../../../../../components/ui/Buttons'
import InfoAdicionais from '../../../InfoAdicionais'
import { InputCamposProntuario } from '../../../../../components/Input/InputCamposProntuario'

import SettingsIcon from '../../../../../components/Icon/Settings'
import CloseIcon from '../../../../../components/Icon/CloseIcon'

import string from '../../../../../utils/string'
import { calculateAge } from '../../utils/formatAge'
import { useDebounce } from '../../utils/useDebounce'
import moment from 'moment'

const SelectedFields = observer(({ classes, prontuarioStore, handleEditarCamposProntuario, handleOpenCIDModal }) => {
  const { sujeitoAtencaoSelected, selectedCamposProntuario, agendamentoSelected } = prontuarioStore
  const [camposProntuario, setCamposProntuario] = useState([])
  const [searchTerm, setSearchTerm] = useState({ value: '', field: '' })
  const [disableField, setDisableField] = useState(false)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const loadCamposProntuario = async () => {
    try {
      const response = await prontuarioStore.findAllCampoProntuarioByProfissionalSaudeLogadoList()
      setCamposProntuario(response)
    } catch (error) {
      console.error(error)
    }
  }

  const getCampoProntuarioValue = campoProntuario => {
    const valor = campoProntuario.caminhoValor
    const valorCampo = string.getValueByPath(sujeitoAtencaoSelected, valor)
    if (campoProntuario.campo === 'IDADE' && valorCampo) {
      return calculateAge(valorCampo)
    }
    if(campoProntuario.campo === 'DATA_NASCIMENTO' && valorCampo) {
      return moment(valorCampo).format('DD/MM/YYYY')
    }
    if (campoProntuario.campo && valorCampo) {
      return string.capitalizeEachWord(String(valorCampo))
    }
    return ''
  }

  const handleChangeCampoProntuario = (e, field) => {
    if (!sujeitoAtencaoSelected) return
    const campo = string.capitalizeSecondWord(field)
    sujeitoAtencaoSelected[campo] = e.target.value
    setSearchTerm({ value: e.target.value, field })
  }

  const handleRemoveCampoProntuario = async campo => {
    try {
      setDisableField(true)
      const campoProntuario = camposProntuario.filter(
        item => item.campoProntuario.campo !== campo.campoProntuario.campo
      )
      const newCamposProntuario = campoProntuario.map(item => {
        return {
          campo: item.campoProntuario.campo,
          nome: item.campoProntuario.nome,
          editavel: item.campoProntuario.editavel,
          caminhoValor: item.campoProntuario.caminhoValor,
          ordem: item.ordem,
        }
      })
      await prontuarioStore.updateFiltroCampoProntuarioProfissionalSaudeLogado(newCamposProntuario)
      await loadCamposProntuario()
    } catch (error) {
      console.error(error)
    } finally {
      setDisableField(false)
    }
  }

  const getLabelsCids = () => {
    const { cid10Subcategorias } = agendamentoSelected || {}
    return cid10Subcategorias?.map(item => string.truncate(item.descricao, 25)).join(', ')
  }

  useEffect(() => {
    loadCamposProntuario()
  }, [selectedCamposProntuario])

  useEffect(() => {
    if (searchTerm.value.length === 0) return
    prontuarioStore.updateSujeitoAtencaoNoAtendimento(searchTerm.value, searchTerm.field)
  }, [debouncedSearchTerm])

  return (
    <nav className={classes.container}>
      <div className={classes.wrapperCID}>
        <Button kind='secondary' shape='pill' size='mini' onClick={handleOpenCIDModal} fullWidth>
          <Tooltip
            title={getLabelsCids()}
            placement='top'
            classes={{ tooltip: classes.tooltip }}
          >
            <div className={classes.contentIcon}>
              CID
              {agendamentoSelected?.cid10Subcategorias?.length > 0 &&
              <div className={classes.roundedTag}>{agendamentoSelected?.cid10Subcategorias.length > 99 ? '99+' : agendamentoSelected?.cid10Subcategorias.length}</div>}
            </div>
          </Tooltip>
        </Button>
      </div>
      <div className={classes.swiperContainer}>
        {camposProntuario.map((item, index) => {
          return item.campoProntuario.editavel ? (
            <div className={classes.wrapperEditableFields}>
              <InfoAdicionais informacao={`${item.campoProntuario.nome}:`} key={index}>
                <InputCamposProntuario
                  className={classes.inputEditable}
                  classInput={classes.inputBlockField}
                  classInputRoot={classes.inputRootBlockField}
                  color={'#505050'}
                  value={getCampoProntuarioValue(item.campoProntuario)}
                  mask={item.campoProntuario.campo}
                  onChange={e =>
                    handleChangeCampoProntuario(e, item.campoProntuario.campo.toLowerCase())
                  }
                />
              </InfoAdicionais>
              {item.ordem === 2 && <div className={classes.divider} />}
            </div>
          ) : (
            <div className={classes.wrapperFixedFields}>
              <InfoAdicionais informacao={`${item.campoProntuario.nome}:`} key={index}>
                <div className={classes.infoAdicional}>
                  {getCampoProntuarioValue(item.campoProntuario)}
                  <Button
                    kind='transparent'
                    shape='square'
                    size='mini'
                    disabled={disableField}
                    onClick={() => handleRemoveCampoProntuario(item)}
                  >
                    <CloseIcon color='#33333' size='9' />
                  </Button>
                </div>
              </InfoAdicionais>
            </div>
          )
        })}
      </div>

      <div className={classes.wrapperButton}>
        <Button
          kind='transparent'
          shape='square'
          size='mini'
          onClick={handleEditarCamposProntuario}
        >
          <SettingsIcon color='#33333' />
        </Button>
      </div>
    </nav>
  )
})

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  swiperContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '70vw',
    zIndex: 10,
  },
  wrapperEditableFields: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      backgroundColor: 'white',
      padding: '0.2rem 0rem',
    },
  },
  wrapperCID: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      minWidth: '60px',
    },
  },
  wrapperFixedFields: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      background: '#F8F8F8',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: '100px',
      padding: '0px 8px',
    },
  },
  wrapperButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > button': {
      padding: '0px',
      margin: ' 0 1rem',
    },
  },

  divider: {
    width: '1px',
    height: '20px',
    borderRadius: '50%',
    padding: '0.5px !important',
    backgroundColor: '#E5E5E5 !important',
  },

  inputEditable: {
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    justifyContent: 'center',
    minWidth: 60,
    border: '1px solid rgba(220, 220, 220, 0.2)',
    maxHeight: 22,
    maxWidth: 80,
  },
  inputRootBlockField: {
    paddingLeft: 0,
    border: 'none',
    fontSize: 14,
  },

  inputBlockField: {
    fontFamily: 'Poppins !important',
    padding: '5px 0',
    textAlign: 'center',
    fontWeight: 'normal !important',
    '::-webkit-input-placeholder': {
      color: 'pink',
    },
  },
  infoAdicional: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      padding: '0px 6px',
    },
  },
  tooltip: {
    background: '#5F6368',
    opacity: 1,
    fontFamily: 'Poppins !important',
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: '400',
    border: '1px solid rgba(220, 220, 220, 0.2)',
  },
  contentIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
  },
  roundedTag: {
    width: '16px',
    height: '16px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px !important',
    fontWeight: '700 !important',
    color: '#FFF',
    backgroundColor: '#5462E0',
    border: '1px solid rgba(0, 0, 0, 0.10)',
  },
}

const SelectedFieldsWithStyles = withStyles(styles)(SelectedFields)

export default inject('prontuarioStore')(SelectedFieldsWithStyles)
