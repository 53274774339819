import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import Tooltip from '@material-ui/core/Tooltip';
import CopyIcon from '../Icon/CopyIcon';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '8px',
    height: '32px',
    backgroundColor: '#F2F2F2'
  },
  inputContainer: {
    display: 'flex',
    flex: 1,
  },
  input: {
    flex: 1,
    border: 'none',
    backgroundColor: 'transparent',
    margin: '5px 4px 5px 8px',
    color: '#505050',
    fontWeight: '600'
  },
  copyButton: {
    height: '20px',
    width: '20px',
    "&:hover": {
      cursor: 'pointer'
    }
  },
  textToolTip: {
    fontSize: '12px',
    maxWidth: '400px',
    margin: '10px 0 0 0'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 8px 7px 8px',
    borderRadius: '0 8px 8px 0',
    border: 'solid 1px rgba(0, 0, 0, 0.1)'
  }
});

const ClipboardCopy = ({ text, classes, isShortUrl, baseUrl, disabled, emptyFieldMessage, bgColor, onChange, value }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [hoverInput, setHoverInput] = useState(false);
  const [hoverButton, setHoverButton] = useState(false);

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = async () => {
    if(typeof onChange === 'function' && value) {
      await copyTextToClipboard(value);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
      return;
    }
    await copyTextToClipboard(`${baseUrl}${text}`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const getValue = () => {
    if (!disabled) {
      return isShortUrl ? `...${text}` : `${baseUrl}${text}`;
    } else {
      return '';
    }
  }

  const getTooltipText = () => {
    if (!disabled && typeof onChange !== 'function') {
      return isShortUrl ? `${baseUrl}${text}` : `${baseUrl}${text}`;
    } else if (!disabled && typeof onChange === 'function') {
      return value || ''
    } else {
      return '';
    }
  }

  return (
    <div className={classes.container}>
      <Tooltip
        open={!disabled && hoverInput}
        classes={{
          tooltip: classes.textToolTip
        }}
        title={getTooltipText()}
        placement="bottom-start"
      >
        <div
          className={classes.inputContainer}
          onMouseEnter={() => setHoverInput(true)}
          onMouseLeave={() => setHoverInput(false)}
        >
          <input 
            placeholder={emptyFieldMessage}
            value={typeof onChange === 'function' ? value : getValue()} 
            onChange={onChange}
            disabled={disabled}
            className={classes.input}
          />
        </div>
      </Tooltip>
      <Tooltip 
        open={!disabled && hoverButton}
        title={isCopied ? "Copiado!" : "Copiar"} 
        placement="right"
      >
        <div 
          style={{ backgroundColor: bgColor ? bgColor : '#F9BE73' }}
          className={classes.iconContainer}
          onClick={!disabled ? handleCopyClick : null} 
          onMouseEnter={() => setHoverButton(true)}
          onMouseLeave={() => setHoverButton(false)}
        >
          <CopyIcon 
            color="#F9F9F9"
            size={15}
          />
        </div>
      </Tooltip>
    </div>
  )
}

export default withStyles(styles)(ClipboardCopy);