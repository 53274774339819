import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import React, { useMemo } from "react";
import { Grid, Checkbox } from "@material-ui/core";
import Colors from "../../template/Colors";
import { Button } from "../../components/ui/Buttons";

const AtendimentoModalTabProcedimentosAgendamento = (props) => {
  const {
    classes,
    procedimentosSolicitados,
    handleChangeCheckbox,
    onClickAgendarProcedimento,
    observacaoGeral,
  } = props;

  const disableButton = useMemo(() => {
    return procedimentosSolicitados?.every((item) => item.agendado);
  }, [procedimentosSolicitados]);

  return (
    <div className={classes.contentList}>
      <div className={classes.paperListProcedimentos}>
        {procedimentosSolicitados &&
          procedimentosSolicitados.map((item) => (
            <Grid
              container
              direction={"row"}
              wrap={"nowrap"}
              key={item.id}
              className={classes.procedimentoCheckBox}
            >
              <Grid item>
                <Checkbox
                  color={"primary"}
                  onChange={() => handleChangeCheckbox(item)}
                  checked={item.checked || item.agendado}
                  disabled={item.agendado}
                />
              </Grid>
              <Grid item size={5}>
                {item?.procedimento?.nome}
              </Grid>
            </Grid>
          ))}
      </div>
      <div className={classes.paperListObservacoes}>
        <h3> Observações gerais: </h3>
        <p> {observacaoGeral && observacaoGeral} </p>
      </div>
      <Button
        disabled={disableButton}
        onClick={onClickAgendarProcedimento}
        bgColor={Colors.commons.yellow}
        style={{ marginBottom: "8px" }}
      >
        Agendar procedimento
      </Button>
    </div>
  );
};

const styles = {
  contentList: {
    height: "calc(100% - 32px)",
    overflow: "auto",
    display: "grid",
    gridTemplateRows: "1fr 125px auto",
    gap: "8px",
  },
  labelBotaoAdicionarRemedio: {
    color: "white",
    fontWeight: 500,
    textAlign: "center",
  },
  paperListObservacoes: {
    overflow: "auto",
    boxShadow: "none",
    backgroundColor: Colors.commons.white,
    borderRadius: "10px",
    color: "#505050",
    "& h3, p": {
      margin: "8px",
    },
  },
  paperListProcedimentos: {
    overflow: "auto",
    boxShadow: "none",
    backgroundColor: Colors.commons.white,
    borderRadius: "10px",
  },
  procedimentoCheckBox: {
    alignSelf: "center",
  },
};

const AtendimentoModalTabProcedimentosWithStyles = withStyles(styles)(
  AtendimentoModalTabProcedimentosAgendamento
);
export default inject("atendimentoStore")(
  AtendimentoModalTabProcedimentosWithStyles
);
