import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const LocationIcon = (props) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M6 1C8.48528 1 10.5 3.01472 10.5 5.5C10.5 7.36262 9.0802 9.31485 6.3 11.4C6.12222 11.5333 5.87778 11.5333 5.7 11.4C2.9198 9.31485 1.5 7.36262 1.5 5.5C1.5 3.01472 3.51472 1 6 1ZM6 4C5.17157 4 4.5 4.67157 4.5 5.5C4.5 6.32843 5.17157 7 6 7C6.82843 7 7.5 6.32843 7.5 5.5C7.5 4.67157 6.82843 4 6 4Z" fill="currentColor"/>
    </svg>
  );
};

export default LocationIcon;
