import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DocumentOnePageIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M5.5 2C4.67157 2 4 2.67157 4 3.5V16.5C4 17.3284 4.67157 18 5.5 18H14.5C15.3284 18 16 17.3284 16 16.5L16 3.5C16 2.67157 15.3284 2 14.5 2H5.5ZM12.5899 6.99194L12.5 7H7.5C7.22386 7 7 6.77614 7 6.5C7 6.25454 7.17688 6.05039 7.41012 6.00806L7.5 6H12.5C12.7761 6 13 6.22386 13 6.5C13 6.74546 12.8231 6.94961 12.5899 6.99194ZM12.5899 10.4919L12.5 10.5H7.5C7.22386 10.5 7 10.2761 7 10C7 9.75454 7.17688 9.55039 7.41012 9.50806L7.5 9.5H12.5C12.7761 9.5 13 9.72386 13 10C13 10.2455 12.8231 10.4496 12.5899 10.4919ZM12.5899 13.9919L12.5 14H7.5C7.22386 14 7 13.7761 7 13.5C7 13.2545 7.17688 13.0504 7.41012 13.0081L7.5 13H12.5C12.7761 13 13 13.2239 13 13.5C13 13.7455 12.8231 13.9496 12.5899 13.9919Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DocumentOnePageIcon;
