import React from 'react';
import { IconButton, withStyles } from "@material-ui/core";
import Dialog from '../Dialog/Dialog';
import { Button } from '../../components/ui/Buttons';


const IconAlert = require('../../assets/img/svg/icon-alert.svg');
const IconDismiss = require('../../assets/img/svg/dismiss.svg');

const styles = ({
    popper: {
        heigt: 'fit-content'
    },
    container: {
        display: 'flex',
        flexDirection: "column",
        padding: '20px',
        width: '280px',
    },
    headerIcons: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    title: {
        fontSize: '18px !important',
        fontWeight: '600',
        color: '#505050',
        margin: "5px 0px"
    },
    message: {
        fontWeight: '400',
        color: '#505050',
        marginBottom: "20px"
    },
    buttonArea: {
        display: 'flex',
        flexDirection: 'row',
    }
});

const ButtonStyle = {
    height: '36px',
    textTransform: 'none',
    width: '50%',
    borderRadius: '100px'
};

const ButtonConfirmStyle = {
    backgroundColor: '#FB7676',
};

const ButtonCancelStyle = {
    backgroundColor: '#FFF',
    color: '#505050'
}

const ConfirmAlertModal = ({ open, onClose, title, text, onCancel, onConfirm, classes, isLoading, titleSecundaryButton }) => {
    return (
        <Dialog open={open} className={classes.open}>
            <div className={classes.container}>
                <div className={classes.headerIcons}>
                    <img src={IconAlert} alt="ícone de alerta" />
                    <IconButton onClick={onClose} style={{ backgroundColor: "#ffff" }} >
                        <img src={IconDismiss} alt="close" />
                    </IconButton>
                </div>
                <span className={classes.title}>{title}</span>
                <span className={classes.message}>{text}</span>
                <div>
                    <Button onClick={onCancel} style={{ ...ButtonStyle, ...ButtonCancelStyle }}  >
                        Cancelar
                    </Button>
                    <Button onClick={onConfirm} style={{ ...ButtonStyle, ...ButtonConfirmStyle }} disabled={isLoading}>
                        {titleSecundaryButton}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
};

export default withStyles(styles)(ConfirmAlertModal);
