import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const CancelarAgendamentoIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  {...normalizeProps(props)}>
      <path d="M18 9.02182V5.5H0V14.75C0 16.5449 1.45507 18 3.25 18H9.02182C8.375 16.9897 8 15.7886 8 14.5C8 10.9101 10.9101 8 14.5 8C15.7886 8 16.9897 8.375 18 9.02182ZM18 3.25C18 1.45507 16.5449 0 14.75 0H3.25C1.45507 0 0 1.45507 0 3.25V4H18V3.25ZM20 14.5C20 11.4624 17.5376 9 14.5 9C11.4624 9 9 11.4624 9 14.5C9 17.5376 11.4624 20 14.5 20C17.5376 20 20 17.5376 20 14.5ZM14 11.5C14 11.2239 14.2239 11 14.5 11C14.7761 11 15 11.2239 15 11.5V15.5C15 15.7761 14.7761 16 14.5 16C14.2239 16 14 15.7761 14 15.5V11.5ZM15.125 17.5C15.125 17.8452 14.8452 18.125 14.5 18.125C14.1548 18.125 13.875 17.8452 13.875 17.5C13.875 17.1548 14.1548 16.875 14.5 16.875C14.8452 16.875 15.125 17.1548 15.125 17.5Z" fill="currentColor" />
    </svg>
  );
};

export default CancelarAgendamentoIcon;
