export const WHITELABEL_URLS = {
  development: {
    VIZI_SAUDE: 'https://homologacao.vizisaude.com.br/',
    ANGEPLUS: 'https://cuidadosesaude.hml.angeplus.com.br',
    PARTIC: '',
    SANTO_SAUDE: '',
    _3DPE: '',
    SOS_RS: '',
    TAIP_SAUDE: 'https://homolog.app.taipsaude.com.br/',
  },
  production: {
    VIZI_SAUDE: 'https://vizisaude.com.br/',
    ANGEPLUS: 'https://cuidadosesaude.hml.angeplus.com.br',
    PARTIC: 'https://www.partic.com.br/',
    SANTO_SAUDE: '',
    _3DPE: '',
    SOS_RS: '',
    TAIP_SAUDE: 'https://app.taipsaude.com.br/',
  }
};
