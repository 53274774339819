import { withStyles } from "@material-ui/core/es";
import classNames from "classnames";
import React from "react";
import moment from "moment";
import colors from "../../template/Colors";

const StatusPagamento = (props) => {
  const { classes, dataVencimento, dataPagamento, status } = props;

  const statusPagamento = () => {
    if (status) {
      return status
    }
    else {
      if (dataPagamento) {
        return "Pago";
      } else {
        if (moment(dataVencimento).isSameOrAfter(moment(), "date")) {
          return "Aberto";
        } else {
          return "Atrasado";
        }
      }
    } 
  }

  return (
    <div className={classes.content}>
      <div className={classNames(classes.statusPagamento, statusPagamento())}>
        <div className={classNames(classes.iconStatus, `${statusPagamento()}Icon`)}/>
        {statusPagamento()}
      </div>
    </div>
  );
};

const styles = {
  content: {
    "& .Pago": {
      backgroundColor: colors.commons.greenLight,
      border: `0.5px solid ${colors.commons.green}`,
    },
    "& .Atrasado": {
      backgroundColor: colors.commons.redLight,
      border: `0.5px solid ${colors.commons.red}`,
    },
    "& .Aberto": {
      backgroundColor: colors.commons.yellowLight,
      border: `0.5px solid ${colors.commons.yellow}`,
    },
    "& .Excluído": {
      backgroundColor: "rgba(112, 124, 151, 0.1)",
      border: `0.5px solid #707C97`,
    },
    "& .ExcluídoIcon": {
      backgroundColor: "#707C97",
      border: `0.5px solid rgba(112, 124, 151, 0.1)`,
    },
    "& .PagoIcon": {
      backgroundColor: colors.commons.green,
      border: `0.5px solid ${colors.commons.green}`,
    },
    "& .AtrasadoIcon": {
      backgroundColor: colors.commons.red,
      border: `0.5px solid ${colors.commons.redLight}`,
    },
    "& .AbertoIcon": {
      backgroundColor: colors.commons.yellow,
      border: `0.5px solid ${colors.commons.yellowLight}`,
    },
    "& .vencimento": {
      whiteSpace: "nowrap",
    },
  },
  iconStatus: {
    width: '10px',
    height: '10px',
    borderRadius: '18px',
  },
  statusPagamento: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    color: "#505050",
    padding: "4px 8px",
    fontSize: "13px",
    height: 22,
    gap: "5px",
    fontWeight: 600,
  },
  iconStatusPagamento: {
    height: 12,
    width: 12,
  },
};

export default withStyles(styles)(StatusPagamento);
