import React from "react";
import moment from "moment";
import { applyCurrencyMask } from "../../../utils/CurrencyMask";

const AtendimentosRealizadosContent = (props) => {
  const { dadosRelatorio, dataInicio, dataFim, infosTotal } = props;

  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" }

    }
    return ''
  }

  return (
    <>
      <div style={styles.titulo}>Relatório procedimentos agendados</div>
      <div style={styles.subTitulo}>
        <div style={styles.dataInicio}>
          Data início:
          <span style={styles.subTituloSpan}>
            {moment(dataInicio).format("DD/MM/YYYY")}
          </span>
        </div>
        <div>
          Data fim:
          <span style={styles.subTituloSpan}>
            {moment(dataFim).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.columnProcedimento}>Procedimento</div>
        <div style={styles.column}>Quantidade</div>
        <div style={styles.column}>Valor total</div>
      </div>

      {dadosRelatorio.map((item, index) => {
        return (
          <div key={index} style={{ ...styles.containerColuna, ...columnColor(index) }}>
            <div style={styles.columnProcedimento}>{item.procedimento}</div>
            <div style={styles.column}>{item.quantidade}</div>
            <div style={styles.column}>{applyCurrencyMask(item.valor)}</div>
          </div>
        );
      })}
      <div style={styles.footerInfosTotal}>
        <div style={styles.titleTotal}> Total </div>
        <div style={styles.infosTotal}>
          <div style={styles.info}>
            Quantidade:
            <span style={styles.infoSpan}> {infosTotal.quantidade} </span>
          </div>
          <div style={styles.info}>
            Valor:
            <span style={styles.infoSpan}>
              {applyCurrencyMask(infosTotal.valor)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
  },
  subTituloSpan: {
    marginLeft: "8px",
  },
  dataInicio: {
    marginRight: "16px",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  columnProcedimento: {
    width: "60%",
    minWidth: "60%",
    marginBottom: "10px",
    marginRight: "3px",
  },
  column: {
    width: "20%",
  },
  footerInfosTotal: {
    borderTop: "1px solid #505050",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    maringTop: "8px",
  },
  titleTotal: {
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    margin: "8px 0",
  },
  infosTotal: {
    display: "flex",
    justifyContent: "center",
  },
  info: {
    marginRight: "16px",
  },
  infoSpan: {
    fontWeight: 700,
    marginLeft: "8px",
  },
};

export default AtendimentosRealizadosContent;
