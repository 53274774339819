import { CircularProgress, withStyles } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import style from "./AfericaoVitalStyle";
import InfosHistoricoLancamento from "./InfosHistoricoLancamento";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import MensagemListaVazia from "../../../components/Mensagem/MensagemListaVazia";

const HistoricoLancamento = (props) => {
  const {
    classes,
    actionClose,
    listaAfericoesVitais,
    listarAfericoesVitais,
    handleVisualizar,
    loadMore,
    lastScroll,
    isLoading,
    inativarAfericaoVital,
    afericaoVitalSelecionado
  } = props;
  return (
    <div className={classes.headerContentHistoricoLancamento}>
      <div className={classes.headerHistoricoLancamento}>
        <div className={classes.tituloHistoricoLancamento}>
          Histórico de lançamentos
        </div>
        <div onClick={actionClose} className={classes.buttonClose}>
          <CloseIcon />
        </div>
      </div>

      <div className={classes.boxInfosHistoricoLancamento}>
        {!isLoading && listaAfericoesVitais.length === 0 ?
          <MensagemListaVazia/>
          :
          <Scroll
            loadMore={loadMore}
            hasMore={!lastScroll}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainerModelos}
          >
            {listaAfericoesVitais.map((item, index) => {
              return (
                <InfosHistoricoLancamento
                  key={index}
                  afericaoVital={item}
                  handleVisualizar={() => handleVisualizar(item)}
                  carregaListaHistoricoLancamento={listarAfericoesVitais}
                  inativarAfericaoVital={() => inativarAfericaoVital(item, index)}
                  afericaoVitalSelecionado={afericaoVitalSelecionado}
                />
              );
            })}
          </Scroll>
        }
        {isLoading && (
          <div className={classes.notFoundContainer}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(style)(HistoricoLancamento);
