import React from "react";

import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { CircularProgress } from "@material-ui/core";
import styles from "../../../../components/Auditoria/styles";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import GracefulImage from "../../../../components/Image/GracefulImage";
import { buildUrlFotoPerfil } from "../../../../config/config";


const profileDefault = require("../../../../assets/img/svg/profile-default.svg");



const Auditoria = observer(({
    classes,
    auditoriaStore,
    withEntityName,
    entity,
    entityNameField,
    modificationMessageWithValue
}) => {

    const {
        auditorias,
        lastPage,
    } = auditoriaStore.auditoriaProps;

    if (auditoriaStore.isLoading && auditorias.length === 0) {
        return <div className={classes.notFoundContainer}>
            <CircularProgress />
        </div>
    }


    return (
        <>
            {auditorias.length > 0 ? (
                <Scroll
                    hasMore={!auditoriaStore.isLoading && !lastPage}
                    pageStart={0}
                    initialLoad={false}
                    className={classes.auditoriaBody}
                    loadMore={() => auditoriaStore.loadAuditItems()}
                >
                    {auditorias.map((modificacao, index) => {
                        const { periodoAtendimentoModificadoAuditId, revisao } = modificacao;
                        const { acao, horaFim, horaInicio, data } = periodoAtendimentoModificadoAuditId.periodoAtendimentoModificado
                        const dataHoraModificacao =
                            revisao?.dataHoraModificacao &&
                            moment(revisao.dataHoraModificacao).format(
                                "DD [de] MMMM [às] HH[h]mm"
                            );
                        const dataModified = moment(data).format(
                            "DD/MM/YYYY"
                        );

                        const usuarioPaciente = revisao?.usuario;

                        return (
                            <div className={classes.auditoriaItem} key={index}>
                                <GracefulImage
                                    src={
                                        usuarioPaciente?.fotoPerfil
                                            ? buildUrlFotoPerfil(usuarioPaciente.fotoPerfil)
                                            : profileDefault
                                    }
                                    className={classes.profissionalProfileImage}
                                />
                                <div className={classes.auditoriaText}>
                                    <div className={classes.auditoriaItemInformacao}>
                                        <p>
                                            <strong>{usuarioPaciente?.nome}</strong>
                                            {`${acao === 'BLOQUEAR' ? 'bloqueou' : 'desbloqueou'} 
                                            na data ${dataModified}
                                            das ${horaInicio} às ${horaFim}`}
                                        </p>
                                    </div>
                                    <p className={classes.auditoriaItemData}>
                                        {dataHoraModificacao}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </Scroll>
            ) : (
                <div className={classes.auditoriaNotFoundItens}>
                    Não há modificações
                </div>
            )}
        </>
    );
});

const AuditoriaGeralWithStyle = withStyles(styles)(Auditoria);
const stores = ['auditoriaStore'];
export default inject(stores)(AuditoriaGeralWithStyle);
