export const verificaCamposVazioProcedimentos = (procedimentos) => {
  const verificaCamposVazio = procedimentos.map((item) => {
    const verificaExecutantes = item.executantes.map((itemExecutante) => {
      return (
        !itemExecutante?.ufConselho ||
        !itemExecutante?.cpf ||
        !itemExecutante?.conselhoProfissionalSaude ||
        !itemExecutante?.profissionalSaude.id ||
        !itemExecutante?.numeroConselho ||
        !itemExecutante?.tuss35TerminologiaGrauParticipacao ||
        !itemExecutante?.tuss24TerminologiaCBO
      );
    });

    return (
      !item.data ||
      !item.horaInicial ||
      !item.horaFinal ||
      !item.tabela ||
      !item.codigoProcedimento ||
      !item.descricao ||
      !item.quantidade ||
      !item.valorUnitario ||
      verificaExecutantes
    );
  });
  const isCampoVazio =
    verificaCamposVazio.length === 0 ||
    verificaCamposVazio.some((item) => item === true);

  return isCampoVazio;
};

export const verificarHora = (itemExecucao) => {
  const horaInicialNumber = parseInt(
    itemExecucao?.horaInicial.replace(":", "")
  );
  const horaFinalNumber = parseInt(itemExecucao?.horaFinal.replace(":", ""));
  return horaInicialNumber > horaFinalNumber;
};

export const validationSaveDadoExecucao = (itemExecucao) => {
  return (
    !itemExecucao?.data ||
    !itemExecucao?.horaInicial ||
    !itemExecucao?.horaFinal ||
    !itemExecucao?.tabela ||
    !itemExecucao?.codigoProcedimento ||
    !itemExecucao?.descricao ||
    !itemExecucao?.quantidade ||
    !itemExecucao?.valorUnitario ||
    verificarHora(itemExecucao)
  );
};

export const validationDadosExecutante = (itemExecucao) => {
  const listaExecutantes = itemExecucao?.executantes.map((item) => {
    return (
      !item.profissionalSaude ||
      !item.tuss35TerminologiaGrauParticipacao ||
      !item.cpf ||
      !item.conselhoProfissionalSaude ||
      !item.numeroConselho ||
      !item.estado ||
      !item.tuss24TerminologiaCBO
    );
  });

  const validaExecutantes = listaExecutantes.some((item) => item === true);
  return validaExecutantes;
};

export const verificaCamposObrigatorioExecutante = item => {
  return !(item.profissionalSaude &&
    item.cpf &&
    item.conselhoProfissionalSaude &&
    item.numeroConselho &&
    item.estado &&
    item.tuss24TerminologiaCBO)
}
