import React from 'react';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import Colors from '../../template/Colors';

import {withStyles} from '@material-ui/core/styles';

const styles ={
    root: {
        borderRadius: 100,
        height: 32,
        width: '100%',
        border: '0.5px dashed #7C7C7C',
        background: '#F9F9F9',
        color: Colors.commons.gray9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize'
    }
};

const ButtonDashed = (props) => {
    const {classes, className} = props;
    let buttonClass = classnames(classes.root, className ? className : null);
    return <Button className={buttonClass} {...props}/>;
};


export default withStyles(styles)(ButtonDashed);