import React, { useState } from 'react';
import { FormControl, Popper, withStyles, Paper } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { timePeriodMask } from '../Input/utils/masks';
import WatchIcon from '../Icon/WatchIcon';
import TimeInput from './TimeInput';
import classNames from 'classnames';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 12px 0 6px',
    background: '#f2f2f2',
    color: '#505050',
    borderRadius: '8px',
    height: '40px',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  input: {
    flex: 1, 
    border: 'none',
    maxWidth: '185px',
    width: '100%',
    color: '#868686',
    background: '#f2f2f2',
    fontSize: '14px !important',
    fontWeight: '600 !important',
  },
  error: {
    border: 'solid 1px red',
  },
  popper: {
    zIndex: 1000,
    top: '16px !important',
    left: '-24px !important'
  },
  papper: {
    padding: "10px 0px 10px 24px",
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '56px',
    width: '200px',
    background: 'white',
    borderRadius: '8px',
  },
  pipe: {
    border: 'solid 1px rgba(0, 0, 0, 0.2)', 
    height: 'calc(100% - 16px)',
  }
});

const Pipe = ({ classes }) => <div className={classes.pipe} />;

const PeriodTime = ({ 
  classes, 
  value, 
  filter,
  onChangeInput,
  onChangePeriod,
  error
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open ? "no-transition-popper" : null;

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleChangeHoraInicio = (value) => {
    onChangePeriod(value, 'horaInicio');
  }

  const handleChangeHoraFim = (value) => {
    onChangePeriod(value, 'horaFim');
  }

  return (
    <>
      <FormControl className={classNames(classes.container, error ? classes.error : null)}>
        <MaskedInput
          mask={timePeriodMask}
          className={classes.input}
          value={value}
          onChange={(e) => onChangeInput(e.target.value)}
        />
        <WatchIcon size={16} color="#505050"  onClick={handleClick}/>
      </FormControl>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom"}
        className={classes.popper}
        style={{
          transform: 'translate3d(565px, 132px, 0px)'
        }}  
      >
        <Paper className={classes.paper}>
          <div className={classes.content}>
            <TimeInput 
              label="De: "
              value={filter.horaInicio}
              onChange={handleChangeHoraInicio}
            />
            <Pipe classes={classes} />
            <TimeInput 
              label="Até: "
              value={filter.horaFim}
              onChange={handleChangeHoraFim} 
            />
          </div>
        </Paper>
      </Popper>
    </>
  )
}

export default withStyles(styles)(PeriodTime);
