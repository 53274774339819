import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DocumentDismiss = (props) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M9 0V4.5C9 5.32843 9.67157 6 10.5 6H15V14.5C15 15.3284 14.3284 16 13.5 16H8.74284C9.52818 15.0491 10 13.8296 10 12.5C10 9.46243 7.53757 7 4.5 7C3.97999 7 3.47683 7.07217 3 7.20703V1.5C3 0.671573 3.67157 0 4.5 0H9ZM10 0.25V4.5C10 4.77614 10.2239 5 10.5 5H14.75L10 0.25ZM1.31802 15.682C3.07538 17.4393 5.92462 17.4393 7.68198 15.682C9.43934 13.9246 9.43934 11.0754 7.68198 9.31802C5.92462 7.56066 3.07538 7.56066 1.31802 9.31802C-0.43934 11.0754 -0.43934 13.9246 1.31802 15.682ZM2.73202 10.7324C2.92729 10.5371 3.24387 10.5371 3.43913 10.7324L4.49969 11.7929L5.56062 10.732C5.75588 10.5367 6.07246 10.5367 6.26773 10.732C6.46299 10.9273 6.46299 11.2439 6.26773 11.4391L5.20679 12.5L6.26756 13.5608C6.46282 13.7561 6.46282 14.0727 6.26756 14.2679C6.0723 14.4632 5.75571 14.4632 5.56045 14.2679L4.49969 13.2072L3.4393 14.2675C3.24404 14.4628 2.92745 14.4628 2.73219 14.2675C2.53693 14.0723 2.53693 13.7557 2.73219 13.5604L3.79258 12.5L2.73202 11.4395C2.53676 11.2442 2.53676 10.9276 2.73202 10.7324Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DocumentDismiss;
