import React from 'react';
import { withStyles } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '14px',
    gap: '4px',
    alignItems: 'center'
  },
  input: {
    border: 'none',
    flex: 1,
    width: '50%',
    color: '#505050',
    fontSize: '14px !important'
  },
  label: {
    color: '#505050',
    fontSize: '14px !important'
  }
});

const TimeInput = ({ classes, label, value, onChange }) => {
  return (
    <div className={classes.container}>
      <span className={classes.label}>
        {label}
      </span>
      <MaskedInput
        mask={[/\d/, /\d/, ':', /\d/, /\d/,]}
        className={classes.input}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
};

export default withStyles(styles)(TimeInput);