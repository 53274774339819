import classNames from "classnames";
import { inject } from "mobx-react";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import Anamnese from "./Anamnese";
import Documento from "./Documento";
import Dialog from "../../../components/Dialog/Dialog";
import FooterSolicitacoesPacientes from "./FooterSolicitacoesPacientes";
import HeaderSolicitacoesPacientes from "./HeaderSolicitacoesPacientes";
import { getNewAnamnese } from "../../Atendimento/ModalSolicitacoes/utils";
import PrintAnamnese from "../../Atendimento/ModalSolicitacoes/components/DocumentoSolicitacao/Anamnese/PrintAnamnese";

const ModalSolicitacoesPacientes = observer(
  ({ classes, solicitacoesPacientesStore, configuracaoImpressaoStore }) => {
    const {
      propsModalSolicitacoesPacientes,
      anamnesePerguntasAlteradas,
      isPrintAnamnese,
    } = solicitacoesPacientesStore;
    const { open, dados, onClose } = propsModalSolicitacoesPacientes;
    const isTipoAnamnese = dados?.arquivoTipo === "ANAMNESE";

    useEffect(() => {
      isTipoAnamnese &&
        configuracaoImpressaoStore.getConfig("ANAMNESE");
    }, []);

    const handlePrintAnamnese = () => {
      solicitacoesPacientesStore.isPrintAnamnese = false;
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"md"}
        fullWidth={true}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.contentDialog}>
          <HeaderSolicitacoesPacientes />
          <div
            className={classNames(
              classes.content,
              isTipoAnamnese && classes.contentAnamnese
            )}
          >
            {isTipoAnamnese ? <Anamnese /> : <Documento />}
          </div>
        </div>
        <FooterSolicitacoesPacientes />
        {solicitacoesPacientesStore.isPrintAnamnese && (
          <PrintAnamnese
            sujeitoAtencao={dados.sujeitoAtencao}
            perguntas={getNewAnamnese(dados, anamnesePerguntasAlteradas)}
            anamnese={dados}
            isPrint={isPrintAnamnese}
            handlePrint={() => handlePrintAnamnese()}
          />
        )}
      </Dialog>
    );
  }
);

const styles = {
  paper: {
    maxWidth: "500px",
    maxHeight: "550px",
    height: "100%",
  },
  contentDialog: {
    width: "100%",
    height: "calc(100% - 65px)",
    display: "flex",
    flexDirection: "column",
    background: "#F2F2F2",
  },
  content: {
    margin: "16px 16px 0 16px",
    border: "1px solid #F2F2F2",
    borderRadius: "10px 10px 0 0",
    height: "100%",
  },
  contentAnamnese: {
    background: "#fff",
    height: "calc(100% - 81px)",
    overflow: "auto",
  },
};

const ModalSolicitacoesPacientesWithStyles = withStyles(styles)(
  ModalSolicitacoesPacientes
);
export default inject(
  "solicitacoesPacientesStore",
  "configuracaoImpressaoStore"
)(ModalSolicitacoesPacientesWithStyles);
