import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import ReportItem from './ReportItem';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    flex: 1
  }, 
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '10px',
    columnGap: '14px',
    paddingBottom: '18px',
    marginBottom: '16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '16px !important'
  },
  topicContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px'
  },
  topic: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    color: '#333333',
    marginBottom: '16px'
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px'
  }
});

const Report = ({ classes, type, perceptions, perceptionsCountersByType}) => {

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {type === 'positive' ? 
          <CheckCircle style={{ color: '#24D475' }} /> : 
          <Cancel style={{ color: '#FF0505' }} />
        }
        <span className={classes.title}>
          <b>{`Percepções ${type === 'positive' ? 'positivas' : 'negativas '}`}</b>{`(${perceptions?.length || 0})`}
        </span>
      </div>
      <div className={classes.reportContainer}>
        <div className={classes.topicContainer}>
          <span className={classes.topic}>Sobre a recepção</span>
          <div className={classes.items}>
            <ReportItem
              description={type === 'positive' ? 'Boa recepção' : 'Recepção poderia ser melhor'}
              quantity={perceptionsCountersByType.recepcao}
            />
            <ReportItem
              description={type === 'positive' ? 'Atendimento pontual' : 'Atraso no atendimento'}
              quantity={perceptionsCountersByType.pontualidade}
            />
            <ReportItem
              description={type === 'positive' ? 'Agendamento fácil' : 'Agendamento confuso'}
              quantity={perceptionsCountersByType.dificuldadeAoAgendar}
            />
          </div>
        </div>
        <div className={classes.topicContainer}>
          <span className={classes.topic}>Sobre a consulta</span>
          <div className={classes.items}>
            <ReportItem
              description={type === 'positive' ? 'Explicações de forma clara' : 'Comunicação confusa'}
              quantity={perceptionsCountersByType.explicacoesClaras}
            />
            <ReportItem
              description={type === 'positive' ? 'Ajudou com meu problema' : 'Não me ajudou'}
              quantity={perceptionsCountersByType.ajudou}
            />
            <ReportItem
              description={type === 'positive' ? 'Profissional Atencioso(a)' : 'Consulta muito rápida'}
              quantity={perceptionsCountersByType.atencioso}
            />
          </div>
        </div>
        <div className={classes.topicContainer}>
          <span className={classes.topic}>Sobre a clínica</span>
          <div className={classes.items}>
            <ReportItem
              description={type === 'positive' ? 'Várias opções de pagamento' : 'Poucas opções de pagamento'}
              quantity={perceptionsCountersByType.opcoesPagamento}
            />
            <ReportItem
              description={type === 'positive' ? 'Consultório bem localizado' : 'Difícil localização'}
              quantity={perceptionsCountersByType.localizacao}
            />
            <ReportItem
              description={type === 'positive' ? 'Ambiente agradável' : 'Ambiente inapropriado'}
              quantity={perceptionsCountersByType.ambienteAgradavel}
            />
            <ReportItem
              description={type === 'positive' ? 'Boa acessibilidade' : 'Pouca acessibilidade'}
              quantity={perceptionsCountersByType.acessibilidade}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Report);