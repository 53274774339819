export const getGuiaInternacaoObjectToSave = (guiaInternacao, sujeitoAtencaoId, screen) => {
  const guiaViewsFieldName =
    screen === "prontuario"
      ? "guiaInternacaoTussViews"
      : "guiaInternacaoModeloTussViews";

  const guiaInternacaoObject = {
    ...(guiaInternacao.id && { id: guiaInternacao.id }),
    ...(screen === "configuracao" && { nome: guiaInternacao.nome }),
    ativo: true,
    cid10Subcategoria: {
      id: guiaInternacao.indicacaoClinica.id,
    },
    tuss23TerminologiaCaraterAtendimento: {
      id: guiaInternacao.caraterAtendimento.id,
    },
    tuss57TerminologiaTipoInternacao: {
      id: guiaInternacao.tipoInternacao.id,
    },
    tuss41TerminologiaRegimeInternacao: {
      id: guiaInternacao.regimeInternacao.id,
    },
    tuss36TerminologiaIndicadorAcidente: {
      id: guiaInternacao.indicacaoAcidente.id,
    },
    ...(screen === "configuracao"&& guiaInternacao.profissionalSaude && {
      profissionalSaude: {
        id: guiaInternacao.profissionalSaude?.id || guiaInternacao.profissionalSaude?.value,
      },
    }),
    hasOPME: guiaInternacao.opme,
    quimioterapico: guiaInternacao.quimioterapico,
    observacao: guiaInternacao.observacao,
    [guiaViewsFieldName]: guiaInternacao[guiaViewsFieldName],
    ...(sujeitoAtencaoId && screen === "prontuario" && { sujeitoAtencao: { id: sujeitoAtencaoId } }),
  };

  return guiaInternacaoObject;
};
