import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DocumentCheckmark = (props) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M9 0V4.5C9 5.32843 9.67157 6 10.5 6H15V14.5C15 15.3284 14.3284 16 13.5 16H8.74284C9.52818 15.0491 10 13.8296 10 12.5C10 9.46243 7.53757 7 4.5 7C3.97999 7 3.47683 7.07217 3 7.20703V1.5C3 0.671573 3.67157 0 4.5 0H9ZM10 0.25V4.5C10 4.77614 10.2239 5 10.5 5H14.75L10 0.25ZM9 12.5C9 14.9853 6.98528 17 4.5 17C2.01472 17 0 14.9853 0 12.5C0 10.0147 2.01472 8 4.5 8C6.98528 8 9 10.0147 9 12.5ZM6.85355 10.6464C6.65829 10.4512 6.34171 10.4512 6.14645 10.6464L3.5 13.2929L2.85355 12.6464C2.65829 12.4512 2.34171 12.4512 2.14645 12.6464C1.95118 12.8417 1.95118 13.1583 2.14645 13.3536L3.14645 14.3536C3.34171 14.5488 3.65829 14.5488 3.85355 14.3536L6.85355 11.3536C7.04882 11.1583 7.04882 10.8417 6.85355 10.6464Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DocumentCheckmark;
