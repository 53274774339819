import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import Dialog from '../../../components/Dialog/Dialog';
import Notification from '../../../components/Notification'
import Api from '../../../config/api';
import Chime from "../../../components/AwsChime";
import MensagemTeleconsulta from './MensagemTeleconsulta';
import { findByMeetingId } from '../../../services/ProntuarioService';
import MensagemMeetingId from './MensagemMeetingId';

const TeleconsultaChimeScreen = (props) => {
  const { classes } = props;

  const meetingManager = useMeetingManager()
  const [notificationPopup, setNotificationPopup] = useState({
    isOpen: false,
    message: '',
    variant: ''
  });
  const [showWarning, setShowWarning] = useState(true);
  const [permissoes, setPermissoes] = useState({
    permissaoCamera: false,
    permissaoMicrofone: false,
  })

  const [meetingId, setMeetingId] = useState("");

  const getAttendee = async (prontuarioId) => {
    const response = await Api.post('', {
      query: `
         mutation($id: Long){
           createAtendeeByProfissionalSaude(id: $id) {
             attendee {
               attendeeId, 
               externalUserId, 
               joinToken
             }
             meeting {
               externalMeetingId, 
               mediaPlacement {
                 signalingUrl, 
                 audioFallbackUrl, 
                 audioHostUrl,
                 screenDataUrl, 
                 screenSharingUrl, 
                 screenViewingUrl, 
                 turnControlUrl
               }, 
               mediaRegion, 
               meetingId
             }
           }
         }`,
      variables: {
        id: prontuarioId
      }
    })

    return response?.data?.data?.createAtendeeByProfissionalSaude
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!permissoes?.permissaoCamera || !permissoes?.permissaoMicrofone) {
        const permissoes = meetingId && await findByMeetingId(meetingId)
        setPermissoes(permissoes);
      } else{
        clearInterval(intervalId)
      }
    }, 5000);
    

    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    (async () => {
      const dadosTeleconsulta = JSON.parse(localStorage.getItem('dadosTeleconsulta'));

      if(!meetingId){
        const data = await getAttendee(dadosTeleconsulta.prontuarioId)

        if (data) {
          const attendee = data.attendee;
          const meeting = data.meeting;

          const dataTransformation = {
            Meeting: {
              ExternalMeetingId: meeting.externalMeetingId,
              MediaPlacement: {
                AudioFallbackUrl: meeting.mediaPlacement.audioFallbackUrl,
                AudioHostUrl: meeting.mediaPlacement.audioHostUrl,
                ScreenDataUrl: meeting.mediaPlacement.screenDataUrl,
                ScreenSharingUrl: meeting.mediaPlacement.screenSharingUrl,
                ScreenViewingUrl: meeting.mediaPlacement.screenViewingUrl,
                SignalingUrl: meeting.mediaPlacement.signalingUrl,
                TurnControlUrl: meeting.mediaPlacement.turnControlUrl
              },
              MediaRegion: meeting.mediaRegion,
              MeetingId: meeting.meetingId
            },
            Attendee: {
              AttendeeId: attendee.attendeeId,
              ExternalUserId: attendee.externalUserId,
              JoinToken: attendee.joinToken
            }
          }

          setMeetingId(meeting.meetingId)

          const meetingSessionConfiguration = new MeetingSessionConfiguration(
            dataTransformation.Meeting,
            dataTransformation.Attendee
          );

          try {
            await meetingManager.join(meetingSessionConfiguration);

            await meetingManager.start()

          } catch (error) {
            setNotificationPopup({
              isOpen: true,
              message: 'Erro ao iniciar a teleconsulta',
              variant: 'error'
            });
            console.log(error);
          }
        }
      } 
    })()
  })

  return (
    <Dialog
      open={true}
      maxWidth={"xl"}
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <MensagemMeetingId meetingId={meetingId} />
      {(!permissoes?.permissaoCamera || !permissoes?.permissaoMicrofone) && showWarning && 
        <MensagemTeleconsulta 
          permissoes={permissoes}
          handleCloseWarning={() => setShowWarning(false)}
        />
      }
      <Chime />

      <Notification
        message={notificationPopup.message}
        variant={notificationPopup.variant}
        isOpen={notificationPopup.isOpen}
      />

    </Dialog>
  )
}

const styles = {
  p: {
    alignItem: 'center',
    justifyContent: 'center'
  },
  buttonHeader: {
    marginLeft: '20px',
    boxShadow: 'none',
    '& svg': {
      marginRight: '10px',
    }
  },
  dialog: {
    minHeight: '100vh',
    maxHeight: '100vh',
    minWidth: '100vw',
    maxWidth: '100vw',
    borderRadius: 0
  }
}

TeleconsultaChimeScreen.propTypes = {
  sujeitoAtencao: PropTypes.shape({ nome: PropTypes.string }).isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TeleconsultaChimeScreen);

