import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Dialog, Fab, withStyles } from '@material-ui/core'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'

import ButtonLong from '../../../components/Button/ButtonLong'
import CardSolicitacao from './components/CardSolicitacao'
import TabSelector from '../../../components/TabSelector'
import PopperCustomMenu from '../../../components/Popper/PopperCustomMenu'
import PrintAnamnese from './components/DocumentoSolicitacao/Anamnese/PrintAnamnese'
import ModalConfirmacao from '../../../components/Modal/ModalConfirmacao/ModalConfirmacao'
import DocumentoSolicitacao from './components/DocumentoSolicitacao'

import {
  anexarAnexosPaciente,
  createAnamnese,
  descartarAnamnese,
  descartarAnexo,
  findAllAnexosPaciente,
  findAnamneseByAgendamento,
  quantitySolicitacoes,
} from '../../../services/SolicitacoesProntuarioService'
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../services/storage'

import WarningIcon from '../../../components/Icon/Warning'
import CloseIcon from '../../../components/Icon/Close'
import SuccessIcon from '../../../components/Icon/SuccessIcon'

import { menuOpcoesPopper, getTypeOfSolicitacao, optionsCard, getNewAnamnese } from './utils'
import { buildUrlSolicitacaoPaciente } from '../../../config/config'
import { downloadArquivo, downloadImage } from '../../../utils/downloadImagem'

import { styles } from './styles'
import MoreIcon from '../../../components/Icon/MoreIcon'

const ModalSolicitacoes = observer(
  ({
    classes,
    open,
    onClose,
    prontuarioStore,
    agendamentoId,
    configuracaoImpressaoStore,
    openNotification,
    sujeitoAtencao,
    solicitacoesPacientesStore,
  }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [solicitacoes, setSolicitacoes] = useState([])
    const [editingMode, setEditingMode] = useState(false)
    const [solicitacaoSelected, setSolicitacaoSelected] = useState(null)
    const [anamneseResponse, setAnamneseResponse] = useState({})
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isPrint, setIsPrint] = useState(false)

    const popperRef = useRef(null)

    const [openModalConfirmAction, toggleOpenModalConfirmAction] = useReducer(open => !open, false)

    const loadSolicitacoes = async () => {
      const variables = {
        pageableDTO: {
          pageNumber: 0,
          pageSize: 10,
        },
        descartado: selectedTabIndex === 1,
        prontuarioGerado: false,
        agendamentoId: agendamentoId,
      }
      try {
        const anamnese = await findAnamneseByAgendamento(variables)
        const anexos = await findAllAnexosPaciente(variables)
        const content = [...anamnese.content, ...anexos.content]
        setSolicitacoes(content)
      } catch {
        openNotification('Erro ao carregar solicitações', 'error')
      }
    }

    const handleSelectSolicitacao = id => {
      if (solicitacaoSelected?.id === id) {
        setSolicitacaoSelected(null)
        return
      }
      const documentSelected = solicitacoes.find(solicitacao => solicitacao.id === id)
      setSolicitacaoSelected(documentSelected)
      return documentSelected
    }

    const handleSaveAnamnese = async () => {
      const variables = {
        anamnesePaciente: {
          id: solicitacaoSelected?.id,
          nome: solicitacaoSelected?.nome,
          perguntas: getNewAnamnese(solicitacaoSelected, anamneseResponse),
        },
        agendamentoId: agendamentoId,
      }
      try {
        setIsLoading(true)
        await createAnamnese(variables)
        await loadSolicitacoes()
        await getQuantityOfSolicitacoes()
        setSolicitacaoSelected(null)
        setEditingMode(false)
      } catch (error) {
        openNotification('Erro ao salvar alterações da anamnese')
      } finally {
        setIsLoading(false)
      }
    }

    const anexarProntuario = async () => {
      const { tipoEntradaProntuario, id, nome } = solicitacaoSelected
      const variables = {
        ...(tipoEntradaProntuario === 'ANAMNESE'
          ? {
              anamnesePaciente: {
                id,
                nome,
                perguntas: getNewAnamnese(solicitacaoSelected, anamneseResponse),
              },
            }
          : {
              pacienteAnexoId: id,
            }),
        agendamentoId: agendamentoId,
      }
      const createEntradaProntuario =
        tipoEntradaProntuario === 'ANAMNESE' ? createAnamnese : anexarAnexosPaciente
      try {
        setIsLoading(true)
        await createEntradaProntuario(variables, tipoEntradaProntuario)
        await loadSolicitacoes()
        await getQuantityOfSolicitacoes()
        toggleOpenModalConfirmAction()
        setOpenSuccessAlert(true)
        setSolicitacaoSelected(null)
        setEditingMode(false)
        prontuarioStore.findAllEntradaProntuario()
        solicitacoesPacientesStore.getQuantidadeSolicitacoesPaciente()
      } catch (error) {
        openNotification('Erro ao anexar prontuário', 'error')
      } finally {
        setIsLoading(false)
      }
    }

    const handleEdit = () => {
      setEditingMode(!editingMode)
      return popperRef?.current?.closePopper()
    }

    const handlePrint = () => {
      setIsPrint(true)
      return popperRef?.current?.closePopper()
    }

    const handleDiscardSolicitacao = async () => {
      const { id, tipoEntradaProntuario } = solicitacaoSelected
      const variables = {
        anamneseId: solicitacaoSelected?.id,
        ...(tipoEntradaProntuario === 'ANAMNESE' ? { anamneseId: id } : { id: id }),
      }
      const descartarSolicitacao =
        tipoEntradaProntuario === 'ANAMNESE' ? descartarAnamnese : descartarAnexo
      try {
        await descartarSolicitacao(variables)
        await loadSolicitacoes()
        await getQuantityOfSolicitacoes()
        setSolicitacaoSelected(null)
        return popperRef?.current?.closePopper()
      } catch (error) {
        openNotification('Erro ao descartar solicitação', 'error')
      }
    }

    const getUrl = async(urlDownload) => {
      const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
      const url = buildUrlSolicitacaoPaciente(urlDownload, accessToken);

      return url;
    }

    const handleDownloadDocument = async () => {
      const { tipoEntradaProntuario, amazonS3Objeto, urlDownload } = solicitacaoSelected
      const url = await getUrl(urlDownload);
      const downloadByType = tipoEntradaProntuario === 'ARQUIVO' ? downloadArquivo : downloadImage
      downloadByType(amazonS3Objeto?.nome, url)
      return popperRef?.current?.closePopper()
    }

    const handleOpenNewTab = async () => {
      const { urlDownload } = solicitacaoSelected
      const url = await getUrl(urlDownload);
      window.open(url, '_blank')
      return popperRef?.current?.closePopper()
    }

    const getQuantityOfSolicitacoes = async () => {
      if (!agendamentoId) return
      try {
        const response = await quantitySolicitacoes(agendamentoId)
        prontuarioStore.quantitySolicitacoes = response
      } catch (error) {
        openNotification('Erro ao carregar quantidade de solicitações', 'error')
        prontuarioStore.quantitySolicitacoes = 0
      }
    }

    useEffect(() => {
      loadSolicitacoes()
      getQuantityOfSolicitacoes()
    }, [selectedTabIndex, agendamentoId])

    useEffect(() => {
      if (solicitacaoSelected?.tipoEntradaProntuario !== 'ANAMNESE') {
        setEditingMode(false)
        return
      }
      configuracaoImpressaoStore.getConfig('ANAMNESE')
    }, [isPrint, solicitacaoSelected?.tipoEntradaProntuario])

    return (
      <Dialog
        open={open}
        maxWidth={'md'}
        fullWidth
        onClose={onClose}
        classes={{
          paper: classes.fullSizePaper,
        }}
      >
        <section className={classes.content}>
          <h1 className={classes.title}>Solicitações</h1>
          <div className={classes.wrapper}>
            <TabSelector
              selectedColor='#FFF'
              baseColor='#F2F2F2'
              onSelect={index => {
                setSelectedTabIndex(index)
                setEditingMode(false)
                setSolicitacaoSelected(null)
              }}
              selectedTabIndex={selectedTabIndex}
              tabsOptions={[
                {
                  label: 'Em aberto',
                  roundedTag: true,
                  tagColor: '#5462E0',
                  tagText: prontuarioStore.quantitySolicitacoes,
                },
                { label: 'Descartados' },
              ]}
            />
            {solicitacoes?.map((solicitacao, index) => {
              return (
                <CardSolicitacao
                  key={index}
                  isSelected={solicitacaoSelected?.id === solicitacao.id}
                  options={optionsCard(solicitacao)}
                  id={solicitacao.id}
                  onClick={handleSelectSolicitacao}
                />
              )
            })}
          </div>
        </section>
        <aside className={classes.contentDocument}>
          <div className={classes.headerDocument}>
            <div className={classes.descriptionDocument}>
              <span>
                {getTypeOfSolicitacao(
                  solicitacaoSelected?.tipoEntradaProntuario,
                  solicitacaoSelected?.amazonS3Objeto?.extensao
                )}
              </span>
              <span>{solicitacaoSelected?.agendamento?.especialidadeMedica?.especialidade}</span>
            </div>
            <div className={classes.wrapperActions}>
              <PopperCustomMenu
                placement={'bottom-end'}
                disabledButton={!solicitacaoSelected}
                iconButton={<MoreIcon color="#fff" />}
                menuOpcoes={menuOpcoesPopper({
                  type: solicitacaoSelected?.tipoEntradaProntuario,
                  functions: {
                    handleEdit,
                    handlePrint,
                    handleDiscardSolicitacao,
                    handleDownload: handleDownloadDocument,
                    handleOpenNewTab,
                  },
                  selectedTab: selectedTabIndex,
                })}
                ref={popperRef}
              />
              <Fab size='small' onClick={onClose}>
                <CloseIcon color='#219A97' />
              </Fab>
            </div>
          </div>
          <div className={classes.wrapperDocuments}>
            <DocumentoSolicitacao
              solicitacao={solicitacaoSelected}
              isDisabled={editingMode}
              getValuesAnamnese={value => setAnamneseResponse(value)}
              optionsDocument={{ handleDiscardSolicitacao, handleDownloadDocument }}
              selectedTab={selectedTabIndex}
            />
          </div>
          <div className={classes.wrapperButtons}>
            {editingMode ? (
              <>
                <ButtonLong colorCustom='red' noShadow onClick={() => setEditingMode(false)}>
                  Cancelar
                </ButtonLong>
                <ButtonLong
                  colorCustom='yellow'
                  noShadow
                  onClick={handleSaveAnamnese}
                  loading={isLoading}
                >
                  Anexar ao prontuário
                </ButtonLong>
              </>
            ) : (
              <ButtonLong
                className={classes.buttonProntuario}
                disabled={!solicitacaoSelected}
                onClick={toggleOpenModalConfirmAction}
                customColor='gray'
              >
                Anexar ao prontuário
              </ButtonLong>
            )}
          </div>
        </aside>

        {openModalConfirmAction && (
          <ModalConfirmacao
            open={openModalConfirmAction}
            onClose={toggleOpenModalConfirmAction}
            icon={<WarningIcon />}
            color={'yellow'}
            title={'Essa ação não poderá ser desfeita, você deseja continuar?'}
            buttons={{
              labelPrimaryButton: 'Cancelar',
              handlePrimaryButton: () => toggleOpenModalConfirmAction(),
              labelSecondButton: 'Continuar',
              handleSecondButton: () => anexarProntuario(),
              loadingSecondButton: isLoading,
            }}
          />
        )}
        {openSuccessAlert && (
          <ModalConfirmacao
            open={openSuccessAlert}
            icon={<SuccessIcon />}
            onClose={() => setOpenSuccessAlert(false)}
            color={'green'}
            title={'Documento anexado com sucesso!'}
          />
        )}
        {isPrint && (
          <PrintAnamnese
            sujeitoAtencao={sujeitoAtencao}
            perguntas={getNewAnamnese(solicitacaoSelected, anamneseResponse)}
            anamnese={solicitacaoSelected}
            isPrint={isPrint}
            handlePrint={() => setIsPrint(false)}
          />
        )}
      </Dialog>
    )
  }
)

const ModalSolicitacoesWithStyles = withStyles(styles)(ModalSolicitacoes)

export default inject('configuracaoImpressaoStore', 'prontuarioStore', 'solicitacoesPacientesStore')(ModalSolicitacoesWithStyles)
