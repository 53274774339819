import { ViziApi } from "../config/api";
import { UNIDADE_LOGADA_KEY } from "./storage";
import { WHITELABEL_URLS } from "../constants/WHITELABEL_URLS";

export const getWhitelabelAtual = () => {
  try {
    const whitelabelAtual = JSON.parse(
      localStorage.getItem("_immortal|" + UNIDADE_LOGADA_KEY)
    )?.rede?.whitelabel;

    return whitelabelAtual;
  } catch (error) {
    return null;
  }
};

export const getWhitelabelNome = () => {
  const whitelabel = getWhitelabelAtual();
  return whitelabel?.nomeEmpresa || "";
};

export const getWhitelabelByUnidade = (unidade) => {
  return unidade?.rede?.whitelabel || null;
};

export const getWhitelabelNomeByUnidade = (unidade) => {
  return unidade?.rede?.whitelabel?.nomeEmpresa || "";
};

export const getWhitelabelAtualURL = () => {
  const whitelabel = getWhitelabelAtual();
  const environment = process.env.NODE_ENV || "development";
  const url = WHITELABEL_URLS[environment][whitelabel?.nome];

  return url || process.env.REACT_APP_VIZI_SAUDE_URL;
};

export const findConfiguracaoWhitelabelByWhitelabelAtual = async () => {
  const whitelabelNameInHeader = ViziApi.defaults.headers["whitelabel"];
  const whitelabel = getWhitelabelAtual();

  
  if (!whitelabel) {
    throw new Error("A rede desta unidade não possui uma whitelabel.");
  }

  if (whitelabelNameInHeader !== whitelabel?.nome) {
    ViziApi.defaults.headers["whitelabel"] = whitelabel?.nome;
  }

  const response = await ViziApi.post(
    "",
    {
      query: `
      query {
        findConfiguracaoWhitelabelByWhitelabelAtual {
          taxasIugu {
            taxaAdministrativa
          }
        }
      }
    `,
    },
  );

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findConfiguracaoWhitelabelByWhitelabelAtual;
};
