const messagesPaginationSettingsDefault = {
  pageableDTO: {
    pageSize: 30,
    pageNumber: 0,
    sortField: 'dataHoraCriacao',
    sortDir: 'DESC',
  },
  lastPage: false,
};

export const createChatContactObject = (contact) => {
  return {
    contact,
    paginationOptions: messagesPaginationSettingsDefault,
    messages: [],
  };
};

export const getChatContactObjectUpdated = (contact, paginationOptions, messages, lastPage) => {
  const { pageableDTO } = paginationOptions;
  const { pageNumber } = pageableDTO;

  return {
    contact,
    paginationOptions: {
      pageableDTO: {
        ...pageableDTO,
        pageNumber: lastPage ? pageNumber : pageNumber + 1,
      },
      lastPage
    },
    messages
  };
};
