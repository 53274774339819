import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PersonEditIcon = props => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <rect y='0.166016' width='24' height='24' rx='12' fill='#F9BE73' />
      <path
        d='M12.2944 15.686L14.6133 13.3671H8.55172C7.80651 13.3671 7.20239 13.9712 7.20239 14.7164V15.2684C7.20239 15.6117 7.30944 15.9463 7.50861 16.2259C8.34127 17.3945 9.65046 18.0324 11.4 18.1484C11.3975 18.0388 11.4097 17.9259 11.4382 17.8117L11.7128 16.7132C11.81 16.3245 12.011 15.9694 12.2944 15.686ZM12.0003 6.16992C13.6571 6.16992 15.0003 7.51307 15.0003 9.16992C15.0003 10.8268 13.6571 12.1699 12.0003 12.1699C10.3434 12.1699 9.00027 10.8268 9.00027 9.16992C9.00027 7.51307 10.3434 6.16992 12.0003 6.16992ZM16.26 12.5687L12.7185 16.1101C12.512 16.3166 12.3655 16.5753 12.2947 16.8586L12.0201 17.957C11.9007 18.4347 12.3334 18.8674 12.8111 18.748L13.9095 18.4734C14.1928 18.4026 14.4515 18.2561 14.658 18.0496L18.1994 14.5081C18.735 13.9726 18.735 13.1042 18.1994 12.5687C17.6639 12.0331 16.7955 12.0331 16.26 12.5687Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default PersonEditIcon
