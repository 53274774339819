import React from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core";
import colors from "../../template/Colors";
import {
  Group as GroupIcon,
  MeetingRoom as MeetingRoomIcon,
} from "@material-ui/icons";
import ButtonSelected from "../../components/Button/ButtonSelected";
import { observer } from "mobx-react-lite";

const AtendimentoButtonHeader = observer((props) => {
  const {
    atendimentoStore,
    classes,
    handleClickEncaixes,
    handleClickSalas,
  } = props;

  const { isEncaixeSelected, isSalaSelected } = atendimentoStore;

  const buttons = [
    {
      label:"Encaixes",
      onClick: handleClickEncaixes,
      icon: <GroupIcon/>,
      isSelected: isEncaixeSelected,
    },
    {
      label:"Salas",
      onClick: handleClickSalas,
      icon: <MeetingRoomIcon/>,
      isSelected: isSalaSelected,
    }
  ]

  return (
    <>
      <div className={classes.contentButtonLong}>
        {
          buttons.map(item => {
            return <ButtonSelected {...item}/>
          })
        }
      </div>
      <div className={classes.contentButtonCircle}>
        {
          buttons.map(item => {
            return <ButtonSelected {...item} isCircle/>
          })
        }
      </div>
    </>
  );
});

const styles = {
  contentButtonLong: {
    display: "flex",
    gap: '8px',
    "@media (max-width: 1100px)": {
      display: "none",
    },
  },
  contentButtonCircle: {
    display: "none",
    "@media (max-width: 1100px)": {
      display: "flex",
      gap: '8px',
    },
  },
  backgroundSelected: {
    background: colors.commons.secondary
  },
  background: {
    background: colors.commons.gray7
  }
};

const AtendimentoButtonHeaderWithStyles = withStyles(styles)(
  AtendimentoButtonHeader
);
export default inject("atendimentoStore")(AtendimentoButtonHeaderWithStyles);
