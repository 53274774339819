import {
    Paper,
    Popper,
    Checkbox,
    FormControlLabel,
    ClickAwayListener,
  } from "@material-ui/core";
  import styled from "styled-components";
  import React, { useState, forwardRef, useImperativeHandle } from "react";
  import ArrowUpIcon from "../Icon/ArrowUpIcon";
  
  const Select = styled.div`
    background: #f2f2f2;
    width: 118px;
    height: 30px;
    border: 1px solid rgba(220, 220, 220, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    justify-content: space-between;
  `;
  
  const ColorBox = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1.5px solid rgba(220, 220, 220, 0.2);
    background: ${(props) => props.cor};
  `;
  
  const CheckboxOptions = styled.div`
    display: flex;
    flex-direction: column;
    & > label {
      margin-left: 11px;
      border-radius: 100px;
      margin-bottom: 8px;
      padding-left: 5px;
      overflow: hidden;
    }
  `;
  
  const WrapperCheckbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    & > label {
      margin: 0px;
      flex: 1;
    }
  `;
  
  const WrapperColorBox = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
  `;
  
  const PopperCheck = forwardRef((props, ref) => {
    const { options, changeCheckbox, label } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    
    const handleOpenPopper = (event) => {
      const { currentTarget } = event;
      setAnchorEl(currentTarget);
      setOpen(!open);
    };
  
    const closePopper = (event) => {
      if (anchorEl?.contains(event?.target)) {
        return;
      }
      setOpen(false);
    };
  
    useImperativeHandle(ref, () => {
      return {
        closePopper,
      };
    });
    return (
      <>
        <Select ref={ref} onClick={handleOpenPopper}>
          {label}
          <ArrowUpIcon color="#868686" size='12'/>
        </Select>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={"bottom"}
          style={{ zIndex: 11 }}
        >
          <ClickAwayListener onClickAway={closePopper}>
            <Paper>
              <CheckboxOptions>
                {options.map((item, index) => (
                  <WrapperCheckbox key={item.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.checked}
                          color={"#505050"}
                        />
                      }
                      label={<span>{item.name}</span>}
                      key={index}
                      onChange={() => changeCheckbox(item, index)}
                    />
                    {item.cor && (
                      <WrapperColorBox>
                        <ColorBox cor={item.cor} />
                      </WrapperColorBox>
                    )}
                  </WrapperCheckbox>
                ))}
              </CheckboxOptions>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    );
  });
  
  export default PopperCheck;
  