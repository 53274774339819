import Api from "../config/api";
import { viasInternas, viasExternas } from "../utils/viaAdmContants";

export const findAllReceitaModelo = (filter) => {
  return Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
            findAllReceitaModelo(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
                last
                numberOfElements
                content {
                    ativo
                    quantidadeUso
                    nome
                    tipo
                    id
                    receitaModeloItens {
                        id
                        adscricao
                        remedio
                        receitaItemTipoUso
                        quantidade
                        medicamento {
                            fabricante
                            formaFarmaceutica
                            id
                            principioAtivo
                            produto
                            receituario
                            status
                            subtitulo
                            tarja
                            tipo
                            titularidade
                        }
                    }
                }
                pageable {
                    pageNumber
                }
            }
        }
    `,
    variables: {
      searchDTO: {
        search: filter?.search || "",
        ativo: filter?.ativo,
      },
      pageableDTO: {
        pageSize: 30,
        pageNumber: filter?.pageNumber || 0,
        sortDir: filter?.sortDir || "ASC",
        sortField: filter?.sortField || "nome",
      },
    },
  });
};

export const findAllEstadoList = async () => {
  return await Api.post("", {
    query: `
            {
              findAllEstadoList {
                id,
                uf
              }
            }
          `,
  });
};

export const getRouteAdmByValue = (value) => {
  const allRoutesAdm = [...viasInternas, ...viasExternas];
  const routeAdm = allRoutesAdm.filter(item => item.value === value)[0];
  return routeAdm.name;
};
