import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const AtendimentoIcon = props => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path 
        d="M14.633 2.2186L11.1386 2.26493C9.98245 2.28026 9.04189 3.16622 8.93236 4.29081L7.17136 4.31472C5.93068 4.33117 4.93824 5.35027 4.95469 6.59095L5.13338 20.0696C5.14983 21.3103 6.16893 22.3028 7.40961 22.2863L18.8915 22.1341C20.1321 22.1176 21.1246 21.0985 21.1081 19.8579L20.9294 6.37917C20.913 5.13849 19.8939 4.14605 18.6532 4.1625L16.8922 4.18529C16.7529 3.06399 15.7892 2.20328 14.633 2.2186ZM11.1584 3.76256L14.6529 3.71624C15.0665 3.71075 15.4062 4.04156 15.4116 4.45512C15.4171 4.86868 15.0863 5.20839 14.6728 5.21387L11.1783 5.26019C10.7647 5.26568 10.425 4.93487 10.4195 4.52131C10.4141 4.10775 10.7449 3.76804 11.1584 3.76256ZM13.4942 10.4721C13.4887 10.0586 13.8195 9.71887 14.2331 9.71338L17.7276 9.66706C18.1411 9.66157 18.4808 9.99239 18.4863 10.4059C18.4918 10.8195 18.161 11.1592 17.7474 11.1647L14.253 11.211C13.8394 11.2165 13.4997 10.8857 13.4942 10.4721ZM14.306 15.2047L17.8003 15.1584C18.2139 15.1529 18.5536 15.4837 18.5591 15.8973C18.5645 16.3108 18.2337 16.6505 17.8202 16.656L14.3258 16.7023C13.9123 16.7078 13.5726 16.377 13.5671 15.9634C13.5616 15.5499 13.8924 15.2102 14.306 15.2047ZM11.771 10.026L9.80068 12.0493C9.51212 12.3456 9.038 12.3519 8.74169 12.0633L7.73003 11.0781C7.43372 10.7896 7.42744 10.3154 7.71599 10.0191C8.00455 9.72284 8.47867 9.71655 8.77498 10.0051L9.25012 10.4678L10.698 8.98101C10.9866 8.68471 11.4607 8.67842 11.757 8.96697C12.0533 9.25553 12.0596 9.72965 11.771 10.026ZM11.8298 14.4583C12.1261 14.7468 12.1324 15.221 11.8438 15.5173L9.87347 17.5406C9.58492 17.8369 9.1108 17.8432 8.81449 17.5546L7.80283 16.5694C7.50652 16.2809 7.50024 15.8068 7.78879 15.5105C8.07735 15.2142 8.55147 15.2079 8.84778 15.4964L9.32292 15.9591L10.7708 14.4723C11.0594 14.176 11.5335 14.1697 11.8298 14.4583Z" 
        fill="currentColor"
      />
    </svg>
  );
};

export default AtendimentoIcon;