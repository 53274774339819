import React from 'react'
import { ClickAwayListener, Paper, Popper, withStyles } from '@material-ui/core'

import ButtonLong from '../../../components/Button/ButtonLong'
import PreviewPerfilPublico from './PreviewPerfilPublico'
import ColorPicker from './ColorPicker'

const PRESET_COLORS = [
  '#EF4444',
  '#6366F1',
  '#FACC15',
  '#4ADE80',
  '#2DD4BF',
  '#3B82F6',
  '#F97316',
  '#EC4899',
  '#F43F5E',
  '#D946EF',
  '#8B5CF6',
  '#0EA5E9',
  '#10B981',
  '#84CC16',
]

const ColorCustomPopper = props => {
  const {
    open,
    anchorEl,
    placement,
    handleCorSelecionada,
    resetColor,
    classes,
    color,
    handleChangeColor,
    closePopper,
  } = props

  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} className={classes.popper}>
      <Paper className={classes.contentPaper}>
        <ClickAwayListener onClickAway={closePopper}>
          <div className={classes.containerColorCustom}>
            <ColorPicker
              onChange={handleChangeColor}
              color={color}
              disableAlpha
              presetColors={PRESET_COLORS}
              classes={classes}
            />
            <div className={classes.wrapperResetColor}>
              <button onClick={resetColor} className={classes.buttonResetColor}>
                Restaurar cor padrão
              </button>
            </div>
            <ButtonLong colorCustom='green' onClick={handleCorSelecionada}>
              Selecionar
            </ButtonLong>
          </div>
          <div className={classes.containerPreview}>
            <span className={classes.infoPreview}>Exemplo Aplicado ao perfil</span>
            <PreviewPerfilPublico baseColor={color} />
          </div>
        </ClickAwayListener>
      </Paper>
    </Popper>
  )
}

const styles = {
  popper: {
    zIndex: 1,
  },
  contentPaper: {
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
  },
  containerColorCustom: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '12px 0px 0px 12px',
    padding: '16px',
  },
  containerPreview: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '0px 12px 12px 0px',
    padding: '16px',
    width: '380px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '&>div': {
      height: 'auto !important',
    },
  },
  infoPreview: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#505050',
  },
  wrapperResetColor: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    padding: '16px 0',
  },
  buttonResetColor: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#5F6368',
    fontWeight: 700,
    fontSize: '0.625rem',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      opacity: 0.8,
    },
  },
}

export default withStyles(styles)(ColorCustomPopper)
