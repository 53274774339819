import React from "react";
import moment from "moment";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import styles from "./WeekCalendar.style";
import string from "../../../utils/string";

const semanas = [0, 1, 2, 3, 4, 5, 6];

const WeekCalendarHeader = props => {
  const {
    selectedDate,
    classes,
    informacoesAgendamento
  } = props;

  return (
    <div className={classes.weekCalendarHeaderInner}>
      {semanas.map((week) => {
        const currentDate = moment(selectedDate).weekday(week);
        const nomeDiaSemana = string.capitalize(currentDate.format("ddd"));
        const today = moment(currentDate).format("YYYYMMDD") === moment().format("YYYYMMDD");

        return (
          <div key={week} className={classes.weekCalendarColumn}>
            <div className={classNames(classes.weekCalendarColumnHeader, {[classes.weekCalendarColumnHeaderTodayLabel]: today})}>
              <span
                className={
                  classes.weekCalendarColumnHeaderDayWeekLabel
                }
              >
                {(currentDate.format("ddd")).toUpperCase()}
                <Tooltip 
                  classes={{ tooltip: classes.tooltip }} 
                  title={`
                    ${informacoesAgendamento[nomeDiaSemana]?.quantidadeAgendamentos} agendamentos e 
                    ${informacoesAgendamento[nomeDiaSemana]?.horariosDisponiveis} horários disponíveis`
                  }
                >
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </span>
              <span
                className={
                  classes.weekCalendarColumnHeaderDayLabel
                }
              >
                {currentDate.format("DD")}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(WeekCalendarHeader);