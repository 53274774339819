import React, { Component } from 'react';
import {inject} from "mobx-react";
import {withStyles} from "@material-ui/core/styles/index";
import classNames from 'classnames';
import {
    Paper,
    Popper,
  } from "@material-ui/core";
import CheckboxAtendimento from "../../components/Checkbox/CheckboxAtendimento";
import styles from "./FiltroSituacaoStyle";
import FilterIcon from '../../components/Icon/FilterIcon';


@inject("atendimentoStore", "prontuarioStore", "SADTStore")
class FiltroSituacao extends Component{
    state = {
        anchorEl: null,
        open: false,
        placement: null,
    };

    findAllAgendamento = async (filter = {}, isShowLoading = true) => {
        try {
            const { prontuarioStore } = this.props;
            await prontuarioStore.findAllAgendamento(filter, isShowLoading);
        } catch (error) {
            console.error(error);
        }
    };

    handleClick = placement => event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: state.placement !== placement || !state.open,
            placement,
        }));
    };

    changeCheckbox = e => {
        const { prontuarioStore, changeDate } = this.props;
        let dateFilter = {
            search: prontuarioStore.searchTextAgendamento,
            dataInicial: changeDate
                .startOf("day")
                .format("YYYY-MM-DD"),
            dataFinal: changeDate.endOf("day").format("YYYY-MM-DD")
        };

        for (let i of this.props.atendimentoStore.situacoes) {
            if (i.value === e.target.value) {
                i.disabled = !e.target.checked;
                break;
            }
        }

        this.findAllAgendamento(dateFilter);
    };

    render() {
        const { classes, atendimentoStore, openDrawer } = this.props;
        const { anchorEl, open, placement } = this.state;

        return (
          <div className={classes.divFiltro}>
            <div onClick={this.handleClick(openDrawer ? "bottom-end" : "bottom")} className={classNames(classes.buttonFilter, { [classes.buttonFilterCircle]: !openDrawer, [classes.buttonSelected]: open })}>
              <FilterIcon className={classNames(classes.filterIcon, { [classes.filterIconSelected]: open}) } />
              { openDrawer && <div> Filtrar </div>}
            </div>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              className={classes.popper}
            >
              <Paper className={classes.typography}>
                <div className={classes.filtros}> Filtros: </div>
                <div className={classes.checkboxOptions}>
                  {atendimentoStore.filtroSituacoes.map((item, index) => (
                    <CheckboxAtendimento
                      checked={atendimentoStore.status.includes(item.value)}
                      color={item.cor}
                      value={item.value}
                      label={item.name}
                      key={index}
                      onChange={(e) =>
                        this.changeCheckbox(e, atendimentoStore.status)
                      }
                    />
                  ))}
                </div>
              </Paper>
            </Popper>
          </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FiltroSituacao);