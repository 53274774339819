import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { Typography, Fab, Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import InputForm from "../../../components/Input/InputForm";
import { TextFieldSearch } from "../../../components/TextField";
import styles from "./SADTStyle";
import { TypeModalEnum } from "../../../stores/SADT.store";
import ContentProcedimentosSelecionados from "./ContentProcedimentosSelecionados";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import classNames from "classnames";

const statusNovoModelo = [
  { name: "Ativo", value: true },
  { name: "Inativo", value: false },
];

const InformacoesSADT = observer((props) => {
  const { classes, SADTStore, formErrors } = props;
  const { readOnly, typeModal, objView } = SADTStore;

  const handleChangeNomeModelo = (e) => {
    const value = e.target.value;
    SADTStore.nomeModelo = value;
  };

  const handleChangeStatusNovoModelo = (e) => {
    SADTStore.changeFieldsStore({
      statusNovoModelo: e.value
    });
  };

  const handleIndicacaoClinicaLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllCid10Subcategoria",
    });
  };

  const handleTabelaLoadOptions = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findTabelasDisponiveisTussView",
      field: "tabela",
    });
  };

  const handleCodigoTermoLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptionsDescricao({
      search: search,
      loadedOptions,
      data: { page },
      method: "findAllTussView",
      field: "codigoTermo",
    });
  };

  const handleLoadMoreOptionsDescricao = async ({
    search,
    data,
    method,
    sortDir,
    field,
  }) => {
    const response = await SADTStore[method](
      {
        search,
        tabela: SADTStore.objView.tabela
          ? SADTStore.objView.tabela.value
          : null,
        pageableDTO: {
          pageNumber: data.page,
          sortDir,
        },
      },
      field
    );
    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const handleLoadMoreOptions = async ({ search, data, method, field }) => {
    let searchDTO = {};

    if (data.searchDTO) {
      searchDTO = {
        ...data.searchDTO,
      };
    }
    const response = await SADTStore[method](
      {
        pageNumber: data.page,
        search,
        ...searchDTO,
      },
      field
    );
    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const handleChange = (field, value) => {
    switch (field) {
      case "descricao":
        if (value !== null) {
          SADTStore.objView.descricao = {
            value: value.substring(0, 255) || "",
            label: value.substring(0, 255),
          };
        } else {
          SADTStore.objView.descricao = "";
          SADTStore.objView.codigoTermo = "";
        }
        break;

      case "quantidadeSolicitada":
        const quantidadeSolicitada = parseInt(value);
        if (quantidadeSolicitada <= 999 && quantidadeSolicitada > 0) {
          SADTStore.objView.quantidadeSolicitada = quantidadeSolicitada;
        }
        break;

      case "observacao":
        SADTStore.objView.observacao = value.substring(0, 255) || "";
        break;

      case "tabela":
        SADTStore.objView.tabela = value;
        SADTStore.objView.descricao = "";
        SADTStore.objView.codigoTermo = "";
        break;

      case "codigo":
        if (value !== null) {
          SADTStore.objView.descricao = {
            value: value.descricao,
            label: value.descricao,
          };
          SADTStore.objView.tabela = {
            value: value.tabela,
            label: value.tabela,
          };
          SADTStore.objView.codigoTermo = value;
        } else {
          SADTStore.objView.descricao = "";
          SADTStore.objView.codigoTermo = "";
        }
        break;

      case "indicacaoClinica":
        SADTStore.objView.indicacaoClinica = {
          value: value?.id,
          label: value?.descricaoAbreviada,
        };
        break;
      default:
        SADTStore.objView[field] = value;
    }
  };

  const getErrorMessageFormTuss = (form) => {
    const quantidadeSolicitada = parseInt(form.quantidadeSolicitada, 10) || 0;
    if (!form.tabela || !form.codigoTermo || !form.descricao) {
      return "Selecione a guia de serviço TUSS";
    }

    if (quantidadeSolicitada > 999 || quantidadeSolicitada < 1) {
      return "A quantidade solicitada deve ser entre 1 e 999";
    }

    return "";
  };

  const handleClickAdd = () => {
    const quantidadeSolicitada =
      parseInt(objView.quantidadeSolicitada, 10) || 0;
    const errors = getErrorMessageFormTuss(objView);

    if (!errors) {
      objView.guiaServicoTussViews = [
        ...objView.guiaServicoTussViews,
        {
          descricao: objView.descricao.value,
          quantidadeSolicitada,
          tussView: {
            tabela: objView.tabela.value,
            codigoTermo: objView.codigoTermo.value,
            descricao: objView.descricao.value,
          },
        },
      ];

      objView.codigoTermo = "";
      objView.descricao.label = "";
      objView.quantidadeSolicitada = 1;
    } else {
      SADTStore.openNotification(errors, "error");
    }
  };

  return (
    <>
      <Grid container className={classes.headerContent}>
        {typeModal !== TypeModalEnum.SADT_MODAL && (
          <Grid className={classes.obsStatus}>
            <Grid item xs={8} className={classes.input}>
              <Typography
                color="primary"
                component="label"
                className={classes.label}
              >
                Nome do modelo:
              </Typography>
              {!readOnly && (
                <InputForm
                  alternativeInputClass={classes.inputObservacoes}
                  className={classNames([classes.inputObs, formErrors.nome ? classes.inputError : null])}
                  placeholder=""
                  value={SADTStore.nomeModelo}
                  onChange={handleChangeNomeModelo}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <div className={classes.input}>
                <Typography
                  color="primary"
                  component="label"
                  className={classes.label}
                >
                  Status:
                </Typography>
                <ReactSelect
                  className={classes.selectStatus}
                  name="status"
                  placeholder="Status"
                  value={SADTStore.statusNovoModelo ? statusNovoModelo[0] : statusNovoModelo[1]}
                  onChange={handleChangeStatusNovoModelo}
                  options={statusNovoModelo || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                />
              </div>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <div className={classes.input}>
            <Typography
              color="primary"
              component="label"
              className={classes.label}
            >
              Indicação Clínica:
            </Typography>
            {readOnly && (
              <Typography color="inherit" component="label">
                {objView.indicacaoClinica?.label || ""}
              </Typography>
            )}
            {!readOnly && (
              <TextFieldSearch
                placeholder=""
                classNotched={classes.notchedOutline}
                classInput={classNames([classes.inputTextField, formErrors.indicacaoClinica ? classes.inputError : null])}
                classIcons={classes.classIcons}
                loadOptions={handleIndicacaoClinicaLoadOptions}
                withPaginate
                value={objView.indicacaoClinica}
                onChange={(e) => handleChange("indicacaoClinica", e)}
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.input}>
            <Typography
              color="primary"
              component="label"
              className={classes.label}
            >
              Observações/ Justificativa:
            </Typography>
            {readOnly && (
              <Typography color="inherit" component="label">
                {objView.observacao || " - "}
              </Typography>
            )}
            {!readOnly && (
              <InputForm
                alternativeInputClass={classes.inputObservacoes}
                className={classes.inputObs}
                placeholder=""
                value={objView.observacao}
                onChange={(e) => handleChange("observacao", e.target.value)}
              />
            )}
          </div>
        </Grid>

        {!readOnly && (
          <>
            <Grid item xs={2}>
              <div className={classes.input}>
                <Typography
                  color="primary"
                  component="label"
                  className={classes.label}
                >
                  Tabela:
                </Typography>
                <TextFieldSearch
                  placeholder=""
                  classNotched={classes.notchedOutline}
                  classInput={classes.inputTextField}
                  classIcons={classes.classIcons}
                  loadOptions={handleTabelaLoadOptions}
                  withPaginate
                  value={objView.tabela}
                  onChange={(e) => handleChange("tabela", e)}
                  debounceTimeout={300}
                  additional={{
                    page: 0,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.input}>
                <Typography
                  color="primary"
                  component="label"
                  className={classes.label}
                >
                  Código e descrição tuss:
                </Typography>
                <TextFieldSearch
                  key={`autocomplete-codigo-by-table-${objView.tabela?.value}`}
                  placeholder=""
                  classNotched={classes.notchedOutline}
                  classInput={classes.inputTextField}
                  classIcons={classes.classIcons}
                  loadOptions={handleCodigoTermoLoadOptions}
                  withPaginate
                  value={objView.codigoTermo}
                  onChange={(e) => handleChange("codigo", e)}
                  debounceTimeout={300}
                  additional={{
                    page: 0,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.input}>
                <Typography
                  color="primary"
                  component="label"
                  className={classes.label}
                >
                  Descrição:
                </Typography>
                <InputForm
                  alternativeInputClass={classes.inputObservacoes}
                  className={classes.inputObs}
                  placeholder=""
                  value={objView.descricao?.label}
                  onChange={(e) => handleChange("descricao", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={classes.input}>
                <Typography
                  color="primary"
                  component="label"
                  className={classes.label}
                >
                  Qtde.:
                </Typography>
                <InputForm
                  alternativeInputClass={classes.inputQtd}
                  type="number"
                  placeholder=""
                  value={objView.quantidadeSolicitada}
                  onChange={(e) =>
                    handleChange("quantidadeSolicitada", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={classes.buttonAction}>
                <Fab className={classes.buttonIcon} onClick={handleClickAdd} color="primary">
                  <AddIcon />
                </Fab>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <ContentProcedimentosSelecionados/>
    </>
  );
});

const InformacoesSADTWithStyles = withStyles(styles)(InformacoesSADT);
export default inject("SADTStore")(InformacoesSADTWithStyles);
