import React, { useEffect, useState } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import InputMaskCurrency from '../../../components/Input/InputMaskCurrency';
import classNames from 'classnames';
import VisibilityIcon from '../../../components/Icon/VisibilityIcon';
import VisibilityOffIcon from '../../../components/Icon/VisibilityOffIcon';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import { TextFieldSearch } from '../../../components/TextField';
import { findAllAnamneseModelo } from '../../../services/AnamneseService';

import { getUnidadeLogado, getProfissionalSaudeLogado } from '../../../services/UsuarioService';

const styles = ({
  container: {
    height: 'fit-content',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    padding: '8px',
    borderRadius: '8px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    paddingRight: '45px',
    marginBottom: '10px'
  },
  headerText: {
    fontSize: '10px !important',
    fontWeight: '400',
    color: '#868686'
  },
  itemsArea: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '10px'
  },
  itemText: {
    flex: 1,
    fontSize: '14px',
    fontWeight: '400',
    color: '#505050'
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  input: {
    height: '32px',
    width: '90px',
    borderRadius: '8px',
    backgroundColor: '#F2F2F2',
    padding: '4px 4px 4px 8px',
    color: '#505050'
  },
  disableInput: {
    opacity: '0.5'
  },
  tooltip: {
    display: 'flex',
    width: '318px',
    backgroundColor: '#FFF',
    color: '#5F6368',
    padding: '12px',
    borderRadius: '8px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    fontSize: '12px !important',
    lineHeight: '122%',
    fontWeight: '400'
  },
  textDisabled: {
    textDecoration: 'line-through'
  },
  inputError:{
    border: '1px solid #FB7676'
  },
  inputContainer:{
		width: '90px',
		background: '#F2F2F2',
		color: '#505050',
		borderRadius: '8px',
		height: '32px',
		padding: '4px 8px',

		'& > div': {
			'& > p': {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			}
		}
	},
  classIcons: {
    fontSize: 24,
  },
  notchedOutline: {
    border: '0',
  },
  withoutMarginBottom: {
		margin: '10px 0 0 0'
	},
});

const tooltipMessageStyle = {
  fontSize: '12px',
  lineHeight: '122%',
  fontWeight: '400'
};

const DisabledByUtilizaTelemedicinaMessage = (
  <span style={tooltipMessageStyle}>
    Você não ativou o item <b>Teleconsulta</b> no seu painel.<br/> Ao ativar ele habilitará esse campo de valor para ser configurado.
  </span>
);
const DisabledByAtendePresencial = (
  <span style={tooltipMessageStyle}>
    Você não ativou o item <b>Atendimento Presencial</b> no seu painel.<br/> Ao ativar ele habilitará esse campo de valor para ser configurado.
  </span>
);

const hoverOptDefault = {
  hover: false,
  index: null,
  type: ''
};

const EspecialidadeValores = observer(({ 
  classes, 
  especialidadesItems, 
  utilizaTelemedicina, 
  atendePresencial, 
  perfilType,
  onChangeValue,
  removeFromList
}) => {
  const [hoverOpt, setHoverOpt] = useState({ hover: false, index: null, type: '' });
  const [profissionalSaudeId, setProfissionalSaudeId] = useState(null);
  const [unidadeId, setUnidadeId] = useState(null);

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    const { id: profissionalSaudeId } = await getProfissionalSaudeLogado();
    const { id: unidadeId } = await getUnidadeLogado();
    setProfissionalSaudeId(profissionalSaudeId);
    setUnidadeId(unidadeId);
  }

  const handleChange = (value, field, index) => {
    const formatedValue = value.replace('R$', '');
    onChangeValue(formatedValue, field, index);
  };

  const loadAllAnamneseModelo = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 30,
    };

    try {
      const response = await findAllAnamneseModelo({
        searchDTO: {
          search,
          profissionalSaudeId,
          unidadeId,
        },
        pageableDTO,
      });
      const {
        content,
        last,
      } = response
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error){
      console.log(error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    }
  };

  const renderEspecialidadesItemsValores = () => {
    const items = especialidadesItems.map((item, index) => {
      const onlineFieldDisabled = perfilType === 'profissional' ? !utilizaTelemedicina : !utilizaTelemedicina || !item.ativo;
      const presentialFieldDisabled = perfilType === 'profissional' ? !atendePresencial : !atendePresencial || !item.ativo;
      const hoverCurrentItem = hoverOpt.hover && hoverOpt.index === index;
      const valorOnlineError = utilizaTelemedicina && !item.valorOnline;
      const valorPresencialError = atendePresencial && !item.valorPresencial;

      const inputStateClass = (fieldDisabled, error) => {
        if(fieldDisabled) {
          return classes.disableInput;
        }
        if(error && perfilType === 'profissional') {
          return classes.inputError;
        }
        return null
      }
      
      return (
        <div className={classes.itemContainer} key={index}>
          <span 
            className={classNames(classes.itemText, !item.ativo && perfilType ==='unidade' ? classes.textDisabled : null)}>
              {item.especialidadeMedica.especialidade}
            </span>
            <div>
            <TextFieldSearch
                placeholder="Selecione"
                classNotched={classes.notchedOutline}
                classInput={classes.inputContainer}
                classIcons={classes.classIcons}
                styles={{ 
                  dropdownIndicator: (provided) => ({ ...provided, padding: 0 }),
                }}
                loadOptions={loadAllAnamneseModelo}
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option}
                value={item.anamneseModelo}
                onChange={(value) => onChangeValue(value, 'anamneseModelo', index)}
                withPaginate
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                menuPosition="fixed"
                className={[
                  classes.inputContainer,
                  classes.withoutMarginBottom,
                ]}
              />
            </div>
          <Tooltip
            open={
              !utilizaTelemedicina && 
              hoverOpt.type === 'online' &&
              hoverCurrentItem
            }
            placement="top"
            title={DisabledByUtilizaTelemedicinaMessage}
            classes={{
              tooltip: classes.tooltip
            }}
          >
            <div
              onMouseEnter={() => setHoverOpt({ hover: true, index, type: 'online' })}
              onMouseLeave={() => setHoverOpt(hoverOptDefault)}
            >
              <InputMaskCurrency 
                className={classNames(classes.input, inputStateClass(onlineFieldDisabled, valorOnlineError))}
                disabled={onlineFieldDisabled}
                value={item.valorOnline || ''}
                onChange={(e) => handleChange(e.target.value, 'valorOnline', index)}
              />
            </div>
          </Tooltip>
          <Tooltip
            open={
              !atendePresencial && 
              hoverOpt.type === 'presencial' && 
              hoverCurrentItem
            }
            placement="top"
            title={DisabledByAtendePresencial}
            classes={{
              tooltip: classes.tooltip
            }}
            >
            <div
              onMouseEnter={() => setHoverOpt({ hover: true, index, type: 'presencial' })}
              onMouseLeave={() => setHoverOpt(hoverOptDefault)}
            >
              <InputMaskCurrency 
                className={classNames(classes.input, inputStateClass(presentialFieldDisabled, valorPresencialError))}
                disabled={presentialFieldDisabled}
                value={item.valorPresencial || ''}
                onChange={(e) => handleChange(e.target.value, 'valorPresencial', index)}
              />
            </div>
          </Tooltip>
          {perfilType === 'profissional' && (
            <DeleteIcon
              size={24}
              color="#868686"
              className={classes.icon} 
              onClick={() => removeFromList(index)}
            />
          )}
          {perfilType === 'unidade' && !item?.ativo && (
            <VisibilityIcon 
              size={22} 
              color="#868686"
              className={classes.icon}
              onClick={() => onChangeValue(true, 'ativo', index)}
            />
          )}
          {perfilType === 'unidade' && item?.ativo && (
            <Tooltip 
              title='Ao clicar em ocultar uma especialidade você não exibirá mais ela em seu perfil público.' 
              placement='top'
              classes={{
                tooltip: classes.tooltip
              }}
            >
              <VisibilityOffIcon 
                size={22} 
                color="#868686"
                className={classes.icon} 
                onClick={() => onChangeValue(false, 'ativo', index)}
              />
            </Tooltip>
          )}
        </div>
      )
    });
    return items;
  }
  
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.headerText} style={{flex: 1}}>Especialidade</span>
        <span className={classes.headerText} style={{width: '90px', marginRight: '10px'}}>Anamnese</span>
        <span className={classes.headerText} style={{width: '90px', marginRight: '10px'}}>Valor Online</span>
        <span className={classes.headerText} style={{width: '90px'}}>Valor Presencial</span>
      </div>
      {especialidadesItems?.length > 0 && (
        <div className={classes.itemsArea}>
          {renderEspecialidadesItemsValores()}
        </div>
      )}
    </div>
  )
});

export default withStyles(styles)(EspecialidadeValores);
