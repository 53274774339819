import React from 'react';
import Button from '@material-ui/core/Button';

import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        borderRadius: '100px',
        minHeight: 37,
        width: '100px',
        border: 0,
        background: theme.palette.commons.red,
        color: theme.palette.commons.white,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        padding: '0px 16px',
        "& span": {
            fontSize: 14,
            fontWeight: 700
        },
        "&:hover": {
            background: theme.palette.commons.darkRed,
            color: theme.palette.commons.white,
        }
    }
});

const ButtonCancel = (props) => {
    const {classes} = props;
    return <Button className={classes.root} {...props}/>;
};


export default withStyles(styles)(ButtonCancel);