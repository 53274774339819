import { withStyles } from '@material-ui/core';
import React from 'react';
import style from './MensagemListaVaziaStyle';

const MensagenListaVazia = props => {
    const { classes } = props;

    return(
        <div className={classes.mensagemDeListaVazia}>
            Nenhum item encontrado
        </div>
    )
};

export default withStyles(style)(MensagenListaVazia);