import { inject } from "mobx-react";
import { CircularProgress, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import localStorageService, {
  ACCESS_TOKEN_KEY,
} from "../../../services/storage";
import { buildUrlDownload } from "../../../config/config";

const Documento = ({ classes, solicitacoesPacientesStore }) => {
  const { propsModalSolicitacoesPacientes } = solicitacoesPacientesStore;
  const { dados } = propsModalSolicitacoesPacientes;
  const [url, setUrl] = useState();
  const [imageIsLoaded, setImageIsLoaded] = useState(false)

  useEffect(() => {
    getUrl();
  }, []);

  const getUrl = async () => {
    const token = await localStorageService.get(ACCESS_TOKEN_KEY);
    const url = buildUrlDownload(dados.urlDownload, token);

    setUrl(url);
  };

  return (
    <div className={classes.contentImagem}>
      {
        !imageIsLoaded && <CircularProgress color='primary' size={24}/>
      }
      <img
        onLoad={() => setImageIsLoaded(true)}
        src={url}
        alt={dados.amazonS3Objeto.nome}
        className={classes.imagem}
      />
    </div>
  );
};

const styles = {
  contentImagem: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  imagem: {
    maxWidth: "100%",
  },
};

const DocumentoWithStyles = withStyles(styles)(Documento);
export default inject("solicitacoesPacientesStore")(DocumentoWithStyles);
