import {action, observable} from 'mobx';
import BaseStore from "./Base.store";
import moment from 'moment';

export default class RelatorioStore extends BaseStore {

  @observable configuracaoUnidade = null;

  @action verificaLimiteTrintaDias = (dataInicial, dataFinal) => {
    const dataInicialMoment = moment(dataInicial);
    const dataFinalMoment = moment(dataFinal);

    const dias = dataFinalMoment.diff(dataInicialMoment, 'days');

    return dias <= 31;
  };

}