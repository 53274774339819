import { action, observable } from "mobx";
import Api, { AuthApi } from "../config/api";
import { createMutation } from "../pages/Querys";
import string from "../utils/string";
import moment from "moment";
import localStorageService, {
	ACCESS_TOKEN_KEY,
	USUARIO_LOGADO_KEY,
	PROFISSIONAL_SAUDE_LOGADO_KEY
} from "../services/storage";
import { getProfissionalSaudeLogado } from "../services/UsuarioService";
import { configuracaoHorarioAtendimentoVigente } from "../services/ConfiguracaoHorarioAtendimentoService";

const profissionalSaudeDefault = {
	id: "",
	nome: "",
	cpf: "",
	cns: "",
	ativo: "",
	dataNascimento: "",
	numeroConselho: "",
	convenios: [],
	conselhoProfissionalSaude: {
		id: 0,
		descricao: "",
		sigla: ""
	},
	ufConselho: { value: "", name: "" },
	possuiAgenda: "",
	utilizaAssinaturaDigital: false,
	utilizaSecretariaVirtual: false,
	utilizaTelemedicina: false,
	permiteAvaliacaoVizi: false,
	utilizaAgendamentoRetorno: false,
	retornoDias: null,
	valorTelemedicina: "",
	especialidades: [],
	usuario: {
		email: "",
		fotoPerfil: "",
	},
	tuss24TerminologiaCBO:{
		codigoTermo: '',
		termo: '',
	},
	perfilPublico: false,
	enviaEmailConfirmacaoAgendamento: false,
	atendePresencial: true,
	atendeCrianca: true,
	utilizaVIZI: false,
	compartilharProntuario: false,
	visualizaOutrosProntuarios: true,
	visualizarRegistroInativo: false,
};

const informacaoDefault = {
  formasPagamento: [],
  idiomas: [],
  profissionalSaudeProfissao: null
};

const profissionalSaudePublico = {
	especialidades:[],
	informacao: {
		id: "",
		convenios: [],
		experiencia: "",
		formacao: "",
		formasPagamento: [],
		idiomas: [],
		tuss24TerminologiaCBO:{
			codigoTermo: '',
			termo: '',
		},
		profissionalSaudeProfissao: {
			descricao: "",
			id: ""
		},
		sobre: "",
		telefonePrincipal: "",
		telefoneSecundario: "",
  },
};

export default class ProfissionalSaudeStore {
	usuarioStore = null;
	perfilPublicoStore = null;
	filtroHeaderStore = null;
	@observable profissionalSaude = profissionalSaudeDefault;
	@observable conselhosList = [];
	@observable estadosList = [];
	@observable prefixosList = [];
	@observable profissionalSaudePublico = profissionalSaudePublico;
	@observable convenioList = []
	@observable languageList = []
	@observable paymentMethodsList = []
	@observable professionList = []
	@observable specializationList = []
	@observable notification = {
		isOpen: false,
		variant: "",
		message: "",
	};

	@observable errors = {
		cpf: false,
		nome: false,
		cns: false,
		numeroConselho: false,
		email: false,
		dataNascimento: false,
	};

	@observable profissionaisSaudeUnidade = [];
	@observable loadingSaveDadosPerfil = false;
	@observable perfilPublicoSalvo = false;
	@observable isLoadingInfo = false;
	@observable profissionalHasAgenda = false;
	@observable canPrintSigned = false;

	@action async findByUnidadeComAgenda(unidadeId, especialidadeMedica, tipoConsulta) {
		try {
			const { data } = await Api.post("", {
				query: `
                        query ($unidadeId: Long, $pageableDTO: PageableDTOInput, $tipoConsulta: TipoConsulta, $especialidadeMedica: Long) {
                            query: findProfissionalSaudeByUnidadeComAgenda(unidadeId: $unidadeId, pageableDTO: $pageableDTO, tipoConsulta: $tipoConsulta, especialidadeMedica: $especialidadeMedica) {
                                id
                                nome
                                cns
                                cpf
								utilizaAgendamentoRetorno
																procedimentos {
																	id
																	nome
																}
																especialidades {
																	especialidade
																	id
																}
                            }
                        }
                        `, 				
						variables: {
							unidadeId: unidadeId,
							pageableDTO: {
								sortDir: "ASC",
								sortField: "nome",
								pageSize: 999,
								pageNumber: 0
							},
							...(especialidadeMedica && {especialidadeMedica: especialidadeMedica}),
							...(tipoConsulta && {tipoConsulta: tipoConsulta}),
						}
			});

			if (data.errors) {
				throw data.errors[0];
			}

			return data.data.query;
		} catch (error) {
			throw error;
		}
	}

	@action async findByUnidadeSemAgenda(unidadeId) {
		try {
			const { data } = await Api.post("", {
				query: `
                        query {
                            query: findProfissionalSaudeByUnidadeSemAgenda(unidadeId: ${unidadeId}) {
                                id
                                nome
                                cns
                                cpf
                                usuario {
                                    id
                                    nome
                                }
                            }
                        }
                        `,
			});

			if (data.errors) {
				throw data.errors[0];
			}

			return data.data.query;
		} catch (error) {
			throw error;
		}
	}

	@action async findProfissionalSaudeByUnidadeAndTipo(unidadeId, tipo) {
		try {
			const response = await Api.post("", {
				query: `
				query ($tipo: Long, $unidadeId: Long) {
					findProfissionalSaudeByUnidadeAndTipo(tipo: $tipo, unidadeId: $unidadeId) {
						id
						nome
						cns
						cpf
						usuario {
							id
							nome
						}
					}
				  }
				`,
				variables: {
					tipo: tipo,
					unidadeId: unidadeId
				}
			});

			if (response.errors) {
				throw response.errors[0];
			}

			return response.data.data.findProfissionalSaudeByUnidadeAndTipo;
		} catch (error) {
			throw error;
		}
	}

	@action async carregaProfissionalSaude() {
		try {
			const { data } = await AuthApi.post("graphql", {
				query: `{
                        obterUsuarioLogado {
							id
							nome
							login
							fotoPerfil
							email
							authorities {
								authority
							}
                        }
                    }`,
			});

			if (data.errors) {
				throw data.errors[0];
			}
			const profissionalSaudeLogado = await getProfissionalSaudeLogado();

			const usuarioLogado = data.data.obterUsuarioLogado;
			this.profissionalSaude = profissionalSaudeLogado;
			
			this.profissionalSaude = {
				...this.profissionalSaude,
				compartilharProntuario: !this.profissionalSaude.prontuarioExclusivo,
				usuario: {
					email: usuarioLogado.email,
					fotoPerfil: usuarioLogado.fotoPerfil,
				},
			};
			this.canPrintSigned = !!(this.profissionalSaude?.utilizaAssinaturaDigital && this.profissionalSaude?.numeroConselho && this.profissionalSaude?.ufConselho);
			this.profissionalSaude.dataNascimento = this.profissionalSaude
				.dataNascimento
				? moment(this.profissionalSaude.dataNascimento, "YYYY-MM-DD")
				: null;
			this.perfilPublicoSalvo = profissionalSaudeLogado.perfilPublico;

			await localStorageService.set(PROFISSIONAL_SAUDE_LOGADO_KEY, profissionalSaudeLogado);
		} catch (error) {
			throw error;
		}
	}

	@action async findByIdProfissionalSaudePublico(id) { 
		if(this.profissionalSaudePublico?.id) return
		try {
			const response = await Api.post("", {
				query: `
				query ($id: Long){
					findByIdProfissionalSaude(id: $id){
						id
						tipo
						perfilPublico
						tuss24TerminologiaCBO{
							id
							codigoTermo
							termo
						}
						especialidades {
							especialidade
							id
						}
						procedimentos {
							id
							nome
						} 
						informacao {
							id
							experiencia
							formacao
							formasPagamento{
								id
								nome
							}
							convenios {
								id
								descricao
							}
							idiomas {
								id
								ativo
								descricao
							}
							profissionalSaudeProfissao{
								id
								descricao
							}
							sobre
							telefonePrincipal
							telefoneSecundario
						}
						prontuarioExclusivo
						utilizaVIZI
					}
				}
			`,
                variables: { id },
            });
			this.profissionalSaudePublico = response.data.data
				.findByIdProfissionalSaude.informacao
				? response.data.data.findByIdProfissionalSaude
				: {
					...response.data.data.findByIdProfissionalSaude,
					informacao: informacaoDefault,
				};
            return response.data.data.findByIdProfissionalSaude;
        } catch (error) {
            this.openNotification(
                "Erro ao encontrar o profissional de saúde.",
                "error"
            );
        }
	}

	@action isCartaoCreditoSelected = () => {
		const selectedPaymentMethods = this.profissionalSaudePublico?.informacao?.formasPagamento;
		return selectedPaymentMethods?.some?.((method) => method.id === 2);
	}

	@action insereCartaoCredito() {
		if(!this.isCartaoCreditoSelected() && this.profissionalSaude.utilizaTelemedicina) {
			this.profissionalSaudePublico.informacao.formasPagamento= [
				...this.profissionalSaudePublico?.informacao.formasPagamento,
				{
					id: 2,
					nome: "Cartão de Crédito",
				},
			]
		}
	}

	@action async carregarInformacoesUsuarioLogado() {
		try {
			const { data } = await Api.post("", {
				query: `{
						findProfissionalSaudeLogado {
							nome
							conselhoProfissionalSaude {
								id
								descricao
								sigla
							}
							tuss24TerminologiaCBO {
								id
								codigoTermo
								termo
							}
						}
					}
				`
			});

			if (data.errors) {
				throw(data.errors[0]);
			}

			const { conselhoProfissionalSaude, tuss24TerminologiaCBO } = data.data.findProfissionalSaudeLogado;
			this.profissionalSaude.conselhoProfissionalSaude = conselhoProfissionalSaude;
			this.profissionalSaude.tuss24TerminologiaCBO = tuss24TerminologiaCBO

		} catch (error) {
			throw error;
		}
	}

	especialidadeSemValor = () => {
		const especialidades = this.perfilPublicoStore.perfilPublicoProfissional.especialidades.map(item => ({
			id: item.especialidadeMedica.id
		}))

		return especialidades;
	};

	@action async updateProfissionalSaudeLogado(location) {
		if ((location === 'dadosPrincipais' && !this.contemErros()) || ((location === 'dadosPublicos') && !this.contemErrosPerfilVizi())) {
			try {
				this.loadingSaveDadosPerfil = true;

				const profissionalSaude = {
					...this.profissionalSaude,
					valorTelemedicina: string.currencyMaskToFloat(this.profissionalSaude.valorTelemedicina),
					cpf: string.removeSpecialChars(this.profissionalSaude.cpf),
					especialidades: this.especialidadeSemValor(),
					tuss24TerminologiaCBO: this.profissionalSaude.tuss24TerminologiaCBO,
					informacao: 
						this.profissionalSaude.perfilPublico && this.profissionalSaudePublico.informacao ? {
							...this.profissionalSaudePublico.informacao,
							telefonePrincipal: string.removeSpecialChars(this.profissionalSaudePublico.informacao.telefonePrincipal),
							telefoneSecundario: string.removeSpecialChars(this.profissionalSaudePublico.informacao.telefoneSecundario)
						} : null
					,
					dataNascimento: moment(
						this.profissionalSaude.dataNascimento
					)?.isValid()
						? moment(
								this.profissionalSaude.dataNascimento,
								"DD/MM/YYYY"
							).format("YYYY-MM-DD")
						: this.profissionalSaude.dataNascimento,
					prontuarioExclusivo: !this.profissionalSaude.compartilharProntuario,
				};
				delete profissionalSaude.compartilharProntuario;

				Api.post(
					"",
					JSON.stringify({
						query: createMutation(
							{
								name: "updateProfissionalSaudeLogado",
								objType: "ProfissionalSaudeInput",
								objName: "profissionalSaude",
								attrsReturn: `
									id
									ativo
									atendePresencial
									especialidades {
										id
										especialidade
									}
									tuss24TerminologiaCBO{
										id
										codigoTermo
										termo
									}
									perfilPublico
									cns
									cpf
									dataNascimento
									nome
									numeroConselho
									possuiAgenda
									tipo
									ufConselho
									conselhoProfissionalSaude {
										id
										descricao
										sigla
									}
									convenios {
										id
										descricao
										convenioSistema{
											id
										}
									}
									procedimentos {
										id
										nome
									}
									utilizaAssinaturaDigital
									utilizaSecretariaVirtual
									utilizaTelemedicina
									visualizaOutrosProntuarios
									prontuarioExclusivo
									utilizaVIZI
									permiteAvaliacaoVizi
									utilizaAgendamentoRetorno
									retornoDias
									atendeCrianca
									enviaEmailConfirmacaoAgendamento
									valorTelemedicina
									nomeSala
									prefixo {
										prefixo
									}
									usuario {
										email,
										fotoPerfil
									}
									informacao {
								id
								formacao
								experiencia
								formasPagamento {
									id
									nome
								}
								idiomas {
									id
									ativo
									descricao
								}
								profissionalSaudeProfissao {
									id
									descricao
									}
									sobre
									telefonePrincipal
									telefoneSecundario
								}
								visualizarRegistroInativo
							`,
							},
							"mutation"
						),
						variables: {
							profissionalSaude
						},
					})
				).then((result) => {
					const { updateProfissionalSaudeLogado } = result?.data?.data;
					if (updateProfissionalSaudeLogado) {
						this.profissionalSaude = updateProfissionalSaudeLogado;
						this.profissionalSaude = {
							...this.profissionalSaude,
							compartilharProntuario: !updateProfissionalSaudeLogado.prontuarioExclusivo,
							usuario: {
								email: updateProfissionalSaudeLogado.usuario?.email,
								fotoPerfil: updateProfissionalSaudeLogado.usuario?.fotoPerfil,
							},
						};
						this.profissionalSaude.dataNascimento = this.profissionalSaude
							.dataNascimento
							? moment(this.profissionalSaude.dataNascimento, "YYYY-MM-DD")
							: null;
						this.canPrintSigned = !!(this.profissionalSaude?.numeroConselho && this.profissionalSaude?.ufConselho);
						this.loadingSaveDadosPerfil = false;
					}

					localStorageService.set(PROFISSIONAL_SAUDE_LOGADO_KEY, this.profissionalSaude);
				});
			} catch (error) {
				throw("Erro ao atualizar cadastro.");
			}
		} else {
			if (this.errors.cpf) {
				throw "Informe um CPF válido."
			}
			throw("Campos obrigatórios não foram preenchidos!");
		}
	}

	@action
	async getEstadoList() {
		Api.post("", {
			query: `{
                        findAllEstadoList(searchDTO: {sortDir: "ASC", sortField: "id"}) {
                          value: uf
                          name: uf
                        }
                    }
                `,
		}).then((result) => {
			const { findAllEstadoList } = result.data.data;

			this.estadosList = findAllEstadoList;
		});
	}

	@action
	async getPrefixoList() {
		Api.post("", {
			query: `{
					  	findAllPrefixoList{
					  	value: prefixo
            	name: prefixo
					  }
					}
                `,
		}).then((result) => {
			const { findAllPrefixoList } = result.data.data;

			this.prefixosList = findAllPrefixoList;
		});
	}

	@action
	async getConselhoList() {
		await Api.post("", {
			query: `{
				findAllConselhoProfissionalSaudeList {
					id
					descricao
					sigla
				}
			}`
		}).then((result) => {
			const { findAllConselhoProfissionalSaudeList } = result.data.data;
			this.conselhosList = findAllConselhoProfissionalSaudeList;
		});
	}

	@action
	async getConvenioList() {
		const usuarioLogado = await localStorageService.get(USUARIO_LOGADO_KEY);
        Api.post("", {
            query: `
				query ($searchDTO: SearchDTOInput){
					findAllConvenioList(searchDTO: $searchDTO) {
						name: descricao
						value: id
						}
					}
				`,
            variables: {
                searchDTO: {
                    profissionalSaudeId: usuarioLogado.id,
                },
            },
        }).then((result) => {
			const allConvenioList = result.data.data.findAllConvenioList;
            this.convenioList = allConvenioList;
        });
	}

	@action
	async getLanguageList() {
        Api.post("", {
            query: `
				query ($ativo: Boolean){
					findAllIdiomaList(ativo: $ativo){
					name: descricao
					value: id
					}
				}
				`,
            variables: {
					ativo: true
				,
            },
        }).then((result) => {
			const allLanguageList = result.data.data.findAllIdiomaList;
            this.languageList = allLanguageList;
        });
	}

	@action
	async getPaymentMethods() {
        Api.post("", {
            query: `
				query ($searchDTO: SearchDTOInput) {
					findAllFormaPagamento(searchDTO: $searchDTO) {
					content {
						name: nome
						value: id
					}
					}
				}			  
				`,
            variables: {
                searchDTO: {
					pageSize: 10
				},
            },
        }).then((result) => {
			const allPaymentMethods = result.data.data.findAllFormaPagamento.content;
            this.paymentMethodsList = allPaymentMethods;
        });
	}

	@action
	async getProfessions() {
        Api.post("", {
            query: `
				query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
					findAllProfissionalSaudeProfissao(pageableDTO: $pageableDTO, searchDTO: $searchDTO) {
					numberOfElements
					totalElements
					content {
						name: descricao
						value: id
					}
					}
				}			  
				`,
            variables: {
                searchDTO: {
                    ativo: true,
                },
                pageableDTO: {
                    pageNumber: 0,
                    pageSize: 100,
                },
            },
        }).then((result) => {
            const professionList =
                result.data.data.findAllProfissionalSaudeProfissao.content;
            this.professionList = professionList;
        });
	}

	@action
	async getSpecializations(unidadeId=null) {
        Api.post("", {
            query: `
			query ($pageableDTO: PageableDTOInput, $searchDTO: SearchDTOInput) {
				findAllEspecialidadeMedica(pageableDTO: $pageableDTO, searchDTO: $searchDTO) {
				  totalElements
				  content {
					name: especialidade
					value: id
				  }
				}
			  }
						
				`,
				variables: {
					pageableDTO: {
					  pageNumber: 0,
					  pageSize: 999
					},
					searchDTO: {
					  ativo: true,
					  ...(unidadeId && {unidadeId})
					}
				  },
        }).then((result) => {
            const specializationsList =
                result.data.data.findAllEspecialidadeMedica.content;
            this.specializationList = specializationsList;
        });
	}

	@action checkProfissionalHasHorarioAtendimento = async () => {
		try {
			const response = await configuracaoHorarioAtendimentoVigente({
				profissionalSaudeId: this.profissionalSaude.id,
				dataInicial: moment().format('YYYY-MM-DD'),
				dataFinal: moment().format('YYYY-MM-DD'),
			});
			return Boolean(response.data?.data?.configuracaoHorarioAtendimentoVigente?.length > 0);
		} catch(error) {
			throw(error);
		}
	};

	async getAccessToken() {
		return await localStorageService.get(ACCESS_TOKEN_KEY);
	}

	contemErros() {
		this.errors = {
			cpf: string.isEmpty(this.profissionalSaude.cpf)
				? true
				: !string.validaCPF(this.profissionalSaude.cpf),
			nome: string.isEmpty(this.profissionalSaude.nome),
			cns: string.isEmpty(this.profissionalSaude.cns),
			email: string.isEmpty(this.profissionalSaude.usuario.email),
			numeroConselho: string.isEmpty(this.profissionalSaude.numeroConselho),
			dataNascimento:
				this.profissionalSaude.dataNascimento === undefined ||
				this.profissionalSaude.dataNascimento === null,
		};

		return (
			this.errors.cpf ||
			this.errors.nome ||
			this.errors.telefone ||
			this.errors.cns ||
			this.errors.dataNascimento ||
			this.errors.email ||
			(this.profissionalSaude.possuiAgenda ? this.errors.numeroConselho : false)
		);
	}

	contemErrosPerfilVizi() {
		this.errors = {
			nome: string.isEmpty(this.profissionalSaude.nome),
			email: string.isEmpty(this.profissionalSaude.usuario.email),
			dataNascimento:
				this.profissionalSaude.dataNascimento === undefined ||
				this.profissionalSaude.dataNascimento === null,
		};
		return (
			this.errors.nome ||
			this.errors.email ||
			this.errors.dataNascimento
		);
	};

	closeNotification = () => {
		this.notification.message = "";
		this.notification.variant = "";
		this.notification.isOpen = false;
	};

	resetNotification = () => {
		this.notification.message = "";
		this.notification.variant = "";
		this.notification.isOpen = false;
	};

	openNotification = (message, variant) => {
		this.notification.message = message;
		this.notification.variant = variant;
		this.notification.isOpen = true;
	};

	@action findAllProfissionalSaudeByUnidade = async () => {
		try {
			const unidade = await this.usuarioStore.getUnidadeAtual();

			const response = await Api.post("", {
				query: `
				query {
					findAllProfissionalSaudeByUnidade(unidadeId: ${unidade?.id}) {
					  id
					  nome
					  possuiAgenda
					}
				}`,
				variables: {
					pageableDTO: {
						pageNumber: 0,
						pageSize: 999
					},
					searchDTO: {
						ativo: true,
					}
					},
			});

			if (response?.data?.data?.findAllProfissionalSaudeByUnidade) {
				const profissionais = response.data.data.findAllProfissionalSaudeByUnidade
				
				this.profissionaisSaudeUnidade = [
					{
						id: 'unidade',
						nome: unidade.nome
					}, ...profissionais];
			}

		} catch(error) {
			throw(error);
		}
	};
	
}
