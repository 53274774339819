import React from "react";
import { withStyles } from "@material-ui/core";
import Header from "../../template/Header/Header";
import Profile from "../../template/Header/Profile";
import InputSearchFilter from "../../components/Input/InputSearchFilter";
import { ReactSelect } from "../../components/Select/SelectSearch";
import moment from "moment";
import InfosHeader from "./components/InfosHeader";
import { TextFieldSearch } from "../../components/TextField";

const dataAtual = moment();
const inicioSemana = moment().isoWeekday(1);
const finalSemana = moment().isoWeekday(5);
const inicioMes = moment()
  .startOf("month")
  .subtract(0, "month");
const finalMes = moment()
  .endOf("month")
  .subtract(0, "month");

export const periodos = [
  {
    value: "dia",
    label: "Dia",
    dataInicial: moment(dataAtual).format("YYYY-MM-DD"),
    dataFinal: moment(dataAtual).format("YYYY-MM-DD"),
  },
  {
    value: "semana",
    label: "Semana",
    dataInicial: moment(inicioSemana).format("YYYY-MM-DD"),
    dataFinal: moment(finalSemana).format("YYYY-MM-DD"),
  },
  {
    value: "mes",
    label: "Mês",
    dataInicial: moment(inicioMes).format("YYYY-MM-DD"),
    dataFinal: moment(finalMes).format("YYYY-MM-DD"),
  },
];

const HeaderRelatorio = (props) => {
  const {
    classes,
    withzindex,
    periodoSelecionado,
    handlePeriodo,
    hiddenButtons,
    hiddenSearch,
    title,
    totalAgendamentos,
    children,
    totalTitle,
    infosHeader,
    hiddenTotal,
    ...others
  } = props;
  return (
    <Header
      padding
      className={[classes.header, withzindex && classes.zindex]}
      disableShadow
    >
      <div className={classes.contentSearchChildren}>
        <div className={classes.contentTitleSearch}>
          {title && <div className={classes.title}> {title} </div>}
          {!hiddenSearch && (
            <>
              {others.searchWithSelect ? (
                <TextFieldSearch
                  placeholder="Selecione"
                  classNotched={classes.notchedOutline}
                  classInput={classes.inputTextField}
                  classIcons={classes.classIcons}
                  loadOptions={others.loadOptions}
                  getOptionLabel={(option) => option.nome || option.label || option.descricao}
                  getOptionValue={(option) => option.id}
                  withPaginate
                  value={others.valueSelect}
                  onChange={others.onChangeSelect}
                  debounceTimeout={300}
                  additional={{
                      page: 0
                  }}
                />
              ): (
                  <InputSearchFilter
                  placeholder="Pesquisar"
                  className={classes.inputSearch}
                  classes={{ paper: classes.paper }}
                  {...others}
                />
              )}
            </>
          )}
        </div>
        {children}
        {!hiddenButtons && (
          <div className={classes.contentPeriodo}>
            <div className={classes.titlePeriodo}> Filtrar por período: </div>
            <ReactSelect
              value={periodoSelecionado}
              onChange={handlePeriodo}
              options={periodos}
              className={classes.reactSelect}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        )}
      </div>
      <div className={classes.contentButtonsProfile}>
        {infosHeader && <div className={classes.contentInfosHeader}>
          {infosHeader.map(item => {
            return <InfosHeader title={item.title} label={item.label} />
          }) }
        </div>}
        {!hiddenTotal && (
        <div className={classes.contentTotal}>
          <div className={classes.totalTitle}> Total de {totalTitle} </div>
          <div className={classes.totalValue}> {totalAgendamentos || 0} </div>
        </div>
        )}
        <Profile />
      </div>
    </Header>
  );
};

const style = {
  header: {
    height: "80px !important",
    background: "#fff",
    justifyContent: "space-between",
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
  },
  zindex: {
    zIndex: 100,
  },
  contentButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    paddingBottom: "8px",
    gap: "8px",
  },
  contentTitleSearch: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#000000",
  },
  inputSearch: {
    height: "32px",
    width: "250px",
    background: "#F2F2F2",
    borderRadius: "8px",
    border: "1px solid rgba(220, 220, 220, 0.20)",
    justifyContent: "center",
  },
  contentSearchChildren: {
    display: "flex",
    gap: "8px",
    alignItems: "flex-end",
    minWidth: "30%",
  },
  contentButtonsProfile: {
    display: "flex",
    height: "100%",
  },
  contentTotal: {
    display: "flex",
    flexDirection: "column",
    width: "110px",
    justifyContent: "end",
  },
  totalTitle: {
    lineHeight: "16px",
  },
  totalValue: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: '20px',
  },
  contentPeriodo: {
    display: "flex",
    flexDirection: "column",
  },
  reactSelect: {
    "&> div": {
      width: "110px",
      height: "32px",
    },
  },
  titlePeriodo: {
    color: "#868686",
    fontSize: "12px",
  },
  contentInfosHeader: {
    display: 'flex',
    alignItems: 'end',
    gap: '4px',
    marginRight: '4px',
  },
  notchedOutline: {
      border: '0',
  },
  inputTextField: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    fontSize: '0.875rem',
    fontWeight: '600',
    height: '32px',
    maxWidth: '250px',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
  classIcons: {
    fontSize: 24,
  },
};

export default withStyles(style)(HeaderRelatorio);
