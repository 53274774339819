import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import ModalFinanceiro from "../../ModalFinanceiro";
import string from "../../../../utils/string";
import { defaultModalCategoriaFilho } from "../../../../stores/Financeiro/CategoriaFinanceira.store";
import InformacoesCategoriaFilho from "./InformacoesCategoriaFilho";

const CategoriaFilhoModal = observer(({ categoriaFinanceiraStore }) => {
  const { modalCategoriaFilho } = categoriaFinanceiraStore;
  const { open, objView, saving } = modalCategoriaFilho;
  const { id, nome, tipo, ativo, categoriaPai } = objView;
  const action = objView.id ? "Editar" : "Adicionar";
  const title = `${action} ${string.capitalize(objView.tipo)}`;

  const handleClose = () => {
    categoriaFinanceiraStore.modalCategoriaFilho = defaultModalCategoriaFilho;
  };

  const handleSave = async () => {
    try {
      categoriaFinanceiraStore.modalCategoriaFilho.saving = true;

      await categoriaFinanceiraStore.save({
        nome,
        id,
        tipo,
        ativo,
        categoriaFinanceiraPai: {
          id: categoriaPai.id,
        },
      });

      categoriaFinanceiraStore.modalCategoriaFilho = defaultModalCategoriaFilho;
      categoriaFinanceiraStore.findAllCategoriaFinanceira(true);
    } catch {
      categoriaFinanceiraStore.openNotification("Erro ao salvar", "error");
    } finally {
      categoriaFinanceiraStore.modalCategoriaFilho.saving = false;
    }
  };

  return (
    <ModalFinanceiro
      open={open}
      pageTitle={`Financeiro - ${title} categoria financeira`}
      handleClose={handleClose}
      modalTitle={`${title}`}
      handleSave={handleSave}
      loadingSave={saving}
      componentInformacoes={<InformacoesCategoriaFilho />}
    />
  );
});

export default inject("categoriaFinanceiraStore")(CategoriaFilhoModal);
