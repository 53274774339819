import React, { useEffect } from 'react'

import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography, DialogTitle } from '@material-ui/core'

import { TextFieldSearch } from '../../../../components/TextField'
import InputSelectComLabel from '../../../../components/Input/InputSelectComLabel'
import { InputDateForm } from '../../../../components/Modal/Input'

import { findAllTuss23TerminologiaCaraterAtendimento } from '../../../../services/GuiaDeServicosService'

const StepSolicitacao = props => {
  const {
    classes,
    openNotification,
    solicitacao,
    setDisableButton,
    handleChangeGuiaServico,
  } = props

  const isNumeric = numero => /^\d+$/.test(numero)

  useEffect(() => {
    validationStep()
  }, [solicitacao])

  const loadAllTuss23 = async (search, loadedOptions, { page }) => {
    const field = isNumeric(search) ? 'codigoTermo' : 'termo'
    const pageableDTO = {
      pageNumber: page,
      pageSize: 30,
    }

    const searchDTO = {
      [field]: search ? search : null,
    }

    try {
      const response = await findAllTuss23TerminologiaCaraterAtendimento(pageableDTO, searchDTO)
      const { content, last } = response.data.data.findAllTuss23TerminologiaCaraterAtendimento
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch {
      openNotification('Erro ao carregar caráter de atendimento', 'error')
    }
  }

  const handleChange = (field, value) => {
    let newSolicitacao = solicitacao
    newSolicitacao = {
      ...newSolicitacao,
      [field]: value && value,
      ...(value?.codigoTermo && { caraterAtendimento: value?.codigoTermo })
    }
    handleChangeGuiaServico('solicitacao', newSolicitacao)
  }

  const defaultCaraterAtendimento = async () => {
    if(solicitacao?.tuss23TerminologiaCaraterAtendimento) return
    const response = await findAllTuss23TerminologiaCaraterAtendimento({ pageNumber: 0, pageSize: 30})
    const { content } = response.data.data.findAllTuss23TerminologiaCaraterAtendimento
    const defaultOption = content.find(option => option?.codigoTermo === '1')
    if(!defaultOption) return
    handleChange('tuss23TerminologiaCaraterAtendimento', defaultOption)
  }

  const validationStep = () => {
    try {
      if (solicitacao) {
        const { tuss23TerminologiaCaraterAtendimento, data } = solicitacao
        if (!tuss23TerminologiaCaraterAtendimento || !data || data === 'Invalid date') {
          setDisableButton(true)
          return
        }
        setDisableButton(false)
      }
    } catch {
      setDisableButton(false)
    }
  }

  useEffect(() => {
    if(solicitacao?.tuss23TerminologiaCaraterAtendimento && solicitacao?.data) return
    defaultCaraterAtendimento()
    handleChange('data', moment().format('YYYY-MM-DD'))
  }, [solicitacao?.tuss23TerminologiaCaraterAtendimento, solicitacao?.data])
  
  return (
    <>
      <DialogTitle>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <Typography className={classes.typography} component='label'>
                  Caráter do atendimento
                </Typography>
                <TextFieldSearch
                  placeholder='Selecione'
                  loadOptions={loadAllTuss23}
                  classNotched={classes.notchedOutline}
                  classInput={classes.inputContainer}
                  classIcons={classes.classIcons}
                  getOptionLabel={option => `${option?.codigoTermo} - ${option?.termo}`}
                  getOptionValue={option => option}
                  value={solicitacao?.tuss23TerminologiaCaraterAtendimento}
                  onChange={option =>
                    handleChange('tuss23TerminologiaCaraterAtendimento', option)
                  }
                  withPaginate
                  debounceTimeout={300}
                  additional={{
                    page: 0,
                  }}
                  menuPosition='fixed'
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.typography} component='label'>
                  Data da solicitação
                </Typography>
                <InputDateForm
                  iconposition='end'
                  className={classes.inputDateForm}
                  value={solicitacao?.data}
                  onChange={e =>
                    handleChange('data', moment(e).format('YYYY-MM-DD'))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.typography} component='label'>
              Indicação Clínica
            </Typography>
            <InputSelectComLabel
              value={solicitacao?.indicacaoClinica}
              classes={{
                input: classes.inputContainer,
              }}
              onChange={e => handleChange('indicacaoClinica', e.target.value)}
              maxLength={500}
              noMargin
            />
          </Grid>
        </Grid>
      </DialogTitle>
    </>
  )
}

const styles = () => ({
  notchedOutline: {
    border: '0',
  },
  classIcons: {
    fontSize: 24,
  },
  typography: {
    color: '#868686',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginBottom: '4px',
  },
  inputContainer: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 8px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  inputDateForm: {
    width: '100%',
    background: '#F2F2F2 !important',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 0px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    '&>div': {
      '&>input': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
})

export default withStyles(styles)(StepSolicitacao)
