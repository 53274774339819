import React from "react";
import Dialog from "../Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonAlert from "../Button/ButtonAlert";
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, DialogActions, DialogContent, DialogContentText, Fab } from '@material-ui/core';
import { withStyles, Grid } from "@material-ui/core";



const styles = theme => ({
    alertContainer: {
        width: "400px",
        height: "500px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: "5px"
    },
    alertTitle: {
        "& h6": {
            fontSize: 16,
        },
        width: '80%'
    },
    content: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center'
    },
    containerButtons: {

    },

    buttonSalvar: {
        fontWeight: 'bold',
        width: '200px',
        height: 38,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
        borderRadius: 85,
        "& span": {
            fontSize: 15
        },
        marginLeft: 5,
        background: '#26ACA9',
        "&:hover": {
            background: '#218785'
        }
    },
    button: {
        position: 'absolute',
        right: 20,
        top: 20
    },
    buttonClose: {
        width: '24px',
        height: '24px',
        minHeight: 'initial',
        boxShadow: 'none',
        color: '#fff',
        background: '#868686',
    },
    buttonContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    imgContainer: {
        width: "100%",
        alignItems: "center",
        heigth: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column"
    },
    cardText: {
        fontSize: '12px !important',
        fontWeight: '400 !important',
        color: '#5F6368',
    },
    warningCode: {
        fontSize: '12px !important',
        fontWeight: '400 !important',
        color: '#5F6368',
        textAlign: "center"
    },
});

const QRCodeModal = ({
    open,
    onClose,
    updateQrCode,
    classes,
    imageQrCode,
    loadingQrCode }) => {




    return (

        <Dialog
            open={open}
            onClose={onClose}
        >
            <div className={classes.alertContainer}>
                <DialogTitle className={classes.alertTitle}>
                    <Grid>
                        <div>
                            QR Code
                        </div>
                        <div className={classes.button}>
                            <Fab className={classes.buttonClose} onClick={onClose}>
                                <CloseIcon fontSize={'small'} />
                            </Fab>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.content}>



                    {(!!imageQrCode && !loadingQrCode) &&
                        <>
                            <div item xs={12} className={classes.imgContainer}>
                                <img src={imageQrCode} alt="QR Code" />
                            </div>
                            <DialogContentText item xs={12} align="center">
                                <span className={classes.warningCode}>
                                    Este QR Code é válido por apenas 20 segundos clique novamente no botão para recarregar.
                                </span>


                            </DialogContentText>
                        </>
                    }
                    {loadingQrCode &&
                        <CircularProgress />
                    }
                </DialogContent>
                <DialogActions disableActionSpacing className={classes.buttonContainer}>
                    <ButtonAlert
                        className={classes.buttonSalvar}
                        onClick={() => updateQrCode()}
                        autoFocus
                    >
                        Carregar
                    </ButtonAlert>
                </DialogActions>

            </div>
        </Dialog>
    )
};

export default withStyles(styles)(QRCodeModal);