// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react';
import { inject } from 'mobx-react';
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';

import { StyledP } from './Styled';

const EndMeetingControl = (props) => {
  const meetingManager = useMeetingManager();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const { prontuarioStore } = props;

  const leaveMeeting = async () => {
    await prontuarioStore.openModalConfirmarTempoTeleconsulta();
    meetingManager.leave();
    window.close();
  };

  return (
    <>
      <ControlBarButton icon={<Phone />} onClick={toggleModal} label="Sair" />
      {showModal && (
        <Modal size="md" onClose={toggleModal} rootId="modal-root" style={{ zIndex: 9999 }}>
          <ModalHeader title="Sair da consulta" />
          <ModalBody>
            <StyledP>
              Deseja sair da consulta?
            </StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <ModalButton
                onClick={leaveMeeting}
                variant="primary"
                label="Sair"
                closesModal
              />,
              <ModalButton variant="secondary" label="Cancelar" closesModal />
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default (inject("prontuarioStore")(EndMeetingControl));
