import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import DenteSupranumerario from "./DenteSupranumerario";

const DivisorAreaDenteSupranumerario = observer(
  ({ classes, odontogramaStore }) => {
    const { denteSupranumerario } = odontogramaStore.odontograma;

    const areasDente = [
      {
        label: "Dentes superiores",
        denteSupranumerario: denteSupranumerario.filter(
          (item) => item.local === "ARCADA_SUPERIOR"
        ),
      },
      {
        label: "Dentes inferiores",
        denteSupranumerario: denteSupranumerario.filter(
          (item) => item.local === "ARCADA_INFERIOR"
        ),
      },
    ];

    return (
      <div className={classes.content}>
        {areasDente.map((item) => {
          const { denteSupranumerario } = item;
          return (
            <div className={classes.contentDivisor}>
              <div className={classes.divisorLateralDireito} />
              <div className={classes.labelLateralDireito}> {item.label} </div>
              {denteSupranumerario.length > 0 && (
                <DenteSupranumerario
                  denteSupranumerario={denteSupranumerario}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
);

const styles = {
  content: {
    marginTop: "36px",
    display: "flex",
    gap: "8px",
    flexDirection: "column",
  },
  contentDivisor: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    width: "27px",
    position: "relative",
  },
  divisorLateralDireito: {
    width: 21,
    border: "1px solid #F9BE73",
    borderLeft: "none",
    minWidth: "21px",
    display: "flex",
    marginLeft: "12px",
  },
  labelLateralDireito: {
    transform: "rotate(90deg)",
    fontWeight: 500,
    fontSize: "10px",
    color: "#F5F5F5",
    whiteSpace: "nowrap",
    width: "100%",
    height: "2px",
    alignSelf: "flex-start",
    display: "flex",
    marginTop: "36px",
  },
};

const DivisorAreaDenteSupranumerarioWithStyles = withStyles(styles)(
  DivisorAreaDenteSupranumerario
);
export default inject("odontogramaStore")(
  DivisorAreaDenteSupranumerarioWithStyles
);
