import React from 'react';
import Button from '@material-ui/core/Button';

import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Colors from '../../template/Colors';

const styles = theme => ({
    root: {
        minHeight: 37,
        width: '100px',
        border: 0,
        background: Colors.commons.secondary,
        color: theme.palette.commons.white,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        borderRadius: '100px',
        padding: '0px 16px',
        "& span": {
            fontSize: 14,
            fontWeight: 700
        },
        "&:hover": {
            background: theme.palette.primary.dark,
            color: theme.palette.commons.white,
        },
        "&:disabled": {
            background: theme.palette.commons.gray6,
            color: theme.palette.commons.white,
        }
    },
    noBorderLeft: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    noBorderRight: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    }
});

const ButtonConfirm = (props) => {
    const {classes, noBorderRight, noBorderLeft, ...others} = props;
    let className = classes.root;
    
    if (noBorderLeft) {
        className = classnames(className, classes.noBorderLeft);
    }
    
    if (noBorderRight) {
        className = classnames(className, classes.noBorderRight);
    }
    
    return <Button className={className} {...others}/>;
};


export default withStyles(styles)(ButtonConfirm);