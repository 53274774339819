import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { tabs } from "./Constants";
import Informacoes from "./Informacoes";
import HeaderFinanceiro from "../HeaderFinanceiro";
import CategoriaModal from "./Modal/CategoriaModal";
import ButtonFloat from "../../../components/ButtonFloat";
import Notification from "../../../components/Notification";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Auditoria from "../../../components/Auditoria/Auditoria";
import styles from "../../../assets/jss/pages/categoriaFinanceiraStyle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import CategoriaFilhoModal from "./Modal/CategoriaFilhoModal";
import { findAllCategoriaFinanceiraAuditByUnidade } from "../../../services/AuditoriaService";

const Categoria = observer(
  ({
    categoriaFinanceiraStore,
    classes,
    googleAnalyticsStore,
    auditoriaStore,
  }) => {
    const {
      categoriaFinanceiraList,
      open,
      filters,
      notification,
      modalConfirmacao,
      modalCategoriaFilho,
    } = categoriaFinanceiraStore;

    const [tabSelected, setTabSelected] = useState(tabs.INFORMACOES);

    useEffect(() => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoria: "a categoria financeira",
        auditoriaTela: "categoriaFinanceiraUnidade",
      };
      const paginaVisualizada = {
        page: window.location.hash,
        title: "Extrato - Categorias Financeiras",
      };
      googleAnalyticsStore.pageView(paginaVisualizada);
    }, []);

    const handleSearchChange = (e) => {
      const search = e.target.value;
      categoriaFinanceiraStore.filters.search = search;
    };

    const onClickPesquisar = () => {
      categoriaFinanceiraStore.findAllCategoriaFinanceira();
    };

    const onKeypressSearch = (e) => {
      if (e.key === "Enter" && filters.search.length >= 3) {
        categoriaFinanceiraStore.findAllCategoriaFinanceira(true);
      }
    };

    const handleClickClearPesquisar = () => {
      categoriaFinanceiraStore.filters.search = "";
      categoriaFinanceiraStore.findAllCategoriaFinanceira(true);
    };

    const handleStatus = (status) => {
      categoriaFinanceiraStore.filters = {
        ...filters,
        ativo: status.value,
      };
      categoriaFinanceiraStore.findAllCategoriaFinanceira(true);
    };

    const changeTabSelected = async (tabSelected) => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
      };

      if (tabSelected === tabs.AUDITORIA) {
        auditoriaStore.load = {
          query: findAllCategoriaFinanceiraAuditByUnidade,
          variableName: "",
          variableId: "",
        };
        await auditoriaStore.loadAuditItems();
      }

      setTabSelected(tabSelected);
    };

    const renderTab = () => {
      return tabSelected === tabs.INFORMACOES ? (
        <Informacoes />
      ) : (
        <Auditoria
          entity="categoriaFinanceira"
          entityNameField="nome"
          withEntityName
          modificationMessageWithValue
        />
      );
    };

    return (
      <div className={classes.contentPage}>
        <PageTitle title="Financeiro - Categoria financeira" />
        <HeaderFinanceiro
          onClickButtonSearch={onClickPesquisar}
          handleClickClear={handleClickClearPesquisar}
          value={filters.search}
          onChange={handleSearchChange}
          onKeyPress={onKeypressSearch}
          propsStatus={{
            status: filters.ativo,
            handleStatus: handleStatus,
          }}
          propsButton={{
            label: "Nova categoria",
            handleClickNew: () => categoriaFinanceiraStore.openNew(),
          }}
          withzindex
          title="Categoria financeira"
        />

        <div className={classes.content}>
          <MultiToggleButtons
            options={["Informações", "Auditoria"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
            disabled={categoriaFinanceiraList.length === 0}
            posicaoDesabilitada={[1]}
            classes={{ buttonsContainer: classes.buttonsTab }}
          />
          <div className={classes.contentTab}>{renderTab()}</div>
        </div>

        <ButtonFloat onClick={() => categoriaFinanceiraStore.openNew()} />
        {open && <CategoriaModal open={open} />}
        {modalCategoriaFilho.open && <CategoriaFilhoModal />}
        {modalConfirmacao.open && <ModalConfirmacao {...modalConfirmacao} />}
        <Notification
          close={(e) => categoriaFinanceiraStore.closeNotification(e)}
          reset={() => categoriaFinanceiraStore.resetNotification()}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </div>
    );
  }
);

const stores = [
  "categoriaFinanceiraStore",
  "googleAnalyticsStore",
  "auditoriaStore",
];
const CategoriaWithStyles = withStyles(styles)(Categoria);
export default inject(...stores)(CategoriaWithStyles);
