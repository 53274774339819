import Api from "../config/api";

export const createMultiplo = ({
  lista,
  nomeVariables,
  tipo,
  mutationName,
}) => {
  let query = "";
  let itemTipo = "";
  let variablesComNome = [];
  let listaDeVariables = {};

  lista.forEach((item, index) => {
    itemTipo = itemTipo + `$${nomeVariables}${index}: ${tipo}, `;
    query =
      query +
      `${nomeVariables}${index}: ${mutationName}(${nomeVariables}: $${nomeVariables}${index}){
                id
            }`;
    variablesComNome.push({ nome: `${nomeVariables}${index}`, value: item });
  });
  variablesComNome.forEach((item) => {
    listaDeVariables = { ...listaDeVariables, [item.nome]: item.value };
  });

  return Api.post("", {
    query: `
                mutation (${itemTipo}){
                    ${query}
                }
            `,
    variables: listaDeVariables,
  });
};
