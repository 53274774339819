import React, { lazy, Suspense, useState } from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

import TabSelector from '../../../../../components/TabSelector';
import { tabsOptions } from './Constants';

const ListaSecretarias = lazy(() => import('./ListSecretarias'));
const Auditoria = lazy(() => import('./Auditoria'));

const Hero = ({
  classes,
  handleOpenCreateSecretaria,
  openNotification,
  shouldReload,
  setShouldReload,
  search,
  setShowButton,
  profissionalHavePermission,
  showButton,
  handleSelectProfissionalSaude
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChangeTab = (index) => {
    const indexesToValidate = [0, 2, 3];
    setSelectedTabIndex(index);
    indexesToValidate.includes(index)
      ? profissionalHavePermission()
      : setShowButton(false);
  };

  return (
    <div className={classes.content}>
      <div className={classes.wrapperTabSelector}>
        <TabSelector
          tabsOptions={tabsOptions}
          baseColor='#F2F2F2'
          selectedColor='#FFF'
          onSelect={(index) => handleChangeTab(index)}
          selectedTabIndex={selectedTabIndex}
        />
      </div>
      <Suspense
        fallback={
          <div className={classes.wrapperLoading}>
            <CircularProgress />
          </div>
        }
      >
        {selectedTabIndex !== 4 ? (
          <ListaSecretarias
            handleOpenCreateSecretaria={handleOpenCreateSecretaria}
            handleSelectProfissionalSaude={handleSelectProfissionalSaude}
            openNotification={openNotification}
            shouldReload={shouldReload}
            setShouldReload={setShouldReload}
            search={search}
            selectedTabIndex={selectedTabIndex}
            showButton={showButton}
          />
        ) : (
          <div className={classes.containerAudit}>
            <Auditoria />
          </div>
        )}
      </Suspense>
    </div>
  );
};

const styles = (theme) => ({
  content: {
    width: '100%',
    flexDirection: 'column',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    marginBottom: '1rem',
    height: '100%',
    maxHeight: 'calc(100% - 145px)',

    '& th': {
      paddingRight: 8,
    },

    '& td': {
      paddingRight: 8,
    },
  },
  wrapperTabSelector: {
    padding: '1rem 1rem 0.5rem 1rem',
  },
  wrapperLoading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    minHeight: 'calc(100vh - 400px)',
    marginTop: '3rem',
  },
  containerAudit: {
    height: 'calc(100vh - 282px)',
    '& > div': {
      height: 'calc(100% - 12px)',
      paddingTop: '1rem',
    },
  },
});

export default withStyles(styles)(Hero);
