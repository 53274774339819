export const searchOptionsDefault = {
  search: '',
  status: {
    label: 'Ativo',
    value: true,
  },
  pageNumber: 0,
  pageSize: 20,
  sortDir: 'ASC',
  sortField: 'nome',
};

export const notificationOptionsDefault = {
  isOpen: false,
  message: '',
  variant: ''
};

export const guiaInternacaoModeloDefalt = {
  id: null,
  nome: '',
  indicacaoClinica: null,
  caraterAtendimento: null,
  tipoInternacao: null,
  regimeInternacao: null,
  indicacaoAcidente: null,
  opme: false,
  quimioterapico: false,
}

export const tussTabelaOptions = [
  {
    name: 'Todos',
    value: 'all',
  }, 
  {
    name: '41',
    value: 41,
  },
  {
    name: '57',
    value: 57,
  }
]

export const caraterAtendimentoOptions = [
  {
    name: 'Efetiva',
    value: 'EFETIVA',
  },
  {
    name: 'Urgência/Emergência',
    value: 'URGÊNCIA',
  }
];

export const tipoInternacaoOptions = [
  {
    name: 'Clínica',
    value: 'CLÍNICA',
  },
  {
    name: 'Cirúrgica',
    value: 'CIRÚRGICA',
  },
  {
    name: 'Obstétrica',
    value: 'OBSTÉTRICA',
  },
  {
    name: 'Pediátrica',
    value: 'PEDIÁTRICA',
  },
  {
    name: 'Psiquiátrica',
    value: 'PSIQUIÁTRICA',
  }
];

export const regimeInternacaoOptions = [
  {
    name: 'Hospitalar',
    value: 'HOSPITALAR',
  },
  {
    name: 'Hospital-dia',
    value: 'HOSPITAL-DIA',
  },
  {
    name: 'Domiciliar',
    value: 'DOMICILIAR',
  }
];

export const indicacaoAcidenteOptions = [
  {
    name: 'Acidente ou doença relacionada ao trabalho',
    value: 'ACIDENTE OU DOENÇA RELACIONADA AO TRABALHO',
  },
  {
    name: 'Trânsito',
    value: 'TRÂNSITO',
  },
  {
    name: 'Outros',
    value: 'OUTROS',
  }
];

export const guiaInternacaoValidations = (isModelo) => [
  (isModelo && {
    field: 'nome',
    isRequired: true,
  }),
  {
    field: 'indicacaoClinica',
    isRequired: true,
  },
  {
    field: 'caraterAtendimento',
    isRequired: true,
  },
  {
    field: 'tipoInternacao',
    isRequired: true,
  },
  {
    field: 'regimeInternacao',
    isRequired: true,
  },
  {
    field: 'indicacaoAcidente',
    isRequired: true,
  },
];

export const modalSugestionsTabs = [
  {
    label: 'Sugestões',
  },
  {
    label: 'Modelos criados',
  }
]
