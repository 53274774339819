import React from "react";
import { IconButton, InputAdornment, withStyles } from "@material-ui/core";
import { DateTimePicker } from "material-ui-pickers";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@material-ui/icons";
import Colors from "../template/Colors";
import CalendarIcon from "./Icon/Calendar";

const CustomDateTimePicker = props => {
  const { classes, iconPosition } = props;

  return (
    <DateTimePicker
      {...props}
      ampm={false}
      allowKeyboardControl={false}
      leftArrowIcon={<KeyboardArrowLeftIcon />}
      leftarrowbuttonprops={{ "aria-label": "Prev month" }}
      rightarrowbuttonprops={{ "aria-label": "Next month" }}
      rightArrowIcon={<KeyboardArrowRightIcon />}
      InputProps={{
        ...(iconPosition === "start" ? {
          startAdornment: (
            <InputAdornment position="start">
              <IconButton style={{padding: 0}}>
                <CalendarIcon color='black'/>
              </IconButton>
            </InputAdornment>
          ),
        } : {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton classes={{ root: classes.iconButton }}  >
                <CalendarIcon color='black'/>
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
      classes={{ root: classes.dateTime }}
      format="DD/MM/YYYY HH:mm"
    />
  );
};

const style = {
  dateTime: {
    height: '32px',
    background: '#F2F2F2',
    borderRadius: '100px',
    padding: '0 8px',
    "& input": {
      fontFamily: "Poppins",
      color: Colors.commons.gray9,
      fontSize: "14px",
      textAlign: 'center',
      paddingTop: '8px',
    },
    "& >div": {
      "&::before": {
        borderBottom: 'none', 
        borderBottomStyle: 'none !important'
      },
      "&::after": {
        borderBottom: 'none',
      },
      "&::disabled":{
        borderBottom: 'none',
      }
    },
    "&::hover": {
      borderBottom: 'none',
    }
  },
  iconButton: {
    padding: 3,
    color: Colors.commons.gray7,
    "& svg": {
      width: 18,
      height: 18
    },
  },

};

export default withStyles(style)(CustomDateTimePicker);
