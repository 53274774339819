import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import styles from "./styles";
import { findAllTituloAuditByTituloId } from "../../../services/AuditoriaService";
import TabProcedimentos from "./TabProcedimentos";
import InformacoesLancamento from "./InformacoesLancamento";
import Auditoria from "../../../components/Auditoria/Auditoria";
import ButtonLong from "../../../components/Button/ButtonLong";
import { TituloTipoRepeticao } from "../../../stores/Financeiro/Extrato.store";
import TabSelector from "../../../components/TabSelector";

const ContentModalLancamento = observer((props) => {
  const {
    classes,
    extratoStore,
    auditoriaStore,
    setTabSelected,
    tabSelected,
    setProcedimentos,
    procedimentos,
  } = props;
  const { listTab, titulo, loadingButtonActionModal } = extratoStore;

  useEffect(() => {
    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      auditoria: "o título",
      auditoriaTela: "lancamento",
    }

  }, []);

  const changeTabSelected = async (tabSelected) => {
    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    };

    const isTabAuditoria = (titulo.tipo === "RECEITA" && tabSelected === 2) ||
      (titulo.tipo === "DESPESA" && tabSelected === 1);

    if (isTabAuditoria) {
      auditoriaStore.load = {
        query: findAllTituloAuditByTituloId,
        variableName: "tituloId",
        variableId: titulo.titulo.id,
      };
      await auditoriaStore.loadAuditItems();
    }

    setTabSelected(tabSelected);
  };

  const renderTab = () => {
    if (titulo.tipo === "RECEITA") {
      switch (tabSelected) {
        case 0:
        default:
          return (
            <InformacoesLancamento
              titulo={titulo}
              isDisabled={!extratoStore.editMode}
            />
          );
        case 1:
          return (
            <TabProcedimentos
              titulo={titulo}
              procedimentos={procedimentos}
              setProcedimentos={setProcedimentos}
              isDisabled={extratoStore.editMode && !!titulo.dataPagamento}
            />
          );
        case 2:
          return <Auditoria />;
      }
    } else {
      if (tabSelected === 0) {
        return (
          <InformacoesLancamento
            titulo={titulo}
            isDisabled={!!titulo.dataPagamento}
          />
        );
      } else {
        return <Auditoria />;
      }
    }
  };

  const getProcedimentosOdontologico = () => {
    const procedimentos = titulo.procedimentos.map(( procedimento ) => {
      return {
        id: procedimento.id
      }
    })

    return procedimentos;
  };

  const handleClickSave = async () => {
    try {
      extratoStore.loadingButtonActionModal = true;
      const { procedimentoControleSessao, orcamento } = titulo || {};
      const {procedimento, agendamentoTipo, totalQuantity, controleSessaoId} = procedimentoControleSessao || {};

      const procedimentos = orcamento?.id ? getProcedimentosOdontologico() : titulo.procedimentos;

      const newProcedimentoControleSessao = !!procedimento?.id && {
        id: procedimento?.id,
        nome: procedimento?.nome, 
        totalQuantity,
      };
      const newAgendamentoTipo = !!agendamentoTipo?.id && {
        id: agendamentoTipo?.id,
        descricao: agendamentoTipo?.descricao,
        totalQuantity
      };
      const newTitulo = {
        ...titulo,
        procedimentos: titulo.procedimentoControleSessao && procedimento?.id ? [...titulo.procedimentos, newProcedimentoControleSessao] : procedimentos,
        ...(newAgendamentoTipo && { agendamentoTipo: newAgendamentoTipo }),
        ...(controleSessaoId && { controleSessaoId: controleSessaoId  }),
        ...(procedimentoControleSessao?.sessao?.id && { sessao: procedimentoControleSessao?.sessao  })
      };
      if (titulo?.id) {
        await extratoStore.update(newTitulo);
      } else {
        const dataPagamentoEntrada = titulo.dataPagamentoEntrada;
        delete titulo.dataPagamentoEntrada;

        const tituloCriado = await extratoStore.create(newTitulo);

        if (tituloCriado.tipoRepeticao === TituloTipoRepeticao.PARCELAR && dataPagamentoEntrada) {
          const parcelaEntrada = tituloCriado.parcelas.find(item => item.numeroParcela === 0);
          delete parcelaEntrada.numeroParcela;
          await extratoStore.updateTituloParcela({
            ...parcelaEntrada,
            id: tituloCriado.parcelas[0].id,
            dataPagamento: dataPagamentoEntrada
          });
        }
      }

      if (!titulo.valor || !titulo.nome || !titulo.dataVencimento) {
        console.error(error && error.message);
        return;
      }

      await extratoStore.closeModal();
      extratoStore.tituloList = [];
      extratoStore.currentPage = null;
      extratoStore.searchDTO.pageNumber = 0;
      extratoStore.findAll();
    } catch (error) {
      console.error(error && error.message);
    } finally {
      extratoStore.loadingButtonActionModal = false;
    }
  };

  return (
    <div className={classes.contentModal}>
      <TabSelector
            tabsOptions={listTab}
            baseColor='#F2F2F2'
            selectedColor='#FFF'
            onSelect={changeTabSelected}
            selectedTabIndex={tabSelected}
          />
      <div
        className={classNames(
          classes.contentTab,
          listTab[tabSelected] === "Auditoria" && classes.contentTabAuditorias
        )}
      >
        {renderTab()}
      </div>
      <ButtonLong
        colorCustom="green"
        disabled={loadingButtonActionModal}
        onClick={handleClickSave}
        noShadow
        className={classNames(classes.buttonHeader, classes.buttonSave)}
      >
        Salvar
      </ButtonLong>
    </div>
  );
});

const ContentModalLancamentoWithStyle = withStyles(styles)(
  ContentModalLancamento
);
export default inject(
  "extratoStore",
  "auditoriaStore"
)(ContentModalLancamentoWithStyle);
