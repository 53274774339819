import { inject } from "mobx-react";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, withStyles } from "@material-ui/core";
import { COLUMNS } from "../Constatnts";
import Table from "../../../../../../../components/Table/Table";
import MensagemListaVazia from "../../../../../../../components/Mensagem/MensagemListaVazia";
import string from "../../../../../../../utils/string";
import { applyCurrencyMask } from "../../../../../../../utils/CurrencyMask";
import classNames from "classnames";

const ProcedimentosOdontograma = observer(
  ({ classes, orcamentoStore, isLoadingProcedimentos, isDisabled }) => {
    const { orcamento, errors } = orcamentoStore;
    const { orcamentoProcedimentos, tipoPagamento, convenio } = orcamento;

    useEffect(() => {
      somarValores();
    }, [orcamentoProcedimentos]);

    useEffect(() => {
      alteraValorProcedimentoPorConvenio();
    }, [convenio]);

    const alteraValorProcedimentoPorConvenio = () => {
      const valoresProcedimentoPorConvenio = orcamentoProcedimentos.map(
        (item) => {
          const valorUnitarioConvenio = orcamento?.id
            ? item.valorUnitario
            : item.procedimento?.convenioProcedimentos?.find(
                (convenioProcedimento) =>
                  convenioProcedimento?.convenio?.id === convenio?.value
              )?.valor || 0;

          const valorUnitario = item.checked ? valorUnitarioConvenio : "";
          return { ...item, valorUnitario };
        }
      );

      orcamentoStore.orcamento.orcamentoProcedimentos = valoresProcedimentoPorConvenio;
    };

    const allCheckBoxChecked = () => {
      const allChecked = orcamentoProcedimentos && orcamentoProcedimentos.every((item) => item.checked);
      return allChecked;
    };

    const handleCheckAll = () => {
      const checked = allCheckBoxChecked();
      const procedimentosSelecionados = orcamentoProcedimentos.map((item) => {
        let convenioValor = "";
        if (!checked) {
          convenioValor = item.procedimento.convenioProcedimentos.find(
            (convenioProcedimento) => {
              return convenioProcedimento?.convenio?.id === convenio?.value;
            }
          );
        }

        return {
          ...item,
          valorUnitario: convenioValor?.valor || "",
          checked: !checked,
        };
      });

      orcamentoStore.orcamento.orcamentoProcedimentos = procedimentosSelecionados;
    };

    const handleChangeCheck = (row) => {
      const procedimentosCheckedAlterado = orcamentoProcedimentos.map(
        (item) => {
          let convenioValor = "";
          if (!item.checked) {
            convenioValor = item.procedimento.convenioProcedimentos.find(
              (convenioProcedimento) => {
                return convenioProcedimento?.convenio?.id === convenio?.value;
              }
            );
          }

          return item.id === row.id
            ? {
                ...item,
                valorUnitario: convenioValor?.valor
                  ? applyCurrencyMask(String(convenioValor?.valor))
                  : "",
                checked: !item.checked,
              }
            : item;
        }
      );

      orcamentoStore.orcamento.orcamentoProcedimentos = procedimentosCheckedAlterado;
    };

    const handleValor = (value, row) => {
      const procedimentos = orcamentoProcedimentos;

      const index = procedimentos.findIndex((item) => item.id === row.id);
      procedimentos[index] = {
        ...procedimentos[index],
        valorUnitario: value,
      };

      somarValores();
    };

    const somarValores = () => {
      const soma = orcamentoProcedimentos
        .filter((item) => item.checked)
        .reduce((accumulator, currentValue) => {
          const valor =
            typeof currentValue.valorUnitario === "string"
              ? string.currencyMaskToFloat(currentValue.valorUnitario)
              : currentValue?.valorUnitario;

          return accumulator + valor;
        }, 0);

      orcamentoStore.orcamento.valor = soma;
    };

    return (
      <div
        className={classNames(
          classes.content,
          errors.procedimentos && classes.contentError
        )}
      >
        <Table
          dados={orcamentoProcedimentos || []}
          columns={COLUMNS({
            handleCheckAll,
            allCheckBoxChecked,
            handleChangeCheck,
            handleValor,
            isDisabled,
            errors,
          })}
          classes={{
            tabela: classNames(
              classes.table,
              tipoPagamento.value === "A_PRAZO" && classes.tableMenor
            ),
          }}
          emptyMessage={
            <>
              {isLoadingProcedimentos ? (
                <div className={classes.wrapperLoading}>
                  <CircularProgress />
                </div>
              ) : (
                <MensagemListaVazia />
              )}
            </>
          }
        />
        <div className={classes.containerTotal}>
          <div className={classes.contentTotal}>
            <label className={classes.total}> Subtotal: </label>
            <label className={classes.totalValue}>
              {applyCurrencyMask(orcamento.valor)}
            </label>
          </div>
          <div className={classes.contentTotal}>
            <label className={classes.total}> Total: </label>
            <label className={classes.totalValue}>
              {typeof orcamento.subtotal === "string"
                ? orcamento.subtotal
                : applyCurrencyMask(orcamento.subtotal)}
            </label>
          </div>
        </div>
      </div>
    );
  }
);

const styles = {
  content: {
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    height: "100%",

    "& thead": {
      "& tr": {
        height: "40px",
        borderRadius: "10px 0 0 10px",
      },

      "& th": {
        textAlign: "left",
        top: 0,
        position: "sticky",
        padding: "0 0 0 10px",

        "&:first-child": {
          width: "29px",
          borderRadius: "10px 0 0 0",
        },

        "&:last-child": {
          borderRadius: "0 10px 0 0",
          width: "100px",
        },
      },
    },

    "& tbody": {
      "& tr": {
        "& td": {
          padding: "0 0 0 10px",
          border: "none",
        },
      },
    },
    "& #inputValor": {
      background: "#F2F2F2",
      borderRadius: "8px",
      height: "30px",
      paddingLeft: "8px",
    },
  },
  contentError: {
    border: "1px solid #FB7676",
  },
  wrapperLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    height: "100%",
    overflow: "auto",
    maxHeight: "217px",
  },
  tableMenor: {
    maxHeight: "115px",
  },
  containerTotal: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
  },
  contentTotal: {
    height: "40px",
    paddingRight: "8px",
    textAlign: "end",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  total: {
    fontWeight: 600,
    fontSize: 16,
  },
  totalValue: {
    fontWeight: 600,
    fontSize: 18,
  },
};

const ProcedimentosOdontogramaWithStyles = withStyles(styles)(
  ProcedimentosOdontograma
);
export default inject("orcamentoStore")(ProcedimentosOdontogramaWithStyles);
