import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/pages/contaBancariaStyle";
import { tabs } from "./Constants";
import Informacoes from "./Informacoes";
import HeaderFinanceiro from "../HeaderFinanceiro";
import ContaBancariaModal from "./ContaBancariaModal";
import Notification from "../../../components/Notification";
import Auditoria from "../../../components/Auditoria/Auditoria";
import PageTitle from "../../../components/PageTitle/PageTitle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { defaultFilters } from "../../../stores/Financeiro/CategoriaFinanceira.store";
import { findAllContaBancariaAuditByUnidade } from "../../../services/AuditoriaService";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";

const ContaBancaria = observer(
  ({ classes, contaBancariaStore, googleAnalyticsStore, auditoriaStore }) => {
    const {
      filters,
      open,
      modalConfirmacao,
      notification,
    } = contaBancariaStore;
    const [isDisabled, setIsDisabled] = useState(true);
    const [tabSelected, setTabSelected] = useState(tabs.INFORMACOES);

    useEffect(() => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoria: "a conta bancária",
        auditoriaTela: "contaBancariaUnidade",
      };

      contaBancariaStore.filters = defaultFilters;
      contaBancariaStore.findAllContaBancaria(true);
      const paginaVisualizada = {
        page: window.location.hash,
        title: "Extrato - Contas Bancárias",
      };
      googleAnalyticsStore.pageView(paginaVisualizada);
    }, []);

    const handleSearchChange = (e) => {
      const search = e.target.value;
      contaBancariaStore.filters.search = search;
    };

    const onClickPesquisar = () => {
      contaBancariaStore.findAllContaBancaria(true);
    };

    const onKeypressSearch = (e) => {
      if (e.key === "Enter" && filters.search.length >= 3) {
        contaBancariaStore.findAllContaBancaria(true);
      }
    };

    const handleClickClearPesquisar = () => {
      contaBancariaStore.filters.search = "";
      contaBancariaStore.findAllContaBancaria(true);
    };

    const disableSaldoInicial = (disable) => {
      setIsDisabled(disable);
    };

    const handleStatus = (status) => {
      contaBancariaStore.filters = {
        ...filters,
        ativo: status.value,
      };
      contaBancariaStore.findAllContaBancaria(true);
    };

    const changeTabSelected = async (tabSelected) => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
      };

      if (tabSelected === 1) {
        auditoriaStore.load = {
          query: findAllContaBancariaAuditByUnidade,
          variableName: "",
          variableId: "",
        };
        await auditoriaStore.loadAuditItems();
      }

      setTabSelected(tabSelected);
    };

    const renderTab = () => {
      return tabSelected === tabs.INFORMACOES ? (
        <Informacoes disableSaldoInicial={disableSaldoInicial} />
      ) : (
        <Auditoria
          entity="contaBancaria"
          entityNameField="nome"
          withEntityName
          modificationMessageWithValue
        />
      );
    };

    const handleClickClear = () => {
      disableSaldoInicial(false);
      contaBancariaStore.openNew();
    };

    return (
      <div className={classes.content}>
        <PageTitle title="Financeiro - Contas bancárias" />
        <HeaderFinanceiro
          onClickButtonSearch={onClickPesquisar}
          handleClickClear={handleClickClearPesquisar}
          value={filters.search}
          propsStatus={{
            status: filters.ativo,
            handleStatus: handleStatus,
          }}
          propsButton={{
            label: "Nova conta",
            handleClickNew: () => handleClickClear(),
          }}
          onChange={handleSearchChange}
          onKeyPress={onKeypressSearch}
          withzindex
          title="Conta bancária"
        />

        <div className={classes.contentList}>
          <MultiToggleButtons
            options={["Informações", "Auditoria"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
            classes={{ buttonsContainer: classes.buttonsContainer }}
          />
          {renderTab()}
        </div>
        {open && (
          <ContaBancariaModal
            disableSaldoInicial={disableSaldoInicial}
            isDisabled={isDisabled}
            open={open}
          />
        )}
        {modalConfirmacao.open && <ModalConfirmacao {...modalConfirmacao} />}
        <Notification
          close={(e) => contaBancariaStore.closeNotification(e)}
          reset={() => contaBancariaStore.resetNotification()}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </div>
    );
  }
);

const ContaBancariaWithStyles = withStyles(styles)(ContaBancaria);
export default inject(
  "contaBancariaStore",
  "googleAnalyticsStore",
  "auditoriaStore"
)(ContaBancariaWithStyles);
