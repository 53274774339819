import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { Suspense, lazy, useEffect } from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';
import Dialog from '../../../components/Dialog/Dialog';
import { Button } from '../../../components/ui/Buttons';
import CloseIcon from '../../../components/Icon/CloseIcon';

const Auditoria = lazy(() => import('../../../components/Auditoria/Auditoria'));

const ModalAuditoria = observer(({
    auditoriaStore,
    atendimentoStore,
    classes,
    open,
    onClose,
    agendamentoId,
    nomePaciente,
}) => {

    useEffect(() => {
        auditoriaStore.auditoriaProps = {
            ...auditoriaStore.auditoriaProps,
            auditoria: "o agendamento",
            auditoriaTela: "agendamento",
            pageNumber: 0,
            lastPage: false,
            auditorias: [],
        };

        auditoriaStore.load = {
            query: atendimentoStore.findaAgendamentoAuditById,
            variableName: "agendamentoId",
            variableId: agendamentoId,
        };

        loadAuditoria();
    }, []);
    
    const loadAuditoria = async() => {
        await auditoriaStore.loadAuditItems();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth={true}
            classes={{ paper: classes.paper }}
        >
            <div className={classes.header}>
                <h1 className={classes.title}> {nomePaciente} - Auditoria </h1>
                <Button
                    onClick={onClose}
                    shape="circle"
                    bgColor="#FB7676"
                >
                    <CloseIcon />
                </Button>
            </div>
            <Suspense fallback={<CircularProgress size={32} />}>
                <Auditoria
                    modificationMessageWithValue
                />
            </Suspense>
        </Dialog>
    )
});

const style = {
    paper: {
        height: '424px',
        width: '429px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 24px 0 24px'

    },
    title: {
        fontSize: '18px',
    },
};

const ModalAuditoriaWithStyles = withStyles(style)(ModalAuditoria);
export default inject("auditoriaStore", "atendimentoStore")(ModalAuditoriaWithStyles);