import moment from 'moment'

export const formatAge = sujeitoAtencao => {
  if (!sujeitoAtencao?.dataNascimento) return ''
  return calculateAge(sujeitoAtencao.dataNascimento)
}

export const calculateAge = dateOfBirth => {
  if (!dateOfBirth) {
    return null
  }
  const dob = moment(dateOfBirth)
  const ageInYears = moment().diff(dob, 'years')

  if (ageInYears >= 18) {
    return `${ageInYears} anos`
  }

  const ageParts = [
    { label: 'ano(s)', value: 'years'},
    { label: 'mês(es)', value: 'months' },
    { label: 'dia(s)', value: 'days' },
  ]
  const ageIntervals = ageParts.map(part => {
    const diff = moment().diff(dob, part.value)
    dob.add(diff, part.value)
    return `${diff} ${part.label}`
  })
  return ageIntervals.join(', ')
}
