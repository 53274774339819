export const informacoesPrincipaisConfigOptions = [
  {
    label: 'Atendimento Presencial',
    attribute: 'atendePresencial',
    storedIn: 'profissionalSaudeStore',
    condition: ''
  },
  {
    label: 'Atendimento por teleconsulta',
    attribute: 'utilizaTelemedicina',
    storedIn: 'profissionalSaudeStore',
    condition: '',
    informacao: 'Ativando essa funcionalidade será ativado automaticamente para seu paciente o meio de pagamento online via cartão de crédito.'
  },
  {
    label: 'Atende crianças',
    attribute: 'atendeCrianca',
    storedIn: 'profissionalSaudeStore',
    condition: ''
  },
  {
    label: 'Utilizar secretária virtual',
    attribute: 'utilizaSecretariaVirtual',
    storedIn: 'profissionalSaudeStore',
    condition: ''
  },
  {
    label: 'Utilizar notificação de agendamento online',
    attribute: 'enviaEmailConfirmacaoAgendamento',
    storedIn: 'profissionalSaudeStore',
    condition: ''
  },
  {
    label: 'Utilizar assinatura digital',
    attribute: 'utilizaAssinaturaDigital',
    storedIn: 'profissionalSaudeStore',
    condition: ''
  },
  {
    label: 'Compartilhar prontuário',
    attribute: 'compartilharProntuario',
    storedIn: 'profissionalSaudeStore',
  },
  {
    label: 'Visualizar prontuario de outros profissionais.',
    attribute: 'visualizaOutrosProntuarios',
    storedIn: 'profissionalSaudeStore',
  },
  {
    label: 'Aceitar agendamento de retorno. Defina a quantidade de dias:',
    attribute: 'utilizaAgendamentoRetorno',
    storedIn: 'profissionalSaudeStore',
    withInput: true,
    attributeInput: 'retornoDias',
  },
  {
    label: 'Visualizar registros inativados no prontuário.',
    attribute: 'visualizarRegistroInativo',
    storedIn: 'profissionalSaudeStore',
  },
];