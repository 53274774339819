import React from "react";
import {IconButton} from '@material-ui/core'
import {  
  ExpandMore as ExpandMoreIcon,
  ExpandLess as  ExpandLessIcon
} from "@material-ui/icons";
import {withStyles} from "@material-ui/core";
import {inject} from 'mobx-react';

const CalendarioMinimizadoDia = (props) =>{
  const { classes, dataAnterior, dataPosterior, onClickCurrentDayButton, atendimentoStore } = props;

  return(
    <div className={classes.divDataAtualMinimizado}>
      <div onClick={dataAnterior}>
          <ExpandLessIcon className={classes.botoesMudarDia}/>
      </div>
      <div className={classes.dataAtual}> {atendimentoStore.selectedDate.format("DD/MM")} </div>
      <div onClick={dataPosterior}>
          <ExpandMoreIcon className={classes.botoesMudarDia}/>
      </div>
      <IconButton
          onClick={onClickCurrentDayButton}
          className={classes.botaoDataAtual}
      >
          Hoje
      </IconButton>
    </div>
  )
}

const styles = theme => ({
  divDataAtualMinimizado:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: 15,
  },
  botoesMudarDia:{
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)', 
    marginRight: '-5px'
  },
  dataAtual:{
    color: '#009A97',
    cursor: 'pointer',
    padding: '0px 0px 3px 0px',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  botaoDataAtual:{
    borderRadius: 0,
    padding: 7,
    fontSize: 14,
    color: '#27B0AD',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: 10,
  },
  
})

const CalendarioMinimizadoDiaWithStyles = withStyles(styles)(CalendarioMinimizadoDia);
export default inject("atendimentoStore")(CalendarioMinimizadoDiaWithStyles);