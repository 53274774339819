import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import classnames from "classnames";
import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { Print as PrintIcon } from "@material-ui/icons";
import moment from "moment";
import { observer } from "mobx-react-lite";
import styles from "../RelatorioStyle";
import { InputDateForm } from "../../../components/Modal/Input";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import HeaderRelatorio, { periodos } from "../HeaderRelatorio";
import { TextFieldSearch } from "../../../components/TextField";
import {
  findAllMunicipio,
  findAllSujeitoAtencaoAniversariante,
} from "../../../services/RelatorioService";
import SelectCustomIcon from "../../Configuracoes/ModelosDocumento/Anamnese/SelectCustomIcon";
import { StatusAtivoIcon, StatusInativoIcon } from "../../../assets/img/svg";
import { Dates } from "../../../utils";
import Notification from "../../../components/Notification";
import ButtonYellow from "../../../components/Button/ButtonYellow";
import RelatorioAniversariantesDocument from "../../../template/pdf/relatorio/Aniversariantes";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import cidadeFormatada from "../../../utils/cidadeFormatada";
import { formattedPhone } from "../../../components/Input/TelefoneFormatado";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ButtonWhatsApp from "../../../components/Button/ButtonWhatsApp";
import { goToWhatsApp } from "../../../utils/goToWhatsApp";
import { Button } from "../../../components/ui/Buttons";

const columns = ({ utilizaTelefoneInternacional }) => [
  {
    Header: "",
    getValue: (aniversariante) => {
      return (
        <div className={classnames("status", String(aniversariante.ativo))} />
      );
    },
  },
  {
    Header: "Paciente",
    field: "nome",
    getValue: (aniversariante) => {
      return aniversariante.nome;
    },
  },
  {
    Header: "Telefone",
    getValue: (aniversariante) => {
      const {telefonePrincipal, telefoneSecundario, telefonePrincipalDDI, telefoneSecundarioDDI} = aniversariante?.contato;
      const telefone = telefonePrincipal || telefoneSecundario;
      const telefoneDDI = telefonePrincipal ? telefonePrincipalDDI : telefoneSecundarioDDI;

      const styleTelefone = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      };

      return telefone && <div style={styleTelefone}>
        { formattedPhone({ telefone, telefoneDDI, utilizaTelefoneInternacional }) }
        <ButtonWhatsApp onClick={() => goToWhatsApp(telefone, telefoneDDI)} />
      </div>
    },
  },
  {
    Header: "E-mail",
    getValue: (aniversariante) => {
      return aniversariante?.contato?.email || " - ";
    },
  },
  {
    Header: "Cidade",
    field: "endereco.municipio.nome",
    getValue: (aniversariante) => {
      return cidadeFormatada({
        municipio: aniversariante.endereco?.municipio?.nome, 
        uf: aniversariante.endereco?.municipio?.uf, 
        espanhaMunicipio: aniversariante.espanhaMunicipio?.descricao
      })
    },
  },
  {
    Header: "Idade",
    getValue: (aniversariante) => {
      return Dates.calculaIdade(aniversariante.dataNascimento);
    },
  },
  {
    Header: "Aniversário",
    field: "dataNascimento",
    getValue: (aniversariante) => {
      return aniversariante?.dataNascimento
        ? moment(aniversariante?.dataNascimento).format("DD/MM/YYYY")
        : " - ";
    },
  },
];

const statusList = [
  { label: "Ativo", value: true, icon: StatusAtivoIcon },
  { label: "Inativo", value: false, icon: StatusInativoIcon },
];

const diaInicialMesAtual = moment()
  .startOf("month")
  .subtract(0, "month")
  .format("YYYY-MM-DD");

const diaFinalMesAtual = moment()
  .endOf("month")
  .subtract(0, "month")
  .format("YYYY-MM-DD");

const RelatorioAniversariantes = observer((props) => {
  const { classes, relatorioStore } = props;
  const  { utilizaTelefoneInternacional } = relatorioStore.configuracaoUnidade || {};

  const [aniversariantesLoading, setAniversariantesLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [aniversariantes, setAniversariantes] = useState([]);
  const [dataInicial, setDataInicial] = useState(diaInicialMesAtual);
  const [dataFinal, setDataFinal] = useState(diaFinalMesAtual);
  const [searchNome, setsearchNome] = useState("");
  const [periodoSelecionado, setPeriodoSelecionado] = useState(periodos[2]);
  const [cidade, setCidade] = useState(null);
  const [status, setStatus] = useState({
    label: "Ativo",
    value: true,
    icon: StatusAtivoIcon,
  });
  const [ordenarTabela, setOrdenarTabela] = useState({
    sortField: "dataNascimento",
    sortDir: "ASC",
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const [listaTotalAniversariantes, setListaTotalAniversariantes] = useState([]);
  const [metaData, setMetaData] = useState({
    last: false,
    totalElements: 0,
  })
  const [isPrintMustache, setIsPrintMustache] = useState(false);

  useEffect(() => {
    loadAniversariantes({ isClearable: true });
  }, [ordenarTabela]);

  const loadAniversariantes = async(options) => {
    try {
      setAniversariantesLoading(true);
      const response = await findAllSujeitoAtencaoAniversariante({
        ativo: status.value,
        search: options?.clearSearch ? "" : searchNome,
        dataInicio: dataInicial,
        dataFinal: dataFinal,
        municipioId: cidade?.id,
        pageable: {
          pageSize: 30,
          pageNumber: options?.isClearable ? 0 : pageNumber,
          sortDir: ordenarTabela.sortDir,
          sortField: ordenarTabela.sortField,
        },
      });
      const { content, last, totalElements } = response;

      const listaAniversariantes = options?.isClearable ? [...content] : [...aniversariantes, ...response.content];
      const responseMetaData = {
        last: last,
        totalElements: totalElements
      }
      setMetaData(responseMetaData)
      setAniversariantes(listaAniversariantes);
      setPageNumber(options?.isClearable ? 1 : pageNumber + 1);

    } catch (error) {
      const notification = {
        isOpen: true,
        message: "Erro ao carregar a lista de aniversariantes",
      };
      showAlertMessage(notification);
    } finally {
      setAniversariantesLoading(false);
    }
  };

  const handlePeriodoSelecionado = (value) => {
    const { dataInicial, dataFinal } = value;
    setPeriodoSelecionado(value);
    setDataInicial(dataInicial);
    setDataFinal(dataFinal);
  };

  const handleDateChange = (e, field) => {
    setPeriodoSelecionado('');
    const dataFormatada = e && moment(e).format("YYYY-MM-DD");
    field === "inicio" ? alteraDataInicio(dataFormatada) : setDataFinal(dataFormatada);
  };

  const alteraDataInicio = (dataFormatada) => {
    setDataInicial(dataFormatada);
    dataFormatada > dataFinal && setDataFinal(dataFormatada);
  };

  const onClickPesquisar = () => {
    loadAniversariantes({ isClearable: true });
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter" && searchNome.length >= 3) {
      loadAniversariantes({ isClearable: true });
    }
  };

  const handleClickClearPesquisar = (e) => {
    setsearchNome("");
    loadAniversariantes({ clearSearch: true, isClearable: true });
  };

  const handleSearchChange = (e) => {
    setsearchNome(e.target.value);
  };

  const loadMore = () => {
    if (aniversariantesLoading) {
      return;
    }
    setPageNumber(aniversariantes.length > 0 ? pageNumber + 1 : 0);
  };

  const handleMunicipioLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
    });
  };

  const handleMunicipioChange = (e) => {
    setCidade(e);
  };

  const handleLoadMoreOptions = async ({ search, data }) => {
    let searchDTO = {};

    if (data.searchDTO) {
      searchDTO = {
        ...data.searchDTO,
      };
    }
    const response = await findAllMunicipio({
      pageNumber: data.page,
      search,
      ...searchDTO,
    });

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const onStatusChange = (e) => {
    setStatus(e);
  };

  const handleClickOrdenar = (value) => {
    const sortDir =
      ordenarTabela.sortField !== value
        ? "ASC"
        : ordenarTabela.sortDir === "ASC"
        ? "DESC"
        : "ASC";

    setOrdenarTabela({
      sortDir: sortDir,
      sortField: value,
    });
  };

  const dataLimite = () => {
    const limiteAno = moment(dataInicial).endOf("year");
    return limiteAno;
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };

  const printRelatorio = () => {
    if (dataInicial && dataFinal) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)
      if (isValid) {
        findAllSujeitoAtencaoAniversariante({
          ativo: status.value,
          search: searchNome,
          dataInicio: dataInicial,
          dataFinal: dataFinal,
          municipioId: cidade?.id,
          pageable: {
            pageSize: metaData.totalElements,
            pageNumber: 0,
            sortDir: ordenarTabela.sortDir,
            sortField: ordenarTabela.sortField,
          },
        })
          .then((response) => {
            setListaTotalAniversariantes(response.content);
            setIsPrintMustache(true);
          })
          .catch((error) => {
            const notification = {
              isOpen: true,
              message: "Erro ao carregar a lista de aniversariantes para impressão",
            };
            showAlertMessage(notification);
          })

      } else {
        const notification = {
          isOpen: true,
          message: "O limite de trinta dias foi excedido!",
        };
        showAlertMessage(notification);
        return;
      }
    }
  };

  const handleClickSearch = () => {
    if (dataInicial && dataFinal) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)
      if (isValid) {
        loadAniversariantes({ isClearable: true });
      } else {
        const notification = {
          isOpen: true,
          message: "O limite de trinta dias foi excedido!",
        };
        showAlertMessage(notification);
      }
    }
  }

  const disabledButtonFilter = !dataInicial || !dataFinal || aniversariantesLoading
  return (
    <div className={classes.content}>
      <PageTitle title="Relatórios - Aniversariantes"/>
      <HeaderRelatorio
        handlePeriodo={handlePeriodoSelecionado}
        search={onClickPesquisar}
        clearSearch={handleClickClearPesquisar}
        value={searchNome}
        title='Aniversariantes'
        totalTitle='cadastros'
        onChange={handleSearchChange}
        onKeyPress={onKeypressSearch}
        periodoSelecionado={periodoSelecionado}
        totalAgendamentos={metaData.totalElements}
        hiddenFilter
      />
      <div className={classes.contentFiltros}>
        <div className={classes.filtros}>
          <Grid item xs={3} className={classes.spacing}>
            <span className={classes.tituloFiltros}> Data início: </span>
            <InputDateForm
              iconposition="end"
              openTo="day"
              views={["year", "month"]}
              value={dataInicial}
              classes={{
                input: classes.inputData,
              }}
              onChange={(e) => handleDateChange(e, "inicio")}
            />
          </Grid>
          <Grid item xs={3} className={classes.spacing}>
            <span className={classes.tituloFiltros}> Data fim: </span>
            <InputDateForm
              iconposition="end"
              openTo="day"
              views={["year", "month"]}
              value={dataFinal}
              classes={{
                input: classes.inputData,
              }}
              disabled={!dataInicial}
              maxDate={dataLimite()}
              minDate={dataInicial}
              onChange={(e) => handleDateChange(e, "fim")}
            />
          </Grid>
          <Grid item xs={3} className={classes.spacing}>
            <span className={classes.tituloFiltros}> Cidade: </span>
            <TextFieldSearch
              placeholder="Selecione"
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              loadOptions={handleMunicipioLoadOptions}
              withPaginate
              value={cidade}
              onChange={handleMunicipioChange}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.spacing}>
            <span className={classes.tituloFiltros}> Situação: </span>
            <SelectCustomIcon
              isSelect
              onChange={onStatusChange}
              value={status}
              options={statusList}
              className={classes.selectSituacao}
            />
          </Grid>
        </div>
        <Button
          shape='pill'
          bgColor='#707C97'
          onClick={handleClickSearch}
          disabled={disabledButtonFilter}
        >
          Filtrar
        </Button>
      </div>

      <div className={classes.tableDadosAniversariantes}>
        <Scroll
          loadMore={loadMore}
          hasMore={!metaData.last}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainer}
        >
          {aniversariantes.length === 0 && !aniversariantesLoading && (
            <div className={classes.notFoundContainer}>
              <h3>Nenhum item encontrado</h3>
            </div>
          )}

          {aniversariantes.length > 0 && (
            <Table
              className={classes.table}
              dados={aniversariantes}
              columns={columns({ utilizaTelefoneInternacional })}
              clickable={false}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
            />
          )}

          {aniversariantesLoading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
        </Scroll>
        <div className={classes.buttonsDownloadPrint}>
          <ButtonYellow 
            onClick={printRelatorio}
            disabled={!dataInicial || !dataFinal}
          >
            <PrintIcon />
          </ButtonYellow>
        </div>
      </div>
      { isPrintMustache && 
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={ <RelatorioAniversariantesDocument 
            dataInicio={dataInicial}
            dataFim={dataFinal}
            cidade={cidade}
            situacao={status.label}
            aniversariantes={listaTotalAniversariantes}
            utilizaTelefoneInternacional={utilizaTelefoneInternacional}
          />}
        />}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </div>
  );
});

const RelatorioAniversariantesWithStyles = withStyles(styles)(RelatorioAniversariantes)
export default inject("configuracaoImpressaoStore", "relatorioStore")(RelatorioAniversariantesWithStyles);
