import { getWhitelabelNome } from "../../../services/WhitelabelService";

export const perfilViziAtendimentoPresencialOption = {
  label: 'Atendimento Presencial',
  attribute: 'atendePresencial',
  storedIn: 'perfilPublicoStore'
}

export const perfilViziTeleconsultaOption = {
  label: 'Atendimento por teleconsulta',
  attribute: 'utilizaTelemedicina',
  storedIn: 'perfilPublicoStore',
  informacao: 'Ativando essa funcionalidade será ativado automaticamente para seu paciente o meio de pagamento online via cartão de crédito.',
}

export const perfilViziUnidadeOptions = [
  {
    label: 'Exibir profissionais da unidade',
    attribute: 'permiteProfissionalUnidade',
    storedIn: 'perfilPublicoStore'
  },
  {
    label: `Perfil visível na busca`,
    attribute: 'permiteBuscaProfissional',
    storedIn: 'perfilPublicoStore'
  },
  {
    ...perfilViziAtendimentoPresencialOption
  },
  {
    ...perfilViziTeleconsultaOption
  },
  {
    label: 'Agendamento de exames',
    attribute: '',
    storedIn: '',
    disabled: true,
  },
  {
    label: 'Agendamento de procedimentos',
    attribute: '',
    storedIn: '',
    disabled: true,
  },
  {
    label: 'Exibir perfil nos resultados do Google',
    attribute: 'permiteBuscaGoogle',
    storedIn: 'perfilPublicoStore',
    informacao: 'Se caso não queira que sua página seja encontrada nos resultados de busca de plataformas como Google, Bing, entre outros, desative essa funcionalidade.'
  },
  {
    label: 'Habilitar perfil apenas para visualização',
    attribute: 'perfilVitrine',
    storedIn: 'perfilPublicoStore'
  }
];

export const unidadeViziPerfilIncompletoTitle = "Seu perfil público da unidade está incompleto!";
export const unidadeViziPerfilIncompletoMessage = "Para ter um bom perfil e ser encontrado pelos seus pacientes, é nescessário preencher alguns campos básicos.";
export const unidadeSemProfissionalTitle = "Nenhum perfil de Profissional vinculado e ativado :(";
export const unidadeSemProfissionalMessage = `Para conseguir que a sua unidade realize atendimento online, precisa ter pelo menos um profissional da saúde ativado dentro do ${getWhitelabelNome()}. Assim o perfil público da unidade não exibirá dentro da pesquisa na plataforma.`;
export const unidadeEspecialidadeText = "Essas especialidades são as que os médicos da sua clínica atendem. Você pode ocultar caso não queria exibir no perfil público da clínica, e/ou definir um valor a ser cobrado pelo agendamento realizado pelo perfil da clínica.";
export const unidadeEspecialidadeOBS = "OBS.: Se o campo ficar vazio, será selecionado o valor que o profissional da saúde colocou.";