import React from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import InputSearch from "../../../../components/Input/Input";
import colors from "../../../../template/Colors";
import Search from "@material-ui/icons/Search";
import CardPaciente from "./CardPaciente";

const ListaPaciente = (props) => {
  const {
    classes,
    title,
    valueSearch,
    onChangeSearch,
    onKeypressSearch,
    handleClickSearch,
    lista,
    loadMore,
    lastScroll,
    isLoading,
    onClickPaciente,
    handleClickClear
  } = props;

  return (
    <div className={classes.content}>
      <div className={classes.title}>{title}</div>
      <div className={classes.contentLista}>
        <InputSearch
          hideStartAdornment
          endAdornment={
            <>
              {valueSearch && <IconButton
                className={classes.iconButtonSearch}
                onClick={handleClickClear}
              >
                <CloseIcon className={classes.closeIcon}/>
              </IconButton>}
              <IconButton
                className={classes.iconButtonSearch}
                onClick={handleClickSearch}
              >
                <Search />
              </IconButton>
            </>
          }
          placeholder="Pesquisar"
          value={valueSearch}
          onChange={onChangeSearch}
          onKeyPress={onKeypressSearch}
          classInputRoot={classes.inputRootSearch}
          className={classes.inputSearch}
        />
        <Scroll
          className={classes.scroll}
          loadMore={loadMore}
          hasMore={!lastScroll}
          initialLoad={false}
        >
          <div className={classes.contentScrollList}>
         {
              lista.map((item, index) => {
                return(
                  <CardPaciente 
                    sujeitoAtencao={item}
                    onClickPaciente={() => onClickPaciente(item, index)}
                    key={index}
                  />
                )
             })
         }

        {isLoading && 
           <div className={classes.notFoundContainer}>
            <CircularProgress/>
            </div>
        }

         </div>
        </Scroll>
      </div>
    </div>
  );
};

const styles = {
  content: {    
    background: colors.commons.padrao,
    padding: "19px 18px 11px 16px",
    height: 'calc(100% - 30px)',
  },
  title: {
    color: colors.commons.white,
    fontWeight: 600,
    fontSize: 18,
  },
  contentLista: {
    padding: "12px 8px",
    background: '#FFFFFF',
    borderRadius: '16px',
    marginTop: '16px',
    height: 'calc(100% - 70px)',
    display: 'grid',
    gridTemplateRows: '42px 1fr'
  },
  iconButtonSearch: {
    padding: 0,
  },
  inputSearch: {
    paddingBottom: 8,
    width: '100%',
  },
  inputRootSearch: {
    background: "#F2F2F2",
    border: "none",
    fontFamily: "Poppins",
    fontSize: 14,
    height: 32,
  },
  contentScrollList: {
    maxHeight: '0px',
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    margin: 10,
  },
  closeIcon: {
    width: 18,
    height: 18,
  }
};

export default withStyles(styles)(ListaPaciente);
