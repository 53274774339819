import React, { useState, useEffect, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import moment from 'moment'
import classNames from 'classnames'

import AnamneseInput from './AnamneseInput'
import ListagemAnamnese from './ListagemAnamnese'

function Anamnese({ classes, perguntas, isDisabled, getValuesAnamnese, isSolicitacaoPaciente }) {
  const [value, setValue] = useState({})

  const getValueByType = (type, value) => {
    switch (type) {
      case 'RADIO':
        return value?.target?.checked
      case 'DATE':
        return moment(value).format('YYYY-MM-DD')
      case 'TIME':
        return moment(value).format('HH:mm')
      case 'DATETIME':
        return moment(value).format('YYYY-MM-DDTHH:mm:ss')
      default:
        return value?.target?.value
    }
  }

  const handleChange = (value, idPergunta, idResposta, type) => {
    if (type === 'CHECKBOX') {
      setValue(prevState => {
        const currentValue = prevState?.[idPergunta]?.[idResposta]
        return {
          ...prevState,
          [idPergunta]: {
            ...prevState?.[idPergunta],
            [idResposta]: !currentValue,
          },
        }
      })
      return
    }
    setValue(prevState => {
      return {
        ...prevState,
        [idPergunta]: {
          [idResposta]: getValueByType(type, value),
        },
      }
    })
  }

  const handleChangeOutro = (value, idPergunta, idResposta, type) => {
    const currentValue = value?.target?.value
    setValue(prevState => {
      return {
        ...prevState,
        [idPergunta]: {
          ...prevState?.[idPergunta],
          [idResposta]: currentValue,
        },
      }
    })
  }

  const defaultValues = useMemo(() => {
    const response = perguntas?.reduce((acc, item) => {
      return {
        ...acc,
        [item?.id]: item?.respostas?.reduce((acc, resposta) => {
          return {
            ...acc,
            [resposta?.id]:
              item?.tipo === 'COMBOBOX'
                ? { id: resposta?.id, nome: resposta?.nome }
                : resposta?.valorConvertido,
          }
        }, {}),
      }
    }, {})
    return response
  }, [perguntas])

  useEffect(() => {
    setValue(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    getValuesAnamnese(value)
  }, [value])

  return (
    <>
      {perguntas?.map((item, index) => {
        const respostaOption = item?.respostas?.map(resposta => resposta?.id)
        return (
          <div className={classNames(classes.container, isSolicitacaoPaciente && classes.containerSolicitacaoPaciente)} key={`${item?.id}${index}`}>
            <span className={classNames(classes.label, isDisabled && classes.labelDisabled, isSolicitacaoPaciente && classes.labelSolicitacaoPaciente)}>
              {item?.pergunta}
            </span>
            {item?.tipo === 'COMBOBOX' && !isDisabled ? (
              <AnamneseInput
                isSolicitacaoPaciente
                type={item?.tipo}
                value={value?.[item?.id]?.[respostaOption?.id]}
                options={item?.respostas}
                onChange={value => {
                  const getIdSelected = item?.respostas?.find(option => option?.id === value?.id)
                  handleChange(value, item?.id, getIdSelected?.id)
                }}
              />
            ) : (
              <>
                {item?.respostas?.map((resposta, index) => (
                  <React.Fragment key={index}>
                    {isDisabled ? (
                      <ListagemAnamnese type={item?.tipo} resposta={resposta} isSolicitacaoPaciente/>
                    ) : (
                      <AnamneseInput
                        isSolicitacaoPaciente
                        type={item?.tipo}
                        label={resposta.nome}
                        value={value?.[item?.id]?.[resposta?.id]}
                        options={[
                          { valorConvertido: resposta?.valorConvertido, nome: resposta?.nome },
                        ]}
                        handleChangeOutro={value =>
                          handleChangeOutro(value, item?.id, resposta.id, item?.tipo)
                        }
                        onChange={value => handleChange(value, item?.id, resposta.id, item?.tipo)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        )
      })}
    </>
  )
}

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '265px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #F2F2F2',
    borderRadius: '10px',
    padding: '1rem',
    marginBottom: '1rem',
  },
  label: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    paddingBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.03em',
    color: '#000000',
  },
  labelDisabled: {
    color: '#868686',
  },
  containerSolicitacaoPaciente: {
    border: 'none',
    padding: 0,
    marginBottom: '10px',
    maxWidth: "100%"
  },
  labelSolicitacaoPaciente: {
    fontWeight: 400,
    fontSize: "12px !important",
    paddingBottom: "8px",
  }
})

export default withStyles(styles)(Anamnese)
