import { primaryColor } from "../appHealth.style";
import colors from "../../../template/Colors";

const itemListaPacienteStyle = (theme) => ({
    primaryColor,
    gridItem: {
        display: "grid",
        maxWidth: 210,
    },
    border: {
        paddingRight: '13px',
        borderRadius: '16px',
    },
    gridItemMinimizado:{
        display:'flex',
        justifyContent: 'center',
    },

    item: {
        display: "flex",
        color: colors.commons.gray7,
        transition: "all 0.2s",
        cursor: "pointer",
        alignItems: "center",
        background: "#F9F9F9",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "7.38281px 7.38281px 18.457px rgb(112 124 151 / 5%), 11.0742px 11.0742px 25.8398px rgb(112 124 151 / 5%)",
        borderRadius: "16px",
        height: "64px",
        width: "175px",
    },

    imageIconeAniversario:{
        display: "grid",
    },

    itemMinimizado: {
        display: "grid",
        gridTemplateColumns: "40px 1fr",
        maxHeight: "40px",
        border: "solid 4px",
        color: colors.commons.gray7,
        transition: "all 0.2s",
        cursor: "pointer",
        borderRadius: 50,
    },

    itemSelected: {
        '&.red': {
            backgroundColor: colors.commons.redLight
        },
        '&.yellow': {
            backgroundColor: colors.commons.yellowLight
        },
        '&.blue': {
            backgroundColor: colors.commons.blueLight
        },
        '&.purple': {
            backgroundColor: colors.commons.purpleLight
        },
        '&.green': {
            backgroundColor: colors.commons.greenLight
        },
        '&.darkGray': {
            backgroundColor: colors.commons.darkGrayLight
        },
        '&.waikawaGrey': {
            backgroundColor: colors.commons.waikawaGreyLight
        },
        '&.pink': {
            backgroundColor: colors.commons.pinkLight
        }
    },

    itemSelectedMinimizado:{
        border: '4px solid',
        color: "#fff",
    },

    info: {
        position: "relative",
        alignItems: "center",
        padding: "5px 5px",

        "& > div": {
            display: "grid",
            gridRowGap: '10px',
            [theme.breakpoints.down('md')]: {
                gridRowGap: '0px'
            },
        },
    },

    infoMinimizado:{
        position: "relative",
        alignItems: "center",
    },

    image:{
        width: 40,
        height: 40,
        borderRadius: 50,
        marginLeft: '-22px',
    },
    imageMinimizado: {
        marginLeft: 0
    },

    icon: {
        gridRow: 'span 2',
    },

    playStopIcon: {
        position: "absolute",
        top: 2,
        right: -5,
        padding: "5px 10px",
    },

    textInfo: {
        margin: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: 12,
    },
    
    maxWidthTipo: {
        maxWidth: '89px',
    },

    centraliza: {
        alignSelf: 'center',
    },

    nomePaciente: {
        maxWidth: "143px",
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 14,
        fontStyle: 'normal',
        color: '#505050',
    },
    
    textInfoContainer: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column"
        },
        flexDirection: 'column'
    },
    textTipo: {
        fontSize: "12px",
        color: '#7C7C7C',
    },
    colorFont: {
        color: '#219A97',
        fontWeight: 700,
    },

    horaTipo:{
        display: 'flex',
        gap: '4px',
    },

    ptipo:{
        marginLeft: 10,
    },

    greenStatus: {
        borderColor: theme.palette.commons.green,
        backgroundColor: theme.palette.commons.green,
    },
    redStatus: {
        borderColor: theme.palette.commons.red,
        backgroundColor: theme.palette.commons.red,
    },
    yellowStatus: {
        borderColor: theme.palette.commons.yellow,
        backgroundColor: theme.palette.commons.yellow,
    },
    purpleStatus: {
        borderColor: theme.palette.commons.purple,
        backgroundColor: theme.palette.commons.purple,
    },
    blueStatus: {
        borderColor: theme.palette.commons.blue,
        backgroundColor: theme.palette.commons.blue,
    },
    darkGrayStatus: {
        borderColor: theme.palette.commons.darkGray,
        backgroundColor: theme.palette.commons.darkGray,
    },
    waikawaGreyStatus: {
        borderColor: theme.palette.commons.waikawaGrey,
        backgroundColor: theme.palette.commons.waikawaGrey,
    },
    pinkStatus: {
        borderColor: theme.palette.commons.pink,
        backgroundColor: theme.palette.commons.pink,
    },

});

export default itemListaPacienteStyle;
