import React from "react";
import { observer } from "mobx-react-lite";
import { FormControlLabel, Switch } from "@material-ui/core";

const DentesSwitch = observer(({ handleSwitchChange, dentes }) => {
    return (<>
        {
            dentes.map((item, index) => {
                return (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={item.status === "ATIVO"}
                                color="primary"
                            />
                        }
                        onChange={() => handleSwitchChange && handleSwitchChange(index)}
                        label={item.codigo}
                        labelPlacement='end'
                    />
                )
            })
        }
    </>)
});

export default DentesSwitch;
