import moment from 'moment'

export const openModalAgendamento = async (data, atendimentoStore) => {
  await atendimentoStore.loadAgendamentoTipo()
  await atendimentoStore.initObjectWeekView()
  atendimentoStore.isAgendamentoModalListaEspera = true;

  const horaInicio = moment()
  const duracao = atendimentoStore.configVigente.duracao
  const horaFim = moment(horaInicio).add(duracao, 'minutes')

  const event = {
    id: data?.id,
    horaInicio,
    horaFim,
    isModalSala: data?.sala?.id,
  }

  const setDefaultValuesModalAgendamento = () => {
    if (atendimentoStore.agendamentoListaEspera?.id) {
      atendimentoStore.objView = {
        ...atendimentoStore.objView,
        data: atendimentoStore.agendamentoListaEspera.data,
        horaInicio: atendimentoStore.agendamentoListaEspera.horaInicio,
        horaFim: atendimentoStore.agendamentoListaEspera.horaFim,
      }
    }
    atendimentoStore.objView.listaDeEspera = false
  }
  return await atendimentoStore.changeOpen(event, setDefaultValuesModalAgendamento)
}
