import Api from "../config/api";

export const saveDocumentoModelo = async ( documentoModelo ) => {
  const method = documentoModelo.id ? 'updateDocumentoModelo' : 'createDocumentoModelo';

  const response = await Api.post("", {
    query: `
      mutation ($documentoModelo: DocumentoModeloInput){
        ${method}(documentoModelo: $documentoModelo){
          nome
          unidade {
            id
          }
          modelo
          id
        }
      }`,
    variables: { documentoModelo },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
};

export const findAllDocumentoModeloCampoSistema = async (page) => {
  const response = await Api.post('', {
    query: `
      query ($page: SearchDTOInput){
        findAllDocumentoModeloCampoSistema(searchDTO: $page){
          campo
          entidade
          descricao
          }
      }`,
    variables: { page }
  });

  return response.data.data.findAllDocumentoModeloCampoSistema;
};