const styles = {
  dialogHeader: {
    color: "#505050",
    fontSize: "18px",
    fontWeight: 700,
    padding: "24px 24px 16px",
    width: "252px",
  },
  dialogContent: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    alignSelf: "center",
    gap: "8px",
  },
  labelTime: {
    color: "#505050",
    fontFamily: "Poppins",
    fontSize: "12px",
    width: "100%",
    textAlign: "center",
  },
  date: {
    border: "none",
  },
  inputDate: {
    color: "#505050",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 500,
    width: "55px",
    padding: "0",
    display: "flex",
    textAlign: "center",
  },
  iconTime: {
    display: "none",
  },
  contentButtons: {
    flexDirection: "column",
    gap: "8px",
    margin: 0,
  },
};

export default styles;
