import { action, observable } from "mobx";
import {
  findAllEstadoList,
  getDadosGuiaServicoAgendamento,
} from "../services/GuiaDeServicosService";

export default class GuiaDeServicoStore {
  @observable isOpenModal = false;
  @observable guiaServico = null;

  @action changeFieldsStore(values) {
    const arrayKey = Object.keys(values);

    arrayKey.forEach((item) => {
      this[item] = values[item];
    });
  }

  @action async carregaGuiaServicoAgendamento(id) {
    const response = await getDadosGuiaServicoAgendamento(id);
    const listaEstados = await findAllEstadoList();

    const estado = response.profissionalSaude?.ufConselho
      ? listaEstados.data.data.findAllEstadoList.find(
          (item) => response.profissionalSaude?.ufConselho === item.uf
        )
      : "";

    let procedimentos = [];

    response.procedimentos.forEach((procedimento) => {
      const procedimentosRepetidos = response.procedimentos.filter(
        (item) => procedimento.procedimento.id === item.procedimento.id
      );
      const procedimentosRepetidosQuantidade = {
        ...procedimento,
        quantidadeAutorizada: procedimentosRepetidos.length,
        quantidadeSolicitada: procedimentosRepetidos.length,
      };

      procedimentos.push(procedimentosRepetidosQuantidade);
    });

    const dadosGuia = {
      agendamentoId: response.id,
      beneficiario: {
        sujeitoAtencao: {
          id: response.sujeitoAtencao.id,
          nome: response.sujeitoAtencao.nome,
          nomeSocial: response.sujeitoAtencao.nomeSocial,
        },
        nome: response.sujeitoAtencao.nome,
        numeroCarteira: response.sujeitoAtencao.dadosConvenio?.numeroCarteira,
        dataValidadeCarteira:
          response.sujeitoAtencao.dadosConvenio?.validadeCarteira,
        cns: response.sujeitoAtencao.cns,
        atendimentoRN: "N",
      },
      convenio: response.convenio,
      solicitanteInterna: {
        profissional: response.profissionalSaude.nome,
        conselhoProfissionalSaude: {
          id: response.profissionalSaude?.conselhoProfissionalSaude?.id,
          sigla: response.profissionalSaude?.conselhoProfissionalSaude?.sigla,
        },
        codigoConselho:
          response.profissionalSaude?.conselhoProfissionalSaude?.codigoTermo,
        estado: estado,
        codigoCbo:
          response.profissionalSaude?.informacao?.tuss24TerminologiaCBO
            ?.codigoTermo,
        numeroConselho: response.profissionalSaude?.numeroConselho,
        tuss24TerminologiaCBO:
          response.profissionalSaude?.informacao?.tuss24TerminologiaCBO,
        nomeContratado: response.unidade?.nome,
        codigoOperadora: response.convenio?.codigoUnidade,
      },
      solicitacao: {
        itens: procedimentos,
      },
    };

    this.guiaServico = dadosGuia;
    this.isOpenModal = true;
  }
}
