import React from "react";
import moment from "moment";

const ReciboExtratoPdfDocument = (props) => {
    const { dadosTitulo } = props;
    const profissionalSaude = dadosTitulo?.profissionalSaude || dadosTitulo?.titulo?.profissionalSaude;
    const sujeitoAtencao = dadosTitulo?.sujeitoAtencao || dadosTitulo?.titulo?.sujeitoAtencao;

    let validaProfissionalSaude = profissionalSaude 
    && profissionalSaude.conselhoProfissionalSaude 
    && profissionalSaude.numeroConselho 
    && profissionalSaude.ufConselho;

    const getCurrentValor = () => {
        if(!dadosTitulo?.desconto) return dadosTitulo?.valor;
        if(dadosTitulo?.tipoDesconto === "VALOR") {
            return dadosTitulo?.valor - dadosTitulo?.desconto;
        } else {
            const valorDesconto =  dadosTitulo?.valor * (dadosTitulo?.desconto/100);
            return dadosTitulo?.valor - valorDesconto;
        }
    }

    return (
        <>
            <div style={styles.titulos}>
                    Recibo
            </div>

            {sujeitoAtencao &&
            <div style={styles.text}>
                Caro (a) {sujeitoAtencao.nome}, {sujeitoAtencao.cpf && `portador do Documento ${sujeitoAtencao.cpf}, `} este é seu recibo de pagamento referente a {dadosTitulo.nome}.
            </div>}

            {validaProfissionalSaude ?
            <div style={styles.text}>
                Seu atendimento foi efetuado pelo (a) profissional {profissionalSaude.nome},
                portador do {profissionalSaude.conselhoProfissionalSaude.sigla} {profissionalSaude.numeroConselho} {profissionalSaude.ufConselho}.
            </div>
            :
            <div style={styles.text}>
                Seu atendimento foi efetuado pelo (a) profissional {profissionalSaude?.nome}.
            </div>
            }

            <div style={styles.titulos}>
                    Dados financeiros
            </div>

            {dadosTitulo.formaPagamento &&
            <div style={styles.text}>
                Método de pagamento: {dadosTitulo.formaPagamento.nome}
            </div>}
            {dadosTitulo.dataVencimento &&
            <div style={styles.text}>
                Data de vencimento: {moment(dadosTitulo.dataVencimento).format("DD/MM/YYYY")}
            </div>}
            {dadosTitulo.dataPagamento &&
            <div style={styles.text}>
                Data de pagamento: {moment(dadosTitulo.dataPagamento).format("DD/MM/YYYY")}
            </div>}

            {(dadosTitulo.tipoRepeticao === "PARCELAR") ?
                <div>
                    <div style={styles.text}>
                        Tipo de pagamento: Parcelado
                    </div>
                    {dadosTitulo.titulo.valorEntrada &&
                    <div style={styles.text}>
                        Valor da entrada: R$ {dadosTitulo.titulo.valorEntrada} reais
                    </div>}
                    {dadosTitulo.titulo.parcelas && 
                        <>
                            <div style={styles.text}>
                                Valor da parcela: R$ {dadosTitulo.titulo.parcelas[1].valor} reais
                            </div>
                            <div style={styles.text}>
                                Número de parcelas: {dadosTitulo.totalParcelas}
                            </div>
                        </>
                    }
                    <div style={styles.text}>
                        Valor total: R$ {dadosTitulo.titulo.valorEntrada + (dadosTitulo.totalParcelas * parseFloat(dadosTitulo.titulo.parcelas[1].valor))} reais.
                    </div>
                </div>
            :
                <div>
                    <div style={styles.text}>
                        Valor: R$ {getCurrentValor()} reais.
                    </div>
                </div>
            }
            </>
    );
};

const styles = {
    text: {
        fontSize: '14px',
        margin: '5px',
        textAlign: 'justify',
    },
    textBold: {
        fontWeight: 700,
    },
    titulos: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderBottom: '2px solid #E5E5E5',
        marginLeft: '0px',
        marginBottom: '15px',
        padding: '5px 0',
        fontSize: '18px',
        fontWeight: 700,
    }
}

export default ReciboExtratoPdfDocument;
