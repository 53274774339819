export const eventCardHeight = 130;

export default theme => ({
    weekCalendar: {
        padding: '0 20px 0 0',
        overflow: 'auto'
    },
    calendarContainer: {
        display: "flex", 
        flexDirection: "column", 
        overflow: "hidden", 
        height: "100%"
    },
    weekCalendarInner: {
        width: '100%',
        height: 'calc(100vh - 10vh - 11.94vh - 17px - 130px)',
        flexDirection: 'row',
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'hidden',
        alignItems: 'flex-start'  
    },
    weekCalendarHeaderInner: {
        flexDirection: 'row',
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'hidden',
        minHeight: '70px',
        marginLeft: '80px'
    },
    weekCalendarColumn: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    weekCalendarColumnHeader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 74,
        color: "#505050",

    },
    weekCalendarColumnHeaderDayLabel: {
        fontSize: "24px !important",
        userSelect: "none"
    },
    weekCalendarColumnHeaderDayWeekLabel: {
        fontSize: "16.5px !important",
        fontFamily: "Poppins",
        userSelect: "none"
    },
    weekCalendarColumnHeaderTodayLabel: {
        color: "#F9BE73"
    },
    infoIcon: {
        color: "#F9BE73",
        fontSize: "19px",
        marginLeft: "2px"
    },
    tooltip: {
        backgroundColor: "#FFFFFF",
        color: "#000",
        fontSize: "14px",
        boxShadow: theme.shadows[1],
    }
});
