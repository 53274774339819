import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { InputDateForm } from "../../../components/Modal/Input";
import { ReactSelect, ReactSelectCheckbox } from "../../../components/Select/SelectSearch";
import PeriodDate from "../../../components/PeriodDate/PeriodDate";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import CancelarAgendamentoIcon from "../../../components/Icon/CancelarAgendamentoIcon";
import { meses,periodos } from "../ModalBloquear/Constants";
import { countChecked } from "../ModalBloquear/util/utils";
import { generateTimeList } from "../../../utils/generateTimeList";

const ModalCancelamento = observer(({
  classes,
  open,
  close,
  handleOpenModalConfirmacao,
  atendimentoStore,
  operation,
  isLoading,
  horariosOptions,
  openModalConfirmarCancelamento,
}) => {
  const [periodo, setPeriodo] = useState("");
  const [mes, setMes] = useState("");

  useEffect(() => {
    atendimentoStore.horariosOptions = getHorariosToCheck();
  }, [ModalCancelamento.ope]);


  const handlePeriodo = (e) => {
    atendimentoStore.dataSelecionadaCancelaAgenda = {
      dataInicial: null,
      dataFinal: null,
    }

    if (e.nome === "Personalizado") {
      atendimentoStore.dataSelecionadaCancelaAgenda = !atendimentoStore
        .dataSelecionadaCancelaAgenda?.dataInicial && {
        dataInicial: moment().format("YYYY-MM-DD"),
        dataFinal: moment().format("YYYY-MM-DD"),
      };
    }

    if (e.nome === "Semana") {
      atendimentoStore.dataSelecionadaCancelaAgenda = {
        dataInicial: moment()
          .day(0)
          .format("YYYY-MM-DD"),
        dataFinal: moment()
          .day(6)
          .format("YYYY-MM-DD"),
      };
    }

    setPeriodo(e);
  };

  const handleDia = (e) => {
    atendimentoStore.dataSelecionadaCancelaAgenda = {
      dataInicial: e,
      dataFinal: e,
    };
  };

  const handleMes = (e) => {
    setMes(e);
    const currentDate = moment();
    const selectedMonth = e.value;
    const year = selectedMonth >= currentDate.month() ? currentDate.year() : currentDate.year() + 1;
    const dataMesSelecionado = moment().month(selectedMonth).year(year);

    const primeiroDiaDoMes = dataMesSelecionado.startOf("month");
    const ultimoDiaDoMes = dataMesSelecionado.clone().endOf("month");

    atendimentoStore.dataSelecionadaCancelaAgenda = {
      dataInicial: primeiroDiaDoMes,
      dataFinal: ultimoDiaDoMes,
    };
  };


  const getHorariosToCheck = () => {
    const { duracao, horaInicioPeriodoAtendimento, horaFimPeriodoAtendimento } = atendimentoStore?.configVigente;
    const timeList = generateTimeList(horaInicioPeriodoAtendimento, horaFimPeriodoAtendimento, duracao);
    timeList.unshift('Dia todo');
    return timeList.map(time => ({ label: time, value: time, checked: false }));
  };

  const handleDataSelecionadaCancelaAgenda = (value) => {
    atendimentoStore.dataSelecionadaCancelaAgenda = value;
  };

  const handleChangeDate = (dataInicial, dataFinal) => {
    atendimentoStore.dataSelecionadaCancelaAgenda = {
      dataInicial,
      dataFinal,
    };
  };

  const handleClickHorario = (horarios) => {
    let horariosOptionsAux = [...horariosOptions];
  
    const opcaoDiaTodoSelecionadoHorarios = horarios.some(item => item.value === "Dia todo");
    const opcaoDiaTodoSelecionadoHorariosOptions = horariosOptionsAux.some(item => item.value === "Dia todo" && item.checked);
  
    if (opcaoDiaTodoSelecionadoHorariosOptions) {
      horariosOptionsAux = opcaoDiaTodoSelecionadoHorarios
        ? horariosOptionsAux.map(h => ({ ...h, checked: h.value === "Dia todo" ? false : horarios.some(item => item.value === h.value) }))
        : horariosOptionsAux.map(h => ({ ...h, checked: false }));

    } else {
      horariosOptionsAux = (opcaoDiaTodoSelecionadoHorarios || (!opcaoDiaTodoSelecionadoHorarios && horarios.length === horariosOptionsAux.length - 1))
        ? horariosOptionsAux.map(h => ({ ...h, checked: true }))
        : horariosOptionsAux.map(h => ({ ...h, checked: horarios.some(item => item.value === h.value) }));
    }
  
    atendimentoStore.horariosOptions = horariosOptionsAux;
  };

  const renderSelectDate = () => {
    switch (periodo?.nome) {
      case "Dia":
      default:
        return (
          <InputDateForm
            iconposition="end"
            className={classes.dateTime}
            disabled={!periodo?.nome}
            classes={{
              date: classes.inputDate,
            }}
            value={atendimentoStore.dataSelecionadaCancelaAgenda.dataInicial}
            onChange={handleDia}
          />
        );
      case "Semana":
        return (
          <PeriodDate
            filter={atendimentoStore.dataSelecionadaCancelaAgenda}
            setFilter={handleDataSelecionadaCancelaAgenda}
            onChangeDate={handleChangeDate}
            handleAplicar={() => {}}
            stylePopper={classes.popperPeriodDate}
            withoutZIndex
          />
        );
      case "Mês":
        return (
          <ReactSelect
            className={classes.selectSearch}
            placeholder="Selecione"
            value={mes}
            options={meses}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.value}
            onChange={handleMes}
          />
        );
      case "Personalizado":
        return (
          <PeriodDate
            filter={atendimentoStore.dataSelecionadaCancelaAgenda}
            setFilter={handleDataSelecionadaCancelaAgenda}
            onChangeDate={handleChangeDate}
            handleAplicar={() => {}}
            stylePopper={classes.popperPeriodDate}
            withoutZIndex
          />
        );
    }
  };



  const confirmaCancelaAgenda = () => {
    close()
    openModalConfirmarCancelamento();
    handleOpenModalConfirmacao()
  };

  return (
    
    <ModalConfirmacao
      open={open}
      icon={<CancelarAgendamentoIcon/> }
      color={"red"}
      title={"Cancelar agendamentos"}
      classes={{
        paperAlert: classes.paperAlert,
      }}
      buttons={{
        labelPrimaryButton: "Cancelar",
        handlePrimaryButton: () => close(),
        disabledPrimaryButton: isLoading,
        labelSecondButton: "Continuar",
        handleSecondButton: () => confirmaCancelaAgenda(),
        disabledSecondButton: !atendimentoStore.dataSelecionadaCancelaAgenda?.dataInicial || countChecked(atendimentoStore.horariosOptions) === 0,
        // loadingSecondButton: isLoading,
      }}
    >
      <div className={classes.content}>
        <div className={classes.inputWithLabelContainer}>
          <div className={classes.title}>Qual período você deseja cancelar?</div>
          <ReactSelect
            className={classes.selectSearch}
            placeholder="Selecione"
            value={periodo}
            options={periodos}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.value}
            onChange={handlePeriodo}
          />
        </div>
        <div className={classes.inputWithLabelContainer}>
          <div className={classes.title}> Selecione a data </div>
          {renderSelectDate()}
        </div>
        <div className={classes.inputWithLabelContainer}>
          <div className={classes.title}> Horário </div>
          <ReactSelectCheckbox
            onChange={handleClickHorario}
            options={atendimentoStore?.horariosOptions || []}
            value={atendimentoStore?.horariosOptions.filter(item => item.checked)}
          />
        </div>
      </div>
    </ModalConfirmacao>
  );
});

const ModalCancelamentWithStyles = withStyles(styles)(ModalCancelamento)
export default inject("atendimentoStore")(ModalCancelamentWithStyles);

