import Colors from "../../../template/Colors";

const style = {
    fullSizePaper: {  
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
        overflow: "unset",
        height: 625,
    },
    contentModal: {
      width: "100%",
      display: 'grid',
      gridRemplateRows: '170px auto 1fr',
    },
    contentHistoricoLancamento: {
      display: 'flex',
      padding: 20,
      borderRadius: "0px 10px 10px 0px",
      background: Colors.commons.padrao,
      width: '100%',
      maxWidth: '315px',
    },
    tituloHeader: {
        fontSize: 18,
        fontWeight: 700,
        color: Colors.commons.secondary,
        borderRadius: "10px 10px 0 0",
        height: 42
    },
    header: {
        display: "flex",
        flexDirection: 'column',
        padding: 32,
        boxShadow: "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
    },
    dadosPacienteFoto: {
      display: 'flex',
      width: '75%',
    },
    nomePaciente: {
      color: Colors.commons.gray9,
      fontWeight: 700,
      height: '32px',
      alignItems: 'center',
      display: 'flex',
    },
    boxDadosPaciente: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 14,
    },
    dadosPaciente: {
      display: 'flex'
    },
    dadosPacienteItem: {
      fontSize: '12px !important',
      height: '21px',
      alignItems: 'center',
      marginRight: 8,
      "&>span": {
        fontSize: '12px !important',
        fontWeight: 700,
        color: Colors.commons.gray9,
      }
    },
    buttonHeader: {
        width: "35px",
        height: "35px",
        color: "#fff",
        marginLeft: 10,
        boxShadow: "none",
    },
    buttonActionSave: {
        background: Colors.commons.secondary,
        "&:hover": {
          background: Colors.primary.dark,
        },
    },
    buttonActionClose: {
        background: Colors.error.main,
        "&:hover": {
          background: Colors.error.dark,
        },
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        margin: '20px 20px 16px 20px',
        border: '1px solid #F2F2F2',
        borderRadius: 10,
        overflow: 'auto',
      },
      spacing: {
        paddingRight: 16,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      spacingLast: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      titulo: {
        color: Colors.commons.gray7,
        fontSize: "12px !important",
      },
      iconTime:{
        '& button': {
          backgroundColor: 'initial',
          "&:disabled": {
            backgroundColor: Colors.commons.gray8
        }
        },
        '& svg':{
          fill: `${Colors.commons.gray7} !important`
        }
      },
      inputRootBlockField: {
        paddingLeft: 0,
        border: 'none',
        fontSize: 14,
      },
    
      inputMask:{
        backgroundColor: Colors.commons.gray2,
        borderRadius: 8,
        justifyContent: 'center',
        width: '100%',
        border: 'none',
        height: 32,
      },
    
      inputBlockField: {
        fontFamily: "Poppins !important",
        padding: "5px 0",
        textAlign: "center",
        color: Colors.commons.gray9,
      },

      tituloHistoricoLancamento: {
        fontSize: 18,
        fontWeight: 700,
        color: "#fff",
        marginBottom: '17px',
    },
    buttonClose: {
      position: 'absolute',
      right: '15px',
      top: 18,
      minHeight: 26,
      height: 32,
      width: 32,
      backgroundColor: Colors.commons.gray,
      color: Colors.commons.secondary,
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: Colors.commons.gray4,
      }
    },
    boxInfosHistoricoLancamento: {
      background: "#f9f9f9",
      borderRadius: 13,
      height: '100%',
      padding: 14,
      overflow: 'auto'
    },
    headerContentHistoricoLancamento: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    tituloBoxAfericaoVital: {
      color: "#000",
      fontWeight: 600,
      fontSize: 13,
    },
    infosRow: {
      display: 'flex',
      flexDirection: 'row',
      margin: '3px 0 8px 0',
    },
    inputColeta: {
      height: 21,
    },
    inputColetaVisualizar: {
      height: 23,
      marginRight: 0,
      background: "#EEEEEE"
    },
    selectColeta: {
      "&> div": {
        height: 32,
        "&> div": {
          height: 32,
        }
      }
    },
    tituloColeta: {
      marginBottom: 0
    },
    contentButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 20px 16px 20px',
      alignItems: 'center',
    },
    buttonLimparCampos: {
      color: Colors.commons.gray9,
      cursor: 'pointer',
      height: 20,
      fontWeight: 700
    },
    buttonSave: {
      width: '100%',
      maxWidth: '215px',
    },
    inputPressaoMedia: {
      border: '0.5px solid rgba(220, 220, 220, 0.2)',
      borderRadius: '8px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '10px',
      color: Colors.commons.gray9,
    },
    inputColor: {
      color: Colors.commons.gray9,
    },
    semMargem: {
      marginTop: '0px !important',
    },
    scrollContainerModelos: {
      width: '100%',
      flex: 1,
      height: '100%',
      overflow: 'auto'
    },
    notFoundContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80%',
      margin: 10,
    },
    imageMini: {
      position: "relative",
      borderRadius: "30px",
    },

}

export default style; 
