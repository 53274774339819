import Api from '../config/api'

export const findAllNotificacao = async variables => {
  const response = await Api.post('', {
    query: `
    query findAllNotificacao($visualizado: Boolean, $dataHoraCriacaoInicio: LocalDateTime, $tipoNotificacao: [TipoNotificacao], $dataHoraCriacaoFim: LocalDateTime, $pageableDTO: PageableDTOInput, $descricao: String) {
      findAllNotificacao(visualizado: $visualizado, dataHoraCriacaoInicio: $dataHoraCriacaoInicio, tipoNotificacao: $tipoNotificacao, dataHoraCriacaoFim: $dataHoraCriacaoFim, pageableDTO: $pageableDTO, descricao: $descricao) {
        last
        totalElements
        numberOfElements
        content {
          agendamento {
            id
            data
            horaInicio
            horaFim
            profissionalSaude {
              id
              nome
              usuario {
                fotoPerfil
              }
            }
          }
          dataHoraCriacao
          descricao
          id
          tipoNotificacao
          unidade {
            id
          }
          visualizado
        }
      }
    }
    `,
    variables,
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.findAllNotificacao
}

export const visualizarNotificacao = async variables => {
  const response = await Api.post('', {
    query: `
    mutation visualizarNotificacao ($notificacaoId: UUID) {
      visualizarNotificacao (notificacaoId: $notificacaoId) {
        id
      }
    }
    `,
    variables,
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.visualizarNotificacoes
}

export const marcarTodasNotificacoesLida = async variables => {
  const response = await Api.post('', {
    query: `
    mutation visualizarTodasNotificacoes {
      visualizarTodasNotificacoes
    }
    `,
    variables,
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.marcarTodasNotificacoesLida
}

export const countUnreadNotifications = async () => {
  const response = await Api.post('', {
    query: `
    query countNotificacoes($visualizado: Boolean) {
      countNotificacoes(visualizado: $visualizado)
    }
    `,
    variables: {
      visualizado: false,
    },
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.countNotificacoes
}
