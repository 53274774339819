import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const GuiaIcon = (props) => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
    <path d="M13.1291 0.12424C13.5082 0.119214 13.8252 0.396769 13.8796 0.761609L13.8878 0.863129L13.8971 1.61095L14.6451 1.6019C15.8856 1.58588 16.9045 2.57801 16.9214 3.81861L17.0579 14.0525L13.3097 14.1022L13.1559 14.1094C12.0411 14.1997 11.1606 15.1017 11.0965 16.2176L11.093 16.3784L11.1427 20.1271L2.91416 20.2362C1.67366 20.2524 0.654689 19.2602 0.637917 18.0196L0.45125 4.03724C0.434477 2.79652 1.42683 1.77713 2.66756 1.76068L3.41372 1.74993L3.40437 1.00211C3.39889 0.588549 3.7297 0.248848 4.14326 0.243365C4.52236 0.23834 4.83939 0.515895 4.89382 0.880735L4.902 0.982254L4.91135 1.73008L7.90861 1.69034L7.8992 0.94252C7.89372 0.52896 8.22453 0.189259 8.63809 0.183777C9.01719 0.178751 9.33423 0.456306 9.38865 0.821146L9.39684 0.922666L9.40625 1.67049L12.3995 1.63081L12.3902 0.882983C12.3847 0.469423 12.7155 0.129722 13.1291 0.12424ZM16.6384 15.556L12.6336 19.6684L12.5906 16.3586L12.5961 16.2569C12.6368 15.924 12.8963 15.6575 13.228 15.608L13.3295 15.5998L16.6384 15.556ZM8.32067 14.1683L4.32875 14.2213L4.22723 14.2294C3.86239 14.2839 3.58483 14.6009 3.58986 14.98C3.59488 15.3591 3.88075 15.6687 4.2469 15.7134L4.3486 15.7189L8.34053 15.666L8.44205 15.6578C8.80689 15.6034 9.08444 15.2863 9.07942 14.9072C9.07439 14.5281 8.78853 14.2186 8.42237 14.1738L8.32067 14.1683ZM13.2598 10.1085L4.2758 10.2276L4.17428 10.2358C3.80944 10.2902 3.53189 10.6072 3.53691 10.9863C3.54194 11.3654 3.8278 11.675 4.19396 11.7197L4.29566 11.7252L13.2797 11.6061L13.3812 11.5979C13.746 11.5435 14.0236 11.2265 14.0186 10.8474C14.0136 10.4683 13.7277 10.1587 13.3615 10.114L13.2598 10.1085ZM13.2069 6.11479L4.22286 6.23389L4.12134 6.24207C3.7565 6.2965 3.47894 6.61354 3.48397 6.99263C3.48899 7.37173 3.77486 7.6813 4.14101 7.72604L4.24271 7.73152L13.2267 7.61242L13.3283 7.60424C13.6931 7.54981 13.9707 7.23277 13.9656 6.85368C13.9606 6.47458 13.6747 6.16502 13.3086 6.12028L13.2069 6.11479Z" fill="currentColor"/>
    </svg>
  );
};



export default GuiaIcon;
