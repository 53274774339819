import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { findByIdConfiguracaoHorarioAtendimento } from '../../../../services/ConfiguracaoHorarioAtendimentoService';

import { styles } from './styles';
import Table from '../../../../components/Table/Table';

import { getProcedimentosTableHeader } from '../utils/getProcedimentosTableHeader';
import { generateTimeList } from '../../../../utils/generateTimeList';
import { convertToObject } from '../utils/convertToObject';
import Scroll from '../../../../components/InfiniteScroll/Scroll';
import { defaultProcedimentosModalData } from '../constants/constants';
import ConfigProcedimentosModal from '../components/ConfigProcedimentosModal';

const ProcedimentosTab = ({ classes, configuracaoHorarioAtendimentoId }) => {
  const [currentConfigVigente, setCurrentConfigVigente] = useState(null);
  const [configVigentes, setConfigVigentes] = useState([]);
  const [modalProcedimentosData, setModalProcedimentosData] = useState(defaultProcedimentosModalData);
  const [horarios, setHorarios] = useState([]);

  const loadConfigVigentes = async () => {
    try {
      const response  = await findByIdConfiguracaoHorarioAtendimento({ id: configuracaoHorarioAtendimentoId });
      const { 
        duracao, 
        horaInicioPeriodoAtendimento, 
        horaFimPeriodoAtendimento, 
        horariosAtendimento 
      } = response;
      setCurrentConfigVigente(response);
      onFinishLoading({ duracao, horaInicioPeriodoAtendimento, horaFimPeriodoAtendimento, horariosAtendimento });
    } catch(error) {
      console.error(error);
    }
  }

  const onFinishLoading = ({ duracao, horaInicioPeriodoAtendimento, horaFimPeriodoAtendimento, horariosAtendimento }) => {
    const timeList = generateTimeList(horaInicioPeriodoAtendimento, horaFimPeriodoAtendimento, Number(duracao));
    setHorarios(timeList);
    if (timeList?.length > 0) {
      mapHorariosAtendimento(timeList, horariosAtendimento);
    }
  };

  const mapHorariosAtendimento = (timeList, horariosAtendimento) => {
    const configByTime = [];
    timeList.forEach(time => {
      const configfiltered = horariosAtendimento.filter((config) => {
        return config.horaInicio === time;
      });
      configByTime.push({
        ...convertToObject(configfiltered),
        horaInicio: time,
      });
    })
    setConfigVigentes(configByTime);
  }

  const handleCellClick = (item) => {
    setModalProcedimentosData({
      ...modalProcedimentosData,
      ...item,
      isOpen: true,
    });
  }

  const handleCloseModal = async ({ isReloadingConfig }) => {
    setModalProcedimentosData(defaultProcedimentosModalData)
    if (isReloadingConfig)
    await loadConfigVigentes();
  }

  useEffect(() => {
    if (configuracaoHorarioAtendimentoId)
    loadConfigVigentes();
  }, []);

  return (
    <div className={classes.root}>
      <Scroll>
        <Table
          columns={getProcedimentosTableHeader({ onClick: handleCellClick })}
          dados={configVigentes || []}
        />
      </Scroll>
      <ConfigProcedimentosModal
        {...modalProcedimentosData}
        onClose={handleCloseModal}
        horarios={horarios}
        currentConfigVigente={currentConfigVigente}
      />
    </div>
  );  
};

export default withStyles(styles)(ProcedimentosTab);
