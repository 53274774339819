import React from "react";
import classNames from "classnames";
import { inject } from "mobx-react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { labelTipoDenteDeciduo, labelTipoDentePermanente } from "../Constants";

const styleBorder = "1px solid #F9BE73";

const DivisorSuperior = styled.div`
  height: 17px;
  border-top: ${(props) =>
    props.border.some((item) => item === "top") ? styleBorder : "none"};
  border-right: ${(props) =>
    props.border.some((item) => item === "right") ? styleBorder : "none"};
  border-left: ${(props) =>
    props.border.some((item) => item === "left") ? styleBorder : "none"};
  margin-right: ${(props) => (props.marginRight ? "8px" : "0")};
`;

const DivisorTipoDente = observer(({ classes, index, odontogramaStore }) => {
  const { tabVisualizacaoSelected } = odontogramaStore;
  const isPermanente = tabVisualizacaoSelected === 0;
  const label = isPermanente ? labelTipoDentePermanente[index] : labelTipoDenteDeciduo[index];
  const { show, nome, border, marginRight } = label;

  return (
    <>
      <div
        className={classNames(
          classes.labelDivisorTipo,
          (nome === "Pré-molar" || nome === "Incisivos" || (!isPermanente && nome === "Molar")) &&
            classes.labelTipoCentro
        )}
      >
        {show && nome}
      </div>
      <DivisorSuperior border={border} marginRight={marginRight} />
    </>
  );
});

const styles = {
  labelDivisorTipo: {
    color: "#F5F5F5",
    fontWeight: 500,
    fontSize: "10px",
    height: "15px",
  },
  labelTipoCentro: {
    whiteSpace: "nowrap",
    display: "flex",
    marginLeft: "30%",
  },
};

const DivisorTipoDenteWithStyles = withStyles(styles)(DivisorTipoDente);
export default inject("odontogramaStore")(DivisorTipoDenteWithStyles);
