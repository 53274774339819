import React from "react";
import { inject, observer } from "mobx-react";
import ModalFinanceiro from "../../ModalFinanceiro";
import InformacoesCategoria from "./InformacoesCategoria";
import { findAllCategoriaFinanceiraAuditByCategoriaFinanceiraId } from "../../../../services/AuditoriaService";

const tabs = {
  INFORMACOES: 0,
  AUDITORIA: 1,
};

@inject("categoriaFinanceiraStore", "auditoriaStore")
@observer
class CategoriaModal extends React.Component {
  state = {
    tabSelected: tabs.INFORMACOES,
  };

  componentDidMount() {
    const { auditoriaStore } = this.props;

    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      auditoria: "a categoria financeira",
      auditoriaTela: "categoriaFinanceira",
    };
  }

  handleChange = (field, event) => {
    const { categoriaFinanceiraStore } = this.props;

    if (field === "categoriaFinanceiraPai") {
      categoriaFinanceiraStore.objView[field] = event;
      return;
    }

    if (field === "tipo") {
      categoriaFinanceiraStore.objView.categoriaFinanceiraPai = null;
      categoriaFinanceiraStore.objView[field] = event;
      return;
    }

    categoriaFinanceiraStore.objView[field] = event.target.value;
  };

  handleClose = () => {
    const { onClose, categoriaFinanceiraStore, auditoriaStore } = this.props;

    categoriaFinanceiraStore.closeModal();

    if (typeof onClose === "function") {
      onClose();
      auditoriaStore.reset();
    }
  };

  handleSave = async (e) => {
    try {
      e.preventDefault();

      const { categoriaFinanceiraStore } = this.props;

      const categoriaFinanceira = {
        ...categoriaFinanceiraStore.objView,
      };

      await categoriaFinanceiraStore.save(categoriaFinanceira);
    } catch (error) {
      console.warn(error && error.message);
    }
  };

  changeTabSelected = async (tabSelected) => {
    const { auditoriaStore, categoriaFinanceiraStore } = this.props;

    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    };

    if (tabSelected === 1) {
      auditoriaStore.load = {
        query: findAllCategoriaFinanceiraAuditByCategoriaFinanceiraId,
        variableName: "categoriaFinanceiraId",
        variableId: categoriaFinanceiraStore.objView.id,
      };
      await auditoriaStore.loadAuditItems();
    }

    this.setState({ tabSelected });
  };

  handleStatus = async() => {
    const { categoriaFinanceiraStore } = this.props;
    const { objView } = categoriaFinanceiraStore;

    categoriaFinanceiraStore.objView.ativo = !objView.ativo
  };

  render() {
    const { open, categoriaFinanceiraStore } = this.props;
    const { objView, saving, opening } = categoriaFinanceiraStore;
    const { tabSelected } = this.state;

    const action = objView?.id ? "Editar" : "Nova";
    const modalTitle = objView?.id ? "Categoria" : "Nova categoria financeira"

    return (
      <>
        <ModalFinanceiro
          opening={opening}
          status={{
            ativo: objView.ativo,
            handleStatus: this.handleStatus,
            show: !!objView?.id,
          }}
          tabsProps={{
            options: ["Informações", "Auditoria"],
            tabSelected: tabSelected,
            changeTabSelected: this.changeTabSelected,
            disabled: !objView?.id,
            posicaoDesabilitada: [1],
          }}
          open={open}
          pageTitle={`Financeiro - ${action} categoria financeira`}
          handleClose={this.handleClose}
          modalTitle={modalTitle}
          handleSave={this.handleSave}
          loadingSave={saving}
          componentInformacoes={
            <InformacoesCategoria
              handleChange={this.handleChange}
              isDisabled={objView?.id}
            />
          }
        />
      </>
    );
  }
}

export default CategoriaModal;
