import React from "react";
import { inject, observer } from "mobx-react";
import { findAllContaBancariaAuditByContaBancariaId } from "../../../services/AuditoriaService";
import ModalFinanceiro from "../ModalFinanceiro";
import InformacoesContaBancaria from "./InformacoesContaBancaria";

const tabs = {
  INFORMACOES: 0,
  AUDITORIA: 1,
};

@inject("contaBancariaStore", "auditoriaStore")
@observer
class ContaBancariaModal extends React.Component {
  state = {
    tabSelected: tabs.INFORMACOES,
  };

  componentDidMount() {
    const { auditoriaStore } = this.props;

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      auditoria: "a conta bancária",
      auditoriaTela: "contaBancaria",
    }
  };

  handleChange = (field, event) => {
    const { contaBancariaStore } = this.props;

    if (field === "banco") {
      contaBancariaStore.objView[field] = event;
      return;
    }

    contaBancariaStore.objView[field] = event.target.value;
  };

  handleClose = () => {
    const { onClose, contaBancariaStore, auditoriaStore } = this.props;
    this.props.disableSaldoInicial(true);
    contaBancariaStore.closeModal();

    if (typeof onClose === "function") {
      onClose();
      auditoriaStore.reset();
    }
  };

  contemErros = () => {
    const { contaBancariaStore } = this.props;
    const { objView } = contaBancariaStore;

    if (!objView.nome || !objView.banco || !objView.saldoInicial) {
      return true;
    }
  };

  handleSave = async (e) => {
    try {
      e.preventDefault();

      const { contaBancariaStore, auditoriaStore } = this.props;

      if (this.contemErros()) {
        auditoriaStore.notification = {
          isOpen: true,
          message: "Por favor, preencha todos os campos.",
        };
        auditoriaStore.showAlertMessage(notification);
      }

      const contaBancaria = {
        ...contaBancariaStore.objView,
      };

      await contaBancariaStore.save(contaBancaria);
      contaBancariaStore.closeModal();
      contaBancariaStore.contaBancariaList = [];
      contaBancariaStore.searchDTO.pageNumber = 0;
      contaBancariaStore.findAllContaBancaria(true);
    } catch (error) {
      console.warn(error && error.message);
    }
  };

  handleBancoLoadOptions = async (search, loadedOptions, { page }) => {
    const { contaBancariaStore } = this.props;

    const response = await contaBancariaStore.findAllBanco({
      pageNumber: page,
      search,
    });

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: page + 1,
      },
    };
  };

  changeTabSelected = async (tabSelected) => {
    const { contaBancariaStore, auditoriaStore } = this.props;

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    }

    if (tabSelected === 1) {
      auditoriaStore.load={
        query: findAllContaBancariaAuditByContaBancariaId, 
        variableName: "contaBancariaId",
        variableId: contaBancariaStore.objView.id, 
      }
      await auditoriaStore.loadAuditItems();
    }

    this.setState({ tabSelected });
  };

  render() {
    const { open, contaBancariaStore, isDisabled } = this.props;
    const { objView, saving, opening } = contaBancariaStore;
    const {
      tabSelected,
    } = this.state;

    const pageTitle = objView?.id
      ? "Financeiro - Editar conta bancária"
      : "Financeiro - Nova conta bancária"

    return (
      <ModalFinanceiro
        opening={opening}
        tabsProps={{
          options: ["Informações", "Auditoria"],
          tabSelected: tabSelected,
          changeTabSelected: this.changeTabSelected,
          disabled: !objView?.id,
          posicaoDesabilitada: [1],
        }}
        open={open}
        pageTitle={pageTitle}
        handleClose={this.handleClose}
        modalTitle="Conta bancária"
        handleSave={this.handleSave}
        loadingSave={saving}
        componentInformacoes={
          <InformacoesContaBancaria
            handleChange={this.handleChange}
            isDisabled={isDisabled}
          />
        }
      />
    );
  }
}

export default ContaBancariaModal;
