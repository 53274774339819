
import { action, observable } from 'mobx';
import localStorageService, { PROFISSIONAL_SAUDE_LOGADO_KEY } from '../services/storage';

export default class GoogleAnalyticsStore {
    @observable profissionalSaude = '';

    @observable tempoInicioAgendamento = 0;

    @action async pageView(paginaVisualizada) {
        const profissionalSaudeAtual = await localStorageService.get(PROFISSIONAL_SAUDE_LOGADO_KEY);
        this.profissionalSaude = profissionalSaudeAtual?.possuiAgenda ? 'medico' : 'secretario';
        ga('set', 'userId', profissionalSaudeAtual?.id);
        ga('set', 'dimension1', this.profissionalSaude);
        ga('set', 'dimension2', profissionalSaudeAtual?.id);
        ga(this.profissionalSaude + '.set', paginaVisualizada);
        ga(this.profissionalSaude + '.send', 'pageview', );
    }
}