const styles = theme => ({
  root: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: ` 
      "header" 
      "content"
    `,
    flex: 1,
    overflow: "hidden",
    maxHeight: "100%;",
  },

  header: {
    gridArea: "header"
  },

  container: {
    gridArea: "content",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: ` 
      "search" 
      "lista"
    `,
    overflow: "hidden",
    position: 'relative',
    "& thead th": {
      textAlign: "left",
      top: 0,
      position: "sticky",
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '24px 24px 0 24px',
    backgroundColor: '#F5F5F5',
    gap: '16px'
  },

  search: {
    gridArea: "search",
    marginBottom: 20,
    marginLeft: 27.5,
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '16px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF'
  },

  lista: {
    gridArea: "lista",
    marginBottom: 20,
    overflow: "hidden",
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1
  },

  scrollContainer: {
    height: '100%'
  },

  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",

    "& h3": {
      color: theme.palette.primary.main,
      fontWeight: "normal",
      fontSize: "1rem"
    }
  },
  contentLoadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: '16px',
  }
});

export default styles;
