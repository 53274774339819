import React from 'react'
import { withStyles } from '@material-ui/core'
import { formatDate } from '../../utils/formatDate'

const ListGuias = props => {
  const { classes, guiaSelected } = props

  const listGuias = guiaSelected?.guias.length ? guiaSelected?.guias : guiaSelected.guiasConsulta

  return (
    <div className={classes.container}>
      <div className={classes.wrapperTable}>
        <h1>Guias incluídas no lote:</h1>
        <div className={classes.containerTable}>
          <table className={classes.table}>
            <tbody>
              {listGuias?.map((guia, index) => (
                <tr key={index}>
                  <td>{guia?.numeroGuiaPrestador}</td>
                  <td>{guia?.beneficiario?.nome}</td>
                  <td>{guia?.convenio?.descricao}</td>
                  <td>[{formatDate(guia?.dataAtendimento || guia?.solicitacao?.data)}]</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    minHeight: '400px',
    maxHeight: '400px',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#F9F9F9',
    borderRadius: '8px',
    padding: '1rem',
  },
  wrapperTable: {
    width: '100%',
    textAlign: 'center',
  },
  containerTable: {
    width: '100%',
    height: 'calc( 100% - 32px )',
    maxHeight: 'calc( 100vh - 64px - 48px )',
    overflowY: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
  },
  table: {
    width: '100%',

    '&>tbody>tr': {
      display: 'grid',
      paddingInline: '0.5rem',
      gridTemplateRows: '1fr',
      textAlign: 'start',
      gridTemplateColumns: '0.5fr 3fr 1fr 1fr',
      padding: '0.5rem',

      '&>td': {
        color: '#505050',
        fontSize: '1rem',
        fontWeight: '600',

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },

      '&>td:last-child': {
        textAlign: 'end',
      },
    },
  },
}

export default withStyles(styles)(ListGuias)
