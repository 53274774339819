import React from 'react'
import { withStyles } from "@material-ui/core/styles/index";


class CardDadosCadastrais extends React.Component {

    render(){
        const { classes, titulo, label, subtitle } = this.props;
        return (
            <div className={classes.card}>
                <div className={classes.titulo}>
                    <div className={classes.textArea}>
                        {titulo}
                        {subtitle ? <span className={classes.subtitle}>{subtitle}</span> : null}
                    </div>
                    {label}
                </div>
                <div className={classes.conteudo}>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

const styles = () => ({
    card: {
        borderRadius: 8,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        background:'white',
        padding: '16px',
        overflow: 'visible',
        height: 'calc(100% - 32px)',
        minWidth: '330px',
    },
    
    titulo:{
        display: 'flex',
        borderTopLeftRadius:10,
        borderTopRightRadius:10,
        marginBottom: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    textArea: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 16,
        fontFamily: 'Poppins !important',
        color: '#505050',
        fontWeight: 700,
        alignItems: 'center',
        columnGap: '4px'
    },

    subtitle: {
        fontWeight: '400'
    },

    conteudo:{
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        borderBottomLeftRadius:10,
        borderBottomRightRadius:10,
        fontFamily: 'Poppins !important',
        gap: '8px',
    },

})


export default withStyles(styles)(CardDadosCadastrais)