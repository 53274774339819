import React from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

const CardSolicitacao = ({ classes, options, onClick, id, isSelected }) => {
  return (
    <div className={classNames(classes.wrapperCard, isSelected && classes.wrapperCardSelected)}>
      {options.map((option, index) => {
        return (
          <div className={classes.item} key={index} onClick={() => onClick(id)}>
            <span className={classNames(classes.label, isSelected && classes.labelSelected)}>
              {option.label}
            </span>
            <span className={classes.value}>{option.value}</span>
          </div>
        )
      })}
    </div>
  )
}

const styles = () => ({
  wrapperCard: {
    width: '100%',
    height: '100px',
    background: '#fff',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
    borderRadius: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    margin: '12px 0',
    cursor: 'pointer',
  },
  wrapperCardSelected: {
    background: '#F2F2F2',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 10px',
  },
  label: {
    color: '#868686',
    fontSize: '12px',
    fontWeight: '700',
  },
  labelSelected: {
    color: '#000000',
  },
  value: {
    color: '#505050',
    fontSize: '14px',
    fontWeight: '700',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export default withStyles(styles)(CardSolicitacao)
