import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import styles from "./CategoriaFilhoModalStyles";
import { InputForm } from "../../../../components/Modal/Input";

const InformacoesCategoriaFilho = observer(
  ({ classes, categoriaFinanceiraStore }) => {
    const { modalCategoriaFilho } = categoriaFinanceiraStore;
    const { objView } = modalCategoriaFilho;

    const handleChangeNome = (e) => {
      const value = e.target.value;
      categoriaFinanceiraStore.modalCategoriaFilho.objView.nome = value;
    };

    return (
      <div>
        <div>
          <div className={classes.titleField}> Nome </div>
          <InputForm
            value={objView.nome}
            onChange={handleChangeNome}
            className={classes.widthMaximo}
            classes={{
              input: classes.inputRetangular,
            }}
          />
        </div>
      </div>
    );
  }
);

const InformacoesCategoriaFilhoWithStyles = withStyles(styles)(
  InformacoesCategoriaFilho
);
export default inject("categoriaFinanceiraStore")(
  InformacoesCategoriaFilhoWithStyles
);
