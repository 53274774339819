import Api from '../config/api'

export const conectarTotem = async (totemCode) => {
  const response = await Api.post("", {
    query: `
      mutation conectarTotemByCode($code: String) {
        conectarTotemByCode(code: $code) {
          id
        }
      }
    `,
    variables: {
      code: totemCode
    }
  })

  if (response.data.errors) {
    throw Error("Falha ao conectar o totem");
  }

  return response.data.data.conectarTotemByCode
}
