import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Rating } from 'react-simple-star-rating';
import { buildUrlFotoPerfil } from '../../../../config/config';
import { inject } from 'mobx-react';
import CollapseButton from '../../../../components/Button/CollapseButton';
import moment from 'moment';
import CommentRatingsButton from './CommentRatingsButton';
import { observer } from 'mobx-react-lite';
const profileDefault = require("../../../../assets/img/svg/img-profile-default.svg");

const styles = ({
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '8px',
    width: '100%',
    flex: 1,
    marginBottom: '8px'
  },
  profilePic: {
    height: '60px',
    width: '60px',
    borderRadius: '100%'
  },
  profileData: {
    marginBottom: '8px'
  },
  textData: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '3px',
    alignItems: 'center'
  },
  name: {
    fontSize: '14px !important',
    color: '#000000',
    fontWeight: '600 !important'
  },
  date: {
    fontSize: '10px !important',
    color: '#656565',
    fontWeight: '400 !important'
  },
  commentText: {
    fontSize: '12px !important',
    color: '#333333',
    fontWeight: '400 !important'
  },
  collapseAndExpandButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '24px 0',
    columnGap: '80px'
  },
  emptyComments: {
    display: 'flex',
    height: '156px',
    alignItems: 'center',
    textAlign: 'center'
  },
  emptyCommentsText: {
    flex: 1,
    fontSize: '20px !important',
    fontWeight: '600'
  }
});

const initialValue = 3;
const stepSize = 10;

const Comments = observer(({ classes, perfilPublicoStore }) => {
  const { perfilPublicoProfissionalLogadoAvaliacoes } = perfilPublicoStore;
  const commentsLength = perfilPublicoProfissionalLogadoAvaliacoes.length;
  const [totalVisible, setTotalVisible] = useState(initialValue);
  const [loadingThumb, setLoadingThumb] = useState(false);

  const handleClickThumbButton = async (avaliacaoId, newValue) => {
    setLoadingThumb(true);
    await perfilPublicoStore.updateAvaliacao(avaliacaoId, newValue);
    setLoadingThumb(false);
  };

  const getCommentsToRender = () => {
    if (totalVisible > initialValue) {
      return perfilPublicoProfissionalLogadoAvaliacoes.slice(0, totalVisible);
    } else {
      const inititalQuantity = initialValue < commentsLength ? initialValue : commentsLength;
      return perfilPublicoProfissionalLogadoAvaliacoes.slice(0, inititalQuantity);
    }
  }

  const commentsToRender = getCommentsToRender();

  const renderComments = () => {
    return (
      <>
        {commentsToRender.map((comment, index) => (
          <div className={classes.commentContainer} key={index}>
            <div className={classes.profileContainer}>
              <div>
                <img className={classes.profilePic} 
                  src={
                    comment?.avaliador?.fotoPerfil && perfilPublicoStore.accessToken ?
                      buildUrlFotoPerfil(comment.avaliador.fotoPerfil, perfilPublicoStore.accessToken) :
                      profileDefault
                  }
                  alt="Foto perfil do Avaliador" 
                />
              </div>
              <div className={classes.profileData}>
                <div className={classes.textData}>
                  <span className={classes.name}>{comment.name || comment.avaliador?.nome}</span>
                  {comment?.agendamento?.data && (
                    <span className={classes.date}>{`(${moment(comment?.agendamento?.data).format('DD/MM/YYYY')})`}</span>
                  )}
                </div>
                <Rating 
                  emptyColor='#BDBDBD' 
                  fillColor='#FFB905' 
                  initialValue={comment.nota} 
                  disableFillHover 
                  size={16}
                  allowFraction
                  readonly
                />
              </div>
              <CommentRatingsButton 
                liked={comment.gostou} 
                handleClick={handleClickThumbButton}
                avaliacaoId={comment.id}
                loading={loadingThumb}
              />
            </div>
            <span className={classes.commentText}>
              {comment.descricao}
            </span>
          </div>
        ))}
      </>
    )
  };

  const incrementTotalVisible = (commentsLength) => {
    const totalVisibleIncremented = totalVisible + stepSize;
    setTotalVisible(totalVisibleIncremented < commentsLength ? totalVisibleIncremented : commentsLength);
  }

  const showMore = async () => {
    const commentsLength = perfilPublicoProfissionalLogadoAvaliacoes.length;
    if (totalVisible < commentsLength) {
      incrementTotalVisible(commentsLength);
      return;
    }

    if (totalVisible >= commentsLength && !perfilPublicoStore.lastAvaliacaoPage) {
      await loadMoreAvaliacoes();
    }
  }

  const loadMoreAvaliacoes = async () => {
    perfilPublicoStore.avaliacaoPageable = {
      ...perfilPublicoStore.avaliacaoPageable,
      pageNumber: perfilPublicoStore.avaliacaoPageable.pageNumber + 1
    };
    await perfilPublicoStore.findAllAvaliacaoProfissionalLogado();
    const commentsLength = perfilPublicoStore.perfilPublicoProfissionalLogadoAvaliacoes.length;
    incrementTotalVisible(commentsLength);
  };

  return (
    <div className={classes.commentsContainer}>
      {perfilPublicoProfissionalLogadoAvaliacoes && perfilPublicoProfissionalLogadoAvaliacoes.length > 0 && (
        <>
          {renderComments()}
          <div className={classes.collapseAndExpandButtons}>
            {(
                totalVisible < perfilPublicoProfissionalLogadoAvaliacoes.length || 
                (totalVisible >= perfilPublicoProfissionalLogadoAvaliacoes.length && !perfilPublicoStore.lastAvaliacaoPage)
              ) &&
              <CollapseButton
                type="expand"
                onClick={() => showMore()}
              />
            }
            { totalVisible > initialValue &&
              <CollapseButton
                type="collapse"
                onClick={() => setTotalVisible(initialValue)}
              />
            }
          </div>
        </>
      )}
      {perfilPublicoProfissionalLogadoAvaliacoes.length === 0 && (
        <div className={classes.emptyComments}>
          <span className={classes.emptyCommentsText}>Você ainda não possui avaliações</span>
        </div> 
      )}
    </div>
  )
});

const CommentsStyles = withStyles(styles)(Comments); 
export default inject("perfilPublicoStore")(CommentsStyles);
