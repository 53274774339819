import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const FormHeading = ({ label }) => (
    <View style={styles.formHeading}>
        <Text style={styles.label}>{label}</Text>
    </View>
);

const styles = StyleSheet.create({
    formHeading: {
        paddingHorizontal: 2,
        paddingVertical: 1,
        backgroundColor: '#ddd',
        marginBottom: 1,
        marginHorizontal: 2
    },
    label: {
        fontSize: 6,
        fontFamily: "Lato-Bold",
    },
});

export default FormHeading;
