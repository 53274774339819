import React from 'react';
import { withStyles } from '@material-ui/core';
import RectLabel from '../Labels/RectLabel';


const styles = ({
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
    padding: '2px',
    borderRadius: '100px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tab: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    borderRadius: '100px',
  },
  selectedTab: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    height: '100%'
  },
  tabLabel: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
  },
  disableLabel: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#000',
    opacity: '0.5'
  },
  tagEmBreveContainer: {
    position: 'relative',
    top: '-18px',
    left: '40%'
  },
  roundedTag: {
    backgroundColor: 'rgb(84, 98, 224)',
    width: '16px',
    height: '16px',
    padding: '2px',
    margin: '6px',
    borderRadius: '16px',
    border: '1px solid rgba(0,0,0,0.1)'
  }
});

const TabSelector = ({ 
  classes, 
  tabsOptions, 
  selectedColor, 
  baseColor,
  onSelect,
  selectedTabIndex,
  borderRadius,
}) => {

  const renderTabs = () => {
    return (
      <>
        {tabsOptions.map((tab, index) => (
          <div 
            key={index} 
            className={index === selectedTabIndex ? classes.selectedTab : classes.tab}
            style={{
              backgroundColor: index === selectedTabIndex ? selectedColor : baseColor,
              color: index === selectedTabIndex ? "#000" : "#868686",
              borderRadius: borderRadius || '100px',
            }}
            onClick={() => !tab?.isDisabled && onSelect(index)}
          >
            {tab.tagEmBreve &&
              <div className={classes.tagEmBreveContainer}>
                <RectLabel color={tab.tagColor} text="Em breve"/>
              </div>
            }
            <span className={tab?.isDisabled ? classes.disableLabel : classes.tabLabel}>
              {tab.label}
            </span>
            {tab.roundedTag && (
              <RectLabel color={tab.tagColor} text={tab.tagText} areaStyle={styles.roundedTag} />
            )}
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={classes.tabs} 
      style={{
        backgroundColor: baseColor,
        borderRadius: borderRadius || '100px'
      }}
    >
      {renderTabs()}
    </div>
  )
};

export default withStyles(styles)(TabSelector);
