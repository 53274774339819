export const verificaCamposVazioStepAtendimento = (guiaConsulta) => {
    const fieldsToValidate = [
        'dataAtendimento',
        'agendamentoTipo',
        'indicacaoAcidente',
        'valorProcedimento',
    ];
    return fieldsToValidate.some(field => !guiaConsulta[field]);
};

export const verificaCamposVazioStepPaciente = (guiaConsulta) => {
    const { beneficiario, convenio } = guiaConsulta || {};
    if(!beneficiario || !convenio) return true;
    const fieldsBenefeciarioToValidate = ['nome', 'numeroCarteira', 'dataValidadeCarteira'];
    const beneficiarioIsInvalid = fieldsBenefeciarioToValidate.some(
        field => !beneficiario[field]
    )
    const beneficiarioDataValidadeCarteiraIsInvalid = beneficiario.dataValidadeCarteira === "Data inválida";
    return beneficiarioIsInvalid || beneficiarioDataValidadeCarteiraIsInvalid;
};

export const verificaCamposVazioStepProfissionalSaude = (guiaConsulta) => {
    const fieldsToValidate = [
        'profissionalSaude',
        'nomeProfissional',
        'numeroConselhoProfissional',
        'conselhoProfissional',
        'uf',
        'cbo',
    ];
    return fieldsToValidate.some(field => !guiaConsulta?.[field]);
};
