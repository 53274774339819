import React from 'react'
import { withStyles } from '@material-ui/core'

import { SujeitoAtencaoInfos, ProfissionalInfos, ActionsHeader, SelectedFields } from './components'

const HeaderProntuario = ({
  classes,
  handleClickFinanceiro,
  handleClickEditSujeitoAtencao,
  onPlayAgendamento,
  onStopAgendamento,
  onCallAgendamento,
  toggleModalSolicitacoes,
  eventoGA,
  handleEditarCamposProntuario,
  isLoaded,
  handleOpenCIDModal,
}) => {
  return (
    <>
      <header className={classes.container}>
        {isLoaded && (
          <>
            <div className={classes.wrapperInfos}>
              <SujeitoAtencaoInfos
                handleClickEdit={handleClickEditSujeitoAtencao}
                handleClickFinanceiro={handleClickFinanceiro}
              />

              <ActionsHeader
                eventoGA={eventoGA}
                onPlayAgendamento={onPlayAgendamento}
                onStopAgendamento={onStopAgendamento}
                onCallAgendamento={onCallAgendamento}
                toggleModalSolicitacoes={toggleModalSolicitacoes}
              />
            </div>
            <ProfissionalInfos />
          </>
        )}
      </header>
      {isLoaded && (
        <SelectedFields
          handleEditarCamposProntuario={handleEditarCamposProntuario}
          handleOpenCIDModal={handleOpenCIDModal}
        />
      )}
    </>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  wrapperInfos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
}

export default withStyles(styles)(HeaderProntuario)
