import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { WhatsApp } from '../Icon';
import CircleCount from '../Labels/CircleCount';

const styles = {
    buttonWhatsAppContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'visible'
    },
    buttonWhatsApp: {
		backgroundColor: '#4390E1',
		display: 'flex',
		borderRadius: '100%',
		cursor: "pointer",
		marginLeft: 5,
        width: '20px',
        minWidth: '20px',
        maxWidth: '20px',
		height: '20px',
		justifyContent: 'center',
        marginRight: '5px',
		"&:hover":{
			backgroundColor: '#4390E1',
		}
	},
    buttonWhatsAppMessageSent: {
        backgroundColor: '#1B33D2',
        "&:hover":{
			backgroundColor: '#1B33D2',
            opacity: 0.8
		}
    },
    whatsAppIcon: {
		color: "#fff",
        width: 12,
        height: 12,
        alignSelf: 'center'
	},
    counterPosition: {
        position: 'absolute',
        right: '-7px',
        top: '-7px',
    }
};

const ButtonWhatsApp = (props) => {
    const {classes, className, messageSent, labelTooltip, withCount, quantidadeEnviado, ...others} = props;
    let buttonClass = classNames(classes.buttonWhatsApp, className && className, messageSent && classes.buttonWhatsAppMessageSent);
    return (
        <div className={buttonClass} {...others}>
        {withCount && quantidadeEnviado > 0 ? (
            <Tooltip title={labelTooltip} placement="bottom">
                <div className={classes.buttonWhatsAppContainer}>
                    <CircleCount
                        value={quantidadeEnviado}
                        color="#26ACAA"
                        textColor="#FFF"
                        fontSize={8}
                        size={14}
                        className={classes.counterPosition}
                    />
                    <WhatsApp className={classes.whatsAppIcon}/>
                </div>
            </Tooltip>
        ) : (
            <WhatsApp className={classes.whatsAppIcon}/>
        )}
        </div>
    )
};


export default withStyles(styles)(ButtonWhatsApp);
