import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const SolicitacoesPacientesIcon = props => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M18.64 3.4438C20.4321 3.42005 21.9042 4.85356 21.9279 6.64566L22.0802 18.1275C22.1039 19.9196 20.6704 21.3916 18.8783 21.4154L7.39645 21.5676C5.60436 21.5914 4.13232 20.1578 4.10856 18.3658L3.95635 6.88391C3.93259 5.09182 5.36611 3.61978 7.1582 3.59602L18.64 3.4438ZM18.6599 4.94144L7.17805 5.09365C6.21308 5.10645 5.44119 5.89908 5.45398 6.86405L5.54332 13.6034L10.0362 13.5438C10.4153 13.5388 10.7324 13.8164 10.7868 14.1812L10.795 14.2827C10.8114 15.5234 11.8305 16.5158 13.0712 16.4994C14.3119 16.4829 15.3043 15.4638 15.2879 14.2232C15.2824 13.8096 15.6132 13.4699 16.0267 13.4644L20.5196 13.4049L20.4303 6.66551C20.4181 5.74879 19.7022 5.00632 18.8033 4.94533L18.6599 4.94144Z" 
        fill="currentColor" />
    </svg>

  );
};

export default SolicitacoesPacientesIcon;