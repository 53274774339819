import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";
import Notification from "../../../components/Notification";
import {
  findAllFinanceiroGuiaServicoLote,
  findByIdFinanceiroGuiaServicoLote,
} from "../../../services/GuiaDeServicosLoteService";
import colors from "../../../template/Colors";
import GuiaDeServicosLoteModal from "./Modal/GuiaDeServicosLoteModal";
import MensagemListaVazia from "../../../components/Mensagem/MensagemListaVazia";
import HeaderFinanceiro from "../../Financeiro/HeaderFinanceiro";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ButtonFloat from '../../../components/ButtonFloat';

const columns = [
  {
    Header: "Número Lote",
    getValue: (guiaServicoLote) => {
      return guiaServicoLote?.numeroLote;
    },
    props: { component: "th" },
  },
];

function GuiaDeServicosLote({ classes }) {
  const [guiaServicoLote, setGuiaServicoLote] = useState([]);
  const [guiaServicoLoteSelected, setGuiaServicoLoteSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "Erro",
    type: "error",
  });
  const [openModal, setOpenModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [search, setSearch] = useState("");
  const [tipoGuia, setTipoGuia] = useState("");

  useEffect(() => {
    tipoGuia && setOpenModal(true);
  }, [tipoGuia]);

  const loadGuiasDeServicoLote = (options) => {
    const pageableDTO = {
      sortDir: "DESC",
      sortField: "numeroLote",
      pageNumber: options?.isSearching ? 0 : pageNumber,
      pageSize: 30,
    };

    const textSearch = options?.isClear ? "" : search;

    const nextPage = pageNumber + 1;

    setIsLoading(true);
    findAllFinanceiroGuiaServicoLote(pageableDTO, textSearch)
      .then(({ data: response }) => {
        const content = response.data.findAllFinanceiroGuiaServicoLote.content;
        const lastPage = response.data.findAllFinanceiroGuiaServicoLote.last;
        setGuiaServicoLote(
          options?.isSearching ? content : [...guiaServicoLote, ...content]
        );
        setLastPage(lastPage);
        setPageNumber(nextPage);
      })
      .catch((error) => {
        openNotification("Erro ao listar lotes das guias de serviços", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setPageNumber(0);
    setSearch(value);
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter" && search.length >= 3) {
      setGuiaServicoLote([]);
      loadGuiasDeServicoLote({ isSearching: true });
    }
  };

  const handleClickClearSearch = () => {
    setSearch("");
    setGuiaServicoLote([]);
    loadGuiasDeServicoLote({ isSearching: true, isClear: true });
  };

  const handleOpenModal = async (id) => {
    setGuiaServicoLoteSelected(null);

    if(id) {
      const response = await findByIdFinanceiroGuiaServicoLote(id);
      setTipoGuia(response.guiasConsulta.length > 0 ? "CONSULTA" : "SERVICO");

      setGuiaServicoLoteSelected(response);
    }

    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openNotification = (message, variant = null) => {
    setNotification((prevState) => ({
      ...prevState,
      open: true,
      type: variant,
      message: message,
    }));
  };

  const closeAlertMessage = () => {
    setNotification((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    loadGuiasDeServicoLote();
  }, []);

  const onClickPesquisar = () => {
    setGuiaServicoLote([]);
    loadGuiasDeServicoLote({ isSearching: true });
  };


  return (
    <div className={classes.container}>
      <PageTitle title="Financeiro - Lote de guias" />
      <HeaderFinanceiro
        onClickButtonSearch={() => onClickPesquisar()}
        handleClickClear={() => handleClickClearSearch()}
        value={search}
        onChange={handleSearchChange}
        onKeyPress={onKeypressSearch}
        withzindex
        title="Lote de guias"
      />

      <div className={classes.wrapper}>
        <div className={classes.tableContainer}>
          {guiaServicoLote.length > 0 && (
            <Scroll
              hasMore={!isLoading && !lastPage}
              pageStart={0}
              initialLoad={false}
              loadMore={() => loadGuiasDeServicoLote()}
            >
              <Table
                dados={guiaServicoLote}
                columns={columns}
                clickable={true}
                handleClick={(id) => handleOpenModal(id)}
              />
            </Scroll>
          )}
          {isLoading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
          {!isLoading && guiaServicoLote.length === 0 && <MensagemListaVazia />}
        </div>
        {openModal && (
          <GuiaDeServicosLoteModal
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            setOpenModal={setOpenModal}
            guiaServicoLoteSelected={guiaServicoLoteSelected}
            loadGuiasDeServicoLote={loadGuiasDeServicoLote}
            openNotification={openNotification}
          />
        )}
        <ButtonFloat onClick={() => handleOpenModal()} />
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.open}
          variant={notification.type}
          message={notification.message}
        />
      </div>
    </div>
  );
}

const styles = () => ({
  container: {
    gridArea: "content",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    position: "relative",
    overflow: "hidden",
  },
  header: {
    height: "5rem",
    background: colors.commons.white,
    display: "flex",
    justifyContent: "space-between",
  },
  profile: {
    textAlign: "end",
  },
  wrapper: {
    paddingTop: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 1,
    overflow: "hidden",
    height: "100%",
    "& thead": {
      position: "sticky",
      top: 0,
    },
    "& th": {
      textAlign: "left",
    },
  },
  floatButtonContainer: {
    position: "absolute",
    bottom: 24,
    right: 37,
  },
  buttonAddGuiaConsulta: {
    backgroundColor: "#00B0AE !important",
    color: "#fff",
  },
  buttonAddGuiaServico: {
    backgroundColor: "#00B0AE !important",
    color: "#fff",
  },
});

export default withStyles(styles)(GuiaDeServicosLote);
