import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ThumbsUpIcon = props => {
  return (
    <svg 
      width="19" 
      height="22" 
      viewBox="0 0 19 22" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M13.0565 8.0041C13.5173 6.57708 13.75 5.32791 13.75 4.25083C13.75 1.85216 12.8109 0.00341797 11.25 0.00341797C10.4032 0.00341797 10.1412 0.507658 9.81325 1.75024C9.83038 1.68531 9.65036 2.3842 9.59767 2.57145C9.49673 2.93019 9.32094 3.54094 9.07065 4.40246C9.06395 4.42554 9.05409 4.44729 9.04126 4.46737L6.17451 8.95303C5.49646 10.014 4.49599 10.8295 3.32011 11.2797L2.06287 11.761C1.2695 12.0648 0.808883 12.8945 0.970598 13.7285L1.65676 17.2672C1.8239 18.1291 2.4772 18.8151 3.33 19.0242L11.5795 21.0461C14.1107 21.6665 16.6691 20.1316 17.313 17.6062L18.8869 11.4335C19.2621 9.96184 18.3733 8.4646 16.9016 8.08935C16.6795 8.03274 16.4513 8.0041 16.2221 8.0041H13.0565Z" 
      fill="currentColor"/>
    </svg>
  );
};

export default ThumbsUpIcon;