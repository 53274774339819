import { ROLE_PROTECTION_CONDITIONS } from "../constants";

export const checkUserRoles = async (roles = [], condition = ROLE_PROTECTION_CONDITIONS.all) => {
    const usuarioLogado = await JSON.parse(
        localStorage[`_immortal|usuarioLogado`] || null
    );

    const checkAuthorities = condition === ROLE_PROTECTION_CONDITIONS.ALL ? "every" : "some";

    return (
        usuarioLogado?.authorities &&
        roles[checkAuthorities]((role) =>
            usuarioLogado?.authorities.some(({ authority }) => authority === role)
        )
    );
};