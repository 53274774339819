import React, { useEffect }  from 'react';
import { withStyles } from '@material-ui/core';
import styles from "../../../assets/jss/pages/profissionalSaudeStyle";
import PageTitle from "../../../components/PageTitle/PageTitle";
import classNames from 'classnames';
import CardDadosCadastrais from '../../../components/Cards/CardDadosCadastrais';
import Report from './components/Report';
import Header from './components/Header';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import Comments from './components/Comments';
import { getViziWebUrl } from '../../../config/config';
import { getWhitelabelNome } from '../../../services/WhitelabelService';

const AvaliacaoVizi = observer(({ classes, perfilPublicoStore }) => {
  const loadAvaliacoes = async () => {
    perfilPublicoStore.resetAvaliacaoFilter();
    await perfilPublicoStore.findAllAvaliacaoProfissionalLogado();
  }

  const getPerceptionsByType = async (type) => {
    const { perfilPublicoProfissionalLogadoAvaliacoes } = perfilPublicoStore;
    const perceptionType = type === 'positive' ? 'Boa' : 'Ruim';
    let perceptions = [];
    
    await perfilPublicoProfissionalLogadoAvaliacoes.forEach(review => {
      const field = `perfilPublicoAvaliacaoExperiencia${perceptionType}`;
      perceptions = [...perceptions, ...review[field]];
    });
    if (type === 'positive') {
      perfilPublicoStore.positivePerceptions = perceptions;
      await perfilPublicoStore.counterPositivePerceptionsByType();
    }
    
    if (type === 'negative') {
      perfilPublicoStore.negativePerceptions = perceptions;
      await perfilPublicoStore.counterNegativePerceptionsByType();
    }
  }

  useEffect(() => {
    if (perfilPublicoStore.perfilPublicoProfissional.id) {
      perfilPublicoStore.perfilPublicoProfissionalLogadoAvaliacoes = [];
      perfilPublicoStore.resetPerceptions();
      loadAvaliacoes();
    }
  }, [perfilPublicoStore.perfilPublicoProfissional.id]);

  useEffect(() => {
    if (perfilPublicoStore.perfilPublicoProfissionalLogadoAvaliacoes.length > 0) {
      getPerceptionsByType('positive');
      getPerceptionsByType('negative');
    }
  }, [perfilPublicoStore.perfilPublicoProfissionalLogadoAvaliacoes]);

  return (
    <div className={classes.tableContainer}>
      <PageTitle title="Avaliações" />
      <div className={classes.header}>
        <h3 className={classNames(classes.titleHeader, classes.headerTitleRoot)}>Avaliações</h3>
        <div className={classes.viziLinkContainer}>
          <span className={classes.viziLinkLabel}>{getWhitelabelNome()}</span>
          <a className={classes.viziLink} target="_blank" href={getViziWebUrl()} rel="noreferrer">ACESSAR PORTAL</a>
        </div>
			</div>
      <div className={classes.avaliacaoPageContainer}>
        <CardDadosCadastrais>
          <Header 
            totalReviews={perfilPublicoStore.perfilPublicoProfissional.avaliacaoQuantidade} 
            score={Number(perfilPublicoStore.perfilPublicoProfissional.avaliacaoNotaMedia)}
          />
          <div className={classes.reportContainer}>
            <Report 
              type="positive" 
              perceptions={perfilPublicoStore.positivePerceptions}
              perceptionsCountersByType={perfilPublicoStore.positivePerceptionsCountersByType}
            />
            <Report 
              type="negative" 
              perceptions={perfilPublicoStore.negativePerceptions}
              perceptionsCountersByType={perfilPublicoStore.negativePerceptionsCountersByType}
            />
          </div>
        </CardDadosCadastrais>
        <CardDadosCadastrais>
          <span className={classes.commentsHeaderTitle}><b>Cometários</b></span>
          <Comments />
        </CardDadosCadastrais>
      </div>
    </div>
  );
});

const AvaliacaoViziStyle = withStyles(styles)(AvaliacaoVizi); 
export default inject("perfilPublicoStore")(AvaliacaoViziStyle);