import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactSelect } from "../../components/Select/SelectSearch";
import CalendarMonth from "../../components/CalendarMonth";

const DashboardFilters = observer(
  ({ classes, dashboardStore, profissionalSaudeStore }) => {
    const [permissaoUnidade, setPermissaoUnidade] = useState(false);

    useEffect(() => {
      verificaPermissao();
    }, []);

    const verificaPermissao = async () => {
      const isPermiteUnidade = await dashboardStore.verificaPermissaoUnidade();

      setPermissaoUnidade(isPermiteUnidade);
    };

    const handleChangeProfisisonalSaude = (profissionalSaude) => {
      const profissionalSaudeId =
        profissionalSaude?.id === "unidade" ? null : profissionalSaude?.id;

      dashboardStore.profissionalSaudeSelected = profissionalSaude;
      dashboardStore.loadDados({
        profissionalSaudeId,
      });
    };

    const handleFilterMes = (dataInicial, dataFinal) => {
        if (typeof dashboardStore.profissionalSaudeSelected?.id === "undefined")
          return;

      dashboardStore.filterDate = {
        dataInicial,
        dataFinal,
      };


      dashboardStore.loadDados({
        profissionalSaudeId:
          dashboardStore.profissionalSaudeSelected?.id === "unidade"
            ? null
            : dashboardStore.profissionalSaudeSelected?.id,
      });
    };

    return (
      <div className={classes.content}>
        <div className={classes.nomeProfissional}>
          {dashboardStore.profissionalSaudeSelected?.nome || ""}
        </div>
        <div className={classes.contentFilters}>
          {permissaoUnidade && (
            <div className={classes.contentSelect}>
              <div className={classes.titleSelect}> Ver dados </div>
              <ReactSelect
                className={classes.reactSelect}
                placeholder={"Selecione"}
                value={dashboardStore.profissionalSaudeSelected}
                onChange={handleChangeProfisisonalSaude}
                options={profissionalSaudeStore.profissionaisSaudeUnidade || []}
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.id}
              />
            </div>
          )}
          <div className={classes.contentFilterMonth}>
            <CalendarMonth
              dataSelected={dashboardStore.filterDate.dataInicial}
              handleFilter={handleFilterMes}
            />
          </div>
        </div>
      </div>
    );
  }
);

const styles = {
  content: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  nomeProfissional: {
    fontSize: "24px",
    color: "#000",
    fontWeight: 600,
  },
  titleSelect: {
    fontSize: "12px",
    color: "#868686",
  },
  reactSelect: {
    width: "100%",
    maxWidth: "200px",
    "&>div>div": {
      borderRadius: "8px",
      height: "40px",
    },
  },
  contentFilters: {
    display: "flex",
    gap: "16px",
    width: "35%",
    justifyContent: "end",
  },
  contentSelect: {
    width: "100%",
  },
  contentFilterMonth: {
    width: "100%",
    maxWidth: "230px",
  },
};

const DashboardFiltersWithStyles = withStyles(styles)(DashboardFilters);
export default inject(
  "dashboardStore",
  "profissionalSaudeStore",
  "usuarioStore"
)(DashboardFiltersWithStyles);
