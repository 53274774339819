import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import colors from '../../template/Colors'
import {theme} from "../../template/Theme";

const RoundButton = (props) => {
    const { classes, children, onClick, color, marginLeft, fullWidth } = props;

    const classFab = classnames(classes.button, {
        [classes.marginLeft]: marginLeft,
        [classes.fullWidth]: fullWidth,
    });

    return (
        <Fab
            className={classFab}
            variant="extended"
            color={color}
            onClick={onClick}
        >
            {children}
        </Fab>
    )
}

const styles = {
    button: {
        boxShadow: 'none',
        background: colors.commons.purple,
        height: 40,
        [theme.breakpoints.down('md')]:{
            padding:0,
            width: 40,
            height: 40,
            minWidth: 40
        },

        '&:hover':{
            background: colors.commons.purpleDark,
        },
        '& svg': {
            marginRight: '10px',
            [theme.breakpoints.down('md')]:{
                marginRight: 0,
            },
        },
        '& span': {
            textTransform: 'capitalize !important',
            fontWeight: 900,
            fontSize: 14,
        }
    },
    marginLeft: {
        marginLeft: '20px',
    },
    fullWidth: {
        width: '100%'
    },
};

RoundButton.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    marginLeft: PropTypes.bool,
    fullWidth: PropTypes.bool,
}

RoundButton.defaultProps = {
    color: 'primary',
    marginLeft: false,
    fullWidth: false,
}

export default withStyles(styles)(RoundButton);
