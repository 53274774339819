import React, { useMemo } from 'react'
import { withStyles, FormControlLabel, Radio } from '@material-ui/core'
import moment from 'moment'

import {
  InputDateForm,
  InputForm,
  InputTimeForm,
} from '../../../../../../../components/Input/InputDadosCadastraisPaciente'
import Checkbox from '../../../../../../../components/Checkbox/Checkbox'
import CustomDateTimePicker from '../../../../../../../components/CustomDateTimePicker'
import { ReactSelect } from '../../../../../../../components/Select/SelectSearch'
import classNames from 'classnames'

import colors from '../../../../../../../template/Colors'

const STYLE_INPUT = {
  width: 'calc(100% - 16px)',
  background: '#F2F2F2',
  color: 'black',
  borderRadius: '8px',
  padding: '0px 8px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}

const AnamneseInput = ({ type, classes, options, label, value, onChange, handleChangeOutro, isSolicitacaoPaciente }) => {
  const INPUT_BY_TYPE = {
    CHECKBOX: ({ value, onChange, handleChangeOutro, label }) => (
      <div className={classes.opcaoOutroComInput}>
        <FormControlLabel
          style={{ marginLeft: 8, marginRight: 0 }}
          control={<Checkbox checked={!!value} style={{ color: 'black' }} onChange={onChange} />}
          label={label === '' ? 'Outro:' : label}
        />
        {label === '' && (
          <InputForm disabled={!value} onChange={handleChangeOutro} multiline style={STYLE_INPUT} />
        )}
      </div>
    ),
    COMBOBOX: ({ value, onChange, options }) => (
      <ReactSelect
        isClearable
        options={options || []}
        getOptionLabel={option => option.nome}
        getOptionValue={option => option.id}
        placeholder={'Selecione'}
        className={classes.comboboxContainer}
        defaultValue={options.find(option => option.valorConvertido)}
        value={value}
        onChange={onChange}
      />
    ),
    DATE: ({ value, onChange }) => (
      <InputDateForm
        value={value}
        onChange={onChange}
        classes={{
          icon: classes.iconTime,
          date: classes.dateTime,
        }}
      />
    ),
    DATETIME: ({ value, onChange }) => (
      <CustomDateTimePicker
        value={value}
        onChange={onChange}
        iconPosition='start'
        style={STYLE_INPUT}
      />
    ),
    INPUT: ({ value, onChange }) => (
      <InputForm value={value} onChange={onChange} multiline style={STYLE_INPUT} />
    ),
    NUMBER: ({ value, onChange }) => (
      <input type='number' value={value} onChange={onChange} className={classes.inputNumber} />
    ),
    RADIO: ({ value, onChange, label, handleChangeOutro }) => (
      <div className={classNames(classes.opcaoOutroComInput, isSolicitacaoPaciente && classes.marginLeft)}>
        <FormControlLabel
          style={{ marginRight: 0 }}
          control={<Radio checked={!!value} onChange={onChange} style={{ color: 'black' }} />}
          label={label === '' ? 'Outro:' : label}
        />
        {label === '' && (
          <InputForm disabled={!value} onChange={handleChangeOutro} multiline style={STYLE_INPUT} />
        )}
      </div>
    ),
    TEXT_AREA: ({ value, onChange }) => (
      <InputForm value={value} onChange={onChange} multiline style={STYLE_INPUT} />
    ),
    TIME: ({ value, onChange }) => (
      <InputTimeForm
        iconposition='start'
        value={moment(value, 'HH:mm').toDate()}
        onChange={onChange}
        invalidLabel='Hora inválida'
        invalidDateMessage='Hora inválida'
        classes={{
          icon: classes.iconTime,
          date: classes.dateTime,
        }}
      />
    ),
  }

  const Input = useMemo(() => INPUT_BY_TYPE[type], [type])

  return (
    <Input
      className={classes.inputContainer}
      value={value}
      onChange={onChange}
      label={label}
      options={options}
      handleChangeOutro={handleChangeOutro}
    />
  )
}

const styles = () => ({
  comboboxContainer: {
    background: '#F2F2F2',
    color: 'black',
    borderRadius: '8px',
    padding: '0px 8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: '8px',
  },
  opcaoOutroComInput: {
    display: 'flex',
    flexDirection: 'row',
    '&>label': {
      '&>span': {
        padding: '0 5px 0 0',
      },
    },
  },
  inputNumber: {
    background: '#F2F2F2',
    color: 'black',
    borderRadius: '8px',
    padding: '6px 8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  iconTime: {
    '& button': {
      background: colors.commons.gray2,
      margin: '0px 8px',
      color: 'black',
      '& >span>svg': {
        fill: 'black !important',
      },
      '&:disabled': {
        background: 'transparent',
      },
    },
  },
  dateTime: {
    background: '#F2F2F2',
    color: 'black',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    '& >  input': {
      color: 'black',
      padding: '0px 8px',
      textAlign: 'start',
    },
  },
  marginLeft: {
    marginLeft: '8px !important',
  },
})

export default withStyles(styles)(AnamneseInput)
