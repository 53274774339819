import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const AgendaIcon = props => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M23.732 18.8916L23.6103 9.70854L3.57462 9.97416L3.69636 19.1572C3.72692 21.4625 5.62051 23.3065 7.9258 23.2759L19.6133 23.121C21.9186 23.0904 23.7626 21.1969 23.732 18.8916ZM10.2979 13.6398C10.307 14.33 9.75494 14.897 9.06476 14.9061C8.37457 14.9153 7.80764 14.3632 7.79849 13.673C7.78934 12.9828 8.34143 12.4159 9.03162 12.4067C9.72181 12.3976 10.2887 12.9497 10.2979 13.6398ZM10.3532 17.8139C10.3624 18.5041 9.81028 19.0711 9.12009 19.0802C8.4299 19.0894 7.86298 18.5373 7.85383 17.8471C7.84468 17.1569 8.39677 16.59 9.08696 16.5808C9.77714 16.5717 10.3441 17.1238 10.3532 17.8139ZM14.8927 13.5789C14.9018 14.2691 14.3497 14.836 13.6595 14.8452C12.9693 14.8543 12.4024 14.3022 12.3933 13.6121C12.3841 12.9219 12.9362 12.3549 13.6264 12.3458C14.3166 12.3366 14.8835 12.8887 14.8927 13.5789ZM14.948 17.753C14.9571 18.4432 14.4051 19.0101 13.7149 19.0193C13.0247 19.0284 12.4578 18.4763 12.4486 17.7862C12.4395 17.096 12.9915 16.5291 13.6817 16.5199C14.3719 16.5108 14.9388 17.0628 14.948 17.753ZM19.4809 13.5181C19.4901 14.2083 18.938 14.7752 18.2478 14.7844C17.5576 14.7935 16.9907 14.2414 16.9815 13.5512C16.9724 12.861 17.5245 12.2941 18.2146 12.285C18.9048 12.2758 19.4718 12.8279 19.4809 13.5181ZM23.5771 7.20408C23.5465 4.89879 21.653 3.05475 19.3477 3.08531L7.66019 3.24026C5.35489 3.27082 3.51086 5.1644 3.54142 7.46969L3.55249 8.30452L23.5882 8.0389L23.5771 7.20408Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default AgendaIcon
