import React from "react";
import styled from "styled-components";
import { components } from "react-select";

const Option = props => {
  const { data } = props;
  
  if (data.__isNew__) {
    return (
      <components.Option {...props}>
        <Title>
          <Subtitle>Adicionar</Subtitle>
          <ProductName>{` "${data.value}"`}</ProductName>
        </Title>
      </components.Option>
    )
  }
  
  return (
    <components.Option {...props}>
      <Title>
        {data.label}
      </Title>
    </components.Option>
  );
};

const Title = styled.p`
  margin: 0;
`;
const ProductName = styled.span`
  font-weight: bold;
`;
const Subtitle = styled.span`
    
`;

export default Option;
