import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const HorizontalLineIcon = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M3.99652 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H3.99652C3.44424 11 2.99652 11.4477 2.99652 12C2.99652 12.5523 3.44424 13 3.99652 13Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default HorizontalLineIcon
