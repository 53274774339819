import { db } from "../config/config";

export const USUARIO_LOGADO_KEY = "usuarioLogado";
export const UNIDADE_LOGADA_KEY = "unidadeLogada";
export const REDES_KEY = "redes";
export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const CONVENIOS_KEY = "convenios";
export const TIPO_NOVO_KEY = "tipo_novo";
export const ATENDIMENTOS_EM_ANDAMENTO = "atendimentos_andamento";
export const LAYOUT_MULTI_AGENDA = "layout_multi_agenda";
export const MULTI_AGENDA_SELECIONADAS = "multi_agenda_selecionadas";
export const PROFISSIONAL_SAUDE_LOGADO_KEY = "profissional_saude_logado";
export const EXPIRES_IN = "expires_in";
export const CURRENT_AGENDA_OWNER = "current_agenda_owner"

const keys = [
  USUARIO_LOGADO_KEY,
  REDES_KEY,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  CONVENIOS_KEY,
  TIPO_NOVO_KEY,
  ATENDIMENTOS_EM_ANDAMENTO,
  UNIDADE_LOGADA_KEY,
  LAYOUT_MULTI_AGENDA,
  MULTI_AGENDA_SELECIONADAS,
  PROFISSIONAL_SAUDE_LOGADO_KEY,
  EXPIRES_IN,
  CURRENT_AGENDA_OWNER
];

export const getAuthLocal = function() {
  const accessToken = JSON.parse(localStorage[`_immortal|${ACCESS_TOKEN_KEY}`] || null);
  const refreshToken = JSON.parse(localStorage[`_immortal|${REFRESH_TOKEN_KEY}`] || null);
  const usuarioLogado = JSON.parse(localStorage[`_immortal|${USUARIO_LOGADO_KEY}`] || null);
  
  return {
    accessToken,
    refreshToken,
    usuarioLogado
  };
}

const get = async function(key) {
  if (!keys.includes(key)) {
    throw Error(
      `A "key" ${key} não foi configurada em src/services/storage.js`
    );
  }
  
  const value = await db.get(key);

  if(!value) {
      return null;
  }
  
  return JSON.parse(value);
};

const set = async function(key, value) {
  if (!keys.includes(key)) {
    throw Error(
      `A "key" ${key} não foi configurada em src/services/storage.js`
    );
  }

  return db.set(key, JSON.stringify(value));
};

const removeAll = async function() {
  return Promise.all(keys.map(key => db.remove(key)));
};

const localStorageService = {
  get,
  set,
  removeAll,
};

export default localStorageService;
