import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@material-ui/core';
import { inject } from 'mobx-react';

import InputForm from '../../../components/Input/InputForm';
import { Button } from '../../../components/ui/Buttons';
import { Send } from '@material-ui/icons';

import AttachFileIcon from '../../../components/Icon/AttachFileIcon';
import EmojiIcon from '../../../components/Icon/EmojiIcon';
import MicIcon from '../../../components/Icon/MicIcon';
import AudioRecorder from './AudioRecorder';
import EmojiPickerModal from './EmojiPickerModal';
import classNames from 'classnames';

import { getFileTypeByExtension } from '../../../utils/getFileTypeByExtension';

const styles = ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    gap: '16px', 
  },
  inputContainerSmallPadding: {
    padding: '8px 4px',
    gap: '2px'
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  input: {
    flex: 1,
    width: '100%',
    backgroundColor: '#F2F2F2',
    borderRadius: '4px',
    color: '#000',
  },
  fileLoader: {
    display: 'none'
  }, 
  inputRoot: {
    border: 0,
    height: '28px'
  }
});

const FILE_SIZE_LIMIT = 40 * 1024 * 1024; // 40 MB

const ChatInput = observer(({ classes, chatStore, isPopup, popupContact }) => {
  const selectedContact = popupContact?.contact || chatStore?.selectedContact?.contact;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showRecordComponent, setShowRecordComponent] = useState(false);
  const [message, setMessage] = useState('');

  const textInputRef = useRef(null);

  useEffect(() => {
    if (!isPopup) {
      textInputRef.current.focus();
    }
  }, [selectedContact]);

  const fileInputRef = useRef(null);

  const isTyping = message.trim().length > 0;

  const onChangeInput = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setMessage(value);
  }

  const handleSendMessage = (event) => {
    event && event.preventDefault();
    if (message.trim().length === 0) return;
  
    const messageParams = {
      message,
      tipoMensagem: 'TEXT',
      contact: selectedContact,
      isPopup,
    }
    chatStore.sendMessage(messageParams);
    setMessage('');
  }

  const handleCancelRecord = () => {
    setShowRecordComponent(false);
  }

  const handlePickEmoji = (emoji) => {
    setMessage((prevState) => {
      return prevState + emoji.emoji;
    })
    setShowEmojiPicker(false);
  }

  const handleSelectFile = (event) => {
    event.persist();
    const { files } = event.target;

    if (files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);

      if (files[0].size > FILE_SIZE_LIMIT) {
        return chatStore.openNotification('Tamanho máximo de arquivo permitido: 40 MB', 'error');
      }

      fileReader.onload = (e) => {
        const base64WithoutPrefix = e.target.result.split('base64,')[1];
        const file = {
          nome: files[0].name.split('.')[0],
          extensao: files[0].name.split('.').pop(),
          base64: base64WithoutPrefix,
        }
        const type = getFileTypeByExtension(file.extensao);
        const messageParams = {
          message,
          tipoMensagem: type,
          contact: selectedContact,
          file,
          isPopup
        }
        chatStore.sendMessage(messageParams);
      };
    }
  };

  const handleClickSendButton = () => {
    if (!isTyping) {
      setShowRecordComponent(true);
    } else {
      handleSendMessage()
    }
  }

  return (
    <div className={classNames(classes.inputContainer, isPopup && classes.inputContainerSmallPadding)}>
      {showRecordComponent ? (
        <AudioRecorder 
          handleCancel={handleCancelRecord} 
          isPopup={isPopup} 
          contact={selectedContact}
        />
      ) : (
        <> 
          {!isPopup && (
            <Button kind="transparent" shape="circle" size="mini" onClick={() => setShowEmojiPicker(true)}>
              <EmojiIcon size={24} color='#868686'/>
            </Button>
          )}
          <Button 
            kind="transparent" 
            shape="circle" 
            size="mini"
            onClick={() => fileInputRef.current.click()}
          >
            <AttachFileIcon size={24} color='#868686' />
          </Button>
          <input className={classes.fileLoader} type="file" ref={fileInputRef} onChange={handleSelectFile} />
          <form className={classes.form} onSubmit={handleSendMessage}>
            <InputForm
              inputRef={textInputRef}
              placeholder="Digite sua mensagem"
              className={classes.input}
              classes={{
                input: classes.inputRoot
              }}
              onChange={onChangeInput}
              value={message}
              maxLength={4000}
            />
          </form>
          <Button 
            kind="transparent"
            shape="circle" 
            size="mini"
            onClick={handleClickSendButton}
          >
            {isTyping ? <Send /> : <MicIcon color='#868686' size={24} />}
          </Button>
        </>
      )}
      {!isPopup && (
        <EmojiPickerModal 
          open={showEmojiPicker} 
          onPick={(emoji) => handlePickEmoji(emoji)}
          onClose={() => setShowEmojiPicker(false)}
        />  
      )}
    </div>
  )
});

const ChatInputStyles = withStyles(styles)(ChatInput);
export default inject('chatStore')(ChatInputStyles);
