import React from "react";
import moment from "moment";
import Local from "./Components/Local";
import string from "../../../../utils/string";
import MoreIcon from "../../../../components/Icon/More";
import TrashIcon from "../../../../components/Icon/Trash";
import PencilIcon from "../../../../components/Icon/Pencil";
import AgendaIcon from "../../../../components/Icon/Agenda";
import DuplicaIcon from "../../../../components/Icon/DuplicaIcon";
import InativarIcon from "../../../../components/Icon/InativarIcon";
import HistoricoIcon from "../../../../components/Icon/HistoricoIcon";
import CheckMarkIcon from "../../../../components/Icon/CheckMarkIcon";
import ObservacaoIcon from "../../../../components/Icon/ObservacaoIcon";
import ProcedimentoIcon from "../../../../components/Icon/ProcedimentoIcon";
import PopperCustomMenu from "../../../../components/Popper/PopperCustomMenu";
import Situacao from "../../../../components/Agenda/Situacao/Situacao";

export const labelTipoDentePermanente = [
  { nome: "Molar", show: false, border: ["top", "left"] },
  { nome: "Molar", show: true, border: ["top"] },
  { nome: "Molar", show: false, border: ["top", "right"], marginRight: true },
  { nome: "Pré-molar", show: true, border: ["top", "left"] },
  {
    nome: "Pré-molar",
    show: false,
    border: ["top", "right"],
    marginRight: true,
  },
  {
    nome: "Canino",
    show: true,
    border: ["top", "right", "bottom", "left"],
    marginRight: true,
  },
  { nome: "Incisivos", show: false, border: ["top", "left"] },
  { nome: "Incisivos", show: true, border: ["top"] },
  { nome: "Incisivos", show: false, border: ["top"] },
  {
    nome: "Incisivos",
    show: false,
    border: ["top", "right"],
    marginRight: true,
  },
  {
    nome: "Canino",
    show: true,
    border: ["top", "right", "bottom", "left"],
    marginRight: true,
  },
  { nome: "Pré-molar", show: true, border: ["top", "left"] },
  {
    nome: "Pré-molar",
    show: false,
    border: ["top", "right"],
    marginRight: true,
  },
  { nome: "Molar", show: false, border: ["top", "left"] },
  { nome: "Molar", show: true, border: ["top"] },
  { nome: "Molar", show: false, border: ["top", "right"] },
];

export const labelTipoDenteDeciduo = [
  { nome: "Molar", show: true, border: ["top", "left"] },
  { nome: "Molar", show: false, border: ["top", "right"], marginRight: true },
  {
    nome: "Canino",
    show: true,
    border: ["top", "right", "bottom", "left"],
    marginRight: true,
  },
  {
    nome: "Incisivo lateral",
    show: true,
    border: ["top", "right", "bottom", "left"],
    marginRight: true,
  },
  { nome: "Incisivos", show: true, border: ["top", "left"] },
  {
    nome: "Incisivos",
    show: false,
    border: ["top", "right"],
    marginRight: true,
  },
  {
    nome: "Incisivo lateral",
    show: true,
    border: ["top", "right", "bottom", "left"],
    marginRight: true,
  },
  {
    nome: "Canino",
    show: true,
    border: ["top", "right", "bottom", "left"],
    marginRight: true,
  },
  { nome: "Molar", show: true, border: ["top", "left"] },
  { nome: "Molar", show: false, border: ["top", "right"] },
];

export const tabsModalConfiguracao = {
  TIPO_DENTE: 0,
  HIPERDONTIA: 1,
};

export const tabsModalProcedimento = {
  INFORMACAO: 0,
  AUDITORIA: 1,
};

export const posicoes = [
  { id: "ARCADA_SUPERIOR", nome: "Arcada superior" },
  { id: "ARCADA_INFERIOR", nome: "Arcada inferior" },
];

export const menuOpcoesPopperProcedimentos = (options) => {
  const optionsMenu = [
    {
      ativo: true,
      label: "Agendar",
      onClick: () => options?.functions?.handleAgendar(),
      icon: <AgendaIcon size="18" color="#505050" />,
    },
    {
      ativo: true,
      label: "Editar",
      onClick: () => options?.functions?.handleEditar(),
      icon: <PencilIcon size="18" color="#505050" />,
    },
    {
      ativo: true,
      label: "Duplicar",
      onClick: () => options?.functions?.handleDuplicar(),
      icon: <DuplicaIcon size="18" color="#505050" />,
    },
    {
      ativo: true,
      label: "Excluir",
      onClick: () => options?.functions?.handleExcluir(),
      icon: <TrashIcon size="18" color="#505050" />,
    },
  ];
  return optionsMenu;
};

export const columns = ({ popperRef, functions }) => [
  {
    Header: "Data",
    field: "agendamento.data",
    getValue: (row) =>
      row.agendamento?.data &&
      moment(row.agendamento?.data).format("DD/MM/YYYY"),
  },
  {
    Header: "Procedimento",
    field: "procedimentoId",
    getValue: (row) => string.truncate(row.procedimento?.nome, 25),
  },
  {
    Header: "Orçamento",
    getValue: (row) => {
      const {faturado} = row.orcamentoAtual || {};

      const status = {
        PENDENTE: "PENDENTE",
        APROVADO: "APROVADO",
      };
      
      const statusOrcamento = status[row.orcamentoAtual?.status] || "NAO_REALIZADO";

      return <Situacao label={statusOrcamento} faturado={faturado}/>
    }
  },
  {
    Header: "Local",
    field: "dente.codigo",
    getValue: (row) => {
      const local = row.dente
        ? `Dente ${row.dente.codigo}`
        : row.mucosaOral.descricao;
      return <Local label={local} />;
    },
  },
  {
    Header: "Status",
    field: "agendamento.situacao",
    getValue: (row) => {
      const isSituacaoAguardandoOuAtendendo = row.agendamento?.situacao === "AGUARDANDO" || row.agendamento?.situacao === "ATENDENDO";
      return row.agendamento?.situacao &&
      !isSituacaoAguardandoOuAtendendo ? (
        <Situacao label={row.agendamento?.situacao} />
      ) : (
        functions.showButton(row.id)
      )
    }
  },
  {
    Header: "",
    isClick: true,
    getValue: (row) => {
      return (
        !row.agendamento?.id && (
          <PopperCustomMenu
            ref={popperRef}
            placement="bottom-end"
            colorButton={{
              background: "#fff",
              hover: "#fff",
              color: "#5F6368",
            }}
            iconButton={<MoreIcon color="#5F6368" size={16} />}
            menuOpcoes={menuOpcoesPopperProcedimentos({
              functions: {
                handleAgendar: (e) => functions.handleAgendar(popperRef?.current?.closePopper(e), row.id),
                handleEditar: (e) => functions.handleEditar(popperRef?.current?.closePopper(e), row.id),
                handleDuplicar: (e) => functions.handleDuplicar(popperRef?.current?.closePopper(e), row.id),
                handleExcluir: (e) => functions.handleExcluir(popperRef?.current?.closePopper(e), row.id),
              },
            })}
          />
        )
      );
    },
  },
];

export const denteTipoFace = [
  {
    value: "DISTAL",
    label: "D",
    checked: false,
  },
  {
    value: "OCLUSAL_INCISAL",
    label: "O/I",
    checked: false,
  },
  {
    value: "PALATINA_LINGUAL",
    label: "P/L",
    checked: false,
  },
  {
    value: "MESIAL",
    label: "M",
    checked: false,
  },
  {
    value: "VESTIBULAR",
    label: "V",
    checked: false,
  },
];

export const ICON_TYPES = {
  OBSERVACAO: "OBSERVACAO",
  PROCEDIMENTO: "PROCEDIMENTO",
  URGENTE: "URGENTE",
  INATIVO: "INATIVO",
};

export const ICON_PROPS_BY_TYPE = (isPosicaoSuperior) => ({
  [ICON_TYPES.OBSERVACAO]: `
  ${
    isPosicaoSuperior
      ? `
      top: 12px;
      right: 0;
    `
      : `
      bottom: 12px;
      right: 0;
    `
  }
  background-color: #27B0AD;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(220, 220, 220, 0.2);
  `,
  [ICON_TYPES.PROCEDIMENTO]: `
  ${
    isPosicaoSuperior
      ? `
      top: 30px;
      left: 0;
    `
      : `
      bottom: 30px;
      left: 0;
    `
  }
    background-color: #FC71B4;
    width: 18px;
    height: 18px;
  `,
  [ICON_TYPES.URGENTE]: `
    ${
      isPosicaoSuperior
        ? `
        bottom: 40%;
        right: 0;
      `
        : `
        top: 40%;
        right: 0;
      `
    }
    background-color: #FB7676;
    width: 12px;
    height: 12px;
  `,
  [ICON_TYPES.INATIVO]: `
  ${
    isPosicaoSuperior
      ? `
      top: 20px;
      left: 0;
    `
      : `
      bottom: 20px;
      left: 0;
    `
  }
  position: absolute
  `,
});

export const menuOpcoesPopperLocal = ({ isDente, ativo, functions }) => {
  const optionsMenu = [
    {
      ativo: ativo,
      label: "Procedimento",
      onClick: () => functions?.openModalProcedimento(),
      icon: <ProcedimentoIcon size="14" color="#505050" />,
    },
    {
      ativo: true,
      label: "Observação",
      onClick: () => functions?.openModalObservacao(),
      icon: <ObservacaoIcon size="14" color="#505050" />,
    },
    {
      ativo: isDente && ativo,
      label: "Inativar dente",
      onClick: functions?.handleAlteraStatusDente,
      icon: <InativarIcon size="14" color="#505050" />,
    },
    {
      ativo: isDente && !ativo,
      label: "Ativar dente",
      onClick: functions?.handleAlteraStatusDente,
      icon: <CheckMarkIcon size="14" color="#505050" />,
    },
    {
      ativo: true,
      label: "Histórico",
      onClick: () => functions?.openModalHistorico(),
      icon: <HistoricoIcon size="14" color="#505050" />,
    },
  ];
  return optionsMenu;
};

export const situacoes = {
  PENDENTE: {
      label: "Orçado",
      backgroundColor: "#FAEAE8",
      color: "#D84E24",
  },
  APROVADO: {
      label: "Aprovado",
      backgroundColor: "#E1F3F4",
      color: "#007F79",
  },
  NAO_REALIZADO: {
      label: "Não realizado",
      backgroundColor: "#E6E8EC",
      color: "#2E416E",
  },
  CONFIRMADO: {
      label: "Confirmado",
      backgroundColor: "#D4EEEE",
      color: "#00B0AE",
  },
  AGUARDANDO: {
      label: "Aguardando",
      backgroundColor: "#FEF2E3",
      color: "#FBBF6D",
  },
  CANCELADO: {
      label: "Cancelado",
      backgroundColor: "#FFE1E0",
      color: "#FB6D6D",
  },
  ATENDIDO: {
      label: "Atendido",
      backgroundColor: "#EAE3FE",
      color: "#9B69FF",
  },
  AGENDADO: {
      label: "Agendado",
      backgroundColor: "#E3F4FE",
      color: "#6DC7FB",
  },
  BLOQUEADO: {
      label: "Bloqueado",
      backgroundColor: "#E7E7E7",
      color: "#666666",
  },
  ATENDENDO: {
      label: "Atendendo",
      backgroundColor: "#E7E7E7",
      color: "#657496",
  },
  FALTOU: {
      label: "Faltou",
      backgroundColor: "#FEF0F7",
      color: "#FC71B4",
  },
  EXCLUIDO: {
      label: "ExcluÍdo",
      backgroundColor: "#E5E5E3",
      color: "#000",
  },
  FATURADO: {
      label: "Faturado",
      backgroundColor: "#ffe0b2",
      color: "#f2a30b",
  },
};