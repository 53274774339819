import Api from "../config/api";

export const findAllGuiaServicoModelo = (filter) => {        
    return Api.post("", {
        query: `
        query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
            findAllGuiaServicoModelo(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
                last
                numberOfElements
                content {
                id
                nome
                observacao
                ativo
                cid10Subcategoria {
                    id
                    descricaoAbreviada
                }
                guiaServicoModeloTussViews {
                    tussView {
                        tabela
                        descricao
                        codigoTermo
                    }
                }
            }
                pageable {
                pageNumber
                }
            }
        }
    `, variables:
    {
        searchDTO: {
            search: filter?.search || '',
            ativo: filter?.ativo 
        },
        pageableDTO: {
            pageSize: 30,
            pageNumber: filter?.pageNumber || 0,
            sortDir: filter?.sortDir || 'ASC',
            sortField: filter?.sortField || 'nome'
        }
    }})
};

export const criarEImprimirGuiaServicoAssinado = (variables) => {
    return Api.post("", {
        query: `
            mutation ($guiaServico: GuiaServicoInput, $tokenAplicativo: String) {
                criarEImprimirGuiaServicoAssinado(guiaServico: $guiaServico, tokenAplicativo: $tokenAplicativo) {
                    id
                    entradaProntuario {
                        urlDownload
                    }
                }
            }
        `,
        variables
    });
};

export const imprimirAssinadoGuiaServico = (variables) => {
    return Api.post("", {
        query: `
            mutation ($id: UUID, $tokenAplicativo: String) {
                imprimirAssinadoGuiaServico(id: $id, tokenAplicativo: $tokenAplicativo) {
                    id
                    entradaProntuario {
                        urlDownload
                    }
                }
            }
        `,
        variables: {
            id: variables.guiaServico.id,
            tokenAplicativo: variables.tokenAplicativo
        }
    });
};
