import React from 'react'
import { withStyles } from '@material-ui/core'

import AgendaIcon from '../../../../../Icon/Agenda'
import ClipboardTaskListIcon from '../../../../../Icon/ClipboardTaskListIcon'
import ConfiguracoesIcon from '../../../../../Icon/Configuracoes'
import PacientesIcon from '../../../../../Icon/Pacientes'
import MoneyIcon from '../../../../../Icon/Money'

const TAGS = [
  {
    type: 'AGENDA',
    label: 'Agenda',
    bgColor: '#5462E0',
    icon: <AgendaIcon color='white' size='20' />,
  },
  {
    type: 'ATENDIMENTO',
    label: 'Atendimento',
    bgColor: '#FC71B4',
    icon: <ClipboardTaskListIcon color='white' size='20' />,
  },
  {
    type: 'FINANCEIRO',
    label: 'Financeiro',
    bgColor: '#219A97',
    icon: <MoneyIcon color='white' size='20' />,
  },
  {
    type: 'PACIENTE',
    label: 'Paciente',
    bgColor: '#F9BE73',
    icon: <PacientesIcon color='white' size='20' />,
  },
  {
    type: 'SISTEMA',
    label: 'Sistema',
    bgColor: '#707C97',
    icon: <ConfiguracoesIcon color='white' size='20' />,
  },
  {
    type: 'CONTROLE_SESSAO',
    label: 'Controle de Sessão',
    bgColor: '#DB7C2F',
    icon: <PacientesIcon color='white' size='20' />,
  },
]

const Tags = ({ classes, type }) => {
  const tag = TAGS.find(tag => tag.type === type)
  if (!tag) return null
  const { bgColor, icon, label } = tag
  return (
    <div className={classes.container} style={{ backgroundColor: bgColor }}>
      {icon}
      <span>{label}</span>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    padding: '0px 8px',
    height: '32px',
    '& span': {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#FFF',
      fontWeight: '700',
    },
  },
}

export default withStyles(styles)(Tags)
