import React from "react";
import InputForm from "./InputForm";
import MaskedInput from "react-text-mask";
import string from "../../utils/string";
import masks from "../../utils/masks";

let pais;

export const CEPMask = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={"\u2000"}
      showMask={props.showmask}
      mask={pais === "BRASIL" ? masks.CEP : masks.codigoPostal}
      {...other}
    />
  );
};

export const InputCEPForm = ({ ...props }) => {
  pais = props?.pais || "BRASIL";

  return (
    <InputForm
      {...props}
      inputComponent={CEPMask}
      onClick={e => {
        if (string.removeSpecialChars(e.target.value).length === 0)
          e.target.setSelectionRange(0, 0);
      }}
    />
  );
};
