import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import styles from "../GuiaServicosFaturamentoStyle";
import Table from "../../../../../components/Table/Table";
import { applyCurrencyMask } from "../../../../../utils/CurrencyMask";
import classNames from "classnames";

const columns = [
  {
    Header: "Data",
    paddingLeft: true,
    getValue: (row) => {
      return row.data && moment(row.data).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Hora",
    paddingLeft: true,
    getValue: (row) => {
      return `${row.horaInicial} ${row.horaFinal}`;
    },
  },
  {
    Header: "Descrição",
    paddingLeft: true,
    getValue: (row) => {
      return row.descricao?.nome;
    },
  },
  {
    Header: "Qtde",
    paddingLeft: true,
    getValue: (row) => {
      return row.quantidade;
    },
  },
  {
    Header: "Val unit",
    paddingLeft: true,
    getValue: (row) => {
      return row.valorUnitario
        ? applyCurrencyMask(row.valorUnitario)
        : `R$ 0,00`;
    },
  },
  {
    Header: "Val total",
    paddingLeft: true,
    getValue: (row) => {
      const valorTotal =
        row.valorUnitario && row.valorUnitario * row.quantidade;
      return applyCurrencyMask(valorTotal);
    },
  },
];

const columnsExecutantes = [
  {
    Header: "Grau part",
    paddingLeft: true,
    getValue: (row) => {
      return row.tuss35TerminologiaGrauParticipacao?.codigoTermo;
    },
  },
  {
    Header: "Nome do profissional",
    paddingLeft: true,
    getValue: (row) => {
      return row.profissionalSaude?.nome;
    },
  },
];

const StepResumo = (props) => {
  const {
    classes,

    guiaServicoFaturamento,
  } = props;

  const [executantesItemSelecionado, setExecutantesItemSelecionado] = useState(
    {}
  );
  const [showItemSelecionado, setShowItemSelecionado] = useState(false);

  const getDadosRow = (row) => {
    setExecutantesItemSelecionado(row.executantes);
    setShowItemSelecionado(true);
  };

  const handleCloseVisualizacao = () => {
    setShowItemSelecionado(false);
  };

  const calculaValorTotalFaturamento = () => {
    const valorTotalFatura = guiaServicoFaturamento.itensExecucao.reduce((previousValue, currentValue) => {
      const current = currentValue.valorUnitario * currentValue.quantidade;
      return previousValue + current
      }, 0);

    return applyCurrencyMask(valorTotalFatura);
  }

  return (
    <div className={classes.containerResumo}>
      <div className={classes.rowResumo}>
        <div className={classes.labelResumo}>
          Tipo de atendimento:
          <strong>
            {
              guiaServicoFaturamento.atendimento
                ?.tuss50TerminologiaTipoAtendimento?.termo
            }
          </strong>
        </div>
        <div className={classes.labelResumo}>
          Indicação de acidente:
          <strong>
            {
              guiaServicoFaturamento.atendimento
                ?.tuss36TerminologiaIndicadorAcidente?.termo
            }
          </strong>
        </div>
      </div>
      { guiaServicoFaturamento.atendimento?.tuss50TerminologiaTipoAtendimento?.termo === "Consulta" &&
        <div className={classes.rowResumo}>
          <div className={classes.labelResumo}>
            Tipo de consulta:
            <strong>
              {
                guiaServicoFaturamento.atendimento?.tuss52TerminologiaTipoConsulta
                  ?.descricao
              }
            </strong>
          </div>
      </div>}
      { guiaServicoFaturamento.atendimento?.tuss39TerminologiaMotivoEncerramento?.termo && 
        <div className={classes.rowResumo}>
          <div className={classes.labelResumo}>
            Motivo de encerramento do atendimento:
            <strong>
              {
                guiaServicoFaturamento.atendimento
                  ?.tuss39TerminologiaMotivoEncerramento?.termo
              }
            </strong>
          </div>
        </div>
      }

      <Grid className={classes.contentTableResumo}>
        {showItemSelecionado ? (
          <>
           <div
           onClick={handleCloseVisualizacao}
           className={classes.buttonListaProcedimentos}
         >
          {'< Lista de procedimentos'}
         </div>
         <div className={classNames(
              classes.listaResumo, 
              !guiaServicoFaturamento.atendimento?.tuss39TerminologiaMotivoEncerramento?.termo && 
              !guiaServicoFaturamento.atendimento?.tuss52TerminologiaTipoConsulta?.descricao && 
              classes.listaHeigthMaior
              )}>
            <Table
              className={classes.table}
              dados={executantesItemSelecionado}
              columns={columnsExecutantes}
            />
          </div>
          </>
        ) : (
          <>
            <div className={classNames(
              classes.listaResumo, 
              !guiaServicoFaturamento.atendimento?.tuss39TerminologiaMotivoEncerramento?.termo && 
              !guiaServicoFaturamento.atendimento?.tuss52TerminologiaTipoConsulta?.descricao && 
              classes.listaHeigthMaior
              )}>
              <Table
                clickable={true}
                className={classes.table}
                dados={guiaServicoFaturamento.itensExecucao}
                columns={columns}
                getDadosRow={getDadosRow}
              />
            </div>
            <div className={classNames(classes.labelResumo, classes.marginTop)}>
            Total faturamento:
            <strong>
              {calculaValorTotalFaturamento()}
            </strong>
          </div>
        </>
        )}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(StepResumo);
