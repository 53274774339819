import React, { useEffect, useState } from "react";
import moment from "moment";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "../../../components/Dialog/Dialog";
import { Grid } from "@material-ui/core";
import style from "./AfericaoVitalStyle";
import { InputDateForm, InputTimeForm } from "../../../components/Modal/Input";
import { InputTriagem } from "../../../components/Input/InputTriagem";
import { createAfericaoVital } from "../../../services/AfericaoVitalService";
import Notification from "../../../components/Notification";
import { buildUrlFotoPerfil } from "../../../config/config";
import GracefulImage from "../../../components/Image/GracefulImage";
import HistoricoLancamento from "./HistoricoLancamento";
import { findAllAfericaoVital } from "../../../services/AfericaoVitalService";
import InputSelectComLabel from "../../../components/Input/InputSelectComLabel";
import ButtonConfirm from "../../../components/Button/ButtonConfirm";
import string from "../../../utils/string";
import PageTitle from "../../../components/PageTitle/PageTitle";

const profileDefault = require("../../../assets/img/svg/profile-default.svg");

const opcoesColeta = [
  { value: "JEJUM", label: "Jejum" },
  { value: "POS_PRANDIAL", label: "Pós prandial" },
];

const AfericaoVitalModal = (props) => {
  const {
    classes,
    openModal,
    handleClose,
    sujeitoAtencao,
    idadeSujeitoAtencao,
    afericaoVitalSelecionado,
    screen,
  } = props;
  const [data, setData] = useState(moment());
  const [horario, setHorario] = useState(moment());
  const [coleta, setColeta] = useState("");
  const [dadosAfericao, setDadosAfericao] = useState({});
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    variant: "",
  });
  const [listaAfericoesVitais, setListaAfericoesVitais] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastScroll, setLastScroll] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isVisualizar, setIsVisualizar] = useState(false);

  useEffect(() => {
    listarAfericoesVitais();
    afericaoVitalSelecionado && preencherDadosAfericaoVitalSelecionado();
  }, []);

  useEffect(() => {
    pageNumber > 0 && listarAfericoesVitais();
  }, [pageNumber])

  const preencherDadosAfericaoVitalSelecionado = () => {
    setDadosAfericao(afericaoVitalSelecionado);
    setData(afericaoVitalSelecionado.data);
    setHorario(afericaoVitalSelecionado.data);
    setIsVisualizar(true)
  };

  const listarAfericoesVitais = async () => {
    try {
      setIsLoading(true);

      const responseListaAfericoesVitais = await findAllAfericaoVital({
        sujeitoAtencaoId: sujeitoAtencao.id,
        pageableDTO: {
          pageSize: 30,
          pageNumber: pageNumber,
          sortDir: "DESC",
          sortField: "data",
        },
      });

      setListaAfericoesVitais([
        ...listaAfericoesVitais,
        ...responseListaAfericoesVitais.content,
      ]);
      setLastScroll(responseListaAfericoesVitais.last);
    } finally {
      setIsLoading(false);
    }
  };

  const listarAfericoesVitaisAposSalvar = async () => {
    try {
      setIsLoading(true);
      setPageNumber(0);

      const responseListaAfericoesVitais = await findAllAfericaoVital({
        sujeitoAtencaoId: sujeitoAtencao.id,
        pageableDTO: {
          pageSize: 30,
          pageNumber: 0,
          sortDir: "DESC",
          sortField: "data",
        },
      });

      setListaAfericoesVitais(responseListaAfericoesVitais.content);
      setLastScroll(responseListaAfericoesVitais.last);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVisualizar = (item) => {
    setDadosAfericao(item);
    setData(moment(item.data).format("YYYY-MM-DD"));
    setHorario(moment(item.data).format("HH:mm:ss"));
    item.coleta &&
      setColeta({ value: item.coleta, label: string.capitalize(item.coleta) });
    setIsVisualizar(true);
  };

  const loadMore = () => {
    if (isLoading) {
      return;
    }
    setPageNumber(listaAfericoesVitais.length > 0 ? pageNumber + 1 : 0);
  };

  const contemErros = () => {
    const valida = !(dadosAfericao.temperatura ||
        dadosAfericao.frequenciaCardiaca ||
        dadosAfericao?.frequenciaRespiratoria ||
        dadosAfericao?.pressaoArterialSistolica ||
        dadosAfericao?.pressaoArterialDiastolica ||
        dadosAfericao?.glicemia ||
        dadosAfericao?.saturacaoO2
      );

    return valida;
  };

  const novoLancamento = () => {
    setIsVisualizar(false);
    setData(moment());
    setHorario(moment());
  };

  const onSave = async () => {
    try {
      setLoadingSalvar(true);

      if (contemErros() || !data || !horario) {
        const notification = {
          isOpen: true,
          message:
            data || horario
              ? "Nenhum campo preenchido."
              : "Favor preencher a data e o horário.",
          variant: "error",
        };
        showAlertMessage(notification);
        return;
      }
      const dadosSemSufixo = {
        temperatura:
          dadosAfericao?.temperatura &&
          typeof dadosAfericao?.temperatura === "string"
            ? 
            string.removeMask(dadosAfericao.temperatura, " °C")
            : dadosAfericao?.temperatura,
        pressaoArterialSistolica:
          dadosAfericao?.pressaoArterialSistolica &&
          typeof dadosAfericao?.pressaoArterialSistolica === "string"
            ? string.removeMask(dadosAfericao.pressaoArterialSistolica, " mmhg")
            : dadosAfericao.pressaoArterialSistolica,
        pressaoArterialDiastolica:
          dadosAfericao?.pressaoArterialDiastolica &&
          typeof dadosAfericao?.pressaoArterialDiastolica === "string"
            ? string.removeMask(dadosAfericao.pressaoArterialDiastolica, " mmhg")
            : dadosAfericao.pressaoArterialDiastolica,
        frequenciaRespiratoria:
          dadosAfericao?.frequenciaRespiratoria &&
          typeof dadosAfericao?.frequenciaRespiratoria === "string"
            ? string.removeMask(dadosAfericao.frequenciaRespiratoria, " irpm")
            : dadosAfericao.frequenciaRespiratoria,
        frequenciaCardiaca:
          dadosAfericao?.frequenciaCardiaca &&
          typeof dadosAfericao?.frequenciaCardiaca === "string"
            ? string.removeMask(dadosAfericao.frequenciaCardiaca, " bpm")
            : dadosAfericao?.frequenciaCardiaca,
        glicemia:
          dadosAfericao?.glicemia && typeof dadosAfericao?.glicemia === "string"
            ? string.removeMask(dadosAfericao.glicemia, " mg/dl")
            : dadosAfericao.glicemia,
        saturacaoO2:
          dadosAfericao?.saturacaoO2 &&
          typeof dadosAfericao?.saturacaoO2 === "string"
            ? string.removeMask(dadosAfericao.saturacaoO2, " %")
            : dadosAfericao.saturacaoO2,
      };

      const afericaoVital = {
        ativo: true,
        data:
          typeof data === "string"
            ? `${data}T${horario}`
            : `${data.format("YYYY-MM-DD")}T${horario.format("HH:mm:ss")}`,
        ...dadosSemSufixo,
        coleta: coleta?.value,
        sujeitoAtencao: { id: sujeitoAtencao.id },
      };

      await createAfericaoVital(afericaoVital);
      const notification = {
        isOpen: true,
        message: "Aferição vital realizado com sucesso!",
        variant: "success",
      };
      showAlertMessage(notification);
      setPageNumber(0);
      listarAfericoesVitaisAposSalvar();
      handleClickLimparCampos();
    } catch {
      const notification = {
        isOpen: true,
        message: "Erro ao lançar a aferição vital.",
        variant: "error",
      };
      showAlertMessage(notification);
    } finally {
      setLoadingSalvar(false);
     
    }
  };

  const handleDateChange = (e) => {
    setData(e);
  };

  const onChangeHorario = (e) => {
    setHorario(e);
  };

  const handleSelectColeta = (e) => {
    setColeta(e);
  };

  const handleChange = (e, field) => {
    const value = e.target.value;

    switch (field) {
      case "temperatura":
        return setDadosAfericao({ ...dadosAfericao, temperatura: value });
      case "paSistolica":
        return setDadosAfericao({
          ...dadosAfericao,
          pressaoArterialSistolica: value,
        });
      case "paDiastolica":
        return setDadosAfericao({
          ...dadosAfericao,
          pressaoArterialDiastolica: value,
        });
      case "frequenciaRespiratoria":
        return setDadosAfericao({
          ...dadosAfericao,
          frequenciaRespiratoria: value,
        });
      case "frequenciaCardiaca":
        return setDadosAfericao({
          ...dadosAfericao,
          frequenciaCardiaca: value,
        });
      case "glicemia":
        return setDadosAfericao({ ...dadosAfericao, glicemia: value });
      case "saturacaoO2":
      default:
        return setDadosAfericao({ ...dadosAfericao, saturacaoO2: value });
    }
  };

  const calculaPressaoMedia = () => {
    const pressaoArterialDiastolicaSemSufixo =
      dadosAfericao?.pressaoArterialDiastolica &&
      typeof dadosAfericao?.pressaoArterialDiastolica === "string"
        ? string.removeMask(dadosAfericao?.pressaoArterialDiastolica, " mmhg")
        : dadosAfericao?.pressaoArterialDiastolica;

    const pressaoArterialSistolicaSemSufixo =
      dadosAfericao?.pressaoArterialSistolica &&
      typeof dadosAfericao?.pressaoArterialSistolica === "string"
        ? string.removeMask(dadosAfericao?.pressaoArterialSistolica, " mmhg")
        : dadosAfericao?.pressaoArterialSistolica;

    const resultado =
      pressaoArterialDiastolicaSemSufixo +
      0.333 *
        (pressaoArterialSistolicaSemSufixo -
          pressaoArterialDiastolicaSemSufixo);

    return resultado ? `${resultado} mmng` : "";
  };

  const showAlertMessage = (notificationEnviado) => {
    setNotification(notificationEnviado);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
      variant: "",
    };
    setNotification(notification);
  };

  const handleClickLimparCampos = () => {
    setData(moment());
    setHorario(moment());
    setColeta(null);
    setDadosAfericao(null);
  };

  const inativarAfericaoVital = (afericao, index) => {
    const listaComItemInativado = listaAfericoesVitais;
    listaComItemInativado[index] = {...afericao, ativo: false};

    setListaAfericoesVitais(listaComItemInativado);
  };

  const getPageTitle = () => {
    const acao = isVisualizar ? "Visualizar" : "Novo";

    return `${screen} - ${acao} aferições vitais`
  }

  return (
    <>
    <PageTitle title={getPageTitle()}/>
    <Dialog
      classes={{
        paper: classes.fullSizePaper,
      }}
      maxWidth="md"
      open={openModal}
      fullWidth
    >
      <div className={classes.contentModal}>
        <div className={classes.header}>
          <div className={classes.tituloHeader}> Aferição vital </div>
          <div className={classes.dadosPacienteFoto}>
            <GracefulImage
              src={
                sujeitoAtencao?.fotoPerfil
                  ? buildUrlFotoPerfil(sujeitoAtencao?.fotoPerfil)
                  : profileDefault
              }
              className={classes.imageMini}
              alt={"Imagem Perfil"}
              height="60"
              placeholderColor={"#00ACA9"}
            />
            <div className={classes.boxDadosPaciente}>
              <div className={classes.nomePaciente}>{sujeitoAtencao.nome}</div>
              <div className={classes.dadosPaciente}>
                <div className={classes.dadosPacienteItem}>
                  Idade: <span> {idadeSujeitoAtencao} </span>
                </div>
                <div className={classes.dadosPacienteItem}>
                  Convênio:
                  <span>
                    {sujeitoAtencao.dadosConvenio?.convenio?.descricao || " - "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.tituloBoxAfericaoVital}>
            
            Dados do lançamento
          </div>
          <div className={classes.infosRow}>
            <Grid item xs={4} className={classes.spacing}>
              <span className={classes.titulo}> Data: </span>
              <InputDateForm
                iconposition="end"
                openTo="day"
                views={["year", "month"]}
                value={data}
                onChange={handleDateChange}
                classes={{
                  input: classes.inputColor,
                }}
                disabled={isVisualizar}
              />
            </Grid>
            <Grid item xs={4} className={classes.spacing}>
              <span className={classes.titulo}> Hora: </span>
              <InputTimeForm
                value={moment(horario, "HH:mm").toDate()}
                classes={{
                  date: classes.semMargem,
                  icon: classes.iconTime,
                  input: classes.inputColor,
                  label: classes.titulo,
                }}
                iconposition="end"
                className={classes.dateTime}
                onChange={onChangeHorario}
                disabled={isVisualizar}
              />
            </Grid>
          </div>

          <div className={classes.tituloBoxAfericaoVital}> Corpo </div>
          <div className={classes.infosRow}>
            <Grid item xs={4} className={classes.spacing}>
              <span className={classes.titulo}> Temperatura: </span>
              <InputTriagem
                className={classes.inputMask}
                classInput={classes.inputBlockField}
                classInputRoot={classes.inputRootBlockField}
                value={dadosAfericao?.temperatura ? String(dadosAfericao?.temperatura).replace(".", ",") : ""}
                onChange={(e) => handleChange(e, "temperatura")}
                placeholder={"°C"}
                mask={"temperatura"}
                disabled={isVisualizar}
              />
            </Grid>
            <Grid item xs={4} className={classes.spacing}>
              <span className={classes.titulo}> Frequência cardíaca: </span>
              <InputTriagem
                className={classes.inputMask}
                classInput={classes.inputBlockField}
                classInputRoot={classes.inputRootBlockField}
                value={dadosAfericao?.frequenciaCardiaca || ""}
                onChange={(e) => handleChange(e, "frequenciaCardiaca")}
                placeholder={"bpm"}
                mask={"frequenciaCardiaca"}
                disabled={isVisualizar}
              />
            </Grid>
            <Grid item xs={4} className={classes.spacingLast}>
              <span className={classes.titulo}> Frequência respiratória: </span>
              <InputTriagem
                className={classes.inputMask}
                classInput={classes.inputBlockField}
                classInputRoot={classes.inputRootBlockField}
                value={dadosAfericao?.frequenciaRespiratoria || ""}
                onChange={(e) => handleChange(e, "frequenciaRespiratoria")}
                placeholder={"irpm"}
                mask={"frequenciaRespiratoria"}
                disabled={isVisualizar}
              />
            </Grid>
          </div>

          <div className={classes.tituloBoxAfericaoVital}> Sangue </div>
          <div className={classes.infosRow}>
            <Grid item xs={4} className={classes.spacing}>
              <span className={classes.titulo}> pa sistólica: </span>
              <InputTriagem
                className={classes.inputMask}
                classInput={classes.inputBlockField}
                classInputRoot={classes.inputRootBlockField}
                value={dadosAfericao?.pressaoArterialSistolica || ""}
                onChange={(e) => handleChange(e, "paSistolica")}
                placeholder={"mmhg"}
                mask={"pa"}
                disabled={isVisualizar}
              />
            </Grid>
            <Grid item xs={4} className={classes.spacing}>
              <span className={classes.titulo}> pa diastólica: </span>
              <InputTriagem
                className={classes.inputMask}
                classInput={classes.inputBlockField}
                classInputRoot={classes.inputRootBlockField}
                value={dadosAfericao?.pressaoArterialDiastolica || ""}
                onChange={(e) => handleChange(e, "paDiastolica")}
                placeholder={"mmhg"}
                mask={"pa"}
                disabled={isVisualizar}
              />
            </Grid>
            <Grid item xs={4} className={classes.spacingLast}>
              <span className={classes.titulo}> Pressão média: </span>
              <div className={classes.inputPressaoMedia}>
                {dadosAfericao?.pressaoArterialSistolica &&
                  dadosAfericao?.pressaoArterialDiastolica &&
                  calculaPressaoMedia()}
              </div>
            </Grid>
          </div>

          <div className={classes.infosRow}>
          <Grid item xs={4} className={classes.spacing}>

              {isVisualizar ?
              <InputSelectComLabel
                label={"Coleta:"}
                value={coleta?.label && coleta.label.replace("_", " ")}
                disabled={true}
                classes={{
                  input: classes.inputColetaVisualizar,
                  titulo: classes.tituloColeta,
                }}
                className={classes.selectColeta}
              />
              :
              <InputSelectComLabel
                label={"Coleta"}
                placeholder="Selecione"
                isSelect
                value={coleta}
                classes={{
                  input: classes.inputColeta,
                  titulo: classes.tituloColeta,
                }}
                className={classes.selectColeta}
                onChange={(e) => handleSelectColeta(e)}
                options={opcoesColeta || []}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />}
            </Grid>
            <Grid item xs={4} className={classes.spacing}>
              <span className={classes.titulo}> Glicemia: </span>
              <InputTriagem
                className={classes.inputMask}
                classInput={classes.inputBlockField}
                classInputRoot={classes.inputRootBlockField}
                value={dadosAfericao?.glicemia ? String(dadosAfericao?.glicemia).replace(".", ",") : ""}
                onChange={(e) => handleChange(e, "glicemia")}
                placeholder={"mg/dl"}
                mask={"glicemia"}
                disabled={isVisualizar}
              />
            </Grid>
            <Grid item xs={4} className={classes.spacingLast}>
              <span className={classes.titulo}> Saturação O2: </span>
              <InputTriagem
                className={classes.inputMask}
                classInput={classes.inputBlockField}
                classInputRoot={classes.inputRootBlockField}
                value={dadosAfericao?.saturacaoO2 ? String(dadosAfericao?.saturacaoO2).replace(".", ",") : ""}
                onChange={(e) => handleChange(e, "saturacaoO2")}
                placeholder={"%"}
                mask={"saturacaoO2"}
                disabled={isVisualizar}
              />
            </Grid>
          </div>
          <Notification
            close={closeAlertMessage}
            reset={closeAlertMessage}
            isOpen={notification.isOpen}
            variant={notification.variant}
            message={notification.message}
          />
        </div>
        {!afericaoVitalSelecionado && <div className={classes.contentButtons}>
           <ButtonConfirm
            onClick={isVisualizar ? novoLancamento : onSave}
            disabled={loadingSalvar}
            classes={{ root: classes.buttonSave }}
          >
            {isVisualizar ? "Novo" : "Salvar"}
          </ButtonConfirm>

          {!isVisualizar && <span
            className={classes.buttonLimparCampos}
            onClick={handleClickLimparCampos}
          >
            Limpar campos
          </span>}
        </div>}
      </div>
      <div className={classes.contentHistoricoLancamento}>
        <HistoricoLancamento
          actionClose={handleClose}
          listaAfericoesVitais={listaAfericoesVitais}
          listarAfericoesVitais={listarAfericoesVitais}
          handleVisualizar={handleVisualizar}
          loadMore={loadMore}
          lastScroll={lastScroll}
          isLoading={isLoading}
          inativarAfericaoVital={inativarAfericaoVital}
          afericaoVitalSelecionado={afericaoVitalSelecionado}
        />
      </div>
    </Dialog>
    </>
  );
};

const TriagemWithStyles = withStyles(style)(AfericaoVitalModal);
export default inject("configuracaoImpressaoStore")(TriagemWithStyles);
