import React from "react"
import { withStyles } from "@material-ui/core";
import ButtonLong from "../../../../../../components/Button/ButtonLong"


const Header = ({ classes, handleOpenContratoModal, isLoading }) => {

  return (
    <div className={classes.contentHeaderConsultas}>
      <div className={classes.titleHeaderConsultas}>
        Documentos
      </div>
      <div className={classes.contentButtonHeaderConsultas}>
        <ButtonLong
          className={classes.button}
          colorCustom="gray"
          onClick={handleOpenContratoModal}
          disabled={isLoading}
          noShadow>
          Novo documento
        </ButtonLong>
      </div>
    </div>
  )
}

const styles = {
  contentHeaderConsultas: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    borderTop: "1px solid #f2f2f2",
    background: "#fff",
    height: "69px",
  },
  titleHeaderConsultas: {
    fontWeight: 700,
    fontSize: "18px",
  },
  contentButtonCircleConsultas: {
    display: "flex",
    gap: "16px",
    position: "absolute",
    right: "40px",
    bottom: "40px",
  },
  button: {
    padding: "10px"
  }
};

export default withStyles(styles)(Header)