export const goToWhatsApp = (phone, dialCode = '55', message = null) => {
    if(phone){
        const text = message ? `text=${encodeURIComponent(message)}` : '';

        const link = `https://api.whatsapp.com/send/?phone=${dialCode}${phone}&${text}`
        window.open(link, "_blank")
    }

    if(!phone && message){
        const link = `https://api.whatsapp.com/send/?text=${message}`
        window.open(link, "_blank")
    }
};