import colors from '../../../../template/Colors'

const styles = {
  dialog: {
    height: '100%',
    maxHeight: '520px',
  },
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    height: '100%',
  },
  typography: {
    color: '#868686',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginBottom: '4px',
  },
  inputColor: {
    color: colors.commons.fontColor,
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    fontSize: '0.875rem',
    fontWeight: '600',
    height: '32px',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
  inputContainer: {
    background: '#F2F2F2',
    borderRadius: '8px',
    color: '#505050',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    height: '32px',
    padding: '2px 8px',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  inputDateForm: {
    width: '100%',
    background: '#F2F2F2 !important',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 0px',
    '&>div': {
      '&>input': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
  inputValorProcedimento: {
    width: '95%',
  },
  classIcons: {
    fontSize: 24,
  },
  formActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    margin: '1rem 0',
  },
  inlineButtons: {
    paddingRight: '10px',
    display: 'inline-flex',
    width: '100%',
    '& button': {
      flex: 1,
      fontWeight: 'bold',
    },
    '& button[disabled]': {
      opacity: '0.5',
      cursor: 'default !important',
    },
  },
  borderNone: {
    '& fieldset': { border: 'none' },
  },
  inputDisabled: {
    borderRadius: '8px',
    fontFamily: 'Poppins',
    color: '#505050',
    fontSize: '0.875rem',
    fontWeight: '600',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  contentModal: {
    padding: '24px 24px 0',
  },
  backgroundField: {
    '& >div>div': {
      backgroundColor: '#f2f2f4',
    },
  },
  inputObservacao: {
    background: '#F2F2F2',
    fontFamily: 'Poppins',
    color: '#505050',
    fontSize: '0.875rem',
    fontWeight: '600',
    borderRadius: 10,
    width: 'calc(100% - 18px)',
    maxHeight: 'auto',
    boxSizing: 'borderBox',
    padding: '0 8px',
  },
  inputCurrency: {
    backgroundColor: '#f2f2f4',
    borderRadius: '100px',
    fontFamily: 'Poppins',
    height: '27px',
    padding: '4px 16px',
    width: 'auto',
    maxWidth: '148px',
  },
  inputData: {
    width: '100%',
    '&> div': {
      backgroundColor: '#f2f2f4',
    },
  },
  inputDataBorder: {
    borderRadius: '100%',
  },
  codProcedimento: {
    height: '32px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    color: '#505050',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    borderRadius: '8px',
    width: '100%',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    display: 'flex',
    alignItems: 'center',
  },
  contentTipoConsulta: {
    maxWidth: '278px',
  },
  dialogHeader: {
    background: 'white',
    borderRadius: '15px 15px 0 0',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>h1': {
      color: '#26ACA9',
      fontSize: '1.5rem',
      marginTop: '0.375rem',
    },
  },
  wrapperActionsTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonActionClose: {
    backgroundColor: '#FB7676',
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
  },
}

export default styles
