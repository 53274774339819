import React from 'react'
import { withStyles } from '@material-ui/core'
import moment from 'moment'

import CheckmarkCircleIcon from '../../../../Icon/CheckmarkCircleIcon'
import Tags from './Tags'
import { Button } from '../../../../ui/Buttons'

import { buildUrlFotoPerfil } from '../../../../../config/config'
import { visualizarNotificacao } from '../../../../../services/NotificationsService'

const CardNotification = ({ classes, item, refetch, openModalListaEspera, accessToken }) => {
  const toggleNotificationToRead = async item => {
    try {
      await visualizarNotificacao({
        notificacaoId: item?.id,
      })
      await refetch()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: item?.visualizado ? '#F2F2F2' : '#fff',
      }}
    >
      <div className={classes.header}>
        <Tags type={item?.tipoNotificacao} />
        <Button
          kind='transparent'
          shape='circle'
          onClick={() => toggleNotificationToRead(item)}
          disabled={item?.visualizado}
        >
          <CheckmarkCircleIcon color='#219A97' outline={!item?.visualizado} />
        </Button>
      </div>
      <div className={classes.content}>
        <img
          src={buildUrlFotoPerfil(
            item?.agendamento?.profissionalSaude?.usuario?.fotoPerfil,
            accessToken
          )}
          alt='Imagem'
        />
        <span dangerouslySetInnerHTML={{ __html: item?.descricao }} />
        {item?.tipoNotificacao === 'AGENDA' && (
          <Button kind='transparent' onClick={() => openModalListaEspera(item)}>
            Ver lista de espera
          </Button>
        )}
      </div>
      <div className={classes.footer}>
        <span>Mensagem enviada</span>
        <span>{moment(item?.dataHoraCriacao).format('DD/MM/YYYY HH:mm')}</span>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    padding: '16px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '& > button': {
      opacity: 1,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& > img': {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
    },
    '& > span': {
      fontSize: '14px',
      fontWeight: '400',
      flex: 1,
    },

    '& > button': {
      border: '1px solid transparent !important',
      '&:hover': {
        border: '1px solid #219A97 !important',
        backgroundColor: '#F1FCFA',
      },
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '4px',
    borderTop: '1px solid rgba(0, 0, 0, 0.10)',
    '& > span': {
      fontSize: '10px',
      fontWeight: '400',
    },
  },
}

export default withStyles(styles)(CardNotification)
