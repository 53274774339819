import { applyCurrencyMask } from "./CurrencyMask";
import string from "./string";

export const calculaSubtotal = ({valorTotal, desconto, tipoDesconto}) => {
    const valor = typeof valorTotal === "string" ? string.currencyMaskToFloat(valorTotal) : valorTotal;

    if(valorTotal && tipoDesconto && desconto){
      if(tipoDesconto.value === "VALOR") {
        const descontoValor = typeof desconto === "string" ? string.currencyMaskToFloat(desconto) : desconto

        return applyCurrencyMask(valor - descontoValor);

      } else {
        const descontoPercentil = typeof desconto === "string" ? string.currencyMaskToFloat(desconto) : desconto
        const valorDesconto =  valor * (descontoPercentil/100);

        return applyCurrencyMask(valor - valorDesconto);
      }
    }

    return valorTotal;
};

export const calculaParcela = ({ valorTotal, valorEntrada, totalParcelas }) => {
    const valor = typeof valorTotal === "string" ? string.currencyMaskToFloat(valorTotal) : valorTotal;
    const entrada = typeof valorEntrada === "string" ? string.currencyMaskToFloat(valorEntrada) : valorEntrada;

    return applyCurrencyMask((valor - entrada) / totalParcelas);
};