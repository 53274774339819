import { CircularProgress, DialogTitle, withStyles } from "@material-ui/core";
import React, { useEffect, useState, useMemo } from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import { Fab } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styles from "./GuiaDeConsultaModalStyles";
import HorizontalLinearStepper from "../../GuiaDeServicos/Stepper";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import StepAtendimento from "./Steps/StepAtendimento";
import StepPaciente from "./Steps/StepPaciente";
import { findAllSujeitoAtencao } from "../../../../services/GuiaDeServicosService";
import ButtonLong from "../../../../components/Button/ButtonLong";
import StepProfissionalSaude from "./Steps/StepProfissionalSaude";
import StepValidacao from "./Steps/StepValidacao";
import {
  createFinanceiroGuiaConsulta,
  createFinanceiroGuiaConsultaAgendamento,
  listarSelectModal,
  updateFinanceiroGuiaConsulta,
} from "../../../../services/GuiaDeConsultaService";
import string from "../../../../utils/string";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { verificaCamposVazioStepAtendimento, verificaCamposVazioStepPaciente, verificaCamposVazioStepProfissionalSaude } from "./Steps/ValidaDados";
import ButtonTransparent from "../../../../components/Button/ButtonTransparent";


const titlesModal = {
  "0": "Guia do paciente",
  "1": "Guia do atendimento",
  "2": "Guia do executante",
  "3": "Guia da validação",
};

const steps = ["Paciente", "Atendimento", "Executante", "Validação"];

const GuiaDeConsultaModal = observer((props) => {
  const {
    classes,
    handleCloseModal,
    guiaConsultaSelected,
    openNotification,
    carregaListaGuiaConsulta,
    guiaDeConsultaStore,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [guiaConsulta, setGuiaConsulta] = useState({});
  const [listaSelectStepAtendimento, setListaSelectStepAtendimento] = useState(
    []
  );
  const [loadingSave, setLoadingSave] = useState(false);
  const validationsByStep = [verificaCamposVazioStepPaciente, verificaCamposVazioStepAtendimento, verificaCamposVazioStepProfissionalSaude]



  const getListaSelect = async () => {
    const response = await listarSelectModal();
    setListaSelectStepAtendimento(response);
  };

  const handleChangeGuiaConsulta = (field, value) => {
    if (field === "profissionalSaude") {
      setGuiaConsulta((prevState) => ({
        ...prevState,
        ...value,
      }));
    } else {
      setGuiaConsulta((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    handleCloseModal();
  };

  const loadSujeitosAtencao = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllSujeitoAtencao(page, search)
      ).data.data.findAllSujeitoAtencao;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch {
      openNotification("Erro ao carregar ", "error");
    }
  };

  const getPageTitle = () => {
    const acao = guiaConsultaSelected?.id ? "Editar" : "Nova";

    return `Financeiro - ${acao} guia de consulta`;
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleSave = async () => {
    try {
      setLoadingSave(true);

      if (guiaConsulta.id) {
        const financeiroGuiaConsulta = {
          ...guiaConsulta,
          id: guiaConsulta.id,
          agendamentoTipo: { id: guiaConsulta.agendamentoTipo?.id },
          ausenciaCodigoValidacao: guiaConsulta.ausenciaCodigoValidacao?.value,
          financeiroGuiaServicoAusenciaCodValidacao:
            guiaConsulta.ausenciaCodigoValidacao?.value,
          beneficiario: {
            ...guiaConsulta.beneficiario,
            cns: guiaConsulta.beneficiario.cns || null,
            sujeitoAtencao: {
              id: guiaConsulta.beneficiario.sujeitoAtencao.id,
            },
          },
          tuss24TerminologiaCBO: guiaConsulta.tuss24TerminologiaCBO,
          tuss36TerminologiaIndicadorAcidente: guiaConsulta.indicacaoAcidente,
          valorProcedimento:
            typeof guiaConsulta.valorProcedimento === "string"
              ? string.removeMask(guiaConsulta.valorProcedimento, "R$")
              : guiaConsulta.valorProcedimento,
          codigoProcedimento: guiaConsulta.codigoProcedimento?.codigoTermo,
          convenio: { id: guiaConsulta.convenio?.id },
          indicacaoAcidente: guiaConsulta.indicacaoAcidente?.codigoTermo,
          observacao: guiaConsulta.observacao ? guiaConsulta.observacao : null,
          codigoValidacao: guiaConsulta.codigoValidacao
            ? guiaConsulta.codigoValidacao
            : null,
          conselhoProfissional: guiaConsulta.conselhoProfissional?.codigoTermo,
          uf: guiaConsulta.uf?.codigoTermo,
        };

        await updateFinanceiroGuiaConsulta(financeiroGuiaConsulta);
      } else {
        const agendamentoId = guiaConsulta.agendamentoId || null;

        const guiaConsultaAdaptado = {
          agendamentoId,
          dataAtendimento: guiaConsulta.dataAtendimento,
          profissionalSaudeId: guiaConsulta.profissionalSaude?.id,
          ausenciaCodValidacao: guiaConsulta.ausenciaCodigoValidacao?.value || null,
          observacao: guiaConsulta.observacao ? guiaConsulta.observacao : null,
          convenioId: guiaConsulta.convenio.id,
          cbo: guiaConsulta.tuss24TerminologiaCBO,
          valorProcedimento:
            typeof guiaConsulta.valorProcedimento === "string"
              ? string.removeMask(guiaConsulta.valorProcedimento, "R$")
              : guiaConsulta.valorProcedimento,
          codigoValidacao: guiaConsulta.codigoValidacao
            ? guiaConsulta.codigoValidacao
            : null,
          indicacaoAcidente: guiaConsulta.indicacaoAcidente,
          agendamentoTipoId: guiaConsulta.agendamentoTipo.id,
          beneficiario: {
            ...guiaConsulta.beneficiario,
            cns: guiaConsulta.beneficiario.cns || null,
            sujeitoAtencao: {
              id: guiaConsulta.beneficiario.sujeitoAtencao.id,
            },
          },
        };

        agendamentoId ? await createFinanceiroGuiaConsultaAgendamento(guiaConsultaAdaptado) : await createFinanceiroGuiaConsulta(guiaConsultaAdaptado);
      }

      handleCloseModal();
      carregaListaGuiaConsulta();
    } catch (error){
      openNotification("Erro ao salvar ", "error");
    } finally {
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    setGuiaConsulta(
      guiaDeConsultaStore.guiaConsulta
        ? guiaDeConsultaStore.guiaConsulta
        : guiaConsultaSelected
    );
    getListaSelect();
  }, []);

  const isButtonDisabled = useMemo(() => {
    const validateFunction = validationsByStep[activeStep];
    if (validateFunction) {
      const hasErrors = validateFunction(guiaConsulta);
      return hasErrors;
    }
    return false
    }, [guiaConsulta, activeStep])

  return (
    <>
      <PageTitle title={getPageTitle()} />
      <Dialog
        open={guiaDeConsultaStore.isOpenModal}
        maxWidth={"sm"}
        fullWidth={true}
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.root}>
          <DialogTitle classes={{ root: classes.dialogHeader }} disableTypography>
            <div className={classes.dialogTitle}>
              <h1>{titlesModal[activeStep]}</h1>
              <div className={classes.wrapperActionsTitle}>
                <Fab className={classes.buttonActionClose} onClick={handleClose}>
                  <CloseIcon />
                </Fab>
              </div>
            </div>
        </DialogTitle>
          <HorizontalLinearStepper activeStep={activeStep} steps={steps} />
          <div className={classes.contentModal}>
            {activeStep === 0 && (
              <StepPaciente
                handleChangeGuiaConsulta={handleChangeGuiaConsulta}
                guiaConsulta={guiaConsulta}
                loadSujeitosAtencao={loadSujeitosAtencao}
                openNotification={openNotification}
              />
            )}
            {activeStep === 1 && (
              <StepAtendimento
                handleChangeGuiaConsulta={handleChangeGuiaConsulta}
                guiaConsulta={guiaConsulta}
                openNotification={openNotification}
                listaSelectStepAtendimento={listaSelectStepAtendimento}
              />
            )}
            {activeStep === 2 && (
              <StepProfissionalSaude
                handleChangeGuiaConsulta={handleChangeGuiaConsulta}
                guiaConsulta={guiaConsulta}
                openNotification={openNotification}
              />
            )}
            {activeStep === 3 && (
              <StepValidacao
                handleChangeGuiaConsulta={handleChangeGuiaConsulta}
                guiaConsulta={guiaConsulta}
                openNotification={openNotification}
              />
            )}
          </div>
          <div className={classes.formActions}>
              <div className={classes.inlineButtons}>
                <ButtonTransparent onClick={handleBack} disabled={activeStep === 0}>
                  {activeStep === 0 ? "" : "Voltar"}
                </ButtonTransparent>
              </div>

            <div className={classes.inlineButtons}>
              {activeStep === 3 ? (
                <ButtonLong
                  colorCustom="yellow"
                  onClick={handleSave}
                  disabled={isButtonDisabled || loadingSave}
                >
                  Salvar
                  {loadingSave && <CircularProgress size={12} />}
                </ButtonLong>
              ) : (
                <ButtonLong
                  colorCustom="green"
                  onClick={handleNext}
                  disabled={isButtonDisabled}
                >
                  Próxima etapa
                </ButtonLong>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
});

const GuiaDeConsultaModalWithStyles = withStyles(styles)(GuiaDeConsultaModal);
export default inject("guiaDeConsultaStore")(GuiaDeConsultaModalWithStyles);
