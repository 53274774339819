import React from 'react';
import classNames from 'classnames';
import {withStyles} from "@material-ui/core/styles/index";
import {applyPhoneMask, applyInternationalPhoneMask} from "../../../utils/PhoneMask";
import {CircularProgress, ClickAwayListener, Tooltip} from '@material-ui/core';
import {inject, observer} from 'mobx-react';
import Moment from 'moment';
import string from '../../../utils/string';
import { getStatusAtendimento, getStatusRowAtendimento } from '../../../utils';
import {getDadosMensagem}  from '../../../pages/Atendimento/utils/getDadosMensagem';
import { goToWhatsApp } from '../../../utils/goToWhatsApp';

import ColumnInformation from './ColumnInformation';
import ButtonWhatsApp from '../../Button/ButtonWhatsApp';
import ModalMensagemWhatsapp from '../../../pages/Atendimento/ModalMensagemWhatsapp';
import { findAllDadosMensagemSelecionada } from '../../../services/MensagensService';


@inject('atendimentoStore', 'unidadeStore','filtroHeaderStore')
@observer
class EventDay extends React.Component {
    state = {
        showBarraStatus: false,
        loading: false,
        isAniversarioProximo: false,
        isAniversariante: false,
        nomesProcedimentosPedido: '',
        openModalMensagemWhatsapp:false,
        dadosMensagem:null,
    };

    constructor(props) {
        super(props);
        this.cardRef = React.createRef();
        this.timeoutIdHideBarraStatus = null;
    }
    
    componentDidMount() {
        const { event } = this.props;
        this.verificaAniversario();
        event.procedimentoPedido && event.procedimentoPedido[0]?.procedimentosSolicitados?.length > 0 && this.verificaProcedimentoPedido();
    }
    
    componentWillUnmount() {
        if(this.timeoutIdHideBarraStatus) {
            clearTimeout(this.timeoutIdHideBarraStatus);
            this.timeoutIdHideBarraStatus = null;
        }
    }

    handleContextMenu = (e) => {
        e.preventDefault();
    };

    handleMouseUp = (e) => {
        if (typeof e === 'object' && e.button === 2) {
            this.handleRemoveEvent();
        }
    };

    handleSelectEvent = () => {
        const { onSelectAgendamento, onSelectNovoAgendamento, event, selectedDate, salaDay } = this.props;
        const { horaFim, horaInicio, procedimentosConfig, profissionalSolicitanteConfig } = event || {};
        let horarioSelecionado = {
            start: Moment(
                `${selectedDate.format("YYYY-MM-DD")} ${
                    horaInicio
                }`,
                "YYYY-MM-DD HH:mm"
            ),
            end: Moment(
                `${selectedDate.format("YYYY-MM-DD")} ${
                    horaFim
                }`,
                "YYYY-MM-DD HH:mm"
            ),
            ...(salaDay && {
                procedimentosConfig,
                profissionalSolicitanteConfig,
            }),
        };

        if (!event.status) {
            onSelectNovoAgendamento(horarioSelecionado, salaDay);
        } else {
            onSelectAgendamento({
                ...horarioSelecionado,
                id: event.id,
                status: event.status
            }, salaDay);
        }
    };

    handleRemoveEvent = () => {
        const { onRemoveAgendamento, event } = this.props;
        if (event.status) {
            onRemoveAgendamento(event);
        }
    };

    handleClickBarraStatus = (e) => {
        e.stopPropagation();
        this.setState(state => ({ showBarraStatus: !state.showBarraStatus }));
    };

    handleMouseOverBarraStatus = () => {
        const { event } = this.props;
        if(!this.state.showBarraStatus && event.status) {
            this.setState({ showBarraStatus: true });
            return;
        }
        if(this.timeoutIdHideBarraStatus) {
            clearTimeout(this.timeoutIdHideBarraStatus);
            this.timeoutIdHideBarraStatus = null;
        }
    };

    handleMouseLeaveBarraStatus = () => {
        if(this.state.showBarraStatus && !this.timeoutIdHideBarraStatus) {
            this.timeoutIdHideBarraStatus = setTimeout(() => {
                this.setState({ showBarraStatus: false }, () => this.timeoutIdHideBarraStatus = null);
            }, 800);
        }
    };

    handleCloseBarraStatus = (e) => {
        e.stopPropagation();
        this.setState({ showBarraStatus: false });
    };

    handleClickAlteraStatus = async(e, situacao) => {
        try {
            e.stopPropagation();
            this.setState({ showBarraStatus: false, loading: true });
    
            const { atendimentoStore, event } = this.props;
            await atendimentoStore.updateSituacaoAgendamento({ id: event.id, situacao });

        } catch (error) {
            this.props.atendimentoStore.openNotification(error, 'error');
        } finally {
            this.setState({ loading: false });  
        }
    };

    verificaAniversario = () => {
        const { event } = this.props;

        const {isAniversarioProximo, isAniversariante} = string.verificaAniversarioProximoADataAtual(event.dataNascimento, 5);

        this.setState({ 
            isAniversarioProximo,
            isAniversariante
        })
    };

    verificaProcedimentoPedido = () => {
        const { event } = this.props;
        const {procedimentoPedido} = event;

        let nomeProcedimento = 'Procedimentos solicitados: ';

        procedimentoPedido[0].procedimentosSolicitados.forEach(element => {
            nomeProcedimento = `${nomeProcedimento} ${element.procedimento.nome},`
        });

        this.setState({
            nomesProcedimentosPedido: nomeProcedimento
        })
    };


    handleClickWhatsapp = async (e) => {
        e.stopPropagation();
        const{unidadeStore}=this.props
        const configuracaoUnidade = unidadeStore.configuracaoUnidade
        const{utilizaMensagemPersonalizada,horasPermiteConfirmarAgendamento, utilizaEnderecoMensagemAgendamento}=configuracaoUnidade;
        const {event, atendimentoStore,salaDay} = this.props;
        const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);
        const { endereco, nomeFantasia } = unidadeLogada || {};
        const response = await atendimentoStore.getDadosMensagemPersonalizada(event.id);
        const { profissionalSaude } = response || {};

        if(utilizaMensagemPersonalizada && !salaDay){
            this.setState({openModalMensagemWhatsapp:true});
            const idMensagem = event.id;
            if(this.state.loading ) return
            await atendimentoStore.getConfiguracaoUnidadeLogada();

            const mensagem= await findAllDadosMensagemSelecionada(idMensagem);
            const { contato } = mensagem?.sujeitoAtencao || {};
            this.setState({dadosMensagem:{
                ...mensagem,
                ...(profissionalSaude && { nomeProfissional: profissionalSaude }),
                ...(contato?.telefonePrincipal && {telefone: contato.telefonePrincipal}),
                ...(contato?.telefonePrincipalDDI && {telefoneDDI: contato.telefonePrincipalDDI})
            }});
            atendimentoStore.increaseWhatsappMessageCounter(event.id);
        }
        if(salaDay || !utilizaMensagemPersonalizada){
            let message =""
            const telefone = event.sujeitoAtencao?.contato?.telefonePrincipal || event.telefone
            const telefoneDDI = event.sujeitoAtencao?.contato?.telefonePrincipalDDI || event.telefoneDDI

            const dadosMensagem={
                id:event.id,
                nomePaciente: event.nome,
                nomeFantasiaUnidade: nomeFantasia,
                nomeProfissional: profissionalSaude?.nome || '',
                horaInicio: event.horaInicio || " - ",
                data: event.data,
                chavePublica: event.chavePublica,
                isAgendamentoVizi: event.paciente?.id,
                remoto: event.remoto,
                linkTelemedicina: event.linkTelemedicina,
                horasPermiteAgendamento: horasPermiteConfirmarAgendamento,
                withEndereco: utilizaEnderecoMensagemAgendamento,
                endereco,
                telefone,
                telefoneDDI,
                idade: event.idade,
                observacao:event.observacao,
            };
            message = getDadosMensagem(dadosMensagem);
            if(!event.id ) return

            atendimentoStore.increaseWhatsappMessageCounter(event.id);
            
            return goToWhatsApp(telefone, telefoneDDI, message)
            
        }
    };

    handleCloseModalMensagemWhatsapp=()=>{
        this.setState({openModalMensagemWhatsapp: false})
    }    
    
    getSolicitante = (event) => {
      if (typeof(event?.id) === 'number') {
        return event.solicitante;
      } else {
        return event?.profissionalSolicitanteConfig?.nome || '';
      }
    }

    getProcedimentos = (event) => {
      if (typeof(event?.id) === 'number') {
        return string.concatenarArrayProcedimentosEmString(event.procedimentos);
      } else {
        return event?.procedimentosConfig?.map(({ nome }) => nome).join(', ') || '';
      }
    }

    render() {
        const { classes, style, event, salaDay, history } = this.props;
        const { showBarraStatus, loading, isAniversarioProximo, isAniversariante, nomesProcedimentosPedido, openModalMensagemWhatsapp } = this.state;
        const backgroundColorByStatus = getStatusRowAtendimento(event.status);
        const haveCompletePhone = event?.telefone && event?.telefoneDDI;
        const {filtroHeaderStore} = this.props;
        const {profissionalSaude} = filtroHeaderStore;
        const eventTipo = !salaDay ? event.tipo : this.getProcedimentos(event) || event.tipo;

        return (
            
            <React.Fragment>
                <div
                    ref={this.cardRef}
                    className={`
                        ${classes.root} 
                        ${event.status === 'BLOQUEADO' ? 'evento-bloqueado' : 'evento'}
                        ${salaDay ? classes.dayCalendarInnerSala : classes.dayCalendarInner}
                    `}
                    style={{backgroundColor: backgroundColorByStatus}}
                    onClick={this.handleSelectEvent}
                    onContextMenu={event.id && this.handleContextMenu}
                    onMouseUp={event.id && ((e) => this.handleMouseUp(e))}>
                    {event.status === 'BLOQUEADO' && (<div className={'line-blocked'}/>)}
                    {event.status !== 'BLOQUEADO' ? (   
                        <React.Fragment>
                            <div className={classNames(classes.dayCalendarColumn, 'barra-status')}
                                style={{
                                    backgroundColor: getStatusAtendimento(event.status).color
                                }}
                                onMouseOver={event.id ? this.handleMouseOverBarraStatus : null}
                                onMouseLeave={event.id ? this.handleMouseLeaveBarraStatus : null}
                            >
                                <div className={classNames(classes.dayCalendarColumnLabelHour, { status: event.status })}>
                                    {event.horaInicio}
                                </div>
                                <ClickAwayListener onClickAway={this.handleCloseBarraStatus}>
                                    <div
                                        onClick={event.id ? this.handleClickBarraStatus : null}>
                                        <div
                                            className={classNames('barra-status-inner', { show: showBarraStatus })}>
                                            {this.props.atendimentoStore.situacoesAlteracaoStatus.map(statusItem => (
                                                <Tooltip
                                                    title={statusItem.name}
                                                    key={statusItem.value}
                                                    placement="right"
                                                >
                                                    <div
                                                        className="status-button"
                                                        style={{background: getStatusAtendimento(statusItem.value).color}}
                                                        onClick={(e) => this.handleClickAlteraStatus(e, statusItem.value)}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <div className={classNames(classes.dayCalendarColumn)}>
                                {event.sujeitoAtencaoNome || event.nome}
                            </div>

                            <div className={classes.dayCalendarColumn}>
                                {salaDay ? this.getSolicitante(event) : event.idade}
                            </div>
                            <div className={classNames(classes.dayCalendarColumn, classes.columnTipo)}>                       
                            {
                                !salaDay && event.destaqueTipo ?
                                    <div className={classes.destaqueTipo}>
                                        {eventTipo}
                                    </div>
                                    :
                                    eventTipo
                            }
                            </div>
                            {!salaDay && (
                                <div
                                    onClick={haveCompletePhone ? this.handleClickWhatsapp : this.handleSelectEvent}
                                    className={classNames(classes.dayCalendarColumn, classes.phoneColumnLabel)}
                                >
                                        {this.props.unidadeStore.unidade.utilizaTelefoneInternacional ?
                                            applyInternationalPhoneMask(event?.telefone, event?.telefoneDDI) :
                                            applyPhoneMask(event.sujeitoAtencaoTelefone || event.telefone)
                                        }
                                        {haveCompletePhone && (
                                            <ButtonWhatsApp
                                                withCount
                                                messageSent={event?.mensagemWhatsappEnviada}
                                                labelTooltip='Mensagem enviada, deseja enviar novamente?'
                                                quantidadeEnviado={event?.whatsappMensagemQuantidade}
                                                onClick={ this.handleClickWhatsapp}
                                            />
                                        )}
                                </div>
                            )}
                            <div className={classes.dayCalendarColumn}>
                                {event.sujeitoAtencaoConvenio || event.convenio}
                            </div>
                            
                            <div className={classes.dayCalendarColumn}>
                                <ColumnInformation 
                                    event={event} 
                                    handleClickWhatsapp={this.handleClickWhatsapp} 
                                    nomesProcedimentosPedido={nomesProcedimentosPedido}
                                    isAniversarioProximo={isAniversarioProximo}
                                    isAniversariante={isAniversariante}
                                    salaDay={salaDay}
                                    verificaProcedimentoPedido={this.verificaProcedimentoPedido}
                                    openNotification={this.props.atendimentoStore.openNotification}
                                    history={history}
                                />
                            </div>
                                        
                                <ModalMensagemWhatsapp
                                    open={openModalMensagemWhatsapp}
                                    close={this.handleCloseModalMensagemWhatsapp}
                                    dadosMensagem={this.state.dadosMensagem}
                                    profissionalSaudeSelecionado={profissionalSaude.id}
                                />

                        </React.Fragment> 
                        ) :
                        <React.Fragment>
                                <div className={classNames(classes.dayCalendarColumn, 'hora-bloqueada')} style={{backgroundColor:'#D7D7D7', paddingInline: '16px'}}>
                                    {event.horaInicio}
                                </div>
                                <div className={classes.dayCalendarColumn} style={{ minWidth: '250px'}}>
                                    {!string.isEmpty(event.nome) && (
                                        <div>
                                            <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                                                Motivo do bloqueio:
                                            </span>
                                            {event.nome}
                                        </div>
                                    )}
                                </div>
                        </React.Fragment>
                    }
                </div>

                {(loading && event.id) && (
                        <div className={classes.loading}>
                            <CircularProgress color="primary" size={24} />
                        </div>
                    )}
            </React.Fragment>
        )
    }
}

const styles = theme => ({
    root: {
        position: 'absolute',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&:hover': {
            backgroundColor: '#efefef',
        },
        '& .strike': {
            color: theme.palette.commons.red,
        },
        '& .barra-status': {
            paddingInline: '16px',
            '& .barra-status-inner': {
                backgroundColor: '#fff',
                display: 'none',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '80px',
                boxSizing: 'border-box',
                flexWrap: 'wrap',

                '&.show': {
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width:'80px',
                    zIndex: 4,
                    height: '100%',
                },

                '& .status-button': {
                    width: 15,
                    height: 15,
                    marginLeft: 4,
                    borderRadius: '50%',

                    '&:last-child': {
                        marginBottom: 0,
                    }
                },
            },
        },
        '&.evento-bloqueado': {
            zIndex: 2,
            '& .line-blocked': {
                backgroundColor: '#FB7676',
                height: '1px',
                width: '100%',
                borderRadius: '100px',
                position: 'absolute',
                top: '50%',
                left: '90px',
            },
            '& .hora-bloqueada': {
                color: '#BDBDBD !important',
                fontSize: 14,
                fontWeight: '700',
            },
        },
        '&.evento': {
            zIndex: 3,
        },
        '& div': {
            fontWeight: 500,
            userSelect: 'none',
            '&.convenio': {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            "@media (max-width: 1368px)": {
                fontSize: 13
            },
            "@media (max-width: 1199px)": {
                fontSize: 11
            }
        },
    },

    dayCalendarInner: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '80px 2fr 100px 1fr 2fr 2fr 3fr',
        gridColumnGap: '16px',
        overflowY: 'auto',
        overflowX: 'hidden',

        color: '#868686 !important',
        fontSize: '14px',
    },

    dayCalendarInnerSala: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '80px 2fr 100px 2fr 2fr 3fr',
        gridColumnGap: '16px',
        overflowY: 'auto',
        overflowX: 'hidden',
        color: '#868686 !important',
        fontSize: '14px',
    },

    dayCalendarColumnLabelHour: {
        color: '#bdbdbd',
        fontWeight: 'bold !important',
        fontSize: '14px!important',
        alignItems: 'center',
        marginLeft: '0px',
        fontFamily: "Poppins",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.status ': {
            color: '#fff',
            fontWeight: '500 !important',
        }
    },

    dayCalendarColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    phoneColumnLabel:{
        display: 'grid',
        gridTemplateColumns: 'calc(100% - 25px) 25px',
        alignItems: 'center',
        marginLeft: '6px',
        justifyContent: 'flex-start',
    },
    
    tooltip: {
        backgroundColor: "#FFFFFF",
        color: "#000",
        fontSize: "14px",
        boxShadow: theme.shadows[1],
    },

    button: {
        backgroundColor: theme.palette.commons.purple,
        color: '#fff',
        fontSize: 10,
        position: 'absolute',
        top: 3,
        right: 3,
        minHeight: 22,
        minWidth: 22,
        maxHeight: 22,
        maxWidth: 22,
        padding: 0,
    },

    loading: {
        zIndex: "10",
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    destaqueTipo: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        background: '#000',
        color: '#fff',
        padding: '8px',
    },
    columnTipo: {
        minWidth: '100px',
    }
});

export default withStyles(styles)(EventDay);