export const datePeriodMask = [
  /\d/, /\d/, '/',
  /\d/, /\d/, '/',
  /\d/, /\d/, /\d/, /\d/,
  ' ', 'à', ' ',
  /\d/, /\d/, '/',
  /\d/, /\d/, '/',
  /\d/, /\d/, /\d/, /\d/,
];

export const timePeriodMask = [
  /\d/, /\d/, ':', /\d/, /\d/,
  ' ', 'à', ' ',
  /\d/, /\d/, ':', /\d/, /\d/,
]