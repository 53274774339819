export const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    width: '300px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    padding: '32px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '48px',
    padding: '0 24px',
  },
  title: {
    fontWeight: '700',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
});
