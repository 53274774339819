export const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  prevButton: {
    left: 0
  },
  nextButton: {
    right: 0
  },
  cursorPointer: {
    '&:hover': {
      cursor: 'pointer'
    } 
  }
});

export const directionButton = {
  position: 'absolute',
  backgroundColor: 'transparent',
  border: 'none',
  alignSelf: 'center',
};
