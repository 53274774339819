import moment from 'moment';

export const generateTimeList = (startTime, endTime, interval) => {
  const timeList = [];
  const format = 'HH:mm';

  const startMoment = moment(startTime, format);
  const endMoment = moment(endTime, format);

  let currentMoment = startMoment.clone();
  while (currentMoment.isSameOrBefore(endMoment)) {
    timeList.push(currentMoment.format(format));
    currentMoment.add(interval, 'minutes');
  }

  return timeList;
};
