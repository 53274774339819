import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PencilIcon = props => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M11.5513 4.3999L15.6001 8.4487L7.64958 16.3992C7.42808 16.6207 7.15261 16.7805 6.8504 16.863L2.75785 17.9791C2.30995 18.1013 1.89897 17.6903 2.02112 17.2424L3.13727 13.1498C3.21969 12.8476 3.37955 12.5721 3.60106 12.3506L11.5513 4.3999ZM17.2242 2.77598C18.3422 3.89395 18.3422 5.70654 17.2242 6.82451L16.4481 7.5999L12.3993 3.5519L13.1757 2.77598C14.2937 1.65801 16.1063 1.65801 17.2242 2.77598Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default PencilIcon
