import colors from "../../../../template/Colors";

const styles = {
    paper: {
        overflow: 'hidden',
        maxWidth: 837,
        width: '100%',
        height: '100%',
        maxHeight: '640px',
    },
    container: {
       height: '100%',
    },
    contentModal: {
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        height: '100%',
    },
    contentUnificacao: {
        width: '100%',
        maxWidth: 505,
        maxHeight: 640,
        display: 'flex',
        flexDirection: 'column',
        background: '#f9f9f9',
    },
    contentAlert: {
        display: 'flex',
        background: colors.commons.yellow2,
        color: colors.commons.gray9,
    },
    iconAlert: {
        width: 12,
        height: 10,
        color: "#DADADA",
        margin: '16px 4px 16px 20px',
        filter: 'brightness(0.4)',
    },
    mensagemAlert: {
        textAlign: 'justify',
        padding: '8px 12px',
        fontSize: 10,
    },
    contentList: {
        width: '100%',
        maxWidth: '320px',
    },
    contentButtons: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '24px',
        height: '80px',
    },
    buttonCancel: {
        color: colors.commons.gray9,
        cursor: "pointer",
        height: 20,
        fontWeight: 700,
        marginRight: 36,
      },
      divButtonUnificar: {
        width: '165px',
        marginRight: 26,
      }
};

export default styles;
