import {
  withStyles,
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Colors from "../../../template/Colors";
import { InputForm } from "../../../components/Input/InputDadosCadastraisPaciente";
import { InputDateForm, InputTimeForm } from "../../../components/Modal/Input";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import CustomDateTimePicker from "../../../components/CustomDateTimePicker";
import moment from "moment";
import TextCharCounter from "../../../components/TextCharCounter/TextCharCounter";

const TiposCampoAnamnese = (props) => {
  const {
    classes,
    label,
    tipocampo,
    onChange,
    respostas,
    onChangeTipoComMultiplasRespostas,
    onChangeOutro,
    readOnly,
    textLength
  } = props;

  const [valueCombobox, setValueCombobox] = useState(undefined);

  useEffect(() => {
    const findValue = (condition) => {
      return respostas.find(item => condition(item));
    };

    if (tipocampo === "COMBOBOX") {
      const condition = readOnly ? (item => item?.valorConvertido) : (item => item.valorResposta);
      const respostaTrue = findValue(condition);
      setValueCombobox(respostaTrue);
    }
  }, [respostas, tipocampo, readOnly]);

  const changeCombobox = (e, tipocampo) => {
    setValueCombobox(e);
    onChangeTipoComMultiplasRespostas(e, tipocampo);
  }

  const renderTipoCampo = () => {
    switch (tipocampo) {
      case "INPUT":
        return (
          <>
            <input
              readOnly={readOnly}
              onChange={(e) => onChange(e, tipocampo)}
              className={classes.input}
              value={readOnly ? respostas[0]?.valorConvertido : (respostas[0]?.valorResposta || "")}
            />
            <TextCharCounter
              currQuantity={textLength}
              limit={4000}
            />
          </>
        );
      case "TEXT_AREA":
        return (
          <>
            <InputForm
              readOnly={readOnly}
              value={readOnly ? respostas[0]?.valorConvertido : (respostas[0]?.valorResposta || "")}
              className={classes.textArea}
              rowsMax={20}
              rows={4}
              multiline
              onChange={(e) => onChange(e, tipocampo)}
            />
            <TextCharCounter
              currQuantity={textLength}
              limit={4000}
            />
          </>
        );
      case "RADIO":
        const respostasMultiplaEscolha = respostas?.map((item, index) => {
          const nomeLabel = item.nome === "" ? "Outro:" : item.nome;
          return (
            <div className={classes.opcaoOutroComInput} key={index}>
              <FormControlLabel
                control={
                  <>
                    <Radio
                      disabled={readOnly}
                      color="primary"
                      checked={readOnly ? item?.valorConvertido : item.valorResposta}
                      onChange={(e) => onChangeTipoComMultiplasRespostas(e, tipocampo, index)}
                    />
                  </>
                }
                label={nomeLabel}
              />
              {nomeLabel === "Outro:" && (
                <input
                  disabled={!item.valorResposta || readOnly}
                  value={item.outro || ""}
                  onChange={(e) => onChangeOutro(e, index)}
                  className={[`${classes.input}  ${classes.inputOutro}`]}
                />
              )}
            </div>
          );
        });

        return respostasMultiplaEscolha;
      case "CHECKBOX":
        const respostasCheckbox = respostas.map((item, index) => {
          const nomeLabel = item.nome === "" ? "Outro:" : item.nome;

          return (
            <div className={classes.opcaoOutroComInput} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkboxColor}
                    disabled={readOnly}
                    checked={readOnly ? item?.valorConvertido : item.valorResposta}
                    color="primary"
                    onChange={(e) => onChangeTipoComMultiplasRespostas(e, tipocampo, index)}
                  />
                }
                label={nomeLabel}
                className={classes.labelRespostas}
              />
              {nomeLabel === "Outro:" && (
                <input
                  readOnly={!item.valorResposta || readOnly}
                  onChange={(e) => onChangeOutro(e, index)}
                  value={item.outro || ""}
                  className={[`${classes.input}  ${classes.inputOutro}`]}
                />
              )}
            </div>
          );
        });

        return respostasCheckbox;
      case "COMBOBOX":
        return (
          <>
            {readOnly ?
              <input
                className={classes.input}
                value={valueCombobox?.nome || ""}
                readOnly
              />
              :
              <ReactSelect
                placeholder={"Selecione"}
                onChange={(e) => changeCombobox(e, tipocampo)}
                options={respostas || []}
                value={valueCombobox}
                isClearable
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.id}
              />
            }
          </>
        );
      case "DATE":
        return (
          <Grid item className={classes.paddingTipoDate}>
            <InputDateForm
              disabled={readOnly}
              iconposition="end"
              classes={{
                input: classes.inputColor,
                date: classes.date,
              }}
              value={readOnly ? respostas[0]?.valorConvertido : (respostas[0]?.valorResposta || null)}
              onChange={(e) => onChange(e, tipocampo)}
            />
          </Grid>
        );

      case "TIME":
        return (
          <Grid item className={classes.paddingTipoDate}>
            <InputTimeForm
              disabled={readOnly}
              classes={{
                input: classes.inputColor,
                icon: classes.iconTime,
                date: classes.dateTime,
              }}
              iconposition="end"
              value={readOnly ?
                moment(respostas[0]?.valorConvertido, "HH:mm").toDate() :
                (respostas[0]?.valorResposta ? (moment(respostas[0]?.valorResposta, "HH:mm").toDate()) : null)
              }
              onChange={(e) => onChange(e, "TIME")}
            />
          </Grid>
        );

      case "DATETIME":
        return (
          <Grid item className={classes.paddingTipoDate}>
            <CustomDateTimePicker
              disabled={readOnly}
              disabletoolbar={readOnly.toString()}
              onChange={(e) => onChange(e, "DATETIME")}
              value={readOnly ? (respostas[0]?.valorConvertido) : (respostas[0]?.valorResposta || null)}
              invalidLabel=""
              invalidDateMessage=""
            />
          </Grid>
        );
      case "NUMBER":
        return (
          <input
            value={readOnly ? respostas[0]?.valorConvertido : respostas[0]?.valorResposta}
            readOnly={readOnly}
            onChange={(e) => onChange(e, "NUMBER")}
            className={classes.input}
            type="number"
            min={0}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className={classes.divLabel}>
      <span className={classes.titulo}>{label}</span>
      {renderTipoCampo()}
    </div>
  );
};

const style = {
  divLabel: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: 20,
  },
  titulo: {
    color: Colors.commons.gray7,
    fontSize: "12px !important",
    fontWeight: 400,
    marginBottom: 4,
  },
  input: {
    borderRadius: 100,
    padding: "4px 15px",
    background: Colors.commons.gray11,
    color: Colors.commons.gray9,
    fontFamily: "Poppins",
    border: "none",
    height: "24px",
    "&::disabled": {
      color: Colors.commons.gray9,
    }
  },
  textArea: {
    background: "#F2F2F2",
    borderRadius: "10px",
    padding: "0px 8px",
    maxHeight: "101px",
    overflow: "auto",
    "& textarea": {
      color: Colors.commons.gray9,
    },
  },
  iconTime: {
    "& button": {
      background: Colors.commons.gray2,
      "&:disabled": {
        background: `${Colors.commons.gray2} !important`,
      }
    },
  },
  inputColor: {
    color: Colors.commons.gray9,
  },
  opcaoOutroComInput: {
    display: "flex",
    flexDirection: "row",
    "&>label": {
      "&>span": {
        padding: "0 5px 0 0",
      },
    },
  },
  inputOutro: {
    width: "100%",
  },
  dateTime: {
    marginTop: "0px !important",
    borderRadius: '100px',
  },
  paddingTipoDate: {
    padding: "0 5px"
  },
  labelRespostas: {
    "&>span:last-child": {
      color: `${Colors.commons.gray9} !important`,
    },
  },
  checkboxColor: {
    color: Colors.commons.gray7
  },
  date: {
    borderRadius: '100px',
  },
};

export default withStyles(style)(TiposCampoAnamnese);
