import React, { useEffect } from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { InputDateForm } from '../../../../components/Modal/Input'
import InputSelectComLabel from '../../../../components/Input/InputSelectComLabel'
import colors from '../../../../template/Colors'
import InputSenha from '../../../../components/Input/InputSenha'

const StepAutorizacao = props => {
  const { classes, handleChangeGuiaServico, guiaServico, setDisableButton, disableButton } = props

  const handleChange = (field, value) => {
    let autorizacao = guiaServico?.autorizacao
    autorizacao = { ...autorizacao, [field]: value }
    handleChangeGuiaServico('autorizacao', autorizacao)
  }

  const validarCampos = () => {
    try {
      const {
        senha,
        dataValidadeSenha,
        numeroGuiaOperadora,
        dataAutorizacao,
      } = guiaServico?.autorizacao
      if (!senha || !dataValidadeSenha || !numeroGuiaOperadora || !dataAutorizacao) {
        setDisableButton(true)
        return
      }
      setDisableButton(false)
    } catch {
      setDisableButton(true)
    }
  }

  const validarCamposEdit = () => {
    const { autorizacao } = guiaServico
    if(autorizacao && Object.values(autorizacao).some(value => !!value)) {
      setDisableButton(true)
      return
    } else if(!autorizacao) {
      setDisableButton(true)
      return
    }
    setDisableButton(false)
  }

  useEffect(() => {
    guiaServico?.id && validarCamposEdit()
    validarCampos()
  }, [guiaServico?.autorizacao])

  return (
    <div className={classes.wrapperStep}>
      <Grid item xs={12}>
        <Grid container xs={12}>
          <Grid item xs={6} className={classes.paddingRight}>
            <Typography className={classes.typography} component='label'>
              Informe a senha:
            </Typography>
            <InputSenha
              value={guiaServico?.autorizacao?.senha}
              onChange={e => handleChange('senha', e.target.value)}
              placeholder='Digite sua senha'
              className={classes.inputContainer}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typography} component='label'>
              Data validade da senha
            </Typography>
            <InputDateForm
              iconposition='end'
              placeholder='00/00/0000'
              className={classes.inputDateForm}
              value={guiaServico?.autorizacao?.dataValidadeSenha || ''}
              onChange={e => handleChange('dataValidadeSenha', moment(e).format('YYYY-MM-DD'))}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} className={classes.paddingRight}>
            <InputSelectComLabel
              label={'Número da guia:'}
              name='numeroGuiaOperadora'
              placeholder='Digite o número da guia'
              classes={{
                input: classes.inputContainer,
              }}
              value={guiaServico?.autorizacao?.numeroGuiaOperadora}
              onChange={e => handleChange('numeroGuiaOperadora', e.target.value)}
              onInput={e => (e.target.value = e.target.value.slice(0, 20))}
              type='number'
              noMargin
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typography} component='label'>
              Data da autorização
            </Typography>
            <InputDateForm
              iconposition='end'
              placeholder='00/00/0000'
              className={classes.inputDateForm}
              value={guiaServico?.autorizacao?.dataAutorizacao || ''}
              onChange={e => handleChange('dataAutorizacao', moment(e).format('YYYY-MM-DD'))}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = () => ({
  typography: {
    color: '#868686',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    marginBottom: '4px',
  },
  wrapperStep: {
    padding: '24px',
  },
  paddingRight: {
    paddingRight: 8,
  },
  inputColor: {
    color: colors.commons.fontColor,
  },
  dateField: {
    backgroundColor: colors.commons.gray11,
    height: 33,
  },
  inputContainer: {
    width: 'auto',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 8px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    '&>div': {
      '&>p': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  inputDateForm: {
    width: '100%',
    background: '#F2F2F2 !important',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '2px 0px',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontWeight: '600',
    '&>div': {
      '&>input': {
        color: '#505050',
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },
})

export default withStyles(styles)(StepAutorizacao)
