import moment from "moment";
import React from "react";

const renderItem = ({styles, item, index}) => {
    return (
        <div style={styles.containerColuna} key={index}>
            <div style={styles.colunaDescricao}>
                <div style={styles.text}>
                    {item.data}
                </div>
            </div>
            <div style={styles.colunaDescricao}>
                <div style={styles.text}>
                    {item.nome}
                </div>
            </div>
            <div style={styles.coluna}>
                <div style={styles.text}>
                   {item.profissionalSaude?.nome || "-"}
                </div>
            </div>
            <div style={styles.coluna}>
                <div style={styles.text}>
                    {item.valorRepasseAgendamento}
                </div>
            </div>
        </div>
    );
};

const RelatorioRepassePdfDocument = ({ filter, search, repasses }) => {
    const {
        dataInicial,
        dataFinal,
        profissionalSaude,
        situacoes,
    } = filter;

    const periodoData = `${moment(dataInicial).format("DD/MM/YYYY")} - ${moment(dataFinal).format("DD/MM/YYYY")}`;
    const getSituacoesFiltrada = () => {
        let situacoesSelecionadas = "";
        situacoes.forEach(element => {
            situacoesSelecionadas = element.checked ? situacoesSelecionadas + element.name : situacoesSelecionadas;
        });

        return situacoesSelecionadas || '-';
    };

    return (
        <>
            <div style={styles.titulo}>
                <div style={styles.textBold}>
                    Relatório repasses
                </div>
            </div>
            <div style={styles.subTitulo}>
                <div style={styles.textBold}> 
                    Pesquisado por: {search || " - "} 
                </div>
                <div style={styles.textBold}> 
                    Data: {periodoData}
                </div>
                <div style={styles.textBold}> 
                    Profissional: {profissionalSaude?.nome || " - "} 
                </div>
                <div style={styles.textBold}> 
                    Situação: {getSituacoesFiltrada()} 
                </div>
            </div>

            <div style={styles.containerColuna}>
                <div style={styles.colunaDescricao}>
                    <div style={styles.textBold}>
                       Data
                    </div>
                </div>
                <div style={styles.colunaDescricao}>
                    <div style={styles.textBold}>
                        Paciente
                    </div>
                </div>
                <div style={styles.coluna}>
                    <div style={styles.textBold}>
                        Profissional
                    </div>
                </div>
                <div style={styles.coluna}>
                    <div style={styles.textBold}>
                        Valor
                    </div>
                </div>
            </div>

            {repasses.map((item, index) =>
                renderItem({styles, item, index})
            )}
        </>
    );
};

const styles = {
    text: {
        fontSize: 12
    },
    textBold: {
        fontSize: 12,
        fontWeight: 700,
    },
    titulo: {
        marginLeft: 0,
        marginBottom: 10,
        marginTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
    },
    subTitulo:{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #505050',
        marginBottom: 15,
        paddingBottom: 5,
        justifyContent: 'space-between',
    },
    containerColuna: {
        display: "flex",
        flexDirection: "row",
    },
    coluna: {
        flexGrow: "1",
        width: "50px"
    },
    colunaDescricao: {
        flexGrow: "2",
        width: "90px",
        marginBottom: "10px",
        marginRight: "3px"
    },
};

export default RelatorioRepassePdfDocument;
