import React from "react";

import { withStyles } from "@material-ui/core/styles/index";

import { ReactSelect } from "../../components/Select/SelectSearch";
import ButtonPrimary from "../../components/Button/ButtonPrimary";

const styleInputSearch = () => ({
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FFF",
        "& > h3": {
            color: "#5F6368",
            fontSize: "16px",
        },
        "& > span": {
            color: "#626060",
            fontSize: "14px",
            textDecorationLine: "underline",
            "&:hover": {
                cursor: "pointer"
            }
        }
    },
    reactSelect: {
        width: "100%"
    },
    searchButton: {
        borderRadius: 85,
        width: "50%",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
        "& > span": {
            fontSize: "14px"
        }
    },
    buttons: {
        marginTop: "20px"
    },
    dateTime: {
        width: "100%",
        fontFamily: "Poppins !important",
        fontSize: 12,
        "& input": {
            color: "#505050",
            fontWeight: 500,
        },
    },
});

const FiltrosGuiaServico = (props) => {
    const {
        classes,
        listas,
        filter,
        redefinirFiltros,
        aplicarFiltro,
        handleChangeInput
    } = props;

    return (
        <div>
            <div className={classes.title}>
                <h3>Filtrar</h3>
                <span onClick={() => redefinirFiltros()}>
                    Redefinir filtros
                </span>
            </div>
            <div>
                <div>
                    <span>Convênios </span>
                    <ReactSelect
                        className={classes.reactSelect}
                        name="convenio"
                        placeholder={"Convênio"}
                        value={filter.convenio }
                        onChange={(e) => handleChangeInput(e, 'convenio')}
                        options={listas.convenio || []}
                        isClearable
                        getOptionLabel={(option) => option.descricao}
                        getOptionValue={(option) => option.id}
                    />
                </div>
            </div>
            <div className={classes.buttons}>
                <ButtonPrimary
                    onClick={() => aplicarFiltro()}
                    className={classes.searchButton}
                >
                    Aplicar filtro
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default withStyles(styleInputSearch)(FiltrosGuiaServico);