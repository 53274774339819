import { inject } from "mobx-react";
import React from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import DentesSwitch from "../../../Components/DentesSwitch";

const TipoDente = observer(({ classes, odontogramaStore, setDentesStatus, dentesStatus }) => {
  const { modalConfiguracao } = odontogramaStore;
  const { tipoDenteSelected } = modalConfiguracao;


  const handleSwitchChange = (posicao) => {
    const dentes = dentesStatus[tipoDenteSelected.name];
    const dente = dentes[posicao];

    dentes[posicao] = {
      ...dente,
      status: dente.status === "INATIVO" ? "ATIVO" : "INATIVO",
      alterado: !dente.alterado,
    };

    setDentesStatus({
      ...dentesStatus,
      [tipoDenteSelected.name]: dentes,
    });
  };

  return (
    <div className={classes.content}>
      <div className={classes.title}> Dentes {tipoDenteSelected.name} </div>
      <div className={classes.dentes}>
        <DentesSwitch
          handleSwitchChange={handleSwitchChange}
          dentes={dentesStatus[tipoDenteSelected.name] || []}
        />
      </div>
    </div>
  );
});

const style = {
  content: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#505050",
  },
  dentes: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "space-around",
    "& label": {
      height: 30,
    },
  },
};

const TipoDenteWithStyle = withStyles(style)(TipoDente);
export default inject("odontogramaStore")(TipoDenteWithStyle);
