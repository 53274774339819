import React from "react";
import Dialog from "../../../components/Dialog/Dialog";
import { withStyles, CircularProgress, Fab } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ButtonClearPrimary from "../../../components/Button/ButtonClearPrimary";
import { Grid } from "@material-ui/core/es";

const buttonStyle = {
  maxWidth: 158
};

const styles = theme => ({
  alertTitle: {
    marginTop: 20,
    "& h6": {
      fontSize: 16,
      textAlign: "center"
    }
  },
  alertDescription: {
    lineHeight: "normal",
    textAlign: "center",
    fontSize: 14,
    color: theme.palette.commons.fontColor
  },
  btnClose: {
    position: 'absolute',
    right: 0,
    padding: '13px 10px'
  },
  btnCloseMini: {
      minHeight: 26,
      height: 26,
      width: 26,
      boxShadow: 'none'
  },
  containerButtons: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15
  },
  buttonCadastrarNovo: {
    ...buttonStyle,
    marginRight: 5
  },
  buttonVincular: {
    ...buttonStyle,
    marginLeft: 5
  }
});

const VincularSujeitoAlertDialog = ({
  open,
  onClose,
  onCadastrarNovo,
  onVincular,
  alertDescription,
  alertTitle,
  classes,
  loadingCadastrarNovo,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <div className={classes.btnClose}>
        <Fab
            onClick={onClose}
            classes={{
                sizeSmall: classes.btnCloseMini,
                extendedFab: classes.extendedFab
            }} size="small" color="primary" aria-label="close">
            <CloseIcon />
        </Fab>
    </div>
    {alertTitle && (
      <DialogTitle id="alert-dialog-title" className={classes.alertTitle}>{alertTitle}</DialogTitle>
    )}
    {alertDescription && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.alertDescription}>
          {alertDescription}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogActions style={{ textAlign: 'center' }}>
      <Grid container className={classes.containerButtons}>
          <Grid item xs={12}>
            <ButtonClearPrimary className={classes.buttonCadastrarNovo} onClick={onCadastrarNovo} disabled={loadingCadastrarNovo}>
              <React.Fragment>
                Cadastrar Novo
                {loadingCadastrarNovo && <CircularProgress color="primary" size={12} style={{ marginLeft: 10 }} />}
              </React.Fragment>
            </ButtonClearPrimary>
            <ButtonPrimary className={classes.buttonVincular} onClick={onVincular} autoFocus>
              <React.Fragment>
                Vincular Sujeito
              </React.Fragment>
            </ButtonPrimary>
          </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(VincularSujeitoAlertDialog);
