import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const TripleDotsIcon = props => {
  return (
    <svg 
      width="18" 
      height="4" 
      viewBox="0 0 18 4" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M4.21863 2C4.21863 3.08731 3.33719 3.96875 2.24988 3.96875C1.16257 3.96875 0.281128 3.08731 0.281128 2C0.281128 0.912689 1.16257 0.03125 2.24988 0.03125C3.33719 0.03125 4.21863 0.912689 4.21863 2ZM10.9686 2C10.9686 3.08731 10.0872 3.96875 8.99988 3.96875C7.91257 3.96875 7.03113 3.08731 7.03113 2C7.03113 0.912689 7.91257 0.03125 8.99988 0.03125C10.0872 0.03125 10.9686 0.912689 10.9686 2ZM15.7499 3.96875C16.8372 3.96875 17.7186 3.08731 17.7186 2C17.7186 0.912689 16.8372 0.03125 15.7499 0.03125C14.6626 0.03125 13.7811 0.912689 13.7811 2C13.7811 3.08731 14.6626 3.96875 15.7499 3.96875Z" fill="currentColor"/>
    </svg>
  );
};

export default TripleDotsIcon;