import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const ProfissionalIcon = (props) => {
  return (
    <svg 
      width="26" 
      height="26" 
      viewBox="0 0 26 26" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M18.4751 13.4852C19.7711 13.4852 20.8216 14.5358 20.8216 15.8318V16.6139C20.8216 19.6979 17.9127 22.8713 12.9999 22.8713C8.08713 22.8713 5.17822 19.6979 5.17822 16.6139V15.8318C5.17822 14.5358 6.22879 13.4852 7.52474 13.4852H18.4751ZM12.9999 3.31702C15.3758 3.31702 17.3019 5.24306 17.3019 7.61896C17.3019 9.99486 15.3758 11.9209 12.9999 11.9209C10.624 11.9209 8.69799 9.99486 8.69799 7.61896C8.69799 5.24306 10.624 3.31702 12.9999 3.31702Z" fill="currentColor"/>
    </svg>
  );
};

export default ProfissionalIcon;
