import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';

import { styles, directionButton } from './styles';
import { Button } from '../ui/Buttons';
import Indicators from './Indicators';
import CirclePrevIcon from '../Icon/CirclePrevIcon';
import CircleNextIcon from '../Icon/CircleNextIcon';
import classNames from 'classnames';

const TRANSITION_TIME = 500;

const ImageSlider = ({ classes, images, delay = 5000 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const timer = useRef(null);

  useEffect(() => {
    restartTimer();
    return () => clearInterval(timer.current);
  }, [images.length]);

  const handleClickPrev = () => {
    clearInterval(timer.current);
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentImageIndex(currentImageIndex => (currentImageIndex - 1 + images.length) % images.length);
      setIsTransitioning(false);
      restartTimer();
    }, TRANSITION_TIME);
  };

  const handleClickNext = () => {
    clearInterval(timer.current);
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentImageIndex(currentImageIndex => (currentImageIndex + 1) % images.length);
      setIsTransitioning(false);
      restartTimer();
    }, TRANSITION_TIME);
  }

  const restartTimer = () => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImageIndex(currentImageIndex => (currentImageIndex + 1) % images.length);
        setIsTransitioning(false);
      }, 500);
    }, delay);
  }

  const handleClickIndicator = (newImageIndex) => {
    clearInterval(timer.current);
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentImageIndex(newImageIndex);
      setIsTransitioning(false);
      restartTimer();
    }, TRANSITION_TIME);
    restartTimer();
  }

  const currentImageStyle = {
    opacity: isTransitioning ? 0 : 1,
    transition: 'opacity 0.5s ease-in-out',
  };

  const imageClassnames = classNames(classes.image, images[currentImageIndex].isClickable ? classes.cursorPointer : null);
  const onClickImage = images[currentImageIndex].isClickable ? 
    () => window.open(images[currentImageIndex].redirectURL, '_blank') :
    null;

  return (
    <div className={classes.container}>
      <img
        src={images[currentImageIndex].src} 
        alt={images[currentImageIndex].alt}
        className={imageClassnames}
        style={currentImageStyle}
        onClick={onClickImage}
      />
      <Button
        bgColor="transparent"
        className={classes.prevButton}
        style={directionButton}
        onClick={handleClickPrev}
      >
        <CirclePrevIcon />
      </Button>
      <Button
        bgColor="transparent"
        className={classes.nextButton}
        style={directionButton}
        onClick={handleClickNext}
      >
        <CircleNextIcon />
      </Button>
      <Indicators 
        size={images.length} 
        currentIndex={currentImageIndex} 
        onClickIndicator={handleClickIndicator}
      />
    </div>
  );
};

export default withStyles(styles)(ImageSlider);
