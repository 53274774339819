import Api from '../config/api'

export const consultaDeCep = (cep) => {
  return Api.post("", {
    query: `
      query ($cep: String) {
        endereco: consultaDeCep(cep: $cep) {
          bairro,
          cidade {
            id,
            nome
          },
          logradouro,
        }
      }
    `,
    variables: { cep }
  });
};

export const findMunicipioById = (municipioId) => {
  return Api.post("", {
    query: `
      query ($id: Long) {
        cidade: findByIdMunicipio(id: $id) {
          estado {
            id,
            uf
          }
        }
      }
    `,
    variables: { id:  municipioId}
  });
};

export const findEstadoById = (id) => {
  return Api.post("", {
    query: `
      query ($id: Long!) {
        findByEstado(estado: {id: $id}) {
          id,
          nome
        }
      }
    `,
    variables: { id },
  });
};
