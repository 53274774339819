import React from 'react';
import { withStyles, IconButton } from '@material-ui/core';
import styles from '../styles';
import Button from '../../../../components/ui/Buttons/Button';
import FilterIcon from '../../../../components/Icon/FilterIcon';
import { InputForm } from '../../../../components/Modal/Input';
import ItemGrid from '../../../../components/Modal/ItemGrid';
import SearchIcon from '../../../../components/Icon/Search';

const Filter = ({ classes, onClickFilterButton, onChange, filter, handleApplyFilter, isLoading }) => {
  const handleKeyPress = () => {
    handleApplyFilter();
  }

  return (
    <div className={classes.filterContainer}>
      <div style={{ flex: 1 }}>
        <ItemGrid 
          label="Pesquise por pacientes" 
          classes={{
            item: classes.item
          }}
        >
          <InputForm
            placeholder="Pesquisar"
            className={classes.inputTextField}
            onChange={(e) => onChange(e.target.value, 'search')}
            onKeyDown={(e) => e.key === 'Enter' && handleKeyPress()}
            value={filter.search}
            startAdornment={(
              <IconButton
                className={classes.searchButton}
                onClick={handleApplyFilter}
                disabled={isLoading}
              >
                <SearchIcon />
              </IconButton>
            )} 
          />
        </ItemGrid>
      </div>
      <Button
        kind="secondary"
        startEnhancer={
          <FilterIcon size={12} color="white"/>
        }
        onClick={onClickFilterButton}
        className={classes.filterButton}
      >
        Filtrar
      </Button>
    </div>
  );
};

export default withStyles(styles)(Filter);