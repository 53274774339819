import React from "react";
import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/pages/dashboardStyle";
import Footer from "../../components/Footer/Footer";
import Header from "../../template/Header/Header";
import Profile from "../../template/Header/Profile";
import moment from "moment";
import { applyCurrencyMask } from "../../utils/CurrencyMask";
import PieChart from "../../components/Charts/PieChart";
import TermoUsoModal from "./TermoUso/TermoUsoModal";
import ButtonGray from "../../components/Button/ButtonGray";
import { referenciaAzulControleUnidadeLogado, findVersaoSistemaByOauthClientId } from "../../services/UnidadeService";
import { linkLoginAzulControle } from "../../services/FinanceiroService";
import { acessoFinanceiro } from "../../services/UsuarioService";
import Notification from "../../components/Notification";
import VersoesSistemaModal from "./VersoesSistema/VersoesSistemaModal";
import PageTitle from "../../components/PageTitle/PageTitle";
import CardFinanceiro from "./CardFinanceiro";
import { RelatoriosIcon, SaldoIcon, TotalEntradasIcon, TotalSaidasIcon } from "../../assets/img/svg";
import CardGrafico from "./CardGrafico";
import ButtonLong from "../../components/Button/ButtonLong";
import GraficoAtendimentos from "./Graficos/GraficoAtendimentos";
import string from "../../utils/string";
import GraficoTempoEspera from "./Graficos/GraficoTempoEspera";
import Publicidade from "./Publicidade";
import { findAllByUsuarioLogadoPublicidade } from "../../services/PublicidadeService";
import DashboardFilters from "./DashboardFilters";
import { filterDateDefault } from "../../stores/Dashboard.store";
import { checkUserRole } from "../../utils/checkUserRole";
import RelatorioDashboardIcon from "../../components/Icon/RelatoriosDashboardIcon";

@inject("dashboardStore","termoUsoStore", "googleAnalyticsStore", "usuarioStore", "profissionalSaudeStore")
@observer
class Dashboard extends React.Component {
	state = {
        referenciaAzulControle: null,
		acessoFinanceiroRole: null,
		notification: {
			isOpen: false,
			message: ''
		},
		versoesSistema: [],
		openModalVersoes: false,
		publicidades: [],
		showPublicidade: false,
		canViewDashboard: false,
    };

	async componentDidMount() {
		const { termoUsoStore, profissionalSaudeStore } = this.props;

		await termoUsoStore.buscarTermoUso("CONDICOES_USO")
		const canViewDashboard = await checkUserRole('ROLE_DASHBOARD_READ');
		await profissionalSaudeStore.findAllProfissionalSaudeByUnidade();
		await this.verificaSelectedUnidade();
		const referenciaAzulControle = await referenciaAzulControleUnidadeLogado();
		const acessoFinanceiroRole = await acessoFinanceiro();
		const versoesSistema = await findVersaoSistemaByOauthClientId("APPHEALTH_WEB");
		this.getPublicidades();
		
		this.setState({ 
			referenciaAzulControle,
			acessoFinanceiroRole,
			versoesSistema,
			openModalVersoes: versoesSistema?.length > 0,
			canViewDashboard,
		})

		const paginaVisualizada = {
			page: window.location.hash, 
			title: 'Dashboard'
		  }
		this.props.googleAnalyticsStore.pageView(paginaVisualizada);
	}

	getPublicidades = async() => {
		const publicidades = await findAllByUsuarioLogadoPublicidade();
		this.setState({ 
			publicidades,
			showPublicidade: publicidades?.length > 0,
		});
	};

	verificaSelectedUnidade = async() => {
		const { dashboardStore, usuarioStore } = this.props;

		dashboardStore.filterDate = filterDateDefault;
		const isPermiteUnidade = await dashboardStore.verificaPermissaoUnidade(); 

		const unidadeLogada = await usuarioStore.getUnidadeAtual();

		dashboardStore.profissionalSaudeSelected = isPermiteUnidade ? {
			id: 'unidade',
        	nome: unidadeLogada.nome
		} : {
			id: null
		};

		dashboardStore.loadDados({ 
			profissionalSaudeId: dashboardStore.profissionalSaudeSelected?.id === "unidade" ? null : dashboardStore.profissionalSaudeSelected?.id,
			dataInicial: filterDateDefault.dataInicial,
			dataFinal: filterDateDefault.dataFinal,
		})
	};

	renderTooltipChartSaldo({ active, payload, label }) {
		if (active && payload && payload[0]) {
			return (
				<div className="custom-tooltip">
					<p className="label">{`${applyCurrencyMask(
						payload[0].payload.value
					)}`}</p>
				</div>
			);
		}

		return null;
	}

	renderTickFormatterSaldo(value) {
		if (!value) return "";
		return `${applyCurrencyMask(value)}`;
	}

	renderTooltipChartMediaTempoAtendimento({ active, payload, label }) {
		if (active && payload && payload[0]) {
			return (
				<div className="custom-tooltip">
					<p className="label">{`${payload[0].payload.tempo} min`}</p>
				</div>
			);
		}

		return null;
	}

	renderTickFormatterMediaTempoAtendimento(value) {
		if (!value) return "";
		return `${value} min`;
	}

	renderTooltipChartAgendamentosAtendido({ active, payload, label }) {
		if (active && payload && payload[0]) {
			const value = payload[0].payload.value;
			return (
				<div className="custom-tooltip">
					<p className="label">{`${value} agendamento${value > 1 ? "s" : ""}`}</p>
				</div>
			);
		}

		return null;
	}

	renderTooltipChartAgenda({ active, payload, label }) {
		if (active && payload && payload[0]) {
			return (
				<div className="custom-tooltip">
					<p className="label">{`${payload[0].payload.value}`}</p>
				</div>
			);
		}

		return null;
	}

	renderValorTotal(valor) {
		const { canViewDashboard } = this.state;

		if (!canViewDashboard) return;
		const valorFormatado = applyCurrencyMask(valor);

		const centavos = valorFormatado.split(",")[1] || "00";
		const valorSemCentavos = valorFormatado.split(",")[0] || "0";

		return (
			<>
				<span>R$ </span>
				{valorSemCentavos.replace("R$ ", "")}
				<span>,{centavos}</span>
			</>
		);
	}

	openFinanceiro = async() => {
		const linkAzulControle = await linkLoginAzulControle();

		if(!linkAzulControle){
			const notification = {
                isOpen: true,
                message: 'Erro ao entrar no financeiro.'
              }
              this.showAlertMessage(notification);
			return;
		}
		window.open(linkAzulControle);
	};

	showAlertMessage = (notification) => {
        this.setState({ notification });

        const timeoutId = setTimeout(() => {
          this.closeAlertMessage();
          clearTimeout(timeoutId);
        }, 3000);
      };

      closeAlertMessage = () => {
        const notification = {
          isOpen: false,
          message: ''
        };
        this.setState({ notification });
      };

	  closeModalVersoes = () => {
		  this.setState({ openModalVersoes: false })
	  }

	  acessarRelatorios = () => {
		window.location.replace('#/relatorios/agendamentos')
	  };

	  renderInformacoesAgenda = () => {
		const { dashboardStore, classes } = this.props;

		const total = dashboardStore.agendaGraphData[0]?.value + dashboardStore.agendaGraphData[1]?.value;

		const porcentagemAgendados = total === 0 ? 0 : ((dashboardStore.agendaGraphData[0]?.value/total)*100).toFixed(0);
		const porcentagemAtendidos = total === 0 ? 0 : ((dashboardStore.agendaGraphData[1]?.value/total)*100).toFixed(0);

		return(
			<>
				<div className={classes.informacaoAgenda}> Pacientes agendados { dashboardStore.agendaGraphData[0] ? porcentagemAgendados : '0'}% </div>
				<div className={classNames(classes.informacaoAgenda, classes.informacaoAtendido)}> Pacientes atendidos {dashboardStore.agendaGraphData[1] ? porcentagemAtendidos : '0'}% </div>
			</>
		)
	  };

	  handleClosePublicidade = () => {
		this.setState({ showPublicidade: false });
	  };

	  handleFilter = (e) => {
		this.setState({ 
			filter: e
		});
	};

	render() {
		const { classes, dashboardStore, termoUsoStore, history } = this.props;
		const {
			valorTotalReceita,
			valorTotalDespesa,
			saldoTotalTituloParcela,
		} = dashboardStore;
		const { referenciaAzulControle, acessoFinanceiroRole, versoesSistema, showPublicidade, publicidades } = this.state;

		const infosCardFinanceiro = [
			{ 
				title: "Total de entradas", 
				icon: TotalEntradasIcon,
				info: this.renderValorTotal(valorTotalReceita),
				color: "#26ACA9",
			},
			{ 
				title: "Total de saídas", 
				icon: TotalSaidasIcon,
				info: this.renderValorTotal(valorTotalDespesa),
				color: "#FB7676",
			},
			{ 
				title: "Saldo", 
				icon: SaldoIcon,
				info: this.renderValorTotal(saldoTotalTituloParcela),
				color: "#73C8F9",
			},
		];

		const graphTotalAtendimentoMes1 = string.capitalize(moment(dashboardStore.filterDate.dataInicial).format("MMM"));
		const graphTotalAtendimentoMesAnterior = string.capitalize(moment(dashboardStore.filterDate.dataInicial).subtract(1,'months').format("MMM"));

		return (
			<div className={classes.root}>
				<PageTitle title="Dashboard"/>
				<Header padding>
					<Grid item xs={12}>
						<Grid container justify={"space-between"} alignItems={"center"}>
							<Grid item className={classes.title}>
								Dashboard
							</Grid>

							<Grid item className={classes.buttonProfile}>
								{ referenciaAzulControle && acessoFinanceiroRole && 
								<ButtonGray 
									onClick={this.openFinanceiro}	
								>
									Ir para financeiro
								</ButtonGray>}
								<Profile />
							</Grid>
						</Grid>
					</Grid>
				</Header>
				<div className={classes.content}>
					<DashboardFilters />
					<Grid container spacing={16}>
						{ infosCardFinanceiro.map(item => (
							<Grid item xs={3}>
								<CardFinanceiro
									{...item}
								/>
							</Grid>
							)
						)}
						<Grid item xs={3} >
							<div
								className={classes.cardRelatorio}
								onClick={this.acessarRelatorios}
							>
								<div className={classes.cardText}>
									<RelatorioDashboardIcon size={24} />
									<span > Acessar relatórios </span>
								</div>
							</div>

						</Grid>
					</Grid>

					<Grid container spacing={16}>
						<Grid item xs={6}>
							<CardGrafico
								title="Total de Atendimentos"
							>
								<GraficoAtendimentos
									data={dashboardStore.atendimentosAtendidoData}
									datasKey={[graphTotalAtendimentoMes1, graphTotalAtendimentoMesAnterior]}
								/>
							</CardGrafico>
						</Grid>
						<Grid item xs={3}>
							<CardGrafico title="Tempo espera">
								<GraficoTempoEspera
									data={dashboardStore.mediaTempoEsperaData}
									datasKey={["tempo"]}
									TooltipProps={{
										content: this.renderTooltipChartMediaTempoAtendimento,
									}}
								/>
							</CardGrafico>
						</Grid>
						<Grid item xs={3}>
							<CardGrafico title="Agenda">
								<PieChart
									data={dashboardStore.agendaGraphData}
									PieProps={{ dataKey: "value" }}
									TooltipProps={{ content: this.renderTooltipChartAgenda }}
								/>
								<div className={classes.divider} />
								{ this.renderInformacoesAgenda() }
							</CardGrafico>
						</Grid>
					</Grid>
				</div>

				{termoUsoStore.isOpenTermoUsoModal && 	        
					<TermoUsoModal
						open={termoUsoStore.isOpenTermoUsoModal}
						documento={termoUsoStore.termoUso}
						tipo={"CONDICOES_USO"}
						titulo={"Termos e Condições de Uso do APP Health"}
						handleAccept={termoUsoStore.assinarTermoUso}
						handleRefuse={ () => termoUsoStore.refuseTermoUso(history)}
	       		 />}
				{
					!termoUsoStore.isOpenTermoUsoModal && this.state.openModalVersoes && 
					<VersoesSistemaModal
						open={this.state.openModalVersoes}
						versoes={versoesSistema}
						screen="Dashboard"
						close={this.closeModalVersoes}
					/>
				}
				<Notification
					close={this.closeAlertMessage}
					reset={this.closeAlertMessage}
					isOpen={this.state.notification.isOpen}
					variant={'error'}
					message={this.state.notification.message}
				/>
				{ showPublicidade && <Publicidade closePublicidade={this.handleClosePublicidade} publicidade={publicidades[0]}/> }

				<Footer />
			</div>
		);
	}
}

export default withStyles(styles)(Dashboard);
