import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const OpenIcon = props => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M7.91571 5.99007C6.83576 5.99007 5.96028 6.86554 5.96028 7.9455V18.1137C5.96028 19.1937 6.83576 20.0692 7.91571 20.0692H18.0839C19.1639 20.0692 20.0394 19.1937 20.0394 18.1137V15.7672C20.0394 15.1192 20.5647 14.594 21.2126 14.594C21.8606 14.594 22.3859 15.1192 22.3859 15.7672V18.1137C22.3859 20.4896 20.4598 22.4157 18.0839 22.4157H7.91571C5.53981 22.4157 3.61377 20.4896 3.61377 18.1137V7.9455C3.61377 5.5696 5.53981 3.64355 7.91571 3.64355H10.2622C10.9102 3.64355 11.4355 4.16884 11.4355 4.81681C11.4355 5.46478 10.9102 5.99007 10.2622 5.99007H7.91571ZM12.9994 4.81681C12.9994 4.16884 13.5247 3.64355 14.1727 3.64355H21.2122C21.8602 3.64355 22.3855 4.16884 22.3855 4.81681V11.8564C22.3855 12.5043 21.8602 13.0296 21.2122 13.0296C20.5643 13.0296 20.039 12.5043 20.039 11.8564V7.64975L15.0023 12.6863C14.5441 13.1445 13.8013 13.1445 13.3431 12.6863C12.8849 12.2281 12.8849 11.4853 13.3431 11.0271L18.3802 5.99007H14.1727C13.5247 5.99007 12.9994 5.46478 12.9994 4.81681Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default OpenIcon
