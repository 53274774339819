import { conformToMask } from "react-text-mask";
import string from "./string";

export const phoneMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const cellphoneMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const phoneEspanhaMask = [
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
];

export const applyPhoneMask = phoneNumber => {
  if (string.isEmpty(phoneNumber)) {
    return "";
  }

  const mask = phoneNumber.length < 11 ? phoneMask : cellphoneMask;

  const conformedPhoneNumber = conformToMask(phoneNumber, mask, {
    guide: false
  });

  return conformedPhoneNumber.conformedValue;
};

export const applyPhoneEspanhaMask = phoneNumber => {
  if (string.isEmpty(phoneNumber)) {
    return "";
  }

  const mask = phoneEspanhaMask;

  const conformedPhoneNumber = conformToMask(phoneNumber, mask, {
    guide: false
  });

  return conformedPhoneNumber.conformedValue; 
};

export const applyInternationalPhoneMask = (phoneNumber, dialCode) => {
  let mask = [];
  if (string.isEmpty(phoneNumber)) {
    return "";
  }

  if (dialCode === '55') {
    const phoneNumberMask = phoneNumber.length < 11 ? phoneMask : cellphoneMask
    mask = ['+', /\d/, /\d/, ' ', ...phoneNumberMask];

  } else if(dialCode === '34') {
    mask = ['+', /\d/, /\d/, ' ', ...phoneEspanhaMask];
  } else {
    if (dialCode?.length > 0) {
      const dialCodeMask = dialCode.split('').map(() => {
        return /\d/;
      });
      const phoneNumberMask = phoneNumber.split('').map(() => {
        return /\d/;
      });
      mask = ['+', ...dialCodeMask, ' ', ...phoneNumberMask];
    } else {
      mask = phoneNumber.split('').map(() => {
        return /\d/;
      });
    }
  }
  const conformedPhoneNumber = conformToMask(dialCode + phoneNumber, mask, {
    guide: false
  });
  return conformedPhoneNumber.conformedValue;
}
