import axios from 'axios';
import { action, observable } from 'mobx';

import localStorageService, { ACCESS_TOKEN_KEY } from '../services/storage';
import { createSocketConnection } from '../services/SocketService';

import { getBestChatUrl } from '../utils/getBestChatUrl';
import { checkUserRole } from '../utils/checkUserRole';

export default class SocketStore {
  @observable socket = null;
  @observable usuarioStore = null;
  @observable isConnectionOpen = false;
  @observable ChatApi = null;
  @observable chatUrl = null;
  @observable isApiInitialized = false;

  constructor(rootStore) {
    this.usuarioStore = rootStore.usuarioStore;
  }

  @action initializeApi = async () => {
    const urls = JSON.parse(process.env.REACT_APP_CHAT_URLS);
    try {
      const chatUrl = await getBestChatUrl(urls);
      if (!chatUrl) {
        return;
      }
  
      const ChatApi = axios.create({
        baseURL: `${chatUrl}/api/v1/graphql`,
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      this.ChatApi = ChatApi;
      this.chatUrl = chatUrl;
    } catch(error) {
      console.error(error);
    }
  };

  @action connect = async (isChangeUnidade) => {
    if (this.isConnectionOpen && isChangeUnidade) {
      this.disconnect()
    }

    const hasPermission = await checkUserRole('ROLE_CHAT_READ');
    if (!hasPermission) {
      return;
    }

    if (!this.chatUrl) return;

    try {
      const { id: profissionalSaudeId } = await this.usuarioStore.findProfissionalSaudeLogado();
      const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);

      this.socket = createSocketConnection(profissionalSaudeId, accessToken, this.chatUrl);
      this.isConnectionOpen = true;
      console.log(`Socket connected to ${this.chatUrl}`);
    } catch(error) {
      console.error(error);
    }
  }

  @action disconnect = () => {
    this.socket.close();
    this.socket = null;
    this.isConnectionOpen = false;
  }

  @action resetSettings = () => {
    this.socket = null;
    this.isConnectionOpen = false;
    this.isApiInitialized = false;
    this.ChatApi = null;
    this.chatUrl = null;
  }
}
