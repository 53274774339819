import React from 'react'
import { withStyles } from '@material-ui/core'
import { STATUS } from '../../utils/statusBadge'

const StatusBadge = ({ classes, status }) => {
  if (!status) return null
  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: STATUS[status]?.color,
        border: `0.5px solid ${STATUS?.[status]?.borderColor}`,
      }}
    >
      <div
        className={classes.badge}
        style={{
          backgroundColor: STATUS?.[status]?.borderColor,
        }}
      />
      <span className={classes.text}>{STATUS?.[status]?.text}</span>
    </div>
  )
}

const styles = {
  container: {
    width: 'fit-content',
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '8px',
  },
  badge: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    border: '1.5px solid rgba(220, 220, 220, 0.2)',
  },
  text: {
    color: '#505050',
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: '0 8px',
  },
}

export default withStyles(styles)(StatusBadge)
