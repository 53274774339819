import { withStyles, IconButton } from "@material-ui/core";
import React from "react";
import StarIcon from '@material-ui/icons/Star';
import RemoveIcon from "@material-ui/icons/Remove";
import classNames from "classnames";
import colors from '../../../../template/Colors';

const RowUnificacao = props => {
    const { classes, cadastro, handleClickTrocaCadastroPrincipal, handleClickRemoveCadastro, posicao } = props;

    const numeroPosicao = cadastro.principal ? "01" : String(posicao+2).padStart(2, '0')

    return(
        <div className={classNames(classes.row, cadastro.principal ? classes.rowCadastroPrincipal : classes.rowCadastroOutros)}>
            <div>
                {`${numeroPosicao}.`} 
            </div>
            <div>
                {cadastro.nome}
            </div>
            <div>
                {cadastro.dataNascimento}
            </div>
            <div className={classes.contentButtons}>
                <IconButton
                    onClick={() => { !cadastro.principal && handleClickTrocaCadastroPrincipal()}}
                    className={classNames(classes.buttonUnificacao, cadastro.principal ? classes.buttonPrincipal : classes.buttonOutros) }
                >
                    <StarIcon className={classes.icon}/>
                </IconButton>    

                <IconButton
                    onClick={() => {!cadastro.principal && handleClickRemoveCadastro()}}
                    className={classNames(classes.buttonUnificacao, classes.buttonRemoveUnificacao, cadastro.principal && classes.buttonDisabled)}
                    disabled={cadastro.principal}
                >
                    <RemoveIcon className={classes.icon}/>
                </IconButton>            
            </div>
        </div>
    )
};

const styles = {
    row: {
        display: 'grid',
        gridTemplateColumns: '7% 55% 20% 18%',
        alignItems: 'center',
        color: colors.commons.gray9,
        padding: '8px 2px 8px 8px',
    },
    rowCadastroOutros: {
        borderBottom: `1px solid #F5F5F5`,
        marginBottom: '8px',
    },
    rowCadastroPrincipal: {
        marginBottom: '6px',
    },
    contentButtons: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    buttonUnificacao: {
        width: 20,
        height: 20,
    },
    buttonPrincipal: {
        background: colors.commons.yellow2,
        "&:hover": {
            background: colors.commons.yellow2,
            cursor: 'default',
        }
    },
    buttonOutros: {
        background: colors.commons.gray6,
    },
    icon: {
        color: '#fff',
        width: '17px',
        height: '17px',
    },
    buttonRemoveUnificacao: {
        background: colors.commons.red,
    },
    buttonDisabled: { 
        opacity: '0.3',
    }
}

export default withStyles(styles)(RowUnificacao);
