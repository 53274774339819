import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import {withStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./SujeitoAtencaoAnexoStyle";
import Dialog from "../../../../components/Dialog/Dialog";
import { buildUrlSujeitoAtencaoAnexo } from "../../../../config/config";
import localStorageService, {
  ACCESS_TOKEN_KEY,
} from "../../../../services/storage";
import { downloadImage } from '../../../../utils/downloadImagem';
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { Button } from "../../../../components/ui/Buttons";
import ArrowDownIcon from "../../../../components/Icon/ArrowDownIcon";

const ModalAnexo = (props) => {
  const { open, closeModal, classes, anexo } = props;

  const [urlAnexo, setUrlAnexo] = useState("");

  useEffect(() => {
    imageUrl();
  }, []);

  const imageUrl = async () => {
    const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
    const url = anexo.amazonS3Objeto.id;

    const urlImage = buildUrlSujeitoAtencaoAnexo(
      url,
      accessToken,
      "imagem/completa"
    );
    setUrlAnexo(urlImage);
  };

  return (
    <>
    <PageTitle title="Paciente - Visualizar arquivo"/>
    <Dialog
      open={open}
      fullWidth={true}
      classes={{
        paper: classes.dialog,
      }}
    >
      <div className={classes.modalContent}>
        <div className={classes.headerModalVisualizacaoAnexo}>
          <div className={classes.titleHeaderModalVisualizacaoAnexo}>
            {anexo?.amazonS3Objeto?.nomeComExtensao || ""}
          </div>
          <div className={classes.buttonsHeaderModalVisualizacaoAnexo}>
            <Button
              onClick={() => downloadImage(anexo.amazonS3Objeto?.nome, urlAnexo)}
              shape='circle'
              bgColor='#707C97'
              shadow
            >
              <ArrowDownIcon/>
            </Button>
            <Button
              onClick={closeModal}
              shape='circle'
              bgColor='#FB7676'
              shadow
              className={classes.buttonClose}
            >
              <CloseIcon/>
            </Button>
          </div>
        </div>
        <div className={classes.contentModalVisualizarAnexo}>
          {anexo?.tipo === "IMAGEM" && (
            <img
              src={urlAnexo}
              alt={anexo?.amazonS3Objeto?.nome}
              style={{
                maxWidth: "95%",
                maxHeight: "95%",
              }}
            />
          )}
        </div>
      </div>
    </Dialog>
    </>
  );
};

const ModalAnexodWithStyles = withStyles(styles)(ModalAnexo);
export default inject("sujeitoAtencaoAnexoStore")(ModalAnexodWithStyles);
