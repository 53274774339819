import React from "react";
import classNames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import CheckboxFaces from "../../../Components/CheckboxFaces";
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import { ReactSelectCheckbox } from "../../../../../../../components/Select/SelectSearch";

const RowProcedimentos = observer(
  ({
    classes,
    procedimento,
    posicao,
    handleCheckProcedimento,
    handleCheckFace,
    showLocal,
    changeCheckboxLocal,
  }) => {
    const isDente = () => {
      return procedimento.localOdontograma.some(
        (item) => item?.checked && item?.local === 4
      );
    };

    const verificaPossuiDenteTipoFace = () => {
      return !showLocal && procedimento.denteTipoFace?.length > 0;
    };

    const locaisOdontograma = procedimento.localOdontograma.map((item) => {
      return {
        ...item,
        label: item.descricao,
        value: item.id,
      };
    });

    return (
      <div className={classes.content} key={procedimento.id}>
        <div className={classes.contentRowProcedimento}>
          <div className={classes.rowProcedimento}>
            <FormControlLabel
              label={procedimento.nome}
              className={classes.formControlLabelCheckbox}
              control={
                <Checkbox
                  color={"primary"}
                  onChange={() =>
                    handleCheckProcedimento(procedimento, posicao)
                  }
                  checked={procedimento.checked}
                />
              }
            />
          </div>
          {showLocal && (
            <div>
              <ReactSelectCheckbox
                className={classNames(
                  classes.reactSelectCheckbox,
                  !procedimento.checked && classes.reactSelectCheckboxDisabled
                )}
                onChange={changeCheckboxLocal}
                options={locaisOdontograma || []}
                isDisabled={!procedimento.checked}
              />
            </div>
          )}
        </div>
        {(verificaPossuiDenteTipoFace() || isDente()) && (
          <CheckboxFaces
            dentesTipoFace={procedimento.denteTipoFace}
            disabled={!procedimento.checked}
            handleCheckFace={handleCheckFace}
            procedimento={procedimento}
            posicao={posicao}
          />
        )}
      </div>
    );
  }
);

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "8px",
    "& svg": {
      fontSize: "14px",
    },
  },
  contentRowProcedimento: {
    display: "grid",
    gridTemplateColumns: "70% 30%",
  },
  rowProcedimento: {
    display: "flex",
    flexDirection: "row",
  },
  codigoTermoDescricao: {
    color: "#868686",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
  },
  checkLocal: {
    borderRadius: "8px",
    marginBottom: "4px",
  },
  popper: {
    width: "auto",
    maxHeight: "100px",
    overflow: "auto",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
  },
  reactSelectCheckbox: {
    "&>div>div": {
      paddingRight: "0",
    },
  },
  reactSelectCheckboxDisabled: {
    opacity: 0.5,
  },
  formControlLabelCheckbox: {
    marginTop: 0,
    marginBottom: 5,
    paddingLeft: 15,
    "& input": {
      color: "#00B0AE",
    },
    "& svg": {
      fontSize: "20px",
    },
  },
};

const RowProcedimentosWithStyles = withStyles(styles)(RowProcedimentos);
export default inject("odontogramaStore")(RowProcedimentosWithStyles);
