import colors from '../../../template/Colors'

export const styles = () => ({
  fullSizePaper: {
    width: '100%',
    minHeight: '600px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: '8px',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1rem',
  },
  title: {
    color: colors.commons.padrao,
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
    padding: '16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  contentDocument: {
    maxWidth: '370px',
    width: '100%',
    height: '100%',
    minHeight: '600px',
    background: colors.commons.secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      color: 'red',
    },
  },
  headerDocument: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1rem 0 1rem',
  },
  descriptionDocument: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      color: colors.commons.white,
      fontSize: '0.875rem',
      maxWidth: '100%',
      fontWeight: '400',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  wrapperDocuments: {
    height: '100%',
    minHeight: '400px',
    maxHeight: '400px',
    borderRadius: '10px',
    margin: '1rem',
    padding: '1rem',
    background: colors.commons.white,
    overflow: 'auto',
  },
  wrapperActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  wrapperButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    padding: '0 1rem',
  },
  buttonProntuario: {
    background: '#75809A',
    border: '2px solid rgba(220, 220, 220, 0.2)',
    padding: '1rem',
    boxShadow: 'none',
    display: 'flex',
    textTransform: 'none',
    alignItems: 'center',
    '&:hover': {
      background: '#2D4884',
      opacity: '0.95',
    },
    '&>span': {
      fontSize: '0.875rem',
      fontWeight: '700',
      color: '#FFF',
    },
    '&:disabled': {
      background: '#75809A',
      opacity: '0.95',
    },
  },
})
