import React from 'react';
import { Checkbox } from "@material-ui/core";
import moment from "moment";
import SituacaoTableCell from "../Modais/components/SituacaoTableCell";

const tableDateStyle = {
  whiteSpace: 'nowrap', 
}

export const getAgeendamentosTableHeader = (
  onCheckAgendamento, 
  agendamentoIsChecked, 
  toggleAllCheckboxes, 
  toggleAllCheckboxesLastValue
) => {
  return [
    {
      Header: <Checkbox color='primary' checked={toggleAllCheckboxesLastValue} onClick={toggleAllCheckboxes} />,
      getValue: row => (
        <>
          <Checkbox 
            color='primary' 
            checked={agendamentoIsChecked(row)} 
            onChange={(event) => onCheckAgendamento(row, event)}
            onKeyDown={(event) => event.stopPropagation()}
          />
        </>
      ),
      props: {center: 'true'}
    },
    {
      Header: 'DATA',
      getValue: row => {
        return moment(row?.data).format('DD-MM-YYYY') || '';
      },
      style: tableDateStyle,
      field: 'data'
    },
    {
      Header: 'PACIENTE',
      getValue: row => {
        return row?.nome || '';
      },
      field: 'nome'
    },
    {
      Header: 'INICIO',
      getValue: row => {
        return row?.horaInicio || '';
      },
      field: 'horaInicio'
    },
    {
      Header: 'FIM',
      getValue: row => {
        return row?.horaFim || ''
      },
      field: 'horaFim'
    },
    {
      Header: 'PROFISSIONAL',
      getValue: row => {
        return row.profissionalSaude?.nome || '';
      },
      field: 'profissionalSaude.nome'
    },
    {
      Header: 'STATUS',
      getValue: row => (
        <SituacaoTableCell situacao={row?.situacao} />
      ),
      field: 'situacao'
    },
  ];
};