import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const ArrowLeftIcon = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M12.2673 16.293C11.9674 16.5787 11.4926 16.5672 11.2069 16.2672L6.20561 11.0168C5.92968 10.7271 5.92968 10.2719 6.20561 9.9822L11.2069 4.73173C11.4926 4.43181 11.9674 4.42028 12.2673 4.70597C12.5672 4.99166 12.5787 5.46639 12.2931 5.76631L7.78447 10.4995L12.2931 15.2327C12.5787 15.5326 12.5672 16.0073 12.2673 16.293Z"
        fill="#505050"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
