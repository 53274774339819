import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core";
import {
    AfericaoVital,
    DadoAntropometrico,
    AnamneseIcon,
    ProcedimentosIcon,
    SADTIcon,
    DocumentoIcon,
    MedicineIcon,
    GuiaInternacaoIcon
} from "../../assets/img/svg";
import ButtonOptions from "./ButtonOptions";
import LabelButton from "../../components/Button/LabelButton";
import { checkUserRole } from '../../utils/checkUserRole';

@inject("prontuarioStore")
@observer
class OpcoesProntuario extends React.Component {
    state = {
        openOptions: false
    }
    
    async componentDidMount() {
        const { prontuarioStore } = this.props;
        const canCreateGuiaInternacao = await checkUserRole('ROLE_GUIA_INTERNACAO_CREATE');

        prontuarioStore.canCreateGuiaInternacao = canCreateGuiaInternacao;
    }

    clickOptions = (tipo) => {
        this.changeOptionsState(false);
        switch (tipo) {
            case 'receita':
                return this.props.handleOpenReceituario();
            case 'documento':
                return this.props.handleDocument();
            case 'sadt':
                return this.props.handleClickSADT();
            case 'guiaInternacao':
                return this.props.handleClickGuiaInternacao();
            case 'procedimento':
                return this.props.handleClickSolicitacaoProcedimento();
            case 'anamnese':
                return this.props.handleClickAnamnese();
            case 'avaliacaoAntropometrica':
                return this.props.handleClickAvaliacaoAntropometrica();
            case 'triagem':
                return this.props.handleClickTriagem();
            default:
                return ;
        }
    }

    changeOptionsState = (value) => {
        this.setState({openOptions: value});
    }

    renderButtons = hiddenLabel => {
        const { classes, hiddenProcedimentosButton,sujeitoAtencaoId } = this.props;
        const hiddenLabelButton = hiddenLabel === "hiddenLabel";
        const { canCreateGuiaInternacao } = this.props.prontuarioStore;

        return(
            <div className={classes.buttonsContent}>
                <LabelButton
                    hiddenLabel = {hiddenLabelButton}
                    title={"Receita"}
                    icon={MedicineIcon}
                    onClick={() => this.clickOptions('receita')}
                    disabled={!sujeitoAtencaoId}
                />
                <LabelButton
                    hiddenLabel = {hiddenLabelButton}
                    title={"Documento"}
                    icon={DocumentoIcon}
                    onClick={() => this.clickOptions('documento')}
                    disabled={!sujeitoAtencaoId}
                />
                <LabelButton
                    hiddenLabel = {hiddenLabelButton}
                    title={"SP/SADT"}
                    icon={SADTIcon}
                    onClick={() => this.clickOptions('sadt')}
                    disabled={!sujeitoAtencaoId}
                />
                {canCreateGuiaInternacao && (
                    <LabelButton
                        hiddenLabel = {hiddenLabelButton}
                        title={"Guia de internção"}
                        icon={GuiaInternacaoIcon}
                        onClick={() => this.clickOptions('guiaInternacao')}
                        disabled={!sujeitoAtencaoId}
                    />
                )}
                {!hiddenProcedimentosButton &&
                    <LabelButton
                        hiddenLabel = {hiddenLabelButton}
                        title={"Procedimentos"}
                        icon={ProcedimentosIcon}
                        onClick={() => this.clickOptions('procedimento')}
                        disabled={!sujeitoAtencaoId}
                    />
                }
                <LabelButton
                    hiddenLabel = {hiddenLabelButton}
                    title={"Anamnese"}
                    icon={AnamneseIcon}
                    onClick={() => this.clickOptions('anamnese')}
                    disabled={!sujeitoAtencaoId}
                />
                <LabelButton
                    hiddenLabel = {hiddenLabelButton}
                    title={"Dados antropométricos"}
                    icon={DadoAntropometrico}
                    onClick={() => this.clickOptions('avaliacaoAntropometrica')}
                    disabled={!sujeitoAtencaoId}
                />
                <LabelButton
                    hiddenLabel = {hiddenLabelButton}
                    title={"Aferições vitais"}
                    icon={AfericaoVital}
                    onClick={() => this.clickOptions('triagem')}
                    disabled={!sujeitoAtencaoId}
                />
            </div>
        )
    }


    render() {
        return <ButtonOptions buttons={this.renderButtons}/>
    }
}

const styles = () => ({
    buttonsContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    iconButton: {
        color: 'white',
    },
})

export default withStyles(styles)(OpcoesProntuario);
