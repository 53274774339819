import React from 'react';
import { inject } from "mobx-react";
import { withStyles } from '@material-ui/core/styles';

import Popper from '@material-ui/core/Popper';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import { ClickAwayListener, Fab, Tooltip } from "@material-ui/core";

import FilterWhiteIcon from "../../assets/img/svg/filter-white-icon.svg";
import ActionSwitchButton from '../../components/Agenda/AgendaFloatButtons/ActionSwitchButton';

import { entradaProntuarioTypes, entradaProntuarioTypesEnum } from './constants/constants';

const colors = {
    menu: '#515E79',
    active: '#707C97',
    deactivated: '#C6CEDF'
}

const styles = theme => ({
    typography: {
        padding: theme.spacing.unit * 2,
    },
    fade: {
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'absolute',
        bottom: '-190px',
        right: '10px',
        gap: '8px'
    },
    iconButton: {
        color: 'white',
    },
    buttonGray: {
        width: "40px",
        height: "40px",
        minHeight: "1px",
        background: '#515E79',
        "&:hover": {
            background: '#2a3140',
        },
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonContainer: {
        display: 'flex',
        height: '40px',
        minWidth: '115px',
        borderRadius: '8px',
        padding: '0 12px',
        columnGap: 0,
        '& span': {
            textAlign: 'center',
            flex: 1,
        }
    },
    filterButtons: {
        display: 'grid',
        width: 'fit-content',
        rowGap: '22px',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        columnGap: '8px',
    }
});

@inject("prontuarioStore", "profissionalSaudeStore", "usuarioStore")
class FiltroTipoDocumento extends React.Component {
    state = {
        anchorEl: null,
        open: false,
        placement: null,
        texto: true,
        receita: true,
        documento: true,
        profissionalLogado: null,
    };

    componentWillUnmount() {
        const { prontuarioStore } = this.props;
        prontuarioStore.showEntradasOnlyProfissionalLogado = false;
        prontuarioStore.selectedsTypesToShow = [];
        prontuarioStore.tipos = [];
    }

    handleClick = placement => event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: state.placement !== placement || !state.open,
            placement,
        }));
    };

    handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    handlePressFilterButton = tipo => {
        const { prontuarioStore } = this.props;
        const { selectedsTypesToShow, showEntradasOnlyProfissionalLogado } = prontuarioStore;

        if (tipo !== 'PROFISSIONAL') {
            if (prontuarioStore.tipos.includes(tipo)) {
                prontuarioStore.tipos = prontuarioStore.tipos.filter(
                    t => t !== tipo
                );
            } else {
                prontuarioStore.tipos.push(tipo);
            }
        } else {
            prontuarioStore.showEntradasOnlyProfissionalLogado = !showEntradasOnlyProfissionalLogado;
        }

        let selectedsTypesToShowUpdated = [];
        if (selectedsTypesToShow.includes(tipo)) {
            selectedsTypesToShowUpdated = selectedsTypesToShow.filter(
                t => t !== tipo
            );
        } else {
            selectedsTypesToShowUpdated = [...selectedsTypesToShow, tipo];
        }

        prontuarioStore.selectedsTypesToShow = selectedsTypesToShowUpdated;
        const typesToFilter = entradaProntuarioTypes.filter((type) => !selectedsTypesToShowUpdated.includes(type));
        prontuarioStore.tipos = typesToFilter.length !== entradaProntuarioTypes.length ? typesToFilter : [];

        this.reloadEntradas({
            withLoading: false,
        });
    };

    reloadEntradas = ({ withLoading = false } = {}) => {
        const { prontuarioStore } = this.props;
        this.pageNumberEntradas = 0;
        prontuarioStore.findAllEntradaProntuario({ withLoading }, prontuarioStore.sujeitoAtencaoSelected);
    };

    closePopper = event => {
        if (this.state.anchorEl.contains(event.target)) {
            return;
        };

        this.setState({ open: false })
    };

    render() {
        const { classes, prontuarioStore } = this.props;
        const { selectedsTypesToShow, showEntradasOnlyProfissionalLogado } = prontuarioStore;
        const { anchorEl, open, placement } = this.state;

        const { 
          AFERICAO_VITAL, 
          ANAMNESE, 
          AVALIACAO_ANTROPOMETRICA, 
          DOCUMENTO, 
          GUIA_INTERNACAO, 
          GUIA_SERVICO, 
          IMAGEM, 
          PROCEDIMENTO_REALIZADO, 
          RECEITA, 
          TEXTO 
        } = entradaProntuarioTypesEnum;

        return (
            <div className={classes.root}>
                <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={this.closePopper}>
                            <Fade {...TransitionProps} timeout={350} className={classes.fade}>
                                <div className={classes.buttonsContent}>
                                    <ActionSwitchButton
                                        buttonEnabled={!showEntradasOnlyProfissionalLogado}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={'SOMENTE MEUS'}
                                        disableText={'SOMENTE MEUS'}
                                        onClick={() => this.handlePressFilterButton("PROFISSIONAL")}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(RECEITA)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={RECEITA}
                                        disableText={RECEITA}
                                        onClick={() => this.handlePressFilterButton(RECEITA)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(DOCUMENTO)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={DOCUMENTO}
                                        disableText={DOCUMENTO}
                                        onClick={() => this.handlePressFilterButton(DOCUMENTO)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(GUIA_SERVICO)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={'SP/SADT'}
                                        disableText={'SP/SADT'}
                                        onClick={() => this.handlePressFilterButton(GUIA_SERVICO)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(GUIA_INTERNACAO)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={'GUIA DE INTERNAÇÃO'}
                                        disableText={'GUIA DE INTERNAÇÃO'}
                                        onClick={() => this.handlePressFilterButton(GUIA_INTERNACAO)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(TEXTO)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={TEXTO}
                                        disableText={TEXTO}
                                        onClick={() => this.handlePressFilterButton(TEXTO)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(PROCEDIMENTO_REALIZADO)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={'PROCEDIMENTOS'}
                                        disableText={'PROCEDIMENTOS'}
                                        onClick={() => this.handlePressFilterButton(PROCEDIMENTO_REALIZADO)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(ANAMNESE)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={ANAMNESE}
                                        disableText={ANAMNESE}
                                        onClick={() => this.handlePressFilterButton(ANAMNESE)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(AVALIACAO_ANTROPOMETRICA)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={'DADOS ANTROPOMÉTRICOS'}
                                        disableText={'DADOS ANTROPOMÉTRICOS'}
                                        onClick={() => this.handlePressFilterButton(AVALIACAO_ANTROPOMETRICA)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(AFERICAO_VITAL)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={'AFERIÇÕES VITAIS'}
                                        disableText={'AFERIÇÕES VITAIS'}
                                        onClick={() => this.handlePressFilterButton(AFERICAO_VITAL)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />

                                    <ActionSwitchButton
                                        buttonEnabled={prontuarioStore.tipos && !selectedsTypesToShow.includes(IMAGEM)}
                                        enableColor={colors.active}
                                        disableColor={colors.deactivated}
                                        enableText={'IMAGENS'}
                                        disableText={'IMAGENS'}
                                        onClick={() => this.handlePressFilterButton(IMAGEM)}
                                        classes={{
                                            actionSwitchButtonContainer: classes.buttonContainer
                                        }}
                                    />
                                </div>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Fab className={classes.buttonGray} onClick={this.handleClick('left-start')}>
                            <Tooltip
                                title="Filtro por tipo"
                                placement="left"
                            >
                                <img
                                    className={classes.documentoIcon}
                                    src={FilterWhiteIcon}
                                    alt="Filtro"
                                    onClick={this.handleDocument}
                                />
                            </Tooltip>

                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(FiltroTipoDocumento);
