import { inject } from 'mobx-react';
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { Fab, Tooltip, withStyles } from "@material-ui/core";
import {
    SearchIcon,
} from "../../assets/img/svg";
import colors from "../../template/Colors";

const styles = () => ({
    root: {
        marginBottom: '4px'
    },
    optionButton: {
        display: 'flex',
    },
    labelButton: {
        background: '#FFF5E9',
        borderRadius: '1000px',
        position: 'relative',
        right: '-28px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
        fontWeight: 600,
        '& input': {
            background: '#FFF5E9',
            border: 'none',
            marginLeft: '8px',
            width: '200px',
            marginRight: '32px'
        }
    },
    fade: {
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'absolute',
        bottom: '-75px',
        right: '10px'
    },
    buttonsContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    closeButton: {
        width: "40px",
        height: "40px",
        background: "#FB7676",
        color: '#FFFFFF',
        "&:hover": {
            background: "#b55959",
        },
    },
    searchButton: {
        width: "40px",
        height: "40px",
        background: colors.commons.yellow,
        color: '#FFFFFF',
        "&:hover": {
            background: "#e0a048",
        },
    },
})

const PesquisarFlutuante = (props) => {
    const { classes, prontuarioStore } = props;
    const [searchText, setSearchText] = useState('');
    const [showInput, setShowInput] = useState(false);

    const handlePressCloseIcon = () => {
        prontuarioStore.searchProntuarioText = ''
        setSearchText('');
        setShowInput(false);
        reloadEntradas();
    }

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const handlePressSearchButton = () => {
        if (showInput) {
            prontuarioStore.searchProntuarioText = searchText;
            reloadEntradas();
        } else {
            setShowInput(true)
        }
    };

    const reloadEntradas = () => {
        prontuarioStore.findAllEntradaProntuario({ withLoading: false }, prontuarioStore.sujeitoAtencaoSelected);
    };

    const onKeyPressSearch = (e) => {
        if (e.key === 'Enter' && searchText.length >= 3) {
            handlePressSearchButton();
        }
    }

    useEffect(() => {
        if(prontuarioStore.searchProntuarioText) {
            setShowInput(true);
            setSearchText(prontuarioStore.searchProntuarioText);
        }
    }, [])

    return (
        <>
            <div className={classes.optionButton}>
                {showInput && (
                    <div className={classes.labelButton}>
                        <Fab className={classes.closeButton} onClick={handlePressCloseIcon}>
                            <CloseIcon />
                        </Fab>
                        <input
                            value={searchText}
                            onChange={(e) => handleChange(e)}
                            onKeyPress={(e) => onKeyPressSearch(e)}
                        />
                    </div>
                )}

                <Fab className={classes.searchButton} onClick={handlePressSearchButton}>
                    <Tooltip
                        title={'Pesquisar'}
                        placement="left"
                    >
                        <img src={SearchIcon} alt={"Pesquisar"} />
                    </Tooltip>
                </Fab>
            </div>

        </>
    )
}

const PesquisarFlutuanteWithStyles = withStyles(styles)(PesquisarFlutuante);
export default inject("prontuarioStore")(PesquisarFlutuanteWithStyles);
