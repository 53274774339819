import Api from '../config/api'

export const findAllFinanceiroGuiaServico = async (
  pageableDTO,
  search,
  dataSolicitacaoFinal,
  dataSolicitacaoInicial,
  convenioId
) => {
  return Api.post('', {
    query: `
        query($pageableDTO: PageableDTOInput, $search: String, $dataSolicitacaoFinal:LocalDate, $dataSolicitacaoInicial:LocalDate, $convenioId: Long){
          findAllFinanceiroGuiaServico(pageableDTO: $pageableDTO, search: $search, dataSolicitacaoFinal:$dataSolicitacaoFinal, dataSolicitacaoInicial:$dataSolicitacaoInicial, convenioId: $convenioId){
            last
            totalPages
            totalElements
            content{
            id
            beneficiario{
              sujeitoAtencao{
                nome
                nomeSocial
              }
            }
            convenio{
              id
              descricao
            }
            numeroGuiaPrestador
            numeroGuiaPrincipal
          }
        }
    }`,
    variables: {
      pageableDTO,
      search,
      dataSolicitacaoFinal,
      dataSolicitacaoInicial,
      convenioId,
    },
  })
}

export const findByIdFinanceiroGuiaServico = async id => {
  return await Api.post('', {
    query: `
        query ($id: UUID ){
          findByIdFinanceiroGuiaServico(id: $id){
            id
            beneficiario{
              atendimentoRN
              dataValidadeCarteira
              numeroCarteira
              nome
              cns
              sujeitoAtencao{
                nomeSocial
                dadosConvenio{
                  numeroCarteira
                  validadeCarteira
                  convenio{
                    descricao
                  }
                }
              }
            }
            solicitante{
              codigoCbo
              codigoConselho
              codigoOperadora
              conselhoProfissionalSaude{
                codigoTermo
                descricao
                id
                sigla
              }
              estado{
                ativo
                codigoTermo
                id
                nome
                uf
              }
              nomeContratado
              numeroConselho
              profissional
              ufConselho
            }
            solicitacao{
              caraterAtendimento
              data
              indicacaoClinica
              itens{
                procedimento{
                  nome
                  tuss22ProcedimentoEventoSaude{
                    codigoTermo
                    descricao
                  }
                }
                quantidadeAutorizada
                quantidadeSolicitada
              }
              tuss23TerminologiaCaraterAtendimento{
                id
                codigoTermo
                termo
              }
            }
            autorizacao{
              dataAutorizacao
              dataValidadeSenha
              numeroGuiaOperadora
              senha
            }
            convenio{
              descricao
              codigoUnidade
            }
            observacao
            agendamentosNaoFaturados{
              id
            }
        }
    }`,
    variables: { id },
  })
}

export const findAllTuss23TerminologiaCaraterAtendimento = async (
  pageableDTO,
  searchDTO
) => {
  return Api.post('', {
    query: `
        query($termo: String, $codigoTermo: String, $pageableDTO: PageableDTOInput ){
          findAllTuss23TerminologiaCaraterAtendimento(termo:$termo, codigoTermo: $codigoTermo, pageableDTO: $pageableDTO){
            last
            totalPages
            totalElements
            content{
              id
              termo
              codigoTermo
          }
        }
    }`,
    variables: { pageableDTO, ...searchDTO },
  })
}

export const findAllProcedimentos = async variables => {
  return Api.post('', {
    query: `
  query ($nome: String, $ativo: Boolean, $pageableDTO: PageableDTOInput, $salaId: UUID) {
    findAllProcedimentos(nome: $nome, ativo: $ativo, pageableDTO: $pageableDTO, salaId: $salaId) {
      last
      content {
        id
        nome
        ativo
        produtoProcedimentos {
          quantidade
        }
        tuss22ProcedimentoEventoSaude{
          codigoTermo
          descricao
          id
        }
      }
    }
  }
  
`,
    variables: variables,
  })
}

export const findAllSujeitoAtencao = async (pageNumber, search) => {
  return Api.post('', {
    query: `
      query ($page: SearchSujeitoAtencaoDTOInput){
          findAllSujeitoAtencao(searchDTO: $page){
              totalElements
              numberOfElements
              last
              totalPages
              totalElements
              content {
                  id
                  nome
                  nomeSocial
                  cns
                  dadosConvenio{
                    numeroCarteira
                    validadeCarteira
                    convenio{
                      id
                      codigoUnidade
                      descricao
                      convenioAgendamentoTipo {
                        agendamentoTipo {
                          id
                          descricao
                        }
                        valor
                      }
                    }
                  }
              }
          }
      }`,
    variables: {
      page: {
        search,
        pageSize: 20,
        pageNumber: pageNumber,
        sortField: 'nome',
        sortDir: 'ASC',
      },
    },
  })
}

export const findAllProfissionalSaude = variables => {
  const defaultSearchDTO = {
    pageSize: 30,
    pageNumber: 0,
    sortDir: 'ASC',
    sortField: 'nome',
  }

  return Api.post('', {
    query: `
          query ($searchDTO: SearchProfissionalSaudeDTOInput) {
              findAllProfissionalSaude(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
                tuss24TerminologiaCBO{
                    id
                    codigoTermo
                    termo
                    dataInicioVigencia
                    dataFimVigencia
                    dataFimImplantacao
                  }
                conselhoProfissionalSaude{
                  id
                  sigla
                  descricao
                  codigoTermo
                }
                endereco{
                  estado{
                    id
                    uf
                  }
                }
                numeroConselho
                ufConselho
                assinaturaTermoUso{
                  profissionalSaude{
                    nome
                  }
                }
            }
        }
          }`,
    variables: {
      searchDTO: {
        ...defaultSearchDTO,
        ...variables,
      },
    },
  })
}

export const findAllEstadoList = async () => {
  return await Api.post('', {
    query: `
      {
      findAllEstadoList {
        id,
        ativo
        codigoTermo
        nome
        uf
      }
    }
    `,
  })
}

export const findAllConselhoProfissionalSaudeList = async () => {
  return await Api.post('', {
    query: `
      {
        findAllConselhoProfissionalSaudeList {
          id
          descricao
          codigoTermo
          sigla
        
      }
    }
    `,
  })
}

export const findAllTuss24TerminologiaCBO = async (pageableDTO, searchDTO) => {
  return Api.post('', {
    query: `
        query($termo: String, $codigoTermo: String, $pageableDTO: PageableDTOInput ){
          findAllTuss24TerminologiaCBO (termo:$termo, codigoTermo: $codigoTermo, pageableDTO: $pageableDTO){
            last
            totalPages
            totalElements
            content{
              id
              codigoTermo
              termo
              dataInicioVigencia
              dataFimVigencia
              dataFimImplantacao
          }
        }
    }`,
    variables: { pageableDTO, ...searchDTO },
  })
}

export const saveGuiaServico = async guiaServico => {
  const nameQuery = guiaServico.agendamentoId ? "createFinanceiroGuiaServicoAgendamento" : "createFinanceiroGuiaServico"

  const response = await Api.post('', {
    query: `
      mutation(${guiaServico.agendamentoId && "$agendamentoId: Long, "}$solicitacao:FinanceiroGuiaServicoSolicitacaoInput, $autorizacao: FinanceiroGuiaServicoAutorizacaoInput, $observacao:String, $solicitante:FinanceiroGuiaServicoSolicitanteInput, $convenio: ConvenioInput, $beneficiario:FinanceiroGuiaServicoBeneficiarioInput){
        ${nameQuery}(${guiaServico.agendamentoId && "agendamentoId: $agendamentoId, "}solicitacao: $solicitacao,autorizacao: $autorizacao, observacao: $observacao, solicitante: $solicitante, convenio: $convenio, beneficiario: $beneficiario ){
          id
        }
      }
    `,
    variables: { ...guiaServico },
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response;
}

export const findAllTuss = async (variables, query) => {
  const response = await Api.post('', 
    {
      query: `
        query ($termo: String, $pageableDTO: PageableDTOInput) {
          findAllTuss${query}(termo: $termo, pageableDTO: $pageableDTO) {
            last
            content {
              id
              termo
              codigoTermo
            }
          }
        }
      `,
      variables: variables,
    });

  return response.data.data;
}

export const findAllFinanceiroGuiaServicoBySujeitoAtencao = async (
  pageableDTO,
  search,
  sujeitoAtencaoId,
  somenteGuiasPrincipais
) => {
  const response = await Api.post('', {
    query: `
    query ($sujeitoAtencaoId: UUID, $somenteGuiasPrincipais: Boolean, $pageableDTO: PageableDTOInput, $search: String) {
      findAllFinanceiroGuiaServicoBySujeitoAtencao(sujeitoAtencaoId: $sujeitoAtencaoId, somenteGuiasPrincipais: $somenteGuiasPrincipais, pageableDTO: $pageableDTO, search: $search) {
        last
        content {
          id
          numeroGuiaPrestador
        }
      }
    }`,
    variables: {
      pageableDTO,
      search,
      sujeitoAtencaoId,
      somenteGuiasPrincipais
    },
  });

  return response.data.data.findAllFinanceiroGuiaServicoBySujeitoAtencao;
};

export const findAllFinanceiroGuiaConsultaBySujeitoAtencao = async (
  pageableDTO,
  search,
  sujeitoAtencaoId
) => {
  const response = await Api.post('', {
    query: `
    query ($sujeitoAtencaoId: UUID, $pageableDTO: PageableDTOInput, $search: String) {
      findAllFinanceiroGuiaConsultaBySujeitoAtencao(sujeitoAtencaoId: $sujeitoAtencaoId, pageableDTO: $pageableDTO, search: $search) {
        last
        content {
          id
          numeroGuiaPrestador
        }
      }
    }`,
    variables: {
      pageableDTO,
      search,
      sujeitoAtencaoId
    },
  });

  return response.data.data.findAllFinanceiroGuiaConsultaBySujeitoAtencao;
};

export const dadosGuiaServicoFaturamento = async id => {
  const response = await Api.post('', {
    query: `
        query ($id: UUID ){
          findByIdFinanceiroGuiaServico(id: $id){
            id
            observacao
            numeroGuiaPrestador
            beneficiario{
              nome
              sujeitoAtencao{
                fotoPerfil
              }
            }
            convenio{
              id
              descricao
              codigoUnidade
            }
            unidade{
              nome
              codigoCnes
            }
            agendamentosNaoFaturados{
              profissionalSaude{
                nome
                id
                cpf
                conselhoProfissionalSaude{
                  id
                  sigla
                }
                numeroConselho
                ufConselho
                informacao{
                  tuss24TerminologiaCBO{
                    id
                    codigoTermo
                  }
                }
              }
              data
              horaFim
              horaInicio
              procedimentos{
                procedimento{
                  nome
                  id
                  tuss22ProcedimentoEventoSaude{
                    id
                    codigoTermo
                    descricao
                  }
                  convenioProcedimentos{
                    convenio{
                      id
                    }
                    valor
                  }
                }
                realizado
              }
            }
        }
    }`,
    variables: { id },
  });

  return response.data.data.findByIdFinanceiroGuiaServico;
};

export const findAllTuss22ProcedimentoEventoSaude = async (variables) => { 
  const response = await Api.post("", {
        query: `
        query($codigoTermo: String, $pageableDTO: PageableDTOInput, $descricao: String, $ativo: Boolean){
            findAllTuss22ProcedimentoEventoSaude(codigoTermo: $codigoTermo,ativo:$ativo, descricao: $descricao, pageableDTO: $pageableDTO ){
                last
                content{
                  id
                  descricao
                  codigoTermo
                }
            }
        }
        `,variables: variables
    });

    return response.data.data.findAllTuss22ProcedimentoEventoSaude;
};

export const profissionalExecutante = (variables) => {
  const defaultSearchDTO = {
      pageSize: 30,
      pageNumber: 0,
      sortDir: "ASC",
      sortField: "nome",
  };

  return Api.post("", {
      query: `
          query ($searchDTO: SearchProfissionalSaudeDTOInput) {
            findAllProfissionalSaude(searchDTO: $searchDTO) {
            last
            totalElements
            numberOfElements
            content {
              id
              nome
              cpf
              numeroConselho
              conselhoProfissionalSaude{
                id
                sigla
              }
              ufConselho
              numeroConselho
              informacao{
                tuss24TerminologiaCBO{
                  codigoTermo
                  id
                }
              }
            }
          }
        }`,
      variables: {
          searchDTO: {
              ...defaultSearchDTO,
              ...variables
          }
      }
  })
};

export const faturarFinanceiroGuiaServico = async variables => {
  const response = await Api.post("", {
    query: `
      mutation ($financeiroGuiaServicoId: UUID, $execucaoItens: [FinanceiroGuiaServicoExecucaoItemInput], $executante: FinanceiroGuiaServicoExecutanteInput, $observacao: String, $atendimento: FinanceiroGuiaServicoAtendimentoInput) {
        faturarFinanceiroGuiaServico(financeiroGuiaServicoId: $financeiroGuiaServicoId, execucaoItens: $execucaoItens, executante: $executante, observacao: $observacao, atendimento: $atendimento) {
          id
        }
      }
    `,
    variables: variables
  });
  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response;
}

export const updateGuiaServico = async guiaServico => {
  return await Api.post('', {
    query: `
          mutation( $id: UUID, $autorizacao: FinanceiroGuiaServicoAutorizacaoInput){
            adicionarDadosAutorizacaoFinanceiroGuiaServico(id: $id, autorizacao: $autorizacao ){
              id
            }
          }
      `,
    variables: { ...guiaServico },
  })
}

export const getDadosGuiaServicoAgendamento = async (id) => {
  const response = await Api.post("", {
    query: `
      {
        content: findByIdAgendamento(id: "${id}") {
          id
          sujeitoAtencao {
            id
            nome
            nomeSocial
            dadosConvenio {
              numeroCarteira
              validadeCarteira
            }
            cns
          }
          convenio {
            id
            descricao
            codigoUnidade
            convenioAgendamentoTipo {
              agendamentoTipo {
                id
              }
              valor
            }
          }
          unidade {
            nome
          }
          profissionalSaude {
            id
            nome
            ufConselho
            numeroConselho
            endereco {
              estado {
                codigoTermo
                id
                nome
                uf
              }
            }
            conselhoProfissionalSaude {
              id
              sigla
              codigoTermo
            }
            informacao {
              tuss24TerminologiaCBO {
                id
                codigoTermo
                termo
              }
            }
          }
          procedimentos {
            procedimento {
              id
              nome
              tuss22ProcedimentoEventoSaude {
                id
                codigoTermo
              }
            }
          }
        }
      }  
    `,
  });

  return response.data.data.content;
};
