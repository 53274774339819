import React from "react";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import LogoImage from "../../components/LogoImage/LogoImage";
import {
  MenuItem,
  MenuList,
  ClickAwayListener,
  Paper,
  Grow,
  Popper,
  withStyles
} from "@material-ui/core";
import ButtonTransparent from "../../components/Button/ButtonTransparent";
import localStorageService, { REDES_KEY, USUARIO_LOGADO_KEY, ACCESS_TOKEN_KEY } from "../../services/storage";
import MudarSenhaModal from "./MudarSenhaModal";
import { buildUrlFotoPerfil } from "../../config/config";
import VersoesSistemaModal from "../../pages/Dashboard/VersoesSistema/VersoesSistemaModal";
import { MeuPerfilIcon, SairIcon, TrocarSenhaIcon, VersoesSistemaIcon, AlterarUnidadeIcon } from "../../assets/img/svg";
import { Button } from "../../components/ui/Buttons";
import NotificationIcon from "../../components/Icon/NotificationIcon";

const FIND_NOTIFICATIONS_INTERVAL = 5 * 60 * 1000; // 5 minutes

@inject('loginStore', 'profissionalSaudeStore', 'usuarioStore', 'socketStore', 'chatStore', 'notificationStore')
class Profile extends React.Component {
  state = {
    user: null,
    redes: null,
    accessToken: null,
    openMenu: false,
    openMudarSenha: false,
    versoesSistema: [],
    openModalVersoes: false,
  };
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.getUsuarioLogado();
    const { notificationStore, usuarioStore } = this.props;
    const findAndVerifyNotifications = async () => {
      if(!usuarioStore.isAuthenticated || !this.mounted) {
        return;
      }
      await notificationStore.findAllNotifications();
    };

    const setupNotificationInterval = () => {
      findAndVerifyNotifications();
      const intervalId = setInterval(findAndVerifyNotifications, FIND_NOTIFICATIONS_INTERVAL);
      return () => clearInterval(intervalId);
    };

    setupNotificationInterval();
  }
  
  componentWillUnmount() {
    this.mounted = false;
  }

  async getUsuarioLogado() {
    const user = await localStorageService.get(USUARIO_LOGADO_KEY);
    const redes = await localStorageService.get(REDES_KEY);
    const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
    
    if (!this.mounted) {
      return;
    }
    
    this.setState({ user, redes, accessToken });
  }

  handleToggleMenu = () => {
    this.setState(state => ({
      openMenu: !state.openMenu
    }));
  };

  handleCloseMenu = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ openMenu: false });
  };
  
  handleClickAlterarUnidade = () => {
    this.setState({ openMenu: false }, () => {
        this.props.history.push({
            pathname: '/select-rede',
            state: { redes: this.state.redes }
        });
    });
  };
  
  handleClickLogout = () => {
    this.setState({ openMenu: false }, () => {
        this.props.loginStore.logout(this.props);
    });
  };

  handleClickPerfil = () => {
    this.setState({ openMenu: false }, () => {
      this.props.history.push({
        pathname: '/perfil'
      });
    });
  };

  handleClickVersao = async() => {  
    this.setState({ openModalVersoes: true });
  };

  openMudarSenhaModal = () => {
    this.handleToggleMenu();
    this.props.usuarioStore.openMudarSenhaModal();
  };

  closeModalVersoes = () => {
    this.setState({ openModalVersoes: false })
  }

  render() {
    const { user, openMenu, redes, accessToken } = this.state;
    const { usuarioStore, classes, notificationStore } = this.props;

    const hasUnreadNotifications = notificationStore.notifications?.[0]?.visualizado === false;
    return (
      <div className={classes.container}>
        <Button 
          kind='transparent' 
          shape='circle' 
          onClick={(event) => {
            if (notificationStore.openModalNotifications) {
              notificationStore.closeModal();
            } else {
              notificationStore.openModal(event);
            }
          }}
          ref={node => (notificationStore.anchorEl = node)}
          aria-owns={notificationStore.anchorEl ? "menu-notifications" : undefined}
          aria-haspopup="true"
        >
          <NotificationIcon color='#505050' unreadNotifications={hasUnreadNotifications} />
        </Button>
        <ButtonTransparent
          ref={node => (this.anchorEl = node)}
          aria-owns={this.anchorEl ? "menu-profile" : undefined}
          aria-haspopup="true"
          onClick={this.handleToggleMenu}
        >
          { accessToken && (
            <LogoImage
            image={buildUrlFotoPerfil(usuarioStore.fotoPerfil || user?.fotoPerfil, accessToken)} />
          )}
        </ButtonTransparent>
        <Popper
          open={openMenu}
          anchorEl={this.anchorEl}
          transition
          style={{ zIndex: 20 }}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-profile"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper style={{ width: 228 }}>
                <ClickAwayListener onClickAway={this.handleCloseMenu}>
                  <MenuList>
                    { redes?.length > 1 || redes[0]?.unidades?.length > 1 ? (
                      <MenuItem onClick={this.handleClickAlterarUnidade} className={classes.menuItem}>
                        <img src={AlterarUnidadeIcon} alt="Alterar unidade"/>
                        Alterar Unidade
                      </MenuItem>
                    ) : null}
                    <MenuItem onClick={this.handleClickPerfil} className={classes.menuItem}>
                      <img src={MeuPerfilIcon} alt="Meu perfil"/>
                      Meu perfil
                    </MenuItem>
                    <MenuItem onClick={this.openMudarSenhaModal} className={classes.menuItem}>
                      <img src={TrocarSenhaIcon} alt="Trocar senha"/>
                      Trocar senha
                    </MenuItem>
                    <MenuItem onClick={this.handleClickVersao} className={classes.menuItem}>
                      <img src={VersoesSistemaIcon} alt="Versões do sistema"/>
                      Versões do sistema
                    </MenuItem>
                    <MenuItem onClick={this.handleClickLogout} className={classes.menuItem}>
                      <img src={SairIcon} alt="Sair"/>
                      Sair
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <MudarSenhaModal open={this.props.usuarioStore.mudarSenhaModalOpen}/>
        {
          this.state.openModalVersoes && 
          <VersoesSistemaModal 
            screen="Profile"
            open={this.state.openModalVersoes}
            close={this.closeModalVersoes}
          />
        }
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "max-content",
    gap: "8px",

    "& button": {
      [theme.breakpoints.down('sm')] : {
        display: 'none'
      }
    }
  },
  menuItem: {
    "& img": {
      width: 20,
      maxHeight: 20,
      marginRight: 14
    }
  }
});

const ProfileWithStyles = withStyles(styles)(Profile);
export default withRouter(ProfileWithStyles);
