import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import Dialog from "../../../../../../components/Dialog/Dialog";
import ItemGrid from "../../../../../../components/Modal/ItemGrid";
import Button from "../../../../../../components/ui/Buttons/Button";
import { statusOptions, tipoOptions } from "../../../../../../stores/Orcamento.store";
import { TextFieldSearch } from "../../../../../../components/TextField";
import { InputDateForm } from "../../../../../../components/Modal/Input";

const FilterModal = observer(
  ({
    classes,
    orcamentoStore,
    open,
    handleClose,
    handleApplyFilter,
    handleFilterChange,
    isLoading,
    filters,
    resetFilter,
  }) => {
    const {
      dataInicio,
      dataFim,
      profissionalSaude,
      procedimento,
      status,
      tipo
    } = filters;

    const handleClickApplyFilter = () => {
      handleApplyFilter();
      handleClose();
    };

    const loadProfissionais = async (search, loadedOptions, { page }) => {
      return handleLoadMoreOptions({
        search,
        loadedOptions,
        data: { page },
        method: "findProfissionalSaudeByUnidadeComAgendaPage",
      });
    };

    const loadProcedimentos = async (search, loadedOptions, { page }) => {
      return handleLoadMoreOptions({
        search,
        loadedOptions,
        data: { page },
        method: "findAllProcedimentos",
      });
    };

    const handleLoadMoreOptions = async ({ search, data, method }) => {
      let searchDTO = {};

      if (data.searchDTO) {
        searchDTO = {
          ...data.searchDTO,
        };
      }

      const response = await orcamentoStore[method]({
        pageNumber: data.page,
        nome: search,
        ...searchDTO,
      });

      return {
        options: response?.content || response,
        hasMore: !response.last,
        additional: {
          page: data.page + 1,
        },
      };
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.modalContainer,
        }}
      >
        <div className={classes.modalHeader}>
          <span className={classes.title}>Filtrar</span>
          <Button
            kind="transparent"
            style={{
              border: "none",
            }}
            onClick={resetFilter}
          >
            Redefinir filtros
          </Button>
        </div>
        <div className={classes.rowDate}>
          <ItemGrid classes={{ item: classes.fillDiv }} label="Data início">
            <InputDateForm
              value={dataInicio || ""}
              onChange={(e) => handleFilterChange(e, "dataInicio")}
              iconposition="end"
              invalidDateMessage=""
              className={classes.inputDate}
            />
          </ItemGrid>
          <ItemGrid classes={{ item: classes.fillDiv }} label="Data fim">
            <InputDateForm
              value={dataFim || ""}
              onChange={(e) => handleFilterChange(e, "dataFim")}
              iconposition="end"
              invalidDateMessage=""
              className={classes.inputDate}
            />
          </ItemGrid>
        </div>
          <ItemGrid
            classes={{ item: classes.fillDiv }}
            label="Procedimento"
          >
            <TextFieldSearch
              classInput={classes.selectInput}
              classNotched={classes.notchedOutline}
              placeholder="Selecione"
              loadOptions={loadProcedimentos}
              withPaginate
              onChange={(value) => handleFilterChange(value, "procedimento")}
              getOptionLabel={option => option.nome}
              getOptionValue={option => option}
              value={procedimento}
              additional={{
                page: 0,
              }}
            />
          </ItemGrid>
        <ItemGrid
          classes={{ item: classes.fillDiv }}
          label="Profissional de Saúde"
        >
          <TextFieldSearch
            classInput={classes.selectInput}
            classNotched={classes.notchedOutline}
            placeholder="Selecione"
            loadOptions={loadProfissionais}
            withPaginate
            onChange={(value) => handleFilterChange(value, "profissionalSaude")}
            value={profissionalSaude}
            additional={{
              page: 0,
            }}
          />
        </ItemGrid>
        <ItemGrid classes={{ item: classes.fillDiv }} label="Status">
          <TextFieldSearch
            classInput={classes.selectInput}
            classNotched={classes.notchedOutline}
            placeholder="Selecione"
            options={statusOptions}
            value={status}
            menuPosition="fixed"
            onChange={(value) => handleFilterChange(value, "status")}
          />
        </ItemGrid>
        <ItemGrid classes={{ item: classes.fillDiv }} label="Tipo">
          <TextFieldSearch
            classInput={classes.selectInput}
            classNotched={classes.notchedOutline}
            placeholder="Selecione"
            options={tipoOptions}
            value={tipo}
            menuPosition="fixed"
            onChange={(value) => handleFilterChange(value, "tipo")}
          />
        </ItemGrid>
        <Button onClick={handleClickApplyFilter} disable={isLoading}>
          Aplicar Filtro
        </Button>
      </Dialog>
    );
  }
);

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "486px",
    padding: "32px",
    gap: "16px",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "24px !important",
    color: "#505050",
    fontWeight: "700",
  },
  rowDate: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectInput: {
    flex: 1,
    backgroundColor: "#F2F2F2",
    height: "40px",
    borderRadius: "8px",
    color: "#505050",
    fontWeight: "600",
  },
  notchedOutline: {
    border: 0,
  },
  fillDiv: {
    flex: 1,
    padding: "0px !important",
  },
  inputDate: {
    width: "100%",
  },
};

const FilterModalStyles = withStyles(styles)(FilterModal);
export default inject("orcamentoStore")(FilterModalStyles);
