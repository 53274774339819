import React from 'react';
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';
import FormGroup from '../SADT/FormGroup';
import Header from '../SADT/Header';
import FormHeading from '../SADT/FormHeading';
import dates from '../../../utils/dates';

Font.register({
  family: "Lato-Bold",
  src: "/fonts/lato-v15-latin-700.ttf",
});

Font.register({
  family: "Lato-Regular",
  src: "/fonts/lato-v15-latin-regular.ttf",
});

const GuiaInternacaoPDF = ({ guiaInternacao, logo }) => {
  const { 
    guiaInternacaoTussViews, 
    tuss23TerminologiaCaraterAtendimento: caraterAtendimento,
    tuss36TerminologiaIndicadorAcidente: indicacaoAcidente,
    cid10Subcategoria: indicacaoClinica,
    observacao,
    hasOPME,
    quimioterapico,
    tuss41TerminologiaRegimeInternacao: regimeInternacao,
    tuss57TerminologiaTipoInternacao: tipoInternacao,
    sujeitoAtencao,
    profissionalSaude,
  } = guiaInternacao || {};

  const { dadosConvenios, nomeSocialOuNome, nome: sujeitoAtencaoNome } = sujeitoAtencao || {};
  const convenioPrincipal = dadosConvenios?.filter((item) => item.convenioPrincipal);
  const { numeroCarteira, validadeCarteira } = convenioPrincipal[0] || {};
  const { nome: profissionalSaudeNome, numeroConselho, ufConselho, conselhoProfissionalSaude, tuss24TerminologiaCBO } = profissionalSaude || {};
  const { sigla } = conselhoProfissionalSaude || {};

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Header
              titulo={'Guia de Solicitação de internação'}
              numeroGuiaPrestador={''}
            />
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup label='1 - Registro ANS' maxLength={6}/>
            <FormGroup label='3 - Número Guia Principal' maxLength={20}/>
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup
              label='4 - Data da Autorização'
              type='date'
              maxLength={10}
            />
            <FormGroup label='5 - Senha' maxLength={20}/>
            <FormGroup
              label='6 - Data de Validade da Senha'
              type='date'
              maxLength={10}
            />
          </View>
          <FormHeading label="Dados do Beneficiário"/>
          <View style={styles.formGroupContainer}>
            <FormGroup 
              label="7 - Número da Carteira" 
              maxLength={20}
              value={numeroCarteira || ''}
            />
            <FormGroup 
              label="8 -Validade da Carteira" 
              maxLength={10}
              value={dates.formatDate(validadeCarteira, 'YYYY-MM-DD', 'DD/MM/YYYY') || ''}
              type="date"
            />
            <FormGroup label="9 - Atendimento a RN" maxLength={1}/>
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup 
              label="10 - Nome Social" 
              maxLength={50} 
              noBorder style={{ flex: 1 }}
            />
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup 
              label="11 - Nome" 
              maxLength={50} 
              noBorder style={{ flex: 1 }}
              value={sujeitoAtencaoNome || ''}
            />
          </View>
          <FormHeading label="Dados do Contratado Solicitante"/>
          <View style={styles.formGroupContainer}>
            <FormGroup label="12 - Código na Operadora" maxLength={14}/>
            <FormGroup 
              label="13 - Nome do contratado" 
              maxLength={44} 
              noBorder
              style={{ flex: 1 }}  
            />
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup 
              label="14 - Nome do Profissional Solicitante"
              maxLength={24} 
              noBorder 
              style={{ flex: 1 }}
              value={profissionalSaudeNome || ''}
            />
            <FormGroup 
              label="15 - Conselho Profissional" 
              maxLength={3}
              value={sigla || ''}
            />
            <FormGroup 
              label="16 - Número no Conselho" 
              maxLength={15} 
              value={numeroConselho || ''}
            />
            <FormGroup 
              label="17 - UF" 
              maxLength={2} 
              value={ufConselho || ''}
            />
            <FormGroup 
              label="18 - Código CBO" 
              maxLength={6}
              value={tuss24TerminologiaCBO?.codigoTermo || ''}
            />
          </View>
          <FormHeading label="Dados do Hospital / Local Solicitado / Dados da Internação"/>
          <View style={styles.formGroupContainer}>
            <FormGroup label="19 - Código na Operadora / CNPJ" maxLength={14}/>
            <FormGroup 
              label="20 - Nome do Hospital / Local Solicitado" 
              maxLength={32} 
              noBorder
              style={{ flex: 1 }}
            />
            <FormGroup label="21 - Data sugerida para internação" type='date' maxLength={8}/>
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup 
              label="22 - Caráter do atendimento" 
              maxLength={1} 
              value={caraterAtendimento?.codigoTermo || ''}
            />
            <FormGroup 
              label="23 - Tipo de internação" 
              maxLength={1}
              value={tipoInternacao?.codigoTermo || ''}
            />
            <FormGroup 
              label="24 - Regime de Internação" 
              maxLength={1}
              value={regimeInternacao?.codigoTermo || ''}
            />
            <FormGroup 
              label="25 - Qtde. Diárias Solicitadas" 
              maxLength={4} 
              style={{ flex: 1 }}
            />
            <FormGroup label="26 - Previsão de uso de OPME" maxLength={1} value={hasOPME ? 'X' : ''}/>
            <FormGroup label="27 - Previsão de uso quimioterápico" maxLength={1} value={quimioterapico ? 'X' : ''}/>
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup
              label="28 - Indicação Clinica"
              style={{ flex: 1, height: 100, alignItems: 'flex-start' }}
              value={indicacaoClinica?.descricao || ''}
              noBorder
            />
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup 
              label="29 - CID 10 Principal(Opcional)" 
              maxLength={4} 
              style={{ backgroundColor: '#DDD' }}
              value={indicacaoClinica?.codigo || ''}
            />
            <FormGroup 
              label="30 - CID 10 (2) (Opcional)" 
              maxLength={4} 
              style={{ backgroundColor: '#DDD' }} 
            />
            <FormGroup 
              label="31 - CID 10 (3) (Opcional)" 
              maxLength={4}
              style={{ backgroundColor: '#DDD' }}
            />
            <FormGroup 
              label="32 - CID 10 (4) (Opcional)" 
              maxLength={4} 
              style={{ backgroundColor: '#DDD' }}
            />
            <FormGroup 
              label="33 - Indicação de Acidente (acidente ou doença relacionada)"
              maxLength={1}
              style={{ flex: 1 }}
              value={indicacaoAcidente?.codigoTermo || ''}
            />
          </View>
          <FormHeading label="Procedimentos ou Itens Assistenciais Solicitados" />
          <View style={styles.formGroupContainerBorder}>
            {guiaInternacaoTussViews?.length > 0 && guiaInternacaoTussViews.map((item, index) => (
              <View key={index} style={[styles.formGroupContainer, { marginBottom: 0 }]}>
                <FormGroup
                  label={index !== 0 ? undefined : "34 - Tabela"}
                  maxLength={2}
                  showRowNumber
                  startRowNumber={index + 1}
                  style={[
                    styles.formGroupRows,
                    { 
                      width: 34 
                    }
                  ]}
                  value={item?.tussView?.tabela || ''}
                />
                <FormGroup
                  label={index!== 0 ? undefined : "35 - Código do Procedimento"}
                  maxLength={10}
                  style={[
                    styles.formGroupRows,
                  ]}
                  value={item?.tussView?.codigoTermo || ''}
                />
                <FormGroup
                  label={index !== 0 ? undefined : "36 - Descrição"}
                  maxLength={35}
                  style={[
                    styles.formGroupRows,
                    { flex: 1 }
                  ]}
                  value={item?.descricao || ''}
                />
                <FormGroup
                  label={index !== 0 ? undefined : "37 - Qtde. Solicitada"}
                  maxLength={4}
                  style={[
                    styles.formGroupRows,
                    {
                      width: 60
                    }
                  ]}
                  value={item?.quantidadeSolicitada || ''}
                />
              </View>
            ))}
          </View>
          <FormHeading label="Dados da Autorização" />
          <View style={styles.formGroupContainer}>
            <FormGroup label="38 - Data Provável de Admissão Hospitalar" maxLength={8} type='date'/>
            <FormGroup label="39 - Qtde. Diárias Autorizadas" maxLength={4}/>
            <FormGroup label="40 - Tipo de Acomodação Autorizada" maxLength={2}/>
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup label="41 - Código na Operadora / CNPJ autorizado" maxLength={14}/>
            <FormGroup label="42 - Nome do Hospital / Local autorizado" maxLength={32} style={{ flex: 1 }} noBorder/>
            <FormGroup label="43 - Código CNES" maxLength={7}/>
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup 
              label="44 - Observação / Justificativa" 
              style={{ backgroundColor: '#DDD', flex: 1, height: 64, alignItems: 'flex-start' }}
              value={observacao || ''}
              noBorder
            />
          </View>
          <View style={styles.formGroupContainer}>
            <FormGroup label="45 - Data da Solicitação" maxLength={8} type='date'/>
            <FormGroup label="46 - Assinatura do Profissional Solicitante" maxLength={18} style={{ flex: 1 }} noBorder/>
            <FormGroup label="46 - Assinatura do Beneficiário ou Responsável" maxLength={18} style={{ flex: 1 }} noBorder/>
            <FormGroup label="46 - Assinatura do Responsável pela Autorização" maxLength={18} style={{ flex: 1 }} noBorder/>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 15,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  container: {
    borderWidth: 1,
    borderColor: '#707070',
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
  },
  formGroupContainerBorder: {
    borderColor: "#707070",
    borderWidth: 0.5,
    marginLeft: 2,
    marginRight: 2,
  },
  formGroupRows: {
    borderWidth: 0,
  },
  formGroupNoMarginLeft: {
    marginLeft: 0,
  },
  formGroupBorderButtom: {
    borderBottomWidth: 0.3,
    borderColor: "#707070",
    marginBottom: 0
  },
});

export default GuiaInternacaoPDF;
