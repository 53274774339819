import moment from "moment";
import classNames from "classnames";
import { inject } from "mobx-react";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { MoreHoriz } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";
import string from "../../../../utils/string";
import ModalParcela from "./Modal/ModalParcela";
import { VisualizarIcon } from "../../../../assets/img/svg";
import { applyCurrencyMask } from "../../../../utils/CurrencyMask";

const ListaParcelas = observer((props) => {
  const { classes, extratoStore, tipoLista } = props;
  const { onlyReadParcelas } = extratoStore;
  const { tituloParcelaList, tituloParcelaExcluidaList, tipoRepeticao } = extratoStore.modalState;
  const [openModal, setOpenModal] = useState(false);


  const lista = tipoLista === 0 ? tituloParcelaList : tituloParcelaExcluidaList;

  const selecionaParcela = async(parcela) => {
    await extratoStore.findByIdTituloParcela(parcela.id)
    setOpenModal(true);
  };

  return (
    <>
      <div className={classNames(classes.contentParcelas, 
        tipoRepeticao === "PARCELAR" && classes.contentParcelasParcelar
        )}>
        <div className={classNames(classes.row, classes.header)}>
          <div className={classes.titleHeader}> N </div>
          <div className={classes.titleHeader}> Vencimento </div>
          <div className={classes.titleHeader}> Valor </div>
          <div className={classes.moreHorizIcon}>
            <MoreHoriz />
          </div>
        </div>
        {lista.map((item, index) => {
          const showModalParcela = !onlyReadParcelas && item.id;
          return (
            <div
              key={index}
              className={classNames(
                item.status,
                classes.row,
                (onlyReadParcelas || !item.id) && classes.removeCursor,
                classes.fontWeight,
                item.excluido && classes.parcelaExcluida
              )}
              onClick={() => showModalParcela && selecionaParcela(item)}
            >
              <div> {item.numeroParcela === 0 ? "E" : item.numeroParcela} </div>
              <div>
                {string.isEmpty(item.dataVencimento)
                  ? "-"
                  : moment(item.dataVencimento).format("DD/MM/YYYY")}
              </div>
              <div>
                {typeof item.valor !== "number"
                  ? "-"
                  : applyCurrencyMask(item.valor)}
              </div>
              <div className={classNames(classes.visualizar, (onlyReadParcelas || !item.id) && classes.iconDisabled)}>
                <img src={VisualizarIcon} alt="visualizar parcela"/>
              </div>
            </div>
          );
        })}
      </div>
      { openModal && <ModalParcela
        open={openModal}
        close={() => setOpenModal(false)}
        title={string.capitalize(extratoStore.modalState.tipoRepeticao) }
      />}
    </>
  );
});

const styles = {
  contentParcelas: {
    background: "#F2F2F2",
    padding: "0 8px 8px",
    borderRadius: "8px",
    marginTop: "16px",
    overflow: "auto",
    height: "30%",

    "& .Pago": {
      color: "rgba(0, 154, 149, 1)",
    },
    "& .Atrasado": {
      color: "#FB7676",
    }
  },
  contentParcelasParcelar: {
    height: "calc(100% - 200px)",
  },
  parcelaExcluida: {
    textDecorationLine: 'line-through',
    opacity: 0.5,

  },
  fontWeight: {
    fontWeight: 600,
  },
  header: {
    cursor: "auto !important",
    position: "sticky",
    top: 0,
    background: "#f2f2f2",
    zIndex: 1,
  },
  titleHeader: {
    fontSize: 11,
    fontWeight: 500,
  },
  moreHorizIcon: {
    display: "flex",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    color: "#505050",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "8px",
    alignItems: "center",
    cursor: "pointer",
  },
  removeCursor: {
    cursor: "auto",
  },
  visualizar: {
    background: "#707C97",
    height: "24px",
    width: "24px",
    borderRadius: "100px",
    textAlign: "center",
  },
  iconDisabled: {
    background: 'rgba(134, 134, 134, 0.4)'
  }
};

const ListaParcelasWithStyles = withStyles(styles)(ListaParcelas);
export default inject("extratoStore")(ListaParcelasWithStyles);
