import { action, observable } from 'mobx';

import { findConfiguracaoWhitelabelByWhitelabelAtual } from '../services/WhitelabelService';
import BaseStore from './Base.store';

export default class WhitelabelStore extends BaseStore {
  @observable configuracaoWhitelabel = null;

  @action getConfiguracaoWhitelabel = async () => {
    try {
      this.configuracaoWhitelabel = await findConfiguracaoWhitelabelByWhitelabelAtual();
    } catch(error) {
      console.error(error);
      this.openNotification(error.message, 'error');
    }
  }

  @action clearConfiguracaoWhitelabel = () => {
    this.configuracaoWhitelabel = null;
  }
}
