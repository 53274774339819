import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ThumbsUpEmpytIcon = props => {
  return (
    <svg 
      width="18" 
      height="22" 
      viewBox="0 0 18 22" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path 
        d="M13.4996 4.2021C13.4996 1.76017 12.3595 0.00341797 10.4932 0.00341797C9.46699 0.00341797 9.11488 0.604783 8.74696 2.00299C8.67192 2.29184 8.63497 2.43248 8.59597 2.57109C8.49503 2.92982 8.31924 3.54058 8.06895 4.4021C8.06226 4.42518 8.0524 4.44692 8.03957 4.467L5.17281 8.95266C4.49476 10.0136 3.49429 10.8291 2.31841 11.2793L1.84513 11.4605C0.598402 11.9379 -0.125426 13.2416 0.128697 14.5522L0.533195 16.6383C0.774622 17.8834 1.71828 18.8743 2.9501 19.1762L10.5778 21.0457C13.109 21.6661 15.6674 20.1312 16.3113 17.6059L17.7262 12.0567C18.1697 10.3174 17.1192 8.54796 15.3799 8.10449C15.1175 8.03758 14.8478 8.00373 14.5769 8.00373H12.7536C13.2497 6.37084 13.4996 5.11106 13.4996 4.2021ZM1.60127 14.2667C1.48576 13.671 1.81477 13.0783 2.38147 12.8614L2.85475 12.6802C4.33036 12.1152 5.58585 11.0918 6.43674 9.76043L9.3035 5.27477C9.39354 5.13388 9.46292 4.98082 9.50949 4.82026C9.76079 3.95525 9.93751 3.34126 10.0399 2.97737C10.083 2.82412 10.1239 2.66867 10.1976 2.3847C10.3875 1.663 10.4809 1.50342 10.4932 1.50342C11.3609 1.50342 11.9996 2.48749 11.9996 4.2021C11.9996 5.08659 11.6738 6.53754 11.0158 8.51717C10.8544 9.0027 11.2158 9.50373 11.7275 9.50373H14.5769C14.7228 9.50373 14.868 9.52196 15.0093 9.55799C15.9459 9.79678 16.5115 10.7496 16.2727 11.6861L14.8578 17.2353C14.4172 18.9631 12.6668 20.0133 10.9349 19.5889L3.30718 17.7193C2.64389 17.5568 2.13577 17.0232 2.00577 16.3528L1.60127 14.2667Z" 
        fill="currentColor"
      />
    </svg>
  );
};

export default ThumbsUpEmpytIcon;