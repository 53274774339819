import React, { useState } from 'react'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { withStyles } from '@material-ui/core'

import { InputForm } from '../../../../../components/Modal/Input'
import string from '../../../../../utils/string'

const InputQuantity = observer(
  ({
    data,
    options,
    controleSessaoData,
    numberOfSessions,
    atendimentoStore,
    classes,
    currentField,
  }) => {
    const [quantity, setQuantity] = useState(controleSessaoData?.idPacote ? 1 : 0)
    const isAgendamentoTipo = data?.controleSessaoAgendamentoTipos?.length > 0
    const { procedimentos } = atendimentoStore?.objView

    const maxQuantity = () => {
      const maxWithSessionControl = numberOfSessions.totalQuantity - numberOfSessions.quantity
      const field = string.currentFieldControleSessao(data)?.field
      const quantity = data?.[field]?.filter(item => {
        return item?.agendamento?.id
      }).length
      const totalQuantity = data?.[field]?.filter(item => {
        return item?.ativo
      }).length
      const maxWithSchedule = totalQuantity - quantity
      return controleSessaoData?.idPacote ? Number(maxWithSessionControl) : Number(maxWithSchedule)
    }

    const handleChangeQuantity = ({ target: { value } }) => {
      if (controleSessaoData?.idPacote && value < 1) return
      if (isAgendamentoTipo && value > 1) return
      if (value > maxQuantity()) {
        return setQuantity(maxQuantity)
      }
      setQuantity(value)
    }

    const handleAddProcedimentoBySessionControl = value => {
      if (value > maxQuantity()) return
      const { field } = currentField
      const procedimentoPacote = options?.[field]?.[0]?.procedimento
      const arrayOfProcedimentos = Array.from({ length: value }, () => {
        const availablesControleSessaoProcedimento = options?.controleSessaoProcedimentos?.filter((item) => {
          return item?.ativo && !item?.agendamento?.id 
        })
        const idControleSessaoProcedimento = availablesControleSessaoProcedimento?.[quantity || 0]?.id
        return {
          procedimento: {
            idPacote: data?.id,
            ...(options?.controleSessaoProcedimentos && {
              idControleSessaoProcedimento,
            }),
            nome: procedimentoPacote?.nome,
            id: procedimentoPacote?.id,
          },
        }
      })
      const otherProcedimentos = procedimentos?.filter(item => {
        return item?.procedimento?.id !== procedimentoPacote?.id
      })
      atendimentoStore.objView.procedimentos = otherProcedimentos
        ? [...otherProcedimentos, ...arrayOfProcedimentos]
        : [...arrayOfProcedimentos]
    }

    const handleAddProcedimentoBySchedule = value => {
      if (value > maxQuantity()) return
      const procedimentoPacote = data?.controleSessaoProcedimentosOrdenado?.find(item => item.agendamento === null)?.procedimento;

      if (value < quantity) {
        const procedimentosByIndex = procedimentos?.findIndex(item => {
          return item?.procedimento?.id === procedimentoPacote?.id
        })
        procedimentos.splice(procedimentosByIndex, 1)
      } else {
        const newProcedimentos = Array.from({ length: value - quantity }, () => {
          const availablesControleSessaoProcedimento = data?.controleSessaoProcedimentosOrdenado?.filter((item) => {
            return item?.ativo && !item?.agendamento?.id 
          })
          const idControleSessaoProcedimento = availablesControleSessaoProcedimento?.[quantity || 0]?.id
          return {
            procedimento: {
              idPacote: data?.id,
              idControleSessaoProcedimento,
              nome: procedimentoPacote?.nome,
              id: procedimentoPacote?.id,
              dataVencimento: data?.dataVencimento,
              totalQuantity: maxQuantity(),
            },
          }
        })

        atendimentoStore.objView.procedimentos =
          procedimentos?.length > 0
            ? [...procedimentos, ...newProcedimentos]
            : [...newProcedimentos]
      }
    }

    const handleAddAgendamentoTipoBySchedule = value => {
      if (value > 1) return
      const agendamentoTipoSelecionado = data?.controleSessaoAgendamentoTiposOrdenado.map(item => {
        const { agendamentoTipo, agendamento, id, } = item
        if (!agendamentoTipo) return null
        return {
          idPacote: data?.id,
          label: agendamentoTipo?.descricao,
          value: agendamentoTipo?.id,
          agendamento: agendamento,
          idControleSessaoAgendamentoTipo: id,
        }
      })

      const firstAgendamentoTipoSelecionadoWithoutAgendamento = agendamentoTipoSelecionado.find(item => item.agendamento === null);
      atendimentoStore.objView.tipo = firstAgendamentoTipoSelecionadoWithoutAgendamento
      if (value < quantity) {
        atendimentoStore.objView.tipo = null
      }
    }

    const handleInputChange = e => {
      if (e.target.value < 0) return
      handleChangeQuantity(e)
      if (controleSessaoData?.idPacote) {
        if (e.target.value < 1) return
        handleAddProcedimentoBySessionControl(e.target.value)
      } else if (!isAgendamentoTipo) {
        handleAddProcedimentoBySchedule(e.target.value)
      } else {
        if (e.target.value > 1) return
        handleAddAgendamentoTipoBySchedule(e.target.value)
      }
    }

    const { agendamentoTipo } = atendimentoStore.objView?.controleSessaoAgendamentoTipo || {};
    return (
      <InputForm
        placeholder={'00'}
        disabled={isAgendamentoTipo && agendamentoTipo?.id}
        className={classes.inputContainer}
        value={quantity}
        type='number'
        onChange={e => handleInputChange(e)}
      />
    )
  }
)

const styles = {
  inputContainer: {
    background: '#F2F2F2',
    width: '40px',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    '&>div>input': {
      paddingRight: '0px',
    },
  },
}

const stores = ['atendimentoStore', 'controleSessaoStore']
const InputQuantityWithStyles = withStyles(styles)(InputQuantity)
export default inject(...stores)(InputQuantityWithStyles)
