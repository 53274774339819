import React from 'react';
import { InputAdornment, withStyles } from '@material-ui/core';
import InputSearch from '../../../../components/Input/Input';
import SearchIcon from '../../../../components/Icon/Search';
import CloseIcon from '../../../../components/Icon/CloseIcon';

const styles = ({
  inputRoot: {
    border: 'none',
    height: '40px',
    padding: '0 14px',
  },
  input: {
    height: '100%',
    alignItem: 'center',
    margin: '0 8px'
  },
  inputSearch: {
    width: '90%',
    backgroundColor: 'white',
    borderRadius: '100px',
    height: '40px',
    alignSelf: 'center'
  },
  inputIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const ModeloSearchInput = ({ 
  classes, 
  search, 
  handleChange, 
  handleClear, 
  handleSearch,
}) => {

  const StartAdornment = (
    <InputAdornment 
      className={classes.inputIcon}
      >
      <SearchIcon 
        size={16}
        onClick={handleSearch}
      />
    </InputAdornment>
  );

  const EndAdornment = (
    <InputAdornment 
      className={classes.inputIcon}
      >
      <CloseIcon 
        size={14}
        onClick={handleClear}
      />
    </InputAdornment>
  );

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <>
      <InputSearch
        placeholder="Pesquisar por modelo"
        startAdornment={StartAdornment}
        endAdornment={EndAdornment}
        classInputRoot={classes.inputRoot}
        classInput={classes.input}
        className={classes.inputSearch}
        onChange={e => handleChange(e)}
        value={search}
        onKeyPress={(e) => handleKeyPress(e)}
      />
    </>
  )
};

export default withStyles(styles)(ModeloSearchInput);