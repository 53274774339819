import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '20px',
    width: 'auto',
    color: '#FFF',
    maxWidth: '20px',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: '1',
    padding: '4px',
    transition: 'max-width 0.5s', 
    minHeight: 0,
    minWidth: 0,
  },
  expandToLeft: {
    flexDirection: 'row-reverse',
    float: 'right',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '-2px',
      marginRight: '2px',
    },
    '& > span': {
      whiteSpace: 'nowrap',
      fontSize: '8px !important',
      textTransform: 'capitalize'
    }
  },
  wrapperLockedIcon:{
    '& > svg': {
      transform: 'scale(0.8)',
    },
  }
});

const ButtonExpandHover = (props) => {
  const { classes, icon, label, color, hoverColor, onClick, direction, fontSize, locked } = props;
  const [hover, setHover] = useState(false);
  return (
    <Button 
      className={[classes.root, direction === 'toLeft' ? classes.expandToLeft : null]} 
      color={color} 
      onClick={onClick}
      onMouseOver={!locked ? () => setHover(true) : null}
      onMouseOut={!locked ? () => setHover(false) : null}
      style={{ 
        backgroundColor: hover ? hoverColor : color, 
        fontSize: fontSize,
        maxWidth: hover ? '100%' : '20px'
      }}
    >
      {!locked ? (
        <div className={classes.label}>
          <div>{icon}</div>
          <span>{hover ? label : null}</span>
        </div>
      ) : 
        <Tooltip title={label} placement="bottom">
          {icon}
        </Tooltip>
      }
    </Button>
  )
};

export default withStyles(styles)(ButtonExpandHover);
