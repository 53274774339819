import Api from "../config/api";

export const findAllSujeitoAtencaoAnexo = async (variables) => {
  const response = await Api.post("", {
    query: `
    query findAllSujeitoAtencaoAnexo($search: String, $sujeitoAtencaoId: UUID, $pageableDTO: PageableDTOInput) {
      findAllSujeitoAtencaoAnexo(search: $search, sujeitoAtencaoId: $sujeitoAtencaoId, pageableDTO: $pageableDTO) {
        last
        totalElements
        content {
          amazonS3Objeto {
            id
            nome
            extensao
            nomeComExtensao
            tamanho
          }
          anexo {
            base64
          }
          ativo
          tipo
          id
        }
      }
    }`,
    variables: variables,
  });
  return response.data.data.findAllSujeitoAtencaoAnexo;
};

export const findByIdSujeitoAtencaoAnexo = async (id) => {
  const response = await Api.post("", {
    query: `
    query findByIdSujeitoAtencaoAnexo {
      findByIdSujeitoAtencaoAnexo(sujeitoAtencaoAnexoId: "${id}") {
        id
        ativo
        tipo
        sujeitoAtencao {
          id
        }
        amazonS3Objeto {
          id
          nome
          nomeComExtensao
        }
      }
    }`,
  });
  return response.data.data.findByIdSujeitoAtencaoAnexo;
};

export const inativarSujeitoAtencaoAnexo = async variables => {
  const response = await Api.post("", {
    query: `
    mutation($sujeitoAtencaoAnexoId: UUID, $motivo: String){
      inativarSujeitoAtencaoAnexo(sujeitoAtencaoAnexoId: $sujeitoAtencaoAnexoId, motivo: $motivo) {
        sujeitoAtencaoAnexoInativacao {
          id
          dataInativacao
          inativador {
            id
            nome
          }
          motivo
          sujeitoAtencaoAnexo {
            id
          }
        }
      }    
    }
    `,
    variables: variables
  });

  return response.data.data.inativarSujeitoAtencaoAnexo
};

export const createSujeitoAtencaoAnexos = async variables => {
  const response = await Api.post("", {
    query: `
    mutation($sujeitoAtencaoId: UUID, $anexos: [SujeitoAtencaoAnexoInput]){
      createSujeitoAtencaoAnexos(sujeitoAtencaoId: $sujeitoAtencaoId, anexos: $anexos) {
        id
        amazonS3Objeto{
          id
        }
      }    
    }
    `,
    variables: variables
  });

  if(response.data.errors){
    throw response.data.errors[0]
  }

};