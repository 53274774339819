export const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflow: 'hidden',
  },
  fullSize: {
    width: '100%',
  },
  suggestionHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  scrollRoot: {
    flex: 1
  },
  suggestionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: '#FFF',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    padding: '8px',
    cursor: 'pointer',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50px',
    '& > label': {
      fontSize: '12px',
      color: '#868686',
    },
    '& > span': {
      fontSize: '12px',
      color: '#505050',
      fontWeight: 500
    }
  },
  row: {
    display: 'flex',
    gap: '4px'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    '& > label': {
      fontSize: '12px',
      color: '#FFF',
    }
  },
  suggestionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  }
});

export const buttonStyle = {
  width: '100%',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
}
