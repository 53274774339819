import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../styles';
import Button from '../../../../components/ui/Buttons/Button';
import Profile from '../../../../template/Header/Profile';
import Header from '../../../../template/Header/Header';

const PacientesHeader = ({ classes, handleClickActionButton, quantidadeAtivo, quantidadeTotal }) => {
  return (
    <Header className={classes.headerContainer}>
      <div className={classes.headerTitleContainer}>
        <div className={classes.titleContent} >
          <span className={classes.title}>Pacientes</span>
          <span className={classes.patitentsCounterText}>{`(${quantidadeAtivo}/${quantidadeTotal})`}</span>
        </div>
        <span className={classes.description}>
          Cadastro, ativação e inativação de Pacientes
        </span>
      </div>
      <div className={classes.buttonAndProfileArea}>
        <Button
          onClick={handleClickActionButton}
        >
          Novo Paciente
        </Button>
        <Profile />
      </div>
    </Header>
  )
};

export default withStyles(styles)(PacientesHeader);