import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledCanvas = styled.canvas`
  height: ${({ height }) => (height ? `${height}px` : '48px')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  flex: 1;
`;

const AudioWave = ({
  height,
  width,
  isRecording,
  mediaRecorderRef,
  strokeColor,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    setupAudioVisualization();
  }, [isRecording]);

  const setupAudioVisualization = () => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 256;
    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext('2d');

    const visualize = () => {
      const bufferLength = analyzer.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      analyzer.getByteTimeDomainData(dataArray);

      canvasContext.fillStyle = 'rgb(255, 255, 255)';
      canvasContext.fillRect(0, 0, canvas.width, canvas.height);

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = strokeColor || 'rgb(0, 0, 0)';
      canvasContext.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let xAxis = 0;

      for (let i = 0; i < bufferLength; i++) {
        const value = dataArray[i] / 128.0;
        const yAxis = (value * canvas.height) / 2;

        if (i === 0) {
          canvasContext.moveTo(xAxis, yAxis);
        } else {
          canvasContext.lineTo(xAxis, yAxis);
        }

        xAxis += sliceWidth;
      }

      canvasContext.lineTo(canvas.width, canvas.height / 2);
      canvasContext.stroke();

      requestAnimationFrame(visualize);
    };

    if (isRecording && mediaRecorderRef.current) {
      const source = audioContext.createMediaStreamSource(
        mediaRecorderRef.current.stream
      );
      source.connect(analyzer);
    }
    visualize();
  };

  return <StyledCanvas ref={canvasRef} height={height} width={width} />;
};

export default AudioWave;
