import styled from "styled-components";

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || "850px"};
  max-height: ${({ maxHeight }) => maxHeight || "800px"};
  border-radius: ${({ borderRadius }) => borderRadius || "16px"};
  background-color: ${({ color }) => color || "#fff"};
  padding: ${({ padding }) => padding};
  overflow: hidden;
`;
