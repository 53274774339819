import React from 'react'
import TrashIcon from '../../../../../components/Icon/Trash'
import MoneyIcon from '../../../../../components/Icon/Money'
import OrcamentoIcon from '../../../../../components/Icon/OrcamentoIcon'

export const menuOpcoesPopper = ({data, functions }) => {
  const { status, faturado } = data;

  const labelFaturar = faturado ? 'Ver faturamento' : 'Faturar';

  const handleCallCurrentFunction = (functionName) => {
    if (functionName) {
      functionName(data)
      functions.closePopper()
    }
  };

  const optionsMenu = [
    {
      ativo: status !== "PENDENTE",
      icon: <OrcamentoIcon size='20' color='#505050' />,
      label: 'Ver orçamento',
      onClick: () => handleCallCurrentFunction(functions?.handleClickVisualizar),
    },
    {
      ativo: status === "APROVADO",
      icon: <MoneyIcon size='20' color='#505050' />,
      label: labelFaturar,
      onClick: () => handleCallCurrentFunction(functions?.handleFaturar),
    },
    {
      ativo: status === "PENDENTE",
      icon: <TrashIcon size='20' color='#505050' />,
      label: 'Excluir',
      onClick: () => handleCallCurrentFunction(functions?.handleExcluir),
    },
  ]
  return optionsMenu
}
