import React from "react";
import classNames from "classnames";
import { Checkbox, withStyles } from "@material-ui/core";
import FieldTitle from "./FieldTitle";
import { observer } from "mobx-react-lite";

const CheckboxFaces = observer(({
  classes,
  dentesTipoFace,
  disabled,
  handleCheckFace,
  posicao,
}) => {
  const handleCheck = (posicao, item, index) => {
    handleCheckFace(posicao, item, index)
  };

  return (
    <div className={classes.contentFace}>
      <FieldTitle title="Face:" />
      {dentesTipoFace.map((item, index) => {
        return (
          <div
            className={classNames(
              classes.contentDenteTipoFace,
              disabled && classes.contentFaceDisabled
            )}
            key={index}
          >
            <Checkbox
              color={"primary"}
              onChange={() => handleCheck(posicao, item, index)}
              checked={item.checked}
              disabled={disabled}
            />
            <label> {item.label} </label>
          </div>
        );
      })}
    </div>
  );
});

const styles = {
  contentFace: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "4px 30px",
    gap: "10px",
    background: "#F2F2F2",
    borderRadius: "0px 0px 10px 10px",
  },
  contentDenteTipoFace: {
    fontSize: "12px",
    color: "#868686",
    display: "flex",
    gap: "4px",

    "& span": {
      margin: 0,
    },
  },
  contentFaceDisabled: {
    opacity: 0.4,
  },
};

export default withStyles(styles)(CheckboxFaces);
