import React from "react";
import { inject, observer } from "mobx-react";
import {
  CircularProgress,
  Grid,
  DialogContent,
  withStyles,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fab
} from "@material-ui/core";
import Dialog from "../../components/Dialog/Dialog";
import DialogHeader from "../../components/Dialog/DialogHeader";
import { InputDateForm } from "../../components/Input/InputDadosCadastraisPaciente";
import { applyCurrencyMask } from "../../utils/CurrencyMask";
import { createMultiplo } from "../../services/GraphqlService";
import Notification from "../../components/Notification";
import { ReactSelect } from "../../components/Select/SelectSearch";
import ItemGrid from "../../components/Modal/ItemGrid";
import PageTitle from "../PageTitle/PageTitle";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#219A97",
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  body: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    color: "#505050",
  },
}))(TableCell);

@inject("extratoStore", "procedimentosRealizadosStore")
@observer
class ModalFaturarProcedimentoRealizado extends React.Component {
  state = {
    valorTotal: 0,
    listaFaturar: [],
    listaAdaptada: [],
    dados: {
      categoriaFinanceira: null,
      contaBancaria: null,
      formaPagamento: null,
      centroCusto: null,
      dataPagamento: null,
      dataVencimento: null
    },
    notification: {
      isOpen: false,
      message: "",
    },
    savingFaturar: false,
    listaCategoriaFinanceira: [],
    listaContaBancaria: [],
    listaFormaPagamento: [],
    listaCentroCusto: [],
  };

  async componentDidMount() {
    const { procedimentosRealizadosStore } = this.props;

    const procedimentosRealizados = await procedimentosRealizadosStore.lista.filter(
      (item) => item.checkedFaturar
    );

    this.setState({ listaFaturar: procedimentosRealizados });
    this.adaptarListaProcedimentosRealizados();
    this.calculaSomaTotal();
    this.loadingOptionsFields();
    
  };

  adaptarListaProcedimentosRealizados = () => {
    const { listaFaturar } = this.state;

    const listaAdaptada = listaFaturar.map((item) => {
      const procedimentoConvenio =
        !!item.sujeitoAtencao?.dadosConvenio?.convenio?.id &&
        item.procedimento.convenioProcedimentos.find(
          (itemProcedimento) =>
            itemProcedimento?.convenio?.id ===
            item.sujeitoAtencao.dadosConvenio.convenio.id
        );
      const valorProcedimento = !!procedimentoConvenio
        ? procedimentoConvenio.valor
        : 0;
      return {
        procedimentoRealizado: {
          ...(item?.controleSessaoProcedimento?.id && {
            controleSessaoProcedimento: {
              id: item.controleSessaoProcedimento.id,
            },
          }),
          id: item.id,
        },
        nome: item.procedimento.nome,
        idConvenio:
          item?.convenio?.id ||
          item?.sujeitoAtencao?.dadosConvenio?.convenio?.id,
        valor: item?.valor === 0 ? 0 : item?.valor || valorProcedimento,
        idProfissionalSaude: item?.recebedor?.id,
        idSujeitoAtencao: item.sujeitoAtencao.id,
      };
    });
    this.setState({
      listaAdaptada: listaAdaptada,
    });
  };

  calculaSomaTotal = () => {
    const { listaAdaptada } = this.state;
    const valor = listaAdaptada.map(
      (item) =>  item?.valor === 0 ? 0 :
        item?.valor ||
        item.procedimento?.convenioProcedimentos.find(
          (itemProcedimento) =>
            itemProcedimento.convenio.id ===
            item?.sujeitoAtencao?.dadosConvenio?.convenio?.id
        ).valor
    );

    const valorTotal =
      !!valor &&
      valor.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

    this.setState({ valorTotal: valorTotal });
  };

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  loadingOptionsFields = () => {
    const { extratoStore } = this.props;
    
    this.handleLoadOptions(extratoStore.findAllCategoriaFinanceira(), 'listaCategoriaFinanceira');
    this.handleLoadOptions(extratoStore.findAllContaBancaria(), 'listaContaBancaria');
    this.handleLoadOptions(extratoStore.findAllFormaPagamento(), 'listaFormaPagamento');
    this.handleLoadOptions(extratoStore.findAllCentroCusto(), 'listaCentroCusto');
  };

  handleLoadOptions = (findAll, lista) => {
    findAll.then(data => {
      const listaItens = data.content.map(item => ({
        id: item.id,
        nome: item.nome
      }));
      
      this.setState({ [lista]: listaItens})
    })
  }

  handleChange = (field, event) => {
    const { dados } = this.state;

    this.setState({ dados: 
      {
      ...dados,
      [field]: event 
      }
    });
  };

  handleFaturar = () => {
    try {
      const {
        listaAdaptada,
        dados
      } = this.state;

      this.setState({ savingFaturar: true });

      if (!dados?.dataVencimento) {
        const notification = {
          isOpen: true,
          message: "Por favor, preencha o campo da data de vencimento.",
        };
        this.showAlertMessage(notification);
        this.setState({
          errorVencimento: true,
          savingFaturar: false,
        });
        return;
      }

      const titulos = listaAdaptada.map((item) => ({
        procedimentoRealizado: {
          ...(item?.procedimentoRealizado?.controleSessaoProcedimento?.id && {
            controleSessaoProcedimento: {
              id: item.procedimentoRealizado.controleSessaoProcedimento.id,
            },
          }),
          id: item.procedimentoRealizado.id,
        },
        convenio: item.idConvenio && { id: item.idConvenio },
        nome: item.nome,
        profissionalSaude: { id: item.idProfissionalSaude },
        sujeitoAtencao: { id: item.idSujeitoAtencao },
        tipo: "RECEITA",
        valor: item.valor,
        dataVencimento: dados.dataVencimento.format("YYYY-MM-DD"),
        dataPagamento: dados.dataPagamento && dados.dataPagamento.format("YYYY-MM-DD"),
        formaPagamento: dados.formaPagamento && { id: dados.formaPagamento?.id } ,
        centroCusto: dados.centroCusto && { id: dados.centroCusto?.id} ,
        categoriaFinanceira: dados.categoriaFinanceira && { id: dados.categoriaFinanceira?.id},
        contaBancaria: dados.contaBancaria && { id: dados.contaBancaria?.id},
      }));

      createMultiplo({
        lista: titulos, 
        nomeVariables: "titulo", 
        tipo: "TituloInput", 
        mutationName:"createTitulo"
      });


      const { closeModal, goToFinanceiro } = this.props;

      this.setState({ savingFaturar: false });

      goToFinanceiro();
      closeModal();
    } catch (error) {
      console.log(error)
    }
  };

  showAlertMessage = (notification) => {
    this.setState({ notification });

    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    this.setState({ notification });
  };

  render() {
    const {
      classes,
      open,
    } = this.props;
    const {
      dados,
      savingFaturar,
      listaFormaPagamento,
      listaCentroCusto,
      listaCategoriaFinanceira,
      listaContaBancaria
    } = this.state;

    return (
      <>
      <PageTitle title="Paciente - Faturar procedimentos realizados"/>
      <Dialog open={open} maxWidth={"sm"} fullWidth={true}>
        <DialogHeader
          title="Faturamento"
          closeButton={true}
          actionClose={this.handleClose}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid item xs={12} className={classes.listaFaturar}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRowHeader}>
                  <CustomTableCell align="center">
                    PROCEDIMENTO REALIZADO
                  </CustomTableCell>
                  <CustomTableCell align="center">CONVENIO</CustomTableCell>
                  <CustomTableCell align="center">VALOR</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.listaFaturar.map((item) => {
                  return (
                    <>
                      <TableRow className={classes.tableRow} key={item.id}>
                        <CustomTableCell
                          component="th"
                          className={classes.nomeProcedimento}
                        >
                          {item.procedimento.nome}
                        </CustomTableCell>
                        <CustomTableCell component="th">
                          {item?.convenio?.descricao ||
                            item?.sujeitoAtencao?.dadosConvenio?.convenio
                              ?.descricao}
                        </CustomTableCell>
                        <CustomTableCell
                          component="th"
                          className={classes.valor}
                          align="center"
                        >
                          {applyCurrencyMask(
                            item?.valor === 0 ? 0 : item?.valor ||
                              item.procedimento.convenioProcedimentos.filter(
                                (itemProcedimento) =>
                                  itemProcedimento.convenio.descricao ===
                                  item?.sujeitoAtencao?.dadosConvenio?.convenio
                                    ?.descricao
                              )[0]?.valor
                          )}
                        </CustomTableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
          <div className={classes.total}>
            <span className={classes.spanTotal}>Total:</span>
            <span>{applyCurrencyMask(this.state.valorTotal)}</span>
          </div>
          <div className={classes.content}>
            <Grid item className={classes.formGroup}>
              <Grid item className={classes.rowDados}>
                <ItemGrid size={4} label={"Vencimento *:"}>
                  <InputDateForm
                    id="vencimento"
                    iconposition="end"
                    className={classes.dateTime}
                    value={dados.dataVencimento}
                    onChange={(e) => this.handleChange("dataVencimento", e)}
                  />
                </ItemGrid>
                <ItemGrid size={4} label={"Data de pag.:"}>
                  <InputDateForm
                    id="dataPagamento"
                    iconposition="end"
                    className={classes.dateTime}
                    value={dados.dataPagamento}
                    onChange={(e) => this.handleChange("dataPagamento", e)}
                  />
                </ItemGrid>
              </Grid>
              <Grid item className={classes.rowDados}>
                <ItemGrid size={6} label={"Categoria:"}>
                  <ReactSelect
                    className={classes.field}
                    value={dados.categoriaFinanceira}
                    onChange={(e) =>
                      this.handleChange("categoriaFinanceira", e)
                    }
                    options={listaCategoriaFinanceira || []}
                    isClearable
                    getOptionLabel={(option) => option.nome}
                    getOptionValue={(option) => option.id}
                  />
                </ItemGrid>
                <ItemGrid size={6} label={"Conta:"}>
                  <ReactSelect
                    className={classes.field}
                    value={dados.contaBancaria}
                    onChange={(e) => this.handleChange("contaBancaria", e)}
                    options={listaContaBancaria || []}
                    isClearable
                    getOptionLabel={(option) => option.nome}
                    getOptionValue={(option) => option.id}
                  />
                </ItemGrid>
              </Grid>
              <Grid item className={classes.rowDados}>
                <ItemGrid size={6} label={"Forma de Pagamento:"}>
                  <ReactSelect
                    className={classes.field}
                    value={dados.formaPagamento}
                    onChange={(e) => this.handleChange("formaPagamento", e)}
                    options={listaFormaPagamento || []}
                    isClearable
                    getOptionLabel={(option) => option.nome}
                    getOptionValue={(option) => option.id}
                  />
                </ItemGrid>
                <ItemGrid size={6} label={"Centro de custo:"}>
                  <ReactSelect
                    className={classes.field}
                    value={dados.centroCusto}
                    onChange={(e) => this.handleChange("centroCusto", e)}
                    options={listaCentroCusto || []}
                    isClearable
                    getOptionLabel={(option) => option.nome}
                    getOptionValue={(option) => option.id}
                  />
                </ItemGrid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <Grid container justify="flex-end">
          <Grid item xs={3} className={classes.gridButton}>
            <Fab
              color="primary"
              type="submit"
              onClick={this.handleFaturar}
              disabled={savingFaturar}
              className={classes.botaoFaturar}
            >
              Faturar
              {savingFaturar && (
                <CircularProgress
                  color="inherit"
                  size={14}
                  style={{ marginLeft: 10 }}
                />
              )}
            </Fab>
          </Grid>
        </Grid>
        <Notification
          close={this.closeAlertMessage}
          reset={this.closeAlertMessage}
          isOpen={this.state.notification.isOpen}
          variant={"error"}
          message={this.state.notification.message}
        />
      </Dialog>
      </>
    );
  }
}

const styles = () => ({
  formGroup: {
    marginTop: 15,
  },
  listaFaturar: {
    display: "flex",
    flexDirection: "column",
    maxHeight: '170px',
    overflow: 'auto',
  },
  total: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "7%",
    marginTop: "20px",
    fontWeight: "600",
  },
  spanTotal: {
    marginRight: "23%",
  },
  rowDados: {
    marginTop: 15,
    marginLeft: 10,
    display: 'flex',

  },
  nomeProcedimento: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 170,
  },
  valor: {
    width: 100,
  },
  field: {
    width: '100%',
  },
  dateTime: {
		marginTop: 5,
		"& input": {
			textAlign: "start",
			minWidth: 87,
			width: "100%",
		},
	},
  tableRow:{
    height: 40
  },
  tableRowHeader: {
    position: 'sticky',
    top: 0,
    height: 40
  },
  botaoFaturar:{
    fontSize: 14,
    width: '100%',
    height: '40px',
    borderRadius: '100px',
  },
  gridButton: {
    margin: '10px 24px 20px',
  },
  dialogContent: {
    padding: '0 24px',
    overflow: 'hidden',
  },
  content: {
    overflow: 'auto',
    maxHeight: '20vh',
    height: '20vh',
  },
});
export default withStyles(styles)(ModalFaturarProcedimentoRealizado);
