const styles = {
  content: {
    maxWidth: "505px",
    display: "flex",
    flexDirection: "column",
    width: '100%',
    padding: '0 36px',
  },
  titulo: {
    fontSize: "54px",
    lineHeight: "81px",
    color: "#505050",
    marginBottom: "8px",
  },
  inputStyle: {
    height: "56px",
    padding: "0 16px",
    background: "#fff",
    border: "1px solid #EBEBEB",
    marginBottom: "18px",

    "& input": {
      height: "41px",
      padding: 0,
      fontFamily: "Poppins !important",
    },
  },
  inputError: {
    border: '1px solid #e27575',
  },
  titleInput: {
    color: "#7C7C7C",
    fontWeight: 600,
    fontSize: "16px",
  },
  textoInformativo: {
    marginBottom: "18px",
  },
  buttonEnviar: {
    background: "#219A97",
    height: "56px",
    border: "2px solid rgba(0, 0, 0, 0.1)",
    marginBottom: "82px",

    "@media (max-width: 768px)": {
      marginBottom: "40px",
    },
  },
  arrowLeftIcon: {
    width: "14px",
    height: "12px",
  },
  voltarLoginLinkStyle: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#505050",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
  textoVoltarLogin: {
    marginLeft: "8px",
  },
  wrapperItens: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',

    '@media (max-width: 990px)': {
      gridTemplateColumns: '1fr',
    },
  },
  verifyPassword: {
    display: 'flex',
    flexDirection: 'column',
    '& > h1': {
      fontSize: '1rem',
      color: '#333333',
      fontWeight: '600',
    },
  },
  verifyPasswordItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '4px 2px'
  },
  verifyPasswordItemText: {
    fontSize: '0.875rem',
    color: '#333333',
    fontWeight: '500',
    whiteSpace: 'nowrap',
  },
  validPasswordItemText: {
    textDecoration: 'line-through',
    color: '#868686',
  },
  invalidPasswordItemText: {
    color: '#FB7676',
  },
};

export default styles;
