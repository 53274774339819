import React from "react";
import { Route, withRouter } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";

import App from "./app/pages/App";
import Login from "./app/pages/Login/Login";
import RecuperarSenha from "./app/pages/RecuperarSenha/RecuperarSenha";
import SelectRede from "./app/pages/SelectRede/index";

import Theme from "./app/template/Theme";
import { dadosGtm } from "./app/services/UsuarioService";

class Routes extends React.Component {
  componentWillUpdate ({ location, history }) {
    if (location.pathname === this.props.location.pathname) {
        // don't log identical link clicks (nav links likely)
        return;
    }
    dadosGtm({pagePath: location.pathname});
  }
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Theme>
          <Route path="/login" component={Login} />
          <Route path="/redefinir-senha" component={RecuperarSenha} />
          <Route path="/select-rede" component={SelectRede} />
          <Route path="/" component={App} />
        </Theme>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withRouter(Routes);
