import React from 'react';
import { withStyles } from "@material-ui/core";
import Dialog from '../Dialog/Dialog';
import {Button} from '../../components/ui/Buttons';

const styles = ({
  popper: {
    heigt: 'fit-content'
  },
  container: {
    display: 'flex',
    flexDirection: "column",
    padding: '32px',
  },
  title: {
    fontSize: '18px !important',
    fontWeight: '600',
    color: '#505050'
  },
  message: {
    fontWeight: '400',
    color: '#505050',
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
  }
});

const ButtonStyle = {
  height: '36px',
  textTransform: 'none',
  width: '50%',
  borderRadius: '100px'
};

const ButtonConfirmStyle = {
  backgroundColor: '#FB7676',
};

const ButtonCancelStyle = {
  backgroundColor: '#FFF',
  color: '#505050'
}

const ConfirmModal = ({ open, title, text, onCancel, onConfirm, classes, isLoading }) => {
  return (
    <Dialog open={open} className={classes.open}>
      <div className={classes.container}>
        <span className={classes.title}>{title}</span>
        <span className={classes.message}>{text}</span>
        <div>
          <Button onClick={onCancel} style={{...ButtonStyle, ...ButtonCancelStyle}}  >
            Cancelar
          </Button>
          <Button onClick={onConfirm} style={{...ButtonStyle, ...ButtonConfirmStyle} } disabled={isLoading}>
            Confirmar
          </Button>
        </div>
      </div>
    </Dialog>
  )
};

export default withStyles(styles)(ConfirmModal);
