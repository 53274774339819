import React from 'react';
import Select, {components} from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import CreatableSelect from 'react-select/lib/Creatable';

import Colors from "../../template/Colors";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {Cancel as CancelIcon} from "@material-ui/icons";

import {Checkbox, withStyles} from '@material-ui/core';

function noOptionsMessage() {
    return 'Pesquisar'
}

function loadingMessage() {
    return 'Carregando...'
}

const customStyles = (customStylesSelect) => {
    const { menu } = customStylesSelect || {};
    
    return ({
    menu: (provided) => ({
        ...provided,
        maxHeight: menu?.maxHeight || '20vh',
        "&> div": {
            maxHeight: menu?.maxHeight ||'20vh'
        }
      }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ccc' : (state.isFocused ? '#e9e9e9' : '#fff'),
        color: Colors.commons.fontColor,
        cursor: 'pointer',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        width: '100% !important',
        ':active': {
            backgroundColor: state.isSelected ? '#999' : '#e9e9e9',
            color: 'black',
        },
    }),
    control: (provided) => ({
        ...provided,
        width: '100%',
        minHeight: 0,
        fontSize: '14px',
        fontFamily: 'Poppins !important',
        color: Colors.commons.black,
        backgroundColor: Colors.commons.gray2,
        borderRadius: '8px',
        cursor: 'pointer',
        borderStyle:'none',
        maxHeight: 'auto',
        lineHeight: 1.5,
        boxShadow: "none",
        ':hover': {
            borderColor: '#868686'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: Colors.commons.darkGray,
        fontFamily: 'Poppins !important',
    }),
    input: (provided) => ({
        ...provided,
        color: Colors.commons.black,
        opacity: '1 !important',
        fontFamily: 'Poppins !important',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
})}

const styles = {
    lista: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},

	chip: {
		placeItems: 'center',
		background: '#F2F2F2',
		border: '0.5px solid #F2F2F2',
		borderRadius: '100px',
		minWidth: '80px',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '4px 8px',
		margin: '3px',
		transition: "border 0.4s",
        '&:hover': {
			border: '1px solid #363636',
		},
		'& svg': {
            color: '#868686',
            marginLeft: '5px',
			"&:hover": {
				cursor: 'pointer',
			}
        },
	},
}

const DropdownIndicator = (props) => {
    return components.DropdownIndicator && (
        <i className={`material-icons ${props.className} `}
           style={{
               fontFamily: 'Poppins !important',
               color: Colors.commons.gray7,
               top: 'calc(50% - 12px)',
               fontSize: '24px',
               borderRadius: '50%',
               right: 6,
               margin:'3px 5px',
               width: '24px'
           }}>
            {props.selectProps.menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        </i>
    );
};

const ClearIndicator = (props) => {
    return components.ClearIndicator && (
        <components.ClearIndicator {...props}>
            <i className={`material-icons ${props.className} `}
                style={{
                    color: Colors.commons.gray7,
                    fontSize: '16px',
                    borderRadius: '50%',
                }}>
                {'close'}
            </i>
        </components.ClearIndicator>
    );
};

export const SelectSearch = props => {
    const { className, elements, placeholder, ...other } = props;

    return <FormControl className={className}>
        <Select
            {...other}
            styles={customStyles()}
            placeholder={placeholder}
            isClearable={true}
            components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator}}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            options={elements?.map(c => ({ value: c.value, label: c.name }))}
        />
    </FormControl>
};

export const ReactSelect = props => {
    const { className, elements, placeholder, isClearable, disableMenuPortal, customStylesSelect, ...other } = props;

    return <FormControl className={className}>
        <Select
            {...other}
            styles={customStyles(customStylesSelect)}
            placeholder={placeholder || ""}
            isClearable={isClearable}
            components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator}}
            menuPortalTarget={disableMenuPortal ? null : document.body}
        />
    </FormControl>
};

export const ReactSelectCheckbox = props => {
    return <Select
        {...props}
        placeholder="Selecione"
        defaultValue={[]}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        styles={{
            ...customStyles(),
            menu: (provided) => ({
                ...provided,
              }),
            }}
        components={{ 
            DropdownIndicator,
            ClearIndicator,
            IndicatorSeparator: null,
            Option: ({ children, isSelected, ...props }) => {
                return <components.Option {...props}>
                    <Checkbox
                        color="primary"
                        checked={isSelected}
                    />
                    {children}
                </components.Option>
            },
            ValueContainer: ({ children, ...props }) => {
                let [values, input] = children
                if (Array.isArray(values)) {
                    const plural = values.length === 1 ? "" : "s";
                    values = `${values.length} selecionado${plural}`;
                }

                return <components.ValueContainer {...props}>
                    {values}
                    {input}
                </components.ValueContainer>
            }
        }}
        menuPortalTarget={document.body}
    />
}

export const ReactSelectCreate = props => {
    const { className, elements, placeholder, ...other } = props;

    return <FormControl className={className}>
        <CreatableSelect
            {...other}
            styles={customStyles()}
            placeholder={placeholder || ""}
            isClearable
            components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator}}
            createOptionPosition="first"
            menuPortalTarget={document.body}
        />
    </FormControl>
};

export const ReactSelectSearchComponent = props => {
    const { classes, className, elements, propLabel = 'name' , propValue = 'value', optionLabel = 'descricao', optionValue = 'id', onChange, isMulti, isClearable, disabled, showIsMulti=true, disableDeleteOption, isValueComplete, ...other } = props;
    let {value} = props;

    const handleClick = (element) => {
        const disableElement = element?.id === disableDeleteOption?.id
        if(disableElement) return
        onChange(value = value.filter(e=>e[optionValue]!==element[optionValue]));
    }

    return <>
        { !disabled &&
            <FormControl className={className}>
                <Select
                    {...other}
                    styles={customStyles()}
                    isClearable={isClearable || false}
                    components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator}}
                    noOptionsMessage={noOptionsMessage}
                    loadingMessage={loadingMessage}
                    getOptionLabel={option => option[optionLabel]}
                    getOptionValue={option => option[optionValue]}
                    options={elements?.map(c => (isValueComplete ? c : { [optionValue]: c[propValue], [optionLabel]: c[propLabel]}))}
                    isMulti={isMulti}
                    onChange={onChange}
                    controlShouldRenderValue={!isMulti}
                />
            </FormControl>
        }
        { showIsMulti && isMulti &&
            <div className={classes.lista}>
                {value?.map((element) => {
                    return (<div className={classes.chip}>
                        {element[optionLabel]}
                        { !disabled &&
                            <CancelIcon
                                onClick={() => handleClick(element)}
                            />
                        }
                    </div>)}
                )}
            </div>
        }
    </>
};

export const ReactSelectSearch = withStyles(styles)(ReactSelectSearchComponent);

export const SelectSearchAsync = props => {
    const { className, placeholder, ...other } = props;

    return <FormControl className={className}>
        <AsyncSelect
            {...other}
            styles={customStyles()}
            cacheOptions
            isClearable={true}
            placeholder={placeholder}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            components={{ IndicatorSeparator: null, DropdownIndicator, ClearIndicator }}
        />
    </FormControl>
};