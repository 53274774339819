import { Switch } from '@material-ui/core';
import React from 'react';

export const getMedicamentosColumns = (onClickSwitch) => {
  return [
    {
      Header: "Nome",
      getValue: medicamento => medicamento.produto,
      props: { component: "th", scope: "row" },
      field: "produto"
    },
    {
      Header: "Forma farmacêutica",
      getValue: medicamento => medicamento.formaFarmaceutica,
      props: { center: "true" },
      field: "formaFarmaceutica"
    },
    {
      Header: "Principios ativos",
      getValue: medicamento => medicamento.principioAtivo,
      props: { center: "true  " },
      field: "principioAtivo"
    },
    {
      Header: "Status",
      getValue: medicamento => 
        <Switch 
          onClick={() => onClickSwitch(medicamento.id)}
          checked={medicamento.ativo}
          color="primary"
        />,
      isClick: true,
      props: { center: "true  " },
      field: "ativo"
    },
  ];
};
