import React from 'react'
import moment from 'moment'
import string from '../../../../../utils/string'
import { TAXA_ADMINISTRATIVA } from '../../../constants/perfilViziConstants'

export const getColumns = () => {
  const COLUMNS = [
    {
      Header: 'Data',
      field: 'dataPagamento',
      getValue: row => {
        return moment(row?.dataPagamento).format('DD/MM/YYYY')
      },
    },
    {
      Header: 'Médico',
      field: 'profissionalSaude.nome',
      getValue: row => {
        return string.truncate(row?.profissionalSaude?.nome, 20)
      },
    },
    {
      Header: 'Paciente',
      field: 'sujeitoAtencao.nome',
      getValue: row => {
        return string.truncate(row?.sujeitoAtencao?.nome, 20)
      },
    },
    {
      Header: 'Unidade',
      field: 'unidade.nome',
      getValue: row => {
        return string.truncate(row?.unidade?.nomeFantasia, 20)
      },
    },
    {
      Header: 'Valor Pago',
      field: 'valor',
      getValue: row => {
        return (
          <span
            style={{
              color: '#219A97',
              fontWeight: '600',
            }}
          >
            {row?.valor ? row?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
          </span>
        )
      },
    },
    {
      Header: 'Valor Receber',
      getValue: row => {
        const valor = row?.valor - (row?.valor * TAXA_ADMINISTRATIVA) / 100
        return (
          <span
            style={{
              color: '#219A97',
              fontWeight: '600',
            }}
          >
            {valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </span>
        )
      },
    },
  ]
  return COLUMNS
}
