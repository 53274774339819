export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',

        '& .rbc-day-slot .rbc-time-slot': {
            borderBottom: 'none',
            minHeight: '130px',
            fontWeight: '300',
            color: '#ccc',
            '&:before': {
                top: '43%',
                transformOrigin: 'center',
                transform: 'scale(1, 1.3) translate(-50%)'
            },
            '@media (max-width: 1280px)': {
                minHeight: '80px'
            }
        },
        '& .rbc-header, & .rbc-day-slot .rbc-time-slot': {
            fontFamily: `'Nexa', "Arial Black", sans-serif !important`
        },
        '& .rbc-header': {
            fontWeight: 'lighter',

            '&.rbc-today .header-label .day-label': {
                color: theme.palette.primary.main + ' !important'
            }
        },
        '& .header-label': {
            '& .day-label': {
                fontWeight: 'lighter',
                fontSize: '3em',
                color: '#999'
            },

            '& .day-week-label': {
                fontWeight: 'lighter',
                color: theme.palette.primary.main,
                fontSize: '1em'
            }
        },
        '& .rbc-time-view': {
            border: 0
        },
        /*'& .rbc-time-header-content': {
                borderColor: 'transparent',
            },*/
        '& .rbc-time-content > * + * > *': {
            borderLeft: 'transparent'
        },

        '& .rbc-time-content .rbc-day-slot.rbc-time-column .rbc-events-container': {
            borderLeft: '1px solid #DDD'
        }
    },

    container: {
        gridArea: 'container',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '64px 1fr',
        gridTemplateAreas: `
            "header"
            "content"
        `,
        height: '100%',
        width: '100%'
    },

    content: {
        gridArea: 'content',
        margin: '0 16px 20px 5%',
    },

    header: {
        gridArea: 'header',
        justifyContent: 'space-between',
        padding: '0 16px !important',
    },

    titleFilterHeader: {
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
    },

    status: {
        display: 'flex',
        alignItems: 'center'
    },
    titleHeader: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        margin: 0
    },

    dividerRoot: {
        backgroundColor: theme.palette.commons.gray3
    },

    panelLeft: {
        overflowY: "auto",
        padding: '0 25px',
        maxWidth: '280px',
        height: '100%',
        [theme.breakpoints.up('xl')]: {
            padding: '0 20px',
            maxWidth: '330px'
        }
    },

    rootPanelLeft: {
        padding: '15px 0',
        // fontWeight: '900',
        // fontFamily: 'sans-serif',
        maxWidth: '280px',
        [theme.breakpoints.up('xl')]: {
            padding: '15px',
            maxWidth: '330px'
        }
    },

    tableVigencesRoot: {
        border: `solid 1px ${theme.palette.commons.gray3}`,
        borderRadius: 27,
        borderCollapse: 'separate',
        overflow: 'hidden',
        width: '98%'
    },

    tableVigencesHeader: {
        backgroundColor: theme.palette.commons.gray3
    },

    tableVigencesHeadRow: {
        height: 36
    },

    checkboxOptions: {
        display: 'grid',
        gridRowGap: 0,
        [theme.breakpoints.down('md')]: {
            padding: '0 15px'
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, auto))',
            padding: '0 15px'
        },
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, auto))',
            padding: '0 10px'
        }
    },

    headCell: {
        color: theme.palette.commons.white,
        fontSize: '0.8rem',
        padding: '0',
        textTransform: 'none',
        '&:last-child': {
            paddingRight: 0
        }
    },

    bodyRowRoot: {
        height: 32
    },

    bodyCell: {
        borderRight: `1px solid ${theme.palette.commons.gray3}`,
        padding: '12px 0 5px',
        width: '50%',
        '&:last-child': {
            borderRight: 0,
            paddingRight: 0
        }
    },

    searchBlock: {
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: 25
    },

    openModal: {
        position: 'absolute',
        top: '20px',
        right: '10px',
        width: '400px'
    },

    contentLoadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },

    notFoundTitle: {
        fontSize: '1.2rem',
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    search: {
        maxWidth: '700px',
        margin: '15px 0'
    },
    inputName: {
        borderRadius: 10,
        borderRight: `1px solid ${theme.palette.commons.gray3}`,
    },

    camposEntrada: {
        textTransform: 'capitalize',
        color: '#00C1BF',
        listStyle: 'none',
        padding: 0
    },
    camposSujeitoAtencao: {
        color: '#333333',
        listStyle: 'none',
        padding: 0
    },
    camposProfissionaisSaude: {
        color: '#333333',
        listStyle: 'none',
        padding: 0,
    },
    camposSistemaItem: {
        marginBottom: '3px',
        cursor: 'pointer'
    },
    camposSistemaItemSelected: {
        color: "#52D5FC",
    },
    hashtag: {
        color: 'red'
    },
    handle: {
        background: 'blue'
    },
    editor: {
        border: 'none',
    },
    scrollEditor: {
        maxHeight: '55vh',
        overflow: 'scroll !important'
    },
    footer:{
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    acoes: {
		position: "absolute",
		right: "35px",
		bottom: "45%",
		display: "flex",
		flexDirection: "column",
	},

    buttonCancelar: {
		margin: 8,
		height: 40,
		width: 40,
		color: '#fff',
		background: theme.palette.commons.red,
		"&:hover": {
			background: theme.palette.commons.darkRed,
		}
	},

    contentToggleButtons: {
        gridArea: 'toggleButtons',
        alignSelf: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        width: '100%',
    },

    buttonsTab: {
        margin: '0 24px 8px'
    },

    contentInformacoes: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 169px)',
    },
    contentTab: {
        height: 'calc(100vh - 169px)',
        marginTop: '16px',
    },
    selectProfissional:{
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        marginLeft: '16px',
        maxWidth: '190px',
    },
    reactSelect: {
        width: '100%',
        maxWidth: '190px',
        "&>div>div": {
          height: '40px',
          borderRadius: '8px',
        }
    },
    switch:{
        padding:"10px",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        color:"#868686",
        fontSize:"12px",
        fontWeight:"500",
    }    
});
