import colors from "../../../../template/Colors";

const styles={
    content: {
        overflow: 'auto',

        "& thead th": {
            top: 0,
            position: "sticky",
        },
        "& .statusPagamento": {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            color: "#868686",
            padding: '4px 8px',
            fontSize: '13px',
        },
        "& .Pago": {
            backgroundColor: colors.commons.greenLight,
            border: `1px solid ${colors.commons.green}`,
        },
        "& .Atrasado": {
            backgroundColor: colors.commons.redLight,
            border: `1px solid ${colors.commons.red}`,
        },
        "& .Aberto": {
            backgroundColor: colors.commons.blueLight,
            border: `1px solid ${colors.commons.blue}`,
        },
    },
    notFoundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },

};

export default styles;