import React from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import DoneIcon from '@material-ui/icons/Done';
import { AlertIcon } from "../../../../assets/img/svg";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

export const alertasUnificacao = {
    default: {
        nome: 'default',
        open: false,
        title: '',
        text: '',
        textStrong: '',
        icon: null,
        color: '',
        isButtons: false,
      },
      primeiraConfirmacao:{
        nome: 'primeiraConfirmacao',
        open: true,
        title: "Essa ação não poderá ser desfeita!",
        textStrong: "",
        text: "Essa ação irá unificar todos os dados dos cadastros selecionados para um cadastro principal que você irá definir. Os demais cadastros selecionados serão inativados para sempre.",
        icon: <img src={AlertIcon} alt="Alert"/>,
        color: "yellow",
        isButtons: true,
      },
      segundaConfirmacao:{
        nome: 'segundaConfirmacao',
        open: true,
        title: "Você realmente deseja continuar?",
        text: "Ao confirmar você irá iniciar o processo de unificação de todos os dados dos cadastros selecionados, migrando eles para o cadastro selecionado como principal.",
        textStrong: "Os demais cadastros serão inativados para sempre e essa ação não poderá ser desfeita!",
        icon: null,
        color: "",
        isButtons: true, 
      },
      ultimaConfirmacao: {
        nome: 'ultimaConfirmacao',
        open: true,
        title: "Essa ação não poderá ser desfeita, você deseja continuar?",
        text: "",
        textStrong: "",
        icon: <img src={AlertIcon} alt="Alert"/>,
        color: "yellow",
        isButtons: true,
        ultimaConfirmacaoUnificar: true,
      },
      processoIniciado: {
        nome: 'processoIniciado',
        open: true,
        title: "Processo de unificação iniciado",
        textStrong: "",
        text: "Aguarde! Esse processo de unificação pode demorar alguns segundos, dependendo da quantidade de arquivos e registros que precisam ser migrados.",
        icon: <SyncIcon/>,
        color: "blue",
        isButtons: false,
      },
      error: {
        nome: 'error',
        open: true,
        title: "Erro o realizar unificação",
        text: "Ocorreu um erro ao realizar a migração, por favor entre em contato com o suporte técnico através do telefone (44) 3013 6077.",
        textStrong: "",
        icon: <img src={AlertIcon} alt="Alert"/>,
        color: "red",
        isButtons: false,
      },
      sucess: {
        nome: 'sucess',
        open: true,
        title: "Cadastros unificados com sucesso!",
        text: "",
        textStrong: "",
        icon: <DoneIcon/>,
        color: "green",
        isButtons: false,
      },
      preAviso: {
        nome: 'preAviso',
        open: true,
        title: "Unificação de dados",
        text: "Esta ação irá unificar todos os dados dos cadastros selecionados para um cadastro principal que você irá definir. Os demais cadastros selecionados serão",
        textStrong: "inativados para sempre e essa ação não poderá ser desfeita!",
        icon: <img src={AlertIcon} alt="Alert"/>,
        color: "yellow",
        isButtons: true,
      },
      motivo: {
        nome: 'motivo',
        open: true,
        title: "Qual o motivo da unificação?",
        icon: <LiveHelpIcon/>,
        color: "purple",
        text: "",
        textStrong: "",
        isButtons: true,
      }
    }
