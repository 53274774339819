import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const EmojiIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...normalizeProps(props)}>
      <path d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM7.15467 12.4273C6.96002 12.2314 6.64344 12.2304 6.44757 12.4251C6.25169 12.6197 6.25069 12.9363 6.44533 13.1322C8.33704 15.0359 11.3779 15.103 13.3505 13.3265L13.5547 13.1322L13.6123 13.0627C13.7467 12.8674 13.7265 12.5981 13.5524 12.4251C13.3783 12.252 13.1088 12.2336 12.9144 12.3692L12.8453 12.4273L12.6671 12.5961C11.0877 14.0045 8.66416 13.9463 7.15467 12.4273ZM12.5 7.5C11.9477 7.5 11.5 7.94772 11.5 8.5C11.5 9.05228 11.9477 9.5 12.5 9.5C13.0523 9.5 13.5 9.05228 13.5 8.5C13.5 7.94772 13.0523 7.5 12.5 7.5ZM7.5 7.5C6.94772 7.5 6.5 7.94772 6.5 8.5C6.5 9.05228 6.94772 9.5 7.5 9.5C8.05228 9.5 8.5 9.05228 8.5 8.5C8.5 7.94772 8.05228 7.5 7.5 7.5Z" fill="currentColor"/>
    </svg>
  );
};

export default EmojiIcon;
