import React from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import { Close as CloseIcon, Save as SaveIcon } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import styles from "../../../assets/jss/pages/profissionalSaudeStyle";
import Notification from "../../../components/Notification";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { 
	dadosInsuficientesTitle,
	dadosInsuficientesMessage,
	agendaNaoConfiguradaTitle,
	agendaNaoConfiguradaMessage,
	dadosBancariosNaoConfiguradosTitle,
	dadosBancariosNaoConfiguradosMessage,
	tabs
} from "../constants/perfilViziConstants";
import PropagandaVizi from "../PropagandaVizi/PropagandaVizi";
import DadosInsuficientesWarning from "../components/DadosInsuficientesWarning";
import { buildUrlPerfilVizi, getViziWebUrl } from "../../../config/config";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import DadosGerais from "./Tabs/DadosGerais";
import Customizacao from "./Tabs/Customizacao";
import Atividade from "./Tabs/Atividade";
import { getWhitelabelNome } from "../../../services/WhitelabelService";

const confirmModalDefault = {
	open: false,
	title: '',
	text: '',
}

@inject("profissionalSaudeStore", "usuarioStore", "perfilPublicoStore", "profissionalSaudeBraspagStore")
@observer
class PerfilDadosPublicos extends React.Component {
	state = {
		confirmModal: confirmModalDefault,
		showMessageDadosBancarios: false,
		tabSelected: tabs.DADOS_GERAIS,
	}
	constructor(props) {
    super(props);

    this.handleSwitchOnChange = this.handleSwitchOnChange.bind(this);
		this.switchIsChecked = this.switchIsChecked.bind(this);
	}

	async	componentDidMount() {
		const { profissionalSaudeStore, perfilPublicoStore } = this.props;
		await profissionalSaudeStore.getConvenioList();
		await profissionalSaudeStore.getLanguageList();
		await profissionalSaudeStore.getPaymentMethods();
		await profissionalSaudeStore.getProfessions();
		await profissionalSaudeStore.getSpecializations();
		await profissionalSaudeStore.getEstadoList();
		await profissionalSaudeStore.getPrefixoList();
		await profissionalSaudeStore.getConselhoList();
		await profissionalSaudeStore.carregarInformacoesUsuarioLogado();
		await perfilPublicoStore.findAllConvenioSistemaList();
		await this.checkProfissionalHasAgenda();
		this.loadProfissionalSaudeLogadoDadosBraspag()
	}

	onFieldChange(value, campo) {
		const { perfilPublicoStore, profissionalSaudeStore } = this.props;
		const camposPerfilPublico = [ElementsList.CONVENIOS, ElementsList.HORARIO_ATENDIMENTO, ElementsList.MEDIDAS_SEGURANCA];
		const isCampoPerfilPublico = camposPerfilPublico.includes(campo);

		if (campo === ElementsList.ESPECIALIDADES) {
			const { especialidades } = perfilPublicoStore.perfilPublicoProfissional;
			const lastValueInserted = value.pop();
			const alreadySelected = !especialidades?.some(({ especialidadeMedica }) => especialidadeMedica.especialidade === lastValueInserted?.especialidade);
			if (alreadySelected) {
				perfilPublicoStore.perfilPublicoProfissional = {
					...perfilPublicoStore.perfilPublicoProfissional,
					[campo]: [...perfilPublicoStore.perfilPublicoProfissional[campo], {
						ativo: true,
						valorOnline: 0,
						valorPresencial: 0,
						especialidadeMedica: lastValueInserted
					}]
				};
				profissionalSaudeStore.profissionalSaude[campo] = [...this.extractEspecialidadesMedicas([...value]), lastValueInserted];
			}
			return;
		}

		if (isCampoPerfilPublico) {
			perfilPublicoStore.perfilPublicoProfissional = {
				...perfilPublicoStore.perfilPublicoProfissional,
				[campo]: value
			}
		} else {
			profissionalSaudeStore.profissionalSaudePublico.informacao = {
				...profissionalSaudeStore.profissionalSaudePublico.informacao,
				[campo]: value,
			};
		}

		if (campo === ElementsList.FORMAS_PAGAMENTO || campo === ElementsList.ESPECIALIDADES) {
			perfilPublicoStore.checkPerfilProfissionalIsComplete();
		}
	}

	extractEspecialidadesMedicas(especialidades) {
		const especialidadesMedicas = especialidades.map((especialidade) => {
			return especialidade.especialidadeMedica;
		});
		return especialidadesMedicas;
	};

	handleChangeEspecilidadeValor = (value, field, index) => {
		const { perfilPublicoStore } = this.props;
		const prevValue = perfilPublicoStore.perfilPublicoProfissional.especialidades[index][field];
		perfilPublicoStore.perfilPublicoProfissional.especialidades[index][field] = value;
		if((!prevValue && value) || (!value && prevValue)){
			this.props.perfilPublicoStore.checkPerfilProfissionalIsComplete();
		}
	};

	handleRemoveEspecialidade = (index) => {
		const { perfilPublicoStore, profissionalSaudeStore } = this.props;
		const updatedArray = perfilPublicoStore.perfilPublicoProfissional.especialidades.filter((item, idx) => idx !== index);
		perfilPublicoStore.perfilPublicoProfissional.especialidades = updatedArray;
		profissionalSaudeStore.profissionalSaude.especialidades = this.extractEspecialidadesMedicas(updatedArray);
	};

	onProfissionalDataChange(value, campo) {
		this.props.profissionalSaudeStore.profissionalSaude = {
			...this.props.profissionalSaudeStore.profissionalSaude,
			[campo]: value,
		};
		if (campo === "nome" || campo === "cns" || campo === "numeroConselho") {
			this.props.profissionalSaudeStore.errors[campo] = string.isEmpty(value);
			if (campo === "nome") {
				this.props.perfilPublicoStore.checkPerfilProfissionalIsComplete();
			}
		}

		if (campo === "cpf") {
			this.validaCpf(value);
		}
	}

	onDataNascimentoChange(value) {
		this.props.profissionalSaudeStore.profissionalSaude.dataNascimento = value;

		this.props.profissionalSaudeStore.errors.dataNascimento =
			value === undefined || value === "" || value === null;
	}

	onEmailChange(event) {
		let value = event.target.value;
		this.props.profissionalSaudeStore.profissionalSaude.usuario.email = value;

		this.props.profissionalSaudeStore.errors.email = string.isEmpty(value);
	}

	onPrefixoChange(value) {
		if(value === ""){
			this.props.profissionalSaudeStore.profissionalSaude.prefixo = null;
		}else{
			this.props.profissionalSaudeStore.profissionalSaude.prefixo = {
				prefixo: value
			};
		}
	}

	onFieldRedesSociaisChange(value, campo) {
    const isValueSocialMediaBaseUrl = socialMediaLinks[campo]?.length - 1 === value?.length;
    if (isValueSocialMediaBaseUrl) return null;
		const valueWithoutDomain = value.replaceAll(socialMediaLinks[campo], "");

		if(value.includes('http')) {
			this.props.perfilPublicoStore.perfilPublicoProfissional = {
				...this.props.perfilPublicoStore.perfilPublicoProfissional,
				[campo]: value,
			};
		}else{
			this.props.perfilPublicoStore.perfilPublicoProfissional = {
				...this.props.perfilPublicoStore.perfilPublicoProfissional,
				[campo]: socialMediaLinks[campo] + valueWithoutDomain,
			};
		}
	}

  handleAddProfissao = (profissaoSelecionada) => {
		if (!profissaoSelecionada) return;

		const { informacao } = this.props.profissionalSaudeStore.profissionalSaudePublico;

		const profissao = {
			descricao: profissaoSelecionada.label,
			id: profissaoSelecionada.value,
		};

		informacao.profissionalSaudeProfissao = profissao;
  };

	handleRemoveProfissao = () => {
		const { informacao } = this.props.profissionalSaudeStore.profissionalSaudePublico;
		informacao.profissionalSaudeProfissao = null;
	};

	update = async () => {
		const { profissionalSaudeStore, perfilPublicoStore } = this.props;
		try {
			if (profissionalSaudeStore.profissionalSaude.utilizaVIZI) {
				if (perfilPublicoStore.perfilPublicoProfissional.id) {
					await perfilPublicoStore.updatePerfilPublicoProfissional();
				} else {
					await perfilPublicoStore.createPerfilPublicoProfissional();
				}
			}
			profissionalSaudeStore.openNotification('Perfil público atualizado com sucesso.', 'success');
		} catch (error) {
				profissionalSaudeStore.openNotification(error, 'error');
		};
	};

	cancelar = () => {
		this.props.history.push({
			pathname: "/",
		});
	};

	handleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.props.profissionalSaudeStore.closeNotification();
	};

	resetNotification = () => this.props.profissionalSaudeStore.resetNotification();

	handleSwitchOnChange(option) {
		const { profissionalSaudeStore, perfilPublicoStore } = this.props;
		if (option.attribute !== 'permiteBuscaProfissional' && option.attribute !== 'permiteBuscaGoogle') {
			profissionalSaudeStore.profissionalSaude[option.attribute] = !profissionalSaudeStore.profissionalSaude[option.attribute];
		} else {
			if (option.attribute === 'permiteBuscaGoogle' && perfilPublicoStore.perfilPublicoProfissional[option.attribute]) {
				return this.handleOpenConfirmDisableGoogleSearch();
			}
			perfilPublicoStore.perfilPublicoProfissional[option.attribute] = !perfilPublicoStore.perfilPublicoProfissional[option.attribute];
		}

		if (option.attribute === 'atendePresencial' || option.attribute === 'utilizaTelemedicina') {
			const perfilIncomplete = !this.props.perfilPublicoStore.checkPerfilProfissionalIsComplete();
			this.setState({ perfilIncomplete });
		}

		if (option.attribute === 'utilizaTelemedicina') {
			profissionalSaudeStore.profissionalSaude?.utilizaTelemedicina && !this.isCartaoCreditoSelected() && this.insereCartaoCredito();
		}
	}

	handleConfirmDisableGoogleSearch = () => {
		this.props.perfilPublicoStore.perfilPublicoProfissional.permiteBuscaGoogle = !this.props.perfilPublicoStore.perfilPublicoProfissional.permiteBuscaGoogle;
		this.setState({
			confirmModal: confirmModalDefault
		});
	}

	handleCancelDisableGoogleSearch = () => {
		this.setState({
			confirmModal: confirmModalDefault
		});
	}

	switchIsChecked(option) {
		if (option.storedIn === 'profissionalSaudeStore') {
			return this.props.profissionalSaudeStore.profissionalSaude[option.attribute];
		} else {
			return this.props.perfilPublicoStore.perfilPublicoProfissional[option.attribute];
		}
	}

	checkProfissionalHasAgenda = async () => {
		if (this.props.profissionalSaudeStore.profissionalSaude.id) {
			const profissionalHasAgenda = await this.props.profissionalSaudeStore.checkProfissionalHasHorarioAtendimento();
			this.props.profissionalSaudeStore.profissionalHasAgenda = profissionalHasAgenda;
		}
	}

	loadProfissionalSaudeLogadoDadosBraspag = async () => {
    const response = await this.props.profissionalSaudeBraspagStore.findByProfissionalSaudeBraspag();
		if(!response) return;
		this.setState({ showMessageDadosBancarios: response?.status === null });
  };

  changeTabSelected = (tabSelected) => {
	this.setState({
		tabSelected: tabSelected
	});
  };

  handleConfirmModal = (confirmModal) => {
	this.setState({ confirmModal });
  };

  renderTab = () => {
	const { tabSelected } = this.state;
	const contentTab = {
		0: <DadosGerais
			handleConfirmModal={this.handleConfirmModal}
		/>,
		1: <Customizacao/>,
		2: <Atividade/>
	}
	return contentTab[tabSelected];
  };

  getErrorTab = tab => {
	const { perfilPublicoStore } = this.props;
	const { perfilPublicoProfissionalErrors } = perfilPublicoStore;

	const errors = {
		tabDadosGerais: ['nome', 'formasPagamento', 'atendimento'],
		tabCustomizacao: ['fotoPerfil'],
		tabAtividade: ['especialidades']
	}
	const errorList = errors[tab].filter(item => perfilPublicoProfissionalErrors[item].error);

	return errorList.length > 0 ? errorList.length : "";
  };

  getQuantidadesError = () => {
	const { perfilPublicoStore } = this.props;
	const { perfilProfissionalIsIncomplete, perfilPublicoProfissional } = perfilPublicoStore;

	const quantidades =  perfilPublicoProfissional?.id && perfilProfissionalIsIncomplete ? [
		this.getErrorTab('tabDadosGerais'),
		this.getErrorTab('tabCustomizacao'),
		this.getErrorTab('tabAtividade')
	] : ['', '', '']

	return quantidades;
  };

	render() {
		const { classes, profissionalSaudeStore, perfilPublicoStore } = this.props;
		const {
			profissionalSaude,
		} = this.props.profissionalSaudeStore;
		const { tabSelected } = this.state;
		return (
			<>
				<PageTitle title={`Perfil ${getWhitelabelNome()}`}/>
				{profissionalSaude?.utilizaVIZI && profissionalSaude.perfilPublico ? (
					<div className={classes.tableContainer}>
						<div className={classes.header}>
							<h3 className={classNames(classes.titleHeader, classes.headerTitleRoot)}>Perfil {getWhitelabelNome()}</h3>
							<div className={classes.viziLinkContainer}>
								<span className={classes.viziLinkLabel}>{getWhitelabelNome()}</span>
								<a className={classes.viziLink} target="_blank" href={`${getViziWebUrl()}${buildUrlPerfilVizi(perfilPublicoStore.perfilPublicoProfissional)}`} rel="noreferrer">ACESSAR PORTAL</a>
							</div>
						</div>
						<div>
							{!profissionalSaudeStore.isLoadingInfo &&
								profissionalSaude.perfilPublico && 
								perfilPublicoStore.perfilProfissionalIsIncomplete && 
								(
									<DadosInsuficientesWarning 
										title={dadosInsuficientesTitle}
										message={dadosInsuficientesMessage}
										buttonText="Preencher Perfil Público"
										withoutButton
										isPerfilWarning
										requiredFields={perfilPublicoStore.perfilPublicoProfissionalErrors}
									/>
								)
							}
							{!profissionalSaudeStore.isLoadingInfo && profissionalSaude.utilizaVIZI && profissionalSaude.perfilPublico && !profissionalSaudeStore.profissionalHasAgenda && (
								<DadosInsuficientesWarning
									title={agendaNaoConfiguradaTitle}
									message={agendaNaoConfiguradaMessage}
									buttonText="Configurar agenda"
									onClickButton={() => this.props.history.push('/configuracoes/horario-atendimento')}
								/>
							)}
							{this.state.showMessageDadosBancarios && (
								<DadosInsuficientesWarning
									title={dadosBancariosNaoConfiguradosTitle}
									message={dadosBancariosNaoConfiguradosMessage}
									buttonText="Preencher dados bancários"
									onClickButton={() => this.props.history.push('/perfil/recebimentos-profissional')}
								/>
							)}
						</div>
					<div >
						<div className={classes.contentWithMultiToggleButtons}>
							<MultiToggleButtons
								options={["Dados gerais", "Customização", "Atividade"]}
								tabSelected={tabSelected}
								changeTabSelected={this.changeTabSelected}
								posicaoDesabilitada={[1]}
								classes={{ buttonsContainer: classes.toogleButtonsContainer }}
								quantidades={!profissionalSaudeStore.isLoadingInfo && this.getQuantidadesError()}
								colorQuantidade="#FB7676"
							/>
							<div className={classNames(classes.gridCardContainerPublic, tabSelected === 2 && classes.gridCardContainerPublicAtitvidade)}>
								{ this.renderTab() }	
							</div>
						</div>
					</div>

					<div className={classes.acoes}>
						<Fab
							className={classes.buttonSalvar}
							onClick={this.update}
							disabled={profissionalSaudeStore.loadingSaveDadosPerfil || profissionalSaudeStore.isLoadingInfo || perfilPublicoStore.canDisableSaveButtonThroughEspecialidades}
						>
							<SaveIcon />
						</Fab>
						<Fab className={classes.buttonCancelar} onClick={this.cancelar}>
							<CloseIcon />
						</Fab>
					</div>
					<Notification
						close={this.handleClose}
						reset={this.resetNotification}
						isOpen={profissionalSaudeStore.notification.isOpen}
						variant={profissionalSaudeStore.notification.variant}
						message={profissionalSaudeStore.notification.message}
					/>
					<ConfirmModal 
						open={this.state.confirmModal.open} 
						title={this.state.confirmModal.title}
						text={this.state.confirmModal.text}
						onCancel={this.handleCancelDisableGoogleSearch}
						onConfirm={this.handleConfirmDisableGoogleSearch}
					/>
					</div>
				) :
					<PropagandaVizi />
				}
			</>
		);
	};
};

export default withStyles(styles)(PerfilDadosPublicos);
