import { Paper, Popper, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";
import string from "../utils/string";
import CalendarIcon from "../components/Icon/Calendar";
import ChevronIcon from "./Icon/Chevron";
import classNames from "classnames";

const meses = [
  { nome: "Janeiro", value: "01" },
  { nome: "Fevereiro", value: "02" },
  { nome: "Março", value: "03" },
  { nome: "Abril", value: "04" },
  { nome: "Maio", value: "05" },
  { nome: "Junho", value: "06" },
  { nome: "Julho", value: "07" },
  { nome: "Agosto", value: "08" },
  { nome: "Setembro", value: "09" },
  { nome: "Outubro", value: "10" },
  { nome: "Novembro", value: "11" },
  { nome: "Dezembro", value: "12" },
];

const CalendarMonth = ({ classes, dataSelected, handleFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const anoSelected = moment(dataSelected).format("YYYY");
  const mesSelected = {
    nome: string.capitalize(moment(dataSelected).format("MMMM")),
    value: string.transformDoisDigitosString(moment(dataSelected).month() + 1),
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const openPopper = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const renderDataSelected = () => {
    return `${mesSelected.nome} ${anoSelected}`;
  };

  const handleAnoAnterior = () => {
    loadDados(Number(anoSelected) - 1, "ano")
  };

  const handleAnoPosterior = () => {
    loadDados(Number(anoSelected) + 1, "ano")
  };

  const handleSelectMonth = (mes) => {
    loadDados(mes, "mes")
  };

  const loadDados = (data, field) => {
    const ano = field === "ano" ? data : anoSelected;
    const mes = field === "mes" ? data : mesSelected;

    const date = `${ano}-${mes.value}-01`;
    const dataInicial = moment(date)
      .startOf("month")
      .format("YYYY-MM-DD");
    const dataFinal = moment(date)
      .endOf("month")
      .format("YYYY-MM-DD");

    handleFilter(dataInicial, dataFinal);
  };

  return (
    <>
      <div className={classes.title}> Filtrar por data </div>
      <div onClick={openPopper} className={classes.contentDataSelected}>
        {renderDataSelected()}
        <CalendarIcon className={classes.calendarIcon} />
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={"bottom-end"}>
        <Paper className={classes.paper}>
          <div className={classes.contentAno}>
            <ChevronIcon
              className={classNames(classes.rotateIcon, classes.iconAno)}
              onClick={handleAnoAnterior}
            />
            {anoSelected}
            <ChevronIcon
              className={classes.iconAno}
              onClick={handleAnoPosterior}
            />
          </div>
          <div className={classes.contentMeses}>
            {meses.map((item) => {
              return (
                <div
                  key={item.value}
                  className={classNames(
                    classes.contentMes,
                    item.value === mesSelected.value && classes.mesSelected
                  )}
                  onClick={() => handleSelectMonth(item)}
                >
                  {item.nome.slice(0, 3)}
                </div>
              );
            })}
          </div>
        </Paper>
      </Popper>
    </>
  );
};

const styles = {
  title: {
    fontSize: "12px",
    color: "#868686",
  },
  paper: {
    borderRadius: "8px",
    padding: "8px",
  },
  contentDataSelected: {
    padding: "4px 8px",
    display: "flex",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  iconAno: {
    cursor: "pointer",
  },
  calendarIcon: {
    color: "#868686",
  },
  contentAno: {
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    gap: "16px",
  },
  contentMeses: {
    width: "292px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  contentMes: {
    minWidth: "90px",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#505050",
    border: "1px solid #fff",
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  mesSelected: {
    background: "#26ACAA",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    color: "#fff",
  },
};

export default withStyles(styles)(CalendarMonth);
