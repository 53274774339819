import { action, observable } from 'mobx';
import BaseStore from './Base.store';

import { guiaInternacaoFormValidation } from '../pages/Configuracoes/ModelosDocumento/GuiaInternacao/utils/guiaInternacaoFormValidation';
import { guiaInternacaoValidations } from '../pages/Configuracoes/ModelosDocumento/GuiaInternacao/constants/constants';

import { 
  findAllCid10Subcategoria,
  findAllGuiaInternacaoModelo, 
  findAllTuss23TerminologiaCaraterAtendimento,
  findAllTuss36TerminologiaIndicadorAcidente, 
  findAllTuss41TerminologiaRegimeInternacao, 
  findAllTuss57TerminologiaTipoInternacao, 
  findAllTussView, 
  findByIdGuiaInternacaoModelo,
  saveGuiaInternacaoModelo,
  findTabelasDisponiveisTussView,
  changeGuiaInternacaoModeloStatus,
  imprimirGuiaInternacao,
  criarEImprimirGuiaInternacao,
  findByIdGuiaInternacao,
} from '../services/GuiaInternacaoService';
import { findProfissionalSaudeByUnidadeComAgendaPage } from '../services/OrcamentoService';
import { getGuiaInternacaoObjectToSave } from '../pages/Configuracoes/ModelosDocumento/GuiaInternacao/utils/getGuiaInternacaoObjectToSave';

const guiaInternacaoDefault = {
  id: null,
  nome: '',
  ativo: true,
  indicacaoClinica: null,
  caraterAtendimento: null,
  tipoInternacao: null,
  regimeInternacao: null,
  indicacaoAcidente: null,
  profissionalSaude: null,
  opme: false,
  quimioterapico: false,
  observacao: '',
  guiaInternacaoTussViews: [],
  guiaInternacaoModeloTussViews: [],
}

const modelosPageSettingsDefault = {
  pageNumber: 0,
  pageSize: 20,
  sortDir: 'ASC',
  sortField: 'nome',
  search: '',
  last: false,
  status: {value: true, label: 'Ativo'},
};

const itemTabelaTussDefault = {
  tabela: {
    value: 22,
    label: '22',
  },
  codigo: null,
  descricao: '',
  quantidade: 1,
};

export default class GuiaInternacaoStore extends BaseStore {
  @observable selectedEntity = 'guiaInternacao';
  @observable guiaInternacao = guiaInternacaoDefault;
  @observable formValidFields = {
    indicacaoClinica: false,
    caraterAtendimento: false,
    tipoInternacao: false,
    regimeInternacao: false,
    indicacaoAcidente: false,
    guiaInternacaoTussViews: false,
    profissionalSaude: false,
  };
  @observable modelosPageSettings = modelosPageSettingsDefault;
  @observable guiaInternacaoModelosList = [];
  @observable guiaInternacaoSelectedId = null;
  @observable itemTabelaTuss = itemTabelaTussDefault;
  @observable tabelaTussSelected = null;
  @observable unidadeId = null;
  @observable openGuiaInternacaoModal = false;
  @observable guiaInternacaoToPrint  = null;
  @observable isPrintingComplete = false;

  onChangeGuiaInternacao = (value, field) => {
    this.guiaInternacao[field] = value;
  }

  @action onChangeItemTabelaTuss = (value, field) => {
    if (field === 'tabela') {
      this.itemTabelaTuss = {
        [field]: value ? {value: value?.value || null, label: value?.label || ''} : null,
        codigo: null,
        descricao: '',
        quantidade: 1,
      }

      this.tabelaTussSelected = value;
    } else {
      this.itemTabelaTuss = {
        ...this.itemTabelaTuss,
        [field]: value,
      }

      if (field === 'codigo') {
        this.itemTabelaTuss = {
          ...this.itemTabelaTuss,
          descricao: value?.descricao || '',
          tabela: {
            value: value?.tabela,
            label: value?.tabela,
          }
        }
      }
    }
  }

  @action addTabelaTussCurrItem = () => {
    const { codigo, quantidade, descricao } = this.itemTabelaTuss;
    const itemTabelaTuss = {
      tussView: {
        ...codigo,
      },
      quantidadeSolicitada: parseInt(quantidade),
      descricao,
    }
    const guiaTussField = this.selectedEntity === 'guiaInternacao' ? 'guiaInternacaoTussViews' : 'guiaInternacaoModeloTussViews';
    this.guiaInternacao[guiaTussField].push(itemTabelaTuss);
    this.itemTabelaTuss = itemTabelaTussDefault;
  }

  @action editGuiaInternacaoModeloTussView = (tussItem, operation) => {
    if (operation === 'remove') {
      this.guiaInternacao.guiaInternacaoTussViews = this.guiaInternacao.guiaInternacaoTussViews.filter(item => item.id !== tussItem.id);
    } else {
      const itemIndex = this.guiaInternacao.guiaInternacaoTussViews.findIndex(item => item.id === tussItem.id);
      this.guiaInternacao.guiaInternacaoTussViews[itemIndex] = tussItem;
    }
  }

  @action handleSaveGuiaInternacaoModelo = async () => {
    const isValid = guiaInternacaoFormValidation(this.guiaInternacao, guiaInternacaoValidations(this.selectedEntity === "guiaInternacaoModelo"), this.formValidFields);

    if (!isValid) {
      throw new Error('Por favor, verifique os campos obrigatórios');
    }
    const guiaInternacaoModelo = getGuiaInternacaoObjectToSave(this.guiaInternacao, null, 'configuracao');

    if (guiaInternacaoModelo?.guiaInternacaoModeloTussViews?.length === 0) {
      throw new Error('É necessário informar no mínimo um procedimento.');
    }

    try {
      const method = this.guiaInternacao.id ? 'updateGuiaInternacaoModelo' : 'createGuiaInternacaoModelo';

      const response = await saveGuiaInternacaoModelo(guiaInternacaoModelo, method);
      return response?.id;
    } catch(error) {
      console.error(error);
      this.openNotification('Erro ao salvar o modelo', 'error');
    }
  }

  @action handleChangeGuiaInternacaoModeloStatus = async (id, status) => {
    try {
      const response = await changeGuiaInternacaoModeloStatus(id, status);
      return response;
    } catch(error) {
      console.error(error);
      this.openNotification('Erro ao alterar o status do modelo', 'error');
    }
  }

  updateModifiedModelo = (id, newStatus) => {
    const index = this.guiaInternacaoModelosList.findIndex(modelo => modelo.id === id);
    const updatedGuiaInternacaoModelo = { ...this.guiaInternacaoModelosList[index], ativo: newStatus };
    this.guiaInternacaoModelosList[index] = updatedGuiaInternacaoModelo;
  }

  resetFormValidFields = () => {
    this.formValidFields = {
      indicacaoClinica: false,
      caraterAtendimento: false,
      tipoInternacao: false,
      regimeInternacao: false,
      indicacaoAcidente: false,
      guiaInternacaoTussViews: false,
    };
    this.guiaInternacao = guiaInternacaoDefault;
  }

  @action loadGuiaInternacaoModelos = async () => {
    const { search, pageNumber, pageSize, sortDir, sortField, status } = this.modelosPageSettings;
    try {
      const searchDTO = {
        search: search,
        ativo: status?.value,
      }

      const pageableDTO = {
        pageSize,
        pageNumber,
        sortDir,
        sortField
      }

      const { content, last } = await findAllGuiaInternacaoModelo(searchDTO, pageableDTO);
      if (!last) {
        this.modelosPageSettings.pageNumber++;
      }
      this.modelosPageSettings.last = last;
      this.guiaInternacaoModelosList = pageNumber === 0 ? [...content] : [...this.guiaInternacaoModelosList, ...content];
    } catch(error) {
      this.openNotification('Erro ao carregar modelos de guia internação', 'error');
    }
  };
  
  resetModelosPageSettings = () => {
    this.modelosPageSettings = modelosPageSettingsDefault;
    this.guiaInternacaoModelosList = [];
  };

  resetFormData = () => {
    this.guiaInternacao = guiaInternacaoDefault;
  }

  @action loadGuiaInternacaoModeloById = async (isDuplicate) => {
    try {
      const tussViewField = this.selectedEntity === 'guiaInternacao' ? 'guiaInternacaoTussViews' : 'guiaInternacaoModeloTussViews';
      const response  = this.selectedEntity === 'guiaInternacaoModelo' ?
        await findByIdGuiaInternacaoModelo(this.guiaInternacaoSelectedId) : 
        await findByIdGuiaInternacao(this.guiaInternacaoSelectedId);

      const { 
        id, 
        cid10Subcategoria,
        tuss23TerminologiaCaraterAtendimento,
        tuss57TerminologiaTipoInternacao,
        tuss41TerminologiaRegimeInternacao,
        tuss36TerminologiaIndicadorAcidente,
        hasOPME, 
        quimioterapico, 
        observacao,
        profissionalSaude,
      } = response;

      const guiaInternacao = {
        ...(!isDuplicate && { id }),
        ...(this.selectedEntity === 'guiaInternacaoModelo' && { nome: response.nome }),
        ...(profissionalSaude && { profissionalSaude }),
        indicacaoClinica: cid10Subcategoria,
        caraterAtendimento: tuss23TerminologiaCaraterAtendimento,
        tipoInternacao: tuss57TerminologiaTipoInternacao,
        regimeInternacao: tuss41TerminologiaRegimeInternacao,
        indicacaoAcidente: tuss36TerminologiaIndicadorAcidente,
        opme: hasOPME,
        quimioterapico,
        observacao,
        [tussViewField]: !isDuplicate ? response[tussViewField] : this.removeItemsTussId(response[tussViewField]),
      }
      this.guiaInternacao = guiaInternacao;
      
      if (isDuplicate) {
        this.guiaInternacaoSelectedId = null;
      }

    } catch(error) {
      this.openNotification('Erro ao carregar modelo de guia internação', 'error');
    }
  }

  @action loadGuiaInternacaoModeloAsGuiaInternacao = async(modeloId) => {
    this.clearData();
    try {
      const response  = await findByIdGuiaInternacaoModelo(modeloId);
      const {
        cid10Subcategoria,
        tuss23TerminologiaCaraterAtendimento,
        tuss57TerminologiaTipoInternacao,
        tuss41TerminologiaRegimeInternacao,
        tuss36TerminologiaIndicadorAcidente,
        hasOPME, 
        quimioterapico, 
        observacao,
        profissionalSaude,
        guiaInternacaoModeloTussViews
      } = response;

      this.guiaInternacao = {
        ...(profissionalSaude && { profissionalSaude }),
        indicacaoClinica: cid10Subcategoria,
        caraterAtendimento: tuss23TerminologiaCaraterAtendimento,
        tipoInternacao: tuss57TerminologiaTipoInternacao,
        regimeInternacao: tuss41TerminologiaRegimeInternacao,
        indicacaoAcidente: tuss36TerminologiaIndicadorAcidente,
        opme: hasOPME,
        quimioterapico,
        observacao,
        guiaInternacaoTussViews: this.removeItemsTussId(guiaInternacaoModeloTussViews),
      };
    } catch(error) {
      console.error(error);
    }
  }

  removeItemsTussId = (items) => {
    return items.map((item) => {
      delete item.id;
      return item;
    });
  };

  @action loadProfissionaisSaude = async (search, _, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    };

    if (!this.unidadeId) return;

    try {
      const response = await findProfissionalSaudeByUnidadeComAgendaPage({ unidadeId: this.unidadeId, search, pageableDTO});
      const { content, last } = response;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        }
      }
    } catch(error) {
      console.error(error);
    }
  }

  @action loadIndicacaoClinicaOptions = async (search, _, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'descricao',
    };

    try {
      const response = await findAllCid10Subcategoria({ search, pageableDTO });
      const { content, last } = response;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        }
      }
    } catch(error) {
      console.error(error);
    }
  }

  @action loadCaraterAtendimentoOptions = async (search, _, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'termo',
    };

    try {
      const response = await findAllTuss23TerminologiaCaraterAtendimento({
        search,
        pageableDTO
      });

      const { content, last } = response;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        }
      }
    } catch(error) {
      console.error(error);
    }
  }

  @action loadTipoInternacaoOptions = async (search, _, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'termo',
    };

    try {
      const response = await findAllTuss57TerminologiaTipoInternacao({
        search,
        pageableDTO
      });
      
      const { content, last } = response;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        }
      }
    } catch(error) {
      console.error(error);
    }
  }
  @action loadRegimeInternacaoOptions = async (search, _, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'termo',
    };

    try {
      const response = await findAllTuss41TerminologiaRegimeInternacao({
        search,
        pageableDTO
      });

      const { content, last } = response;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        }
      };
    } catch(error) {
      console.error(error);
    }
  }
  @action loadIndicacaoAcidenteOptions = async (search, _, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'termo',
    };

    try {
      const response = await findAllTuss36TerminologiaIndicadorAcidente({
        search,
        pageableDTO
      });

      const { content, last } = response;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        }
      };
    } catch(error) {
      console.error(error);
    }
  }

  @action loadTussTablesOptions = async () => {
    try {
      const response = await findTabelasDisponiveisTussView();
      const responseToSelectOptions = response.map(tabela => ({ value: tabela, label: tabela }));
      return {
        options: [{ value: 'Todos', label: 'Todos' }, ...responseToSelectOptions],
        hasMore: false,
      }
    } catch(error) {
      console.error(error);
    }
  };

  @action loadTussViewOptions = async (search, _, { page: currentPage }) => {
    const itemTabela = this?.itemTabelaTuss?.tabela;
    const page = this.tabelaTussSelected !== itemTabela ? 0 : currentPage

    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'descricao',
    };

    try {
      const response = await findAllTussView({
        search, 
        pageableDTO, 
        tabela: itemTabela?.value === 'Todos' ? null : itemTabela?.value
      });

      const { content, last } = response;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        }
      };
    } catch(error) {
      console.error(error);
    }
  };

  @action imprimirComoDucumentoGuiaInternacao = async (sujeitoAtencaoId) => {
    const isValid = guiaInternacaoFormValidation(this.guiaInternacao, guiaInternacaoValidations(this.selectedEntity === "guiaInternacaoModelo"), this.formValidFields);
    if (!isValid) {
      throw new Error('Por favor, verifique os campos obrigatórios');
    }
    const guiaInternacao = getGuiaInternacaoObjectToSave(this.guiaInternacao, sujeitoAtencaoId, "prontuario");
    
    if (guiaInternacao.guiaInternacaoTussViews?.length === 0) {
      throw new Error('É necessário informar no mínimo um procedimento.');
    }

    try {
      const response = guiaInternacao?.id ? await imprimirGuiaInternacao(guiaInternacao.id) : await criarEImprimirGuiaInternacao(guiaInternacao);
      this.guiaInternacaoToPrint = response;
      return response?.pdf;
    } catch(error) {
      console.error(error);
      throw new Error('Erro ao imprimir guia internação');
    }
  }

  @action clearData = () => {
    this.resetFormValidFields();
    this.resetFormData();
    this.guiaInternacaoSelectedId = null;
    this.itemTabelaTuss = itemTabelaTussDefault;
    this.guiaInternacaoToPrint = null;
  };

  @action clearModelosPageSettings = () => {
    this.modelosPageSettings = modelosPageSettingsDefault;
  }
}