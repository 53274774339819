import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const CircleNextIcon = props => {
  return (
    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...normalizeProps(props)}>
      <g filter="url(#filter0_dd_9110_189737)">
      <rect x="58.4219" y="8" width="42.4219" height="42.4219" rx="21.211" transform="rotate(90 58.4219 8)" fill="currentColor"/>
      <path d="M34.0046 21.3469C34.4008 20.9695 35.028 20.9847 35.4054 21.3809L42.0127 28.3173C42.3772 28.7 42.3772 29.3014 42.0127 29.6841L35.4054 36.6205C35.028 37.0167 34.4008 37.0319 34.0046 36.6545C33.6084 36.2771 33.5931 35.6499 33.9705 35.2537L39.9268 29.0007L33.9705 22.7477C33.5931 22.3515 33.6084 21.7243 34.0046 21.3469Z" fill="#7C7C7C"/>
      <rect x="57.9219" y="8.5" width="41.4219" height="41.4219" rx="20.711" transform="rotate(90 57.9219 8.5)" stroke="black" stroke-opacity="0.1"/>
      </g>
      <defs>
      <filter id="filter0_dd_9110_189737" x="-0.00012207" y="0" width="74.422" height="74.4219" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="8"/>
      <feGaussianBlur stdDeviation="8"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9110_189737"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_9110_189737" result="effect2_dropShadow_9110_189737"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9110_189737" result="shape"/>
      </filter>
      </defs>
    </svg>
  );
};

export default CircleNextIcon;