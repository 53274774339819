export const weekDays = [
  {
    nome: 'Domingo',
    value: 'DOMINGO'
  },
  {
    nome: 'Segunda',
    value: 'SEGUNDA_FEIRA'
  },
  {
    nome: 'Terça',
    value: 'TERCA_FEIRA'
  },
  {
    nome: 'Quarta',
    value: 'QUARTA_FEIRA'
  },
  {
    nome: 'Quinta',
    value: 'QUINTA_FEIRA'
  },
  {
    nome: 'Sexta',
    value: 'SEXTA_FEIRA'
  },
  {
    nome: 'Sábado',
    value: 'SABADO'
  },
];
