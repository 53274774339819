import React from "react";
import classnames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Grid, withStyles } from "@material-ui/core";
import styles from "./styles";
import string from "../../../utils/string";
import { InputForm } from "../../../components/Modal/Input";
import { TextFieldSearch } from "../../../components/TextField";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import { tiposDesconto } from "../../../stores/Financeiro/Extrato.store";
import InputMaskCurrency from "../../../components/Input/InputMaskCurrency";
import { InputMaskPercentil } from "../../../components/Input/InputMaskPercentil";

const InformacoesLancamento = observer((props) => {
  const {
    classes,
    isDisabled,
    extratoStore,
  } = props;
  const { titulo, handleChangeModal, errors } = extratoStore;

  const handleSujeitoAtencaoLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return extratoStore.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllSujeitoAtencao",
    });
  };

  const handleProfissionalSaudeLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return extratoStore.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllProfissionalSaude",
    });
  };

  return (
    <>
      <Grid>
        <Grid container spacing={16}>
        <Grid item xs={12}>
            <Grid className={classes.titleField}>Convênio</Grid>
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleConvenioLoadOptions}
              withPaginate
              value={titulo.convenio}
              onChange={(e) => handleChangeModal("convenio", e)}
              getOptionLabel={(option) => option.descricao}
              getOptionValue={(option) => option.id}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              isDisabled={isDisabled || titulo.id}
              classNotched={classes.notchedOutlineSearch}
              classInput={classes.inputSearch}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} >
          <Grid item xs={6}>
            <div className={classes.titleField}>Paciente</div>
            <TextFieldSearch
              placeholder=""
              loadOptions={handleSujeitoAtencaoLoadOptions}
              withPaginate
              value={titulo.sujeitoAtencao}
              onChange={(e) => handleChangeModal("sujeitoAtencao", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              isDisabled={isDisabled || titulo.id}
              classNotched={classes.notchedOutlineSearch}
              classInput={classes.inputSearch}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid className={classes.titleField}>Profissional da saúde</Grid>
            <TextFieldSearch
              placeholder=""
              loadOptions={handleProfissionalSaudeLoadOptions}
              withPaginate
              value={titulo.profissionalSaude}
              onChange={(e) => handleChangeModal("profissionalSaude", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              isDisabled={isDisabled}
              classNotched={classes.notchedOutlineSearch}
              classInput={classes.inputSearch}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} >
          <Grid item xs={4}>
            <Grid className={classes.titleField}>Valor (Obrigatório)</Grid>
            <InputMaskCurrency
              value={titulo.valor || ""}
              onChange={(e) => handleChangeModal("valor", e)}
              className={classnames(
                classes.inputValor,
              )}
              error={errors.valor}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={3}>
            <Grid className={classes.titleField}>Desconto</Grid>
            {
              isDisabled ? 
              <div className={classes.fieldDisabled}> { titulo.tipoDesconto?.value ? string.capitalize(titulo.tipoDesconto?.value) : "" } </div>
              :
              <ReactSelect
                value={titulo.tipoDesconto}
                className={classes.selectParcelas}
                onChange={(e) => handleChangeModal("tipoDesconto", e)}
                options={tiposDesconto}
                menuPlacement={"auto"}
                getOptionLabel={(option) => string.capitalize(option.value)}
                getOptionValue={(option) => option.id}
                isClearable
              />
            }
          </Grid>
          <Grid item xs={2} className={classes.fieldDesconto}>
            { titulo.tipoDesconto && titulo.tipoDesconto?.value === "VALOR" ?
              <InputMaskCurrency
                value={titulo.desconto || ""}
                onChange={(e) => handleChangeModal("desconto", e)}
                className={classnames(
                  classes.inputValor,
                  errors.valor && classes.inputValorError
                )}
                disabled={isDisabled || !titulo.tipoDesconto}
              />
              :
              <InputMaskPercentil
                className={classes.inputPercentil}
                classInputRoot={classes.inputRootPercentil}
                value={titulo.desconto}
                onChange={(e) => handleChangeModal("desconto", e)}
                disabled={isDisabled || !titulo.tipoDesconto }
              />
            }
          </Grid>
          <Grid item xs={3}>
            <Grid className={classes.titleField}>Subtotal</Grid>
            <Grid className={classes.subtotal}>
              <InputMaskCurrency
                value={titulo.subtotal || ""}
                className={classnames(
                  classes.inputValor,
                )}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={8}>
            <Grid className={classes.titleField}>Centro de custo</Grid>
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleCentroCustoLoadOptions}
              withPaginate
              value={titulo.centroCusto}
              onChange={(e) => handleChangeModal("centroCusto", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              isDisabled={isDisabled}
              classNotched={classes.notchedOutlineSearch}
              classInput={classes.inputSearch}
              maxMenuHeight={200}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid className={classes.titleField}>Categoria</Grid>
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleCategoriaLoadOptions}
              withPaginate
              value={titulo.categoriaFinanceira}
              onChange={(e) => handleChangeModal("categoriaFinanceira", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              isDisabled={isDisabled}
              classNotched={classes.notchedOutlineSearch}
              classInput={classes.inputSearch}
              maxMenuHeight={200}
            />
          </Grid>
        </Grid>
        <Grid className={classnames(classes.contentFieldDescricao, classes.marginTop)}>
          <Grid className={classes.titleField}>Descrição (Obrigatório)</Grid>
          <InputForm
            className={classes.descricao}
            classes={{
              input: classes.inputInfos,
            }}
            rows={7}
            multiline
            error={errors.nome}
            value={titulo.nome || ""}
            onChange={e => handleChangeModal("nome", e)}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
});


const InformacoesLancamentoStyles = withStyles(styles)(InformacoesLancamento);
export default inject("extratoStore")(InformacoesLancamentoStyles);
