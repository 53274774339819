import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const MensagemWhatsappIcon =(props)=>{
  return(
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...normalizeProps(props)}>
    <rect x="0.397705" width="50.2045" height="50.2045" rx="25.1023" fill="#4390E1"/>
    <g clip-path="url(#clip0_15659_102653)">
      <path d="M25.4286 10.7539C17.99 10.7539 11.9346 16.8041 11.9319 24.2427C11.9305 26.6204 12.5533 28.9417 13.7341 30.9871L11.9028 37.7737L18.9718 36.1034C20.9429 37.1788 23.1618 37.7433 25.4206 37.7447H25.4259C32.8631 37.7447 38.9159 31.6931 38.92 24.2559C38.9227 20.6501 37.5205 17.2599 34.9726 14.7092C32.4246 12.1599 29.0384 10.7553 25.4286 10.7539ZM25.4259 13.4559C28.3116 13.4572 31.0235 14.5823 33.0622 16.6196C35.1008 18.6596 36.2207 21.3702 36.218 24.2533C36.2153 30.203 31.3757 35.0427 25.4233 35.0427C23.6224 35.0413 21.8386 34.5892 20.2674 33.7313L19.357 33.2352L18.3517 33.4727L15.6919 34.1007L16.341 31.689L16.6339 30.6071L16.0745 29.6361C15.1316 28.0041 14.6325 26.1381 14.6338 24.2427C14.6365 18.2956 19.4775 13.4559 25.4259 13.4559ZM20.6526 18.0155C20.427 18.0155 20.0622 18.0999 19.7528 18.4377C19.4434 18.7741 18.5707 19.5888 18.5707 21.2478C18.5707 22.9068 19.7792 24.5105 19.9481 24.7361C20.1156 24.9604 22.2808 28.4725 25.7083 29.8234C28.5561 30.9461 29.1346 30.7246 29.7533 30.6678C30.3721 30.6124 31.7495 29.8538 32.0305 29.0662C32.3115 28.2785 32.3121 27.601 32.2284 27.4619C32.1446 27.3213 31.9196 27.2376 31.5819 27.0687C31.2455 26.8998 29.5877 26.0858 29.2784 25.9736C28.969 25.8615 28.7427 25.8048 28.5184 26.1425C28.2942 26.4803 27.6497 27.2376 27.4524 27.4619C27.2552 27.6875 27.0593 27.7178 26.7215 27.5489C26.3838 27.3787 25.2978 27.0217 24.009 25.8734C23.0065 24.9804 22.3301 23.8786 22.1329 23.5408C21.937 23.2044 22.1144 23.0196 22.2833 22.8521C22.4346 22.7008 22.6184 22.4583 22.7873 22.2611C22.9548 22.0638 23.0129 21.9233 23.125 21.699C23.2372 21.4748 23.1798 21.2768 23.096 21.108C23.0122 20.9391 22.3563 19.2739 22.0564 18.6065C21.8037 18.0459 21.5369 18.0328 21.2964 18.0234C21.1005 18.0153 20.8769 18.0155 20.6526 18.0155Z" fill="#F9F9F9"/>
    </g>
    <defs>
    <clipPath id="clip0_15659_102653">
    <rect width="32" height="29" fill="white" transform="translate(9.5 10.6016)"/>
    </clipPath>
    </defs>
</svg>
)}
export default MensagemWhatsappIcon;