import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const MoreVerticalIcon = (props) => {
  return (
    <svg
      width="3"
      height="12"
      viewBox="0 0 3 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M1.5 3.25C0.809644 3.25 0.25 2.69036 0.25 2C0.25 1.30964 0.809644 0.75 1.5 0.75C2.19036 0.75 2.75 1.30964 2.75 2C2.75 2.69036 2.19036 3.25 1.5 3.25ZM1.5 7.25C0.809643 7.25 0.25 6.69036 0.25 6C0.25 5.30964 0.809644 4.75 1.5 4.75C2.19036 4.75 2.75 5.30964 2.75 6C2.75 6.69036 2.19036 7.25 1.5 7.25ZM0.25 10C0.25 10.6904 0.809643 11.25 1.5 11.25C2.19036 11.25 2.75 10.6904 2.75 10C2.75 9.30964 2.19036 8.75 1.5 8.75C0.809643 8.75 0.25 9.30964 0.25 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoreVerticalIcon;
