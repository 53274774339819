import React from 'react';
import Prototype from 'prop-types';

import * as Styles from './styles';

const Modal = ({ children, isOpen, ...others }) => {
  if (!isOpen) return null;

  return (
    <Styles.Modal>
      <Styles.ModalContent {...others}>
        {children}
      </Styles.ModalContent>
    </Styles.Modal>
  )
};

Modal.prototype = {
  isOpen: Prototype.bool,
  children: Prototype.node.isRequired,
  maxWidth: Prototype.string,
  maxHeight: Prototype.string,
  borderRadius: Prototype.string,
  color: Prototype.string,
  padding: Prototype.string,
};

export default Modal;
