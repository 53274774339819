import moment from "moment";
import string from "../../../../../utils/string";

export const groupEntradaTypeImage = (entradaProntuarioList, interval) => {
  return entradaProntuarioList.reduce((listWithGroupedImageMessages, entradaProntuario) => {
    if (entradaProntuario.tipo !== "IMAGEM" || !entradaProntuario.ativo) {
      listWithGroupedImageMessages.push(entradaProntuario);
    } else {
      const lastEntradaInserted = listWithGroupedImageMessages[listWithGroupedImageMessages.length - 1];
      const canBeGrouped = lastEntradaInserted && 
        lastEntradaInserted.tipo === "IMAGEM" &&
        lastEntradaInserted.ativo &&
        entradasCreatedBySameProfissionalSaude(lastEntradaInserted, entradaProntuario) &&
        entradasIsWithinInterval(entradaProntuario, lastEntradaInserted, interval) &&
        entradasTextIsTheSameOrBothTextAreFilenames(lastEntradaInserted, entradaProntuario);
      const entradaProntuarioUrl = {
        id: entradaProntuario.id,
        urlDownload: entradaProntuario.urlDownload,
        urlMiniatura: entradaProntuario.urlMiniatura
      }

      if (canBeGrouped) {
        lastEntradaInserted.urls.push(entradaProntuarioUrl);
      } else {
        listWithGroupedImageMessages.push({
          ...entradaProntuario,
          urls: [entradaProntuarioUrl]
        });
      }
    } 
    return listWithGroupedImageMessages;
  }, []);
}

const entradasIsWithinInterval = (currentEntradaProntuario, lastEntradaInserted, interval) => {
  const { date, hour } = currentEntradaProntuario;
  const { date: lastDate, hour: lastHour } = lastEntradaInserted;
  const currentDateHour = moment(`${date} ${hour}`, "DD/MM/YYYY HH:mm");
  const lastDateHour = moment(`${lastDate} ${lastHour}`, "DD/MM/YYYY HH:mm");
  return lastDateHour.diff(currentDateHour, 'minutes') < interval;
};

const entradasTextIsTheSameOrBothTextAreFilenames = (lastEntradaInserted, entradaProntuario) => {
  const sameText = lastEntradaInserted.texto === entradaProntuario.texto;
  const bothTextAreFilenames = string.isFilenameWithExtension(lastEntradaInserted.texto) && string.isFilenameWithExtension(entradaProntuario.texto);
  return sameText || bothTextAreFilenames;
};

const entradasCreatedBySameProfissionalSaude = (lastEntradaInserted, entradaProntuario) => {
  return lastEntradaInserted?.profissionalSaude?.id === entradaProntuario?.profissionalSaude?.id;
}
