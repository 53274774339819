import React from "react";
import { inject, observer } from 'mobx-react';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "../../../components/Dialog/Dialog";
import {
  Grid,
  ListItem,
  ListItemIcon,
  DialogTitle,
  DialogContent,
  Fab,
  Checkbox,
  ListItemText
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import DragAndDropList from "./DragAndDropList";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ButtonLong from "../../../components/Button/ButtonLong";
import Button from "../../../components/Button/Button";

@inject("prontuarioStore")
@observer
class PersonalizarCamposProntuario extends React.Component {
  constructor(props) {
    super(props);
    this.refModelDocumentEditor = React.createRef();

    this.state = {
      loadingButtonSave: false,
      allCampoProntuarioList: [],
      selectedCampoProntuarioList: []
    };
  }

  async componentDidMount() {
    let allCampoProntuarioList = await this.props.prontuarioStore.findAllCampoProntuario();

    const camposSelecionadosProps = this.props.selectedCamposProntuario;
    const listaTodosCamposProntuarios = allCampoProntuarioList.map(campoProntuario => {
      campoProntuario.checked = camposSelecionadosProps.some(selecionado => campoProntuario.campo === selecionado.campoProntuario.campo);
      if (campoProntuario.campo === 'ALTURA' || campoProntuario.campo === 'PESO') {
        campoProntuario.disabled = true;
      }
      return campoProntuario
    })

    this.setState({ allCampoProntuarioList: listaTodosCamposProntuarios });

    const camposSelecionados = listaTodosCamposProntuarios.filter(campoProntuario => campoProntuario.checked);
    this.setState({ selectedCampoProntuarioList: camposSelecionados })
  }

  handleClickSave = async () => {
    this.setState({ loadingButtonSave: true });
    try{
    await this.props.prontuarioStore.updateFiltroCampoProntuarioProfissionalSaudeLogado(this.state.selectedCampoProntuarioList);
    this.props.updateSelectedCamposProntuario(this.state.selectedCampoProntuarioList);
    await this.props.prontuarioStore.findAllCampoProntuarioByProfissionalSaudeLogadoList()
    }catch(error){
      openNotification(
        'Erro ao salvar alterações',
        'Error'
      )
    }  
    finally{  
      this.setState({ loadingButtonSave: false });
      this.props.handleClose();
    }
  };

  handleChangeCampoProntuarioList = (selectedCampoProntuarioList) => {
    this.setState({
      selectedCampoProntuarioList
    })
  }

  handleChangeCheckbox = (campoProntuario) => {
    if (this.state.selectedCampoProntuarioList.length > 7 && !campoProntuario.checked) {
      return
    }

    campoProntuario.checked = !campoProntuario.checked;
    const camposSelecionados = this.state.allCampoProntuarioList.filter(campoProntuario => campoProntuario.checked);
    this.setState({ selectedCampoProntuarioList: camposSelecionados })
  }

  render() {
    const { classes, open, handleClose } = this.props;
    const { allCampoProntuarioList } = this.state;

    return (
      <>
      <PageTitle title="Editar barra de atalhos"/>
      <Dialog
        classes={{ scrollPaper: classes.scrollPaper, paper: classes.paper }}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogHeader }} disableTypography>
          <div className={classes.dialogTitle}>
            <div className={classes.dialogTitleName}>
              <h1>Editar barra de atalhos</h1>
            </div>
            <div>
              <Fab
                className={classes.buttonActionClose}
                onClick={handleClose}
              >
                <CloseIcon />
              </Fab>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContentBorder}>
          <div className={classes.selectedItens}>
            <h1>Itens Selecionados</h1>
            <DragAndDropList
              selectedCampoProntuarioList={this.state.selectedCampoProntuarioList}
              handleChangeCampoProntuarioList={this.handleChangeCampoProntuarioList}
              handleChangeCheckbox={this.handleChangeCheckbox}
            />
          </div>
          <div className={classes.dialogContent}>
            <h1 className={classes.tituloTodosProntuarios}>
              Selecione até 08 itens para mostrar na barra:
            </h1>
            <Grid className={classes.gridContainer} container direction="column" >
              {allCampoProntuarioList.map((item, index) => (
                <div className={classes.campoProntuario}>
                  <Grid item>
                    <ListItem key={item.campo} button onClick={() => this.handleChangeCheckbox(item)} disabled={item.disabled}>
                      <ListItemIcon>
                        <Checkbox
                          checked={item.checked}
                          color="primary"
                        />
                      </ListItemIcon>
                      <ListItemText id={item.campo} primary={item.nome} className={classes.listItemText} />

                    </ListItem>
                  </Grid>
                </div>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <div className={classes.wrapperFooter}>
            <ButtonLong onClick={handleClose} colorCustom="transparency"  className={classes.buttonCancel}>
              Cancelar
            </ButtonLong>
            <Button onClick={this.handleClickSave} colorCustom="green"className={classes.buttonSave} loading={this.state.loadingButtonSave} disabled={this.state.loadingButtonSave}>
              Salvar
            </Button>
          </div>
      </Dialog>
      </>
    );
  }
}

export default withStyles(theme => ({
  root: {
    position: "relative",
    overflow: "none",
    zIndex: 1
  },
  paper: {
    borderRadius: 35,
    overflow: "unset",
    height: 630,
  },
  dialogHeader: {
    background: 'white',
    borderRadius: '15px',
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: '1rem 1rem 0.5rem',
  },
  dialogTitleName: {
    display: 'flex',
    alignItems: 'center',
    '& > h1': {
      color: '#333333',
      fontSize: '18px',
      fontWeight: 'bold',
      marginLeft: '8px'
    },
  },
  dialogContentBorder: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F9F9F9',
    padding: '1rem',
    borderRadius: '0 0 15px 15px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: "hidden",
    width: '65%',
    height: '100%',
    margin: 0,
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    borderRadius: '10px',
  },
  gridContainer: {
    margin: '20px 0 0 30px',
    height: '85%',
    width: '30%'
  },
  tituloTodosProntuarios: {
    color: '#505050',
    fontSize: '16px',
    margin: '24px 0 0 35px'
  },
  selectedItens: {
    overflowY: "scroll",
    width: '35%',
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginRight: '1rem',
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    borderRadius: '10px',
    height: '100%',
    '& > h1': {
      fontSize: '16px',
      color: '#505050',
      margin: '24px'
    }
  },
  camposProntuarioDisponiveis: {
    display: 'flex',
    flexDirection: 'column'
  },
  campoProntuario: {
    display: 'flex',
    flexDirection: 'row',
    '& > div>div>div:first-child': {
      marginRight: '0px'
    },
  },
  container: {
    height: "300px",
    border: "1px solid " + theme.palette.commons.gray3,
    marginTop: "20px",
    padding: "20px",
    borderRadius: '8px',
  },
  buttonActionClose: {
    backgroundColor: "#FB7676",
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10
  },
  tuneIcon: {
    width: '1em',
    height: '1em',
    color: "#26ACA9",
  },
  listItemText:{
    padding: '0 8px',
    '& > span': {
      color: '#505050',
      fontWeight: '500',
    },
  },
  wrapperFooter:{
    display: 'flex',
    padding: '1rem',
    backgroundColor: '#F1F1F3',
    '& > button': {
      flex: 1,
      margin: '0 0.5rem',
    },
  },
  buttonCancel:{
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonSave:{
    backgroundColor: '#26ACA9',
    border: '2px solid rgba(220, 220, 220, 0.2)',
    boxShadow: 'none',
    borderRadius: "100px",
    height: 32,
    width: "100%",
    border: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
    textTransform: "capitalize",
  },
}))(PersonalizarCamposProntuario);
