import React from "react";
import moment from "moment";
import string from "../../../utils/string";
import StatusAgendamento from "../../../components/ItemStatusColor/StatusAgendamento";

export const columnsConsultas = [
  {
    Header: "Status",
    paddingLeft: true,
    getValue: (row) => {
      return <StatusAgendamento status={string.capitalize(row.situacao)} />;
    },
    props: { center: "true" },
  },
  {
    Header: "Data",
    paddingLeft: true,
    getValue: (row) => {
      return moment(row.data).format("DD/MM/YYYY");
    },
    props: { center: "true" },
  },
  {
    Header: "Horário",
    paddingLeft: true,
    getValue: (row) => {
      return row.horaInicio;
    },
    props: { center: "true" },
  },
  {
    Header: "Tipo",
    paddingLeft: true,
    getValue: (row) => {
      return row.tipo?.descricao;
    },
    props: { center: "true" },
  },
  {
    Header: "Profissional Saúde",
    paddingLeft: true,
    getValue: (row) => {
      return row.profissionalSaude?.nome || row.solicitante?.nome;
    },
    props: { center: "true" },
  },
  {
    Header: "Sala",
    paddingLeft: true,
    getValue: (row) => {
      return row.sala?.nome;
    },
    props: { center: "true" },
  },
  {
    Header: "Procedimentos",
    paddingLeft: true,
    getValue: (row) => {
      return string.concatenarArrayProcedimentosEmString(row?.procedimentos);
    },
    props: { center: "true" },
  },
];

export const situacoes = [
  { value: "AGENDADO", name: "Agendado", cor: "#73C8F9", checked: true },
  { value: "CONFIRMADO", name: "Confirmado", cor: "#26ACAA", checked: true },
  { value: "AGUARDANDO", name: "Aguardando", cor: "#F9BE73", checked: true },
  { value: "ATENDENDO", name: "Atendendo", cor: "#717FFC", checked: true },
  { value: "ATENDIDO", name: "Atendido", cor: "#9871FC", checked: true },
  { value: "CANCELADO", name: "Cancelado", cor: "#FB7676", checked: true },
  { value: "EXCLUIDO", name: "Excluido", cor: "#FF6230", checked: true },
  { value: "BLOQUEADO", name: "Bloqueado", cor: "#566280", checked: true },
  { value: "FALTOU", name: "Faltou", cor: "#FC71B4", checked: true },
];
