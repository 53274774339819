import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { matchPath } from "react-router";
import styles from "../../assets/jss/pages/financeiroStyle";
import PanelLeft from "../../components/PanelLeft/PanelLeft";
import Footer from "../../components/Footer/Footer";
import Header from "../../template/Header/Header";
import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import SubMenu from "./Menu/SubMenu";
import Route from "react-router-dom/Route";
import Categoria from "./Categoria/Categoria";
import CentroDeCusto from "./CentroDeCusto/CentroDeCusto";
import ContaBancaria from "./ContaBancaria/ContaBancaria";
import Lancamento from "./Lancamento/Lancamento";
import { LockedFallback, RoleProtection } from "../../components/RoleProtection";

import configuracoesBlur from "../../assets/img/blur-screens/configuracoes.png";

const routes = [
  {
    title: "Geral",
    children: [
      {
        uri: "/lancamento",
        title: "Lançamentos",
        component: Lancamento,
        menu: true,
      },
      {
        uri: "/contas-bancarias",
        title: "Contas Bancárias",
        component: ContaBancaria,
        menu: true,
      },
      {
        uri: "/categorias",
        title: "Categorias Financeiras",
        component: Categoria,
        menu: true,
      },
      {
        uri: "/centro-de-custos",
        title: "Centro de Custos",
        component: CentroDeCusto,
        menu: true,
      },
    ],
  },
];

@inject("extratoStore", "usuarioStore", "configuracaoImpressaoStore")
@observer
class Financeiro extends React.Component {
  state = {
    unidadeLogada: null,
    subMenuItens: [],
    showSidebar: true,
  };

  async componentDidMount() {
    const {
      usuarioStore,
      configuracaoImpressaoStore,
      history,
      match,
      location,
    } = this.props;
    const unidadeLogada = await usuarioStore.getUnidadeAtual();
    configuracaoImpressaoStore.getConfig("RELATORIO");

    const subMenuItens = routes.map((route) => {
      return {
        ...route,
        children: route.children?.filter((item) => item?.menu),
      };
    });

    let showSidebar = true;
    routes.forEach((route) => {
      return route?.children.forEach((item) => {
        const isMatchPath = matchPath(location.pathname, {
          path: `${match.path}${item?.uri}`,
          exact: true,
        });

        if (!isMatchPath) return;
        showSidebar = item?.menu;
      });
    });

    this.setState({ subMenuItens, showSidebar: showSidebar }, () => {
      const item = this.state.subMenuItens[0].children[0] || null;
      if (item && match.isExact) {
        history.push(`${match.path}${item.uri}`);
      }
    });

    this.setState({
      unidadeLogada,
    });
  }

  render() {
    const { classes, match, location } = this.props;
    const { subMenuItens, showSidebar } = this.state;

    const isExtratoPage =
      location && location.pathname && location.pathname === "/financeiro"
        ? true
        : false;

    return (
      <div className={isExtratoPage ? classes.rootExtrato : classes.root}>
        {showSidebar && (
          <PanelLeft className={classes.panelLeft}>
            <Grid item>
              <Header>
                <Grid
                  item
                  container
                  alignItems={"center"}
                  justify={"center"}
                  xs={12}
                >
                  <h3 className={classes.titleHeader}>Financeiro</h3>
                </Grid>
              </Header>
            </Grid>
            <div className={classes.submenuItens}>
              <SubMenu items={subMenuItens} />
            </div>
          </PanelLeft>
        )}
        {routes.map((route) => {
          return route.children.map(({ uri, roles, component: Component }) => (
            <Route
              exact
              key={uri}
              path={`${match.path}${uri}`}
              component={(props) => (
                <RoleProtection roles={roles} fallback={(props) => (
                  <LockedFallback blurImageSrc={configuracoesBlur} {...props} />
                )}>
                  <Component {...props} />
                </RoleProtection>
              )}
            />
          ));
        })}

        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Financeiro);
