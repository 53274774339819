import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import Dialog from "../../../components/Dialog/Dialog";
import {
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  withStyles,
} from "@material-ui/core";
import InputTimeForm from "../../../components/Input/InputTimeForm";
import { Button } from "../../../components/ui/Buttons";
import moment from "moment";
import styles from "./EncerrarTeleconsultaModalStyles";

const EncerrarTeleconsultaModal = (props) => {
  const { prontuarioStore, classes } = props;
  const [form, setForm] = useState({ inicio: "", fim: "" });

  const inicioTeleconsulta = localStorage.getItem("inicioTeleconsulta");
  const fimTeleconsulta = localStorage.getItem("fimTeleconsulta");

  const handleConfirm = () => {
    const { inicio, fim } = form;
    const horaInicio = moment(inicio, "HH:mm").toDate();
    const horaFim = moment(fim, "HH:mm").toDate();

    if (horaInicio > horaFim) {
      prontuarioStore.openNotification(
        "O horário de início é maior que o horário de término.",
        "error"
      );
      return;
    }

    if (!inicio || !fim) {
      prontuarioStore.openNotification(
        "Preencha o horário inicial e final.",
        "error"
      );
      return;
    } else {
      const entradaProntuarioHorario = {
        inicio: horaInicio,
        fim: horaFim,
        isPacienteNaoCompareceu: false,
      };

      prontuarioStore.createEntradaProntuarioTeleconsulta(
        entradaProntuarioHorario
      );
    }
  };

  const handleClickPacienteNaoCompareceu = () => {
    prontuarioStore.createEntradaProntuarioTeleconsulta({
      isPacienteNaoCompareceu: true,
    });
  };

  const handleChange = (name) => (event) => {
    const hour = moment(event).format("HH:mm");

    setForm((oldForm) => ({ ...oldForm, [name]: hour }));
  };

  useEffect(() => {
    setForm({
      inicio: moment(inicioTeleconsulta).format("HH:mm"),
      fim: moment(fimTeleconsulta).format("HH:mm"),
    });
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="encerrar-teleconsulta-modal"
      open
    >
      <DialogTitle
        classes={{ root: classes.dialogHeader }}
        disableTypography
        id="encerrar-teleconsulta-modal"
      >
        Teleconsulta encerrada
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.content}>
          <InputTimeForm
            label="Início:"
            value={moment(form.inicio, "HH:mm").toDate()}
            onChange={handleChange("inicio")}
            helperText={""}
            classes={{
              date: classes.date,
              label: classes.labelTime,
              input: classes.inputDate,
              icon: classes.iconTime,
            }}
          />
          <InputTimeForm
            label="Fim:"
            value={moment(form.fim, "HH:mm").toDate()}
            onChange={handleChange("fim")}
            helperText={""}
            classes={{
              date: classes.date,
              label: classes.labelTime,
              input: classes.inputDate,
              icon: classes.iconTime,
            }}
          />
        </div>
        <Grid item xs={12}></Grid>
        <DialogActions className={classes.contentButtons}>
          <Button
            fullWidth
            bgColor="#707C97"
            onClick={handleClickPacienteNaoCompareceu}
          >
            Paciente não compareceu
          </Button>
          <Button fullWidth kind="primary" onClick={handleConfirm}>
            Confirmar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

EncerrarTeleconsultaModal.propTypes = {
  prontuarioStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const stores = ['prontuarioStore', 'usuarioStore'];
const EncerrarTeleconsultaModalStyles = withStyles(styles)(EncerrarTeleconsultaModal);
export default inject(...stores)(EncerrarTeleconsultaModalStyles);
