import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const LockIcon = (props) => {
  return (
    <svg 
      width="16" 
      height="16" 
      viewBox="0 0 16 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M7.8 1.80078C9.12548 1.80078 10.2 2.8753 10.2 4.20078V5.40078H11.7C12.1971 5.40078 12.6 5.80373 12.6 6.30078V12.9008C12.6 13.3978 12.1971 13.8008 11.7 13.8008H3.9C3.40294 13.8008 3 13.3978 3 12.9008V6.30078C3 5.80373 3.40294 5.40078 3.9 5.40078H5.4V4.20078C5.4 2.8753 6.47452 1.80078 7.8 1.80078ZM7.80008 8.70078C7.30302 8.70078 6.90008 9.10373 6.90008 9.60078C6.90008 10.0978 7.30302 10.5008 7.80008 10.5008C8.29713 10.5008 8.70008 10.0978 8.70008 9.60078C8.70008 9.10373 8.29713 8.70078 7.80008 8.70078ZM7.8 3.00078C7.13726 3.00078 6.6 3.53804 6.6 4.20078V5.40078H9V4.20078C9 3.53804 8.46274 3.00078 7.8 3.00078Z" fill="currentColor" />
    </svg>
  );
};

export default LockIcon;
