import React from 'react';
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/src/createNumberMask";

export const renderMaskedInput= (maskProps, props) => {
    const {suffix, placeholder, integerLimit, decimalLimit, allowDecimal, ...rest} = maskProps;
    const {...other} = props
  
    const formattedMask = createNumberMask({
      prefix: '',
      suffix: suffix,
      includeThousandsSeparator: false,
      decimalSymbol: ',',
      integerLimit: integerLimit,
      allowDecimal: allowDecimal,
      decimalLimit: decimalLimit,
      ...rest
    })
  
    return (<MaskedInput 
      ref={ref => {
          other.inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={'\u2000'} 
      showMask={props.showmask}
      mask={formattedMask}
      placeholder={placeholder}
      {...other}
    />)
  
  }
  
