import React from "react";
import { Dates } from "../../../utils";
import moment from "moment";
import { formattedPhone } from "../../../components/Input/TelefoneFormatado";

const renderItem = ({ item, index, utilizaTelefoneInternacional }) => {
  const calculaIdade = () => {
    return Dates.calculaIdade(item.dataNascimento);
  };

  const horarioAgendamento = () => {
    const horaInicio = moment(item.start).format("HH:mm");
    const horaFim = moment(item.end).format("HH:mm");

    return `${horaInicio} - ${horaFim}`
  };

  return (
    <div style={styles.containerColuna} key={index}>
      <div style={styles.coluna}>
        <div style={styles.text}>{horarioAgendamento()}</div>
      </div>

      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.nome}</div>
      </div>

      <div style={styles.coluna}>
        <div style={styles.text}>{item.tipo}</div>
      </div>

      <div style={styles.colunaQuebraPalavra}>
        <div style={styles.text}>
          {item.sujeitoAtencaoTelefone && formattedPhone({
            utilizaTelefoneInternacional,
            telefone: item.sujeitoAtencaoTelefone, 
            telefoneDDI: item.sujeitoAtencaoTelefoneDDI
          })}
        </div>
      </div>

      <div style={styles.coluna}>
        <div style={styles.text}>{item.convenio}</div>
      </div>

      <div style={styles.colunaQuebraPalavra}>
        <div style={styles.text}>{item.dataNascimento && calculaIdade()}</div>
      </div>
    </div>
  );
};

const RelatorioAgendaDiaDocument = (props) => {
  const { data, agendamentos, utilizaTelefoneInternacional, profissional } = props;
  
  return (
    <>
      <div style={styles.titulo}>
        <div style={styles.textBold}> Relatório agenda - { moment(data).format("DD/MM/YY") } </div>
        <div style={styles.textBold}> Profissional: { profissional } </div>
      </div>
      <div style={styles.containerColuna}>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Horário</div>
        </div>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>Nome</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Tipo</div>
        </div>
        <div style={styles.colunaQuebraPalavra}>
          <div style={styles.textBold}>Telefone</div>
        </div>
        <div style={styles.coluna}>
          <div style={styles.textBold}>Convênio</div>
        </div>
        <div style={styles.colunaQuebraPalavra}>
          <div style={styles.textBold}>Idade</div>
        </div>
      </div>

      {agendamentos.map((item, index) => renderItem({ item, index, utilizaTelefoneInternacional }))}
    </>
  );
};

const styles = {
  text: {
    fontSize: '12px',
    marginBottom: '8px',
  },

  textBold: {
    fontWeight: 700,
    fontSize: '12px',
    marginBottom: '8px',
  },

  titulo: {
    marginLeft: '0px',
    marginBottom: '10px',
    marginTop: '5px',
    paddingBottom: '5px',
    textAlign: 'center',
  },

  containerColuna: {
    display: 'flex',
    flexDirection: 'row',
  },

  coluna: {
    display: 'flex',
    flexGrow: 1,
    width: '75px',
  },

  colunaQuebraPalavra: {
    display: 'flex',
    flexGrow: 1,
    width: '75px',
    wordBreak: 'break-all',
  },

  colunaDescricao: {
    display: 'flex',
    flexGrow: 2,
    width: '150px',
    marginBottom: '8px',
    marginRight: '3px',
  },
}

export default RelatorioAgendaDiaDocument;
