import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '../../../components/Button/Button';
import { WhatsApp } from '../../../components/Icon';
import MailOutline from '@material-ui/icons/MailOutline';
import { supportWhatsappLink, suppEmail, LPComercial } from '../constants/perfilViziConstants';

const styles = ({
  activatedCard: {
    display: 'flex',
    flexDirection: 'column',
    color: '#5F6368'
  },
  viziWarningText: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: '#5F6368',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '16px',
    marginTop: '8px',
    marginBottom: '16px'
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '6px',
    color: '#5F6368',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    },
    '&>a': {
      color: '#5F6368',
    }
  }
});

const PortalViziActivationCard = ({ 
    classes, 
    onPressActivation, 
    beforeActivationText, 
    afterActivationText,
    onlyOneText,
    whoActivate,
    activationDate,
    userType,
    variant,
  }) => {

  return (
    <>
      {variant === 'viziDisabledInUnidade' && (
        <>
          <span className={classes.viziWarningText}>
            {onlyOneText}
          </span>
          <Button
            style={{
              borderRadius: '100px',
              padding: '8px 16px',
              height: '36px',
              textTransform: 'none'
            }}
            onClick={() => window.open(LPComercial, '_blank')}
          >
            Saber Mais
          </Button>
        </>
      )}
      {variant === 'viziReadyToActivate' && (
        <>
          <span className={classes.viziWarningText}>
            {beforeActivationText}
          </span>
          <Button
            style={{
              borderRadius: '100px',
              padding: '8px 16px',
              height: '36px',
              textTransform: 'none'
            }}
            onClick={onPressActivation}
          >
            {userType === 'profissional' || userType === 'unidade' ? 'Ativar funcionalidade' : 'Ativar acesso'}
          </Button>
        </>
      )}
      {variant === 'viziActivated' && (
        <div className={classes.activatedCard}>
          <span className={classes.viziWarningText}>
            {afterActivationText}
          </span>
          <div className={classes.contactContainer}>
            <div className={classes.contactItem}>
              <WhatsApp />
              <a href={supportWhatsappLink} target='_blank' rel="noreferrer">
                <b>
                  WhatsApp
                </b>
              </a>
            </div>
            <div className={classes.contactItem}>
              <MailOutline />
              <a href={`mailto: ${suppEmail}`} target='_blank' type='email' rel="noreferrer" >
                <b>
                  E-mail
                </b>
              </a>
            </div>
          </div>
          {activationDate &&
            <span><b>Ativado em: </b>{activationDate || ''}</span>
          }
          {whoActivate &&
            <span><b>Por:</b>{whoActivate || ''}</span>
          }
        </div> 
      )}
    </>
  );
};

export default withStyles(styles)(PortalViziActivationCard);