import React, { useEffect, useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import { matchPath } from "react-router";
import styles from "../../assets/jss/pages/financeiroStyle";
import PanelLeft from "../../components/PanelLeft/PanelLeft";
import Footer from "../../components/Footer/Footer";
import Header from "../../template/Header/Header";
import { Grid } from "@material-ui/core";
import SubMenu from "../Financeiro/Menu/SubMenu";
import Route from "react-router-dom/Route";
import { LockedFallback, RoleProtection } from "../../components/RoleProtection";

import configuracoesBlur from "../../assets/img/blur-screens/configuracoes.png";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { routes } from "./constants";

const Guias = observer(({
  configuracaoImpressaoStore,
  classes,
  history,
  match,
  location,
}) => {

  const [subMenuItens, setSubMenuItens] = useState([]);
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    configuracaoImpressaoStore.getConfig("RELATORIO");
    getSubMenuItens();
    verificaShowSidebar();
  }, []);

  useEffect(() => {
    if (subMenuItens.length > 0) {
      const item = subMenuItens[0].children[0] || null;
      if (item && match.isExact) {
        history.push(`${match.path}${item.uri}`);
      }
    };

  }, [showSidebar]);

  const getSubMenuItens = () => {
    const subMenuItens = routes.map((route) => {
      return {
        ...route,
        children: route.children?.filter((item) => item?.menu),
      };
    });

    setSubMenuItens(subMenuItens);
  };

  const verificaShowSidebar = () => {
    let showSidebar = true;
    routes.forEach((route) => {
      return route?.children.forEach((item) => {
        const isMatchPath = matchPath(location.pathname, {
          path: `${match.path}${item?.uri}`,
          exact: true,
        });

        if (!isMatchPath) return;
        showSidebar = item?.menu;
      });
    });

    setShowSidebar(showSidebar);
  };


  return (
    <div className={classes.root}>
      {showSidebar && (
        <PanelLeft className={classes.panelLeft}>
          <Grid item>
            <Header>
              <Grid
                item
                container
                alignItems={"center"}
                justify={"center"}
                xs={12}
              >
                <h3 className={classes.titleHeader}>Guias</h3>
              </Grid>
            </Header>
          </Grid>
          <div className={classes.submenuItens}>
            <SubMenu items={subMenuItens} />
          </div>
        </PanelLeft>
      )}
      {routes.map((route) => {
        return route.children.map(({ uri, roles, component: Component }) => (
          <Route
            exact
            key={uri}
            path={`${match.path}${uri}`}
            component={(props) => (
              <RoleProtection roles={roles} fallback={(props) => (
                <LockedFallback blurImageSrc={configuracoesBlur} {...props} />
              )}>
                <Component {...props} />
              </RoleProtection>
            )}
          />
        ));
      })}

      <Footer />
    </div>
  )
});

const GuiasWithStyles = withStyles(styles)(Guias);
export default inject("extratoStore", "usuarioStore", "configuracaoImpressaoStore")(GuiasWithStyles);