import React from "react";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import Dialog from "../../../../components/Dialog/Dialog";
import ButtonLong from "../../../../components/Button/ButtonLong";
import colors from "../../../../template/Colors";
import { InputForm } from "../../../../components/Input/InputDadosCadastraisPaciente";

const AlertaUnificacao = (props) => {
  const {
    open,
    classes,
    icon,
    color,
    title,
    text,
    textStrong,
    handleCancelar,
    handleContinuar,
    isButtons,
    ultimaConfirmacaoUnificar,
    valueInput,
    onChangeMotivo,
  } = props;

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paperAlert,
      }}
    >
      {icon && <div className={classNames(classes.icon, classes[color])}>{icon}</div>}
      <div
        className={classNames(
          classes.title,
          classes.marginTop,
          classes.colorFont,
          ultimaConfirmacaoUnificar && classes.titleFontMenor,
        )}
      >
        {title}
      </div>
      <div
        className={classNames(
          classes.text,
          classes.marginTop,
          classes.colorFont
        )}
      >
        {text}
        <strong> {` ${textStrong}`}</strong>
      </div>

     {title === "Qual o motivo da unificação?" &&
        <>
          <div className={classes.titleInput}> Descreva o motivo aqui: </div>
          <InputForm
            multiline
            rows={3}
            value={valueInput}
            onChange={onChangeMotivo}
            className={classes.inputMotivo}
          />
        </>
      }
      {isButtons && (
        <div className={classNames(classes.contentButtons)}>
          <ButtonLong
            colorCustom="transparency"
            onClick={handleCancelar}
            className={classes.buttonCancelar}
          >
            Cancelar
          </ButtonLong>
          <ButtonLong
            colorCustom="green"
            onClick={handleContinuar}
            className={classes.noBoxShadow}
          >
            Continuar
          </ButtonLong>
        </div>
      )}
    </Dialog>
  );
};

const styles = {
  paperAlert: {
    maxWidth: 256,
    borderRadius: "16px",
    padding: '32px 32px 33px 32px',
  },
  icon: {
    width: 50,
    height: 50,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    alignSelf: "center",
    textAlign: "center",
  },
  titleFontMenor: {
    fontSize: 18,
  },
  text: {
    textAlign: "justify",
  },
  marginTop: {
    marginTop: 16,
  },
  colorFont: {
    color: colors.commons.gray9,
  },
  contentButtons: {
    display: "flex",
    marginTop: 18,
  },
  noBoxShadow: {
    boxShadow: "none",
  },
  buttonCancelar: {
    border: "none",
    boxShadow: "none",
    marginRight: 16,
    "&:hover": {
      color: colors.commons.gray9,
      background: 'none'
    }
  },
  yellow: {
    background: colors.commons.yellow2,
    color: colors.commons.white,
  },
  blue: {
    background: "#717FFC",
    color: colors.commons.white,
  },
  green: {
    background: "#36ABA8",
    color: colors.commons.white,
  },
  red: {
    background: colors.error.main,
    color: colors.commons.white,
  },
  purple: {
    background: "#9871FC",
    color: colors.commons.white
  },
  inputMotivo: {
    background: colors.commons.gray2,
		borderRadius: 10,
		padding: '0 10px',
		boxSizing: "borderBox",
  },
  titleInput: {
    fontSize: '12px',
  }
};

export default withStyles(styles)(AlertaUnificacao);
