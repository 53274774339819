import React from "react";
import { XAxis, Tooltip, AreaChart, Area, ResponsiveContainer } from "recharts";

const GraficoTempoEspera = (props) => {
  const { data, datasKey, TooltipProps } = props;

  return (
    <ResponsiveContainer width="100%" height={260} id={"grafico-tempo-espera"}>
      <AreaChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <Tooltip {...TooltipProps} />
        <Area
          type="monotone"
          dataKey={datasKey && datasKey[0]}
          stroke="#27B0AD"
          strokeWidth={2}
          fill="#fff"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default GraficoTempoEspera;
