import React, { useState, useEffect } from 'react';
import { withStyles, Dialog } from '@material-ui/core';
import { styles } from './styles';

import SelectProfissionalStep from './ModalTransferenciaSteps/SelectProfissionalStep';
import ConfirmStep from './ModalTransferenciaSteps/ConfirmStep'; 
import ConflictStep from './ModalTransferenciaSteps/ConflictStep';
import SuccessStep from './ModalTransferenciaSteps/SuccessStep';

const ModalTransferencia = ({ 
  open, 
  classes, 
  currentFilter, 
  totalAgendamentos, 
  selectedAgendamentos, 
  handleClose,
  conflictedAgendamentos,
  setConflictedAgendamentos,
  handleOpenConflictModal,
  openNotification,
  modalStep,
  setModalStep,
  setNotification,
  allSelected,
}) => {
  const [profissionalSaudeDestino, setProfissionalSaudeDestino] = useState(null);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  
  useEffect(() => {
    if (modalStep === 0) {
      setProfissionalSaudeDestino(null);
    }
  }, [modalStep]);

  const renderStep = () => {
    switch(modalStep) {
      case 1:
        return (
          <ConfirmStep
            onCloseModal={onCloseModal}
            profissionalSaudeDestino={profissionalSaudeDestino}
            selectedAgendamentos={selectedAgendamentos}
            setTransactionSuccess={setTransactionSuccess}
            setConflictedAgendamentos={setConflictedAgendamentos}
            setModalStep={setModalStep}
            currentFilter={currentFilter}
            setNotification={setNotification}
            allSelected={allSelected}
          />
        )
      case 2:
      if (transactionSuccess) {
        return (
          <SuccessStep />
        );
      } else {
        return (
          <ConflictStep
            conflictedAgendamentos={conflictedAgendamentos}
            openNotification={openNotification}
            handleOpenConflictModal={handleOpenConflictModal}
            onCloseModal={onCloseModal}
          />
        );
      }  
      
      default:
        return (
          <SelectProfissionalStep
            profissionalSaudeDestino={profissionalSaudeDestino}
            selectedAgendamentos={selectedAgendamentos}
            setModalStep={setModalStep}
            setProfissionalSaudeDestino={setProfissionalSaudeDestino}
            onCloseModal={onCloseModal}
            totalAgendamentos={totalAgendamentos}
            allSelected={allSelected}
          />
        );
    }
  }

  const onCloseModal = () => {
    handleClose();
    setModalStep(0);
    setProfissionalSaudeDestino(null);
  }

  return (
    <Dialog 
      open={open} 
      onClose={onCloseModal}
      classes={{
        paper: classes.paper
      }}
    >
      <div className={classes.modalContent}>
        {renderStep()}
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ModalTransferencia);
