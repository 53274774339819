import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InputSearchRetangular from "../../../components/Input/InputSearchRetangular";
import {
    IconButton, 
    InputAdornment,
  } from '@material-ui/core'
  import {
    Search,
  } from "@material-ui/icons";
import ListaTitulos from "./ListaTitulos";
import { findAllTitulo } from "../../../services/FinanceiroService";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

const ContentListaTitulo = observer(props => {
    const { classes, atendimentoStore, extratoStore } = props;
    const [search, setSearch] = useState("");
    const [titulos, setTitulos] = useState([]);
    const [loadingTitulos, setLoadingTitulos] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [last, setLast] = useState(false);

    useEffect(() => {
        loadTitulos(true);
    }, []);

    const handleClickSearch = () => {
        loadTitulos(true);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
    };

    const onKeypressSearch = e => {
        if (e.key === 'Enter' && search.length >= 3) {
            loadTitulos(true);
        }
    };

    const loadTitulos = async(clear = false) => {
        try {
            setLoadingTitulos(true);
            let pageNumberTitulos = pageNumber;
            let titulosLoad = titulos;
    
            if (clear) {
                pageNumberTitulos = 0;
                titulosLoad = [];
            };

            const response = await findAllTitulo({
                search,
                profissionalSaudeId: atendimentoStore.objView.profissionalSaude?.id,
                possuiAgendamento: false,
                sujeitoAtencaoId: atendimentoStore.objView.sujeitoAtencao?.id,
                pageableDTO: {
                    sortDir: "DESC",
                    sortField: "dataVencimento",
                    pageSize: 20,
                    pageNumber: pageNumberTitulos
                }
            });

            setPageNumber(pageNumberTitulos+1);
            setLast(response.last);
            setTitulos([...titulosLoad, ...response.content]);

        } catch(e) {
            atendimentoStore.showAlertMessage({
                isOpen: true,
                message: "Erro ao carregar a lista de títulos.",
            });

        } finally {
            setLoadingTitulos(false);

        }
    };

    const selecionaTitulo = async(titulo) => {
        const dadosTitulo = await extratoStore.findByIdTitulo(titulo.id);
        extratoStore.preencherDadosPagamentoDoAgendamento(dadosTitulo);
        extratoStore.isTituloVinculaAgendamento = true;
        atendimentoStore.showTitulo = true;
    };

    return(
        <div className={classes.contentVinculeTitulo}>
            <div className={classes.ou}> Ou </div>
            <div className={classes.title}>Vincule com um título existente</div>
            <InputSearchRetangular
                hideStartAdornment
                endAdornment={
                <InputAdornment position="end">
                    <IconButton className={classes.iconButtonSearch}
                                onClick={() => handleClickSearch()}>
                    <Search/>
                    </IconButton>
                </InputAdornment>}
                placeholder={"Pesquisar"}
                value={search}
                onChange={onChangeSearch}
                onKeyPress={onKeypressSearch}
                className={classes.search}
            />
            <ListaTitulos 
                titulos={titulos}
                loadMoreTitulos={loadTitulos}
                last={last}
                loadingTitulos={loadingTitulos}
                selecionaTitulo={selecionaTitulo}
            />

        </div>
    )
});

const styles = {
    contentVinculeTitulo: {
        height: 'calc(100% - 170px)',
    },
    title: {
        fontWeight: 500,
        fontSize: '12px',
        color: '#868686',
    },
    search: {
        height: 28,
        width: 'calc(100% - 16px)',
        "&> div": {
            border: '1px solid rgba(220, 220, 220, 0.2)',
        }
    },
    ou: {
        color: '#505050',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '36px',
        margin: '4px 0',
    },
    iconButtonSearch: {
        padding: 0
    },

};

const ContentListaTituloWithStyles = withStyles(styles)(ContentListaTitulo);
export default inject("atendimentoStore", "extratoStore")(ContentListaTituloWithStyles);