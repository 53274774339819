import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'

import Content from './components/Content'
import { findControleSessaoById } from '../../../../services/ControleSessaoService'
import string from '../../../../utils/string'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'

const Sessoes = observer(({ classes, history, controleSessaoStore }) => {
  const [pacoteSessao, setPacoteSessao] = useState({})
  const idPacote = window.location.href.split('/')[8]

  const findPacoteSelected = async () => {
    try {
      const response = await findControleSessaoById(idPacote)
      setPacoteSessao(response)
      if (controleSessaoStore.triggerReloadFindSelectedSessao) {
        controleSessaoStore.triggerReloadFindSelectedSessao = false
      }
    } catch (error) {
      console.error(error)
    }
  }
  
  useEffect(() => {
    if (!idPacote && !controleSessaoStore.triggerReloadFindSelectedSessao) return
    findPacoteSelected()
  }, [idPacote, controleSessaoStore.triggerReloadFindSelectedSessao])

  const { saldoDisponivel, agendados } = pacoteSessao
  
  return (
    <div className={classes.container}>
      <div className={classes.wrapperTitle}>
        <h1>{string.truncate(pacoteSessao?.nomeSessao, 25)}</h1>
        {pacoteSessao?.titulo?.id && (
          <div className={classes.wrapperFinanceiroInfos}>
            <div className={classes.wrapperFinanceiroInfo}>
              <span className={classes.label}>Valor total</span>
              <span className={classes.value}>{`R$ ${pacoteSessao?.titulo
                ?.valor || 0}`}</span>
            </div>
            <div className={classes.wrapperFinanceiroInfo}>
              <span className={classes.label}>Saldo disponível</span>
              <span
                className={classes.value}
              >{`R$ ${saldoDisponivel}`}</span>
            </div>
            <div className={classes.wrapperFinanceiroInfo}>
              <span className={classes.label}>Agendados</span>
              <span className={classes.value}>{agendados}</span>
            </div>
          </div>
        )}
      </div>
      <Content history={history} />
    </div>
  )
})

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '24px',
    overflow: 'hidden',
    backgroundColor: '#F5F5F5',
  },
  wrapperFinanceiroInfos: {
    display: 'flex',
    gap: '16px',
  },
  wrapperFinanceiroInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '4px',
  },
  label: {
    fontSize: '12px',
    fontWeight: '400',
  },
  value: {
    fontSize: '18px !important',
    fontWeight: '700',
  },
  wrapperTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h1': {
      fontSize: '18px',
      margin: '0',
      fontWeight: '700',
    },
  },
})

const stores = ['controleSessaoStore']
const SessoesStyles = withStyles(styles)(Sessoes)
export default inject(...stores)(SessoesStyles)
