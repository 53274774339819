import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { Grid, DialogContent, Fab } from "@material-ui/core";
import { Remove as RemoveIcon } from "@material-ui/icons";
import styles from "./SADTStyle";

const ContentProcedimentosSelecionados = observer((props) => {
  const { classes, SADTStore } = props;
  const { readOnly, objView } = SADTStore;

  const handleClickRemove = (selectedIndex) => {
    objView.guiaServicoTussViews.splice(selectedIndex, 1);
  };

  return (
    <DialogContent
      className={
        readOnly ? classes.semLateralSugestoes : classes.dialogContentBorder
      }
    >
      <Grid container className={classes.dialogContent}>
        <Grid item xs={12} className={classes.scrollContainer}>
          <Grid container className={classes.tableHeadRow}>
            <Grid item xs={2} className={classes.tableHead}>
              Tabela
            </Grid>
            <Grid item xs={3} className={classes.tableHead}>
              Código
            </Grid>
            <Grid item xs={5} className={classes.tableHeadDescricao}>
              Descrição
            </Grid>
            <Grid item xs={1} className={classes.tableHead}>
              Qtde.
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {objView.guiaServicoTussViews.map((row, index) => (
            <Grid container key={index} className={classes.tableDataRow}>
              <Grid item xs={2} className={classes.tableData}>
                {row.tussView.tabela}
              </Grid>
              <Grid item xs={3} className={classes.tableData}>
                {row.tussView.codigoTermo}
              </Grid>
              <Grid item xs={5} className={classes.tableDataDescricao}>
                {row.descricao || row.tussView.descricao}
              </Grid>
              <Grid item xs={1} className={classes.tableData}>
                {row.quantidadeSolicitada || ""}
              </Grid>
              <Grid item xs={1} align="right" className={classes.tableData}>
                {!readOnly && (
                  <div className={classes.buttonAction}>
                    <Fab
                      className={classes.buttonIcon}
                      onClick={() => handleClickRemove(index)}
                      color="primary"
                    >
                      <RemoveIcon />
                    </Fab>
                  </div>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DialogContent>
  );
});

const ContentProcedimentosSelecionadosWithStyles = withStyles(styles)(
  ContentProcedimentosSelecionados
);
export default inject("SADTStore")(ContentProcedimentosSelecionadosWithStyles);
