export const STATUS = {
  ATIVO: {
    borderColor: '#007F79',
    color: '#E1F3F4',
    text: 'Ativo',
  },
  FINALIZADO: {
    borderColor: '#2E416E',
    color: '#E6E8EC',
    text: 'Finalizado',
  },
  AGUARDANDO: {
    borderColor: '#F9BE73',
    color: '#FCF0DF',
    text: 'Aguardando',
  },
  AGENDADO: {
    borderColor: '#73C8F9',
    color: '#E5F3FC',
    text: 'Agendado',
  },
  CANCELADO: {
    borderColor: '#FB7676',
    color: '#FAE2E1',
    text: 'Cancelado',
  },
  ATENDIDO: {
    borderColor: '#9871FC',
    color: '#EEE7FC',
    text: 'Atendido',
  },
  CONFIRMADO: {
    borderColor: '#00B0AE',
    color: '#D4EEEE',
    text: 'Confirmado',
  },
  FALTOU: {
    borderColor: '#FC71B4',
    color: '#FEF0F7',
    text: 'Faltou',
  },
  EXCLUIDO: {
    borderColor: '#FF6230',
    color: '#FDECE9',
    text: 'Excluído',
  },
  ATENDENDO: {
    borderColor: '#657496',
    color: '#E7E7E7',
    text: 'Atendendo',
  },
}
