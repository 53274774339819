import moment from "moment";

const getStatusFinanceiro = (dataVencimento, dataPagamento) => {
    if(dataPagamento) {
        return "Pago"
    } else {
        return moment(dataVencimento).isSameOrAfter(moment(), "date") ? "Aberto" : "Atrasado";
    }
};

export default getStatusFinanceiro;