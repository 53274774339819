import { action, observable } from "mobx";

export const tabs = {
    INFORMACOES: 0,
    AUDITORIA: 1,
}

export default class ModalConfiguracoesStore {
    @observable titulo = "";
    @observable showModal = false;
    @observable opcao = "";
    @observable pesquisaPor = "";

    constructor(rootStore) {
        this.auditoriaStore = rootStore.auditoriaStore;
    };

    @action reset() {
        this.titulo = "";
        this.show = false;
        this.opcao = "";
        this.pesquisaPor = "";
    };
    
}
