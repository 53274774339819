import { withStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import moment from "moment";
import styles from "../GuiaDeConsultaModalStyles";
import { InputDateForm } from "../../../../../components/Modal/Input";
import InputSelectComLabel from "../../../../../components/Input/InputSelectComLabel";
import { TextFieldSearch } from "../../../../../components/TextField";
import { findAllConvenio } from "../../../../../services/GuiaDeConsultaService";

const opcoesAtendimentoRN = [
  { value: "S", label: "Sim" },
  { value: "N", label: "Não" },
];

const StepPaciente = (props) => {
  const {
    classes,
    guiaConsulta,
    handleChangeGuiaConsulta,
    loadSujeitosAtencao,
    openNotification,
  } = props;

  const handleChange = (field, value) => {
    const newBeneficiario = {
      ...guiaConsulta.beneficiario,
      [field]: value,
    };

    handleChangeGuiaConsulta("beneficiario", newBeneficiario);
  };

  const loadConvenios = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (await findAllConvenio(
        search,
        { sortDir: "ASC", sortField: "descricao" },
        true,
        page,
        20
      )).data.data.findAllConvenio;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch {
      openNotification("Erro ao carregar os convênios", "error");
    }
  };

  const handleChangeSujeitoAtencao = (value) => {
    const newSujeitoAtencao = {
      ...guiaConsulta?.beneficiario,
      sujeitoAtencao: value,
      nome: value?.nome,
      numeroCarteira: value?.dadosConvenio?.numeroCarteira,
      dataValidadeCarteira: value?.dadosConvenio?.validadeCarteira,
      cns: value?.cns,
    };
    handleChangeGuiaConsulta("beneficiario", newSujeitoAtencao);
  };

  const getValue = (resposta) => {
    if (resposta === "S") {
      return { value: "S", label: "Sim" };
    } else if (resposta === "N") {
      return { value: "N", label: "Não" };
    }
  };

  return (
    <Grid container>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Typography className={classes.typography} component="label">
            Paciente
          </Typography>
          <TextFieldSearch
            placeholder="Selecione"
            loadOptions={loadSujeitosAtencao}
            classNotched={classes.notchedOutline}
            classInput={classes.inputContainer}
            classIcons={classes.classIcons}
            getOptionLabel={(option) => option?.nomeSocial || option?.nome}
            getOptionValue={(option) => option?.id}
            value={guiaConsulta.beneficiario?.sujeitoAtencao}
            onChange={(option) => {
              handleChangeSujeitoAtencao(option);
              handleChangeGuiaConsulta(
                "convenio",
                option?.dadosConvenio?.convenio
              );
            }}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
            maxMenuHeight={200}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typography} component="label">
            Convênio
          </Typography>
          <TextFieldSearch
            placeholder="Selecione"
            loadOptions={loadConvenios}
            classNotched={classes.notchedOutline}
            classInput={classes.inputContainer}
            classIcons={classes.classIcons}
            getOptionLabel={(option) => option?.descricao}
            getOptionValue={(option) => option}
            value={guiaConsulta?.convenio}
            onChange={(option) => handleChangeGuiaConsulta("convenio", option)}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
            maxMenuHeight={200}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <InputSelectComLabel
            label={"Número da Carteira"}
            placeholder='Digite o número da sua carteira'
            classes={{
              input: classes.inputContainer,
            }}
            onInput={(e) => (e.target.value = e.target.value.slice(0, 20))}
            value={guiaConsulta?.beneficiario?.numeroCarteira || ""}
            onChange={(e) => handleChange("numeroCarteira", e.target.value)}
            type="number"
            noMargin
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typography} component="label">
            Validade da Carteira
          </Typography>
          <InputDateForm
            iconposition="end"
            placeholder='00/00/0000'
            className={classes.inputDateForm}
            value={guiaConsulta?.beneficiario?.dataValidadeCarteira || ""}
            onChange={(e) =>
              handleChange(
                "dataValidadeCarteira",
                moment(e).format("YYYY-MM-DD")
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <InputSelectComLabel
            label={"Cartão Nacional da Saúde"}
            onInput={(e) => (e.target.value = e.target.value.slice(0, 15))}
            placeholder='Digite o número do seu CNS'
            classes={{
              input: classes.inputContainer,
            }}
            value={guiaConsulta?.beneficiario?.cns || ""}
            onChange={(e) => handleChange("cns", e.target.value)}
            type="number"
            noMargin
          />
        </Grid>
        <Grid item xs={6}>
          <InputSelectComLabel
            label={"Atendimento a RN"}
            placeholder="Selecione"
            isSelect
            value={getValue(guiaConsulta?.beneficiario?.atendimentoRN)}
            className={classes.inputContainer}
            onChange={(e) => handleChange("atendimentoRN", e.value)}
            options={opcoesAtendimentoRN || []}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(StepPaciente);
