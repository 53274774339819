import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../GuiaServicosFaturamentoStyle";
import {
  InputForm,
} from "../../../../../components/Modal/Input";

const StepObservacao = (props) => {
  const {
    classes,
    handleChangeGuiaServicoFaturamento,
    guiaServicoFaturamento,
    setDisableButton
  } = props;

  useEffect(() => {
    setDisableButton(false)
  }, []);

  const handleChangeObservacao = (e) => {
    const { value } = e.target
    handleChangeGuiaServicoFaturamento("observacao", value);
  };  

  return (
    <Grid item xs={12} className={classes.contentObservacao} >
      <Typography className={classes.typography} component='label'>
        Observação
      </Typography>
      <InputForm
        className={classes.observacao}
        classes={{
          input: classes.inputInfosObservacao,
        }}
        rowsMax={20}
        rows={8}
        multiline
        onChange={handleChangeObservacao}
        alternativeInputClass={classes.inputNome}
        value={guiaServicoFaturamento?.observacao}
        onInput={(e) => e.target.value = e.target.value.slice(0, 500)}
      />
    </Grid>
  );
};

export default withStyles(styles)(StepObservacao);
