import { primaryColor } from '../../assets/jss/appHealth.style';

const styles = (theme) => ({
  gridChatContainer: {
    display: 'grid',
    position: 'relative',
    gridTemplateColumns: `25% 1fr`,
    gridTemplateRows: '1fr 64px',
    gridTemplateAreas: `
            'sidebar-left content'
            'footer footer'
            `,
    flex: 1,
  },
  sidebarLeft: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '1px 0 1px rgba(0, 0, 0, 0.1)',
    height: '100%',
    overflow: 'hidden',
  },
  sidebarHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    padding: '16px 24px',
  },
  contactSearchForm: {
    flex: 1,
    width: '100%',
  },
  contactSearch: {
    flex: 1,
    border: 'none',
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
  },
  tabSelectorCustomized: {
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 24px',
  },
  selectedContact: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px !important',
    color: primaryColor,
    fontWeight: 700,
  },
  sideBarTitle: {
    fontSize: '18px !important',
    color: primaryColor,
    fontWeight: 700,
    alignSelf: 'start',
  },
  unselectedContact: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#F5F5F5',
  },
  imageContainer: {
    display: 'flex',
    height: 'fit-content',
    width: 'fit-content',
  },
});

export default styles;
