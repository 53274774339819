import { gridContainer } from "../appHealth.style"

const styles = theme => ({
  root: {
    ...gridContainer,
    overflow: "hidden",
  },
  
  rootWithoutSidebar: {
    display: "block",
    height: '100%'
  },
  
  panelLeft: {
    padding: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0px 10px 15px rgb(0 0 0 / 3%), 0px 7px 25px rgb(38 172 169 / 4%), 0px 5px 25px rgb(38 172 169 / 4%)',
  },
  
  submenuItens:{
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  
  titleHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
});

export default styles;
