export const styles = {
  dialog: {
    width: "max-content",
    zIndex: 1400,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: 16,

    padding: "32px",

    border: "1px solid rgba(0, 0, 0, 0.1)",

    height: "370px",
    maxWidth: "770px",

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gridColumnGap: "32px",
  },

  dialogText: {
    padding: "24px",
    maxWidth: "336px",

    "& h3": {
      fontWeight: 700,
      fontSize: 24,
    },

    "& p": {
      fontWeight: 500,
      fontSize: 16,
      color: "#828282",
      whiteSpace: "pre-line",
    },
  },
};
