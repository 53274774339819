import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid/Grid";
import Colors from "../../template/Colors";
import classNames from 'classnames';

const style = theme => ({
    itemTitle: {
        color: Colors.commons.gray7,
        fontWeight: 400,
        fontSize: '12px',
        fontFamily: 'Poppins !important',
        marginBottom: 4,
    },
    boldText: {
        fontSize: '14px !important',
        fontWeight: '700'
    }
});

const ItemGrid = (props) => {
    const {label, classes, size, children, bold, style} = props;

    return  <Grid item xs={size} className={classes.item} style={style}>
                <div className={classNames([classes.itemTitle, bold ? classes.boldText : null])}>{label}</div>
                {children}
            </Grid>
};

export default withStyles(style)(ItemGrid);