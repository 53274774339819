import { action, observable } from "mobx";

const defaultAuditoriaProps = {
    auditorias: [],
    auditoria: "",
    lastPage: false,
    auditoriaTela: "",
    withWhomModified: false,
    pageNumber: 0
};

const defaultNotification = {
    isOpen: false,
    message: "",
};

const defaultLoad = {
    query: "",
    variableName: "",
    variableId: null,
};

export const tabs = {
    INFORMACOES: 0,
    AUDITORIA: 1,
}

export default class AuditoriaStore {
    @observable auditoriaProps = defaultAuditoriaProps;
    @observable notification = defaultNotification;
    @observable load = defaultLoad;
    @observable isLoading = false;

    @action async loadAuditItems() {
        try {
            this.isLoading = true;
            const nextPage = this.auditoriaProps.pageNumber + 1;

            const response = await this.load.query({
                [this.load.variableName]: this.load.variableId,
                pageableDTO: {
                    pageSize: 10,
                    pageNumber: this.auditoriaProps.pageNumber,
                },
            })

            this.auditoriaProps.lastPage = response.last;
            this.auditoriaProps.auditorias = [...this.auditoriaProps.auditorias, ...response?.content];
            this.auditoriaProps.pageNumber = nextPage;

        } catch (error) {
            this.notification = {
                isOpen: true,
                message: `Erro ao carregar as auditorias.`,
            }
        } finally {
            this.isLoading = false;
        }
    };

    @action reset = () => {
        this.auditoriaProps = defaultAuditoriaProps;
    };

    @action closeAlertMessage = () => {
        this.notification = defaultNotification;
    };

    @action showAlertMessage = (notification) => {
        this.notification = notification;

        const timeoutId = setTimeout(() => {
            this.closeAlertMessage();
            clearTimeout(timeoutId);
        }, 3000);
    };

}
