import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const UrgenteIcon = (props) => {
  return (
    <svg
      width="3"
      height="10"
      viewBox="0 0 3 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M1.5 9.5C1.25833 9.5 1.05208 9.41458 0.88125 9.24375C0.710417 9.07292 0.625 8.86667 0.625 8.625C0.625 8.38333 0.710417 8.17708 0.88125 8.00625C1.05208 7.83542 1.25833 7.75 1.5 7.75C1.74167 7.75 1.94792 7.83542 2.11875 8.00625C2.28958 8.17708 2.375 8.38333 2.375 8.625C2.375 8.86667 2.28958 9.07292 2.11875 9.24375C1.94792 9.41458 1.74167 9.5 1.5 9.5ZM0.625 6.5V0.5H2.375V6.5H0.625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UrgenteIcon;
