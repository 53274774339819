import React, { useEffect } from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import classNames from 'classnames'

import { InputDateForm } from '../../../../../components/Modal/Input'
import InputSelectComLabel from '../../../../../components/Input/InputSelectComLabel'
import InputMaskCurrency from '../../../../../components/Input/InputMaskCurrency'

import styles from '../GuiaDeConsultaModalStyles'

const StepAtendimento = props => {
  const { classes, guiaConsulta, handleChangeGuiaConsulta, listaSelectStepAtendimento } = props

  const handleChangeTipoAtendimento = (field, value) => {
    handleChangeGuiaConsulta(field, value)
    handleChangeGuiaConsulta('tuss52TerminologiaTipoConsulta', value.tuss52TerminologiaTipoConsulta)
    handleChangeGuiaConsulta('tuss22ProcedimentoEventoSaude', value.tuss22ProcedimentoEventoSaude)

    const valorProcedimento =
      guiaConsulta.convenio?.convenioAgendamentoTipo &&
      guiaConsulta.convenio?.convenioAgendamentoTipo.find(
        item => item.agendamentoTipo.id === value.id
      )
    handleChangeGuiaConsulta('valorProcedimento', valorProcedimento?.valor)
  }

  const getTipoConsulta = () => {
    if (!guiaConsulta.tuss52TerminologiaTipoConsulta) return ''
    return `${guiaConsulta.tuss52TerminologiaTipoConsulta?.codigoTermo} - ${guiaConsulta.tuss52TerminologiaTipoConsulta?.descricao}`
  }

  const getProcedimentoEventoSaude = () => {
    if (!guiaConsulta.tuss22ProcedimentoEventoSaude) return ''
    return `${guiaConsulta.tuss22ProcedimentoEventoSaude?.codigoTermo} - ${guiaConsulta.tuss22ProcedimentoEventoSaude?.descricao}`
  }

  useEffect(() => {
    if (!guiaConsulta?.indicacaoAcidente) {
      const { findAllTuss36TerminologiaIndicadorAcidente } = listaSelectStepAtendimento
      const defaultIndicacaoAcidente = findAllTuss36TerminologiaIndicadorAcidente?.content?.find(
        item => item.codigoTermo === '9'
      )
      handleChangeGuiaConsulta('indicacaoAcidente', defaultIndicacaoAcidente)
    }
  }, [])

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Typography className={classes.typography} component='label'>
            Data
          </Typography>
          <InputDateForm
            iconposition='end'
            placeholder='00/00/0000'
            className={classes.inputDateForm}
            classes={{
              date: classes.inputData,
              input: classes.inputColor,
            }}
            value={guiaConsulta?.dataAtendimento || ''}
            onChange={value =>
              handleChangeGuiaConsulta(
                'dataAtendimento',
                value && moment(value).format('YYYY-MM-DD')
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <InputSelectComLabel
            label='Indicação acidente'
            placeholder='Selecione'
            className={classes.inputContainer}
            value={guiaConsulta?.indicacaoAcidente}
            onChange={value => handleChangeGuiaConsulta('indicacaoAcidente', value)}
            isSelect
            options={
              listaSelectStepAtendimento?.findAllTuss36TerminologiaIndicadorAcidente?.content || []
            }
            getOptionLabel={option => `${option.codigoTermo} - ${option.termo}`}
            getOptionValue={option => option.id}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <InputSelectComLabel
            label='Tipo atendimento'
            placeholder='Selecione'
            className={classes.inputContainer}
            value={guiaConsulta?.agendamentoTipo}
            onChange={value => handleChangeTipoAtendimento('agendamentoTipo', value)}
            isSelect
            options={listaSelectStepAtendimento.findAllAgendamentoTipo || []}
            getOptionLabel={option => option.descricao}
            getOptionValue={option => option.id}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typography} component='label'>
            Tipo consulta
          </Typography>
          <div className={classNames(classes.codProcedimento, classes.contentTipoConsulta)}>
            <span style={{ padding: '0.5rem' }}>{getTipoConsulta()}</span>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Typography className={classes.typography} component='label'>
            Código procedimento
          </Typography>
          <div className={classes.codProcedimento}>
            <span style={{ padding: '0.5rem' }}>{getProcedimentoEventoSaude()}</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.typography} component='label'>
            Valor procedimento
          </Typography>
          <InputMaskCurrency
            classes={{
              input: classNames(classes.inputContainer, classes.inputValorProcedimento),
            }}
            placeholder='R$'
            value={guiaConsulta?.valorProcedimento || ''}
            onChange={e => handleChangeGuiaConsulta('valorProcedimento', e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(StepAtendimento)
