import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import string from "../../../utils/string";
import AnexoIcon from "../../../components/Icon/Anexo";
import {
  anexarSolicitacoesPaciente,
  descartarSolicitacoesPaciente,
} from "../../../services/SolicitacoesPacientesService";
import DeleteIcon from "../../../components/Icon/DeleteIcon";
import SuccessIcon from "../../../components/Icon/SuccessIcon";
import WarningIcon from "../../../components/Icon/WarningIcon";
import ButtonLong from "../../../components/Button/ButtonLong";
import PopperCustomMenu from "../../../components/Popper/PopperCustomMenu";
import { propsModalConfirmacaoDefault } from "../../../stores/SolicitacoesPacientes.store";
import { createAnamnese, descartarAnamnese } from "../../../services/SolicitacoesProntuarioService";
import { menuOpcoes } from "./utils";
import MoreIcon from "../../../components/Icon/MoreIcon";

const ButtonAcaoEmAberto = observer(
  ({ classes, solicitacoesPacientesStore, solicitacao }) => {

    const isTipoAnamnese = solicitacao.arquivoTipo === "ANAMNESE";

    const closeModal = () => {
      solicitacoesPacientesStore.propsModalConfirmacao = propsModalConfirmacaoDefault;
    };

    const criarAnamnese = async () => {
      const dadosAnamnese = await solicitacoesPacientesStore.getDadosAnamnese(solicitacao.id);
      const perguntas = dadosAnamnese.perguntas.map(item => {
        const respostas = item.respostas.map(resposta => {
          delete resposta.valorConvertido;
          return resposta
        });

        return {
          ...item,
          respostas
        }
      });

      await createAnamnese(
        {
          agendamentoId: dadosAnamnese.agendamento.id,
          anamnesePaciente: {
            id: dadosAnamnese.id,
            nome: dadosAnamnese.nome,
            perguntas: perguntas,
          }
        }
      )
    };

    const anexarSolicitacoes = async () => {
      await anexarSolicitacoesPaciente(
        {
          pacienteAnexoId: solicitacao.id,
          agendamentoId: solicitacao.agendamento.id,
        },
        string.capitalize(solicitacao.arquivoTipo)
      );
    }

    const confirmaAcao = async (acao) => {
      try {
        const { propsModalConfirmacao } = solicitacoesPacientesStore;

        solicitacoesPacientesStore.propsModalConfirmacao = {
          ...propsModalConfirmacao,
          buttons: {
            ...propsModalConfirmacao.buttons,
            disabledPrimaryButton: true,
            disabledSecondButton: true,
          },
        };

        if (acao === "anexado") {
          isTipoAnamnese ? await criarAnamnese() : await anexarSolicitacoes();
        } else {
          isTipoAnamnese ? await descartarAnamnese({ anamneseId: solicitacao.id }) : await descartarSolicitacoesPaciente(solicitacao.id);
        }

        solicitacoesPacientesStore.propsModalConfirmacao = {
          open: true,
          icon: <SuccessIcon />,
          color: "green",
          title: `Documento ${acao} com sucesso!`,
          buttons: false,
        };

        setTimeout(() => {
          closeModal();
        }, 2000);

        solicitacoesPacientesStore.loadSolicitacaoPaciente(true);
        solicitacoesPacientesStore.getQuantidadeSolicitacoes();
        solicitacoesPacientesStore.getQuantidadeSolicitacoesPaciente();
      } catch (e) {
        console.error(e);
      }
    };

    const openModalConfirmacao = (acao) => {
      solicitacoesPacientesStore.propsModalConfirmacao = {
        open: true,
        icon: <WarningIcon />,
        color: "yellow",
        buttons: {
          labelPrimaryButton: "Cancelar",
          handlePrimaryButton: () => closeModal(),
          labelSecondButton: "Continuar",
          handleSecondButton: () => confirmaAcao(acao),
        },
        children: (
          <div className={classes.contentMensagem}>
            Essa ação não podera ser desfeita, você deseja continuar?{" "}
          </div>
        ),
      };
    };

    const menuConfig = {
      isTipoAnamnese,
      solicitacao,
      solicitacoesPacientesStore, 
    }   

    return (
      <div className={classes.contentButtonAcao}>
        <PopperCustomMenu 
          placement={"bottom-end"} 
          menuOpcoes={menuOpcoes(menuConfig)}
          iconButton={<MoreIcon color="#fff" />}
        />
        <ButtonLong
          className={classes.buttonAcao}
          colorCustom="green"
          onClick={() => openModalConfirmacao("anexado")}
          noShadow
        >
          <AnexoIcon />
          <div> Prontuário </div>
        </ButtonLong>
        <ButtonLong
          className={classes.buttonAcao}
          colorCustom="red"
          onClick={() => openModalConfirmacao("descartado")}
          noShadow
        >
          <DeleteIcon />
          Descartar
        </ButtonLong>
      </div>
    );
  }
);

const styles = {
  contentButtonAcao: {
    display: "flex",
    gap: "10px",
    maxWidth: "300px",
    alignItems: "center",

    "& #buttonCircle": {
      minWidth: "40px",
    },
  },
  buttonAcao: {
    height: "40px",
    "& span": {
      display: "flex",
      gap: "8px",
    },
  },
  widthButton: {
    maxWidth: "130px",
  },
  contentMensagem: {
    fontWeight: 600,
    fontSize: "18px",
    textAlign: "center",
  },
};

const ButtonAcaoEmAbertoWithStyles = withStyles(styles)(ButtonAcaoEmAberto);
export default inject("solicitacoesPacientesStore")(
  ButtonAcaoEmAbertoWithStyles
);
