import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { withStyles, CircularProgress, Switch, FormControlLabel, FormGroup } from "@material-ui/core";
import Button from "../../../components/Button/Button";
import { buildUrlFotoPerfil } from "../../../config/config";
import ButtonWithFileDialog from "../../../components/Button/ButtonWithFileDialog";
import ColorCustomPopper from "./ColorCustomPopper";
import { inject } from "mobx-react";
import styled from "styled-components";
import CardDadosCadastrais from "../../../components/Cards/CardDadosCadastrais";
import { observer } from "mobx-react-lite";
import { getWhitelabelNome } from "../../../services/WhitelabelService";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  containerWithBottomSpacing: {
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
  },
  title: {
    fontSize: "16px !important",
    fontWeight: "700",
    color: "#5F6368",
    lineHeight: "24px",
  },
  titleContainer: {
    color: "#5F6368",
    fontWeight: 500,
    fontSize: "14px",
  },
  text: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#868686",
  },
  warning: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#868686",
  },
  profileContainer: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
  },
  profileImage: {
    height: "120px",
    width: "120px",
    borderRadius: "100%",
    border: "solid 2px #26ACA9",
  },
  bannerContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  bannerImage: {
    height: "150px",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
  },
  contentColorCustom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "4px 8px",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    width: "calc(100% - 18px)",
    height: "22px",
    justifyContent: "space-between",
  },
  containerColor: {
    flexDirection: "column",
    rowGap: '0px',
  },
  containerCustomColor: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  wrapperResetColor: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  buttonResetColor: {
    border: "none",
    backgroundColor: "transparent",
    color: "#5F6368",
    fontWeight: 700,
    fontSize: "0.625rem",
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: 0.8,
    },
  },
  contentColumnCustom: {
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  controller: {
    width: '100%',
    justifyContent: 'space-between',
    marginLeft: 0,
  },
  cardText: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: '#5F6368',
  },
};

const buttonsStyles = {
  addImage: {
    height: "36px",
    borderRadius: "100px",
  },
  changeImage: {
    backgroundColor: "#707C97",
    height: "36px",
    borderRadius: "100px",
    width: "50%",
  },
  removeImage: {
    backgroundColor: "#FB7676",
    height: "36px",
    borderRadius: "100px",
    width: "50%",
  },
};

const DivColorCustom = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background-color: ${(props) => props.colorSelecionada};
`;

const ProfileImageAndBanner = observer(({
  profileImageId,
  bannerImageId,
  updateProfile,
  updateBanner,
  removeProfileImage,
  removeBanner,
  classes,
  hideProfile,
  token,
  loadingProfileImage,
  loadingBannerImage,
  disabled,
  corPersonalizada,
  field,
  perfilPublicoStore,
  cardFotoPerfilUnidade,
}) => {
  const [showPopperColorCustom, setShowPopperColorCustom] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState(null);
  const [colorSelecionada, setColorSelecionada] = useState("");
  const [whitelabelNome, setWhitelabelNome] = useState("");

  const { habilitaCorPersonalizado } = perfilPublicoStore;

  useEffect(() => {
    setColorSelecionada(corPersonalizada || "");
    perfilPublicoStore.habilitaCorPersonalizado=!!corPersonalizada;

    (async () => {
      const res = await getWhitelabelNome();
      setWhitelabelNome(res);
    })()
  }, []);

  const renderButtons = (type) => (
    <div className={classes.buttonsContainer}>
      <Button
        style={buttonsStyles.removeImage}
        onClick={type === "profile" ? removeProfileImage : removeBanner}
        disabled={disabled}
      >
        Excluir
      </Button>
      <ButtonWithFileDialog
        style={buttonsStyles.changeImage}
        text="Alterar"
        onLoadFile={type === "profile" ? updateProfile : updateBanner}
        disabled={disabled}
      />
    </div>
  );

  const renderAdicionarButton = (type) => (
    <ButtonWithFileDialog
      style={buttonsStyles.addImage}
      text="Adicionar Imagem"
      onLoadFile={type === "profile" ? updateProfile : updateBanner}
      disabled={disabled}
    />
  );

  const openColorCustomPopper = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setShowPopperColorCustom((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleCorSelecionada = () => {
    perfilPublicoStore[field] = {
      ...perfilPublicoStore[field],
      corPersonalizada: colorSelecionada,
    };
    setShowPopperColorCustom(false);
  };

  const resetColor = () => {
    perfilPublicoStore[field] = {
      ...perfilPublicoStore[field],
      corPersonalizada: "",
    };
    setColorSelecionada("");
  };

  const handleChangeColor = (color, event) => {
    setColorSelecionada(color.hex);
  };

  const handleSwitchChange = () => {
    perfilPublicoStore.habilitaCorPersonalizado = !perfilPublicoStore.habilitaCorPersonalizado;
  };

  return (
    <div className={classes.contentColumnCustom}>
      { !cardFotoPerfilUnidade?.hidden && <CardDadosCadastrais titulo="Customização do perfil">
        <div
          className={classnames(
            classes.container,
            classes.containerColor
          )}
        >
          <FormGroup className={classes.switchContainer}>
            <FormControlLabel
              control={
                <Switch
                checked={habilitaCorPersonalizado}
                color="primary"
                />
              }
              onChange={handleSwitchChange}
              label="Personalizar cor do perfil"
              labelPlacement='start'
              className={classes.controller}
            />
          </FormGroup>
         { habilitaCorPersonalizado && <div className={classes.containerCustomColor}>
            <div
              className={classes.contentColorCustom}
              onClick={openColorCustomPopper("bottom-end")}
            >
              {corPersonalizada || "Cor Padrão"}
              <DivColorCustom colorSelecionada={corPersonalizada} />
            </div>
            {showPopperColorCustom && (
              <ColorCustomPopper
                open={showPopperColorCustom}
                anchorEl={anchorEl}
                placement={placement}
                handleCorSelecionada={handleCorSelecionada}
                resetColor={resetColor}
                handleChangeColor={handleChangeColor}
                color={colorSelecionada}
                closePopper={() => setShowPopperColorCustom(false)}
              />
            )}
          </div>}
        </div>
      </CardDadosCadastrais>}
      <CardDadosCadastrais titulo={cardFotoPerfilUnidade?.title ? cardFotoPerfilUnidade?.title : "Imagem Principal de Perfil"}>
        {!hideProfile && (
          <div
            className={classnames(
              classes.container,
              !cardFotoPerfilUnidade?.informacao && classes.containerWithBottomSpacing
            )}
          >
            { cardFotoPerfilUnidade?.informacao ?
              <div className={classes.cardText}>
                { cardFotoPerfilUnidade?.informacao }
              </div>
            :
              <div className={classes.textContainer}>
                <span className={classes.titleContainer}>
                  Foto do Perfil Público
                </span>
                <span className={classes.text}>Selecione sua melhor foto</span>
              </div>}
            {profileImageId ? (
              <>
                <div className={classes.profileContainer}>
                  {loadingProfileImage && <CircularProgress />}
                  {!loadingProfileImage && profileImageId && (
                    <img
                      className={classes.profileImage}
                      src={buildUrlFotoPerfil(profileImageId, token)}
                      alt={`Foto perfil do ${whitelabelNome}`}
                    />
                  )}
                </div>
                {renderButtons("profile")}
              </>
            ) : (
              renderAdicionarButton("profile")
            )}
          </div>
        )}
      </CardDadosCadastrais>
      {!cardFotoPerfilUnidade?.hidden && <CardDadosCadastrais titulo="Banner do Perfil Público">
        <div className={classes.container}>
          <div className={classes.textContainer}>
            <span className={classes.warning}>
              Deve ter no mínimo 400 pixels de largura e 150 pixels de altura
            </span>
          </div>
          {bannerImageId ? (
            <>
              <div className={classes.bannerContainer}>
                {loadingBannerImage && <CircularProgress />}
                {!loadingBannerImage && bannerImageId && (
                  <img
                    className={classes.bannerImage}
                    src={buildUrlFotoPerfil(bannerImageId, token)}
                    alt={`Imagem do banner do perfil ${whitelabelNome}`}
                  />
                )}
              </div>
              {renderButtons("banner")}
            </>
          ) : (
            renderAdicionarButton("banner")
          )}
        </div>
      </CardDadosCadastrais>}
    </div>
  );
});

const ProfileImageAndBannerWithStyles = withStyles(styles)(
  ProfileImageAndBanner
);
export default inject("perfilPublicoStore")(ProfileImageAndBannerWithStyles);
