import React from "react";
import { View, StyleSheet, Text} from "@react-pdf/renderer";

const Header = ({ titulo, numeroGuiaPrestador }) => (
    <View style={styles.container}>
        <View style={styles.tituloContainer}>
            <Text style={styles.titulo}>{titulo}</Text>
        </View>
        <View style={styles.numeroGuiaPrestadorContainer}>
            <Text style={styles.numeroGuiaPrestadorLabel}>2 - Nº Guia do Prestador</Text>
            <Text style={styles.numeroGuiaPrestador}>{numeroGuiaPrestador}</Text>
        </View>
    </View>
);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingBottom: 2.5,
        width: '100%',
        justifyContent: 'flex-end'
    },
    tituloContainer: {
        width: '40%',
        paddingRight: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    numeroGuiaPrestadorContainer: {
        width: '30%',
    },
    titulo: {
        fontSize: 9,
        fontFamily: "Lato-Bold",
        textAlign: 'center'
    },
    numeroGuiaPrestadorLabel: {
        fontSize: 6,
        fontFamily: "Lato-Bold",
        marginTop: 10,
    },
    numeroGuiaPrestador: {
        fontSize: 8,
        fontFamily: "Lato-Regular",
    }
});

export default Header;
