export const ATENDIMENTO_MODAL_ETIQUETAS_SIZE = {
  NEONATAL: {
    contentHeight: 18,
    contentWidth: 87
  },
  INFANTIL: {
    contentHeight: 22,
    contentWidth: 77
  },
  ADULTO: {
    contentHeight: 22,
    contentWidth: 119
  }
}

export const ATENDIMENTO_MODAL_ETIQUETAS_OPTIONS = [
  {name: 'Neonatal', value: 'NEONATAL'},
  {name: 'Infantil', value: 'INFANTIL'},
  {name: 'Adulto', value: 'ADULTO'},
]