export const verificaCamposVazioPaciente = (sujeitoSelected, guiaServico) => {
  return (
    !sujeitoSelected?.nome ||
    !guiaServico?.convenio?.descricao ||
    !guiaServico?.beneficiario?.numeroCarteira ||
    !guiaServico?.beneficiario?.atendimentoRN
  )
};

export const verificaCamposVazioTabInterna = (solicitante, guiaServico) => {
  return (
    !solicitante.codigoCbo ||
    !solicitante.codigoConselho ||
    !guiaServico?.convenio?.codigoUnidade ||
    !solicitante.conselhoProfissionalSaude ||
    !solicitante.nomeContratado ||
    !solicitante.numeroConselho ||
    !solicitante.profissional ||
    !solicitante.tuss24TerminologiaCBO ||
    !solicitante.estado
  )
};

export const verificaCamposVazioTabInternaProfissional = (solicitante) => {
  return (
    !solicitante.codigoCbo ||
    !solicitante.codigoConselho ||
    !solicitante.conselhoProfissionalSaude ||
    !solicitante.numeroConselho ||
    !solicitante.profissional ||
    !solicitante.tuss24TerminologiaCBO ||
    !solicitante.estado
  )
};