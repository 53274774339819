import React, { useRef, useState } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid/Grid'
import { InputForm } from '../../components/Input/InputDadosCadastraisPaciente'

import PreviewFile from './PreviewFile'
import PopperCustomMenu from '../Popper/PopperCustomMenu'
import MoreIcon from '../Icon/More'
import { menuOpcoesPopper } from './utils/menuOpcoesPopper'

const style = theme => ({
  listItem: {
    marginBottom: '5px',
    background: '#fff',
    borderRadius: '10px',
    padding: '17px 15px',
    margin: '8px 23px',
  },
  titulo: {
    color: '#868686',
    fontSize: '12px',
    fontWeight: '400',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconBaixar: {
    height: 20,
    width: 20,
  },
  icon: {
    height: 23,
    width: 23,
  },
  button: {
    cursor: 'pointer',
    width: '28px',
    textAlign: 'center',
    borderRadius: '100px',
  },
  rowArquivo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelArquivo: {
    background: '#F2F2F2',
    borderRadius: '8px',
    width: '100%',
    padding: '6px 10px',
  },
  labelDescricao: {
    background: '#F2F2F2',
    borderRadius: '8px',
    marginRight: '15px',
    padding: '3px 10px',
    marginBottom: 10,
  },
  labelInputDescricao: {
    background: '#F2F2F2',
    padding: '0 10px',
    borderRadius: 8,
    width: '93%',
    '& textArea': {
      padding: 0,
    },
  },
  labelInputArquivo: {
    width: '100%',
    '& input': {
      borderBottom: 0,
    },
  },
})

const ItemListArquivosEnviar = props => {
  const {
    nomeArquivo,
    descricao,
    classes,
    size,
    baixarArquivo,
    onNomeChange,
    handleRemoveArquivo,
    onDescricaoChange,
    item,
  } = props

  const [showPreview, setShowPreview] = useState(false)
  const popperRef = useRef(null)

  const handleShowPreview = () => {
    setShowPreview(!showPreview)
  }

  return (
    <Grid item xs={size} className={classes.listItem}>
      <div className={classes.content}>
        {showPreview ? (
          <PreviewFile item={item} handleClose={handleShowPreview} />
        ) : (
          <>
            <div className={classes.titulo}> Nome do arquivo </div>
            <div className={classes.rowArquivo}>
              <div className={classes.labelArquivo}>
                <InputForm
                  id='nome'
                  placeholder='Digite o nome do arquivo'
                  value={nomeArquivo}
                  className={classes.labelInputArquivo}
                  onChange={e => onNomeChange(e)}
                />
              </div>
              <PopperCustomMenu
                placement={'bottom-end'}
                ref={popperRef}
                iconButton={<MoreIcon color='#505050' />}
                colorButton={{
                  background: '#fff',
                }}
                menuOpcoes={menuOpcoesPopper({
                  functions: {
                    handleShowPreview,
                    baixarArquivo,
                    handleRemoveArquivo,
                    closePopper: popperRef?.current?.closePopper,
                  },
                })}
              />
            </div>
            <div className={classes.titulo}> Descrição </div>
            <InputForm
              id='descricao'
              placeholder='Digite a descrição do arquivo'
              value={descricao}
              className={classes.labelInputDescricao}
              multiline
              onChange={e => onDescricaoChange(e)}
            />
          </>
        )}
      </div>
    </Grid>
  )
}

export default withStyles(style)(ItemListArquivosEnviar)
