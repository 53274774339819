import React from 'react';
import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles/index";
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';
import Colors from "../../template/Colors";


const style = theme => ({
    root: {
        width: '100%',
        fontFamily: "Poppins!important",

        '&.width-auto': {
            width: 'auto',
        }
    },
    select: {
        fontSize: '14px',
        fontFamily: 'Poppins !important',
        border: '0',
        minHeight: '6px',
        maxHeight: '26px',
        color: Colors.commons.fontColor,
        backgroundColor: Colors.commons.gray2,
        margin: '0 0 0 0',
        padding: '10px 0px 4px 18px',
        borderRadius: '100px',
        textAlign: 'start',
        paddingLeft: 18,
        height: 26,
        minWidth: 100,

        '&:focus': {
            borderRadius: '100px',
            borderColor: Colors.primary.main,
            color: Colors.commons.darkGray,
            backgroundColor: Colors.commons.gray2,
        },
        '& div:focus': {
            backgroundColor: 'transparent',
        },
        
        '&.hide-borders': {
            borderColor: 'transparent',
            paddingRight: 30,
            lineHeight: 'normal'
        },
        
        '&.primary': {
            color: Colors.primary.main
        }
    },
    inputLabel: {
        color: Colors.primary.main,
        lineHeight: '15px',
        transition: 'none',
        transform: 'none',
    },
    menuItem: {
        lineHeight: '17px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        color: Colors.commons.fontColor,
        paddingBottom: '5px'
    },

    itemLabel: {
        paddingRight: '10px',
        color: Colors.commons.fontColor
    },

    icon: {
        backgroundColor: Colors.primary.main,
        color: Colors.commons.white,
        top: 'calc(50% - 10px)',
        fontSize: '24px',
        borderRadius: '50%',
        right: 6
    },
    status: {
        display: 'inline-box',
        marginTop: '1px',
        width: '15px',
        minWidth: '15px',
        height: '15px',
        borderRadius: '50%'
    },
    statusLabel: {
        marginLeft: '5px',
        marginTop: '2px',
        fontFamily: 'Poppins !important',
    },
    itemSelectedStatus: {
        display: 'inline-flex',
        alignItems: 'center',
        width: '65%'
    }
});

export const SelectForm = props => {
    const {classes, elements, allowEmpty, labelClear, labelOption, hideBorders, selectedItemColor, formControlProps, ...other} = props;

    return <FormControl {...formControlProps} 
                        className={classNames(classes.root, hideBorders ? 'width-auto' : null, formControlProps.className)}
            >
        {props.label && 
            <InputLabel
                disableAnimation={true}
                classes={{ root: classes.inputLabel }}
                htmlFor={props.name}>
                {props.label}
            </InputLabel>
        }

        <Select
            {...other}
            disableUnderline={true}
            classes={{
                root: classNames(classes.root, {'width-auto': hideBorders}),
                selectMenu: classNames(classes.select, {'hide-borders' : hideBorders, selectedItemColor})
            }}
            IconComponent={props =>
                <i {...props} className={`material-icons ${props.className} ${classes.icon}`}>
                    arrow_drop_down
                </i>
            }
            inputProps={{
                name: props.name,
                id: props.id,
            }}>
            {allowEmpty && <MenuItem className={classes.menuItem} key={"none"} value="" />}

            {
            props.labelOption && 
                <MenuItem className={classes.menuItem} key={"none"} value="none" disabled={!labelClear} > 
                    {props.labelOption}
                </MenuItem>
            }

            {elements.map(item =>
                <MenuItem className={classes.menuItem} key={item.value} value={item.value}>
                    {item.cor ?
                        <div className={classes.itemSelectedStatus}>
                            <div className={classes.status} style={{backgroundColor: Colors.commons[item.cor]}} />
                            <div className={classes.statusLabel}>{item.name}</div>
                        </div>
                        :  item.name
                    }
                </MenuItem>
            )}
        </Select>
    </FormControl>
};

SelectForm.defaultProps = {
    value: '',
    hideBorders: false,
    selectedItemColor: null,
    formControlProps: {
        className: null,
    }
};

export default withStyles(style)(SelectForm);
