import React from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import DocumentPillIcon from '../../../components/Icon/DocumentPillIcon'
import DocumentTextIcon from '../../../components/Icon/DocumentTextIcon'
import InfoIconFilled from '../../../components/Icon/InfoIconFilled'

const MinimizedTaskbar = ({
  classes,
  onOpenReceita,
  isReceitaMinimized,
  isDocumentoMinimized,
  indexSelected,
  onOpenDocumento,
  isAnamneseMinimized,
  onOpenAnamnese,
}) => {
  const options = [
    {
      icon: DocumentPillIcon,
      title: 'Receituario',
      visible: isReceitaMinimized,
      onClick: onOpenReceita,
    },
    {
      icon: DocumentTextIcon,
      title: 'Documento',
      visible: isDocumentoMinimized,
      onClick: onOpenDocumento,
    },
    {
      icon: DocumentTextIcon,
      title: 'Anamnese',
      visible: isAnamneseMinimized,
      onClick: onOpenAnamnese,
    }
  ]

  const taskbarVisible = options.some(item => item.visible)

  if (!taskbarVisible) return null
  return (
    <div className={classes.wrapperTaskbar}>
      <div className={classes.container}>
        {options.map((item, index) => {
          if (!item.visible) return null
          return (
            <button
              key={index}
              className={classNames(
                classes.option,
                indexSelected === index && classes.optionSelected
              )}
              onClick={() => item.onClick(index)}
            >
              <item.icon />
              <span>{item.title}</span>
            </button>
          )
        })}
      </div>

      <div className={classes.notification}>
        <InfoIconFilled size={24} />
        <span>
          <strong>Aviso:</strong> Ao sair do prontuário, sem finalizar seus documentos os mesmos não
          serão salvos.
        </span>
      </div>
    </div>
  )
}

const styles = theme => ({
  wrapperTaskbar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#3DC4BE',
    overflow: 'hidden',
  },
  container: {
    flex: 2,
    display: 'flex',
    gap: '8px',
    maxWidth: '80%',
    overflowX: 'auto',
    padding: '8px 16px',
    background: '#3DC4BE',
  },
  notification: {
    flex: 1,
    height: '100%',
    background: '#FCEDEF',
    borderRadius: '8px 0px 0px 8px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    gap: '4px',
    color: '#505050',
    fontWeight: '400',
    '& svg': {
      flex: 1,
    },
    '& span': {
      flex: 9,
      fontSize: '12px',
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: '4px',
    width: 'fit-content',
    height: 'fit-content',
    background: '#fff',
    padding: '8px 16px',
    borderRadius: '12px',
    border: '1px solid rgba(33, 154, 151, 0.8)',
    color: '#505050',
    '& span': {
      fontSize: '14px',
      fontWeight: '600',
      color: '#505050',
    },
  },
  optionSelected: {
    background: '#21A8A7',
    color: '#fff',
    '& span': {
      color: '#fff',
    },
  },
})

export default withStyles(styles)(MinimizedTaskbar)
