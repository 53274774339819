import React from "react";
import moment from "moment";
import debounce from "lodash.debounce";
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";
import {Link} from "react-router-dom";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import styles from "../../../assets/jss/pages/listarHorarioAtendimentoStyle";
import ButtonFloat from "../../../components/ButtonFloat";
import FiltroHeader from "../../../template/Header/FiltroHeader";
import HeaderConfiguracoes from "../../../components/Header/HeaderConfiguracoes";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";

const columns = [
    {
        Header: "Tempo de atendimento",
        getValue: row => {
            return row.duracao ? `${row.duracao} minuto${parseInt(row.duracao) > 1 ? 's' : ''}` : '-'
        },
        props: {center: "true"}
    },
    {
        Header: "Horário Início",
        getValue: row => {
            return row.horaInicioPeriodoAtendimento || '-'
        },
        props: {center: "true"}
    },
    {
        Header: "Horário Fim",
        getValue: row => {
            return row.horaFimPeriodoAtendimento || '-'
        },
        props: {center: "true"}
    },
    {
        Header: "Começo Vigência",
        getValue: row => {
            return row.dataInicioVigencia ? moment(row.dataInicioVigencia).format('DD/MM/YYYY') : '-'
        },
        props: {center: "true"}
    },
    {
        Header: "Final Vigência",
        getValue: row => {
            return row.dataFimVigencia ? moment(row.dataFimVigencia).format('DD/MM/YYYY') : '-';
        },
        props: {center: "true"}
    }
];

@inject("configuracaoHorarioAtendimentoStore", "filtroHeaderStore", "googleAnalyticsStore")
@observer
class ListarHorarioAtendimento extends React.Component {
    constructor(props) {
        super(props);

        this.debounceConsulta = debounce(this.debounceConsulta, 500);
        this.state = {
            configSalas: false
        }
    }

    componentDidMount() {
        const {configuracaoHorarioAtendimentoStore, match} = this.props;
        const configSalas = match.path.search('salas');

        if (configSalas > 0) {
            const { sala } = this.props.filtroHeaderStore;
            if (sala instanceof Object ) {
                configuracaoHorarioAtendimentoStore.findAllConfiguracaoHorarioAtendimentoSala({pageNumber: 0});
            } 
            this.setState({configSalas: true});
        } else {
            configuracaoHorarioAtendimentoStore.findAll({pageNumber: 0});
        }
        const paginaVisualizada = {
            page: window.location.hash, 
            title: 'Configurações - Config. horário de atendimento'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);
    }

    componentWillUnmount() {
        const {configuracaoHorarioAtendimentoStore} = this.props;
        configuracaoHorarioAtendimentoStore.horarioAtendimentoList = [];
        configuracaoHorarioAtendimentoStore.resetSearchDTO();
    }

    debounceConsulta() {
        const {configuracaoHorarioAtendimentoStore} = this.props;
        const {configSalas} = this.state;

        if (configSalas) {
            configuracaoHorarioAtendimentoStore.findAllConfiguracaoHorarioAtendimentoSala({pageNumber: 0});
        } else {
            configuracaoHorarioAtendimentoStore.findAll({pageNumber: 0});
        }
    }

    handleSearchChange = e => {
        const {configuracaoHorarioAtendimentoStore} = this.props;
        const search = e.target.value;
        configuracaoHorarioAtendimentoStore.currentPage = null;
        configuracaoHorarioAtendimentoStore.searchDTO.pageNumber = 0;
        configuracaoHorarioAtendimentoStore.searchDTO.search = search;
        this.debounceConsulta();
    };

    loadMore = () => {
        const {configuracaoHorarioAtendimentoStore} = this.props;
        const {configSalas} = this.state;

        if (configSalas) {
            configuracaoHorarioAtendimentoStore.findAllConfiguracaoHorarioAtendimentoSala({pageNumber: 0});
        } else {
            configuracaoHorarioAtendimentoStore.findAll({pageNumber: configuracaoHorarioAtendimentoStore.searchDTO.pageNumber});
        }
    };

    handleItemClick = id => {
        const {history} = this.props;
        const {configSalas} = this.state;

        let configuracao = 'atendimento'
        if (configSalas) {
            configuracao = 'salas'
        }
        history.push(`horario-${configuracao}/${id}/editar`);
    };

    getPageTitle = () => {
        const title = window.location.hash.includes("atendimento") ? "Horário atendimento" : "Horário salas";

        return pageConfiguracoes(title)
    };

    verificaFiltroSelecionado = (configSalas) => {
        const { filtroHeaderStore } = this.props;

        return configSalas !== -1 ? !filtroHeaderStore.sala?.id : !filtroHeaderStore.profissionalSaude?.id;
    };

    render() {
        const {classes, configuracaoHorarioAtendimentoStore, filtroHeaderStore, match} = this.props;
        const configSalas = match.path.search('salas');
        const {
            loading,
            horarioAtendimentoList,
            last
        } = configuracaoHorarioAtendimentoStore;

        const isProfissionalSaudeSelecionado = configSalas > 0 ? !!filtroHeaderStore.sala : !!filtroHeaderStore.profissionalSaude ;

        const screen = configSalas > 0 ? "CONFIGURACAO_HORARIO_SALAS" : "CONFIGURACAO_HORARIO_ATENDIMENTO"

        return (
            <div className={classes.content}>
                <PageTitle title={this.getPageTitle()}/>
                <HeaderConfiguracoes>
                    <FiltroHeader screen={screen}/>
                </HeaderConfiguracoes>
                <div className={classes.tableContainer}>
                    {isProfissionalSaudeSelecionado ? (
                        <>
                            {!loading && horarioAtendimentoList.length === 0 && (
                                <div className={classes.notFoundContainer}>
                                    <h3>Nenhum item encontrado</h3>
                                </div>
                            )}
                            {horarioAtendimentoList.length > 0 && (
                                <Scroll
                                    loadMore={this.loadMore}
                                    hasMore={!last}
                                    pageStart={0}
                                    className={classes.scrollContainer}
                                >
                                    <Table
                                        dados={horarioAtendimentoList}
                                        columns={columns}
                                        clickable={true}
                                        handleClick={this.handleItemClick}
                                    />
                                </Scroll>
                            )}
                            {loading && horarioAtendimentoList.length === 0 && (
                                <div className={classes.notFoundContainer}>
                                    <CircularProgress/>
                                </div>
                            )}
                        </>
                    )
                    :
                        <div className={classes.notFoundContainer}>
                          <h3>{configSalas > 0 ? 'É necessário selecionar uma sala.' : 'É necessário selecionar um profissional de saúde.' }</h3>
                        </div>
                    }
                    <Link to={configSalas > 0 ? "horario-salas/criar" : "horario-atendimento/criar"}>
                        <ButtonFloat disabled={this.verificaFiltroSelecionado(configSalas)}/>
                    </Link>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ListarHorarioAtendimento);
