import React, { useReducer, useState } from 'react'
import { withStyles, Tooltip } from '@material-ui/core'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'

import { Button } from '../../../../../components/ui/Buttons'
import TempoAtendimento from '../../../TempoAtendimento'
import TeleconsultaButton from '../../../TeleconsultaButton'
import ModalLinkTelemedicina from '../ModalLinkTelemedicina'

import { getMensagemTeleconsulta } from '../../../utils/getDadosMensagem'
import { goToWhatsApp } from '../../../../../utils/goToWhatsApp'
import useMediaQuery from '../../utils/useMediaQuery'

import PlayIcon from '../../../../../components/Icon/Play'
import CallIcon from '../../../../../components/Icon/Call'
import CopyIcon from '../../../../../components/Icon/CopyIcon'
import StopIcon from '../../../../../components/Icon/StopIcon'
import { getViziWebUrl } from '../../../../../config/config'

const ActionsHeader = observer(
  ({
    classes,
    prontuarioStore,
    termoUsoStore,
    onPlayAgendamento,
    onStopAgendamento,
    eventoGA,
    toggleModalSolicitacoes,
    onCallAgendamento,
    atendimentoStore,
  }) => {
    const {
      agendamentoSelected,
      sujeitoAtencaoSelected,
      quantitySolicitacoes,
      timer,
      callingPaciente,
    } = prontuarioStore

    const [open, toggleModalTelemedicina] = useReducer(state => !state, false)
    const [disable, setDisable]=useState(false)
    const primeiroNomeSujeito =
      typeof sujeitoAtencaoSelected?.nome === 'string' && sujeitoAtencaoSelected.nome.split(' ')[0]

    const isLargeScreen = useMediaQuery('(min-width: 1620px)')
    const profissionalLogado = JSON.parse(
      localStorage[`_immortal|profissional_saude_logado`] || null
    )
    const atendimentoFinalizado = () => {
      if (!agendamentoSelected?.id) return false
      return !!(agendamentoSelected.dataInicioAtendimento && agendamentoSelected.dataFimAtendimento)
    }

    const isAtendimentoFinalizado = atendimentoFinalizado()

    const checkIsPlaying = () => {
      if (!agendamentoSelected?.id) return false
      return !!(
        agendamentoSelected.dataInicioAtendimento && !agendamentoSelected.dataFimAtendimento
      )
    }

    const isPlaying = checkIsPlaying()

    const handleCopyLinkToClipboard = async () => {
      const { prontuarioStore } = atendimentoStore
      const link = `${getViziWebUrl()}/telemedicina/${agendamentoSelected.chavePublica}`
      await navigator.clipboard.writeText(link)
      prontuarioStore.openNotification(
        'Link copiado com sucesso para a área de transferência',
        'success'
      )
    }

    const verificarTermosUsoAssinados = async () => {
      const termoUso = await termoUsoStore.buscarTermoUso('TELEMEDICINA')
      return termoUso
    }

    const renderLabelSolicitacoes = () => {
      if (isLargeScreen) return 'Solicitações do paciente'
      return 'Solicitações'
    }

    const handleClickWhatsappTeleconsulta = () => {
      const { prontuarioStore } = atendimentoStore
      const { agendamentoSelected } = prontuarioStore
      const dadosMensagem = {
        nomePaciente:
          agendamentoSelected.sujeitoAtencao?.nomeSocialOuNome || agendamentoSelected.nome,
        nomeFantasiaUnidade: agendamentoSelected.unidade?.nomeFantasia,
        nomeProfissional: agendamentoSelected.profissionalSaude?.nome,
        horaInicio: agendamentoSelected.horaInicio || ' - ',
        data: moment(agendamentoSelected.data).format('DD/MM/YYYY'),
        remoto: true,
        chavePublica: agendamentoSelected.chavePublica,
      }
      const mensagemTeleconsulta = getMensagemTeleconsulta(dadosMensagem)
      goToWhatsApp(
        agendamentoSelected.telefone,
        agendamentoSelected.telefoneDDI,
        mensagemTeleconsulta
      )
    }

    const toggleAtendimentoPlay = (e) => {
      if (!isPlaying) {
        onPlayAgendamento(e, agendamentoSelected)
        setDisable(true)
        setTimeout(() => {
          setDisable(false);
        }, 3000)
      } else {
        onStopAgendamento(e);
      }
    }

    return (
      <>
        <div className={classes.wrapperActions}>
          {sujeitoAtencaoSelected ? (
            <div className={classes.wrapperButtonGroup}>
              <Tooltip title={isPlaying ? 'Encerrar consulta' : 'Iniciar consulta'} placement='bottom'>
                <Button
                  kind='primary'
                  shape='circle'
                  size='default'
                  bgColor={isPlaying ? '#FB7676' : '#26ACA9'}
                  onClick={e => toggleAtendimentoPlay(e)}
                  disabled={disable||isAtendimentoFinalizado}
                >
                  {isPlaying ? <StopIcon size={16} /> : <PlayIcon size={22} /> }
                </Button>
              </Tooltip>
              <Button
                kind='primary'
                shape='pill'
                size='default'
                bgColor={isPlaying ? '#F9BE73' : '#868686'}
                style={{ whiteSpace: 'nowrap' }}
              >
                <div className={classes.wrapperTimeAtendimento}>
                  <TempoAtendimento
                    timer={timer}
                    isPlaying={isPlaying}
                    isAtendimentoFinalizado={isAtendimentoFinalizado}
                  />
                </div>
              </Button>
              {!agendamentoSelected?.remoto && (
                <Tooltip title={`Chamar ${primeiroNomeSujeito}`} placement='bottom'>
                  <Button
                    kind='primary'
                    shape='circle'
                    size='default'
                    bgColor='#F9BE73'
                    onClick={onCallAgendamento}
                    disabled={callingPaciente}
                  >
                    <CallIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          ) : null}
          {sujeitoAtencaoSelected && agendamentoSelected?.remoto && (
            <div className={classes.wrapperButtonGroup}>
              <TeleconsultaButton
                agendamento={agendamentoSelected}
                sujeitoAtencao={sujeitoAtencaoSelected}
                verificarTermosUsoAssinados={verificarTermosUsoAssinados}
                eventoGA={eventoGA}
              />
              <Button
                bgColor='#F2F2F2'
                color='#505050'
                shape='pill'
                size='default'
                onClick={toggleModalTelemedicina}
                endEnhancer={() => <CopyIcon />}
              >
                Link
              </Button>
            </div>
          )}
          {sujeitoAtencaoSelected && profissionalLogado?.utilizaVIZI && (
            <div className={classes.wrapperSolicitacoes}>
              <Button
                kind='secondary'
                shape='pill'
                size='default'
                onClick={toggleModalSolicitacoes}
                endEnhancer={() => (
                  <div className={classes.quantitySolicitacoes}>{quantitySolicitacoes}</div>
                )}
              >
                {renderLabelSolicitacoes()}
              </Button>
            </div>
          )}
        </div>
        <ModalLinkTelemedicina
          open={open}
          onClose={toggleModalTelemedicina}
          linkTelemedicina={`${getViziWebUrl()}/telemedicina/${agendamentoSelected?.chavePublica}`}
          handleCopyLinkToClipboard={handleCopyLinkToClipboard}
          handleClickWhatsApp={handleClickWhatsappTeleconsulta}
        />
      </>
    )
  }
)

const styles = {
  wrapperActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px 16px 16px 0',
    gap: '16px',
    '& > button': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  quantitySolicitacoes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    color: '#fff',
    fontSize: '10px',
    fontWeight: 'bold',
    borderRadius: '50%',
    backgroundColor: '#717FFC',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  wrapperButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
  },
  wrapperTimeAtendimento: {
    width: '50px',
    fontSize: '12px !important'
  },
  wrapperSolicitacoes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
}

const ActionsHeaderWithStyles = withStyles(styles)(ActionsHeader)

export default inject(
  'prontuarioStore',
  'termoUsoStore',
  'atendimentoStore'
)(ActionsHeaderWithStyles)
