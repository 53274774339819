import Api from "../config/api";

export const ativarMedicamento = async (id) => {
  const response = await Api.post('', {
    query:`
      mutation($medicamentoId: UUID) {
        ativarMedicamento(medicamentoId: $medicamentoId) {
          id
          produto
          formaFarmaceutica
          principioAtivo
          ativo
        }
      }
    `,
    variables: {
      medicamentoId: id,
    }
  });

  if (!response?.data?.data?.ativarMedicamento?.id) {
    throw Error('Falha ao ativar o medicamento');
  }

  return response.data.data.ativarMedicamento;
};

export const inativarMedicamento = async (id) => {
  const response = await Api.post('', {
    query:`
      mutation($medicamentoId: UUID) {
        inativarMedicamento(medicamentoId: $medicamentoId) {
          id
          produto
          formaFarmaceutica
          principioAtivo
          ativo
        }
      }
    `,
    variables: {
      medicamentoId: id,
    }
  });

  if (!response?.data?.data?.inativarMedicamento?.id) {
    throw Error('Falha ao inativar o medicamento');
  }

  return response.data.data.inativarMedicamento;
};