import React from "react";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import styles from "../../../assets/jss/pages/centroDeCustoStyle";
import CentroDeCustoModal from "./CentroDeCustoModal";
import ButtonFloat from "../../../components/ButtonFloat";
import HeaderFinanceiro from "../HeaderFinanceiro";
import ButtonMore from "./ButtonMore";
import PageTitle from "../../../components/PageTitle/PageTitle";

const columns = [
  {
    Header: "",
    getValue: row => {
        return <div className={classnames("status", String(row.ativo).toLowerCase())}/>;
    }
  },
  {
    Header: "Nome",
    field: "nome",
    getValue: row => {
      return row.nome;
    },
  },
  {
    Header: "",
    isClick: true,
    getValue: row => {
    return <ButtonMore centroDeCusto={row}/>
    }
  }
];

@inject("centroDeCustoStore", "googleAnalyticsStore")
@observer
class CentroDeCusto extends React.Component {
  state = {
    ordenarTabela: {sortField: "nome", sortDir: "ASC"}
  }

  componentDidMount() {
    const { centroDeCustoStore } = this.props;
    this.clearListAndFilter();

    centroDeCustoStore.searchDTO.search = "";
    centroDeCustoStore.findAll({ pageNumber: 0 });
    const paginaVisualizada = {
      page: window.location.hash, 
      title: 'Extrato - Centro de Custos'
    }
    this.props.googleAnalyticsStore.pageView(paginaVisualizada);
  }

  componentWillUnmount() {
    this.clearListAndFilter();
  }

  clearListAndFilter = () => {
    const { centroDeCustoStore } = this.props;
    centroDeCustoStore.centroCustoList = [];
  };

  handleSearchChange = e => {
    const { centroDeCustoStore } = this.props;
    const search = e.target.value;
    centroDeCustoStore.clearPesquisar();
    centroDeCustoStore.searchDTO.search = search;
  };

  onKeypressSearch = e => {
    const { centroDeCustoStore } = this.props;
    if(e.key === 'Enter' && centroDeCustoStore.searchDTO.search.length >= 3){
      centroDeCustoStore.findAll({ pageNumber: 0 });
    }
  };

  handleClickClearPesquisar = () => {
    const { centroDeCustoStore } = this.props;

    centroDeCustoStore.clearPesquisar();
    centroDeCustoStore.searchDTO.search = "";
    centroDeCustoStore.findAll({ pageNumber: 0 });
  };

  onClickPesquisar = () => {
    const { centroDeCustoStore } = this.props;
    centroDeCustoStore.findAll({ pageNumber: 0 });
  };

  loadMore = () => {
    const { centroDeCustoStore } = this.props;

    centroDeCustoStore.findAll();
  };

  handleItemClick = id => {
    const { centroDeCustoStore } = this.props;

    centroDeCustoStore.edit(id);
  };

  handleClickOrdenar = (value) => {
    const { centroDeCustoStore } = this.props;
    const { ordenarTabela } = this.state;

    const sortDir = ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC";
    centroDeCustoStore.clearPesquisar();
    this.setState({            
      ordenarTabela: { 
        sortDir: sortDir,
        sortField: value,
      },
  });
    centroDeCustoStore.findAll({pageNumber: 0, sortDir: sortDir, sortField: value});
  };

  render() {
    const { classes, centroDeCustoStore } = this.props;
    const {
      loading,
      centroCustoList,
      open,
      searchDTO,
      numberOfElements
    } = centroDeCustoStore;
    const { ordenarTabela } = this.state;

    const hasMore = numberOfElements > 0;

    return (
      <div className={classes.content}>
        <PageTitle title="Financeiro - Centro de custos"/>
        <HeaderFinanceiro
          onClickButtonSearch={this.onClickPesquisar}
          handleClickClear={this.handleClickClearPesquisar}
          value={searchDTO.search}
          onChange={this.handleSearchChange}
          onKeyPress={this.onKeypressSearch}
          withzindex
          title="Centro de custos"
        />
        <div className={classes.tableContainer}>
          {loading &&
            <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
            >
                <CircularProgress size={30} />
            </Grid>
          }
          {!loading && centroCustoList.length === 0 && (
            <div className={classes.notFoundContainer}>
              <h3>Nenhum item encontrado</h3>
            </div>
          )}
          {!loading && centroCustoList.length > 0 && (
            <Scroll
              initialLoad={false}
              loadMore={this.loadMore}
              hasMore={hasMore}
              pageStart={0}
              className={classes.scrollContainer}
            >
              <Table
                dados={centroCustoList}
                columns={columns}
                clickable={true}
                handleClick={this.handleItemClick}
                comOrdenacao
                ordenarTabela={ordenarTabela}
                handleClickOrdenar={this.handleClickOrdenar}
              />
            </Scroll>
          )}
          <ButtonFloat onClick={() => centroDeCustoStore.openNew()} />
        </div>
        {open && <CentroDeCustoModal open={open} />}
      </div>
    );
  }
}

export default withStyles(styles)(CentroDeCusto);
