import { withStyles } from "@material-ui/core/es";
import React from "react";
import { Popper } from "@material-ui/core";
import { InputDateForm } from "../../../components/Modal/Input";
import Colors from "../../../template/Colors";
import { inject } from "mobx-react";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import classNames from "classnames";

const FiltroAvaliacaoAntropometrica = (props) => {
  const {
    classes,
    openModalFiltrar,
    anchorEl,
    placement,
    avaliacaoAntropometricaStore,
    handleDateInicioChange,
    handleDateFinalChange,
    tabSelectedTipoGrafico,
    tabSelectedTipoVisualizacao,
    changeTabSelectedTipoGrafico,
    changeTabSelectedTipoVisualizacao,
    handleDateInicioSemanaChange,
    handleDateFinalSemanaChange,
    tabSelectedPeriodoVisualizacao,
    changeTabSelectedPeriodoVisualizacao
  } = props;

  const popperModifiers = {
    name: "preventOverflow",
    enabled: true,
    options: {
      altAxis: false,
      altBoundary: false,
      tether: false,
      rootBoundary: "viewport",
      padding: 8,
    },
  };

  const renderFilterMes = () => {
    return (
      <div className={classes.boxInfoData}>
        <div>
          <span className={classes.tituloFiltros}> Data início: </span>
          <InputDateForm
            fullWidth
            views={["year", "month"]}
            value={avaliacaoAntropometricaStore.dataInicioFiltroGrafico}
            onChange={handleDateInicioChange}
            format="MM/YYYY"
            placeholder={"__/____"}
            iconposition="end"
            mask={(value) =>
              value ? [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
            }
          />
        </div>
        <div className={classes.filtroDataFim}>
          <span className={classes.tituloFiltros}> Data fim: </span>
          <InputDateForm
            fullWidth
            views={["year", "month"]}
            value={avaliacaoAntropometricaStore.dataFimFiltroGrafico}
            onChange={handleDateFinalChange}
            format="MM/YYYY"
            placeholder={"__/____"}
            iconposition="end"
            mask={(value) =>
              value ? [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
            }
          />
        </div>
      </div>
    );
  };

  const renderFilterSemana = () => {
    return (
      <div className={classes.boxInfoData}>
        <div className={classes.filtroDataContent}>
          <span className={classes.tituloFiltros}> Data início: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month", "day"]}
            value={avaliacaoAntropometricaStore.dataInicioFiltroGraficoSemanal}
            onChange={handleDateInicioSemanaChange}
          />
        </div>
        <div
          className={classNames(
            classes.filtroDataFim,
            classes.filtroDataContent
          )}
        >
          <span className={classes.tituloFiltros}> Data fim: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month", "day"]}
            value={avaliacaoAntropometricaStore.dataFimFiltroGraficoSemanal}
            onChange={handleDateFinalSemanaChange}
          />
        </div>
      </div>
    );
  };

  return (
    <Popper
      open={openModalFiltrar}
      anchorEl={anchorEl}
      placement={placement}
      disablePortal={true}
      modifiers={popperModifiers}
      className={classes.contentPopper}
    >
      <div>
        <div className={classes.tituloPopper}>Visualização</div>
        <div>
          <div className={classes.tituloCampoPopper}>
            Período da visualização:
          </div>
         {tabSelectedTipoVisualizacao === 0 && <MultiToggleButtons
            options={["0 - 6m", "0 - 2a", "2a - 5a"]}
            tabSelected={tabSelectedPeriodoVisualizacao}
            changeTabSelected={changeTabSelectedPeriodoVisualizacao}
            classes={{ buttonsContainer: classes.noMargin }}
          />}
          <div className={classes.contentRowPopper}>
            {tabSelectedTipoVisualizacao === 0
              ? renderFilterMes()
              : renderFilterSemana()}
          </div>
          <div className={classes.contentRowPopperTab}>
            <div className={classes.tituloCampoPopper}> Tipo de gráfico: </div>
            <MultiToggleButtons
              options={["ZScore", "Percentiles"]}
              tabSelected={tabSelectedTipoGrafico}
              changeTabSelected={changeTabSelectedTipoGrafico}
              classes={{ buttonsContainer: classes.noMargin }}
            />
          </div>
          <div className={classes.contentRowPopperTab}>
            <div className={classes.tituloCampoPopper}>
              Tipo de visualização:
            </div>
            <MultiToggleButtons
              options={["Mensal", "Semanal"]}
              tabSelected={tabSelectedTipoVisualizacao}
              changeTabSelected={changeTabSelectedTipoVisualizacao}
              classes={{ buttonsContainer: classes.noMargin }}
            />
          </div>
        </div>
      </div>
    </Popper>
  );
};

const style = {
  contentPopper: {
    background: "#fff",
    padding: "5px",
    marginRight: "5px",
    borderRadius: "16px",
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
    maxWidth: "300px",
    height:"200px",
  },
  tituloPopper: {
    color: Colors.commons.gray9,
    fontWeight: 700,
    fontSize: 16,
    marginBottom: "5px",
  },
  tituloCampoPopper: {
    color: "#000",
    fontSize: 12,
    fontWeight: 500,
    margin: "10px 0 5px 0",
  },
  contentRowPopper: {
    display: "flex",
  },
  tituloFiltros: {
    color: Colors.commons.gray7,
    fontSize: "12px !important",
  },
  filtroDataFim: {
    marginLeft: "7px",
  },
  noMargin: {
    marginRight: 0,
  },
  contentRowPopperTab: {
    margin: "5px 0",
  },
  filtroDataContent: {
    display: "flex",
    flexDirection: "column",
  },
  boxInfoData: {
    display: "flex",
    flexDirection: "row",
  },
};

const FiltroAvaliacaoAntropometricaWithStyle = withStyles(style)(FiltroAvaliacaoAntropometrica);
export default inject("avaliacaoAntropometricaStore")(FiltroAvaliacaoAntropometricaWithStyle);
