import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import colors from '../../../../template/Colors'

import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'

const style = theme => ({
  root: {
    fontFamily: 'Poppins',
    fontSize: '1em',
  },

  item: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '100px 0px 0px 100px',
    margin: '4px 0px 6px 16px',

    '&>a': {
      fontWeight: 500,
      textDecoration: 'none',
      color: colors.commons.gray7,
    },
  },

  selected: {
    background: 'rgba(38, 172, 169, 0.1)',
    '&>a': {
      color: colors.commons.gray9,
      fontWeight: 500,
      fontSize: '14px',
    },
  },

  badgeCounter: {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: '#717FFC',
    color: 'white',
    fontSize: '10px',
    fontWeight: '600',
    marginLeft: '8px',
    marginRight: '24px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
})

const ItemSubMenu = props => {
  const { classes, name, title, id, hidden, notification, badgeCounter, selected, setSelected } = props

  const toRef = useRef(props.to)

  const className = classNames(classes.item, {
    [classes.selected]: selected,
  })

  const handleSelected = (id) => {
    if (toRef.current === props.to) return
    setSelected(id)
  }
  return (
    !hidden && (
      <div className={className}>
        <Link to={props.to} className={classes.item} onClick={() => handleSelected(id)}>
          {name || title}
        </Link>
        {badgeCounter && badgeCounter >= 1 && (
          <div className={classes.badgeCounter}>{badgeCounter > 99 ? '99+' : badgeCounter}</div>
        )}
        {notification}
      </div>
    )
  )
}

export default withStyles(style)(ItemSubMenu)
