import moment from "moment";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import string from "../../../../utils/string";
import FooterModalFinanceiro from "./FooterModalFinanceiro";
import ItemGrid from "../../../../components/Modal/ItemGrid";
import { TextFieldSearch } from "../../../../components/TextField";
import InputDateForm from "../../../../components/Input/InputDateForm";
import colors from "../../../../template/Colors";
import LinkPagamento from "./LinkPagamento";
import { InputForm } from "../../../../components/Modal/Input";

const copiarLinkIcon = require("../../../../assets/img/svg/copiar-icon.svg");

const AVista = observer((props) => {
  const { classes, extratoStore } = props;
  const { titulo, handleChangeModal, errors } = extratoStore;
  const [valorPago, setValorPago] = useState(0);

  const disabled = (titulo?.id && extratoStore.onlyReadParcelas) || !extratoStore.editMode;

  useEffect(() => {
    let valorSubtotal = titulo.subtotal;

    if (typeof valorSubtotal === "string") {
      valorSubtotal = string.removeMask(titulo.subtotal, "R$")
    }

    if (titulo.dataPagamento) {
      setValorPago(valorSubtotal);
    } else {
      if (moment(titulo.dataVencimento).isAfter(moment())) {
        setValorPago(0);
      } else {
        setValorPago(0);
      }
    }
  }, [titulo.dataPagamento, titulo.subtotal]);

  const tituloParcela = titulo?.chavePublica ? titulo : titulo?.totalParcelas?.value === 1 && titulo?.parcelas?.find((parcela) => parcela?.dataPagamento === null);
  const isPago = titulo?.dataPagamento || tituloParcela?.dataPagamento;

  return (
    <>
    <Grid direction="column" className={classes.content}>
      <div>
        <div className={classes.titleField}>Forma de pagamento</div>
          {disabled ? (
            <div className={classes.fieldDisabled}>
              <div> {titulo.formaPagamento?.label} </div>
            </div>
          ) : (
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleFormaPagamentoLoadOptions}
              withPaginate
              value={titulo.formaPagamento}
              onChange={(e) => handleChangeModal("formaPagamento", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              classNotched={classes.notchedOutlineSearch}
              maxMenuHeight={140}
            />
          )}
        <div className={classes.titleField}>Conta</div>
          {disabled ? (
            <div className={classes.fieldDisabled}>
              <div> {titulo.contaBancaria?.label} </div>
            </div>
          ) : (
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleContaBancariaLoadOptions}
              withPaginate
              value={titulo.contaBancaria}
              classes={{ item: classes.removePadding }}
              onChange={(e) => handleChangeModal("contaBancaria", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              classNotched={classes.notchedOutlineSearch}
              maxMenuHeight={200}
            />
          )}
          <div className={classes.titleField}>Código de transação:</div>
            <InputForm
              value={titulo.codigoTransicao}
              className={classes.codTransacao}
              onChange={(e) => handleChangeModal("codigoTransicao", e)}
              disabled={disabled}
            />
        <div className={classes.row}>
          <ItemGrid size={6} label="Vencimento">
            <InputDateForm
              value={titulo.dataVencimento || ""}
              onChange={(e) => handleChangeModal("dataVencimento", e)}
              iconposition="end"
              invalidDateMessage=""
              disabled={disabled}
              error={errors?.dataVencimento}
            />
            </ItemGrid>
          <ItemGrid size={6} label="Data de pagamento" classes={{itemTitle: classes.titleSemQuebraLinha, item: classes.removepaddingRight}}>
            <InputDateForm
              iconposition="end"
              value={titulo.dataPagamento || ""}
              onChange={(e) => handleChangeModal("dataPagamento", e)}
              invalidDateMessage=""
              disabled={disabled}
            />
          </ItemGrid>
        </div>
        
        {tituloParcela?.id && !isPago && (
          <LinkPagamento
            chavePublica={tituloParcela.chavePublica}
            formaPagamentoName={titulo.formaPagamento?.label}            
          />
        )}
      </div>
      <FooterModalFinanceiro
        valorTotal={string.currencyMaskToFloat(titulo.subtotal)}
        valorPago={valorPago}
      />
    </Grid>
    </>
  );
});

const styles = {
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  titleField: {
    color: "#868686",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "2px",
    marginTop: "8px",
  },
  notchedOutlineSearch: {
    border: "none",
  },
  row: {
    display: "flex",
    marginTop: "8px",
  },
  marginTop: {
    marginTop: "10px",
  },
  fieldDisabled: {
    display: "flex",
    alignItems: "center",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  titleSemQuebraLinha: {
    whiteSpace: 'nowrap',
  },
  removepaddingRight: {
    paddingRight: "0px !important"
  },
  botaoCopiarLink:{
    width: '22px',
    height: '20px',
    borderRadius: '100px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
    "&:hover": {
            background: colors.commons.gray8,
    } 
  },
  inputComBotao: {
    backgroundColor: "#f2f2f2",
    height: 32,
    width: '100%',
    display: "flex",
    alignItems: "center",
    borderRadius: 100,
  },
  codTransacao: {
    width: '100%'
  },
};

const AVistaWithStyles = withStyles(styles)(AVista);
export default inject("extratoStore")(AVistaWithStyles);
