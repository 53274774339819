export const guiaInternacaoFormValidation = (form, fieldsToValidade, validationObject) => {
  let isValid = true;
  fieldsToValidade.forEach(({ field, isRequired }) => {
    if (isRequired && !form[field]) {
      isValid = false;
      validationObject[field] = true;
    } else {
      validationObject[field] = false;
    }
  });
  return isValid;
};
