import React, { Component } from 'react'
import { withStyles, IconButton } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import CloseIcon from '@material-ui/icons/Close'
import { TextField } from '@material-ui/core'
import {
  createSujeitoAtencaoInformacaoImportante,
  inativarSujeitoAtencaoInformacaoImportante,
  updateSujeitoAtencaoInformacoesImportantes,
} from '../../../services/SujeitoAtencaoService'
import PageTitle from '../../../components/PageTitle/PageTitle'
import classNames from 'classnames'
import colors from '../../../template/Colors'
import { AddCircle } from '@material-ui/icons'
import TrashIcon from '../../../components/Icon/Trash'
import ModalInativarEntradaProntuario from '../../../components/Modal/ModalInativarEntradaProntuario'
import ModalInfosInativacao from '../../../components/Modal/ModalInfosInativacao'
import moment from 'moment'
import VisibilityOffIcon from '../../../components/Icon/VisibilityOffIcon'
import Notification from '../../../components/Notification'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '260px',
    height: '100%',
    backgroundColor: '#FB7676',
    borderRadius: '15px',
    zIndex: 999,
  },
  containerLarge: {
    width: '500px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 2px 8px',
  },
  containerCloseIcon: {
    color: '#FFFFFF !important',
  },
  title: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#FFFFFF',
    margin: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    overflowY: 'auto',
    margin: '8px',
    paddingTop: '16px',
    padding: '18px 8px 8px 8px',
    overflowX: 'hidden',
  },
  informacaoInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '14px',
  },
  addIcon: {
    height: '32px',
    width: '32px',
    fontSize: '14px',
    color: colors.commons.padrao,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeIcon: {
    height: '32px',
    width: '32px',
    fontSize: '14px',
    marginRight: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  informacaoInput: {
    width: '100%',
    backgroundColor: '#F2F2F2',
    padding: '4px',
    borderRadius: '8px',
    wordBreak: 'break-all',
    border: 'none',
    paddingBlock: '6.5px',
  },
  resize: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#505050',
    padding: '0 !important',
  },
  informacaoLabel: {
    fontSize: 10,
    color: '#868686',
  },
  infosTable: {
    borderRadius: '10px',
    overflow: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    flex: 1,

    '& > div': {
      overflow: 'auto',
      maxHeight: '100%',
    },
  },
  informacaoItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1);',
    backgroundColor: 'white',
    gap: '4px',
  },
  informacaoItemDate: {
    minWidth: '90px',
    padding: '4px',
    minHeight: '100%',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#F9F9F9',
  },
  informacaoItemContent: {
    padding: '4px',
    flex: 1,
    wordBreak: 'break-all',
    display: 'flex',
    alignItems: 'center',
  },
  deleteIcon: {
    backgroundColor: '#FB7676',
    color: '#FFFFFF',
    height: '32px',
    width: '32px',
    padding: '2px',
    display: 'grid',
    placeItems: 'center',
  },
  deleteIconContainer: {
    minHeight: '100%',
    padding: '8px 10px 8px 0px',
    display: 'grid',
    placeItems: 'center',
  },
  informacaoItemHeader: {
    padding: '16px 10px',
    backgroundColor: colors.commons.padrao,
    display: 'grid',
    gridTemplateColumns: '90px 1fr max-content',
    '& > div': {
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
  },
  informacoInativada: {
    '& > div:nth-child(1), & > div:nth-child(2)': {
      opacity: 0.5,
    },

    '& > div:nth-child(2)': {
      textDecoration: 'line-through',
    },

    '& > div:nth-child(3) > button': {
      backgroundColor: '#707C97',
    },
  },
}

@inject('prontuarioStore')
@observer
class InformacoesImportantesModal extends Component {
  state = {
    informacoesImportantes: [],
    sujeitoAtencaoSelectedId: '',
    showInformacaoInput: false,
    novaInformacao: '',
    loading: false,
    showModalInativar: false,
    motivoInativacao: '',
    idInformacaoInativacao: '',
    showModalInfosInativacao: false,
    isLoading:false,
  }

  async componentDidMount() {
    const { prontuarioStore } = this.props

    await prontuarioStore.refreshSelectedSujeitoAtencao()

    const { sujeitoAtencaoSelected } = prontuarioStore
    const { informacoesImportantes, id } = sujeitoAtencaoSelected

    if (informacoesImportantes?.length > 0) {
      this.setState({
        informacoesImportantes,
      })
    }

    this.setState({ sujeitoAtencaoSelectedId: id })
  }

  renderInformacoesImportantes = informacoes => {
    const { classes, openedByButton } = this.props
    const { loading } = this.state

    if (informacoes?.length > 0) {
      const informacoesItems = informacoes.map((informacao, index) => {
        return (
          <div
            key={index}
            className={classNames(
              classes.informacaoItem,
              informacao.inativacao && classes.informacoInativada
            )}
          >
            <div className={classes.informacaoItemDate}>
              {informacao.inativacao &&
                moment(informacao.inativacao.dataInativacao).format(
                  'DD/MM/YYYY'
                )}
            </div>
            <div className={classes.informacaoItemContent}>
              {informacao.informacao}
            </div>
            {openedByButton && (
              <div className={classes.deleteIconContainer}>
                <IconButton
                  className={classes.deleteIcon}
                  onClick={() =>
                    informacao.inativacao
                      ? this.handleShowModalInfosInativacao(
                        informacao.inativacao
                      )
                      : this.handleOpenModalInativar(informacao.id)
                  }
                  disabled={loading}
                >
                  {informacao.inativacao ? (
                    <VisibilityOffIcon />
                  ) : (
                    <TrashIcon />
                  )}
                </IconButton>
              </div>
            )}
          </div>
        )
      })
      return informacoesItems
    }
  } 

  addNovaInformacao = async () => {
    this.setState({isLoading :true,})
    try{
      if (!this.state.novaInformacao) return
      this.setState({ loading: true })
      const { novaInformacao, sujeitoAtencaoSelectedId } = this.state
      const informacoesImportantesUpdated = await createSujeitoAtencaoInformacaoImportante(
        {
          sujeitoAtencao: {
            id: sujeitoAtencaoSelectedId,
          },
          informacaoImportante: {
            informacao: novaInformacao,
          },
        }
        
      )
      this.setState({
        informacoesImportantes: informacoesImportantesUpdated,
        loading: false,
      })
      this.handleHideNovaInformacaoInput()
    }catch(error){
      console.error(error)
    }finally{
      this.setState({isLoading :false,})
    }
  }

  onEnterPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.addNovaInformacao()
    }
  }

  removeInformacao = async () => {
    this.setState({ loading: true })
    const { prontuarioStore } = this.props
    const { idInformacaoInativacao, motivoInativacao } = this.state
    await inativarSujeitoAtencaoInformacaoImportante({
      idSujeitoAtencaoInformacaoImportante: idInformacaoInativacao,
      motivo: motivoInativacao,
    })

    await prontuarioStore.refreshSelectedSujeitoAtencao()
    const {
      informacoesImportantes,
    } = this.props.prontuarioStore.sujeitoAtencaoSelected
    this.setState({
      informacoesImportantes,
      loading: false,
      motivoInativacao: '',
      idInformacaoInativacao: '',
      showModalInativar: false,
      showModalInfosInativacao: false,
      infosInativacao: null,
    })
  }

  finishUpdatingInformacoes = async informacoes => {
    const { prontuarioStore } = this.props
    const { sujeitoAtencaoSelectedId } = this.state
    const variables = {
      sujeitoAtencao: {
        id: sujeitoAtencaoSelectedId,
      },
      informacoesImportantes: informacoes,
    }

    const informacoesImportantesUpdated = await updateSujeitoAtencaoInformacoesImportantes(
      variables
    )
    await prontuarioStore.refreshSelectedSujeitoAtencao()
    this.setState({
      informacoesImportantes: informacoesImportantesUpdated,
      loading: false,
    })
  }

  handleNovaInformacaoClick = () => {
    this.setState({ showInformacaoInput: true })
  }

  handleHideNovaInformacaoInput = () => {
    this.setState({ showInformacaoInput: false, novaInformacao: '' })
  }

  handleNovaInformacaoChange = event => {
    const novaInformacao = event.target.value
    this.setState({ novaInformacao })
  }

  handleCloseModalInativar = () => {
    this.setState({ showModalInativar: false })
  }

  handleChangeMotivoInativacao = event => {
    const motivoInativacao = event.target.value
    this.setState({ motivoInativacao })
  }

  handleOpenModalInativar = idInformacaoInativacao => {
    this.setState({ showModalInativar: true, idInformacaoInativacao })
  }

  handleCloseModalInfosInativacao = () => {
    this.setState({ showModalInfosInativacao: false })
  }

  handleShowModalInfosInativacao = infos => {
    this.setState({
      showModalInfosInativacao: true,
      infosInativacao: {
        ...infos,
        inativador: infos.inativador.nome,
        hora: moment(infos.dataInativacao).format('HH:mm'),
        dia: moment(infos.dataInativacao).format('DD/MM/YYYY'),
      },
    })
  }

  render() {
    const { classes, openedByButton, onClose, screen } = this.props
    const { informacoesImportantes , isLoading} = this.state
    return (
      <div
        className={classNames(
          classes.container,
          openedByButton && classes.containerLarge
        )}
      >
        {screen && <PageTitle title={`${screen} - Dados importantes`} />}
        <div className={classes.header}>
          <p className={classes.title}>Dados Importantes</p>
          {openedByButton && (
            <IconButton
              onClick={onClose}
              className={[classes.closeIcon, classes.containerCloseIcon]}
              disabled={this.state.loading}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.content}>
          {openedByButton && (
            <div>
              <label className={classes.informacaoLabel}>
                Adicione uma informação
              </label>
              <div className={classes.informacaoInputContainer}>
                <TextField
                  multiline
                  placeholder='Nova informação'
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.resize,
                    },
                    style: {
                      padding: 0,
                    },
                  }}
                  className={classes.informacaoInput}
                  value={this.state.novaInformacao}
                  onChange={event => this.handleNovaInformacaoChange(event)}
                  onKeyPress={event => this.onEnterPress(event)}
                  autoFocus
                />
                <IconButton
                  onClick={this.addNovaInformacao}
                  className={classes.addIcon}
                  disabled={isLoading}
                >
                  <AddCircle />
                </IconButton>
              </div>
            </div>
          )}
          <div className={classes.infosTable}>
            <div>
              <div className={classes.informacaoItemHeader}>
                <div>Data</div>
                <div>Informação</div>
                <div>{openedByButton && 'Ações'}</div>
              </div>
              {this.renderInformacoesImportantes(informacoesImportantes)}
            </div>
          </div>
        </div>

        <ModalInativarEntradaProntuario
          show={this.state.showModalInativar}
          onClose={this.handleCloseModalInativar}
          inativar={this.removeInformacao}
          motivo={this.state.motivoInativacao}
          changeMotivo={this.handleChangeMotivoInativacao}
          loadingInativacao={this.state.loading}
          screen={'Informação importante'}
        />
        {this.state.showModalInfosInativacao && (
          <ModalInfosInativacao
            show={this.state.showModalInfosInativacao}
            onClose={this.handleCloseModalInfosInativacao}
            infosInativacao={this.state.infosInativacao}
          />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(InformacoesImportantesModal)
