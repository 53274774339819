import Api from "../config/api";

export const findAllFinanceiroGuiaServicoLote = async (
  pageableDTO,
  numeroLote
) => {
  return Api.post("", {
    query: `
        query($pageableDTO: PageableDTOInput, $numeroLote: String){
          findAllFinanceiroGuiaServicoLote(pageableDTO: $pageableDTO, numeroLote: $numeroLote){
            last
            totalPages
            totalElements
            content{
            id
            numeroLote
          }
        }
    }`,
    variables: {
      pageableDTO,
      numeroLote,
    },
  });
};

export const findByIdFinanceiroGuiaServicoLote = async (loteId) => {
  const response = await Api.post("", {
    query: `
    query ($loteId: UUID) {
      findByIdFinanceiroGuiaServicoLote(loteId: $loteId) {
        id
        numeroLote
        convenio {
          id
          descricao
        }
        guias {
          id
          numeroGuiaPrestador
          solicitacao {
            data
          }
          convenio {
            descricao
            id
          }
          beneficiario {
            nome
          }
        }
        guiasConsulta {
          id
          numeroGuiaPrestador
          dataAtendimento
          convenio {
            descricao
            id
          }
          beneficiario {
            nome
          }
        }
      }
    }`,
    variables: {
      loteId,
    },
  });
  return response?.data?.data?.findByIdFinanceiroGuiaServicoLote;
};

export const saveGuiaServicoLote = async (guiaServicoLote) => {
  return await Api.post("", {
    query: `
          mutation($lote:FinanceiroGuiaServicoLoteInput){
            createFinanceiroGuiaServicoLote(lote: $lote ){
              id
            }
          }
      `,
    variables: { ...guiaServicoLote },
  });
};

export const updateGuiaServicoLote = async (guiaServicoLote) => {
  return await Api.post("", {
    query: `
          mutation($lote:UUID, $guias:[UUID], $guiasConsulta:[UUID]){
            updateFinanceiroGuiaServicoLote(lote: $lote, guias: $guias, guiasConsulta: $guiasConsulta ){
              id
            }
          }
      `,
    variables: { ...guiaServicoLote },
  });
};

export const nextNumeroLote = async () => {
  const response = await Api.post("", {
    query: `
      mutation {
        nextNumeroLoteGuiaServico
      }
    `,
  });
  return response.data.data.nextNumeroLoteGuiaServico;
};

export const findAllFinanceiroGuiaServicoFaturadaSemLote = async (
  variables
) => {
  const response = await Api.post("", {
    query: `
        query($pageableDTO: PageableDTOInput, $convenioId: Long){
          findAllFinanceiroGuiaServicoFaturadaSemLote(pageableDTO: $pageableDTO, convenioId: $convenioId){
            last
            content {
            id
            numeroGuiaPrestador
            solicitacao {
              data
            }
            beneficiario {
              nome
            }
            convenio {
              descricao
              id
            }
          }
        }
    }`,
    variables: variables,
  });

  return response.data.data.findAllFinanceiroGuiaServicoFaturadaSemLote;
};

export const findAllFinanceiroGuiaConsultaSemLote = async (variables) => {
  const response = await Api.post("", {
    query: `
        query($pageableDTO: PageableDTOInput, $convenioId: Long){
          findAllFinanceiroGuiaConsultaSemLote(pageableDTO: $pageableDTO, convenioId: $convenioId){
            last
            content {
            id
            numeroGuiaPrestador
            dataAtendimento
            nomeContratado
            dataAtendimento
            beneficiario {
              nome
            }
            convenio {
              descricao
              id
            }
          }
        }
    }`,
    variables: variables,
  });

  return response.data.data.findAllFinanceiroGuiaConsultaSemLote;
};

export const gerarXmlLote = async (loteId) => {
  let response = await Api.post("", {
    query: `
      mutation {
        gerarXmlLote(loteId: "${loteId}") {
          hash
          xmlLote
        }
      }
    `,
  });

  return response.data.data.gerarXmlLote;
};
