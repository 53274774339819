import React, { useEffect, useMemo, useState } from 'react'
import Dialog from '../../../../../components/Dialog/Dialog'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import moment from 'moment'

import CriarSenha from './CriarSenha'
import { InputForm, InputDateForm, InputCPFForm } from '../../../../../components/Modal/Input'
import ItemGrid from '../../../../../components/Modal/ItemGridCard'
import TabSelector from '../../../../../components/TabSelector'
import ButtonTransparent from '../../../../../components/Button/ButtonTransparent'

import {Button} from '../../../../../components/ui/Buttons'

import { createUsuario, updateUsuario } from '../../../../../services/ConfiguracaoSecretariaService'

import { unMask } from '../../utils'
import { ITENS_TO_VERIFY } from '../../../../../utils/passwordValidations'
import string from '../../../../../utils/string'
import { ReactSelect } from '../../../../../components/Select/SelectSearch'
import CancelIcon from "@material-ui/icons/Cancel";
import { findGroupByRedeId } from '../../../../../services/ConfiguracoesUnidadeService'
import { findByIdProfissionalSaude } from '../../../../../services/ProfissionalSaudeService'

const tiposProfissional = [{
  label: "Secretários",
  value: 2,
},
{
  label: "Técnicos",
  value: 4,
},
{
  label: "Administrador",
  value: 3,
}]

const ModalCreateSecretaria = ({ classes, isOpen, onClose, openNotification, profissionalSaudeSelected }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [secretariaInformations, setSecretariaInformations] = useState({
    nome: '',
    cpf: '',
    dataNascimento: null,
    email: '',
    confirmarEmail: '',
    senha: '',
    confirmarSenha: '',
    tipo: null,
    groups: []
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [optionsGroups, setOptionsGroups] = useState([])

  useEffect(() => {
    getGroups()
  }, []);

  useEffect(() => {
    if (isOpen && profissionalSaudeSelected) {
      loadProfissionalInfos();
    }
  }, [profissionalSaudeSelected]);

  const loadProfissionalInfos = async() => {
    try {
      const profissionalSaude = await findByIdProfissionalSaude(profissionalSaudeSelected);
      const { nome, cpf, email, dataNascimento, tipo, unidadeProfissionalSaudeGroups } = profissionalSaude;
      const groups = unidadeProfissionalSaudeGroups.map(group => group.group);
      const tipoObject = tiposProfissional.find(tipoProfissional => tipoProfissional.value === tipo);
      setSecretariaInformations({
        ...secretariaInformations,
        nome,
        cpf,
        dataNascimento,
        email,
        confirmarEmail: email,
        tipo: tipoObject,
        groups
      });
    } catch(error) {
      openNotification('Profissional de saúde não encontrado', 'error');
    }
  }

  const getGroups = async () => {
    try {
      const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);
      const groups = await findGroupByRedeId(unidadeLogada.rede.id)
      setOptionsGroups(groups)
    }
    catch (e) {
      console.error(e)
    }
  }

  const fieldsEmpty = () => {
    const withPassword = selectedTabIndex === 0 && !profissionalSaudeSelected ? ['senha', 'confirmarSenha'] : []
    const fieldsToValidate = ['nome', 'cpf', 'dataNascimento', 'email', 'tipo', ...withPassword]
    const fields = fieldsToValidate.filter(field => !secretariaInformations[field])
    const isGroups = secretariaInformations.groups.length > 0
    !isGroups && fields.push('groups')

    return fields
  }

  const checkErrors = () => {
    const fields = Object.keys(secretariaInformations)

    return isSubmitted
      ? {
        ...fields.reduce(
          (acc, curr) => ({ ...acc, [curr]: !secretariaInformations?.[curr] }),
          {}
        ),
        cpf: !!secretariaInformations.cpf && !string.validaCPF(secretariaInformations.cpf),
      }
      : {}
  }

  const hasError = useMemo(() => checkErrors(), [secretariaInformations])

  const handleChange = (event, field) => {
    const { value } = event.target
    setSecretariaInformations(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleChangeDate = (date, field) => {
    setSecretariaInformations(prevState => ({
      ...prevState,
      [field]: date,
    }))
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const { senha, nome, cpf, email, dataNascimento, groups, tipo } = secretariaInformations
      const variables = {
        ...(!profissionalSaudeSelected && { senha: selectedTabIndex === 0 ? senha : null }),
        profissionalSaude: {
          ...(profissionalSaudeSelected && { id: profissionalSaudeSelected }),
          nome,
          cpf: unMask(cpf),
          email,
          dataNascimento: moment(dataNascimento).format('YYYY-MM-DD'),
          cns: '',
          prontuarioExclusivo: false,
          utilizaVIZI: false,
          permiteAvaliacaoVizi: false,
          tipo: tipo?.value,
        },
        groups,
      }
      if (fieldsEmpty().length > 0) {
        setIsSubmitted(true)
        return openNotification('Preencha todos os campos obrigatórios', 'error')
      }
      
      if (profissionalSaudeSelected) {
        await updateUsuario({ ...variables })
      } else {
        await createUsuario({ ...variables })
      }
      onClose()
    } catch (error) {
      const { message } = error
      const currentMessage = message.includes('Exception') ? 'Erro ao criar usuário' : message
      openNotification(currentMessage, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const renderCurrentTab = {
    0: (
      <CriarSenha
        secretariaInformations={secretariaInformations}
        handleChange={handleChange}
        hasError={hasError}
      />
    ),
    1: <div className={classes.sendEmailMessage}>Será enviado um e-mail para criar uma senha.</div>,
  }

  const isDisabled = useMemo(() => {
    if (selectedTabIndex === 1) return false
    const { senha, confirmarSenha } = secretariaInformations
    const isDifferent = senha !== confirmarSenha
    const passwordIsValid = ITENS_TO_VERIFY.every(verifyFunction => verifyFunction(senha).isValid)
    return (isDifferent || !passwordIsValid) && !profissionalSaudeSelected;
  }, [secretariaInformations, selectedTabIndex, profissionalSaudeSelected])

  const handleChangeSelect = (value, field) => {
    setSecretariaInformations(
      {
        ...secretariaInformations,
        [field]: value
      }
    )
  }

  const handleChangeGroup = (value) => {
    setSecretariaInformations(
      {
        ...secretariaInformations,
        groups: [...secretariaInformations.groups, value]
      }
    )
  }

  const handleRemoveGroups = (id) => {
    const groups = secretariaInformations.groups.filter(group => group.id !== id)
    handleChangeSelect(groups, "groups")
  }

  const avaliableGroups = optionsGroups.filter(group => !secretariaInformations.groups.some(selectedGroup => selectedGroup.id === group.id));

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth={true}
      classes={{
        paper: classes.fullSizePaper,
      }}
    >
      <div className={classes.content}>
        <h1 className={classes.title}>Adicionar Usuário</h1>
        <ItemGrid size={12} label={'Tipo*'}>
          <ReactSelect
            value={secretariaInformations.tipo}
            onChange={(value) => handleChangeSelect(value, "tipo")}
            options={tiposProfissional}
            className={classes.selectTipo}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </ItemGrid>
        <ItemGrid size={12} label={'Grupo de permissão*'}>
          <ReactSelect
            value={null}
            onChange={handleChangeGroup}
            options={avaliableGroups || []}
            className={classes.selectTipo}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
          />
          <div className={classes.listaGroupsSelecionados}>
            {secretariaInformations.groups.length > 0 &&
              secretariaInformations.groups.map((item, index) => (
                <div className={classes.groupOption} key={index}>
                  {item.nome}
                  <CancelIcon
                    onClick={() =>
                      handleRemoveGroups(item?.id)
                    }
                  />
                </div>
              ))}
          </div>
        </ItemGrid>
        <ItemGrid size={12} label={'Nome*'}>
          <InputForm
            name={'nome'}
            value={secretariaInformations.nome}
            onChange={e => handleChange(e, 'nome')}
            placeholder={'Digite seu nome'}
            className={classNames(classes.inputContainer, hasError['nome'] && classes.inputError)}
          />
        </ItemGrid>
        <div className={classes.displayInLine}>
          <ItemGrid size={6} label={'CPF*'}>
            <InputCPFForm
              name={'cpf'}
              value={secretariaInformations.cpf}
              onChange={e => handleChange(e, 'cpf')}
              placeholder={'000.000.000-00'}
              showmask={'false'}
              className={classNames(classes.inputContainer, hasError['cpf'] && classes.inputError)}
            />
          </ItemGrid>

          <ItemGrid size={6} label={'Data de nascimento*'}>
            <InputDateForm
              name={'dataNascimento'}
              value={secretariaInformations.dataNascimento}
              onChange={date => handleChangeDate(date, 'dataNascimento')}
              placeholder={'00/00/0000'}
              showmask={'false'}
              className={classNames(
                classes.inputContainer,
                hasError['dataNascimento'] && classes.inputError
              )}
            />
          </ItemGrid>
        </div>
        <ItemGrid size={12} label={'E-mail*'}>
          <InputForm
            name={'email'}
            type={'email'}
            value={secretariaInformations.email}
            onChange={e => handleChange(e, 'email')}
            placeholder={'Digite seu e-mail'}
            className={classNames(classes.inputContainer, hasError['email'] && classes.inputError)}
          />
        </ItemGrid>
        <ItemGrid size={12} label={'Confirme o E-mail '}>
          <InputForm
            name={'confirmEmail'}
            type={'email'}
            value={secretariaInformations.confirmarEmail}
            onChange={e => handleChange(e, 'confirmarEmail')}
            placeholder={'Digite seu e-mail'}
            className={classNames(classes.inputContainer)}
          />
        </ItemGrid>
        {!profissionalSaudeSelected && (
          <>
            <TabSelector
              tabsOptions={[{ label: 'Criar senha' }, { label: 'Enviar por e-mail' }]}
              baseColor='#F2F2F2'
              selectedColor='#FFF'
              onSelect={index => setSelectedTabIndex(index)}
              selectedTabIndex={selectedTabIndex}
            />
            {renderCurrentTab[selectedTabIndex]}
          </>
        )}
      </div>
      <div className={classes.footer}>
        <ButtonTransparent onClick={onClose}>Cancelar</ButtonTransparent>
        <Button
          colorCustom='green'
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading ||isDisabled}
        >
          Confirmar
        </Button>
      </div>
    </Dialog>
  )
}

const styles = theme => ({
  fullSizePaper: {
    borderRadius: '8px',
    maxWidth: '450px',
  },
  content: {
    padding: '1rem 1.5rem',
    '& > div': {
      marginBottom: '1rem',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: '#26ACA9',
  },
  inputContainer: {
    width: 'calc( 100% - 20px )',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '4px 8px',
    '& > div': {
      '& > p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  inputError: {
    border: '1px solid ' + theme.palette.commons.red,
  },
  displayInLine: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  footer: {
    display: 'flex',
    margin: '0 1rem 1rem 1rem',
    '& > button': {
      flex: 1,
    },
  },
  sendEmailMessage: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#505050',
    textAlign: 'center',
  },

  selectTipo: {
    width: '100%',
  },
  listaGroupsSelecionados: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  groupOption: {
    background: "#F2F2F2",
    border: "0.5px solid #BDBDBD",
    borderRadius: "100px",
    minWidth: "80px",
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 8px",
    margin: "3px",
    "& svg": {
      color: "#868686",
      marginLeft: "5px",
    },
  },
})

export default withStyles(styles)(ModalCreateSecretaria)
