import React, { useEffect, useState } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'

import { buildUrlDownload } from '../../../../../../config/config'
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../../../../services/storage'

import PdfIcon from '../../../../../../components/Icon/Pdf'
import ArrowDownloadIcon from '../../../../../../components/Icon/ArrowDownload'
import TrashIcon from '../../../../../../components/Icon/Trash'

const Document = ({ solicitacao, classes, optionsDocument, selectedTab }) => {
  const [url, setUrl] = useState()
  const [imageIsLoaded, setImageIsLoaded] = useState(false)

  const getUrlDocument = async () => {
    const { urlDownload } = solicitacao
    const token = await localStorageService.get(ACCESS_TOKEN_KEY)
    const url = buildUrlDownload(urlDownload, token)
    setUrl(url)
    return url
  }

  const options = [
    {
      ativo: true,
      label: 'Baixar',
      onClick: optionsDocument.handleDownloadDocument,
      icon: <ArrowDownloadIcon size='24' color='#505050' />,
    },
    {
      ativo: selectedTab === 0,
      label: 'Descartar',
      onClick: optionsDocument.handleDiscardSolicitacao,
      icon: <TrashIcon size='24' color='#505050' />,
    },
  ]

  useEffect(() => {
    if (!solicitacao?.tipoEntradaProntuario === 'ARQUIVO') return
    getUrlDocument()
  }, [solicitacao?.tipoEntradaProntuario])

  return (
    <>
      {solicitacao?.tipoEntradaProntuario === 'ARQUIVO' ? (
        <div className={classes.wrapperDocuments}>
          <PdfIcon color='black' />
          <span className={classes.titleDocument}>
            {solicitacao?.amazonS3Objeto?.nomeComExtensao}
          </span>
          <div className={classes.wrapperActionsDocument}>
            {options.map((option, index) => {
              if (!option.ativo) return null
              return (
                <button key={index} className={classes.actionDocument} onClick={option.onClick}>
                  {option.icon}
                  {option.label}
                </button>
              )
            })}
          </div>
        </div>
      ) : (
        <div className={classes.contentImagem}>
          {!imageIsLoaded && (
            <div className={classes.wrapperLoading}>
              <CircularProgress color='primary' size={24} />
            </div>
          )}
          <img
            onLoad={() => setImageIsLoaded(true)}
            src={url}
            alt={solicitacao.amazonS3Objeto.nome}
            className={classes.imagem}
          />
        </div>
      )}
    </>
  )
}

const style = {
  contentImagem: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    minHeight: 'inherit',
    maxHeight: '432px',
  },
  wrapperLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagem: {
    maxWidth: '100%',
    objectFit: 'cover',
  },
  thumbnailContent: {
    minHeight: '40vh',
    backgroundColor: '#f2f2f2',
    filter: 'blur(5px)',
  },
  thumbnail: {
    height: '100%',
    width: '100%',
  },
  wrapperDocuments: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  titleDocument: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#333333',
  },
  wrapperActionsDocument: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
    width: '100%',
    flexDirection: 'column',
    gap: '1rem',
  },
  actionDocument: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: '6px',
    padding: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '99px',
    backgroundColor: '#F2F2F2',
    fontSize: '14px',
    fontWeight: '600',
    color: '#505050',
  },
}

export default withStyles(style)(Document)
