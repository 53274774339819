import React from 'react';
import SelectedButton from '../../../components/Button/SelectedButton';
import { withStyles } from "@material-ui/core";
import classnames from 'classnames';

const styles = theme => ({
  root: {
    justifyContent: 'space-between'
  },
  recipeTypes: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    width: 284,
    height: 28,
    left: 196,
    pading: 2,
    top: 30,
    background: '#00B0AE',
    boxShadow: '0px 5px 25px rgba(38, 172, 169, 0.07), 0px 7px 25px rgba(38, 172, 169, 0.08), 0px 10px 15px rgba(0, 0, 0, 0.03)',
    borderRadius: 53,
  },
  borderRadius: {
    minHeight: '20.92px',
    margin: '2px',
    width: 88,
    borderRadius: 53,
  }
});

const RecipeType = ({ typeCurrentRecipe, handleChangeTypeRecipe, className, readOnly, classes }) => {
  const container = classnames(classes.root, className ? className : null);

  return (
    <div className={container}>
      <div className={classes.recipeTypes}>
        <SelectedButton
          className={classes.borderRadius}
          selected={typeCurrentRecipe === 'SIMPLES'}
          onClick={readOnly ? null : handleChangeTypeRecipe('SIMPLES')}
        >
          Simples
        </SelectedButton>

        <SelectedButton
          className={classes.borderRadius}
          selected={typeCurrentRecipe === 'CONTROLE_ESPECIAL'}
          onClick={readOnly ? null : handleChangeTypeRecipe('CONTROLE_ESPECIAL')}
        >
          Controlada
        </SelectedButton>

        <SelectedButton
          className={classes.borderRadius}
          selected={typeCurrentRecipe === 'OFTALMOLOGICA'}
          onClick={readOnly ? null : handleChangeTypeRecipe('OFTALMOLOGICA')}
        >
          Oftalmologica
        </SelectedButton>
      </div>
    </div>)
};

export default withStyles(styles)(RecipeType);