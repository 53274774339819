import React, { useEffect, useState } from "react";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import AtendimentoModalTabProcedimentosAgendamento from "../AtendimentoModalTabProcedimentosAgendamento";
import { inject } from "mobx-react";
import TabProcedimentoPedidoFaturado from "./TabProcedimentoPedidoFaturado";
import { withStyles } from "@material-ui/core";

const tabs = {
  AGENDAMENTO: 0,
  FATURADOS: 1,
};

const AtendimentoModalTabProcedimentos = ({
  classes,
  atendimentoStore,
  handleChangeCheckbox,
  onClickAgendarProcedimento,
  observacaoGeral,
  procedimentosSolicitados,
}) => {
  const [tabSelected, setTabSelected] = useState(tabs.AGENDAMENTO);

  useEffect(() => {
    procedimentosSolicitados.length === 0 && setTabSelected(tabs.FATURADOS);
  }, []);

  const changeTabSelected = (tabSelected) => {
    setTabSelected(tabSelected);
  };

  const render = () => {
    return tabSelected === tabs.AGENDAMENTO ? (
      <AtendimentoModalTabProcedimentosAgendamento
        procedimentosSolicitados={procedimentosSolicitados}
        handleChangeCheckbox={handleChangeCheckbox}
        onClickAgendarProcedimento={onClickAgendarProcedimento}
        observacaoGeral={observacaoGeral}
      />
    ) : (
      <TabProcedimentoPedidoFaturado />
    );
  };

  return (
    <div className={classes.container}>
      <MultiToggleButtons
        options={["Solicitados", "Faturados"]}
        tabSelected={tabSelected}
        changeTabSelected={changeTabSelected}
        classes={{ buttonsContainer: classes.buttonsTab }}
      />
      {render()}
    </div>
  );
};

const styles = {
  container: {
    height: "calc(100% - 40px)",
  },
  buttonsTab: {
    marginBottom: "8px",
  },
};

const AtendimentoModalTabProcedimentosStyle = withStyles(styles)(
  AtendimentoModalTabProcedimentos
);
export default inject("atendimentoStore")(
  AtendimentoModalTabProcedimentosStyle
);
