import colors from "../../../../template/Colors";

const styles = {
  content: {
    maxHeight: 'calc(100vh - 192px)',

    "& thead th": {
      top: 0,
      position: 'sticky',
    },

    "& tbody td": {
      padding: '0 0 0 24px',
      
      "& #buttonCircle": {
        background: "#fff",
        height: "30px",
        width: "35px",
        
        "&:hover": {
          background: "#f9f9f9",
        }
      },

      "& .status": {
          width: 16,
          height: 16,
          borderRadius: 100,
          display: 'inline-block'
      },
      "& .true": {
          backgroundColor: "#00C1BF",
      },
      "& .false": {
          backgroundColor: "#FB7676",
      },
    },
  },
  dialogPaper: {
    maxWidth: 550,
    maxHeight: 370,
    height: '100%'
  },
  buttonDownloadRow: {
    display: "flex",
    borderRadius: 100,
    cursor: 'pointer',
  },
  buttonsDownloadPrint: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: "15px",
    bottom: "15px",
  },
  buttonDownload: {
    marginRight: 8,
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: 10,
  },
  buttonMore: {
    display: "flex",
    color: "#7c7c7c",
    borderRadius: '100px',
    padding: '2px',
    width: 34,
    height: 34,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    background: 'transparent',
    "&:hover": {
      background: '#F5F5F5'
    }
  },
  columnVisiblity: {
    display: "flex",
    color: colors.commons.gray10,
  },
  headerModalVisualizacaoAnexo: {
    display: "flex",
    justifyContent: "space-between",
    maxHeight: 70,
    alignItems: "center",
    padding: "9px 20px",
  },
  buttonsHeaderModalVisualizacaoAnexo: {
    display: "flex",
  },
  buttonClose: {
    marginLeft: 16,
  },
  titleHeaderModalVisualizacaoAnexo: {
    color: colors.commons.gray9,
    fontWeight: 600,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  contentModalVisualizarAnexo: {
    background: "#7C7C7C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 'calc(100% - 58px)',
  },
  dialog: {
    maxWidth: "1090px",
    maxHeight: "563px",
    width: "100vw",
    height: "90vh",
  },
  contentImageMini: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imagemMiniatura: {
    borderRadius: 5
  },
  buttonInativar: {
    color: colors.commons.gray9
  },
  divDownloadImage: {
    display: 'flex',
    justifyContent: 'center'
  },
  contentModalAnexo: {
    height: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: '370px',
  },
  divButtons: {
    display: 'flex'
  },
  titleHeaderModalAnexo: {
    color: colors.commons.gray9,
    fontWeight: 700,
    fontSize: 24
  },
  buttonSaveAnexo:{
    flex: 1,
    boxShadow: 'none',

  },
  buttonCancelarAnexo: {
    color: colors.commons.gray9,
    fontWeight: 700,
    fontSize: 14,
    display:'flex',
    justifyContent: 'center',
    height: '35px',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer'
  },
  buttonCancelarAnexoDisabled: {
    cursor: 'default',
    opacity: 0.3
  },
  buttonDashed: {
    textTransform: 'lowercase',
    margin: '16px 0',
  },
  contentButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  listaAnexos: {
    overflow: 'auto',
    maxHeight: '200px'
  },
  inputNome: {
    margin: 0
  },
  scrollContainer: {
    height: "100%",
    "&>div": {
        height: 'calc(100% - 64px)'
    }
  },
  documentoFileInput: {
    display: "none"
  }
};

export default styles;
