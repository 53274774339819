import React from "react";

import Grid from "@material-ui/core/Grid";
import ItemSubMenu from "./ItemSubMenu";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const TitleMenu = styled.div`
    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #505050;
    padding-left: 30px;
    margin-bottom: 4px;
    margin-top: ${(props) => props.title !== "Agendamentos" ? "16px" : 0}
`;

class SubMenu extends React.PureComponent {
    render() {
        const { match, location, items } = this.props;

        return (
            <Grid container direction={"column"} wrap={"nowrap"}>
                {items.map(item => (
                    <>
                        <TitleMenu title={item.title}> { item.title } </TitleMenu>
                        {item?.children?.map(route => { 
                            
                            return(
                            <ItemSubMenu
                                key={route.uri}
                                to={`${match.url}${route.uri}`}
                                title={route.title}
                                location={location}
                            />
                        )})
                        }
                    </>
                ))}
            </Grid>
        );
    }
}

export default withRouter(SubMenu);
