import React from "react";
import { inject } from "mobx-react";
import { Grid, DialogContent, withStyles, CircularProgress } from "@material-ui/core";
import {
  Save as SaveIcon
} from "@material-ui/icons";
import { SelectSearch } from "../../../components/Select/SelectSearchAsync";
import Dialog from "../../../components/Dialog/Dialog";
import DialogHeader from "../../../components/Dialog/DialogHeader";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ItemGrid from "../../../components/Modal/ItemGridCard";
import { TextFieldSearch } from "../../../components/TextField";
import Colors from "../../../template/Colors";
import PageTitle from "../../../components/PageTitle/PageTitle";
import InputMaskCurrency from "../../../components/Input/InputMaskCurrency";

const ModalConvenios = (props) => {
  const {
    open,
    closeModal,
    onConvenioChange,
    classes,
    sujeitoAtencaoStore,
    convenio,
    saving,
    handleSalvar,
    valorProcedimentoRealizado,
    onValorChange,
    errorValorProcedimentoRealizadoSelecionado,
    procedimentoRealizadoSelecionado,
    onRecebedorChange,
    recebedor,
    handleRecebedorLoadOptions,
  } = props;

  return (
    <>
    <PageTitle title="Paciente - Editar procedimento realizado"/>
    <Dialog 
      open={open} 
      maxWidth={"sm"} 
      classes={{
        paper: classes.paper,
    }}>
      <DialogHeader
        title="Editar"
        closeButton={true}
        actionClose={closeModal}
      />
      <DialogContent 
        size={12}
        className={classes.dialog}
      >
        <div className={classes.content}>
          <ItemGrid label={"Convênio:"}>
            <SelectSearch
              className={classes.contentItemConvenio}
              name="convenio"
              placeholder={"Convênio"}
              value={convenio}
              elements={sujeitoAtencaoStore.convenios}
              onChange={(e) => onConvenioChange(e, procedimentoRealizadoSelecionado)}
            />
          </ItemGrid>
          <ItemGrid label={"Valor:"} className={classes.contentItemValor}>
            <InputMaskCurrency
              name={'valor'}
              value={valorProcedimentoRealizado || ""}
              className={errorValorProcedimentoRealizadoSelecionado ? classes.errorValor : classes.inputValor}
              onChange={(e) => onValorChange(e)}
              disabled={saving}
            />
          </ItemGrid>
        </div>
        <div className={classes.gridRecebedor}>
        <ItemGrid label={"Recebedor:"}>
          <TextFieldSearch
            classes={{ paper: classes.paperSelect }}
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            name="Recebedor"
            placeholder={"Recebedor"}
            value={recebedor}
            onChange={onRecebedorChange}
            loadOptions={handleRecebedorLoadOptions}
            withPaginate
            debounceTimeout={300}
            additional={{
                page: 0
            }}
          />
          </ItemGrid>
          </div>
        <Grid container justify="flex-end" className={classes.gridButtonSalvar}>
          <Grid item xs={3} className={classes.inlineButtons}>
            <ButtonPrimary 
              onClick={handleSalvar}
              className={classes.botaoSalvar}  
              disabled={saving}
            >
              {saving ? 
                <CircularProgress
                  color="inherit"
                  size={14}
                  style={{ marginLeft: 10 }}
                />
                :
                <SaveIcon />
              }
               <span className={classes.labelSalvar}> Salvar </span>
            </ButtonPrimary>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
    </>
  );
};

const styles = {
  dialog:{
    overflow: 'visible',
  },
  paper:{
    overflow: 'visible',
  },
  contentItemConvenio: {
    width: 200,
    "&> div": {
      maxHeight: '30vh',
      borderRadius: 100
    },
  },
  contentItemValor: {
    width: 200,
  },
  labelSalvar:{
    fontSize: '14px !important',
    marginLeft: 10,
  },
  botaoSalvar:{
    borderRadius: 85,
    padding: '7px 13px',
    justifyContent: 'end',
  },
  content:{
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 20px',
  },
  errorValor: {
    borderBottom: '1px solid red'
  },
  gridRecebedor:{
    padding: '10px 0px 10px 20px',
  },
  reactSelect:{
    width: '100%',
  },
  gridButtonSalvar:{
    padding: '20px 25px 0',
  },
  inputValor:{
    height: '30px',
    background: '#eeeeee',
    borderRadius: '100px',
    padding: '1px 16px',
    "& fieldset":{
      borderRadius: 100
    }
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    height: 32,
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '100px',
  },
  classIcons: {
      fontSize: 24,
  },
  paperSelect: {
    maxHeight: '30vh',
    "&> div": {
      maxHeight: '30vh',

    }
  }
};

const CustomizedWithStyles = withStyles(styles)(ModalConvenios);
export default inject("sujeitoAtencaoStore")(CustomizedWithStyles);
