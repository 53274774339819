import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const NotificationIcon = props => {
  return props.unreadNotifications > 0 ? (
    <svg
      width='25'
      height='31'
      viewBox='0 0 25 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M8.22603 27.1524H16.2843C15.9592 29.0838 14.279 30.5553 12.2551 30.5553C10.2313 30.5553 8.55113 29.0838 8.22603 27.1524ZM12.2551 4C17.8967 4 22.47 8.57335 22.47 14.2148V19.6607L24.4017 23.9647C24.4765 24.1313 24.5152 24.3119 24.5152 24.4945C24.5152 25.2091 23.9359 25.7884 23.2213 25.7884H1.29421C1.11199 25.7884 0.931838 25.7499 0.765528 25.6755C0.113308 25.3835 -0.178722 24.618 0.113258 23.9658L2.04032 19.6612L2.04047 14.1972L2.04649 13.8568C2.2359 8.36711 6.74713 4 12.2551 4Z'
        fill='currentColor'
      />
      <g clip-path='url(#clip0_1_4)'>
        <path
          d='M18.8438 12C22.1575 12 24.8438 9.31371 24.8438 6C24.8438 2.68629 22.1575 0 18.8438 0C15.53 0 12.8438 2.68629 12.8438 6C12.8438 9.31371 15.53 12 18.8438 12Z'
          fill='#FB7676'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_4'>
          <rect width='13' height='12' fill='white' transform='translate(12)' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width='33'
      height='34'
      viewBox='0 0 33 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M12.1272 26.1915H20.1854C19.8604 28.1229 18.1802 29.5944 16.1563 29.5944C14.1324 29.5944 12.4522 28.1229 12.1272 26.1915ZM16.1563 3.03906C21.7978 3.03906 26.3712 7.61241 26.3712 13.2539V18.6998L28.3029 23.0038C28.3777 23.1704 28.4163 23.351 28.4163 23.5336C28.4163 24.2482 27.837 24.8275 27.1225 24.8275H5.19533C5.01311 24.8275 4.83296 24.789 4.66665 24.7146C4.01443 24.4226 3.7224 23.6571 4.01438 23.0049L5.94144 18.7003L5.94159 13.2363L5.94761 12.8959C6.13702 7.40617 10.6483 3.03906 16.1563 3.03906Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default NotificationIcon
