const styles = {
    paper: {
        maxWidth: '744px',
        height: '100%',
        maxHeight: '645px',
    },
    contentAuditoria: {
        
    },
    header: {
        padding: '40px 32px 22px'
    },
    buttonsTab: {
        margin: '0 32px 18px 32px'
    },
};

export default styles;