import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({
  labelArea: {
    display: 'flex',
    justifyContent: 'center',
    width: 'max-content',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '0 8px',
  },
  withPadding: {
    padding: '4px 8px',
  },
  labelText: {
    fontWeight: '600 !important',
    color: '#F9F9F9',
    fontSize: '10px !important'
  },
});

const RectLabel = ({ text, color, classes, withPadding, areaStyle }) => {
  return (
    <div 
      className={withPadding ? classNames([classes.labelArea, classes.withPadding]) : classes.labelArea} 
      style={{ backgroundColor: color, ...areaStyle }}
    >
      <span className={classes.labelText}>
        {text}
      </span>
    </div>
  )
};

export default withStyles(styles)(RectLabel);
