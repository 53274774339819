import moment from "moment";

const dateFormatRegex = /(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;

export const getPeriodInterval = (period) => {
  let dataInicial, dataFinal;

    switch(period) {
      case 'CURRENT_MONTH':
        dataInicial = moment()
          .startOf("month");
        dataFinal = moment()
          .endOf("month");
        break;
      case 'LAST_MONTH':
        dataInicial = moment()
          .subtract(1,'months')
          .startOf("month");
        dataFinal = moment()
          .subtract(1,'months')
          .endOf("month");
        break;
      case 'LAST_30':
        dataInicial = moment()
          .subtract(30, "days");
        dataFinal = moment();
        break;
      case 'LAST_90':
        dataInicial = moment()
          .subtract(90, "days");
        dataFinal = moment();
        break;
      case 'CURRENT_YEAR':
        dataInicial = moment()
          .startOf("year");
        dataFinal = moment()
          .endOf("year");
        break;
      default:
        dataInicial = moment();
        dataFinal = moment();
    }
    return { dataInicial: dataInicial.toDate(), dataFinal: dataFinal.toDate() };
};

export const checkValidDate = (date) => {
  const split = date.split(' à ');
  return dateFormatRegex.test(split[0]) && dateFormatRegex.test(split[1]);
}

export const startDayIsBeforeEndDay = (date = null, start = null, end = null) => {
  if (date) {
    const split = date.split(' à ');
    return moment(split[0], 'DD/MM/YYYY').isSameOrBefore(moment(split[1], 'DD/MM/YYYY'), 'day');
  }

  if (start && end) {
    return moment(start, 'DD/MM/YYYY').isSameOrBefore(moment(end, 'DD/MM/YYYY'), 'day');
  }
};

export const getDatesFromString = (value) => {
  const splited = value.split(` à `);
  return { 
    dataInicial: moment(splited[0], 'DD/MM/YYYY').toDate(),
    dataFinal: moment(splited[1], 'DD/MM/YYYY').toDate(),  
  }
}
