import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/src/createNumberMask";
import { withStyles } from "@material-ui/core/styles/index";
import classNames from "classnames";
import { applyCurrencyMask } from "../../utils/CurrencyMask";

const defaultMaskOptions = {
  prefix: 'R$',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 10
}

const CurrencyInput = ({ maskOptions, className, classes, error, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  let inputPropsValueString = inputProps;  
  if(typeof inputProps.value === 'number') {
    inputPropsValueString={
      ...inputPropsValueString,
      value: applyCurrencyMask(inputPropsValueString.value),
    }
  } 

  return <MaskedInput 
    mask={currencyMask} 
    className={classNames( classes.input, className && className, error && classes.error)}
    {...inputPropsValueString} 
  />
}

CurrencyInput.defaultProps = {
  maskOptions: {},
}

const styles =() => ({
  input: {
    color: "#505050",
    border: 'none',
    width: '85%',
    background: '#fff',
  },
  error: {
    border: 'solid 1px #FB7676'
  }
})

export default withStyles(styles)(CurrencyInput);
