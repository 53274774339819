import { action, observable } from "mobx";
import moment from "moment";
import 'moment/locale/pt-br';
import Api from '../config/api';
import string from "../utils/string";
import { checkUserRole } from "../utils/checkUserRole";

const primeiroDiaMesAtual = moment().startOf("month").format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment().endOf("month").format("YYYY-MM-DD");

export const filterDateDefault = {
    dataInicial: primeiroDiaMesAtual,
    dataFinal: ultimoDiaMesAtual
};
export default class DashboardStore {
    usuarioStore = null;
    @observable valorTotalReceita = 0;
    @observable valorTotalDespesa = 0;
    @observable saldoTotalTituloParcela = 0;
    @observable totalAgendamentos = 0;
    @observable totalAgendamentosDia = 0;
    @observable atendimentosAtrasados = 0;
    @observable porcentagemAtendimentosAtrasados = 0;
    @observable agendaGraphData = [];
    @observable mediaTempoEsperaData = [];
    @observable saldoGraphData = [];
    @observable atendimentosAtendidoData = [];
    @observable profissionalSaudeSelected = null;
    @observable filterDate = filterDateDefault;

    async loadDados({profissionalSaudeId}) {
        try {
            const { data } = await Api.post('',
                {
                    query: `
                    {
                        valorTotalReceita: valorTotalTituloParcela(search: {profissionalSaudeId: ${profissionalSaudeId}, tituloTipo: RECEITA, dataInicial: "${this.filterDate.dataInicial}", dataFinal: "${this.filterDate.dataFinal}"})
                        valorTotalDespesa: valorTotalTituloParcela(search: {profissionalSaudeId: ${profissionalSaudeId}, tituloTipo: DESPESA, dataInicial: "${this.filterDate.dataInicial}", dataFinal: "${this.filterDate.dataFinal}"})
                        
                        saldoTotalTituloParcela(search: {profissionalSaudeId: ${profissionalSaudeId}, dataInicial: "${this.filterDate.dataInicial}", dataFinal: "${this.filterDate.dataFinal}"})

                        ${this.createQueries({query: 'saldoTotalTituloParcela', quantidade: 6, profissionalSaudeId, dataInicial: this.filterDate.dataInicial, dataFinal: this.filterDate.dataFinal})}
                        ${this.createQueries({query: 'mediaTempoEspera', quantidade: 3, profissionalSaudeId, dataInicial: this.filterDate.dataInicial, dataFinal: this.filterDate.dataFinal})}
                        ${this.createQueriesAgendamentosAtendidos({query: 'totalAgendamentos', nome: 'agendamentosAtendido', situacoes: 'ATENDIDO', profissionalSaudeId, dataInicial: this.filterDate.dataInicial, dataFinal: this.filterDate.dataFinal})}

                        totalAgendamentos(search: {profissionalSaudeId: ${profissionalSaudeId}, dataInicial: "${this.filterDate.dataInicial}", dataFinal: "${this.filterDate.dataFinal}"})
                        totalAgendamentosAgendados: totalAgendamentos(search: {profissionalSaudeId: ${profissionalSaudeId}, situacoes: [AGENDADO, CONFIRMADO, AGUARDANDO, ATENDENDO], dataInicial: "${this.filterDate.dataInicial}", dataFinal: "${this.filterDate.dataFinal}"})
                        totalAgendamentosDia(search: {profissionalSaudeId: ${profissionalSaudeId}, dataInicial: "${this.getHoje()}"})
                        totalAgendamentosAtendidos: totalAgendamentos(search: {profissionalSaudeId: ${profissionalSaudeId}, situacoes: ATENDIDO, dataInicial: "${this.filterDate.dataInicial}", dataFinal: "${this.filterDate.dataFinal}"})
                    }
                        `
                }
            );
            
            if(data.errors) {
                throw data.errors[0];
            }

            this.valorTotalReceita = data.data.valorTotalReceita;
            this.valorTotalDespesa = data.data.valorTotalDespesa;
            this.saldoTotalTituloParcela = data.data.saldoTotalTituloParcela;
            this.totalAgendamentos = data.data.totalAgendamentos;
            this.totalAgendamentosDia = data.data.totalAgendamentosDia;
            this.atendimentosAtrasados = data.data.atendimentosAtrasados;
            this.porcentagemAtendimentosAtrasados = this.totalAgendamentos ? this.atendimentosAtrasados / this.totalAgendamentos * 100 : 0;
            
            const getDadosResponseTempoEspera = (nome, dataInicial) => {
                const mes1 = moment(dataInicial).format('MMM');
                const mes2 = moment(dataInicial).subtract(1, 'M').format('MMM');
                const mes3 = moment(dataInicial).subtract(2, 'M').format('MMM');

                const nomeMeses = [mes3, mes2, mes1];

                let meses = [];

                for (let index = 0; index < 3; index++) {
                    const tempoEspera = {
                        name: nomeMeses[index],
                        tempo: data.data[`${nome}${index+1}`],
                    }

                    meses = [
                        ...meses,
                        tempoEspera
                    ];
                };
                return meses;
            };

            const getDadosResponseAgendamentosAtendido = (nome) => {
                let dia = {}
                let agendamentosAtendido = [];
                
                const mes1 = moment(this.filterDate.dataInicial);
                const mesAnterior = moment(this.filterDate.dataInicial).subtract(1, 'M');

                const mesAtualNome = string.capitalize(mes1.format('MMM'));
                const mesAnteriorNome = string.capitalize(mesAnterior.format('MMM'));
                    
                for (let index = 1; index <= 31; index++) {
                    dia = {
                        name: index,
                        [mesAtualNome]: data.data[`${nome}${index}${mesAtualNome}`],
                        [mesAnteriorNome]: data.data[`${nome}${index}${mesAnteriorNome}`]
                    };

                    agendamentosAtendido= [
                        ...agendamentosAtendido,
                        dia
                    ];
                };

                return agendamentosAtendido;
            };

            this.getMediaTempoEsperaData(getDadosResponseTempoEspera("mediaTempoEsperaMes", this.filterDate.dataInicial));

            this.getAgendamentoAtendidoData(getDadosResponseAgendamentosAtendido("agendamentosAtendidoDia"));
            
            this.getAgendaGraphData({
                agendados: data.data.totalAgendamentosAgendados,
                atendidos: data.data.totalAgendamentosAtendidos,
                total: data.data.totalAgendamentos,
            });
            
            return data.data;
        } catch (error) {
            throw error;
        }
    }
    
    createQueries = ({query, nome = query, quantidade, situacoes, profissionalSaudeId, dataInicial, dataFinal}) => {
        let nomeQuery = ""

        for (let index = 1; index <= quantidade; index++) {
            const campoSituacoes = situacoes && `, situacoes: ${situacoes}`
            const campos = `search: {profissionalSaudeId: ${profissionalSaudeId}, dataInicial: "${this.getDataInicioFimMes(quantidade-index, dataInicial).inicio}", dataFinal: "${this.getDataInicioFimMes(quantidade-index, dataFinal).fim}" ${campoSituacoes || ""}}` 
            nomeQuery = nomeQuery + `${nome}Mes${index}: ${query}(${campos}) `;
        };
        
        return nomeQuery;
    }

    createQueriesAgendamentosAtendidos = ({ query, nome = query, situacoes, profissionalSaudeId, dataInicial, dataFinal }) => {
        let nomeQuery = "";
        let quantidadeRepetir = 0;

        while(quantidadeRepetir < 2) {
            const mes = moment(dataInicial).subtract(quantidadeRepetir, 'M')
            const quantidadeDiasNoMêsAtual = mes.daysInMonth();
            const mesNome = string.capitalize(mes.format('MMM'));
            const mesNumero = mes.month()+1;
            const anoNumero = mes.year();
            
            for (let index = 1; index <= quantidadeDiasNoMêsAtual; index++) {
                const data = `${anoNumero}-${string.transformDoisDigitosString(mesNumero)}-${string.transformDoisDigitosString(index)}`;

                const campoSituacoes = situacoes && `, situacoes: ${situacoes}`
                const campos = `search: {profissionalSaudeId: ${profissionalSaudeId}, dataInicial: "${data}", dataFinal: "${data}" ${campoSituacoes || ""}}` 
                nomeQuery = nomeQuery + `${nome}Dia${index}${mesNome}: ${query}(${campos}) `;
            };
            quantidadeRepetir++;
        };
        
        return nomeQuery;
    }

    getDataInicioFimMes(subtract = 0, dataInicial) {
        return {
            inicio: moment(dataInicial).subtract(subtract, 'month').startOf('month').format('YYYY-MM-DD'),
            fim: moment(dataInicial).subtract(subtract, 'month').endOf('month').format('YYYY-MM-DD'),
        }
    }
    
    getMes(subtract = 0) {
        return moment().subtract(subtract, 'month').startOf('month').format('MMMM')
    }
    
    getAgendaGraphData(data) {
        this.totalAgendamentos = data.total || 0;
        this.agendaGraphData = [
            { label: 'Pacientes agendados', value: data.agendados || 0, color: "#219A97" },
            { label: 'Pacientes atendidos', value: data.atendidos || 0, color: "rgba(33, 154, 151, 0.5)" },
        ];
    }
    
    getMediaTempoEsperaData(data) {
        this.mediaTempoEsperaData = data;
    }

    getAgendamentoAtendidoData(data) {
        this.atendimentosAtendidoData = data;
    }

    getHoje(){
        return moment().format("YYYY-MM-DD")
    }

    @action verificaPermissaoUnidade = async() => {
        const isPermissaoTituloParcelaUnidade = await checkUserRole(
          "ROLE_TITULO_PARCELA_READ_OUTROS_PROFISSIONAIS"
        );
        const isPermissaoAgendamentoUnidade = await checkUserRole(
          "ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS"
        );
    
        const isPermiteUnidade = isPermissaoTituloParcelaUnidade && isPermissaoAgendamentoUnidade;
    
        return (isPermiteUnidade);
    };
}
