import Api from '../config/api'

export const findAllProcedimentoModelo = async variables => {
  const response = await Api.post('', {
    query: `
    query FindAllProcedimentoModelo($pageableDto: PageableDTOInput, $search: String, $ativo: Boolean) {
      findAllProcedimentoModelo(pageableDTO: $pageableDto, search: $search, ativo: $ativo) {
        last
        content {
          nome
          id
          ativo
          procedimentos {
            id
            nome
          }
        }
      }
    }`,
    variables,
  })

  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  }

  return response.data.data.findAllProcedimentoModelo
}

export const createModeloProcedimento = async variables => {
  return await Api.post('', {
    query: `
    mutation CreateProcedimentoModelo($procedimentoModelo: ProcedimentoModeloInput) {
      createProcedimentoModelo(procedimentoModelo: $procedimentoModelo) {
        id
      }
    }`,
    variables,
  })
}

export const updateStatusProcedimentoModelo = async id => {
  return await Api.post('', {
    query: `
    mutation UpdateStatusProcedimentoModelo($procedimentoModeloId: UUID) {
      updateStatusProcedimentoModelo(procedimentoModeloId: $procedimentoModeloId) {
        id
      }
    }`,
    variables: { procedimentoModeloId: id },
  })
}

export const updateProcedimentoModelo = async variables => {
  return await Api.post('', {
    query: `
    mutation UpdateProcedimentoModelo($procedimentoModelo: ProcedimentoModeloInput) {
      updateProcedimentoModelo(procedimentoModelo: $procedimentoModelo) {
        id
      }
    }`,
    variables,
  })
}
