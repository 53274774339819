import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import Profile from "../../template/Header/Profile";
import SearchIcon from "../../components/Icon/Search";
import ItemGrid from "../../components/Modal/ItemGrid";
import styles from "./SolicitacoesPacienteHeaderStyles";
import ButtonLong from "../../components/Button/ButtonLong";
import PeriodDate from "../../components/PeriodDate/PeriodDate";
import { ReactSelect } from "../../components/Select/SelectSearch";
import InputSearchRetangular from "../../components/Input/InputSearchRetangular";
import { tiposSolicitacoes } from "../../stores/SolicitacoesPacientes.store";

const SolicitacoesPacientesHeader = observer(
  ({ classes, solicitacoesPacientesStore }) => {
    const { filterSolicitacoesPacientes } = solicitacoesPacientesStore;

    const handleChangeFilter = (value, field) => {
      solicitacoesPacientesStore.filterSolicitacoesPacientes = {
        ...filterSolicitacoesPacientes,
        [field]: value,
      };
    };

    const handleChangeInputDate = (dataInicial, dataFinal) => {
      solicitacoesPacientesStore.filterSolicitacoesPacientes = {
        ...filterSolicitacoesPacientes,
        dataInicial,
        dataFinal,
      };
    };

    const handleChangeFiltersData = (value) => {
      solicitacoesPacientesStore.filterSolicitacoesPacientes = value;
    };

    const handleClickFilter = () => {
      solicitacoesPacientesStore.loadSolicitacaoPaciente(true);
      solicitacoesPacientesStore.getQuantidadeSolicitacoes();
    };

    return (
      <div className={classes.content}>
        <div className={classes.contentTitleHeader}>
          <div className={classes.titleHeader}> Solicitações de Pacientes </div>
          <Profile />
        </div>
        <div className={classes.contentFilters}>
          <ItemGrid label="Paciente" size={12}>
            <InputSearchRetangular
              hideStartAdornment
              endAdornment={
                <InputAdornment position="end">
                    <SearchIcon />
                </InputAdornment>
              }
              placeholder="Pesquise por paciente"
              value={filterSolicitacoesPacientes.search}
              onChange={(e) => handleChangeFilter(e.target.value, "search")}
              className={classes.search}
            />
          </ItemGrid>
          <ItemGrid label="Data" size={12}>
            <PeriodDate
              filter={filterSolicitacoesPacientes}
              setFilter={handleChangeFiltersData}
              onChangeDate={handleChangeInputDate}
              hiddenButtonAplicar
            />
          </ItemGrid>
          <ItemGrid label="Tipo" size={12}>
            <ReactSelect
              className={classes.reactSelect}
              placeholder="Selecione"
              value={filterSolicitacoesPacientes.tipo}
              onChange={(e) => handleChangeFilter(e, "tipo")}
              options={tiposSolicitacoes}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option}
            />
          </ItemGrid>
          <ButtonLong
            colorCustom="gray"
            noShadow
            onClick={handleClickFilter}
            className={classes.buttonFiltrar}
            disabled={solicitacoesPacientesStore.isLoading}
          >
            Filtrar
          </ButtonLong>
        </div>
      </div>
    );
  }
);

const SolicitacoesPacientesHeaderWithStyles = withStyles(styles)(
  SolicitacoesPacientesHeader
);
export default inject("solicitacoesPacientesStore")(
  SolicitacoesPacientesHeaderWithStyles
);
