import React, {Component} from 'react'

import HourSelectable from './HourSelectable';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flex: 1,
    },
});

class List extends Component {
    state = {
        items: this.props.weekday.values
    };

    componentDidUpdate(prevProps) {
        if (this.props.weekday.isNew) {
            this.setState({items: this.props.weekday.values});
            this.props.weekday.isNew = false;
        }
    }

    render() {
        const {classes, lastItem, weekday} = this.props;

        return (

            <div className={classes.root}>

                {this.state.items.map((item, i) => {

                    return <HourSelectable
                        lastCol={lastItem}
                        weekday={weekday}
                        selected={item.selected}
                        key={i}
                        item={item}
                    />
                })}

            </div>
        )
    }
}

export default withStyles(styles)(List);