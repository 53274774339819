import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const ChevronIcon = props => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M7.4343 4.73431C7.12188 5.04673 7.12188 5.55327 7.4343 5.86569L12.4686 10.9L7.4343 15.9343C7.12188 16.2467 7.12188 16.7533 7.4343 17.0657C7.74672 17.3781 8.25325 17.3781 8.56567 17.0657L14.1657 11.4657C14.4781 11.1533 14.4781 10.6467 14.1657 10.3343L8.56567 4.73431C8.25325 4.4219 7.74672 4.4219 7.4343 4.73431Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default ChevronIcon
