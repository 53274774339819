import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PhotoIcon = props => {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M11.4746 13.7179L11.5582 13.6469C11.8174 13.4576 12.1705 13.4552 12.432 13.6399L12.5254 13.7179L19.4531 20.5186C18.9578 20.8239 18.3745 21 17.75 21H6.25C5.62551 21 5.04216 20.8239 4.54692 20.5186L11.4746 13.7179L11.5582 13.6469L11.4746 13.7179ZM17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 18.3771 20.8224 18.9626 20.5148 19.4592L13.5762 12.6475L13.4477 12.5306C12.615 11.8293 11.3941 11.8273 10.5592 12.5248L10.4238 12.6475L3.4852 19.4592C3.17758 18.9626 3 18.3771 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM15.7521 6C14.5083 6 13.5 7.00831 13.5 8.25212C13.5 9.49592 14.5083 10.5042 15.7521 10.5042C16.9959 10.5042 18.0042 9.49592 18.0042 8.25212C18.0042 7.00831 16.9959 6 15.7521 6ZM15.7521 7.5C16.1675 7.5 16.5042 7.83673 16.5042 8.25212C16.5042 8.6675 16.1675 9.00423 15.7521 9.00423C15.3367 9.00423 15 8.6675 15 8.25212C15 7.83673 15.3367 7.5 15.7521 7.5Z" fill="currentColor"/>
    </svg>
  );
};

export default PhotoIcon;
