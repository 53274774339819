import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  ClickAwayListener,
  MenuItem,
  Paper,
  Popper,
} from "@material-ui/core/es";
import MoreIcon from "../Icon/More";
import { Button } from "../ui/Buttons";

const PopperCustomMenu = forwardRef((props, ref) => {
  const {
    placement,
    menuOpcoes,
    colorButton,
    iconButton,
    disabledButton,
    children,
    colorIcon,
    closePopperAfterClickOption,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenPopper = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const closePopper = (event) => {
    if (anchorEl?.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      closePopper,
      handleOpenPopper,
    };
  });

  const handleClickItem=(item,e)=>{
    item.onClick(e)
    closePopper()
  }

  return (
    <div ref={ref}>
      {children || (
        <Button
        id="buttonCircle"
        kind="transparent"
        bgColor={
          !!colorButton?.background ? colorButton.background :
          "#505050"
        }
        color={
          !!colorButton?.background ? "#fff" :
          "#505050"
        }
        shape='circle'
        onClick={handleOpenPopper}
        disabled={disabledButton}
      >
        {iconButton || <MoreIcon color={colorIcon || "#5F6368"}/>}
      </Button>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        disablePortal={true}
        style={styles.popperMenu}
      >
        <ClickAwayListener onClickAway={closePopper}>
          <Paper style={styles.popperPaper}>
            {menuOpcoes.map((item, index) => {
              return (
                item.ativo && (
                  <MenuItem
                    key={index}
                    style={styles.menuItem}
                    onClick={() => {
                      item.onClick();
                      if (closePopperAfterClickOption) {
                        closePopper();
                      }
                    }}
                  >
                    {item.icon}
                    <div style={styles.marginLabel}> {item.label} </div>
                  </MenuItem>
                )
              );
            })}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
});

const styles = {
  popperMenu: {
    zIndex: 9999,
  },
  popperPaper: {
    width: "auto",
  },
  marginLabel: {
    marginLeft: 6,
  },
};

export default PopperCustomMenu;
