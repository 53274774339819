import {gridContainer} from "../appHealth.style";
import colors from "../../../template/Colors";

const styles = theme => ({
    root: {
        ...gridContainer,
        overflow: "hidden"
    },

    content: {
        gridArea: "content",
        display: "grid",
        gridTemplateRows: 'auto 1fr',
        position: 'relative',
        overflow: "hidden"
    },


    inputError: {
        border: '1px solid ' + theme.palette.commons.red
    },

    headerTitleRoot: {
        maxWidth: theme.commons.menuLeft.width,
        margin: '0'
    },

    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1
    },

    leftSpace: {
        padding: '10px'
    },

    titleHeader: {
        whiteSpace: 'nowrap',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },

    tableContainer: {
        display: "grid",
        gridRowGap: "24px",
        alignContent: "start",
        overflowY: 'auto',
        padding: "2% 4%",
        backgroundColor: '#F5F5F5',
    },

    radioButtonGroupContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    radioButtonGroupLabel: {
        margin: 0
    },

    gridCardContainerPerfil: {
        display: "grid",
        gridGap: "24px",
        width: "90%",
        paddingBottom: 20,
        marginLeft: 0,
        gridTemplateColumns: "1fr 1fr",

        "@media (max-width: 1100px)": {
			gridTemplateColumns: "1fr",
			gridTemplateRows: "auto auto auto",
		},
    },

    numeroBairro: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '8px'
    },

    acoes: {
        position: 'absolute',
        right: '35px',
        bottom: '35%',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonCancelar: {
        margin: 8,
        height: 40,
        width: 40,
        background: colors.commons.red,
        color: '#fff',
        "&:hover": {
            background: colors.commons.darkRed,
        }
    },

    buttonSalvar: {
        margin: 8,
        height: 40,
        width: 40,
        background: colors.primary.main,
        color: '#fff',
        "&:hover": {
            background: colors.primary.dark,
        }
    },

    inputWithCheckbox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    formControlLabelCheckbox: {
        marginTop: 5,
    },

    input: {
        width: "100%",
        border: 'none',
        borderRadius: '10px',
        paddingLeft: '8px',
        backgroundColor: '#F2F2F2',
        fontSize: '14px',
        color: colors.commons.gray9,
        '&:focus': {
            borderColor: colors.primary.main
        }
    },

    withoutPadding: {
        padding: 0,
    },

    footer: {
        gridArea: "footer",
        paddingLeft: 15,
        paddingRight: 45,
        display: "flex"
    },

    botaoAdicionarRemedio:{
        display: 'flex',
        flexDirection: 'row',
        height: 36,
        width: 240,
        marginLeft: 0,
        color: 'white',
        borderRadius: '100px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        background: colors.commons.yellow,
    },

    labelBotaoAdicionarRemedio:{
        color: 'white',
        fontWeight: 500,
    },
    dadosPublicos:{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "24px",
        width: "90%",
        [theme.breakpoints.down('md')]:{
            maxWidth: 425,
            gridTemplateColumns: "1fr",
        },
    },
    dadosPublicosColumn: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px'
    },
    dadosPublicosInputs:{
        gridGap: "24px",
        display: "grid",
        gridTemplateColumns: "50% 45%",
        [theme.breakpoints.down('md')]:{
            display: "block",
        },
    },
    radioButton: {
		margin: "0 0 0 10px",
		'& label': {
			color: `${colors.commons.secondary} !important`,
			fontWeight: 'bold',
		},
	},
    radioButtonVermelho: {
		fontWeight: 600,
		fontSize: 10,
		"& label": {
			color: `${colors.commons.red} !important`,
			fontFamily: "Poppins !important",
		},
		"&:checked": {
			background: `${colors.commons.red} !important`,
			color: `${colors.commons.red} !important`,
		},
	},
    inputEnderecoEspanha: {
        width: '100%',
    },
    notchedOutline: {
		border: '0',
	},
	inputTextField: {
		fontSize: '0.875rem',
		border: '0',
		minHeight: '0.375rem',
		height: 32,
		color: colors.commons.fontColor,
		backgroundColor: colors.commons.gray2,
		margin: '0',
		borderRadius: '6.25rem',
	},
	classIcons: {
		fontSize: 24,
	},
    inputMask: {
		color: '#000',
		border: 'none',
		borderRadius: 0,
		fontSize: '14px',
		padding: '8px 0 0',
	},
    inputMaskError: {
		border: 'none',
		borderBottom: `1px solid ${colors.commons.red}`,
		fontFamily: 'Poppins',
		borderRadius: 0,
		fontSize: '14px',
		padding: '8px 0 0',
	},
    inputDadosCadastraisPaciente: {
		padding: "5px 0 10px 0 !important",
		width: "100%",
		fontFamily: "Poppins !important",
		"& ::placeholder": {
			color: colors.commons.darkGray,
			opacity: 1,
		},
	},
    selectableCardsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '40px',
        rowGap: '40px'
    },
    cardPaddingRight: {
        paddingRight: '25px'
    },
    selectError: {
        border: '1px solid ' + theme.palette.commons.red,
        borderRadius: '100px',
        background: '#EEEEEE',
        height: '32px', 
    },
    viziActivationCard: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px'
    },
    viziWarningText: {
		fontSize: '12px !important',
		fontWeight: '400 !important',
		color: '#5F6368',
	},
    inputContainer:{
		width: 'calc( 100% - 20px )',
		background: '#F2F2F2',
		color: '#505050',
		borderRadius: '8px',
		height: '32px',
		padding: '4px 8px',
	},
    cardText: {
        fontSize: '12px !important',
        fontWeight: '400 !important',
        color: '#5F6368',
    }
});

export default styles;