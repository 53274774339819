import { background, height, overflow, padding } from "styled-system";
import colors from "../../../../template/Colors";

export const style = ({
  pdfContainer:{
    height:"calc(100% - 27px)",
    overflow:"Scroll",
  },
  contentSwitch:{
    color:"#868686",
    fontSize:"12px",
    paddingRigth:"10px",
  },
  paper: {
    overflow: "unset",
    maxWidth: '960px',
    height: '630px',
    "& .jodit-container": {
      background: '#fff'
    }
  },
  fullSizePaper: {
    display: 'flex',
    flexDirection: 'row',
    overflow: "unset",
    maxWidth: '1038px',
    backgroundColor: 'transparent',
    height: '630px'
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    borderRadius: '15px',
    background: '#F2F2F2'
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    backgroundColor: 'white',
    padding: '16px 24px'
  },
  headerArea: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1
  },
  searchInputLabel: {
    color: '#868686 !important',
    fontSize: '12px !important'
  },
  contentHeaderSearch:{
    display: 'flex',
    flexDirection:"column",
    gap:"10px"
  },
  loading: {
    alignSelf: 'center'
  },
  notchedOutline: {
    border: 'solid 1px rgba(220, 220, 220, 1) !important',
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    height: 32,
    margin: '0 0 0 0',
    borderRadius: '100px',
    fontFamily: "Poppins"
  },
  inputsContainer: {
    display: 'flex',
    gap: '16px',
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    width: '200px',
    '&>input': {
      borderRadius: '8px',
    },
    '&>label': {
      fontSize: '12px',
      color: "#868686",
    }
  },
  modalTitle: {
    fontSize: '18px !important',
    color: '#333333 !important',
    fontWeight: '700',
  },
  closeButton: {
    height: '40px',
    width: '40px',
    backgroundColor: '#FB7676'
  },
  minimizeButton: {
    height: '40px',
    width: '40px',
    backgroundColor: colors.commons.gray10
  },
  wrapperButtons: {
    display: 'flex',
    gap: '8px'
  },
  dialogContent: {
    height: '100%',
    overflow: 'hidden',
    padding: '10px 24px 16px'
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px rgba(0, 0, 0, 0.10)',
    borderRadius: '12px',
    gap: '36px'
  },
  emptyMessage: {
    color: '#5F6368',
    fontSize: '18px !important',
    fontWeight: '600'
  },
  emptyMessageLink: {
    color: '#219A97',
    fontSize: '18px !important',
    fontWeight: '600',
    textDecorationLine: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textEditorWrapper: {
    maxWidth: '100%',
    height: '100%',
    overflow: "hidden"
  },
  textEditorScroll: {
    height: '100%',
    maxHeight: '100%',
    '& .jodit-react-container': {
      height: '100%',
      overflow: 'hidden',
      '& .jodit-container': {
        height: '100% !important',
        '& .jodit-workplace': {
          height: 'calc(100% - 39px) !important'
        }
      },
      '& .jodit_fullsize':{
        height: '100vh !important',
      }
    }
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px',
    background: "#ffff"
  },
  printButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px'
  },
  selectDocumento : {
    width: '100%',
  },
  buttonSave: {
    backgroundColor: colors.commons.secondary,
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
    '&:hover': {
      background: colors.primary.dark,
    },
  },
  contratoContainer:{
    width:"100%",
    height:"100%",
    overflow:"auto"
  }
});
