import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const VoucherIcon = props => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M12.7372 2.73034C12.0538 2.04692 10.9458 2.04692 10.2624 2.73034L2.53014 10.4626C1.84672 11.146 1.84672 12.254 2.53014 12.9374L3.3053 13.7126C3.71183 14.1191 4.29094 14.0495 4.6512 13.8523C4.82853 13.7553 5.03205 13.7 5.25 13.7C5.94036 13.7 6.5 14.2596 6.5 14.95C6.5 15.1679 6.44464 15.3714 6.34762 15.5487C6.15049 15.909 6.08082 16.4881 6.48735 16.8947L7.26237 17.6697C7.94579 18.3531 9.05383 18.3531 9.73725 17.6697L17.4695 9.93744C18.1529 9.25403 18.1529 8.14599 17.4695 7.46257L16.6944 6.68748C16.2879 6.28101 15.7089 6.3506 15.3486 6.54766C15.1714 6.64463 14.9679 6.69995 14.75 6.69995C14.0596 6.69995 13.5 6.14031 13.5 5.44995C13.5 5.23207 13.5553 5.0286 13.6523 4.85131C13.8493 4.49105 13.9189 3.91203 13.5125 3.50556L12.7372 2.73034Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default VoucherIcon
