import React from 'react';
import { withStyles } from '@material-ui/core';
import InputDateForm from '../../../../../components/Input/InputDateForm';
import classNames from 'classnames';
import moment from 'moment';

const styles = ({
  date: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    border: 'none',
    width: '124px',
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    padding: '6px 0 6px 8px'
  },
  input: {
    flex: 1,
    width: 'fit-content',
    color: '#505050',
    fontWeight: '600'
  },
  error: {
    color: '#FB7676' 
  },
  canceled: {
    textDecoration: 'line-through'
  }
});

const TableCellDateInput = ({value, onChange, classes, field, rowId, isModified, isCanceled}) => {
  const handleChangeDate = (value) => {
    onChange(rowId, value, field);
  }

  const checkError = typeof isModified === 'boolean';
  const checkCanceled = typeof isCanceled === 'boolean';

  return (
    <InputDateForm
      value={value}
      onChange={handleChangeDate}
      classes={{
        date: classes.date,
        input: classNames(
          classes.input, 
          checkError && !isModified ? classes.error : null,
          checkCanceled && isCanceled ? classes.canceled : null
        )
      }}
      minDate={moment().format('YYYY-MM-DD')}
      minDateMessage=""
    />
  )
}

export default withStyles(styles)(TableCellDateInput);