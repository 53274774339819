import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import styles from "./GuiaServicosFaturamentoStyle";
import { buildUrlFotoPerfil } from "../../../../config/config";
import GracefulImage from "../../../../components/Image/GracefulImage";
const profileDefault = require("../../../../assets/img/svg/img-profile-default.svg");

const DadosGuiaServicoFaturamento = (props) => {
  const { classes, guiaServicoSelected } = props;

  return (
    <div className={classes.dadosGuiaServicoFaturamento}>
      <GracefulImage
        src={
          guiaServicoSelected.beneficiario?.sujeitoAtencao?.fotoPerfil
            ? buildUrlFotoPerfil(
                guiaServicoSelected.beneficiario?.sujeitoAtencao?.fotoPerfil
              )
            : profileDefault
        }
        className={classes.imageMini}
        alt={"Imagem Perfil"}
        height="60"
        placeholderColor={"#00ACA9"}
      />
      <div className={classes.infosPaciente}>
        <div className={classes.nomePaciente}>
          {guiaServicoSelected.beneficiario.nome}
        </div>
        <div className={classes.infoPaciente}>
          N° guia no prestador:
          <div> {guiaServicoSelected.numeroGuiaPrestador} </div>
        </div>
        <div className={classes.infoPaciente}>
          Convênio: <div> {guiaServicoSelected.convenio.descricao} </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(DadosGuiaServicoFaturamento);
