import Api from "../config/api";

export const findAllAnamneseModelo = async (variables) => {
  try {
    const response = await Api.post("", {
      query: `
      query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
        findAllAnamneseModelo(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
          last
          content {
            ativo
            padrao
            id
            nome
            perguntas {
              id
              pergunta
              tipo
              order
              respostas {
                id
                nome
                valorResposta:valor
              }
            }
            profissionaisSaude {
              id
            }
          }
        }
      }           
    `,
      variables: variables,
    });
    return response.data.data.findAllAnamneseModelo;
  } catch (error) {
    console.log(error);
  }
};

export const findAnamnesePadrao= async ()=>{
 const response= await Api.post ("",{
  query:`query {
    findAnamnesePadrao {
      ativo
      id
      nome
      padrao
      perguntas{
        pergunta
        id
        tipo
        respostas {
          id
          nome
          valor
        }
      }
    }
  }`})
  if(response.data.error){
    throw Error('Erro ao buscar anamnese padrao')
  }
  return response.data.data.findAnamnesePadrao
}

export const saveAnamneseModelo = async(anamneseModelo) => {
  const method = anamneseModelo.id
    ? "updateAnamneseModelo"
    : "createAnamneseModelo";
  const response = await Api.post("", {
    query: `
        mutation($anamneseModelo: AnamneseModeloInput){
            ${method}(anamneseModelo: $anamneseModelo){
              id
              nome
            }
          }`,
    variables: { anamneseModelo },
  });
  if(response.data.errors){
    throw Error(response.data.errors[0].message)
  }
  return response;
};

export const saveAnamneseModeloByProfissionais = async(anamneseModelo, profissionaisSaude) => {
  const method = anamneseModelo.id
    ? "updateAnamneseModelo"
    : "createAnamneseModelo";
  const response = await Api.post("", {
    query: `
      mutation($anamneseModelo: AnamneseModeloInput){
        ${method}(anamneseModelo: $anamneseModelo) {
          id
        }
      }
    `,
    variables: {
      anamneseModelo: {
        ...anamneseModelo,
        profissionaisSaude,
      }
    },
  });
  if(response.data.errors){
    throw Error(response.data.errors[0].message)
  }
  return response;
}

export const ativarDesativarAnamneseModelo = (id) => {
  return Api.post("", {
    query: `
        mutation($id: UUID){
            ativarDesativarAnamneseModelo(id: $id){
              id
              nome
            }
          }
        `,
    variables: { id },
  });
};

export const findByIdAnamneseModelo = async (id) => {
  const response = await Api.post("", {
    query: `
    {
      findByIdAnamneseModelo(id: "${id}") {
        ativo
        nome
        padrao
        perguntas {
          pergunta
          id
          tipo
          order
          respostas {
          nome
          }
        }
        profissionaisSaude {
          id
        }
      }
    }
    `,
  });

  if(response.data.errors){
    throw Error(response.data.errors[0].message)
  }

  return response.data.data.findByIdAnamneseModelo;
};

export const saveAnamnese = async(variables) => {
  
  const response = await Api.post("", {
    query: `
        mutation($anamneseModelo: AnamneseModeloInput, $sujeitoAtencao: SujeitoAtencaoInput){
          createAnamnese(anamneseModelo: $anamneseModelo, sujeitoAtencao: $sujeitoAtencao){
              id
            }
          }`,
    variables: variables
  });
  if(response.data.errors){
    throw Error(response.data.errors[0].message)
  }
  return response;
};

export const findAnamneseByIdEntradaProntuario = async id => {
  const response = await Api.post("", {
    query: `
    {
      findAnamneseByIdEntradaProntuario(id: ${id}){
        ativo
        id
        nome
        perguntas{
          id
          pergunta
          tipo
          respostas{
            id
            nome
            valorConvertido
          }
        }
      }
    }
    `
  });

  return response.data.data.findAnamneseByIdEntradaProntuario
}
