import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import React from "react";
import {
  Star as StarIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";
import {
  Radio,
  RadioGroup,
} from "../../../../components/RadioButton/RadioButtonModal";
import styles from "../SADTStyle";
import { TypeModalEnum } from "../../../../stores/SADT.store";
import { observer } from "mobx-react-lite";
import Sugestoes from "./Sugestoes";
import Modelos from "./Modelos";

const tipoListagem = [
  { name: "Sugestões", icon: StarIcon, value: "sugestoes", selected: true },
  { name: "Modelos", icon: DescriptionIcon, value: "modelos", selected: false },
];

const ListagemLateral = observer((props) => {
  const { classes, SADTStore } = props;
  const { typeModal } = SADTStore;

  const onChangeTipoListagem = (tipoListagem) => {
    SADTStore.tipoListagemSelecionada = tipoListagem;

    if (tipoListagem === "sugestoes") {
      SADTStore.changeFieldsStore({
        sugestoesPagAtual: 0,
        sugestoes: [],
      })

      SADTStore.findAllGuiaServicoTussViewQuantidadeUso({
        pageableDTO: {
          pageSize: 6,
          pageNumber: SADTStore.sugestoesPagAtual,
          sortDir: "DESC",
          sortField: "quantidadeUso",
        },
      });
    } else {
      SADTStore.changeFieldsStore({
        modelosPagAtual: 0,
        modelos: [],
      })

      SADTStore.findAllGuiaServicoModelo({
        searchDTO: {
          ativo: true,
        },
        pageableDTO: {
          pageSize: 10,
          pageNumber: SADTStore.modelosPagAtual,
          sortDir: "DESC",
          sortField: "quantidadeUso",
        },
      });
    }
  };

  return (
    <div className={classes.lateralSugestoes}>
      <div className={classes.radioButton}>
        <RadioGroup name="tipoListagem" />
        {typeModal === TypeModalEnum.SADT_MODAL ? (
          tipoListagem.map((item) => (
            <Radio
              key={item.value}
              value={item.value}
              className={classes.botoesSugestoesModelosProntos}
              checked={SADTStore.tipoListagemSelecionada === item.value}
              icon={item.icon}
              onChange={() => onChangeTipoListagem(item.value)}
              label={item.name}
            />
          ))
        ) : (
          <div className={classes.tituloSugestao}>
            <StarIcon style={{ marginRight: 10 }} />
            Sugestões
          </div>
        )}
      </div>
      {SADTStore.tipoListagemSelecionada === "sugestoes" && <Sugestoes/>}
      {SADTStore.tipoListagemSelecionada === "modelos" &&  <Modelos onChangeTipoListagem={onChangeTipoListagem}/>}
    
    </div>
  );
});

const ListagemLateralWithStyles = withStyles(styles)(ListagemLateral);
export default inject("SADTStore")(ListagemLateralWithStyles);
