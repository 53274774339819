import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import SelectForm from "../../../../components/Select/Select";
import styles from "../../../../assets/jss/pages/configuracaoReceitaStyle";
import TextFieldMetricUnit from "../../../../components/TextField/TextFieldMetricUnit";

const TamanhoPapel = observer((props) => {
  const { classes, configuracaoImpressaoStore } = props;
  const {
    configuracaoImpressao,
    errors,
    handleChangeInput,
    handleChangeUnit,
  } = configuracaoImpressaoStore;

  const larguraError = errors.largura
  const alturaError = errors.altura

  return (
    <>
      <Grid item xs={4} className={classes.formGroupTamanhoPapel}>
        <div className={classes.input}>
          <Typography component="h6">Tamanho do Papel</Typography>
          <SelectForm
            elements={configuracaoImpressaoStore.getTamanhoPapelList()}
            value={configuracaoImpressao.tamanhoPapel}
            onChange={(e) => handleChangeInput("tamanhoPapel", e)}
          />
        </div>
      </Grid>
      <Grid item xs={12} xl={10} className={classes.formGroup}>
        <Grid container direction="row">
          <Grid item xs={6}>
            <div className={classes.numberStepGroup}>
              <Typography component="label">Largura</Typography>
              <TextFieldMetricUnit
                unit="cm"
                value={configuracaoImpressao.largura}
                onUnitChange={(e) => handleChangeUnit("largura", e)}
                error={larguraError}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.numberStepGroup}>
              <Typography component="label">Altura</Typography>
              <TextFieldMetricUnit
                unit="cm"
                value={configuracaoImpressao.altura}
                onUnitChange={(e) => handleChangeUnit("altura", e)}
                error={alturaError}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

const TamanhoPapelWithStyles = withStyles(styles)(TamanhoPapel);
export default inject("configuracaoImpressaoStore")(TamanhoPapelWithStyles);
