import React, { useEffect } from "react";
import { checkUserRoles } from "../../utils";

export const withHasAuthority = (WrappedComponent) => {
  const WithProtection = ({ condition, roles, ...rest }) => {
    const [loading, setLoading] = React.useState(true);
    const [hasAuthority, setHasAuthority] = React.useState(true);

    useEffect(() => {
      (async () => {
        try {
          setLoading(true);
          const shouldSkip = !roles || roles?.length === 0;
          const hasAuthority =
            shouldSkip || (await checkUserRoles(roles, condition));
          setHasAuthority(hasAuthority);
        } catch (e) {
          setLoading(false);
          setHasAuthority(false);
        } finally {
          setLoading(false);
        }
      })();

      setLoading(false);
    }, [roles]);

    return (
      <WrappedComponent
        {...rest}
        hasAuthority={hasAuthority}
        isLoading={loading}
        condition={condition}
        roles={roles}
      />
    );
  };

  return WithProtection;
};
