import React from 'react';
import classNames from 'classnames';
import Moment from 'moment';
import {withStyles} from "@material-ui/core/styles/index";
import {CircularProgress, ClickAwayListener, Tooltip} from '@material-ui/core';
import {inject, observer} from 'mobx-react';
import string from '../../../utils/string';
import {applyPhoneMask} from "../../../utils/PhoneMask";
import { getStatusAtendimento, getStatusRowAtendimento } from '../../../utils';
import ColumnInformation from '../DayCalendar/ColumnInformation';

function convertStyle() {
    return {
        height: 44,
        minHeight: 44,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
}

const convertStyleTodosIcons = () => {
    return {
        height: 60,
        minHeight: 60,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
}

@inject('atendimentoStore')
@observer
class EventEncaixe extends React.Component {
    state = {
        showBarraStatus: false,
        loading: false,
        isAniversarioProximo: false,
        isAniversariante: false,
        nomesProcedimentosPedido: '',
    };

    constructor(props) {
        super(props);
        this.cardRef = React.createRef();
        this.timeoutIdHideBarraStatus = null;
    }

    componentDidMount() {
        const { event } = this.props;
        event.dataNascimento && this.verificaAniversario();
        event.procedimentoPedido && event.procedimentoPedido[0]?.procedimentos?.length > 0 && this.verificaProcedimentoPedido();
    }

    componentDidUpdate(prevProps) {
        if (this.props.event !== prevProps.event) {
            this.props.event.dataNascimento && this.verificaAniversario();
        }
    };

    componentWillUnmount() {
        if(this.timeoutIdHideBarraStatus) {
            clearTimeout(this.timeoutIdHideBarraStatus);
            this.timeoutIdHideBarraStatus = null;
        }
    }

    handleContextMenu = (e) => {
        e.preventDefault();
    };

    handleMouseUp = (e) => {
        if (typeof e === 'object' && e.button === 2) {
            this.handleRemoveEvent();
        }
    };

    verificaProcedimentoPedido = () => {
        const { event } = this.props;
        const {procedimentoPedido} = event;

        let nomeProcedimento = 'Procedimentos solicitados: ';

        procedimentoPedido[0].procedimentosSolicitados.forEach(element => {
            nomeProcedimento = `${nomeProcedimento} ${element.procedimento.nome},`
        });

        this.setState({
            nomesProcedimentosPedido: nomeProcedimento
        })
    };

    handleSelectEvent = () => {
        const { onSelectEvent, event } = this.props;

        onSelectEvent(event);
    };

    handleRemoveEvent = () => {
        this.setState(() => {
            const { onRemoveEvent, event } = this.props;
            onRemoveEvent(event);
        });
    };

    handleClickBarraStatus = (e) => {
        e.stopPropagation();
        this.setState(state => ({ showBarraStatus: !state.showBarraStatus }));
    };

    handleMouseOverBarraStatus = () => {
        if(!this.state.showBarraStatus) {
            this.setState({ showBarraStatus: true });
            return;
        }
        if(this.timeoutIdHideBarraStatus) {
            clearTimeout(this.timeoutIdHideBarraStatus);
            this.timeoutIdHideBarraStatus = null;
        }
    };

    handleMouseLeaveBarraStatus = () => {
        if(this.state.showBarraStatus && !this.timeoutIdHideBarraStatus) {
            this.timeoutIdHideBarraStatus = setTimeout(() => {
                this.setState({ showBarraStatus: false }, () => this.timeoutIdHideBarraStatus = null);
            }, 800);
        }
    };

    handleCloseBarraStatus = (e) => {
        e.stopPropagation();
        this.setState({ showBarraStatus: false });
    };

    handleClickAlteraStatus = (e, situacao) => {
        e.stopPropagation();

        const { atendimentoStore, event } = this.props;
        const backColor = getStatusRowAtendimento(event.status);

        this.setState({ backColor: backColor });

        this.setState({ showBarraStatus: false, loading: true }, async () => {
            await atendimentoStore.updateSituacaoAgendamento({ id: event.id, situacao });
            this.setState({ loading: false });
        });
    };

    verificaAniversario = () => {
        const { event } = this.props;

        const {isAniversarioProximo, isAniversariante} = string.verificaAniversarioProximoADataAtual(event.dataNascimento, 5);
        this.setState({ 
            isAniversarioProximo,
            isAniversariante
        })
    };

    render() {
        const { classes, style, event, possuiTodosIcons ,salaDay} = this.props;
        const { showBarraStatus, loading, isAniversarioProximo, isAniversariante, nomesProcedimentosPedido } = this.state;
        const { observacao } = event;
        const backgroundColorByStatus = getStatusRowAtendimento(event.status);
        const possuiProcedimentoSolicitado = event.procedimentoPedido && event.procedimentoPedido[0]?.procedimentos?.length > 0;
        const zeroGapIcons = nomesProcedimentosPedido && observacao && isAniversarioProximo;

        return (
            <React.Fragment>
                <div
                    ref={this.cardRef}
                    className={`
                        ${classes.root} 
                        ${classes.dayCalendarInner}
                    `}
                    style={possuiTodosIcons ? {...convertStyleTodosIcons(style)} : {...convertStyle(style)}}
                    onClick={this.handleSelectEvent}
                    onContextMenu={event.id && this.handleContextMenu}
                    onMouseUp={event.id && ((e) => this.handleMouseUp(e))}>
                        <React.Fragment>
                            <div className={classNames(classes.dayCalendarColumn, 'barra-status', possuiTodosIcons && classes.dayCalendarColumnRowIcons)}
                                style={{
                                    width: '79px',
                                    minWidth: '79px',
                                    alignItems: 'center',
                                    backgroundColor: getStatusAtendimento(event.status).color
                                }}
                                onMouseOver={event.id ? this.handleMouseOverBarraStatus : null}
                                onMouseLeave={event.id ? this.handleMouseLeaveBarraStatus : null}
                            >
                                <div className={classes.dayCalendarColumnLabelHour}>
                                    -
                                </div>
                                <ClickAwayListener onClickAway={this.handleCloseBarraStatus}>
                                    <div
                                        onClick={event.id ? this.handleClickBarraStatus : null}>
                                        <div
                                            className={classNames('barra-status-inner', { show: showBarraStatus })}>
                                            {this.props.atendimentoStore.situacoesAlteracaoStatus.map(statusItem => (
                                                <Tooltip
                                                    title={statusItem.name}
                                                    key={statusItem.value}
                                                    placement="right"
                                                >
                                                    <div
                                                        className="status-button"
                                                        style={{background: getStatusAtendimento(statusItem.value).color}}
                                                        onClick={(e) => this.handleClickAlteraStatus(e, statusItem.value)}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <div
                                className={classNames(classes.dayCalendarColumn, possuiTodosIcons && classes.dayCalendarColumnRowIcons)}
                                style={{backgroundColor: backgroundColorByStatus}} >
                                    <div className={classNames(classes.dayCalendarColumnLabel, classes.columnNome, {[classes.dayCalendarColumnLabelAniversario]: possuiProcedimentoSolicitado || observacao || isAniversarioProximo })}>
                                    <div className={classNames(classes.contentIcons, zeroGapIcons && classes.gapIcons)}>
                                    </div>
                                    <div className={classNames(classes.dayCalendarColumnLabel)}>
                                    {event.sujeitoAtencaoNome || event.nome}
                                    </div>
                                    </div>
                            </div>

                            <div
                                className={classNames(classes.dayCalendarColumn, possuiTodosIcons && classes.dayCalendarColumnRowIcons)}
                                style={{backgroundColor: backgroundColorByStatus}} >
                                <div className={classes.dayCalendarColumnLabel}>{event.idade}</div>
                            </div>
                            <div
                                className={classes.dayCalendarColumnTipo}
                                style={{backgroundColor: backgroundColorByStatus}} >
                                <div className={classes.dayCalendarColumnLabel}>{event.tipo}</div>
                            </div>
                            <div
                                className={classes.dayCalendarColumnTelefone }
                                style={{backgroundColor: backgroundColorByStatus}} >
                                <div className={classes.dayCalendarColumn && classes.phoneColumnLabel}>{applyPhoneMask(event.sujeitoAtencaoTelefone || event.telefone)}</div>
                            </div>
                            <div
                                className={classNames(classes.dayCalendarColumn)}
                                style={{backgroundColor: backgroundColorByStatus}} >
                                <div className={classes.dayCalendarColumnLabel}>{ event.sujeitoAtencaoConvenio || event.convenio}</div>
                            </div>

                            
                            <div className={classes.dayCalendarColumn}>
                            <ColumnInformation 
                                    event={event} 
                                    handleClickWhatsapp={this.handleClickWhatsapp} 
                                    nomesProcedimentosPedido={nomesProcedimentosPedido}
                                    isAniversarioProximo={isAniversarioProximo}
                                    isAniversariante={isAniversariante}
                                    salaDay={salaDay}
                                    verificaProcedimentoPedido={this.verificaProcedimentoPedido}
                                    openNotification={this.props.atendimentoStore.openNotification}
                                    hiddenOrigemAgendamento
                                />
                            </div>

                        </React.Fragment> 
                </div>

                {(loading && event.id) && (
                        <div className={classes.loading} style={{...convertStyle(style)}}>
                            <CircularProgress color="primary" size={24} />
                        </div>
                    )}
            </React.Fragment>
        )
    }
}

const styles = theme => ({
    root: {
        position: 'absolute',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&:hover': {
            backgroundColor: '#efefef',
        },
        '& .strike': {
            color: theme.palette.commons.red,
        },
        '& .barra-status': {

            '& .barra-status-inner': {
                backgroundColor: '#fff',
                display: 'none',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '79px',
                boxSizing: 'border-box',
                flexWrap: 'wrap',

                '&.show': {
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width:'79px',
                    zIndex: 4,
                    height: '100%',
                },

                '& .status-button': {
                    width: 15,
                    height: 15,
                    marginLeft: 4,
                    borderRadius: '50%',

                    '&:last-child': {
                        marginBottom: 0,
                    }
                },
            },
        },
        '&.evento-bloqueado': {
            zIndex: 2,
            '& div': {
                '&.bloqueado': {
                    backgroundColor: '#FB7676',
                    height: '1px',
                    width: '95%',
                    borderRadius: '100px',
                },
            },
            '& .hora-bloqueada': {
                color: '#BDBDBD!important',
                fontFamily: 'Nexa Bold!important',
                fontSize: 18,
                textAlign: 'center',
                '& div': {
                    fontSize: '1.1em',
                    marginTop: -2,
                    display: 'block'
                },
            },
        },
        '&.evento': {
            zIndex: 3,
        },
        '& div': {
            color: '#777',
            fontSize: 15,
            fontWeight: 500,
            userSelect: 'none',
            '&.convenio': {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            "@media (max-width: 1368px)": {
                fontSize: 13
            },
            "@media (max-width: 1199px)": {
                fontSize: 11
            }
        },
    },

    dayCalendarInner: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '80px 2fr 140px 1fr 2fr 2fr 3fr',
        gridColumnGap: '16px',
        overflowY: 'auto',
        overflowX: 'hidden',
        color: '#868686 !important',
        textAlign:'left'
    },
    dayCalendarColumnLabel: {
        color: '#868686!important',
        fontSize: '14px!important',
        fontFamily: "Poppins !important",
        overflowX: 'hidden',
        overflowY: 'hidden',
    },

    dayCalendarColumnLabelHour: {
        color: '#ffffff!important',
        fontSize: '14px!important',
        alignItems: 'center',
        marginLeft: '0px',
        fontFamily: "Poppins !important",
    },

    dayCalendarColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minWidth: '140px',
        minHeight: '40px',
        height: '100%',
        
    },

    dayCalendarColumnTipo:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '110px',
        minHeight: '40px',
        height: '100%',
    },

    dayCalendarColumnTelefone:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '180px',
        minHeight: '40px',
        height: '100%',
    },

    button: {
        backgroundColor: theme.palette.commons.purple,
        color: '#fff',
        fontSize: 10,
        position: 'absolute',
        top: 3,
        right: 3,
        minHeight: 22,
        minWidth: 22,
        maxHeight: 22,
        maxWidth: 22,
        padding: 0,
    },

    loading: {
        zIndex: "10",
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(239, 239, 239, 0.5)"
    },

    dayCalendarColumnLabelAniversario: {
        marginLeft: '4px',
    },

    contentIcon: {
        width: '18px',
        height: '18px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    colorYellow: {
        background: '#F9BE73',
    },
    colorGray: {
        background: '#868686',
    },
    colorBlue: {
        background: '#5462E0',
    },
    colorPink: {
        background: '#FC71B4',
    },
    columnNome: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    contentIcons: {
        gap: '2px',
        display: 'flex',
        flexDirection: 'column',
    },
    gapIcons: {
        gap: '1px',
    },
    dayCalendarColumnRowIcons: {
        minHeight: '60px',
    },
});

export default withStyles(styles)(EventEncaixe);
