import { CircularProgress, IconButton, withStyles } from '@material-ui/core';
import React from 'react';
import { 
  Grid, 
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Dialog from '../../../components/Dialog/Dialog';
import {
  Save as SaveIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

import Colors from "../../../template/Colors";
import { TextFieldSearch } from "../../../components/TextField";
import SolicitacaoProcedimentoList from './SolicitacaoProcedimentoList';
import { inject, observer } from 'mobx-react';
import Notification from "../../../components/Notification";
import { InputForm } from "../../../components/Input/InputDadosCadastraisPaciente";
import { createByProcedimentoModelo, saveSolicitacaoProcedimento } from "../../../services/SolicitacaoProcedimentoService";
import { findByAgendamentoProcedimentoPedido } from "../../../services/AgendamentoService";

import ModalProcedimentosRealizados from '../../SujeitoAtencao/Manutencao/ModalProcedimentosRealizados';
import AlertDialog from '../../../components/Dialog/AlertDialog';
import InformacoesImportantesModal from "../InformacoesImportantes/InformacoesImportantesModal";
import { checkUserRole } from '../../../utils/checkUserRole';
import { getProfissionalSaudeLogado } from '../../../services/UsuarioService';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TabSelector from '../../../components/TabSelector';
import { Button } from '../../../components/ui/Buttons';
import TrashIcon from '../../../components/Icon/Trash';
import classNames from 'classnames';
import InputSearch from "../../../components/Input/Input";
import AddIcon from '../../../components/Icon/AddIcon';
import ItemGrid from '../../../components/Modal/ItemGrid';
import SearchIcon from '../../../components/Icon/Search';
import Scroll from '../../../components/InfiniteScroll/Scroll';
import string from '../../../utils/string';
import { findAllProcedimentoModelo } from '../../../services/ProcedimentoModeloService';
import ModalCreateModelo from './ModalCreateModelo';
import PrintIcon from '../../../components/Icon/Print';
import ImpressaoHtml from '../../../components/Impressao/ImpressaoHtml';
import SolicitacaoImpressaoRelatorio from './components/SolicitacaoImpressaoRelatorio';

@inject ('procedimentoStore', 'prontuarioStore', "atendimentoStore", "configuracaoImpressaoStore")
@observer
class SolicitacaoProcedimentoModal extends React.Component {
  state={
    procedimentos: [],
    procedimento: null,
    isEditingProcedimento: false,
    lastIdProcedimento: 1,
    procedimentoEdit: null,
    loading: false,
    notification: {
      isOpen: false,
      message: ''
    },
    observacoes: "",
    idSolicitacaoProcedimento: "",
    agendamentoSelecionadoId: "",
    tabSelecionada: this.props.prontuarioStore.agendamentoSelected?.procedimentos.length > 0 ? 1 : 0,
    alertDialogOpts: {
      openAlert: false,
      alertTitle: '',
    },
    procedimentoSelecionadoIndice: null,
    showInformacoesImportantesModal: false,
    procedimentosModelos: [],
    pageProcedimentosModelos: 0,
    hasMoreProcedimentosModelos: true,
    searchProcedimentosModelos: '',
    loadingProcedimentosModelos: false,
    selectModeloTab: 0,
    openModalCreateModelo: false,
    modelosSelecionados: [],
    openPrint: false,
    loadingPrint: false,
  };
  
  async componentDidMount(){
    const { prontuarioStore } = this.props;
    const { sujeitoAtencaoSelected, agendamentoSelected } = prontuarioStore;

    this.verificaSolicitacaoPedido();
    this.setState({agendamentoSelecionadoId: agendamentoSelected?.id});

    const profissionalSaudeLogado = await getProfissionalSaudeLogado();
    this.props.atendimentoStore.idSolicitanteDoProcedimento = profissionalSaudeLogado;

    const roleEntradaProntuarioRead = checkUserRole('ROLE_ENTRADA_PRONTUARIO_READ');
    this.setState({showInformacoesImportantesModal: roleEntradaProntuarioRead && sujeitoAtencaoSelected?.informacoesImportantes?.length > 0});
    await this.loadAllModelosProcedimentos(true, this.state.selectModeloTab === 0 ? 'quantidadeUso' : 'nome') 
  };

  verificaSolicitacaoPedido = async () => {
    const { prontuarioStore } = this.props;

    await findByAgendamentoProcedimentoPedido(
      prontuarioStore.agendamentoSelected.id
    ).then(response => {
      const pedido = response?.data?.data?.findByAgendamentoProcedimentoPedido;

      if(!!pedido){
        const pedidoNaoAgendado = pedido.procedimentosSolicitados.filter(item => !item.agendado);
  
        const listaAdaptada = pedidoNaoAgendado.map(item => ({
          label: item.procedimento.nome,
          id: item.procedimento.id
        }));

        this.setState({ 
          procedimentos: listaAdaptada,
          observacoes: pedido.observacao || "",
          idSolicitacaoProcedimento: pedido.id || ""
        })
      }
    })
  }

  handleChangeProcedimento = (e) => {
    this.setState({
      procedimento: e
    });
  };

  handleChangeProcedimentoEdit = (e) => {
    this.setState({
      procedimentoEdit: e
    });
  };

  handleProcedimentoLoadOptions = async(search, loadedOptions, { page }) => {
    return this.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllProcedimentos"
  });
  };

  handleLoadMoreOptions = async ({ search, data, method }) => {
    const { procedimentoStore } = this.props;

    const response = await procedimentoStore[method]({
      nome: search,
      pageableDTO: {
        pageNumber: data.page,
        pageSize: 30,
        sortDir: "ASC"
      }
    });
    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1
      }
    };
};

  handleConfirmar = async(id) => {
    const { procedimentoEdit, procedimentos } = this.state;
    if (!procedimentoEdit){
      const notification = {
        isOpen: true,
        message: 'Favor informar o procedimento.'
      }
      this.showAlertMessage(notification);
      return;
    }
    const posicao = await procedimentos.findIndex(procedimento => procedimento.id === id)
    procedimentos.splice(posicao, 1, procedimentoEdit)
    this.setState({
      procedimentoEdit: null
    });
  };

  handleAdicionar = async() => {
    const { procedimento, tabSelecionada } = this.state;
    const { prontuarioStore } = this.props;

    if (!procedimento){
      const notification = {
        isOpen: true,
        message: 'Favor informar o procedimento.'
      }
      this.showAlertMessage(notification);
      return;
    };

    if (tabSelecionada === 0) {
      await this.setState((prevState) => ({
        procedimentos: [...prevState.procedimentos, procedimento]
      }));
  
      this.setState({
        procedimento: null
      });
    } else {
      const novoProcedimento = {
        procedimento: {
          ativo: procedimento.ativo,
          descricao: procedimento.descricao,
          id: procedimento.id,
          nome: procedimento.nome
        },
        realizado: false
      }

      const procedimentos = [...prontuarioStore?.agendamentoSelected?.procedimentos, novoProcedimento];
      prontuarioStore.updateAgendamentoProcedimentos(procedimentos, prontuarioStore?.agendamentoSelected?.id)
    }
  };

  handleEditProcedimento= (procedimentoSelecionado) => {
    const { id } = procedimentoSelecionado;
    this.setState({
      isEditingProcedimento: true,
      [`procedimentos`]: this.state[`procedimentos`]
        .map((procedimento) => procedimento.id === id ? 
          { ...procedimento, isEditing: true} 
          : 
          procedimento),
      procedimentoEdit: procedimentoSelecionado
    });
  };

  handleSaveProcedimento = (id) => {
    this.setState((prevState) => ({
      isEditingprocedimento: false,
      [`procedimentos`]: prevState[`procedimentos`].map((procedimento) =>
          procedimento.id === id ? { ...procedimento, isEditing: false } : procedimento
      ),
      procedimentoEdit: null
  }));
  };

  handleRemoveProcedimento = (id, index) => {
    let {procedimentos} = this.state
    procedimentos.splice(index, 1)
    this.setState({procedimentos})
  };

  handleRemoverProcedimentoRealizar = (index) => {
    const {prontuarioStore} = this.props;
    const procedimentoNome = prontuarioStore?.agendamentoSelected?.procedimentos[index].procedimento.nome; 

    this.setState({
      alertDialogOpts: {
        openAlert: true,
        alertTitle: `Deseja remover o procedimento ${procedimentoNome} da lista que serão realizados?`,
      },
      procedimentoSelecionadoIndice: index
    });
  }

  confirmarRemoverProcedimentoRealizar = () => {
    const {prontuarioStore} = this.props;
    const procedimentos = [...prontuarioStore?.agendamentoSelected?.procedimentos];
    const {procedimentoSelecionadoIndice} = this.state;
  
    procedimentos.splice(procedimentoSelecionadoIndice, 1);
    prontuarioStore.updateAgendamentoProcedimentos(procedimentos, prontuarioStore?.agendamentoSelected?.id)

    this.setState({
      alertDialogOpts: {
        openAlert: false
      },
      procedimentoSelecionadoIndice: null
    });
  }

  onObservacaoChange = e => {
    const { value } = e.target;
    this.setState({
      observacoes: value
    })
  };

  showAlertMessage = (notification) => {
    this.setState({ notification });
    
    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: ''
    };
    this.setState({ notification });
  };

  handleChangeTab = (index) => {
    this.setState({tabSelecionada: index})
  }

  realizarProcedimento = (procedimento) => {
    const { atendimentoStore } = this.props;
    atendimentoStore.openModalRealizarProcedimentos = true
    atendimentoStore.realizarProcedimento(procedimento.procedimento);
  }
  handleCloseModalRealizarProcedimento = () =>{
    this.props.atendimentoStore.openModalRealizarProcedimentos = false
  }

  loadAllModelosProcedimentos = async (clear = false, sortField) => {
    const { pageProcedimentosModelos, searchProcedimentosModelos } = this.state;
    this.setState({loadingProcedimentosModelos: true})
    try {
      const variables = {
        pageableDto: {
          pageNumber: clear ? 0 : pageProcedimentosModelos,
          pageSize: 30,
          sortDir: 'ASC',
          sortField: sortField || 'nome'
        },
        search: searchProcedimentosModelos,
        ativo: true
      }
      const { content, last } = await findAllProcedimentoModelo(variables)
      this.setState({hasMoreProcedimentosModelos: !last})
      this.setState({pageProcedimentosModelos: clear ? 0 : pageProcedimentosModelos + 1})
      this.setState({procedimentosModelos: clear || this.state.pageProcedimentosModelos === 0 ? content : [...this.state.procedimentosModelos, ...content]})
    } catch (error) {
      console.error(error)
    } finally { 
      this.setState({loadingProcedimentosModelos: false})
    }
   }

   handleSearch = async (e) => {
    this.setState({searchProcedimentosModelos: e.target.value})
    await this.loadAllModelosProcedimentos(true)
   }

   onKeypressSearch = (e) => {
      if (e.key === 'Enter') {
        this.handleSearch()
      }
    }

  handleChangeModeloTab = (index) => {
    this.setState({selectModeloTab: index, pageProcedimentosModelos: 0, procedimentosModelos: []})

    if (index === 0) {
      this.loadAllModelosProcedimentos(true, 'quantidadeUso')
    } else {
      this.loadAllModelosProcedimentos(true, 'nome')
    }
  }

  handleClickModelo = async (item) => {
    try {
      const { procedimentos } = this.state;
      const newProcedimentos = item.procedimentos.map(procedimento => {
        return {
          ...procedimento,
          idModelo: item.id
        }
      })
      this.setState({procedimentos: [...procedimentos, ...newProcedimentos], 
        modelosSelecionados: [...this.state.modelosSelecionados, item.id]})
    } catch (error) {
      console.error(error)
    }
  } 

  handleSave = async () => {
    const { procedimentos, observacoes, idSolicitacaoProcedimento, agendamentoSelecionadoId } = this.state;
    this.setState({loading: true})
    try {
      const procedimentosAdaptado = procedimentos.map(procedimento => {
        return {
            procedimento: {
              id: procedimento.id
            }
        }
      });
  
      const procedimentoPedido = idSolicitacaoProcedimento === "" ? 
      {
        observacao: observacoes,
        agendamento: {id: agendamentoSelecionadoId},
        procedimentosSolicitados: procedimentosAdaptado,
        sujeitoAtencao: {id: this.props.sujeitoAtencao.id}
      } 
      :
      {
        id: idSolicitacaoProcedimento,
        agendamento: {id: agendamentoSelecionadoId},
        observacao: observacoes,
        procedimentosSolicitados: procedimentosAdaptado,
        sujeitoAtencao: {id: this.props.sujeitoAtencao.id}
      }
      const existProcedimentosByModelo = procedimentos.some(procedimento => procedimento.idModelo)
      
      if (existProcedimentosByModelo) {
        await createByProcedimentoModelo({
          observacao: observacoes,
          agendamentoId: agendamentoSelecionadoId,
          sujeitoAtencaoId: this.props.sujeitoAtencao.id,
          procedimentoModelos: this.state.modelosSelecionados.map(id => ({id})),
          procedimentos: procedimentosAdaptado.filter(procedimento => !procedimento.idModelo) || []
        })
      } else {
        await saveSolicitacaoProcedimento(procedimentoPedido);
      }
      this.props.handleClose();
      this.props.prontuarioStore.openNotification("Procedimento solicitado!", "success");
    } catch (error) {
      this.props.prontuarioStore.openNotification("Erro ao solicitar procedimento!", "error");
      console.error(error)
    } finally { 
      this.setState({loading: false})
    }
  }

  toggleModalCreateModelo = () => {
    this.setState({openModalCreateModelo: !this.state.openModalCreateModelo})
  }

  handlePrintRelatorioSolicitados = async () => {
    const { configuracaoImpressaoStore } = this.props;
    await configuracaoImpressaoStore.getConfig('OUTROS');
    this.setState({ openPrint: true, loadingPrint: true });
  }

  render(){
    const { openModal, classes, handleClose, prontuarioStore, atendimentoStore } = this.props;
    const { 
      procedimento, 
      procedimentos, 
      tabSelecionada, 
      showInformacoesImportantesModal, 
      agendamentoSelecionadoId, 
      openPrint, 
      loadingPrint 
    } = this.state;
    const { openModalRealizarProcedimentos } = atendimentoStore;
    const { sujeitoAtencaoSelected, agendamentoSelected } = prontuarioStore;

    return (
      <>
        <PageTitle title='Atendimento - Procedimento' />
        {openModalRealizarProcedimentos && (
          <ModalProcedimentosRealizados
            handleCloseSolicitacaoModal={this.props.handleClose.bind(this)}
            handleClose={this.handleCloseModalRealizarProcedimento}
            idAgendamento={agendamentoSelecionadoId}
            idSujeitoAtencao={sujeitoAtencaoSelected?.id}
            showModal={openModalRealizarProcedimentos}
            isProntuario
            pageTitle='Atendimento - Realizar procedimento'
          />
        )}
        <Dialog
          classes={{
            scrollPaper: classes.scrollPaper,
            paper: showInformacoesImportantesModal
              ? classes.fullSizePaper
              : classes.paper,
          }}
          maxWidth='md'
          open={openModal}
          fullWidth
        >
          {showInformacoesImportantesModal && (
            <div>
              <InformacoesImportantesModal openedInsideModal={openModal} />
            </div>
          )}
          <div className={classes.procedimentoModalContent}>
            <DialogTitle
              classes={{ root: classes.dialogHeader }}
              disableTypography
            >
              <Grid className={classes.dialogTitle}>
                <h1>Procedimento</h1>
              </Grid>
              <Grid className={classes.wrapperTabSelector}>
                <TabSelector
                  tabsOptions={[
                    { label: 'Solicitar Procedimento' },
                    { label: 'Realizar Procedimento' },
                  ]}
                  baseColor='#F2F2F2'
                  selectedColor='#FFF'
                  onSelect={(index) => this.handleChangeTab(index)}
                  selectedTabIndex={tabSelecionada}
                />
              </Grid>
              <Grid
                container
                alignItems={'flex-end'}
                className={classes.wrapperAddProcedimento}
              >
                <Grid item xs={10} className={classes.inputLabel}>
                  <TextFieldSearch
                    classNotched={classes.notchedOutline}
                    classInput={classes.inputTextField}
                    classIcons={classes.classIcons}
                    value={procedimento}
                    placeholder={'Pesquise por código ou procedimento'}
                    onChange={this.handleChangeProcedimento}
                    withPaginate
                    debounceTimeout={300}
                    additional={{
                      page: 0,
                    }}
                    loadOptions={this.handleProcedimentoLoadOptions}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    kind='secondary'
                    onClick={this.handleAdicionar}
                    bgColor='#F9BE73'
                    fullWidth
                  >
                    Adicionar
                  </Button>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent
              className={[
                classes.contentContainer,
                tabSelecionada === 1 ? classes.contentWithBottomBorder : null,
              ]}
            >
              {tabSelecionada === 0 ? (
                <div className={classes.content}>
                  {procedimentos.length > 0 && (
                    <SolicitacaoProcedimentoList
                      procedimentos={procedimentos}
                      handleEdit={(id) => this.handleEditProcedimento(id)}
                      handleSave={(id) => this.handleSaveProcedimento(id)}
                      handleRemove={(id, index) =>
                        this.handleRemoveProcedimento(id, index)
                      }
                      handleChangeProcedimento={
                        this.handleChangeProcedimentoEdit
                      }
                      loadProcedimentos={this.handleProcedimentoLoadOptions}
                      readOnly={false}
                      procedimentoEdit={this.state.procedimentoEdit}
                      handleConfirmar={this.handleConfirmar}
                    />
                  )}
                </div>
              ) : (
                <div
                  className={classNames(
                    classes.content,
                    classes.wrapperProcedimentos
                  )}
                >
                  {prontuarioStore?.agendamentoSelected?.procedimentos?.map(
                    (procedimento, index) => (
                      <div
                        key={index}
                        className={classes.realizarProcedimentoItemContainer}
                      >
                        <div className={classes.procedimento}>
                          <h1 className={classes.nomeProcedimento}>
                            {procedimento.procedimento.nome}
                          </h1>
                          <div className={classes.procedimentoButtonsContainer}>
                            <Button
                              onClick={() =>
                                this.realizarProcedimento(procedimento)
                              }
                            >
                              Realizar
                            </Button>
                            <Button
                              bgColor={'#FB7676'}
                              shape={'circle'}
                              onClick={() =>
                                this.handleRemoverProcedimentoRealizar(index)
                              }
                            >
                              <TrashIcon size='24' />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </DialogContent>
            {tabSelecionada === 0 && (
              <Grid className={classes.footer}>
                <div className={classes.observacoes}> Observações gerais: </div>
                <div className={classes.boxObservacoes}>
                  <InputForm
                    id='observacoes'
                    placeholder='Digite aqui as observações do procedimento.'
                    value={this.state.observacoes}
                    className={classes.inputObervacoes}
                    rowsMax={5}
                    rows={5}
                    multiline
                    onChange={(e) => this.onObservacaoChange(e)}
                  />
                </div>
              </Grid>
            )}
            <div className={classes.footerActions}>
              {tabSelecionada === 0 ? (
                <Button 
                  kind='transparent' 
                  onClick={this.handlePrintRelatorioSolicitados}
                  disabled={procedimentos?.length === 0 || loadingPrint}
                >
                  <PrintIcon size={24} color="#505050"/>
                  Imprimir
                </Button>
              ) : (
                <Button onClick={handleClose} kind='transparent'>
                  Cancelar
                </Button>
              )}
              {tabSelecionada === 0 && (
                <Button
                  onClick={this.handleSave}
                  kind='primary'
                  isLoading={this.state.loading}
                >
                  <SaveIcon />
                  Salvar
                </Button>
              )}
            </div>
          </div>
          <div className={classes.containerModeloProcedimentos}>
            <div className={classes.headerModelos}>
              <Button kind='transparent' shape='square' onClick={handleClose}>
                <CloseIcon color='#fff' />
              </Button>
            </div>
            <div className={classes.wrapperTabModelos}>
              <TabSelector
                tabsOptions={[
                  { label: 'Recentes' },
                  { label: 'Modelos criados' },
                ]}
                baseColor='#F2F2F2'
                selectedColor='#FFF'
                onSelect={(index) => this.handleChangeModeloTab(index)}
                selectedTabIndex={this.state.selectModeloTab}
              />
            </div>
            <Button
              kind='secondary'
              fullWidth
              onClick={this.toggleModalCreateModelo}
            >
              <AddIcon />
              Criar novo modelo
            </Button>
            <ItemGrid
              label='Pesquisar modelos'
              classes={{
                itemTitle: classes.itemTitle,
              }}
            >
              <InputSearch
                hideStartAdornment
                endAdornment={
                  <IconButton onClick={this.handleSearch}>
                    <SearchIcon />
                  </IconButton>
                }
                placeholder='Pesquisar'
                value={this.state.searchProcedimentosModelos}
                onChange={this.handleSearch}
                onKeyPress={this.onKeypressSearch}
                classInputRoot={classes.inputSearch}
              />
            </ItemGrid>
            <div className={classes.contentModelosProcedimentos}>
              <h3>Modelos de procedimentos</h3>
              <Scroll
                loadMore={() =>
                  this.loadAllModelosProcedimentos(
                    false,
                    this.state.selectModeloTab === 0 ? 'quantidadeUso' : 'nome'
                  )
                }
                hasMore={this.state.hasMoreProcedimentosModelos}
                pageStart={0}
                initialLoad={false}
              >
                <div className={classes.wrapperModelosProcedimentos}>
                  {this.state.procedimentosModelos.length === 0 &&
                    !this.state.loadingProcedimentosModelos && (
                      <div className={classes.cardProcedimento}>
                        <h1>Nenhum modelo encontrado</h1>
                      </div>
                    )}
                  {this.state.loadingProcedimentosModelos && (
                    <div className={classes.wrapperLoading}>
                      <CircularProgress />
                    </div>
                  )}
                  {this.state.procedimentosModelos.map(
                    (procedimento, index) => (
                      <div
                        key={index}
                        className={classes.cardProcedimento}
                        onClick={() => this.handleClickModelo(procedimento)}
                      >
                        <h1>MODELO</h1>
                        <span>{string.truncate(procedimento.nome, 18)}</span>
                      </div>
                    )
                  )}
                </div>
              </Scroll>
            </div>
          </div>
          <Notification
            close={this.closeAlertMessage}
            reset={this.closeAlertMessage}
            isOpen={this.state.notification.isOpen}
            variant={'error'}
            message={this.state.notification.message}
          />
        </Dialog>
        {this.state.openModalCreateModelo && (
          <ModalCreateModelo
            open={this.state.openModalCreateModelo}
            onClose={this.toggleModalCreateModelo}
            refetch={() =>
              this.loadAllModelosProcedimentos(
                true,
                this.state.selectModeloTab === 0 ? 'quantidadeUso' : 'nome'
              )
            }
          />
        )}
        <AlertDialog
          open={this.state.alertDialogOpts.openAlert}
          onClose={() =>
            this.setState({ alertDialogOpts: { openAlert: false } })
          }
          onCancel={() =>
            this.setState({ alertDialogOpts: { openAlert: false } })
          }
          onOk={this.confirmarRemoverProcedimentoRealizar}
          alertTitle={this.state.alertDialogOpts.alertTitle}
        />
        {openPrint && (
          <ImpressaoHtml
            sujeitoAtencaoId={sujeitoAtencaoSelected.id}
            handlePrintMustache={() => this.setState({ openPrint: false, loadingPrint: false })}
            htmlStringComponent={
              <SolicitacaoImpressaoRelatorio
                agendamento={agendamentoSelected}
                procedimentosSolicitados={procedimentos}
              />
            }
          />
        )}
      </>
    );
  }
};

const style = {
  procedimento:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '8px 16px',
    margin: '8px 0',
  },
  nomeProcedimento: {
    width: 'calc(100% - 210px)',
    fontSize: '14px',
    fontWeight: '600',
    color: '#868686',
  },
  procedimentoButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    '& > button': {
      padding: '8px 16px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 10,
    overflow: "unset",
    height: 650,
    background: '#F9F9F9',
  },
  fullSizePaper: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 15,
    overflow: "unset",
    height: 650,
    width: 'fit-content',
    background: 'transparent',
    maxWidth: '1024px',
  },
  procedimentoModalContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundColor: '#E5E5E5',
    borderRadius: '10px 0 0 10px',
    maxWidth: '669px'
  },
  dialogHeader:{
    borderRadius: '10px 0px 0px 0px',
    padding: '16px 24px',
    background: '#fff',
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    zIndex: 1,
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: "center",
    '&>h1': {
        color: '#333333',
        fontSize: '24px',
        fontWeight: '600',
        margin: 0,
    },
  },
  buttonSave:{
    backgroundColor: Colors.commons.secondary,
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
    boxShadow: 'none',
    "&:hover":{
        background: Colors.primary.dark
    },        
  },
  headerModelos:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& > button': {
      padding: '2px',
      color: '#fff',
    },
  },
  buttonPrint:{
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
    background: Colors.commons.yellow,
    boxShadow: 'none',
    "&:hover": {
        background: '#e0a048',
    },        
  },
  buttonClose: {
    backgroundColor: Colors.commons.red,
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
    boxShadow: 'none',
    "&:hover":{
      background: Colors.commons.darkRed
    }
  },
  linhaHeader:{
    display: 'flex',
    flexDirection: 'row',
  },
  inputLabel: {
    display: 'inline-flex',
    flexDirection: 'column',
    paddingRight: '8px',
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '14px',
    minHeight: '6px',
    height: 40,
    color: Colors.commons.fontColor,
    backgroundColor: '#F2F2F2',
    margin: '0 0 0 0',
    border: '0.5px solid rgba(220, 220, 220, 0.2)',
    borderRadius: '8px',
    fontFamily: "Poppins"
  },
  contentContainer:{
    display: 'flex',
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
    backgroundColor: '#E5E5E5',
    margin: '0 16px 16px 16px',
    padding: 0,
    height: '100%',
    overflow: 'hidden',
    borderRadius: '0 0 15px 15px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '0 24px 24px 24px',
    overflowY: 'scroll',
  },
  contentWithBottomBorder: {
    borderRadius: '0 0 8px 8px'
  },
  realizarProcedimentoItemContainer: {
    width: '100%',
  },
  observacoes:{
    color: "#505050",
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 6,
  },
  footer:{
    maxHeight: 134,
    height: 134,
    padding: '0 16px 16px',
    borderRadius: '0 0 10px 10px'
  },
  boxObservacoes:{
    background: '#FFF',
    color: '#505050',
    borderRadius: 10,
    padding: '0px 8px'
  },
  inputObervacoes:{
    width: "100%",
    overflow: "auto",
  },
  wrapperTabSelector:{
    margin: '0.5rem 0',
  },
  wrapperProcedimentos:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'scroll',
    paddingTop: '16px',
  },
  wrapperAddProcedimento:{
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem 0',
  },
  containerModeloProcedimentos:{
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px',
    overflowY: 'hidden',
    backgroundColor: '#219A97',
    width: '380px',
    borderRadius: '0 8px 8px 0',
    '& > button': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  },
  footerActions:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',

    '& > button': {
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    }
  },
  contentModelosProcedimentos:{
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    maxHeight: 'calc(100% - 200px)',
    '& > h3': {
      color: '#fff',
      fontSize: 14,
      fontWeight: 600,
      margin: '0 0 8px 0',
    }
  },
  wrapperModelosProcedimentos:{
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  cardProcedimento:{
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '8px 16px',
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#505050',
    cursor: 'pointer',

    '& > h1': {
      margin: 0,
      fontSize: 12,
      fontWeight: 400,
      color: '#868686',
    },
    '& > span': {
      fontSize: 12,
      fontWeight: 400,
      color: '#505050',
    }
  },
  inputSearch:{
    width: '100%',
    borderRadius: '8px',
    border: 'none',
    background: '#F2F2F2',
    padding: '0 8px',
  },
  itemTitle:{
    color: '#fff !important',
  },
  wrapperLoading:{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: 100
  }
};

export default withStyles(style)(SolicitacaoProcedimentoModal);