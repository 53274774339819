import React from 'react';
import { withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { getWhitelabelNome } from '../../../services/WhitelabelService';

const styles = ({
  container: {
    marginTop: '10px',
    padding: '16px 24px',
    backgroundColor: '#E3F4FE',
    borderRadius: '8px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '6px'
  },
  title: {
    fontSize: '14px',
    color: '#2E6483',
    fontWeight: '500',
  },
  content: {

  },
  contentText: {
    color: '#2E6483',
  },
});

const CartaoCreditoWarning = ({ classes, tax }) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <InfoIcon nativeColor='#2E6483' fontSize='small' />
        <span className={classes.title}>
          Taxas cobradas sobre o cartão de crédito
        </span>
      </div>
      <div className={classes.content}>
        <span className={classes.contentText}>
          Será cobrada uma taxa administrativa de {tax}% sobre todas os pagamentos realizados por cartão de crédito, dentro do sistema {getWhitelabelNome()}. Devido a taxa do gatway de pagamento.
        </span>
      </div>
    </div>
  )
};

export default withStyles(styles)(CartaoCreditoWarning);