import React from "react";
import { inject, observer } from "mobx-react/index";
import { withStyles } from "@material-ui/core/styles/index";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";
import Dialog from "../../../components/Dialog/Dialog";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import styles from "./MedicamentoStyle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { tabs } from "../../../stores/Auditoria.store";
import { findAllMedicamentoAuditByMedicamentoId } from "../../../services/AuditoriaService";
import Auditoria from "../../../components/Auditoria/Auditoria";
import InformacoesMedicamento from "./InformacoesMedicamento";
import ButtonLong from "../../../components/Button/ButtonLong";
import string from "../../../utils/string";

@inject("medicamentoPersonalizadoStore", "auditoriaStore")
@observer
class MedicamentoModal extends React.Component {
  state = {
    tabSelected: tabs.INFORMACOES,
    loadingSave: false,
  };

  componentDidMount() {
    const { auditoriaStore } = this.props;

    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      auditoria: "o medicamento",
      auditoriaTela: "medicamento",
    };
  }

  handleChange = (field, event) => {
    const { medicamentoPersonalizadoStore } = this.props;
    medicamentoPersonalizadoStore.medicamento[field] = event.target.value;
  };

  handleSalvar = async (e) => {
    try {
      e.preventDefault();
      this.setState({ loadingSave: true });

      const { medicamentoPersonalizadoStore } = this.props;
      const produto = medicamentoPersonalizadoStore.medicamento.produto;

      if(!produto || string.isEmpty(produto)) {
        medicamentoPersonalizadoStore.openNotification("Digite o nome do medicamento", 'error')
        return
      }

      const medicamento = {
        ...medicamentoPersonalizadoStore.medicamento,
      };

      if (medicamento.id) {
        await medicamentoPersonalizadoStore.update(medicamento);
      } else {
        await medicamentoPersonalizadoStore.create(medicamento);
      }
      medicamentoPersonalizadoStore.closeModal();
      medicamentoPersonalizadoStore.elements = [];
      medicamentoPersonalizadoStore.currentPage = null;
      medicamentoPersonalizadoStore.pageableDTO.pageNumber = 0;
      medicamentoPersonalizadoStore.findAllMedicamentoPersonalizado();
    } catch (error) {
      console.warn(error && error.message);
    } finally {
      this.setState({ loadingSave: false });
    }
  };

  handleClose = () => {
    const { onClose, medicamentoPersonalizadoStore } = this.props;

    medicamentoPersonalizadoStore.closeModal();

    if (typeof onClose === "function") {
      onClose();
    }
  };

  getPageTitle = () => {
    const { medicamentoPersonalizadoStore } = this.props;
    const { medicamento } = medicamentoPersonalizadoStore;

    const acao = !!medicamento.id ? "Editar" : "Novo";

    return pageConfiguracoes(`${acao} medicamento personalizado`);
  };

  changeTabSelected = async (tabSelected) => {
    const { auditoriaStore, medicamentoPersonalizadoStore } = this.props;
    const { medicamento } = medicamentoPersonalizadoStore;

    auditoriaStore.auditoriaProps = {
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    };

    if (tabSelected === 1) {
      auditoriaStore.load = {
        query: findAllMedicamentoAuditByMedicamentoId,
        variableName: "medicamentoId",
        variableId: medicamento.id,
      };
      await auditoriaStore.loadAuditItems();
    }

    this.setState({ tabSelected });
  };

  renderTab = () => {
    const { tabSelected } = this.state;

    return tabSelected === 0 ? <InformacoesMedicamento /> : <Auditoria />;
  };

  render() {
    const { classes, medicamentoPersonalizadoStore } = this.props;
    const { tabSelected, loadingSave } = this.state;
    const { medicamento } = medicamentoPersonalizadoStore;

    const isUpdate = !!medicamento.id;

    return (
      <>
        <PageTitle title={this.getPageTitle()} />
        <Dialog
          open={medicamentoPersonalizadoStore.open}
          classes={{
            paper: classes.dialogPaper,
          }}
          fullWidth={true}
        >
          <div className={classes.modalHeader}>
            <div className={classes.titleModal}>
              {isUpdate ? "Editar medicamento" : "Novo medicamento"}
            </div>
            <div className={classes.buttonClose} onClick={this.handleClose}>
              <CloseIcon />
            </div>
          </div>
          <MultiToggleButtons
            options={["Informações", "Auditoria"]}
            tabSelected={tabSelected}
            changeTabSelected={this.changeTabSelected}
            classes={{ buttonsContainer: classes.toggleButtons }}
            disabled={!medicamento.id}
            posicaoDesabilitada={[1]}
          />
          <div className={classes.contentModal}>{this.renderTab()}</div>

          {tabSelected === 0 && (
            <div className={classes.footer}>
              <div
                onClick={this.handleClose}
                className={classes.buttonCancelar}
              >
                Cancelar
              </div>
              <ButtonLong
                colorCustom="green"
                noShadow
                onClick={this.handleSalvar}
                disabled={loadingSave}
              >
                Salvar
                {loadingSave && (
                  <CircularProgress
                    color="inherit"
                    size={14}
                    style={{ marginLeft: 10 }}
                  />
                )}
              </ButtonLong>
            </div>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(MedicamentoModal);
