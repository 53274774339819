import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, withStyles } from "@material-ui/core";
import Notification from "../../../components/Notification";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Table from "../../../components/Table/Table";
import { consultaSujeitosDeAtencao, findAllFinanceiroGuiaConsulta, findByIdFinanceiroGuiaConsulta, listasDoFiltro } from "../../../services/GuiaDeConsultaService";
import HeaderFinanceiro from "../../Financeiro/HeaderFinanceiro";
import styles from "./GuiaDeConsultaStyles";
import MensagemListaVazia from "../../../components/Mensagem/MensagemListaVazia";
import ButtonFloat from "../../../components/ButtonFloat";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import GuiaDeConsultaModal from "./Modal/GuiaDeConsultaModal";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { guiaConsultaDefault } from "../../../stores/GuiaDeConsulta.store";
import { ausenciaCodigosValidacao } from "./Modal/Steps/Constants";

const columns = [
  {
    Header: "Nome",
    onlyIcon: true,
    getValue: (guiaConsulta) => {
      return guiaConsulta?.beneficiario?.sujeitoAtencao?.nomeSocial || guiaConsulta?.beneficiario?.sujeitoAtencao?.nome;
    },
    props: { component: "th" },
  },
  {
    Header: "Convenio",
    onlyIcon: true,
    getValue: (guiaConsulta) => {
      return guiaConsulta?.convenio?.descricao;
    },
  },
  {
    Header: "Tipo de agendamento",
    onlyIcon: true,
    getValue: (guiaConsulta) => {
      return guiaConsulta?.agendamentoTipo?.descricao;
    },
  },
  {
    Header: "N° guia prestador",
    onlyIcon: true,
    getValue: (guiaConsulta) => {
      return guiaConsulta?.numeroGuiaPrestador;
    },
  },
];

const primeiroDiaMesAtual = moment().startOf("month").format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment().endOf("month").format("YYYY-MM-DD");

const filterDefault = {
  convenio: "",
  search: "",
  dataInicial: primeiroDiaMesAtual,
  dataFinal: ultimoDiaMesAtual,
  sujeitoAtencao: "",
  agendamentoTipo: "",
};

const notificationDefault = {
  open: false,
  message: "Erro",
  type: "error",
};

const GuiaDeConsulta = observer((props) => {
  const { classes, guiaDeConsultaStore } = props;

  const [filter, setFilter] = useState(filterDefault);
  const [listasFilters, setListasFilters] = useState(null);
  const [guiasConsulta, setGuiasConsulta] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(notificationDefault);
  const [last, setLast] = useState(false);
  const [guiaConsultaSelected, setGuiaConsultaSelected] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    carregaListaFilters();
    loadGuiasConsulta();
  }, []);

  useEffect(() => {
    filter.search === "" && loadGuiasConsulta({ isSearching: true });
  }, [filter.search])

  const carregaListaFilters = async () => {
    const response = await listasDoFiltro();

    setListasFilters({
      convenio: response.data.data.findAllConvenioList,
      agendamentoTipo: response.data.data.findAllAgendamentoTipo,
    });
  };

  const loadGuiasConsulta = async(options) => {
    try {
      setLoading(true);
      options?.isSearching && setGuiasConsulta([]);

      const response = await findAllFinanceiroGuiaConsulta({
        pageableDTO: {
          pageNumber: options?.isSearching ? 0 : pageNumber,
          pageSize: 30,
          sortDir: "DESC",
          sortField: "numeroGuiaPrestador",
        },
        search: filter.search,
        dataFim: moment(filter.dataFinal).format("YYYY-MM-DD"),
        dataInicio: moment(filter.dataInicial).format("YYYY-MM-DD"),
        convenioId: filter.convenio?.id,
        sujeitoAtencaoId: filter.sujeitoAtencao?.value,
        agendamentoTipoId: filter.agendamentoTipo?.id
      });
      
      setLast(response.last);
      setGuiasConsulta(options?.isSearching ? response.content : [...guiasConsulta, ...response.content]);
      setPageNumber( options?.isSearching ? 1 : pageNumber+1)
    } catch {
      showAlertMessage({
        open: true,
        type: "error",
        message: "Erro ao carregar as guias de consulta.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setFilter({
      ...filter,
      search,
    });
  };

  const handleClickClearPesquisar = () => {
    setFilter({
      ...filter,
      search: "",
    });
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter" && filter.search.length >= 3) {
      loadGuiasConsulta({ isSearching: true });
    }
  };

  const handleChangeInputDate = (dataInicial, dataFinal) => {
    setFilter({
      ...filter,
      dataInicial,
      dataFinal,
    });
  };

  const handleChangeInput = (e, field) => {
    setFilter({
      ...filter,
      [field]: e
    });
  };

  const redefinirFiltros = () => {
    setFilter({
      ...filter,
      convenio: "",
      sujeitoAtencao: "",
      agendamentoTipo: "",
    });
  };

  const handleOpenModal = async(id) => {
    let guiaConsulta = guiaConsultaDefault;

    if(id) {
      const dadosGuiaConsulta = await findByIdFinanceiroGuiaConsulta(id);
      guiaConsulta = {
        ...dadosGuiaConsulta,
        conselhoProfissional: dadosGuiaConsulta.conselhoProfissionalSaude,
        codigoProcedimento: dadosGuiaConsulta.tuss22ProcedimentoEventoSaude,
        ausenciaCodigoValidacao: ausenciaCodigosValidacao.find(item => item.value === dadosGuiaConsulta.ausenciaCodigoValidacao),
        indicacaoAcidente: dadosGuiaConsulta.tuss36TerminologiaIndicadorAcidente,
      };
    };

    setGuiaConsultaSelected(guiaConsulta);
    guiaDeConsultaStore.changeFieldsStore({
      isOpenModal: true,
    })
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    setNotification(notificationDefault);
  };

  const openNotification = (message, variant = null) => {
    setNotification((prevState) => ({
      ...prevState,
      open: true,
      variant: variant,
      message: message,
    }));
  };

  const handleAplicar = () => {
    loadGuiasConsulta({ isSearching: true });
  };

  const handleSujeitoAtencaoLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllSujeitoAtencao",
    });
  };

  const handleLoadMoreOptions = async ({ search, data, method }) => {
    let searchDTO = {};

    if (data.searchDTO) {
      searchDTO = {
        ...data.searchDTO,
      };
    }

    const response = await consultaSujeitosDeAtencao({
      pageNumber: data.page,
      search,
      ...searchDTO,
    });

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const closeModal = () => {
    guiaDeConsultaStore.changeFieldsStore({
      isOpenModal: false,
      guiaConsulta: null,
    });
  };

  return (
    <div className={classes.content}>
      <PageTitle title="Financeiro - Guia de consultas" />
      <HeaderFinanceiro
        placeholder="Pesquisar"
        onChange={handleSearchChange}
        value={filter.search}
        search={() => loadGuiasConsulta({ isSearching: true })}
        clearSearch={handleClickClearPesquisar}
        onKeyPress={onKeypressSearch}
        searchFinanceiroGuiaConsulta={() => loadGuiasConsulta({ isSearching: true })}
        handleChangeInput={handleChangeInput}
        redefinirFiltros={redefinirFiltros}
        screen={"GuiaDeConsulta"}
        filter={filter}
        setFilter={setFilter}
        listas={listasFilters}
        handleSujeitoAtencaoLoadOptions={handleSujeitoAtencaoLoadOptions}
        propsDate={{
          handleChange: handleChangeInputDate,
          handleAplicar: handleAplicar,
        }}
        withFilter
        title="Guia de consultas"
      />
      <div className={classes.tableContainer}>
        {guiasConsulta.length > 0 && (
          <Scroll
            hasMore={!loading && !last}
            pageStart={0}
            initialLoad={false}
            loadMore={() => loadGuiasConsulta()}
          >
            <Table
              dados={guiasConsulta}
              columns={columns}
              clickable={true}
              handleClick={(id) => handleOpenModal(id)}
            />
          </Scroll>
        )}

        {loading && guiasConsulta.length === 0 && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <CircularProgress size={30} />
          </Grid>
        )}

        {!loading && guiasConsulta.length === 0 && <MensagemListaVazia />}

        <ButtonFloat onClick={() => handleOpenModal()} />
      </div>
      {guiaDeConsultaStore.isOpenModal && <GuiaDeConsultaModal
        openModal={guiaDeConsultaStore.isOpenModal}
        handleCloseModal={() => closeModal()}
        guiaConsultaSelected={guiaConsultaSelected}
        openNotification={openNotification}
        carregaListaGuiaConsulta={() => loadGuiasConsulta({ isSearching: true })}
      />}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.open}
        variant={notification.type}
        message={notification.message}
      />
    </div>
  );
});

const GuiaDeConsultaWithStyles = withStyles(styles)(GuiaDeConsulta);
export default inject("guiaDeConsultaStore")(GuiaDeConsultaWithStyles);
