import { observable, action } from "mobx";

import Api from "../../config/api";
import string from "../../utils/string";
import { findAllCategoriaFinanceira } from "../../services/FinanceiroService";
import BaseStore from "../Base.store";

export const categoriaFinanceiraTipo = [
  'DESPESA',
  'RECEITA'
];

const defaultObjView = {
  id: null,
  tipo: categoriaFinanceiraTipo[0],
  categoriaFinanceiraPai: null,
  nome: null,
  unidade: null
};

const defaultSearchDTO = {
  pageNumber: 0,
  pageSize: 20,
  sortDir: 'ASC',
  sortField: 'nome',
};

export const defaultFilters = {
  search: '',
  ativo: true,
};

export const defaultModalConfirmacao = {
  open: false,
  title: "",
  color: "",
  icon: null,
  buttons: null,
};

export const defaultModalCategoriaFilho = {
  open: false,
  saving: false,
  objView: {
    id: null,
    nome: "",
    tipo: "",
    categoriaPai: {
      id: null,
      nome: "",
      tipo: "",
    }
  }
};

export default class CategoriaFinanceiraStore extends BaseStore {
  usuarioStore = null;
  @observable currentPage = null;
  @observable last = false;
  @observable loading = true;
  @observable saving = false;
  @observable opening = false;
  @observable open = false;
  @observable filters = defaultFilters;
  @observable categoriaFinanceiraList = [];
  @observable objView = {
    ...defaultObjView
  };
  @observable searchDTO = {
    ...defaultSearchDTO
  }
  @observable modalConfirmacao = defaultModalConfirmacao;
  @observable modalCategoriaFilho = defaultModalCategoriaFilho;

  @action findAllCategoriaFinanceira = async(clear) => {
    try {
      let searchDTO = this.searchDTO;
      let list = this.categoriaFinanceiraList;

      if(clear) {
        searchDTO=defaultSearchDTO;
        list = []
      }
      this.loading = true;

      const response = await findAllCategoriaFinanceira({searchDTO: {
        ...searchDTO,
        ...this.filters
      }});
      const { content, last } = response;
      let categoriaFinanceira = []
      content.forEach(item => {
        const categoriasFilhos = item.categoriasFinanceiras;
        categoriaFinanceira.push(item);

        if(categoriasFilhos.length > 0) {
          categoriasFilhos.forEach(categoria => {
            const categoriaFilho = {
              ...categoria,
              categoriaFilho: true,
              categoriaPai: item,
              rowStyle: true
            };

            categoriaFilho.ativo && categoriaFinanceira.push(categoriaFilho)
          });
        }
      });

      this.categoriaFinanceiraList = [...list, ...categoriaFinanceira];
      this.last = last;
      this.searchDTO={
        ...searchDTO,
        pageNumber: searchDTO.pageNumber+1
      }
  
    } catch {
      this.openNotification(
        "Erro ao carregar as categorias financeira",
        "error"
      );
    } finally {
      this.loading = false;
    }
  };

  @action async findAll(searchDTO = {}) {
    try {
      if (this.currentPage === this.searchDTO.pageNumber) {
        return;
      }
      
      this.currentPage = this.searchDTO.pageNumber;
      
      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = unidadeAtual.id;

      this.searchDTO = {
        ...this.searchDTO,
        ...searchDTO
      };
      this.loading = true;
      
      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchFinanceiroDTOInput) {
            findAllCategoriaFinanceira(searchDTO: $searchDTO) {
              totalElements
              numberOfElements
              content {
                id
                ativo
                nome
                tipo
                categoriasFinanceiras {
                  id
                  nome
                }
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...this.searchDTO,
            ...searchDTO,
            unidadeId
          }
        }
      });

      const list = response.data.data.findAllCategoriaFinanceira.content;
      this.numberOfElements = response.data.data.findAllCategoriaFinanceira.numberOfElements;
      
      if (this.numberOfElements === 0 && string.isEmpty(this.searchDTO.search)) {
        return;
      }
      
      if(this.searchDTO.pageNumber > 1) {
        this.categoriaFinanceiraList = [...this.categoriaFinanceiraList, ...list];
      } else {
        this.categoriaFinanceiraList = [...list];
      }
      this.searchDTO.pageNumber += 1;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
  
  @action async findCategoriasFinanceirasPais(searchDTO = {}, categoriaFinanceiraTipo) {
    try {
      const defaultSearchDTO = {
        pageSize: 20,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome"
      };
      
      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchFinanceiroDTOInput) {
            findAllCategoriaFinanceira(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO,
            categoriaFinanceiraTipo
          }
        }
      });

      const { last, content } = response.data.data.findAllCategoriaFinanceira;
      
      const customContent = content.map(c => ({
        ...c,
        value: c.id,
        label: c.nome
      }));
      
      return {
        last,
        content: customContent,
      };
    } catch (error) {
      throw error;
    }
  }

  @action async save(categoriaFinanceira) {
    try {
      if (this.saving) {
        return;
      }

      if (!categoriaFinanceira) {
        throw new Error('Preencha os dados');
      }

      if (
        string.isEmpty(categoriaFinanceira.nome) ||
        string.isEmpty(categoriaFinanceira.tipo)
      ) {
        throw new Error('Preencha os dados');
      }
      
      const categoriaFinanceiraDados = {
        tipo: categoriaFinanceira.tipo,
        nome: categoriaFinanceira.nome,
      };

      let metodo = "create";
      if (categoriaFinanceira.id) {
        categoriaFinanceiraDados.id = categoriaFinanceira.id;
        categoriaFinanceiraDados.ativo = categoriaFinanceira.ativo;

        metodo = "update";
      }
      
      if (categoriaFinanceira.categoriaFinanceiraPai && categoriaFinanceira.categoriaFinanceiraPai.id) {
        categoriaFinanceiraDados.categoriaFinanceiraPai = {
          id: categoriaFinanceira.categoriaFinanceiraPai.id
        };
      }

      const usuarioLogado = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = usuarioLogado.id;
      
      this.saving = true;
      await Api.post("", {
        query: `
          mutation ($categoriaFinanceira: CategoriaFinanceiraInput) {
              ${metodo}CategoriaFinanceira(categoriaFinanceira: $categoriaFinanceira) {
                  id
              }
          }
        `,
        variables: {
          categoriaFinanceira: {
            ...categoriaFinanceiraDados,
            unidade: {
              id: unidadeId
            }
          }
        }
      });
      this.closeModal();
      this.findAllCategoriaFinanceira(true);
    } catch (error) {
      throw error;
    } finally {
      this.saving = false;
    }
  }

  @action edit(id) {
    this.loadById(id);
  }

  @action openNew() {
    this.open = true;
    this.objView = {
      ...defaultObjView
    };
  }

  @action async loadById(id) {
    try {
      this.opening = true;
      const response = await Api.post("", {
        query: `
            query ($id: Long) {
                findByIdCategoriaFinanceira(id: $id) {
                    id
                    nome
                    tipo
                    ativo
                    categoriaFinanceiraPai {
                      id
                      nome
                    }
                }
            }
        `,
        variables: {
          id
        }
      });

      const data = response.data.data.findByIdCategoriaFinanceira;
      const { categoriaFinanceiraPai } = data;
      
      if (categoriaFinanceiraPai && categoriaFinanceiraPai.id) {
        categoriaFinanceiraPai.value = categoriaFinanceiraPai.id;
        categoriaFinanceiraPai.label = categoriaFinanceiraPai.nome;
      }
      
      this.objView = {
        ...data,
        categoriaFinanceiraPai: categoriaFinanceiraPai || null,
      };
      this.open = true;

    } catch (error) {
      throw error;
    } finally {
      this.opening = false;
    }
  }

  @action closeModal() {
    this.open = false;
    this.objView = {
      ...defaultObjView
    };
  };

}
