import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const DocumentPillIcon = props => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M10 2V6.5C10 7.32843 10.6716 8 11.5 8H16V8.91338C15.2462 9.01788 14.5189 9.35994 13.9393 9.93957L9.93932 13.9396C8.84135 15.0375 8.59583 16.6652 9.20275 18H5.5C4.67157 18 4 17.3284 4 16.5V3.5C4 2.67157 4.67157 2 5.5 2H10ZM11 2.25V6.5C11 6.77614 11.2239 7 11.5 7H15.75L11 2.25ZM18.3535 10.6467C17.3298 9.62298 15.6701 9.62298 14.6464 10.6467L10.6464 14.6467C9.62274 15.6704 9.62274 17.3301 10.6464 18.3538C11.6701 19.3775 13.3298 19.3775 14.3535 18.3538L18.3535 14.3538C19.3772 13.3301 19.3772 11.6704 18.3535 10.6467ZM15.3535 11.3538C15.9867 10.7206 17.0133 10.7206 17.6464 11.3538C18.2796 11.9869 18.2796 13.0135 17.6464 13.6467L16 15.2931L13.7071 13.0002L15.3535 11.3538ZM13.8536 15.6464C14.0488 15.8417 14.0488 16.1583 13.8536 16.3536L12.8536 17.3536C12.6583 17.5488 12.3417 17.5488 12.1464 17.3536C11.9512 17.1583 11.9512 16.8417 12.1464 16.6464L13.1464 15.6464C13.3417 15.4512 13.6583 15.4512 13.8536 15.6464Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocumentPillIcon
