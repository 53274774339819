import React from "react";
import { inject } from "mobx-react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import InputForm from "../../../components/Input/InputForm";
import { withStyles } from "@material-ui/core/styles";
import Colors from "../../../template/Colors";
import ModelDocumentEditor from "../../../components/ModelDocumentEditor";
@inject("receitaStore", "receituarioStore")
class DrugForm extends React.Component {
  state = {
    adscricaoList: [],
  };

  handleChange = name => event => {
    this.props.onChange(name, event.target.value, this.props.recipe.id);
  };

  render() {
    const { receitaOftalmologica, classes, readOnly } = this.props;
    return (
      <Form className={classes.formContent}>
        <Row container spacing={5}>
          <Grid item xs={12} className={classes.inputLabel}>
            <label>Longe</label>
          </Grid>

          <Grid xs={12} container alignItems={"center"}>
            <Grid item xs={1} className={classes.inputLabel}>
              <label>OD:</label>

            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>ESF.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoDireitoEsferico}
                onChange={this.handleChange('longeOlhoDireitoEsferico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>CIL.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoDireitoCilindrico}
                onChange={this.handleChange('longeOlhoDireitoCilindrico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>EIXO:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoDireitoEixo}
                onChange={this.handleChange('longeOlhoDireitoEixo')}
              />
            </Grid>
            <Grid item xs={2} className={classes.inputLabel}>
              <label>DNP:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoDireitoDnp}
                onChange={this.handleChange('longeOlhoDireitoDnp')}
              />
            </Grid>
          </Grid>

          <Grid xs={12} container alignItems={"center"}>
            <Grid item xs={1} className={classes.inputLabel}>
              <label>OE:</label>

            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>ESF.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoEsquerdoEsferico}
                onChange={this.handleChange('longeOlhoEsquerdoEsferico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>CIL.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoEsquerdoCilindrico}
                onChange={this.handleChange('longeOlhoEsquerdoCilindrico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>EIXO:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoEsquerdoEixo}
                onChange={this.handleChange('longeOlhoEsquerdoEixo')}
              />
            </Grid>
            <Grid item xs={2} className={classes.inputLabel}>
              <label>DNP:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.longeOlhoEsquerdoDnp}
                onChange={this.handleChange('longeOlhoEsquerdoDnp')}
              />
            </Grid>
          </Grid>
        </Row>

        <Row container spacing={5}>
          <Grid item xs={12} className={classes.inputLabel}>
            <label>Perto</label>
          </Grid>

          <Grid xs={12} container alignItems={"center"}>
            <Grid item xs={1} className={classes.inputLabel}>
              <label>OD:</label>

            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>ESF.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoDireitoEsferico}
                onChange={this.handleChange('pertoOlhoDireitoEsferico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>CIL.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoDireitoCilindrico}
                onChange={this.handleChange('pertoOlhoDireitoCilindrico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>EIXO:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoDireitoEixo}
                onChange={this.handleChange('pertoOlhoDireitoEixo')}
              />
            </Grid>
            <Grid item xs={2} className={classes.inputLabel}>
              <label>DNP:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoDireitoDnp}
                onChange={this.handleChange('pertoOlhoDireitoDnp')}
              />
            </Grid>
          </Grid>

          <Grid xs={12} container alignItems={"center"}>
            <Grid item xs={1} className={classes.inputLabel}>
              <label>OE:</label>

            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>ESF.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoEsquerdoEsferico}
                onChange={this.handleChange('pertoOlhoEsquerdoEsferico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>CIL.:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoEsquerdoCilindrico}
                onChange={this.handleChange('pertoOlhoEsquerdoCilindrico')}
              />
            </Grid>
            <Grid item xs={3} className={classes.inputLabel}>
              <label>EIXO:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoEsquerdoEixo}
                onChange={this.handleChange('pertoOlhoEsquerdoEixo')}
              />
            </Grid>
            <Grid item xs={2} className={classes.inputLabel}>
              <label>DNP:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoOlhoEsquerdoDnp}
                onChange={this.handleChange('pertoOlhoEsquerdoDnp')}
              />
            </Grid>
          </Grid>

          <Grid container>

            <Grid item xs={3} className={classes.inputLabel}>
              <label>Adição:</label>
              <InputForm
                disabled={readOnly}
                alternativeInputClass={classes.inputStatus}
                value={receitaOftalmologica?.pertoAdicao}
                onChange={this.handleChange('pertoAdicao')}
              />
            </Grid>

          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.inputLabel}>
              <label>Observação:</label>
              <ModelDocumentEditor
                key={receitaOftalmologica?.observacao}
                refChild={this.props.refEditorObservacao}
                disabledButtons={['image', 'strikethrough', 'brush', 'table']}
                textoHtml={receitaOftalmologica?.observacao}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Row>
      </Form>
    );
  }
}

const Form = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 17px;
  box-sizing: border-box;
`;

const Row = styled(Grid)`
  margin-bottom: 30px;
  display: 'flex';
  justify-content: 'space-between';
`;

export default withStyles(theme => ({
  actions: {
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 15,
  },

  botaoAdicionarRemedio: {
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    width: 110,
    color: 'white',
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: Colors.commons.yellow,
    "@media (max-width: 900px)": {
      width: 80
    }
  },

  labelBotaoAdicionarRemedio: {
    color: 'white',
    fontWeight: 500,
  },

  inputStatus: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    border: '0',
    minHeight: '6px',
    height: '18px',
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    padding: '9px 0px 5px 10px',
    borderRadius: '100px',
  },
  notchedOutline: {
    border: '0',
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    minHeight: '6px',
    height: 32,
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '100px',
    fontFamily: "Poppins"
  },
  inputLabel: {
    display: 'inline-flex',
    flexDirection: 'column',
    paddingRight: 5,
    '&>label': {
      fontWeight: 'bold',
      color: "#26ACA9",
    },
    textOverflow: 'ellipsis',
    width: 100,

  },
  inputObservacoes: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    border: '0',
    minHeight: '6px',
    height: '64px',
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    padding: '9px 0px 5px 10px',
  },
  classIcons: {
    fontSize: 24,
  },
  input: {
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 15,
  },
  label: {
    fontFamily: "Poppins !important",
    fontWeight: 'bold',
  },
  formContent: {
    padding: "10px 30px 0 30px"
  }

}))(DrugForm)

