import colors from '../template/Colors'; 

export const getStatusAtendimento = (status) => {
    switch (status) {
        case 'CONFIRMADO':
            return {
                backgroundColor: "green",
                backgroundOpacity: "#D4EEEE",
                color: colors.commons.green
            };
        case 'ATENDIDO':
            return {
                backgroundColor: "purple",
                backgroundOpacity: "#EAE3FE",
                color: colors.commons.purple
            };
        case 'AGENDADO':
            return {
                backgroundColor: "blue",
                backgroundOpacity: "#E3F4FE",
                color: colors.commons.blue
            };
        case 'AGUARDANDO':
            return {
                backgroundColor: "yellow",
                backgroundOpacity: "#FEF2E3",
                color: colors.commons.yellow
            };
        case 'ATENDENDO':
            return {
                backgroundColor: "waikawaGrey",
                backgroundOpacity: "#E7E7E7",
                color: colors.commons.waikawaGrey
            };
        case 'CANCELADO':
            return {
                backgroundColor: "red",
                backgroundOpacity: "#F8D0D0",
                color: colors.commons.red
            };
        case 'BLOQUEADO':
            return {
                backgroundColor: "white",
                backgroundOpacity: "rgba(134,134,134)",
                color: colors.commons.white
            };
        case 'FALTOU':
                return {
                    backgroundColor: "pink",
                    backgroundOpacity: "#FEF0F7",
                    color: colors.commons.pink
                };
        case 'MULTI':
            return {
                backgroundOpacity: "#FBFBFB"
            }
        default:
            return {
                backgroundColor: "white",
                backgroundOpacity: "rgba(102, 117, 149)",
                color: '#F2F2F2'
            };
    }
}

export const getStatusRowAtendimento = (status) => {
    switch (status) {
        case 'CONFIRMADO':
            return '#ebffff';
        case 'ATENDIDO':
            return '#F4F0FF';
        case 'AGENDADO':
            return '#F8FCFF';
        case 'AGUARDANDO':
            return '#FFF8EF';
        case 'ATENDENDO':
            return '#e8efff';
        case 'BLOQUEADO':
            return '#C6C7C7';
        case 'CANCELADO':
            return '#FFE1E0';
        case 'FALTOU':
            return '#FEF0F7';
        default:
            return '#EFEFEF';
    }
}