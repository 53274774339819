import { withStyles } from '@material-ui/core';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import MultiToggleButtons from '../../components/Button/MultiToggleButtons';
import { CalendarIcon, TimerIcon } from '../../assets/img/svg';

const OpcaoListaAgendamentos = observer(props => {
    const { classes, prontuarioStore, open, listarAgendamentosESelecionarAgendamento, filterFindAllAgendamento } = props;

    const getQuantidade = () => {
        return prontuarioStore.agendamentosAguardando.length === 0 ? '' : prontuarioStore.agendamentosAguardando.length
    };

    const changeTabSelected = (tabSelected) => {
        prontuarioStore.tabSelected=tabSelected;
        listarAgendamentosESelecionarAgendamento()
        prontuarioStore.findAllAgendamento(filterFindAllAgendamento);
    };

    return(
        <MultiToggleButtons
            options={open ? ['Todos', 'Em espera'] : [{icon: CalendarIcon, alt: "Calendário"}, {icon: TimerIcon, alt: "Timer"}]}
            tabSelected={prontuarioStore.tabSelected}
            changeTabSelected={changeTabSelected}
            colorQuantidade={"#F9BE73"}
            classes={{ buttonsContainer: classes.toggleButtons }}
            quantidades={['', getQuantidade()]}
            isButtonMinimized={!open}
        />
    )
});

const styles ={
    toggleButtons: {
        margin: '12px 8px',
    },
}

const OpcaoListaAgendamentosWithStyles = withStyles(styles)(OpcaoListaAgendamentos);
export default inject("prontuarioStore")(OpcaoListaAgendamentosWithStyles);