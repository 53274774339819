export const typeOfFilters = [
  {
    label: 'Data Vencimento',
    value: 'DATA_VENCIMENTO'
  },
  {
    label: 'Data Pagamento',
    value: 'DATA_PAGAMENTO'
  },
];

export const defaultPeriods = [
  {
    label: 'Personalizado',
    value: 'CUSTOM'
  },
  {
    label: 'Mês atual',
    value: 'CURRENT_MONTH'
  },
  {
    label: 'Mês passado',
    value: 'LAST_MONTH'
  },
  {
    label: 'Últimos 30 dias',
    value: 'LAST_30'
  },
  {
    label: 'Últimos 90 dias',
    value: 'LAST_90'
  },
  {
    label: 'Ano atual',
    value: 'CURRENT_YEAR'
  }
]