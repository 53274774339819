const styles = (theme) => ({
  contentPage: {
    gridArea: "content",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    position: "relative",
    overflow: "hidden",
  },

  search: {
    gridArea: "search",
    marginBottom: 20,
  },

  lista: {
    gridArea: "lista",
    marginBottom: 20,
    overflow: "hidden",
  },

  headerTitleRoot: {
    maxWidth: theme.commons.menuLeft.width,
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
  },

  titleHeader: {
    fontSize: 25,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    paddingLeft: 15,
  },

  tableContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100%",
    "& thead th": {
      textAlign: "left",
      top: 0,
      position: "sticky",
    },
    "& tbody td": {
      "& div": {
        fontSize: 13
      },
      "& strong": {
        color: "#444",
        fontSize: 15,
        margin: "5px 0"
      },
      "& p": {
        marginBottom: 5
      }
    }
  },

  scrollContainer: {
    height: "100%",
  },

  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",

    "& h3": {
      color: theme.palette.primary.main,
      fontWeight: "normal",
      fontSize: "1rem",
    },
  },

  wrapperLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
  },

  content: {
    margin: "30px 24px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
    border: "1px solid #E6E6E6",
    background: "#fff",
    height: "calc(100vh - 205px)",
  },

  buttonsTab: {
    margin: "16px",
  },

  contentTab: {
    borderTop: "1px solid #E6E6E6",
    overflow: "auto",
  },
});

export default styles;
