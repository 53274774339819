import React from 'react'

import EmojiPicker from 'emoji-picker-react';
import Dialog from '../../../components/Dialog/Dialog';

const EmojiPickerModal = ({ open, classes, onPick, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <EmojiPicker onEmojiClick={onPick} />
    </Dialog>
  )
};

export default EmojiPickerModal;
