import React, { useEffect } from "react";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";

const KeyDownWrapper = ({ children }) => {
    const { zoomIn, zoomOut } = useControls();


    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            if (e.key === "+") {
                zoomIn();
            }
            if (e.key === "-") {
                zoomOut();
            }
        });
    }, []);

    return <>{children}</>;
}

export const ZoomWrapper = ({children}) => {
    return (
        <TransformWrapper>
            <TransformComponent wrapperStyle={{ flex: 1, display: 'flex' }} contentStyle={{ flex: 1 }}>
                <KeyDownWrapper>
                {children}
                </KeyDownWrapper>
            </TransformComponent>
        </TransformWrapper>
    )
}