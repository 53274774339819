import colors from '../../template/Colors';

const FiltroSituacaoStyle = (theme) => ({
    divFiltro: {
        textAlign: 'center'
    },

    circuloFilter:{
        background: colors.primary.main,
        borderRadius: '100%',
        height: 32,
        width: 32,
        '& svg':{
            margin: 'inherit',
            minHeight: '16px',
            minWidth: '16px',
        },
        "&:hover":{
            background: colors.primary.dark,
        }
    },

    typography: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px',
        background: '#F9F9F9',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        marginTop: '4px',
    },

    checkboxOptions: {
        display: "grid",
        gridRowGap: 0,
    },

    filtros:{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#000000',
    },
    popper:{
        zIndex: 10000
    },
    filterIcon: {
        color: "#505050",
        width: 16,
    },
    filterIconSelected: {
        color: "#F9F9F9",
        width: 13,
    },
    buttonFilter: {
        background: "#F2F2F2",
        height: 32,
        width: 78,
        gap: '4px',
        color: '#868686',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    buttonFilterCircle: {
        width: 32,
        borderRadius: '100px',
    },
    buttonSelected: {
        background: '#868686',
        color: '#F9F9F9'
    }
});

export default FiltroSituacaoStyle;