import React, { Suspense, useEffect, useState } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'

import TabSelector from '../../../../../../components/TabSelector'

import {
  findAllControleSessaoProcedimentoAuditControleSessaoId,
  findAllControleSessaoAgendamentoTipoAuditByControleSessaoId,
} from '../../../../../../services/AuditoriaService'
import AtendimentoModal from '../../../../../Atendimento/AtendimentoModal'
import DeleteAgendamentoAlert from '../DeleteAgendamentoAlert'

const TableTab = React.lazy(() => import('./TableTab'))
const ObservacoesTab = React.lazy(() => import('../Observacoes'))
const AuditoriaTab = React.lazy(() => import('../../../../../../components/Auditoria/Auditoria'))

const TABS_OPTIONS = [
  {
    label: 'Informações',
  },
  {
    label: 'Observações',
  },
  {
    label: 'Auditoria',
  },
]

const SessoesContent = observer(
  ({ classes, auditoriaStore, controleSessaoStore, atendimentoStore, history }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const isProcedure = controleSessaoStore?.sessoes[0]?.procedimento?.id
    const idPacoteSessoes = window.location.href.split('/')[8]

    const loadAudit = async () => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoriaTela: 'sessao',
        auditoria: `a sessão `,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
      }

      auditoriaStore.load = {
        query: isProcedure
          ? findAllControleSessaoProcedimentoAuditControleSessaoId
          : findAllControleSessaoAgendamentoTipoAuditByControleSessaoId,
        variableName: 'controleSessaoId',
        variableId: idPacoteSessoes,
      }
      await auditoriaStore.loadAuditItems()
    }

    const tabsOptions = {
      0: (
        <TableTab
          openNotification={(message, type) =>
            atendimentoStore.prontuarioStore.openNotification(message, type)
          }
          history={history}
        />
      ),
      1: <ObservacoesTab />,
      2: (
        <div className={classes.wrapperAudit}>
          <AuditoriaTab />
        </div>
      ),
    }

    const selectedTabComponent = tabsOptions[selectedTab]

    useEffect(() => {
      if (selectedTab === 2) {
        loadAudit()
      }
    }, [selectedTab])

    return (
      <div
        className={classes.content}
        style={{
          flex: selectedTab === 1 ? 'none' : 1,
        }}
      >
        <div className={classes.tabsArea}>
          <TabSelector
            baseColor='#F2F2F2'
            selectedColor='#FFFFFF'
            onSelect={setSelectedTab}
            tabsOptions={TABS_OPTIONS}
            selectedTabIndex={selectedTab}
          />
        </div>
        <Suspense
          fallback={
            <div className={classes.wrapperLoading}>
              <CircularProgress />
            </div>
          }
        >
          {selectedTabComponent}
        </Suspense>
        <AtendimentoModal />
        <DeleteAgendamentoAlert idPacoteSessao={idPacoteSessoes} />
      </div>
    )
  }
)

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    width: '100%',
    overflow: 'hidden',
  },
  content: {
    backgroundColor: '#FFF',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    maxHeight: 'calc(100vh - 72px)',
  },
  tabsArea: {
    padding: '18px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  wrapperAudit: {
    width: '100%',
    overflow: 'auto',
    height: 'calc(100% - 72px)',
  },
  wrapperLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
  },
}

const SessoesContentStyles = withStyles(styles)(SessoesContent)
const stores = ['auditoriaStore', 'controleSessaoStore', 'atendimentoStore']
export default inject(...stores)(SessoesContentStyles)
