import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core/es";
import React, { useEffect, useState } from "react";
import RowHistorico from "./RowHistorico";
import styles from "./ModalHistoricoStyle";
import HeaderModal from "../../Components/HeaderModal";
import Dialog from "../../../../../../components/Dialog/Dialog";
import MensagemListaVazia from "../../../../../../components/Mensagem/MensagemListaVazia";
import { findByDente, findByMucosaOral } from "../../../../../../services/OdontogramaService";

const ModalHistorico = observer(({ classes, odontogramaStore }) => {
  const { showModalHistorico, odontograma, locaisSelecionados } = odontogramaStore;
  const localSelecionado = locaisSelecionados[0];

  const [historico, setHistorico] = useState([]);

  useEffect(() => {
    getHistorico();

  }, []);

  const getHistorico = async() => {
    let tipo = findByDente;
    let tipoId = 'denteId';

    if(localSelecionado.local !== 4) {
      tipo = findByMucosaOral;
      tipoId = 'mucosaOralId';
    }

    const response = await tipo({
      odontogramaId: odontograma.id,
      [tipoId]: localSelecionado.id,
    });

    const procedimentosRealizados = response.procedimentoOdontologicoRealizado.filter(item => item.realizado)
    setHistorico(procedimentosRealizados);
  };

  const changeCloseModalHistorico = () => {
    odontogramaStore.showModalHistorico = false;
    odontogramaStore.isClickRightLocalSelecionado = false;
    odontogramaStore.locaisSelecionados=[];
  };

  return (
    <Dialog
      open={showModalHistorico}
      maxWidth="md"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <HeaderModal
        title="Ver histórico"
        onClose={changeCloseModalHistorico}
        classes={{ header: classes.header }}
      />
      <div className={classes.content}>
        {
          historico.length > 0 ? 
          historico.map(item => {
            return <RowHistorico procedimentoOdontologico={item}/>
          })
          :
          <MensagemListaVazia/>
        }
      </div>
    </Dialog>
  );
});

const ModalHistoricoWithStyles = withStyles(styles)(ModalHistorico);
export default inject("odontogramaStore")(ModalHistoricoWithStyles);
