import React from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core";
import SADTModal from "../../../Atendimento/SADT/SADTModal";
import ListarModeloSADT from "./ListarModeloSADT";
import { TypeModalEnum } from "../../../../stores/SADT.store";
import { findAllGuiaServicoModelo } from '../../../../services/SADTService';
import HeaderConfiguracoes from "../../../../components/Header/HeaderConfiguracoes";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../../utils/getPageTitle";
import { Add } from "@material-ui/icons";
import { Button } from "../../../../components/ui/Buttons";

@inject("SADTStore")
@observer
class ModeloSADT extends React.Component {
    state = {
        paginaAtual: 0,
        last: false,
        modelosLoading: false,
        search: ""
    }
    componentDidMount() {
        const { SADTStore } = this.props;

        SADTStore.changeFieldsStore({
            pageNumberModelo: 0,
            searchNomeModelo: '',
            statusModelo: null,
            sortDirModelo: 'ASC',
            sortFieldModelo: 'nome',
            modelosConfig: [],
        });
        this.loadModelos();
    };

    loadModelos = ()  => {
        const { SADTStore } = this.props;

        this.setState({modelosLoading: true})
        findAllGuiaServicoModelo({
            search: SADTStore.searchNomeModelo || '',
            ativo: SADTStore.statusModelo,
            pageNumber: SADTStore.pageNumberModelo || 0,
            sortDir: SADTStore.sortDirModelo || 'ASC',
            sortField: SADTStore.sortFieldModelo || 'nome'
        }).then(response => {
            const { content, pageable: { pageNumber }, last } = response.data.data.findAllGuiaServicoModelo;
            this.setState({
                paginaAtual: pageNumber,
                last: last
            });
            SADTStore.modelosConfig = [...SADTStore.modelosConfig, ...content]
        })
        .catch(() =>{
            SADTStore.openNotification('Falha ao carregar a lista de modelos de SADT.', 'error');
        })
        .finally(() => {
            this.setState({modelosLoading: false})
        })  
    }

    handleClickNovoModelo = async () => {
        const { SADTStore } = this.props;

        SADTStore.resetModelo();
        SADTStore.changeFieldsStore({
            isProntuario: false,
            typeModal: TypeModalEnum.NOVO_MODELO_MODAL,
            openModal: true,
            sugestoesPagAtual: 0,
            sugestoes: []
        });
        await SADTStore.findAllGuiaServicoTussViewQuantidadeUso({
            pageableDTO: {
                pageSize: 6,
                pageNumber: SADTStore.sugestoesPagAtual,
                sortDir: "DESC",
                sortField: "quantidadeUso"
            }
        })
    }

    handleCloseModalNovoModelo = () => {
        const { SADTStore } = this.props;

        SADTStore.changeFieldsStore({
            modelosConfig: [],
            pageNumberModelo: 0,
            openModal: false,
            tipoListagemSelecionada: 'sugestoes',
            idModeloSelecionado: ''
        })
        this.loadModelos();
    }

    handleChangeStatusModelo = (e) => {
        const { SADTStore } = this.props;
        let value = e.target.value;

        SADTStore.changeFieldsStore({
            statusModelo: value,
            modelosConfig: [],
            pageNumberModelo: 0
        })
        this.loadModelos();
    };

    handleClickSearch = () => {
        const { SADTStore } = this.props;
        SADTStore.searchNomeModelo = this.state.search;
        SADTStore.changeFieldsStore({
            modelosConfig: [],
            pageNumberModelo: 0
        })
        this.loadModelos();
    };

    handleClickClear = () => {
        const { SADTStore } = this.props;
        this.setState({search: ''})
        SADTStore.changeFieldsStore({
            searchNomeModelo: '',
            modelosConfig: [],
            pageNumberModelo: 0
        })
        this.loadModelos();
    };

    onChangeSearchNomeModelo = (e) => {
        this.setState({search: e.target.value})
    };

    onKeypressSearchNomeModelo = (e) => {
        const { SADTStore } = this.props;
        if (e.key === 'Enter' && this.state.search.length >= 3) {
            SADTStore.changeFieldsStore({
                searchNomeModelo: this.state.search,
                modelosConfig: [],
                pageNumberModelo: 0
            })
            this.handleClickSearch();
        }
    };

    render(){
        const {classes, SADTStore} = this.props;
        const status = [
            {name: 'Ativo', label: 'Ativo', value: true}, 
            {name: 'Inativo', label: 'Inativo', value: false},
        ];


    return (
        <>
        <div>
            <PageTitle title={pageConfiguracoes("Modelos SP/SADT")}/>
            <HeaderConfiguracoes
                handleClickSearch={this.handleClickSearch}
                handleClickClear={this.handleClickClear}
                placeholderSearch="Pesquisa por nome do modelo"
                valueSearch={this.state.search}
                showSearch
                onChangeSearch={this.onChangeSearchNomeModelo}
                onKeypressSearch={this.onKeypressSearchNomeModelo}
                valueStatus={this.props.SADTStore.statusModelo}
                showStatus
                handleChangeStatus={this.handleChangeStatusModelo}
                status={status}
            />
            <div className={classes.contentList}>
            <ListarModeloSADT 
                loadModelos={() => this.loadModelos()} 
                paginaAtual={this.state.paginaAtual} 
                last={this.state.last} 
                modelosLoading={this.state.modelosLoading}
            />
            </div>
            <Button
                shadow
                shape='circle'
                bgColor='#26ACA9'
                onClick={this.handleClickNovoModelo}
                className={classes.buttonCriar}
            >
                <Add/>
            </Button>
        </div>

        {SADTStore.openModal && <SADTModal screen="Configurações" handleClose={this.handleCloseModalNovoModelo} loadModelos={() => this.loadModelos()}/>}
        </>
    )
}};

export default withStyles(theme => ({
    headerProfile:{
        padding: '0 16px',
    },
    header:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contentList:{
        display: 'flex',
        flexDirection: 'row',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
    },
    buttonNovoModelo:{
        width: 152,
        height: 40,
        borderRadius: 87,
        boxShadow: 'none',
        background: '#F9BE73',
        color: '#fff',
        textTransform: 'capitalize',
        padding: '0 14px',
        marginLeft: 24,
        minWidth: 152,
        "@media (max-width: 1600px)": {
            width: 40,
            minWidth: 40,
            borderRadius: '100%',
        }
    },
    labelButtonNovoModelo: {
        marginLeft: '6px',
        "@media (max-width: 1600px)": {
            display: 'none',
        }
    },

    columnHeaderFiltro:{
        justifyContent: 'center',
        width: 70,
    },
    columnHeaderNome:{
        width: '50%',
    },
    columnHeaderItens: {
        width: '15%'
    },
    columnHeaderStatus:{
        width: '15%',
    },
    columnHeaderLabel:{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 20,
        alignItems: 'center',
    },
    arrowIcon: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterButton: {
        width: 48,
        height: 48,
        background: '#fff',
        boxShadow: 'none',
        marginRight: 20,
    },

    inputRootSearch:{
        border: 'none',
    },
    
    inputSearch:{
        fontFamily: "Poppins!important",
    },

    searchNomeModelo:{
        width: '100%',
        height: 48,
        borderRadius: "8px !important",
        border: 'none !important',
        background: "#F2F2F2",
        justifyContent: 'center',
        fontWeight: 400,
    },
    selectStatus:{
        width: 200,
        marginLeft: 16,
    },
    iconButtonSearch:{
        width: 30,
        height: 30,
    },
    gridSearch:{
        width: '100%',
        maxWidth: '582px'
    },
    buttonCriar: {
        position: 'absolute',
        bottom: 88,
        right: 37,
        zIndex: 100
    },

}))(ModeloSADT);
