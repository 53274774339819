import React, { useEffect, useRef, useState } from "react"
import { CircularProgress, withStyles } from "@material-ui/core"
import Header from "./components/Header"
import Scroll from "../../../../components/InfiniteScroll/Scroll"
import Table from "../../../../components/Table/Table"
import { getColumns } from "./utils/getColumns"
import AddIcon from "../../../../components/Icon/AddIcon"
import ButtonFloat from "../../../../components/ButtonFloat"
import Filter from "./components/Filter/index"
import ModalContrato from "./components/ModalContrato"
import { cancelarDocumentoClienteAssinatura, findAllDocumentoClienteAssinatura } from "../../../../services/ContratoService"
import { observer } from "mobx-react-lite"
import { inject } from "mobx-react"
import { findDocumentoClienteAssinaturaById, findDocumentoClienteAssinaturaByIdPlugsign } from "../../../../services/DocumentoService"
import Notification from "../../../../components/Notification"
import { buildUrlDownloadDocumento } from "../../../../config/config"

const pageableDTODefault = {
  sortDir: "ASC",
  sortField: "nome",
};


const Documentos = observer(({ classes, configuracaoImpressaoStore, sujeitoAtencaoStore ,history,prontuarioStore}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [openContratoModal, setOpenContratoModal] = useState(false)
  const [documentoSelecionado, setDocumentoSelecionado] = useState({})
  const [documentos, setDocumentos] = useState([]);
  const [ordenarTabela, setOrdenarTabela] = useState(pageableDTODefault);
  const [search, setSearch] = useState({})
  const [last, setLast] = useState(false);
  const [readOnly, setIsReadOnly] = useState(false)
  const [pdfCurrentPage, setPdfCurrentPage] = useState(null)
  const [historyValue,setHistoryValue]=useState(null)
  const [pdfNumPages, setpdfNumPages] = useState(null)
  const [isDadosFiltrados, setIsDadosFiltrados] = useState(null)
  
  const [openNotification, setOpenNotification] = useState({
    isOpen: false,
    variant: "",
    message: ""

  })
  const [pageableDto, setPageableDto] = useState({
    pageNumber:0,
    pageSize: 30,
    ...pageableDTODefault
  })

  const {notification}=prontuarioStore
  const {isOpen,message,variant}=notification;

  const popperRef = useRef(null);
  const dados = history.location.state


  useEffect(() => {
    setHistoryValue(dados)
  }, [])

  useEffect(()=>{
    if (historyValue?.agendamentoId && historyValue?.possuiDocumento) {
      loadContratos({isClear:true, agendamentoId:historyValue?.agendamentoId})
      setIsDadosFiltrados(true)
    }
    else if(historyValue?.agendamentoId && !historyValue?.possuiDocumento) {
      loadContratos({isClear:true})
      setOpenContratoModal(true)
    }
  },[historyValue])

  useEffect(() => {
    if(!dados){
      loadContratos({isClear:true})
    }
  }, [ordenarTabela])

  const loadContratos = async (options) => {
    const { idOpened } = sujeitoAtencaoStore;
    const pageable= options?.isClear? {...pageableDto, pageNumber:0}:pageableDto
    if(isDadosFiltrados){
      setIsDadosFiltrados(false)
    }
    const variables = {
      ...search,
      pageableDto:pageable,
      sujeitoAtencao: {
        id: idOpened,
      },
      atualizarSituacoes: true,
      ...(options?.agendamentoId && {agendamentoId:options.agendamentoId})
    }
    setIsLoading(true)
    try {
      const response = await findAllDocumentoClienteAssinatura(variables)
      const{last,content}=response
      const documentosClear = options?.isClear? [] : documentos
      setDocumentos([...documentosClear,...content])
      setLast(last)
      setPageableDto({...pageableDto,pageNumber:pageableDto.pageNumber +1})
    }
    catch (error) {
      console.error(error)
    }
    finally {
      setIsLoading(false)
    }

  }

  const handleSearchChange = (e, key) => {
    setSearch(prevSearch => ({
      ...prevSearch,
      [key]: e
    }));
  };

  const handleClickFilterButton = async () => {
    loadContratos({isClear: true})
  }

  const handleOpenContratoModal = () => {
    setOpenContratoModal(true)
  }

  const handleClickVisualizar = async (contrato) => {
    setIsReadOnly(true)
    if (contrato) {
      const documento = await findDocumentoClienteAssinaturaById(contrato.id)
      setDocumentoSelecionado({ ...documento, destinatarios: documento.destinatario })
    }
    setOpenContratoModal(true)
  }

  const handleClickCancelar = async (contrato) => {
    try {
      await cancelarDocumentoClienteAssinatura(contrato.id)
      prontuarioStore.openNotification( "Documento cancelado!",  "success" )
      loadContratos({isClear:true})
    } catch (error) {
      prontuarioStore.openNotification("Erro ao cancelar documento!", "error" )
      console.log(error)
    }
  }

  const handleClickOrdenar = (value) => {
    const sortDir =
      ordenarTabela.sortField !== value ? 'ASC' : ordenarTabela.sortDir === 'ASC' ? 'DESC' : 'ASC'
    setOrdenarTabela({
      sortDir: sortDir,
      sortField: value,
    })
    setPageableDto({...pageableDto,sortDir:ordenarTabela.sortDir, sortField: value})
  }

  const closeContratoModal = () => {
    setHistoryValue(null)
    setOpenContratoModal(false)
    setDocumentoSelecionado({})
    setIsReadOnly(false)
  }

  const handlePdfPageChange = (currentPage, numPages) => {
    setpdfNumPages(numPages)
    setPdfCurrentPage(currentPage)
  };

  const handleClickBaixar = async (item) => {
    try {
      setIsLoading(true)
      const url = await buildUrlDownloadDocumento(item.urlDownload);
      const response = await fetch(url)
      const blob = await response.blob()
      const blobUrl = URL.createObjectURL(blob)
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${item.nome}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
      alert('Não foi possível baixar o arquivo. Tente novamente.');
    }finally{
      setIsLoading(false)
    }
  };

  const closeNotification = () => {
    setOpenNotification({
      notification: {
        isOpen: false
      }
    })
  }


  return (
    <div>
      <Header handleOpenContratoModal={handleOpenContratoModal} isLoading={isLoading} />
      <div className={classes.content}>
        <Filter
          handleApplyFilter={handleClickFilterButton}
          onChange={handleSearchChange}
          filter={search}
          isLoading={isLoading}
          dadosFiltrados={isDadosFiltrados}
          text={"Documentos do agendamento "}
          textButton={"Mostrar tudo"}
          functionButton={()=>loadContratos({ isClear: true })}
        />
        <div className={classes.contentTable}>
          <Scroll
            loadMore={() => loadContratos()}
            hasMore={!last}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainer}
          >
            <Table
              dados={documentos}
              classes={classes}
              columns={getColumns({
                popperRef,
                functionsPopper: {
                  handleClickVisualizar,
                  handleClickCancelar,
                  handleClickBaixar,
                },
              })}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
            />
            {documentos?.length === 0 && !isLoading &&
              <div className={classes.emptyMessage}>
                Nenhum registro encontrado
              </div>
            }

            {isLoading &&
              <div className={classes.circularProgress}>
                <CircularProgress color={"primary"} />
              </div>
            }

          </Scroll>
        </div>
      </div>
      <div className={classes.floatButtonsContainer}>
        <ButtonFloat
          icon={AddIcon}
          onClick={handleOpenContratoModal}
          disabled={isLoading}
        />
      </div>
      {openContratoModal && <ModalContrato
        open={openContratoModal}
        onClose={() => closeContratoModal()}
        readOnly={readOnly}
        modeloDocumento={documentoSelecionado}
        config={configuracaoImpressaoStore.configuracaoImpressao}
        onPageChange={handlePdfPageChange}
        loadContratos={loadContratos}
        history={historyValue}
      />}
      <Notification
        isOpen={isOpen}
        message={message}
        variant={variant}
        close={closeNotification}
      />
    </div>
  )
})

const style = {
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 0 16px",
    gap: "16px",
    justifyContent: "flex-start"
  },
  scrollContainer: {
    height: "100%",
  },
  contentTable: {
    display: "flex",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#ffff",
    height: "calc(100vh - 325px)",
    "& .situacaoContrato": {
      padding: "4px 8px 4px 8px",
      fontWeight: 600,
      color: "#505050",
      borderRadius: "8px",
      textAlign: "center"
    },
    "& .assinado": {
      background: "#e1f3f4",
      border: "0.5px solid #007F79",
      width: "116px",
    },
    "& .naoAssinado": {
      background: "#FCF0DF",
      width: "114px",
      border: "0.5px solid #DB7C2F",
    },
    "& .cancelado": {
      background: "#FCEDEF",
      width: "107px",
      border: "0.5px solid #BF3028",
    }
  },
  divHeaderComIcon: {
    textAlign: "left !important",
    justifyContent: "flex-start !important"
  },
  floatButtonsContainer: {
    position: "absolute",
    bottom: 24,
    right: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "end",
    columnGap: "8px",
    "& > div": {
      alignItems: "flex-end",
    },
  },
  emptyMessage:{
    display:"flex !important",
    justifyContent:"center !important",
    alignItems:"center !important",
    padding:"10px !important",
    fontSize:"15px !important",
    color:"#505050 !important"
  },
  circularProgress:{
    display:"flex ",
    justifyContent:"center ",
    alignItems:"center ",
    paddingTop:"10px ",
    height:"100%"
  },
  documentosFiltrados:{
    display:"flex",
    justifyContent:"space-between",
    color:"#505050",
    fontWeight:"600",
    alignItems:"center"
  },
  button:{
    width:"130px",
    borderRadius:"30px",
    textWrap:"nowrap",
    fontSize:"13px",

  }

};

const DocumentosWithStyles = withStyles(style)(Documentos)
export default inject("documentoStore", "configuracaoImpressaoStore", "sujeitoAtencaoStore","prontuarioStore")(DocumentosWithStyles)