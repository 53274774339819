import { withStyles } from "@material-ui/core/es";
import React from "react";

const CardFinanceiro = (props) => {
  const { classes, title, icon, color, info } = props;

  const styleFontInfo = {
    color: color,
    fontWeight: 700,
    fontSize: "28px",
    whiteSpace: "nowrap",
  };

  return (
    <div className={classes.card}>
      <div className={classes.title}> {title} </div>
      <div className={classes.divider} />
      <div className={classes.content}>
        <img src={icon} alt={title} />
        <div style={styleFontInfo}> {info} </div>
      </div>
    </div>
  );
};

const styles = {
  card: {
    padding: "15px 20px 32px",
    background: "#FFFFFF",
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
  },
  title: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
  },
  divider: {
    border: "1px solid rgba(38, 172, 169, 0.1)",
    margin: "13px 0 27px",
  },
  content: {
    display: "flex",
    gap: "10%",
  },
};

export default withStyles(styles)(CardFinanceiro);
