export const eventCardHeight = 40.5;

export default theme => ({
    dayCalendar: {
        fontFamily: "Poppins!important",
        overflow: 'auto',
        display: 'flex',
        height: '100%'
    },

    iconOption: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
    },

    dayCalendarInner: {
        fontFamily: "Poppins!important",
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        overflowY: 'scroll',
        overflowX: 'hidden',
        alignItems: 'flex-start'
    },
    
    dayCalendarHeaderInner: {
        display: 'grid',
        gridTemplateColumns: '80px 2fr 100px 1fr 2fr 2fr 3fr',
        gridColumnGap: '16px',
        backgroundColor: '#219A97',
        minHeight: '64px',
        position: 'sticky',
        top: 0,
        zIndex: 20,
    },
    dayCalendarHeaderInnerSala: {
        display: 'grid',
        gridTemplateColumns: '80px 2fr 106px 2fr 2fr 3fr',
        gridColumnGap: '16px',
        backgroundColor: '#219A97',
        minHeight: '64px',
        position: 'sticky',
        top: 0,
        zIndex: 20,
    },
    dayCalendarColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: '250px',
        height: '40px',
        minHeight: '40px',
    },
    dayCalendarColumnHeader: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        color: '#F9F9F9',
        fontSize: '16px',
        fontWeight: 700,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dayCalendarColumnHeaderLabel: {
        color: '#F9F9F9',
        fontSize: '16px',
        fontWeight: 700,
    },

    weekCalendarColumnHeaderDayWeekLabel: {
        color: theme.palette.primary.main,
        fontSize: '0.9em !important',
        fontWeight: 'lighter',
        userSelect: 'none'
    },
    dayCalendarEvent: {
        width: '100%',
        minHeight: '44px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        borderBottom:'1px solid #b5b5b5',
        
        "&>div": {
            fontSize: '1.1em !important',
            color: '#ccc',
            userSelect: 'none',
            zIndex: '2 !important',
        },
        
        '&>div[role="tooltip"]': {
            top: '30% !important',
            left: '10px !important',
            zIndex: 3
        },
        '&.last-day > div[role="tooltip"]': {
            left: 'auto !important',
            right: '5px !important',
        }
    },
    rowMaior: {
        minHeight: '60px',
    },
    dayCalendarColumnLabel: {
        color: '#BDBDBD!important',
        fontSize: '14px!important',
        fontFamily: "Poppins!important",
        fontWeight: 700,
    },
    columnHeaderTipo: {
        minWidth: "100px",
    }
});
