import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const InfoIconFilled = props => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5ZM8.74905 6.00003C8.74905 6.41371 8.41369 6.74907 8.00001 6.74907C7.58633 6.74907 7.25098 6.41371 7.25098 6.00003C7.25098 5.58635 7.58633 5.251 8.00001 5.251C8.41369 5.251 8.74905 5.58635 8.74905 6.00003ZM8 7.5C8.27614 7.5 8.5 7.72386 8.5 8V11C8.5 11.2761 8.27614 11.5 8 11.5C7.72386 11.5 7.5 11.2761 7.5 11V8C7.5 7.72386 7.72386 7.5 8 7.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InfoIconFilled
