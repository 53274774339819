import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { CircularProgress, Grid } from "@material-ui/core";
import { columns } from "./Constants";
import string from "../../../utils/string";
import Table from "../../../components/Table/Table";
import ButtonFloat from "../../../components/ButtonFloat";
import CircleError from "../../../components/Icon/CircleError";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import styles from "../../../assets/jss/pages/contaBancariaStyle";
import { defaultModalConfirmacao } from "../../../stores/Financeiro/ContaBancaria.store";
import { alteraStatusContaBancaria, getSaldoAtual } from "../../../services/FinanceiroService";

const Informacoes = observer(({ classes, contaBancariaStore, disableSaldoInicial }) => {
  const {
    contaBancariaList,
    loading,
    last,
  } = contaBancariaStore;

  const loadMore = () => {
    contaBancariaStore.findAllContaBancaria();
  };

  const handleItemClick = (id) => {
    contaBancariaStore.edit(id);
  };

  const handleGetValor = async (row) => {
    const { id, isOculta } = row;

    const indexConta = contaBancariaList.indexOf(row);
    let contasBancarias = contaBancariaList;

    if (isOculta) {
      const saldoAtual = await getSaldoAtual(id);
      contasBancarias[indexConta] = {
        ...row,
        saldoAtual,
        isOculta: !isOculta,
      };
    } else {
      contasBancarias[indexConta] = {
        ...row,
        isOculta: !isOculta,
      };
    }
  };

  const handleClickStatus = async ({ id, ativo }) => {
    const action = ativo ? "inativar" : "ativar";
    const color = ativo ? "#FB7676" : "#219A97";

    contaBancariaStore.modalConfirmacao = {
      open: true,
      title: `Deseja ${action} essa categoria financeira?`,
      icon: <CircleError color={color} />,
      buttons: {
        labelPrimaryButton: "Cancelar",
        labelSecondButton: string.capitalize(action),
        handlePrimaryButton: () => {
          contaBancariaStore.modalConfirmacao = defaultModalConfirmacao;
        },
        handleSecondButton: async () => {
          await alteraStatus({ id, action });
          contaBancariaStore.modalConfirmacao = defaultModalConfirmacao;
        },
      },
    };
  };

  const alteraStatus = async ({ id, action }) => {
    try {
      await alteraStatusContaBancaria(id, action);
      contaBancariaStore.findAllContaBancaria(true);

      contaBancariaStore.openNotification(
        "Status alterado com sucesso",
        "success"
      );
    } catch {
      contaBancariaStore.openNotification("Erro ao alterar o status.", "error");
    }
  };

  return (
    <div className={classes.tableContainer}>
      {loading && contaBancariaList.length === 0 && (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <CircularProgress size={30} />
        </Grid>
      )}

      {!loading && contaBancariaList.length === 0 && (
        <div className={classes.notFoundContainer}>
          <h3>Nenhum item encontrado</h3>
        </div>
      )}
      {!loading && contaBancariaList.length > 0 && (
        <Scroll
          loadMore={loadMore}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainer}
        >
          <Table
            dados={contaBancariaList}
            columns={columns({
              handleGetValor: handleGetValor,
              handleClickStatus: handleClickStatus,
            })}
            clickable={true}
            handleClick={handleItemClick}
          />
        </Scroll>
      )}
      <ButtonFloat
        onClick={() => {
          disableSaldoInicial(false);
          contaBancariaStore.openNew();
        }}
      />
    </div>
  );
});

const InformacoesWithStyles = withStyles(styles)(Informacoes);
export default inject("contaBancariaStore")(InformacoesWithStyles);
