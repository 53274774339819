import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const PrintIcon = props => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}

    >
      <path d="M19.0815 17.7568L19.098 19.0048C19.1145 20.2455 18.122 21.2646 16.8813 21.281L9.39318 21.3803C8.1525 21.3968 7.1334 20.4043 7.11695 19.1636L7.10039 17.9146L5.35317 17.9388C4.11249 17.9553 3.09339 16.9629 3.07694 15.7222L2.99757 9.73516C2.97381 7.94307 4.40733 6.47103 6.19942 6.44727L6.94723 6.43678L6.93826 5.68496C6.92181 4.44428 7.91425 3.42518 9.15493 3.40873L16.6456 3.30942C17.8863 3.29298 18.9054 4.28541 18.9218 5.52609L18.9313 6.27791L19.6806 6.26855C21.4722 6.24655 22.9434 7.67845 22.9698 9.46925L23.0525 15.457C23.0708 16.6971 22.0788 17.7172 20.8378 17.7344L19.0815 17.7568ZM16.7821 13.7929L9.29391 13.8922C8.88035 13.8976 8.54954 14.2373 8.55502 14.6509L8.61458 19.1438C8.62007 19.5574 8.95977 19.8882 9.37333 19.8827L16.8615 19.7834C17.275 19.7779 17.6059 19.4382 17.6004 19.0247L17.5408 14.5318C17.5353 14.1182 17.1956 13.7874 16.7821 13.7929ZM16.6654 4.80706L9.17478 4.90636C8.76122 4.91184 8.43041 5.25154 8.43589 5.6651L8.44486 6.41693L17.4337 6.29776L17.4242 5.54595C17.4187 5.13239 17.079 4.80157 16.6654 4.80706Z" fill="currentColor" />
    </svg>

  );
};

export default PrintIcon;
