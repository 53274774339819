import React from 'react';
import InputDefault from './InputDefault';
import { renderMaskedInput } from './InputMaskGeneric';

  export const TemperaturaMask = (props) => {
    const maskedProps = {
      suffix: ' °C',
      placeholder: ' °C',
      integerLimit: 3,
      decimalLimit: 1,
      allowDecimal: true
    }

    return renderMaskedInput(maskedProps, props)
}

export const PaMask = (props) => {
    const maskedProps = {
      suffix: ' mmhg',
      placeholder: ' mmhg',
      integerLimit: 3,
      decimalLimit: 0,
      allowDecimal: false
    }

    return renderMaskedInput(maskedProps, props)
}

export const FrequenciaRespiratoriaMask = (props) => {
    const maskedProps = {
      suffix: ' irpm',
      placeholder: ' irpm',
      integerLimit: 3,
      decimalLimit: 0,
      allowDecimal: false
    }

    return renderMaskedInput(maskedProps, props)
}

export const FrequenciaCardiacaMask = (props) => {
    const maskedProps = {
      suffix: ' bpm',
      placeholder: ' bpm',
      integerLimit: 3,
      decimalLimit: 0,
      allowDecimal: false
    }

    return renderMaskedInput(maskedProps, props)
}

export const GlicemiaMask = (props) => {
    const maskedProps = {
      suffix: ' mg/dl',
      placeholder: ' mg/dl',
      integerLimit: 3,
      decimalLimit: 2,
      allowDecimal: true
    }

    return renderMaskedInput(maskedProps, props)
}

export const SaturacaoO2Mask = (props) => {
    const maskedProps = {
      suffix: ' %',
      placeholder: ' %',
      integerLimit: 3,
      decimalLimit: 1,
      allowDecimal: true
    }

    return renderMaskedInput(maskedProps, props)
}

export const InputTriagem = (props) => {
    let mascara;

    switch(props.mask){
        case 'temperatura':
            mascara = TemperaturaMask;
            break;
        case 'pa':
            mascara = PaMask;
            break;
        case 'frequenciaRespiratoria':
            mascara = FrequenciaRespiratoriaMask;
            break;
        case 'frequenciaCardiaca':
            mascara = FrequenciaCardiacaMask;
            break;
        case 'glicemia':
            mascara = GlicemiaMask;
            break;
        case 'saturacaoO2':
            default:
            mascara = SaturacaoO2Mask;
            break;
    }

    return <InputDefault 
        InputProps={{ inputComponent: mascara }} 
        {...props} 
    />
};
