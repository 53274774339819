import firebase from "firebase/app";
import "firebase/messaging";

// Initialize Firebase
var config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGIN_SENDER_ID,
	appId: process.env.REACT_APP_ID,
};

export const initializeFirebase = () => {
	firebase.initializeApp(config);
	navigator.serviceWorker
		.register("/firebase-messaging-sw.js")
		.then((registration) => {
			firebase.messaging().useServiceWorker(registration);
		});
};

export const askForPermissionToReceiveNotifications = async () => {
	try {
		const messaging = firebase.messaging();
		await messaging.requestPermission();
		const token = await messaging.getToken();
		return {
			token: token,
		};
	} catch (error) {
		return {
			error: error,
		};
	}
};

export const getPushNotificationToken = async () => {
	const messaging = firebase.messaging();
	return messaging.getToken();
};
