import React from 'react';
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip';
import Colors from "../../template/Colors";
import { withStyles } from '@material-ui/core';

class RadioComponent extends React.Component {

  onClick = (value) => {
    const {onChange} = this.props;
    onChange && onChange(value);
  }
  
  render() {
    const { value, label, checked, icon, openDrawer, tooltipTitle, classes, isIconImg } = this.props;
    const Icon = icon;
    return (
      <Tooltip title={tooltipTitle} placement={openDrawer ? 'top' : 'right'}>
        <button 
          className={classNames(classes.button, {'active': checked, [classes.buttonMinimizado]: !openDrawer})} 
          onClick={() => this.onClick(value)}
        >
          <div style={{display: 'flex', placeItems: 'center', placeContent: 'center'}}>
            {
              isIconImg ?
              <img src={icon} className={classes.iconImg}/>
              :
              <Icon style={{fontSize:24, verticalAlign: 'middle'}} />
            }
            <span>{label}</span>
          </div>
        </button>
        </Tooltip>
    );
  }
}

const styles = {
  button: {
    width: '72px',
    height: '32px',
    boxShadow: '0px 4px 8px rgb(38 172 169 / 9%), 0px 4px 8px rgb(38 172 169 / 8%), 0px 4px 8px rgb(0 0 0 / 3%)',
    borderRadius: '100px',
    background: Colors.commons.gray7,
    color: Colors.commons.grayLight,
    fontWeight: 600,
    margin: '0px 4px',
    border: 'none',
    fontSize: '12px !important',
    cursor: 'pointer',
    '&.active': {
      backgroundColor: Colors.commons.secondary,
    }
  },
  buttonMinimizado: {
    width: '40px',
    height: '40px',
    margin: '4px 0px',
    fontSize: '14px',
  },
  iconImg: {
    color: "#fff",
    fill: "#fff"
  },
}

const Radio = withStyles(styles)(RadioComponent)

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.options = [];
  }

  getChildContext() {
    const {name} = this.props;
    return {radioGroup: {
      name,
      onChange: this.onChange.bind(this)
    }};
  }
  
  onChange(selected, child) {
    this.options.forEach(option => {
      if (option !== child) {
        option.setSelected(!selected);
      }
    });
  }
  
  render() {
    let children = React.Children.map(this.props.children, child => {
      child.onChange = this.onChange;
      return React.cloneElement(child, {
        ref: (component => {this.options.push(component);}) 
      });
    });
    return <div className="radio-group">{children}</div>;
  }
}


export {
  Radio,
  RadioGroup,
}