import colors from '../../../../template/Colors';

const styles = {
    root: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      height: '100%',
    },
    content: {
      padding: '20px',
      height: '100%',
      overflow: 'auto',
    },
    fullSizePaper: {
      paddingBottom: '2rem',
      borderRadius: '8px',
    },
    listaGuiaServicoSelecionadoSelecionados: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 8,
      height: '56px',
      overflow: 'auto',
    },
    guiaServicoOption: {
      background: "#F2F2F2",
      border: "0.5px solid #BDBDBD",
      borderRadius: "100px",
      minWidth: "80px",
      display: "flex",
      justifyContent: "space-between",
      padding: "4px 8px",
      margin: "3px",
      height: "24px",
      "& svg": {
        color: "#868686",
        marginLeft: "5px",
      },
    },
    notchedOutline: {
      border: '0',
    },
    inputTextField: {
      fontSize: '0.875rem',
      border: '0',
      minHeight: '0.375rem',
      height: 32,
      color: colors.commons.fontColor,
      backgroundColor: colors.commons.gray11,
      margin: '0',
      borderRadius: '6.25rem',
    },
    classIcons: {
      fontSize: 24,
    },
    typography: {
      marginTop: 8,
      color: '#868686',
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: '400',
    },
    menuNumeroGuiaPrestador: {
      maxHeight: '30vh',
      "&> div": {
        maxHeight: '30vh',
      }
    },
    buttonsTab: {
      margin: '0.5rem 1rem'
    },
    radioButton: {
      display: 'flex',
      margin: '5px 0px',
      "&> span":{
        fontSize: "14px !important",
        padding: '1px 3px 0 0',
        color: '#505050',
      },
      "&> input": {
        padding: '0px !important'
      },
      "& svg": {
        height: 20,
        width: 20,    
      },
    },
    containerFilters: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    wrapperTypes: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    inputContainer:{
      width: '100%',
      background: '#F2F2F2',
      color: '#505050',
      borderRadius: '8px',
      height: '2rem',
    },
    buttonsContainer:{
      width: '100%',
      display: 'flex',
      '&> button': {
        flex:1,
        margin: '1rem 16px 0',
        fontWeight: 'bold',
      },
    },
    wrapperInfosGuia:{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '1rem 0',
    }
};

export default styles;