export const downloadImage = async(nomeDownload, urlImagem) => {
    const image = await fetch(urlImagem);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = nomeDownload;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadArquivo = (nomeDownload, urlArquivo) => {
    const link = document.createElement("a");
    link.download = nomeDownload;
    link.href = urlArquivo;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};