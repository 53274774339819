import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const CircleWarning = props => {
  return (
    <svg 
      width="50" 
      height="50" 
      viewBox="0 0 50 50" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <circle cx="25" cy="25" r="25" fill="currentColor"/>
      <g clip-path="url(#clip0_8124_133181)">
      <path d="M12.1667 35.5007H37.8333L25 13.334L12.1667 35.5007ZM26.1667 32.0007H23.8333V29.6673H26.1667V32.0007ZM26.1667 27.334H23.8333V22.6673H26.1667V27.334Z" fill="#F9F9F9"/>
      </g>
      <defs>
      <clipPath id="clip0_8124_133181">
      <rect width="28" height="28" fill="white" transform="translate(11 11)"/>
      </clipPath>
      </defs>
    </svg>
    
  );
};

export default CircleWarning;