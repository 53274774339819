import React from "react";

import { withStyles } from "@material-ui/core/styles/index";

import { ReactSelect } from "../../components/Select/SelectSearch";
import { TextFieldSearch } from "../TextField";
import { InputDateForm } from "../../components/Modal/Input";
import Colors from "../../template/Colors";
import { Button } from "../ui/Buttons";

const styleInputSearch = () => ({
    selectSearch: {
        width: "100%",
        marginRight: "8px",
    },
    searchStatus: {
        width: "100%",
        "&>div": {
            maxHeight: 32,
            "&>div": {
                display: "flex",
                alignContent: "center",
            }
        }
    },
    selects: {
        display: 'flex',
    },
    searchButton: {
        borderRadius: 85,
        width: "50%",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
        "& > span": {
            fontSize: "14px"
        }
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        gap: "8px",
        marginTop: "20px"
    },
    notchedOutline: {
        border: '0',
    },
    inputTextField: {
        fontSize: '14px',
        border: '0',
        minHeight: '6px',
        height: 32,
        color: Colors.commons.fontColor,
        backgroundColor: Colors.commons.gray2,
        margin: '0 0 0 0',
        borderRadius: '100px',
    },
    classIcons: {
        fontSize: 24,
    },
    dateTime: {
        width: "100%",
        fontFamily: "Poppins !important",
        fontSize: 12,
        "& input": {
            color: "#505050",
            fontWeight: 500,
        },
    },
    wrapperFields: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginTop: "8px",
        "& span": {
            color: "#868686",
            fontSize: "12px",
            fontWeight: 400,
        },
    },
});

const FiltrosBuscaPacientes = (props) => {
    const {
        classes,
        municipios,
        profissionalSaude,
        handleChangeAtivoInput,
        handleChangeMunicipioInput,
        handleChangeProfissionalInput,
        handleChangeConvenioInput,
        searchPaciente,
        filterPacient,
        convenios,
        redefinirFiltros,
        handleMunicipioLoadOptions,
        handleChangeInput,
        screen,
    } = props;

    const ativoSearch = [{ name: 'Ativos', value: true }, { name: 'Inativos', value: false }];

    return (
        <div className={classes.content}>
            <div className={classes.wrapperFields}>
                <div>
                    <span>Profissional Saúde </span>
                    <ReactSelect
                        className={classes.selectSearch}
                        name="Profissional Saúde"
                        placeholder={"Profissional Saúde"}
                        value={filterPacient.profissionalSaudeId}
                        onChange={handleChangeProfissionalInput}
                        options={profissionalSaude || []}
                        isClearable
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                { screen === "Atendimento" && <>
                    <div>
                        <span>Data Início</span>
                        <InputDateForm
                            id="dataInicio"
                            iconposition="end"
                            className={classes.dateTime}
                            classes={{
                                date: classes.inputDadosAgendamento,
                            }}
                            value={filterPacient.dataInicio}
                            onChange={(e) => handleChangeInput(e, 'dataInicio')}
                        />
                    </div>
                    <div>
                        <span>Data Fim</span>
                        <InputDateForm
                            id="dataFinal"
                            iconposition="end"
                            className={classes.dateTime}
                            classes={{
                                date: classes.inputDadosAgendamento,
                            }}
                            value={filterPacient.dataFim}
                            onChange={(e) => handleChangeInput(e, 'dataFim')}
                        />
                    </div>
                </>}
                {municipios && (
                    <>
                        <div className={classes.selects}>
                            <div className={classes.selectSearch}>
                                <span>Município </span>
                                <TextFieldSearch
                                    placeholder="Município"
                                    classNotched={classes.notchedOutline}
                                    classInput={classes.inputTextField}
                                    classIcons={classes.classIcons}
                                    loadOptions={handleMunicipioLoadOptions}
                                    withPaginate
                                    value={filterPacient.municipioId}
                                    onChange={handleChangeMunicipioInput}
                                    debounceTimeout={300}
                                    additional={{
                                        page: 0
                                    }}
                                />
                            </div>
                            <div className={classes.searchStatus}>
                                <span>Status </span>
                                <ReactSelect
                                    className={classes.searchStatus}
                                    name="Status"
                                    placeholder={"Status"}
                                    value={filterPacient.ativo}
                                    options={ativoSearch || []}
                                    onChange={handleChangeAtivoInput}
                                    getOptionLabel={(option) => option.name}
                                />
                            </div>
                        </div>
                        <div>
                            <span> Convênio </span>
                            <ReactSelect
                                className={classes.selectSearch}
                                name="convenio"
                                placeholder={"Convênio"}
                                value={filterPacient.convenioId}
                                onChange={handleChangeConvenioInput}
                                options={convenios || []}
                                isClearable
                                getOptionLabel={(option) => option.descricao}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className={classes.buttons}>
                <Button
                    onClick={redefinirFiltros}
                    kind="transparent"
                    fullWidth
                >
                    Redefinir filtros
                </Button>
                <Button
                    onClick={searchPaciente}
                    kind="primary"
                    fullWidth
                >
                    Pesquisar horários
                </Button>
            </div>
        </div>
    );
};

export default withStyles(styleInputSearch)(FiltrosBuscaPacientes);
