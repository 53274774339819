import Colors from "../../../template/Colors";

const styles = {
  root: {
    position: "relative",
    overflow: "none",
    zIndex: 1,
    fontFamily: "Poppins !important",
  },
  conteudoEsquerdo: {
    display: "flex",
    flexDirection: "column",
    minWidth: 740,
    width: " 100%",
  },
  lateralSugestoes: {
    display: "flex",
    flexDirection: "column",
    background: "#26ACA9",
    minWidth: 330,
    borderRadius: "0 15px 15px 0",
    alignItems: "center",
    overflowY: "hidden",
  },
  paper: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 15,
    overflow: "unset",
    height: "100%",
  },
  fullSizePaper: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 15,
    overflow: "unset",
    height: 630,
    maxWidth: "1288px !important",
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 16,
    marginLeft: 5,
  },
  infos: {
    boxShadow: "inset 0px 11px 38px -15px rgba(0,0,0,0.07);",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    color: "#26ACA9",
    fontSize: "18px",
    fontWeight: 700,
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 32px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    background: '#fff',
    borderRadius: '16px',
  },
  dialogContent: {
    overflowY: "hidden",
    margin: 0,
    boxShadow: "inset 0px 14px 38px -30px rgba(0,0,0,0.3)",
    paddingTop: 16,
    background: "#fff",
    borderRadius: "0 0 15px 15px",
    height: "100%",
  },
  semLateralSugestoes: {
    backgroundColor: "#E0E0E0",
    borderRadius: "0 0 15px 15px",
    padding: "0 15px 15px 15px",
  },
  dialogContentBorder: {
    backgroundColor: "#E0E0E0",
    borderRadius: "0 0 0 15px",
    padding: "0 15px 15px 15px",
  },
  radioButton: {
    margin: "24px 0 16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "85%",
  },
  input: {
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 4,
  },
  scrollContainer: {
    overflowY: "auto",
    height: "295px",
    maxHeight: "100%",
  },
  actions: {
    position: "absolute",
    right: 0,
    bottom: -65,
  },
  buttonImpressao: {
    backgroundColor: "#26ACA9",
    width: "40px",
    height: "40px",
    color: "white",
    marginLeft: "10px",
    "&:hover": {
      background: Colors.primary.dark,
    },
  },
  downloadIconLink: {
    display: "flex",
    alignItems: "center",
  },
  buttonActionClose: {
    backgroundColor: "#FB7676",
    width: "40px",
    height: "40px",
    color: "white",
    marginLeft: 10,
    "&:hover": {
      background: Colors.commons.darkRed,
    },
  },
  tableHeadRow: {
    color: "#505050",
    fontWeight: 700,
  },
  tableHead: {
    padding: "6px 8px",
    textAlign: "center",
  },
  tableHeadDescricao: {
    padding: "6px 8px",
  },
  tableDataRow: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  tableData: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.8125rem",
    fontWeight: 400,
    padding: "6px 8px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  tableDataDescricao: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.8125rem",
    fontWeight: 400,
    padding: "6px 8px",
    alignItems: "center",
    display: "flex",
  },
  fieldReadOnly: {
    padding: "8px 0",
  },
  inputQtd: {
    fontSize: "14px",
    border: "0",
    minHeight: "6px",
    height: "18px",
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: "0 0 0 0",
    padding: "9px 0px 5px 10px",
    borderRadius: "100px",
  },
  notchedOutline: {
    border: "0",
  },
  inputTextField: {
    fontSize: "14px",
    border: "0",
    minHeight: "6px",
    height: 32,
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: "0 0 0 0",
    borderRadius: "100px",
  },
  classIcons: {
    fontSize: 24,
  },
  inputObservacoes: {
    fontFamily: "Poppins!important",
    border: "0",
    fontSize: "14px",
    minHeight: "6px",
    height: "18px",
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: "0 0 0 0",
    padding: "9px 0px 5px 10px",
    borderRadius: "100px",
    width: "100%",
  },
  inputObs: {
    width: "100%",
  },
  label: {
    fontFamily: "Poppins !important",
    fontWeight: "bold",
  },
  botoesSugestoesModelosProntos: {
    display: "flex",
    flexDirection: "row",
    minWidth: "auto",
    fontWeight: "normal !important",
    background: "rgba(0, 0, 0, 0.1)",
    "&.active": {
      background: "rgba(0, 0, 0, 0.5)",
    },
  },
  tituloSugestao: {
    display: "flex",
    flexDirection: "row",
    minWidth: "auto",
    fontWeight: "normal !important",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: 87,
    color: "#fff",
    width: "100%",
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 12px",
  },
  botaoLateral: {
    borderRadius: 24,
    boxShadow: "none",
    width: "auto",
    height: 40,
    padding: 16,
    textTransform: "capitalize",
    color: "#fff",
    background: "rgba(0, 0, 0, 0.1)",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.5)",
    },
  },
  cardSugestao: {
    margin: "10px 30px",
    background: "#fff",
    alignSelf: "center",
    padding: 10,
    width: "80%",
    borderRadius: 10,
    cursor: "pointer",
  },

  codigoTabela: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  criarNovoModelo: {
    backgroundColor: "#F9BE73",
    height: 40,
    width: 272,
    borderRadius: 100,
    textTransform: "capitalize",
    color: "#fff",
    alignSelf: "center",
    margin: "0 0 11px 8px",
  },
  labelCriar: {
    paddingLeft: 10,
  },
  opcaoModelo: {
    alignSelf: "center",
    height: "80%",
  },
  cardModelo: {
    margin: "16px 0",
    background: "#fff",
    alignSelf: "center",
    padding: 10,
    borderRadius: 10,
    width: 272,
    cursor: "pointer",
  },
  itemList: {
    fontSize: "12px !important",
    fontFamily: "Poppins !important",
  },
  obsStatus: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  buttonDelet: {
    color: "#fff",
    width: 40,
    height: 40,
    marginLeft: 10,
    backgroundColor: Colors.commons.gray6,
    "&:hover": {
      background: Colors.commons.gray7,
    },
  },
  scrollContainerSugestoes: {
    height: 520,
  },
  scrollContainerModelos: {
    overflowY: "auto",
    height: "60vh",
    maxHeight: "100%",
    "@media (max-height: 620px)": {
      height: "50vh",
    },
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    margin: 20,
  },
  buttonsTab: {
    margin: '16px 16px 8px',
  },
  headerContent: {
    padding: '8px 24px',
    background: '#fff',
  },
  cardSugestaoDisabled: {
    opacity: 0.5,
  },
  cursorDefault: {
    cursor: 'default',
  },
  buttonAction: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonIcon: {
    width: '30px !important',
    height: '30px !important',
    minHeight: 'initial !important',
  },
  selectStatus: {
    width: '100%',
  },
  inputError: {
    border: 'solid 1px red',
    borderRadius: '100px'
  }
};

export default styles;
