const styles = theme => ({
  content: {
    gridArea: "content",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    position: "relative",
    overflow: "hidden",

    "& thead th": {
      textAlign: "left",
      top: 0,
      position: "sticky",

      "&:first-child": {
          padding: '0 0 0 24px',
          width: '20px',
      },
      "&:last-child":{
        width: '50px',
        zIndex: 1,
      }
  },

    "& tbody td": {
      padding: '0 0 0 24px',

      "& .status": {
          width: 17,
          height: 16,
          borderRadius: 100,
          display: 'inline-block'
      },
      "& .true": {
          backgroundColor: "#00C1BF",
      },
      "& .false": {
          backgroundColor: "#FB7676",
      },
  }
  },

  search: {
    gridArea: "search",
    marginBottom: 20
  },

  lista: {
    gridArea: "lista",
    marginBottom: 20,
    overflow: "hidden"
  },

  tableContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 1,
    overflow: "hidden"
  },

  scrollContainer: {
    height: "100%"
  },

  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",

    "& h3": {
      color: theme.palette.primary.main,
      fontWeight: "normal",
      fontSize: "1rem"
    }
  },
});

export default styles;
