import React, { useEffect, useState } from 'react';
import { Paper, Popper, withStyles } from '@material-ui/core';
import PeriodInputDateForm from '../../../../components/Input/PeriodInputDateForm';
import { ReactSelect } from '../../../../components/Select/SelectSearch';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';
import ptBR from 'date-fns/locale/pt-BR';
import ButtonConfirm from '../../../../components/Button/ButtonConfirm';
import ButtonCancel from '../../../../components/Button/ButtonCancel';
import { typeOfFilters, defaultPeriods } from './config';
import { getPeriodInterval } from './dateUtil';
import moment from 'moment';
import { checkValidDate, getDatesFromString, startDayIsBeforeEndDay } from './dateUtil';

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 24px 48px rgba(0, 0, 0, 0.2)',
    justifyContent: 'space-between',
  },
  selectsContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 16px 0 16px',
    columnGap: '8px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  label: {
    fontSize: '12px !important',
    fontWeight: '400',
    color: '#868686'
  },
  calendars: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    padding: '0 16px',
    columnGap: '8px',
  },
  calendar: {
    width: '50%',
    paddingBottom: '16px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 16px 16px 0',
    columnGap: '8px',
  },
  day: {
    height: '2.5rem',
  },
  month: {
    width: '16rem'
  },
  errorMessage: {
    fontSize: '10px !important',
    color: '#FB7676',
    paddingLeft: '4px'
  },
  zIndex: {
    zIndex: 100
  }
});

const defaultFilter = {
  dataInicial: moment().startOf("month").toDate(),
  dataFinal: moment().endOf("month").toDate(),
  typeDateFilter: typeOfFilters[0],
  period: defaultPeriods[0],
}

const FiltroDataModal = ({ classes, onApply, isMesAtual }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState(defaultFilter);
  const [dateError, setDateError] = useState('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClickCalendar = (e) => {
    setDateError('');
    setAnchorEl(anchorEl ? null : e.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    isMesAtual && setFilter({
      ...filter,
      dataInicial: moment().startOf("month").toDate(),
      dataFinal: moment().endOf("month").toDate(),
    });

  }, [isMesAtual]);

  const handleChangeFilter = (value, field) => {
    if (field === 'period') {
      const { dataInicial, dataFinal } = getPeriodInterval(value.value);
      setFilter({
        ...filter,
        period: defaultPeriods.filter(periodo => periodo.value === value.value),
        dataInicial,
        dataFinal,
      });
    }

    if (field === 'dataInicial' || field === 'dataFinal') {
      setFilter({
        ...filter,
        period: defaultPeriods[0],
        [field]: value,
      });
    }

    if (field === 'typeDateFilter') {
      setFilter({
        ...filter,
        [field]: value
      });
    }
  };

  const handleApplyFilter = () => {
    const startIsBeforeEnd = startDayIsBeforeEndDay(null, filter.dataInicial, filter.dataFinal);
    if (startIsBeforeEnd) {
      onApply({
        dataInicial: filter.dataInicial,
        dataFinal: filter.dataFinal,
        typeFilter: filter.typeDateFilter.value,
      });
      setDateError('');
      setAnchorEl(null);
    } else {
      setDateError('Data inicial deve preceder data final');
      setAnchorEl(null);
    }
  };

  const handleDateInputChange = (e) => {
    const date = e?.target?.value;
    const dateIsValid = checkValidDate(date);
    if (dateIsValid) {
      const { dataInicial, dataFinal } = getDatesFromString(date);
      setFilter({...filter, dataInicial, dataFinal});
      const startIsBeforeEnd = startDayIsBeforeEndDay(date);
      if (startIsBeforeEnd) {
        setDateError('');
        onApply({
          dataInicial,
          dataFinal,
          typeFilter: filter.typeDateFilter.value,
        });
      } else {
        setDateError('Data inicial deve preceder data final');
      }
    } else {
      setDateError('Uma das datas estão inválidas');
    }
  };

  const calendarClassnames = {
    day: classes.day,
    month: classes.month,
  }

  return (
    <>
      <PeriodInputDateForm 
        onClickCalendar={handleClickCalendar}
        value={`
          ${moment(filter.dataInicial).format('DD/MM/YYYY')} à 
          ${moment(filter.dataFinal).format('DD/MM/YYYY')}
        `}
        onChange={handleDateInputChange}
        error={dateError}
        disabled={open}
      />
      {dateError && (
          <span className={classes.errorMessage}>{dateError}</span>
        )}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-end'}
        style={{ zIndex: 10 }}
      >
        <Paper >
          <div className={classes.paper}>
            <div className={classes.selectsContainer}>
              <div className={classes.form}> 
                <span className={classes.label}>Filtrar por:</span>
                <ReactSelect 
                  className={classes.select}
                  options={typeOfFilters}
                  value={filter.typeDateFilter}
                  onChange={item => handleChangeFilter(item, 'typeDateFilter')}
                />
              </div>
              <div className={classes.form}>
                <span className={classes.label}>Período</span>
                <ReactSelect 
                  className={classes.select}
                  options={defaultPeriods}
                  value={filter.period}
                  onChange={item => handleChangeFilter(item, 'period')}
                />
              </div>
            </div>
            <div className={classes.calendars}>
              <Calendar
                classNames={calendarClassnames}
                locale={ptBR}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                date={filter.dataInicial}
                onChange={day => handleChangeFilter(day, 'dataInicial')}
                color="#26ACA9"
                weekdayDisplayFormat='EEEEE'
              />
              <Calendar
                classNames={calendarClassnames}
                locale={ptBR}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                date={filter.dataFinal}
                onChange={day => handleChangeFilter(day, 'dataFinal')}
                color="#26ACA9"
                weekdayDisplayFormat='EEEEE'
              />
            </div>
            <div className={classes.buttonContainer}>
              <ButtonCancel onClick={handleClose}>Cancelar</ButtonCancel>
              <ButtonConfirm onClick={handleApplyFilter}>Aplicar</ButtonConfirm>
            </div>
          </div>
        </Paper>
      </Popper>
    </>
  );
};

export default withStyles(styles)(FiltroDataModal); 
