import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';

import { withStyles } from '@material-ui/core';
import { Button } from '../../../components/ui/Buttons';
import DeleteIcon from '../../../components/Icon/DeleteIcon';

import SendIcon from '../../../components/Icon/SendIcon';

import moment from 'moment';
import VoiceRecorder from '../../../components/VoiceRecorder';
import classNames from 'classnames';

const styles = ({
  container: {
    flex: 1,
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  containerSmallGap: {
    gap: '4px'
  },
  audioPlayer: {
    flex: 1,
    maxHeight: '40px',
  },
  audioRecorder: {
    maxHeight: '40px'
  }
});

const MAX_TIME = 10 * 60; // 10 minutes

const AudioRecorderComponent = observer(({ classes, handleCancel, chatStore, isPopup, contact }) => {
  const { loggedUser } = chatStore;
  const firstRender = useRef(true);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleDeleteAudio = () => {
    setIsCancelled(true)
    handleCancel();
  }

  const handleSendAudio = () => {
    if (!audioBlob) return;

    const dateNow = moment().format('YYYY-MM-DD_HH:mm:ss');
    const fileName = `${loggedUser.id}_${dateNow}`;

    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);

    reader.onload = async (e) => {
      const fileExtension = audioBlob.type.split('/').pop();
      const base64 = e.target.result.split('base64,')[1];

      const file = {
        nome: fileName,
        extensao: `.${fileExtension}`,
        base64: base64,
      }

      const messageParams = {
        message: '',
        tipoMensagem: 'AUDIO',
        contact: contact,
        file,
        isPopup
      }
      setIsSending(true);
      await chatStore.sendMessage(messageParams);
      handleDeleteAudio();
      setIsSending(false);
    }
  }

  const handleVoiceRecorderError = (errorMessage) => {
    chatStore.openNotification(errorMessage, 'error');
    handleDeleteAudio();
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    handleDeleteAudio();
  }, [contact]);

  return (
    <div className={classNames(classes.container, isPopup && classes.containerSmallGap)}>
      <Button
        kind='transparent'
        shape='circle'
        size='mini'
        onClick={handleDeleteAudio}
      >
        <DeleteIcon size={24} />
      </Button>
      <VoiceRecorder
        height={40}
        waveColor={"#27B0AD"}
        onData={setAudioBlob}
        isCancelled={isCancelled}
        limitTime={MAX_TIME}
        onError={handleVoiceRecorderError}
      />
      <Button 
        kind='transparent'
        shape='circle'
        onClick={handleSendAudio}
        disabled={!audioBlob || isSending}
      >
        <SendIcon size={24} color="#219A97"/>
      </Button>
    </div>
  );
});

const AudioRecorderStyles = withStyles(styles)(AudioRecorderComponent);
export default inject('chatStore')(AudioRecorderStyles);
