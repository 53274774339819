
const filterProcedimentosChecked = (procedimentos) => {
  return procedimentos.orcamentoProcedimentos.filter((item) => item.checked)
}

const verificaOrcamentosProcedimento = (orcamentoSelecionado, orcamentoComparacao, isOdontologico) => {

  let orcamentoProcedimentosSelecionadoChecked
  let orcamentoProcedimentosComparacaoChecked

  if (isOdontologico) {
    orcamentoProcedimentosSelecionadoChecked = filterProcedimentosChecked(orcamentoSelecionado)
    orcamentoProcedimentosComparacaoChecked = filterProcedimentosChecked(orcamentoComparacao)
  } else {
    orcamentoProcedimentosSelecionadoChecked = orcamentoSelecionado
    orcamentoProcedimentosComparacaoChecked = orcamentoComparacao
  }
  return orcamentoProcedimentosSelecionadoChecked.orcamentoProcedimentos.some((procedimentoSelecionado) => {
    const { id, valorUnitario, quantidade } = procedimentoSelecionado

    const procedimento = orcamentoProcedimentosComparacaoChecked.orcamentoProcedimentos.find((procedimento) => procedimento.id === id);
    return (
      procedimento &&
      valorUnitario === procedimento.valorUnitario &&
      quantidade === procedimento.quantidade
    );
  });
};



export const verificaIsDifferent = (orcamentoSelecionado, orcamentoComparacao, isOdontologico) => {

  const {
    nome,
    profissionalSaude,
    valor,
    tipoPagamento,
    dataVencimento,
    totalParcelas,
    valorEntrada,
    convenio,
    desconto,
    observacao
  } = orcamentoSelecionado


  const isDifferent =
    nome !== orcamentoComparacao?.nome ||
    profissionalSaude?.id !== orcamentoComparacao?.profissionalSaude?.id ||
    valor !== orcamentoComparacao?.valor ||
    dataVencimento !== orcamentoComparacao?.dataVencimento ||
    tipoPagamento?.value !== orcamentoComparacao?.tipoPagamento?.value ||
    totalParcelas?.value !== orcamentoComparacao?.totalParcelas?.value ||
    valorEntrada !== orcamentoComparacao?.valorEntrada ||
    convenio?.id !== orcamentoComparacao?.convenio?.id ||
    desconto?.tipoDesconto?.value !== orcamentoComparacao?.desconto?.tipoDesconto?.value ||
    observacao !== orcamentoComparacao.observacao ||
    !verificaOrcamentosProcedimento(orcamentoSelecionado, orcamentoComparacao, isOdontologico)

  return isDifferent

}