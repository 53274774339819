import React from 'react'
import moment from 'moment'
import { TAXA_ADMINISTRATIVA } from '../../../pages/ProfissionalSaude/constants/perfilViziConstants'

const ExtratoPagamentosContent = ({ dadosRelatorio, dataInicio, dataFim }) => {
  if (!dadosRelatorio) return null
  return (
    <>
      <div style={styles.titulo}>Relatório procedimentos agendados</div>
      <div style={styles.subTitulo}>
        {dataInicio && (
          <div style={styles.dataInicio}>
            Data início:
            <span style={styles.subTituloSpan}>{moment(dataInicio).format('DD/MM/YYYY')}</span>
          </div>
        )}
        {dataFim && (
          <div>
            Data fim:
            <span style={styles.subTituloSpan}>{moment(dataFim).format('DD/MM/YYYY')}</span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Médico</div>
        <div style={styles.column}>Paciente</div>
        <div style={styles.column}>Unidade</div>
        <div style={styles.column}>Valor Pago</div>
        <div style={styles.column}>Valor Receber</div>
      </div>

      {dadosRelatorio.map((item, index) => {
        const valorReceber = item?.valor - (item?.valor * TAXA_ADMINISTRATIVA) / 100
        return (
          <div key={index} style={styles.containerColuna}>
            <div style={styles.column}>{item.profissionalSaude.nome}</div>
            <div style={styles.column}>{item.sujeitoAtencao?.nome}</div>
            <div style={styles.column}>{item.unidade?.nomeFantasia}</div>
            <div style={styles.column}>
              {item?.valor ? item?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
            </div>
            <div style={styles.column}>
              {valorReceber ? valorReceber.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
            </div>
          </div>
        )
      })}
    </>
  )
}

const styles = {
  titulo: {
    marginLeft: '0px',
    marginBottom: '10px',
    marginTop: '5px',
    paddingBottom: '5px',
    textAlign: 'center',
    fontWeight: 700,
  },
  subTitulo: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #505050',
    marginBottom: '15px',
    paddingBottom: '5px',
    fontWeight: 700,
    justifyContent: 'center',
  },
  subTituloSpan: {
    marginLeft: '8px',
  },
  dataInicio: {
    marginRight: '16px',
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 700,
    width: '100%',
  },
  containerColuna: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  columnProcedimento: {
    width: '60%',
    minWidth: '60%',
    marginBottom: '10px',
    marginRight: '3px',
  },
  column: {
    width: '20%',
  },
  footerInfosTotal: {
    borderTop: '1px solid #505050',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    maringTop: '8px',
  },
  titleTotal: {
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0',
  },
  infosTotal: {
    display: 'flex',
    justifyContent: 'center',
  },
  info: {
    marginRight: '16px',
  },
  infoSpan: {
    fontWeight: 700,
    marginLeft: '8px',
  },
}

export default ExtratoPagamentosContent
