import React from 'react';
import { withStyles } from '@material-ui/core';
import { Rating } from 'react-simple-star-rating';

const styles = ({
  score: {
    fontSize: '20px !important',
    marginLeft: '15px'
  }
});

const Score = ({ classes, score }) => {
  return (
    <div>
      <Rating 
        emptyColor='#BDBDBD' 
        fillColor='#FFB905' 
        initialValue={score} 
        disableFillHover 
        size={20}
        allowFraction
        readonly
      />
      <span className={classes.score}>{score.toFixed(1)}</span>
    </div>
  )
};

export default withStyles(styles)(Score);