import moment from "moment";
import { getViziWebUrl } from "../../../config/config";

export const getDadosMensagem = (dadosMensagem) => {
    const { endereco, remoto, withEndereco, nomeProfissional, nomePaciente, chavePublica, isAgendamentoVizi, nomeFantasiaUnidade, horasPermiteAgendamento, data, horaInicio } = dadosMensagem;
    const mensagemProfissional = nomeProfissional ? ` com o(a) ${nomeProfissional}` : '';
    const linkTelemedicina = `${getViziWebUrl()}/telemedicina/${chavePublica}`;
    const url = isAgendamentoVizi ? process.env.REACT_APP_CONFIRMAR_AGENDAMENTO_VIZI : process.env.REACT_APP_CONFIRMAR_AGENDAMENTO;
    const mensagemTelemedicina = remoto ? `\n\nLink de acesso para telemedicina: ${linkTelemedicina}` : '';
    const enderecoUnidade = withEndereco && !remoto ? `No endereço ${endereco?.nomeLogradouro || ''} ${endereco?.numero || ''} ${endereco?.bairro ? `- ${endereco?.bairro || ''}` : ''}, ${endereco?.cep || ''} ${endereco?.municipio?.nome || ''}, ${endereco?.estado?.nome || ''}` : '';
    const mensagemConfirmacao = `\n\n*Clique no link a seguir para cancelar ou confirmar:* ${url}${chavePublica}`;
    const weekDay = moment(dadosMensagem.data, 'DD-MM-YYYY').format('dddd');
    const mensagemPadrao = `Olá ${nomePaciente}. Sou o(a) secretário(a) da(o) ${nomeFantasiaUnidade} e estou entrando em contato para confirmar seu agendamento ${mensagemProfissional} no dia ${data} (${weekDay}) às ${horaInicio}. ${enderecoUnidade ? `${enderecoUnidade}.` : ''}`;
    const mensagemConfirmacaoConsulta = `\n\nLembramos que a confirmação ou cancelamento da consulta deve ser realizada com no mínimo ${horasPermiteAgendamento} horas de antecedência do horário agendado, ok?`;

    return `${mensagemPadrao}${mensagemTelemedicina}${mensagemConfirmacao}${mensagemConfirmacaoConsulta}`;
};

export const getMensagemTeleconsulta = (dadosMensagem) => {
    const mensagemProfissional = dadosMensagem.nomeProfissional ? ` com o(a) ${dadosMensagem.nomeProfissional}` : '';
    const linkTelemedicina = `${getViziWebUrl()}/telemedicina/${dadosMensagem.chavePublica}`;
    const mensagemTelemedicina = dadosMensagem?.remoto ? `\n\nPara acessar clique no link abaixo: 👇\n${linkTelemedicina}` : '';
    const dataAgendamento = dadosMensagem.data;
    const horaAgendamento = dadosMensagem.horaInicio;
    const weekDay = moment(dadosMensagem.data, 'DD-MM-YYYY').format('dddd');
    const mensagemPadrao = `👋 Olá ${dadosMensagem.nomePaciente}, sou a secretário(a) da(o) ${dadosMensagem.nomeFantasiaUnidade}. Estamos entrando em contato para avisar sobre a sua teleconsulta ${mensagemProfissional} no dia 🗓️ ${dataAgendamento} (${weekDay}) às 🕐 ${horaAgendamento}.`;
    const mensagemRecomendacao = `\n\nRecomendação: Prepare seu ambiente e configure seus dispositivos de áudio e vídeo.`;

    return `${mensagemPadrao} ${mensagemRecomendacao} ${mensagemTelemedicina}`;
}