import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const CheckMarkIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M10 0.61377C15.1838 0.61377 19.3861 4.81605 19.3861 9.99983C19.3861 15.1836 15.1838 19.3859 10 19.3859C4.81623 19.3859 0.613953 15.1836 0.613953 9.99983C0.613953 4.81605 4.81623 0.61377 10 0.61377ZM13.3168 7.12766L8.82601 11.6305L6.64223 9.44675C6.33677 9.14129 5.84153 9.14129 5.53607 9.44675C5.23062 9.7522 5.23062 10.2474 5.53607 10.5529L8.27367 13.2905C8.57942 13.5962 9.07523 13.5959 9.38057 13.2898L14.4245 8.23234C14.7295 7.92647 14.7289 7.43123 14.423 7.12618C14.1171 6.82114 13.6219 6.8218 13.3168 7.12766Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckMarkIcon;
