import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import classNames from 'classnames'
import { Fab } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles/index'
import { Grid, CircularProgress, DialogTitle } from '@material-ui/core'
import StepSujeitoAtencao from './Steps/StepSujeitoAtencao'
import StepTabInterna from './Steps/StepTabInterna'
import StepSolicitacao from './Steps/StepSolicitacao'
import StepTabExterna from './Steps/StepTabExterna'
import StepAutorizacao from './Steps/StepAutorizacao'
import StepObservacao from './Steps/StepObservacao'
import StepItens from './Steps/StepItens'
import HorizontalLinearStepper from './Stepper'
import ButtonLong from '../../../components/Button/ButtonLong'
import Dialog from '../../../components/Dialog/Dialog'
import MultiToggleButtons from '../../../components/Button/MultiToggleButtons'
import {
  findAllProfissionalSaude,
  findAllSujeitoAtencao,
  saveGuiaServico,
} from '../../../services/GuiaDeServicosService'

import ButtonTransparent from '../../../components/Button/ButtonTransparent'

const tabs = {
  INTERNO: 0,
  EXTERNO: 1,
}

const GuiaDeServicosModal = observer(props => {
  const {
    classes,
    handleCloseModal,
    openNotification,
    loadFinanceiroGuiaServico,
    guiaDeServicoStore,
  } = props

  const [disableButton, setDisableButton] = useState(true)
  const [tabSelected, setTabSelected] = useState(tabs.INTERNO)
  const [activeStep, setActiveStep] = useState(0)
  const [procedimentoSelected, setProcedimentoSelected] = useState()
  const [guiaServico, setGuiaServico] = useState({})
  const [loadingSave, setLoadingSave] = useState(false)

  useEffect(() => {
    setGuiaServico(guiaDeServicoStore.guiaServico || {})
    setActiveStep(0)
  }, [guiaDeServicoStore.isOpenModal])

  const handleChangeGuiaServico = (field, value) => {
    setGuiaServico(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleSave = async () => {
    try {
      setLoadingSave(true)
      const agendamentoId = guiaServico.agendamentoId || ''

      const newGuiaServico = {
        agendamentoId,
        solicitacao: guiaServico.solicitacao,
        autorizacao: guiaServico.autorizacao,
        observacao: guiaServico.observacao,
        solicitante:
          tabSelected === tabs.INTERNO
            ? guiaServico.solicitanteInterna
            : guiaServico.solicitanteExterna,
        convenio: guiaServico.convenio,
        beneficiario: {
          ...guiaServico.beneficiario,
          sujeitoAtencao: {
            id: guiaServico.beneficiario.sujeitoAtencao.id,
          },
        },
      }
      await saveGuiaServico(newGuiaServico)
      guiaDeServicoStore.changeFieldsStore({
        isOpenModal: false,
        guiaServico: null,
      })
    } catch {
      openNotification('Erro ao salvar ', 'error')
    } finally {
      setLoadingSave(false)
      loadFinanceiroGuiaServico({ isSearching: true })
    }
  }

  const loadSujeitosAtencao = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllSujeitoAtencao(page, search)
      ).data.data.findAllSujeitoAtencao
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch {
      openNotification('Erro ao carregar ', 'error')
    }
  }

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
        })
      ).data.data.findAllProfissionalSaude
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch {
      openNotification('Erro ao carregar profissionais ', 'error')
    }
  }

  const handleNext = () => {
    handleChangeStep(activeStep + 1)
    setDisableButton(true)
  }

  const handleChangeStep = step => {
    setActiveStep(step)
  }

  const handleBack = () => {
    handleChangeStep(activeStep - 1)
  }

  const handleClose = () => {
    handleCloseModal()
    setTabSelected(tabs.INTERNO)
    setActiveStep(0)
    setGuiaServico({})
  }

  const changeTabSelected = tabSelected => {
    setTabSelected(tabSelected)
  }

  const toogleTitleModal = activeStep => {
    let title = ''
    switch (activeStep) {
      case 0:
        title = 'Guia do paciente'
        break

      case 1:
        title = 'Guia do solicitante'
        break

      case 2:
        title = 'Guia da solicitação'
        break

      case 3:
        title = 'Guia da solicitação'
        break

      case 4:
        title = 'Guia da observação'
        break

      case 5:
        title = 'Guia de autorização'
        break

      default:
        title = 'Guia de serviço'
        break
    }

    return title
  }

  const getSteps = () => {
    return [
      'Paciente',
      'Solicitante',
      'Solicitação',
      'Itens',
      { label: 'Observação', description: '*Não obrigatório' },
      { label: 'Autorização', description: '*Não obrigatório' },
    ]
  }

  return (
    <Dialog
      open={guiaDeServicoStore.isOpenModal}
      maxWidth={'md'}
      fullWidth={true}
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.root}>
        <DialogTitle classes={{ root: classes.dialogHeader }} disableTypography>
          <div className={classes.dialogTitle}>
            <h1>{toogleTitleModal(activeStep)}</h1>
            <div className={classes.wrapperActionsTitle}>
              <Fab className={classes.buttonActionClose} onClick={handleClose}>
                <CloseIcon />
              </Fab>
            </div>
          </div>
        </DialogTitle>
        <HorizontalLinearStepper activeStep={activeStep} steps={getSteps()} />

        <div
          className={classNames(
            classes.modalContent,
            activeStep === 3 && classes.modalContentItens
          )}
        >
          {activeStep === 0 && (
            <StepSujeitoAtencao
              sujeitoSelected={guiaServico?.beneficiario?.sujeitoAtencao}
              loadSujeitosAtencao={loadSujeitosAtencao}
              handleChangeGuiaServico={handleChangeGuiaServico}
              setDisableButton={setDisableButton}
              guiaServico={guiaServico}
            />
          )}

          {activeStep === 1 && (
            <div className={classes.toggleButtons}>
              <MultiToggleButtons
                options={['Interno', 'Externo']}
                tabSelected={tabSelected}
                changeTabSelected={changeTabSelected}
              />
            </div>
          )}

          {activeStep === 1 && tabSelected === tabs.INTERNO && (
            <StepTabInterna
              openNotification={openNotification}
              loadProfissionaisSaude={loadProfissionaisSaude}
              guiaServico={guiaServico}
              handleChangeGuiaServico={handleChangeGuiaServico}
              setDisableButton={setDisableButton}
            />
          )}

          {activeStep === 1 && tabSelected === tabs.EXTERNO && (
            <StepTabExterna
              openNotification={openNotification}
              tabExterna={guiaServico?.solicitanteExterna}
              handleChangeGuiaServico={handleChangeGuiaServico}
              setDisableButton={setDisableButton}
            />
          )}

          {activeStep === 2 && (
            <StepSolicitacao
              openNotification={openNotification}
              solicitacao={guiaServico?.solicitacao}
              handleChangeGuiaServico={handleChangeGuiaServico}
              setDisableButton={setDisableButton}
            />
          )}

          {activeStep === 3 && (
            <StepItens
              openNotification={openNotification}
              procedimentoSelected={procedimentoSelected}
              setProcedimentoSelected={setProcedimentoSelected}
              handleChangeGuiaServico={handleChangeGuiaServico}
              guiaServico={guiaServico}
              setDisableButton={setDisableButton}
            />
          )}

          {activeStep === 4 && (
            <StepObservacao
              handleChangeGuiaServico={handleChangeGuiaServico}
              guiaServico={guiaServico}
              setDisableButton={setDisableButton}
            />
          )}

          {activeStep === 5 && (
            <StepAutorizacao
              handleChangeGuiaServico={handleChangeGuiaServico}
              guiaServico={guiaServico}
              setDisableButton={setDisableButton}
            />
          )}
        </div>
        <Grid item xs={12} className={classes.formActions}>
          {activeStep === 3 || activeStep === 4 ? (
            <div className={classes.formActions}>
              <div className={classes.inlineButtons}>
                <ButtonTransparent onClick={handleBack}>Voltar</ButtonTransparent>
              </div>

              <div className={classes.inlineButtons}>
                <ButtonLong colorCustom='green' onClick={handleNext} disabled={disableButton}>
                  Próxima etapa
                </ButtonLong>
              </div>

              <div className={classes.inlineButtons}>
                <ButtonLong
                  colorCustom='yellow'
                  onClick={handleSave}
                  disabled={(activeStep === 3 && disableButton) || loadingSave}
                >
                  {loadingSave && <CircularProgress color='primary' size={12} />}
                  <span
                    className={classNames(
                      classes.labelSalvar,
                      loadingSave && classes.buttonLoadingSalvar
                    )}
                  >
                    Salvar
                  </span>
                </ButtonLong>
              </div>
            </div>
          ) : (
            <div className={classes.formActions}>
              <div className={classes.inlineButtons}>
                {activeStep !== 0 && (
                  <ButtonTransparent onClick={handleBack} disabled={activeStep === 0}>
                    Voltar
                  </ButtonTransparent>
                )}
              </div>
              <div className={classes.inlineButtons}>
                <ButtonLong
                  colorCustom={activeStep === 5 ? 'yellow' : 'green'}
                  onClick={activeStep === 5 ? handleSave : handleNext}
                  disabled={disableButton || loadingSave}
                >
                  {loadingSave && <CircularProgress color='inherit' size={12} />}
                  <span className={classes.labelSalvar}>
                    {activeStep === 5 ? 'Salvar' : 'Próxima etapa'}
                  </span>
                </ButtonLong>
              </div>
            </div>
          )}
        </Grid>
      </div>
    </Dialog>
  )
})

const styles = () => ({
  dialog: {
    height: 'max-content',
  },
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    height: '100%',
  },
  toggleButtons: {
    margin: '16px',
  },
  modalContent: {
    height: '100%',
    overflowY: 'auto',
  },
  modalContentItens: {
    overflowY: 'hidden',
  },
  formActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    margin: '1rem 0',
  },
  inlineButtons: {
    paddingRight: '10px',
    display: 'inline-flex',
    width: '100%',
    '& button': {
      flex: 1,
      fontWeight: 'bold',
    },
    '& button[disabled]': {
      opacity: '0.5',
      cursor: 'default !important',
    },
  },
  labelSalvar: {
    fontSize: '14px ',
  },
  buttonLoadingSalvar: {
    marginLeft: 10,
  },
  dialogHeader: {
    background: 'white',
    borderRadius: '15px 15px 0 0',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>h1': {
      color: '#26ACA9',
      fontSize: '1.5rem',
      marginTop: '0.375rem',
    },
  },
  wrapperActionsTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonActionClose: {
    backgroundColor: '#FB7676',
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
  },
})

const GuiaDeServicosModalWithStyles = withStyles(styles)(GuiaDeServicosModal)
export default inject('guiaDeServicoStore')(GuiaDeServicosModalWithStyles)
