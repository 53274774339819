import classNames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React from "react";
import ButtonLong from "../../../components/Button/ButtonLong";
import { tiposRepeticao } from "../../../stores/Financeiro/Extrato.store";
import string from "../../../utils/string";
import SuccessIcon from "../../../components/Icon/SuccessIcon";
import { modalConfirmacaoDefault } from "../../../stores/Atendimento.store";
import CircleCloseIcon from "../../../components/Icon/CircleCloseIcon";

const ButtonsTabPagamento = observer((props) => {
  const {
    classes,
    atendimentoStore,
    extratoStore,
    filtroHeaderStore,
    history,
    changeTabSelected,
    handleActionModalConfirmaEditRecorrencia,
  } = props;
  const { titulo, isTituloVinculaAgendamento, saving } = extratoStore || {};
  const { objView, isAgendamentoRecorrenciaFaturado, showTitulo } = atendimentoStore;
  const {
    convenio,
    sujeitoAtencao,
    profissionalSaude,
    procedimentos,
  } = objView || {};

  const isPossuiTitulo = titulo?.id;

  const handleSalvarPagamento = async () => {

    try {
      const procedimentos = objView.procedimentos.map((item)=>{
        return {
          id:item.procedimento.id,
          nome: item.procedimento.nome,
        }
      })

      const dadosTitulo = {
        ...titulo,
        sujeitoAtencao: { id: sujeitoAtencao?.id },
        tipo: "RECEITA",
        ...(profissionalSaude?.id && {profissionalSaude: {id: profissionalSaude?.id}}),
        convenio: convenio,
        voucherPrever: objView?.voucherPrever,
        procedimentos:[...procedimentos]
      };

      const response = await extratoStore.create(dadosTitulo, "agendamento");
      if (response?.id) {
        atendimentoStore.objView.titulo=[response]
        extratoStore.titulo.id = response?.id;
        changeTabSelected(1);
        extratoStore.preencherDadosPagamentoDoAgendamento();
      }else{
        atendimentoStore.dadosModalConfirmacao = {
          open: true,
          title: "Erro ao gerar pagamento!",
          color: "red",
          icon: <CircleCloseIcon/>
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleVincularTitulo = async () => {
    try {
      const response = await extratoStore.vincularTituloAgendamento({
        agendamentoId: atendimentoStore.idOpened,
        tituloId: extratoStore.titulo?.id,
      });
  
      atendimentoStore.objView.titulo = atendimentoStore.objView.titulo !== null
      ? [...atendimentoStore.objView.titulo, response]
      : [response];
  
      atendimentoStore.dadosModalConfirmacao = {
        open: true,
        title: "Título vinculado com sucesso",
        color: "green",
        icon: <SuccessIcon/>,
      };
  
      const timeoutId = setTimeout(() => {
        atendimentoStore.dadosModalConfirmacao = modalConfirmacaoDefault;
        clearTimeout(timeoutId);
      }, 3000);
  
      extratoStore.isTituloVinculaAgendamento = false;
      changeTabSelected(0);

    } catch (error) {
      atendimentoStore.openNotification('Erro ao vincular o titulo.', 'error');
    }
  };

  const handleAbrirFinanceiro = async () => {
    try {
      if (objView.titulo?.id) {
        await extratoStore.preencherDadosPagamentoDoAgendamento();
      } else {
        extratoStore.titulo = {
          ...extratoStore.titulo,
          tipo: "RECEITA",
          convenio,
          agendamento: {
            id: atendimentoStore.idOpened,
            sujeitoAtencao: {
              id: sujeitoAtencao?.id,
            },
          },
          procedimentos:
            procedimentos?.length > 0 ? getProcedimentos(procedimentos) : [],
          sujeitoAtencao: {
            ...sujeitoAtencao,
            label: sujeitoAtencao?.nome,
            value: sujeitoAtencao?.id,
          },
          profissionalSaude: {
            ...profissionalSaude,
            label: profissionalSaude?.nome,
            value: profissionalSaude?.id,
          },
          valor: typeof titulo?.valor === 'string' ? string.removeMask(String(titulo?.valor), "R$") : titulo?.valor,
          subtotal: typeof titulo?.subtotal === 'string' ?
            string.removeMask(String(titulo?.subtotal), "R$")
            : titulo?.subtotal,
        };

        extratoStore.modalState = {
          ...extratoStore.modalState,
          tipoRepeticao: tiposRepeticao[extratoStore.tabSelectedParcelamento],
        };
      }
      extratoStore.open = true;
      extratoStore.editMode = true;
      atendimentoStore.open = false;
      history.push("/financeiro");
      extratoStore.onlyReadParcelas = false;
      extratoStore.getTabs();
    } catch (error) {
      console.log(error);
    }
  };

  const getProcedimentos = (procedimentos) => {
    return procedimentos.map((procedimento) => {
      return {
        ...procedimento?.procedimento,
        ativo: true,
        label: procedimento.procedimento.nome,
        value: procedimento?.id,
      };
    });
  };

  const handleActionTitulo = async (action) => {
    const dadosTitulo = {
      ...titulo,
      sujeitoAtencao: { id: atendimentoStore.objView?.sujeitoAtencao?.id },
      tipo: "RECEITA",
      profissionalSaude: { id: filtroHeaderStore.profissionalSaude?.id },
      procedimentos: atendimentoStore.objView.procedimentos,
      convenio: atendimentoStore.objView.convenio,
    };

    if (extratoStore.contemErros(dadosTitulo)) return;

    const isRecorrencia = !!atendimentoStore.agendamentoRecorrencia?.id;
    
    if (isRecorrencia) {
      if (isAgendamentoRecorrenciaFaturado || atendimentoStore.isLastAgendamentoOfRecurrence) {
        actionSave(action, dadosTitulo);
      } else {
        handleActionModalConfirmaEditRecorrencia(true);
      }
    } else {
      actionSave(action, dadosTitulo);
    }
  };
  
  const actionSave = (action, dadosTitulo) =>
    action === "gerar"
      ? handleSalvarPagamento(dadosTitulo)
      : handleVincularTitulo();
  
  return (
    <div className={classes.contentButtons}>
          {!isPossuiTitulo && showTitulo && (
            <ButtonLong
              className={classes.button}
              colorCustom="green"
              onClick={() => handleActionTitulo("gerar")}
              noShadow
              disabled={saving}
              loading={saving}
            >
              Gerar pagamento
            </ButtonLong>
          )}
          {(isTituloVinculaAgendamento) && (
            <ButtonLong
              className={classes.button}
              colorCustom="green"
              onClick={() => handleActionTitulo("vincular")}
              noShadow
              disabled={saving}
              loading={saving}
            >
              Vincular titulo
            </ButtonLong>
          )}
          {isPossuiTitulo && (
            <ButtonLong
              className={classNames(
                classes.button,
                classes.buttonAbrirFinanceiro
              )}
              colorCustom="gray"
              onClick={handleAbrirFinanceiro}
              noShadow
              disabled={saving}
              loading={saving}
            >
              Editar
            </ButtonLong>
          )}
    </div>
  );
});

const styles = {
  contentButtons: {
    display: "flex",
    margin: "6px 16px 10px 0px",
    gap: "10px",
    width: "100%",
    justifyContent: "start",
  },
  buttonsPagamentoFinanceiro: {
    display: "flex",
    gap: "10px",
    width: "100%",
    maxWidth: "335px",
  },
  button: {
    padding: "8px",
    fontWeight: 700,
    maxWidth: "152px",
  },
  buttonAbrirFinanceiro: {
    maxWidth: "135px",
  },
};

const ButtonsTabPagamentoWithStyles = withStyles(styles)(ButtonsTabPagamento);
export default inject(
  "atendimentoStore",
  "extratoStore",
  "filtroHeaderStore"
)(ButtonsTabPagamentoWithStyles);
