import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { theme as defaultTheme } from "../../template/Theme";
import colors from "../../template/Colors";

const StyledCheckboxAtendimento = props => {
  const { color, label, checked, ...others } = props;
  const colorSelected = colors.commons[color];
  const colorLight = colors.commons[`${color}Light`];

  return (
    <MuiThemeProvider
      theme={
        colorSelected
          ? {
              ...defaultTheme,
              overrides: {
                ...defaultTheme.overrides,
                MuiFormControlLabel: {
                  root: {
                    borderRadius: '100px',
                    backgroundColor: colorLight,
                    color: colorSelected,
                    margin: '5px 0 !important',
                    width: 'min-content',
                  },
                  label:{
                    color: colorSelected,
                    padding: '0 5px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '10px !important',
                  },
                },
                MuiCheckbox: {
                  root: {
                    padding: 0,
                    color: `${colorSelected} !important`,
                    borderRadius: '50px !important',
                    backgroundColor: colorLight,
                    "&$checked": {
                      color: `${colorSelected} !important`,
                      backgroundColor: `${colorSelected}`,
                    }
                  }
                },
                MuiIconButton: {
                  label:{
                    borderRadius: 100,
                    width: 10,
                    height: 10,
                    border: `2px solid ${colorSelected}`,

                    "& checked": {
                    backgroundColor: colorSelected,
                    borderRadius: 100,
                    width: 10,
                    height: 10,
                  }
                  }
                },
                MuiSvgIcon: {
                  root: {
                    display: 'none',
                  }
                },
              }
            }
          : defaultTheme
      }
    >
      <FormControlLabel
      label={label}
      checked={checked}
      control={
      <Checkbox {...others} color={colors.commons[color] ? undefined : color} />
    }
    />
    </MuiThemeProvider>
    
  );
};

export default StyledCheckboxAtendimento;
