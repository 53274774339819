import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import string from "../../utils/string";

const styles = theme => ({
  root: {
    userSelect: "none",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fill: "currentColor",
    flexShrink: 0,
    fontSize: 22,
    transition: theme.transitions.create("fill", {
      duration: theme.transitions.duration.shorter
    })
  },
  colorPrimary: {
    color: theme.palette.primary.main
  },
  colorSecondary: {
    color: theme.palette.commons.gray4,
  }
});

const Eye = ({ classes, color, ...others }) => {
  const colorClass =
    typeof color === "string" ? classes[`color${string.capitalize(color)}`] : null;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.923 18.702"
      className={classNames(classes.root, colorClass)}
      {...others}
    >
      <path
        d="M14.962,3C6.7,3,0,12.351,0,12.351S6.7,21.7,14.962,21.7s14.962-9.351,14.962-9.351S23.224,3,14.962,3Zm0,14.962a5.611,5.611,0,1,1,5.611-5.611A5.61,5.61,0,0,1,14.962,17.962Z"
        transform="translate(0 -3)"
      />
    </svg>
  );
};

export default withStyles(styles)(Eye);
