import React, { useRef } from 'react'
import PopperCustomMenu from '../../../../../components/Popper/PopperCustomMenu'
import PencilIcon from '../../../../../components/Icon/Pencil'
import MoreIcon from '../../../../../components/Icon/More'
import DuplicaIcon from '../../../../../components/Icon/DuplicaIcon'
import Status from '../components/Status'

const menuOpcoesPopper = ({ data, functions }) => {
  const handleCallCurrentFunction = functionName => {
    if (functionName) {
      functionName(data)
      functions.closePopper()
    }
  }

  const optionsMenu = [
    {
      ativo: true,
      icon: <PencilIcon size='20' color='#505050' />,
      label: 'Editar',
      onClick: () => handleCallCurrentFunction(functions?.handleEdit),
    },
    {
      ativo: true,
      icon: <DuplicaIcon size='20' color='#505050' />,
      label: 'Duplicar',
      onClick: () => handleCallCurrentFunction(functions?.handleDuplicar),
    },
  ]
  return optionsMenu
}

export const COLUMNS_PROCEDIMENTO = ({ handleChangeStatus, disabledStatus, openModal }) => {
  const popperRef = useRef(null)
  return [
    {
      Header: 'Nome do modelo',
      getValue: row => {
        return row.nome
      },
    },

    {
      Header: 'Procedimentos realizados',
      getValue: row => {
        return (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            {row?.procedimentos?.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    padding: '4px 8px',
                    borderRadius: '100px',
                    backgroundColor: '#F2F2F2',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                  }}
                >
                  {item.nome}
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      Header: 'Status',
      onlyIcon: true,
      getValue: row => {
        return (
          <Status
            checked={row.ativo}
            onChange={() => handleChangeStatus(row.id)}
            disabled={disabledStatus}
          />
        )
      },
    },
    {
      Header: '',
      onlyIcon: true,
      getValue: row => {
        return (
          <PopperCustomMenu
            ref={popperRef}
            placement={'bottom-end'}
            menuOpcoes={menuOpcoesPopper({
              data: row,
              functions: {
                handleEdit: () => openModal(row),
                handleDuplicar: () => openModal(row, true),
                closePopper: popperRef?.current?.closePopper,
              },
            })}
            iconButton={<MoreIcon color='#505050' />}
            colorButton={{
              background: '#fff',
            }}
          />
        )
      },
    },
  ]
}
