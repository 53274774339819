import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const EyeOutlineIcon = props => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M5.15351 14.6448L5.15198 14.6502C5.04097 15.0673 4.61279 15.3164 4.19547 15.2058C3.52117 15.027 3.63989 14.2492 3.63989 14.2492L3.66752 14.1532C3.66752 14.1532 3.70836 14.0216 3.74125 13.9261C3.80699 13.7353 3.90752 13.4684 4.05044 13.1509C4.33551 12.5176 4.79369 11.6726 5.48879 10.825C6.89282 9.11295 9.2531 7.41016 12.9998 7.41016C16.7465 7.41016 19.1068 9.11295 20.5108 10.825C21.2059 11.6726 21.6641 12.5176 21.9492 13.1509C22.0921 13.4684 22.1926 13.7353 22.2584 13.9261C22.2913 14.0216 22.3155 14.0984 22.3321 14.1532C22.3404 14.1806 22.3468 14.2026 22.3513 14.2187L22.3568 14.2385L22.3586 14.245L22.3593 14.2474L22.3597 14.2492C22.4705 14.6668 22.2217 15.095 21.8042 15.2058C21.3872 15.3163 20.9597 15.0685 20.8482 14.6521L20.8477 14.6502L20.8461 14.6448L20.8346 14.6057C20.8235 14.5688 20.8053 14.5111 20.7793 14.4355C20.7272 14.2842 20.6438 14.0622 20.5227 13.793C20.2797 13.2531 19.889 12.5337 19.3012 11.817C18.1395 10.4004 16.1979 8.9745 12.9998 8.9745C9.80179 8.9745 7.86013 10.4004 6.6984 11.817C6.11062 12.5337 5.71997 13.2531 5.47693 13.793C5.35579 14.0622 5.27245 14.2842 5.22032 14.4355C5.19429 14.5111 5.17613 14.5688 5.165 14.6057L5.15351 14.6448ZM9.08909 16.014C9.08909 13.8541 10.84 12.1032 12.9999 12.1032C15.1598 12.1032 16.9108 13.8541 16.9108 16.014C16.9108 18.1739 15.1598 19.9249 12.9999 19.9249C10.84 19.9249 9.08909 18.1739 9.08909 16.014Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default EyeOutlineIcon
