import moment from "moment";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import string from "../../../../../../../utils/string";
import {
  defaultErrors,
  orcamentoDefault,
} from "../../../../../../../stores/Orcamento.store";
import { getProfissionalSaudeLogado } from "../../../../../../../services/UsuarioService";
import {
  findConfiguracaoUnidade,
  saveOrcamento,
} from "../../../../../../../services/OdontogramaService";
import Notification from "../../../../../../../components/Notification";
import ModalOrcamento from "./ModalOrcamento";
import { findAllConvenioList } from "../../../../../../../services/OrcamentoService";

const ModalOrcamentoOrtopedico = observer(
  ({
    orcamentoStore,
    setOpenModalOrcamento,
    openModalOrcamento,
    sujeitoAtencaoSelected,
    loadList,
  }) => {
    const { dadosConvenio, nome: sujeitoAtencaoNome } = sujeitoAtencaoSelected || {};
    const { convenio: convenioSujeitoAtencao } = dadosConvenio || {};
    const {
      orcamento,
      handleFieldModal,
      notification,
      closeAlertMessage,
    } = orcamentoStore;

    const [configUnidade, setConfigUnidade] = useState(null);
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const {
      nome,
      convenio,
      id,
      observacao,
      orcamentoProcedimentos,
      profissionalSaude,
      status,
      tipoPagamento,
      totalParcelas,
      valor,
      valorEntrada,
      locaisPalmilhaPeDireito,
      locaisPalmilhaPeEsquerdo,
    } = orcamento || {};

    useEffect(() => {
      if(!id) {
        getDadosOrcamento();
        alteraNomeOrcamento();
      };

      getConfigUnidade();
      orcamentoStore.errors = defaultErrors;
    }, []);

    const alteraNomeOrcamento = () => {
      handleFieldModal('nome', sujeitoAtencaoNome);

    };

    const getDadosOrcamento = async () => {
      getDadosSemOrcamento();
    };

    const getConfigUnidade = async () => {
      const configUnidade = await findConfiguracaoUnidade();

      setConfigUnidade(configUnidade);
    };

    const contemErros = () => {
      const errors = {
        nome: !nome,
        procedimentos: orcamentoProcedimentos.length === 0,
        tipoPagamento: !tipoPagamento,
        valor: !valor,
      };

      orcamentoStore.errors = errors;
      const possuiErros = Object.values(errors).some((item) => item);

      return possuiErros;
    };

    const getLocaisPalmilha = () => {
      const locaisDireito = locaisPalmilhaPeDireito.map((item) => {
        return {
          localPalmilha: {
            id: item.id,
          },
          espessura: item.checked ? parseFloat(item.espessura) : null,
        }
      });

      const locaisEsquerdo = locaisPalmilhaPeEsquerdo.map((item) => {
        return {
          localPalmilha: {
            id: item.id,
          },
          espessura: item.checked ? parseFloat(item.espessura) : null,
        }
      });

      return {
        locaisPalmilhaPeDireito: locaisDireito,
        locaisPalmilhaPeEsquerdo: locaisEsquerdo,
      };
    };

    const handleSave = async (action = "") => {
      try {
        if (contemErros()) {
          const message = "Favor verificar os campos obrigatórios";

          orcamentoStore.openNotification(message, "error");
          return;
        }

        setIsLoadingSave(true);
        const procedimentos = orcamentoProcedimentos.map((item) => {
          return {
            procedimento: {
              id: item.procedimento.id,
            },
            ...( item?.espessura && { espessura: item.espessura }),
            valorUnitario:
              typeof item.valorUnitario === "string"
                ? string.currencyMaskToFloat(item.valorUnitario)
                : item.valorUnitario,
          };
        });
       const locaisPalmilha = getLocaisPalmilha();

        const variables = {
          ...(id && { id }),
          nome,
          observacao,
          tipo: "ORTOPEDICO",
          status: action || status || "PENDENTE",
          orcamentoProcedimentos: procedimentos,
          valor:
            typeof valor === "string"
              ? string.currencyMaskToFloat(valor)
              : valor,
          profissionalSaude: {
            id: profissionalSaude?.id,
          },
          ...(totalParcelas && { totalParcelas: totalParcelas.value }),
          ...(valorEntrada && {
            valorEntrada:
              typeof valorEntrada === "string"
                ? string.currencyMaskToFloat(valorEntrada)
                : valorEntrada,
          }),
          ...(convenio?.value && {
            convenio: { id: convenio?.value },
          }),
          ...(tipoPagamento?.value && {
            tipoPagamento: tipoPagamento.value,
          }),
          sujeitoAtencao: {
            id: sujeitoAtencaoSelected.id,
          },
          locaisPalmilhaPeDireito: locaisPalmilha.locaisPalmilhaPeDireito,
          locaisPalmilhaPeEsquerdo: locaisPalmilha.locaisPalmilhaPeEsquerdo,
        };

        const response = await saveOrcamento(variables);
        await loadList();

        if (response.status === "PENDENTE") {
          orcamentoStore.orcamento = orcamentoDefault;
        } else if (response.status === "APROVADO") {
          orcamentoStore.orcamento = {
            ...orcamentoStore.orcamento,
            id: response?.id,
          };
        }

        orcamentoStore.openNotification(
          "Orçamento salvo com sucesso!",
          "success"
        );
        setOpenModalOrcamento(false);
      } catch (e) {
        orcamentoStore.openNotification("Erro ao salvar o orçamento", "error");
      } finally {
        setIsLoadingSave(false);
      }
    };

    const getDadosSemOrcamento = async () => {
      const profissionalLogado = await getProfissionalSaudeLogado();
      const convenios = await findAllConvenioList();

      const profissionalSaude = {
        id: profissionalLogado.id,
        value: profissionalLogado.id,
        label: profissionalLogado.nome,
      };

      orcamentoStore.orcamento = {
        ...orcamento,
        profissionalSaude,
        convenio: convenios[0],
      };
    };

    return (
      <>
        <ModalOrcamento
          open={openModalOrcamento}
          onClose={() => setOpenModalOrcamento(false)}
          title={"Orçamento palmilha"}
          handleSave={handleSave}
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
          isLoadingSave={isLoadingSave}
          setIsLoadingSave={setIsLoadingSave}
        />
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </>
    );
  }
);

export default inject(
  "odontogramaStore",
  "orcamentoStore"
)(ModalOrcamentoOrtopedico);
