import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const AttachFileIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...normalizeProps(props)}>
      <path d="M5.28033 10.6088L10.5836 5.30546C11.6576 4.23151 13.3988 4.23151 14.4727 5.30546C15.5467 6.3794 15.5467 8.1206 14.4727 9.19454L8.10876 15.5585C7.6206 16.0467 6.82915 16.0467 6.34099 15.5585C5.85283 15.0703 5.85283 14.2789 6.34099 13.7907L11.9978 8.13388C12.2907 7.84099 12.2907 7.36612 11.9978 7.07322C11.705 6.78033 11.2301 6.78033 10.9372 7.07322L5.28033 12.7301C4.20639 13.804 4.20639 15.5452 5.28033 16.6192C6.35427 17.6931 8.09548 17.6931 9.16942 16.6192L15.5334 10.2552C17.1931 8.59548 17.1931 5.90452 15.5334 4.2448C13.8737 2.58507 11.1827 2.58507 9.52297 4.2448L4.21967 9.5481C3.92678 9.84099 3.92678 10.3159 4.21967 10.6088C4.51256 10.9017 4.98744 10.9017 5.28033 10.6088Z" fill="currentColor"/>
    </svg>
  );
};

export default AttachFileIcon;
