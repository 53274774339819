import React from "react";
import radioUnchecked from "../../../assets/img/radio-unchecked.jpg";
import radioChecked from "../../../assets/img/radio-checked.jpg";
import checkBoxUnchecked from "../../../assets/img/check-box-unchecked.jpg";
import checkBoxChecked from "../../../assets/img/check-box-checked.jpg";

const AnamnesePdfDocument = (props) => {
  const { dadosAnamnese, sujeitoAtencao } = props;

  const tipoCampoAnamnese = (tipo, resposta) => {
    const { valorImpressao, valorResposta, nome } = resposta;
    const respostaValor = valorImpressao || valorResposta;

    switch (tipo) {
      case "CHECKBOX":
        return (respostaValor) ? (
          <div style={styles.iconNome}>
            <div style={styles.divIcon}>
              <img
                src={checkBoxChecked}
                style={styles.icon}
                alt="check-box-checked"
              />
            </div>
            <div style={styles.divRespostaNome}> {nome} </div>
          </div>
        ) : (
          <div style={styles.iconNome}>
            <div style={styles.divIcon}>
              <img
                src={checkBoxUnchecked}
                style={styles.icon}
                alt="check-box-unchecked"
              />
            </div>
            <div style={styles.divRespostaNome}> {nome} </div>
          </div>
        );
      case "RADIO":
        return (respostaValor) ? (
          <div style={styles.iconNome}>
            <div style={styles.divIcon}>
              <img
                src={radioChecked}
                style={styles.icon}
                alt="radio-checked"
              />
            </div>
            <div style={styles.divRespostaNome}> {nome} </div>
          </div>
        ) : (
          <div style={styles.iconNome}>
            <div style={styles.divIcon}>

              <img
                src={radioUnchecked}
                style={styles.icon}
                alt="radio-unchecked"
              />
            </div>
            <div style={styles.divRespostaNome}> {nome} </div>
          </div>
        );
      case "COMBOBOX":
        if (respostaValor) {
          return (
            <div style={styles.respostaView}>
              <div> {nome} </div>
            </div>
          );
        } else {
          return;
        }
      default:
        return (
          <div
            style={
              tipo === "TEXT_AREA"
                ? styles.respostaViewTextarea
                : styles.respostaView
            }
          >
            <div> {resposta.valorConvertido || valorResposta || ""} </div>
          </div>
        );
    }
  };

  const tipoPerguntaTamanho = (tipo) => {
    return tipo === "DATE" || tipo === "DATETIME" || tipo === "TIME";
  };

  return (
    <>
      <div style={styles.nomeAnamnese}> {dadosAnamnese.nome} </div>
      <div style={styles.dadosPaciente}>
        <div> Nome: {sujeitoAtencao.nome} </div>
        <div> Telefone: {sujeitoAtencao.contato?.telefonePrincipal} </div>
      </div>
      <div style={tipoPerguntaTamanho && styles.viewPeguntas}>
        {dadosAnamnese.perguntas.map((item) => (
          <div
            key={item.id}
            style={
              tipoPerguntaTamanho(item.tipo)
                ? styles.respostaViewDates
                : styles.pergunta
            }
          >
            <div style={styles.colorFont}> {item.pergunta} </div>
            <div></div>
            <div style={styles.marginTopResposta}>
              {item.respostas.map((resposta, index) => (
                <div key={index}>{tipoCampoAnamnese(item.tipo, resposta)}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const styles = {
  textBold: {
    fontWeight: 700,
  },
  headerData: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: "15px",
    height: "15px",
    marginRight: "8px",
  },
  respostaView: {
    backgroundColor: "#f2f2f2",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "4px",
    paddingLeft: "8px",
    borderRadius: "100px",
  },
  colorFont: {
    color: "#505050",
  },
  respostaViewTextarea: {
    backgroundColor: "#f2f2f2",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "4px",
    paddingLeft: "8px",
    minHeight: "50px",
    borderRadius: "10px",
  },
  divRespostaNome: {
    paddingTop: "8px",
  },
  iconNome: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  divIcon: {
    marginTop: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pergunta: {
    marginTop: "10px",
    width: "100%",
  },
  marginTopResposta: {
    marginTop: "5px",
  },
  nomeAnamnese: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 700,
  },
  respostaViewDates: {
    flexGrow: 1,
    padding: "0 5px",
  },
  viewPeguntas: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  dadosPaciente: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 700,
  },
};

export default AnamnesePdfDocument;
