import React from 'react'
import { withStyles } from '@material-ui/core'
import Dialog from '../../../../../components/Dialog/Dialog'
import CloseIcon from '../../../../../components/Icon/CloseIcon'

const ModalInfoBaixa = ({ open, icon, classes, title, onClose, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.paperAlert,
      }}
    >
      <div className={classes.dialogHeader}>
        {icon && <div className={classes.wrapperIcon}>{icon}</div>}
        {title && <h5>{title}</h5>}
        <CloseIcon onClick={onClose} size={20} />
      </div>
      {children}
    </Dialog>
  )
}

const styles = {
  paperAlert: {
    borderRadius: '12px',
    padding: '24px',
    gap: '16px',
    minWidth: '350px',
    maxWidth: '400px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',

    '& h5': {
      fontSize: '18px',
      fontWeight: '500',
      color: '#000',
      margin: 0,
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  wrapperIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: '#219A97',
    borderRadius: '100px',
  },
}

export default withStyles(styles)(ModalInfoBaixa)
