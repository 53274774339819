import Colors from "../../template/Colors";

const styles = {
  dialogPaper: {
    maxWidth: "540px",
  },
  modalHeader: {
    marginTop: '24px',
    padding: "0px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleModal: {
    fontWeight: 600,
    fontSize: "24px",
    color: "#000",
  },
  toggleButtons: {
    marginRight: 0,
  },
  contentModal: {
    margin: "16px 16px 0 16px",
  },
  contentTab: {
    marginTop: 12,
    maxHeight: '175px',
    overflow: 'hidden',

    "&>div": {
      height: "100%",
    },
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 32px",
    gap: "10px",
    height: "69px",
    alignItems: "center",
  },
  buttonCancelar: {
    color: Colors.commons.gray9,
    cursor: "pointer",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontWeight: 700,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    marginTop: 10,
  },
  rowBanco: {
    display: 'flex',
    gap: '16px'
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  titleField: {
    color: "#868686",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "4px",
  },
  inputValor: {
    width: "calc(100% - 10px)",
    background: "#F2F2F2",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  widthMaximo: {
    width: "100%",
  },
  inputRetangular: {
    borderRadius: "8px",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  notchedOutlineSearch: {
    border: "none",
  },
  saldoInicial: {
    maxWidth: 160,
    marginTop: "8px",
  },
  contentTabInformacoes: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
  buttonClose: {
    width: 32,
    height: 32,
    background: "#FB7676",
    borderRadius: "100px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: Colors.commons.darkRed,
    },
    "& svg": {
      color: "#fff",
    },
  },
  semMargin: {
    margin: 0,
  },
  inputStatus: {
    "&>div>div": {
      height: 32,
      borderRadius: "8px",
      display: "flex",
      alignContent: "center",
      background: "#f2f2f2",
    },
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default styles;
