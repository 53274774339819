import React from 'react'
import {Route} from "react-router-dom"

import {withStyles} from "@material-ui/core/styles/index"

import {inject, observer} from "mobx-react"
import {Grid} from '@material-ui/core'
import InfoAdicionais from "../../Atendimento/InfoAdicionais";

import Notification from "../../../components/Notification"
import Footer from "../../../components/Footer/Footer"

import PanelLeft from '../../../components/PanelLeft/PanelLeft'
import SujeitoAtencaoSubMenu from './Menu/SujeitoAtencaoSubMenu'

import InformacoesPrincipais from "./InformacoesPrincipais";
import ProntuarioSubMenu from "./ProntuarioSubMenu";
import ConsultasPage from "./ConsultasPage";
import Auditoria from './AuditoriaPage'
import ProcedimentosRealizados from "./ProcedimentosRealizados";
import SujeitoAtencaoAnexo from "./Anexos/SujeitoAtencaoAnexo";
import PersonalizarCamposProntuario from "../../Atendimento/Prontuario/PersonalizarCamposProntuario";

import styles from "../../../assets/jss/pages/sujeitoAtencaoStyle"
import Profile from '../../../template/Header/Profile';
import {findByIdSujeitoAtencaoImpressao} from "../../../services/SujeitoAtencaoService";
import string from '../../../utils/string';
import { verificaScreen } from '../../../utils/verificaScreen';
import dates from '../../../utils/dates';
import {findUnidadeLogada} from "../../../services/UnidadeService";
import ImageProfile from '../../../components/ImageProfile/ImageProfile'
import SearchProcedimentosRealizados from "./SearchProcedimentosRealizados";
import SearchHeader from './SearchHeader'
import PagamentosPaciente from './Pagamentos/PagamentosPaciente'
import PacoteSessoes from './PacoteSessoes'
import Sessoes from './Sessoes'
import Orcamentos from "./Orcamentos";
import moment from 'moment'
import Documentos from './Documentos/index'

@inject(
    'sujeitoAtencaoStore', 
    'configuracaoImpressaoStore', 
    'profissionalSaudeStore', 
    'atendimentoStore', 
    'prontuarioStore',
    'sujeitoAtencaoAnexoStore',
    'unidadeStore',
    'odontogramaStore',
)
@observer
class SujeitoAtencaoDados extends React.Component {
    state = {
        printing: false,
        sujeitoAtencaoRecuperado: [],
        unidade: [],
        loadingImage: false,
        selectedCamposProntuario: [],
        openPersonalizarCamposProntuario: false,
    };

    async componentDidMount() {
        const {profissionalSaudeStore, sujeitoAtencaoAnexoStore, sujeitoAtencaoStore} = this.props;
        const paramsSujeitoAtencaoId = this.props.match.params.sujeitoId
        const storeSujeitoAtencaoId = sujeitoAtencaoStore?.objView?.id

        !profissionalSaudeStore.profissionalSaude && await profissionalSaudeStore.carregaProfissionalSaude();

        const sujeitoAtencaoId = paramsSujeitoAtencaoId || storeSujeitoAtencaoId;
        sujeitoAtencaoStore.load(sujeitoAtencaoId);
        await sujeitoAtencaoStore.getUltimaConsulta(sujeitoAtencaoStore.idOpened);

        const selectedCamposProntuario = await verificaScreen('prontuario', this.props.location) && await this.props.prontuarioStore.findAllCampoProntuarioByProfissionalSaudeLogadoList()

        this.setState({selectedCamposProntuario})

        const accessToken = await sujeitoAtencaoAnexoStore.getAccessToken();
        sujeitoAtencaoAnexoStore.accessToken = accessToken;
    }

    saveSujeitoAtencao = () => this.props.sujeitoAtencaoStore.save();
    resetNotification = () => this.props.sujeitoAtencaoStore.resetNotification();

    cancelar = () => {
        this.props.sujeitoAtencaoStore.reset();
        this.props.history.push(`/sujeitos-de-atencao`)
    };


    handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.sujeitoAtencaoStore.closeNotification();
    };

    handleChangeCampoProntuario = (e, field) => {
        const { prontuarioStore } = this.props;
        if (!prontuarioStore.sujeitoAtencaoSelected) {
            return;
        }

        const campo = string.capitalizeSecondWord(field)
        prontuarioStore.sujeitoAtencaoSelected[campo] = e.target.value;
        this.debounceUpdateCampoProntuario(e.target.value, field);
    };

    handlePrint = async () => {
        let {idOpened} = this.props.sujeitoAtencaoStore;

        const dadosRecuperados = await findByIdSujeitoAtencaoImpressao(idOpened).then(resposta => {
            return resposta.data.data.findByIdSujeitoAtencao;
        });

        const unidade = await findUnidadeLogada().then(resposta => {
            return resposta;
        });

        this.setState({
            sujeitoAtencaoRecuperado: dadosRecuperados,
            unidade: unidade,
            printing: true
        })
    };

    handleSaveImage = (image) => {
        this.salvarImagem(image);
    };

    async salvarImagem(image) {
        this.setState({loadingImage: true});
        await this.props.sujeitoAtencaoStore.salvarImagem(image);
        this.setState({loadingImage: false});
    }

    updateSelectedCamposProntuario = async (campo) => {
        const selectedCamposProntuario = await this.props.prontuarioStore.findAllCampoProntuarioByProfissionalSaudeLogadoList()
        this.setState({selectedCamposProntuario});
    }

    debounceUpdateCampoProntuario(value, field) {
        this.props.prontuarioStore.updateSujeitoAtencaoNoAtendimento(value, field);
    }

    getCampoProntuarioValue = (campoProntuario) => {
        const sujeitoAtencao = this.props.prontuarioStore.sujeitoAtencaoSelected;
        const valor = campoProntuario.caminhoValor;
        const valorCampo = string.getValueByPath(sujeitoAtencao, valor);

        if(!valorCampo) return;

        switch (campoProntuario.campo) {
            case 'IDADE':
                return dates.calculaIdade(valorCampo);
            case 'ALTURA':
                return string.adicionaSufixo(valorCampo, 'cm');
            case 'PESO':
                return string.adicionaSufixo(valorCampo, 'kg');
            case 'DATA_NASCIMENTO':
                return moment(valorCampo).format("DD/MM/YYYY");
            default:
                return string.capitalizeEachWord(valorCampo);
        }
    }

    render() {
        const {classes, sujeitoAtencaoStore, match, location, sujeitoAtencaoAnexoStore, odontogramaStore} = this.props;
        const { utilizaTelefoneInternacional } = this.props.unidadeStore.unidade;
        const {objView} = this.props.sujeitoAtencaoStore;
        const {loadingImage, selectedCamposProntuario} = this.state;

        return (
            <div className={classes.root}>

                <PanelLeft style={{padding: "0"}}>
                    <div className={classes.headerPanelLeft}>
                        <Grid item container alignItems={"center"} justify={"center"} xs={12}>
                            <h3 className={classes.titleHeader}>Paciente</h3>
                        </Grid>
                    </div>

                    <SujeitoAtencaoSubMenu match={match} location={location}/>
                </PanelLeft>
                <div className={classes.content}>

                    {
                        !odontogramaStore.isTelaAmpliada &&
                        <>
                            <div className={classes.headerContent}>
                                <Grid item xs={12}>
                                    <Grid container justify={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            <ImageProfile
                                                image={objView.urlImagemPerfil}
                                                nome={objView.nome}
                                                dataNascimento={objView.dataNascimento}
                                                contato={
                                                    utilizaTelefoneInternacional ? 
                                                        sujeitoAtencaoStore.getPhoneValue(objView.telefonePrincipal, objView.telefonePrincipalDDI) :
                                                        objView.telefonePrincipal
                                                }
                                                telefonePrincipalDDI={objView?.telefonePrincipalDDI || ''}
                                                sujeitoAtencaoId={this.props.match.params.sujeitoId}
                                                loading={loadingImage}
                                                onSave={this.handleSaveImage}
                                                utilizaTelefoneInternacional={utilizaTelefoneInternacional}
                                            />
                                        </Grid>
                                        <Grid item style={{
                                            display: "flex",
                                            backgroundColor: "white",
                                            zIndex: 1,
                                            height: 64,
                                            paddingRight: 16
                                        }}>
                                            <Profile/>
                                        </Grid>
                                    </Grid>
                                    {location?.pathname.includes('/prontuario') &&
                                        <div className={classes.infoAdicionais}>
                                            {selectedCamposProntuario.map(item => {
                                                return (
                                                    <InfoAdicionais informacao={`${item.campoProntuario.nome}:`}>
                                                        <div className={classes.infoAdicional}>
                                                            {this.getCampoProntuarioValue(item.campoProntuario)}
                                                        </div>
                                                    </InfoAdicionais>
                                                )
                                            })}
                                        </div>
                                    }
                                    
                                    {location?.pathname.includes('/procedimentos-realizados') && <SearchProcedimentosRealizados sujeitoId={this.props.match.params.sujeitoId}/>}
                                    {location?.pathname.includes('/arquivos') && 
                                    <SearchHeader
                                        placeholder="Pesquise por nome do arquivo"
                                        handleClickSearch={sujeitoAtencaoAnexoStore.handleClickSearchHeader}
                                        handleClickClear={sujeitoAtencaoAnexoStore.handleClickClearHeader}
                                        onChangeSearch={sujeitoAtencaoAnexoStore.onChangeSearchHeader}
                                        onKeypressSearch={sujeitoAtencaoAnexoStore.onKeypressSearchHeader}
                                        search={sujeitoAtencaoAnexoStore.searchHeader}
                                    
                                    />}
                                </Grid>
                            </div>
                        </>
                    }

                    <Route exact path="/sujeito-atencao" component={InformacoesPrincipais}/>
                    <Route path={`/sujeito-atencao/prontuario`} render={props => {
                        return (<ProntuarioSubMenu resetNotification={this.resetNotification} {...props}/>)
                    }}/>
                    <Route exact path="/sujeito-atencao/edit/:id" component={InformacoesPrincipais}/>
                    <Route path="/sujeito-atencao/edit/:id/prontuario" render={props => {
                        return (<ProntuarioSubMenu resetNotification={this.resetNotification} {...props}/>)
                    }}/>
                    <Route path="/sujeito-atencao/edit/:id/consultas" component={ConsultasPage}/>
                    <Route path="/sujeito-atencao/edit/:id/auditoria" component={Auditoria}/>
                    <Route path="/sujeito-atencao/edit/:id/procedimentos-realizados" component={ProcedimentosRealizados}/>
                    <Route path="/sujeito-atencao/edit/:id/arquivos" component={SujeitoAtencaoAnexo}/>
                    <Route path="/sujeito-atencao/edit/:id/pagamentos" component={PagamentosPaciente}/>
                    <Route exact path="/sujeito-atencao/edit/:id/sessoes-em-andamento" component={PacoteSessoes}/>
                    <Route exact path="/sujeito-atencao/edit/:id/sessoes-em-andamento/:sessionId" component={Sessoes}/>
                    <Route exact path="/sujeito-atencao/edit/:id/orcamentos" component={Orcamentos}/>
                    <Route path="/sujeito-atencao/edit/:id/documentos" component={Documentos}/>
                </div>

                <Footer />

                <Notification
                    close={this.handleClose}
                    reset={this.resetNotification}
                    isOpen={sujeitoAtencaoStore.notification.isOpen}
                    variant={sujeitoAtencaoStore.notification.variant}
                    message={sujeitoAtencaoStore.notification.message}
                />

                {this.state.openPersonalizarCamposProntuario && (
                    <PersonalizarCamposProntuario
                        open={this.state.openPersonalizarCamposProntuario}
                        handleClose={this.setState({openPersonalizarCamposProntuario: false})}
                        selectedCamposProntuario={this.state.selectedCamposProntuario}
                        updateSelectedCamposProntuario={this.updateSelectedCamposProntuario}
                    />
                )}

            </div>
        )

    }
}

export default withStyles(styles)(SujeitoAtencaoDados)
