import { inject } from "mobx-react";
import { withStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import styles from "../../../../assets/jss/pages/configuracaoReceitaStyle";
import string from "../../../../utils/string";
import {
    LarguraAlturaPapel, posicoes,
} from "../../../../stores/ConfiguracaoImpressaoStore";
import { cmToPx } from "../../../../utils/MetricUnit";

const errorsName = ["largura", "altura", "margem.superior", "margem.inferior", "margem.direita", "margem.esquerda"]

const PreVisualizacao = observer((props) => {
  const { classes, configuracaoImpressaoStore } = props;
  const {
    errors,
    configuracaoImpressao,
    margemFocused,
  } = configuracaoImpressaoStore;

  const previewScale = 4;

  const getWidthHeightPreviewPapel = () => {
    const { retrato } = configuracaoImpressao;
    const largura = string.numberMaskToFloat(configuracaoImpressao.largura);
    const altura = string.numberMaskToFloat(configuracaoImpressao.altura);

    if (!largura && !altura) {
      return {
        display: "none",
      };
    }

    if (largura > LarguraAlturaPapel.A4[0]) {
      return {
        display: "none",
      };
    }
    if (altura > LarguraAlturaPapel.A4[1]) {
      return {
        display: "none",
      };
    }

    const larguraEmPx = cmToPx(largura);
    const alturaEmPx = cmToPx(altura);

    const larguraPreview = larguraEmPx / previewScale || 0;
    const alturaPreview = alturaEmPx / previewScale || 0;

    return {
      width: retrato ? larguraPreview : alturaPreview,
      height: retrato ? alturaPreview : larguraPreview,
    };
  };

  const getStyleMargemPreviewPapel = (posicao) => {
    const margem = string.numberMaskToFloat(
      configuracaoImpressao.margem[posicao]
    );
    const largura = string.numberMaskToFloat(configuracaoImpressao.largura);
    const altura = string.numberMaskToFloat(configuracaoImpressao.altura);
    const isMargemVertical = posicao === "superior" || posicao === "inferior";
    const isMargemHorizontal = posicao === "direita" || posicao === "esquerda";

    if (!margem) {
      return { display: "none" };
    }

    if (isMargemVertical && margem > altura) {
      return { display: "none" };
    }

    if (isMargemHorizontal && margem > largura) {
      return { display: "none" };
    }

    const margemEmPx = cmToPx(margem);

    const margemPreview = margemEmPx / previewScale || 0;

    const cssPosition = posicao && posicoes[posicao];

    if (!cssPosition) {
      return { display: "none" };
    }

    return {
      [cssPosition]: margemPreview,
    };
  };

  return (
    <div className={classes.previewContainer}>
      <Typography component="h6">Pré-Visualização</Typography>

      {errors && (
        <div className={classes.previewErrorContainer}>
            {
                errorsName.map((item, index) => {
                    const possuiMargem = item.includes("margem");
                    const field = possuiMargem ? item.substring(7, item.length) : item;
                    const erro = possuiMargem && errors.margem ? (errors.margem[field]) : errors[field];
                    return <p key={index} className={classes.previewErrorMessage}>
                        {erro}
                    </p>
                })
            }
        </div>
      )}

      <div
        className={classes.previewPaper}
        style={{ ...getWidthHeightPreviewPapel() }}
      >
        <div
          className={classnames(
            classes.previewMargemHorizontal,
            margemFocused.superior
              ? classes.previewMargemHorizontalFocused
              : null,
            classes.previewMargemSuperior
          )}
          style={{ ...getStyleMargemPreviewPapel("superior") }}
        />
        <div
          className={classnames(
            classes.previewMargemHorizontal,
            margemFocused.inferior
              ? classes.previewMargemHorizontalFocused
              : null,
            classes.previewMargemInferior
          )}
          style={{ ...getStyleMargemPreviewPapel("inferior") }}
        />
        <div
          className={classnames(
            classes.previewMargemVertical,
            margemFocused.esquerda
              ? classes.previewMargemVerticalFocused
              : null,
            classes.previewMargemEsquerda
          )}
          style={{ ...getStyleMargemPreviewPapel("esquerda") }}
        />
        <div
          className={classnames(
            classes.previewMargemVertical,
            margemFocused.direita ? classes.previewMargemVerticalFocused : null,
            classes.previewMargemDireita
          )}
          style={{ ...getStyleMargemPreviewPapel("direita") }}
        />
      </div>
    </div>
  );
});

const PreVisualizacaoWithStyles = withStyles(styles)(PreVisualizacao);
export default inject("configuracaoImpressaoStore")(PreVisualizacaoWithStyles);
