import React from "react";
import { inject } from "mobx-react";
import { withStyles, CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import InputDefault from "../../../components/Input/InputDefault";
import AgendaConfiguracaoAtendimento from "../../../components/AgendaConfiguracaoAtendimento/AgendaConfiguracaoAtendimento";
import styles from "../../../assets/jss/pages/horarioAtendimentoStyle";
import { InputDateForm, InputTimeForm } from "../../../components/Modal/Input";
import classNames from "classnames";


const InformacoesHorarioAtendimento = observer((props) => {
  const { classes, configuracaoHorarioAtendimentoStore } = props;
  const {
    agendaValues,
    loadingConfig,
    configErrorMessage,
  } = configuracaoHorarioAtendimentoStore;

  const handleChange = (e, action) => {
    configuracaoHorarioAtendimentoStore[action](action === "setDuracao" ? e.target.value : e);
  };

  const handleSelected = (selecteds) => {
    agendaValues.map((weekday) => {
      return weekday.values.map((hour) => (hour.selected = false));
    });

    selecteds.forEach((selected) => {
      const { props, state } = selected;

      if (props.item.selected !== state.selected) {
        props.item.selected = state.selected;
      }
    });

    configuracaoHorarioAtendimentoStore.createTimesSelecteds();
  };

  return (
    <>
      {agendaValues.length > 0 && (
        <div className={classes.agenda}>
          <div className={classes.inputsContainer}>
            <div className={classes.inputGroup}>
              <InputDefault
                value={configuracaoHorarioAtendimentoStore.values.duracao}
                onChange={(e) => handleChange(e, "setDuracao")}
                error={configuracaoHorarioAtendimentoStore.duracaoError}
                label="Tempo de atendimento"
                classes={{
                  inputRoot: classes.durationInputRoot,
                  inputFocused: classes.durationInputFocused,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.durationInputLabel,
                  },
                }}
              />
            </div>
            <div className={classes.inputGroup}>
              <InputTimeForm
                label="Início"
                value={configuracaoHorarioAtendimentoStore.values.horaInicioPeriodoAtendimento}
                classes={{
                  date: classes.dateHora,
                }}
                iconposition="end"
                className={classes.dateTime}
                onChange={(e) => handleChange(e, "setHoraInicioPeriodoAtendimento")}
              />
              <InputTimeForm
                label="Fim"
                value={configuracaoHorarioAtendimentoStore.values.horaFimPeriodoAtendimento}
                classes={{
                  date: classes.dateHora,
                }}
                iconposition="end"
                className={classes.dateTime}
                onChange={(e) => handleChange(e, "setHoraFimPeriodoAtendimento")}
              />
            </div>
            <div className={classes.inputGroup}>
              <InputDateForm
                label="Inicio da vigência"
                iconposition="end"
                maxDate={configuracaoHorarioAtendimentoStore.values
                  .dataFimVigencia}
                className={classNames(classes.date, classes.dateTime)}
                classes={{
                  date: classes.dateHora,
                  label: classes.dateLabel
                }}
                value={configuracaoHorarioAtendimentoStore.values.dataInicioVigencia}
                onChange={(e) => handleChange(e, "setDataInicioVigencia")}
              />
              <InputDateForm
                label="Fim da vigência"
                iconposition="end"
                minDate={configuracaoHorarioAtendimentoStore.values
                  .dataInicioVigencia}
                className={classNames(classes.date, classes.dateTime)}
                classes={{
                  date: classes.dateHora,
                  label: classes.dateLabel
                }}
                value={configuracaoHorarioAtendimentoStore.values
                  .dataFimVigencia}
                onChange={(e) => handleChange(e, "setDataFimVigencia")}
              />  
            </div>
          </div>
          <AgendaConfiguracaoAtendimento
            values={configuracaoHorarioAtendimentoStore.agendaValues}
            onSelected={handleSelected}
          />
        </div>
      )}

      {loadingConfig && (
        <div className={classes.contentLoadingContainer}>
          <CircularProgress color="primary" size={32} />
        </div>
      )}
      {configErrorMessage && (
        <div className={classes.contentLoadingContainer}>
          <h3 className={classes.notFoundTitle}>{configErrorMessage}</h3>
        </div>
      )}
    </>
  );
});

const InformacoesHorarioAtendimentoWithStyles = withStyles(styles)(
  InformacoesHorarioAtendimento
);
export default inject("configuracaoHorarioAtendimentoStore")(
  InformacoesHorarioAtendimentoWithStyles
);
