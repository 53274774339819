const getTipoProfissional = (tipoProfissional) => {
    switch (tipoProfissional) {
        case 1:
			return 'medico'
		case 2:
			return 'secretario' 
		case 3:
			return 'administrador' 
		case 4:
			return 'tecnico' 
		default:
			return null;
    }
}

export default getTipoProfissional;