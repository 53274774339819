import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import Notification from "../../../components/Notification";
import {
  findAllTuss52TerminologiaTipoConsulta,
  findAllTuss22ProcedimentoEventoSaude,
} from "../../../services/AgendamentoTipoService";

import { TextFieldSearch } from "../../../components/TextField";
import Colors from "../../../template/Colors";
import classNames from "classnames";
class RowHeaderModal extends React.Component {
  state = {
    notification: {
      isOpen: false,
      message: "",
    },
  };

  componentDidMount() {
    const { agendamentoTipo } = this.props;

    if (agendamentoTipo) {
      this.setState((prevState) => ({
        agendamentoTipo: { ...prevState.agendamentoTipo, ...agendamentoTipo },
      }));
    }
  }

  loadAllTuss52 = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: "ASC",
      sortField: "descricao",
    };
    try {
      const { content, last } = (await findAllTuss52TerminologiaTipoConsulta(
        pageableDTO
      )).data.data.findAllTuss52TerminologiaTipoConsulta;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch {
      this.openNotification(
        "Erro ao carregar Tuss 52 - Tipos de consultas",
        "error"
      );
    }
  };

  isNumeric = (numero) => /^\d+$/.test(numero);

  loadAllTuss22 = async (search, loadedOptions, { page }) => {
    const field = this.isNumeric(search) ? "codigoTermo" : "descricao";
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: "ASC",
      sortField: "descricao",
    };
    const searchDTO = {
      pageableDTO,
      [field]: search,
    };

    try {
      const { content, last } = (await findAllTuss22ProcedimentoEventoSaude(
        searchDTO
      )).data.data.findAllTuss22ProcedimentoEventoSaude;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch {
      this.openNotification(
        "Erro ao carregar Tuss 22 - Procedimentos e eventos em saúde ",
        "error"
      );
    }
  };

  openNotification = (message, variant = null) => {
    this.setState({
      notification: {
        isOpen: true,
        message: message,
        variant: variant,
      },
    });
  };

  closeNotification = () => {
    this.setState({
      notification: {
        isOpen: false,
      },
    });
  };

  resetNotification = () => {
    this.setState({
      notification: {
        isOpen: false,
        message: "",
        variant: "",
      },
    });
  };

  render() {
    const { classes, handleChangeTipoConsulta, agendamentoTipo } = this.props;
    return (
      <>
        <Grid container direction="row" className={classes.marginTop}>
          <Grid item xs={6}>
            <Typography
              color="primary"
              component="label"
              className={classes.typography}
            >
              Tuss 52 - Tipos de consultas
            </Typography>
            <TextFieldSearch
              placeholder=""
              classNotched={classes.notchedOutline}
              classInput={classNames(
                classes.inputTextField,
                classes.marginRight
              )}
              classIcons={classes.classIcons}
              loadOptions={this.loadAllTuss52}
              getOptionLabel={(option) =>
                `${option.codigoTermo} - ${option.descricao}`
              }
              getOptionValue={(option) => option}
              value={agendamentoTipo.tuss52TerminologiaTipoConsulta}
              onChange={(option) =>
                handleChangeTipoConsulta(
                  option,
                  "tuss52TerminologiaTipoConsulta"
                )
              }
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition="fixed"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              color="primary"
              component="label"
              className={classes.typography}
            >
              Tuss 22 - Procedimentos e eventos em saúde
            </Typography>
            <TextFieldSearch
              placeholder=""
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              loadOptions={this.loadAllTuss22}
              getOptionLabel={(option) =>
                `${option.codigoTermo} - ${option.descricao}`
              }
              getOptionValue={(option) => option}
              value={agendamentoTipo.tuss22ProcedimentoEventoSaude}
              onChange={(option) =>
                handleChangeTipoConsulta(
                  option,
                  "tuss22ProcedimentoEventoSaude"
                )
              }
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition="fixed"
            />
          </Grid>
        </Grid>
        <Notification
          close={this.closeNotification}
          reset={this.resetNotification}
          isOpen={this.state.notification.isOpen}
          variant={this.state.notification.variant}
          message={this.state.notification.message}
        />
      </>
    );
  }
}

const styles = () => ({
  contentRow: {
    display: "flex",
    flexDirection: "row",
  },
  marginTop: {
    marginTop: "8px",
  },
  notchedOutline: {
    border: "0",
  },
  inputTextField: {
    fontSize: "0.875rem",
    border: "0",
    minHeight: "0.375rem",
    height: 32,
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray11,
    margin: "0",
    borderRadius: "6.25rem",
  },
  classIcons: {
    fontSize: 24,
  },
  typography: {
    fontSize: "0.875rem",
    fontFamily: "Nexa",
    fontWeight: "400",
    lineHeight: "normal",
  },
  selectTuss52: {
    width: "100%",
    marginTop: "1rem",
  },
  selectTuss22: {
    width: "100%",
    margin: "1.5rem 0 1rem 0",
  },
  marginRight: {
    marginRight: "10px",
  },
});

export default withStyles(styles)(RowHeaderModal);
