import { CircularProgress, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Table from '../../../../components/Table/Table';
import { pagamentosSujeitoAtencaoProfissionalLogado, pagamentosSujeitoAtencaoOutrosProfissionais } from '../../../../services/SujeitoAtencaoService';
import string from '../../../../utils/string';
import { applyCurrencyMask } from "../../../../utils/CurrencyMask";
import styles from './styles';
import { TituloTipoRepeticao } from '../../../../stores/Financeiro/Extrato.store';
import MensagemListaVazia from '../../../../components/Mensagem/MensagemListaVazia';
import { checkUserRole } from '../../../../utils/checkUserRole';

const columns = [
    {
        Header: "Status",
        paddingLeft: true,
        getValue: row => {
            let statusPagamento = "";

            if (row.dataPagamento) {
                statusPagamento = "Pago"
            } else {
                statusPagamento = moment(row.dataVencimento).isSameOrAfter(moment(), 'date') ? "Aberto" : "Atrasado"
            }

            return <div className={classNames("statusPagamento", statusPagamento)}> {statusPagamento} </div>;
        }
    },
    {
        Header: "Data",
        field: "dataVencimento",
        getValue: row => {
            if (string.isEmpty(row.dataVencimento)) {
                return "-";
            }
            return moment(row.dataVencimento).format("DD/MM/YYYY");
        }
    },
    {
        Header: "Valor",
        getValue: row => {
            if (typeof row.valor !== "number") {
                return "-";
            }
            return applyCurrencyMask(row.valor);
        }
    },
    {
        Header: "Descrição",
        field: "nome",
        getValue: ({ nome, numeroParcela, tipoRepeticao, totalParcelas }) => {
            if (tipoRepeticao === TituloTipoRepeticao.NAO_REPETIR) {
                return nome;
            }
            return `${numeroParcela === 0 ? "E" : numeroParcela
                }/${totalParcelas} ${nome}`;
        }
    },
    {
        Header: "Profissional de saúde",
        field: "profissionalSaude.nome",
        getValue: (row) => {
            return row.profissionalSaude?.nome
                ? row.profissionalSaude.nome
                : "-";
        },
    },
    {
        Header: "Forma de pagamento",
        field: "formaPagamento.nome",
        getValue: (row) => {
            return row.formaPagamento?.nome || '-'
        },
    },
    {
        Header: "Data pagamento",
        getValue: row => {
            if (string.isEmpty(row.dataPagamento)) {
                return "-";
            }
            return moment(row.dataPagamento).format("DD/MM/YYYY");
        }
    },
];


const PagamentosPaciente = props => {
    const { classes, sujeitoAtencaoStore } = props;

    const [titulosParcela, setTitulosParcela] = useState([]);
    const [ordenarTabela, setOrdenarTabela] = useState({
        sortField: "dataVencimento",
        sortDir: "DESC"
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadtitulosParcela()
    }, [ordenarTabela])

    const loadtitulosParcela = async () => {
        setLoading(true);
        const query = await checkUserRole("ROLE_TITULO_PARCELA_READ_OUTROS_PROFISSIONAIS") ? pagamentosSujeitoAtencaoOutrosProfissionais : pagamentosSujeitoAtencaoProfissionalLogado;

        const listaTitulos = await query({
            sujeitoAtencaoId: sujeitoAtencaoStore.idOpened,
            pageableDTO: ordenarTabela
        });
        
        setTitulosParcela(listaTitulos);
        setLoading(false)
    };

    const handleClickOrdenar = async (value) => {
        const sortDir = ordenarTabela.sortField !== value ? "ASC" : (ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC")

        setOrdenarTabela(
            {
                sortDir: sortDir,
                sortField: value,
            }
        );
    };

    return (
        <div className={classes.content}>
            <PageTitle title="Paciente - Pagamentos" />
            {
                loading ?
                    <div className={classes.notFoundContainer}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {titulosParcela.length > 0 ? <Table
                            className={classes.table}
                            dados={titulosParcela}
                            columns={columns}
                            comOrdenacao
                            ordenarTabela={ordenarTabela}
                            handleClickOrdenar={handleClickOrdenar}
                        />
                            :
                            <MensagemListaVazia />}
                    </>
            }

        </div>
    )
};

const PagamentosPacienteWithStyles = withStyles(styles)(PagamentosPaciente);
export default inject("sujeitoAtencaoStore")(PagamentosPacienteWithStyles);