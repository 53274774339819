import { ClickAwayListener, Paper, Popper, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { addDays } from 'date-fns';
import { checkValidDate, startDayIsBeforeEndDay, getDatesFromString } from './dateUtil';
import ptBR from 'date-fns/locale/pt-BR';
import { DateRangePicker } from 'react-date-range';
import 'react-calendar/dist/Calendar.css';
import PeriodInputDateForm from "../Input/PeriodInputDateForm";
import styles from "./PeriodDateStyles";
import ButtonLong from "../Button/ButtonLong";

const PeriodDate = (props) => {
  const { classes, onChangeDate, filter, handleAplicar, setFilter, minDate, searchOnlyOnApply, stylePopper, hiddenButtonAplicar, children, withoutZIndex } = props;

  const [state, setState] = useState([
    {
      startDate: addDays(new Date(filter.dataInicial), 1),
      endDate: addDays(new Date(filter.dataFinal), 1),
      key: 'selection'
    }
  ]);
  const [dateError, setDateError] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    if (!searchOnlyOnApply) {
      handleAplicar && handleAplicar();
    }

  }, [filter.dataInicial, filter.dataFinal])

  const handleClickCalendar = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDate = (item) => {
    setState([item.selection]);
    onChangeDate(item.selection.startDate, item.selection.endDate);
  };

  const handleDateInputChange = (e) => {
    const date = e?.target?.value;
    const dateIsValid = checkValidDate(date);

    if (dateIsValid) {
      const { dataInicial, dataFinal } = getDatesFromString(date);
      setFilter({ ...filter, dataInicial, dataFinal });
      const startIsBeforeEnd = startDayIsBeforeEndDay(date);
      if (startIsBeforeEnd) {
        setDateError("");
        setState([{
          startDate: dataInicial,
          endDate: dataFinal,
          key: 'selection'
        }])
      } else {
        setDateError("Data inicial deve preceder data final");
      }
    } else {
      setDateError("Uma das datas estão inválidas");
    }
  };

  const handleAplicarFiltro = () => {
    handleAplicar && handleAplicar();
    handleClose();
  };

  const initialDate = minDate ? minDate : moment(0).toDate();

  return (
    <>
      <PeriodInputDateForm
        onClickCalendar={handleClickCalendar}
        value={`
          ${moment(filter.dataInicial).format("DD/MM/YYYY")} à 
          ${moment(filter.dataFinal).format("DD/MM/YYYY")}
        `}
        onChange={handleDateInputChange}
        error={dateError}
        disabled={open}
        {...props}
      />
      {dateError && (
          <span className={classes.errorMessage}>{dateError}</span>
        )}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-end'}
        className={stylePopper}
        style={!withoutZIndex ? { zIndex: 10 } : null}
      >
        <Paper className={classes.contentCalendar}>
          <ClickAwayListener onClickAway={() => handleClose()}>
            { children }
            <DateRangePicker
              onChange={handleDate}
              locale={ptBR}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
              editableDateInputs={true}
              weekdayDisplayFormat='EEEEE'
              minDate={initialDate}
            />
            {!hiddenButtonAplicar && <div className={classes.contentButton}>
                <ButtonLong
                    className={classes.button}
                    colorCustom="green"
                    onClick={handleAplicarFiltro}
                >
                    Aplicar
                </ButtonLong>
            </div>}
            </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default withStyles(styles)(PeriodDate);
