import React from "react";
import { inject, observer } from "mobx-react";
import {
    withStyles,
    Grid,
    CircularProgress,
    Fab
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from '../../../components/Icon/Save';
import ButtonLoading from "../../../components/ButtonLoading";
import Profile from "../../../template/Header/Profile";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { tabs } from "../../../stores/Auditoria.store";
import styles from "../../../assets/jss/pages/configuracaoReceitaStyle";
import InformacoesConfiguracaoReceita from "./InformacoesConfiguracaoReceita/InformacoesConfiguracaoReceita";
import Auditoria from "../../../components/Auditoria/Auditoria";
import { findAllConfiguracaoImpressaoAuditByConfiguracaoImpressaoId } from "../../../services/AuditoriaService";
@inject("configuracaoImpressaoStore", "filtroHeaderStore", "googleAnalyticsStore", "auditoriaStore")
@observer
class ConfiguracaoReceita extends React.Component {
    constructor(props) {
        super(props);

        this.previewScale = 4;

        this.state = {
            margemFocused: {
                esquerda: false,
                direita: false,
                superior: false,
                inferior: false,
            },
            showHtmlEdit: false,
            tabSelected: tabs.INFORMACOES,
        };        
    }

    async componentDidMount() {
        const { configuracaoImpressaoStore, match, auditoriaStore } = this.props;
        const tipoImpressao = match.params.tipo

        await configuracaoImpressaoStore.getConfig(tipoImpressao, 'configuracao');

        await configuracaoImpressaoStore.buildUrlImages();

        const paginaVisualizada = {
            page: window.location.hash, 
            title: 'Configurações - Configuração de Horário de Atendimento'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "a configuração de impressão",
            auditoriaTela: "configuracaoImpressao",
        }
    }

    handleChangeCheckbox = (campo, event) => {
        const { configuracaoImpressaoStore } = this.props;
        configuracaoImpressaoStore.configuracaoImpressao[campo] = event.target.checked;
    };

    handleChangeMargem = (campo, event) => {
        const { configuracaoImpressaoStore } = this.props;
        configuracaoImpressaoStore.configuracaoImpressao.margem[campo] = event.target.value;
    };

    handleSave = async () => {
        try {
            const { configuracaoImpressaoStore } = this.props;
            await configuracaoImpressaoStore.save();
    
            this.goToList();
        } catch (error) {
            console.error(error)
        }
    };

    goToList = () => {
        const { history } = this.props;
        history.push("/configuracoes/receita") 
    };

    changeTabSelected = async(tabSelected) => {
        const { auditoriaStore, configuracaoImpressaoStore } = this.props;

        auditoriaStore.auditoriaProps={
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        }

        if(tabSelected === 1) {
            auditoriaStore.load={
                query: findAllConfiguracaoImpressaoAuditByConfiguracaoImpressaoId, 
                variableName: "configuracaoUnidadeId",
                variableId: configuracaoImpressaoStore.configuracaoImpressao.id, 
            }
            await auditoriaStore.loadAuditItems();
        }

        this.setState({ tabSelected })
    };

    renderTab = () => {
        const { filtroHeaderStore } = this.props;
        const { tabSelected } = this.state;

        return tabSelected === 0 ? 
            <InformacoesConfiguracaoReceita
                isProfissionalSaudeSelecionado = {filtroHeaderStore.profissionalSaude !== 0}
            /> 
            : <Auditoria />
    };

    render() {
        const { classes, configuracaoImpressaoStore, filtroHeaderStore } = this.props;
        const { tabSelected, } = this.state;
        const { loadingConfig } = configuracaoImpressaoStore;

        const isProfissionalSaudeSelecionado = filtroHeaderStore.profissionalSaude !== 0;

        return (
            <div className={classes.content}>
                <PageTitle title={pageConfiguracoes("Editar impressão")}/>
                <Grid container justify={"space-between"} alignItems={"center"} className={classes.headerContent}>
                    <Grid item xs={5}>
                        <h3 className={classes.titleHeader}>Configuração de Impressão</h3>
                    </Grid>
                    <Grid item>
                        <Profile />
                    </Grid>
                </Grid>
                <MultiToggleButtons
                    options={["Informações", "Auditoria"]}
                    tabSelected={tabSelected}
                    changeTabSelected={this.changeTabSelected}
                    classes={{ buttonsContainer: classes.buttonsTab }}
                />
                <div className={classes.contentTab}>
                    { this.renderTab() }
                    {!isProfissionalSaudeSelecionado &&
                        <div className={classes.notFoundContainer}>
                            <h3>É necessário selecionar um profissional de saúde.</h3>
                        </div>
                    }

                    {loadingConfig && isProfissionalSaudeSelecionado && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={32} />
                        </div>
                    )}
                </div>
                <div className={classes.acoes}>
                    <ButtonLoading 
                        loading={configuracaoImpressaoStore.saving}
                        onClick={this.handleSave}
                        icon={SaveIcon}
                    />
                    <Fab className={classes.buttonCancelar} onClick={this.goToList}>
                        <CloseIcon/>
                    </Fab>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ConfiguracaoReceita);
