import React from "react"

import {withStyles} from "@material-ui/core/styles/index";
import Dialog from "../Dialog/Dialog";
import {DialogActions, DialogContent, DialogContentText} from '@material-ui/core';
import { red } from "../../assets/jss/appHealth.style";
import { TextField } from "../TextField";
import ButtonClearPrimary from "../Button/ButtonClearPrimary";
import ButtonAlert from "../Button/ButtonAlert";
import PageTitle from "../PageTitle/PageTitle";

const styles = (theme) => ({
    inativarAlertMessage:{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: red
    },

    buttonModal:{
        width: 150
    },
})

const ModalInativarEntradaProntuario = (props) =>{
    const {show, onClose, changeMotivo, inativar, motivo, classes, loadingInativacao, screen } = props
    return(
        <>
        <PageTitle title={`${screen} Inativar`}/>
        <Dialog
        fullWidth
        maxWidth="sm"
        open={show}
        onClose={onClose}
        >
            <DialogContent>
                <h1 className={classes.inativarAlertMessage}>
                    Atenção, essa ação é irreversível!
                </h1>
                <DialogContentText>
                    Por favor, insira o motivo da inativação:
                </DialogContentText>
                <TextField
                rows={3}
                multiline
                onChange={(e) =>{
                    changeMotivo(e)
                }}
                error = {motivo?.length > 2000}
                required
                value={motivo}
                />
            </DialogContent>
            <DialogActions>
                <ButtonClearPrimary 
                disabled={loadingInativacao}
                onClick={onClose} className={classes.buttonModal}>
                    Cancelar
                </ButtonClearPrimary>
                <ButtonAlert 
                disabled={loadingInativacao}
                onClick={inativar} className={classes.buttonModal}>
                    Inativar
                </ButtonAlert>
            </DialogActions>
        </Dialog>
        </>
    )
}


export default withStyles(styles)(ModalInativarEntradaProntuario)