import React from 'react'

import AlertModal from './AlertModal'
import InfoIconFilled from '../Icon/InfoIconFilled'

const ConfirmarHorarioAgendamentoModal = ({ open, onClose, onConfirm }) => (
  <AlertModal
    open={open}
    onClose={onClose}
    icon={<InfoIconFilled color='white' size={24} />}
    title={
      'Já existe um agendamento nesse horário, deseja agendar mesmo assim?'
    }
    withButtons
    bgIconColor={'#219A97'}
    fullWidth
    buttonOptions={{
      onCancel: onClose,
      bgColorConfirm: '#219A97',
      confirmText: 'Sim',
      onConfirm: onConfirm,
    }}
  />
)

export default ConfirmarHorarioAgendamentoModal
