import React from 'react'
import { withStyles } from '@material-ui/core/es'

const Card = ({ classes, dados, columns }) => {
  const unidades = dados?.unidades || []
  const unidadesVencidas = dados?.unidadesVencidas || []
  const unidadesRede = [...unidades, ...unidadesVencidas]
  return (
    <table className={classes.card}>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index} className={classes.content}>
              {column.Header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {unidadesRede.map((dado, index) => (
          <tr key={index} className={classes.content}>
            {columns.map((column, index) => (
              <td key={index}>{column.getValue(dado)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const styles = {
  card: {
    background: '#F9F9F9',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    marginBottom: '20px',
    maxHeight: '250px',
    overflow: 'auto',
    padding: '0 16px',
    '& thead': {
      '& tr': {
        '& th': {
          '&:nth-child(1)': {
            width: '100%',
            minWidth: '120px',
            maxWidth: '120px',
            overflow: 'hidden',
          },
          '&:nth-child(2)': {
            width: '100%',
            minWidth: '240px',
            maxWidth: '240px',
            overflow: 'hidden',
          },
          '&:nth-child(3)': {
            width: '100%',
            minWidth: '120px',
            maxWidth: '120px',
            overflow: 'hidden',
          },
          '&:nth-child(4)': {
            width: '100%',
            minWidth: '120px',
            maxWidth: '120px',
            overflow: 'hidden',
          },
        },
      },
    },
  },
  content: {
    textAlign: 'start',
    minHeight: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    padding: '8px 0px',

    '& td': {
      padding: '8px 0px',
    },

    '&:nth-child(1)': {
      width: '100%',
      padding: '8px 0px',
      minWidth: '120px',
      maxWidth: '120px',
      overflow: 'hidden',
    },
    '&:nth-child(2)': {
      width: '100%',
      padding: '8px 0px',
      minWidth: '240px',
      maxWidth: '240px',
      overflow: 'hidden',
    },
    '&:nth-child(3)': {
      width: '100%',
      padding: '8px 0px',
      minWidth: '120px',
      maxWidth: '120px',
      overflow: 'hidden',
    },
    '&:nth-child(4)': {
      width: '100%',
      padding: '8px 0px',
      minWidth: '120px',
      maxWidth: '120px',
      overflow: 'hidden',
    },
  },
}

export default withStyles(styles)(Card)
