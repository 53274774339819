import React, { useEffect, useReducer, useState } from 'react'
import { withStyles } from '@material-ui/core'
import moment from 'moment'

import { Button } from '../../../../components/ui/Buttons'
import StopIcon from '../../../../components/Icon/StopIcon'
import ClipboardTaskListIcon from '../../../../components/Icon/ClipboardTaskListIcon'

import { buildUrlFotoPerfil } from '../../../../config/config'
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../../services/storage'

import ProfileDefault from '../../../../assets/img/svg/img-profile-default.svg'
import string from '../../../../utils/string'
import dates from '../../../../utils/dates'
import CamIcon from '../../../../components/Icon/Cam'
import AlertModal from '../../../../components/Modal/AlertModal'
import InfoIconFilled from '../../../../components/Icon/InfoIconFilled'

const CardAtendimento = ({
  classes,
  agendamento,
  onStopAgendamento,
  handleSelectAgendamento,
  loadingFinaliza,
}) => {
  const [openAlertModal, toggleAlertModal] = useReducer(prevState => !prevState, false)
  const [url, setUrl] = useState(null)
  const [isFinalizado, setIsFinalizado] = useState(null)

  const buildUrlFotoSujeitoAtencao = async agendamento => {
    const { fotoPerfil } = agendamento?.sujeitoAtencao || {}
    if (!fotoPerfil) {
      setUrl(ProfileDefault)
      return ProfileDefault
    }
    const token = await localStorageService.get(ACCESS_TOKEN_KEY)
    const url = buildUrlFotoPerfil(fotoPerfil, token)
    setUrl(url)
    return url
  }

  const handleStopAgendamento = async (e, agendamento) => {
    try {
      await onStopAgendamento(e, agendamento)
      setIsFinalizado(true)
    } catch (error) {
      console.error(error)
      setIsFinalizado(false)
    }
  }

  useEffect(() => {
    buildUrlFotoSujeitoAtencao(agendamento)
  }, [agendamento])

  return (
    <>
      <div className={classes.cardAtendimento}>
        <div className={classes.wrapperInfosCard}>
          <div className={classes.wrapperImage}>
            <img src={url} alt='avatar' />
            <div className={classes.wrapperInfos}>
              <span className={classes.wrapperNameAgendamento}>
                {string.truncate(agendamento.nome, 15)}
                {agendamento?.remoto && (
                  <div className={classes.wrapperCamIcon}>
                    <CamIcon color='white' size={12} />
                  </div>
                )}
              </span>
              <p>{dates.calculaIdade(agendamento?.sujeitoAtencao?.dataNascimento)}</p>
            </div>
          </div>
          <div className={classes.wrapperInfos}>
            <span>{agendamento?.horaInicio}</span>
            <span>{moment(agendamento?.data).format('DD/MM/YYYY')}</span>
          </div>
        </div>
        <div className={classes.wrapperActions}>
          {!isFinalizado ? (
            <>
              <Button
                kind='transparent'
                className={classes.buttonFinalizar}
                onClick={toggleAlertModal}
              >
                <StopIcon color='#505050' />
                Finalizar
              </Button>
              <Button
                kind='transparent'
                className={classes.buttonProntuario}
                onClick={handleSelectAgendamento}
              >
                <ClipboardTaskListIcon color='#505050' size={24} />
                Prontuário
              </Button>
            </>
          ) : (
            <div className={classes.atendimentoFinalizado}>
              <span>Atendimento finalizado</span>
            </div>
          )}
        </div>
      </div>
      <AlertModal
        open={openAlertModal}
        onClose={toggleAlertModal}
        icon={<InfoIconFilled color='white' size={24} />}
        title={`Deseja Finalizar o atendimento com o ${string.truncate(agendamento.nome, 15)}?`}
        withButtons
        buttonOptions={{
          onCancel: toggleAlertModal,
          onConfirm: e => {
            handleStopAgendamento(e, agendamento)
            toggleAlertModal()
          },
          loadingConfirm: loadingFinaliza,
        }}
      />
    </>
  )
}

const styles = {
  wrapperInfosCard: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '8px 0px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.10)',

    '& img': {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      border: '1px solid rgba(220, 220, 220, 0.20)',
      boxShadow:
        '0.73828px 0.73828px 36.91406px 0px rgba(154, 81, 48, 0.15), 1.47656px 1.47656px 7.38281px 0px rgba(154, 81, 48, 0.10), 2.95313px 2.95313px 11.07422px 0px rgba(154, 81, 48, 0.05)',
    },
  },
  wrapperImage: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flex: '1',
  },
  cardAtendimento: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '16px',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    marginBottom: '8px',
  },

  wrapperInfos: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    '& span': {
      fontSize: '14px',
      fontWeight: '600',
      color: '#0D1C2E',
    },

    '& p': {
      fontSize: '12px',
      fontWeight: '500',
      color: '#7C7C7C',
      margin: '0px',
    },
  },

  wrapperCamIcon: {
    display: 'flex',
    alignItems: 'center',
    background: '#9871FC',
    borderRadius: '100px',
    padding: '2px',
  },

  wrapperActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',

    '& button': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flex: '1',
      border: '1px solid transparent !important',
    },
  },
  buttonFinalizar: {
    '&:hover': {
      border: '1px solid #EC5341 !important',
      backgroundColor: '#FCEDEF !important',
    },
  },
  buttonProntuario: {
    '&:hover': {
      border: '1px solid #219A97 !important',
      backgroundColor: '#F1FCFA !important',
    },
  },
  atendimentoFinalizado: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',

    '& span': {
      fontSize: '14px',
      fontWeight: '600',
      color: '#BDBDBD',
    },
  },
  wrapperNameAgendamento: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}

export default withStyles(styles)(CardAtendimento)
