import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const GuiaConsultaIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      {...normalizeProps(props)}
    >
      <path
        d="M7.19995 9.1019C7.19995 8.89151 7.23605 8.68954 7.30239 8.50187H3.15133C2.40612 8.50187 1.802 9.10598 1.802 9.85119V10.4032C1.802 10.7464 1.90905 11.0811 2.10822 11.3607C3.03352 12.6593 4.5473 13.3026 6.59988 13.3026C6.83841 13.3026 7.06968 13.2939 7.2936 13.2765C7.23287 13.096 7.19995 12.9028 7.19995 12.7019V9.1019ZM6.59988 1.30469C8.25673 1.30469 9.59988 2.64783 9.59988 4.30469C9.59988 5.96154 8.25673 7.30469 6.59988 7.30469C4.94302 7.30469 3.59988 5.96154 3.59988 4.30469C3.59988 2.64783 4.94302 1.30469 6.59988 1.30469ZM7.79995 9.1019C7.79995 8.43916 8.33721 7.9019 8.99995 7.9019H13.2C13.8627 7.9019 14.4 8.43916 14.4 9.1019V12.7019C14.4 13.3646 13.8627 13.9019 13.2 13.9019H8.99995C8.33721 13.9019 7.79995 13.3646 7.79995 12.7019V9.1019ZM9.29995 9.7019C9.13427 9.7019 8.99995 9.83622 8.99995 10.0019C8.99995 10.1676 9.13427 10.3019 9.29995 10.3019H12.9C13.0656 10.3019 13.2 10.1676 13.2 10.0019C13.2 9.83622 13.0656 9.7019 12.9 9.7019H9.29995ZM9.29995 11.5019C9.13427 11.5019 8.99995 11.6362 8.99995 11.8019C8.99995 11.9676 9.13427 12.1019 9.29995 12.1019H12.9C13.0656 12.1019 13.2 11.9676 13.2 11.8019C13.2 11.6362 13.0656 11.5019 12.9 11.5019H9.29995Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GuiaConsultaIcon;
