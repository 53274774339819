import React from "react";

import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { CircularProgress } from "@material-ui/core";
import { buildUrlFotoPerfil } from "../../config/config";
import GracefulImage from "../Image/GracefulImage";
import Scroll from "../InfiniteScroll/Scroll";
import string from "../../utils/string";
import CamposModificados from "./CamposModificados";

import styles from "./styles";

const profileDefault = require("../../assets/img/svg/profile-default.svg");


const Auditoria = observer(({
  classes,
  auditoriaStore,
  withEntityName,
  entity,
  entityNameField,
  modificationMessageWithValue
}) => {

  const {
    auditorias,
    auditoria,
    lastPage,
    auditoriaTela,
    withWhomModified,
  } = auditoriaStore.auditoriaProps;

  if (auditoriaStore.isLoading && auditorias.length === 0) {
    return <div className={classes.notFoundContainer}>
      <CircularProgress />
    </div>
  }
  const getModifiedValues = (indiceAuditoria) =>{
    const auditoriaAtual =  auditorias[indiceAuditoria]
    const auditoriaAnterior =  auditorias[indiceAuditoria + 1 ] 
    const modificacoesInicialFinal = {}
   
   if( !auditoriaAnterior) return {}

   const handleModification = (key) => {

    const modified = auditoriaAtual[key]
    const keyFormated = key.replace("Modified", "")

    if(!modified) return

   
    
    const modifiedArray = [
      "documentoModified",
      "dataNascimentoModified",
      "horaFimModified",
      "horaInicioModified",
      "nomeModified",
      "observacaoModified",
      "pacienteIdModified",
      "profissionalSaudeIdModified",
      "telefoneModified",
      "motivoCancelamentoModified",
      "numeroGuiaTissExternaModified",
      "agendamentoRecorrenciaIdModified",
    ]
    switch (key) {
      case  "convenioIdModified":
        modificacoesInicialFinal[key] = [
          auditoriaAtual.convenio?.descricao,
          auditoriaAnterior.convenio?.descricao
        ]
        break;
        case "convenioNumeroCarteiraModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual.numeroCarteira,
            auditoriaAnterior.numeroCarteira
          ]
        break;
        case "dataModified":
          modificacoesInicialFinal.dataModified = [
            moment(auditoriaAtual[keyFormated]).format("DD/MM/YYYY"),
            moment(auditoriaAnterior[keyFormated]).format("DD/MM/YYYY")
              ]
        break;
        case "salaIdModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual.sala?.nome,
            auditoriaAnterior.sala?.nome
          ]
        break;
        case "solicitanteIdModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual.solicitante?.nome,
            auditoriaAnterior.solicitante?.nome
          ]
        break;
        case "dataChegadaSujeitoAtencaoModified":
          modificacoesInicialFinal[key] = [
            !auditoriaAtual[keyFormated] ? null : moment(auditoriaAtual[keyFormated]).format("DD/MM/YYYY"),
            !auditoriaAnterior[keyFormated] ? null : moment(auditoriaAnterior[keyFormated]).format("DD/MM/YYYY")
          ];
        break;
        case "remotoModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual[keyFormated]? "Remoto":"Presencial",
            auditoriaAnterior[keyFormated]? "Remoto":"Presencial"
          ];
        break;
        case "convenioValidadeCarteiraModified":
          modificacoesInicialFinal[key] = [
            !auditoriaAtual.validadeCarteira ? null : moment(auditoriaAtual.validadeCarteira).format("DD/MM/YYYY"),
            !auditoriaAnterior.validadeCarteira ? null :  moment(auditoriaAnterior.validadeCarteira).format("DD/MM/YYYY")
          ];
        break;
        case "situacaoModified":
          let situacaoInicialToLowerCase = auditoriaAtual.situacao.toLowerCase()
          let situacaoFinalToLowerCase = auditoriaAnterior.situacao.toLowerCase()
          let situacaoInicialCapitalize = situacaoInicialToLowerCase[0].toUpperCase() + situacaoInicialToLowerCase.slice(1)
          let situacaoFinalCapitalize = situacaoFinalToLowerCase[0].toUpperCase() + situacaoFinalToLowerCase.slice(1)
    
          modificacoesInicialFinal[key] = [
          situacaoInicialCapitalize,
          situacaoFinalCapitalize
          ]
        break;
        case "profissionalSaudeIdModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual.profissionalSaude?.nome,
            auditoriaAnterior.profissionalSaude?.nome
          ]
        break
        case "tipoModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual.tipoEntidade?.descricao,
            auditoriaAnterior.tipoEntidade?.descricao
          ]
        break;
        case "urgenteModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual.urgente? "urgente": "normal",
            auditoriaAnterior.urgente? "urgente": "normal"
          ]
        break;
        case "listaDeEsperaModified":
          modificacoesInicialFinal[key] = [
            auditoriaAtual.listaDeEspera? "adicionado a lista de espera": "retirado da lista de espera",
            auditoriaAnterior.listaDeEspera? "adicionado a lista de espera": "retirado da lista de espera"
          ]
        break;
        case "dataNascimentoModified":
          modificacoesInicialFinal[key] = [
            !auditoriaAtual.dataNascimento ? null : moment(auditoriaAtual.dataNascimento).format("DD/MM/YYYY"),
            !auditoriaAnterior.dataNascimento ? null :  moment(auditoriaAnterior.dataNascimento).format("DD/MM/YYYY")
          ]
          break
      default:
        if(modifiedArray.includes(key)){
          modificacoesInicialFinal[key] = [
          auditoriaAtual[keyFormated],
          auditoriaAnterior[keyFormated]
        ]}
        break;
    }
  }

const auditoriaKeys = Object.keys(auditoriaAtual).filter((key)=> key.includes("Modified"))
    auditoriaKeys.forEach((key)=>{
     handleModification(key)     
    })
    
    return modificacoesInicialFinal   
}

  const getModificacaoName = (modificacao) => {
    let modificacaoNome = '';

    if (modificacao?.nome && withWhomModified && !withEntityName) {
      modificacaoNome = modificacao.nome;
    }

    if (withEntityName && entity && entityNameField) {
      modificacaoNome = modificacao[entity][entityNameField] && string.capitalizeEachWord(modificacao[entity][entityNameField]);
    }

    return <strong>{modificacaoNome}</strong>;
  }

  return (
    <>
      {auditorias.length > 0 ? (
        <Scroll
          hasMore={!auditoriaStore.isLoading && !lastPage}
          pageStart={0}
          initialLoad={false}
          className={classes.auditoriaBody}
          loadMore={() => auditoriaStore.loadAuditItems()}
        >
          {auditorias.map((modificacao, index) => {
            const { revtype, revisao } = modificacao;
            const dataHoraModificacao =
              revisao?.dataHoraModificacao &&
              moment(revisao.dataHoraModificacao).format(
                "DD [de] MMMM [às] HH[h]mm"
              );

            const usuarioPaciente = revisao?.usuario || revisao?.paciente;
            const tipoModificacao = revtype === "CREATE" ? "Criou" : "Alterou";

            return (
              <div className={classes.auditoriaItem} key={index}>
                <GracefulImage
                  src={
                    usuarioPaciente?.fotoPerfil
                      ? buildUrlFotoPerfil(usuarioPaciente.fotoPerfil)
                      : profileDefault
                  }
                  className={classes.profissionalProfileImage}
                />
                <div className={classes.auditoriaText}>
                  <div className={classes.auditoriaItemInformacao}>
                    <p>
                      <strong>{usuarioPaciente?.nome}</strong>
                      {`${tipoModificacao} ${auditoria} `}
                      {getModificacaoName(modificacao)}
                    </p>
                    {revtype === "UPDATE" && (
                      <ul>
                        <CamposModificados
                          revtype={revtype}
                          modificacao={modificacao}
                          auditoriaTela={auditoriaTela}
                          modificationMessageWithValue={modificationMessageWithValue}
                          auditChangesValues={getModifiedValues(index)}
                        />
                      </ul>
                    )}
                  </div>
                  <p className={classes.auditoriaItemData}>
                    {dataHoraModificacao}
                  </p>
                </div>
              </div>
            );
          })}
        </Scroll>
      ) : (
        <div className={classes.auditoriaNotFoundItens}>
          Não há modificações
        </div>
      )}
    </>
  );
});

const AuditoriaGeralWithStyle = withStyles(styles)(Auditoria);
const stores = ['auditoriaStore'];
export default inject(stores)(AuditoriaGeralWithStyle);
