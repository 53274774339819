import React, { useState, useEffect } from "react";
import { inject } from "mobx-react";
import moment from "moment";
import {
  Grid,
  CircularProgress,
} from "@material-ui/core";
import {
  Print as PrintIcon
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles/index";
import styles from "../RelatorioStyle";
import SelectCustomIcon from "../../Configuracoes/ModelosDocumento/Anamnese/SelectCustomIcon";
import { StatusAtivoIcon, StatusInativoIcon } from "../../../assets/img/svg";
import { relatorioSujeitoAtencaoQuemIndicouData } from "../../../services/RelatorioService";
import Table from "../../../components/Table/Table";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import HeaderRelatorio, { periodos } from "../HeaderRelatorio";
import Notification from "../../../components/Notification";
import ButtonYellow from "../../../components/Button/ButtonYellow";
import RelatorioIndicacoesPorDataDocument from "../../../template/pdf/relatorio/IndicacoesPorData";
import { InputDateForm } from "../../../components/Modal/Input";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/ui/Buttons";

const columns = [
  {
    Header: '',
    getValue: () => {
        return null;
    },
  },
  {
    Header: 'Indicado por',
    field: "quemIndicou",
    getValue: (indicado) => {
        return indicado.quemIndicou;
    },
  },
  {
  Header: "Quantidade",
  field: "quantidade",
  getValue: (indicado) => {
    return indicado.quantidade;
  },
},
];

const statusList = [
  { label: "Ativo", value: true, icon: StatusAtivoIcon },
  { label: "Inativo", value: false, icon: StatusInativoIcon },
];

const diaInicialMesAtual = moment()
  .startOf("month")
  .subtract(0, "month")
  .format("YYYY-MM-DD");

const diaFinalMesAtual = moment()
  .endOf("month")
  .subtract(0, "month")
  .format("YYYY-MM-DD");

const RelatorioIndicacoesAgendamento = (props) => {
    const { classes,relatorioStore } = props;

    const [indicacoesLoading, setIndicacoesLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [indicacoes, setIndicacoes] = useState([]);
    const [lastScroll, setLastScroll] = useState(false);
    const [searchNome, setSearchNome] = useState('');
    const [status, setStatus] = useState({
      label: "Ativo",
      value: true,
      icon: StatusAtivoIcon,
    });
    const [ordenarTabela, setOrdenarTabela] = useState({
      sortField: "quantidade",
      sortDir: "DESC",
    });
    const [notification, setNotification] = useState({
      isOpen: false,
      message: "",
    });
    const [atualizaListaSearch, setAtualizaListaSearch] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [periodoSelecionado, setPeriodoSelecionado] = useState(periodos[2]);
    const [dataInicial, setDataInicial] = useState(diaInicialMesAtual);
    const [dataFinal, setDataFinal] = useState(diaFinalMesAtual);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
      loadIndicacoes();
    }, [
      pageNumber,
      ordenarTabela,
      status.value,
      atualizaListaSearch,
    ]);

    const loadIndicacoes = () => {
      if (indicacoesLoading){
        return
      }
      setIndicacoesLoading(true);
      relatorioSujeitoAtencaoQuemIndicouData({
          ativo: status.value,
          search: searchNome,
          pageable: {
            pageSize: 30,
            pageNumber,
            sortDir: ordenarTabela.sortDir,
            sortField: ordenarTabela.sortField
          },
          dataInicio: dataInicial,
          dataFinal: dataFinal
        }).then((response) => {
          const { content, last, totalElements } = response;
          const listaIndicacoes = [...indicacoes, ...content]
          setLastScroll(last)
          setIndicacoes(listaIndicacoes);
          setTotalElements(totalElements);
        }).catch(error => {
          const notification = {
            isOpen: true,
            message: "Erro ao carregar a lista de indicações"
          };
          showAlertMessage(notification);
        }).finally(() => {
          setIndicacoesLoading(false);
        })
    }

    const onClickPesquisar = () => {
      clearPesquisar();
      setAtualizaListaSearch(!atualizaListaSearch);
    }

    const onKeypressSearch = (e) => {
        if (e.key === 'Enter' && searchNome.length >= 3) {
          clearPesquisar();
          setAtualizaListaSearch(!atualizaListaSearch);
        }
    }

    const handleClickClearPesquisar = e => {
      setSearchNome("");
      setAtualizaListaSearch(!atualizaListaSearch);
      clearPesquisar();
    }

    const clearPesquisar = () => {
        setPageNumber(0);
        setIndicacoes([]);
        setLastScroll(false);
    }

    const handleSearchChange = e => {
        setSearchNome(e.target.value);
    };

    const loadMore = () => {
        if (indicacoesLoading) {
          return;
        }

        setPageNumber( indicacoes.length > 0 ? pageNumber + 1 : 0 )
    };

    const onStatusChange = (e) => {
      clearPesquisar();
      setStatus(e);
    };

    const handleClickOrdenar = async (value) => {
      clearPesquisar();

      const sortDir =
        ordenarTabela.sortField !== value
          ? "ASC"
          : ordenarTabela.sortDir === "ASC"
          ? "DESC"
          : "ASC";

      setOrdenarTabela({
        sortDir: sortDir,
        sortField: value,
      });
    };

  const showAlertMessage = (notification) => {
    setNotification(notification)

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };

  const printRelatorio = () => {
    const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)

    if(!isValid) {
      const notification = {
        isOpen: true,
        message: "O limite de trinta dias foi excedido!"
      };
      showAlertMessage(notification);
      return;
    }

    setPrinting(true);
  }

  const handleDateChange = (e, field) => {
    setPeriodoSelecionado('');
    const dataFormatada = e && moment(e).format("YYYY-MM-DD");
    clearPesquisar();
    field === "inicio" ? setDataInicial(dataFormatada) : setDataFinal(dataFormatada);
  };

  const handlePeriodoSelecionado = (value) => {
    const { dataInicial, dataFinal } = value;
    clearPesquisar();
    setPeriodoSelecionado(value);
    setDataInicial(dataInicial);
    setDataFinal(dataFinal);
  };

  const handleClickSearch = () => {

    if (dataInicial && dataFinal) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)
      if (isValid) {
        loadIndicacoes();
      } else {
        const notification = {
          isOpen: true,
          message: "O limite de trinta dias foi excedido!"
        };
        showAlertMessage(notification);
      }
    }
  }

  const disabledButtonFilter = !dataInicial || !dataFinal || indicacoesLoading
  return (
    <div className={classes.content}>
      <PageTitle title="Relatórios - Indicações por data"/>
      <HeaderRelatorio
        handlePeriodo={handlePeriodoSelecionado}
        search={onClickPesquisar}
        clearSearch={handleClickClearPesquisar}
        value={searchNome}
        title='Indicações por data'
        totalTitle='cadastros'
        onChange={handleSearchChange}
        onKeyPress={onKeypressSearch}
        periodoSelecionado={periodoSelecionado}
        totalAgendamentos={totalElements}
        hiddenFilter
      />
      <div className={classes.contentFiltros}>
        <div className={classes.filtros}>
          <Grid item xs={3} className={classes.spacing}>
            <span className={classes.tituloFiltros}> Data início: </span>
            <InputDateForm
              iconposition="end"
              openTo="day"
              views={["year", "month"]}
              value={dataInicial}
              classes={{
                input: classes.inputData,
              }}
              onChange={(e) => handleDateChange(e, "inicio")}
            />
          </Grid>
          <Grid item xs={3} className={classes.spacing}>
            <span className={classes.tituloFiltros}> Data fim: </span>
            <InputDateForm
              iconposition="end"
              openTo="day"
              views={["year", "month"]}
              value={dataFinal}
              classes={{
                input: classes.inputData,
              }}
              disabled={!dataInicial}
              minDate={dataInicial}
              onChange={(e) => handleDateChange(e, "fim")}
            />
          </Grid>
          <Grid item xs={3} className={classes.spacing}>
            <span className={classes.tituloFiltros}> Situação: </span>
            <SelectCustomIcon
              isSelect
              onChange={onStatusChange}
              value={status}
              options={statusList}
              className={classes.selectSituacao}
            />
          </Grid>
        </div>
          <Button
            shape='pill'
            bgColor='#707C97'
            onClick={handleClickSearch}
            disabled={disabledButtonFilter}
          >
            Filtrar
          </Button>
      </div>

      <div className={classes.tableDadosAniversariantes}>
        <Scroll
          loadMore={loadMore}
          hasMore={!lastScroll}
          pageStart={0}
          initialLoad={true}
          className={classes.scrollContainer}
        >
          { indicacoes.length === 0 && !indicacoesLoading &&
            <div className={classes.notFoundContainer}>
              <h3>Nenhum item encontrado</h3>
            </div>
          }
          {indicacoes.length > 0 &&
            <Table
              className={classes.table}
              dados={indicacoes}
              columns={columns}
              clickable={false}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
            />
          }
          {
            indicacoesLoading && <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <CircularProgress size={30} />
          </Grid>
          }
        </Scroll>
        <div className={classes.buttonsDownloadPrint}>
          <ButtonYellow onClick={printRelatorio}>
            <PrintIcon />
          </ButtonYellow>
        </div>
      </div>
      {printing && 
        <ImpressaoHtml
          isPrintMustache={printing}
          handlePrintMustache={() => setPrinting(false)}
          htmlStringComponent={ <RelatorioIndicacoesPorDataDocument
            situacao={status.label}
            indicacoes={indicacoes}
            pesquisadoPor={searchNome}
            dataInicio={dataInicial}
            dataFim={dataFinal}
          />}
        />}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={'error'}
        message={notification.message}
      />
    </div>
  );
};

const RelatorioIndicacoesAgendamentoWithStyles = withStyles(styles)(RelatorioIndicacoesAgendamento)
export default inject("configuracaoImpressaoStore","relatorioStore")(RelatorioIndicacoesAgendamentoWithStyles)
