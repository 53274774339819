import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const AtivaIcon = (props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M4.74186 0.0498047C2.14997 0.0498047 0.0488281 2.15095 0.0488281 4.74283L0.0488281 17.2576C0.0488281 19.8495 2.14997 21.9506 4.74186 21.9506H17.2566C19.8485 21.9506 21.9496 19.8495 21.9496 17.2576V4.74283C21.9496 2.15094 19.8485 0.0498047 17.2566 0.0498047L4.74186 0.0498047ZM16.2453 8.4246L9.98796 14.682C9.68251 14.9874 9.18726 14.9874 8.88181 14.682L5.75312 11.5533C5.44766 11.2478 5.44766 10.7526 5.75312 10.4471C6.05858 10.1417 6.55382 10.1417 6.85928 10.4471L9.43488 13.0227L15.1392 7.31844C15.4446 7.01298 15.9399 7.01298 16.2453 7.31844C16.5508 7.6239 16.5508 8.11914 16.2453 8.4246Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AtivaIcon;
