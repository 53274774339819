import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '../../../components/Button/Button';
import logoVizi from '../../../assets/img/svg/logoVizi.svg';
import macbookVizi from '../../../assets/img/macbookVizi.png';
import { LPComercial } from '../constants/perfilViziConstants';

const styles = ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    padding: '60px'
  },
  cardContainer: {
    width: '386px',
    zIndex: 1
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    padding: '40px',
    background:'white',
    boxShadow: '9px 8px 25px rgba(112, 124, 151, 0.20)',
    borderRadius: '16px'
  },
  logo: {
    height: '50px',
    width: '140px',
  },
  title: {
    margin: 0,
    padding: 0,
    fontSize: '24px !important',
    fontWeight: '700 !important',
    color: '#000',
    '&>span': {
      color: '#27B0AD',
      fontSize: '24px !important',
      fontWeight: '700 !important',
    }
  },
  text: {
    fontSize: '14px !important',
    color: '#5F6368'
  },
  backgroundImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 0,
    "@media(max-width: 1024px)": {
      display: 'none'
    },
    "@media(max-height: 700px)": {
      display: 'none'
    }
  }
});

const buttonStyle = {
  textTransform: 'none',
  height: '36px',
  borderRadius: '100px',
  marginTop: '24px',
};

const PropagandaVizi = ({ classes }) => {
  const handleOnClickConhecer = () => {
    window.open(LPComercial, '_blank')
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <img className={classes.logo} src={logoVizi} alt="Logo do Vizi Saúde" />
          <p className={classes.title}>
            Faça parte do <span>Vizi Saúde</span>, o Marketplace da saúde!
          </p>
          <span className={classes.text}>
            Dê visibilidade à sua clínica ou consultório, ofereça agendamento online, tenha um sistema de gestão completo e multiplique a eficiência do seu negócio.
          </span>
          <Button
            style={buttonStyle}
            onClick={handleOnClickConhecer}
          >Conhecer</Button>
        </div>
      </div>
      <img className={classes.backgroundImage} src={macbookVizi} alt="Vizi Saúde no macbook e no Iphone" />
    </div>
  )
};

export default withStyles(styles)(PropagandaVizi);