import classNames from "classnames";
import React, { Suspense } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, withStyles } from "@material-ui/core";
import InativarIcon from "../../../../../components/Icon/InativarIcon";
import { ICON_PROPS_BY_TYPE, ICON_TYPES } from "../Constants";
import UrgenteIcon from "../../../../../components/Icon/UrgenteIcon";
import styled from "styled-components";
import ObservacaoIcon from "../../../../../components/Icon/ObservacaoIcon";
import ProcedimentoIcon from "../../../../../components/Icon/ProcedimentoIcon";

const ContentIcon = styled.div`
  position: absolute;
  border-radius: ${(props) => (props.circle ? "50%" : "8px")};
  border: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => ICON_PROPS_BY_TYPE(props.isPosicaoSuperior)[props.type]}
`;

const Dente = observer(({ classes, dente, invert, isPosicaoSuperior }) => {
  const {
    codigo,
    ImagemFrontal,
    ImagemVistaSuperior,
    selecionado,
    status,
    observacao,
    procedimentoOdontologico,
  } = dente;
  const isInativo = status === "INATIVO";
  const urgente = procedimentoOdontologico.some(
    (item) => item.agendamento?.urgente
  );

  const verificaProcedimentos = () => {
    const isProcedimentoNaoRealizado =
      procedimentoOdontologico?.length > 0 &&
      procedimentoOdontologico.some((element) => {
        return !element.agendamento?.realizado;
      });

    return isProcedimentoNaoRealizado;
  };

  const isProcedimentoNaoRealizado = verificaProcedimentos();

  return (
    <>
      <div
        className={classNames(
          classes.content,
          selecionado && classes.denteSelecionado
        )}
      >
        <Suspense
          fallback={
            <div className={classes.wrapperLoading}>
              <CircularProgress size={24} />
            </div>
          }
        >
          <div
            className={classNames(
              classes.imagemDente,
              invert && classes.imagemDenteVistaSuperior
            )}
          >
            {invert ? ImagemVistaSuperior : ImagemFrontal}
          </div>
        </Suspense>
        <div className={classes.codigoDente}> {codigo} </div>
        <Suspense
          fallback={
            <div className={classes.wrapperLoading}>
              <CircularProgress size={24} />
            </div>
          }
        >
          <div
            className={classNames(
              classes.imagemDente,
              invert
                ? classes.imagemDenteFrontalInferior
                : classes.imagemDenteVistaSuperior
            )}
          >
            {invert ? ImagemFrontal : ImagemVistaSuperior}
          </div>
        </Suspense>
        {isInativo ? (
          <ContentIcon
            type={ICON_TYPES.INATIVO}
            isPosicaoSuperior={isPosicaoSuperior}
          >
            <InativarIcon size="18" color="#FFFFFF" />
          </ContentIcon>
        ) : (
          <>
            {urgente && isProcedimentoNaoRealizado && (
              <ContentIcon
                type={ICON_TYPES.URGENTE}
                isPosicaoSuperior={isPosicaoSuperior}
                circle
              >
                <UrgenteIcon color="white" size="12" />
              </ContentIcon>
            )}
            {observacao && (
              <ContentIcon
                type={ICON_TYPES.OBSERVACAO}
                isPosicaoSuperior={isPosicaoSuperior}
              >
                <ObservacaoIcon color="white" size="12" />
              </ContentIcon>
            )}
            {isProcedimentoNaoRealizado && (
              <ContentIcon
                type={ICON_TYPES.PROCEDIMENTO}
                isPosicaoSuperior={isPosicaoSuperior}
              >
                <ProcedimentoIcon color="white" size="12" />
              </ContentIcon>
            )}
          </>
        )}
      </div>
    </>
  );
});

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    width: "40px",
    height: "140px",
    position: "relative",
  },
  denteSelecionado: {
    background: "#868686",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "38px",
    height: "138px",
  },
  imagemDente: {
    height: "80px",
    display: "flex",
    alignItems: "end",
    width: "40px",
    justifyContent: "center",
  },
  codigoDente: {
    color: "#F4FBFF",
    display: "flex",
    alignSelf: "center",
    padding: "8px 0",
  },
  imagemDenteVistaSuperior: {
    height: "25px",
    position: "relative",
  },
  imagemDenteFrontalInferior: {
    height: "72px",
    alignItems: "start",
    position: "relative",
  },
};

export default withStyles(styles)(Dente);
