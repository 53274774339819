import React, { useReducer, useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'

import { Hero, ModalCreateSecretaria } from './components'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Notification from '../../../components/Notification'
import HeaderConfiguracoesButtonNovo from '../Components/HeaderConfiguracoesButtonNovo'

import { pageConfiguracoes } from '../../../utils/getPageTitle'
import { checkUserRole } from '../../../utils/checkUserRole'

import AddIcon from '../../../components/Icon/AddIcon'
import { Button } from '../../../components/ui/Buttons'

function ConfiguracaoSecretaria({ classes }) {
  const [notification, setNotification] = useState({
    open: false,
    message: 'Erro',
    type: 'error',
  })
  const [showButton, setShowButton] = useState(false)
  const [shouldReload, setShouldReload] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [profissionalSaudeSelected, setProfissionalSaudeSelected] = useState(null);

  const profissionalHavePermission = async () => {
    const role = await checkUserRole('ROLE_PROFISSIONAL_SAUDE_CREATE')
    setShowButton(role)
    return role
  }

  const [openCreateSecretaria, toggleModalCreateSecretaria] = useReducer(state => !state, false)

  const openNotification = (message, variant = null) => {
    setNotification(prevState => ({
      ...prevState,
      open: true,
      type: variant,
      message: message,
    }))
  }

  const closeAlertMessage = () => {
    setNotification(prevState => ({ ...prevState, open: false }))
  }

  useEffect(() => {
    profissionalHavePermission()
  }, []);

  const handleCloseModalCreateSecretario = () => {
    if (profissionalSaudeSelected) {
      setProfissionalSaudeSelected(null)
    }
    toggleModalCreateSecretaria()
    setShouldReload(true)
  }

  const handleChangeSearch = (e) => {
    setValueSearch(e.target.value)
  };

  const onKeypressSearch = (e) => {
    if (e.key === 'Enter' && valueSearch.length >= 3) {
      setShouldReload(true)
    }
  };

  const handleClickClear = () => {
    setValueSearch("");
    setShouldReload(true);
  };

  const onClickButtonSearch = () => {
    setShouldReload(true);
  };

  useEffect(() => {
    if (profissionalSaudeSelected && !openCreateSecretaria) {
      toggleModalCreateSecretaria();
    }
  }, [profissionalSaudeSelected]);

  return (
    <div>
      <PageTitle title={pageConfiguracoes('Configuração de usuários')} />
      <HeaderConfiguracoesButtonNovo
        title='Pesquisar usuário'
        labelButton='Adicionar Usuário'
        handleClickNovo={toggleModalCreateSecretaria}
        hiddenButton={!showButton}
        search={{
          handleChange: handleChangeSearch,
          onKeypress: onKeypressSearch,
          handleClickClear: handleClickClear,
          onClickButtonSearch: onClickButtonSearch,
          value: valueSearch,
        }}
      />
      <div className={classes.content}>
        <Hero
          handleOpenCreateSecretaria={toggleModalCreateSecretaria}
          handleSelectProfissionalSaude={(profissionalSaudeId) => setProfissionalSaudeSelected(profissionalSaudeId)}
          openNotification={openNotification}
          shouldReload={shouldReload}
          setShowButton={setShowButton}
          showButton={showButton}
          profissionalHavePermission={profissionalHavePermission}
          setShouldReload={setShouldReload}
          search={valueSearch}
        />
        {openCreateSecretaria && (
          <ModalCreateSecretaria
            isOpen={openCreateSecretaria}
            onClose={handleCloseModalCreateSecretario}
            openNotification={openNotification}
            profissionalSaudeSelected={profissionalSaudeSelected}
          />
        )}
      </div>
      {showButton && <Button
        shadow
        shape='circle'
        bgColor='#26ACA9'
        onClick={toggleModalCreateSecretaria}
        className={classes.buttonCriar} 
      >
        <AddIcon/>
      </Button>}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.open}
        variant={notification.type}
        message={notification.message}
      />
    </div>
  )
}

const styles = () => ({
  header: {
    display: 'flex',
    justifyContent: 'end',
    '& button': {
      paddingRight: '22px',
    },
  },
  content: {
    padding: '1.5rem',
    height: '100%', 
  },
  buttonCriar: {
    position: 'absolute',
    bottom: 88,
    right: 37,
    zIndex: 100
  },
})

export default withStyles(styles)(ConfiguracaoSecretaria)
