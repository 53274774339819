import string from "./string";

export const verificaIconsAgendamento = (agendamento) => {
    const condicoes = [];
    
    agendamento.procedimentoPedido && agendamento?.procedimentoPedido[0]?.procedimentos?.some(item => !!item.procedimento?.nome) && 
        condicoes.push('procedimentoPedido');
    agendamento.observacao && condicoes.push('observacao');
    const { isAniversarioProximo, isAniversariante } = string.verificaAniversarioProximoADataAtual(agendamento.dataNascimento, 5);
    (isAniversarioProximo || isAniversariante) && condicoes.push('aniversario');

    return condicoes.length === 3;
}