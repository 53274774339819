export const contactsTabs = [
  {
    label: 'Ativos',
  },
  {
    label: 'Inativos',
  }
]

export const chatWhatsAppURL = ' https://wa.me/554491152683?text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20o%20Chat%20Interno.';
export const chatBlockedScreenTitle = 'Contrate agora';
export const chatBlockedScreenMessage = 'Converse com sua equipe, envie arquivos (documentos, imagens e áudio) em tempo real e mantenha histórico com a segurança de um ambiente adequado à LGPD. \n Se você tem interesse em ativar essa funcionalidade, entre em contato conosco agora mesmo.';