import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Grid,
  withStyles,
} from "@material-ui/core";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import HeaderRelatorio from "../../HeaderRelatorio";
import Table from "../../../../components/Table/Table";
import {
  relatorioProcedimentosRealizadosCsv,
  relatorioProcedimentosRealizados,
  findAllConvenio,
  findAllProcedimentos,
} from "../../../../services/RelatorioService";
import { columns, filtersDefault } from "./constants";
import { TextFieldSearch } from "../../../../components/TextField";
import { findAllProfissionalSaude } from "../../../../services/ProfissionalSaudeService";
import PrintIcon from "../../../../components/Icon/Print";
import { Button } from "../../../../components/ui/Buttons";
import ImpressaoHtml from "../../../../components/Impressao/ImpressaoHtml";
import Notification from "../../../../components/Notification";
import RelatorioProcedimentosRealizadosContent from "../../../../template/pdf/relatorio/ProcedimentosRealizados";
import ArrowDownloadIcon from "../../../../components/Icon/ArrowDownload";
import { base64Convert } from "../../../../utils/base64ToCsv";
import { InputDateForm } from "../../../../components/Modal/Input";
import { inject } from "mobx-react";
import { pageableDTODefault } from "./constants";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import styles from "./RelatorioProcedimentosRealizadosStyles";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";
import moment from "moment";

const RelatorioProcedimentosRealizados = ({ classes, relatorioStore }) => {
  const [procedimentosRealizados, setProcedimentosRealizados] = useState([]);
  const [filters, setFilters] = useState(filtersDefault);
  const [last, setLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageableDTO, setPageableDTO] = useState(pageableDTODefault);
  const [totalElements, setTotalElements] = useState(0);
  const [
    procedimentosRealizadosPrint,
    setProcedimentosRealizadosPrint,
  ] = useState([]);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });

  const {
    profissionalSaude,
    faturado,
    dataInicio,
    dataFim,
    convenio,
    procedimento,
  } = filters || {};
  const disabled = procedimentosRealizados.length === 0;

  useEffect(() => {
    loadProcedimentosRealizados({ isClearable: true });
  }, [pageableDTO.sortDir]);

  const loadProcedimentosRealizados = async (options) => {
    try {
      setIsLoading(true);

      const variables = {
        pageableDTO: {
          ...pageableDTO,
          pageNumber: options?.isClearable ? 0 : pageableDTO.pageNumber,
        },
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude?.id,
        }),
        ...(procedimento?.id && { procedimento }),
        ...(convenio?.id && { convenio }),
        faturado,
        dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(dataFim).format("YYYY-MM-DD"),
      };

      const response = await relatorioProcedimentosRealizados(variables);
      const { content, last, totalElements: elements } = response;
      const page = options?.isClearable ? 0 : pageableDTO.pageNumber;

      setProcedimentosRealizados(
        options?.isClearable
          ? [...content]
          : [...procedimentosRealizados, ...content]
      );
      setPageableDTO({
        ...pageableDTO,
        pageNumber: page + 1,
      });
      setLast(last);
      setTotalElements(elements);
    } catch (error) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "Erro ao carregar o relatório de procedimentos realizados",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilters = (value, field) => {
    setFilters({ ...filters, [field]: value });
  };

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { mostrarApenasProfissionaisAtivo } = relatorioStore.configuracaoUnidade || {};
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
          ativo: mostrarApenasProfissionaisAtivo,
        })
      ).data.data.findAllProfissionalSaude;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadConvenios = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = await findAllConvenio({
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          sortDir: "ASC",
          sortField: "descricao",
          search,
          ativo: true,
        },
      });
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadProcedimentos = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = await findAllProcedimentos({
        pageableDTO: {
          pageNumber: page,
          pageSize: 10,
          sortDir: "ASC",
          sortField: "nome",
        },
        nome: search,
        ativo: true,
      });
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const variables = {
        ...(profissionalSaude?.id && {
          profissionalSaudeId: profissionalSaude?.id,
        }),
        ...(procedimento?.id && { procedimento }),
        ...(convenio?.id && { convenio }),
        faturado,
        dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(dataFim).format("YYYY-MM-DD"),
      };

      const base64 = await relatorioProcedimentosRealizadosCsv(variables);
      if (base64 === "error") return;
      const blob = base64Convert(base64);
      const url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "RelatorioProcedimentosRealizados.csv");
      document.body.appendChild(link);
      link.click();
    } catch {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "Erro ao baixar o relatório",
      });
    }
  };

  const handlePrint = async () => {
    const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)
    if (!isValid) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "O limite de trinta dias foi excedido!",
      });
    }

    const variables = {
      pageableDTO: {
        ...pageableDTO,
        pageSize: totalElements,
        pageNumber: 0,
      },
      ...(profissionalSaude?.id && {
        profissionalSaudeId: profissionalSaude?.id,
      }),
      ...(procedimento?.id && { procedimento }),
      ...(convenio?.id && { convenio }),
      faturado,
      dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      dataFim: moment(dataFim).format("YYYY-MM-DD"),
    };

    const response = await relatorioProcedimentosRealizados(variables);

    setProcedimentosRealizadosPrint(response.content);
    setIsPrintMustache(true);
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };

  const handleClickOrdenar = async (value) => {
    const sortDir = pageableDTO.sortDir === "ASC" ? "DESC" : "ASC";

    setPageableDTO({
      ...pageableDTO,
      sortDir,
      sortField: value,
    });
  };

  const handleChangeCheckbox = (e) => {
    const { checked } = e.target;
    setFilters((filter) => ({ ...filter, faturado: checked }));
  };

  const handleClickSearch = () => {

    if (dataInicio && dataFim) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)
      if (isValid) {
        loadProcedimentosRealizados({ isClearable: true });
      } else {
        showAlertMessage({
          isOpen: true,
          variant: "error",
          message: "O limite de trinta dias foi excedido!",
        });
      }
    }
  }

  const disabledButtonFilter = !dataInicio || !dataFim || isLoading
  return (
    <div className={classes.content}>
      <PageTitle title="Relatório - Procedimentos realizados" />
      <HeaderRelatorio
        title="Procedimentos realizados"
        hiddenSearch
        hiddenFilter
        hiddenButtons
        hiddenTotal
      />
      <div className={classes.filtros}>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Data início: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataInicio || ""}
            onChange={(e) => handleFilters(e, "dataInicio")}
            classes={{
              input: classes.inputData,
            }}
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Data Fim: </span>
          <InputDateForm
            iconposition="end"
            openTo="day"
            views={["year", "month"]}
            value={filters.dataFim || ""}
            onChange={(e) => handleFilters(e, "dataFim")}
            classes={{
              input: classes.inputData,
            }}
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Procedimento: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            loadOptions={loadProcedimentos}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={procedimento}
            onChange={(e) => handleFilters(e, "procedimento")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Convênio: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            loadOptions={loadConvenios}
            getOptionLabel={(option) => option?.descricao}
            getOptionValue={(option) => option?.id}
            value={convenio}
            onChange={(e) => handleFilters(e, "convenio")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </div>
        <div className={classes.campoFiltro}>
          <span className={classes.tituloFiltros}> Profissional: </span>
          <TextFieldSearch
            placeholder="Selecione"
            classNotched={classes.notchedOutline}
            loadOptions={loadProfissionaisSaude}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option?.id}
            value={profissionalSaude}
            onChange={(e) => handleFilters(e, "profissionalSaude")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuPosition="fixed"
          />
        </div>
        <div className={classes.wrapperCheckbox}>
          <Checkbox color={"primary"} onChange={handleChangeCheckbox} />
          <span className={classes.tituloFiltros}> Faturado </span>
        </div>
        <div>
          <Button
            shape='pill'
            bgColor='#707C97'
            onClick={handleClickSearch}
            disabled={disabledButtonFilter}
          >
            Filtrar
          </Button>
        </div>
      </div>
      <div className={classes.tableContainer}>
        <Scroll
          loadMore={loadProcedimentosRealizados}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
        >
          <Table
            dados={procedimentosRealizados}
            columns={columns}
            comOrdenacao
            ordenarTabela={pageableDTO}
            handleClickOrdenar={handleClickOrdenar}
          />
          {procedimentosRealizados.length === 0 && !isLoading && (
            <MensagemListaVazia />
          )}
          {isLoading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: 20 }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
        </Scroll>
      </div>
      <div className={classes.buttonsDownloadPrint}>
        <Button
          id="exportCsv"
          shape="circle"
          bgColor="#F9BE73"
          onClick={handleDownloadCSV}
          disabled={disabled}
        >
          <ArrowDownloadIcon />
        </Button>
        <Button
          shape="circle"
          bgColor="#F9BE73"
          onClick={handlePrint}
          disabled={disabled}
        >
          <PrintIcon />
        </Button>
      </div>
      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={
            <RelatorioProcedimentosRealizadosContent
              dadosRelatorio={procedimentosRealizadosPrint || []}
              filters={filters}
            />
          }
        />
      )}

      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </div>
  );
};

const stores = ["unidadeStore","relatorioStore"];
const RelatorioProcedimentosRealizadosWithStyles = withStyles(styles)(
  RelatorioProcedimentosRealizados
);
export default inject(...stores)(RelatorioProcedimentosRealizadosWithStyles);
