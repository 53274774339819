import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Checkbox, withStyles } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { menuOpcoesPopperLocal } from "../../Constants";
import InfosMucosaOral from "../../Components/InfosMucosaOral";
import MoreVerticalIcon from "../../../../../../components/Icon/MoreVerticalIcon";
import PopperCustomMenu from "../../../../../../components/Popper/PopperCustomMenu";

const MucosaOral = observer(({ classes, odontogramaStore }) => {
  const {
    odontograma,
    locaisSelecionados,
    isClickRightLocalSelecionado,
  } = odontogramaStore;
  const { mucosaOral } = odontograma;

  const popperRef = useRef(null);

  const [listaMucosaOral, setListaMucosaOral] = useState([]);
  const [mucosaOralChecked, setMucosaOralChecked] = useState(null);

  useEffect(() => {
    setListaMucosaOral(mucosaOral);
  }, [mucosaOral]);

  const verificaPossuiApenasUmaMucosa = () => {
    return listaMucosaOral.some((item) => !!item.checked);
  };

  const handleChangeCheckbox = (index) => {
    if (isTipoSelecionadoDente()) return;

    const list = listaMucosaOral;
    const mucosaOralSelected = list[index];
    const mucosaCheckedAlterado = {
      ...mucosaOralSelected,
      checked: !mucosaOralSelected.checked,
    };

    list[index] = mucosaCheckedAlterado;

    setMucosaOralChecked(mucosaCheckedAlterado);
    setListaMucosaOral(list);
    odontogramaStore.locaisSelecionados = mucosaCheckedAlterado.checked
      ? [mucosaCheckedAlterado]
      : [];
    odontogramaStore.isClickRightLocalSelecionado = false;
  };

  const isTipoSelecionadoDente = () => {
    const possuiLocaisSelecionados = locaisSelecionados.length > 0;
    const isTipoDente =
      possuiLocaisSelecionados &&
      locaisSelecionados.some((item) => item.local === 4);

    return !isClickRightLocalSelecionado && isTipoDente;
  };

  const openModalObservacao = (e, mucosaOral) => {
    odontogramaStore.modalObservacao.showModalObservacao = true;
    odontogramaStore.locaisSelecionados = [mucosaOral];
    return popperRef?.current?.closePopper(e);
  };

  const openModalProcedimento = (e, mucosaOral) => {
    odontogramaStore.modalProcedimento.showModalProcedimento = true;
    odontogramaStore.locaisSelecionados = [mucosaOral];
    return popperRef?.current?.closePopper(e);
  };

  const openModalHistorico = (e, mucosaOral) => {
    odontogramaStore.showModalHistorico = true;
    odontogramaStore.locaisSelecionados = [mucosaOral];
    return popperRef?.current?.closePopper(e);
  };

  const verificaMucosaSelecionada = () => {
    const mucosasChecked = listaMucosaOral.some((item) => item.checked);

    return mucosasChecked;
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentOpcoes}>
        {listaMucosaOral.map((item, index) => {
          return (
            <div className={classes.checkboxLabel} key={index}>
              <Checkbox
                key={`${item.id}-${item.checked}`}
                color="#F5F5F5"
                onChange={() => handleChangeCheckbox(index)}
                checked={item.checked}
                disabled={
                  !item.checked &&
                  (isTipoSelecionadoDente() || verificaPossuiApenasUmaMucosa())
                }
              />

              {item.descricao}
              <div className={classes.buttonMore}>
                <PopperCustomMenu
                  ref={popperRef}
                  placement="bottom-end"
                  colorButton={{
                    background: "#7C7C7C",
                    hover: "#505050",
                  }}
                  iconButton={<MoreVerticalIcon color="#F5F5F5" size={16} />}
                  menuOpcoes={menuOpcoesPopperLocal({
                    isDente: false,
                    ativo: true,
                    functions: {
                      openModalProcedimento: (e) =>
                        openModalProcedimento(e, item),
                      openModalObservacao: (e) => openModalObservacao(e, item),
                      openModalHistorico: (e) => openModalHistorico(e, item),
                    },
                  })}
                  position={true}
                  disabledButton={
                    !item.checked &&
                    (isTipoSelecionadoDente() ||
                      verificaPossuiApenasUmaMucosa())
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      {verificaMucosaSelecionada() && (
        <InfosMucosaOral mucosaOralChecked={mucosaOralChecked} />
      )}
    </div>
  );
});

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    background: "#7C7C7C",
    borderRadius: "0 0 16px 16px",
  },
  contentOpcoes: {
    display: "flex",
    gap: "8px",
    minHeight: "40px",
    padding: "0 24px",
    overflow: "auto",
  },
  checkboxLabel: {
    display: "flex",
    flexDirection: "row",
    color: "#F5F5F5",
    alignItems: "center",
    fontSize: "13px",
    "&>span": {
      color: "#f5f5f5",
    },
    "& svg": {
      fontSize: "14px",
    },
  },
  buttonMore: {
    marginLeft: "4px",
    cursor: "pointer",
  },
};

const MucosaOralWithStyles = withStyles(styles)(MucosaOral);
export default inject("odontogramaStore")(MucosaOralWithStyles);
