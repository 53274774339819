import React, { useState, useReducer, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import TabSelector from "../../../../../../components/TabSelector";
import { modalSugestionsTabs } from "../../constants/constants";
import Scroll from "../../../../../../components/InfiniteScroll/Scroll";
import { InputForm } from "../../../../../../components/Modal/Input";
import {
  findAllGuiaInternacaoModelo,
  findAllGuiaServicoTussViewQuantidadeUso,
} from "../../../../../../services/GuiaInternacaoService";
import { Button } from "../../../../../../components/ui/Buttons";
import AddIcon from "../../../../../../components/Icon/AddIcon";
import SearchIcon from "../../../../../../components/Icon/Search";

import { styles, buttonStyle } from "./styles";

const variablesDefault = {
  search: "",
  pageNumber: 0,
  pageSize: 30,
  sortDir: "DESC",
};

const GuiaInternacaoSuggestions = ({
  classes,
  onSelect,
  selectedEntity,
  changeToModeloCreation,
}) => {
  const [tabSelected, setTabSelected] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [variables, setVariables] = useState(variablesDefault);
  const [lastPage, setLastPage] = useState(false);
  const [isLoading, toggleLoading] = useReducer((state) => !state, false);

  const isTussViewSuggestions = tabSelected === 0;
  const isModeloSuggestions = tabSelected === 1;

  const loadSugestions = async ({ page, searchValue }) => {
    try {
      toggleLoading();
      const { search, pageNumber, pageSize, sortDir, sortField } = variables;
      const willSearchTussViews =
        (selectedEntity === "guiaInternacao" && isTussViewSuggestions) ||
        selectedEntity === "guiaInternacaoModelo";
      const pageableDTO = {
        pageNumber: page || pageNumber,
        pageSize,
        ...(willSearchTussViews
          ? { sortDir, sortField }
          : { sortDir: "ASC", sortField: "nome" }),
      };
      const searchDTO = {
        search: searchValue || search,
      };

      const { content, last } =
        isModeloSuggestions && selectedEntity === "guiaInternacao"
          ? await findAllGuiaInternacaoModelo(searchDTO, pageableDTO)
          : await findAllGuiaServicoTussViewQuantidadeUso({ pageableDTO });

      const suggestionsLoaded =
        pageNumber === 0 ? content : [...suggestions, ...content];
      setSuggestions(suggestionsLoaded);
      setVariables({
        ...variables,
        search: searchValue,
        pageNumber: last ? pageNumber : pageNumber + 1,
      });
      setLastPage(last);
    } catch (error) {
      console.error(error);
    } finally {
      toggleLoading();
    }
  };

  const onChangeSearch = (event) => {
    const { value } = event.target;
    setVariables({
      ...variables,
      search: value,
    });
    loadSugestions({ page: 0, searchValue: value });
  };

  const handleSelectSuggestion = (suggestion) => {
    const type = isTussViewSuggestions ? "tussViewItem" : "modeloItem";
    onSelect(suggestion, type);
  };

  const handleCreateModelo = () => {
    setTabSelected(0);
    changeToModeloCreation();
    loadSugestions({ page: 0, searchValue: "" });
  };

  const renderSuggestions = () => {
    const suggestionsListComponent = suggestions.map((suggestion, index) => (
      <div
        className={classes.suggestionContainer}
        onClick={() => handleSelectSuggestion(suggestion)}
        key={index}
      >
        {(isTussViewSuggestions ||
          selectedEntity === "guiaInternacaoModelo") && (
          <>
            <div className={classes.itemContainer} style={{ width: "100%" }}>
              <label>DESCRIÇÃO</label>
              <span>{suggestion?.tussView?.descricao}</span>
            </div>
            <div className={classes.row}>
              <div className={classes.itemContainer}>
                <label>CÓDIGO</label>
                <span>{suggestion?.tussView?.codigoTermo}</span>
              </div>
              <div className={classes.itemContainer}>
                <label>TABELA</label>
                <span>{suggestion?.tussView?.tabela}</span>
              </div>
            </div>
          </>
        )}
        {isModeloSuggestions && (
          <div className={classes.itemContainer}>
            <label>MODELO</label>
            <span>{suggestion.nome}</span>
          </div>
        )}
      </div>
    ));

    return suggestionsListComponent;
  };

  useEffect(() => {
    loadSugestions(0);
  }, [tabSelected]);

  return (
    <div className={classes.container}>
      {selectedEntity === "guiaInternacao" && (
        <TabSelector
          baseColor="#F2F2F2"
          selectedColor="#FFFFFF"
          onSelect={setTabSelected}
          selectedTabIndex={tabSelected}
          tabsOptions={modalSugestionsTabs}
        />
      )}
      {isTussViewSuggestions && (
        <div className={classes.suggestionHeader}>
          <Button
            bgColor="#707C97"
            style={buttonStyle}
            onClick={handleCreateModelo}
          >
            <AddIcon size={16} color="#FFF" />
            Criar novo modelo
          </Button>
          <div className={classes.searchContainer}>
            <label>Pesquisar modelos</label>
            <InputForm
              value={variables.search}
              onChange={onChangeSearch}
              placeholder="Pesquise"
              InputProps={{
                startAdornment: <SearchIcon size={16} color="#707C97" />,
              }}
            />
          </div>
        </div>
      )}
      <Scroll
        loadMore={loadSugestions}
        hasMore={!lastPage && !isLoading}
        initialLoad={true}
        classes={{ root: classes.scrollRoot }}
      >
        {suggestions?.length > 0 && (
          <div className={classes.suggestionsList}>{renderSuggestions()}</div>
        )}
      </Scroll>
    </div>
  );
};

export default withStyles(styles)(GuiaInternacaoSuggestions);
