import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  RootRef,
  Checkbox,
} from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { withStyles } from '@material-ui/core/styles/index'

const reordenarItens = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const DragAndDropList = ({
  classes,
  handleChangeCampoPacienteList,
  handleChangeCheckbox,
  selectedCampoPaciente,
}) => {
  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const updatedList = reordenarItens(
      selectedCampoPaciente,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({ ...item, ordem: index + 1 }))
    handleChangeCampoPacienteList(updatedList)
  }

  return (
    <>
      <div className={classes.wrapperField}>
        <DragHandleIcon classes={{ root: classes.dragHandleIcon }} />
        <ListItemIcon>
          <Checkbox
            checked={true}
            disabled={true}
            marginRight={0}
            classes={{
              root: classes.wrapperCheckbox,
              checked: classes.checked,
            }}
          />
        </ListItemIcon>
        <p className={classes.labelDisabled}>Nome</p>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {provided => (
            <RootRef rootRef={provided.innerRef}>
              <List>
                {selectedCampoPaciente.map((item, index) => {
                  if (item.campo === 'NOME' || item.campo === 'STATUS') {
                    return null
                  }
                  return (
                    <Draggable
                      key={item.campo}
                      draggableId={item.campo}
                      index={index}
                      isDragDisabled={item.disabled}
                    >
                      {provided => (
                        <ListItem
                          className={classes.listItem}
                          ContainerComponent='li'
                          ContainerProps={{ ref: provided.innerRef }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          disabled={item.disabled}
                        >
                          <DragHandleIcon classes={{ root: classes.dragHandleIcon }} />
                          <ListItemIcon>
                            <Checkbox
                              checked={true}
                              onClick={() => handleChangeCheckbox(item)}
                              disabled={item.disabled}
                              marginRight={0}
                              classes={{
                                root: classes.checkbox,
                                checked: classes.checked,
                              }}
                            />
                          </ListItemIcon>
                          <p className={classes.boxWhiteTitle}>{item.nome}</p>
                          <ListItemSecondaryAction />
                        </ListItem>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
      <div className={classes.wrapperField}>
        <DragHandleIcon classes={{ root: classes.dragHandleIcon }} />
        <ListItemIcon>
          <Checkbox
            checked={true}
            disabled={true}
            marginRight={0}
            classes={{
              root: classes.wrapperCheckbox,
              checked: classes.checked,
            }}
          />
        </ListItemIcon>
        <p className={classes.labelDisabled}>Status</p>
      </div>
    </>
  )
}

const styles = {
  checkbox: {
    color: '#000',
    '&$checked': {
      color: '#26ACA9',
    },
  },
  checked: {
    color: '#26ACA9',
  },
  dragHandleIcon: {
    color: '#000',
  },
  listItem: {
    paddingBottom: '0px',
    '&>div': {
      margin: '0 8px',
    },
  },
  wrapperField: {
    display: 'flex',
    alignItems: 'center',
    paddingInline: '16px',
    '&>div': {
      marginRight: '4px',
    },
  },
  listItemRoot: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  wrapperCheckbox: {
    color: '#000',
    marginInline: '8px',
    '&$checked': {
      color: '#26ACA9',
    },
  },
  labelDisabled: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'capitalize',
    color: '#868686',
    marginTop: 5,
    marginBottom: 5,
    opacity: 0.5,
  },
  boxWhiteTitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'capitalize',
    color: '#7C7C7C',
    marginTop: 5,
    marginBottom: 5,
  },
}

export default withStyles(styles)(DragAndDropList)
