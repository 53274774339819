import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from "recharts";

const LineChartGraficoPercentis = props => {
    const { classes, dados, labelY, campo, valorMinimoY, isTipoGraficoSemanal } = props;
    const [dadosGrafico, setDadosGrafico] = useState(dados);

    useEffect(() => {
        setDadosGrafico(dados);
    }, [dados]);
    
    const getValueY = (data, lista) => {
        const index = lista.findIndex(item => item.data === data.data);
        return index !== -1 ? lista[index][campo] : null;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className={classes.tooltipContent}>
              <p className={classes.label}>{`Mês: ${label}`}</p>
              {payload[0]?.value && <p className={classes.label}>{`Paciente: ${payload[0].value}`}</p>}
              {payload[1]?.value && <p className={classes.label}>{`3%: ${payload[1].value}`}</p>}
              {payload[2]?.value && <p className={classes.label}>{`15%: ${payload[2].value}`}</p>}
              {payload[3]?.value && <p className={classes.label}>{`50%: ${payload[3].value}`}</p>}
              {payload[4]?.value && <p className={classes.label}>{`85%: ${payload[4].value}`}</p>}
              {payload[5]?.value && <p className={classes.label}>{`97%: ${payload[5].value}`}</p>}
            </div>
          );
        }
      
        return null;
      };
      const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;

        const renderValueX = () => {
            if (payload.value.substr(0, 2) === "01"){
                return `jan/${payload.value.substr(3)}`
            }
            return payload.value.substr(0, 2);
        };

        const renderValueXSemanal = () => {
            return payload.value;
        }
        return (
          <g transform={`translate(${x},${y})`}>
            <text
              x={0}
              y={0}
              dy={16}
              fill="#666"
            >
              {isTipoGraficoSemanal ? renderValueXSemanal() : renderValueX()}
            </text>
          </g>
        );
    };

    return (
        <LineChart
            width={800}
            height={255}
            data={dadosGrafico[2]?.length > 0 ? dadosGrafico[2] : dadosGrafico[0]}
            margin={{
                top: 0,
                right: 5,
                left: 0,
                bottom: 5
            }}
        >
            <XAxis 
                dataKey="data" 
                interval={3}
                label={{ value: 'Data', position: 'insideBottom', dy: 10 }}
                tick={<CustomizedAxisTick />}
            />
            <YAxis 
                label={{ value: labelY, angle: -90, position: 'insideLeft' }}
                domain={[valorMinimoY-10 < 0 ? 0 : valorMinimoY-10]}
            />
            <Tooltip 
                content={<CustomTooltip/>}
                filterNull={false}
            />
            <CartesianGrid strokeDasharray="3 3" />
                <Line
                    type="monotone"
                    stroke="#657496"
                    dataKey={data => getValueY(data, dadosGrafico[0] || [])}
                    connectNulls
                    strokeWidth={2}
                /> 
                <Line
                    type="monotone"
                    stroke="#FB7676"
                    dataKey={data => getValueY(data, dadosGrafico[1] || [])}
                    dot={false}
                />
                <Line
                    type="monotone"
                    stroke="#F9BE73"
                    dataKey={data => getValueY(data, dadosGrafico[2] || [])}
                    dot={false}
                />
                <Line
                    type="monotone"
                    stroke="#26ACA9"
                    dataKey={data => getValueY(data, dadosGrafico[3] || [])}
                    dot={false}
                />
                <Line
                    type="monotone"
                    stroke="#F9BE73"
                    dataKey={data => getValueY(data, dadosGrafico[4] || [])}
                    dot={false}
                />
                <Line
                    type="monotone"
                    stroke="#FB7676"
                    dataKey={data => getValueY(data, dadosGrafico[5] || [])}
                    dot={false}
                />
        </LineChart>  
    );
};

const style = {
    tooltipContent: {
        background: "#fff",
        borderRadius: 10,
        border: '1px solid #F2F2F2',
        padding: 5
    },
    label: {
        margin: "0px 3px"
    },
}

export default withStyles(style)(LineChartGraficoPercentis);
