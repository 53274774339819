import { action, observable } from "mobx";
import { findAllSujeitoAtencaoAnexo } from "../services/SujeitoAtencaoAnexoService";
import localStorageService, {
  ACCESS_TOKEN_KEY,
} from "../services/storage";
export default class SujeitoAtencaoAnexoStore {
  sujeitoAtencaoStore = null;
  @observable searchHeader = "";
  @observable anexos = [];
  @observable loading = false;
  @observable pageNumber = 0;
  @observable last = false;
  @observable ordenarTabela = {
    sortField: "dataHoraLancamento",
    sortDir: "DESC",
  };
  @observable accessToken = this.getAccessToken;

  constructor(rootStore) {
    this.sujeitoAtencaoStore = rootStore.sujeitoAtencaoStore;
  }

  @action getAccessToken = async() => {
    const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
    return accessToken;
  };

  @action handleClickSearchHeader = () => {
    this.clearPesquisar();
    this.padraoOrdenarTabela();
    this.findAllSujeitoAtencaoAnexo();
  };

  @action handleClickClearHeader = () => {
    this.clearPesquisar();
    this.padraoOrdenarTabela();
    this.searchHeader = "";

    this.findAllSujeitoAtencaoAnexo();
  };

  @action padraoOrdenarTabela = () => {
    this.ordenarTabela = {
      sortDir: "DESC",
      sortField: "dataHoraLancamento",
    };
  };

  @action findAllSujeitoAtencaoAnexo() {
    this.loading = true;
    findAllSujeitoAtencaoAnexo({
      search: this.searchHeader,
      sujeitoAtencaoId: this.sujeitoAtencaoStore.objView.id,
      pageableDTO: {
        pageSize: 30,
        pageNumber: this.pageNumber,
        sortDir: this.ordenarTabela.sortDir,
        sortField: this.ordenarTabela.sortField,
      },
    })
      .then((response) => {
        this.last = response.last;
        this.anexos = [...this.anexos, ...response.content];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @action onChangeSearchHeader = (e) => {
    const { value } = e.target;
    this.searchHeader = value;
  };

  @action onKeypressSearchHeader = (e) => {
    if (e.key === "Enter" && this.searchHeader.length >= 3) {
      this.handleClickSearchHeader();
    }
  };

  @action clearPesquisar = () => {
    this.pageNumber = 0;
    this.anexos = [];
    this.last = false;
  };
}
