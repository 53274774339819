import React, { useEffect, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'

import Dialog from '../../../components/Dialog/Dialog'
import ItemGrid from '../../../components/Modal/ItemGrid'
import InputForm from '../../../components/Input/InputForm'

import { Button } from '../../../components/ui/Buttons'
import { InputCPFForm } from '../../../components/Input/InputCPFForm'
import { InputPhoneForm } from '../../../components/Input/InputPhoneForm'
import {
  findSujeitoAtencaoById,
  updateSujeitoAtencaoConfirmarPresenca,
} from '../../../services/ProntuarioService'

const ModalConfirmarPresenca = ({
  open,
  onClose,
  classes,
  sujeitoAtencaoId,
  onPlayAgendamento,
  openNotification,
}) => {
  const [sujeitoAtencao, setSujeitoAtencao] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChange = (value, field) => {
    const newValue = value.replace(/\D/g, '')
    setSujeitoAtencao({ ...sujeitoAtencao, [field]: newValue })
  }

  const handleChangeContato = (value, field) => {
    if (field === 'telefonePrincipal') {
      value = value.replace(/\D/g, '')
    }
    setSujeitoAtencao({
      ...sujeitoAtencao,
      contato: {
        ...sujeitoAtencao.contato,
        [field]: value,
      },
    })
  }
  const findSujeitoAtencaoSelected = async () => {
    const sujeitoAtencao = await findSujeitoAtencaoById(sujeitoAtencaoId)
    setSujeitoAtencao(sujeitoAtencao)
  }

  const handleSave = async e => {
    try {
      setLoading(true);
      const variables = {
        sujeitoAtencao: {
          id: sujeitoAtencaoId,
          cpf: sujeitoAtencao.cpf,
          contato: {
            telefonePrincipal: sujeitoAtencao.contato.telefonePrincipal,
            email: sujeitoAtencao.contato.email,
          },
        },
      }
      await updateSujeitoAtencaoConfirmarPresenca(variables)
      onPlayAgendamento(e)
      onClose()
    } catch (error) {
      console.error(error)
      openNotification('Erro ao salvar os dados do paciente', 'error')
    } finally {
      setLoading(false);
    }
  }

  const disableButton = useMemo(() => {
    return (
      !sujeitoAtencao?.cpf ||
      !sujeitoAtencao?.contato?.telefonePrincipal ||
      !sujeitoAtencao?.contato?.email
    )
  }, [sujeitoAtencao])

  useEffect(() => {
    if (!sujeitoAtencaoId) return
    findSujeitoAtencaoSelected()
  }, [sujeitoAtencaoId])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth={true}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.content}>
        <div className={classes.header}>
          <h1>Dados do paciente incompleto</h1>
          <span>
            Preencha os dados para que o paciente possa receber o e-mail de confirmação de presença
          </span>
        </div>
        <div className={classes.wrapperContent}>
          <ItemGrid size={12} label={'CPF:'} bold>
            <InputCPFForm
              placeholder={'000.000.000-00'}
              className={classes.inputContainer}
              value={sujeitoAtencao?.cpf}
              onChange={e => handleChange(e.target.value, 'cpf')}
            />
          </ItemGrid>
          <ItemGrid size={12} label={'Telefone:'} bold>
            <InputPhoneForm
              placeholder={'(00) 0 0000 0000'}
              className={classes.inputContainer}
              value={sujeitoAtencao?.contato?.telefonePrincipal}
              onChange={e => handleChangeContato(e.target.value, 'telefonePrincipal')}
            />
          </ItemGrid>
          <ItemGrid size={12} label={'Email:'} bold>
            <InputForm
              value={sujeitoAtencao?.contato?.email}
              onChange={e => handleChangeContato(e.target.value, 'email')}
              placeholder={'Digite o e-mail'}
              type='email'
              className={classes.inputContainer}
            />
          </ItemGrid>
        </div>
        <div className={classes.footerButtons}>
          <Button onClick={handleSave} kind='primary' disabled={disableButton || loading}>
            Salvar
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const styles = {
  paper: {
    width: '400px',
    height: 'fit-content',
    padding: '24px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 4px',
    '& h1': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    '& span': {
      fontSize: '14px',
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  wrapperContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  inputContainer: {
    width: '100%',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '4px 8px',

    '& > div': {
      '& > input': {
        border: 'none',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
      '& > p': {
        overflow: 'hidden',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },

  footerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      minWidth: '150px',
    },
  },
}

export default withStyles(styles)(ModalConfirmarPresenca)
