import React from 'react'

import {withStyles} from '@material-ui/core/styles';
import GracefulImage from '../Image/GracefulImage';
const profileDefault = require("../../assets/img/svg/img-profile-default.svg");


const styles = theme => ({

    root: {
        height: "100% ",
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]:{
        display: 'none',
        },
    },

    userInfo: {
        fontSize: 16,
        [theme.breakpoints.down('md')]:{
            display: 'none',
        }
    },

    userImage: {
        height: 40,
        width: 40,
        borderRadius: 50,
        [theme.breakpoints.down('md')]:{
            marginLeft: 0,
            padding:0,
        }
    },

    userImageDefault: {
        height: 40,
        width: 40,
        background: theme.palette.commons.gray,
        borderRadius: 50

    }

});

function LogoImage(props) {
    const {classes, ...other} = props;

    return (
        <div className={classes.root}>
            <div className={classes.userInfo}>
                <p>{other.text}</p>
            </div>

            { 
                other.image
                ?
                <GracefulImage className={classes.userImage} src={other.image || profileDefault} alt={other.text} placeholderColor={'#00ACA9'} />
                :
                <div className={classes.userImageDefault} />
            }
        </div>
    )
}

export default withStyles(styles)(LogoImage)