import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import Grid from '@material-ui/core/Grid'

import HorizontalLinearStepper from '../Stepper'
import {
  findAllTuss,
  faturarFinanceiroGuiaServico,
  findAllEstadoList
} from '../../../../services/GuiaDeServicosService'

import ButtonLong from '../../../../components/Button/ButtonLong'

import Dialog from '../../../../components/Dialog/Dialog'
import DialogHeader from '../../../../components/Dialog/DialogHeader'
import colors from '../../../../template/Colors'
import DadosGuiaServicoFaturamento from './DadosGuiaServicoFaturamento';
import StepAtendimento from './Steps/StepAtendimento'
import StepItens from './Steps/StepItens'
import { findAllTuss52TerminologiaTipoConsulta } from '../../../../services/AgendamentoTipoService'
import StepResumo from './Steps/StepResumo'
import StepObservacao from './Steps/StepObservacao'
import classNames from 'classnames'

const GuiaServicosFaturamentoModal = props => {
  const {
    classes,
    openModal,
    openNotification,
    setOpenModalFaturamento,
    guiaServicoSelected,
    loadFinanceiroGuiaServico,
  } = props

  const [disableButton, setDisableButton] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [guiaServicoFaturamento, setGuiaServicoFaturamento] = useState({});
  const [loadingFaturar, setLoadingFaturar] = useState(false);

  const handleChangeGuiaServicoFaturamento = (field, value) => {
    setGuiaServicoFaturamento(prevState => ({
      ...prevState,
      [field]: value,
    }))
  };
  
  useEffect(() => {
    listarDados();
  }, []);  

  const getTuss35Padrao = async() => {
    const padrao = await findAllTuss({
      pageableDTO: {
        pageNumber: 0,
        pageSize: 30,
        sortDir: "ASC",
        sortField: "termo",
      },
      termo: "Clínico"
    }, "35TerminologiaGrauParticipacao");

    return padrao.findAllTuss35TerminologiaGrauParticipacao.content[0]
  }

  const listarDados = async () => {
    let listaProcedimentosAgendamento = [];

    const listaEstados = await findAllEstadoList();

    guiaServicoSelected.agendamentosNaoFaturados.forEach((agendamento) => {
      const ufConselho = listaEstados.data.data.findAllEstadoList.find(item => item.uf === agendamento.profissionalSaude.ufConselho);
      agendamento.procedimentos.forEach((item) => {
        const valorUnitario =
          item.procedimento?.convenioProcedimentos.length > 0
            ? item.procedimento.convenioProcedimentos.find(
                (convenioProcedimento) =>
                  convenioProcedimento.convenio.id === guiaServicoSelected.convenio.id
              )?.valor
            : "";
        const verificaIndexProcedimentoDuplicado = listaProcedimentosAgendamento.findIndex(
          (procedimentoAgendamento) =>
            procedimentoAgendamento.descricao.id === item.procedimento.id
        );
        verificaIndexProcedimentoDuplicado === -1
          ? listaProcedimentosAgendamento.push({
              data: agendamento.data,
              horaInicial: agendamento.horaInicio,
              horaFinal: agendamento.horaFim,
              tabela: 22,
              codigoProcedimento:
                item.procedimento?.tuss22ProcedimentoEventoSaude,
              descricao: {
                nome: item.procedimento?.nome,
                id: item.procedimento.id
              },
              valorUnitario: valorUnitario,
              quantidade: 1,
              executantes: [{
                ...agendamento.profissionalSaude,
                profissionalSaude: {
                  nome: agendamento.profissionalSaude.nome,
                  id: agendamento.profissionalSaude.id,
                },
                estado: ufConselho,
                tuss24TerminologiaCBO:
                  agendamento.profissionalSaude.informacao
                    ?.tuss24TerminologiaCBO,
              }],
            })
          : (listaProcedimentosAgendamento[
              verificaIndexProcedimentoDuplicado
            ].quantidade =
              listaProcedimentosAgendamento[verificaIndexProcedimentoDuplicado]
                .quantidade + 1);
      });
    });

    const tuss35Padrao = await getTuss35Padrao();
    
    const listaProcedimentosAgendamentoComId = listaProcedimentosAgendamento.map(
      (item, index) => {
        const listaExecutantes = item.executantes.map(executante => {
          return (
            {
              ...executante,
              tuss35TerminologiaGrauParticipacao: tuss35Padrao
            }
          )
        });

        return ({
        ...item,
        executantes: listaExecutantes,
        id: index,
      })
    });

    const tuss36PadraoTermoNaoAcidente = await tuss36Padrao(); 

    setGuiaServicoFaturamento({
      ...guiaServicoFaturamento, 
      itensExecucao: listaProcedimentosAgendamentoComId,
      codigoOperadora: guiaServicoSelected.convenio.codigoUnidade,
      nomeContratado: guiaServicoSelected.unidade.nome,
      codigoCnes: guiaServicoSelected.unidade.codigoCnes,
      observacao: guiaServicoSelected.observacao,
      atendimento: {
        tuss36TerminologiaIndicadorAcidente: tuss36PadraoTermoNaoAcidente[0]
      }
    });
  };

  const tuss36Padrao = async() => {
    const termoNaoAcidente = await findAllTuss({
      pageableDTO: {
        pageNumber: 0,
        pageSize: 30,
        sortDir: "ASC",
        sortField: "termo"
      },
      termo: "Não Acidente"
    }, "36TerminologiaIndicadorAcidente");

    return termoNaoAcidente.findAllTuss36TerminologiaIndicadorAcidente.content;
  }

  const loadTipoAtendimento = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
        search,
        loadedOptions,
        data: { page },
        method: "findAllTuss50TerminologiaTipoAtendimento",
        list: '50TerminologiaTipoAtendimento'
    });
  };

  const loadIndicacaoAcidente = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
        search,
        loadedOptions,
        data: { page },
        method: "findAllTuss36TerminologiaIndicadorAcidente",
        list: '36TerminologiaIndicadorAcidente'
    });
  };

  const loadTipoConsulta = async(search, loadedOptions, { page }) => {
    const pageableDTO = {
        pageNumber: page,
        pageSize: 10,
        sortDir: 'ASC',
        sortField: 'descricao'
    }
    const {content, last} = (await findAllTuss52TerminologiaTipoConsulta(pageableDTO, search)).data.data.findAllTuss52TerminologiaTipoConsulta
    return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1
        }
    }
}

  const loadMotivoEncerramentoAtendimento = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
        search,
        loadedOptions,
        data: { page },
        method: "findAllTuss39TerminologiaMotivoEncerramento",
        list: '39TerminologiaMotivoEncerramento'
    });
  };

  const handleLoadMoreOptions = async ({ search, data, method, list }) => {
    const response = await findAllTuss(
      {
        pageableDTO: {
          pageNumber: data.page,
          pageSize: 30,
          sortDir: "ASC",
          sortField: "termo"
        },
        search
      },
      list);

    const { content, last } = response[method];
    return {
      options: content,
      hasMore: !last,
      additional: {
        page: data.page + 1
      }
    };
};

  const handleNext = () => {
    handleChangeStep(activeStep + 1)
    setDisableButton(true)
  }

  const handleBack = () => {
    handleChangeStep(activeStep - 1)
  }

  const handleFaturar = async() => {
    try{
      setLoadingFaturar(true);
      const execucaoItens = guiaServicoFaturamento.itensExecucao.map((item, index) => {
        const executantes = item.executantes.map(executante => {
          return ({
            profissionalSaude: {id: executante.profissionalSaude.id},
            codigoOperadoraCPF: executante.cpf,
            conselhoProfissional: String(executante.conselhoProfissionalSaude.id),
            grauParticipacao: "00",
            nomeProfissional: executante.profissionalSaude.nome,
            numeroConselhoProfissional: executante.numeroConselho,
            tuss24TerminologiaCBO: executante.tuss24TerminologiaCBO,
            estado: executante.estado
          })
        });

        return{
          data: item.data,
          sequencialItem: index+1,
          descricao: item.descricao.nome,
          horaFinal: item.horaFinal,
          horaInicial: item.horaInicial,
          quantidade: item.quantidade,
          tabela: String(item.tabela),
          tuss48TerminologiaTecnicaUtilizada: item.tuss48TerminologiaTecnicaUtilizada,
          tuss61TerminologiaViaAcesso: item.tuss61TerminologiaViaAcesso,
          codigoProcedimento: String(item.codigoProcedimento.id),
          valorUnitario: parseFloat(item.valorUnitario),
          executantes
        }
      });

      await faturarFinanceiroGuiaServico({
        financeiroGuiaServicoId: guiaServicoSelected.id,
        executante: {
          codigoCnes: guiaServicoFaturamento.codigoCnes,
          codigoOperadora: guiaServicoFaturamento.codigoOperadora,
          nomeContratado: guiaServicoFaturamento.nomeContratado
        },
        execucaoItens: execucaoItens,
        atendimento: guiaServicoFaturamento.atendimento,
        observacao: guiaServicoFaturamento.observacao
      });

      setOpenModalFaturamento(false);
      loadFinanceiroGuiaServico({ isSearching: true });
    } catch {
      openNotification("Erro ao faturar", "error");
    } finally {
      setLoadingFaturar(false);
    }
  };

  const handleClose = () => {
    setOpenModalFaturamento(false)
    setActiveStep(0)
  }

  const handleChangeStep = step => {
    setActiveStep(step)
  }

  const getSteps = () => {
    return [
      'Atendimento',
      'Itens',
      'Observação',
      'Resumo',
    ]
  }

  return (
    <Dialog 
      open={openModal}
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classNames(classes.root, isEdit && classes.rootEdit)}>
        <DialogHeader
          title={'Faturamento guia de serviço'}
          closeButton={true}
          actionClose={handleClose}
        />
        <DadosGuiaServicoFaturamento
          guiaServicoSelected={guiaServicoSelected}
        />
        <HorizontalLinearStepper activeStep={activeStep} steps={getSteps()}/>

        <div className={ isEdit ? classes.modalContentEdit : classes.modalContent}>
          {activeStep === 0 && (
            <StepAtendimento
              loadTipoAtendimento={loadTipoAtendimento}
              loadTipoConsulta={loadTipoConsulta}
              loadIndicacaoAcidente={loadIndicacaoAcidente}
              loadMotivoEncerramentoAtendimento={loadMotivoEncerramentoAtendimento}
              openNotification={openNotification}
              guiaServicoFaturamento={guiaServicoFaturamento}
              handleChangeGuiaServicoFaturamento={handleChangeGuiaServicoFaturamento}
              setDisableButton={setDisableButton}
              />
              )}

          {activeStep === 1 && (
            <StepItens
              openNotification={openNotification}
              guiaServicoFaturamento={guiaServicoFaturamento}
              listaAgendamentos={guiaServicoFaturamento.itensExecucao}
              handleChangeGuiaServicoFaturamento={handleChangeGuiaServicoFaturamento}
              setDisableButton={setDisableButton}
              convenioId={guiaServicoSelected.convenio.id}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
          )}

          {activeStep === 2 && (
            <StepObservacao
              handleChangeGuiaServicoFaturamento={handleChangeGuiaServicoFaturamento}
              guiaServicoFaturamento={guiaServicoFaturamento}
              setDisableButton={setDisableButton}
            />
          )}

          {activeStep === 3 && (
            <StepResumo
              openNotification={openNotification}
              guiaServicoFaturamento={guiaServicoFaturamento}
              handleChangeGuiaServicoFaturamento={handleChangeGuiaServicoFaturamento}
              setDisableButton={setDisableButton}
              setIsEdit={setIsEdit}
              />
          )}
        </div>
        {!isEdit && <Grid item xs={12} className={classes.formActions}>
          {activeStep === 3 ? (
            <div className={classes.wrapperButtons}>
              <Grid item xs={3} className={classes.inlineButtons}>
                <ButtonLong 
                  colorCustom='transparency' 
                  onClick={handleBack}
                  disabled={loadingFaturar}
                >
                  Voltar
                </ButtonLong>
              </Grid>
              <Grid item xs={3} className={classes.inlineButtons}>
                <ButtonLong 
                colorCustom='green' 
                onClick={handleFaturar}
                loading={loadingFaturar}
                disabled={loadingFaturar}
                >
                  <div className={classes.paddingRight}> Faturar </div>
                </ButtonLong>
              </Grid>
            </div>
          ) : (
            <Grid container>
              <Grid item xs={6} />
              { !isEdit && 
              <>
              <Grid item xs={3} className={classes.inlineButtons}>
                {activeStep !== 0 && !isEdit &&  (
                  <ButtonLong 
                      colorCustom='transparency' 
                      onClick={handleBack}
                  >
                    Voltar
                  </ButtonLong>
                )}
              </Grid>
              <Grid item xs={3} className={classes.inlineButtons}>
                <ButtonLong
                  colorCustom='green'
                  onClick={handleNext}
                  disabled={disableButton}
                >
                  Próxima etapa
                </ButtonLong>
              </Grid>
              </>
              }
            </Grid>
          )}
        </Grid>}
      </div>
    </Dialog>
  )
}

const styles = () => ({
  paper: {
    width: '100vw',
    maxWidth: 650,
    overflow: 'hidden',
    height: '100%',
    maxHeight: 550
  },
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto 1fr 76px',
    height: '100%'
  },
  rootEdit: {
    gridTemplateRows: 'auto auto auto 1fr',
  },
  toggleButtons: {
    margin: '0 auto',
    width: '80%',
  },
  modalContent: {
    height: '100%',
    overflow: 'auto'
  },
  modalContentEdit: {
    height: 'calc(100% - 30px)',
    maxHeight: '376px',
    maxWidth: '650px',
  },
  formActions: {
    padding: '20px 14px 20px 0',
    maxHeight: '76px',
  },
  inlineButtons: {
    paddingRight: '10px',
    display: 'inline-flex',
    width: '100%',
    '& button[disabled]': {
      opacity: '0.5',
    },
  },
  wrapperButtons: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  buttonNextStep: {
    width: '10rem',
  },
  buttonVoltar: {
    color: colors.commons.gray9,
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    height: '35px',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  paddingRight: {
    paddingRight: 8,
  }
})

export default withStyles(styles)(GuiaServicosFaturamentoModal)
