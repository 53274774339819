import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const CallIcon = props => {
  return (
    <svg
      width='27'
      height='28'
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M11.3387 12.433L13.0612 10.8105C14.0261 9.90155 14.3426 8.49788 13.8612 7.26284L13.0897 5.28383C12.492 3.75064 10.8048 2.94697 9.23765 3.44905C6.45922 4.33922 4.34952 6.96001 5.05044 10.031C5.51138 12.0506 6.37501 14.5817 7.98508 17.267C9.59789 19.9568 11.4253 21.9369 12.9876 23.3218C15.3475 25.4137 18.6731 24.8387 20.7907 22.8112C21.9688 21.6833 22.0661 19.8335 21.0129 18.5882L19.6245 16.9465C18.7686 15.9344 17.3838 15.5432 16.1248 15.9579L13.8772 16.6982C13.7893 16.6102 13.6873 16.5048 13.576 16.384C13.1997 15.9754 12.7282 15.4021 12.3291 14.7316C11.93 14.0611 11.6509 13.3732 11.4713 12.8476C11.4181 12.6922 11.3742 12.5523 11.3387 12.433Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CallIcon
