import colors from '../../../../../template/Colors'

const borderStyleByKind = kind => {
  if (kind === 'outline') return `2px solid ${colors.commons.padrao} !important`
  if (kind === 'transparent' || kind === 'transparentHover') return '1px solid transparent !important'
  return '2px solid rgba(220, 220, 220, 0.2)'
}

const borderHoverStyle = {
  transparentHover: '1px solid #219A97 !important'
}

const backgroundColorByKind = {
  primary: {
    backgroundColor: colors.commons.padrao,
    color: colors.commons.white,
  },
  secondary: {
    backgroundColor: colors.commons.gray10,
    color: colors.commons.white,
  },
  outline: {
    backgroundColor: 'transparent',
    color: colors.commons.padrao,
  },
  transparent: {
    backgroundColor: 'transparent',
    color: colors.commons.gray9,
  },
  transparentHover: {
    backgroundColor: 'transparent',
    color: colors.commons.gray9,
    hover: '#F1FCFA',
  }
}

const getShape = {
  pill: `
    border-radius: 20px;
  `,
  square: `
    width: max-content;
    height: max-content;
    border-radius: 0px;
  `,
  round: `
    border-radius: 50%;
  `,
  circle: `
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

const paddingBySize = {
  default: {
    padding: '0.5rem 1rem',
  },
  large: {
    padding: '0.75rem 1.5rem',
  },
  mini: {
    padding: '0.25rem 0.5rem',
  },
}

const fontSizeBySize = {
  default: {
    fontSize: '0.875rem',
  },
  large: {
    fontSize: '1rem',
  },
  mini: {
    fontSize: '0.75rem',
  },
}

export { getShape, backgroundColorByKind, borderStyleByKind, paddingBySize, fontSizeBySize, borderHoverStyle }
