import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { withStyles } from "@material-ui/core/styles";
import { 
    Fab, 
    CircularProgress, 
    Grid, 
    List,
} from "@material-ui/core";
import {Add as AddIcon} from "@material-ui/icons";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import ItemList from "../../../components/Modal/ItemList";
import ItemListRemedio from "../../../components/Modal/ItemListRemedio";
import ModeloSearchInput from './ModeloSearchInput/ModeloSearchInput';

const Lista = (props) => {
    const { receituarioStore, classes, loadMoreSugestoes, selectSugestao, handleClickCriarNovoModelo, loadMoreModelos, handleClickSelecionarModelo } = props;
    const [search, setSearch] = useState('');

    const handleChangeSearchInput = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setSearch(value);
    };

    const handleClearSearchInput = () => {
        setSearch('');
        loadMoreModelos('', true);
    };

    const handleSearch = () => {
        loadMoreModelos(search, true);
    }
    
    return( 
    <>
    {receituarioStore.tipoListagemSelecionada === 'sugestoes' ?                        
        <Scroll
            loadMore={loadMoreSugestoes}
            hasMore={!receituarioStore.sugestoesLast}
            pageStart={0}
            className={classes.scrollContainerSugestoes}
        >                              
            {
                receituarioStore.sugestoesLoading ? (
                    <div className={classes.notFoundContainer}>
                        <CircularProgress style={{color: '#fff'}} />
                    </div>
                ) 
                :
                <List>
            {receituarioStore.sugestoes.map((item, i) => (
                <Grid container key ={i} className={classes.cardSugestao} onClick={ () => selectSugestao(item) }>
                    <div className={classes.linhaItemLista}>
                            <ItemListRemedio
                            size={12}
                            field={"Remédio:"}
                            value={`${item.medicamento.produto}${item.medicamento.subtitulo ? `, ${item.medicamento.subtitulo}` : ''}`}
                            className={classes.itemList}
                        />
                    </div>
                </Grid>
            )
            )}
            </List>

            }
        </Scroll>
        :
        <Grid className={classes.opcaoModelo}>
            <Fab className={classes.criarNovoModelo} onClick={() => handleClickCriarNovoModelo()}>
                <AddIcon />
                <span className={classes.labelCriar}>Criar novo modelo </span>
            </Fab>
            <ModeloSearchInput
                search={search}
                handleChange={handleChangeSearchInput}
                handleClear={handleClearSearchInput}
                handleSearch={handleSearch}
            />  
            <Scroll
                loadMore={() => loadMoreModelos(search)}
                hasMore={!receituarioStore.modelosLast}
                pageStart={0}
                className={classes.scrollContainerModelos}
            >  
                <List>
                {receituarioStore.modelos.map(item => (
                    <Grid className={classes.cardModelo} onClick={() => handleClickSelecionarModelo(item)}>
                        <ItemList
                            size={12}
                            field={"Modelo:"}
                            className={classes.itemList}
                            value={item.nome}
                        />
                    </Grid>))}
                    {receituarioStore.modelosLoadingModal && (
                    <div className={classes.notFoundContainer}>
                        <CircularProgress style={{color: '#fff'}}/>
                    </div>
                )}
                </List> 
            </Scroll>                         
        </Grid>
    }
    </>
)}

const styles = theme => ({
    notFoundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
        margin: 20,
    },
    scrollContainerModelos:{
        overflowY: 'auto',
        height: '60vh',
        maxHeight: '100%',
        "@media (max-height: 620px)":{
            height: '50vh',
        }
    },
    cardSugestao: {
        margin: '10px 30px',
        background: '#fff',
        alignSelf: 'center',
        padding: 10,
        width: '80%',
        borderRadius: 10,
        cursor: 'pointer',
    },
    linhaItemLista:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    itemList:{
        fontSize: '12px !important',
        fontFamily: "Poppins !important",  
    },
    opcaoModelo:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        textAlign: '-webkit-center',
        width: '100%',
        overflowY: 'hidden',
    },
    criarNovoModelo:{
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
        height: 40,
        background: '#FBBF6D',
        color: '#fff',
        alignItems: 'center',
        borderRadius: 100,
        marginTop: 13,
        marginBottom: 13,
        boxShadow: 'none',
        textTransform: 'capitalize',
        alignSelf: 'center',
        '&:hover' : {
            background: '#f5b864',
        }
        
    },
    labelCriar:{
        paddingLeft: 10,
    },
    cardModelo: {
        margin: '16px 0',
        background: '#fff',
        alignSelf: 'center',
        padding: 10,
        borderRadius: 10,
        width: '80%',
        cursor: 'pointer',
        textAlign: 'start',
    },
});

const ListaWithStyles = withStyles(styles)(Lista);
export default inject("receituarioStore")(ListaWithStyles)
