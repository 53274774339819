import React, { useEffect, useState, useRef } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core/styles/index";
import classnames from "classnames";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import styles from "./SujeitoAtencaoAnexoStyle";
import Table from "../../../../components/Table/Table";
import PopperCustomMenu from "../../../../components/Popper/PopperCustomMenu";
import { DownloadGrayIcon } from "../../../../assets/img/svg";
import {
  findByIdSujeitoAtencaoAnexo,
  inativarSujeitoAtencaoAnexo,
} from "../../../../services/SujeitoAtencaoAnexoService";
import Notification from "../../../../components/Notification";
import MensagenListaVazia from "../../../../components/Mensagem/MensagemListaVazia";
import enviarArquivo from "../../../../assets/img/svg/enviar-arquivo.svg";
import ModalAnexo from "./ModalAnexo";
import ModalVisualizacaoAnexo from "./ModalVisualizacaoAnexo";
import { buildUrlSujeitoAtencaoAnexo } from "../../../../config/config";
import {
  ImagePdf,
  ImageOutros,
  ImageXlsx,
  ImageDoc,
  ImagePpt,
} from "../../../../assets/img/svg";
import ModalInativarEntradaProntuario from "../../../../components/Modal/ModalInativarEntradaProntuario";
import { formatBytes } from "../../../../utils/bytes";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import GracefulImage from '../../../../components/Image/GracefulImage';
import { downloadImage, downloadArquivo } from '../../../../utils/downloadImagem';
import { uploadArquivos } from "../../../../utils/uploadArquivo";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import MoreIcon from "../../../../components/Icon/MoreIcon";
import { Button } from "../../../../components/ui/Buttons";

const extensoes = {
  pdf: ImagePdf,
  xlsx: ImageXlsx,
  docx: ImageDoc,
  ppt: ImagePpt,
};

const SujeitoAtencaoAnexo = observer((props) => {
  const { classes, sujeitoAtencaoAnexoStore, sujeitoAtencaoStore } = props;
  const [openModalVisualizar, setOpenModalVisualizar] = useState(false);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    variant: "",
  });
  const [openModalEnviarArquivo, setOpenModalEnviarArquivo] = useState(false);
  const [anexoSelecionado, setAnexoSelecionado] = useState(null);
  const [showModalInativar, setShowModalInativar] = useState(false);
  const [infosInativacao, setInfosInativacao] = useState({
    dia: "",
    hora: "",
    inativador: "",
    motivo: "",
  });
  const [loadingInativacao, setLoadingInativacao] = useState(false);
  const [anexoSelecionadoUpload, setAnexoSelecionadoUpload] = useState({});
  const [loadingDownload, setLoadingDownload] = useState({value: false, row: null});
  const documentoFileInput = useRef();

  const columns = [
    {
      Header: "",
      onlyIcon: true,
      getValue: (row) => {
        return <div className={classnames("status", String(row.ativo))} />;
      },
    },
    {
      Header: "",
      onlyIcon: true,
      getValue: (row) => {
        const url = row.amazonS3Objeto.id;
        const urlImageMiniatura = buildUrlSujeitoAtencaoAnexo(
          url,
          sujeitoAtencaoAnexoStore.accessToken,
          "imagem/miniatura"
        );
        return (
          <div className={classes.contentImageMini}>
            {row.tipo === "IMAGEM" ? (
              <GracefulImage 
                src={urlImageMiniatura} 
                alt={""} 
                height="24" 
                width="32" 
                className={classes.imagemMiniatura}
              />
            ) : (
              <img
                src={renderIconeArquivo(row.amazonS3Objeto?.extensao)}
                alt={""}
                className={classes.imagemMiniatura}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Nome do arquivo",
      field: "amazonS3Objeto.nome",
      getValue: (row) => {
        return row.amazonS3Objeto.nome;
      },
    },
    {
      Header: "Tipo",
      field: "amazonS3Objeto.extensao",
      getValue: (row) => {
        return row.amazonS3Objeto.extensao;
      },
    },
    {
      Header: "Tamanho",
      field: "amazonS3Objeto.tamanho",
      getValue: (row) => {
        return formatBytes(row.amazonS3Objeto.tamanho);
      },
    },
    {
      Header: "",
      onlyIcon: true,
      getValue: (row) => {
        return row.tipo === "IMAGEM" ? (
          <div className={classes.columnVisiblity}>
            <VisibilityIcon />
          </div>
        ) : (
          ""
        );
      },
    },
    {
      Header: "",
      isClick: true,
      onlyIcon: true,
      getValue: (row) => {
        return downloadAnexoRow(row);
      },
    },
    {
      Header: "",
      isClick: true,
      onlyIcon: true,
      getValue: (row) => {
        return (
          <div>

            {row.ativo && (
              <PopperCustomMenu
                placement="bottom-end"
                iconButton={<MoreIcon color="#505050"/>}
                menuOpcoes={menuOpcoesPopper(row)}
                position={true}
                disabledButton={sujeitoAtencaoStore.isSujeitoAtencaoUnificado}
              />
            )}
          </div>
        );
      },
    },
  ];

  const menuOpcoesPopper = (row) => [
    {
      ativo: true,
      label: "Inativar",
      onClick: () => handleShowInativar(row),
      icon: <VisibilityOffIcon className={classes.buttonInativar} />,
    },
  ];

  useEffect(() => {
    sujeitoAtencaoAnexoStore.clearPesquisar();
  }, []);

  useEffect(() => {
    sujeitoAtencaoStore.objView.id && loadAnexos();
  }, [sujeitoAtencaoStore.objView.id]);

  const renderIconeArquivo = (extensao) => {
    return extensoes[extensao] || ImageOutros;
  };

  const loadAnexos = async () => {
    try {
      await sujeitoAtencaoAnexoStore.findAllSujeitoAtencaoAnexo();
    } catch {
      const notification = {
        isOpen: true,
        message: "Erro ao carregar a lista de anexo",
        variant: "error",
      };
      showAlertMessage(notification);
    }
  };

  const handleClickOrdenar = async (value) => {
    sujeitoAtencaoAnexoStore.clearPesquisar();
    const sortDir =
      sujeitoAtencaoAnexoStore.ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC";
    sujeitoAtencaoAnexoStore.ordenarTabela = {
      sortDir: sortDir,
      sortField: value,
    };

    loadAnexos();
  };

  const handleItemClick = async (id) => {
    const anexoSelecionado = await findByIdSujeitoAtencaoAnexo(id);
    if (anexoSelecionado.tipo === "ARQUIVO") {
      return;
    }
    setAnexoSelecionado(anexoSelecionado);
    setOpenModalVisualizar(true);
  };

  const downloadAnexoRow = (row) => {
    const url = row.amazonS3Objeto.id;

    if (row.tipo === "ARQUIVO") {
      const urlArquivo = buildUrlSujeitoAtencaoAnexo(
        url,
        sujeitoAtencaoAnexoStore.accessToken,
        "arquivo"
      );
      return (
        <>
          {
            loadingDownload.value && loadingDownload.row === row ? 
              <CircularProgress size={22} />
            :
            <div onClick={() => donwloadAnexoArquivo(row, urlArquivo)} className={classes.divDownloadImage}>
              <img src={DownloadGrayIcon} alt="Download" />
            </div>
          }
        </>
      );
    } else {
      const urlImagem = buildUrlSujeitoAtencaoAnexo(
        url,
        sujeitoAtencaoAnexoStore.accessToken,
        "imagem/completa"
      );

      return (
        <>
          {
            loadingDownload.value && loadingDownload.row === row ? 
              <CircularProgress size={22} />
            :
            <div
              className={classes.divDownloadImage}
              onClick={() => downloadAnexoImagem(row, urlImagem)}
            >
              <img src={DownloadGrayIcon} alt="Download" />
            </div>
          }
        </>
      );
    }
  };

  const donwloadAnexoArquivo = async(row, urlArquivo) => {
    setLoadingDownload({value: true, row})
    downloadArquivo(row.amazonS3Objeto?.nome, urlArquivo)
    await sleep(5000)
    setLoadingDownload({value: false, row})
  }

  const downloadAnexoImagem = async(row, urlImagem) => {
    setLoadingDownload({value: true, row})
    await downloadImage(row.amazonS3Objeto?.nome, urlImagem);
    setLoadingDownload({value: false, row})
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const reloadAnexos = () => {
    sujeitoAtencaoAnexoStore.clearPesquisar();
    loadAnexos();
  };

  const handleShowInativar = (item) => {
    setAnexoSelecionado(item);
    setShowModalInativar(true);
  };

  const handleInativarMotivo = async () => {
    try {
      if (
        infosInativacao.motivo?.length > 0 &&
        infosInativacao.motivo?.length < 2000
      ) {
        setLoadingInativacao(true);
        await inativarSujeitoAtencaoAnexo({
          sujeitoAtencaoAnexoId: anexoSelecionado.id,
          motivo: infosInativacao.motivo,
        });

        setShowModalInativar(false);
        sujeitoAtencaoAnexoStore.clearPesquisar();
        loadAnexos();
      }
    } catch (error) {
      const notification = {
        isOpen: true,
        message: "Erro ao inativar o registro.",
        variant: "error",
      };
      showAlertMessage(notification);
    } finally {
      setInfosInativacao({
        dia: "",
        hora: "",
        inativador: "",
        motivo: "",
      })
      setLoadingInativacao(false);
    }
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
      variant: "",
    };
    setNotification(notification);
  };

  const openEnviarArquivo = () => {
    const fileInput = documentoFileInput && documentoFileInput.current;

    if (!fileInput instanceof HTMLElement) {
      return;
    }
    fileInput.value = "";
    fileInput.click();
  };

  const closeModalEnviarArquivo = () => {
    setOpenModalEnviarArquivo(false);
  };

  const closeModalVisualizar = () => {
    setOpenModalVisualizar(false);
  };

  const handleCloseModalInativar = () => {
    setShowModalInativar(false);
  };

  const handleChangeMotivoInativacao = (e) => {
    let motivo = e.target.value;

    setInfosInativacao({ ...infosInativacao, motivo: motivo });
  };

  const handleSelectDocumento = async () => {
    const filesArray = Object.values(documentoFileInput.current.files);
    const listaArquivos = await uploadArquivos(filesArray);
    
    setAnexoSelecionadoUpload(listaArquivos);
    setOpenModalEnviarArquivo(true);
  };

  const loadMore = () => {
    if (sujeitoAtencaoAnexoStore.loading) {
      return;
    }

    sujeitoAtencaoAnexoStore.pageNumber = sujeitoAtencaoAnexoStore.anexos.length > 0 ? sujeitoAtencaoAnexoStore.pageNumber + 1 : 0
    loadAnexos();
};

  return (
    <div className={classes.content}>
      <PageTitle title="Paciente - Arquivos"/>
      <Scroll
        loadMore={loadMore}
        hasMore={!sujeitoAtencaoAnexoStore.last}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainer}
      >
        { sujeitoAtencaoAnexoStore.anexos.length > 0 && (
            <Table
              className={classes.table}
              dados={sujeitoAtencaoAnexoStore.anexos}
              columns={columns}
              clickable={true}
              handleClick={handleItemClick}
              comOrdenacao
              ordenarTabela={sujeitoAtencaoAnexoStore.ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
            />
          )}
        {!sujeitoAtencaoAnexoStore.loading &&
          sujeitoAtencaoAnexoStore.anexos.length === 0 && (
            <MensagenListaVazia />
          )}
        {sujeitoAtencaoAnexoStore.loading && (
          <div className={classes.notFoundContainer}>
            <CircularProgress />
          </div>
        )}
        { !sujeitoAtencaoStore.isSujeitoAtencaoUnificado && <div className={classes.buttonsDownloadPrint}>
          <Button
            shape='circle'
            bgColor='#707C97'
            shadow
            onClick={openEnviarArquivo}
            disabled={sujeitoAtencaoAnexoStore.loading}
          >
            <img src={enviarArquivo} alt={"Enviar arquivo"} />
          </Button>
        </div>}
        {openModalEnviarArquivo && (
          <ModalAnexo
            open={openModalEnviarArquivo}
            anexoSelecionadoUpload={anexoSelecionadoUpload}
            closeModal={closeModalEnviarArquivo}
            reloadAnexos={reloadAnexos}
          />
        )}
        {openModalVisualizar && (
          <ModalVisualizacaoAnexo
            open={openModalVisualizar}
            closeModal={closeModalVisualizar}
            anexo={anexoSelecionado}
          />
        )}
        {showModalInativar && (
          <ModalInativarEntradaProntuario
            show={showModalInativar}
            onClose={handleCloseModalInativar}
            changeMotivo={handleChangeMotivoInativacao}
            inativar={handleInativarMotivo}
            motivo={infosInativacao.motivo}
            loadingInativacao={loadingInativacao}
            screen="Paciente - Arquivos"
          />
        )}
        <input
          ref={documentoFileInput}
          multiple
          type="file"
          className={classes.documentoFileInput}
          onChange={(e) => handleSelectDocumento(e)}
          style={{ display: "none" }}
        />
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </Scroll>
    </div>
  );
});

const SujeitoAtencaoAnexoWithStyles = withStyles(styles)(SujeitoAtencaoAnexo);
export default inject(
  "sujeitoAtencaoAnexoStore",
  "sujeitoAtencaoStore",
  "loginStore"
)(SujeitoAtencaoAnexoWithStyles);
