import React from "react";
import moment from "moment";

const AgendamentosPorSituacao = ({
  dadosRelatorio,
  filters,
  permissaoFinanceiro,
  totalAgendamentos,
  valores,
}) => {
  const {
    dataInicial,
    dataFinal,
    nome,
    convenio,
    profissionalSaude,
    sala,
    tipos,
    faturado,
  } = filters;
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: `#F2F2F2` }
    }
    return ""
  }

  const tiposDescricao = tipos.map(item => item.descricao)

  return (
    <>
      <div style={styles.titulo}>Relatório de Agendamentos por situação</div>
      <div style={styles.header}>
        <div style={styles.subTitulo}>
          {dataInicial && (
            <div>
              Data início:
              <span style={styles.subTituloSpan}>
                {moment(dataInicial).format("DD/MM/YYYY")}
              </span>
            </div>
          )}
          {dataFinal && (
            <div>
              Data fim:
              <span style={styles.subTituloSpan}>
                {moment(dataFinal).format("DD/MM/YYYY")}
              </span>
            </div>
          )}
          {nome && (
            <div>
              Pesquisado por:
              <span style={styles.subTituloSpan}>{nome}</span>
            </div>
          )}
          {convenio && (
            <div>
              Convênio:
              <span style={styles.subTituloSpan}>{convenio}</span>
            </div>
          )}
          {profissionalSaude && (
            <div>
              Profissional:
              <span style={styles.subTituloSpan}>{profissionalSaude}</span>
            </div>
          )}
          {sala && (
            <div>
              Sala:
              <span style={styles.subTituloSpan}>{sala}</span>
            </div>
          )}
          {tiposDescricao.length > 0 && (
            <div>
              Tipos:
              <span style={styles.subTituloSpan}>{tiposDescricao.toString()}</span>
            </div>
          )}
          <div>
            Faturado:
            <span style={styles.subTituloSpan}>{faturado ? "Sim" : "Não"}</span>
          </div>
        </div>
        <div style={styles.headerValores}>
          <div style={styles.headerValores}>
            {valores.map((item) => {
              const { titlePrint, label } = item;
              return (
                <div key={titlePrint} style={styles.margin}>
                  {titlePrint}:<span style={styles.subTituloSpan}>{label}</span>
                </div>
              );
            })}
          </div>
          <div style={styles.margin}>
            Total de agendamentos:
            <span style={styles.subTituloSpan}>{totalAgendamentos}</span>
          </div>
        </div>
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Nome</div>
        <div style={styles.column}>Data</div>
        <div style={styles.column}>Horário</div>
        <div style={styles.column}>Profissional</div>
        <div style={styles.column}>Convênio</div>
        <div style={styles.column}>Tipo</div>
        {permissaoFinanceiro && <div style={styles.column}>Valor</div>}
        <div style={styles.column}>Procedimento</div>
        {permissaoFinanceiro && <div style={styles.column}>Valor Proced.</div>}
      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          const { convenio, procedimento } = item;
          const valorTipo = (convenio?.valorConsulta || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
          const valorProcedimentos = (procedimento?.valorProcedimentos || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
          
          return (
            <div key={item.id} style={{ ...styles.containerColuna, ...columnColor(index) }}>
              <div style={styles.column}>{item.sujeitoAtencao?.nome}</div>
              <div style={styles.column}>
                {moment(item.data).format("DD/MM/YYYY")}
              </div>
              <div style={styles.column}>
                {item.horaInicio} - {item.horaFim}
              </div>
              <div style={styles.column}>{item.profissionalSaude?.nome}</div>
              <div style={styles.column}>{item.convenio?.descricao}</div>
              <div style={styles.column}>{item.tipo?.descricao}</div>
              {permissaoFinanceiro && (
                <div style={styles.column}>{valorTipo}</div>
              )}
              <div style={styles.column}>
             {procedimento.procedimentos}
              </div>
              {permissaoFinanceiro && (
                <div style={styles.column}>{valorProcedimentos}</div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  header: {
    display: "flex",
    flexDirection: "column",
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
    gap: "16px",
  },
  headerValores: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
  },
  margin: {
    marginRight: "16px",
  },
  subTituloSpan: {
    marginLeft: "8px",
  },
  dataInicio: {
    marginRight: "16px",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  column: {
    width: "100%",
  },
};

export default AgendamentosPorSituacao;
