import React from "react";
import {
  withStyles,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import {
  Search,
  Clear as ClearIcon
} from "@material-ui/icons"
import Header from "../../template/Header/Header";
import Profile from '../../template/Header/Profile';
import InputSearch from '../Input/Input';
import SelectFormStatus from '../Select/SelectFormStatus';
import { ReactSelect } from "../Select/SelectSearch";
import ProfissionalIcon from "../Icon/ProfissionalIcon";

const HeaderConfiguracoes = (props) => {
  const { 
    classes,
    handleClickSearch, 
    handleClickClear, 
    placeholderSearch, 
    valueSearch, 
    onChangeSearch, 
    showSearch,
    onKeypressSearch, 
    valueStatus, 
    handleChangeStatus, 
    showStatus,
    status, 
    children,
    filterProfissional,
  } = props;

  return(
    <Header className={classes.headerRoot} >
      <Grid
        container
        justify={"space-between"}
        alignItems={"center"}
        className={ classes.headerProfile}
      >
        <Grid item className={classes.header} >
          {children}
          {showSearch && <Grid item className={classes.gridSearch}>
            <InputSearch
                startAdornment={<InputAdornment position="start">
                    <IconButton className={classes.iconButtonSearch}
                                onClick={handleClickSearch}>
                        <Search/>
                    </IconButton>
                </InputAdornment>
                }
                endAdornment={<InputAdornment position="end">
                    <IconButton className={classes.iconButtonSearch}
                                onClick={handleClickClear}>
                        <ClearIcon/>
                    </IconButton>
                </InputAdornment>}
                classInputRoot={classes.inputRootSearch}
                classInput={classes.inputSearch}
                className={classes.search}
                name={"search"}
                placeholder={placeholderSearch}
                value={valueSearch || ""}
                onChange={onChangeSearch}
                onKeyPress={onKeypressSearch}
            />
          </Grid>}
          {showStatus && <Grid item className={classes.selectStatus}>
            <SelectFormStatus 
              labelOption={'Status'}
              placeholder="Status"
              classes={classes.select}
              name={'status'}
              value={valueStatus === null ? "none" : valueStatus}
              onChange={handleChangeStatus}
              elements={status || []}
            />
          </Grid>}
          {
            filterProfissional?.show && (
              <div className={classes.selectProfissional}>
                <Tooltip
                  title="Profissional"
                  placement="top"
                >
                  <ProfissionalIcon/>
                </Tooltip>
                <ReactSelect
                  className={classes.reactSelect}
                  placeholder={'Selecione'}
                  isClearable
                  value={filterProfissional.profissionalSaude}
                  options={filterProfissional.profissionaisSaude || []} 
                  getOptionLabel={(option) => option.nome}
                  getOptionValue={(option) => option.id}
                  onChange={filterProfissional.handleProfissionalSaudeChange}
                />
              </div>
            )
          }
        </Grid> 
        <Grid item >
            <Profile />
        </Grid>
      </Grid>
    </Header>

  )
};

const style = {
  headerRoot: {
    position: 'relative',
    height: 64,
    background: '#fff',
    boxShadow: '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
  },
  headerProfile:{
    padding: '0 16px',
    gridTemplateColumns: '90% 10%',
    display: 'grid',
  },
  header:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconButtonSearch:{
    width: 30,
    height: 30,
  },
  inputRootSearch:{
    border: 'none',
  },
  inputSearch:{
    fontFamily: "Poppins!important",
  },
  search:{
    width: '100%',
    height: 48,
    borderRadius: "8px !important",
    border: 'none !important',
    background: "#F2F2F2",
    justifyContent: 'center',
    fontWeight: 400,
  },
  selectStatus:{
    width: 200,
    marginLeft: 16,
  },
  labelButtonNovo: {
    marginLeft: '6px',
    whiteSpace: 'nowrap',
    "@media (max-width: 1600px)": {
        display: 'none',
    }
  },
  buttonNovo:{
    width: 180,
    height: 40,
    borderRadius: 87,
    boxShadow: 'none',
    background: '#F9BE73',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '0 14px',
    marginLeft: 24,
    minWidth: 152,
    "@media (max-width: 1600px)": {
        width: 40,
        minWidth: 40,
        borderRadius: '100%',
    }
  },
  gridSearch:{
    width: '100%',
    maxWidth: '582px'
  },
  selectProfissional:{
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    marginLeft: '16px',
  },
  reactSelect: {
    width: '100%',
    maxWidth: '190px',
    "&>div>div": {
      height: '40px',
      borderRadius: '8px',
    }
  },
}

export default withStyles(style)(HeaderConfiguracoes);
