export const styles = ({
  modalContent: {
    height: 'fit-content',
    width: '395px',
  },
  title: {
    display: 'flex',
    fontSize: '18px !important',
    color: '#26ACA9',
    fontWeight: '700',
    lineHeight: '27px',
    marginBottom: '8px'
  },
  selectInput: {
    width: 'calc( 100% - 20px )',
		background: '#F2F2F2',
		color: '#505050',
		borderRadius: '8px',
		height: '32px',
		padding: '4px 8px',
  },
  notchedOutline: {
    border: 'none'
  },
  paper: {
    borderRadius: '16px',
    padding: '32px',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    columnGap: '8px',
    marginTop: '8px'
  },
  item: {
    paddingRight: '0 !important',
    paddingBottom: '5px !important',
    marginBottom: '8px'
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    alignItems: 'center',
    marginBottom: '16px'
  },
  switchLabel: {
    fontSize: '12px',
    color: '#868686',
  },
  confirmContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '16px',
    textAlign: 'center'
  },
  confirmText: {
    color: '#505050',
    fontSize: '18px !important',
    fontWeight: '600'
  },
  successText: {
    color: '#505050',
    fontSize: '24px !important',
    fontWeight: '700'
  },
  descriptionText: {
    color: '#505050',
    fontSize: '16px !important',
    fontWeight: '400'
  }
});

export const buttonStyle = {
  height: '36px',
  flex: 1,
  borderRadius: '100px'
};

export const cancelButtonStyle = {
  color: '#505050',
  backgroundColor: 'white'
}

export const conflicButtonsStyle = {
  height: '36px',
  width: '256px',
  borderRadius: '100px'
}