import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import Dialog from '../../../../components/Dialog/Dialog';
import classNames from 'classnames';
import CircleCloseIcon from '../../../../components/Icon/CircleCloseIcon';
import ConflictsTable from './components/ConflictsTable';
import { updateAgendamentoTransferencia } from '../../../../services/TransferenciaAgendamentoService';
import moment from 'moment';
import {Button} from '../../../../components/ui/Buttons';

const styles = ({
  paper: {
    width: '1145px',
    height: '725px',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 24px',
    height: '100%',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '12px'
  },
  modalTitle: {
    fontSize: '24px !important',
    fontWeight: '600'
  },
  text: {
    color: '#505050',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%'
  }
});

const buttonStyle = {
  height: '36px',
  flex: 1,
  borderRadius: '100px',
  textTransform: 'none',
};

const ModalConflitos = ({ classes, open, onClose, agendamentos, openNotification }) => {
  const [selectedAgendamentos, setSelectedAgendamentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSelectedAgendamentos = (agendamentos) => {
    setSelectedAgendamentos(agendamentos);
  }

  const updateSelectedAgendamentos = async () => {
    if (selectedAgendamentos.length === 0) {
      return;
    }
    
    setIsLoading(true)
    try {
      selectedAgendamentos.forEach(async (agendamento, index) => {
        const {id, horaInicio, horaFim, data, profissionalSaude} = agendamento;
        await updateAgendamentoTransferencia({
          id,
          horaInicio: horaInicio.value,
          horaFim,
          data: moment(data).format('YYYY-MM-DD'),
          profissionalSaude: {
            id: profissionalSaude.id
          },
        });

        if (index === selectedAgendamentos.length - 1) {
          openNotification({
            message: 'Todos os agendamentos selecionados foram atualizados.',
            isOpen: true,
            variant: 'success'
          });
        }
      });
    } catch(error) {
      openNotification({
        message: error.message,
        isOpen: true,
        variant: 'error'
      });
    }finally{
      onFinishRequest();
    }
  }

  const onFinishRequest = () => {
    setIsLoading(false);
    onClose();
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      classes={{ paper: classes.paper }}
      maxWidth={false}
      disableEnforceFocus
    >
      <div className={classes.modalContainer}>
        <div>
          <div className={classes.header}>
            <span className={classNames(classes.modalTitle, classes.text)}>
              Reagende os horários
            </span>
            <CircleCloseIcon color='#FB7676' size={32} onClick={onClose} />
          </div>
            <span className={classes.text}>
              {agendamentos?.length || 0} agendamentos estão dando conflito na agenda do(a) profissional. Selecione uma outra data ou horário pra reagendar
            </span>
        </div>
        <ConflictsTable 
          agendamentos={agendamentos}
          selectedAgendamentos={selectedAgendamentos}
          setSelectedAgendamento={handleChangeSelectedAgendamentos}
          openNotification={openNotification}
        />
        <div className={classes.buttonsContainer}>
          <Button
            style={{ backgroundColor: '#FB7676', ...buttonStyle }}
            onClick={onClose}
          >
              Cancelar
          </Button>
          <Button
            style={buttonStyle}
            onClick={updateSelectedAgendamentos}
            disabled={isLoading}
          >
              Salvar Selecionados
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ModalConflitos);