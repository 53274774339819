import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const SendIcon = props => {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M12.8147 12.1973L5.28344 13.4525C5.10705 13.4819 4.95979 13.6033 4.89723 13.7708L2.29933 20.7282C2.05066 21.3676 2.72008 21.9777 3.33375 21.6709L21.3337 12.6709C21.8865 12.3945 21.8865 11.6056 21.3337 11.3292L3.33375 2.32921C2.72008 2.02238 2.05066 2.63242 2.29933 3.27187L4.89723 10.2293C4.95979 10.3968 5.10705 10.5181 5.28344 10.5475L12.8147 11.8028C12.9236 11.8209 12.9972 11.924 12.9791 12.0329C12.965 12.1172 12.899 12.1833 12.8147 12.1973Z" fill="currentColor"/>
    </svg> 
  );
};

export default SendIcon;
