import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const MoreIcon = props => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M9.01602 13.7091C9.03034 14.7889 8.16655 15.6759 7.08669 15.6903C6.00683 15.7046 5.11983 14.8408 5.10551 13.7609C5.0912 12.6811 5.95499 11.7941 7.03485 11.7797C8.1147 11.7654 9.00171 12.6292 9.01602 13.7091ZM15.2728 13.6261C15.2872 14.706 14.4234 15.593 13.3435 15.6073C12.2637 15.6216 11.3766 14.7578 11.3623 13.678C11.348 12.5981 12.2118 11.7111 13.2917 11.6968C14.3715 11.6825 15.2585 12.5463 15.2728 13.6261ZM19.6003 15.5244C20.6802 15.51 21.544 14.623 21.5297 13.5432C21.5154 12.4633 20.6283 11.5995 19.5485 11.6138C18.4686 11.6282 17.6048 12.5152 17.6192 13.595C17.6335 14.6749 18.5205 15.5387 19.6003 15.5244Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default MoreIcon
