import React from 'react';
import { withStyles } from '@material-ui/core';
import Score from './Score';

const styles = ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: '20px !important'
  }
});

const Header = ({ classes, totalReviews, score }) => {
  return (
    <div className={classes.header}>
      <span className={classes.headerTitle}><b>Opiniões dos pacientes</b>{`(${totalReviews || 0})`}</span>
      <Score score={score} />
    </div>
  );
};

export default withStyles(styles)(Header);
