import React from "react";
import { withStyles } from "@material-ui/core/styles";

import HeaderConfiguracoes from "../../../../components/Header/HeaderConfiguracoes";
import ListaConfiguracoes from "../../../../components/List/ListaConfiguracoes";
import Notification from "../../../../components/Notification";
import {
  findAllAnamneseModelo,
  saveAnamneseModelo,
  ativarDesativarAnamneseModelo,
  findByIdAnamneseModelo,
} from "../../../../services/AnamneseService";
import ModalModeloAnamnese from "./ModalModeloAnamnese";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../../utils/getPageTitle";
import { findProfissionalSaudeLogado, getUnidadeLogado } from '../../../../services/UsuarioService';
class ModelosAnamnese extends React.Component {
  state = {
    notification: {
      isOpen: false,
      message: "",
    },
    search: "",
    statusFiltro: null,
    anamneses: [],
    pageNumber: 0,
    last: false,
    isLoading: false,
    showModal: false,
    idAnamneseSelecionado: null,
    ordenarTabela: {
      sortField: "nome",
      sortDir: "ASC",
    },
    anamneseModeloSelecionado: null,
    profissionalSaudeId: null,
    unidadeId: null,
    anamneseIdBackup: null,
    unidadeIdBackup: null,
    anamnesePadrao:false,
  };

  async componentDidMount() {
    await this.loadModeloAnamneses()
  }

  loadModeloAnamneses = async () => {
    this.setState({ isLoading: true });
    const { search, pageNumber, ordenarTabela, anamneses, statusFiltro } = this.state;
    const { id: profissionalSaudeId } = await findProfissionalSaudeLogado();
    const { id: unidadeId } = await getUnidadeLogado();
    findAllAnamneseModelo(
      {
        searchDTO: {
          search,
          ativo: statusFiltro,
          unidadeId,
          profissionalSaudeId,
        },
            pageableDTO: {
              pageNumber: pageNumber,
              pageSize: 30,
              sortDir: ordenarTabela.sortDir,
              sortField: ordenarTabela.sortField,
            },
          }
    )
      .then((response) => {
        const { last, content } = response;
        this.setState({
          anamneses: [...anamneses, ...content],
          last: last,
          unidadeId,
          profissionalSaudeId
        });
      })
      .catch((error) => {
        const notification = {
          isOpen: true,
          message: "Erro ao carregar lista de anamnese",
        };
        this.showAlertMessage(notification);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleClickSearch = () => {
    this.limpaECarregaListaAnamneses();
  };

  handleClickClear = () => {
    this.setState({ search: "" });
    this.limpaECarregaListaAnamneses();
  };

  onChangeSearch = (e) => {
    let search = e.target.value;
    this.setState({ search });
  };

  onKeypressSearch = (e) => {
    if (e.key === "Enter" && this.state.search.length >= 3) {
      this.handleClickSearch();
    }
  };

  handleChangeStatus = (e) => {
    let value = e.target.value;
    this.setState({
      statusFiltro: value
    });

    this.limpaECarregaListaAnamneses();

  };

  handleClickNovaAnamnese = () => {
    this.setState({
      showModal: true,
      anamneseModeloSelecionado: null,
    });
  };

  showAlertMessage = (notification) => {
    this.setState({ notification: notification });

    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    this.setState({ notification: notification });
  };

  loadMore = () => {
    const { isLoading, anamneses, pageNumber } = this.state;
    if (isLoading) {
      return;
    }

    this.setState({ pageNumber: anamneses.length > 0 ? pageNumber + 1 : 0 });

    this.loadModeloAnamneses();
  };

  atualizarIdAnamneseSelecionado = async (idAnamnese) => {
    this.setState({ idAnamneseSelecionado: idAnamnese });
  };

  handleClickOrdenar = async (value) => {
    const { ordenarTabela } = this.state;

    const sortDir =
      ordenarTabela.sortField !== value
        ? "ASC"
        : ordenarTabela.sortDir === "ASC"
        ? "DESC"
        : "ASC";

    this.setState({
      ordenarTabela: {
        sortDir: sortDir,
        sortField: value
      }
    });
    this.limpaECarregaListaAnamneses();
  };

  handleEditar = async (id) => {
    const selecionado = await findByIdAnamneseModelo(id);
    const selecionadoComId = {
      ...selecionado,
      id,
    };
    this.setState({
      anamneseModeloSelecionado: selecionadoComId,
      idAnamneseSelecionado: id,
      showModal: true,
      anamnesePadrao: selecionado.padrao,
    });
  };

  handleClickRow = (id) => {
    this.handleEditar(id);
  };

  handleDuplicar = async () => {
    const { idAnamneseSelecionado } = this.state;

    await findByIdAnamneseModelo(idAnamneseSelecionado).then(
      (anamneseModeloSelecionado) => {
        const anamneseSemIdPerguntas = {
          ...anamneseModeloSelecionado,
          perguntas: anamneseModeloSelecionado.perguntas.map(item => {
            delete item.id
            return item
          })
        }

        saveAnamneseModelo(anamneseSemIdPerguntas).then(() => {
          this.limpaECarregaListaAnamneses();
        });
      }
    );
  };

  handleAtivar = () => {
    this.ativarDesativarAnamneseModelo();
  };

  handleInativar = () => {
    this.ativarDesativarAnamneseModelo();
  };

  ativarDesativarAnamneseModelo = () => {
    const { idAnamneseSelecionado } = this.state;
    ativarDesativarAnamneseModelo(idAnamneseSelecionado).then(() => {
      this.limpaECarregaListaAnamneses();
    });
  };

  limpaECarregaListaAnamneses = (search="") => {
    this.setState(
      {
        search,
        anamneses: [],
        pageNumber: 0,
      },
      () => this.loadModeloAnamneses()
    );
  };

  onChangeUnidade = async (unidadeId, profssionalSaudeId, initialLoad) => {
    const { 
      anamneseModeloSelecionado, 
      anamneseIdBackup, 
      unidadeIdBackup, 
      unidadeId: previousUnidadeId 
    } = this.state;

    const unidadeChanged = previousUnidadeId && previousUnidadeId !== unidadeId;

    if (initialLoad) {
      return this.setState({
        unidadeId,
        unidadeIdBackup: null,
        anamneseIdBackup: null,
      })
    }

    if (unidadeChanged && anamneseModeloSelecionado?.id) {
      const idModeloAux = anamneseModeloSelecionado.id;
      delete anamneseModeloSelecionado.id;

      return this.setState({
        anamneseModeloSelecionado: {
          ...anamneseModeloSelecionado,
          profissionaisSaude: [{ id: profssionalSaudeId }],
        },
        unidadeId,
        unidadeIdBackup: previousUnidadeId,
        anamneseIdBackup: idModeloAux,
      }, () => {
        this.limpaECarregaListaAnamneses();
      });
    }
    
    if (unidadeId === unidadeIdBackup) {
      const anamnese = await findByIdAnamneseModelo(anamneseIdBackup);
      return this.setState({
        unidadeId,
        unidadeIdBackup: null,
        anamneseIdBackup: null,
        anamneseModeloSelecionado: {
          ...anamnese,
          id: anamneseIdBackup,
        }
      }, () => {
        this.limpaECarregaListaAnamneses();
      });
    }

    this.setState({
      unidadeId,
      anamneseModeloSelecionado: {
        ...anamneseModeloSelecionado,
        profissionaisSaude: [{ id: profssionalSaudeId }],
      }
    }, () => {
      this.limpaECarregaListaAnamneses();
    });
  }

  handleChangeSwitchPadrao=()=>{
    const{anamnesePadrao}=this.state
    this.setState({anamnesePadrao: !anamnesePadrao})
  }

  render() {
    const { classes } = this.props;
    const {
      search,
      statusFiltro,
      isLoading,
      last,
      anamneses,
      idAnamneseSelecionado,
      ordenarTabela,
      notification,
      showModal,
      anamneseModeloSelecionado,
      anamnesePadrao
    } = this.state;

    const statusList = [
      { name: "Ativo", label: "Ativo", value: true },
      { name: "Inativo", label: "Inativo", value: false },
    ];

    return (
      <>
        <div>
          <PageTitle title={pageConfiguracoes("Modelos de anamnese")} />
          <HeaderConfiguracoes
            handleClickSearch={this.handleClickSearch}
            handleClickClear={this.handleClickClear}
            placeholderSearch="Pesquisa por anamnese"
            valueSearch={search}
            showSearch
            onChangeSearch={this.onChangeSearch}
            onKeypressSearch={this.onKeypressSearch}
            valueStatus={statusFiltro}
            handleChangeStatus={this.handleChangeStatus}
            showStatus
            status={statusList}
          />
          <div className={classes.contentList}>
            <ListaConfiguracoes
              nome
              lista={anamneses}
              lastScroll={last}
              loadMore={this.loadMore}
              isLoading={isLoading}
              idConvenioSelecionado={this.atualizarIdAnamneseSelecionado}
              handleClickOrdenar={(value) => this.handleClickOrdenar(value)}
              handleEditar={() => this.handleEditar(idAnamneseSelecionado)}
              handleClickRow={(id) => this.handleClickRow(id)}
              handleDuplicar={this.handleDuplicar}
              handleAtivar={this.handleAtivar}
              handleInativar={this.handleInativar}
              ordenarTabela={ordenarTabela}
              handleClickNovo={this.handleClickNovaAnamnese}
            />
          </div>
        </div>
        {showModal && (
          <ModalModeloAnamnese
            show={showModal}
            onClose={() => {
              this.setState({ showModal: false });
            }}
            onChangeUnidade={this.onChangeUnidade}
            anamneseModeloSelecionado={anamneseModeloSelecionado}
            loadModeloAnamneses={this.limpaECarregaListaAnamneses}
            anamneseIsPadrao={anamnesePadrao}
            handleChangeSwitchPadrao={this.handleChangeSwitchPadrao}
          />
        )}
        <Notification
          close={this.closeAlertMessage}
          reset={this.closeAlertMessage}
          isOpen={notification.isOpen}
          variant={"error"}
          message={notification.message}
        />
      </>
    );
  }
}

const styles = {
  contentList: {
    display: "flex",
    flexDirection: "row",
    color: "#fff",
    fontWeight: 600,
    alignItems: "center",
  },
};

export default withStyles(styles)(ModelosAnamnese);
