import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import { CircularProgress, Checkbox } from "@material-ui/core";
import Scroll from "../../../../../components/InfiniteScroll/Scroll";
import InputMaskCurrency from "../../../../../components/Input/InputMaskCurrency";
import { TextField } from "../../../../../components/TextField";

const ProcedimentoList = ({
  classes,
  listaModal,
  handleChangeCheckbox,
  handleChange,
  loadMore,
  lastScroll,
  loadingModal,
  hiddenValor,
  showQuantidade,
}) => {

  const handleKeyPress = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <div className={classes.content}>
      <Scroll
        loadMore={loadMore}
        hasMore={!lastScroll}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainerModelos}
      >
        <div className={classes.titles}>
          <div className={classes.title}> Selecione o procedimento </div>
          {!hiddenValor && <div className={classNames(classes.title, classes.titleValor)}>
            Valor
          </div>}
          {
            showQuantidade && <div className={classNames(classes.title, classes.titleValor)}>
              Quantidade
            </div>
          }
        </div>
        {listaModal.length > 0 &&
          listaModal.map((itemLista, index) => (
            <div className={classes.linha} key={index}>
              <div className={classes.checkboxDescricao}>
                <Checkbox
                  color={"primary"}
                  onChange={() => handleChangeCheckbox(itemLista, index)}
                  checked={itemLista.procedimentos.checked}
                />
                <div className={classes.procedimento}>
                  {itemLista.procedimentos.nome}
                </div>
              </div>
              { !hiddenValor && <InputMaskCurrency
                name={"valor"}
                value={itemLista.valor === 0 ? "" : itemLista.valor}
                className={classNames(
                  classes.inputValor,
                  !itemLista.procedimentos.checked &&
                    classes.inputValorDesabilitado
                )}
                onChange={(e) => handleChange(e, itemLista, index)}
                disabled={!itemLista.procedimentos.checked}
              />}
              {
                showQuantidade && <TextField
                  name={"quantidade"}
                  value={itemLista.quantidade}
                  type="number"
                  className={classNames(classes.spanValor, itemLista.procedimentos?.checked && classes.spanValorDesabilitado)}
                  onChange={(e) => handleChange(e, itemLista, index)}
                  disabled={!itemLista.procedimentos?.checked}
                  InputProps={{
                    inputProps: { min: 0, type: 'number' },
                    onKeyPress: handleKeyPress
                  }}
              />
              }
            </div>
          ))}
        {loadingModal && (
          <div className={classes.contentLoading}>
            <CircularProgress />
          </div>
        )}
      </Scroll>
    </div>
  );
};

const styles = {
  content: {
    height: "calc(100% - 105px)",
    overflow: "auto",
  },
  titles: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "#fff",
  },
  title: {
    fontSize: "10px",
    color: "#868686",
  },
  titleValor: {
    width: "100%",
    maxWidth: "104px",
  },
  procedimento: {
    color: "#868686",
  },
  linha: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
    height: "40px",
  },
  inputValor: {
    background: "#F2F2F2",
    padding: "4px 4px 4px 8px",
    width: "90px",
    height: "30px",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
  },
  checkboxDescricao: {
    display: "flex",
    alignItems: "center",
    "&>span": {
      color: "#868686",
    },
  },
  contentLoading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  scrollContainerModelos: {
    height: "100%",
  },
  inputValorDesabilitado: {
    color: "#E5E5E5",
  },
  spanValor: {
    background: "#F2F2F2",
    padding: "4px 4px 4px 8px",
    width: "100%",
    maxWidth: "90px",
    height: "30px",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    "& input": {
      fontFamily: "Poppins !important",
      fontSize: 14,
      paddingLeft: 0,
    },
    "& fieldset": {
      border: 'none',
    },
  },
};

export default withStyles(styles)(ProcedimentoList);
