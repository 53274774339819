import classNames from 'classnames'
import React from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core'

const InfoDataValidade = ({ classes, data }) => {
  const dataVencimentoMoment = moment(data.dataVencimento).add(5, 'days');
  const dataAtual = moment()
  const diff = dataVencimentoMoment.diff(dataAtual, 'days')
  const isToExpire = diff <= 1

  const isExpired = moment(data.dataVencimento).add(5, 'days').isBefore(moment())

  return (
    <>
      {isToExpire ? (
        <div
          className={classNames(
            classes.container,
            isExpired ? classes.expired : classes.notExpired
          )}
        >
          <div
            className={classNames(
              classes.dot,
              isExpired ? classes.dotExpired : classes.dotNotExpired
            )}
          />
          <span className={classes.text}>{`${isExpired ? 'Vencida' : 'Expira'} em ${moment(
            data.dataVencimento,
          ).add(5, 'days').format('DD/MM/YYYY')}`}</span>
        </div>
      ) : (
        <span className={classes.defaultDate}>{`${moment(data.dataVencimento).format(
          'DD/MM/YYYY'
        )}`}</span>
      )}
    </>
  )
}

const styles = {
  container: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    gap: '8px',
    padding: '4px 8px',
  },
  expired: {
    backgroundColor: '#FCEDEF',
    border: '1px solid rgba(251, 118, 118, 1)',
  },
  notExpired: {
    backgroundColor: '#E5F3FC',
    border: '1px solid rgba(115, 200, 249, 1)',
  },
  dotExpired: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#FB7676',
  },
  dotNotExpired: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#73C8F9',
  },
  text: {
    color: '#471414',
    fontWeight: '600',
    fontSize: 10,
  },
  defaultDate: {
    color: '#471414',
    fontWeight: '600',
    fontSize: 10,
    minWidth: '205px',
  },
}

export default withStyles(styles)(InfoDataValidade)
