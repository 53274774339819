import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const DoubleCheckIcon = (props) => {
  return (
    <svg 
      width="14" 
      height="14" 
      viewBox="0 0 14 14" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M6.768 8.02661L7.59166 8.85028L12.5299 3.91201L13.3549 4.73697L7.59166 10.5002L3.87933 6.78784L4.70429 5.96289L5.94375 7.20236L6.768 8.02661ZM6.76899 6.37712L9.658 3.48804L10.4807 4.3107L7.59166 7.19973L6.76899 6.37712ZM5.12007 9.67593L4.29581 10.5002L0.583496 6.78784L1.40845 5.96289L2.23271 6.7872L5.12007 9.67593Z" fill="currentColor"/>
    </svg>
  );
};

export default DoubleCheckIcon;
