import { inject } from "mobx-react";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import { columns } from "./Constants";
import string from "../../../utils/string";
import Table from "../../../components/Table/Table";
import CircleError from "../../../components/Icon/CircleError";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import styles from "../../../assets/jss/pages/categoriaFinanceiraStyle";
import { alteraStatusCategoriaFinanceira } from "../../../services/FinanceiroService";
import {
  defaultFilters,
  defaultModalConfirmacao,
} from "../../../stores/Financeiro/CategoriaFinanceira.store";

const Informacoes = observer(({ categoriaFinanceiraStore, classes }) => {
  const {
    loading,
    categoriaFinanceiraList,
    last,
    modalCategoriaFilho,
  } = categoriaFinanceiraStore;

  useEffect(() => {
    categoriaFinanceiraStore.filters = defaultFilters;
    categoriaFinanceiraStore.findAllCategoriaFinanceira(true);
  }, []);

  const handleClickStatus = async ({ id, ativo }) => {
    const action = ativo ? "inativar" : "ativar";
    const color = ativo ? "#FB7676" : "#219A97";

    categoriaFinanceiraStore.modalConfirmacao = {
      open: true,
      title: `Deseja ${action} essa categoria financeira?`,
      icon: <CircleError color={color} />,
      buttons: {
        labelPrimaryButton: "Cancelar",
        labelSecondButton: string.capitalize(action),
        handlePrimaryButton: () => {
          categoriaFinanceiraStore.modalConfirmacao = defaultModalConfirmacao;
        },
        handleSecondButton: async () => {
          await alteraStatus({ id, action });
          categoriaFinanceiraStore.modalConfirmacao = defaultModalConfirmacao;
        },
      },
    };
  };

  const alteraStatus = async ({ id, action }) => {
    try {
      await alteraStatusCategoriaFinanceira(id, action);
      categoriaFinanceiraStore.findAllCategoriaFinanceira(true);

      categoriaFinanceiraStore.openNotification(
        "Status alterado com sucesso",
        "success"
      );
    } catch {
      categoriaFinanceiraStore.openNotification(
        "Erro ao alterar o status.",
        "error"
      );
    }
  };


  const getDadosRow = (dados) => {
    if(dados.categoriaFilho) return;

    categoriaFinanceiraStore.edit(dados.id);
  };

  const handleAdicionarFilho = (categoria) => {
    categoriaFinanceiraStore.modalCategoriaFilho = {
      ...modalCategoriaFilho,
      open: true,
      objView: {
        id: null,
        ativo: true,
        nome: "",
        tipo: categoria.tipo,
        categoriaPai: categoria,
      },
    };
  };

  const handleCategoriaFilhoEdit = (categoria) => {
    const { id, nome, categoriaPai } = categoria;

    categoriaFinanceiraStore.modalCategoriaFilho = {
      ...modalCategoriaFilho,
      open: true,
      objView: {
        id,
        ativo: categoriaPai.ativo,
        nome,
        tipo: categoriaPai.tipo,
        categoriaPai: categoriaPai,
      },
    };
  };

  const handleCategoriaFilhoDelete = (categoria) => {
    handleClickStatus({ id: categoria.id, ativo: true });
  };

  return (
    <div className={classes.tableContainer}>
      {categoriaFinanceiraList.length > 0 && (
        <Scroll
          loadMore={() => categoriaFinanceiraStore.findAllCategoriaFinanceira()}
          hasMore={!loading && !last}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainer}
        >
          <Table
            dados={categoriaFinanceiraList}
            clickable={true}
            columns={columns({
              functions: {
                handleClickStatus: handleClickStatus,
                handleAdicionarFilho: handleAdicionarFilho,
                handleEdit: handleCategoriaFilhoEdit,
                handleDelete: handleCategoriaFilhoDelete,
              },
            })}
            getDadosRow={getDadosRow}
            tableRowStyle={{
              height: "26px",
              background: "#F2F2F2",
              borderTop: "1px solid #00000026",
            }}
          />
        </Scroll>
      )}

      {loading && (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: "8px" }}
        >
          <CircularProgress size={30} />
        </Grid>
      )}

      {!loading && categoriaFinanceiraList.length === 0 && (
        <div className={classes.notFoundContainer}>
          <h3>Nenhum item encontrado</h3>
        </div>
      )}
    </div>
  );
});

const stores = ["categoriaFinanceiraStore"];
const InformacoesWithStyles = withStyles(styles)(Informacoes);
export default inject(...stores)(InformacoesWithStyles);
