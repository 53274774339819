import Api from "../config/api";

export const findAllProfissionalSaude = (variables) => {
    const defaultSearchDTO = {
        pageSize: 10,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome",
    };

    return Api.post("", {
        query: `
            query ($searchDTO: SearchProfissionalSaudeDTOInput) {
                findAllProfissionalSaude(searchDTO: $searchDTO) {
                last
                totalElements
                numberOfElements
                content {
                        id
                        nome
                        prefixo {
                            prefixo
                        }
                    }
                }
            }`,
        variables: {
            searchDTO: {
                ...defaultSearchDTO,
                ...variables
            }
        }
    })
};

export const findAllProfissionalSaudeProcedimento = (variables) => {
    const defaultSearchDTO = {};

    const defaultPageableDTO = {
        pageSize: 30,
        pageNumber: 0,
    };
    return Api.post("", {
        query: `
            query ($searchDTO: SearchProfissionalSaudeDTOInput, $pageableDTO: PageableDTOInput) {
                findAllProfissionalSaudeProcedimento(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
                last
                totalElements
                numberOfElements
                content {
                        id
                        nome
                    }
                }
            }`,
        variables: {
            searchDTO: {
                ...defaultSearchDTO,
                ...variables?.searchDTO
            },
            pageableDTO: {
                ...defaultPageableDTO,
                ...variables?.pageableDTO
            }
        }
    })
}

export const findProfissionalSaudeByUnidadeComAgenda = (variables) => {
    return Api.post('', {
        query: `
            query($unidadeId: Long, $search: String, $pageableDTO: PageableDTOInput) {
                findProfissionalSaudeByUnidadeComAgenda(unidadeId: $unidadeId, search: $search, pageableDTO: $pageableDTO) {
                    id
                    nome
                    prefixo {
                        prefixo
                    }
                }
            }
        `,
        variables
    });
}

export const findByIdProfissionalSaude = async (profissionalSaudeId) => {
    const response = await Api.post('', {
        query: `
            query($id: Long) {
                findByIdProfissionalSaude(id: $id) {
                    id
                    nome
                    cpf
                    email
                    unidadeProfissionalSaudeGroups {
                        group {
                            id
                            nome
                        }
                    }
                    dataNascimento
                    tipo
                }
            }
        `,
        variables: {
            id: profissionalSaudeId
        }
    });

    if (response.data.errors) {
        throw response.data.errors[0];
    }
    return response.data.data.findByIdProfissionalSaude
};

export const findByUnidadeComAgendaEspecialidade = async({unidadeId, especialidadeMedica}) => {
        const response = await Api.post("", {
            query: `
                query ($unidadeId: Long, $pageableDTO: PageableDTOInput,$especialidadeMedica: Long) {
                    findProfissionalSaudeByUnidadeComAgenda(unidadeId: $unidadeId, pageableDTO: $pageableDTO, especialidadeMedica: $especialidadeMedica) {
                        id
                        nome
                    }
                }
                `, 				
            variables: {
                unidadeId,
                pageableDTO: {
                    sortDir: "ASC",
                    sortField: "nome",
                },
                ...(especialidadeMedica && {especialidadeMedica}),
            }
        });

        if (response.data.data.errors) {
            throw data.errors[0];
        }

        return response.data.data.findProfissionalSaudeByUnidadeComAgenda;
}

export const findAllProfissionalSaudeComAgenda = async (variables) => {
    const response = await Api.post('', {
      query: `
        query($unidadeId: Long, $search: String, $pageableDTO: PageableDTOInput, $ativo: Boolean) {
            findProfissionalSaudeByUnidadeComAgendaPage(unidadeId: $unidadeId, search: $search, pageableDTO: $pageableDTO, ativo: $ativo) {
                last
                content {
                    id
                    nome
                }
            }
        }`,
      variables: variables
    })
  
    if(response.data.errors) {
      throw Error(response.data.errors[0].message)
    }
    return response.data.data.findProfissionalSaudeByUnidadeComAgendaPage
  }