import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";

const ActionButton = (props) => {
  const {theme, color, icon, disabled} = props;  
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const Icon = icon;

  return (
    <Zoom
      in={true}
      timeout={transitionDuration}
      unmountOnExit
    >
      <Fab 
        onClick={props.onClick} 
        disabled={disabled}
        style={{backgroundColor: color ? color : '', opacity: disabled ? 0.5 : 1}} 
        color={'primary'}
        size='small'
      >
          {<Icon />}
      </Fab>
    </Zoom>
  );
}

export default withStyles({}, {withTheme: true})(ActionButton);