import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress, Dialog } from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import styles from "./styles";
import ModalHeader from "./ModalHeader";
import Parcelamento from "./Parcelamento";
import Notification from "../../../components/Notification";
import ContentModalLancamento from "./ContentModalLancamento";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import { tiposRepeticao } from "../../../stores/Financeiro/Extrato.store";
import { findAllProcedimentosAtendimento } from "../../../services/ProcedimentoService";
import ReciboExtratoPdfDocument from "../../../template/pdf/financeiro/ReciboExtratoContent";

const LancamentoFinanceiro = observer((props) => {
  const {
    extratoStore,
    onClose,
    open,
    classes,
    auditoriaStore,
  } = props;
  const { titulo, tabs, modalState } = extratoStore;

  const [tabSelected, setTabSelected] = useState(null);

  const [procedimentos, setProcedimentos] = useState([]);

  useEffect(() => {
    extratoStore.titulo.convenio && !extratoStore.titulo?.valor &&
      extratoStore.selectConvenio(extratoStore.titulo.convenio);
    listarProcedimentosNaoSelecionado();
    extratoStore.getTabs();

    extratoStore.tabSelectedParcelamento = tiposRepeticao.indexOf(modalState.tipoRepeticao);

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      auditoria: "o lançamento",
      auditoriaTela: "lancamento",
    }
  }, []);

  useEffect(() => {
    extratoStore.modalState.tipoRepeticao = tiposRepeticao[extratoStore.tabSelectedParcelamento];
  }, [modalState.tipoRepeticao]);

  useEffect(() => {
    if(!tabs) return;
    setTabSelected(tabs.INFORMACOES);
  }, [titulo.tipo, tabs]);

  const pageTitle = () => {
    let tipo = "";
    let acao = "";

    if (titulo.tipo) {
      tipo = (titulo.tipo || "").toLowerCase();
      acao = titulo?.id ? "Editar" : "Nova";
    }

    return `Financeiro - ${acao} ${tipo}`
  };

  const handleClose = () => {
    extratoStore.closeModal();
    extratoStore.clearErrors();
    extratoStore.resetNotification();

    if (typeof onClose === "function") {
      onClose();
      auditoriaStore.reset();
    }
  };

  const listarProcedimentosNaoSelecionado = async () => {
    const procedimentos = await findAllProcedimentosAtendimento();
    let procedimentosNaoSelecionados = [];

    procedimentos.content.forEach((procedimento) => {
      const verificaProcedimentoSelecionado = extratoStore.titulo?.procedimentos && extratoStore.titulo.procedimentos.some(
        (item) => {
          return item.id === procedimento.id;
        }
      );

      !verificaProcedimentoSelecionado &&
        procedimentosNaoSelecionados.push(procedimento);
    });

    setProcedimentos(procedimentosNaoSelecionados);
  };

  return (
    <>
      <PageTitle title={pageTitle()} />
      <Dialog
        classes={{
          scrollPaper: classes.scrollPaper,
          paper: classes.fullSizePaper,
        }}
        open={open}
        fullWidth
      >
        <div className={classes.contentLancamento}>
          <ModalHeader/>
          <DialogContent className={classes.dialogContent}>
            {extratoStore.opening ? (
              <div className={classes.notFoundContainer}>
                <CircularProgress />
              </div>
            ) : (
              <ContentModalLancamento
                setTabSelected={setTabSelected}
                tabSelected={tabSelected}
                setProcedimentos={setProcedimentos}
                procedimentos={procedimentos}
              />
            )}
          </DialogContent>
        </div>
        <Parcelamento 
          handleClose={handleClose}
        /> 
        { extratoStore.isPrintMustacheModal && (
          <ImpressaoHtml
            isPrintMustache={extratoStore.isPrintMustacheModal}
            sujeitoAtencaoId={titulo?.sujeitoAtencao?.id}
            handlePrintMustache={() => extratoStore.isPrintMustacheModal=false}
            htmlStringComponent={
              <ReciboExtratoPdfDocument dadosTitulo={extratoStore.dadosTituloPrint} />
            }
            dadosCustom={extratoStore.dadosParaImpressaoRecibo}
          />
        )}
        <Notification
          close={(event, reason) =>
            extratoStore.closeNotification(event, reason)
          }
          reset={() => extratoStore.resetNotification()}
          isOpen={extratoStore.notification.isOpen}
          variant={extratoStore.notification.variant}
          message={extratoStore.notification.message}
        />
      </Dialog>
    </>
  );
});

const LancamentoFinanceiroWithStyles = withStyles(styles)(LancamentoFinanceiro);
export default inject("extratoStore", "auditoriaStore")(
  LancamentoFinanceiroWithStyles
);
