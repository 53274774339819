import { isSafari } from "../../../utils/Navigator";
import { isIOS } from "../../../utils/OS";

let documentContainerHeight = "27cm";

if (isSafari()) {
  documentContainerHeight = "25cm";
}

if (isIOS) {
  documentContainerHeight = "22cm";
}

const prontuarioPreviewDocumentoStyle = (theme) => ({
    container: {
        position: "absolute",
        bottom: "0px",
        right: "0px",
        left: "0px",
        top: "0px",
        background: "rgb(238, 238, 238)",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        zIndex: 2,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        background: "rgb(0, 181, 178)",
        alignItems: "center",
        padding: "10px 15px",
        boxSizing: "border-box",
        justifyContent: 'space-between',
    },
    headerButtonIcon: {
        color: '#FFF'
    },
    downloadAssinadoLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeButton: {
        color: "rgb(255, 255, 255)",
        fontWeight: "400",
        fontSize: "18px !important",
        cursor: "pointer",
        margin: '0 10px'
    },
    headerTitle: {
        color: "rgb(255, 255, 255)",
        margin: "3px 20px 0px",
        fontWeight: "400"
    },
    documentIcon: {
        maxWidth: "15%",
        [theme.breakpoints.up('xl')]: {
            maxWidth: "10%",
        },
    },
    imageContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexWrap: "wrap",
        padding: 15,
        boxSizing: 'border-box',
        position: 'relative',
        overflow: 'hidden',
    },
    image: {
        maxWidth: "100%",
    },
    form: {
        width: "90%",
        marginBottom: 10
    },
    inputAdornmentStart: {
        width: 20,
        height: 20
    },
    inputMessageSujeitoAtencaoRoot: {
        borderRadius: "30px"
    },
    inputMessageSujeito: {
        padding: '15px 0',
        maxHeight: "90px"
    },
    buttonSendInput: {
        width: "30px",
        height: "30px",
        minHeight: "1px",
        backgroundColor: "transparent !important",
        boxShadow: "none !important"
    },
    buttonSendIcon: {
        marginLeft: 3,
    },
    documentContent: {
        width: '100%',
    },
    printContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflow: "auto",
        flexWrap: "wrap",
        padding: 15,
        boxSizing: 'border-box',
        position: 'relative',

        "@media print": {
            width: isSafari() || isIOS ? 'auto' : "100%",
            height: isSafari() || isIOS ? 'auto' : "100%",
            overflow: "hidden",
        }
    },
    documentContainer: {
        width: "21cm",
        height: documentContainerHeight,
        background: "#fff",
        position: "relative",
        transformOrigin: "top center",
        padding: "10px",
        border: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxSizing: "border-box",
    },
    documentHeader: {
        display: "flex",
        float: "right"
    },
    documentFooter: {
        paddingRight: "5cm"
    },
    pageIndexesContainer: {
        display: "flex",
        flexDirection: "row",
        justifySelf: "center",
        marginRight: "75px",
        color: "#FFF",
        fontWeight: "bold"
    },
    buttonLeftRight: {
        borderRadius: '100px',
        height: '24px',
        width: '24px',
        display: 'flex',
        cursor: 'pointer',
        color: '#565656',
        "&:hover": {
            background: '#F5F5F5',
        }
    },
    hiddenButtonLeftRight: {
        width: '24px',
    },
});

export default prontuarioPreviewDocumentoStyle;
