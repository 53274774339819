import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const styles = ({
  labelArea: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    borderRadius: '8px',
    padding: '10px 9px',
  },
  withPadding: {
    padding: '4px 8px',
  },
  labelText: {
    fontWeight: '600 !important',
    color: '#505050',
    fontSize: '14px !important'
  },
  circleIcon: {
    heigth: '12px',
    width: '12px'
  }
});

const StatusWithCircleLabel = ({ classes, withPadding, areaStyle, item }) => {
  return (
    <div 
      className={withPadding ? classNames([classes.labelArea, classes.withPadding]) : classes.labelArea} 
      style={{ backgroundColor: item.color, ...areaStyle }}
    > 
      <FiberManualRecordIcon className={classes.circleIcon} style={{color: item.iconColor}} />
      <span className={classes.labelText}>
        {item.label}
      </span>
    </div>
  )
};

export default withStyles(styles)(StatusWithCircleLabel);
