import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const SuccessIcon = (props) => {
  return (
    <svg
      width='27'
      height='22'
      viewBox='0 0 27 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <rect
        x='0.735352'
        y='12.1709'
        width='2.94118'
        height='12.5'
        rx='1.47059'
        transform='rotate(-42.3081 0.735352 12.1709)'
        fill='currentColor'
      />
      <rect
        x='0.735352'
        y='12.1709'
        width='2.94118'
        height='12.5'
        rx='1.47059'
        transform='rotate(-42.3081 0.735352 12.1709)'
        fill='currentColor'
      />
      <rect
        width='2.94118'
        height='25.3258'
        rx='1.47059'
        transform='matrix(-0.739536 -0.673117 -0.673117 0.739536 26.5752 2.68555)'
        fill='currentColor'
      />
      <rect
        width='2.94118'
        height='25.3258'
        rx='1.47059'
        transform='matrix(-0.739536 -0.673117 -0.673117 0.739536 26.5752 2.68555)'
        fill='currentColor'
      />
    </svg>
  )
}
export default SuccessIcon
