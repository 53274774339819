import React, { useReducer } from "react";
import { inject } from "mobx-react";
import string from "../../../utils/string";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import AnexoIcon from "../../../components/Icon/Anexo";
import TrashIcon from "../../../components/Icon/Trash";
import ButtonLong from "../../../components/Button/ButtonLong";
import {
  anexarSolicitacoesPaciente,
  descartarSolicitacoesPaciente,
} from "../../../services/SolicitacoesPacientesService";
import {
  createAnamnese,
  descartarAnamnese,
} from "../../../services/SolicitacoesProntuarioService";
import { getNewAnamnese } from "../../Atendimento/ModalSolicitacoes/utils";

const FooterSolicitacoesPacientes = observer(
  ({ classes, solicitacoesPacientesStore }) => {
    const {
      propsModalSolicitacoesPacientes,
      anamnesePerguntasAlteradas,
    } = solicitacoesPacientesStore;
    const { dados, edit } = propsModalSolicitacoesPacientes;
    const [isLoading, toggleLoading] = useReducer((state) => !state, false);

    const isTipoAnamnese = dados.arquivoTipo === "ANAMNESE";

    const criarAnamnese = async() => {
      const variables = {
        agendamentoId: dados.agendamento.id,
        anamnesePaciente: {
          id: dados.id,
          nome: dados.nome,
          perguntas: getNewAnamnese(dados, anamnesePerguntasAlteradas),
        },
      };

      await createAnamnese(variables);
    };

    const handleAnexar = async () => {
      await anexarSolicitacoesPaciente(
        {
          pacienteAnexoId: dados.id,
          agendamentoId: dados.agendamento.id,
        },
        string.capitalize(dados.arquivoTipo)
      );
    };

    const handleClick = async (acao) => {
      try {
        toggleLoading();
        if (acao === "anexar") {
          isTipoAnamnese ? await criarAnamnese() : await handleAnexar();

        } else {
          isTipoAnamnese
            ? await descartarAnamnese({ anamneseId: dados.id })
            : await descartarSolicitacoesPaciente(dados.id);
        }
        solicitacoesPacientesStore.loadSolicitacaoPaciente(true);
        solicitacoesPacientesStore.propsModalSolicitacoesPacientes = {
          ...propsModalSolicitacoesPacientes,
          open: false,
        };

        toggleLoading();
      } catch (e) {
        const notification = {
          isOpen: true,
          message: `Erro ao ${acao} a solicitação.`,
        };
        solicitacoesPacientesStore.showAlertMessage(notification);
        toggleLoading();
      }
    };

    const handleCancel = () => {
      solicitacoesPacientesStore.propsModalSolicitacoesPacientes.edit = false;
      solicitacoesPacientesStore.anamnesePerguntasAlteradas = {};
    };

    const cannotAttach = !dados?.agendamento?.sujeitoAtencao?.id;

    return (
      <div className={classes.contentFooter}>
        {edit ? (
          <div className={classes.buttonsEdit}>
            <ButtonLong
              colorCustom="red"
              onClick={() => handleCancel()}
              className={classes.buttonFooter}
              noShadow
            >
              Cancelar
            </ButtonLong>
            <ButtonLong
              colorCustom="yellow"
              onClick={() => handleClick("anexar")}
              className={classes.buttonFooter}
              noShadow
            >
              Salvar e anexar ao prontuário
            </ButtonLong>
          </div>
        ) : (
          <>
            {dados.descartado ? (
              <>
                <ButtonLong
                  colorCustom="green"
                  onClick={() => handleClick("anexar")}
                  className={classes.buttonFooter}
                  noShadow
                  loading={isLoading}
                  
                >
                  <AnexoIcon />
                  Adicionar ao prontuário
                </ButtonLong>
              </>
            ) : (
              <div className={classes.buttonsEmAberto}>
                <ButtonLong
                  colorCustom="green"
                  onClick={() => handleClick("anexar")}
                  disabled={isLoading || cannotAttach}
                  className={classes.buttonFooter}
                  noShadow
                >
                  <AnexoIcon />
                  Prontuário
                </ButtonLong>
                <ButtonLong
                  colorCustom="red"
                  onClick={() => handleClick("descartar")}
                  disabled={isLoading}
                  className={classes.buttonFooter}
                  noShadow
                >
                  <TrashIcon />
                  Descartar
                </ButtonLong>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

const styles = {
  buttonFooter: {
    "& span": {
      display: "flex",
      gap: "8px",
    },
  },
  contentFooter: {
    padding: "14px 42px",
    background: "#FFFFFF",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
  },
  buttonsEmAberto: {
    display: "flex",
    gap: "10px",
  },
  buttonsEdit: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    gap: "10px",
  }
};

const FooterSolicitacoesPacientesWithStyles = withStyles(styles)(
  FooterSolicitacoesPacientes
);
export default inject("solicitacoesPacientesStore")(
  FooterSolicitacoesPacientesWithStyles
);
