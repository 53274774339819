import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const CakeIcon = props => {
  return (
    <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <g clip-path='url(#clip0_9163_13162)'>
        <path
          d='M3.85715 2.00005C4.18222 2.00005 4.44286 1.74291 4.44286 1.42862C4.44286 1.32005 4.41358 1.22005 4.35794 1.13434L3.85715 0.285767L3.35636 1.13434C3.30072 1.22005 3.27144 1.32005 3.27144 1.42862C3.27144 1.74291 3.53501 2.00005 3.85715 2.00005ZM5.61429 2.85719H4.15001V2.28577H3.56429V2.85719H2.10001C1.61386 2.85719 1.22144 3.24005 1.22144 3.71434V6.28577C1.22144 6.44291 1.35322 6.57148 1.51429 6.57148H6.20001C6.36108 6.57148 6.49286 6.44291 6.49286 6.28577V3.71434C6.49286 3.24005 6.10044 2.85719 5.61429 2.85719ZM5.90715 6.00005H1.80715V5.14291C2.07072 5.14005 2.32258 5.03719 2.51001 4.85434L2.82922 4.54862L3.14258 4.85434C3.52622 5.22862 4.19394 5.22577 4.57465 4.85434L4.89094 4.54862L5.20429 4.85434C5.39172 5.03719 5.64358 5.14005 5.90715 5.14291V6.00005ZM5.90715 4.71434C5.75779 4.71148 5.61722 4.6572 5.51179 4.55148L4.88801 3.94291L4.26129 4.55148C4.04458 4.76291 3.66679 4.76291 3.45008 4.55148L2.82629 3.94291L2.19958 4.55148C2.09708 4.65434 1.95651 4.71148 1.80715 4.71434V3.71434C1.80715 3.55719 1.93894 3.42862 2.10001 3.42862H5.61429C5.77536 3.42862 5.90715 3.55719 5.90715 3.71434V4.71434Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_9163_13162'>
          <rect
            width='7.02857'
            height='6.85714'
            fill='currentColor'
            transform='translate(0.342896 0.285706)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CakeIcon
