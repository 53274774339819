import { NestorApi } from "../config/api";

export const uploadFileWithNestor = (file) => {
  if (file) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      return NestorApi.post('/resource/AppHealth', formData);
    } catch (error) {
      throw error;
    }
  }
};