import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { styles, conflicButtonsStyle } from '../styles';
import CircleError from '../../../../../components/Icon/CircleError';
import { updateAgendamentoSituacao } from '../../../../../services/AgendamentoService';
import {Button} from '../../../../../components/ui/Buttons';

const ConflictStep = ({
  handleOpenConflictModal,
  conflictedAgendamentos,
  openNotification,
  classes,
  onCloseModal
}) => {

  const[isLoading, setIsLoading]=useState(false)

  const handleCancelConflictedAgendamentos = async () => {
    setIsLoading(true)
    try {
      let allSuccess = [];
      conflictedAgendamentos.forEach(async (agendamento) => {
        const updatedAgendamentoId = await updateAgendamentoSituacao(agendamento.id, 'CANCELADO');
        allSuccess.push(updatedAgendamentoId);
      });
      if (allSuccess.length === conflictedAgendamentos.length) {
        openNotification({
          message: 'Todos os agendamentos foram cancelados',
          isOpen: true,
          variant: 'sucess',
        });
      }
      onCloseModal();
    } catch (error) {
      openNotification({
        message: 'Todos os agendamentos foram cancelados',
        isOpen: true,
        variant: 'error',
      });
      onCloseModal();
    }finally{
      setIsLoading(false)
    }
  };

  return (
    <div className={classes.confirmContainer}>
      <CircleError color="#FB7676" size={50} />
      <span className={classes.confirmText}>
        {conflictedAgendamentos?.length} agendamento(s) geraram conflito de agenda
      </span>
      <span className={classes.descriptionText}>
        O que você deseja fazer?
      </span>
      <Button
        style={{...conflicButtonsStyle}}
        onClick={handleOpenConflictModal}
      >
        Reagendar horários
      </Button>
      <Button 
        style={{...conflicButtonsStyle, backgroundColor: '#FB7676'}}
        onClick={handleCancelConflictedAgendamentos}
        disabled={isLoading}
      >
        Cancelar {conflictedAgendamentos?.length} Itens
      </Button>
    </div>
  );
};

export default withStyles(styles)(ConflictStep);