import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {Tooltip} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles/index";
import Moment from 'moment';
import { getStatusAtendimento, getStatusRowAtendimento } from '../../../utils';
import string from '../../../utils/string';
import { AniversarioYellowIcon, AniversarioGrayIcon } from '../../../assets/img/svg';


const EventDay = ({ classes, agendamento, selectedDate, selectAgendamento, tipo }) => {
    const getProcedimentos = () => {
        let quantidade;
        const quantidadeProcedimentos = agendamento.procedimentos?.length;

        if (quantidadeProcedimentos) {
            quantidade = quantidadeProcedimentos > 1 ? `${quantidadeProcedimentos} procedimentos` : agendamento.procedimentos[0]?.procedimento?.nome
        };

        return (
            <>
                {
                    quantidadeProcedimentos > 1 ?                    
                        <Tooltip
                            title={nomeProcedimentos()}
                            placement="top-start"
                            classes={{ tooltip: classes.tooltip }} 
                        >
                            <div>
                                {quantidade}
                            </div>
                        </Tooltip>
                    :
                    <> { quantidade || '' } </>
                }
            </>
        )
    };

    const nomeProcedimentos = () => {
        
        const nomes = agendamento.procedimentos && agendamento.procedimentos?.map(item => {
            return item.procedimento?.nome;
        });

        return nomes && nomes.join(', ');
    };


    const [ isAniversarioProximo, setIsAniversarioProximo ] = useState(false);
    const [ isAniversariante, setIsAniversariante ] = useState(false);

    useEffect(() => {
        agendamento.dataNascimento ? verificaAniversario() : setIsAniversarioProximo(false)
    }, [agendamento.dataNascimento]);

    const verificaAniversario = () => {
        const {isAniversarioProximo, isAniversariante} = string.verificaAniversarioProximoADataAtual(agendamento.dataNascimento, 5);
        
        setIsAniversarioProximo(isAniversarioProximo);
        setIsAniversariante(isAniversariante);
    };

    const horarioAgendamento = () => {
        const tipoAgendamento = tipo === "PROFISSIONAL" ? agendamento?.tipo?.descricao || '' : getProcedimentos();
        return (
            <>
                <div className={classes.container}>
                    <div
                        className={classNames(classes.horarios, { status: agendamento.situacao })}
                        style={{ backgroundColor: getStatusAtendimento(agendamento.situacao).color }}>{agendamento.horaInicio || ''}</div>
                    <div className={classNames(classes.nome, {[classes.nomeAniversario]: isAniversarioProximo})} style={{ backgroundColor: getStatusRowAtendimento(agendamento.situacao) }}>
                        { isAniversarioProximo && 
                            <Tooltip
                                title={Moment(agendamento.dataNascimento).format("DD/MM/YYYY")}
                                placement="top"
                            >
                                <img 
                                    src={isAniversariante ? AniversarioYellowIcon : AniversarioGrayIcon} 
                                    alt={"Ícone aniversário"}
                                    className={classes.iconAniversario}
                                />
                            </Tooltip>}
                        
                        {agendamento?.sujeitoAtencao?.nome || agendamento.nome || ''} 
                    </div>     
                    <div className={classes.descricao} style={{ backgroundColor: getStatusRowAtendimento(agendamento.situacao) }}>
                        {
                            tipo === "PROFISSIONAL" && agendamento?.tipo?.destaque ?
                            <div className={classes.destaqueTipo}>
                                {tipoAgendamento}
                            </div>
                            :
                            tipoAgendamento
                        }
                    </div>
                </div>

            </>
        )
    }

    const horarioBloqueado = () => {
        return (
            <>
                <div className={classes.containerBloqueado}>
                    <div className={classes.horarios} style={{ backgroundColor: getStatusAtendimento(agendamento.situacao).color }}>{agendamento.horaInicio || ''}</div>
                    <div className={classes.nome} style={{ backgroundColor: getStatusRowAtendimento(agendamento.situacao) }}>
                        <div className={classes.bloqueado}>
                            {agendamento.nome && (
                                <div>
                                    <span className={classes.motivoBloqueio}>
                                        Motivo do bloqueio:
                                    </span>
                                    {agendamento.nome}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const handleSelectEvent = (event) => {
        event.stopPropagation();

        let horarioSelecionado = {
            start: Moment(
                `${selectedDate.format("YYYY-MM-DD")} ${agendamento.horaInicio}`,
                "YYYY-MM-DD HH:mm"
            ),
            end: Moment(
                `${selectedDate.format("YYYY-MM-DD")} ${agendamento.horaFim}`,
                "YYYY-MM-DD HH:mm"
            )
        }

        if (!agendamento.situacao) {
            selectAgendamento(horarioSelecionado);
        } else {
            selectAgendamento({
                ...horarioSelecionado,
                id: agendamento.id,
                status: agendamento.situacao
            });
        }
    };

    return (
        <div
            className={classes.eventos}
            onMouseDown={(e) => handleSelectEvent(e)}
        >
            {agendamento.situacao === 'BLOQUEADO' ? horarioBloqueado() : horarioAgendamento()}
        </div>
    )
}

const styles = theme => ({
    eventos: {
        '&:hover': {
            cursor: 'pointer'
        },
    },
    container: {
        display: 'grid',
        gridTemplateColumns: "70px 2fr 1fr",
        gridTemplateRows: '40px',
        alignItems: 'stretch',
        borderBottom: '1px solid #b5b5b5'
    },
    containerBloqueado: {
        display: 'grid',
        gridTemplateColumns: "70px 1fr",
        gridTemplateRows: '40px',
        alignItems: 'stretch',
        borderBottom: '1px solid #b5b5b5'
    },
    horarios: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        color: '#bdbdbd',
        fontWeight: 'bold !important',
        fontSize: '14px!important',
        fontFamily: "Poppins",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.status ': {
            color: '#fff',
            fontWeight: '500 !important',
        }
    },
    nome: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '24px',
        color: '#868686!important',
        fontSize: '14px!important',
        fontWeight: 500,
        fontFamily: "Poppins",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    descricao: {
        display: 'flex',
        alignItems: 'center',
        color: '#868686',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "Poppins",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    bloqueado: {
        backgroundColor: '#FB7676',
        height: '1px',
        width: '95%',
        borderRadius: '100px',
        '& div': {
            color: "#868686",
            position: "relative",
            top: "-10px",
        }
    },
    motivoBloqueio: {
        fontWeight: "bold",
        marginRight: "5px"
    },
    nomeAniversario: {
        paddingLeft: '4px',
    },
    iconAniversario: {
        marginRight: '4px',
        marginBottom: '3px',
    },
    tooltip: {
        fontFamily: "Poppins",
        backgroundColor: "#fff",
        color: "#868686",
        fontSize: "14px",
        height: 'auto',
        width: '100%',
        boxShadow: theme.shadows[1],
    },
    destaqueTipo: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        background: '#000',
        color: '#fff',
        padding: '6px',
    },
});

export default withStyles(styles)(EventDay);