import React from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core";
import { buildUrlFotoPerfil } from "../../../../../../config/config";
import GracefulImage from "../../../../../../components/Image/GracefulImage";
const profileDefault = require("../../../../../../assets/img/svg/profile-default.svg");

const RowHistorico = ({ classes, procedimentoOdontologico }) => {
  const { procedimento, procedimentoRealizado } = procedimentoOdontologico;
  const { dataHoraLancamento, profissionalSaude } = procedimentoRealizado;

  const fotoPerfil = profissionalSaude.usuario.fotoPerfil;

  return (
    <div className={classes.contentRow} key={procedimentoOdontologico.id}>
      <GracefulImage
        src={fotoPerfil ? buildUrlFotoPerfil(fotoPerfil) : profileDefault}
        className={classes.profissionalProfileImage}
      />
      <div className={classes.contentInfo}>
        <div>
          <strong>{profissionalSaude.nome}</strong> realizou o procedimento
          {procedimento.nome}
        </div>
        <div className={classes.data}>
          {moment(dataHoraLancamento).format("DD [de] MMMM [às] HH[h]mm")}
        </div>
      </div>
    </div>
  );
};

const styles = {
  contentRow: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  contentInfo: {
    flexDirection: "column",
  },
  profissionalProfileImage: {
    borderRadius: "50%",
    height: 32,
    width: 32,
  },
  data: {
    color: "#ADADAD",
    padding: 0,
    fontSize: "12px",
  },
};

export default withStyles(styles)(RowHistorico);
