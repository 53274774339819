import React from 'react';
import { withStyles } from '@material-ui/core';

const InfosHeader = ({ classes, title, label }) => {
    return (
        <div className={classes.content}>
            <div className={classes.title}> {title} </div>
            <div className={classes.label}> {label} </div>
        </div>
    )
};

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '120px',
    },
    title: {
        lineHeight: "16px",
    },
    label: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#219A97',
        lineHeight: '19px',
    },
};

export default withStyles(styles)(InfosHeader);