import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "../GuiaServicosFaturamentoStyle";
import CloseIcon from "@material-ui/icons/Close";
import { Save } from "@material-ui/icons";
import classNames from "classnames";
import {
  findAllProcedimentos,
  findAllTuss,
} from "../../../../../services/GuiaDeServicosService";
import MultiToggleButtons from "../../../../../components/Button/MultiToggleButtons";
import DadosExecucao from "./Tabs/DadosExecucao";
import Executantes from "./Tabs/Executantes";
import string from "../../../../../utils/string";
import moment from "moment";
import { validationDadosExecutante, validationSaveDadoExecucao, verificarHora } from "./ValidaDados";

const tabs = {
  DADOS_EXECUCAO: 0,
  EXECUTANTES: 1,
};

const ItemList = (props) => {
  const {
    classes,
    setDisableButton,
    handleChangeGuiaServicoFaturamento,
    guiaServicoFaturamento,
    openNotification,
    itemSelecionado,
    setShowItemSelecionado,
    validationStep,
    handleChangeProcedimentoSelecionado,
    setIsEdit,
  } = props;

  const [itemExecucao, setItemExecucao] = useState({});
  const [tabSelected, setTabSelected] = useState(tabs.DADOS_EXECUCAO);
  const [isEditExecutante, setIsEditExecutante] = useState(false);

  useEffect(() => {
    setDisableButton(true);
    itemSelecionado && listarDados();
  }, [itemSelecionado]);

  const listarDados = () => {
    const dadoSelecionado = {
      ...itemSelecionado,
      valorUnitario: itemSelecionado?.valorUnitario && !(itemSelecionado?.valorUnitario === "") ? parseFloat(itemSelecionado?.valorUnitario).toFixed(2) : itemSelecionado?.valorUnitario
    };

    setItemExecucao(dadoSelecionado);
  };

  useEffect(() => {
    if (itemExecucao.valorUnitario && itemExecucao.quantidade) {
      const valorUnitario =
        itemExecucao.valorUnitario.includes('R$')
          ? 
          string.currencyMaskToFloat(itemExecucao.valorUnitario)
          : itemExecucao.valorUnitario;
      const quantidade =
        typeof itemExecucao.quantidade === "string"
          ? parseFloat(itemExecucao.quantidade)
          : itemExecucao.quantidade;

      const valorTotal = valorUnitario * quantidade;

      setItemExecucao({
        ...itemExecucao,
        valorTotal: valorTotal.toFixed(2)
      });
    }
  }, [itemExecucao.valorUnitario, itemExecucao.quantidade]);

  const changeTabSelected = (tabSelected) => {
    tabSelected === 0 && setIsEditExecutante(false);
    setTabSelected(tabSelected);
  };

  const handleChangeItens = (e, field) => {
    setItemExecucao({ ...itemExecucao, [field]: e });
  };

  const handleChangeHorario = (e, field) => {
    const hora = moment(e).format("HH:mm");

    setItemExecucao({ ...itemExecucao, [field]: hora });
  }

  const handleChangeQuantidade = (e) => {
    setItemExecucao({ ...itemExecucao, quantidade: e.target.value });
  };

  const handleChangeProcedimento = (e) => {
    setItemExecucao({
      ...itemExecucao,
      descricao: e && {
        nome: e?.nome || "",
        id: e.id
      },
      codigoProcedimento: e?.tuss22ProcedimentoEventoSaude,
    });
  };

  const handleChangeValorUnitario = (e) => {
    const { value } = e.target;
    setItemExecucao({
      ...itemExecucao,
      valorUnitario: value,
    });
  };

  const handleCloseEdit = () => {
    setShowItemSelecionado(false);
    validationStep();
    setIsEdit(false);
  };

  const handleSaveEdit = () => {
    if(validationDadosExecutante(itemExecucao)){
      const isMensagemErro = itemExecucao?.executantes.some(item => !item.profissionalSaude || !item.tuss35TerminologiaGrauParticipacao)

      const isMensagemErroExecutanteIncompleto = itemExecucao?.executantes.some(item => !item.cpf ||
        !item.conselhoProfissionalSaude ||
        !item.numeroConselho ||
        !item.ufConselho ||
        !item.tuss24TerminologiaCBO
      )

      isMensagemErro && openNotification("Preencha todos os campos dos executantes. ", "error");
      isMensagemErroExecutanteIncompleto && openNotification("Cadastro do executante está incompleto.", "error");
      return;
    }

    if (validationSaveDadoExecucao(itemExecucao)) {
      let mensagemErro = "";
      if(!itemExecucao?.codigoProcedimento){
        mensagemErro = "O procedimento não possui código"
      } else if(verificarHora(itemExecucao)) {
        mensagemErro ="Hora inicial maior que a hora final" 
      } else {
        mensagemErro = "Preencha todos os campos obrigatórios dos dados de execução."
      }

      openNotification(mensagemErro, "error");
      return;
    }
    handleChangeProcedimentoSelecionado(itemExecucao);
    setShowItemSelecionado(false);
    setIsEdit(false);
  };

  const loadProcedimento = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: 'ASC',
      sortField: 'nome',
    }
    const searchDTO = {
      pageableDTO,
      nome: search,
    }

    try {
      const { content, last } = (await findAllProcedimentos(
        searchDTO
      )).data.data.findAllProcedimentos
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      openNotification('Erro ao carregar nome dos procedimentos', 'error')
    }
  };

  const loadVia = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllTuss61TerminologiaViaAcesso",
      list: "61TerminologiaViaAcesso",
    });
  };

  const loadTec = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllTuss48TerminologiaTecnicaUtilizada",
      list: "48TerminologiaTecnicaUtilizada",
    });
  };

  const handleLoadMoreOptions = async ({ search, data, method, list }) => {
    const response = await findAllTuss(
      {
        pageableDTO: {
          pageNumber: data.page,
          pageSize: 30,
          sortDir: "ASC",
          sortField: "termo",
        },
        search,
      },
      list
    );

    const { content, last } = response[method];
    return {
      options: content,
      hasMore: !last,
      additional: {
        page: data.page + 1,
      },
    };
  };


  const renderTab = () => {
    switch (tabSelected) {
      case 0:
        return <DadosExecucao
        handleChangeItens={handleChangeItens}
        handleChangeHorario={handleChangeHorario}
        itemExecucao={itemExecucao}
        handleChangeProcedimento={handleChangeProcedimento}
        handleChangeValorUnitario={handleChangeValorUnitario}
        handleChangeQuantidade={handleChangeQuantidade}
        loadProcedimento={loadProcedimento}
        loadVia={loadVia}
        loadTec={loadTec}
        />
      case 1: 
        default:
        return <Executantes
          setDisableButton={setDisableButton}
          openNotification={openNotification}
          itemSelecionado={itemExecucao}
          setShowItemSelecionado={setShowItemSelecionado}
          setIsEdit={setIsEdit}
          validationStep={validationStep}
          guiaServicoFaturamento={guiaServicoFaturamento}
          setIsEditExecutante={setIsEditExecutante}
          handleChangeGuiaServicoFaturamento={handleChangeGuiaServicoFaturamento}
          setItemExecucao={setItemExecucao}
        />
    }
  }

  return (
    <div>
      <div className={classes.contentButtonsItens}>
        <div className={classes.multiButtonsItens}>
          <MultiToggleButtons
            options={["Dados de execução", "Executantes"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
          />
        </div>
        <div className={classes.buttonsActionItens}>
        {!isEditExecutante && 
        <>
        <div
            onClick={handleSaveEdit}
            className={classNames(
              classes.buttonItemSelecionado,
              classes.buttonSaveItemSelecionado
            )}
          >
            <Save />
          </div>
          <div
            onClick={handleCloseEdit}
            className={classNames(
              classes.buttonItemSelecionado,
              classes.buttonCloseItemSelecionado
            )}
          >
            <CloseIcon />
          </div>
          </>
          }
        </div>
      </div>
      <div className={classes.boxTab}>{renderTab()}</div>
    </div>
  );
};

export default withStyles(styles)(ItemList);
