import React from 'react';
import styled from 'styled-components';
import Colors from "../../template/Colors";
import classNames from 'classnames'

const Button = styled.button `
  box-shadow: 10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05);
  border-radius: 10px 0px 0px 10px;
  padding: 24px;
  color: #fff;
  background: ${Colors.commons.gray6};
  margin-top: 10px;
  font-weight: 600;
  width: 164px;
  height: 69px;
  cursor: pointer;
  border: none;
  text-transform: capitalize;
  font-size: 14px;
  &.active {
      background-color: ${Colors.primary.dark};
  }
`;

class Radio extends React.Component {

  onClick = (value) => {
    const {onChange} = this.props;
    onChange && onChange(value);
  }
  
  render() {
    const { value, label, checked} = this.props;
    return (
      <Button 
        className={classNames({'active': checked})} 
        onClick={() => this.onClick(value)}
      >
        <span>{label}</span>
      </Button>
    );
  }
}

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.options = [];
  }

  getChildContext() {
    const {name} = this.props;
    return {radioGroup: {
      name,
      onChange: this.onChange.bind(this)
    }};
  }
  
  onChange(selected, child) {
    this.options.forEach(option => {
      if (option !== child) {
        option.setSelected(!selected);
      }
    });
  }
  
  render() {
    let children = React.Children.map(this.props.children, child => {
      child.onChange = this.onChange;
      return React.cloneElement(child, {
        ref: (component => {this.options.push(component);}) 
      });
    });
    return <div className="radio-group">{children}</div>;
  }
}


export {
  Radio,
  RadioGroup,
}