import React, { useEffect, useReducer, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import { withStyles } from '@material-ui/core'

import Dialog from '../../../../../../../../components/Dialog/Dialog'
import Table from '../../../../../../../../components/Table/Table'
import CloseIcon from '../../../../../../../../components/Icon/CloseIcon'
import { Button } from '../../../../../../../../components/ui/Buttons'
import ModalConfirmacao from '../../../../../../../../components/Modal/ModalConfirmacao/ModalConfirmacao'
import SuccessIcon from '../../../../../../../../components/Icon/SuccessIcon'
import Scroll from '../../../../../../../../components/InfiniteScroll/Scroll'

import { getColumns } from './utils/getColumns'
import {
  excluirControleSessao,
  excluirControleSessaoProcedimentoConsulta,
} from '../../../../../../../../services/ControleSessaoService'
import string from '../../../../../../../../utils/string'
import WarningIcon from '../../../../../../../../components/Icon/WarningIcon'

const modalConfirmacaoDefault = {
  title: "",
  color: "",
  icon: null,
  buttons: null,
};

const ModalCancelSession = observer(({ classes, open, onClose, controleSessaoStore, idPacote }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [sessoesSelectedToCancel, setSessoesSelectedToCancel] = useState([])
  const [openModalConfirmacao, toggleModalConfirmacao] = useReducer(state => !state, false)
  const [dadosModalConfirmacao, setDadosModalConfirmacao] = useState(modalConfirmacaoDefault)

  const idSujeitoAtencao = window.location.href.split('/')[6]

  const handleCancelSession = async () => {
    setIsLoading(true)
    toggleModalConfirmacao()
    try {
      const idsSessoes = sessoesSelectedToCancel.map(item => item.id)
      const isProcedure = controleSessaoStore.sessoes[0].procedimento?.id
      const type = isProcedure ? 'PROCEDIMENTO' : 'AGENDAMENTO_TIPO'
      const field = string.currentFieldControleSessao(controleSessaoStore.sessoes).field
      const quantidadeSessoes = controleSessaoStore.sessoes.map(item => item[field]).length
      if (sessoesSelectedToCancel.length === quantidadeSessoes) {
        const variables = {
          controleSessaoId: idPacote,
        }
        await excluirControleSessao(variables)
        onClose()
      } else {
        await excluirControleSessaoProcedimentoConsulta({ idsSessoes, type })
      }
      await controleSessaoStore.resetParamsPacote()
      await controleSessaoStore.loadAllPacotesSessoes({ idSujeitoAtencao }, true)
      setDadosModalConfirmacao({
        title: "Sessões canceladas com sucesso!",
        color: "green",
        icon: <SuccessIcon color='white' />,
        buttons: null,
      });
      toggleModalConfirmacao()
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
      setSessoesSelectedToCancel([])
    }
  }

  const handleOpenModalConfirmacao = () => {
    setDadosModalConfirmacao({
      title: "Tem certeza que deseja cancelar as sessões selecionadas?",
      color: "red",
      icon: <WarningIcon color='white' />,
      buttons: {
        labelPrimaryButton: "Voltar",
        labelSecondButton: "Continuar",
        handlePrimaryButton: () => {
          toggleModalConfirmacao();
          setDadosModalConfirmacao(modalConfirmacaoDefault);
        },
        handleSecondButton: () => {
          handleCancelSession();
          setDadosModalConfirmacao(modalConfirmacaoDefault);
        },
      },
    });
    toggleModalConfirmacao();
  };

  const checkIsFaturadoOrRealizado = data => {
    const { agendamento, titulo } = data
    return agendamento?.id || titulo?.id
  }

  const handleChange = (e, data) => {
    const { checked } = e.target

    if (checked) {
      setSessoesSelectedToCancel(prevState => [...prevState, data])
    } else {
      setSessoesSelectedToCancel(prevState => prevState.filter(item => item.id !== data.id))
    }
  }

  const handleCheckAllCheckbox = e => {
    const { checked } = e.target

    if (checked) {
      const allSessoesFiltered = controleSessaoStore.sessoes.filter(
        item => !checkIsFaturadoOrRealizado(item)
      )
      setSessoesSelectedToCancel(allSessoesFiltered)
    } else {
      setSessoesSelectedToCancel([])
    }
  }

  const allCheckBoxChecked = () => {
    const allSessoesFiltered = controleSessaoStore.sessoes.filter(
      item => !checkIsFaturadoOrRealizado(item)
    )
    return allSessoesFiltered.length === sessoesSelectedToCancel.length
  }

  const handleLoadMore = () => {
    controleSessaoStore.loadSessoes({ idPacoteSessao: idPacote })
  }

  useEffect(() => {
    controleSessaoStore.loadSessoes({ idPacoteSessao: idPacote })
  }, [])

  useEffect(() => {
    controleSessaoStore.resetParamsSessoes()
    controleSessaoStore.loadSessoes({ idPacoteSessao: idPacote })
  }, [controleSessaoStore.ordenarTabelaSessoes])

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'md'}
        fullWidth={true}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.header}>
          <h1>Selecione as sessões que deseja cancelar</h1>
          <CloseIcon onClick={onClose} size='20' />
        </div>
        <div className={classes.content}>
          <div className={classes.wrapperTable}>
            <Scroll
              initialLoad={false}
              loadMore={handleLoadMore}
              hasMore={!controleSessaoStore.isLoadingSessoes && !controleSessaoStore.hasMoreSessoes}
              pageStart={0}
              classes={{
                root: classes.tableRoot,
              }}
            >
              <Table
                columns={getColumns({
                  handleCheckAll: handleCheckAllCheckbox,
                  handleChange,
                  checkIsFaturadoOrRealizado,
                  sessoesSelectedToCancel,
                  allCheckBoxChecked,
                })}
                dados={controleSessaoStore.sessoes}
                comOrdenacao
                ordenarTabela={controleSessaoStore.ordenarTabelaSessoes}
                handleClickOrdenar={controleSessaoStore.handleClickOrdenarSessoes}
                isLoading={controleSessaoStore.isLoadingSessoes}
              />
            </Scroll>
          </div>
          <div className={classes.wrapperButton}>
            <Button
              kind='primary'
              bgColor='#FB7676'
              onClick={handleOpenModalConfirmacao}
              isLoading={isLoading}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Dialog>

      {openModalConfirmacao && (
        <ModalConfirmacao
          open={openModalConfirmacao}
          {...dadosModalConfirmacao}
          onClose={() => {
            toggleModalConfirmacao()
            onClose()
          }}
        />
      )}
    </>
  )
})

const styles = {
  paper: {
    padding: '24px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    '& h1': {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '16px',
    height: '100%',
    minHeight: '500px',
    maxHeight: '500px',
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',

    '& button': {
      minWidth: '150px',
    },
  },
  wrapperTable: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    overflow: 'hidden',
    height: 'fit-content',
  },

  tableRoot: {
    width: '100%',
    maxHeight: '400px',
    overflow: 'auto',
    '&>div>div>table>thead>tr>th': {
      textAlign: 'start',
      padding: '8px',
    },
    '&>div>div>table>tbody>tr>td': {
      borderRadius: '10px',
      padding: '8px',
    },
  },
}

const ModalCancelSessionWithStyles = withStyles(styles)(ModalCancelSession)
export default inject('controleSessaoStore')(ModalCancelSessionWithStyles)
