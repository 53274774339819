import React from 'react';
import { withStyles } from '@material-ui/core';

import moment from 'moment';

const SolicitacaoImpressaoRelatorio = ({ agendamento, procedimentosSolicitados, classes }) => {
  const { data, horaInicio, horaFim, profissionalSaude, sujeitoAtencao } = agendamento || {};

  return (
    <div className={classes.container}>
      <div className={classes.agendamentoDetailsContainer}>
        <div className={classes.agendamentoDetails}>
          <span className={classes.bold}>Paciente:</span> 
          {sujeitoAtencao?.nome}
        </div>
        <div className={classes.agendamentoDetails}>
          <span className={classes.bold}>Agendamento:</span> 
          {moment(data).format('DD/MM/YYYY')} - {horaInicio} - {horaFim}
        </div>
        <div className={classes.agendamentoDetails}>
          <span className={classes.bold}>Profissional de Saúde:</span> 
          {profissionalSaude?.nome}
        </div>
      </div>
      <span className={classes.title}>
        Procedimentos solicitados:
      </span>
      <ol className={classes.listContainer}>
        {procedimentosSolicitados?.map((procedimento, index) => (
          <li key={index} className={classes.listItem}>
            {procedimento?.nome || procedimento?.descricao || procedimento?.label}
          </li>
        ))}
      </ol>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    width: '100%',
    padding: '16px',
    justifyContent: 'center',
    gap: '16px'
  },
  title: {
    fontSize: '16px',
    fontWeight: '700',
  },
  agendamentoDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  agendamentoDetails: {
    fontSize: '14px',
    display: 'flex',
    gap: '4px',
  },
  bold: {
    fontWeight: '700',
  }
};

export default withStyles(styles)(SolicitacaoImpressaoRelatorio);
