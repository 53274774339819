import React from "react";
import moment from "moment";
import { Grid, withStyles } from "@material-ui/core";
import styles from "./Encaixe.style";
import EventEncaixe from "./EventEncaixe";
import ButtonFloat from "../../ButtonFloat";
import { verificaIconsAgendamento } from "../../../utils/verificaIconsAgendamento";
import classNames from "classnames";

export const HeaderAgendamento = props => {
    const {
        selectedDate,
        classes
    } = props;

    return (
        <div className={classes.dayCalendarHeaderInner}>
            <div className={`${classes.dayCalendarColumnHeader} ${classes.ColumnHeaderHour}`}>
                <div className={classes.dayCalendarColumnHeaderLabelHour} >
                    {moment(selectedDate).format("DD/MM")}
                </div>
            </div>
            <div className={classes.dayCalendarColumnHeader}>
                <div className={classes.dayCalendarColumnHeaderLabel}>
                    Nome
                </div>
            </div>
            <div className={classes.dayCalendarColumnHeader}>
                <div className={classes.dayCalendarColumnHeaderLabel}>
                    Idade
                </div>
            </div>
            <div className={classes.dayCalendarColumnHeaderTipo}>
                <div className={classes.dayCalendarColumnHeaderLabel}>
                    Tipo
                </div>
            </div>
            <div className={classes.dayCalendarColumnHeaderTelefone}>
                <div className={classes.dayCalendarColumnHeaderLabel}>
                    Telefone
                </div>
            </div>
            <div className={classes.dayCalendarColumnHeader}>
                <div className={classes.dayCalendarColumnHeaderLabel}>
                    Convênio
                </div>
            </div>
            <div className={classes.dayCalendarColumnHeader}>
                <div className={classes.dayCalendarColumnHeaderLabel}>
                    Informações
                </div>
            </div>

        </div>
    )
};

const Agendamento = props => {

    const {
        selectedDate,
        agendamentos,
        classes,
        onSelectAgendamento,
        onSelectNovoAgendamento,
        onRemoveAgendamento,
    } = props;
    
    const momentNovoAgendamento = moment(
        `${selectedDate.format("YYYY-MM-DD")}`,
        "YYYY-MM-DD HH:mm"
    );

    return (
        <div>
            {agendamentos && agendamentos?.map(agendamento => {
                const possuiTodosIcons = verificaIconsAgendamento(agendamento);
                return (
                    <div
                        key={agendamento.id}
                        className={classNames(classes.dayCalendarEvent, possuiTodosIcons && classes.rowMaior)}
                       >
                       
                        {agendamento && (
                            <EventEncaixe
                                event={agendamento}
                                onSelectEvent={onSelectAgendamento}
                                onRemoveEvent={onRemoveAgendamento}
                                possuiTodosIcons={possuiTodosIcons}
                            />
                        )}
                    </div>
                );
            })}

            <div className={classes.actions}>
                <ButtonFloat onClick={() => {
                    onSelectNovoAgendamento({
                        start: momentNovoAgendamento
                    });
                }}
                />
            </div>
        </div>
    );
};

const Calendar = props => {
    const {
        selectedDate,
        agendamentos,
        classes,
        onSelectAgendamento,
        onSelectNovoAgendamento,
        onRemoveAgendamento,
    } = props;

    return (
        <Grid
            className={classes.dayCalendar}
            item
            xs={12}
            container
            direction={"column"}
            wrap={"nowrap"}
        >
            <HeaderAgendamento selectedDate={selectedDate} classes={classes} />
            <Agendamento 
                selectedDate={selectedDate}
                agendamentos={agendamentos}
                classes={classes}
                onSelectAgendamento={onSelectAgendamento}
                onSelectNovoAgendamento={onSelectNovoAgendamento}
                onRemoveAgendamento={onRemoveAgendamento}
            />
        </Grid>
    )
};

const Encaixe = props => {
    return (
        <Calendar {...props} />
    );
};

export default withStyles(styles)(Encaixe);
