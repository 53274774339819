import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const ObservacaoEditIcon = props => {
  return (
    <svg
      width='24'
      height='28'
      viewBox='0 0 24 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <rect y='1.875' width='24' height='24' rx='12' fill='#5462E0' />
      <path
        d='M18.7083 14.1263C18.7083 17.6931 15.8168 20.5846 12.25 20.5846C8.68312 20.5846 5.79163 17.6931 5.79163 14.1263C5.79163 10.5595 8.68312 7.66797 12.25 7.66797C15.8168 7.66797 18.7083 10.5595 18.7083 14.1263ZM11.6041 14.7721V16.7096C11.6041 17.0663 11.8933 17.3555 12.25 17.3555C12.6066 17.3555 12.8958 17.0663 12.8958 16.7096V14.7721C12.8958 14.4155 12.6066 14.1263 12.25 14.1263C11.8933 14.1263 11.6041 14.4155 11.6041 14.7721ZM12.25 11.2201C11.7149 11.2201 11.2812 11.6538 11.2812 12.1888C11.2812 12.7238 11.7149 13.1576 12.25 13.1576C12.785 13.1576 13.2187 12.7238 13.2187 12.1888C13.2187 11.6538 12.785 11.2201 12.25 11.2201Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default ObservacaoEditIcon
