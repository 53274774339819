import React, { useEffect } from "react";
import { inject } from "mobx-react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { Grid, withStyles } from "@material-ui/core";
import styles from "./ModalStyles";
import string from "../../../../../utils/string";
import { InputForm } from "../../../../../components/Modal/Input";
import ButtonLong from "../../../../../components/Button/ButtonLong";
import { applyCurrencyMask } from "../../../../../utils/CurrencyMask";
import { TextFieldSearch } from "../../../../../components/TextField";
import InputDateForm from "../../../../../components/Input/InputDateForm";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import { tiposDesconto } from "../../../../../stores/Financeiro/Extrato.store";
import InputMaskCurrency from "../../../../../components/Input/InputMaskCurrency";
import { InputMaskPercentil } from "../../../../../components/Input/InputMaskPercentil";
import LinkPagamento from "../LinkPagamento";

const InformacoesParcela = observer((props) => {
  const { classes, extratoStore, close, isDisabled } = props;
  const { tituloParcela } = extratoStore;

  useEffect(() => {
    extratoStore.recalculaSubtotalParcela();

  }, [tituloParcela.valor, tituloParcela.tipoDesconto, tituloParcela.desconto])

  const handleChange = (field, e) => {
    extratoStore.tituloParcela[field] = e;
  };

  const handleCancelar = () => {
    close();
  };

  const handleSaveTituloParcela = async () => {
    const titulo = {
      sujeitoAtencao: extratoStore.titulo?.sujeitoAtencao?.id && { id: extratoStore.titulo?.sujeitoAtencao?.id },
      profissionalSaude: extratoStore.titulo?.profissionalSaude?.id && { id: extratoStore.titulo?.profissionalSaude?.id },
      centroCusto: extratoStore.titulo?.centroCusto?.id && { id: extratoStore.titulo?.centroCusto?.id },
      procedimentos: extratoStore.titulo?.procedimentos,
      tipo: extratoStore.titulo?.tipo,
      ...tituloParcela,
    };

    await extratoStore.updateTituloParcela(titulo);
    extratoStore.findAll();
    close();
  };

  const isPago = !!tituloParcela?.dataPagamento;

  return (
    <div className={classes.infoParcelaContainer}>
      <div className={classes.infoParcelaContent}>
      <Grid container spacing={16} className={classes.rowInfoParcela}>
        <Grid item xs={4}>
          <Grid className={classes.titleField}>Valor (Obrigatório)</Grid>
          <InputMaskCurrency
            value={tituloParcela.valor || ""}
            onChange={(e) => handleChange("valor", e.target.value)}
            className={classnames(
              classes.inputValor
            )}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid className={classes.titleField}>Forma de pagamento</Grid>
          <TextFieldSearch
            placeholder=""
            loadOptions={extratoStore.handleFormaPagamentoLoadOptions}
            withPaginate
            value={tituloParcela.formaPagamento}
            onChange={(e) => handleChange("formaPagamento", e)}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            isDisabled={isDisabled}
            classNotched={classes.notchedOutlineSearch}
            maxMenuHeight={140}
          />
        </Grid>
      </Grid>

      <Grid container spacing={16} className={classes.rowInfoParcela}>
        <Grid item xs={4} className={classes.fieldDesconto}>
          <Grid className={classes.titleField}>Desconto</Grid>
          {isDisabled ? (
            <div className={classes.fieldDisabled}>
              {tituloParcela.tipoDesconto?.value
                ? string.capitalize(tituloParcela.tipoDesconto?.value)
                : ""}
            </div>
          ) : (
            <ReactSelect
              value={tituloParcela.tipoDesconto}
              className={classes.selectParcelas}
              onChange={(e) => handleChange("tipoDesconto", e)}
              options={tiposDesconto}
              menuPlacement={"auto"}
              getOptionLabel={(option) => string.capitalize(option.value)}
              getOptionValue={(option) => option.id}
              isClearable
            />
          )}
        </Grid>
        <Grid item xs={4} className={classes.fieldDesconto}>
          {tituloParcela.tipoDesconto &&
          tituloParcela.tipoDesconto?.value === "VALOR" ? (
            <InputMaskCurrency
              value={tituloParcela.desconto || ""}
              onChange={(e) => handleChange("desconto", e.target.value)}
              className={classnames(
                classes.inputValor,
                extratoStore.errors.valor && classes.inputValorError
              )}
              disabled={isDisabled || !tituloParcela.tipoDesconto}
            />
          ) : (
            <InputMaskPercentil
              className={classes.inputPercentil}
              classInputRoot={classes.inputRootPercentil}
              value={tituloParcela.desconto}
              onChange={(e) => handleChange("desconto", e.target.value)}
              disabled={isDisabled || !tituloParcela.tipoDesconto}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Grid className={classes.titleField}>Subtotal</Grid>
          <Grid className={classes.subtotal}>
            <div className={classes.subtotalValor}>
              {tituloParcela.subtotal
                ? applyCurrencyMask(tituloParcela.subtotal)
                : ""}
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={16} className={classes.rowInfoParcela}>
        <Grid item xs={6}>
          <Grid className={classnames(classes.titleField, classes.vencimento)}>
            Vencimento (Obrigatório)
          </Grid>
          <InputDateForm
            value={tituloParcela.dataVencimento}
            onChange={(e) => handleChange("dataVencimento", e)}
            iconposition="end"
            className={classes.inputDate}
            error={extratoStore.errors.dataVencimento}
            invalidDateMessage=""
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.titleField}>Data de pagamento</Grid>
          <InputDateForm
            iconposition="end"
            value={tituloParcela.dataPagamento}
            className={classes.inputDate}
            onChange={(e) => handleChange("dataPagamento", e)}
            invalidDateMessage=""
            disabled={isDisabled}
          />
        </Grid>
      </Grid>

      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Grid className={classes.titleField}>Conta</Grid>
          <TextFieldSearch
            placeholder=""
            loadOptions={extratoStore.handleContaBancariaLoadOptions}
            withPaginate
            value={tituloParcela.contaBancaria}
            onChange={(e) => handleChange("contaBancaria", e)}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            isDisabled={isDisabled}
            classNotched={classes.notchedOutlineSearch}
            maxMenuHeight={200}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid className={classnames(classes.contentField, classes.marginTop)}>
          <Grid className={classes.titleField}>Observação</Grid>
          <InputForm
            className={classes.descricao}
            rows={3}
            multiline
            error={extratoStore.errors.nome}
            value={tituloParcela.nome || ""}
            onChange={(e) => handleChange("nome", e.target.value)}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      {tituloParcela?.id && !isPago && (
        <LinkPagamento
          chavePublica={tituloParcela.chavePublica}
          formaPagamentoName={tituloParcela.formaPagamento?.label}
        />
      )}
      </div>
      <Grid className={classes.buttonsFooter}>
        <div
          className={classes.cancelar}
          disabled={extratoStore.saving}
          onClick={handleCancelar}
        >
          Cancelar
        </div>
        <ButtonLong
          noShadow
          loading={extratoStore.saving}
          colorCustom="green"
          disabled={extratoStore.saving || tituloParcela?.excluido}
          onClick={handleSaveTituloParcela}
        >
          Salvar
        </ButtonLong>
      </Grid>
    </div>
  );
});

const InformacoesParcelaWithStyles = withStyles(styles)(InformacoesParcela);
export default inject("extratoStore")(InformacoesParcelaWithStyles);
