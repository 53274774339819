import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ThumbsDownEmpty, ThumbsDownFilled, ThumbsUpEmpyt, ThumbsUpFilled} from './ThumbButtons';
import { getWhitelabelNome } from '../../../../services/WhitelabelService';

const styles = ({
  container: {
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F9F9F9',
    borderRadius: '8px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    padding: '8px',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: '8px',
  },
  text: {
    fontSize: '10px !important',
    color: '#656565',
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '14px',
    '&:hover': {
      cursor: 'pointer'
    },
  },

  buttonsLoading: {
    cursor: 'wait',
    opacity: '0.5',
    pointerEvents: 'none'
  }
});

const CommentRatingsButton = observer(({ classes, liked, handleClick, avaliacaoId, loading }) => {
  const handleThumbClicked = (status) => {
    handleClick(avaliacaoId, status);
  }

  const buttonsByState = {
    NULO: {
      likeButton: () => <ThumbsUpEmpyt onClick={() => handleThumbClicked('GOSTEI')} />,
      dislikeButton: () => <ThumbsDownEmpty onClick={() => handleThumbClicked('NAO_GOSTEI')} />,
    },
    GOSTEI: {
      likeButton: () => <ThumbsUpFilled />,
      dislikeButton: () => <ThumbsDownEmpty onClick={() => handleThumbClicked('NAO_GOSTEI')} />
    },
    NAO_GOSTEI: {
      likeButton: () => <ThumbsUpEmpyt onClick={() => handleThumbClicked('GOSTEI')} />,
      dislikeButton: () => <ThumbsDownFilled />
    }
  }

  const renderButtons = () => {
    const selectedAvaliacaoState = liked || 'NULO';

    const LikeButton = buttonsByState[selectedAvaliacaoState].likeButton;
    const DislikeButton = buttonsByState[selectedAvaliacaoState].dislikeButton;
    return (
      <div className={`${classes.buttons} ${loading && classes.buttonsLoading}`}>
        <DislikeButton />
        <LikeButton />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <span className={classes.text}>
        Exibir esse comentário na sua página{" "}{getWhitelabelNome()}?
      </span>
      {renderButtons()}
    </div>
  )
});

export default withStyles(styles)(CommentRatingsButton);