function b64ToUtf8( str ) {
    if(!str){
        return ""
    }
    return decodeURIComponent(escape(window.atob( str )));
}
export const base64Convert = (base64) =>{
    let csvContent = b64ToUtf8(base64);
    const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
    return blob
}