import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const VisibilityOffIcon = props => {
  return (
    <svg 
      width='21' 
      height='21' 
      viewBox='0 0 21 21' 
      fill='none' 
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path 
        d='M0.819675 1.01947C0.55341 1.28574 0.529202 1.7024 0.747057 1.99602L0.819675 2.08013L4.85424 6.1147C2.93225 7.46417 1.49578 9.47973 0.89889 11.8642C0.798306 12.266 1.0425 12.6733 1.44432 12.7739C1.84613 12.8745 2.25341 12.6303 2.35399 12.2284C2.88347 10.1133 4.19112 8.33928 5.93417 7.19433L7.7438 9.00406C7.03628 9.72559 6.60001 10.7141 6.60001 11.8044C6.60001 14.0136 8.39087 15.8044 10.6 15.8044C11.6904 15.8044 12.6788 15.3682 13.4004 14.6606L19.3197 20.5801C19.6126 20.873 20.0874 20.873 20.3803 20.5801C20.6466 20.3139 20.6708 19.8972 20.453 19.6036L20.3803 19.5195L14.2668 13.4053L14.268 13.4038L13.0679 12.2059L10.198 9.33658L10.2 9.3358L7.31878 6.45763L7.32001 6.4558L6.18672 5.32529L1.88034 1.01947C1.58744 0.726581 1.11257 0.726581 0.819675 1.01947ZM8.80408 10.0653L12.3392 13.6004C11.8892 14.0362 11.2759 14.3044 10.6 14.3044C9.21929 14.3044 8.10001 13.1851 8.10001 11.8044C8.10001 11.1285 8.36824 10.5152 8.80408 10.0653ZM10.6 4.2998C9.59975 4.2998 8.62914 4.44788 7.7111 4.7248L8.94807 5.96099C9.48387 5.85512 10.0364 5.7998 10.6 5.7998C14.5231 5.7998 17.9099 8.48007 18.8471 12.233C18.9475 12.6348 19.3547 12.8792 19.7565 12.7789C20.1584 12.6785 20.4028 12.2714 20.3025 11.8695C19.1994 7.45252 15.2155 4.2998 10.6 4.2998ZM10.7947 7.80908L14.596 11.6098C14.4943 9.55288 12.8472 7.90745 10.7947 7.80908Z' 
        fill='currentColor'
      />
    </svg>
  );
};

export default VisibilityOffIcon;