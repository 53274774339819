import React from 'react';
import InputForm from "./InputForm";
import MaskedInput from "react-text-mask";
import string from "../../utils/string";
import masks from "../../utils/masks";

let tipo;

export const DocumentoMask = (props) => {
    const { inputRef, ...other } = props;

    return (<MaskedInput placeholderChar={'\u2000'}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}

        showMask={props.showmask}
        mask={ tipo ? masks[tipo] : [] }
         {...other}
    />);
};

export const InputDocumentos = ({...props}) => {
    tipo = props.tipo;

    return <InputForm inputComponent={DocumentoMask} {...props} onClick={(e) => {
        if (string.removeSpecialChars(e.target.value).length === 0)
            e.target.setSelectionRange(0, 0);
    }}/>
};


