import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Colors from '../../template/Colors';

const styles = () => ({
    root: {
        borderRadius: '100px',
        minHeight: 37,
        width: '100px',
        border: 0,
        background: Colors.commons.gray10,
        color: Colors.commons.white,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        padding: '0px 16px',
        "& span": {
            fontSize: 14,
            fontWeight: 700
        },
        "&:hover": {
            background: Colors.commons.waikawaGrey,
            color: Colors.commons.white,
        }
    }
});

const ButtonTransferir = (props) => {
    const {classes} = props;
    return <Button className={classes.root} {...props}/>;
};


export default withStyles(styles)(ButtonTransferir);