import React from "react";
import Grid from "@material-ui/core/Grid";
import debounce from "lodash.debounce";
import { withStyles } from "@material-ui/core/styles/index";
import { inject, observer } from "mobx-react";
import Dialog from "../../components/Dialog/Dialog";
import styles from "../../assets/jss/pages/mudarSenhaStyles";
import string from "../../utils/string";
import Notification from "../../components/Notification";
import ButtonLong from "../../components/Button/ButtonLong";
import InputSenha from "../../components/Input/InputSenha";
import classNames from "classnames";
import MensagemSenha from "../../components/Mensagem/MensagemSenha";

@inject("usuarioStore")
@observer
class MudarSenhaModal extends React.Component {
  constructor(props) {
    super(props);

    this.debounceOnChangeSenha = debounce(this.debounceOnChangeSenha, 500);
    this.state = {
      senhaForte: false,
      showPassword: false,
    };
  }

  onClose = () => {
    this.props.usuarioStore.closeMudarSenhaModal();
  };

  handleSenhaAtual = (event) => {
    this.props.usuarioStore.mudarSenha.senhaAtual = event.target.value;
    this.props.usuarioStore.errors.senhaAtual = string.isEmpty(
      event.target.value
    );
  };

  handleNovaSenha = (event) => {
    this.props.usuarioStore.mudarSenha.novaSenha = event.target.value;
    this.props.usuarioStore.errors.novaSenha = string.isEmpty(
      event.target.value
    );
    this.debounceOnChangeSenha();
    this.props.usuarioStore.verificaNovaSenha();
  };

  handleConfirmarNovaSenha = (event) => {
    this.props.usuarioStore.mudarSenha.confirmarNovaSenha = event.target.value;
    this.props.usuarioStore.errors.confirmarNovaSenha = string.isEmpty(
      event.target.value
    );
    this.props.usuarioStore.verificaNovaSenha();
  };

  handleClickAlterarSenha = () => {
    const { usuarioStore } = this.props;
    const { senhaForte } = this.state;

    if (!senhaForte) {
      this.debounceOnChangeSenha();
      return;
    }

    usuarioStore.alterarSenha();
  };

  handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.usuarioStore.closeNotification();
  };

  resetNotification = () => this.props.usuarioStore.resetNotification();

  debounceOnChangeSenha = async () => {
    const { usuarioStore } = this.props;

    const validacao = string.verificaSenhaForte(usuarioStore.mudarSenha.novaSenha);
    const validacoes = Object.values(validacao);
    const isSenhaForte = !validacoes.some(validacao => !validacao);

    this.setState({ senhaForte: isSenhaForte });

    isSenhaForte
      ? usuarioStore.closeNotification()
      : usuarioStore.openNotification(
          <MensagemSenha validacao={validacao}/>,
          "warning"
        );
  };

  renderButtons() {
    const { classes } = this.props;

    return (
      <Grid container direction={"row"} wrap={"nowrap"}>
        <Grid onClick={this.onClose} className={classes.buttonCancel}>
          Cancelar
        </Grid>
        <ButtonLong
          colorCustom="green"
          onClick={this.handleClickAlterarSenha}
          disabled={this.props.usuarioStore.disableButtons}
          noShadow
        >
          Confirmar
        </ButtonLong>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { mudarSenhaModalOpen, mudarSenha } = this.props.usuarioStore;

    const errorSenhaDiferente = mudarSenha.confirmarNovaSenha !== undefined &&
    mudarSenha.confirmarNovaSenha !== "" &&
    this.props.usuarioStore.disableButtons

    return (
      <>
        <Dialog
          open={mudarSenhaModalOpen}
          maxWidth={"md"}
          classes={{ paper: classes.paperWidthMd }}
          direction={"column"}
        >
          <Grid className={classes.header}> Alterar senha </Grid>
          <Grid className={classes.content}>
            <Grid className={classes.titleField}> Senha atual </Grid>
            <InputSenha
              value={mudarSenha.senhaAtual}
              onChange={this.handleSenhaAtual}
              className={classes.field}
            />

            <Grid className={classes.titleField}> Nova senha </Grid>
            <InputSenha
              value={mudarSenha.novaSenha}
              onChange={this.handleNovaSenha}
              className={classes.field}
            />

            <Grid className={classes.titleField}> Confirme nova senha </Grid>
            <InputSenha
              value={mudarSenha.confirmarNovaSenha}
              onChange={this.handleConfirmarNovaSenha}
              className={classNames(classes.field, errorSenhaDiferente && classes.fieldError)}
              error={errorSenhaDiferente}
              errorMessage="As senhas não são iguais"
            />
          </Grid>
          {this.renderButtons()}
        </Dialog>

        <Notification
          close={this.handleClose}
          reset={this.resetNotification}
          isOpen={this.props.usuarioStore.notification.isOpen}
          variant={this.props.usuarioStore.notification.variant}
          message={this.props.usuarioStore.notification.message}
        />
      </>
    );
  }
}

export default withStyles(styles)(MudarSenhaModal);
