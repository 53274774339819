import React from 'react'
import {inject, observer} from 'mobx-react';
import {withStyles} from '@material-ui/core/styles';

import FooterNotification from './FooterNotification';
import styles from './Footer.style'

@inject('usuarioStore')
@observer
class Footer extends React.Component {
    state = {
        unidadeLogada: null,
        showUpdateNotification: true
    };

    async componentDidMount() {
        const {usuarioStore} = this.props;
        const unidadeLogada = await usuarioStore.getUnidadeAtual();
        this.setState({
            unidadeLogada
        });
    }

    render() {
        const {classes, unidadeNomeAsChild, hideNotification, ...other} = this.props;
        const className = [
            classes.root,
            this.props.className,
            !unidadeNomeAsChild ? classes.footerGridDefault : null
        ].join(' ');

        return (
            <div className={className}>
                {!unidadeNomeAsChild && 
                    <div className={classes.footerUnidade}>
                        {this.state.unidadeLogada?.nome}
                    </div>
                }
                {other.children}
                {!hideNotification && <FooterNotification />}
            </div>
        );
    }
}


export default withStyles(styles)(Footer);