import React from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import {
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import Colors from "../../../../../template/Colors";
import classNames from "classnames";

const styles = (theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing.unit,
    marginTop: 5,
    width: "200px",
    background: "#F2F2F2",
    borderRadius: "17px",
  },
  dadosAdicionais: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    boxSizing: "border-box",
    borderRadius: "100px",
    height: "32px",
    color: Colors.commons.gray9,
    padding: "0 10px",
  },
  dadosAdicionaisInativo: {
    opacity: 0.5,
    textDecoration: 'line-through',
  },
  visibilityIcon: {
    color: "#5F6368",
  },
  buttonVisibility: {
    display: "flex",
    padding: "1px",
    borderRadius: 100,
    "&:hover": {
      background: "#DFDFDF",
    },
  },
  listaDadosAdicionais: {
    display: "flex",
    flexDirection: "column",
    color: Colors.commons.gray7,

    "& span": {
      fontWeight: 700,
    },
  },
  quantidadeDadosAdicionais: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: " 100%",
    overflow: "hidden",
  },
});

class PopoverDadosAdicionais extends React.Component {
  state = {
    anchorEl: null,
  };

  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, quantidadeCamposPreenchido, dadosAdicionais } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <div
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
        >
          <div className={classNames(classes.dadosAdicionais, {[classes.dadosAdicionaisInativo]: !dadosAdicionais.ativo})}>
            <div className={classes.quantidadeDadosAdicionais}>
              {quantidadeCamposPreenchido}
            </div>
            <div className={classes.buttonVisibility}>
              <VisibilityIcon className={classes.visibilityIcon} />
            </div>
          </div>
        </div>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <div className={classes.listaDadosAdicionais}>
            {dadosAdicionais.peso && <div>
              Peso: <span> {dadosAdicionais.peso} kg </span>
            </div>}
            {dadosAdicionais.estatura && <div>
              Altura: <span> {dadosAdicionais.estatura} cm </span>
            </div>}
            {dadosAdicionais.cintura && <div>
              Cintura: <span> {dadosAdicionais.cintura} cm </span>
            </div>}
             { dadosAdicionais.circunferenciaBraquial && <div>
              Circunferencia braquial: <span> {dadosAdicionais.circunferenciaBraquial} cm </span>
            </div>}
            {dadosAdicionais.perimetroCefalico && <div>
              Perímetro cefálico: <span> {dadosAdicionais.perimetroCefalico} cm </span>
            </div>}
           { dadosAdicionais.quadril && <div>
              Quadril: <span> {dadosAdicionais.quadril} cm </span>
            </div>}
            { dadosAdicionais.pregaCutanea && <div>
              Prega cutanea: <span> {dadosAdicionais.pregaCutanea} mm </span>
            </div>}
          </div>
        </Popover>
      </div>
    );
  }
}

PopoverDadosAdicionais.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PopoverDadosAdicionais);
