const styles = {
  titulo: {
    fontSize: "54px",
    lineHeight: "81px",
    color: "#505050",
    marginBottom: "18px",
    "@media (max-width: 768px)": {
      marginBottom: '0px',
      alignSelf: 'center',
    },
  },
  esqueciMinhaSenhaLinkStyle: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#505050",
    textDecoration: "none",

    "@media (max-width: 768px)": {
      margin: '20px 0 16px',
    },
  },
  content: {
    maxWidth: "505px",
    display: "flex",
    flexDirection: "column",
    width: '100%',
    padding: '0 36px',

    "@media (max-width: 768px)": {
      alignSelf: 'flex-start',
    },
  },
  inputStyle: {
    height: "56px",
    padding: "0 16px",
    background: "#fff",
    border: "1px solid #EBEBEB",

    "& input": {
      height: "41px",
      padding: 0,
      fontFamily: "Poppins !important",
    },
  },
  inputSenha: {
    width: "calc(100% - 32px)",
  },
  titleInput: {
    color: "#7C7C7C",
    fontWeight: 600,
    fontSize: "16px",
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",

    "@media (max-width: 768px)": {
      gap: "4px",
    },
  },
  buttonStyle: {
    background: '#219A97',
    height: '56px',
    border: '2px solid rgba(0, 0, 0, 0.1)',
  },
};

export default styles;
