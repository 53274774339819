import React, { useState, useEffect } from "react";
import moment from 'moment';
import { withStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import EventDay from './EventDay';
import { Dates } from "../../../utils"
import { ReactSelect } from "../../../components/Select/SelectSearch";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ContentLoader from "react-content-loader";
import string from "../../../utils/string";

const styles = () => ({
    item: {
        backgroundColor: '#EEEEEE',
        height: '100%',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid rgba(134, 134, 134, 0.4)',
        display: 'flex',
        flexDirection: 'column'
    },
    iconButton: {
        width: '30px',
        height: '30px',
        marginLeft: '10px'
    },
    icon: {
        fontSize: '20px'
    },
    dragIcon: {
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.54)',
        marginLeft: '4px',
        marginTop: '4px'
    },
    cardHeader: {
        height: '42px',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        zIndex: 5
    },
    listaAgendamentos: {
        overflow: 'auto',
        marginBottom: '16px'
    },
    reactSelect: {
        width: "200px",
        zIndex: 100,
    },
    gridLayout: {
        overflowX: 'hidden'
    }
})


const Loading = () => {
    return (
        <ContentLoader
            backgroundColor="#EEEEEE"
            foregroundColor="#adadad"
            style={{ height: '30px', width: "200px" }}
        >
            <rect x="0" y="4" rx="4" ry="4" width="200" height="24" />
        </ContentLoader>
    );
};

const MiniAgenda = (props) => {

    const {
        selectedDate,
        todosAgendamentos,
        agendamentos,
        configVigente,
        classes,
        onSelectAgendamento,
        onSelectNovoAgendamento,
        onRemoveAgendamento,
        changeAgenda,
        agendaSelecionada,
        agendasDisponiveis,
        removerAgenda,
        hideBlocked,
        hideEmpty,
        loading
    } = props;

    const daysOfWeekTransform = Dates.daysOfWeekTransform();
    const selectDateString = selectedDate.format('dddd');
    const selectedDateTransform = daysOfWeekTransform[string.capitalize(selectDateString)];
    const [listaHorariosAgendamento, setListaHorariosAgendamento] = useState([]);

    useEffect(() => {
        loadAgendamentos();
    }, [agendamentos, hideBlocked, hideEmpty]);

    const loadAgendamentos = () => {
        let listaAgendamentos = agendamentos;

        if (!configVigente) {
            setListaHorariosAgendamento([])
            return
        }

        if (!configVigente) return;

        configVigente[selectedDateTransform].forEach(configuracaoAtual => {
            const possuiAgendamentoNesteHorario = todosAgendamentos.some(agendamento => {
                const horaInicio = agendamento.horaInicio || moment(agendamento.start).format("HH:mm");
                const horaFim = agendamento.horaFim || moment(agendamento.end).format("HH:mm");

                return (
                    (horaInicio === configuracaoAtual.horaInicio) ||
                    (moment(moment(configuracaoAtual.horaInicio, "HH:mm")).isBetween(
                        moment(horaInicio, "HH:mm"),
                        moment(horaFim, "HH:mm"))
                    ))
            })

            if (!possuiAgendamentoNesteHorario) {
                listaAgendamentos = [
                    ...listaAgendamentos,
                    {
                        horaInicio: configuracaoAtual.horaInicio,
                        horaFim: configuracaoAtual?.horaFim,
                        situacao: !configuracaoAtual.permiteAgendamento && 'BLOQUEADO'
                    }
                ]
            }

            return listaAgendamentos;
        })

        if (hideBlocked) {
            listaAgendamentos = listaAgendamentos.filter(agendamento => agendamento.situacao !== 'BLOQUEADO');
        }

        if (hideEmpty) {
            listaAgendamentos = listaAgendamentos.filter(agendamento => !!agendamento.situacao);
        }

        listaAgendamentos.sort((a, b) => moment(a.horaInicio, "HH:mm").format("x") - moment(b.horaInicio, "HH:mm").format("x"))

        setListaHorariosAgendamento(listaAgendamentos);
    }

    const selectAgendamento = (event) => {
        onSelectAgendamento({ ...event, agendaSelecionada })
    }

    const getLabelSelect = (option) => {
        const especialidade = !!option.especialidades && option.especialidades?.length > 0 ? `- ${option?.especialidades[0].especialidade}` : ""

        return `${option.nome}  ${especialidade}`
    };

    return (
        <div className={classes.item}>
            <div className={classes.cardHeader}>
                <div>
                    <DragHandleIcon
                        className={classes.dragIcon}
                    />
                    {loading ? (
                        <Loading />
                    ) :
                        (<ReactSelect
                            className={classes.reactSelect}
                            placeholder={'Selecione a agenda'}
                            value={agendaSelecionada}
                            options={agendasDisponiveis}
                            getOptionLabel={(option) => getLabelSelect(option)}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => changeAgenda(agendaSelecionada, e)}
                            menuPlacement={'auto'}
                            disableMenuPortal
                        />)
                    }
                </div>
                <div>
                    <IconButton
                        className={classes.iconButton}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            onSelectAgendamento({ agendaSelecionada })
                        }}
                    >
                        <AddIcon className={classes.icon} />
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            removerAgenda(agendaSelecionada)
                        }}
                    >
                        <CloseIcon
                            className={classes.icon}
                        />
                    </IconButton>
                </div>
            </div>
            <div className={classes.listaAgendamentos}>
                {listaHorariosAgendamento && listaHorariosAgendamento.map(agendamento => {
                    return (
                        <EventDay
                            agendamento={agendamento}
                            selectedDate={selectedDate}
                            onSelectNovoAgendamento={onSelectNovoAgendamento}
                            onSelectAgendamento={onSelectAgendamento}
                            onRemoveAgendamento={onRemoveAgendamento}
                            selectAgendamento={selectAgendamento}
                            tipo={agendaSelecionada.tipo}
                        />
                    )
                })}
            </div>
        </div>

    )
}

export default withStyles(styles)(MiniAgenda);
