export const configHorarioAtendimentoTabs = {
  'INFORMACOES': 0,
  'PROCEDIMENTOS': 1,
  'AUDITORIA': 2
};

export const defaultProcedimentosModalData = {
  isOpen: false,
  diaSemana: '',
  horaInicio: '',
};

