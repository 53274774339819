import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const AvaliacaoAntropometricaIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      {...normalizeProps(props)}
    >
      <path
        d="M9.75146 1.30078C10.3314 1.30078 10.8015 1.77088 10.8015 2.35078L10.8015 12.2527C10.8015 12.8326 10.3314 13.3027 9.75146 13.3027H5.84981C5.26991 13.3027 4.7998 12.8326 4.7998 12.2527V2.35078C4.7998 1.77088 5.26991 1.30078 5.84981 1.30078H9.75146ZM7.94985 4.90173H5.69981V5.80173H7.94985C8.19838 5.80173 8.39985 5.60026 8.39985 5.35173C8.39985 5.10321 8.19838 4.90173 7.94985 4.90173ZM6.796 6.8506H5.69981L5.69981 7.7506H6.796C7.04453 7.7506 7.246 7.54913 7.246 7.3006C7.246 7.05207 7.04453 6.8506 6.796 6.8506ZM7.94985 8.80173H5.69981V9.70173H7.94985C8.19838 9.70173 8.39985 9.50026 8.39985 9.25173C8.39985 9.00321 8.19838 8.80173 7.94985 8.80173ZM6.796 10.6017H5.69981L5.69981 11.5017H6.796C7.04453 11.5017 7.246 11.3003 7.246 11.0517C7.246 10.8032 7.04453 10.6017 6.796 10.6017ZM6.796 3.10074H5.69981V4.00074H6.796C7.04453 4.00074 7.246 3.79927 7.246 3.55074C7.246 3.30222 7.04453 3.10074 6.796 3.10074Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AvaliacaoAntropometricaIcon;
