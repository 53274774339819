import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ArrowDownIcon = props => {
  return (
    <svg 
      width="12" 
      height="7" 
      viewBox="0 0 12 7" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      { ...normalizeProps(props)}
    >
      <path d="M0.600459 6.54288C0.917543 6.88436 1.45141 6.90413 1.79288 6.58704L6 2.62017L10.2071 6.58704C10.5486 6.90413 11.0825 6.88436 11.3995 6.54288C11.7166 6.20141 11.6969 5.66754 11.3554 5.35046L6.57413 0.850455C6.2504 0.549848 5.7496 0.549848 5.42587 0.850455L0.644623 5.35046C0.303148 5.66754 0.283376 6.20141 0.600459 6.54288Z" fill="currentColor"/>
    </svg>
  );
};

export default ArrowDownIcon;