import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const ClipboardTaskListIcon = props => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M8.75 2C8.09689 2 7.54127 2.4174 7.33535 3H5.75C4.92157 3 4.25 3.67157 4.25 4.5V16.5C4.25 17.3284 4.92157 18 5.75 18H14.75C15.5784 18 16.25 17.3284 16.25 16.5V4.5C16.25 3.67157 15.5784 3 14.75 3H13.1646C12.9587 2.4174 12.4031 2 11.75 2H8.75ZM8.25 3.5C8.25 3.22386 8.47386 3 8.75 3H11.75C12.0261 3 12.25 3.22386 12.25 3.5C12.25 3.77614 12.0261 4 11.75 4H8.75C8.47386 4 8.25 3.77614 8.25 3.5ZM9.60355 7.85356L7.85355 9.60347C7.66451 9.79251 7.36021 9.79939 7.16281 9.6191L6.41281 8.9341C6.20891 8.74788 6.19459 8.43162 6.38081 8.22772C6.56704 8.02382 6.8833 8.0095 7.0872 8.19572L7.48436 8.55847L8.89646 7.14644C9.09173 6.95118 9.40831 6.95119 9.60357 7.14646C9.79882 7.34172 9.79881 7.65831 9.60355 7.85356ZM9.60357 12.1465C9.79882 12.3417 9.79881 12.6583 9.60355 12.8536L7.85355 14.6035C7.66451 14.7925 7.36021 14.7994 7.16281 14.6191L6.41281 13.9341C6.20891 13.7479 6.19459 13.4316 6.38081 13.2277C6.56704 13.0238 6.8833 13.0095 7.0872 13.1957L7.48436 13.5585L8.89646 12.1464C9.09173 11.9512 9.40831 11.9512 9.60357 12.1465ZM11.25 12.75H13.75C14.0261 12.75 14.25 12.9739 14.25 13.25C14.25 13.5261 14.0261 13.75 13.75 13.75H11.25C10.9739 13.75 10.75 13.5261 10.75 13.25C10.75 12.9739 10.9739 12.75 11.25 12.75ZM10.75 8.25C10.75 7.97386 10.9739 7.75 11.25 7.75H13.75C14.0261 7.75 14.25 7.97386 14.25 8.25C14.25 8.52614 14.0261 8.75 13.75 8.75H11.25C10.9739 8.75 10.75 8.52614 10.75 8.25Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default ClipboardTaskListIcon
