import React, { useReducer, useState } from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'

import CardDadosCadastrais from '../../../components/Cards/CardDadosCadastrais'
import { Button } from '../../../components/ui/Buttons'
import { SelectSearch } from '../../../components/Select/SelectSearchAsync'
import ItemGrid from '../../../components/Modal/ItemGrid'
import InputDateForm from '../../../components/Input/InputDateForm'
import ClipboardCopy from '../../../components/Input/ClipboardCopy'
import AlertModal from '../../../components/Modal/AlertModal'

import CheckmarkCircleIcon from '../../../components/Icon/CheckmarkCircle'
import TrashIcon from '../../../components/Icon/Trash'
import CircleIcon from '../../../components/Icon/CircleIcon'
import InfoIconFilled from '../../../components/Icon/InfoIconFilled'

import styles from '../../../assets/jss/pages/sujeitoAtencaoStyle'

const CardMultiConvenios = observer(({ classes, sujeitoAtencaoStore, disabled }) => {
  const [convenioSelected, setConvenioSelected] = useState(null)
  const [convenioToDelete, setConvenioToDelete] = useState(null)
  const [openAlertModal, toggleAlertModal] = useReducer(prevState => !prevState, false)

  const { errors, objView } = sujeitoAtencaoStore

  const handleAddConvenios = () => {
    if (!objView.dadosConvenios.length) {
      objView.dadosConvenios.push({
        ...convenioSelected,
        convenioPrincipal: true,
      })
      setConvenioSelected(null)
      sujeitoAtencaoStore.contemErros({ revalidate: true })
      return
    }
    objView.dadosConvenios.push(convenioSelected)
    setConvenioSelected(null)
  }

  const handleChange = (value, index, name) => {
    objView.dadosConvenios.map((item, i) => {
      if (i === index) {
        item[name] = value
      }
      return item
    })
  }

  const handleOpenAlertModal = index => {
    toggleAlertModal()
    setConvenioToDelete(index)
  }

  const handleCloseAlertModal = () => {
    toggleAlertModal()
    setConvenioToDelete(null)
  }

  const handleRemove = index => {
    objView.dadosConvenios.splice(index, 1)
  }

  return (
    <>
      <CardDadosCadastrais titulo={'Dados convênio:'}>
        <div className={classes.wrapperCardMultiConvenios}>
          <SelectSearch
            className={classNames(classes.selectField, errors.convenio ? classes.inputError : null)}
            name='convenio'
            isDisabled={disabled}
            value={convenioSelected}
            placeholder={'Convênio'}
            elements={sujeitoAtencaoStore.convenios}
            onChange={e => setConvenioSelected(e)}
          />
          <Button
            kind='primary'
            size='mini'
            onClick={handleAddConvenios}
            disabled={!convenioSelected}
          >
            Adicionar
          </Button>
        </div>
        {objView?.dadosConvenios?.map((item, index) => (
          <div
            className={classNames(classes.dadosConvenio, classes.dadosConvenioSelected)}
            key={index}
          >
            <Button
              kind='transparent'
              shape='circle'
              onClick={() => {
                objView.dadosConvenios.map(item => (item.convenioPrincipal = false))
                objView.dadosConvenios[index].convenioPrincipal = true
              }}
            >
              {item?.convenioPrincipal ? (
                <CheckmarkCircleIcon color='#219A97' size={32} />
              ) : (
                <CircleIcon color='#868686' size={32} />
              )}
            </Button>
            <ItemGrid
              label={'Convênio:'}
              classes={{
                item: classes.selectConvenio,
              }}
            >
              <SelectSearch
                className={classNames(
                  classes.selectField,
                  errors.convenio ? classes.inputError : null
                )}
                name='convenio'
                isDisabled={disabled}
                placeholder={'Convênio'}
                value={item?.convenio || item}
                elements={sujeitoAtencaoStore.convenios}
                getOptionLabel={option => option.descricao || option?.label}
                onChange={e => handleChange(e, index, 'convenio')}
              />
            </ItemGrid>
            <ItemGrid label={'Número da carteira:'}>
              <ClipboardCopy
                bgColor='#707C97'
                classes={{
                  input: classes.clipboardCopy,
                }}
                value={item?.numeroCarteira}
                onChange={({ target: { value } }) => handleChange(value, index, 'numeroCarteira')}
                disabled={disabled}
              />
            </ItemGrid>
            <ItemGrid label={'Validade:'}>
              <InputDateForm
                id='validadeCarteira'
                placeholder={'Validade'}
                disabled={disabled}
                value={item?.validadeCarteira || null}
                onChange={e => handleChange(e, index, 'validadeCarteira')}
                iconposition='end'
                className={classNames(
                  classes.inputDadosCadastraisPacienteValidade,
                  classes.inputDateMultiConvenios,
                  errors.validadeCarteira ? classes.inputError : null
                )}
              />
            </ItemGrid>
            <Button
              kind='transparent'
              shape='circle'
              onClick={() => handleOpenAlertModal(index)}
              disabled={item?.convenioPrincipal && objView.dadosConvenios.length > 1}
            >
              <TrashIcon color='#F44336' size={30} />
            </Button>
          </div>
        ))}
      </CardDadosCadastrais>
      {openAlertModal && (
        <AlertModal
          open={openAlertModal}
          onClose={handleCloseAlertModal}
          icon={<InfoIconFilled color='white' size={24} />}
          title={'Deseja realmente excluir esse convênio?'}
          withButtons
          bgIconColor={'#FB7676'}
          buttonOptions={{
            onCancel: handleCloseAlertModal,
            bgColorConfirm: '#FB7676',
            confirmText: 'Excluir',
            onConfirm: e => {
              handleRemove(convenioToDelete)
              toggleAlertModal()
            },
          }}
        />
      )}
    </>
  )
})
const CardMultiConveniosWithStyles = withStyles(styles)(CardMultiConvenios)
export default inject('sujeitoAtencaoStore')(CardMultiConveniosWithStyles)
