import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '../../../components/Button/Button';
import Info from '@material-ui/icons/Info';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import classNames from 'classnames';

const styles = ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FEE3E3',
    borderRadius: '8px',
    padding: '16px',
    rowGap: '16px',
    marginTop: '8px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '16px'
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleArea: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '7px',
    alignItems: 'center'
  },
  infoIcon: {
    color: '#505050',
  },
  title: {
    fontSize: '16px !important',
    fontWeight: '600 !important',
    color: '#505050',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  message: {
    color: '#505050',
    fontSize: '14px !important',
    fontWeight: '400 !important',
  },
  fieldName: {
    color: '#505050',
    fontSize: '12px !important'
  },
  observation: {
    fontSize: '10px !important',
    color: '#505050'
  },
  icon: {
    height: '12px',
    width: '12px'
  },
  incorrectIcon: {
    color: '#FB7676'
  },
  fieldItem: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '3px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  fieldList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    columnGap: '10px',
    flexWrap: 'wrap'
  },
});

const buttonStyle = {
  height: '36px',
  borderRadius: '100px',
  textTransform: 'none',
  whiteSpace: 'nowrap',
};

const DadosInsuficientesWarning = ({ 
  classes, 
  title, 
  message, 
  onClickButton, 
  buttonText, 
  withoutButton, 
  isPerfilWarning, 
  requiredFields,
  messageIsJSX
}) => {
  const renderRequiredFields = () => {
    const fieldsNames = Object.keys(requiredFields);
    const fieldItems = fieldsNames.map((fieldName, index) => {
      return (
        <div className={classes.fieldItem} key={index}>
          {requiredFields[fieldName].error ? 
            <Cancel className={classNames(classes.incorrectIcon, classes.icon)} /> : 
            <CheckCircle className={classes.icon} color='primary' />
          }
          <span 
            className={classes.fieldName}
            style={{ textDecoration: requiredFields[fieldName].error ? 'none' : 'line-through' }}
          >
            {requiredFields[fieldName].name}
          </span>
        </div>
      )
    });

    return fieldItems;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.textArea}>
          <div className={classes.titleArea}>
            <Info className={classes.infoIcon} />
            <span className={classes.title}>{title}</span>
          </div>
          {!messageIsJSX ? 
            <span className={classes.message}>{message}</span> :
            <message />
          }
        </div>
        {!withoutButton && (
          <div>
            <Button
              style={buttonStyle}
              onClick={onClickButton}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
      {isPerfilWarning && Object.keys(requiredFields).length > 0 && (
        <div className={classes.fieldList}>
          {renderRequiredFields()}
        </div>
      )}
      {isPerfilWarning && (
        <span className={classes.observation}>
          Tenha um perfil mais engajado e completo preenchendo todos os campos.
        </span>
      )}
    </div>
  );
};

export default withStyles(styles)(DadosInsuficientesWarning);