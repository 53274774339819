import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import EventThreeDays from './EventThreeDays';
import { verificaIconsAgendamento } from '../../../utils/verificaIconsAgendamento';
import classNames from 'classnames';

const AgendaThreeDays = props => {
    const { 
        classes,
        agendamentos,
        selectedDate,
        onSelectNovoAgendamento,
        onSelectAgendamento,
        onRemoveAgendamento
    } = props;
    
    return agendamentos.map((agendamento, index) => {
        const possuiTodosIcons = verificaIconsAgendamento(agendamento);
        return (
            <div
                key={index}
                className={classNames(classes.dayCalendarEvent, possuiTodosIcons && classes.rowMaior)}
            >
                {!!agendamento && (
                    <EventThreeDays
                        event={agendamento}
                        selectedDate={agendamento.data || selectedDate}
                        onSelectNovoAgendamento={onSelectNovoAgendamento}
                        onSelectAgendamento={onSelectAgendamento}
                        onRemoveAgendamento={onRemoveAgendamento}
                        possuiTodosIcons={possuiTodosIcons}
                    />
                )}
            </div>
        )
    }) 
};

const styles = {
    dayCalendarEvent: {
        width: '100%',
        minHeight: '44px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        
        "&>div": {
            fontSize: '1.1em !important',
            color: '#ccc',
            userSelect: 'none'
        },
        
        '&>div[role="tooltip"]': {
            top: '30% !important',
            left: '10px !important',
            zIndex: 3
        },
        '&.last-day > div[role="tooltip"]': {
            left: 'auto !important',
            right: '5px !important',
        }
    },
    rowMaior: {
        minHeight: '60px',
    },
}

export default withStyles(styles)(AgendaThreeDays);