export const imcStatus = {
  DESNUTRICAO_AGUDA_GRAVE: {
      label: 'Desnutrição Aguda Grave',
      color: 'abaixoPesoII'
  },
  DESNUTRICAO_AGUDA_MODERADA: {
      label: 'Desnutrição Aguda Moderada',
      color: 'abaixoPeso'
  },
  NORMAL: {
      label: 'Normal',
      color: 'pesoNormal'
  },
  SOBREPESO: {
      label: 'Sobrepeso',
      color: 'obesidadeI'
  },
  OBESIDADE: {
      label: 'Obesidade',
      color: 'obesidadeII'
  },
};