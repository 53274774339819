const styles = {
  widthMaximo: {
    width: "100%",
  },
  inputRetangular: {
    borderRadius: "8px",
  },
  titleField: {
    color: "#868686",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "4px",
  },
};

export default styles;
