import { findAllProcedimentos } from "../../../../../../services/ProcedimentoService"

export const loadProcedimentos = async ({ search, pageNumber, profissionalSaudeId, salaId }) => {
  try {
    const pageableDTO = {
      pageNumber,
      sortDir: "ASC",
      sortField: "nome",
      pageSize: 30
    }; 

    const response = await findAllProcedimentos({
      nome: search || "",
      ativo: true,
      pageableDTO,
      ...(profissionalSaudeId && { profissionalSaudeId }),
      ...(salaId && { salaId }),
    });

    if (response?.data?.errors) {
      throw new Error(response.data.errors[0].message)
    }

    return response?.data?.data?.findAllProcedimentos;
  } catch(error) {
    console.error(error)
  }
};

export const combineDayHourPairs = (days, hours) => {
  return days.flatMap(day => hours.map(hour => ({ diaSemana: day.value, horaInicio: hour.value })));
};
