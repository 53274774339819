import GuiaDeConsulta from "./GuiaDeConsulta/GuiaDeConsulta";
import GuiaDeServicos from "./GuiaDeServicos/GuiaDeServicos";
import GuiaDeServicosLote from "./GuiaDeServicosLote/GuiaDeServicosLote";
import AUTHORITIES from "../../config/authorities";

export const routes = [
    {
      title: "Guias",
      children: [
        {
          uri: "/guia-de-consulta",
          title: "Guia de consulta",
          component: GuiaDeConsulta,
          menu: true,
          roles: [AUTHORITIES.ROLE_FINANCEIRO_GUIA_CONSULTA_READ],
        },
        {
          uri: "/guia-de-servicos",
          title: "Guia de Serviços",
          component: GuiaDeServicos,
          menu: true,
          roles: [AUTHORITIES.ROLE_FINANCEIRO_GUIA_SERVICO_READ],
        },
        {
          uri: "/lote-de-guias",
          title: "Lote de Guias",
          component: GuiaDeServicosLote,
          menu: true,
          roles: [AUTHORITIES.ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_READ],
        },
      ],
    },
  ];