import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { denteTipoFace } from "../../Constants";
import FieldTitle from "../../Components/FieldTitle";
import CheckboxFaces from "../../Components/CheckboxFaces";
import ButtonLong from "../../../../../../components/Button/ButtonLong";
import { TextFieldSearch } from "../../../../../../components/TextField";
import { ReactSelect } from "../../../../../../components/Select/SelectSearch";
import { findAllLocalOdontograma } from "../../../../../../services/OdontogramaService";

const Informacoes = observer(({ classes, odontogramaStore }) => {
  const { modalEditarProcedimento, odontograma } = odontogramaStore;
  const {
    procedimentoOdontologicoSelecionado,
    isDuplicarProcedimento,
  } = modalEditarProcedimento;
  const {
    localSelecionado,
    procedimento,
  } = procedimentoOdontologicoSelecionado;

  const [locais, setLocais] = useState([]);
  const [filterLocal, setFilterLocal] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);

  const isDente = localSelecionado?.local === 4;

  useEffect(() => {
    loadLocalOdontograma();
  }, [filterLocal]);

  const loadLocalOdontograma = async () => {
    const response = await findAllLocalOdontograma({
      odontogramaId: odontograma.id,
      localOdontograma: filterLocal,
    });

    setLocais(response);
  };

  const handleProcedimentoLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      search,
      data: {
        page,
      },
    });
  };

  const handleLoadMoreOptions = async ({ search, data }) => {
    let pageableDTO = {
      pageNumber: data.page,
      pageSize: 30,
      sortDir: "ASC",
      sortField: "codigoTermo",
    };

    const response = await odontogramaStore.loadProcedimentoOdontologicoSelect({
      search,
      localOdontograma: filterLocal,
      pageableDTO,
    });

    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const getLocais = (locais) => {
    const localOdontograma = locais.map((item) => item.id);

    return localOdontograma;
  };

  const handleChange = (value, field) => {
    setFilterLocal(
      field === "procedimento"
        ? getLocais(value?.localOdontograma)
        : value?.local
    );

    if (field === "procedimento") {
      odontogramaStore.modalEditarProcedimento.procedimentoOdontologicoSelecionado.localSelecionado = null;
    }

    const dentesTipoFace = value.local === 4 && denteTipoFace;

    odontogramaStore.modalEditarProcedimento.procedimentoOdontologicoSelecionado[
      field
    ] = {
      ...value,
      denteTipoFace: dentesTipoFace,
    };
  };

  const handleCheckFace = (posicao, tipoFace, posicaoFace) => {
    const dentesTipoFace = localSelecionado.denteTipoFace.map((item, index) => {
      const tipoFaceAlteraChecked = {
        ...item,
        checked: !tipoFace?.checked,
      };
      const local = index === posicaoFace ? tipoFaceAlteraChecked : item;

      return local;
    });

    odontogramaStore.modalEditarProcedimento.procedimentoOdontologicoSelecionado.localSelecionado = {
      ...localSelecionado,
      denteTipoFace: dentesTipoFace,
    };
  };

  const handleSave = async () => {
    try {
      setLoadingSave(true);

      if (!localSelecionado) {
        odontogramaStore.openNotification("Favor selecionar um local", "error");

        return;
      }
      const tipo = isDente ? "dente" : "mucosaOral";

      const face =
        isDente &&
        localSelecionado.denteTipoFace
          .filter((item) => item.checked)
          .map((item) => item.value);

      const procedimentoOdontologico = {
        id: procedimentoOdontologicoSelecionado.id,
        tuss22ProcedimentoEventoSaude: {
          id:
            procedimentoOdontologicoSelecionado.tuss22ProcedimentoEventoSaude
              .id,
          descricao: procedimento.nome,
        },
        [tipo]: {
          id: localSelecionado.id,
        },
        face,
      };

      delete procedimentoOdontologico[tipo].denteTipoFace;
      delete procedimentoOdontologico[tipo].local;
      !isDente && delete procedimentoOdontologico.face;

      isDuplicarProcedimento
        ? await odontogramaStore.createProcedimentoOdontologico(
            procedimentoOdontologico
          )
        : await odontogramaStore.updateProcedimentoOdontologico(
            procedimentoOdontologico
          );

      odontogramaStore.findAllProcedimentoOdontologico();
      odontogramaStore.changeCloseModalEditarProcedimento();
    } catch (e) {
      odontogramaStore.openNotification("Erro ao salvar", "error");
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.row}>
        <div>
          <FieldTitle title="Procedimento" fontSize={12} />
          <TextFieldSearch
            placeholder="Selecione aqui"
            loadOptions={handleProcedimentoLoadOptions}
            menuPlacement={"auto"}
            withPaginate
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={procedimento}
            onChange={(e) => handleChange(e, "procedimento")}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            classNotched={classes.notchedOutlineSearch}
            classInput={classes.inputSearch}
            isClearable={false}
          />
        </div>
        <div className={classes.fieldLocal}>
          <FieldTitle title="Local" fontSize={12} />
          <ReactSelect
            value={localSelecionado}
            className={classes.local}
            onChange={(e) => handleChange(e, "localSelecionado")}
            options={locais}
            menuPlacement={"auto"}
            getOptionLabel={(option) =>
              option.local === 4 ? `Dente ${option.codigo}` : option.descricao
            }
            getOptionValue={(option) => option.id}
          />
        </div>
      </div>
      {isDente && (
        <CheckboxFaces
          dentesTipoFace={localSelecionado?.denteTipoFace || []}
          handleCheckFace={handleCheckFace}
        />
      )}
      <div className={classes.contentButtons}>
        <ButtonLong
          colorCustom="noColor"
          onClick={() => odontogramaStore.changeCloseModalEditarProcedimento()}
          noShadow
          loading={loadingSave}
          disabled={loadingSave}
        >
          Cancelar
        </ButtonLong>
        <ButtonLong
          colorCustom="green"
          onClick={handleSave}
          noShadow
          loading={loadingSave}
          disabled={loadingSave}
        >
          Salvar
        </ButtonLong>
      </div>
    </div>
  );
});

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    justifyContent: "space-between",
    height: "100%",
    "& svg": {
      fontSize: "14px",
    },
  },
  row: {
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "49% 49%",
  },
  fieldLocal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  local: {
    "&>div>div": {
      border: "1px solid rgba(220, 220, 220, 0.2)",
      borderRadius: "8px",
      height: "32px",
    },
  },
  notchedOutlineSearch: {
    border: "none",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  contentButtons: {
    display: "flex",
    gap: "10px",
    justifyContent: "end",
    marginTop: "12px",
    "& button": {
      maxWidth: "195px",
    },
  },
};

const InformacoesWithStyles = withStyles(styles)(Informacoes);
export default inject("odontogramaStore")(InformacoesWithStyles);
