import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core/es";
import styles from "./TabPagamentoStyles";
import string from "../../../utils/string";
import ItemGrid from "../../../components/Modal/ItemGrid";
import { InputForm } from "../../../components/Modal/Input";
import { TextFieldSearch } from "../../../components/TextField";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import { tiposDesconto } from "../../../stores/Financeiro/Extrato.store";
import InputMaskCurrency from "../../../components/Input/InputMaskCurrency";
import { InputMaskPercentil } from "../../../components/Input/InputMaskPercentil";
import TabSelector from "../../../components/TabSelector";
import { applyCurrencyMask } from "../../../utils/CurrencyMask";

const AtendimentoModalTabPagamento = observer(({ classes, extratoStore, atendimentoStore }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0); 
  const { titulo, handleChangeModal, errors } = extratoStore;

  const isPossuiTitulo = extratoStore.titulo.id;

  const getValorProcedimento = (item) => {
    const convenioProcedimentos = item?.convenioProcedimentos || item?.procedimento?.convenioProcedimentos || [];
    const valor = convenioProcedimentos.find((convenioProcedimento) => {
        return convenioProcedimento?.convenio?.id === titulo?.convenio?.id || convenioProcedimento?.convenio?.id === atendimentoStore.objView?.convenio?.id
      })
    return applyCurrencyMask(valor?.valor || 0)
  }

  useEffect(()=>{
    
    if(isPossuiTitulo){
      extratoStore.preencherDadosPagamentoDoAgendamento()
    }

  },[])

  const procedimentos = titulo?.procedimentos?.length > 0 ? titulo.procedimentos : atendimentoStore.objView.procedimentos;
  return (
    <>
      <div className={classes.row}>
        <ItemGrid size={4} label="Valor (Obrigatório)">
          <InputMaskCurrency
            value={titulo.valor || ""}
            onChange={(e) => handleChangeModal("valor", e)}
            error={errors.valor}
            className={classnames(
              classes.inputValor,
              isPossuiTitulo && classes.inputDisabled
            )}
            disabled={isPossuiTitulo}
          />
        </ItemGrid>
        <ItemGrid size={3} label="Desconto">
          {
            isPossuiTitulo ?
              <div className={classes.divDisabled}>
                {titulo.tipoDesconto?.value ? string.capitalize(titulo.tipoDesconto?.value) : ""}
              </div>
              :
              <ReactSelect
                value={titulo.tipoDesconto}
                className={classes.selectParcelas}
                onChange={(e) => handleChangeModal("tipoDesconto", e)}
                options={tiposDesconto}
                menuPlacement={"auto"}
                getOptionLabel={(option) => string.capitalize(option.value)}
                getOptionValue={(option) => option.id}
                isClearable
              />}
        </ItemGrid>
        <ItemGrid
          size={3}
          label=""
          classes={{ item: classes.inputValorDesconto }}
        >
          {titulo.tipoDesconto && titulo.tipoDesconto?.value === "VALOR" ? (
            <InputMaskCurrency
              value={titulo.desconto || ""}
              onChange={(e) => handleChangeModal("desconto", e)}
              className={classnames(
                classes.inputValor,
                isPossuiTitulo && classes.inputDisabled,
                extratoStore.errors.valor && classes.inputValorError
              )}
              disabled={isPossuiTitulo}
            />
          ) : (
            <InputMaskPercentil
              className={classnames(
                classes.inputPercentil,
                isPossuiTitulo && classes.inputDateDisabled
              )}
              classInputRoot={classes.inputRootPercentil}
              value={titulo.desconto}
              onChange={(e) => handleChangeModal("desconto", e)}
              disabled={isPossuiTitulo}
            />
          )}
        </ItemGrid>
        <ItemGrid size={3} label="Subtotal">
          <InputMaskCurrency
            value={titulo.subtotal || ""}
            className={classnames(
              classes.inputValor,
              isPossuiTitulo && classes.inputDisabled
            )}
            disabled={true}
          />
        </ItemGrid>
      </div>
      <div className={classes.row}>
        <ItemGrid size={6} label="Centro de custo">
          {isPossuiTitulo ? (
            <div
              className={classnames(
                classes.inputSearch,
                classes.fieldDisabled
              )}
            >
              <div> {titulo.centroCusto?.label} </div>
            </div>
          ) : (
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleCentroCustoLoadOptions}
              withPaginate
              value={titulo.centroCusto}
              onChange={(e) => handleChangeModal("centroCusto", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              classNotched={classes.notchedOutlineSearch}
              classInput={classes.inputSearch}
              maxMenuHeight={200}
            />
          )}
        </ItemGrid>
        <ItemGrid size={6} label="Categoria">
          {isPossuiTitulo ? (
            <div
              className={classnames(
                classes.inputSearch,
                classes.fieldDisabled
              )}
            >
              <div> {titulo.categoriaFinanceira?.label} </div>
            </div>
          ) : (
            <TextFieldSearch
              placeholder=""
              loadOptions={extratoStore.handleCategoriaLoadOptions}
              withPaginate
              value={titulo.categoriaFinanceira}
              onChange={(e) => handleChangeModal("categoriaFinanceira", e)}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              classNotched={classes.notchedOutlineSearch}
              classInput={classes.inputSearch}
              maxMenuHeight={200}
              disabled={isPossuiTitulo}
            />
          )}
        </ItemGrid>
      </div>
      <TabSelector   
        tabsOptions={[{ label: 'Descrição'}, { label: 'Resumo', isDisabled: procedimentos.length === 0 }]}
        baseColor='#F2F2F2'
        selectedColor='#FFF'
        onSelect={index => setSelectedTabIndex(index)}
        selectedTabIndex={selectedTabIndex}
      />
      {selectedTabIndex === 0 && (
        <div className={classes.row}>
          <ItemGrid
            size={12}
            label="Descrição (Obrigatório)"
            classes={{ item: classes.itemGridDescricao }}
          >
            <InputForm
              className={classnames(classes.descricao, isPossuiTitulo && classes.textAreaDisabled)}
              rows={5}
              multiline
              error={errors.nome}
              value={titulo.nome || ""}
              onChange={(e) => handleChangeModal("nome", e)}
              disabled={isPossuiTitulo}
            />
          </ItemGrid>
        </div>
      )}
      {selectedTabIndex === 1 && (
        <div className={classes.wrapperTable}>
          <table className={classes.tableContainer}>
          <thead>
            <tr>
              <th>Procedimento</th>
              <th>Sala</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {procedimentos.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item?.nome || item?.procedimento?.nome}
                  </td>
                  <td>
                    {item?.sala?.nome || atendimentoStore.objView.sala?.nome} 
                  </td>
                  <td>
                    {getValorProcedimento(item)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      )}
    </>
  );
});

const AtendimentoModalTabPagamentoWithStyles = withStyles(styles)(
  AtendimentoModalTabPagamento
);
export default inject(
  "atendimentoStore",
  "extratoStore"
)(AtendimentoModalTabPagamentoWithStyles);
