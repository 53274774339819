import React from 'react';
import Button from '@material-ui/core/Button';

import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = theme => ({
    root: {
        minHeight: 40,
        width: '100%',
        padding: '0 16px',
        background: theme.palette.commons.gray10,
        color: theme.palette.commons.white, 
        textTransform: 'none',
        whiteSpace: 'nowrap',
        borderRadius: 100,
        "&:hover": {
            background: theme.palette.commons.gray10Dark
        },
        "& span": {
            fontSize: 14
        },
        "&:disabled": {
            color: theme.palette.commons.gray2,
        }
    },
    noBorderLeft: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    noBorderRight: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    }
});

const ButtonGray = (props) => {
    const {classes, noBorderRight, noBorderLeft, withMarginLeft, withMarginRight, ...others} = props;
    let className = classes.root;
    
    if (noBorderLeft) {
        className = classnames(className, classes.noBorderLeft);
    }
    
    if (noBorderRight) {
        className = classnames(className, classes.noBorderRight);
    }
    
    return <Button className={className} {...others}/>;
};


export default withStyles(styles)(ButtonGray);