import React from 'react'
import TrashIcon from '../../../../../components/Icon/Trash'
import DuplicaIcon from '../../../../../components/Icon/DuplicaIcon'
import { VisibilityRoundedIcon } from '../../../../../components/Icon/VisibilityRoundedIcon'
import ArrowDownloadIcon from '../../../../../components/Icon/ArrowDownload'

export const menuOpcoesPopper = ({ data, functions }) => {


  const handleCallCurrentFunction = (functionName) => {
    if (functionName) {
      functionName(data)
      functions.closePopper()
    }
  };

  const optionsMenu = [
    {
      ativo: true,
      icon: <VisibilityRoundedIcon size='20' color='#505050' />,
      label: 'Visualizar',
      onClick: () => handleCallCurrentFunction(functions?.handleClickVisualizar),
    },
    {
      ativo: data.urlDownload? true: false,
      icon: <ArrowDownloadIcon size='24' color='#505050' />,
      label: 'Baixar',
      onClick: () => handleCallCurrentFunction(functions?.handleClickBaixar),
    },
    {
      ativo: true,
      icon: <TrashIcon size='20' color='#505050' />,
      label: 'cancelar',
      onClick: () => handleCallCurrentFunction(functions?.handleClickCancelar),
    },
  ]
  return optionsMenu
}