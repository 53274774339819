import React from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

const InfoExpansiveButton = props => {
    const { classes, openInfo, handleOpenInfo, children } = props;

    return (
        <>
            <div className={classNames(classes.barraInfoFechado, openInfo && classes.barraInfoAberto)} onClick={handleOpenInfo}>
                {openInfo ? 
                    <ArrowRightIcon/>
                    :
                    <ArrowLeftIcon/>
                }
            </div>
            { openInfo &&
                <div className={classes.childrenContent}>
                    {children}
                </div>
            }
        </>
    )

};

const styles = {
    barraInfoFechado: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position:'absolute',
        bottom: 145, 
        right: 0,
        background: '#F5F5F5',
        width: 20,
        height: 117,
        borderRadius: '100px 0px 0px 100px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
    },
    barraInfoAberto: {
        right: 235,
    },
    childrenContent: {
        width: 203,
        minWidth: 203,
        height: 82,
        position: 'absolute',
        right: 0,
        bottom: 145,
        padding: "16px 16px 19px",
        background: "#fff",
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
}

export default withStyles(styles)(InfoExpansiveButton);
