import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'
import classnames from 'classnames'

import ButtonTransparent from '../../../../../../components/Button/ButtonTransparent'
import DoubleChevronIcon from '../../../../../../components/Icon/DoubleChevron'
import ChevronIcon from '../../../../../../components/Icon/Chevron'
import { formatDate } from '../../utils/formatDate'

const MultiSelect = props => {
  const {
    classes,
    onEndReached,
    listGuias: allGuiasList,
    isLoading,
    guiaSelected: guiaSelectedProp,
    convenioSelected,
    typeOfGuia,
    getSelectedItems,
  } = props
  const [listaGuiasSelecionadas, setListaGuiasSelecionadas] = useState([])
  const [itensSelecionados, setItensSelecionados] = useState([])
  const [itensToRemove, setItensToRemove] = useState([])
  const [guiasWithSelectedById, setGuiasWithSelectedById] = useState([])

  const guiaSelected = guiaSelectedProp?.guias?.length
    ? guiaSelectedProp?.guias
    : guiaSelectedProp?.guiasConsulta

  const isItemSelected = (item, array) => array.some(i => i.id === item.id)

  const listaGuias = useMemo(
    () => allGuiasList.filter(item => !isItemSelected(item, listaGuiasSelecionadas)),
    [allGuiasList, listaGuiasSelecionadas]
  )

  const maxLengthItens = 100

  const handleAdd = itensSelected => {
    if (listaGuiasSelecionadas.length + itensSelected.length > maxLengthItens) return
    const newListaGuiasSelecionadas = [...listaGuiasSelecionadas, ...itensSelected]

    if (guiaSelectedProp) {
      const newListaGuias = guiasWithSelectedById.filter(
        item => !isItemSelected(item, itensSelected)
      )
      setGuiasWithSelectedById(newListaGuias)
      setGuiasWithSelectedById(prevState => [
        ...prevState,
        ...newListaGuias.filter(item => !isItemSelected(item, prevState)),
      ])
    }

    setListaGuiasSelecionadas(newListaGuiasSelecionadas)
  }

  const handleRemove = itensSelected => {
    const newListaGuiasSelecionadas = listaGuiasSelecionadas.filter(
      item => !itensSelected.some(i => i.id === item.id)
    )

    if (guiaSelectedProp) {
      const newListaGuias = [...itensSelected, ...listaGuias]
      setGuiasWithSelectedById(prevState => [
        ...prevState,
        ...newListaGuias.filter(item => !isItemSelected(item, prevState)),
      ])
    }
    setListaGuiasSelecionadas(newListaGuiasSelecionadas)
  }

  const handleAddAll = () => {
    const guiasToAdd = (guiasWithSelectedById?.length ? guiasWithSelectedById : listaGuias).splice(
      0,
      maxLengthItens - listaGuiasSelecionadas.length
    )
    if (guiasToAdd.length === 0) return
    const newListaGuiasSelecionadas = [...listaGuiasSelecionadas, ...guiasToAdd]

    setListaGuiasSelecionadas(newListaGuiasSelecionadas)
    if (typeof onEndReached === 'function') {
      onEndReached()
    }
  }

  const handleRemoveAll = () => {
    setListaGuiasSelecionadas([])
    if (guiaSelectedProp) {
      const newListaGuias = [...listaGuiasSelecionadas, ...listaGuias]
      setGuiasWithSelectedById(newListaGuias)
    }
  }

  const handleSelectItem = item => {
    if (isItemSelected(item, itensSelecionados)) {
      setItensSelecionados(itensSelecionados.filter(i => i.id !== item.id))
    } else {
      setItensSelecionados([...itensSelecionados, item])
    }
  }

  const handleSelectToRemove = item => {
    if (isItemSelected(item, itensToRemove)) {
      setItensToRemove(itensToRemove.filter(i => i.id !== item.id))
    } else {
      setItensToRemove([...itensToRemove, item])
    }
  }

  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    if (scrollTop + clientHeight >= scrollHeight) {
      return typeof onEndReached === 'function' && onEndReached()
    }
  }

  const emptyList = listaGuias.length === 0 && guiasWithSelectedById.length === 0

  const renderEmptyMessage = () => {
    if (isLoading && convenioSelected && emptyList) return <CircularProgress size={20} />
    if (!isLoading && convenioSelected && emptyList) return 'Não há guias para serem selecionadas'
    if (!convenioSelected) return 'Selecione um convênio'
  }

  useEffect(() => {
    setListaGuiasSelecionadas([])
    setItensSelecionados([])
    setItensToRemove([])
  }, [typeOfGuia])

  useEffect(() => {
    if (guiaSelected?.length) {
      setListaGuiasSelecionadas(guiaSelected)
    }
    setGuiasWithSelectedById(listaGuias)
  }, [guiaSelected])

  useEffect(() => {
    getSelectedItems(listaGuiasSelecionadas)
  }, [listaGuiasSelecionadas])

  return (
    <div className={classes.container}>
      <div className={classes.wrapperCards}>
        <h1>Guias em aberto</h1>
        <div className={classes.card} onScroll={handleScroll}>
          {(guiasWithSelectedById?.length ? guiasWithSelectedById : listaGuias).map(item => (
            <div
              key={item.id}
              className={classnames(
                classes.row,
                isItemSelected(item, itensSelecionados) && classes.rowSelected
              )}
              onClick={() => handleSelectItem(item)}
            >
              <h1>{item?.beneficiario?.nome}</h1>
              <h1>{item?.convenio?.descricao}</h1>
              <h1>[{formatDate(item?.dataAtendimento || item?.solicitacao?.data)}]</h1>
            </div>
          ))}
          <div className={classes.emptyMessage}>{renderEmptyMessage()}</div>
        </div>
      </div>
      <div className={classes.wrapperActions}>
        <ButtonTransparent
          onClick={() => {
            handleRemove(itensToRemove)
            setItensToRemove([])
          }}
        >
          <ChevronIcon color='#5F6368' style={{ transform: 'rotate(180deg)' }} />
        </ButtonTransparent>
        <ButtonTransparent
          onClick={() => {
            handleAdd(itensSelecionados)
            setItensSelecionados([])
          }}
        >
          <ChevronIcon color='#5F6368' />
        </ButtonTransparent>
        <ButtonTransparent onClick={handleRemoveAll}>
          <DoubleChevronIcon color='#5F6368' style={{ transform: 'rotate(180deg)' }} />
        </ButtonTransparent>
        <ButtonTransparent onClick={handleAddAll}>
          <DoubleChevronIcon color='#5F6368' />
        </ButtonTransparent>
      </div>
      <div className={classes.wrapperCards}>
        <h1>{`Selecionados ${listaGuiasSelecionadas?.length || 0}/${maxLengthItens}`}</h1>
        <div className={classes.card}>
          {listaGuiasSelecionadas.map(item => (
            <div
              key={item.id}
              className={classnames(
                classes.row,
                isItemSelected(item, itensToRemove) && classes.rowSelected
              )}
              onClick={() => handleSelectToRemove(item)}
            >
              <h1>{item?.beneficiario?.nome}</h1>
              <h1>{item?.convenio?.descricao}</h1>
              <h1>[{formatDate(item?.dataAtendimento || item?.solicitacao?.data)}]</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    maxHeight: '350px',
    height: 'calc(100% - 138px)',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    margin: '1.5rem 0 0',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#F9F9F9',
    borderRadius: '8px',
  },
  wrapperActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    gap: '2rem',
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.5rem',
      transition: '0.2s',
      '&:hover': {
        '& > svg': {
          color: '#FFFFFF',
        },
        backgroundColor: '#868686 !important',
        borderRadius: '100%',
        padding: '0.5rem',
      },
    },
  },
  wrapperCards: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: 'calc( 50% - 0.5rem )',
    padding: '0.5rem',
  },
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflowY: 'auto',
    height: '100%',
    maxHeight: '210px',
    overflow: 'auto',
  },
  rowSelected: {
    backgroundColor: '#F9BE734D',
  },
  row: {
    maxWidth: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    columnGap: '0.5rem',
    paddingInline: '0.5rem',
    cursor: 'pointer',
    height: '40px',
    '&:hover': {
      backgroundColor: '#F9BE734D',
    },
    '&>h1': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: '#505050',
    },
  },
  emptyMessage: {
    padding: '1rem',
    color: '#505050',
    fontWeight: '700',
  },
}

export default withStyles(styles)(MultiSelect)
