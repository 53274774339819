import React from "react";
import { withStyles } from "@material-ui/core";

const MensagemMeetingId = ({ classes, meetingId }) => {
  return (
    <div className={classes.content}>
      <span> Sala: </span>
      {meetingId}
    </div>
  );
};

const styles = {
  content: {
    position: "fixed",
    top: "10%",
    left: "50%",
    zIndex: 100,

    "& span": {
      color: "#505050",
      fontWeight: 600,
    },
  },
};

export default withStyles(styles)(MensagemMeetingId);
