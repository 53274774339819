import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const SwitchContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: ${props => props.height ? `${props.height}px` : '24px'};
  width: ${props => props.width ? `${props.width}px` : '48px'};
  background-color: ${props => props.bgColor || '#505050'};
  border-radius: 16px;
`;

export const Indicator = styled.span`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || 'white'};
  border: solid 2px ${props => props?.borderColor || 'green'};
  position: absolute;
  left: ${props => (props.checked ? 'calc(100% - 16px)' : '-12px')};
  transition: left 0.3s ease;
`;

export const Label = styled.label`
  font-size: ${props => props.fontSize || '14px'};
  color: ${props => props.color || '#505050'};
`;
