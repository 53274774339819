import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useUpdateCheck } from 'react-update-notification';

import styles from './Footer.style';

const FooterNotification = (props) => {
  const {classes} = props;

  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: '3600000'
  });

  if (status === 'checking' || status === 'current') {
    return null;
  }

  return (
    <div className={classes.notificationContainer} onClick={reloadPage}>
      <div className={classes.notificationMessage}> 
        <span className={classes.textHigilight}>Atualização disponível</span>
        <span>Clique para atualizar o App Health.</span>
      </div>
      <div className={classes.iconContainer}>
        <RefreshIcon className={classes.refreshIcon}/>
      </div>
    </div>
  )
};

export default withStyles(styles)(FooterNotification);