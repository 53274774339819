import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Fab from "@material-ui/core/Fab";

const styles = () => ({
  actionSwitchButtonContainer: {
    display: 'flex',
    padding: '0px 0px 0px 16px',
    width: 'fit-content',
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    justifySelf: 'right',
    cursor: 'pointer', 
    columnGap: '8px',
    '& span': {
      display: 'flex',
      fontSize: '11.45px',
      justifyContent: 'center',
      width: '100%',
      fontWeight: 600
    }
  },
  fab: {
    justifySelf: 'flex-end'
  }
});

const ActionSwitchButton = (props) => {
  const {
    classes, 
    enableIcon, 
    disableIcon, 
    buttonEnabled, 
    enableColor, 
    disableColor, 
    enableText, 
    disableText,
    onClick
  } = props;

  const hasIcon = enableIcon || disableIcon
  const Icon = buttonEnabled ? disableIcon : enableIcon;
  const buttonText = buttonEnabled ? disableText : enableText 
  const buttonBackgroundColor = buttonEnabled ? disableColor : enableColor;
  const fabColor = buttonEnabled ? enableColor : disableColor;

  return (
    <div 
      className={classes.actionSwitchButtonContainer} 
      style={{backgroundColor: buttonBackgroundColor, color: fabColor}}
      onClick={onClick}
    >
      <span>{buttonText}</span>
      <div className={classes.fab}>
        {hasIcon && (
          <Fab 
            style={{
              backgroundColor: fabColor, 
              color: buttonBackgroundColor
            }}
            size='small'
          >
            <Icon />
          </Fab>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(ActionSwitchButton);
