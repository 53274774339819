import React from "react";
import {inject, observer} from "mobx-react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {Close as CloseIcon, Save as SaveIcon} from '@material-ui/icons'
import {Fab} from "@material-ui/core";
import {
	InputCPFForm,
	InputDateForm,
	InputForm,
	SelectForm,
} from "../../../components/Modal/Input";
import styles from "../../../assets/jss/pages/profissionalSaudeStyle";
import string from "../../../utils/string";
import CardDadosCadastrais from "../../../components/Cards/CardDadosCadastrais";
import ItemGrid from "../../../components/Modal/ItemGridCard";
import Notification from "../../../components/Notification";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import CancelIcon from "@material-ui/icons/Cancel";
import PageTitle from "../../../components/PageTitle/PageTitle";
import TermoUsoModal from '../../Dashboard/TermoUso/TermoUsoModal';
import PortalViziActivationCard from "../components/PortalViziActivationCard";
import { 
	beforeActivationText, 
	afterActivationText, 
	advertisementText,
	dadosInsuficientesTitle,
	dadosInsuficientesMessage,
	agendaNaoConfiguradaTitle,
	agendaNaoConfiguradaMessage, 
} from "../constants/perfilViziConstants";
import PerfilPublicoCard from "../components/PerfilPublicoCard";
import ProfissionalSaudeConfigOptions from '../components/ProfissionalSaudeConfigOptions';
import { informacoesPrincipaisConfigOptions } from "../constants/informacoesPrincipaisConstants";
import RectLabel from "../../../components/Labels/RectLabel";
import localStorageService, { ACCESS_TOKEN_KEY } from "../../../services/storage";
import { ativarViziProfissionalSaudeLogado } from "../../../services/PerfilPublicoService";
import DadosInsuficientesWarning from "../components/DadosInsuficientesWarning";
import { TextFieldSearch } from "../../../components/TextField";
import { findAllTuss24TerminologiaCBO } from "../../../services/GuiaDeServicosService";

import { findAllConvenio } from "../../../services/ConvenioService";
import { getWhitelabelNome } from "../../../services/WhitelabelService";

const labelStyle = {
	height: '16px',
	marginRight: '6px'
};
const ViziCardLabel = <RectLabel text="Novidade" color="#FC71B4" areaStyle={labelStyle} />;
@inject("profissionalSaudeStore", "usuarioStore", "perfilPublicoStore", "termoUsoStore", "unidadeStore")
@observer
class PerfilDadosPrincipais extends React.Component {
	async componentDidMount() {
		await this.props.profissionalSaudeStore.getEstadoList();
		await this.props.profissionalSaudeStore.getPrefixoList();
		await this.props.profissionalSaudeStore.getConselhoList();
		await this.props.perfilPublicoStore.findAllConvenioSistemaList();
		await this.props.profissionalSaudeStore.carregarInformacoesUsuarioLogado();
		await this.checkProfissionalHasAgenda();
		this.carregaDadosUnidade();
	}

	carregaDadosUnidade = async () => {
		const { unidadeStore, usuarioStore } = this.props;

		await unidadeStore.getUnidadeLogada();
		await unidadeStore.getUnidadePublicaLogada();
		const paisAtuacao = await usuarioStore.getPaisAtuacao();
		const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
		this.setState({
			accessToken,
			paisAtuacao,
		}, () => {
			this.carregaComunidadesAutonoma(paisAtuacao?.descricao);
		});
	};

	carregaComunidadesAutonoma = async (paisAtuacao) => {
		const { unidadeStore } = this.props;
		paisAtuacao === "ESPANHA" && unidadeStore.loadEspanhaComunidadesAutonoma();
	};

	onFieldChange(value, campo) {
		this.props.profissionalSaudeStore.profissionalSaude = {
			...this.props.profissionalSaudeStore.profissionalSaude,
			[campo]: value,
		};
		if (campo === "nome" || campo === "cns" || campo === "numeroConselho") {
			this.props.profissionalSaudeStore.errors[campo] = string.isEmpty(value);
			if (campo === "nome") {
				this.props.perfilPublicoStore.checkPerfilProfissionalIsComplete();
			}
		}

		if (campo === "cpf") {
			this.validaCpf(value);
		}
	}

	update = async () => {
		const { profissionalSaudeStore } = this.props;
		try {
			await profissionalSaudeStore.updateProfissionalSaudeLogado('dadosPrincipais');
			const feedbackMessage = 'Cadastro do profissional atualizado com sucesso';
			profissionalSaudeStore.openNotification(feedbackMessage, 'success');
			profissionalSaudeStore.perfilPublicoSalvo = profissionalSaudeStore.profissionalSaude.perfilPublico;
		} catch(error) {
			profissionalSaudeStore.openNotification(error, 'error');
		}
	};

	cancelar = () => {
		this.props.history.push({
			pathname: "/",
		});
	};

	validaCpf(cpf) {
		this.props.profissionalSaudeStore.errors.cpf = string.isEmpty(cpf)
			? true
			: !string.validaCPF(cpf);
	}

	onDataNascimentoChange(value) {
		this.props.profissionalSaudeStore.profissionalSaude.dataNascimento = value;

		this.props.profissionalSaudeStore.errors.dataNascimento =
			value === undefined || value === "" || value === null;
	}

	onEmailChange(event) {
		let value = event.target.value;
		this.props.profissionalSaudeStore.profissionalSaude.usuario.email = value;

		this.props.profissionalSaudeStore.errors.email = string.isEmpty(value);
	}

	onSalaChange(event) {
		this.props.profissionalSaudeStore.profissionalSaude.nomeSala = event.target.value;
	}

	handleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.props.profissionalSaudeStore.closeNotification();
	};

	async onRadioChange(value, campo) {
		if (campo !== 'permiteBuscaProfissional') {
			this.props.profissionalSaudeStore.profissionalSaude[campo] = value;
		} else {
			this.props.perfilPublicoStore.perfilPublicoProfissional.permiteBuscaProfissional = value;
		}
	
	}

	handleActivateVizi = async () => {
		const termoDeUso = await this.props.termoUsoStore.buscarTermoUso('VIZI_PROFISSIONAL_SAUDE');
		if (termoDeUso.length === 0) {
			this.liberarAcessoVizi();
		}
	};

	liberarAcessoVizi = async () => {
		const { profissionalSaudeStore, perfilPublicoStore } = this.props;
		const whitelabelNome = getWhitelabelNome();
		try {
			const result = await ativarViziProfissionalSaudeLogado();
			if (result?.data?.data?.ativarViziProfissionalSaudeLogado?.id) {
				if (!perfilPublicoStore?.perfilPublicoProfissional?.id) {
					await this.createMinimumPerfilVizi();
				}
				await this.props.usuarioStore.findProfissionalSaudeLogado();
				profissionalSaudeStore.profissionalSaude.perfilPublico = true;
				profissionalSaudeStore.openNotification(`Perfil ${whitelabelNome} liberado com sucesso!`, 'success');
			} else {
				throw result.data.errors
			}
		} catch (error) {
			console.log('error',error)
			profissionalSaudeStore.openNotification(`Falha ao liberar Perfil ${whitelabelNome}`, 'error');
		}
	}

	createMinimumPerfilVizi = async () => {
		try {
			await this.props.perfilPublicoStore.createProfissionalPerfilPublicoWithMainData();
		} catch(error) {
			throw error;
		}
	};

	handleAssinarTermoUsoVizi = async () => {
		this.props.termoUsoStore.assinarTermoUso('VIZI_PROFISSIONAL_SAUDE');
		this.liberarAcessoVizi();
	}

	onPrefixoChange(value) {
		if(value === ""){
			this.props.profissionalSaudeStore.profissionalSaude.prefixo = null;
		}else{
			this.props.profissionalSaudeStore.profissionalSaude.prefixo = {
				prefixo: value
			};
		}
	}

	onConselhoChange = (event) => {
		const { profissionalSaudeStore } = this.props;

		profissionalSaudeStore.profissionalSaude.conselhoProfissionalSaude = event;
	}

	handleChangeCodigoCBO = (event) => {
		const { profissionalSaudeStore } = this.props;

		profissionalSaudeStore.profissionalSaude.tuss24TerminologiaCBO = event;
	}

	resetNotification = () => {
		this.props.profissionalSaudeStore.resetNotification();
	}

	switchIsChecked = (option) => {
		if (option.storedIn === 'profissionalSaudeStore') {
			return this.props.profissionalSaudeStore.profissionalSaude[option.attribute];
		} else {
			return this.props.perfilPublicoStore.perfilPublicoProfissional[option.attribute];
		}
	}

	handleSwitchOnChange = (option) => {
		const { profissionalSaudeStore, perfilPublicoStore } = this.props;
		if (option.attribute !== 'permiteBuscaProfissional') {
			profissionalSaudeStore.profissionalSaude[option.attribute] = !profissionalSaudeStore.profissionalSaude[option.attribute];
		} else {
			perfilPublicoStore.perfilPublicoProfissional.permiteBuscaProfissional = !perfilPublicoStore.perfilPublicoProfissional.permiteBuscaProfissional;
		}

		if (option.attribute === 'atendePresencial' || option.attribute === 'utilizaTelemedicina') {
			this.props.perfilPublicoStore.checkPerfilProfissionalIsComplete();
		}
	}

	handleInputQuantityChange = (event, option) => {
		const { profissionalSaudeStore } = this.props;
		profissionalSaudeStore.profissionalSaude[option.attributeInput] = event.target.value === '' ? null : event.target.value;
	}

	getPortaViziCardVariant = () => {
		const { profissionalSaudeStore } = this.props;
		const { profissionalSaude } = profissionalSaudeStore;
		if (profissionalSaude.perfilPublico && profissionalSaude.utilizaVIZI) {
			return 'viziActivated';
		}

		if (profissionalSaude.utilizaVIZI && !profissionalSaude.perfilPublico) {
			return 'viziReadyToActivate'
		} else {
			return 'viziDisabledInUnidade';
		}
	}

	checkIsSwitchAvailableToRender = (field) => {
		if (field === 'perfilPublico') {
			return this.props.profissionalSaudeStore.profissionalSaude.perfilPublico;
		}
	}

	checkProfissionalHasAgenda = async () => {
		if (this.props.profissionalSaudeStore.profissionalSaude.id) {
			const profissionalHasAgenda = await this.props.profissionalSaudeStore.checkProfissionalHasHorarioAtendimento();
			this.props.profissionalSaudeStore.profissionalHasAgenda = profissionalHasAgenda;
		}
	}

	loadAllTuss24 = async (search, loadedOptions, { page }) => {
		const pageableDTO = {
			pageNumber: page,
			pageSize: 30,
		}

		const searchDTO = {
			codigoTermo: search,
		}

		try {
		const response = await findAllTuss24TerminologiaCBO(
			pageableDTO,
			searchDTO
		)
		const { content, last } = response.data.data.findAllTuss24TerminologiaCBO
		return {
			options: content,
			hasMore: !last,
			additional: {
			page: page + 1,
			},
		}
		} catch {
			this.props.profissionalSaudeStore.openNotification('Erro ao listar Código CBO', 'error');
		}
	}

	loadConvenios = async (search, loadedOptions, { page }) => {
		const {profissionalSaude} = this.props.profissionalSaudeStore;
    try {
      const { content, last } = (await findAllConvenio(
        search,
        { sortDir: "ASC", sortField: "descricao" },
        true,
        page,
        20
      )).data.data.findAllConvenio;
			const filteredOptions = content.filter((option) => {
				return !profissionalSaude.convenios.some((convenio) => convenio.id === option.id);
			});
      return {
        options: filteredOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch {
      console.error("Erro ao carregar os convênios");
    }
  };

	handleChangeConvenios = (option) => {
		const { profissionalSaudeStore } = this.props;
		const { profissionalSaude } = profissionalSaudeStore;

		if(profissionalSaude?.convenios?.length > 0){
			profissionalSaude.convenios = [...profissionalSaude.convenios, option]
		}else{
			profissionalSaude.convenios = [option]
		}
	}

	handleRemoveConvenio = (id) => {
		const { profissionalSaudeStore } = this.props;
		const { profissionalSaude } = profissionalSaudeStore;
		profissionalSaude.convenios = profissionalSaude.convenios.filter(convenio => convenio.id !== id);
	}

	handleRemoveProcedimento = (id) => {
		const { profissionalSaudeStore } = this.props;
		const { profissionalSaude } = profissionalSaudeStore;
		profissionalSaude.procedimentos = profissionalSaude.procedimentos.filter(procedimento => procedimento.id !== id);
	}

	render() {
		const { classes, profissionalSaudeStore, termoUsoStore, perfilPublicoStore } = this.props;
		const { profissionalSaude, errors } = this.props.profissionalSaudeStore;

		return (
			<div className={classes.tableContainer}>
				<PageTitle title="Dados cadastrais usuário"/>
				<h3
					className={classNames(classes.titleHeader, classes.headerTitleRoot)}
				>
					Dados cadastrais usuário
				</h3>
				<div className={classes.warnings}>
					{!profissionalSaudeStore.isLoadingInfo && 
						profissionalSaude.utilizaVIZI && 
						profissionalSaude.perfilPublico && 
						perfilPublicoStore.perfilProfissionalIsIncomplete && 
						(
							<DadosInsuficientesWarning 
								title={dadosInsuficientesTitle}
								message={dadosInsuficientesMessage}
								buttonText="Preencher Perfil Público"
								onClickButton={() => this.props.history.push('perfil/publico')}
								isPerfilWarning
								requiredFields={perfilPublicoStore.perfilPublicoProfissionalErrors}
							/>
					)}
					{!profissionalSaudeStore.isLoadingInfo &&
						profissionalSaude.perfilPublico && 
						!profissionalSaudeStore.profissionalHasAgenda && 
						(
							<DadosInsuficientesWarning
								title={agendaNaoConfiguradaTitle}
								message={agendaNaoConfiguradaMessage}
								buttonText="Configurar agenda"
								onClickButton={() => this.props.history.push('/configuracoes/horario-atendimento')}
							/>
					)}
				</div>
				<div className={classNames(classes.gridCardContainer, classes.gridCardContainerTemplatColumns)}>
					<div className={classes.cardDadosProfissional}>
						<div>
							<CardDadosCadastrais titulo={"Dados pessoais:"}>
								<ItemGrid size={12} label={"Nome:"} bold>
									<InputForm
										name={"nome"}
										value={profissionalSaude.nome || ""}
										placeholder={"Nome Completo"}
										className={classNames(classes.inputContainer, errors.nome ? classes.inputError : null)}
										onChange={(e) => this.onFieldChange(e.target.value, "nome")}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"Data de nascimento:"} bold>
									<InputDateForm
										name={"dataNascimento"}
										value={profissionalSaude.dataNascimento || ""}
										placeholder={"Data de nascimento"}
										className={classNames(classes.inputContainer, errors.dataNascimento ? classes.inputError : null)}
										iconposition="end"
										onChange={(e) => this.onDataNascimentoChange(e)}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"E-mail: "} bold>
									<InputForm
										name={"E-mail"}
										value={profissionalSaude.usuario?.email || ""}
										placeholder={"E-mail"}
										className={classNames(classes.inputContainer, errors.email ? classes.inputError : null)}
										onChange={(e) => this.onEmailChange(e)}
									/>
								</ItemGrid>
								{ profissionalSaude.possuiAgenda && <div>
									<ItemGrid size={12} label={"Prefixo:"} bold>
										<SelectForm
											allowEmpty
											value={profissionalSaude.prefixo?.prefixo}
											elements={profissionalSaudeStore.prefixosList}
											onChange={(e) => this.onPrefixoChange(e.target.value)}
											className={classes.inputContainer}
										/>
									</ItemGrid>
								</div>}
							</CardDadosCadastrais>
						</div>
						<div>
							<CardDadosCadastrais titulo={"Dados cadastrais: "}>
								<ItemGrid size={12} label={"Conselho"} bold>
									<ReactSelect
										value={profissionalSaudeStore.profissionalSaude.conselhoProfissionalSaude}
										options={profissionalSaudeStore.conselhosList}
										onChange={this.onConselhoChange}
										getOptionLabel={(option) => option.descricao}
										getOptionValue={(option) => option.id}
										className={classes.inputContainer}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"Código CBO:"} bold>
									<TextFieldSearch
										placeholder='Selecione'
										classNotched={classes.notchedOutline}
										classInput={classes.inputContainer}
										classIcons={classes.classIcons}
										loadOptions={this.loadAllTuss24}
										getOptionLabel={option => `${option.termo} - ${option.codigoTermo}`}
										getOptionValue={option => option}
										value={profissionalSaudeStore?.profissionalSaude?.tuss24TerminologiaCBO || ""}
										onChange={this.handleChangeCodigoCBO}
										withPaginate
										debounceTimeout={300}
										additional={{
											page: 0,
										}}
										menuPosition='fixed'
										className={[classes.inputContainer, classes.withoutMarginBottom]}
									/>
								</ItemGrid>
								{ profissionalSaude.possuiAgenda && <div className={classes.conselhoRegionalUF} style={{ columnGap: '8px' }}>
									<ItemGrid size={12} label={"Número do Conselho: "} bold>
										<InputForm
											name={"numeroConselho"}
											value={profissionalSaude.numeroConselho || ""}
											placeholder={"Numero conselho"}
											className={classNames(classes.inputContainer, errors.numeroConselho ? classes.inputError : null)}
											onChange={(e) =>
												this.onFieldChange(e.target.value, "numeroConselho")
											}
										/>
									</ItemGrid>
									<ItemGrid size={12} label={"UF:"} bold>
										<SelectForm
											labelOption={"UF"}
											value={profissionalSaude.ufConselho}
											elements={profissionalSaudeStore.estadosList}
											onChange={(e) =>
												this.onFieldChange(e.target.value, "ufConselho")
											}
											className={classes.inputContainer}
										/>
									</ItemGrid>
								</div>}
								<ItemGrid size={12} label={"CPF: "} bold>
									<InputCPFForm
										name={"cpf"}
										value={profissionalSaude.cpf || ""}
										placeholder={"CPF"}
										showmask={"false"}
										className={classNames(classes.inputContainer, errors.cpf ? classes.inputError : null)}
										onChange={(e) => this.onFieldChange(e.target.value, "cpf")}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"CNS: "} bold>
									<InputForm
										name={"cns"}
										value={profissionalSaude.cns || ""}
										placeholder={"CNS"}
										className={classNames(classes.inputContainer, errors.cns ? classes.inputError : null)}
										onChange={(e) => this.onFieldChange(e.target.value, "cns")}
									/>
								</ItemGrid>
								<ItemGrid size={12} label={"Sala de atendimento: "} bold>
									<InputForm
										name={"Sala"}
										value={profissionalSaude.nomeSala || ""}
										placeholder={"Sala"}
										className={classes.inputContainer}
										onChange={(e) => this.onSalaChange(e)}
									/>
								</ItemGrid>
							</CardDadosCadastrais>
						</div>
					</div>
					<div className={classes.cardDadosProfissional}>
						<div>
							<CardDadosCadastrais titulo={`Portal ${getWhitelabelNome()} Profissional: `} 
								label={ViziCardLabel}
							>
								<PortalViziActivationCard
									variant={this.getPortaViziCardVariant()}
									beforeActivationText={beforeActivationText}
									afterActivationText={afterActivationText}
									onPressActivation={this.handleActivateVizi}
									activated={profissionalSaude.perfilPublico}
									userType="profissional"
									onlyOneText={advertisementText}
								/>
							</CardDadosCadastrais>
						</div>
						{profissionalSaude.perfilPublico && !profissionalSaude.utilizaVIZI && (
							<div>
								<CardDadosCadastrais titulo="Perfil público" subtitle="(Será desativado em breve)">
									<PerfilPublicoCard
										onClickMigrar={() => console.log('Opening migration button')}
									/>
								</CardDadosCadastrais>
							</div>
						)}
						<CardDadosCadastrais titulo={"Convênios e planos de saúde aceitos"}>
            <div>
							<TextFieldSearch
									classNotched={classes.notchedOutline}
									classInput={classes.inputContainer}
									classIcons={classes.classIcons}
									placeholder={"Selecione o procedimento"}
									onChange={(option) => this.handleChangeConvenios(option)}
									value={null}
									menuPlacement={'auto'}
									key={profissionalSaude?.convenios?.length}
									getOptionLabel={(option) => option.descricao}
									getOptionValue={(option) => option.id}
									withPaginate
									loadOptions={this.loadConvenios}
									debounceTimeout={300}
									additional={{
										page: 0,
									}}
							/>
							<div className={classes.listaConveniosSelecionados}>
									{profissionalSaude?.convenios?.length > 0 &&
										profissionalSaude.convenios.map((item, index) => (
											<div className={classes.convenioOption} key={index}>
												{item.descricao}
												<CancelIcon
													onClick={() =>
														this.handleRemoveConvenio(item?.id)
													}
												/>
											</div>
										))}
								</div>
            </div>
          </CardDadosCadastrais>
						<div>
							<CardDadosCadastrais titulo="Ajuste as funcionalidades">
								<ProfissionalSaudeConfigOptions 
									options={informacoesPrincipaisConfigOptions} 
									handleSwitchChange={this.handleSwitchOnChange}
									handleChangeInput={(e, option) => this.handleInputQuantityChange(e, option)}
									isChecked={this.switchIsChecked}
									valueInput={option => this.props.profissionalSaudeStore.profissionalSaude[option.attributeInput]}
									canRender={this.checkIsSwitchAvailableToRender}
								/>
							</CardDadosCadastrais>
						</div>
					</div>
				</div>
				<div className={classes.acoes}>
					<Fab 
						className={classes.buttonSalvar} 
						onClick={this.update}
						disabled={profissionalSaudeStore.loadingSaveDadosPerfil || profissionalSaudeStore.isLoadingInfo}
					>
						<SaveIcon/>
					</Fab>
					<Fab className={classes.buttonCancelar} onClick={this.cancelar}>
						<CloseIcon/>
					</Fab>
				</div>
				<Notification
					close={this.handleClose}
					reset={this.resetNotification}
					isOpen={profissionalSaudeStore.notification.isOpen}
					variant={profissionalSaudeStore.notification.variant}
					message={profissionalSaudeStore.notification.message}
				/>
				{termoUsoStore.isOpenTermoUsoModal && (
					<TermoUsoModal 
						open={termoUsoStore.isOpenTermoUsoModal}
						documento={termoUsoStore.termoUso}
						tipo={"VIZI_PROFISSIONAL_SAUDE"}
						titulo={`Termos e condições da plataforma ${getWhitelabelNome()}`}
						handleAccept={this.handleAssinarTermoUsoVizi}
						handleRefuse={termoUsoStore.closeModal}
					/>
				)}
			</div>
		);
	}
}


export default withStyles(styles)(PerfilDadosPrincipais);
