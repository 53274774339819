import styled from 'styled-components';

export const ClickableTablecell = styled.div`
  min-height: 35px;
  height: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ disabled }) => (disabled ? '#f0f0f0' : '#fff')};
  :hover {
    background-color: ${({ disabled }) => (disabled ? '' : 'rgba(33, 154, 151, 0.8)')};
    transition: all 0.5s ease-in-out;
    border-radius: 8px;
    color: white;
    label {
      color: white;
      font-weight: 500;
    }
  }
`;

export const TableCellText = styled.label`
  color: #505050;
  font-size: 12px;
  font-weight: 400;
`;

export const CellDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  padding: 4px;
`;
