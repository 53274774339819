import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../components/Icon/CloseIcon";
import { findByIdSujeitoAtencao } from "../../../services/SujeitoAtencaoService";

const MensagemTeleconsulta = (props) => {
  const { classes, permissoes, handleCloseWarning } = props;
  const [sujeitoAtencaoNome, setSujeitoAtencaoNome] = useState("");

  let mensagemPermissao = "";
  const { permissaoCamera, permissaoMicrofone } = permissoes;

  useEffect(() => {
    getSujeitoAtencaoNome();
  }, []);

  const getSujeitoAtencaoNome = async () => {
    const dadosTeleconsulta = JSON.parse(
      localStorage.getItem("dadosTeleconsulta")
    );
    const response = await findByIdSujeitoAtencao(
      dadosTeleconsulta.sujeitoAtencaoId
    );

    const { id, nome } = response?.data?.data?.findByIdSujeitoAtencao;
    setSujeitoAtencaoNome(id ? nome : "");
  };

  if (!permissaoCamera && !permissaoMicrofone) {
    mensagemPermissao = "câmera e microfone";
  } else {
    mensagemPermissao = !permissaoCamera ? "câmera" : "microfone";
  }

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <div className={classes.title}>
          O atendimento se iniciará em instantes...
        </div>
        <div className={classes.icon} onClick={handleCloseWarning}>
          <CloseIcon size={14} color="#505050" />
        </div>
      </div>
      <div className={classes.body}>
        <p className={classes.informacao}>
          {`Estamos esperando o seu paciente ${
            sujeitoAtencaoNome ? sujeitoAtencaoNome + "," : ""
          } configurar a ${mensagemPermissao} para iniciar a tele atendimento.`}
        </p>
        <p>Aproveite para verificar seus dispositivos de áudio e vídeo.</p>
      </div>
    </div>
  );
};

const style = {
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    columnGap: "10px",
  },
  icon: {
    "&hover": {
      cursor: "pointer",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: "30%",
    left: "50%",
    zIndex: 100,
    background: "#fff",
    padding: "16px",
    borderRadius: "16px",
    width: "285px",
    height: "fit-content",
    boxShadow: "0px 4px 16px rgb(0 0 0 / 15%)",
    gap: "16px",
  },
  title: {
    color: "#505050",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "21px",
    fontFamily: "Poppins",
  },
  body: {
    "& p": {
      fontSize: "14px",
      fontFamily: "Poppins",
    },
  },
  informacao: {
    marginBottom: "12px",
  },
};

export default withStyles(style)(MensagemTeleconsulta);
