import Colors from "../../../template/Colors";

const style = {
  fullSizePaper: {
    display: "flex",
    borderRadius: 10,
    overflow: "unset",
    height: '625px',
    flexDirection: "row",
  },
  contentNovoLancamento: {
    display: "flex",
    padding: 20,
    borderRadius: "0px 10px 10px 0px",
    background: Colors.commons.padrao,
    width: "100%",
    maxWidth: "275px",
  },
  contentModal: {
    width: "100%",
    height: 'calc(100% - 270px)'
  },
  header: {
    display: "flex",
    flexDirection: "column",
    padding: 32,
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
  },
  tituloHeader: {
    fontSize: 18,
    fontWeight: 700,
    color: Colors.commons.secondary,
    borderRadius: "10px 10px 0 0",
  },
  headerTitleButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonHeader: {
    width: "35px",
    height: "35px",
    color: "#fff",
    marginLeft: 10,
    boxShadow: "none",
  },
  buttonActionSave: {
    background: Colors.commons.secondary,
    "&:hover": {
      background: Colors.primary.dark,
    },
  },
  buttonActionClose: {
    background: Colors.error.main,
    "&:hover": {
      background: Colors.error.dark,
    },
  },
  titulo: {
    color: Colors.commons.gray7,
    fontSize: "12px !important",
  },
  dadosPacienteFoto: {
    display: "flex",
    width: "75%",
  },
  nomePaciente: {
    color: Colors.commons.gray9,
    fontWeight: 700,
    height: "32px",
    alignItems: "center",
    display: "flex",
  },
  boxDadosPaciente: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 14,
  },
  dadosPaciente: {
    display: "flex",
  },
  dadosPacienteItem: {
    fontSize: "12px !important",
    height: "21px",
    alignItems: "center",
    marginRight: 8,
    "&>span": {
      fontSize: "12px !important",
      fontWeight: 700,
      color: Colors.commons.gray9,
    },
  },
  content: {
    padding: "30px 15px 15px 15px",
    height: "100%",
  },
  boxTab: {
    padding: 16,
    border: "1px solid #F2F2F2",
    marginTop: 15,
    borderRadius: 10,
    maxHeight: "340px",
    maxWidth: 580,
    height: "100%",
    overflow: 'auto',
  },
  spacing: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 14,
  },
  inputRootBlockField: {
    paddingLeft: 0,
    border: "none",
    fontSize: 14,
  },

  inputAlturaPeso: {
    backgroundColor: Colors.commons.gray2,
    borderRadius: 100,
    justifyContent: "center",
    width: "100%",
    border: "none",
    height: 32,
  },

  inputBlockField: {
    fontFamily: "Poppins !important",
    padding: "5px 0",
    textAlign: "center",
    color: Colors.commons.gray7,
  },
  tituloNovoLancamento: {
    fontSize: 18,
    fontWeight: 700,
    color: "#fff",
    marginBottom: "7px",
  },
  contentAvaliacao: {
    width: "100%",
    display: "grid",
    maxHeight: "100%",
    gridTemplateRows: "auto 1fr 40px",
  },
  contentAvaliacaoSemBotoes: {
    gridTemplateRows: "auto 1fr",
  },
  contentDadosAvaliacao: {
    background: Colors.commons.grayLight,
    borderRadius: "13px",
    padding: "14px 14px 0 14px",
    overflow: "auto",
  },
  inputImc: {
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "100px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  buttonEnviar: {
    width: "145px",
    marginLeft: "10px",
  },
  imageMini: {
    position: "relative",
    borderRadius: "30px",
  },
  buttonsAcaoNovoLancamento: {
    borderRadius: "100px",
    minHeight: 37,
    width: "100%",
    border: 0,
    color: Colors.commons.white,
    textTransform: "none",
    whiteSpace: "nowrap",
    padding: "0px 16px",
    "& span": {
      fontSize: 14,
      fontWeight: 700,
    },
    "&:hover": {
      color: Colors.commons.white,
    },
  },
  buttonCancelarNovoLancamento: {
    background: Colors.error.main,
    marginRight: 8,
    "&:hover": {
      background: Colors.error.dark,
    },
  },
  buttonGreenNovoLancamento: {
    background: Colors.commons.secondary,
  },
  contentButtonsActionNovoLancamento: {
    display: "flex",
    marginTop: "10px",
  },
  dadoPacienteButton: {
    display: "flex",
  },
  divButtonNovoLancamento: {
    alignSelf: "end",
    width: "25%",
  },
  buttonNovoLancamento: {
    background: Colors.commons.secondary,
    "&:hover": {
      background: Colors.primary.dark,
    },
  },
  circularCarrega: {
    marginLeft: 5,
  },
  informeLancamentoInativado: {
    fontSize: 14,
    fontWeight: 400,
    "& span": {
      fontWeight: 700,
    },
  },
  buttonClose: {
    position: "absolute",
    right: "15px",
    top: 18,
    minHeight: 26,
    height: 32,
    width: 32,
    backgroundColor: Colors.commons.gray,
    color: Colors.commons.secondary,
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.commons.gray4,
    },
  },
  buttonGray: {
    width: "40px",
    height: "40px",
    minHeight: "1px",
    background: "#677594",
    margin: "4px 0",
    position: "absolute",
    bottom: 7,
    right: 330,
    "&:hover": {
      background: "#515E79",
    },
  },
  buttonGraySelecionado:{
    background: "#515E79",
  },
  scrollContainerModelos: {
    width: '100%',
    flex: 1,
    height: '100%',
    overflow: 'auto'
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '80%',
    marginTop: 100,
  },
  mensagemDeListaVazia: {
    color: "#707C97",
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  }
};

export default style;
