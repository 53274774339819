import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import PrintIcon from "../../../components/Icon/Print";
import PencilIcon from "../../../components/Icon/Pencil";
import CloseIcon from "../../../components/Icon/CloseIcon";
import ArrowDownloadIcon from "../../../components/Icon/ArrowDownload";
import PopperCustomMenu from "../../../components/Popper/PopperCustomMenu";
import { propsModalSolicitacoesPacientesDefault } from "../../../stores/SolicitacoesPacientes.store";
import { Button } from "../../../components/ui/Buttons";
import MoreIcon from "../../../components/Icon/More";

const HeaderSolicitacoesPacientes = observer(
  ({ classes, solicitacoesPacientesStore }) => {
    const { propsModalSolicitacoesPacientes } = solicitacoesPacientesStore;
    const { dados, edit } = propsModalSolicitacoesPacientes;

    const menuOpcoesPopper = [
        {
          ativo: dados.arquivoTipo === "ANAMNESE" && !edit,
          label: "Editar",
          onClick: () => solicitacoesPacientesStore.handleEditar(dados),
          icon: <PencilIcon color="#505050" />,
        },
        {
          ativo: dados.arquivoTipo === "IMAGEM" || dados.arquivoTipo === "ANAMNESE",
          label: "Imprimir",
          onClick: () => solicitacoesPacientesStore.handleImprimir(dados),
          icon: <PrintIcon color="#505050" />,
        },
        {
          ativo: dados.arquivoTipo !== "ANAMNESE",
          label: "Baixar",
          onClick: () => solicitacoesPacientesStore.handleDownload(dados),
          icon: <ArrowDownloadIcon color="#505050" />,
        },
      ];

    const closeModal = () => {
      solicitacoesPacientesStore.propsModalSolicitacoesPacientes = propsModalSolicitacoesPacientesDefault;
    };

    return (
      <div className={classes.header}>
        <div className={classes.nome}>{dados?.amazonS3Objeto?.nome || dados?.nome}</div>
        <div className={classes.buttonsHeader}>
          { !propsModalSolicitacoesPacientes.edit && <PopperCustomMenu
            placement={"bottom-end"}
            menuOpcoes={menuOpcoesPopper}
            iconButton={<MoreIcon color="#fff"/>}
          />}
          <Button
             shape='circle'
             bgColor='#FB7676'
            onClick={closeModal} 
          >
            <CloseIcon color="#fff" />
          </Button>
        </div>
      </div>
    );
  }
);

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 32px 8px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    background: "#fff",
  },
  nome: {
    color: "#26ACA9",
    fontWeight: 700,
    fontSize: "18px",
  },
  buttonsHeader: {
    display: "flex",
    gap: "8px",
  },
};

const HeaderSolicitacoesPacientesWithStyles = withStyles(styles)(
  HeaderSolicitacoesPacientes
);
export default inject("solicitacoesPacientesStore")(
  HeaderSolicitacoesPacientesWithStyles
);
