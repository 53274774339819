import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { inject, observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import ListarProcedimentosRealizados from "./ListarProcedimentosRealizados";
import {
  findAllProcedimentosRealizados,
  saveProcedimentoRealizado

} from "../../../services/ProcedimentosRealizadosService";
import Notification from "../../../components/Notification";
import ModalProcedimentosRealizados from "./ModalProcedimentosRealizados";
import ModalFaturarProcedimentoRealizado from "../../../components/Modal/ModalFaturarProcedimentoRealizado";
import ModalConvenios from "../Manutencao/ModalConvenios";
import { findByIdConvenio } from "../../../services/ConvenioService";
import { findAllProfissionalSaude } from "../../../services/ProfissionalSaudeService";
import { tipoProfissionalLogado } from "../../../services/UsuarioService";
import PageTitle from "../../../components/PageTitle/PageTitle";

@inject("procedimentosRealizadosStore", "sujeitoAtencaoStore", "extratoStore", "atendimentoStore")
@observer
class ProcedimentosRealizados extends React.Component {
  state = {
    procedimentosRealizadosLoading: false,
    notification: {
      isOpen: false,
      message: ''
  },
    ordenarProcedimentoRealizado: true,
    ordenarSala: true,
    ordenarData: true,
    ordenarProfissionalResponsavel: true,
    showModal: false,
    idProcedimentoRealizado: "",
    showModalFaturar: false,
    showModalConvenios: false,
    convenio: "none",
    savingConvenio: false,
    procedimentoSelecionado: null,
    valorProcedimentoRealizadoSelecionado: 0,
    ordenarTabela: {
      sortField: "dataHoraLancamento",
      sortDir: "DESC",
    },
    checkedHeader: false,
    isIndeterminanteCheckBox: false,
    recebedor: "none",
    tipoProfissionalLogado: "",
  };

  async componentDidMount() {
    const { procedimentosRealizadosStore } = this.props;
    procedimentosRealizadosStore.lista = [];
    procedimentosRealizadosStore.pageNumber = 0;
    procedimentosRealizadosStore.last = false;

    const tipoProfissional = await tipoProfissionalLogado();
    this.setState({ tipoProfissionalLogado: tipoProfissional})

    this.loadProcedimentosRealizados();
  }

  loadProcedimentosRealizados = () => {
    const { procedimentosRealizadosStore } = this.props;
    const {
      pageNumber,
      sortDirProcedimentosRealizados,
      sortFieldProcedimentosRealizados,
    } = procedimentosRealizadosStore;
    procedimentosRealizadosStore.loading = true;
    findAllProcedimentosRealizados({
      search: procedimentosRealizadosStore.search,
      idSujeitoAtencao: this.props.match.params.id,
      pageableDTO: {
        pageSize: 30,
        pageNumber: pageNumber || 0,
        sortDir: sortDirProcedimentosRealizados || "DESC",
        sortField: sortFieldProcedimentosRealizados || "dataHoraLancamento",
      },
    })
      .then((response) => {
        const {
          content,
          last,
        } = response.data.data.findAllProcedimentoRealizado;
        const listaAdaptado = content.map((item) => ({
          ...item,
          checkedFaturar: false,
        }));
        procedimentosRealizadosStore.lista = [
          ...procedimentosRealizadosStore.lista,
          ...listaAdaptado,
        ];
        procedimentosRealizadosStore.last = last;
      })
      .catch(() => {
        this.openNotification(
          "Erro ao carregar a lista de procedimentos realizados.",
          "error"
        );
      })
      .finally(() => {
        procedimentosRealizadosStore.loading = false;
      });
  };

  handleClickRow = (id) => (event) => {
    this.setState({
      idProcedimentoRealizado: id,
      showModal: true,
    });
  };

  handleClickOrdenar = async(value) => {
    const { procedimentosRealizadosStore } = this.props;
    const { ordenarTabela } = this.state;

    const sortDir = ordenarTabela.sortField !== value ? "ASC" : (ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC")

    procedimentosRealizadosStore.sortDirProcedimentosRealizados = sortDir

    this.setState({
      ordenarTabela: { 
        sortDir: sortDir,
        sortField: value,
      }
    });

    procedimentosRealizadosStore.sortFieldProcedimentosRealizados =
      value === "sala" || value === "profissionalSaude" || value === "procedimento"
        ? `${value}.nome`
        : value;

    procedimentosRealizadosStore.lista = [];
    procedimentosRealizadosStore.pageNumber = 0;
    this.loadProcedimentosRealizados();
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  openNotification = (message, variant = null) => {
    this.setState({
      notification: {
        isOpen: true,
        message: message,
        variant: variant,
      },
    });
  };

  closeNotification = () => {
    this.setState({
      notification: {
        isOpen: false,
      },
    });
  };

  resetNotification = () => {
    this.setState({
      notification: {
        isOpen: false,
        message: "",
        variant: "",
      },
    });
  };

  loadMore = () => {
    const { procedimentosRealizadosStore } = this.props;
    if (procedimentosRealizadosStore.loading) {
      return;
    }

    procedimentosRealizadosStore.pageNumber =
      procedimentosRealizadosStore.lista.length > 0
        ? procedimentosRealizadosStore.pageNumber + 1
        : 0;

    this.loadProcedimentosRealizados();
  };

  handleChangeCheckbox = (itemSelecionado) => {
    const { procedimentosRealizadosStore } = this.props;
    const lista = procedimentosRealizadosStore.lista.map((item) => {
      if (item.id === itemSelecionado.id) {
        return {
          ...item,
          checkedFaturar: !item.checkedFaturar,
        };
      }
      return {
        ...item,
      };
    });
    procedimentosRealizadosStore.lista = lista;
  };

  onClickFaturar = async () => {
    const { procedimentosRealizadosStore } = this.props;

    const procedimentosRealizados = await procedimentosRealizadosStore.lista.filter(
      (item) => item.checkedFaturar
    );

    if(procedimentosRealizados.length === 0){
      this.openNotification(
        "Favor selecionar os procedimentos para realizar o faturamento.",
        "error"
      );
      return
    };

    this.setState({ showModalFaturar: true });
  };

  closeModalFaturar = () => {
    this.setState({ showModalFaturar: false });
  };

  goToFinanceiro = async () => {
    const { history, extratoStore } = this.props;
    await extratoStore.findAll();
    history.push("/financeiro");
  }

  handleClickConvenioValor = (procedimentoRealizado) => {
    if(procedimentoRealizado?.titulo?.id){
      return
    }
    const procedimentoConvenio = !!procedimentoRealizado?.sujeitoAtencao?.dadosConvenio?.convenio?.id && procedimentoRealizado.procedimento.convenioProcedimentos.find(
      (itemProcedimento) =>
        itemProcedimento?.convenio?.id ===
        procedimentoRealizado.sujeitoAtencao.dadosConvenio.convenio.id
    );
    const valorProcedimento = !!procedimentoConvenio
      ? procedimentoConvenio.valor
      : 0;
    this.setState({
      procedimentoSelecionado: procedimentoRealizado,
      valorProcedimentoRealizadoSelecionado:
        procedimentoRealizado.valor === 0 ? 0 : procedimentoRealizado.valor || valorProcedimento,
      convenio: {
        value:
          procedimentoRealizado?.convenio?.id ||
          procedimentoRealizado?.sujeitoAtencao?.dadosConvenio?.convenio?.id,
        label:
          procedimentoRealizado?.convenio?.descricao ||
          procedimentoRealizado?.sujeitoAtencao?.dadosConvenio?.convenio
            ?.descricao,
      },
      recebedor: {
        ...procedimentoRealizado?.recebedor,
        label: procedimentoRealizado?.recebedor?.nome,
        value: procedimentoRealizado?.recebedor?.id
      },
      showModalConvenios: true,
    });
  };

  closeModalConvenios = () => {
    this.setState({ showModalConvenios: false });
  };

  onConvenioChange = async (convenio, procedimentoRealizadoSelecionado) => {

    const convenioSelecionado = await findByIdConvenio(convenio.value);
    const idProcedimentoRealizadoSelecionado = procedimentoRealizadoSelecionado.procedimento.id;
    const listaConvenioProcedimentos = convenioSelecionado.convenioProcedimentos; 

    const procedimentoConvenio = !!listaConvenioProcedimentos && listaConvenioProcedimentos.find(
      (itemConvenioProcedimentos) =>
        itemConvenioProcedimentos?.procedimento?.id ===
        idProcedimentoRealizadoSelecionado
    );
    const valorProcedimento = !!procedimentoConvenio
      ? procedimentoConvenio.valor
      : 0;

    this.setState({
      convenio: convenio,
      valorProcedimentoRealizadoSelecionado: valorProcedimento,
    });
  };

  onValorChange = (e) => {
    let value = e.target.value;
    this.setState({ 
      valorProcedimentoRealizadoSelecionado: value
    });
  };

  onRecebedorChange = (recebedor) => {
    this.setState({
      recebedor: recebedor
    })
  };

  showAlertMessage = (notification) => {
    this.setState({ notification });
    
    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: ''
    };
    this.setState({ notification });
  };

  salvarConvenio = async() => {
    this.setState({ savingConvenio: true });
    const { procedimentosRealizadosStore } = this.props;
    const {
      procedimentoSelecionado,
      convenio,
      valorProcedimentoRealizadoSelecionado,
    } = this.state;

    if (!this.state.recebedor?.id){
      const notification = {
        isOpen: true,
        message: 'Favor preencher o recebedor.'
      }
      this.showAlertMessage(notification);
      this.setState({ savingConvenio: false });
      return
    }

    const valorAdaptado =
      typeof valorProcedimentoRealizadoSelecionado === "string"
        ? +valorProcedimentoRealizadoSelecionado
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
        : valorProcedimentoRealizadoSelecionado;

    await saveProcedimentoRealizado({
      idProcedimentoRealizado: procedimentoSelecionado.id,
      valor: valorAdaptado,
      convenio: convenio.value ? {
        id: convenio.value,
      } : null,
      recebedor: {
        id: this.state.recebedor?.id
      }
    });

    procedimentosRealizadosStore.lista = [];
    procedimentosRealizadosStore.pageNumber = 0;
    procedimentosRealizadosStore.last = false;
    
    this.loadProcedimentosRealizados();
    this.setState({ 
      savingConvenio: false,
      showModalConvenios: false 
    });
  };

  handleRecebedorLoadOptions = async (search, loadedOptions, { page }) => {
    return this.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
    });
  };

  handleLoadMoreOptions = async ({ search, data }) => {
    const response = await findAllProfissionalSaude({
      search,
      pageNumber: data.page
    });

    const { content, last } = response.data.data.findAllProfissionalSaude;
    const options = content.map(option => ({
      ...option,
      value: option.id,
      label: option.nome,
    }))

    return {
      options,
      hasMore: !last,
      additional: {
        page: data.page + 1
      }
    };
  };

  render() {
    const { classes, procedimentosRealizadosStore } = this.props;

    return (
      <Grid className={classes.content}>
        <PageTitle title="Paciente - Procedimentos realizados"/>
        <ListarProcedimentosRealizados
          handleClickRow={this.handleClickRow}
          isLoading={procedimentosRealizadosStore.loading}
          lastScroll={procedimentosRealizadosStore.last}
          handleClickOrdenar={this.handleClickOrdenar}
          ordenarProcedimentoRealizado={this.state.ordenarProcedimentoRealizado}
          ordenarSala={this.state.ordenarSala}
          ordenarData={this.state.ordenarData}
          ordenarProfissionalResponsavel={
            this.state.ordenarProfissionalResponsavel
          }
          loadMore={this.loadMore}
          handleChangeCheckbox={this.handleChangeCheckbox}
          handleClickConvenioValor={(procedimentoRealizado) =>
            this.handleClickConvenioValor(procedimentoRealizado)
          }
          ordenarTabela={this.state.ordenarTabela}
          onClickFaturar={this.onClickFaturar}
          tipoProfissionalLogado={this.state.tipoProfissionalLogado}
        />
        {this.state.showModal && (
          <ModalProcedimentosRealizados
            handleClose={this.handleCloseModal}
            idSujeitoAtencao={this.props.match.params.id}
            showModal={this.state.showModal}
            idProcedimentoRealizado={this.state.idProcedimentoRealizado}
            isAtendimentoProntuario={false}
            pageTitle="Paciente - Visualizar procedimento realizado"
          />)}
         <Notification
              close={() => this.closeNotification()}
              reset={() => this.resetNotification()}
              isOpen={this.state.notification.isOpen}
              variant={this.state.notification.variant}
              message={this.state.notification.message}
          />
        {this.state.showModalFaturar && (
          <ModalFaturarProcedimentoRealizado
            open={this.state.showModalFaturar}
            closeModal={this.closeModalFaturar}
            goToFinanceiro={this.goToFinanceiro}
          />
        )}
        {this.state.showModalConvenios && !this.props.sujeitoAtencaoStore.isSujeitoAtencaoUnificado && (
          <ModalConvenios
            open={this.state.showModalConvenios}
            closeModal={this.closeModalConvenios}
            onConvenioChange={this.onConvenioChange}
            convenio={this.state.convenio}
            saving={this.state.savingConvenio}
            handleSalvar={this.salvarConvenio}
            valorProcedimentoRealizado={
              this.state.valorProcedimentoRealizadoSelecionado
            }
            onValorChange={this.onValorChange}
            procedimentoRealizadoSelecionado={this.state.procedimentoSelecionado}
            onRecebedorChange={this.onRecebedorChange}
            recebedor={this.state.recebedor}
            handleRecebedorLoadOptions={this.handleRecebedorLoadOptions}
          />
        )}
        <Notification
            close={this.closeAlertMessage}
            reset={this.closeAlertMessage}
            isOpen={this.state.notification.isOpen}
            variant={'error'}
            message={this.state.notification.message}
        />
      </Grid>
    );
  }
}

const style = {
  content: {
    overflow: 'auto',
  }
};

export default withStyles(style)(ProcedimentosRealizados);
