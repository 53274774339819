import React from "react";
import MaskedInput from "react-text-mask";
import string from "./string";

export const tokenMask = (props) => {
    const { inputRef, ...other } = props;

    return (<MaskedInput placeholderChar={'\u2000'}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        showMask={props.showmask}
        mask={[
            /\d/, /\d/, /\d/,
            ' ',
            /\d/, /\d/, /\d/]}
        {...other}
    />);
};

export const reomveInputSpecialChars = (e) => {
    if (string.removeSpecialChars(e.target.value).length === 0)
        e.target.setSelectionRange(0, 0);
}