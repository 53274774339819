import { action, observable } from "mobx";
import moment from "moment";
import {
  findAllAvaliacaoAntropometricaMediaDiaria,
  buscaDadosGrafico
} from "../services/AvaliacaoAntropometricaService";

const tiposNiveisGraficoZscore = [
  "MENOS_3",
  "MENOS_2",
  "MENOS_1",
  "MEDIA",
  "MAIS_1",
  "MAIS_2",
  "MAIS_3",
];

const tiposNiveisGraficoPercentiles = [
  "PORCENTAGEM_3", 
  "PORCENTAGEM_15", 
  "PORCENTAGEM_50", 
  "PORCENTAGEM_85", 
  "PORCENTAGEM_97"
];

const dadosPacienteVazio = [];

class AvaliacaoAntropometricaStore {
  @observable graficoCrescimentoInfantilOMSEstatura = [];
  @observable graficoCrescimentoInfantilOMSPeso = [];
  @observable graficoCrescimentoInfantilOMSPerimetroCefalico = [];
  @observable dataFimFiltroGrafico = moment().endOf('month');
  @observable dataInicioFiltroGrafico = moment().startOf('month').subtract(5, "years");
  @observable dataInicioFiltroGraficoSemanal = moment().isoWeekday(1);
  @observable dataFimFiltroGraficoSemanal = moment().isoWeekday(5);
  @observable isTipoGraficoSemanal = false;

  listarPorTipo = (lista, field, tipo) => {
    return lista
      .filter((item) => item.tipo === tipo)
      .map((item) => {
        return {
          tipo: item.tipo,
          [field]: item[field],
          data: moment(item.data).format("MM/YYYY"),
        };
      });
  };

  @action findAllAvaliacaoAntropometricaMediaDiaria = async ({
    ativo,
    sujeitoAtencaoId,
  }) => {
    this.limparListas();
    const response = await findAllAvaliacaoAntropometricaMediaDiaria({
      ativo,
      sujeitoAtencaoId,
      dataInicial: moment(this.dataInicioFiltroGraficoSemanal).format("YYYY-MM-DD"),
      dataFinal: moment(this.dataFimFiltroGraficoSemanal).format("YYYY-MM-DD"),
    });

    if (response.length > 0) {
      this.graficoCrescimentoInfantilOMSEstatura.push(
        this.listarPorCampo(response, "estatura", this.isTipoGraficoSemanal ? "DD/MM/YYYY" : "MM/YYYY")
      );
      this.graficoCrescimentoInfantilOMSPeso.push(
        this.listarPorCampo(response, "peso", this.isTipoGraficoSemanal ? "DD/MM/YYYY" : "MM/YYYY")
      );
      this.graficoCrescimentoInfantilOMSPerimetroCefalico.push(
        this.listarPorCampo(response, "perimetroCefalico", this.isTipoGraficoSemanal ? "DD/MM/YYYY" : "MM/YYYY")
      );
    } else {
      this.adicionarListaPadraoPaciente();
    };

  };


  listarPorCampo = (lista, field, mask) => {
    return lista
      .filter((item) => item[field])
      .map((item) => {
        return {
          tipo: "Paciente",
          [field]: item[field],
          data: moment(item.data).format(mask),
        };
      });
  };

  @action limparListas = () => {
    this.graficoCrescimentoInfantilOMSEstatura = [];
    this.graficoCrescimentoInfantilOMSPeso = [];
    this.graficoCrescimentoInfantilOMSPerimetroCefalico = [];
  };

  reducer = (previousValue, currentValue) => {
    if (previousValue < currentValue) {
      return previousValue;
    }
    return currentValue;
  };

  @action verificaMenorValorEstatura = () => {
    const listaDadoEstatura = this.graficoCrescimentoInfantilOMSEstatura.map(
      (item) => {
        if (item.length === 0) {
          return 0;
        }
        return item
          .map((element) => {
            return element.estatura;
          })
          .reduce(this.reducer);
      }
    );

    const menorValor =
      listaDadoEstatura.length >= 1 && listaDadoEstatura.reduce(this.reducer);
    return menorValor;
  };

  @action verificaMenorValorPerimetroCefalico = () => {
    const listaDadoPerimetroCefalico = this.graficoCrescimentoInfantilOMSPerimetroCefalico.map(
      (item) => {
        if (item.length === 0) {
          return 0;
        }
        return item
          .map((element) => {
            return element.perimetroCefalico;
          })
          .reduce(this.reducer);
      }
    );

    const menorValor =
      listaDadoPerimetroCefalico.length >= 1 &&
      listaDadoPerimetroCefalico.reduce(this.reducer);
    return menorValor;
  };

  @action verificaMenorValorPeso = () => {
    const listaDadoPeso = this.graficoCrescimentoInfantilOMSPeso.map((item) => {
      if (item.length === 0) {
        return 0;
      }
      return item
        .map((element) => {
          return element.peso;
        })
        .reduce(this.reducer);
    });

    const menorValor =
      listaDadoPeso.length >= 1 && listaDadoPeso.reduce(this.reducer);
    return menorValor;
  };

  @action buscaDadosGrafico = async({sujeitoAtencao, tipo}) => {
    this.limparListas();
    const response = await buscaDadosGrafico({
      searchDTO: {
        ativo: true,
        sujeitoAtencaoId: sujeitoAtencao.id,
        dataInicial: moment(this.dataInicioFiltroGrafico).startOf('month').format('YYYY-MM-DD'),
        dataFinal: moment(this.dataFimFiltroGrafico).endOf('month').format('YYYY-MM-DD')
      },
        pageableDTO: {sortDir: "ASC", sortField: "mes"},
        dataNascimento: moment(sujeitoAtencao?.dataNascimento).format('YYYY-MM-DD'),
        sexo: sujeitoAtencao?.sexo?.descricao,
        tipo,
        dataFim: moment(this.dataFimFiltroGrafico).format("YYYY-MM-DD"),
        dataInicio: moment(this.dataInicioFiltroGrafico).format("YYYY-MM-DD")
      }
    );

    if (!response) {
      return;
    }

  const { paciente, grafico } = response;
  const listaTipoNiveisGrafico = tipo === "ZSCORE" ? tiposNiveisGraficoZscore : tiposNiveisGraficoPercentiles;

    if (paciente?.length > 0) {

      this.graficoCrescimentoInfantilOMSEstatura.push(
        this.listarPorCampo(paciente, "estatura", this.isTipoGraficoSemanal ? "DD/MM/YYYY" : "MM/YYYY")
      );
      this.graficoCrescimentoInfantilOMSPeso.push(
        this.listarPorCampo(paciente, "peso", this.isTipoGraficoSemanal ? "DD/MM/YYYY" : "MM/YYYY")
      );
      this.graficoCrescimentoInfantilOMSPerimetroCefalico.push(
        this.listarPorCampo(paciente, "perimetroCefalico", this.isTipoGraficoSemanal ? "DD/MM/YYYY" : "MM/YYYY")
      );
    } else {
      this.adicionarListaPadraoPaciente();
    }

    if (grafico.length > 0) {
      listaTipoNiveisGrafico.forEach((item) =>
        this.graficoCrescimentoInfantilOMSEstatura.push(
          this.listarPorTipo(grafico, "estatura", item)
        )
      );
      listaTipoNiveisGrafico.forEach((item) =>
        this.graficoCrescimentoInfantilOMSPeso.push(
          this.listarPorTipo(grafico, "peso", item)
        )
      );
      listaTipoNiveisGrafico.forEach((item) =>
        this.graficoCrescimentoInfantilOMSPerimetroCefalico.push(
          this.listarPorTipo(grafico, "perimetroCefalico", item)
        )
      );
    }
  }

  adicionarListaPadraoPaciente = () => {
    this.graficoCrescimentoInfantilOMSEstatura.push(dadosPacienteVazio);
    this.graficoCrescimentoInfantilOMSPeso.push(dadosPacienteVazio);
    this.graficoCrescimentoInfantilOMSPerimetroCefalico.push(dadosPacienteVazio);
  };

  @action alteraDataInicioFinal = (dataInicio, dataFim) => {
    this.dataInicioFiltroGrafico = dataInicio;
    this.dataFimFiltroGrafico = dataFim;
  };
}

export default AvaliacaoAntropometricaStore;
