import React from "react";
import { observer } from "mobx-react-lite";
import { Tooltip, withStyles } from "@material-ui/core";
import CloseIcon from "../../../../../components/Icon/Close";
import { Button } from "../../../../../components/ui/Buttons";

const HeaderModal = observer(
  ({ classes, title, onClose,tooltip }) => {
    return (
      <div className={classes.header}>
        <Tooltip title={tooltip} placement={'top'}>
          <div className={classes.title}> {title} </div>
        </Tooltip>
        <Button
          shape='circle'
          bgColor='#FB7676'
          onClick={onClose}
        >
          <CloseIcon color="#fff" />
        </Button>
      </div>
    );
  }
);

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: "#333333",
    fontWeight: 700,
    fontSize: "18px",
  },
};

export default withStyles(styles)(HeaderModal);
