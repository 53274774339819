export const getMinandMaxTime = (times) => {
  const timeObjects = times.map(time => {
    const [hours, minutes] = time.split(':');
    return new Date(1970, 0, 1, hours, minutes);
  });

  const minTime = new Date(Math.min(...timeObjects)).toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: false});
  const maxTime = new Date(Math.max(...timeObjects)).toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: false});

  return {minTime, maxTime};
}

export const getCheckedTimes = (times) => {
  return times.reduce((acc, time) => {
    if (time.checked && time.value !== 'Dia todo') {
        acc.push(time.value);
    }
    return acc;
  }, []);
}
