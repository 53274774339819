import colors from '../../../template/Colors';

const styles = {
    dialogHeader: {
        fontWeight: 600,
        color: colors.commons.padrao,
        display: 'flex',
        height: '72px',
        alignItems: 'center',
        padding: '0 24px',
        justifyContent: 'space-between',
    },
    headerTitle: {
        fontSize: 18,
    },
    scrollPaper: {
        alignItems: "initial",
    },
    paper: {
        borderRadius: 16,
        overflow: "unset",
        height: 630,
    },
    title: {
        display: 'flex',
    },
    versao: {
        fontWeight: 600,
    },
    dataHoraLancamento: {
        marginLeft: 24,
    },
    dialogContent: {
        overflow: 'hidden',
        height: 'calc(100% - 85px)',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        height: '100%',
        overflow: 'auto',
    },
    buttonConfirm: {
        display: 'flex',
        marginTop: '16px',
        width: '35%',
        alignSelf: 'end',
    },
    circularLoading: {
        marginLeft: 8,
    },
    buttonActionClose: {
        backgroundColor: colors.commons.red,
        width: '35px',
        height: '35px',
        color: 'white',
        marginLeft: 10,
        '&:hover': {
        background: colors.commons.darkRed,
        },
    },
    notFoundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
        margin: 10,
    }

}

export default styles;