import React from "react";
import { inject, observer } from "mobx-react";
import { CircularProgress, withStyles } from "@material-ui/core";
import Table from "../../../components/Table/Table";
import AddIcon from "../../../components/Icon/AddIcon";
import Notification from '../../../components/Notification';
import Scroll from "../../../components/InfiniteScroll/Scroll";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import { tabs } from "../../../stores/ModalConfiguracoes.store";
import ContentConfiguracoes from "../Components/ContentConfiguracoes";
import ProcedimentoList from "../Components/Modal/Tabs/ProcedimentoList";
import { columnsConfiguracoes, tabsModal } from "../Constants/Constants";
import { findAllProcedimentos } from '../../../services/ProcedimentoService';
import ModalConfiguracoesTabs from "../Components/Modal/ModalConfiguracoesTabs";
import ProfissionalSaudeList from "../Components/Modal/Tabs/ProfissionalSaudeList";
import { findAllSala, findByIdSala, salaNomeJaExistente, saveSala } from "../../../services/SalaService";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import { findAllProfissionalSaudeProcedimento } from '../../../services/ProfissionalSaudeService';
import { findAllSalaAuditBySalaId, findAllSalaAuditByUnidade } from "../../../services/AuditoriaService";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import WarningIcon from "../../../components/Icon/Warning";
import string from "../../../utils/string";
import { Button } from "../../../components/ui/Buttons";

@inject('modalConfiguracoesStore', 'auditoriaStore')
@observer
class Salas extends React.Component {
    state = {
        notification: {
            isOpen: false,
            message: "",
        },
        search: "",
        isLoading: false,
        salas: [],
        salaSelected: undefined,
        statusFiltro: 'none',
        status: true,
        tipoOpcao: "procedimentos",
        textoModalProfissionalSaude: "Selecione os profissionais de saúde que ficarão disponíveis para utilizar esta sala.",
        textoModalProcedimento: "Selecione os procedimentos disponíveis para esta sala.",
        ordenarNome: true,
        ordenarStatus: true,
        idSalaSelecionado: '',
        pageNumber: 0,
        pageNumberProcedimentos: 0,
        loadingModal: false,
        profissionaisSaude: [],
        pageNumberModal: 0,
        pageNumberProfissionalSaude: 0,
        procedimentos: [],
        last: false,
        lastModal: false,
        searchModal: "",
        loadingSave: false,
        disableButton: false,
        ordenarTabela: {
            sortField: "nome",
            sortDir: "ASC",
        },
        tabSelected: tabs.INFORMACOES,
        propsTabsModal: {
            options: ["Procedimento", "Profissional de saúde"],
            tabSelected: tabsModal.CONVENIO,
            valueSearch: "",
            quantidades: [0, 0],
        },
        openAlertSalas: false,
        propsTabsAuditoria: {
            tabSelected: tabs.INFORMACOES
        },
    }
    componentDidMount() {
        const { modalConfiguracoesStore, auditoriaStore } = this.props;
        this.loadSalas();

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "a sala",
            auditoriaTela: "sala",
        }

        modalConfiguracoesStore.titulo="sala";
        modalConfiguracoesStore.opcao="sala";
    };

    loadSalas = () => {
        this.setState({ isLoading: true });
        findAllSala(
            this.state.status === null ?
            {
                search: this.state.search,
                pageableDTO:{
                    pageNumber: this.state.pageNumber,
                    pageSize: 30,
                    sortDir: this.state.ordenarTabela.sortDir,
                    sortField: this.state.ordenarTabela.sortField
                }
            }
            :
            {
                search: this.state.search,
                ativo: this.state.status,
                pageableDTO:{
                    pageNumber: this.state.pageNumber,
                    pageSize: 30,
                    sortDir: this.state.ordenarTabela.sortDir,
                    sortField: this.state.ordenarTabela.sortField
                }
            }
            ).then((response) => {
            const { last, content } = response.data.data.findAllSala;
            this.setState({
                salas: [...this.state.salas, ...content],
                last: last
            })
        }).catch(
            error => {
                const notification = {
                    isOpen: true,
                    message: "Erro ao carregar lista de salas"
                };
                this.showAlertMessage(notification);
            })
        .finally(() => {
            this.setState({ isLoading: false })
        })
    };

    loadMore = () => {
        const { isLoading, salas } = this.state;
        if (isLoading) {
            return;
        }

        this.setState({ pageNumber: salas.length > 0 ? this.state.pageNumber + 1 : 0 });

        this.loadSalas();
    };

    handleClickSearch = () => {
        this.setState({
            salas: [],
            pageNumber: 0
        });
        this.loadSalas();
    };

    handleClickClear = async () => {
        await this.setState({
            search: "",
            salas: [],
            pageNumber: 0,
        });
        this.loadSalas();
    };

    onChangeSearch = (e) => {
        let search = e.target.value;
        this.setState({ search })
    };

    onKeypressSearch = (e) => {
        if (e.key === 'Enter' && this.state.search.length >= 3) {
            this.setState({
                salas: [],
                pageNumber: 0,
            });
            this.loadSalas();
        }
    };

    handleChangeStatus = () => {
        const { salaSelected } = this.state;
        this.setState({
            salaSelected: {
                ...salaSelected,
                ativo: !salaSelected.ativo
            }
        });
    };

    handleClickNovoSalas = () => {
        const { modalConfiguracoesStore } = this.props;
        const { propsTabsModal } = this.state;

        this.setState({
            salaSelected: {
                nome: '',
                ativo: true,
                procedimentos: [],
                profissionaisSaude: []
            },
            profissionaisSaude: [],
            procedimentos: [],
            pageNumberModal: 0,
            searchModal: "",
            tipoOpcao: "procedimentos",
            propsTabsModal: {
                ...propsTabsModal,
                tabSelected: 0,
                valueSearch: "",
                quantidades: [0, 0]
            },
        }, () => {
            modalConfiguracoesStore.showModal = true;
            this.loadListaModal();
        });
    }

    atualizarIdSalaSelecionado = async (idSala) => {
        this.setState({ idSalaSelecionado: idSala });
    };

    handleClickOrdenar = async (value) => {
        const { ordenarTabela } = this.state;

        const sortDir = ordenarTabela.sortField !== value ? "ASC" : (ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC");
        const status = value === 'ativo' ? sortDir === 'ASC' : this.state.status;

        this.setState({            
            ordenarTabela: { 
              sortDir: sortDir,
              sortField: value,
            },
            status,
            salas: [],
            pageNumber: 0
        }, () => this.loadSalas());

        
    };


    handleClickRow = (id) => {
        this.handleEditar(id);
    };

    handleDuplicar = async (id) => {
        this.handleEditar(id, true);
    };

    handleAtivar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const selecionado = await findByIdSala(id);
        const selecionadoAtivo = {
            ...selecionado.data.data.findByIdSala,
            id,
            ativo: true
        }
        await saveSala(selecionadoAtivo);
        this.setState({
            salas: [],
            pageNumber: 0
        });
        modalConfiguracoesStore.showModal=false;
        this.loadSalas();
    };

    handleInativar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const selecionado = await findByIdSala(id);
        const selecionadoInativo = {
            ...selecionado.data.data.findByIdSala,
            id,
            ativo: false
        }
        await saveSala(selecionadoInativo);
        this.setState({
            salas: [],
            pageNumber: 0
        });
        modalConfiguracoesStore.showModal=false;
        this.loadSalas();
    };

	showAlertMessage = (notification) => {
		this.setState({ notification: notification });

		const timeoutId = setTimeout(() => {
			this.closeAlertMessage();
			clearTimeout(timeoutId);
		}, 3000);
	};

	closeAlertMessage = () => {
		const notification = {
			isOpen: false,
			message: "",
		};
		this.setState({ notification: notification });
	};

    onCloseModal = () => {
        const { modalConfiguracoesStore } = this.props;

        modalConfiguracoesStore.showModal = false;

        this.setState({ 
            searchModal: "", 
        });
    };

    loadMoreModal = () => {
        const { loadingModal, profissionaisSaude, procedimentos, pageNumberModal, tipoOpcao } = this.state;
        if (loadingModal) {
            return;
        }

        if (tipoOpcao === "procedimentos"){
            this.setState({ pageNumberModal: procedimentos.length > 0 ? pageNumberModal + 1 : 0 });
            this.loadProcedimentos();
        } else {
            this.setState({ pageNumberModal: profissionaisSaude.length > 0 ? pageNumberModal + 1 : 0 });
            this.loadProfissionalSaude();
        }
    };

    onChangeSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        let value = e.target.value;
        this.setState({ propsTabsModal: {
            ...propsTabsModal,
            valueSearch: value
        } })
    };

    handleClickClearModal = () => {
        this.setState({
            propsTabsModal: {
                ...this.state.propsTabsModal,
                valueSearch: "",
            },
            profissionaisSaude: [],
            procedimentos: [],
            pageNumberModal: 0,
            lastModal: false        
        }, () => {
            this.loadListaModal();
        });
    };

    onKeypressSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        if (e.key === 'Enter' && propsTabsModal.valueSearch.length >= 3) {
            this.setState({
                profissionaisSaude: [],
                procedimentos: [],
                pageNumberModal: 0,
                lastModal: false,
            }, () => {
                this.loadListaModal();
            });
        };
    };

    loadListaModal = () => {
        this.state.tipoOpcao === "procedimentos" ? this.loadProcedimentos() : this.loadProfissionalSaude();
    }

    handleClickSearchModal = () => {
        this.setState({
            profissionaisSaude: [],
            procedimentos: [],
            pageNumberModal: 0,
            lastModal: false,
        }, () => {
            this.loadListaModal();
        });
    }

    handleEditar = async (id, isDuplicar=false) => {
        const { modalConfiguracoesStore } = this.props;

        const selecionado = await findByIdSala(id);
        const selecionadoComId = {
            ...selecionado.data.data.findByIdSala,
            id: id,
        }

        if (isDuplicar) {
            selecionadoComId.id=null;
            selecionadoComId.nome=`${selecionadoComId.nome} - cópia`;
        };
        
        this.setState({ 
            salaSelected: selecionadoComId,
            procedimentos: [],
            profissionaisSaude: [],
            pageNumberModal: 0,
            lastModal: false,
            searchModal: "",
            tipoOpcao: "procedimentos",
            propsTabsModal: {
                ...this.state.propsTabsModal,
                tabSelected: 0,
                valueSearch: "",
                quantidades: [ selecionadoComId.procedimentos.length, selecionadoComId.profissionaisSaude.length ]
            },
            propsTabsAuditoria: {
                tabSelected: 0
            },
        }, () => {
            this.loadListaModal();
            modalConfiguracoesStore.showModal = true;
        });
    }

    loadProcedimentos = async () => {
        const { propsTabsModal, pageNumberModal, salaSelected } = this.state;
        this.setState({ loadingModal: true });
        await findAllProcedimentos({
            nome: propsTabsModal.valueSearch,
            ativo: true,
            pageableDTO:{
                pageNumber: pageNumberModal,
                pageSize: 10,
                sortDir: "ASC",
                sortField: "nome"
            }
        })
            .then(({ data: response }) => {
                const procedimentos = response.data.findAllProcedimentos.content.map(procedimentoItem => {
                    const procedimentoChecked = salaSelected.procedimentos.find(item => item.id === procedimentoItem.id);

                    return {
                        procedimentos: {
                            nome: procedimentoItem.nome,
                            descricao: procedimentoItem.descricao,
                            id: procedimentoItem.id,
                            checked: !!procedimentoChecked
                        },
                    }
                })

                this.setState({
                    procedimentos: [...this.state.procedimentos, ...procedimentos],
                    lastModal: response.data.findAllProcedimentos.last
                })
            })
            .catch(
                error => {
                    const notification = {
                        isOpen: true,
                        message: "Erro ao carregar a lista de procedimentos"
                    };
                    this.showAlertMessage(notification);
                })
            .finally(() => {
                this.setState({ loadingModal: false })
            })
    };

    loadProfissionalSaude = async () => {
        const { propsTabsModal, pageNumberModal, salaSelected } = this.state;
        
        this.setState({loadingModal: true})
        await findAllProfissionalSaudeProcedimento({
            searchDTO: {
                search: propsTabsModal.valueSearch,
                ativo: true
            },
            pageableDTO: {
                pageNumber: pageNumberModal,
                pageSize: 10,
                sortDir: "ASC",
                sortField: "nome"
            }

        })
        .then(({ data: response }) => {               
                const profissionaisSaude = response.data.findAllProfissionalSaudeProcedimento.content.map(profissionalSaude => {
                    const profissionalSaudeChecked = salaSelected.profissionaisSaude.find(item => item.id === profissionalSaude.id);
                    return {
                        profissionaisSaude: {
                            id: profissionalSaude.id,
                            nome: profissionalSaude.nome,
                            checked: !!profissionalSaudeChecked
                        },
                    }
    
                })

                this.setState({
                    profissionaisSaude: [...this.state.profissionaisSaude, ...profissionaisSaude],
                    lastModal: response.data.findAllProfissionalSaudeProcedimento.last
                })
            })
            .catch(
                error => {
                    const notification = {
                        isOpen: true,
                        message: "Erro ao carregar a lista de profissionais de saúde."
                    };
                    this.showAlertMessage(notification);
                })
            .finally(() => {
                this.setState({loadingModal: false})

            })
    }

    handleChange = (event, item) => {
        const { name, value } = event.target;
        const { salaSelected, tipoOpcao } = this.state;

        if (item) {
            item.valor = value;
            const indexSelecionado = salaSelected[tipoOpcao].findIndex(each => each[tipoOpcao]?.id === item[tipoOpcao]?.id);
            
            if (indexSelecionado >= 0) {
                let object = this.state.salaSelected;
                object.convenioProcedimentos[indexSelecionado].valor = item.valor;
                this.setState({salaSelected: object});
            }

        } else {
            this.setState(prevState => ({
                salaSelected: {
                    ...prevState.salaSelected,
                    [name]: value
                },
            }));
        }
    };

    handleChangeCheckbox = async (item, index) => {
        const { tipoOpcao, propsTabsModal } = this.state;

        item[tipoOpcao].checked = !item[tipoOpcao].checked;
        let object = this.state.salaSelected;

        if (item[tipoOpcao].checked) {
            object[tipoOpcao].push({
                id: item[tipoOpcao].id
            });

        } else {
            const removeItens = object[tipoOpcao].filter(each => each.id !== item[tipoOpcao].id);
            object[tipoOpcao] = removeItens;
        }

        const quantidades = tipoOpcao === "procedimentos" ?
            [ item.procedimentos.checked ? propsTabsModal.quantidades[0]+1 : propsTabsModal.quantidades[0]-1, propsTabsModal.quantidades[1] ]
            :
            [ propsTabsModal.quantidades[0], item.profissionaisSaude.checked ? propsTabsModal.quantidades[1]+1 : propsTabsModal.quantidades[1]-1 ]

        this.setState({ 
            salaSelected: object,
            [tipoOpcao]: [
                ...this.state[tipoOpcao].slice(0, index),
                {
                    ...this.state[tipoOpcao][index],
                    checked: !item[tipoOpcao].checked,
                },
                ...this.state[tipoOpcao].slice(index+1, this.state[tipoOpcao].length)
            ],
            propsTabsModal: {
                ...propsTabsModal,
                quantidades,
            }
        });
    };

    handleSave = async ({isSaveMesmoNome=false}) => {
        try {
            const { modalConfiguracoesStore } = this.props;
            const { salaSelected } = this.state;
            this.setState({ 
                disableButton: true,
                loadingSave: true,
            })
            if(!salaSelected.nome || string.isEmpty(salaSelected.nome)) {
                const notification = {
                    isOpen: true,
                    message: "Digite o nome da sala."
                };
                this.showAlertMessage(notification);
                return
            }
    
            const existeMesmoNome = await this.verificaProcedimentoComMesmoNome(salaSelected.nome)
    
            if(!isSaveMesmoNome && existeMesmoNome) return;
            await saveSala(salaSelected);

            modalConfiguracoesStore.showModal = this.state.openAlertProcedimentos;
        }catch{
            const notification = {
                isOpen: true,
                variant: "error",
                message: "Erro ao salvar a sala"
            };
            this.showAlertMessage(notification);
        }finally{
            this.setState({
                salas: [],
                pageNumber: 0,
                disableButton: false,
                loadingSave: false,
            }, () => this.loadSalas());
        }
    };

    changeTabSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { salaSelected } = this.state;

        auditoriaStore.auditoriaProps={
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        }

        if(tabSelected === 1) {
            auditoriaStore.load={
                query: findAllSalaAuditBySalaId, 
                variableName: "salaId",
                variableId: salaSelected.id, 
            }
            await auditoriaStore.loadAuditItems();
        }

        this.setState({ tabSelected })
    };

    setAuditoriaTela = () => {
        const { auditoriaStore } = this.props;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "a sala",
            auditoriaTela: "sala",
        };

        auditoriaStore.load={
            query: findAllSalaAuditByUnidade, 
            variableName: "",
            variableId: "", 
        };
    };

    changeTabModalAuditoriaSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { salaSelected } = this.state;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            pageNumber: 0,
            lastPage: false,
            auditorias: [],
        };

        if (tabSelected === 1) {
            auditoriaStore.load={
                query: findAllSalaAuditBySalaId, 
                variableName: "salaId",
                variableId: salaSelected.id,
            }
            await auditoriaStore.loadAuditItems();
          }

        this.setState({
            propsTabsAuditoria: {
                tabSelected: tabSelected
            }
        })
    };

    changeTabSelectedModal = async(tabSelected) => {
        const { propsTabsModal } = this.state;

        this.setState(
            {
              procedimentos: [],
              profissionaisSaude: [],
              pageNumberModal: 0,
              lastModal: false,
              tipoOpcao: tabSelected === 0 ? "procedimentos" : "profissionaisSaude",
              propsTabsModal: {
                ...propsTabsModal,
                tabSelected: tabSelected,
                valueSearch: "",
            }
            },
            () =>
            tabSelected === 0
                ? this.loadProcedimentos()
                : this.loadProfissionalSaude()
        );
    };

    saveProcedimentoComMesmoNome = () => {
        const { salaSelected } = this.state;

        this.setState({
            salaSelected: {
                ...salaSelected,
                nome: `${salaSelected.nome} - cópia`
            }
        }, () => {
            this.closeModalConfirmacao();
            this.handleSave({isSaveMesmoNome: true});
        });
    };

    closeModalConfirmacao = () => {
        this.setState({ openAlertSalas: false })
    }

    voltarMudarNome = () => {
        const { modalConfiguracoesStore } = this.props;
        this.closeModalConfirmacao();
        modalConfiguracoesStore.showModal=true;
    };

    verificaProcedimentoComMesmoNome = async(nomeSala) => {
        const { salaSelected } = this.state;
        const existeMesmoNome = await salaNomeJaExistente(nomeSala);
        this.setState({ 
            openAlertSalas: existeMesmoNome && !salaSelected.id,
        })

        return existeMesmoNome && !salaSelected.id;
    };

    render() {
        const { classes, modalConfiguracoesStore } = this.props;
        const { notification, salas, last, isLoading, search, ordenarTabela, propsTabsModal, salaSelected, loadingSave } = this.state;

        return (
            <>
                <div className={classes.content}>
                    <PageTitle title={pageConfiguracoes("Salas")}/>
                    <HeaderConfiguracoesButtonNovo
                        title="Salas"
                        labelButton="Nova Sala"
                        handleClickNovo={this.handleClickNovoSalas}
                        search={{
                            handleChange: this.onChangeSearch,
                            value: search,
                            onKeypress: this.onKeypressSearch,
                            handleClickClear: this.handleClickClear,
                            onClickButtonSearch: this.handleClickSearch
                        }}
                    />
                    <div className={classes.contentList}>
                        <ContentConfiguracoes
                            quantidadeProcedimentos={salas.length}
                            setAuditoriaTela={this.setAuditoriaTela}
                        >
                            <div className={classes.table}>
                                <Scroll
                                    loadMore={this.loadMore}
                                    hasMore={!last}
                                    pageStart={0}
                                    initialLoad={false}
                                >
                                    {
                                        salas.length > 0 && <Table
                                            dados={salas}
                                            columns={columnsConfiguracoes({
                                                headerNome: "Nome da sala",
                                                functions: {
                                                    handleEditar: this.handleEditar,
                                                    handleDuplicar: this.handleDuplicar,
                                                    handleDesativar: this.handleInativar,
                                                    handleAtivar: this.handleAtivar,
                                                },
                                            })}
                                            clickable={true}
                                            handleClick={this.handleClickRow}
                                            comOrdenacao
                                            ordenarTabela={ordenarTabela}
                                            handleClickOrdenar={(value) => this.handleClickOrdenar(value)}
                                        />
                                    }
                                    {
                                        isLoading && (
                                            <div className={classes.notFoundContainer}>
                                                <CircularProgress/>
                                            </div>
                                        )
                                    }
                                </Scroll>
                            </div>
                        </ContentConfiguracoes>
                        <Button
                            shape='circle'
                            bgColor='#26ACA9'
                            onClick={this.handleClickNovoSalas}
                            shadow
                            className={classes.buttonCriar} 
                        >
                            <AddIcon/>
                        </Button>
                        {
                            modalConfiguracoesStore.showModal &&
                             <ModalConfiguracoesTabs
                                itemSelecionado={salaSelected}
                                title={"Sala"}
                                handleChange={this.handleChange}
                                classes={{
                                    content: salaSelected?.id ? classes.contentModalEdit : classes.contentModal
                                }}
                                onClose={this.onCloseModal}
                                handleSave={this.handleSave}
                                loadingModal={this.state.loadingModal}
                                handleChangeStatus={this.handleChangeStatus}
                                loadingSave={loadingSave}
                                propsTabs={{
                                    ...propsTabsModal,
                                    changeTabSelected: this.changeTabSelectedModal,
                                    handleClickSearch: this.handleClickSearchModal,
                                    handleClickClear: this.handleClickClearModal,
                                    onChangeSearch: this.onChangeSearchModal,
                                    onKeypressSearch: this.onKeypressSearchModal,
                                }}
                                propsPopperCustom={{
                                    handleEditar: this.handleEditar,
                                    handleDuplicar: this.handleDuplicar,
                                    handleDesativar: this.handleInativar,
                                    handleAtivar: this.handleAtivar,
                                }}
                                propsTabsAuditoria={{
                                    tabSelected: this.state.propsTabsAuditoria.tabSelected,
                                    changeTabAuditoriaSelected: this.changeTabModalAuditoriaSelected,
                                }}
                             >
                                {
                                    propsTabsModal.tabSelected === 0 ?
                                    <ProcedimentoList
                                        hiddenValor
                                        listaModal={this.state.procedimentos}
                                        handleChangeCheckbox={this.handleChangeCheckbox}
                                        handleChange={this.handleChange}
                                        loadMore={this.loadMoreModal}
                                        lastScroll={this.state.lastModal}
                                        loadingModal={this.state.loadingModal}
                                    />
                                    :
                                    <ProfissionalSaudeList
                                        listaModal={this.state.profissionaisSaude}
                                        handleChangeCheckbox={this.handleChangeCheckbox}
                                        handleChange={this.handleChange}
                                        loadMore={this.loadMoreModal}
                                        lastScroll={this.state.lastModal}
                                        loadingModal={this.state.loadingModal}
                                    />
                                }

                             </ModalConfiguracoesTabs>
                        }
                        <ModalConfirmacao
                             open={this.state.openAlertSalas}
                             classes={{
                                 paperAlert: classes.paperAlert
                             }}
                             icon={<WarningIcon/>}
                             color={"yellow"}
                             title={" Já foi criado uma sala com esse nome"}
                             buttons={{
                                labelPrimaryButton: "Salvar assim mesmo",
                                handlePrimaryButton: () => this.saveProcedimentoComMesmoNome(),
                                labelSecondButton: "Voltar e alterar nome",
                                handleSecondButton: () => this.voltarMudarNome(),
                                disabledPrimaryButton: this.state.loadingSave,
                                disabledSecondButton: this.state.loadingSave,
                             }}
                        />
                    </div>
                </div>

                <Notification
					close={this.closeAlertMessage}
					reset={this.closeAlertMessage}
					isOpen={notification.isOpen}
					variant={"error"}
					message={notification.message}
				/>
            </>
        )
    }
};

export default withStyles(theme => ({
    content: {
        background: "#F5F5F5",
        "& #buttonCircle": {
            boxShadow: 'none',
            background: "#fff",
            color: "#5F6368",
        },
    },
    contentModal: {
        height: "calc(100% - 176px) !important",
    },
    contentModalEdit: {
        height: "calc(100% - 216px)",
    },
    contentList: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
        padding: "24px 24px 0",
        gap: '16px',
        height: "calc(100% - 104px)",   
    },
    table: {
        "&>div": {
            height: 'calc(100vh - 240px)',
            overflow: 'auto',
        },
        "& thead th": {
            textAlign: "left",
            top: 0,
            position: "sticky",
            zIndex: 1,
        },
    },
    notFoundContainer: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
    },
    buttonCriar: {
        position: 'absolute',
        bottom: 88,
        right: 37,
        zIndex: 100
    },
    paperAlert: {
        maxWidth: "373px",
    },
}))(Salas);
