import { withStyles } from '@material-ui/core/es';
import React from 'react';
import Colors from '../../../template/Colors';
import { imcStatus } from './ImcStatusConstants';

const StatusImc = props => {
    const { classes, imc, dadoAtivo, isHistoricoLancamentos, imcDescricao } = props;
    let imcValues = null;

    const renderStatus = ( descricao, color ) => {
        return(
            <div className={classes.colorDescricao}>
                <div 
                    style={{ background: Colors.statusImc[color] }} 
                    className={classes.circleColor}
                />
                {`${imc} - ${descricao}`}
            </div>
        )
    }

    if (imcDescricao) {
        imcValues = imcStatus[imcDescricao];
    }

    return(
        <div className={!dadoAtivo && imcDescricao && isHistoricoLancamentos ? classes.dadoInativo : undefined}>
            {imcDescricao && renderStatus(imcValues.label, imcValues.color)}
        </div>
    )
};

const style = {
    colorDescricao: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    circleColor: {
        width: 12,
        height: 12,
        borderRadius: 100,
        marginRight: 8
    },
    dadoInativo: {
        opacity: 0.2,
        textDecoration: 'line-through',
    }
}

export default withStyles(style)(StatusImc);
