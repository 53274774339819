import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, withStyles } from '@material-ui/core';
import { inject } from 'mobx-react';

import Dialog from '../../../../../components/Dialog/Dialog';
import { TextFieldSearch } from '../../../../../components/TextField';
import ItemGrid from '../../../../../components/Modal/ItemGrid';
import Button from '../../../../../components/ui/Buttons/Button';
import { findConveniosByAtivo } from '../../../../../services/ConvenioService';

const styles = ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    padding: '32px',
    gap: '16px'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '24px !important',
    color: '#505050',
    fontWeight: '700'
  },
  profissionalField: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectInput: {
    flex: 1,
    backgroundColor: '#F2F2F2',
    height: '40px',
    borderRadius: '8px',
    color: '#505050',
    fontWeight: '600',
  },
  notchedOutline: {
    border: 0
  },
  fillDiv: {
    flex: 1
  }
});

const FilterModal = observer(({ 
  classes, 
  open, 
  handleFilterChange, 
  handleClose, 
  filter, 
  handleApplyFilter,
  unidadeLogada,
  loading,
  profissionaisSaude,
  sujeitoAtencaoStore
}) => {
  const [convenios, setConvenios] = useState([]);

  useEffect(() => {
    loadConvenios();
  }, []);

  const loadConvenios = async () => {
    try {
      const response = await findConveniosByAtivo(unidadeLogada?.id);

      if (response?.data?.data?.convenios) {
        const convenios = response.data.data.convenios || [];
        setConvenios(convenios);
      } else {
        throw Error('Falha ao carregar os convênios');
      }
    } catch (error) {
      sujeitoAtencaoStore.openNotification(error.message, 'error');
    }
  };

  const handleClickApplyFilter = () => {
    handleApplyFilter();
    handleClose();
  }

  const loadMunicipios = async (search, loadedOptions, { page }) => {
    return handleLoadMoreMunicipios({
      search,
      loadedOptions,
      data: { page }
    });
  };

  const handleLoadMoreMunicipios = async ({ search, data }) => {
    let searchDTO = {};

    if (data.searchDTO) {
      searchDTO = {
				...data.searchDTO
			};
    }

    const response = await sujeitoAtencaoStore.consultaMunicipios({
      pageNumber: data.page,
      search,
      ...searchDTO
    });

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
				page: data.page + 1
			}
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.modalContainer
      }}
    >
      <div className={classes.modalHeader}>
        <span className={classes.title}>Filtrar</span>
        <Button
          kind='transparent'
          style={{
            border: 'none'
          }}
          onClick={sujeitoAtencaoStore.resetFilter}
        >
          Redefinir filtros
        </Button>
      </div>
      <div className={classes.profissionalField}>
        <ItemGrid classes={{item: classes.fillDiv}} label="Profissional de Saúde">
          <TextFieldSearch
            classInput={classes.selectInput}
            classNotched={classes.notchedOutline}
            placeholder="Selecione"
            options={profissionaisSaude}
            getOptionLabel={(option) => option?.nome}
            getOptionValue={(option) => option.id}
            onChange={(value) => handleFilterChange(value, 'profissionalSaudeId')}
            value={filter?.profissionalSaudeId}
            menuPosition="fixed"
          />
        </ItemGrid>
        <ItemGrid label="Status">
          <Switch color="primary"
            checked={filter?.ativo?.value}
            onChange={() => handleFilterChange({value: !filter?.ativo?.value}, 'ativo')}
          />
        </ItemGrid>
      </div>
      <ItemGrid label="Município">
        <TextFieldSearch
          classInput={classes.selectInput}
          classNotched={classes.notchedOutline}
          placeholder="Selecione"
          loadOptions={loadMunicipios}
          withPaginate
          value={filter?.municipioId}
          onChange={(value) => handleFilterChange(value, 'municipioId')}
          additional={{
            page: 0,
          }}
        />
      </ItemGrid>
      <ItemGrid label="Convênio">
        <TextFieldSearch 
          classInput={classes.selectInput}
          classNotched={classes.notchedOutline}
          placeholder="Selecione"
          options={convenios}
          getOptionLabel={(option) => option?.descricao}
          getOptionValue={(option) => option.id}
          value={filter?.convenioId}
          menuPosition="fixed"
          onChange={(value) => handleFilterChange(value, 'convenioId')}
        />
      </ItemGrid>
      <Button
        onClick={handleClickApplyFilter}
        disable={loading}
      >
        Aplicar Filtro
      </Button>
    </Dialog>
  );
});

const FilterModalStyles = withStyles(styles)(FilterModal);
export default inject("sujeitoAtencaoStore")(FilterModalStyles);
