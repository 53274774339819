import React, { useEffect, useReducer, useState } from 'react'
import { CircularProgress, Grid, IconButton, InputAdornment, withStyles } from '@material-ui/core'
import { Search, Clear as ClearIcon } from '@material-ui/icons'
import { Button } from '../../../../components/ui/Buttons'
import Table from '../../../../components/Table/Table'
import { COLUMNS_PROCEDIMENTO } from './utils'
import TabSelector from '../../../../components/TabSelector'
import ModalModelosProcedimentos from './components/ModalModelosProcedimentos'
import {
  findAllProcedimentoModelo,
  updateStatusProcedimentoModelo,
} from '../../../../services/ProcedimentoModeloService'
import Scroll from '../../../../components/InfiniteScroll/Scroll'

import HeaderConfiguracoesButtonNovo from '../../Components/HeaderConfiguracoesButtonNovo'
import AddIcon from '../../../../components/Icon/AddIcon'

function ModeloProcedimento({ classes }) {
  const [modelosProcedimentos, setModelosProcedimentos] = useState([])
  const [rowSelected, setRowSelected] = useState(null)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [ativo, setAtivo] = useState(null)

  const [openModal, toggleModal] = useReducer(state => !state, false)

  const loadAllModelos = async (clear = false) => {
    setIsLoading(true)
    try {
      const variables = {
        pageableDto: {
          pageNumber: clear ? 0 : page,
          pageSize: 30,
          sortDir: 'ASC',
          sortField: 'nome',
        },
        search,
        ...(ativo !== null && { ativo: ativo.value === 'true' }),
      }
      const { content, last } = await findAllProcedimentoModelo(variables)
      setHasMore(!last)
      setPage(prevPage => (clear ? 0 : prevPage + 1))
      setModelosProcedimentos(prevState =>
        clear || page === 0 ? content : [...prevState, ...content]
      )
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearch = async () => {
    await loadAllModelos(true)
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleChangeStatus = async id => {
    setIsLoading(true)
    setModelosProcedimentos(prevState => {
      return prevState.map(item => {
        if (item.id === id) {
          item.ativo = !item.ativo
        }
        return item
      })
    })
    try {
      await updateStatusProcedimentoModelo(id)
      await loadAllModelos(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenModal = (id, isDuplicate) => {
    toggleModal()
    setRowSelected(id)
    setIsDuplicate(isDuplicate)
  }

  const handleCloseModal = async () => {
    toggleModal()
    setRowSelected(null)
    setIsDuplicate(false)
    await loadAllModelos(true)
  }

  useEffect(() => {
    loadAllModelos(true)
  }, [ativo])

  return (
    <div className={classes.content}>
      <HeaderConfiguracoesButtonNovo
        title='Modelo de Procedimento'
        labelButton='Criar novo modelo'
        handleClickNovo={toggleModal}
        showStatus
        status={{
          options: [
            { value: 'true', label: 'Ativo' },
            { value: 'false', label: 'Inativo' },
          ],
          value: ativo,
          onChange: value => {
            setAtivo(value)
            setPage(0)
          },
        }}
        search={{
          handleChange: e => setSearch(e.target.value),
          value: search,
          onKeypress: handleKeyPress,
          handleClickClear: () => {
            setSearch('')
            handleSearch()
          },
          onClickButtonSearch: handleSearch,
        }}
      />

      <div className={classes.container}>
        <Scroll
          loadMore={() => loadAllModelos()}
          hasMore={hasMore && !isLoading}
          pageStart={0}
          initialLoad={false}
          className={classes.scrollContainer}
        >
          <Table
            dados={modelosProcedimentos}
            columns={COLUMNS_PROCEDIMENTO({
              handleChangeStatus: id => handleChangeStatus(id),
              openModal: handleOpenModal,
            })}
            classes={{
              table: classes.table,
              tableHead: classes.tableHeader,
            }}
            isLoading={isLoading}
            emptyMessage={
              <Grid
                container
                justify='center'
                alignItems='center'
                style={{ height: '100%', margin: '0 auto' }}
              >
                <span>Nenhum modelo de procedimento encontrado</span>
              </Grid>
            }
          />
        </Scroll>
        <Button
          shadow
          shape='circle'
          bgColor='#26ACA9'
          onClick={toggleModal}
          className={classes.buttonCriar}
        >
          <AddIcon />
        </Button>
      </div>
      {openModal && (
        <ModalModelosProcedimentos
          open={openModal}
          onClose={handleCloseModal}
          rowSelected={rowSelected}
          isDuplicate={isDuplicate}
        />
      )}
    </div>
  )
}

const styles = {
  content: {
    display: 'flex',
    backgroundColor: '#F2F2F2',
    flexDirection: 'column',
    gap: '1rem',
  },
  scrollContainer: {
    height: '100%',
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    margin: '1rem',
    height: '100%',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 200px)',
  },
  wrapperTabSelector: {
    padding: '1rem 1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  table: {
    '& td': {
      textAlign: 'start',
      color: '#505050',
      fontWeight: '500',
      maxWidth: '100px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&:last-child': {
        textOverflow: 'clip',
      },
    },
  },
  tableHeader: {
    background: '#F9F9F9',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '& th': {
      textAlign: 'start !important',
      color: '#505050',
      fontWeight: '600',
    },
  },
  buttonCriar: {
    position: 'absolute',
    bottom: 88,
    right: 37,
  },
}

export default withStyles(styles)(ModeloProcedimento)
