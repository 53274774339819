export const getBestChatUrl = async (chatUrls, timeout = 2000) => {
  const results = await Promise.all(chatUrls.map(async (chatUrl) => {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      const response = await fetch(`${chatUrl}/api/v1/web-socket/process-load`, { signal: controller.signal });
      const { con: load, e: canConnect } = await response.json() || {};

      clearTimeout(timeoutId);
      return { chatUrl, load, canConnect };
    } catch (error) {
      return { chatUrl, load: 0, canConnect: false };
    }
  }));

  const bestChat = results
    .filter(item => item.canConnect)
    .reduce((a, b) => a.load < b.load ? a : b, { load: Infinity });

  return bestChat.chatUrl;
};