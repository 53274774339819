import classNames from "classnames";
import { inject } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import styles from "./DentesStyles";
import Dente from "../../Components/Dente";
import { menuOpcoesPopperLocal } from "../../Constants";
import DivisorTipoDente from "../../Components/DivisorTipoDente";
import DivisorVisualizacaoDente from "../../Components/DivisorVisualizacaoDente";
import PopperCustomMenu from "../../../../../../components/Popper/PopperCustomMenu";
import DivisorAreaDenteSupranumerario from "../../Components/DivisorAreaDenteSupranumerario";

const Dentes = observer(
  ({ classes, odontogramaStore, openDrawer, prontuarioStore }) => {
    const {
      tabVisualizacaoSelected,
      dentes,
      isTelaAmpliada,
      locaisSelecionados,
      odontograma,
      isClickRightLocalSelecionado,
    } = odontogramaStore;

    const popperRef = useRef(null);
    const tipoDentePermanente = tabVisualizacaoSelected === 0;
    const quantidadeDentes = tipoDentePermanente ? 16 : 10;
    const dentesSuperior = dentes.slice(0, quantidadeDentes);
    const dentesInferior = dentes.slice(quantidadeDentes, dentes.length);

    useEffect(() => {
      odontograma.id && getTodosLocais();
    }, [odontograma.id]);

    const getTodosLocais = () => {
      odontogramaStore.loadTodosLocais();
    };

    const handleClickDente = (e, dente) => {
      if (isTipoLocalMucosa()) return;

      if(isClickRightLocalSelecionado && locaisSelecionados.length === 1) {
        odontogramaStore.locaisSelecionados = [];
      }

      if (e.which === 1 || e.button === 0) {
        selectDente(dente);
      }

      const denteRepetido = locaisSelecionados.some(item => item.id === dente.id);
      if ((locaisSelecionados.length === 0 || (denteRepetido && locaisSelecionados.length === 1)) && (e.which === 3 || e.button === 2)) {

        odontogramaStore.locaisSelecionados = [dente];
        odontogramaStore.isClickRightLocalSelecionado = true;

        return popperRef?.current?.handleOpenPopper(e);
      }

      odontogramaStore.isClickRightLocalSelecionado = false;
    };

    const isTipoLocalMucosa = () => {
      return (
        locaisSelecionados.length > 0 &&
        locaisSelecionados.some((item) => item.local !== 4)
      );
    };

    const selectDente = (dente) => {
      let listaLocaisSelecionados = [...locaisSelecionados];
 
      const dentesSelecionados = dentes.map((element) => {
        return {
          ...element,
          selecionado:
            dente.id === element.id
              ? !dente?.selecionado
              : element.selecionado,
        };
      });
      if(dente.selecionado) {
        const lista = dentesSelecionados.filter(item => item.selecionado);
        listaLocaisSelecionados = lista
      } else {
        listaLocaisSelecionados.push({
          ...dente,
          selecionado: true,
        })
      }

      odontogramaStore.dentes = dentesSelecionados;
      odontogramaStore.locaisSelecionados = listaLocaisSelecionados;
    };

    const openModalObservacao = (e) => {
      odontogramaStore.modalObservacao.showModalObservacao = true;
      return popperRef?.current?.closePopper(e);
    };

    const openModalProcedimento = (e) => {
      odontogramaStore.modalProcedimento.showModalProcedimento = true;
      return popperRef?.current?.closePopper(e);
    };

    const openModalHistorico = (e) => {
      odontogramaStore.showModalHistorico = true;
      return popperRef?.current?.closePopper(e);
    };

    const handleAlteraStatusDente = async (e) => {
      const dentes = [
        {
          id: locaisSelecionados[0].id,
          status: locaisSelecionados[0].status === "ATIVO" ? "INATIVO" : "ATIVO",
        }
      ]

      await odontogramaStore.alterarStatusDente(dentes);

      odontogramaStore.getOdontograma(
        prontuarioStore.sujeitoAtencaoSelected.id
      );

      return popperRef?.current?.closePopper(e);
    };

    const dadosMenuOpcoes = (dente) => ({
      isDente: true,
      ativo: dente.status === "ATIVO",
      functions: {
        openModalProcedimento,
        openModalObservacao,
        openModalHistorico,
        handleAlteraStatusDente,
      },
    });

    const handleContextMenu = (e) => {
      e.preventDefault();
  };

    return (
      <div className={classes.content} onContextMenu={handleContextMenu}>
        <DivisorVisualizacaoDente />

        <div
          className={classNames(
            classes.contentDentes,
            !openDrawer && classes.contentCloseDrawer,
            isTelaAmpliada && classes.contentTelaCheia
          )}
        >
          <div className={classes.dentes}>
            {dentesSuperior.map((dente, index) => {
              return (
                <PopperCustomMenu
                  ref={popperRef}
                  placement={"bottom-end"}
                  colorButton={{
                    background: "#fff",
                  }}
                  menuOpcoes={menuOpcoesPopperLocal(dadosMenuOpcoes(dente))}
                  key={dente.id}
                >
                  <div
                    className={classes.dente}
                    onMouseUp={(e) => handleClickDente(e, dente)}
                  >
                    <DivisorTipoDente index={index} />
                    <Dente dente={dente} isPosicaoSuperior />
                  </div>
                </PopperCustomMenu>
              );
            })}
          </div>
          <div className={classes.divisor} />
          <div className={classes.dentes}>
            {dentesInferior.map((dente) => {
              return (
                <PopperCustomMenu
                  ref={popperRef}
                  placement={"bottom-end"}
                  colorButton={{
                    background: "#fff",
                  }}
                  menuOpcoes={menuOpcoesPopperLocal({
                    isDente: true,
                    ativo: locaisSelecionados[0]?.status === "ATIVO",
                    functions: {
                      openModalProcedimento,
                      openModalObservacao,
                      openModalHistorico,
                      handleAlteraStatusDente,
                    },
                  })}
                  key={dente.id}
                >
                  <div
                    key={dente.id}
                    onMouseDown={(e) => handleClickDente(e, dente)}
                  >
                    <Dente dente={dente} invert />
                  </div>
                </PopperCustomMenu>
              );
            })}
          </div>
        </div>
        <DivisorAreaDenteSupranumerario />
      </div>
    );
  }
);

const DentesWithStyles = withStyles(styles)(Dentes);
export default inject("odontogramaStore", "prontuarioStore")(DentesWithStyles);
