import colors from "../../../template/Colors";

const styles = {
  paperAlert: {
    maxWidth: 256,
    borderRadius: "16px",
    padding: "24px",
  },
  icon: {
    width: 50,
    height: 50,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    alignSelf: "center",
    textAlign: "center",
  },
  titleFontMenor: {
    fontSize: 18,
  },
  text: {
    textAlign: "justify",
  },
  marginTop: {
    marginTop: 16,
  },
  colorFont: {
    color: colors.commons.gray9,
  },
  contentButtons: {
    display: "flex",
    marginTop: 18,
    '& > button': {
      textTransform: 'none',
    }
  },
  columnContentButtons: {
    display: "flex",
    flexDirection: "column",
  },
  noBoxShadow: {
    boxShadow: "none",
  },
  primaryButton: {
    border: "none",
    boxShadow: "none",
    marginRight: 16,
    "&:hover": {
      color: colors.commons.gray9,
      background: "none",
    },
  },
  yellow: {
    background: colors.commons.yellow2,
    color: colors.commons.white,
  },
  blue: {
    background: "#717FFC",
    color: colors.commons.white,
  },
  green: {
    background: "#36ABA8",
    color: colors.commons.white,
  },
  red: {
    background: colors.error.main,
    color: colors.commons.white,
  },
  purple: {
    background: "#9871FC",
    color: colors.commons.white,
  },
  gray: {
    background: "#707C97",
    color: colors.commons.white,
  },
  inputMotivo: {
    background: colors.commons.gray2,
    borderRadius: 10,
    padding: "0 10px",
    boxSizing: "borderBox",
  },
  titleInput: {
    fontSize: "12px",
  },
};

export default styles;
