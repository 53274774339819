export const masks = {
  cnpj: '99.999.999/9999-99',
  cpf: '999.999.999-99',
  phone: '(99) 9 9999-9999',
  cep: '99999-999',
}

export const unMask = value => value?.replace(/\D/g, '')

export const mask = (value, mask) => {
  let i = 0
  const v = value?.replace(/\D/g, '')
  return mask.replace(/9/g, () => v[i++] || '')
}
