import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const CamIcon = props => {
  return (
    <svg
      width='27'
      height='28'
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...normalizeProps(props)}
    >
      <path
        d='M17.1605 18.0153C17.1605 19.6214 15.8584 20.9235 14.2523 20.9235H7.54102C5.93486 20.9235 4.63281 19.6214 4.63281 18.0153V10.4092C4.63281 8.80302 5.93486 7.50098 7.54102 7.50098H14.2523C15.8584 7.50098 17.1605 8.80302 17.1605 10.4092V18.0153ZM22.3165 8.74778C22.454 8.90956 22.5294 9.11497 22.5294 9.32729V19.0969C22.5294 19.5911 22.1288 19.9917 21.6346 19.9917C21.4223 19.9917 21.2169 19.9163 21.0551 19.7788L18.0553 17.2286V11.1948L21.0551 8.64546C21.4317 8.3254 21.9964 8.37122 22.3165 8.74778Z'
        fill='currentColor'
      />
    </svg>
  )
}
export default CamIcon
