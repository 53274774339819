import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const ConfiguracoesIcon = props => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path
        d="M12.9017 2.8901C13.6346 2.88883 14.3657 2.96383 15.0831 3.11387C15.3963 3.17936 15.6332 3.43658 15.6729 3.75406L15.863 5.27624C15.9492 5.97517 16.5472 6.49748 17.2518 6.48888C17.4412 6.48666 17.628 6.44474 17.8019 6.365L19.1922 5.73216C19.4814 5.60054 19.8221 5.66569 20.0423 5.8947C21.0672 6.96062 21.837 8.24511 22.2938 9.6515C22.3923 9.95476 22.2879 10.2871 22.0338 10.4797L20.8064 11.4098C20.4562 11.6743 20.2529 12.0896 20.2587 12.5283C20.2645 12.9671 20.4788 13.3769 20.8366 13.6326L22.0893 14.5301C22.3486 14.7158 22.4617 15.0453 22.3713 15.3512C21.9522 16.7689 21.2171 18.0733 20.2214 19.1662C20.0076 19.4009 19.6689 19.4752 19.3764 19.3517L17.9642 18.7551C17.5601 18.5846 17.0991 18.6165 16.7224 18.8409C16.3457 19.0654 16.0982 19.4556 16.0557 19.8921L15.9071 21.4186C15.8765 21.7333 15.6514 21.9949 15.3448 22.0722C13.9032 22.4354 12.3964 22.4554 10.9457 22.1305C10.6371 22.0614 10.4052 21.8059 10.3662 21.492L10.1775 19.9723C10.1222 19.5379 9.86405 19.1556 9.48183 18.9419C9.09962 18.7283 8.6387 18.7088 8.24065 18.8889L6.8444 19.5228C6.55519 19.6541 6.21462 19.5887 5.99463 19.3596C4.96975 18.2922 4.20032 17.0063 3.74428 15.5986C3.64606 15.2954 3.7505 14.9633 4.00457 14.7708L5.23382 13.8398C5.58396 13.5754 5.78731 13.16 5.7815 12.7213C5.77568 12.2825 5.56138 11.8727 5.20389 11.6173L3.9512 10.7208C3.69173 10.5351 3.57841 10.2054 3.6689 9.89941C4.08823 8.4814 4.82371 7.17696 5.81998 6.08425C6.03403 5.84948 6.37292 5.77532 6.66546 5.89923L8.07176 6.49488C8.47641 6.6661 8.93856 6.6333 9.31698 6.40544C9.69378 6.18016 9.94144 5.78955 9.98447 5.35301L10.1341 3.82749C10.1653 3.50891 10.3956 3.24542 10.7071 3.17178C11.4212 3.00302 12.1508 2.9087 12.9017 2.8901ZM12.9786 9.62956C11.3243 9.65149 10.0011 11.0103 10.023 12.6645C10.0449 14.3188 11.4037 15.642 13.058 15.6201C14.7122 15.5982 16.0355 14.2394 16.0135 12.5851C15.9916 10.9309 14.6328 9.60763 12.9786 9.62956Z" 
        fill="currentColor"
      />
    </svg>
  );
};

export default ConfiguracoesIcon;