import Api from '../config/api'

export const findAnamneseByAgendamento = async variables => {
  const response = await Api.post('', {
    query: `
  query ($descartado: Boolean, $pageableDTO: PageableDTOInput, $prontuarioGerado: Boolean, $agendamentoId: Long){
    findAnamneseByAgendamento(descartado: $descartado, pageableDTO: $pageableDTO, prontuarioGerado: $prontuarioGerado, agendamentoId: $agendamentoId){
      last
      totalElements
      totalPages
      content {
        id
        nome
        prontuarioGerado
        tipoEntradaProntuario
        descartado
        agendamento{
          especialidadeMedica{
            especialidade
            id
          }
        }
        sujeitoAtencao{
          nome
          cpf
          contato{
            telefonePrincipal
          }
        }
        perguntas {
          id
          pergunta
          tipo
          respostas {
            id
            nome
            valor
            valorConvertido
          }
        }
      }
    }
  }`,
    variables,
  })
  return response.data.data.findAnamneseByAgendamento
}

export const createAnamnese = async variables => {
  const response = await Api.post('', {
    query: `
  mutation ($anamnesePaciente: AnamneseInput, $agendamentoId: Long){
    createAnamneseByAgendamentoId(anamnesePaciente: $anamnesePaciente,agendamentoId:$agendamentoId){
      id
      }
  }`,
    variables,
  })
  if (response.data.errors) throw new Error(response.data.errors[0].message)
  return response.data.data.createAnamneseByAgendamentoId
}

export const descartarAnamnese = async variables => {
  const response = await Api.post('', {
    query: `
      mutation ($anamneseId: UUID){
        descartarAnamnese(anamneseId: $anamneseId)
      }`,
    variables,
  })
  if (response.data.errors) throw new Error(response.data.errors[0].message)
  return response.data.data.descartarAnamnese
}

export const quantitySolicitacoes = async agendamentoId => {
  const response = await Api.post('', {
    query: `
  query{
    findAnamneseByAgendamento(descartado: false, pageableDTO: {pageNumber: 0, pageSize: 10}, prontuarioGerado: false, agendamentoId: ${agendamentoId}){
      totalElements
    }
    findAllPacienteAnexo(pageableDTO: {pageNumber: 0, pageSize: 10}, prontuarioGerado: false, descartado: false, agendamentoId: ${agendamentoId}){
      totalElements
    }
  }`,
  })
  const { findAnamneseByAgendamento, findAllPacienteAnexo } = response?.data?.data
  return findAnamneseByAgendamento.totalElements + findAllPacienteAnexo.totalElements
}

export const findAllAnexosPaciente = async variables => {
  const response = await Api.post('', {
    query: `
  query ($pageableDTO: PageableDTOInput, $prontuarioGerado: Boolean, $descartado: Boolean, $agendamentoId: Long) {
    findAllPacienteAnexo(pageableDTO: $pageableDTO, prontuarioGerado: $prontuarioGerado, descartado: $descartado, agendamentoId: $agendamentoId) {
      last
      content {
        id
        dataHoraLancamento
        tipoEntradaProntuario:arquivoTipo
        descartado
        urlDownload
        amazonS3Objeto {
          extensao
          id
          nome
          nomeComExtensao
          tamanho
        }
        sujeitoAtencao {
          nome
          cpf
          contato{
            telefonePrincipal
          }
        }
        agendamento {
          id
        }
      }
    }
  }`,
    variables,
  })
  if (response.data.errors) throw new Error(response.data.errors[0].message)
  return response.data.data.findAllPacienteAnexo
}

export const anexarAnexosPaciente = async (variables, tipoSolicitacao) => {
  const type = tipoSolicitacao === 'ARQUIVO' ? 'Arquivo' : 'Imagem'
  const response = await Api.post('', {
    query: `
      mutation ($pacienteAnexoId: UUID, $agendamentoId: Long) {
        createEntradaProntuarioByPaciente${type}(pacienteAnexoId: $pacienteAnexoId, agendamentoId: $agendamentoId) {
          id
        }
      }
    `,
    variables: variables,
  })
  if (response.data.errors) throw Error(response.data.errors[0].message)
  return response.data.data[`createEntradaProntuarioByPaciente${type}`]
}

export const descartarAnexo = async variables => {
  const response = await Api.post('', {
    query: `
      mutation ($id: UUID) {
        descartarPacienteAnexo(id: $id)
      }
    `,
    variables,
  })
  if (response.data.errors) throw Error(response.data.errors[0].message)
  return response.data.data.descartarPacienteAnexo
}
