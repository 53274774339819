import Api from "../config/api";

export const findAgendamentoById = (id) => {
  return Api.post("", {
    query: `
        query ($id: Long) {
            findByIdAgendamento(id: $id) {
              id
              data
              situacao
              dataInicioAtendimento
              dataFimAtendimento
              meetingId
              nome
              telefone
              telefoneDDI
              cpf
              horaInicio
              horaFim
              linkTelemedicina
              observacao
              dataNascimento
              remoto
              chavePublica
              documentosAssinadosCliente {
              id
              nome
              tipo
              profissionalSaude {
                id
              }
              situacao
              dataHoraValidade
              }
              cid10Subcategorias {
                id
                descricao
                codigo
                descricaoAbreviada
              }
              urgente
              mensagemWhatsappEnviada
              tipo {
                ativo
                codigo
                descricao
                id
              }
              sala {
                id
                nome
              }
              profissionalSaude {
                numeroConselho
                email
                id
                nome
                cpf
              }
              convenio {
                id
                descricao
                convenioAgendamentoTipo {
                  agendamentoTipo {
                    id
                  }
                  valor
                }
              }
              procedimentos {
                id
                procedimento {
                  id
                  nome
                  convenioProcedimentos {
                    convenio {
                      id
                    }
                    valor
                  }
                }
                realizado
              }
              paciente {
                id
                dataNascimento
                email
                endereco {
                  bairro
                  cep
                  complemento
                  nomeLogradouro
                  numero
                  municipio {
                    nome
                  }
                  estado {
                    nome
                  }
                }
                fotoPerfil
                telefonePrincipal
              }
              unidade {
                id
                nomeFantasia
                rede {
                  whitelabel {
                    id
                    frontWebUrl
                    nome
                    nomeEmpresa
                    corPrincipal
                  }
                }
                endereco {
                bairro
                cep
                complemento
                estado {
                  nome
                  uf
                }
                municipio {
                  nome
                  uf
                }
                nomeLogradouro
                numero
              }
              telefonePrincipal
              nomeFantasia
              }
              sujeitoAtencao {
                id
                nome
                nomeSocial
                cpf
                dataNascimento
                fotoPerfil
                nomeSocialOuNome
                religiao
                nomeSocialOuNome
                raca {
                  descricao
                }
                sexo {
                  id
                  descricao
                }
                estadoCivil {
                  descricao
                }
                contato {
                  telefonePrincipal
                  telefonePrincipalDDI
                }
                endereco {
                  municipio {
                    nome
                    uf
                  }
                }
                informacoesImportantes {
                  informacao
                }
                ocupacao {
                  descricao
                }
                altura
                peso
                dadosConvenio {
                  convenio {
                    id
                    descricao
                    convenioSistema {
                      convenioLogo {
                        urlDownload
                      }
                    }
                  }
                }
              }
            }
          }
          `,
    variables: {
      id: id,
    },
  });
};

export const findAllDataLancamentoEntradaProntuario = async (
  sujeitoAtencaoId
) => {
  const response = await Api.post("", {
    query: `
      query ($sujeitoAtencaoId: UUID){
        findAllDataLancamentoEntradaProntuario(sujeitoAtencaoId: $sujeitoAtencaoId) {
          dataLancamento
          profissionais
        }
      }`,
    variables: {
      sujeitoAtencaoId,
    },
  });
  return response.data.data.findAllDataLancamentoEntradaProntuario;
};

export const findByMeetingId = async (meetingId) => {
  const response = await Api.post("", {
    query: `   query {
                findByMeetingId(meetingId: "${meetingId}") {
                  permissaoCamera
                  permissaoMicrofone
                }
              }`,
  });

  return response.data.data.findByMeetingId;
};

export const findSujeitoAtencaoById = async(id) => {
  const response = await Api.post('', {
      query:
        `   
      query {
        findByIdSujeitoAtencao(id: "${id}") {
          cpf
          contato {
            telefonePrincipal
            email
          }
        }
      }`,
  });
  if(response.data.errors) {
    throw new Error(response.data.errors[0].message);
  }
  return response.data.data.findByIdSujeitoAtencao;
};

export const updateSujeitoAtencaoConfirmarPresenca = async(variables) => {
  const response = await Api.post('', {
      query:
        `
        mutation ($sujeitoAtencao: SujeitoAtencaoInput) {
          updateInformacoesConfirmacaoPresencaSujeitoAtencao(sujeitoAtencao: $sujeitoAtencao) {
            id
          }
        }`,
      variables
  });

  if(response.data.errors) {
    throw new Error(response.data.errors[0].message);
  }
  return response.data.data.updateInformacoesConfirmacaoPresencaSujeitoAtencao;
}
export const createEntradaProntuarioTipoTexto = async(mensagem) => {
  const response = await Api.post('', {
    query: `
        mutation($mensagem: EntradaProntuarioInput){
            createEntradaProntuarioTipoTexto(entradaProntuario: $mensagem){
                id
            }
        }
    `,
    variables: { mensagem }
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  };

  return response;
};


export const findAgendamentosAtendendo = async(variables) => {
  const response = await Api.post('', {
    query: `
        query($profissionalSaudeId: Long, $pageableDTO: PageableDTOInput, $dataInicio: LocalDate, $dataFim: LocalDate){
          findAgendamentosAtendendo(profissionalSaudeId: $profissionalSaudeId, pageableDTO: $pageableDTO, dataInicio: $dataInicio, dataFim: $dataFim) {
                last
                totalElements
                totalPages
                content {
                  id
                  situacao
                  data
                  nome
                  remoto
                  horaInicio
                  sujeitoAtencao {
                    fotoPerfil
                    nome
                    dataNascimento
                  }
                }
            }
        }
    `,
    variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  };

  return response.data.data.findAgendamentosAtendendo;
};

export const countAgendamentosAtendendo = async(variables) => {
  const response = await Api.post('', {
    query: `
        query($profissionalSaudeId: Long, $dataInicio: LocalDate, $dataFim: LocalDate){
          countAgendamentosAtendendo(profissionalSaudeId: $profissionalSaudeId, dataInicio: $dataInicio, dataFim: $dataFim)
        }
    `,
    variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  };
  return response.data.data.countAgendamentosAtendendo
};

export const findHorariosAtendimentosPermiteAgendamento = async(variables) => {
  const response = await Api.post('', {
    query: `
        query($profissionalSaudeId: Long, $dataInicio: LocalDate, $dataFim: LocalDate){
          findHorariosAtendimentosPermiteAgendamento(profissionalSaudeId: $profissionalSaudeId, dataInicio: $dataInicio, dataFim: $dataFim) 
        }
    `,
    variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  };

  return response.data.data.findHorariosAtendimentosPermiteAgendamento;
};

export const persistCIDByAgendamento = async(variables) => {
  const response = await Api.post('', {
    query: `
      mutation PersistCidByAgendamento($cid10Subcategorias: [Cid10SubcategoriaInput], $agendamento: AgendamentoInput) {
        persistCidByAgendamento(cid10Subcategorias: $cid10Subcategorias, agendamento: $agendamento)
      }
    `,
    variables,
  })

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.persistCidByAgendamento;
}

export const reenvioEntradaProntuarioHugg = async(idEntradaProntuario) => {
  const response =  await Api.post('', {
    query: `
      mutation($idEntradaProntuario: Long){
        reenviarMensagemHugg(idEntradaProntuario: $idEntradaProntuario)
      }
    `,
    variables: {
      idEntradaProntuario
    },
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  };

  return response.data.data.reenviarMensagemHugg;
}

export const findProfissionalSaudeByUnidadeComAgenda = async (especialidadeMedica=null) => {
  const response = await Api.post('', {
    query: `
      query($especialidadeMedica: Long){
        findProfissionalSaudeByUnidadeComAgenda (especialidadeMedica: $especialidadeMedica) {
          id
          nome
        }
      }
    `, 
    variables: {
      especialidadeMedica
    },
  });
  if (response.data.errors) {
    throw response.data.errors[0];
  };
  return response.data.data.findProfissionalSaudeByUnidadeComAgenda
}

export const finalizarAgendamentoRemoto = async(variables) => {
  const response = await Api.post('', {
    query: `
      mutation ($compareceu: Boolean!, $agendamento: AgendamentoInput, $entradaProntuario: EntradaProntuarioInput) {
        finalizarAgendamentoRemoto(compareceu: $compareceu, agendamento: $agendamento, entradaProntuario: $entradaProntuario)
      }
    `,
    variables: variables,
  });

  if(response?.data?.data) {
    return response.data.data.finalizarAgendamentoRemoto;
  } else {
      throw(new Error(response.data.errors[0]))
  }
}

export const  createChamada= async (agendamentoId) => {
  const response = await Api.post('', {
    query: `
        mutation($agendamento: AgendamentoInput){
            createChamada(agendamento: $agendamento){
                id
                dataHoraChamada
            }
        }
    `,
    variables: {
      agendamento: {
        id: agendamentoId
      }
    }
  });
  if (response.data.errors) {
    throw response.data.errors[0];
  };

return response
}

export const enviarItemProntuarioPaciente = async (variables)=>{
  const response = await Api.post("",{
    query:`mutation($numeroTelefone: String, $entradaProntuarioId: Long) {
  enviarItemProntuarioPaciente(numeroTelefone: $numeroTelefone, entradaProntuarioId: $entradaProntuarioId)
}`,variables:variables
  })
  if(response.data.errors){
    throw response.data.error[0]
  }else{
    return response.data.data.enviarItemProntuarioPaciente
  }
}
