import React from "react";
import Route from "react-router-dom/Route";
import { matchPath } from "react-router";
import classnames from "classnames";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import styles from "../../assets/jss/pages/relatoriosStyle";
import PanelLeft from "../../components/PanelLeft/PanelLeft";
import Footer from "../../components/Footer/Footer";
import Header from "../../template/Header/Header";
import SubMenu from "../Configuracoes/Menu/SubMenu";
import RelatorioAgendamentos from "../Relatorios/Agendamentos/RelatorioAgendamentos";
import RelatorioAgendamentosSituacao from "../Relatorios/Agendamentos/RelatorioAgendamentosSituacao";
import RelatorioAtendimentosRealizados from "./Atendimentos/RelatorioAtendimentosRealizados/RelatorioAtendimentosRealizados";
import RelatorioAniversariantes from "./Paciente/RelatorioAniversariantes";
import RelatorioIndicacoes from "./Paciente/RelatorioIndicacoes";
import RelatorioIndicacoesPorData from "./Paciente/RelatorioIndicacoesPorData";
import RelatorioProcedimentosSolicitados from "./Procedimentos/RelatorioProcedimentosSolicitados";
import AUTHORITIES from "../../config/authorities";
import { LockedFallback, RoleProtection } from "../../components/RoleProtection";

import relatoriosBlur from "../../assets/img/blur-screens/relatorios.png";
import RelatorioRepasse from "./Paciente/RelatorioRepasse";
import RelatorioCID from "./Paciente/RelatorioCID";
import RelatorioUltimosAtendimentos from "./Atendimentos/RelatorioUltimosAtendimentos/RelatorioUltimosAtendimentos";
import RelatorioAgendamentosUsuario from "./Agendamentos/RelatorioAgendamentosUsuario/RelatorioAgendamentosUsuario";
import { findByUnidadeLogadaConfiguracaoUnidade } from "../../services/RelatorioService";
import RelatorioProcedimentosAgendados from "./Procedimentos/RelatorioProcedimentosAgendados/RelatorioProcedimentosAgendados";
import RelatorioProcedimentosRealizados from "./Procedimentos/RelatorioProcedimentosRealizados/RelatorioProcedimentosRealizados";

const getRoutes = ({ isUsuarioAdministrador }) => [
    {
        title: "Agendamentos",
        children: [
            {
                uri: "/agendamentos",
                title: "Agendamentos por convênio",
                component: RelatorioAgendamentos,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
            {
                uri: "/agendamentos-situacao",
                title: "Agendamentos por situação",
                component: RelatorioAgendamentosSituacao,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
            {
                uri: "/agendamentos-usuarios",
                title: "Agendamentos por usuário",
                component: RelatorioAgendamentosUsuario,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
            {
                uri: "/atendimentos-realizados",
                title: "Atendimentos realizados",
                component: RelatorioAtendimentosRealizados,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
            {
                uri: "/procedimentos-solicitados",
                title: "Procedimentos solicitados",
                component: RelatorioProcedimentosSolicitados,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
            {
                uri: "/ultimos-atendimentos",
                title: "Últimos atendimentos",
                component: RelatorioUltimosAtendimentos,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
            {
                uri: "/procedimentos-agendados",
                title: "Procedimentos agendados",
                component: RelatorioProcedimentosAgendados,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
            {
                uri: "/procedimentos-realizados",
                title: "Procedimentos realizados",
                component: RelatorioProcedimentosRealizados,
                menu: true,
                permissao: "ROLE_AGENDAMENTO_RELATORIO",
                roles: [AUTHORITIES.ROLE_AGENDAMENTO_RELATORIO],
            },
        ],
    },
    {
        title: "Geral",
        children: [
            {
                uri: "/aniversariantes",
                title: "Aniversariantes",
                component: RelatorioAniversariantes,
                menu: true,
                permissao: "ROLE_SUJEITO_ATENCAO_RELATORIO",
                roles: [AUTHORITIES.ROLE_SUJEITO_ATENCAO_RELATORIO],
            },
            {
                uri: "/indicacoes",
                title: "Indicações",
                component: RelatorioIndicacoes,
                menu: true,
                permissao: "ROLE_SUJEITO_ATENCAO_RELATORIO",
                roles: [AUTHORITIES.ROLE_SUJEITO_ATENCAO_RELATORIO],
            },
            {
                uri: "/repasse",
                title: "Repasse geral",
                component: RelatorioRepasse,
                menu: true,
                hidden: !isUsuarioAdministrador,
                roles: [AUTHORITIES.ROLE_PROFISSIONAL_SAUDE_RELATORIO],
            },
            {
                uri: "/indicacoes-por-data",
                title: "Indicações por data",
                component: RelatorioIndicacoesPorData,
                menu: true,
                permissao: "ROLE_SUJEITO_ATENCAO_RELATORIO",
                roles: [AUTHORITIES.ROLE_SUJEITO_ATENCAO_RELATORIO],
            },
            {
                uri: "/cid",
                title: "CID",
                component: RelatorioCID,
                menu: true,
                permissao: "ROLE_SUJEITO_ATENCAO_RELATORIO",
                roles: [AUTHORITIES.ROLE_SUJEITO_ATENCAO_RELATORIO],
            },
        ]
    }
];

@inject("configuracaoImpressaoStore", "unidadeStore", "relatorioStore")
@observer
class Relatorios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subMenuItems: [],
            showSidebar: true,
        };
    }

    async componentDidMount() {
        const { history, match, location, configuracaoImpressaoStore, } = this.props;

        if (history.action === "REPLACE") {
            this.getConfiguracaoUnidade();
            configuracaoImpressaoStore.getConfig('RELATORIO');
        }
        const profissionalSaudeLogado = JSON.parse(localStorage[`_immortal|profissional_saude_logado`] || null);
        const routes = getRoutes({ isUsuarioAdministrador: profissionalSaudeLogado.tipo === 3 });
        const routesRemoveHidden = routes.map(route => {
            const children = route.children.filter(item => !item.hidden);
            return {
                ...route,
                children
            }
        });

        const subMenuItems = routesRemoveHidden.map(route => {
            return {
                title: route.title,
                children: route.children,
            };
        });

        const showSidebar = routesRemoveHidden.filter(route => {
            return route.children.filter(item => {
                return item.menu === false && matchPath(location.pathName, {
                    path: `${match.path}${route.uri}`,
                    exact: true
                })
            })
        }).length !== 0;

        this.setState({ subMenuItems, showSidebar }, () => {
            const item = this.state.subMenuItems[0].children[0];
            if (item && match.isExact) {
                history.push(`${match.path}${item.uri}`);
            }
        });
    }

    getConfiguracaoUnidade = async () => {
        const { relatorioStore } = this.props;
        const configuracaoUnidade = await findByUnidadeLogadaConfiguracaoUnidade();
        
        relatorioStore.configuracaoUnidade = configuracaoUnidade;
    };

    render() {
        const { classes, match } = this.props;
        const { subMenuItems, showSidebar } = this.state;

        return (
            <div className={classnames(classes.root, showSidebar ? null : classes.rootWithoutSidebar)}>
                {showSidebar && (
                    <PanelLeft className={classes.panelLeft}>
                        <Grid item>
                            <Header>
                                <Grid
                                    item
                                    container
                                    alignItems={"center"}
                                    justify={"center"}
                                    xs={12}
                                >
                                    <h3 className={classes.titleHeader}>
                                        Relatórios
                                    </h3>
                                </Grid>
                            </Header>
                        </Grid>

                        <SubMenu items={subMenuItems} />
                    </PanelLeft>
                )}

                {subMenuItems?.length > 0 && subMenuItems?.map((route) => {
                    return route.children.map(({ uri, roles, component: Component }) => (
                        <Route
                            exact
                            key={uri}
                            path={`${match.path}${uri}`}
                            component={(props) => (
                                <RoleProtection roles={roles} fallback={(props) => (
                                    <LockedFallback blurImageSrc={relatoriosBlur} {...props} />
                                )}>
                                    <Component {...props} />
                                </RoleProtection>
                            )}
                        />
                    ));
                })}
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles)(Relatorios);
