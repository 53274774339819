import React from "react";
import moment from "moment";
import { CircularProgress, withStyles } from "@material-ui/core";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import MensagemListaVazia from "../../../components/Mensagem/MensagemListaVazia";

const ListaTitulos = (props) => {
  const {
    classes,
    titulos,
    loadMoreTitulos,
    last,
    loadingTitulos,
    selecionaTitulo,
  } = props;

  return (
    <div className={classes.contentListaTitulos}>
      <Scroll
        loadMore={() => loadMoreTitulos()}
        hasMore={!loadingTitulos && !last}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainer}
      >
        {titulos.map((titulo, index) => {
          return (
            <div
              className={classes.row}
              key={index}
              onClick={() => selecionaTitulo(titulo)}
            >
              <div> {titulo.nome} </div>
              <div> {moment(titulo.dataVencimento).format("DD/MM/YYYY")} </div>
            </div>
          );
        })}
        {titulos.length === 0 && loadingTitulos && (
          <div className={classes.notFoundContainer}>
            <CircularProgress />
          </div>
        )}
        {titulos.length === 0 && !loadingTitulos && <MensagemListaVazia />}
      </Scroll>
    </div>
  );
};

const styles = {
  row: {
    display: "flex",
    gap: "8px",
    padding: 8,
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  contentListaTitulos: {
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    padding: "4px 12px",
    background: "#F5F5F5",
    marginTop: "8px",
    width: "calc(100% - 42px)",
    height: 'calc(100% - 42px)',
  },
  scrollContainer: {
    height: "100%",
    "&> div": {
      height: "100%",
    },
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

export default withStyles(styles)(ListaTitulos);
