import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'

const ListagemAnamnese = ({ classes, resposta, type, isSolicitacaoPaciente }) => {
  const getCurrentValue = () => {
    switch (type) {
      case 'COMBOBOX':
      case 'CHECKBOX':
      case 'RADIO':
        return resposta?.valorConvertido && resposta?.nome
      case 'DATE':
        return moment(resposta?.valorConvertido).format('DD/MM/YYYY')
      case 'DATETIME':
        return moment(resposta?.valorConvertido).format('DD/MM/YYYY HH:mm')
      default:
        return resposta?.valorConvertido
    }
  }

  return <span className={classNames(classes.valueListagem, isSolicitacaoPaciente && classes.padding)}>{getCurrentValue()}</span>
}

const styles = () => ({
  valueListagem: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#505050',
  },
  padding: {
    paddingLeft: 8
  }
})

export default withStyles(styles)(ListagemAnamnese)
