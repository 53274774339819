import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import ArrowAmpliarIcon from "../../../../../components/Icon/ArrowAmpliarIcon";
import DenteIcon from "../../../../../components/Icon/DenteIcon";
import { Button } from "../../../../../components/ui/Buttons";

const HeaderOdontograma = observer(
  ({ classes, odontogramaStore, prontuarioStore }) => {
    const {
      tabVisualizacaoSelected,
      isTelaAmpliada,
      odontograma,
      modalConfiguracao,
    } = odontogramaStore;
    const { denteDeciduo, dentePermanente } = odontograma;

    const disabled = !odontograma?.id;

    const { sujeitoAtencaoSelected } = prontuarioStore;

    const handleClickAdicionarProcedimento = () => {
      odontogramaStore.modalProcedimento.showModalProcedimento = true;
    };

    const handleClickConfiguracoes = () => {
      odontogramaStore.modalConfiguracao={
        ...modalConfiguracao,
        dentesTipoSelecionado: {
          Permanentes: [...dentePermanente],
          Deciduo: [...denteDeciduo],
        },
        showModalConfiguracao: true,
      }
    };

    const handleClickAmpliar = () => {
      odontogramaStore.isTelaAmpliada = true;
    };

    const getTipoDente = () => {
      const tipoDente =
        tabVisualizacaoSelected === 0 ? "Permanentes" : "Decíduos";
      return `Dentes ${tipoDente}`;
    };

    const handleSairTelaCheia = () => {
      odontogramaStore.isTelaAmpliada = false;
    };

    return (
      <>
        {isTelaAmpliada && (
          <div className={classes.titleNome}>
            {sujeitoAtencaoSelected?.nome}
          </div>
        )}
        <div className={classes.content}>
          <div className={classes.tipoDente}> {getTipoDente()} </div>
          <div className={classes.contentButtons}>
            <div className={classes.contentButtonAdicionarProcedimento}>
              <Button
                bgColor="#009A95"
                onClick={handleClickAdicionarProcedimento}
                disabled={disabled}
              >
                Adicionar procedimento
              </Button>
            </div>
            <Button
              shape='circle'
              bgColor='#707C97'
              onClick={handleClickConfiguracoes}
              disabled={disabled}
            >
              <DenteIcon color="#fff" />
            </Button>

            {isTelaAmpliada ? (
              <div className={classes.contentButtonSairTelaCheia}>
                <Button
                  bgColor='#FB7676'
                  onClick={handleSairTelaCheia}
                >
                  Sair da tela cheia
                </Button>
              </div>
            ) : (
              <Button
                shape='circle'
                bgColor='#F9BE73'
                onClick={handleClickAmpliar}
              >
                <ArrowAmpliarIcon color="#fff" />
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
);

const styles = {
  content: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    height: "40px",
    alignItems: "center",
  },
  titleNome: {
    fontWeight: 700,
    color: "#141414",
    fontSize: "18px",
    paddingTop: "24px",
  },
  tipoDente: {
    color: "#5F6368",
    fontWeight: 700,
    fontSize: "18px",
  },
  contentButtonSairTelaCheia: {
    width: "100%",
    maxWidth: "160px",
  },
  contentVisualizacao: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  titleVisualizacao: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#5F6368",
  },
  contentButtons: {
    display: "flex",
    justifyContent: "end",
    gap: "8px",
    alignItems: "center",
  },
  contentButtonAdicionarProcedimento: {
    maxWidth: "210px",
    width: "100%",
  },
};

const HeaderOdontogramaWithStyles = withStyles(styles)(HeaderOdontograma);
export default inject(
  "odontogramaStore",
  "prontuarioStore"
)(HeaderOdontogramaWithStyles);
