import Api from "../config/api";

export const configuracaoHorarioAtendimentoVigente = ({ profissionalSaudeId, dataInicial, dataFinal }) => {
  return Api.post('', {
    query: `
      query ($searchDTO: SearchDTOInput) {
        configuracaoHorarioAtendimentoVigente(searchDTO: $searchDTO) {
          id
          dataInicioVigencia
          dataFimVigencia
        }
      }
    `,
    variables: {
      searchDTO: {
        profissionalSaudeId,
        dataInicial,
        dataFinal
      }
    }
  });
};

export const configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude = ({ 
  profissionalSaudeId, 
  unidadeId,
  dataInicial,
  dataFinal
}) => {
  return Api.post('', {
    query: `
      query($searchDTO: SearchDTOInput) {
        configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude(searchDTO: $searchDTO) {
          id
          dataInicioVigencia,
          dataFimVigencia,
          duracao
        }
      }
    `,
    variables: {
      searchDTO: {
        profissionalSaudeId, 
        unidadeId,
        dataInicial,
        dataFinal
      }
    }
  });
};

export const findEspacosLivresDoProfissionalSaude = ({
  profissionalSaudeId,
  horaInicio,
  horaFim,
  data
}) => {
  return Api.post('', {
    query: `
      query($profissionalSaudeId: Long, $horaInicio: LocalTime, $horaFim: LocalTime, $data: LocalDate) {
        findEspacosLivresDoProfissionalSaude(
          profissionalSaudeId: $profissionalSaudeId,
          horaInicio: $horaInicio,
          horaFim: $horaFim,
          data: $data
        )
      }
    `,
    variables: {
      profissionalSaudeId,
      horaInicio,
      horaFim,
      data
    }
  });
};

export const findByIdConfiguracaoHorarioAtendimento = async ({ id }) => {
  const response = await Api.post('', {
    query: `
      query($id: Long) {
        findByIdConfiguracaoHorarioAtendimento(id: $id) {
          id
          duracao
          horaInicioPeriodoAtendimento
          horaFimPeriodoAtendimento
          sala {
            id
          }
          horariosAtendimento {
            diaSemana
            horaFim
            horaInicio
            procedimentos {
              id
              nome
            }
            permiteAgendamento
            solicitante {
              id
              nome
            }
          }
        }
      }
    `,
    variables: {
      id,
    },
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findByIdConfiguracaoHorarioAtendimento;
};

export const updateProcedimentosConfiguracaoHorarioAtendimento = async (configuracaoHorarioAtendimento) => {
  const response = await Api.post('', {
    query: `
      mutation ($configuracaoHorarioAtendimento: ConfiguracaoHorarioAtendimentoInput) {
        updateProcedimentosConfiguracaoHorarioAtendimento(configuracaoHorarioAtendimento: $configuracaoHorarioAtendimento) {
          id
        }
      }
    `,
    variables: {
      configuracaoHorarioAtendimento
    },
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.updateProcedimentosConfiguracaoHorarioAtendimento;
};
