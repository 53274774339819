import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import {isMobile} from "react-device-detect";
import ButtonLong from "../../components/Button/ButtonLong";
import InputSenha from "../../components/Input/InputSenha";
import { ITENS_TO_VERIFY, isValidPassword } from "../../utils/passwordValidations";
import { withStyles } from "@material-ui/core";

import styles from "./RecuperarSenhaStyles";




const AlterarSenha = observer((props) => {
  const { classes, recuperarSenhaStore, history, token } = props;
  const [credentials, setCredentials] = useState({
    novaSenha: "",
    confirmacaoSenha: "",
  });
  const [erroSenha, setErroSenha] = useState(false);

  useEffect(() => {
    credentials.novaSenha && debounceOnChangeSenha()

  }, [credentials]);

  const handleSenhaChange = (e) => {
    const novaSenha = e.target.value;

    setCredentials({
      ...credentials,
      novaSenha,
    });
    setErroSenha(!senhasCoincidem(novaSenha, credentials.confirmacaoSenha));
  };

  const senhasCoincidem = (novaSenha, confirmacaoSenha) => {
    return novaSenha === confirmacaoSenha;
  };

  const debounceOnChangeSenha = () => {
    recuperarSenhaStore.closeNotification()
  };

  const handleConfirmarSenhaChange = (e) => {
    const confirmacaoSenha = e.target.value;

    setCredentials({
      ...credentials,
      confirmacaoSenha,
    });
    setErroSenha(!senhasCoincidem(credentials.novaSenha, confirmacaoSenha));
  };

  const handleSalvarNovaSenha = async (event) => {
    event.preventDefault();

    try {
      await recuperarSenhaStore.salvarNovaSenha(
        token,
        credentials.novaSenha,
        credentials.confirmacaoSenha
      );

      setCredentials({ novaSenha: "", confirmacaoSenha: "" });

      if (!isMobile) {
        history.replace("login?redefinir-senha=1");
        return;
      }

      recuperarSenhaStore.openNotification(
        "Sua senha foi redefinida com sucesso.",
        "success"
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderVerifyPassword = value => {
    return (
      <div className={classes.verifyPassword}>
        <h1>Senha deve conter:</h1>
        <div className={classes.wrapperItens}>
          {ITENS_TO_VERIFY.map((verifyFunction, index) => {
            const { isValid, message } = verifyFunction(value)
            const currentClass = isValid
              ? classes.validPasswordItemText
              : classes.invalidPasswordItemText
            return (
              <ul key={index} className={classes.verifyPasswordItem}>
                <li
                  className={classNames(
                    classes.verifyPasswordItemText,
                    value.length > 0 && currentClass
                  )}
                >
                  {message}
                </li>
              </ul>
            )
          })}
        </div>
      </div>
    )
  }

  const disabledButton = 
    !isValidPassword(credentials.novaSenha) || 
    !isValidPassword(credentials.novaSenha) ||
    !senhasCoincidem(credentials.novaSenha, credentials.confirmacaoSenha);

  return (
    <>
      {renderVerifyPassword(credentials.novaSenha)}
      <div className={classes.titleInput}> Senha </div>
      <InputSenha
        className={classNames(classes.inputStyle, erroSenha && classes.inputError)}
        placeholder="Senha"
        value={credentials.novaSenha}
        onChange={handleSenhaChange}
      />
      <div className={classes.titleInput}> Confirmar senha </div>
      <InputSenha
        placeholder="Confirmar Senha"
        value={credentials.confirmacaoSenha}
        onChange={handleConfirmarSenhaChange}
        className={classNames(classes.inputStyle, erroSenha && classes.inputError)}
      />
      <ButtonLong
        colorCustom="green"
        disabled={disabledButton}
        type="submit"
        className={classes.buttonEnviar}
        loading={recuperarSenhaStore.redefinindo}
        onClick={handleSalvarNovaSenha}
      >
        Enviar
      </ButtonLong>
    </>
  );
});

const AlterarSenhaWithStyles = withStyles(styles)(AlterarSenha);
export default inject("recuperarSenhaStore")(AlterarSenhaWithStyles);
