import React from 'react'
import Dialog from '../../../../../components/Dialog/Dialog'
import { withStyles } from '@material-ui/core'
import CloseIcon from '../../../../../components/Icon/CloseIcon'
import CopyIcon from '../../../../../components/Icon/CopyIcon'

import InputForm from '../../../../../components/Input/InputForm'
import { Button } from '../../../../../components/ui/Buttons'
import { WhatsApp } from '../../../../../components/Icon'

function ModalLinkTelemedicina({
  open,
  onClose,
  classes,
  handleCopyLinkToClipboard,
  linkTelemedicina,
  handleClickWhatsApp,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.paperAlert,
      }}
    >
      <div className={classes.container}>
        <div className={classes.wrapperHeader}>
          <span>Link da chamada</span>
          <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
        </div>
        <span className={classes.description}>
          Copie o link da chamada ou envie via whatsapp para seu paciente
        </span>
        <div>
          <span className={classes.labelInputLink}>Link teleconsulta:</span>
          <div
            className={classes.wrapperInputLink}
            onClick={() => {
              handleCopyLinkToClipboard()
              onClose()
            }}
          >
            <InputForm
              id='link-consulta-remota'
              value={linkTelemedicina}
              className={classes.inputInfosLink}
              classes={{
                input: classes.inputInfosLink,
              }}
              customStyle={classes.inputLink}
              disabled
            />
            <div className={classes.buttonCopyLink}>
              <CopyIcon color='#868686' />
            </div>
          </div>
        </div>
        <Button
          fullWidth
          onClick={() => {
            handleClickWhatsApp()
            onClose()
          }}
          endEnhancer={() => <WhatsApp color='white' />}
        >
          Enviar link pelo Whatsapp
        </Button>
      </div>
    </Dialog>
  )
}

const styles = theme => ({
  paperAlert: {
    width: '100%',
    height: '100%',
    maxHeight: 250,
    maxWidth: 300,
    borderRadius: '16px',
    padding: '24px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  wrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > span': {
      fontSize: '18px',
      fontWeight: '600',
      color: '#505050',
    },
  },
  description: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#505050',
  },
  labelInputLink: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#868686',
  },
  wrapperInputLink: {
    backgroundColor: '#f2f2f2',
    height: 40,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  inputInfosLink: {
    width: '100%',
    border: 'none',
  },
  buttonCopyLink: {
    width: '22px',
    height: '20px',
    borderRadius: '100px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
    '&:hover': {
      opacity: 0.8,
    },
  },
})

export default withStyles(styles)(ModalLinkTelemedicina)
