import React from 'react';
import { withStyles } from "@material-ui/core/styles/index";
import classNames from 'classnames';

const styles = (theme) => ({
    partyBox: {
        width: '12px',
        height: '12px'
    },
    circle: {
        background: '#F9BE73',
        borderRadius: 100,
        padding: '1px',
        marginTop: '-15px',
        marginLeft: '2px',
        display: 'flex',
        alignContent: 'center',
        width: '15px',
        height: '15px',
        justifyContent: 'center',
    },
    circleDrawerMinimizado: {
        marginLeft: '30px',
    },
});

const IconeAniversario = ({ classes, aniversario, selecionado, isDrawerMinimizado }) => {

    return (
        aniversario ?
            <div className={classNames(classes.circle, isDrawerMinimizado && classes.circleDrawerMinimizado)}>
            <svg className={classes.partyBox} viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.00002 1.98158C3.33302 1.98158 3.60002 1.71536 3.60002 1.38998C3.60002 1.27758 3.57002 1.17405 3.51302 1.08531L3.00002 0.206787L2.48702 1.08531C2.43002 1.17405 2.40002 1.27758 2.40002 1.38998C2.40002 1.71536 2.67002 1.98158 3.00002 1.98158ZM4.80002 2.86897H3.30002V2.27737H2.70002V2.86897H1.20002C0.702018 2.86897 0.300018 3.26534 0.300018 3.75637V6.41855C0.300018 6.58124 0.435018 6.71435 0.600018 6.71435H5.40002C5.56502 6.71435 5.70002 6.58124 5.70002 6.41855V3.75637C5.70002 3.26534 5.29802 2.86897 4.80002 2.86897ZM5.10002 6.12275H0.900018V5.23536C1.17002 5.2324 1.42802 5.12591 1.62002 4.9366L1.94702 4.6201L2.26802 4.9366C2.66102 5.3241 3.34502 5.32114 3.73502 4.9366L4.05902 4.6201L4.38002 4.9366C4.57202 5.12591 4.83002 5.2324 5.10002 5.23536V6.12275ZM5.10002 4.79166C4.94702 4.7887 4.80302 4.7325 4.69502 4.62306L4.05602 3.99301L3.41402 4.62306C3.19202 4.84195 2.80502 4.84195 2.58302 4.62306L1.94402 3.99301L1.30202 4.62306C1.19702 4.72954 1.05302 4.7887 0.900018 4.79166V3.75637C0.900018 3.59368 1.03502 3.46057 1.20002 3.46057H4.80002C4.96502 3.46057 5.10002 3.59368 5.10002 3.75637V4.79166Z"
                    fill="white" />
            </svg>
        </div >
            : null
    )
}

export default withStyles(styles)(IconeAniversario);