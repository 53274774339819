import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import InputForm from "../../../../../../components/Input/InputForm";

const TabObservacoes = observer(({ classes, orcamentoStore }) => {
  const { orcamento, handleFieldModal } = orcamentoStore;
  const { observacao } = orcamento;

  return (
    <InputForm
      value={observacao}
      className={classes.inputObservacao}
      rowsMax={20}
      rows={5}
      multiline
      onChange={({ target: { value } }) =>
        handleFieldModal("observacao", value)
      }
    />
  );
});

const styles = {
  inputObservacao: {
    width: "calc(100% - 19px)",
    "& textarea": {
      borderRadius: "10px",
      border: "1px solid rgba(0, 0, 0, 0.10)",
      height: "100%",
      maxHeight: "120px",
      background: '#f2f2f2',
    },
  },
};

const TabObservacoesWithStyles = withStyles(styles)(TabObservacoes);
export default inject("orcamentoStore")(TabObservacoesWithStyles);
