import { withStyles } from '@material-ui/core';
import { inject } from 'mobx-react';
import { Grid, List, CircularProgress } from "@material-ui/core";
import { observer } from 'mobx-react-lite';
import classNames from "classnames";
import React from 'react';
import styles from '../SADTStyle';
import ItemList from "../../../../components/Modal/ItemList";
import Scroll from "../../../../components/InfiniteScroll/Scroll";

const Sugestoes = observer(props => {
    const { classes, SADTStore } = props;

    const loadMoreSugestoes = async () => {
        if (SADTStore.sugestoesLoading) {
          return;
        }
    
        SADTStore.sugestoesPagAtual =
          SADTStore.sugestoes.length > 0 ? SADTStore.sugestoesPagAtual + 1 : 0;
    
        await SADTStore.findAllGuiaServicoTussViewQuantidadeUso({
          pageableDTO: {
            pageSize: 10,
            pageNumber: SADTStore.sugestoesPagAtual,
            sortDir: "DESC",
            sortField: "quantidadeUso",
          },
        });
      };
    
      const selectSugestao = (item) => {
        const { objView } = SADTStore;
    
        if (SADTStore.disabledListaLateral) {
          return;
        }
    
        objView.descricao = {
          value: item.tussView.descricao,
          label: item.tussView.descricao,
        };
        objView.tabela = {
          value: item.tussView.tabela,
          label: item.tussView.tabela,
        };
        objView.codigoTermo = {
          value: item.tussView.codigoTermo,
          label: item.tussView.codigoTermo,
        };
      };

    return(
        <Scroll
          loadMore={loadMoreSugestoes}
          initialLoad={false}
          hasMore={!SADTStore.sugestoesLast}
          pageStart={0}
          className={classes.scrollContainerSugestoes}
        >
          <List>
            {SADTStore.sugestoes.map((item, i) => (
              <Grid
                container
                key={i}
                className={classNames(
                  classes.cardSugestao,
                  SADTStore.disabledListaLateral && classes.cursorDefault
                )}
                onClick={() => selectSugestao(item)}
              >
                <div
                  className={
                    SADTStore.disabledListaLateral && classes.cardSugestaoDisabled
                  }
                >
                  <ItemList
                    size={12}
                    field={"Descrição:"}
                    value={item.tussView.descricao}
                    className={classes.itemList}
                  />
                  <div className={classes.codigoTabela}>
                    <ItemList
                      size={6}
                      field={"Código:"}
                      value={item.tussView.codigoTermo}
                      className={classes.itemList}
                    />
                    <ItemList
                      size={6}
                      field={"Tabela:"}
                      value={item.tussView.tabela}
                      className={classes.itemList}
                    />
                  </div>
                </div>
              </Grid>
            ))}
            {SADTStore.sugestoesLoading && (
              <div className={classes.notFoundContainer}>
                <CircularProgress style={{ color: "#fff" }} />
              </div>
            )}
          </List>
        </Scroll>
    )
});

const SugestoesWithStyles = withStyles(styles)(Sugestoes);
export default inject("SADTStore")(SugestoesWithStyles)