const styles = {
  content: {
    "& tbody td": {
      padding: "0 8px",
      "&:last-child": {
        paddingRight: "8px",
      },
    },
  },
  contentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "8px 0 16px",
  },
  title: {
    color: "#5F6368",
    fontWeight: 700,
    fontSize: "18px",
  },
  contentTable: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: 'auto',
  },
  table: {
    paddingTop: "4px",
    "& td": {
      padding: "0 16px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: "#868686",
    },
  },
  tableHeader: {
    zIndex: 0,
    "& tr": {
      height: "40px",
    },
    "& th": {
      background: "#f2f2f2",
      padding: "0 16px",
      textAlign: "start",
      "&:first-child": {
        borderRadius: "8px 0 0 0",
      },
      "&:last-child": {
        borderRadius: "0 8px 0 0",
      },
    },
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
  },
  contentHeaderButton: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }
};

export default styles;
