const styles = {
  header: {
    height: "48px",
    padding: "8px 27px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
    position: "relative",
    boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.03)",
  },
  redesSocial: {
    display: "flex",
    justifyContent: "space-between",
    width: "125px",
  },
  buttonRedeSocial: {
    display: "flex",
    cursor: "pointer",
    width: "16px",
    height: "16px",
  },
  content: {
    background: "#F5F5F5",
    display: "flex",
    flexDirection: "row",

    "@media (max-width: 768px)": {
      flexDirection: 'column',
      height: "calc(100vh - 64px)",
      overflow: 'auto',
    },
  },
  imagemLoginContent: {
    display: 'flex',
    boxShadow: "0px 10px 15px 0px rgb(0 0 0 / 3%)",
    height: '100vh',
    minWidth: '622px'
  },
  imagemLogin: {
    marginTop: "-64px",
    height: "100vh",
  },
  contentRight: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "@media (max-width: 768px)": {
      height: "100%",
      width: "100%"
    },
  },
  linkTermosPoliticas: {
    textDecorationLine: "underline",
    cursor: "pointer",
    width: "auto",
    height: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#505050",
  },
  contentTermos: {
    display: "flex",
    gap: "8px",
  },
  direitosReservados: {
    fontSize: "12px",
    lineHeight: "15px",
    color: "#525252",
  },
  infosFooter: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 30px 22px 18px",

    "@media (max-width: 768px)": {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      margin: "18px",
    },
  },
  contentChildren: {
    height: "100%",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default styles;
